import { useState, useEffect } from "react"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { isEqual } from "lodash"
import { useQuery } from "@tanstack/react-query"
import { TemplateSection } from "./TemplateSection"

import { queryKeys } from "react-query/constants"
import { getSectionTypes } from "api"
import { SortableNestedList } from "common/nested-list/SortableNestedList"
import { updateInTree } from "common/nested-list/utils/flatMap"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"

const useStyles = makeStyles()(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(4),
  },
  fullWidth: {
    gridColumn: "1 / 3",
  },
  actions: {
    gridColumn: "1 / 3",
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  invalid: {
    border: "1px solid red",
    borderRadius: "10px",
    padding: "5px",
  },
  valid: {
    padding: "5px",
  },
}))

export default function TemplateSections({ sections, onUpdate, onDeleteSection }) {
  const { classes } = useStyles()
  const [orderedSections, setOrderedSections] = useState(sections)
  const { data: sectionTypesResponse } = useQuery([queryKeys.sectionTypes], getSectionTypes)

  useEffect(() => {
    setOrderedSections(sections)
  }, [sections])

  function nestedReorder(sections) {
    if (!sections) return
    for (let i = 0; i < sections.length; i++) {
      sections[i].render_order = i
      nestedReorder(sections[i].children)
    }
  }

  const reorderSections = sections => {
    nestedReorder(sections)
    setOrderedSections(sections)
    onUpdate(sections)
  }

  function deleteHelper(section, sectionList) {
    for (let i = 0; i < sectionList.length; i++) {
      if (isEqual(section, sectionList[i])) {
        sectionList.splice(i, 1)
        return
      } else {
        deleteHelper(section, sectionList[i].children)
      }
    }
  }
  const handleDelete = section => {
    deleteHelper(section, orderedSections)
    if (section.pk) {
      onDeleteSection(section.pk)
    }
    nestedReorder(orderedSections)
    setOrderedSections([...orderedSections])
    onUpdate(orderedSections)
  }

  const handleAdd = () => {
    const section = {
      render_order: orderedSections.length,
      damage_type: "economic",
      children: [],
      posttext: "",
      posttext_json: DEFAULT_VALUE,
      text: "",
      text_json: DEFAULT_VALUE,
    }
    const addedSections = [...orderedSections, section]
    setOrderedSections(addedSections)
  }

  const handleChange = section => {
    const nextSections = updateInTree(orderedSections, item => item.pk === section.pk, section)

    onUpdate(nextSections)
  }

  return (
    <>
      <Box className={classes.fullWidth}>
        <Typography>Damages Sections</Typography>
        <p>In addition to the substitutions provided above you have the following for each section type:</p>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Section Type</TableCell>
              <TableCell>Substitutions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Loss of Income</TableCell>
              <TableCell>
                <div>
                  <b>[[income_loss]]</b> Total Income Loss (e.g. $1,000.00)
                </div>
                <div>
                  <b>[[future_income_loss]]</b> Future Income Loss (e.g. $1,000.00)
                </div>
                <div>
                  <b>[[past_income_loss]]</b> Past Income Loss (e.g. $1,000.00)
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Loss of Household Services</TableCell>
              <TableCell>
                <div>
                  <b>[[past_adjusted_loss]]</b> past adjusted household loss (e.g. $1,000.00)
                </div>
                <div>
                  <b>[[future_adjusted_loss]]</b> future adjusted household loss (e.g. $1,000.00)
                </div>
                <div>
                  <b>[[total_adjusted_loss]]</b> total adjusted household loss (e.g. $1,000.00)
                </div>
                <div>
                  <b>[[starting_hh_hours]]</b> household hours at start of impairment (e.g. 12)
                </div>
                <div>
                  <b>[[housekeeping_salary]]</b> salary for housekeeper from BLS data (e.g. $1,000.00)
                </div>
                <div>
                  <b>[[end_of_impairment_date]]</b> end of impairment date (e.g. December 25, 2022)
                </div>
                <div>
                  <b>[[start_of_impairment_date]]</b> start of impairment date (e.g. December 25, 2022)
                </div>
                <div>
                  <b>[[start_of_impairment_year]]</b> start of impairment year (e.g. 2020)
                </div>
                <div>
                  <b>[[past_impairment_rate]]</b> Past impairment rate (e.g. 40%)
                </div>
                <div>
                  <b>[[future_impairment_rate]]</b> Future impairment rate (e.g. 40%)
                </div>
                <div>
                  <b>[[past_impaired_days]]</b> Number of past impaired days (days between start and end
                  impairment date) (e.g. 200)
                </div>
                <div>
                  <b>[[future_impaired_days]]</b> Number of future impaired days (from end of impairment +
                  years + days) (e.g. 200)
                </div>
                <div>
                  <b>[[impaired_days]]</b> Total number of impaired days (e.g. 200)
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Verdict Analysis</TableCell>
              <TableCell>
                <div>
                  <b>[[analyses_statement]]</b> comparable verdicts (&ldquo;comparable verdicts and per diem
                  analysis&rdquo;, &ldquo;comparable verdicts&rdquo;, or &ldquo;per diem analysis&rdquo;)
                </div>
                <div>
                  <b>[[amount]]</b> The total amount for damages. (e.g. $1,000.00)
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Past and Future Medical Expenses</TableCell>
              <TableCell>
                <i>None</i>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Per-Diem Analysis</TableCell>
              <TableCell>
                <div>
                  <b>[[first_hourly_rate_pain_suffering]]</b> Past hourly rate (e.g. $1,000.00)
                </div>
                <div>
                  <b>[[second_hourly_rate_pain_suffering]]</b> Future hourly rate (e.g. $1,000.00)
                </div>
                <div>
                  <b>[[start_of_past_impairment]]</b> Start date of past impairment, i.e. date of incident
                  (e.g. December 25, 2022)
                </div>
                <div>
                  <b>[[end_of_past_impairment]]</b> End of past impairment (e.g. December 25, 2022)
                </div>
                <div>
                  <b>[[start_of_future_impairment]]</b> Start of future impairment (e.g. December 25, 2022)
                </div>
                <div>
                  <b>[[end_of_future_impairment]]</b> End of future impairment: start of future impairment +
                  impaired years + impaired days (e.g. December 25, 2022)
                </div>
                <div>
                  <b>[[amount]]</b> Sum of past and future per diem amounts (e.g. $1,000.00)
                </div>
                <div>
                  <b>[[past_amount]]</b> Past per diem amount (e.g. $1,000.00)
                </div>
                <div>
                  <b>[[future_amount]]</b> Future per diem amount (e.g. $1,000.00)
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Custom</TableCell>
              <TableCell>
                <div>
                  <b>[[amount]]</b> replace the amount that the section is worth (provided when a Demand is
                  edited) (e.g. $1,000.00)
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box></Box>
      <Box className={classes.fullWidth}>
        <SortableNestedList
          items={orderedSections}
          uniqueKey="pk"
          onUpdate={reorderSections}
          ItemContentComponent={TemplateSection}
          contentProps={{
            sectionTypes: sectionTypesResponse?.results,
            onDelete: handleDelete,
            onChange: handleChange,
          }}
        />
      </Box>
      <Box className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleAdd}>
          Add New Section
        </Button>
      </Box>
    </>
  )
}
