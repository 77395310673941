import { useEffect } from "react"
import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { Skeleton, Table, TableCell, TableHead, TableRow } from "@mui/material"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { documentsService } from "api/services/documents"
import { useSearchParams } from "react-router-dom"
import { GeneratedDocumentsRow } from "./GeneratedDocumentsRow"
import { useSuspenseQuery } from "@suspensive/react-query"
import { withSuspense } from "common/withSuspense"
import { ViewRequestSectionsId } from "../../constants"
import { DOCUMENT_GENERATION_STATUS } from "api/services/documents/types"

interface GeneratedDocumentsProps {
  documentId: string
  requestId: PrimaryKey
  title: string
  refetchInterval?: number
}

const REFETCH_INTERVAL = 5_000

export const GeneratedDocuments = withSuspense(
  ({ documentId, requestId, title, refetchInterval = REFETCH_INTERVAL }: GeneratedDocumentsProps) => {
    const { data: documentGenerations } = useSuspenseQuery(
      [queryKeys.documentGenerations, documentId],
      () => documentsService.getGenerationList({ documentId }),
      {
        meta: SILENT_QUERY_PARAMS.meta,
        refetchInterval: data => {
          return data?.some(
            ({ status }) =>
              status === DOCUMENT_GENERATION_STATUS.IN_PROGRESS ||
              status === DOCUMENT_GENERATION_STATUS.NOT_STARTED
          )
            ? refetchInterval
            : false
        },
      }
    )
    const [params] = useSearchParams()

    useEffect(() => {
      if (params.get("scrollTo") !== ViewRequestSectionsId.GeneratedMedicalChronology) return

      const generatedMedChronSection = document.getElementById(
        ViewRequestSectionsId.GeneratedMedicalChronology
      )

      if (generatedMedChronSection) {
        generatedMedChronSection.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }, [params])

    return (
      <RequestFormModule title={title} id={ViewRequestSectionsId.GeneratedMedicalChronology}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Started</TableCell>
              <TableCell>Completed At</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center" width="10%">
                Download
              </TableCell>
            </TableRow>
          </TableHead>
          {documentGenerations.map(generation => (
            <GeneratedDocumentsRow
              key={generation.id}
              documentGeneration={generation}
              documentId={documentId}
              requestId={requestId}
            />
          ))}
        </Table>
      </RequestFormModule>
    )
  },
  <RequestFormModule title="Generated Medical Chronology">
    <Skeleton variant="rounded" height={40} />
    <Skeleton variant="rounded" height={40} />
  </RequestFormModule>
)
