import { FirmsUserManageModel } from "api/services/users/models"
import { ALL_ROLES, INTERNAL_ROLES } from "common/models/roles"
import { isNull, isUndefined } from "lodash"

export const canRoleDeleteEntityByFirmId = (
  userRole: ALL_ROLES,
  firmId?: Nullable<PrimaryKey>,
  firmsUserManage?: FirmsUserManageModel
): boolean => {
  const isLegalOpsAdmin = INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
  const isLegalOps = INTERNAL_ROLES.LEGALOPS_MANAGER === userRole

  if (isLegalOpsAdmin) return true

  if (isLegalOps && firmsUserManage && !isNull(firmId) && !isUndefined(firmId)) {
    return firmsUserManage.canUserManageTheFirm(firmId)
  }

  return false
}

export const canRoleEditEntityByFirmId = (
  userRole: ALL_ROLES,
  firmId?: Nullable<PrimaryKey>,
  firmsUserManage?: FirmsUserManageModel
): boolean => {
  const isLegalOpsAdmin = INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
  const isLegalOps = INTERNAL_ROLES.LEGALOPS_MANAGER === userRole || INTERNAL_ROLES.LEGALOPS === userRole

  if (isLegalOpsAdmin) return true

  if (isLegalOps && firmsUserManage && !isNull(firmId) && !isUndefined(firmId)) {
    return firmsUserManage.canUserManageTheFirm(firmId)
  }

  return false
}
