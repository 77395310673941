import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import { Appointment } from "documents/types"
import { flagsDataSelectors } from "documents/store/flags"
import { useDocumentStore } from "documents/store"
import { FlagMark, FlagMarkProps } from "./FlagMark"
import { useShallow } from "zustand/react/shallow"
import { DATA_FLAG_ID, FLAG_IMAGE_SIZE } from "./types"
import { groupBy } from "lodash"
import { useDebouncedCallback } from "use-debounce"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"

export interface FlagMarksProps {
  appointmentId: Appointment["id"]
  anchorRef: MutableRefObject<Nullable<HTMLDivElement>>
  editMode?: boolean
  content?: EditorRoot
}

const FLAGS_IN_ROW = 3

// Flags indicators in editors text
export const EditorFlagMarks = ({ appointmentId, editMode, content, anchorRef }: FlagMarksProps) => {
  const flagsInAppointment = useDocumentStore(
    useShallow(flagsDataSelectors.getFlagsIdInAppointment(appointmentId))
  )

  const [flagsMark, setFlagsMark] = useState<FlagMarkProps[]>([])

  const calculateFlagsMark = useCallback(() => {
    if (!anchorRef.current || !flagsInAppointment.length) {
      return setFlagsMark([])
    }

    const wrapperY = anchorRef.current.getBoundingClientRect().y
    const flagsElements = [...anchorRef.current.querySelectorAll(`[${DATA_FLAG_ID}]`)]

    if (flagsElements.length) {
      const result: FlagMarkProps[] = []

      flagsInAppointment.forEach(id => {
        const firstFlagWithId = flagsElements.find(flagElement => {
          return flagElement.getAttribute(DATA_FLAG_ID)?.includes(id)
        })

        if (firstFlagWithId) {
          result.push({
            flagId: id,
            top: Math.round(firstFlagWithId.getBoundingClientRect().y - wrapperY + 2),
          })
        }
      })

      Object.values(groupBy(result, "top")).forEach(flagDataByTop => {
        if (flagDataByTop.length < 2) return

        flagDataByTop.forEach((flagData, i, array) => {
          const haveAdjustment = array.length > FLAGS_IN_ROW
          const rowTopAdjustment = haveAdjustment ? Math.floor(i / FLAGS_IN_ROW) * FLAG_IMAGE_SIZE : 0

          flagData.right = (i % FLAGS_IN_ROW) * FLAG_IMAGE_SIZE

          if (haveAdjustment) flagData.top = flagData.top + rowTopAdjustment
        })
      })

      setFlagsMark(result)
    } else {
      setFlagsMark([])
    }
  }, [anchorRef, flagsInAppointment])

  const debouncedCalculateFlagsMark = useDebouncedCallback(calculateFlagsMark, 200)
  const debouncedCallback = useRef(debouncedCalculateFlagsMark)

  useEffect(() => {
    calculateFlagsMark()
  }, [flagsInAppointment, calculateFlagsMark, editMode])

  useEffect(() => {
    const callback = debouncedCallback.current

    callback()

    return () => {
      callback.cancel()
    }
  }, [flagsInAppointment, content])

  return flagsMark.map(flagData => <FlagMark key={flagData.flagId} {...flagData} />)
}
