import { DOCUMENT_TYPES } from "requests/enums"
import { StyledRequiredIndicator } from "./styled"

export const REQUEST_FORM_HEADER_DATA = {
  [DOCUMENT_TYPES.STANDARD]: {
    title: "Request a Standard demand",
    titleText: "Begin generating your complete demand by providing the information below.",
    additionTitleText: (
      <>
        (All fields not marked with <StyledRequiredIndicator>*</StyledRequiredIndicator> are optional)
      </>
    ),
  },
  [DOCUMENT_TYPES.BASIC_PLUS]: {
    title: "Request a Basic+ demand",
    titleText: "Begin generating your demand by filling out the following form.",
    additionTitleText: (
      <>
        (All fields not marked with <StyledRequiredIndicator>*</StyledRequiredIndicator> are optional)
      </>
    ),
  },
  [DOCUMENT_TYPES.SIMPLE]: {
    title: "Request a Simple demand",
    titleText: "Begin generating your demand by filling out the following form.",
    additionTitleText: (
      <>
        (All fields not marked with <StyledRequiredIndicator>*</StyledRequiredIndicator> are optional)
      </>
    ),
  },
  [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY]: {
    title: "Request a Medical Chronology",
    titleText: "Begin generating your complete Medical Chronology by providing the information below.",
    additionTitleText: (
      <>
        (Any fields not marked by <StyledRequiredIndicator>*</StyledRequiredIndicator> are optional)
      </>
    ),
  },
  [DOCUMENT_TYPES.PREMIUM_MEDCHRON]: {
    title: "Request a Medical Chronology",
    titleText: "Begin generating your complete Medical Chronology by providing the information below.",
    additionTitleText: (
      <>
        (Any fields not marked by <StyledRequiredIndicator>*</StyledRequiredIndicator> are optional)
      </>
    ),
  },
}
