import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"

import "./properties.css"
import { keyframes } from "tss-react"
export type Optional<T> = {
  [K in keyof T]?: T[K]
}

interface RadialProgressOptions {
  showDot: boolean
  size: string
  blankColor: string
  fillColor: string
  overflowColor: string
  thick?: boolean
}

type RadialProgressProps = Optional<RadialProgressOptions> & {
  used: number
  total: number
  showTotals?: boolean
}

type RadialProgressStyleProps = Required<RadialProgressOptions> & {
  value: number
  size: string
  isOverflow: boolean
}

export const RadialProgress = ({
  used,
  total,
  size = "15rem",
  showTotals = true,
  showDot = true,
  blankColor = "#b5b5b6",
  fillColor = "#3cd5ad",
  overflowColor = "#25ac88",
  thick = false,
}: RadialProgressProps): JSX.Element => {
  const percentage = (used / total) * 100
  const isOverflow = used > total
  const value = percentage % 100 === 0 && used !== 0 ? 100 : percentage % 100

  const { classes } = useStyles({
    value: value,
    isOverflow,
    size,
    blankColor,
    fillColor,
    overflowColor,
    showDot,
    thick,
  })

  return (
    <Box className={classes.radialProgress}>
      {showTotals && (
        <Box className={classes.ratioTest} color="black" fontWeight={900}>
          {used}/{total}
        </Box>
      )}
      {showDot && (
        <Box className={classes.dotContainer}>
          <Box className={classes.dot} />
        </Box>
      )}
    </Box>
  )
}
// This animation works by animating based on properties in the css
// by changing the --pgPercentage property we are able to animate the progress bar
// and animate the spinning of the dot
const growProgressBar = ({
  blankColor,
  fillColor,
  value,
}: Pick<RadialProgressStyleProps, "blankColor" | "fillColor" | "value">) => keyframes`
  0% {
    --pgPercentage: 0;
    --baseColor: ${blankColor};
    --fillColor: ${fillColor};
  }
  100% {
    --pgPercentage: ${value};
    --baseColor: ${blankColor};
    --fillColor: ${fillColor};
  }
`

// TODO known issue where the overflow animation doesn't work
// it should animate from percent 0 to 100 then back to 0 and then to the actual value
// but it seems to just animate from 50.1% to the actual value
// i.e, if 20/10 it should do two full spins but it only does one currently
const growProgressBarOverflow = ({
  blankColor,
  fillColor,
  overflowColor,
  value,
}: Pick<RadialProgressStyleProps, "blankColor" | "fillColor" | "value" | "overflowColor">) => keyframes`
  0% {
    --pgPercentage: 0,
    --baseColor: ${blankColor};
    --fillColor: ${fillColor};
  }
  50% {
    --pgPercentage: 100,
    --baseColor: ${blankColor};
    --fillColor: ${fillColor};
  }
  50.1% {
    --pgPercentage: 0;
    --baseColor: ${fillColor};
    --fillColor: ${overflowColor};
  }
  100% {
    --pgPercentage: ${value};
    --baseColor: ${fillColor};
    --fillColor: ${overflowColor};
  }
`

const useStyles = makeStyles<RadialProgressStyleProps>({ name: { RadialProgress } })(
  (_theme, { value, size, isOverflow, blankColor, fillColor, overflowColor, thick }) => ({
    radialProgress: {
      animation: isOverflow
        ? `${growProgressBarOverflow({ blankColor, fillColor, value, overflowColor })} 2s 1 forwards`
        : `${growProgressBar({ blankColor, fillColor, value })} 1.5s 1 forwards`,
      animationTimingFunction: isOverflow ? "linear" : "ease-out",
      background: `
        radial-gradient(closest-side, white ${thick ? "60" : "80"}%, transparent 0 99.9%, white 0),
        conic-gradient(var(--fillColor) calc(var(--pgPercentage) * 1%), var(--baseColor) 0)`,
      border: `solid calc(${size} / 25) #EFEFEF`,
      borderRadius: "50%",
      display: "grid",
      fontSize: `calc(${size} / 5)`,
      height: size,
      position: "relative",
      placeItems: "center",
      width: size,
    },
    dotContainer: {
      animation: isOverflow
        ? `${growProgressBarOverflow({ blankColor, fillColor, value, overflowColor })} 2s 1 forwards`
        : `${growProgressBar({ blankColor, fillColor, value })} 1.5s 1 forwards`,
      animationTimingFunction: isOverflow ? "linear" : "ease-out",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: "50%",
      transform: "rotate(calc(var(--pgPercentage) / 100 * 360deg))",
    },
    dot: {
      position: "absolute",
      borderRadius: "50%",
      top: 0,
      left: "50%",
      transform: `translateY(calc(calc(${size} / 25) - 50%)) translateX(-50%)`,
      width: `calc(0.1 * ${size} + 10%)`,
      height: `calc(0.1 * ${size} + 10%)`,
      backgroundColor: fillColor,
      boxShadow: "0px 0px 2px",
    },
    ratioTest: {
      textAlign: "center",
      lineBreak: "anywhere",
      fontSize: `calc(${size} / 8)`,
    },
  })
)
