import { Editor, Node } from "slate"
import { BlockElement, CustomEditor, InlineElement, VoidElement } from "../CustomEditor"
import { isTextNode } from "./text"

export function isBlockNode(editor: CustomEditor, node: Node): node is BlockElement {
  return !Editor.isEditor(node) && !isTextNode(node) && Editor.isBlock(editor, node)
}

export function isInlineNode(editor: CustomEditor, node: Node): node is InlineElement {
  return !Editor.isEditor(node) && !isTextNode(node) && Editor.isInline(editor, node)
}

export function isVoidNode(editor: CustomEditor, node: Node): node is VoidElement {
  return !Editor.isEditor(node) && !isTextNode(node) && Editor.isVoid(editor, node)
}
