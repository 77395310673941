import { useState } from "react"

import get from "lodash/get"

import Box from "@mui/material/Box"
import Alert, { AlertColor } from "@mui/material/Alert"
import MUIAlertTitle from "@mui/material/AlertTitle"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import { lighten } from "@mui/system"
import { useTheme } from "@mui/material/styles"

import { DUPLICATE_UPLOAD_MATCH, EMPTY_FILE_PREVALIDATION } from "common/file-uploader/constants"

import { VALIDATE_FILE_ALERT_BODY, ALERT_CLOSE_BUTTON } from "./constants"

import { FileError } from "react-dropzone"

import styled from "@emotion/styled"
import { UseFileUploaderReturn } from "common/file-uploader"

const AlertBody = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  color: "black",
}))

const AlertTitle = styled(MUIAlertTitle)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  color: "black",
}))

// Original validation messages (most coming from backend)
const VERIFICATION_FAILED_ERROR = "File verification failed"
const UNSUPPORTED_FILE_TYPE_ERROR = "File type not supported"
export const CORRUPTED_FILE_ERROR = "File is corrupt"
export const PASSWORD_WARNING =
  "File is password-protected and won't be included in credit calculation. Please remove and re-upload."
const PASSWORD_ZIP_ERROR = "Failed to unzip. Error: Encrypted zip are not supported"

// User friendly validation messages
export const HTML_FILE_ERROR = "HTML files are not supported. Please convert to .pdf and try again."
export const FRIENDLY_CORRUPTED_FILE_ERROR = "This file is corrupted."
const FRIENDLY_UNSUPPORTED_FILE_TYPE_ERROR = "This file type is unsupported."
const UNKNOWN_ERROR = "Unknown error"
export const FRIENDLY_PASSWORD_WARNING =
  "This is a password protected file. Please remove the password and try again."
export const FRIENDLY_PASSWORD_ZIP_ERROR =
  "This is a password protected zip file. Please remove the password and try again."

export const VALIDATION_MESSAGE_MAP: {
  [key: string]: { userFriendlyMessage: string; closable: boolean; severity: AlertColor }
} = {
  [VERIFICATION_FAILED_ERROR]: {
    userFriendlyMessage: VERIFICATION_FAILED_ERROR,
    closable: true,
    severity: "error",
  },
  [UNSUPPORTED_FILE_TYPE_ERROR]: {
    userFriendlyMessage: FRIENDLY_UNSUPPORTED_FILE_TYPE_ERROR,
    closable: true,
    severity: "error",
  },
  [CORRUPTED_FILE_ERROR]: {
    userFriendlyMessage: FRIENDLY_CORRUPTED_FILE_ERROR,
    closable: true,
    severity: "error",
  },
  [DUPLICATE_UPLOAD_MATCH]: {
    userFriendlyMessage: DUPLICATE_UPLOAD_MATCH,
    closable: true,
    severity: "error",
  },
  [EMPTY_FILE_PREVALIDATION]: {
    userFriendlyMessage: EMPTY_FILE_PREVALIDATION,
    closable: true,
    severity: "error",
  },
  // HTML file error is a special case where validation is coming
  // from react-dropzone validator
  [HTML_FILE_ERROR]: {
    userFriendlyMessage: HTML_FILE_ERROR,
    closable: true,
    severity: "error",
  },
  [PASSWORD_ZIP_ERROR]: {
    userFriendlyMessage: FRIENDLY_PASSWORD_ZIP_ERROR,
    closable: true,
    severity: "error",
  },
  [PASSWORD_WARNING]: {
    userFriendlyMessage: FRIENDLY_PASSWORD_WARNING,
    closable: false,
    severity: "warning",
  },
  [UNKNOWN_ERROR]: {
    userFriendlyMessage: UNKNOWN_ERROR,
    closable: true,
    severity: "error",
  },
}

const getValidationDetails = (errors: FileError[]) => {
  const message = get(errors, ["0", "message"], UNKNOWN_ERROR) as keyof typeof VALIDATION_MESSAGE_MAP
  return VALIDATION_MESSAGE_MAP[message]
}

export const ERROR_MESSAGES = [
  HTML_FILE_ERROR,
  VERIFICATION_FAILED_ERROR,
  UNSUPPORTED_FILE_TYPE_ERROR,
  EMPTY_FILE_PREVALIDATION,
  DUPLICATE_UPLOAD_MATCH,
  CORRUPTED_FILE_ERROR,
  PASSWORD_WARNING,
]

/**
 * Custom validator for Request Form files, currently used in both drag&drop flow
 * and the zip file processing flow.
 */
export const requestFileValidator = (
  file: File,
  fileUploader: UseFileUploaderReturn
): FileError | Array<FileError> | null => {
  // Note, checking name for .html b/c unzipped files missing 'type'
  if (file.type === "text/html" || file.name?.endsWith(".html")) {
    // Note, arbitrary message/code since we don't surface this to users
    return { message: HTML_FILE_ERROR, code: "400" }
  }

  if (fileUploader.isDuplicateFile(file)) {
    return { message: DUPLICATE_UPLOAD_MATCH, code: "400" }
  }

  return null
}

export interface ValidateFileAlertProps {
  errors: FileError[]
  file: File
}

export const ValidateFileAlert = ({ errors, file }: ValidateFileAlertProps): JSX.Element => {
  const theme = useTheme()
  const [show, setShow] = useState(true)

  // Using state to show/hide due to validation messages coming from different sources
  // e.g. client side validation, server side validation, react-dropzone validation
  const onClose = () => {
    setShow(false)
  }

  const { userFriendlyMessage, severity, closable } = getValidationDetails(errors)
  const action = closable && (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={onClose}
      data-test={ALERT_CLOSE_BUTTON}
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  )

  const alertBgcolor = lighten(theme.palette[severity].main, 0.95)
  const alertStyle = { bgcolor: alertBgcolor, borderRadius: 2 }

  if (!show) return <></>

  return (
    <Box mt={2} key={file.name}>
      <Alert severity={severity} variant="outlined" action={action} sx={alertStyle}>
        <AlertTitle>{file.name}</AlertTitle>
        <AlertBody data-test={VALIDATE_FILE_ALERT_BODY}>{userFriendlyMessage}</AlertBody>
      </Alert>
    </Box>
  )
}
