import { ReactElement } from "react"
import { TextFieldProps } from "@mui/material/TextField"
import { Control, Controller, FieldErrors, FieldValues, Path, UseControllerProps } from "react-hook-form"
import { NumberInput } from "./NumberInput"
import { get } from "lodash"

export type CurrencyFieldProps<TFields extends FieldValues> = {
  control: Control<TFields>
  name: Path<TFields>
  rules?: UseControllerProps<TFields>["rules"]
  errors?: FieldErrors<TFields>
  hideCurrency?: boolean
  decimalScale?: number
} & Partial<TextFieldProps>

export function CurrencyField<T extends FieldValues>({
  control,
  name,
  rules,
  errors,
  hideCurrency = false,
  decimalScale,
  ...inputProps
}: CurrencyFieldProps<T>): ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error: fieldStateError } }) => {
        const error = fieldStateError ?? get(errors, name)
        const errorMessage = error?.message

        return (
          <NumberInput
            error={Boolean(error)}
            {...inputProps}
            {...field}
            helperText={(errorMessage as string) || inputProps.helperText}
            fullWidth
            currencySymbol={hideCurrency ? undefined : "$ "}
            decimalScale={decimalScale}
          />
        )
      }}
    />
  )
}
