import { useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import useUser from "hooks/useUser"
import { usePermissions } from "permissions/usePermissions"
import { StatementOfWorkGeneration } from "settings/Firm/FirmDetails/StatementOfWork"
import { BillingAnalyticEvent, BillingAnalyticsEventTypes } from "infrastructure/apm/events/BillingEvents"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { FirmSelect } from "common/attributes-filter/FirmSelect"
import { getSettingsPageOptions } from "settings/utils"
import { SETTINGS_ROUTE_PREFIX } from "settings/types"

export const Billing = () => {
  const { user } = useUser()
  const { settingsBillingTabEnabled } = usePermissions()
  const [firmId, setFirmId] = useState<number | null>(null)

  useEffect(() => {
    if (user && user.firmId) {
      setFirmId(user.firmId)
    }
  }, [user])

  useEffect(() => {
    if (user.id && user.isAuthorized) {
      amplitudeApm.trackEvent(
        new BillingAnalyticEvent(BillingAnalyticsEventTypes.BillingPageViewed, {
          user_id: user.id,
          user_name: user.userName,
        })
      )
    }
  }, [user.id, user.isAuthorized, user.userName])

  const { pageTitle } = getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.BILLING, user.role)

  return (
    <Box>
      <Typography mb={4} variant="h4" component="h1">
        {pageTitle}
      </Typography>
      {settingsBillingTabEnabled && (
        <StatementOfWorkGeneration firmId={firmId}>
          {user.isInternal && (
            <Box mb={2}>
              <FirmSelect firmId={firmId} setFirmId={setFirmId} />
            </Box>
          )}
        </StatementOfWorkGeneration>
      )}
    </Box>
  )
}
