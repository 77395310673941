import { useEffect, useRef } from "react"

export function useInterval(callback: () => void, delay: number, enabled = true, leading = false) {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    if (delay && enabled) {
      const id = setInterval(() => callbackRef.current(), delay)
      return () => clearInterval(id)
    }
  }, [enabled, delay])

  useEffect(() => {
    if (enabled && leading) {
      callbackRef.current()
    }
  }, [enabled, leading])
}

export const getErrorMessageFromJSONStr = (stringMessage: Nullable<string>): Nullable<string> => {
  if (stringMessage === null) return null

  try {
    const parsedObject = JSON.parse(stringMessage)
    if (typeof parsedObject === "object") {
      return Object.keys(parsedObject)
        .reduce((result, key) => {
          if (Array.isArray(parsedObject[key])) {
            return `${result} ${parsedObject[key].join(" ")}`
          }
          return result
        }, "")
        .trim()
    }
    return null
  } catch {
    return null
  }
}

export const compileFileErrorMessage = (
  fileName: string,
  reason: string | null | undefined,
  defaultReason = ""
): string => {
  const details = reason ? reason : defaultReason
  const info = `There was an error uploading this file: ${fileName}`
  const message = details ? `${info}.\n\nDetails: ${details}` : info

  return message
}

/**
 * This function takes an array of numbers representing page numbers
 * and groups consecutive page numbers into subarrays, returning the result.
 */
export const getRanges = (pages: number[]): number[][] => {
  return pages.reduce((ranges: number[][], pageNumber: number) => {
    const lastRange: number[] | undefined = ranges[ranges.length - 1]

    // If there's no last subarray or the current number is not consecutive to the last number in the last subarray, start a new subarray.
    if (!lastRange || lastRange[lastRange.length - 1] !== pageNumber - 1) {
      ranges.push([])
    }

    // Push the current number to the last subarray.
    ranges[ranges.length - 1].push(pageNumber)

    return ranges
  }, [])
}
