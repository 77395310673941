import { useMemo } from "react"
import Select, { SelectProps, SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import Box from "@mui/material/Box"
import StatusLabel from "common/status/StatusLabel"
import useUser from "hooks/useUser"
import { ALL_ROLES, OSF } from "common/models/roles"
import getStatusToProperties, {
  IntakeStatusOptions,
  getDocumentUploadedDisplayText,
} from "./requestStatusProperties"

import {
  INTAKE_STATUSES,
  INTAKE_STATUS_ORDER,
  INTAKE_NEW_STATUS_ORDER,
  MISSING_DOCUMENT_STATUSES,
} from "../constants"
import { getStatusOptions } from "./utils"
import { usePermissions } from "permissions/usePermissions"
interface StatusSelectorProps {
  onChange: (e: SelectChangeEvent<INTAKE_STATUSES>) => void
  currentStatus: INTAKE_STATUSES
  demandId: Nullable<PrimaryKey>
  revised: boolean
  hasMissingDocs: boolean
}

export const StatusSelector = ({
  onChange,
  currentStatus,
  demandId,
  revised,
  hasMissingDocs,
}: StatusSelectorProps): JSX.Element => {
  const {
    user: { role },
  } = useUser()
  const { newStatusesForRequestsEnabled } = usePermissions()
  const displayStatusToPropertiesByRole = useMemo(
    () => getStatusOptions(role, currentStatus, revised, demandId),
    [role, currentStatus, demandId, revised]
  )
  // If feature flag is enabled filter using new statuses order otherwise use the old statuses order
  const intakeStatusOrder = newStatusesForRequestsEnabled ? INTAKE_NEW_STATUS_ORDER : INTAKE_STATUS_ORDER
  const displayFilteredStatusToPropertiesByRole = useMemo(
    () =>
      Object.keys(displayStatusToPropertiesByRole)
        .filter(status => intakeStatusOrder.includes(status as INTAKE_STATUSES))
        .reduce((obj, key) => {
          obj[key as keyof IntakeStatusOptions] =
            displayStatusToPropertiesByRole[key as keyof IntakeStatusOptions]
          return obj
        }, {} as Partial<IntakeStatusOptions>),
    [displayStatusToPropertiesByRole, intakeStatusOrder]
  )
  // Change the text of the documentsUploaded status based on the newStatusesEnabled flag.
  if (displayFilteredStatusToPropertiesByRole[INTAKE_STATUSES.documentsUploaded] !== undefined) {
    displayFilteredStatusToPropertiesByRole[INTAKE_STATUSES.documentsUploaded].text =
      getDocumentUploadedDisplayText(newStatusesForRequestsEnabled)
  }
  const statuses = useMemo(() => {
    return statusOptionsWithProperties(displayFilteredStatusToPropertiesByRole, role).sort(
      (a, b) =>
        intakeStatusOrder.indexOf(a.id as INTAKE_STATUSES) -
        intakeStatusOrder.indexOf(b.id as INTAKE_STATUSES)
    )
  }, [role, displayFilteredStatusToPropertiesByRole, intakeStatusOrder])

  if (role === OSF && currentStatus === INTAKE_STATUSES.readyForTasker) {
    return <></>
  }

  return (
    <Box data-test="status-selector">
      <InputLabel id="request-status-selector">Status</InputLabel>
      <Select
        labelId="request-status-selector"
        variant="standard"
        value={currentStatus}
        onChange={onChange as SelectProps["onChange"]}
        data-test="request-status-selector"
      >
        {statuses.map(status => {
          return (
            <MenuItem key={status.id} value={status.id} disabled={isDisabled(status, hasMissingDocs)}>
              <StatusLabel
                status={status.id}
                color={status.color}
                text={status.text}
                filled={status.filled}
                disabled={status.disabled}
              />
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}

type StatusWithProperties = {
  id: string
  intake_status: INTAKE_STATUSES
  text: string
  color: string
  filled: boolean
  disabled?: boolean | undefined
}

const statusOptionsWithProperties = (
  displayStatusToPropertiesByRole: Partial<IntakeStatusOptions>,
  role: ALL_ROLES
): StatusWithProperties[] =>
  Object.entries(displayStatusToPropertiesByRole).map(([status, properties]) => ({
    ...properties,
    ...getStatusToProperties(status as INTAKE_STATUSES, role),
    id: status,
  }))

const isDisabled = (status: StatusWithProperties, hasMissingDocs: boolean) => {
  // disable the missing docs status if there are no missing docs and there is no status defined
  // this should prevent users from switching to missing docs status when there are no missing docs
  // yet still allow them to switch back to missing docs in case they accidentally switched to another status
  if (
    MISSING_DOCUMENT_STATUSES.includes(status.intake_status) &&
    !hasMissingDocs &&
    status.disabled === undefined
  ) {
    return true
  }

  return status.disabled === true
}
