import { State } from "common/state/types"
import { FILE_UPLOAD_STATE, FILE_UPLOAD_STATE_TYPE } from "./states"
import { InitialUploadData } from "./types"

export const INITIAL_UPLOAD_STATE: State<FILE_UPLOAD_STATE_TYPE, InitialUploadData> = {
  current: FILE_UPLOAD_STATE.PREPARING_FOR_UPLOAD,
  data: {
    chunkCount: null,
    uploadedChunks: null,
    progress: 0,
  },
}
