import React, { memo, useMemo, useReducer } from "react"
import { HandleMessagesContext } from "./HandleMessagesContext"
import { handleMessagesReducer } from "./handleMessagesReducer"

export const HandleMessagesProvider = memo(function HandleMessagesProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [state, dispatch] = useReducer(handleMessagesReducer, null)
  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch])
  return <HandleMessagesContext.Provider value={providerValue}>{children}</HandleMessagesContext.Provider>
})
