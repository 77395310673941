import { INTAKE_STATUS_ORDER, INTAKE_NEW_STATUS_ORDER, INTAKE_NEW_STATUSES_TEXT } from "requests/constants"
import { INTAKE_STATUSES, INTAKE_STATUSES_TEXT } from "../constants"
import { ALL_ROLES, EXTERNAL_ROLES_VALUES, OSF } from "../../common/models/roles"
import { canUserSeeInternalStatus } from "../permissions/requestAction"
import { OptionProps } from "evenup-ui/MultiSelectAutoComplete"

export interface IntakeStatusOption {
  text: string
  color: string
  filled: boolean
  disabled?: boolean
}

export type IntakeStatusOptions = Record<INTAKE_STATUSES, IntakeStatusOption>

export const statusToPropertiesAll: IntakeStatusOptions = {
  [INTAKE_STATUSES.readyForTasker]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.readyForTasker],
    color: "#aa497e",
    filled: false,
  },
  [INTAKE_STATUSES.taskerAssigned]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.taskerAssigned],
    color: "#aa9349",
    filled: false,
  },
  [INTAKE_STATUSES.taskerCompleted]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.taskerCompleted],
    color: "#75aa49",
    filled: false,
  },
  [INTAKE_STATUSES.notRequested]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.notRequested],
    color: "#7D7D7D",
    filled: true,
  },
  [INTAKE_STATUSES.requested]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.requested],
    color: "#FEA568",
    filled: false,
  },
  [INTAKE_STATUSES.inProgress]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.inProgress],
    color: "#216AFC",
    filled: true,
  },
  [INTAKE_STATUSES.cancelled]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.cancelled],
    color: "#9844A9",
    filled: true,
  },
  [INTAKE_STATUSES.completed]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.completed],
    color: "#4CAF50",
    filled: true,
  },
  [INTAKE_STATUSES.rework]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.rework],
    color: "#E44E48",
    filled: true,
  },
  [INTAKE_STATUSES.redelivered]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.redelivered],
    color: "#49AA6C",
    filled: false,
  },
  [INTAKE_STATUSES.missingDocuments]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.missingDocuments],
    color: "#D50101",
    filled: true,
  },
  [INTAKE_STATUSES.documentsUploaded]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.documentsUploaded],
    color: "#002EB9",
    filled: true,
  },
  [INTAKE_STATUSES.preProcessing]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.preProcessing],
    color: "#48C7FF",
    filled: true,
  },
  [INTAKE_STATUSES.onHold]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.onHold],
    color: "#000000",
    filled: true,
  },
  [INTAKE_STATUSES.readyToResume]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.readyToResume],
    color: "#FFC400",
    filled: true,
  },
  [INTAKE_STATUSES.readyForReview]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.readyForReview],
    color: "#FF6F00",
    filled: true,
  },
  [INTAKE_STATUSES.inReview]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.inReview],
    color: "#80C3E8",
    filled: true,
  },
  [INTAKE_STATUSES.readyToSubmit]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.readyToSubmit],
    color: "#651FFF",
    filled: true,
  },
  [INTAKE_STATUSES.approvalRequired]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.approvalRequired],
    color: "#E040FB",
    filled: true,
  },
  [INTAKE_STATUSES.missingDocsPartialResponse]: {
    text: INTAKE_STATUSES_TEXT[INTAKE_STATUSES.missingDocsPartialResponse],
    color: "#F48FB1",
    filled: true,
  },
}

function getOptionsForStatuses(statuses: INTAKE_STATUSES[]): Partial<IntakeStatusOptions> {
  return statuses.reduce<Partial<IntakeStatusOptions>>((result, status) => {
    result[status] = statusToPropertiesAll[status]
    return result
  }, {})
}

export const taskerStatuses = [INTAKE_STATUSES.taskerAssigned, INTAKE_STATUSES.taskerCompleted]

export const deprecatedStatuses = [INTAKE_STATUSES.rework, INTAKE_STATUSES.redelivered]

export const disabledByRevisionStatuses = [
  INTAKE_STATUSES.missingDocuments,
  INTAKE_STATUSES.missingDocsPartialResponse,
  INTAKE_STATUSES.documentsUploaded,
]

export const internalIntakeStatusesInReview: INTAKE_STATUSES[] = [
  INTAKE_STATUSES.inReview,
  INTAKE_STATUSES.readyToSubmit,
  INTAKE_STATUSES.approvalRequired,
]

export const internalIntakeStatuses: INTAKE_STATUSES[] = [
  INTAKE_STATUSES.preProcessing,
  INTAKE_STATUSES.readyForTasker,
  INTAKE_STATUSES.taskerAssigned,
  INTAKE_STATUSES.taskerCompleted,
  INTAKE_STATUSES.readyForReview,
  ...internalIntakeStatusesInReview,
]

export const externalIntakeStatuses = Object.values(INTAKE_STATUSES).filter(
  status => !internalIntakeStatuses.includes(status)
)

export const statusToPropertiesInternalOSFOnly = getOptionsForStatuses(internalIntakeStatuses)

export const statusToPropertiesExternal = getOptionsForStatuses(externalIntakeStatuses)

export const statusInternalOSFOnly = internalIntakeStatuses

export interface IntakeStatusOptionValue extends IntakeStatusOption {
  intake_status: INTAKE_STATUSES
}

const getStatusToProperties = (status: INTAKE_STATUSES, role: ALL_ROLES): IntakeStatusOptionValue => {
  if (EXTERNAL_ROLES_VALUES.includes(role) && statusInternalOSFOnly.includes(status)) {
    status = internalIntakeStatusesInReview.includes(status)
      ? INTAKE_STATUSES.inReview
      : INTAKE_STATUSES.inProgress
  }

  return { ...statusToPropertiesAll[status], intake_status: status }
}

// this function is used to change the text of the documentsUploaded status based on the newStatusesEnabled flag.
// will be deprecated once the new statuses are fully enabled
export const getDocumentUploadedDisplayText = (newStatusesEnabled: boolean): string => {
  return newStatusesEnabled
    ? INTAKE_NEW_STATUSES_TEXT[INTAKE_STATUSES.documentsUploaded]
    : INTAKE_STATUSES_TEXT[INTAKE_STATUSES.documentsUploaded]
}

export function getStatusOptionsByRole(
  userRole: ALL_ROLES,
  newStatusesEnabled: boolean
): (OptionProps & IntakeStatusOptionValue)[] {
  let result = statusToPropertiesExternal
  if (userRole === OSF) {
    result = statusToPropertiesInternalOSFOnly
  }

  if (canUserSeeInternalStatus(userRole)) {
    result = statusToPropertiesAll
  }
  // Change the text of the documentsUploaded status based on the newStatusesEnabled flag.
  if (result[INTAKE_STATUSES.documentsUploaded] !== undefined) {
    result[INTAKE_STATUSES.documentsUploaded].text = getDocumentUploadedDisplayText(newStatusesEnabled)
  }
  const intakeStatusOrder = newStatusesEnabled ? INTAKE_NEW_STATUS_ORDER : INTAKE_STATUS_ORDER
  const result_keys = Object.keys(result).filter(key => intakeStatusOrder.includes(key as INTAKE_STATUSES))
  return (result_keys as INTAKE_STATUSES[])
    .sort((a, b) => intakeStatusOrder.indexOf(a) - intakeStatusOrder.indexOf(b))
    .map(key => {
      const status = getStatusToProperties(key, userRole)
      return { ...status, value: key, label: status.text }
    })
}

export { getStatusToProperties as default }
