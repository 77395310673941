import Tooltip from "@mui/material/Tooltip"
import Switch from "@mui/material/Switch"
import Typography from "@mui/material/Typography"
import { usePinState } from "hooks/usePinState"

export function PinControl(): JSX.Element {
  const [pinned, setPinned] = usePinState()

  return (
    <Tooltip
      arrow
      title={
        <Typography align="center" variant="body2">
          Pin this document to keep it open.
          <br />
          Other documents will open in a new tab.
        </Typography>
      }
    >
      <Switch name="pin-pdf-page" size="small" checked={pinned} onChange={setPinned} color="primary" />
    </Tooltip>
  )
}
