// 1 -> 1st, 10 -> 10th
export const ordinalSuffixOf = (value: number): string => {
  const i = value % 10
  const j = value % 100

  if (i == 1 && j != 11) {
    return value + "st"
  }
  if (i == 2 && j != 12) {
    return value + "nd"
  }
  if (i == 3 && j != 13) {
    return value + "rd"
  }
  return value + "th"
}

export const amountInDollars = (amount: number | string, numDigits?: number): string => {
  const numAmount = typeof amount === "number" ? amount : parseFloat(amount)
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: numDigits,
    maximumFractionDigits: numDigits,
  }).format(numAmount)
}

export const pluralize = ({
  count,
  noun,
  suffix = "s",
}: {
  count: number
  noun: string
  suffix?: string
  threshold?: number
}) => `${noun}${count === 1 ? "" : suffix}`

export function truncateMiddle(str: string, maxLength: number) {
  if (str.length <= maxLength) {
    return str
  }

  const leftHalf = str.slice(0, maxLength / 2)
  const rightHalf = str.slice(-maxLength / 2)

  return `${leftHalf}...${rightHalf}`
}
