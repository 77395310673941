import styled from "@emotion/styled"
import Toolbar from "@mui/material/Toolbar"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import { omit } from "lodash"
import { inputLabelClasses, outlinedInputClasses, svgIconClasses } from "@mui/material"

interface StyledButtonProps {
  active?: boolean
}

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  padding: theme.spacing(1),
  minHeight: 0,
  display: "flex",
}))

export const StyledToolbarActive = styled(Box)(() => ({
  opacity: 1,
}))

export const StyledToolbarGroup = styled(Box)(({ theme }) => ({
  "&:not(:last-child)": {
    paddingRight: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

export const StyledTextButton = styled("span")(({ theme }) => ({
  display: "flex",
  width: theme.spacing(3),
  height: theme.spacing(3),
  justifyContent: "center",
  alignItems: "center",
}))

export const StyledButton = styled((props: StyledButtonProps & IconButtonProps) => (
  <IconButton
    size="small"
    color={props.active ? "primary" : "default"}
    {...omit<StyledButtonProps, keyof StyledButtonProps>(props, "active")}
  />
))<StyledButtonProps>(({ theme, active }) => ({
  borderRadius: theme.shape.borderRadius,
  margin: "0 1px",
  background: active ? theme.palette.grey[200] : undefined,
  "&:hover": {
    background: active ? theme.palette.grey[300] : undefined,
  },

  [`& .${svgIconClasses.root}`]: {
    height: 20,
    width: 20,
  },

  [`& ${StyledTextButton}`]: {
    height: 20,
    width: 20,
    fontSize: 16,
  },
}))

export const AutocompleteWrapper = styled(Box)({
  [`.${outlinedInputClasses.notchedOutline}`]: {
    top: 0,
  },
  "& legend": {
    display: "none",
  },
  [`& .${inputLabelClasses.root}`]: {
    transition: "none",
  },
  [`& .${inputLabelClasses.shrink}`]: {
    opacity: 0,
    transition: "none",
    animation: "none",
    transform: "none",
  },
})
