import styled from "@emotion/styled"
import { Skeleton } from "@mui/material"
import { Chip } from "@mui/material"

export const StyledFooterWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(4),
}))

export const StyledDetailsSkeleton = styled(Skeleton)(({ theme }) => ({
  height: theme.spacing(7),
  margin: theme.spacing(2, 0),
}))
StyledDetailsSkeleton.defaultProps = {
  variant: "rectangular",
  animation: "wave",
}

export const StyledNewItemDetailsSkeleton = styled(StyledDetailsSkeleton)({
  opacity: 0.3,
})
StyledNewItemDetailsSkeleton.defaultProps = {
  variant: "rectangular",
  animation: false,
}

export const StyledChip = styled(Chip)(({ theme }) => ({
  left: "0px",
  position: "absolute",
  color: theme.palette.grey[600],
}))
