import { FileMetadata } from "common/form-components/files/types"
import { v4 } from "uuid"
import { FileUploaderAction, fileUpdateActionCreator } from "../uploader-state/actions"
import { cancelableFlow } from "./cancelable"
import { getChunksUploadFlow } from "./chunks"
import { GetFlow, Flow, FileToUpload } from "./types"

interface FileUploadFlowArg {
  data: FileToUpload
  metadata: FileMetadata
}

type FileUploadFlow = Flow<FileUploadFlowArg, string>

export const createUploadFlow: GetFlow<FileUploaderAction, FileUploadFlow> = dispatch => {
  let controller = new AbortController()

  const runFlow: FileUploadFlow = async ({ data, metadata }) => {
    const id = v4()

    dispatch({ type: "UPLOAD_NEW_FILE", payload: { id, metadata } })

    const createAction = fileUpdateActionCreator(id)
    const uploadFlow = getChunksUploadFlow(action => dispatch(createAction(action)))
    const cancelableChunksUploadFlow = cancelableFlow<FileUploadFlowArg, void>(
      {
        signal: controller.signal,
        onAborted: () => dispatch(createAction({ type: "CANCEL", payload: {} })),
        onError: reason => dispatch(createAction({ type: "FAIL", payload: { reason } })),
      },
      uploadFlow
    )

    await cancelableChunksUploadFlow({ data, metadata }, { signal: controller.signal })

    return id
  }

  const cancelFlow = () => {
    controller.abort()
    controller = new AbortController()
  }

  return {
    run: runFlow,
    cancel: cancelFlow,
  }
}
