import React, { useCallback, useContext, useEffect, useState } from "react"
import Snackbar from "@mui/material/Snackbar"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import { Message } from "./Message"
import { HandleMessagesContext } from "./HandleMessagesContext"
import { AlertTitle } from "@mui/material"
import { useIssueReporter } from "common/issueReporter"
import { lighten } from "@mui/system"
import { useTheme } from "@mui/material/styles"
import { MESSAGE_ACTION_TYPE } from "./handleMessagesReducer"

interface MessagesSnackbarItemProps {
  message: Message
  show: boolean
}

function MessagesSnackbarItem({ message, show }: MessagesSnackbarItemProps): Nullable<JSX.Element> {
  const [isShowed, setIsShowed] = useState<boolean>(show)
  const onClose = useCallback(() => setIsShowed(false), [])
  const issueReporter = useIssueReporter()

  useEffect(() => {
    if (issueReporter.isAvailable) {
      issueReporter.updateMetadata({
        summary: typeof message.message === "string" ? message.message : undefined,
        error: message.error,
      })
    }
  }, [issueReporter, message.error, message.message])

  useEffect(() => {
    setIsShowed(true)
  }, [message])

  const theme = useTheme()

  if (!show || !isShowed) return null

  const showReportIssue = issueReporter.isAvailable && message.type === "error"
  const alertBgcolor = lighten(theme.palette[message.type].main, 0.95)
  const alertTextColor = theme.palette.text.primary
  const alertStyle = { bgcolor: alertBgcolor, color: alertTextColor, borderRadius: 2 }

  return (
    <Alert
      variant="outlined"
      sx={alertStyle}
      severity={message.type}
      action={
        <Box display="flex" flexDirection="row">
          {showReportIssue && (
            <Button color="inherit" size="small" onClick={issueReporter.triggerUI}>
              Report issue
            </Button>
          )}
          &nbsp;
          {/* close button is not shown when an action is available so we add it manually */}
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
      }
    >
      {message.title && <AlertTitle>{message.title}</AlertTitle>}
      {message.message}
    </Alert>
  )
}

export const MessagesSnackbar = ({ children }: { children?: React.ReactNode }) => {
  const context = useContext(HandleMessagesContext)
  const state = context?.state
  const [isShowed, setIsShowed] = useState<boolean>(true)
  const onClose = useCallback(() => {
    setIsShowed(false)
    context?.dispatch({ type: MESSAGE_ACTION_TYPE.HIDE })
  }, [context])

  useEffect(() => {
    setIsShowed((state?.messages?.length ?? 0) > 0)
  }, [state?.messages])

  return (
    <>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: state?.anchorOrigin ? state.anchorOrigin.vertical : "top",
          horizontal: state?.anchorOrigin ? state?.anchorOrigin.horizontal : "left",
        }}
        open={state !== null && isShowed}
        autoHideDuration={state?.timeout}
        onClose={onClose}
        data-test="message-snackbar"
      >
        <Box>
          {!!state &&
            state.messages.map(message => (
              <MessagesSnackbarItem
                message={message}
                show={isShowed}
                key={`${message.type}_${message.message}`}
              />
            ))}
        </Box>
      </Snackbar>
    </>
  )
}
