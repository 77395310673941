import styled from "@emotion/styled"
import { Box, outlinedInputClasses } from "@mui/material"
import { DateField } from "common/form-components"

export const ButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  /* match the transform in date field to align */
  transform: translateX(-10px);
`

export const PopupContent = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2.25)};
`

export const StyledDateField = styled(DateField)<{ isHighlighted: boolean }>`
  width: 120px;
  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    `
    & .${outlinedInputClasses.notchedOutline} {
      border-width: 2px;
      border-color: ${theme.palette.primary.main};
    }
  `}
`

export const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Dash = styled.span`
  display: inline-block;
  width: ${({ theme }) => theme.spacing(1)};
  height: 1px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.grey[400]};
`
