import { Tooltip } from "@mui/material"
import { Control, Controller } from "react-hook-form"
import { DateField, SelectInput } from "common/form-components"
import { CASE_TYPES } from "requests/constants"
import { CaseFacts } from "api/services/case/types"

type CaseDetailFieldsType = Pick<CaseFacts, "date_of_incident" | "type">

interface CaseDetailFieldsProps<T extends CaseDetailFieldsType> {
  control: Control<T>
  isTemplated?: boolean
}

function CaseTypeField<T extends CaseDetailFieldsType>({
  control,
  isTemplated,
}: CaseDetailFieldsProps<T>): JSX.Element {
  const field = (
    <SelectInput
      control={control as Control<CaseDetailFieldsType>}
      name="type"
      options={Object.entries(CASE_TYPES).map<ValueOption<CaseDetailFieldsType["type"]>>(
        ([value, caseType]) => ({ ...caseType, key: value })
      )}
      label="Case Type"
      disabled={isTemplated}
    />
  )

  return isTemplated ? (
    <Tooltip title="Case type is set in the 'Select Template' section" placement="top">
      {field}
    </Tooltip>
  ) : (
    field
  )
}

export function CaseDetailFields<T extends CaseDetailFieldsType>({
  control,
  isTemplated,
}: CaseDetailFieldsProps<T>): JSX.Element {
  return (
    <>
      <Controller<CaseDetailFieldsType>
        control={control as Control<CaseDetailFieldsType>}
        name="date_of_incident"
        render={({ field }) => (
          <DateField
            initialValue={field.value}
            onChange={field.onChange}
            label="Date of Incident"
            fieldProps={{ name: "date_of_incident" }}
          />
        )}
      />

      <CaseTypeField control={control} isTemplated={isTemplated} />
    </>
  )
}
