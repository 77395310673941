import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import UploadedExhibit from "demand/UploadedExhibit"

const useStyles = makeStyles()(theme => ({
  files: {
    marginTop: theme.spacing(2),
  },
}))

/**
 * A gallery for images. `images` should be a list of objects that have a name
 * property. `downloadImage(image) should give the image data.
 */
export default function ExhibitList({ exhibits, onDelete, fileTypeMap, caseId, onSuccessfulSave }) {
  const { classes } = useStyles()

  return (
    <>
      {!!exhibits?.length && (
        <Box className={classes.files}>
          <Typography variant="h6" component="h3">
            List of files
          </Typography>
          <Box mt={2}>
            {exhibits.map(exhibit => (
              <UploadedExhibit
                key={exhibit.id ?? exhibit.pk}
                caseId={caseId}
                exhibit={exhibit}
                onDeleteExhibit={() => onDelete(exhibit)}
                fileTypeMap={fileTypeMap}
                onSuccessfulSave={onSuccessfulSave}
              />
            ))}
          </Box>
        </Box>
      )}
    </>
  )
}
