import { Dispatch, createContext } from "react"
import { DocumentStructureTreeAction } from "./actions"
import { noop } from "lodash"
import { DocumentStructureStoreData } from "./types"

type DocumentStructureStoreContextType = [DocumentStructureStoreData, Dispatch<DocumentStructureTreeAction>]

export const DocumentStructureStoreContext = createContext<DocumentStructureStoreContextType>([
  {
    items: {},
    sections: {},
    blocks: {},
    structure: {},
  },
  noop,
])
