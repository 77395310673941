import styled from "@emotion/styled"
import { InputField } from "common/form-components"
import { DocumentStructureSection } from "../types"
import { SortableNestedListItem } from "common/nested-list/SortableNestedListItem"
import { LoadingButton } from "@mui/lab"

interface StyledSectionTitleProps {
  empty: boolean
  touched: boolean
}

export const StyledSectionTitle = styled(InputField<DocumentStructureSection>)<StyledSectionTitleProps>(
  ({ theme, empty, touched }) => ({
    "& input": {
      padding: theme.spacing(0.75, 2),
      minWidth: "300px",
    },

    "& .MuiInputBase-root": {
      fontSize: "14px",
      fontWeight: "bold",

      ...((empty || touched) && {
        background: theme.palette.common.white,
      }),

      ...(empty && {
        "& input": {
          fontWeight: "normal",
          fontStyle: "italic",
        },
      }),
    },

    ...(!empty &&
      !touched && {
        "& input:not(:hover) + fieldset": {
          borderColor: "transparent",
        },
      }),
  })
)

export const StyledReadonlySectionTitle = styled("span")(({ theme }) => ({
  lineHeight: theme.spacing(5),
  margin: theme.spacing(0, 2),
}))

export const StyledSectionContent = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}))

export const StyledSectionContentEnd = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
}))

export const StyledButton = styled(LoadingButton)(({ theme }) => ({
  fontWeight: "bold",
  marginLeft: theme.spacing(1),
}))
StyledButton.defaultProps = {
  variant: "text",
  size: "small",
  loading: false,
}

export const StyledSectionListItem = styled(SortableNestedListItem<DocumentStructureSection>)<{
  open: boolean
}>(({ theme, item, open }) => ({
  "& > div > [data-type='list-item']": {
    background: theme.palette.grey.A100,

    border: 0,
    borderRadius: 0,
    boxShadow: `0px 0px 0px 1px ${open ? theme.palette.secondary.main : theme.palette.grey["300"]}`,

    paddingBottom: item.children.length || open ? 0 : theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,

    "& > [data-type='list-item-content']": {
      padding: theme.spacing(0, 2, item.children.length || open ? 1 : 0),
      borderBottom: open ? `1px ${theme.palette.secondary.main} solid` : "none",
    },

    "& > [data-type='list-item-children']": {
      background: theme.palette.common.white,

      "&:before, &:after": {
        content: `""`,
        display: "block",
        height: 0,
        overflow: "hidden",
      },

      "& [data-type='list-item']": {
        border: 0,
        paddingRight: 0,
      },
    },
  },

  "& [data-type='empty-placeholder']": {
    minHeight: theme.spacing(6),
    marginTop: theme.spacing(3),
  },
}))

export const StyledSectionChildren = styled("div")<{ empty: boolean }>(({ theme, empty }) => ({
  padding: theme.spacing(0, 2, empty ? 0 : 1),
  background: theme.palette.common.white,

  minHeight: theme.spacing(18),

  "&:before, &:after": {
    content: `""`,
    display: "block",
    height: 0,
    overflow: "hidden",
  },

  "& [data-type='list-item']": {
    border: 0,
  },
}))

export const StyledSectionFooter = styled("div")(({ theme }) => ({
  background: theme.palette.grey.A100,
  padding: theme.spacing(0.5, 2),
}))

export const StyledNewItemNote = styled("div")(({ theme }) => ({
  fontSize: "12px",
  fontStyle: "italic",
  marginTop: theme.spacing(-1.5),
}))

export const StyledItemContentNote = styled("div")(({ theme }) => ({
  fontStyle: "italic",
  marginRight: theme.spacing(3),
}))
