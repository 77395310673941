import { useQuery, useQueryClient } from "@tanstack/react-query"
import { queryKeys as defaultQueryKeys, DEFAULT_QUERY_PARAMS } from "../react-query/constants"
import { Case } from "case/types"
import { getCase } from "api"
import { useEffect } from "react"
import { reviewActions } from "review/store"

interface UseCaseReturn {
  // case is a reserved word so we use caseObj
  caseObj: Nullable<Case>
  updateCache: (firm: Partial<Case>) => unknown
  isLoading: boolean
}

export default function useCase(
  caseId: number | string,
  queryKey: string = defaultQueryKeys.case
): UseCaseReturn {
  const queryClient = useQueryClient()
  const hasValidCaseId = !!caseId

  const { data: caseObj, isFetching } = useQuery(
    [queryKey, String(caseId)],
    async (): Promise<Nullable<Case>> => {
      return await getCase({ queryKey: [null, caseId] })
    },
    {
      enabled: hasValidCaseId,
      ...DEFAULT_QUERY_PARAMS,
    }
  )

  useEffect(() => {
    if (caseObj?.matter_id) {
      reviewActions.reset(caseObj.matter_id)
    }
  }, [caseObj?.matter_id])

  const updateCache = (newCase: Partial<Case>) => {
    queryClient.setQueryData([queryKey, caseId], (oldCase: Partial<Case> | undefined) => {
      if (oldCase === undefined) return newCase

      return {
        ...oldCase,
        ...newCase,
      }
    })
  }

  return {
    caseObj: caseObj ?? null,
    isLoading: isFetching,
    updateCache,
  }
}
