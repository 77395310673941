import { ChangeEventHandler, useCallback, useEffect, useRef, useState } from "react"
import { Autocomplete, Box, Button, TextField } from "@mui/material"
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags"
import { DraggablePopover } from "evenup-ui/DraggablePopover"
import { StyledTextarea } from "./styled"
import { RelationForm } from "../medical-summary/components/RelationForm"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import TextButton from "common/buttons/TextButton"
import { Flag } from "./types"
import { getRelationIds } from "../medical-summary/components/IcdCodeForm"
import { v4 } from "uuid"
import { theme } from "app/theme"
import { FLAG_CATEGORY } from "api/services/matter/types"
import { LoadingButton } from "@mui/lab"
import { isEmpty, isNil } from "lodash"
import { IcdCodeRelation } from "documents/types"
import { documentActions } from "documents/store"

const options = Object.values(FLAG_CATEGORY).map(value => value)

interface FlagPopoverProps {
  currentFlagData: Partial<Flag>
  flagSelector: Nullable<string>
  onFlagDataChanged: (updatedData: Partial<Flag>) => void
  onClose: () => void
  onSave: () => void
  onDeleteFlag: () => void
  saving: boolean
}

function getInitialRelations(flagData: Partial<Flag>) {
  if (!flagData?.relations || isEmpty(flagData?.relations)) return [null]

  return flagData.relations
}

export const FlagPopover = ({
  currentFlagData,
  flagSelector,
  onFlagDataChanged,
  onClose,
  onDeleteFlag,
  onSave,
  saving,
}: FlagPopoverProps) => {
  const isNewFlag = !currentFlagData.id
  const [flagElement, setFlagElement] = useState<Element | undefined | null>(undefined)
  const [relations, setRelations] = useState<Nullable<IcdCodeRelation>[]>(
    getInitialRelations(currentFlagData)
  )
  const relationUuids = useRef(getRelationIds(currentFlagData.id, currentFlagData.relations?.length))
  const canAddRelations = !relations.some(relation => !relation)
  const disabled = !currentFlagData.category || relations.some(relation => relation && isNil(relation?.page))

  const handleClosePopup = useCallback(() => {
    onClose()
  }, [onClose])

  const handleChangeCategory = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, value: Nullable<FLAG_CATEGORY>) => {
      onFlagDataChanged({ category: value || undefined })
    },
    [onFlagDataChanged]
  )

  const handleChangeReason = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    e => {
      onFlagDataChanged({ reason: e.target.value })
    },
    [onFlagDataChanged]
  )

  const handleSave = useCallback(() => {
    onSave()
  }, [onSave])

  const handleDeleteRelation = (idx: number) => () => {
    setRelations(relations => {
      const nextRelations = [...relations]
      nextRelations.splice(idx, 1)
      relationUuids.current.uuids.splice(idx, 1)
      return nextRelations
    })
  }

  const handleRelationChange = (idx: number) => (relation: Nullable<IcdCodeRelation>) => {
    setRelations(relations => {
      const nextRelations = [...relations]
      nextRelations.splice(idx, 1, relation)
      return nextRelations
    })
  }

  const handleAddRelation = useCallback(() => {
    setRelations(relations => [...relations, null])
    relationUuids.current.uuids.push(v4())
  }, [])

  useEffect(() => {
    if (!relations) {
      onFlagDataChanged({ relations: undefined })

      return
    }

    const filteredRelations: IcdCodeRelation[] = []

    relations.forEach(relation => {
      if (relation && !isNil(relation.page)) filteredRelations.push(relation)
    })

    if (filteredRelations.length) {
      onFlagDataChanged({ relations: filteredRelations })
    } else {
      onFlagDataChanged({ relations: undefined })
    }
  }, [relations, onFlagDataChanged])

  const handleMouseEnter = useCallback(() => {
    if (currentFlagData.id) documentActions.setHoveredFlagId(currentFlagData.id)
  }, [currentFlagData.id])

  useEffect(() => {
    if (!flagSelector || !currentFlagData) {
      setFlagElement(undefined)
      setRelations([null])

      return
    }

    setFlagElement(document.getElementById(flagSelector))
  }, [flagSelector, currentFlagData])

  useEffect(() => {
    return () => documentActions.setHoveredFlagId(null)
  }, [])

  return (
    <DraggablePopover
      open={!!flagElement}
      anchorEl={flagElement}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClosePopup}
      onMouseEnter={handleMouseEnter}
      title={isNewFlag ? "Create Flag" : "Edit Flag"}
      titleIcon={<EmojiFlagsIcon sx={{ fontSize: "30px", color: "#E53935" }} />}
    >
      <Box mb={1.5}>
        <Box mb={1} fontWeight={600}>
          Flag category
        </Box>
        <Autocomplete<FLAG_CATEGORY>
          size="small"
          options={options}
          sx={{ width: 460 }}
          onChange={handleChangeCategory}
          value={currentFlagData.category || null}
          renderInput={params => <TextField {...params} required label="Select" />}
        />
      </Box>
      <Box mb={1.5}>
        <Box mb={1} fontWeight={600}>
          Reason for flagging this section
        </Box>
        <StyledTextarea value={currentFlagData.reason ?? ""} onChange={handleChangeReason} />
      </Box>
      <Box>
        {relations.map((relation, idx) => (
          <RelationForm
            key={relationUuids.current.uuids[idx]}
            relation={relation}
            relations={relations}
            onDelete={relations.length > 1 ? handleDeleteRelation(idx) : undefined}
            onChange={handleRelationChange(idx)}
          />
        ))}
      </Box>
      <Box>
        <TextButton disabled={!canAddRelations} onClick={handleAddRelation}>
          + Add Another Reference Exhibit
        </TextButton>
      </Box>
      <Box mt={3} display="flex" justifyContent="space-between">
        {isNewFlag ? (
          <Box />
        ) : (
          <Button color="error" size="small" onClick={onDeleteFlag}>
            <DeleteIcon fontSize="small" fontWeight={700} />
            <Box fontWeight={700} ml={0.5}>
              Remove Flag
            </Box>
          </Button>
        )}
        <Box display="flex" justifyContent="flex-end" gap={1.5}>
          <Button variant="outlined" color="secondary" onClick={handleClosePopup}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="secondary"
            loading={saving}
            disabled={disabled}
            sx={{ background: theme.palette.blue.primary }}
            onClick={handleSave}
          >
            {isNewFlag ? "Add Flag" : "Save"}
          </LoadingButton>
        </Box>
      </Box>
    </DraggablePopover>
  )
}
