import { ExhibitBuilderData } from "../store/types"

export type PlaintiffsSlice = Pick<ExhibitBuilderData, "plaintiffs">

export const plaintiffsSlice: PlaintiffsSlice = {
  plaintiffs: {},
}

export const plaintiffsSelectors = {
  getPlaintiffs: (state: PlaintiffsSlice) => state.plaintiffs,
}
