import { DocumentStructureStoreData } from "../types"

export function moveDocumentStructureElement(
  state: DocumentStructureStoreData,
  id: string,
  parentId: string,
  index: number
): DocumentStructureStoreData {
  if (!state.sections[id] && !state.blocks[id]) return state
  if (!state.items[parentId] && !state.sections[parentId] && !state.blocks[parentId]) return state

  const nextState = { ...state }

  for (const key in nextState.structure) {
    const children = nextState.structure[key] ?? []

    if (children.some(child => child === id)) {
      nextState.structure[key] = children.filter(child => child !== id)
      break
    }
  }

  const children = nextState.structure[parentId] ?? []
  const childrenAfter = children.splice(index)

  nextState.structure[parentId] = [...children, id, ...childrenAfter]

  return nextState
}
