import { Navigate, useSearchParams } from "react-router-dom"
import { PdfRenderer } from "common/pdf-renderer"
import { extractExhibitIdFromUrl, queryParamToPayload } from "./utils"
import { PDF_DATA_QUERY_PARAM } from "./constants"
import { PinControl } from "./PinControl"

export interface PdfPreviewProps {
  url: string
  fileName: string
  currentFileName?: string
  pageNumberAdjustment?: number
  page?: number
  withCredentials?: boolean
  deletedPages?: [number, number][]
}

export function PdfPreviewPage(): JSX.Element {
  const [searchParams] = useSearchParams()
  const initialParams = queryParamToPayload<PdfPreviewProps>(searchParams.get(PDF_DATA_QUERY_PARAM) ?? "")

  const pdfData = initialParams && initialParams.fileName && initialParams.url ? initialParams : null

  if (!pdfData) {
    return <Navigate to="/exhibit-preview" replace />
  }

  const page = pdfData.page ?? 1
  const adjustedPage = pdfData.pageNumberAdjustment ? Math.max(page, pdfData.pageNumberAdjustment, 1) : page

  return (
    <PdfRenderer
      exhibitId={extractExhibitIdFromUrl(pdfData.url)}
      key={pdfData.fileName}
      filename={pdfData.fileName}
      currentFileName={pdfData.currentFileName}
      pageNumberAdjustment={pdfData.pageNumberAdjustment}
      url={pdfData.url}
      page={adjustedPage}
      withCredentials={pdfData.withCredentials}
      deletedPages={pdfData.deletedPages}
      rightControls={<PinControl />}
    />
  )
}
