import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Copyright from "@mui/icons-material/Copyright"
import { makeStyles } from "tss-react/mui"
import {
  APP_FOOTER_ID,
  FOOTER_HEIGHT_MULTIPLIER,
  FOOTER_MARGIN_BOTTOM_MULTIPLIER,
  FOOTER_MARGIN_TOP_MULTIPLIER,
} from "./constants"
import { TermsOfUseLink } from "./TermsOfUseLink"
import { useIssueReporter } from "common/issueReporter"

const useStyles = makeStyles()(theme => ({
  footer: {
    height: theme.spacing(FOOTER_HEIGHT_MULTIPLIER),
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(FOOTER_MARGIN_TOP_MULTIPLIER),
    marginBottom: theme.spacing(FOOTER_MARGIN_BOTTOM_MULTIPLIER),
    "& > *": {
      margin: theme.spacing(1),
    },
    "& > p": {
      fontSize: "0.85rem",
    },
    "& > svg": {
      fontSize: "1.25rem",
    },
    "@media print": {
      display: "none",
    },
  },
}))

export default function Footer() {
  const { classes } = useStyles()
  const issueReporter = useIssueReporter()
  const date = new Date()
  const year = date.getFullYear()
  return (
    <Box className={classes.footer} data-test="page-footer" id={APP_FOOTER_ID}>
      <Copyright />
      &#xb7;
      <Typography>EvenUp - {year}</Typography>
      &#xb7;
      <TermsOfUseLink />
      {issueReporter.isAvailable && (
        <>
          &#xb7;
          <a href="#" onClick={issueReporter.triggerUI}>
            Report an issue
          </a>
        </>
      )}
    </Box>
  )
}
