import { PropsOf } from "@emotion/react"
import styled from "@emotion/styled"
import { TableCell } from "@mui/material"
import { Link } from "react-router-dom"

const TableCellLink = styled(Link)({
  textDecoration: "none",
  color: "inherit",
  display: "block",
  alignContent: "center",
  padding: "16px",
  height: "100%",
  width: "100%",
})

const StyledCell = styled(TableCell)({
  minHeight: "0px",
  height: "0px",
})

/**
 * A {@link TableCell} that wraps its children in a {@link Link} component.
 * The Link component is styled to fill the entire cell and apply the same
 * padding as the TableCell's defaults.
 * This is useful for creating a table that has full rows clickable.
 */
export const TableCellWithLink = (
  props: PropsOf<typeof TableCell> & Pick<PropsOf<typeof Link>, "to" | "state" | "replace" | "target">
) => {
  const { children, to, state, replace, target, ...cellProps } = props
  return (
    <StyledCell {...cellProps} padding="none">
      <TableCellLink to={to} state={state} replace={replace} target={target}>
        {children}
      </TableCellLink>
    </StyledCell>
  )
}
