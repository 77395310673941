import { useCallback } from "react"
import RequestFormFiles from "requests/RequestForm/FileManagement/RequestFormFiles"
import { StyledFormBox } from "./styled"
import { queryKeys } from "react-query/constants"
import { deleteRequestFile, uploadRequestFile } from "api"
import { useFileUploader } from "common/file-uploader"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { compileFileErrorMessage, getErrorMessageFromJSONStr } from "common/utils"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { RequestFile } from "requests/types"
import { RequestFormModule } from "./RequestFormModule"
import { MutationRequestData } from "requests/RequestForm/FileManagement/types"
import { useRequestContext } from "requests/context"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import { SUPPORT_EMAIL } from "common/constants"
import { NO_CONTRACT_FOUND_EXCEPTION, NO_CURRENT_CONTRACT_EXCEPTION } from "requests/constants"
import { ApiError } from "apiHelper"
import { Box } from "@mui/material"
import { useFormContext } from "react-hook-form"

export const UploadFiles = () => {
  const { requestId, uploadingFiles, setUploadingFiles } = useRequestContext()
  const { getValues } = useFormContext()
  const queryClient = useQueryClient()
  const { showErrorMessage } = useHandleMessages()
  const fileUploader = useFileUploader()

  const deleteFileMutation = useMutation(deleteRequestFile, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.requestFiles])
    },
  })

  const handleUploadError = useCallback(
    (fileName: string, reason: string | null) => {
      const defaultReason = "Please check the files are not empty or password protected"
      const message = compileFileErrorMessage(fileName, reason, defaultReason)
      showErrorMessage(message)
    },
    [showErrorMessage]
  )

  const saveFileMutation = useMutation(uploadRequestFile, {
    onSuccess: () => {
      const { plaintiff_first_name, plaintiff_last_name, type } = getValues()
      queryClient.invalidateQueries([queryKeys.requestFiles])
      requestId &&
        amplitudeApm.trackEvent(
          new RequestAnalyticEvent(RequestAnalyticsEventTypes.UploadedDocumentsToRequest, {
            plaintiff_name: `${plaintiff_first_name} ${plaintiff_last_name}`,
            request_id: `${requestId}`,
            request_type: type,
          })
        )
    },
    onError: (error: ApiError, requestData: MutationRequestData) => {
      const errorType = error?.validationErrors?.type
      if (errorType === NO_CONTRACT_FOUND_EXCEPTION || errorType === NO_CURRENT_CONTRACT_EXCEPTION) {
        return showErrorMessage({
          message: (
            <Box>
              This firm does not have an active contract and is unable to submit requests at this time. If
              this is a mistake please email <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> and we
              will correct this issue as soon as possible.
            </Box>
          ),
        })
      }

      const reason = getErrorMessageFromJSONStr(error.message ?? null)
      handleUploadError(requestData.name, reason)
    },
  })

  const handleDeleteFile = (file: RequestFile) => {
    if (!file) return
    const pk = file.pk || file.id
    if (!pk) return
    deleteFileMutation.mutate({ fileId: pk, requestId })
    fileUploader.unassignUploaded(pk)
  }

  const handleViewFile = async (file: RequestFile) => {
    window.open(file.file_url + "?as_attachment=false", "_blank")
  }

  if (!requestId) {
    return <></>
  }

  return (
    <RequestFormModule title="Supporting Files">
      <StyledFormBox>
        <RequestFormFiles
          onDeleteFile={handleDeleteFile}
          fileUploader={fileUploader}
          saveFileMutation={saveFileMutation}
          requestId={requestId.toString()}
          uploadingFiles={uploadingFiles}
          setUploadingFiles={setUploadingFiles}
          onViewFile={handleViewFile}
        />
      </StyledFormBox>
    </RequestFormModule>
  )
}
