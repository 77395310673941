import { useCallback, useRef, useState } from "react"

type Callback = () => void
type AwaitableTask = Nullable<Promise<boolean>>

export function useAwaitAction(callback: Callback, awaitableTask?: AwaitableTask): [boolean, () => void] {
  const [loading, setLoading] = useState(false)
  const awaitableTaskRef = useRef(awaitableTask)
  const callbackRef = useRef(callback)

  awaitableTaskRef.current = awaitableTask
  callbackRef.current = callback

  const handleAction = useCallback(async () => {
    if (awaitableTaskRef.current) {
      setLoading(true)

      await awaitableTaskRef.current
    }

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        if (callbackRef.current) {
          callbackRef.current()
        }
        setLoading(false)
      })
    })
  }, [])

  return [loading, handleAction]
}
