import { Dispatch, SetStateAction } from "react"
import { Box, Button } from "@mui/material"
import { FileToUploadType } from "common/form-components/files/interfaces"
import PendingImageForm from "../../../DragAndDropFileUploader/PendingImagesForm"

interface PendingImagesProps {
  pendingImages: FileToUploadType[]
  setPendingImages: Dispatch<SetStateAction<FileToUploadType[]>>
  onSubmit: () => void
}

export function PendingImages({
  onSubmit,
  pendingImages,
  setPendingImages,
}: PendingImagesProps): JSX.Element {
  if (!pendingImages.length) {
    return <></>
  }

  return (
    <Box>
      <PendingImageForm pendingImages={pendingImages} setPendingImages={setPendingImages} />
      <Button variant="contained" color="secondary" onClick={onSubmit} data-test="done-adding-images">
        Done Adding Images
      </Button>
    </Box>
  )
}
