import { memo, useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"
import { locationStore, useLocationStore } from "./store"

function LocationStoreUpdater() {
  const location = useLocation()

  useLayoutEffect(() => {
    locationStore.setState({ ...location, isInitialized: true })
  }, [location])

  return <></>
}

export const LocationStoreProvider: React.FC<React.PropsWithChildren> = memo(function LocationStoreProvider({
  children,
}) {
  const isInitialized = useLocationStore(state => state.isInitialized)

  return (
    <>
      <LocationStoreUpdater />
      {isInitialized && children}
    </>
  )
})
