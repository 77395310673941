import { isNil } from "lodash"
import { ExhibitCitation } from "common/citation/ExhibitCitation"
import { useDocumentStore } from "documents/store"
import { useExhibitPreview } from "documents/exhibits/useExhibitPreview"
import { IcdCodeRelation, Partition } from "documents/types"
import { partitionsSelectors } from "documents/store/partitions"

interface FlagRelationProps {
  relation: IcdCodeRelation
}

interface FlagRelationComponentProps {
  partition: Partition
  relation: IcdCodeRelation
}

export const FlagRelationComponent = ({ relation, partition }: FlagRelationComponentProps) => {
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)
  const openPreview = useExhibitPreview(partition.exhibitId)

  return (
    <ExhibitCitation
      index={exhibitsOrder.indexOf(partition.exhibitId)}
      pages={isNil(relation.page) ? [] : [relation.page]}
      onClick={() => openPreview(partition, relation.page)}
    />
  )
}

export const FlagRelation = ({ relation }: FlagRelationProps) => {
  const partition = useDocumentStore(partitionsSelectors.getPartitionById(relation.partitionId))

  if (!partition) return null

  return <FlagRelationComponent relation={relation} partition={partition} />
}
