import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import Typography from "@mui/material/Typography"
import { StyledInformationWrapper } from "../styled"
import { InfoContainer } from "./InfoContainer"
import { FirmInformationProps } from "../types"

export const FirmInformation = ({ firm, submitter }: FirmInformationProps) => {
  return (
    <RequestFormModule title="Firm Information">
      <StyledInformationWrapper>
        <InfoContainer field="Firm Name" value={firm.name} />
        <InfoContainer
          field="Submitter"
          value={
            submitter ? (
              <>
                <Typography>{`${submitter.first_name} ${submitter.last_name}`}</Typography>
                <Typography>({submitter.email})</Typography>
              </>
            ) : (
              <Typography>Unknown Submitter</Typography>
            )
          }
        />
      </StyledInformationWrapper>
    </RequestFormModule>
  )
}
