import { CustomEditor } from "../../CustomEditor"
import { LIST_BLOCK_ELEMENTS } from "../../elements"
import { convertFromList } from "./convertFromList"
import { convertToList } from "./convertToList"
import { isInListOfType } from "./queries"

export function toggleList(editor: CustomEditor, type: LIST_BLOCK_ELEMENTS): void {
  if (!editor.selection) return

  if (!isInListOfType(editor, type)) {
    return convertToList(editor, type)
  }

  return convertFromList(editor)
}
