import { Editor } from "slate"
import { DEFAULT_VALUE } from "../defaultValue"
import { CustomEditor, EditorRoot } from "../CustomEditor"

export function resetEditableState(editor: CustomEditor, children: EditorRoot): void {
  const nextChildren = children.length ? children : DEFAULT_VALUE

  // Apply new children
  editor.children = nextChildren
  // Normalize data according to schema
  Editor.normalize(editor, { force: true })
  // Apply selection at the end of new children (Previous selection can be unreachable)
  const point = Editor.end(editor, [editor.children.length - 1])
  editor.selection = { anchor: point, focus: point }

  // Reset undo/redo
  if (editor.history) {
    editor.history.undos = []
    editor.history.redos = []
  } else {
    editor = {
      ...editor,
      history: {
        undos: [],
        redos: [],
      },
    }
  }
}

export function resetReadonlyState(editor: CustomEditor, children: EditorRoot): void {
  // Apply new children
  editor.children = children
  // Normalize data according to schema
  Editor.normalize(editor, { force: true })
  // Reset selection
  editor.selection = null
}
