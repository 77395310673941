import { useCallback, useState } from "react"

interface Response {
  isOpen: boolean
  openDialog: () => void
  closeDialog: () => void
  toggleDialog: () => void
}

export function useDialog(initialState: boolean = false): Response {
  const [isOpen, setIsOpen] = useState(initialState)
  const openDialog = useCallback(() => setIsOpen(true), [setIsOpen])
  const closeDialog = () => setIsOpen(false)
  const toggleDialog = () => setIsOpen(!isOpen)

  return {
    isOpen,
    openDialog,
    closeDialog,
    toggleDialog,
  }
}
