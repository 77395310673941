import { InputField } from "common/form-components"
import { RequestFormModule } from "./RequestFormModule"
import { StyledEntityWrapper, StyledEntityRow } from "./styled"
import { useRequestContext } from "requests/context"
import { useFormContext } from "react-hook-form"

export const AttorneyInformation = ({ readOnly }: { readOnly?: boolean }) => {
  const { requestId } = useRequestContext()
  const { control } = useFormContext()

  if (!requestId) {
    return <></>
  }

  return (
    <RequestFormModule title="Attorney">
      <StyledEntityWrapper>
        <StyledEntityRow>
          <InputField
            control={control}
            name="assigned_attorney_first_name"
            type="text"
            label="First Name"
            variant="outlined"
            size="small"
            InputProps={{
              readOnly: readOnly,
            }}
            sx={{ gridColumn: "span 2" }}
          />
          <InputField
            control={control}
            name="assigned_attorney_last_name"
            type="text"
            label="Last Name"
            variant="outlined"
            size="small"
            InputProps={{
              readOnly: readOnly,
            }}
            sx={{ gridColumn: "span 2" }}
          />
        </StyledEntityRow>
      </StyledEntityWrapper>
    </RequestFormModule>
  )
}
