import { PaginatedOptions } from "api/PaginatedList"
import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { ApiError } from "apiHelper"
import { AttributeFiltersData } from "common/attributes-filter/types"
import { CASE_SECTIONS } from "common/types/sections"
import invariant from "invariant"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { ApiServiceType, NonUniqueAttributesError } from "../types"
import { getQuery } from "../utils"
import { SectionTemplateServiceDeserializer, SectionTemplateServiceSerializer } from "./serializers"
import { SectionTemplateDto } from "./types"

interface TemplateServiceOptions {
  templateId: BaseEntity["pk"]
}

export type TemplateServiceEntityOptionsArg<Exists extends boolean = false> = {
  data: SectionTemplateDto
} & (Exists extends true ? { options: TemplateServiceOptions } : { options?: never })

export type SectionTemplateListOptions = PaginatedOptions & {
  section?: Nullable<CASE_SECTIONS>
  attributeValues?: AttributeFiltersData
  firmId: Nullable<PrimaryKey>
}

export type SectionTemplateListByFirmOptions = {
  firmId: Nullable<PrimaryKey>
}

enum TEMPLATE_API_PATHS {
  BASE = "library/template",
  SECTIONS = "sections",
  FIRM_TYPES = "firm_types",
}

export class SectionTemplateService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options?: TemplateServiceOptions, path?: TEMPLATE_API_PATHS): string {
    const pathParts = ["", TEMPLATE_API_PATHS.BASE, options?.templateId, path]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getTemplatesList = withResponseSerializer(
    SectionTemplateServiceDeserializer.fromPaginatedListJSON,
    ({ section, attributeValues, firmId, ...options }: SectionTemplateListOptions) => {
      const values = attributeValues
        ? SectionTemplateServiceSerializer.toAttributeValuesJSON(attributeValues)
        : []

      const query = getQuery({
        ["section"]: [section],
        ["attribute_value_ids"]: values,
        ["firm_id"]: [firmId],
      })

      return this.apiService.getPaginatedList(this.getPath(), query, options)
    }
  )

  getTemplatesListByFirm = withResponseSerializer(
    SectionTemplateServiceDeserializer.fromFirmTemplates,
    async ({ firmId }: SectionTemplateListByFirmOptions) => {
      const query = getQuery({ ["firm_id"]: [firmId] })

      return await handleEmptyResponse(
        this.apiService.get(null, this.getPath(undefined, TEMPLATE_API_PATHS.FIRM_TYPES), query)
      )
    }
  )

  createTemplate = withRequestSerializer(
    SectionTemplateServiceSerializer.toDefinitionJSON,
    withResponseSerializer(
      SectionTemplateServiceDeserializer.definitionFromJSON,
      async ({ data }: TemplateServiceEntityOptionsArg) => {
        try {
          return await handleEmptyResponse(this.apiService.create(data, this.getPath()))
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 400) {
            throw new NonUniqueAttributesError()
          }

          throw error
        }
      }
    )
  )

  updateTemplate = withRequestSerializer(
    SectionTemplateServiceSerializer.toDefinitionJSON,
    withResponseSerializer(
      SectionTemplateServiceDeserializer.definitionFromJSON,
      async ({ data, options }: TemplateServiceEntityOptionsArg<true>) => {
        try {
          return await handleEmptyResponse(this.apiService.replace(data, this.getPath(options)))
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 400) {
            throw new NonUniqueAttributesError()
          }

          throw error
        }
      }
    )
  )

  deleteTemplate = ({ options }: { options: { templateId: PrimaryKey } }) => {
    return this.apiService.delete(null, this.getPath(options))
  }

  getMatchingTemplates = withRequestSerializer(
    SectionTemplateServiceSerializer.toAttributeValuesJSON,
    withResponseSerializer(
      SectionTemplateServiceDeserializer.templatesBySectionFromJSON,
      ({ data, firmId }: { data: number[]; firmId: Nullable<PrimaryKey> }) => {
        invariant(
          Array.isArray(data),
          "SectionTemplateService.getMatchingTemplates: data should be instance of Array"
        )

        const query = getQuery({ ["attribute_value_ids"]: data, ["firm_id"]: [firmId] })

        return handleEmptyResponse(
          this.apiService.get(null, this.getPath(undefined, TEMPLATE_API_PATHS.SECTIONS), query)
        )
      }
    )
  )
}

export const sectionTemplateService = new SectionTemplateService(apiService)
