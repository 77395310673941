import React, { useMemo } from "react"
import { useTheme } from "@emotion/react"
import { Box } from "@mui/material"
import { DemandCalendarYear } from "../DemandCalendar/DemandCalendarYear"
import MedicronCalendarEndOfYearGap from "./MedicronCalendarEndOfYearGap"
import { MedicronDayRenderer } from "./MedicronDayRenderer"
import { getYearsToDisplayInCalendar, transformAppointments } from "./medicronCalendarUtilities"
import { MedicronCalendarLegend } from "./MedicronCalendarLegend"
import { MedicronCalendarData } from "documents/store/types"
import { MedicronCalendarAppointment } from "./types"

export interface IMedicronCalendar {
  appointmentData: MedicronCalendarData
  startYear?: Date
  endYear?: Date
  hideLegend?: boolean
  onCalendarDayClick?: (appointments: MedicronCalendarAppointment[], element: HTMLElement) => void
}

/**
 * Planning on using this in the portal? I'd recommend doing some performance touchups on it first.
 * When will the data change?  How will it change? Check what re-renders when that happens
 * This was developed for a case where the data doesn't change, so it's not optimized for that.
 *
 * Tripping hazard: Note that calendar days are 0-indexed, but the display is 1-indexed
 */
export const MedicronCalendar = React.memo(function MedicronCalendar({
  appointmentData,
  startYear,
  endYear,
  onCalendarDayClick,
  hideLegend = false,
}: IMedicronCalendar): React.ReactElement {
  const theme = useTheme()

  const appointments = appointmentData.appointments
  const providers = appointmentData.providers
  const dayOfIncident = appointmentData.dayOfIncident || new Date("October 09, 2023")

  const groupedAppointments = useMemo(
    () => transformAppointments(appointments, providers),
    [appointments, providers]
  )
  const years = getYearsToDisplayInCalendar(groupedAppointments, startYear, endYear)

  return (
    <Box width="fit-content">
      {years.map(year => {
        return (
          <Box
            id={`medicron-calendar-year-box-${year}`}
            key={`calendar-year-box-${year}`}
            marginBottom={theme.spacing(6)}
            data-test={`calendar-year-${year}`}
          >
            <DemandCalendarYear
              key={`calendar-year-${year}`}
              year={year}
              highlightedDay={dayOfIncident}
              groupedData={groupedAppointments}
              dayComponent={MedicronDayRenderer({ onClick: onCalendarDayClick })}
              hideEmptyRows
            />
            <MedicronCalendarEndOfYearGap
              key={`calendar-end-of-year-${year}`}
              year={year}
              groupedAppointments={groupedAppointments}
            />
          </Box>
        )
      })}
      {!hideLegend && <MedicronCalendarLegend providers={providers} />}
    </Box>
  )
})
