import React from "react"
import { makeStyles } from "tss-react/mui"
import HelpIcon from "@mui/icons-material/Help"

const useStyles = makeStyles()(theme => ({
  fullWidth: {
    gridColumn: "1 / 3",
  },
  outer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: theme.spacing(1),
  },
  inner: {
    width: "100%",
    fontSize: `13px`,
    fontWeight: 600,
  },
  helperText: {
    color: "#777777",
    padding: theme.spacing(0, 1),
    fontFamily: `${theme.typography.fontFamily}`,
    fontSize: `0.85em`,
    left: theme.spacing(1),
    display: "inline-flex",
    alignItems: "center",
  },
}))

export interface RichTextFieldProps {
  label?: string
  helperText?: string
  actions?: Nullable<React.ReactElement>
  children: React.ReactElement
}

export function RichTextWithActions({
  label,
  helperText,
  actions,
  children,
}: RichTextFieldProps): JSX.Element {
  const { classes } = useStyles()

  return (
    <div className={classes.fullWidth}>
      <div className={classes.outer}>
        <span className={classes.inner}>
          {label}
          {helperText && (
            <>
              <span className={classes.helperText}>
                {" "}
                -&nbsp;
                <HelpIcon fontSize="inherit" />
                &nbsp;{helperText}
              </span>
            </>
          )}
        </span>
        {actions}
      </div>
      {children}
    </div>
  )
}
