import { useMemo } from "react"
import { Box, Typography } from "@mui/material"
import { useTheme } from "@emotion/react"
import { ReactCalendarBaseProps } from "./types"
import { DemandCalendarMonth } from "./DemandCalendarMonth"
import { determineMonthsToRender } from "./demandCalendarUtilities"

type DemandCalendarYearProps<DayT> = {
  year: number
  hideEmptyRows?: boolean
  monthsPerRow?: number
} & ReactCalendarBaseProps<DayT>

export function DemandCalendarYear<DayT>({
  year,
  hideEmptyRows = false,
  monthsPerRow = 4,
  groupedData,
  ...props
}: DemandCalendarYearProps<DayT>): JSX.Element {
  const theme = useTheme()
  const months = useMemo(
    () =>
      hideEmptyRows
        ? determineMonthsToRender(groupedData, year, monthsPerRow)
        : Array.from({ length: 12 }, (_, i) => i + 1),
    [hideEmptyRows, groupedData, year, monthsPerRow]
  )

  if (months.length === 0) {
    return <></>
  }

  return (
    <Box>
      <Typography
        fontSize={theme.typography.pxToRem(30)}
        fontWeight={theme.typography.fontWeightBold}
        marginBottom={theme.spacing(5)}
      >
        {year}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${monthsPerRow}, 1fr)`,
          gap: theme.spacing(2),
          width: "fit-content",
        }}
      >
        {months.map(month => (
          <DemandCalendarMonth
            key={`${year}-${month}`}
            year={year}
            month={month}
            groupedData={groupedData}
            {...props}
          />
        ))}
      </Box>
    </Box>
  )
}
