import { ExhibitBuilderData } from "../types"

export type FilesSlice = Pick<
  ExhibitBuilderData,
  | "exhibitPartitionMap"
  | "exhibitPartitionOrder"
  | "userExhibitMap"
  | "userExhibitOrder"
  | "recordsAndBillsMap"
  | "recordsAndBillsOrder"
  | "files"
  | "annotationStatus"
>

export const filesSlice: FilesSlice = {
  exhibitPartitionMap: {},
  exhibitPartitionOrder: {},
  userExhibitMap: {},
  userExhibitOrder: [],
  recordsAndBillsMap: {},
  files: {},
  annotationStatus: {},
  recordsAndBillsOrder: {},
}
