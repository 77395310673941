import { useMemo } from "react"
import { useIsFetching, useIsMutating } from "@tanstack/react-query"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Backdrop from "@mui/material/Backdrop"
import { makeStyles } from "tss-react/mui"
import invariant from "invariant"
import { identity } from "lodash"
import { v4 } from "uuid"
import { useLoading } from "./useLoading"

const useStyles = makeStyles()(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: `${theme.palette.text.disabled}`,
  },
  inlineBackdrop: {
    position: "absolute",
  },
  text: {
    marginLeft: theme.spacing(2),
    color: theme.palette.grey["300"],
  },
  disableBackdrop: {
    background: "none",
  },
  inlined: {
    position: "relative",
    display: "inline-block",
  },
}))

interface CustomLoadingProps {
  show: boolean
  label: string
  inline?: boolean
  disableBackdrop?: boolean
}

type DefaultLoadingProps = {
  [K in keyof CustomLoadingProps]?: undefined
} & {
  showOnMutation?: boolean
}

type LoadingProps = DefaultLoadingProps | CustomLoadingProps

export function Loading(props: LoadingProps): JSX.Element {
  const { classes, cx } = useStyles()
  const id = useMemo(() => v4(), [])
  const fetchingCount = useIsFetching({ predicate: query => !query.meta?.disableLoader })
  const mutatingCount = useIsMutating({ predicate: query => !query.meta?.disableLoader })

  const isControlled = props.show !== undefined
  const isLoading = isControlled && props.show
  const isFetching = !isControlled && fetchingCount > 0
  const isMutating = !isFetching && !isControlled && props.showOnMutation && mutatingCount > 0

  invariant(
    [isFetching, isMutating, isLoading].filter(identity).length <= 1,
    "Loading state can only be in one state at a time"
  )

  const showLoadingState = isFetching || isMutating || isLoading
  const { isLoadingActive } = useLoading(id, showLoadingState)

  return (
    <Backdrop
      className={cx(
        classes.backdrop,
        props.inline && classes.inlineBackdrop,
        props.disableBackdrop && classes.disableBackdrop,
        props.inline && props.disableBackdrop && classes.inlined
      )}
      open={isLoadingActive}
      data-test="data-loading-indicator"
    >
      <CircularProgress color="secondary" />
      <Typography variant="h6" component="span" className={classes.text}>
        {isFetching && "Loading..."}
        {isMutating && "Working..."}
        {isLoading && props.label}
      </Typography>
    </Backdrop>
  )
}
