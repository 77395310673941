import React, { PropsWithChildren, useEffect } from "react"
import { initMessageBroker } from "./init"
import { getWorkbox } from "./workbox"
import { noop } from "lodash"
import { isControlledEnvironment } from "infrastructure/env/getEnvironment"
import { useLocationStore } from "app/location/store"

export const MessageBroker = React.memo(function MessageBroker({ children }: PropsWithChildren) {
  const pathname = useLocationStore(location => location.pathname)

  useEffect(() => {
    initMessageBroker()
  }, [])

  useEffect(() => {
    if (!isControlledEnvironment()) {
      const timeout = setTimeout(() => {
        try {
          const workbox = getWorkbox()
          workbox.controlling
            .then(() => {
              return workbox.update()
            })
            .catch(noop)
        } catch {
          return
        }
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [pathname])

  return <>{children}</>
})
