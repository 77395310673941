import { useEffect, useMemo } from "react"
import { Box, Table, Skeleton, TableHead, TableCell, TableRow } from "@mui/material"
import styled from "@emotion/styled"

import { smartAdvocateGetCaseDocuments } from "api"
import { useSuspenseQuery } from "@suspensive/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"

import { withSuspense } from "common/withSuspense"
import { SmartAdvocateFormValues, SmartAdvocateCaseDocument } from "./types"
import Tooltip from "common/Tooltip"
import { UseFormSetValue } from "react-hook-form"
import invariant from "invariant"

interface CaseDocument {
  id: number
  name: string
  type: string
}

interface CaseDocumentsTableProps {
  caseNumber: Nullable<string>
  setValue: UseFormSetValue<SmartAdvocateFormValues>
}

const StyledTableContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: "6px",
  maxHeight: 400,
  overflow: "scroll",
}))

export const CaseDocumentsTable = withSuspense(
  ({ caseNumber, setValue }: CaseDocumentsTableProps) => {
    invariant(!!caseNumber, "Valid Case Number should be provided")

    // TODO: Refactor to move serializer logic to API Service
    const { data: caseDocumentsResponse } = useSuspenseQuery(
      [queryKeys.smartAdvocateDocuments, caseNumber],
      async () => smartAdvocateGetCaseDocuments(caseNumber),
      {
        ...SILENT_QUERY_PARAMS,
      }
    )

    const caseDocuments: Nullable<CaseDocument[]> = useMemo(() => {
      if (!caseDocumentsResponse || !caseDocumentsResponse?.documents) return null

      return caseDocumentsResponse.documents.map((caseDocument: SmartAdvocateCaseDocument) => {
        return {
          id: caseDocument.documentID,
          name: caseDocument.documentName,
          type: caseDocument.docType,
        }
      })
    }, [caseDocumentsResponse])

    useEffect(() => {
      setValue("caseDocuments", caseDocuments?.map((document: CaseDocument) => document.id) ?? [])
    }, [caseDocuments, setValue])

    if (!caseDocuments || !caseDocuments?.length) return <></>

    return (
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableCell sx={{ maxWidth: 175, textOverflow: "ellipsis", overflow: "hidden" }}>
              File Name
            </TableCell>
            <TableCell>File Type</TableCell>
          </TableHead>
          {caseDocuments.map((document: CaseDocument) => {
            return (
              <TableRow key={`document-${document.id}`}>
                <TableCell sx={{ maxWidth: 175, textOverflow: "ellipsis", overflow: "hidden" }}>
                  <Tooltip tooltipText={document.name}>
                    <Box>{document.name}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell>{document.type}</TableCell>
              </TableRow>
            )
          })}
        </Table>
      </StyledTableContainer>
    )
  },
  <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </Box>
)
