import { isNil } from "lodash"
import { usePermissions } from "permissions/usePermissions"
import { ViewRequestRevampProps } from "./types"
import {
  PlaintiffInformation,
  CaseInformation,
  PolicyCarrierInformation,
  AttorneyInformation,
  SupportingFile,
  FirmInformation,
  SubmitMedicalChronology,
  GeneratedDocuments,
  ViewMedicalChronologySection,
  CollaboratorsInformaion,
} from "./components"
import useUser from "hooks/useUser"

export const MedicalChronologyView = ({ request }: ViewRequestRevampProps) => {
  const { webViewMedicalChronologyEnabled } = usePermissions()
  const { user } = useUser()

  const { collaboratorSelectionEnabled } = usePermissions({
    suspense: true,
    enabled: user.isAuthorized,
    firmId: request.firm ? request.firm.pk : null,
  })

  return (
    <>
      {webViewMedicalChronologyEnabled && <ViewMedicalChronologySection request={request} />}
      {!isNil(request.document_id) && !isNil(request.pk) && (
        <GeneratedDocuments
          documentId={request.document_id}
          requestId={request.pk}
          title="Generated Medical Chronology"
        />
      )}
      <SupportingFile files={request.files} request={request} />
      {user.isInternal && <SubmitMedicalChronology request={request} />}
      <PlaintiffInformation plaintiffs={request.plaintiffs} />
      <CaseInformation
        dateOfIncident={request.date_of_incident}
        caseType={request.case_type}
        stateOfIncident={request.state_of_incident}
      />
      <FirmInformation firm={request.firm} submitter={request.submitter} />
      <PolicyCarrierInformation request={request} />
      <AttorneyInformation request={request} />
      {collaboratorSelectionEnabled && <CollaboratorsInformaion request={request} />}
    </>
  )
}
