import styled from "@emotion/styled"
import { Box } from "@mui/material"
import CircleIcon from "@mui/icons-material/Circle"

interface StyledTreatmentEntityTextProps {
  rotated?: boolean
}

export const StyledAbsentOfTreatment = styled("div")(({ theme }) => ({
  position: "relative",
  flexGrow: "1",
  maxWidth: "75px",

  "&::before": {
    content: "''",
    position: "absolute",
    width: "0px",
    height: "calc(100% + 18px)",
    top: "7px",
    left: "0px",
    borderLeft: `1px dashed ${theme.palette.disabled.darker}`,
  },

  "&::after": {
    content: "''",
    position: "absolute",
    width: "1px",
    height: "42px",
    bottom: "-25px",
    transform: "translateY(100%)",
    left: "50%",
    background: theme.palette.blue.primary,
  },
}))

export const StyledBasicTreatmentTimelineEntity = styled("div")(({ theme }) => ({
  position: "relative",
  flexGrow: "1",
  height: "100%",
  paddingTop: "7px",
  display: "flex",
  flexDirection: "column",
  gap: "30px",

  "&::before": {
    content: "''",
    position: "absolute",
    width: "0px",
    height: "calc(100% + 18px)",
    top: "7px",
    left: "0px",
    borderLeft: `1px dashed ${theme.palette.disabled.darker}`,
  },
}))

export const StyledAbsentOfTreatmentBottomBorder = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "0px",
  left: "0px",
  bottom: "-25px",
  borderBottom: `1px solid ${theme.palette.blue.primary}`,
}))

export const StyledTreatmentEntityText = styled("div")<StyledTreatmentEntityTextProps>(({ rotated }) => ({
  position: "absolute",
  color: "#676767",
  fontSize: "12px",
  fontWeight: 700,
  bottom: "-55px",
  left: "0px",
  transform: rotated ? "translate(-90%, -75%) rotate(-45deg)" : "translateX(-50%)",
  transformOrigin: "right",
  whiteSpace: "nowrap",
}))

export const StyledLastTreatmentEntityTextWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "7px",
  right: "-1px",
  height: "calc(100% + 18px)",
  borderLeft: `1px dashed ${theme.palette.disabled.darker}`,
}))

export const StyledLastTreatmentEntityText = styled("div")<StyledTreatmentEntityTextProps>(({ rotated }) => ({
  position: "absolute",
  color: "#676767",
  fontSize: "12px",
  fontWeight: 700,
  bottom: "-30px",
  left: "0px",
  transform: rotated ? "translate(-90%, -75%) rotate(-45deg)" : "translateX(-50%)",
  transformOrigin: "right",
  whiteSpace: "nowrap",
}))

export const StyledTreatmentTimelineLine = styled("div")({
  position: "relative",
  width: "100%",
  height: "1px",
  background: "rgba(217, 217, 217, 0.80)",
})

export const StyledProviderName = styled("div")(({ theme }) => ({
  position: "relative",
  display: "inline-block",
  fontSize: "13px",
  lineHeight: "15px",
  paddingRight: theme.spacing(1),
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  backgroundColor: theme.palette.common.white,
  zIndex: 1,
}))

export const StyledPointNumber = styled(Box)({
  position: "absolute",
  top: "-18px",
  transform: "translateX(-50%)",
  fontSize: "10px",
  fontWeight: 600,
})

export const StyledPoint = styled(CircleIcon)({
  position: "absolute",
  top: "-5px",
  transform: "translateX(-50%)",
  width: "11px",
  height: "11px",
  zIndex: 1,
})

export const StyledPriorIncidentIndicator = styled("div")({
  position: "absolute",
  top: "7px",
  bottom: "0px",
  width: "100%",
  height: "calc(100% + 18px)",
  backgroundColor: "rgba(212, 212, 212, 0.25)",
})

export const StyledIncidentIndicator = styled(Box)({
  position: "absolute",
  top: "7px",
  bottom: "0px",
  height: "calc(100% + 18px)",
  backgroundColor: "rgba(212, 212, 212, 0.25)",
})

export const StyledIncidentIndicatorText = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "0px",
  left: "0px",
  fontSize: "10px",
  fontWeight: 600,
  color: theme.palette.red.warning,
  whiteSpace: "nowrap",
  textAlign: "center",
  transform: "translate(-50%, calc(-100% - 5px))",
}))

export const StyledIncidentLine = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "0px",
  height: "calc(100% + 25px)",
  bottom: "0px",
  right: "0px",
  borderLeft: `1px dashed ${theme.palette.red.warning}`,
}))

export const StyledProviderRuler = styled("div")({
  position: "absolute",
  width: "calc(100% - 20px)",
  height: "1px",
  bottom: "7px",
  right: "0px",
  background: "rgba(217, 217, 217, 0.30)",
})

export const StyledEntitiesWrapper = styled("div")({
  position: "relative",
  width: "calc(100% - 240px)",
  display: "flex",
  justifyContent: "flex-start",
})

export const StyledAbsentOfTreatmentText = styled("div")(({ theme }) => ({
  position: "absolute",
  whiteSpace: "nowrap",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginLeft: theme.spacing(0.5),
  left: "50%",
  bottom: "-95px",
  transform: "translateX(-50%)",
  fontSize: "10px",
  fontWeight: 600,
  color: theme.palette.blue.primary,
}))
