import { APP_VERSION } from "app/constants"
import { getSessionUrl } from "infrastructure/apm"
import Cookies from "js-cookie"
import { getAPIServerURL } from "utils"
import * as Sentry from "@sentry/browser"

export interface UseQueryFunctionProps {
  queryKey: [string]
}

export enum REQUEST_METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export interface BaseCallProps<T = never> {
  customUrl?: string
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: { [key: string]: any }
  method: REQUEST_METHODS
  data?: T extends never ? never : Nullable<T>
  json: false
  blob?: false
  isFormData?: false
  headers?: { [key: string]: string }
}

export interface FormDataCallProps<T extends FormData = FormData>
  extends Omit<BaseCallProps<T>, "isFormData"> {
  isFormData: true
}

export interface JsonCallProps<T = never> extends Omit<BaseCallProps<T>, "json"> {
  json?: true
}

export interface BlobCallProps<T = never> extends Omit<BaseCallProps<T>, "blob"> {
  blob: true
}

export type CallProps<T> = T extends FormData
  ? FormDataCallProps<FormData>
  : JsonCallProps<T> | BlobCallProps<T> | BaseCallProps<T>

export class ApiError extends Error {
  name = "ApiError"
  method: REQUEST_METHODS
  response: Response & { code?: string }

  constructor(message: string, response: Response, method: REQUEST_METHODS) {
    super(message)
    this.cause = JSON.stringify(
      {
        method,
        url: response.url,
        status: response.status,
      },
      null,
      2
    )
    this.method = method
    this.response = response
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get validationErrors(): Record<string, any> | null {
    let errors = null

    try {
      errors = JSON.parse(this.message)
    } catch (jsonError) {
      Sentry.captureMessage("ApiError – validationErrors() failed to parse", {
        level: "warning",
        extra: {
          message: this.message,
          cause: this.cause,
          method: this.method,
          response: this.response,
          jsonParseError: jsonError,
        },
      })
    }

    return errors
  }
}

export type QueryStringValue = string | number | boolean
export type QueryEntry<TValue> = [string, TValue]

function notEmptyEntry<TValue>(entry: QueryEntry<Nullable<TValue> | undefined>): entry is QueryEntry<TValue> {
  const [key, value] = entry
  return Boolean(key) && value !== null && value !== undefined
}

function makeQueryString(query: Record<string, Nullable<QueryStringValue>>): string {
  const queryEntries = Object.entries(query).filter(notEmptyEntry)

  if (queryEntries.length === 0) return ""

  return `?${queryEntries.map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join("&")}`
}

export function getAuthHeaders(): Record<string, string> {
  let deploymentEnv = import.meta.env.VITE_DEPLOYMENT_ENV ?? ""

  // Ephemeral environments will all be under eph.evenup.law
  // When detected, we know what the actual environment name
  // is based on the domain, and therefore, the CSRF token name
  const hostname = window.location.hostname
  if (
    hostname.match(/\.eph\.evenup\.law$/) ||
    hostname.match(/\.int\.evenup\.law$/) ||
    hostname.match(/\.rdprod\.evenup\.law$/)
  ) {
    deploymentEnv = hostname.split(".")[0]
  }

  const csrftoken = Cookies.get(`csrftoken${deploymentEnv}`)

  const headers: Record<string, string> = {
    "X-CSRFToken": csrftoken || "",
  }
  const sessionUrl = getSessionUrl()

  if (sessionUrl) {
    headers["X-FullStory-URL"] = sessionUrl
  }

  if (APP_VERSION) {
    headers["x-service-version"] = APP_VERSION
  }

  return headers
}

export function getUrl(path: string, query = ""): string {
  return `${getAPIServerURL()}${path}${query}`
}

export class EmptyResponse {}

export class BlobResponse {
  constructor(
    private readonly blobData: Promise<Blob>,
    readonly filename: string = ""
  ) {}

  blob(): Promise<Blob> {
    return this.blobData
  }
}

export function makeApiCall<TData extends FormData = FormData>(
  props: FormDataCallProps<TData>
): Promise<Response>
export function makeApiCall<TResult, TData = unknown>(
  props: JsonCallProps<TData>
): Promise<TResult | EmptyResponse>
export function makeApiCall<TData = unknown>(
  props: BlobCallProps<TData>
): Promise<BlobResponse | EmptyResponse>
export function makeApiCall<TData = unknown>(
  props: TData extends FormData ? never : BaseCallProps<TData>
): Promise<Response>
export function makeApiCall<TData = never>(props: BaseCallProps<TData>): Promise<Response>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function makeApiCall<TData, TProps extends CallProps<TData>>(props: TProps): Promise<any> {
  const query = props.query ? makeQueryString(props.query) : ""
  const url = props.customUrl ? props.customUrl : getUrl(props.path, query)
  const headers = getAuthHeaders()

  if (props.json === undefined || props.json) {
    headers["Content-Type"] = "application/json"
  }

  if (props.headers) {
    Object.entries(props.headers).forEach(([key, value]) => {
      headers[key] = value
    })
  }

  const fetchOptions: RequestInit = {
    method: props.method,
    credentials: "include",
    headers,
  }

  fetchOptions.body

  if (props.data) {
    fetchOptions.body = props.isFormData ? props.data : JSON.stringify(props.data)
  }

  const response = await fetch(url, fetchOptions).catch(reason => {
    // Error in fetch is thrown ONLY when no request happened
    // e.g. CORS errors, some other stuff in browser
    // reason doesn't provide any helpful details here so we try to map it to our ApiError
    const response = new Response(null)
    Object.defineProperties(response, {
      url: { value: url },
      ok: { value: false },
      // As mentioned above - we don't get any response here and have no clues on what happened
      // So will mark it with "teapot" error code - it will be a special marker to find issues and watch replays
      status: { value: 418 },
    })
    throw new ApiError(String(reason), response, props.method)
  })

  if (!response.ok) {
    // get the error response as text
    // so we can pass it down to the caller
    const err = await response.text()
    throw new ApiError(err, response, props.method)
  }

  if (props.json === undefined || props.json) {
    if (response.status == 204 || response.headers.get("Content-Length") === "0") {
      return new EmptyResponse()
    }
    return response.json()
  } else if (props.blob) {
    if (response.status == 204) {
      return new EmptyResponse()
    }
    const header = response.headers.get("Content-Disposition") ?? ""
    const [, filenamePart = ""] = header.split(";")
    const [, filename = ""] = filenamePart.split("=")
    const cleanedFilename = filename.replace(/['"]/g, "")

    return new BlobResponse(response.blob(), cleanedFilename)
  } else {
    return response
  }
}
