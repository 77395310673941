import React, { useCallback, useRef, useState, useMemo, useEffect } from "react"
import { ReactEditor, useSlate } from "slate-react"
import TextButton, { TextButtonProps } from "common/buttons/TextButton"
import { MultiLevelMenu } from "common/multilevel-menu"
import { Variable } from "common/types/variables"
import { Editor as WithVariablesEditor } from "../../../features/variables/Editor"
import { Editor } from "common/form-components/rich-text/Editor"
import { StyledToolbarGroup } from "../styled"

interface AddVariableSideEffectHandlers {
  onOpen: () => void
  onClose: () => void
}

export interface AddVariableButtonProps extends TextButtonProps, AddVariableSideEffectHandlers {
  disabled: boolean
  onVariableSelect: (variable: Variable) => void
  onVariablePreview: (variable: Nullable<Variable>) => void
}

export function AddVariableButton({
  isVariablesEnabled,
}: {
  isVariablesEnabled: Nullable<boolean>
}): Nullable<JSX.Element> {
  const editor = useSlate()

  const [menuOpened, setMenuOpened] = useState(false)
  const menuAnchorRef = useRef<HTMLButtonElement>(null)

  const disabled = !isVariablesEnabled

  const onOpen = useCallback(() => {
    if (Editor.isVariablesEditor(editor)) {
      WithVariablesEditor.insertDraftVariable(editor)
    }
  }, [editor])

  const onClose = useCallback(() => {
    if (Editor.isVariablesEditor(editor)) {
      WithVariablesEditor.clearDraftVariables(editor, { undoSideEffect: true })
    }
  }, [editor])

  const handleMenuToggle = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setMenuOpened(!menuOpened)
    },
    [menuOpened]
  )

  const handleMenuClose = useCallback(() => {
    setMenuOpened(false)
  }, [])

  const handleVariableSelect = useCallback(
    (variable: Variable) => {
      if (Editor.isVariablesEditor(editor)) {
        ReactEditor.focus(editor)
        WithVariablesEditor.finalizeAddVariable(editor, variable)

        requestAnimationFrame(() => {
          handleMenuClose()
        })
      }
    },
    [editor, handleMenuClose]
  )

  const handleVariableHover = useCallback(
    (variable: Nullable<Variable>) => {
      if (Editor.isVariablesEditor(editor)) {
        WithVariablesEditor.updateDraftVariable(editor, variable)
      }
    },
    [editor]
  )

  // Store handlers for side effects
  const sideEffectHandlers = useRef<AddVariableSideEffectHandlers>({ onOpen, onClose })
  // Pass latest props to handlers ref
  sideEffectHandlers.current = { onOpen, onClose }

  // Execute side effect from props when opened state changed
  useEffect(() => {
    if (menuOpened) {
      sideEffectHandlers.current.onOpen()
      return () => sideEffectHandlers.current.onClose()
    }
  }, [menuOpened, sideEffectHandlers])

  // Variables list from editor
  const variables = useMemo(
    () => (Editor.isVariablesEditor(editor) && menuOpened ? editor.variables.getItems() : []),
    [editor, menuOpened]
  )

  return useMemo(() => {
    if (!Editor.isVariablesEditor(editor) || isVariablesEnabled === null) {
      return null
    }

    return (
      <StyledToolbarGroup>
        <TextButton
          aria-controls="categories-list"
          disabled={disabled}
          size="small"
          onMouseDown={handleMenuToggle}
          ref={menuAnchorRef}
          sx={{ textTransform: "none" }}
        >
          + Add Variable
        </TextButton>
        <MultiLevelMenu
          items={variables}
          anchorRef={menuAnchorRef}
          open={menuOpened}
          group={"category"}
          label={"name"}
          displayLabel={"label"}
          onSelect={handleVariableSelect}
          onHover={handleVariableHover}
          onClose={handleMenuClose}
        />
      </StyledToolbarGroup>
    )
  }, [
    editor,
    disabled,
    handleMenuClose,
    handleMenuToggle,
    handleVariableHover,
    handleVariableSelect,
    menuOpened,
    menuAnchorRef,
    variables,
    isVariablesEnabled,
  ])
}
