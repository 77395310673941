import * as _ from "lodash"
import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { ViewRequestRevampProps } from "../types"
import { StyledInformationWrapper } from "../styled"
import { InfoContainer } from "./InfoContainer"
import { getPolicyCoverageType, getPolicyType } from "../utils"

export const PolicyCarrierInformation = ({ request }: ViewRequestRevampProps) => {
  const currencyFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })

  return (
    <RequestFormModule title="Policy and Carrier Information">
      <StyledInformationWrapper>
        <InfoContainer field="Carrier Name" value={request.carrier_name} />
        <InfoContainer field="Policy Type" value={getPolicyType(request.policy_type)} />
        <InfoContainer
          field="Coverage Type"
          value={getPolicyCoverageType(request.policy_type, request.policy_coverage_type)}
        />
        <InfoContainer field="Policy Limit" value={currencyFormat.format(request.policy_limit)} />
        <InfoContainer field="Policy Number" value={request.policy_number} />
        <InfoContainer field="Claim Number" value={request.claim_number} />
        <InfoContainer
          field="Desired Claim Amount"
          value={
            _.isNil(request.intended_claim_amount)
              ? "-"
              : currencyFormat.format(request.intended_claim_amount)
          }
        />
      </StyledInformationWrapper>
    </RequestFormModule>
  )
}
