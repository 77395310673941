import { Flag, isFlagForUpdating } from "documents/flags/types"
import { FlagsData, GetState, SetState } from "./types"
import { documentStateActions } from "./documentState"
import { matterApiService } from "api/services/matter"
import { omit } from "lodash"
import { Appointment } from "documents/types"

export type FlagsDataSlice = FlagsData

export const flagsDataSlice: FlagsDataSlice = {
  flagSelector: null,
  currentFlag: null,
  hoveredFlagId: null,
  scrollToFlag: null,
  flags: {},
}

export const flagsDataSelectors = {
  getFlagById: (id: Flag["id"]) => (state: FlagsDataSlice) => state.flags[id],
  getCurrentFlag: (state: FlagsDataSlice) => state.currentFlag,
  getFlagSelector: (state: FlagsDataSlice) => state.flagSelector,
  getHoveredFlagId: (state: FlagsDataSlice) => state.hoveredFlagId,
  getScrollToFlag: (state: FlagsDataSlice) => state.scrollToFlag,
  getFlags: (state: FlagsDataSlice) => state.flags,
  getFlagsIdInAppointment:
    (id: Appointment["id"]) =>
    (state: FlagsDataSlice): Appointment["id"][] => {
      const result: Appointment["id"][] = []

      Object.values(state.flags).forEach(flag => {
        if (flag.appointment === id) result.push(flag.id)
      })

      return result
    },
}

export const flagsDataActions = (set: SetState<FlagsDataSlice>, get: GetState<FlagsDataSlice>) => ({
  createNewFlag: (flagSelector: string, quote?: string) =>
    set({
      currentFlag: quote ? { quote } : {},
      flagSelector,
    }),
  editFlag: (flagId: Flag["id"], flagSelector: string) => {
    const flags = flagsDataSelectors.getFlags(get())

    set({ currentFlag: flags[flagId], flagSelector })
  },
  setHoveredFlagId: (hoveredFlagId: Nullable<string>) => {
    set({ hoveredFlagId })
  },
  removeCurrentFlag: () => set({ currentFlag: null, flagSelector: null }),
  createFlag: (data: Omit<Flag, "id">, callback?: (flag: Flag) => void) => {
    documentStateActions.save(
      matterApiService.createFlag({ data }).then(flag => {
        if (callback) callback(flag)
        const flags = get().flags

        set({ flagSelector: null, currentFlag: null, flags: { ...flags, [flag.id]: flag } })
      }),
      "There was an error creating new flag."
    )
  },
  updateFlag: () => {
    const currentFlagData = flagsDataSelectors.getCurrentFlag(get())

    if (!currentFlagData) return

    if (isFlagForUpdating(currentFlagData)) {
      documentStateActions.save(
        matterApiService.updateFlag({ data: currentFlagData }).then(flag => {
          const flags = get().flags

          set({ flagSelector: null, currentFlag: null, flags: { ...flags, [flag.id]: flag } })
        }),
        "There was an error updating the flag."
      )
    }
  },
  removeFlag: (flagId: Flag["id"]) => {
    const flags = get().flags
    documentStateActions.save(
      matterApiService.removeFlag({ flagId }).then(() => {
        set({ flags: omit(flags, flagId), currentFlag: null })
      }),
      "There was an error deleting new flag."
    )
  },
  setCurrentFlag: (flagId: Flag["id"]) => {
    const flagById = flagsDataSelectors.getFlagById(flagId)(get())

    set({ currentFlag: { ...flagById } })
  },

  setScrollToFlag: (flag: Nullable<Flag>) => {
    set({ scrollToFlag: flag })
  },
  updateCurrentFlagData: (updatedData: Partial<Flag>) => {
    const currentFlagData = flagsDataSelectors.getCurrentFlag(get())

    set({ currentFlag: { ...currentFlagData, ...updatedData } })
  },
  setFlags: (flags: Record<string, Flag>) => set({ flags }),
})
