import { createContext } from "react"

export interface SortableContextType {
  readonly: boolean

  canDrag: (sourceId: string) => boolean
  canDrop: (targetId: string) => boolean
  canDropAsChild: (targetId: string) => boolean
}

export const SortableContext = createContext<SortableContextType>({
  canDrag: () => true,
  canDrop: () => true,
  canDropAsChild: () => true,

  readonly: false,
})
