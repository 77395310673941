import {
  ALL_ROLES,
  EXTERNAL_ROLES,
  EXTERNAL_ROLES_VALUES,
  INTERNAL_ROLES,
  INTERNAL_ROLES_VALUES,
  OSF,
} from "common/models/roles"
import { User, UserDto } from "common/models/user"
import { isNotOSF } from "common/permission"
import { UploadedFileDto } from "common/types/UploadedFile"
import { ExtendedFirmDto } from "settings/Firm/Firm"
import { REVISION_REQUEST_STATE } from "requests/ViewRequest/Revision/constants"
import { DOWNLOADABLE_DEMAND_PACKAGE_STATUSES, EDITABLE_STATUSES, INTAKE_STATUSES } from "../constants"

export const canUserAssignRequest = (role: ALL_ROLES): boolean => {
  if (INTERNAL_ROLES_VALUES.includes(role)) {
    return true
  }

  return false
}

export const canUserViewAssigneesCell = (role: ALL_ROLES): boolean => {
  return INTERNAL_ROLES_VALUES.includes(role)
}

export const canUserFilterByFirm = (role: ALL_ROLES): boolean => {
  return INTERNAL_ROLES_VALUES.includes(role) || role === OSF
}

export const canUserFilterByAssignee = (role: ALL_ROLES): boolean => {
  return INTERNAL_ROLES_VALUES.includes(role)
}

export const isInternalUser = (role: ALL_ROLES): boolean => {
  return INTERNAL_ROLES_VALUES.includes(role)
}

export const isExternalUser = (role: ALL_ROLES): role is EXTERNAL_ROLES => {
  return EXTERNAL_ROLES_VALUES.includes(role)
}

export const userHasSmartAdvocateCredentials = (user: User): boolean => {
  return Boolean(user.hasSmartAdvocateCredentials)
}

export const canUserSeeFirmCell = isInternalUser

export const canUserSeeSubmitter = isNotOSF
export const canUserSeeDemandPackageDownload = isNotOSF
export const canUserRequestNewDemand = isNotOSF

export const canUserSeeInternalStatus = (role: ALL_ROLES): boolean => {
  return INTERNAL_ROLES_VALUES.includes(role) || role === OSF
}

interface PartialRequest {
  submitter: UserDto
  intake_status: INTAKE_STATUSES
  revised: boolean
}

interface PartialRequestView extends PartialRequest {
  demand_package_files?: UploadedFileDto[]
}

interface PartialRequestListView extends PartialRequest {
  has_demand_package_files: boolean
}

export const canSeeEditRequestButton = (user: User): boolean => {
  return user.role !== OSF
}
export const canUserEditRequest = (user: User, request: PartialRequest): boolean => {
  const role = user.role

  if (role === OSF) {
    return false
  }

  const permissibleRoles: ALL_ROLES[] = [INTERNAL_ROLES.LEGALOPS_ADMIN, INTERNAL_ROLES.LEGALOPS_MANAGER]

  if (permissibleRoles.includes(role)) {
    return true
  }

  const isSubmitter = request.submitter.pk === user.id
  if (INTERNAL_ROLES_VALUES.includes(role) && isSubmitter) {
    return true
  }

  if (request.revised) {
    return false
  }

  if (EDITABLE_STATUSES.includes(request.intake_status)) {
    return true
  }

  return false
}

export const canUserDeleteRequest = (role: ALL_ROLES): boolean => {
  return role === INTERNAL_ROLES.LEGALOPS_ADMIN
}

export const canUserInteractWithDemand = (role: ALL_ROLES): boolean => {
  return INTERNAL_ROLES_VALUES.includes(role)
}

export const canUserViewMissingDocs = isNotOSF

export const canDemandPackageBeDownloaded = (request?: PartialRequestListView): boolean => {
  if (!request) {
    return false
  }

  return (
    DOWNLOADABLE_DEMAND_PACKAGE_STATUSES.includes(request.intake_status) && request.has_demand_package_files
  )
}

export const canMissingDocumentsBeEdited = (request?: PartialRequestView): boolean => {
  if (!request) return false

  return !request.revised && !DOWNLOADABLE_DEMAND_PACKAGE_STATUSES.includes(request.intake_status)
}

export const canUserViewCreditAmount = (role: ALL_ROLES): boolean => {
  return INTERNAL_ROLES_VALUES.includes(role)
}

export const canUserEditCreditAmount = (role: ALL_ROLES): boolean => {
  return role === INTERNAL_ROLES.LEGALOPS_ADMIN
}

export const canUserSeeCreditsUsedOnList = (role: ALL_ROLES, firm: Nullable<ExtendedFirmDto>): boolean => {
  return (
    Boolean(firm?.can_client_view_credits) &&
    Boolean(firm?.current_contract) &&
    EXTERNAL_ROLES_VALUES.includes(role)
  )
}

export const canUserViewRevisions = (role: ALL_ROLES): boolean => {
  return INTERNAL_ROLES_VALUES.includes(role) || EXTERNAL_ROLES_VALUES.includes(role)
}

export const canUserCreateRevisionRequest = (role: ALL_ROLES, request: PartialRequestView): boolean => {
  return (
    canUserViewRevisions(role) &&
    [INTAKE_STATUSES.completed, INTAKE_STATUSES.redelivered].includes(request.intake_status)
  )
}

export const canUserEditRevisionRequest = (role: ALL_ROLES, state: REVISION_REQUEST_STATE): boolean => {
  if (role === OSF) return false

  return (
    INTERNAL_ROLES_VALUES.includes(role) ||
    [REVISION_REQUEST_STATE.REQUESTED, REVISION_REQUEST_STATE.REQUESTED].includes(state)
  )
}

export const canUserCancelRevisionRequest = (role: ALL_ROLES, state: REVISION_REQUEST_STATE): boolean => {
  return canUserEditRevisionRequest(role, state)
}
