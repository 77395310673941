import React, { useEffect, useRef, useState, useCallback, useMemo } from "react"
import Paper from "@mui/material/Paper"
import ListSubheader from "@mui/material/ListSubheader"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import Box, { BoxProps } from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import MoreHoriz from "@mui/icons-material/MoreHoriz"
import InsertDriveFileOutlined from "@mui/icons-material/InsertDriveFileOutlined"
import HelpOutline from "@mui/icons-material/HelpOutline"
import GetApp from "@mui/icons-material/GetApp"
import { downloadExhibit } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { AnnotationDuplicate, AnnotationSplit } from "./AnnotationPartition"
import { comparePageRanges } from "./cacheUtils"
import { EXHIBIT_DELETED_STATUS, STATUS_TO_TEXT } from "./constants"
import DeletePagesBox from "./DeletePagesBox"
import DuplicateBox from "./DuplicateBox"
import ResetDialog from "./ResetDialog"
import SplitBox from "./SplitBox"
import { AnnotationFileIconButton, BoldText, Text } from "./styled"
import {
  AnnotationStatus as AnnotationStatusType,
  PartitionProvider,
  Duplicate,
  Split,
  CaseId,
  Action,
  DeleteAction,
} from "./types"
import { usePublish } from "message-broker/usePublish"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { PreviewExhibitPayload } from "message-broker/types"
import { openWindow } from "common/windows"
import { getExhibitPreviewPath } from "pdf-preview/utils"
import styled from "@emotion/styled"

const DELETED_TOOLTIP_TITLE =
  "This document has been deleted from the client's request and should not be included in the demand."

interface AnnotationStatusProps extends BoxProps {
  status: AnnotationStatusType
  errorDetails: string | null | undefined
}

const DeletedTag: React.FC<BoxProps> = ({ ...boxProps }) => {
  const theme = useTheme()
  const color = theme.palette.annotation[EXHIBIT_DELETED_STATUS.name as AnnotationStatusType]

  return (
    <Box
      borderRadius={`${theme.shape.borderRadius}px`}
      px={1}
      py={0.5}
      bgcolor={color}
      color={theme.palette.common.white}
      {...boxProps}
    >
      <Tooltip placement="bottom" title={DELETED_TOOLTIP_TITLE}>
        <Box display={"flex"}>
          <Text>{EXHIBIT_DELETED_STATUS.display}</Text>
          <Box ml={0.6}>
            <HelpOutline fontSize="inherit" />
          </Box>
        </Box>
      </Tooltip>
    </Box>
  )
}

const AnnotationStatus: React.FC<AnnotationStatusProps> = ({ status, errorDetails, ...boxProps }) => {
  const theme = useTheme()
  const color = theme.palette.annotation[status]

  return (
    <Tooltip title={errorDetails || "No details provided."}>
      <Box
        borderRadius={`${theme.shape.borderRadius}px`}
        px={1}
        py={0.5}
        bgcolor={color}
        color={theme.palette.common.white}
        {...boxProps}
      >
        <Text>{STATUS_TO_TEXT[status]}</Text>
      </Box>
    </Tooltip>
  )
}

interface AdditionalClickableBoxProps {
  clickable: boolean
}

type ClickableBoxProps = AdditionalClickableBoxProps & Omit<BoxProps, keyof AdditionalClickableBoxProps>

const ClickableBox = styled((props: ClickableBoxProps) => {
  const { clickable: _clickable, ...rest } = props
  return <Box {...rest} />
})<AdditionalClickableBoxProps>(({ theme, clickable }) => ({
  display: "flex",
  padding: theme.spacing(1, 2),
  alignItems: "center",
  justifyContent: "space-between",
  "&:hover": {
    backgroundColor: clickable ? theme.palette.action.hover : theme.palette.background.default,
    cursor: clickable ? "pointer" : "default",
  },
}))

const FileName = styled(Typography)({
  maxWidth: "350px",
  overflowWrap: "break-word",
  fontSize: "12px",
})

const FileNameLink = styled("button")({
  border: "none",
  background: "none",
  padding: 0,
  margin: 0,
  font: "inherit",
  cursor: "pointer",
})

const AnnotationFilesDetailsPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1),
  borderTop: `3px solid ${theme.palette.common.black}`,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}))

enum BoxName {
  SPLIT = "split",
  DUPLICATE = "duplicate",
  DELETE = "delete",
}

interface MergePartitionsProps {
  splits: Split[]
  duplicates: Duplicate[]
}

const mergePartitions = ({ splits, duplicates }: MergePartitionsProps): (Split | Duplicate)[] => {
  const merged: (Split | Duplicate)[] = [...splits, ...duplicates].sort(comparePageRanges)
  return merged
}

interface AnnotationFileProps {
  caseId: CaseId
  exhibitId: number
  fileName: string
  numberOfPages: number
  status?: Nullable<AnnotationStatusType>
  clickable?: boolean
  splits: Split[]
  partitionProviders?: PartitionProvider[]
  duplicates: Duplicate[]
  actions: Action[]
  isDeleted: boolean
  errorDetails: string | null | undefined
}

const AnnotationFile: React.FC<AnnotationFileProps> = ({
  caseId,
  exhibitId,
  fileName,
  numberOfPages,
  status,
  errorDetails,
  clickable = true,
  splits,
  partitionProviders,
  duplicates,
  actions,
  isDeleted,
}) => {
  const [downloadingFile, setDownloadingFile] = useState<boolean>(false)
  const [duplicateForDeletions, setDuplicateForDeletions] = useState<Nullable<number>>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const [splittingBoxOpen, setSplittingBoxOpen] = useState(false)
  const [duplicateBoxOpen, setDuplicateBoxOpen] = useState(false)
  const [deleteBoxOpen, setDeleteBoxOpen] = useState(false)
  const splitBoxRef = useRef<HTMLDivElement>(null)
  const duplicateBoxRef = useRef<HTMLDivElement>(null)
  const deleteBoxRef = useRef<HTMLDivElement>(null)
  const { showErrorMessage } = useHandleMessages()
  const { isFeatureEnabled } = useFeatures()
  const isSplittingEnabled = isFeatureEnabled(FEATURES.SPLIT_INTAKE_FILES)

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => setAnchorEl(null)

  const handleBoxOpen = (boxName: BoxName) => {
    if (boxName === BoxName.SPLIT) {
      setDuplicateBoxOpen(false)
      setDeleteBoxOpen(false)
      setSplittingBoxOpen(true)
    }

    if (boxName === BoxName.DUPLICATE) {
      setSplittingBoxOpen(false)
      setDeleteBoxOpen(false)
      setDuplicateBoxOpen(true)
    }

    if (boxName === BoxName.DELETE) {
      setSplittingBoxOpen(false)
      setDuplicateBoxOpen(false)
      setDeleteBoxOpen(true)
    }
  }

  const handleDeleteBoxClose = () => {
    setDeleteBoxOpen(false)
    setDuplicateForDeletions(null)
  }

  const handleIconDownload = () => {
    setDownloadingFile(true)
    downloadExhibit({ caseId, exhibitId, downloadFile: true })
      .catch(error => {
        showErrorMessage({
          message:
            "Failed to download file. Try again shortly and if your problem persists, please report your issue.",
          error,
        })
      })
      .finally(() => setDownloadingFile(false))
  }

  useEffect(() => {
    if (splittingBoxOpen && splitBoxRef.current) {
      splitBoxRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }

    if (duplicateBoxOpen && duplicateBoxRef.current) {
      duplicateBoxRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }

    if (deleteBoxOpen && deleteBoxRef.current) {
      deleteBoxRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }
  }, [splittingBoxOpen, duplicateBoxOpen, deleteBoxOpen])

  const publish = usePublish()

  const handleOpenPreview = useCallback(() => {
    const payload: PreviewExhibitPayload = {
      caseId,
      exhibitId,
    }
    publish(MESSAGE_TOPIC.PREVIEW_EXHIBIT, payload).catch(() => {
      const path = getExhibitPreviewPath(payload)
      if (path) {
        openWindow(`${path.base}/${path.route}`)
      }
    })
  }, [publish, caseId, exhibitId])

  const partitions = useMemo(() => {
    return mergePartitions({ splits, duplicates })
  }, [splits, duplicates])

  const deletions = useMemo(() => {
    return actions.filter(action => action.action_type === "delete") as DeleteAction[]
  }, [actions])

  const activeDuplicate = useMemo(() => {
    if (duplicateForDeletions) {
      return duplicates.find(dup => dup.pk === duplicateForDeletions)
    }
  }, [duplicates, duplicateForDeletions])

  const activeDuplicateDeletions = useMemo(() => {
    return (activeDuplicate?.actions?.filter(action => action.action_type === "delete") ??
      []) as DeleteAction[]
  }, [activeDuplicate])

  return (
    <Box data-test="annotation-file">
      <Paper variant="outlined">
        <ClickableBox clickable={clickable}>
          <Box display="flex" alignItems="center">
            <Box display="flex" mr={4} alignItems="center">
              <Box mr={1}>
                <InsertDriveFileOutlined />
              </Box>
              <FileName>
                {fileName.toLowerCase().endsWith(".pdf") ? (
                  <FileNameLink onClick={handleOpenPreview}>
                    <strong>{fileName}</strong>
                  </FileNameLink>
                ) : (
                  <strong>{fileName}</strong>
                )}
              </FileName>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mr={7}>
              {isDeleted ? (
                <DeletedTag mr={2} />
              ) : (
                status &&
                status !== AnnotationStatusType.COMPLETE && (
                  <AnnotationStatus status={status} errorDetails={errorDetails} mr={2} />
                )
              )}
              {numberOfPages !== null && numberOfPages !== undefined && (
                <BoldText>Total Pages: {numberOfPages}</BoldText>
              )}
            </Box>
            {!isDeleted &&
              (splits.length > 0 ? (
                <Box>
                  <AnnotationFileIconButton onClick={handleMenuOpen}>
                    <MoreHoriz />
                  </AnnotationFileIconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {/* Menu complains about having fragments as children so we do one isSplittingEnabled check for each menu item */}
                    {isSplittingEnabled && (
                      <MenuItem
                        onClick={() => {
                          handleBoxOpen(BoxName.SPLIT)
                          handleMenuClose()
                        }}
                      >
                        {splits.length > 1 ? "Modify Splits" : "Create Splits"}
                      </MenuItem>
                    )}
                    {isSplittingEnabled && (
                      <MenuItem
                        onClick={() => {
                          handleBoxOpen(BoxName.DUPLICATE)
                          handleMenuClose()
                        }}
                      >
                        Create Duplicate
                      </MenuItem>
                    )}
                    {isSplittingEnabled && (
                      <MenuItem
                        onClick={() => {
                          setResetDialogOpen(true)
                          handleMenuClose()
                        }}
                      >
                        Reset Changes
                      </MenuItem>
                    )}
                    {!isSplittingEnabled && <Divider />}
                    {!isSplittingEnabled && <ListSubheader>Coming Soon</ListSubheader>}
                    {!isSplittingEnabled && (
                      <MenuItem disabled onClick={handleMenuClose}>
                        Create Splits
                      </MenuItem>
                    )}
                    {!isSplittingEnabled && (
                      <MenuItem disabled onClick={handleMenuClose}>
                        Create Duplicate Create Duplicate
                      </MenuItem>
                    )}
                    {!isSplittingEnabled && (
                      <MenuItem disabled onClick={handleMenuClose}>
                        Reset Changes
                      </MenuItem>
                    )}
                  </Menu>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" justifyItems="center">
                  {downloadingFile ? (
                    <Box p={1}>
                      <CircularProgress size={24} />
                    </Box>
                  ) : (
                    <AnnotationFileIconButton onClick={handleIconDownload}>
                      <GetApp />
                    </AnnotationFileIconButton>
                  )}
                </Box>
              ))}
          </Box>
        </ClickableBox>
      </Paper>
      {/* we will have excerpts as soon as the labeller submits the document
          but we do not want to display them until the labels are reviewed
          (i.e. status "complete") */}
      {!!partitions.length && (
        <AnnotationFilesDetailsPaper variant="outlined">
          {partitions.map(partition => {
            if ("partition_type" in partition && partition.partition_type === "duplicate") {
              return (
                <AnnotationDuplicate
                  key={partition.pk}
                  plaintiffId={partition?.plaintiff ?? null}
                  {...partition}
                  fileName={fileName}
                  exhibitId={exhibitId}
                  partitionProviders={partitionProviders}
                  caseId={caseId}
                  onDeletePagesClick={partitionId => {
                    setDuplicateForDeletions(partitionId)
                    handleBoxOpen(BoxName.DELETE)
                  }}
                  deletions={
                    (partition.actions?.filter(action => action.action_type === "delete") ??
                      []) as DeleteAction[]
                  }
                  status={status}
                  isDeleted={isDeleted}
                />
              )
            }
            return (
              <AnnotationSplit
                key={partition.pk}
                {...partition}
                fileName={fileName}
                exhibitId={exhibitId}
                partitionProviders={partitionProviders}
                plaintiffId={partition?.plaintiff ?? null}
                caseId={caseId}
                onDeletePagesClick={() => handleBoxOpen(BoxName.DELETE)}
                deletions={deletions}
                status={status}
                isDeleted={isDeleted}
              />
            )
          })}
          {splittingBoxOpen && (
            <SplitBox
              ref={splitBoxRef}
              splits={splits}
              onCancel={() => setSplittingBoxOpen(false)}
              onSuccessfulSave={() => setSplittingBoxOpen(false)}
              exhibitId={exhibitId}
              caseId={caseId}
              numberOfPages={numberOfPages}
            />
          )}
          {duplicateBoxOpen && (
            <DuplicateBox
              ref={duplicateBoxRef}
              onCancel={() => setDuplicateBoxOpen(false)}
              onSuccessfulSave={() => setDuplicateBoxOpen(false)}
              exhibitId={exhibitId}
              caseId={caseId}
              numberOfPages={numberOfPages}
            />
          )}
          {deleteBoxOpen && (
            <DeletePagesBox
              ref={deleteBoxRef}
              deletions={activeDuplicate ? activeDuplicateDeletions : deletions}
              onCancel={handleDeleteBoxClose}
              onSuccessfulSave={handleDeleteBoxClose}
              exhibitId={exhibitId}
              caseId={caseId}
              numberOfPages={numberOfPages}
              duplicate={activeDuplicate}
            />
          )}
        </AnnotationFilesDetailsPaper>
      )}
      <ResetDialog
        open={resetDialogOpen}
        onClose={() => setResetDialogOpen(false)}
        exhibitId={exhibitId}
        fileName={fileName}
        caseId={caseId}
      />
    </Box>
  )
}

export default AnnotationFile
