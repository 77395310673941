import { FetchQueryOptions, QueryClient } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS } from "react-query/constants"

export const prefetchOnHover = (queryClient: QueryClient, queries: FetchQueryOptions[]) => {
  let timer: ReturnType<typeof setTimeout> | null = null
  return {
    onMouseEnter: () => {
      timer = setTimeout(() => {
        if ("requestIdleCallback" in window) {
          requestIdleCallback(() => {
            queries.forEach(query => queryClient.prefetchQuery({ ...SILENT_QUERY_PARAMS, ...query }))
          })
        } else {
          queries.forEach(query => queryClient.prefetchQuery({ ...SILENT_QUERY_PARAMS, ...query }))
        }
      }, 100)
    },
    onMouseLeave: () => {
      if (timer) {
        clearTimeout(timer)
      }
    },
  }
}
