import React, { useState } from "react"
import styled from "@emotion/styled"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined"
import CircularProgress from "@mui/material/CircularProgress"
import { resetDemand } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { queryKeys } from "react-query/constants"

const StyledButton = styled(Button)(({ theme }) => {
  return {
    color: "#000000",
    fontSize: "14px",
    backgroundColor: theme.palette.button.grey,
    width: "150px",
    marginTop: theme.spacing(3),
    fontWeight: 600,
  }
})

interface ResetDemandProps {
  caseId: number
  iconButton?: boolean
}

export function ResetDemand({ caseId, iconButton = false }: ResetDemandProps): Nullable<JSX.Element> {
  const [isResetting, setIsResetting] = useState(false)
  const { showErrorMessage } = useHandleMessages()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const handleReset = async (e: React.MouseEvent) => {
    e.preventDefault()
    setIsResetting(true)
    try {
      await resetDemand(caseId)
      queryClient.invalidateQueries([queryKeys.case])
      navigate("../")
    } catch (error) {
      return showErrorMessage({
        message: "Unable to reset this demand, please try again.",
        error,
      })
    } finally {
      setIsResetting(false)
    }
  }

  if (iconButton) {
    return (
      <Tooltip title="Reset Demand" placement="bottom" arrow>
        <IconButton disabled={isResetting} onClick={handleReset}>
          {isResetting ? (
            <CircularProgress color="secondary" />
          ) : (
            <RestorePageOutlinedIcon color={"action"} />
          )}
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <StyledButton size="small" disabled={isResetting} onClick={handleReset} data-test="reset-demand">
      {isResetting ? "Resetting..." : "Reset Demand"}
    </StyledButton>
  )
}
