import React from "react"
import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { PlaintiffInformationProps } from "../types"
import { InfoContainer } from "./InfoContainer"
import { StyledContentWrapper, StyledDivider, StyledInformationWrapper } from "../styled"
import { useRequestAssets } from "requests/RequestAssets"
import { ASSET_KEYS, ASSET_TYPES, AssetOptionKey } from "requests/constants"
import { Typography } from "@mui/material"
import { getFamilyStatus, getGender, getRole } from "../utils"

export const getDependentStatus = (
  dependantStatus: string[] | string | undefined,
  assets: Partial<Record<(typeof ASSET_TYPES)[number], ValueOptions<AssetOptionKey>>>
) => {
  return Array.isArray(dependantStatus)
    ? assets[ASSET_KEYS.dependentStatus]
        ?.filter(item => dependantStatus?.includes(item.key))
        .map(({ key, display }) => (
          // span because <p> cannot be a child of <p>
          <Typography key={key} component="span" sx={{ display: "block" }}>
            {display}
          </Typography>
        )) ?? "-"
    : "-"
}

export const PlaintiffInformation = ({
  plaintiffs,
  plaintiff_first_name,
  plaintiff_last_name,
}: PlaintiffInformationProps) => {
  const assets = useRequestAssets()

  return (
    <RequestFormModule title="Plaintiff Information">
      <StyledContentWrapper>
        {plaintiffs ? (
          plaintiffs.map((plaintiff, index) => (
            <React.Fragment key={plaintiff.pk}>
              {index > 0 && <StyledDivider />}
              <StyledInformationWrapper>
                <InfoContainer field="First Name" value={plaintiff.first_name} />
                <InfoContainer field="Last Name" value={plaintiff.last_name} />
                <InfoContainer field="Role" value={getRole(plaintiff.role)} />
                <InfoContainer field="Pronoun" value={getGender(plaintiff.pronoun)} />
                <InfoContainer field="State" value={plaintiff.state_of_residence} />
                <InfoContainer
                  field="Family Status"
                  value={getFamilyStatus(assets, plaintiff.family_status)}
                />
                <InfoContainer
                  field="Dependent Status"
                  value={getDependentStatus(plaintiff.dependent_status || "", assets)}
                />
                <InfoContainer field="Is a Minor" value={plaintiff.is_minor ? "Yes" : "No"} />
              </StyledInformationWrapper>
            </React.Fragment>
          ))
        ) : (
          <>
            <InfoContainer field="First Name" value={plaintiff_first_name ?? "-"} />
            <InfoContainer field="Last Name" value={plaintiff_last_name ?? "-"} />
          </>
        )}
      </StyledContentWrapper>
    </RequestFormModule>
  )
}
