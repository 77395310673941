import Box from "@mui/material/Box"
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress"

type CircularProgressWithLabelProps = CircularProgressProps & {
  value: number
}

export function CircularProgressWithLabel({ value, ...props }: CircularProgressWithLabelProps): JSX.Element {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {`${Math.floor(value)}%`}
      </Box>
    </Box>
  )
}
