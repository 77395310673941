import { IntakeStatusOption, IntakeStatusOptions } from "../requestStatusProperties"
import { INTAKE_STATUSES } from "../../constants"

export function disableStatusOptions(
  statusOptions: Partial<IntakeStatusOptions>,
  currentStatus: INTAKE_STATUSES,
  disabledStatuses?: INTAKE_STATUSES[]
): Partial<IntakeStatusOptions> {
  const statuses = disabledStatuses || (Object.keys(statusOptions) as INTAKE_STATUSES[])

  return statuses.reduce<Partial<IntakeStatusOptions>>((options, status) => {
    if (status === currentStatus) {
      return options
    }

    const statusOption: IntakeStatusOption | undefined = options[status]

    if (!statusOption) return options

    const updatedStatusOption: IntakeStatusOption = { ...statusOption, disabled: true }
    const nextOptions = { ...options, [status]: updatedStatusOption }

    return nextOptions
  }, statusOptions)
}
