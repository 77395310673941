import { forwardRef } from "react"
import Button, { ButtonProps } from "@mui/material/Button"
import { makeStyles } from "tss-react/mui"
import getTextColorFromTheme, { ButtonColor } from "./getTextColorFromTheme"

const useStyles = makeStyles<{ textColor: ButtonColor }>()((theme, { textColor }) => ({
  addButton: {
    color: getTextColorFromTheme({ theme, textColor }),
    letterSpacing: "0.3px",
    fontWeight: "bold",
  },
}))

export interface TextButtonProps extends ButtonProps, TestableComponentProps {
  textColor?: ButtonColor
}

const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(function TextButton(
  { children, className, textColor, ...buttonProps },
  ref
): JSX.Element {
  const { classes, cx } = useStyles({ textColor: textColor ?? "blue" })
  return (
    <Button className={cx(classes.addButton, className)} {...buttonProps} ref={ref}>
      {children}
    </Button>
  )
})

export default TextButton
