import { InputFieldSkeleton, FindingItemSkeleton } from "."
import { ObjectiveTestEditableRow, ObjectiveTestFindingsEditableList } from "../styled"

const ObjectiveTestItemSkeleton = () => (
  <ObjectiveTestEditableRow>
    <InputFieldSkeleton />
    <InputFieldSkeleton />
    <ObjectiveTestFindingsEditableList>
      <FindingItemSkeleton />
    </ObjectiveTestFindingsEditableList>
  </ObjectiveTestEditableRow>
)

export default ObjectiveTestItemSkeleton
