import { BaseEditor } from "slate"
import { FlagsEditor, WithFlags } from "./types"
import { Flags } from "./Flags"

export function withFlags<T extends BaseEditor>(editor: T): ReturnType<WithFlags<T>> {
  const editorWithFlags = editor as T & FlagsEditor

  editorWithFlags.flags = new Flags()

  return editorWithFlags
}
