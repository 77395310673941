import React, { PropsWithChildren } from "react"
import { makeStyles } from "tss-react/mui"
import Box from "@mui/material/Box"

const useStyles = makeStyles()(theme => ({
  tabContent: {
    padding: theme.spacing(2),
  },
}))

interface TabProps {
  className: string
  title: string
}

export const Tab: React.FC<PropsWithChildren<TabProps>> = ({ className, children }) => {
  const { classes, cx } = useStyles()

  return <Box className={cx(classes.tabContent, className)}>{children}</Box>
}
