import { StatusProperties } from "./interfaces"

export const buildPropertiesObject = (
  fontColor: string,
  backgroundColor: string,
  display: string
): StatusProperties => {
  return {
    fontColor,
    backgroundColor,
    display,
  }
}

export const getColourByImportance = (importanceLevel: string): string => {
  const HIGH = "#FF0000"
  switch (importanceLevel) {
    case "high":
      return HIGH
    case "medium":
      return "#FA7E02"
    case "low":
      return "#0C76f7"
    default:
      return HIGH
  }
}
