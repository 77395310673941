import { useCallback } from "react"
import { ExhibitContainer } from "exhibit-builder/Exhibit/ExhibitContainer"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { UserExhibit } from "exhibit-builder/store/types"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { CollapseButton } from "./styled"
import { useShallow } from "zustand/react/shallow"
import { CollapsedDisplayName } from "./CollapsedDisplayName"
import { UserExhibitInfo } from "./UserExhibitInfo"
import { ActionPopupButton } from "../ActionPopup/ActionPopupButton"
import { useUserExhibitPreview } from "exhibit-builder/useUserExhibitPreview"

export function UserExhibitContent({ id }: { id: UserExhibit["id"] }) {
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const userExhibit = useExhibitBuilderStore(useShallow(filesSelectors.getUserExhibitById(id)))
  const handleRename = useCallback(
    (nextFileName: string) => exhibitBuilderActions.updateUserExhibit({ id, name: nextFileName }),
    [id]
  )
  const collapsed = useExhibitBuilderStore(useShallow(state => state.collapseStates[id]))
  const openPreview = useUserExhibitPreview(id)

  const handleCollapse = useCallback(() => {
    exhibitBuilderActions.setCollapseState(id, !collapsed)
  }, [id, collapsed])

  return (
    <ExhibitContainer
      editable={!collapsed && !isReadOnly}
      displayName={collapsed ? <CollapsedDisplayName id={id} /> : userExhibit.name}
      exhibitName={userExhibit.name}
      index={userExhibit.index}
      onUserExhibitRename={handleRename}
      onPreview={() => openPreview()}
      exhibitReadyToBeViewed={userExhibit.processingStatus === "complete"}
      renderControls={() => (
        <>
          {collapsed ? <UserExhibitInfo id={id} /> : !isReadOnly && <ActionPopupButton id={id} />}
          <CollapseButton
            data-test="exhibit-collapse-button"
            size="small"
            onClick={handleCollapse}
            style={{
              transform: !collapsed ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            <KeyboardArrowDownIcon />
          </CollapseButton>
        </>
      )}
    />
  )
}
