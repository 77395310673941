import { DocumentStructureDefinition } from "common/types/documentStructure"
import { DOCUMENT_STRUCTURE_ITEM_TYPE } from "../../enums"
import { DocumentStructureSection, DocumentStructureBlock } from "../../types"
import { DocumentStructureStoreData } from "../types"
import { isNil, isUndefined, omitBy } from "lodash"

export function updateDocumentStructureElement(
  state: DocumentStructureStoreData,
  id: string,
  data: DocumentStructureSection | DocumentStructureBlock,
  shouldExist = true
): DocumentStructureStoreData {
  const nextState = { ...state }
  const { children: _children, ...elementData } = omitBy(data, isUndefined) as Partial<
    DocumentStructureSection | DocumentStructureBlock
  >

  if (elementData.type === DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION) {
    if (!state.sections[id] && shouldExist) return state

    nextState.sections[id] = {
      ...nextState.sections[id],
      ...(elementData as DocumentStructureSection),
      REF_ID: id,
    }
  } else {
    if (!state.blocks[id] && shouldExist) return state

    nextState.blocks[id] = {
      ...nextState.blocks[id],
      ...(elementData as DocumentStructureBlock),
      REF_ID: id,
    }
  }

  return nextState
}

export function updateDocumentStructureItem(
  state: DocumentStructureStoreData,
  id: string,
  data: Partial<DocumentStructureDefinition>
): DocumentStructureStoreData {
  const item = state.items[id]
  const dataToUpdate = Object.fromEntries(Object.entries(data).filter(([, value]) => !isUndefined(value)))

  if (!item) return state

  const nextState = { ...state }
  nextState.items[id] = {
    ...item,
    ...dataToUpdate,
    isDraft: !isNil(data.isDraft) ? data.isDraft : true,
  }

  return nextState
}
