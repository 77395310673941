import {
  ASSET_KEYS,
  COMMERCIAL_POLICY_COVERAGE_TYPES,
  INDIVIDUAL,
  INDIVIDUAL_POLICY_COVERAGE_TYPES,
  PLAINTIFF_ROLE_SELECT_OPTIONS,
  POLICY_TYPE_OPTIONS,
  PRONOUN_SELECT_OPTIONS,
} from "requests/constants"
import { PlaintiffPronoun, PlaintiffRole } from "../types"
import { AssetsContextType } from "requests/RequestAssets/context"
import { FILE_CATEGORY_DISPLAY_OPTIONS, FILE_CATEGORY_OPTIONS } from "common/constants"

export const getGender = (gender: Nullable<PlaintiffPronoun>) =>
  PRONOUN_SELECT_OPTIONS.find(({ key }) => key === gender)?.display ?? "-"

export const getRole = (role: Nullable<PlaintiffRole>): string =>
  PLAINTIFF_ROLE_SELECT_OPTIONS.find(({ key }) => key === role)?.display ?? "-"

export const getFamilyStatus = (assets: AssetsContextType, familyStatus?: Nullable<string>): string => {
  return assets[ASSET_KEYS.familyStatus]?.find(({ key }) => key === familyStatus)?.display ?? "-"
}

export const getFileType = (type: FILE_CATEGORY_OPTIONS) => {
  const fileType = Object.entries(FILE_CATEGORY_DISPLAY_OPTIONS).find(([key]) => key === type)
  return fileType ? fileType[1] : "-"
}

export const getPolicyCoverageType = (polictyType: string, policyCoverageType: string) => {
  const coverageTypes =
    polictyType === INDIVIDUAL ? INDIVIDUAL_POLICY_COVERAGE_TYPES : COMMERCIAL_POLICY_COVERAGE_TYPES

  return coverageTypes.find(({ key }) => key === policyCoverageType)?.display ?? "-"
}

export const getPolicyType = (policyType: string) =>
  POLICY_TYPE_OPTIONS.find(({ key }) => key === policyType)?.display ?? "-"
