import styled from "@emotion/styled"
import isValidProp from "@emotion/is-prop-valid"
import { Button } from "@mui/material"
import { blue } from "@mui/material/colors"

export const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2.5),
  marginLeft: `calc(-${theme.spacing(1)} + 2px)`, // accounts for button "invisible padding" when aligning with other items on page
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingBottom: theme.spacing(1),
}))

export const StyledButton = styled(Button, {
  shouldForwardProp: prop => isValidProp(prop) || prop === "startIcon",
})<{ isActive?: boolean; target?: string }>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.blue.primary : theme.palette.grey[600],
  transition: "color 0.2s",
  overflow: "hidden",
  minWidth: 0,

  "&:hover": {
    color: blue[600],
  },
  "&:active": {
    color: theme.palette.blue.primary,
  },
}))

export const ActionContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
}))
