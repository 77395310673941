import { ReferencePage } from "common/types/providers"

const buildReferenceEntityKey = ({
  pageNumber,
  exhibitId,
  partitionId,
}: Pick<ReferencePage, "pageNumber" | "exhibitId" | "partitionId">) => {
  if (exhibitId) {
    return `exhibitId:${exhibitId}-${pageNumber}`
  }

  if (partitionId) {
    return `partitionId:${partitionId}-${pageNumber}`
  }

  return ""
}

export const getReferenceDuplicatesValidationErrors = (references: ReferencePage[]) => {
  const pageCountMap = references.reduce<Record<string, number>>(
    (acc, { exhibitId, partitionId, pageNumber }) => {
      if (!pageNumber) return acc

      const key = buildReferenceEntityKey({ pageNumber, exhibitId, partitionId })
      if (!key) return acc

      if (!acc[key]) acc[key] = 0
      acc[key]++

      return acc
    },
    {}
  )

  const duplicates = references.filter(({ exhibitId, partitionId, pageNumber }) => {
    if (!pageNumber) return false

    const key = buildReferenceEntityKey({ pageNumber, exhibitId, partitionId })

    if (!key) return

    return pageCountMap[key] > 1
  })

  return duplicates.map(duplicate => ({
    referencePageId: duplicate.id!,
    message: "Duplicate reference page found",
  }))
}
