import { createParagraph, createSoftLineBreak } from "../../../create"
import { InlineContent, ParagraphElement, StyledText } from "../../../CustomEditor"
import { INLINE_ELEMENTS } from "../../../elements"
import { isTextNode, isVariableNode } from "../../../queries"
import { isSoftLineBreakNode } from "../../../queries/inlines"
import { TEXT_STYLES } from "../../../styles"

interface StyleNode {
  styles: Set<TEXT_STYLES>
  text: string
  children: StyleNode[]
}

function addStyleNode(node: StyleNode, styles: Set<TEXT_STYLES>, text: string): void {
  let styleList = [...styles]
  let styleNode = node

  while (styleList.length && styleNode.children.length) {
    const childNode = styleNode.children[styleNode.children.length - 1]
    const commonStyles = new Set(styleList.filter(style => childNode.styles.has(style)))

    if (!commonStyles.size) break

    styleList = styleList.filter(style => !commonStyles.has(style))
    styleNode = childNode

    if (
      styleNode.styles.size !== commonStyles.size ||
      [...styleNode.styles].some(style => !commonStyles.has(style))
    ) {
      styleNode.children = [
        {
          text: styleNode.text,
          children: styleNode.children,
          styles: new Set([...styleNode.styles].filter(style => !commonStyles.has(style))),
        },
      ]
      styleNode.text = ""
      styleNode.styles = commonStyles
    }
  }

  styleNode.children.push({ text, styles: new Set(styleList), children: [] })
}

const STYLE_TO_TAG: Record<TEXT_STYLES, string> = {
  [TEXT_STYLES.BOLD]: "b",
  [TEXT_STYLES.ITALIC]: "i",
  [TEXT_STYLES.HIGHLIGHT]: "mark",
  [TEXT_STYLES.UNDERLINE]: "u",
}

function styleToTag(style: TEXT_STYLES, content: string): string {
  const tag = STYLE_TO_TAG[style]
  return tag ? `<${tag}>${content}</${tag}>` : content
}

function styleNodeToHtml(node: StyleNode): string {
  let html = node.text

  for (const child of node.children) {
    html += styleNodeToHtml(child)
  }

  for (const style of node.styles) {
    html = styleToTag(style, html)
  }

  return html
}

function getStyles(style: StyledText): Set<TEXT_STYLES> {
  const stylesSet = new Set<TEXT_STYLES>()

  for (const styleKey of Object.values(TEXT_STYLES)) {
    if (style[styleKey]) {
      stylesSet.add(styleKey)
    }
  }

  return stylesSet
}

export function getParagraphHtml(paragraph: ParagraphElement, isInList = false): string {
  const styleRoot: StyleNode = { styles: new Set(), text: "", children: [] }

  for (const child of paragraph.children) {
    if (isSoftLineBreakNode(child)) {
      addStyleNode(styleRoot, new Set(), "<br />")
    } else if (isVariableNode(child) || isTextNode(child)) {
      const text = isTextNode(child) ? child.text : `<var key="${child.name}"></var>`
      const styles = getStyles(child)

      addStyleNode(styleRoot, styles, text)
    }
  }

  const content = styleNodeToHtml(styleRoot)

  if (isInList) {
    return `<li>${content}</li>`
  }

  return `<p>${content}</p>`
}

export function omitVariableValues(html: string): string {
  const variablesRegex = /(<var key=".*?">).*?(<\/var>)/gi
  return html.replaceAll(variablesRegex, "$1$2")
}

export function omitBlockTags(html: string): string {
  const blockTagsRegex = /<\/?(?:ul|ol|li|p)>/gi
  return html.replaceAll(blockTagsRegex, "")
}

const TAG_TO_STYLE: Record<string, TEXT_STYLES> = {
  b: TEXT_STYLES.BOLD,
  u: TEXT_STYLES.UNDERLINE,
  i: TEXT_STYLES.ITALIC,
  mark: TEXT_STYLES.HIGHLIGHT,
}

function getTextNodesFromHtml(html: string, style: StyledText = {}): InlineContent[] {
  const nodes: InlineContent[] = []

  let i = 0
  let node: InlineContent = { text: "", ...style }

  nodes.push(node)

  while (i < html.length) {
    if (html[i] !== "<") {
      node.text += html[i]
      i++
      continue
    }

    const part = html.slice(i)
    const tag =
      ["b", "i", "mark", "u"].find(tag => part.startsWith(`<${tag}>`)) ||
      (part.startsWith('<var key="') && "var")

    if (!tag) {
      node.text += html[i]
      i++
      continue
    }

    const idx = part.indexOf(`</${tag}>`)

    if (idx === -1) {
      i += tag.length + 2
      continue
    }

    i += idx + 3 + tag.length

    if (tag === "var") {
      const variableName = part.replace('<var key="', "").replace(/".+?$/, "")
      nodes.push({ type: INLINE_ELEMENTS.VARIABLE, name: variableName, children: [{ text: "" }], ...style })
      node = { text: "", ...style }
      nodes.push(node)
    } else {
      const tagStyle = TAG_TO_STYLE[tag]
      nodes.push(...getTextNodesFromHtml(part.slice(tag.length + 2, idx), { ...style, [tagStyle]: true }))
      node = { text: "", ...style }
      nodes.push(node)
    }
  }

  return nodes
}

export function getParagraphNodesFromHtml(html: string): ParagraphElement {
  const paragraph = createParagraph([])
  const [firstLine, ...lines] = html.split("<br />")

  paragraph.children.push(...getTextNodesFromHtml(firstLine))

  for (const line of lines) {
    paragraph.children.push(createSoftLineBreak())
    paragraph.children.push(...getTextNodesFromHtml(line))
  }

  return paragraph
}

type HtmlBlocks<T extends string | Array<string>> = {
  originalBlocks: T
  suggestedBlocks: T
}

export function getHtmlBlocks(
  originalHtml: string,
  suggestedHtml: string
): HtmlBlocks<string> | HtmlBlocks<string[]> {
  const multilineRegex = /<\/(p|li|ul|ol)><(p|li|ul|ol)>/gi
  const isMultilineHtml = multilineRegex.test(originalHtml)

  if (!isMultilineHtml) {
    return {
      originalBlocks: omitBlockTags(omitVariableValues(originalHtml)),
      suggestedBlocks: omitBlockTags(omitVariableValues(suggestedHtml)),
    }
  }

  const lineSplitRegex = /(?<=[^^])<(?:li|p)>/gi
  const originalLines = originalHtml
    .split(lineSplitRegex)
    .map(line => omitBlockTags(omitVariableValues(line)))
  const suggestedLines = suggestedHtml
    .split(lineSplitRegex)
    .map(line => omitBlockTags(omitVariableValues(line)))

  return {
    originalBlocks: originalLines.filter((line, idx) => line !== suggestedLines[idx]),
    suggestedBlocks: suggestedLines.filter((line, idx) => line !== originalLines[idx]),
  }
}
