import { useCallback, useMemo, useState } from "react"
import { StyledToolbarGroup } from "../styled"
import { Editor } from "common/form-components/rich-text/Editor"
import { Editor as WithFlagsEditor } from "common/form-components/rich-text/features/flags/Editor"
import TextButton from "common/buttons/TextButton"
import { useSlate } from "slate-react"
import { FlagPopover } from "documents/flags/FlagPopover"
import { Flag } from "documents/flags/types"
import { isEqual, noop } from "lodash"
import { Range } from "slate"

export const ADD_FLAG_BUTTON = "add_flag_button"

export function AddFlagButton({
  isFlagsEnabled,
}: {
  isFlagsEnabled: Nullable<boolean>
}): Nullable<JSX.Element> {
  const editor = useSlate()
  const [flagData, setFlagData] = useState<Partial<Flag>>({})
  const [saving, setSaving] = useState(false)
  const [flagSelector, setFlagSelector] = useState<Nullable<typeof ADD_FLAG_BUTTON>>(null)
  const disabled = !isFlagsEnabled

  const onFlagDataChanged = useCallback(
    (updatedFlagData: Partial<Flag>) => {
      const result = { ...flagData, ...updatedFlagData }

      if (!isEqual(result, flagData)) setFlagData(result)
    },
    [flagData]
  )

  const handleInsertFlag = useCallback(() => {
    const quote =
      editor.selection && !Range.isCollapsed(editor.selection)
        ? Editor.string(editor, editor.selection)
        : undefined

    setFlagData({ quote })
    setFlagSelector(ADD_FLAG_BUTTON)
  }, [editor])

  const onClose = useCallback(() => {
    setFlagData({})
    setFlagSelector(null)
    setSaving(false)
  }, [setFlagData])

  const onSave = useCallback(() => {
    const callback = (flag: Flag) => {
      WithFlagsEditor.insertFlag(editor, flag.id)
      onClose()
    }

    if (Editor.isFlagsEditor(editor)) {
      setSaving(true)
      editor.onCreateFlag(flagData, callback)
    }
  }, [onClose, editor, flagData])

  return useMemo(() => {
    if (!Editor.isFlagsEditor(editor)) {
      return null
    }

    return (
      <StyledToolbarGroup>
        <FlagPopover
          flagSelector={flagSelector}
          currentFlagData={flagData}
          onFlagDataChanged={onFlagDataChanged}
          onSave={onSave}
          onClose={onClose}
          onDeleteFlag={noop}
          saving={saving}
        />
        <TextButton
          aria-controls="categories-list"
          id={ADD_FLAG_BUTTON}
          size="small"
          sx={{ textTransform: "none" }}
          onClick={handleInsertFlag}
          disabled={disabled}
        >
          + Add Flag
        </TextButton>
      </StyledToolbarGroup>
    )
  }, [editor, flagData, flagSelector, disabled, handleInsertFlag, onFlagDataChanged, onSave, onClose, saving])
}
