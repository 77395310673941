import React from "react"
import { variablesService } from "api/services/variables"
import { Control } from "react-hook-form"
import { useQuery } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { UnstableRichTextField } from "../../common/form-components/rich-text"
import { FirmData } from "./Firm"
import Placeholders from "./Placeholders"
import { Variable } from "common/types/variables"
import { usePermissions } from "permissions/usePermissions"

export type FirmDemandDataFields = Partial<FirmData> &
  Pick<
    FirmData,
    "demand_introduction" | "demand_conclusion" | "demand_introduction_json" | "demand_conclusion_json"
  >

interface FirmDemandProps {
  control: Control<FirmDemandDataFields>
}

export const FirmDemandEditorFields: React.FC<FirmDemandProps & { variables: Variable[] }> = ({
  control,
  variables,
}) => {
  const { introductionConclusionTemplatesEnabled } = usePermissions()

  return (
    <>
      <UnstableRichTextField
        control={control}
        name="demand_introduction_json"
        label="Firm-specific demand introduction"
        helperText={
          introductionConclusionTemplatesEnabled
            ? "The Demand introduction section can be managed via library templates or customized per demand"
            : ""
        }
        withVariables
        variables={variables}
      />
      <UnstableRichTextField
        control={control}
        name="demand_conclusion_json"
        label="Firm-specific demand conclusion"
        helperText={
          introductionConclusionTemplatesEnabled
            ? "The Demand conclusion section can be managed via library templates or customized per demand"
            : ""
        }
        withVariables
        variables={variables}
      />
    </>
  )
}

export const FirmDemand: React.FC<FirmDemandProps> = ({ control }) => {
  const { data: variables } = useQuery(
    [queryKeys.defaultVariables],
    variablesService.getDefaultCaseVariables,
    SILENT_QUERY_PARAMS
  )

  return (
    <>
      <Placeholders />
      <FirmDemandEditorFields control={control} variables={variables ?? []} />
    </>
  )
}
