import { useCallback, useContext } from "react"
import { CollapseContext } from "common/CollapseManager/context"
import styled from "@emotion/styled"
import { Button } from "@mui/material"

export const StyledCollapseButton = styled(Button)(({ theme }) => ({
  letterSpacing: "-0.17px",
  fontWeight: "normal",
  color: theme.palette.blue.primary,
}))

export function CollapseButton({ onClick }: { onClick?: (collapse: boolean) => void }): JSX.Element {
  const { collapse, expand } = useContext(CollapseContext)

  const handleExpand = useCallback(() => {
    expand()
    onClick?.(false)
  }, [expand, onClick])

  const handleCollapse = useCallback(() => {
    collapse()
    onClick?.(true)
  }, [collapse, onClick])

  return (
    <>
      <StyledCollapseButton onClick={handleExpand}>+ Expand all</StyledCollapseButton>
      <StyledCollapseButton onClick={handleCollapse}>— Collapse all</StyledCollapseButton>
    </>
  )
}
