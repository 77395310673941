import { InputField } from "common/form-components"
import { RequestFormModule } from "./RequestFormModule"
import { StyledEntityWrapper, StyledFormBox } from "./styled"
import { useRequestContext } from "requests/context"
import { PlaintiffTabSelector } from "requests/RequestForm/PlaintiffTabSelector"
import { Box } from "@mui/material"
import { useFormContext } from "react-hook-form"

export const PlaintiffAdditionalInformation = () => {
  const { requestId } = useRequestContext()
  const { control } = useFormContext()

  if (!requestId) {
    return <></>
  }

  return (
    <RequestFormModule title="Additional Information">
      <StyledEntityWrapper>
        <StyledFormBox gridColumns="1fr">
          <InputField
            control={control}
            size="small"
            name="additional_information"
            type="text"
            label="Additional Information"
            multiline
            variant="outlined"
          />
          <InputField
            control={control}
            size="small"
            name="case_facts"
            type="text"
            label="Facts"
            multiline
            variant="outlined"
          />
        </StyledFormBox>

        <PlaintiffTabSelector>
          {plaintiffIndex => (
            <Box display="grid" gap={4} marginTop={2}>
              <InputField
                control={control}
                size="small"
                name={`plaintiffs.${plaintiffIndex}.ongoing_complaints`}
                type="text"
                label="Plaintiff's ongoing complaints"
                multiline
                variant="outlined"
              />

              <InputField
                control={control}
                size="small"
                name={`plaintiffs.${plaintiffIndex}.future_treatments`}
                type="text"
                label="Future Treatments"
                multiline
                variant="outlined"
              />
            </Box>
          )}
        </PlaintiffTabSelector>
      </StyledEntityWrapper>
    </RequestFormModule>
  )
}
