import { SortableNestedListItemProps } from "common/nested-list/SortableNestedListItem"
import { ITEM_REF } from "common/nested-list/constants"
import { PropsWithChildren } from "react"
import { UserExhibitItem } from "./UserExhibit/UserExhibitItem"
import { ExhibitPartitionItem } from "./ExhibitPartition/ExhibitPartitionItem"
import { RecordOrBillItem } from "./ExhibitPartition/RecordOrBillItem"
import { ProjectionItemWithChildren } from "common/nested-list/types"
import {
  DndUserExhibitListData,
  DndExhibitPartitionData,
  DndUserExhibitData,
} from "exhibit-builder/store/types"
import { VirtualList } from "./VirtualList"

export function UserExhibitListItem({
  item,
  ...props
}: PropsWithChildren<SortableNestedListItemProps<DndUserExhibitListData>>) {
  const itemData = item[ITEM_REF]

  switch (itemData.type) {
    case "virtualList":
      return <VirtualList item={item} {...props} />
    case "userExhibit":
      return (
        <UserExhibitItem
          id={itemData.id}
          item={item as ProjectionItemWithChildren<DndUserExhibitData>}
          {...props}
        />
      )
    case "exhibitPartition":
      return (
        <ExhibitPartitionItem item={item as ProjectionItemWithChildren<DndExhibitPartitionData>} {...props} />
      )
    case "recordOrBill":
      return (
        <RecordOrBillItem
          id={itemData.id}
          userExhibitId={itemData.userExhibitId}
          partitionId={itemData.partitionId}
        />
      )
    default:
      return null
  }
}
