import { GetState, SetState } from "./types"
import { AuthorizedUser, NotAuthorizedUser } from "common/models/user"

export type UserSlice = {
  user: Nullable<AuthorizedUser | NotAuthorizedUser>
}

export const userSlice: UserSlice = {
  user: null,
}

export const userSelectors = {
  getUser: () => (state: UserSlice) => state.user,
}

// eslint-disable-next-line
export const userActions = (set: SetState<UserSlice>, _: GetState<UserSlice>) => ({
  setUser: (user: AuthorizedUser | NotAuthorizedUser) => set({ user }),
})
