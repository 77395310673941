import { useCallback, useContext, useState } from "react"
import { CASE_SECTIONS } from "common/types/sections"
import { StyledViewTemplatesButton } from "settings/Library/Filters/styled"
import { LibraryTabStateContext } from "settings/Library/State/constants"
import { useForm } from "react-hook-form"
import { createRenderPageActionAction } from "settings/Library/State/templatesActions"
import { DocumentStructureCurrentFirmId } from "settings/Library/Tabs/DocumentStructure"
import { useQuery } from "@tanstack/react-query"
import CancelIcon from "@mui/icons-material/Cancel"
import { queryKeys } from "react-query/constants"
import { CircularProgress } from "@mui/material"
import { sectionTemplateService } from "api/services/section-template"
import { capitalize, isNil } from "lodash"
import { SectionTemplateDefinition } from "common/types/templates"

import { DocumentStructureTemplate } from "./types"
import { REF_ID } from "./constants"
import { SectionContext } from "./section/context"
import { DialogButton } from "../../buttons/DialogButton"
import { StyledBlockTitle, StyledBlockWrapper, StyledDeleteButtonWrapper, StyledBlockSelect } from "./styled"
import { FIRMS_SECTION_TEMPALATES } from "settings/Library/Tabs/constants"

function isTemplateAvailable(
  section: Nullable<CASE_SECTIONS>,
  templates?: Record<string, SectionTemplateDefinition[]>
) {
  if (!templates) return false

  if (!section) return Object.keys(templates).some(value => value.length)

  return templates[section] ? templates[section].length : false
}

interface DocumentStructureTemplateBlockProps {
  onSave?: (section: DocumentStructureTemplate, sectionId: Nullable<PrimaryKey>) => Promise<void>
  onDelete?: (section: DocumentStructureTemplate, sectionId: Nullable<PrimaryKey>) => Promise<void>
  item: DocumentStructureTemplate
}

const sectionOptions = Object.values(CASE_SECTIONS).map(section => ({
  key: section,
  display: capitalize(section),
}))

export function DocumentStructureTemplateBlock({
  onDelete,
  onSave,
  item,
}: DocumentStructureTemplateBlockProps): JSX.Element {
  const { dispatch } = useContext(LibraryTabStateContext)
  const { sectionId } = useContext(SectionContext)
  const { currentFirmId } = useContext(DocumentStructureCurrentFirmId)
  const [isDeleting, setIsDeleting] = useState(false)
  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
    watch,
    control,
    reset,
  } = useForm({
    defaultValues: item,
    mode: "all",
  })
  const templateType = watch("templateType")
  const handleViewTemplates = useCallback(() => {
    dispatch(
      createRenderPageActionAction({ page: `${FIRMS_SECTION_TEMPALATES}?templateType=${templateType}` })
    )
  }, [dispatch, templateType])

  const { data: templates } = useQuery(
    [queryKeys.sectionTemplatesByFirm, currentFirmId],
    () => sectionTemplateService.getTemplatesListByFirm({ firmId: currentFirmId }),
    {
      enabled: !isNil(currentFirmId),
      refetchOnMount: false,
      keepPreviousData: true,
    }
  )

  const handleSave = handleSubmit(async template => {
    if (!onSave || !isDirty) return

    const templateData: DocumentStructureTemplate = {
      ...template,
      [REF_ID]: item[REF_ID],
    }

    if (item.id !== null) {
      templateData.id = item.id
    }

    await onSave(templateData, sectionId)
    reset({}, { keepValues: true })
  })

  const handleDelete = useCallback(() => {
    if (!onDelete) return

    setIsDeleting(true)
    onDelete(item, sectionId)
  }, [item, onDelete, sectionId])

  return (
    <StyledBlockWrapper onBlur={handleSave}>
      <StyledBlockTitle>Template</StyledBlockTitle>
      <StyledBlockSelect<DocumentStructureTemplate, DocumentStructureTemplate["templateType"]>
        disabled={isSubmitting || !onSave}
        size="small"
        name="templateType"
        control={control}
        label={isNil(templateType) ? "Select template" : undefined}
        options={sectionOptions}
      />
      {templates &&
        (isTemplateAvailable(templateType, templates) ? (
          <StyledViewTemplatesButton onClick={handleViewTemplates} dense>
            Firm Template Available
          </StyledViewTemplatesButton>
        ) : (
          <>
            <i>No firm template available.</i>
            <StyledViewTemplatesButton onClick={handleViewTemplates} dense>
              Add Template
            </StyledViewTemplatesButton>
          </>
        ))}
      {onDelete && (
        <StyledDeleteButtonWrapper>
          <DialogButton
            title="Delete?"
            icon={isDeleting ? <CircularProgress size={24} /> : <CancelIcon />}
            confirmationText="Yes, Delete"
            question={
              <span>
                Are you sure you want to delete
                <br />
                <strong>the component placeholder?</strong>
              </span>
            }
            onAction={handleDelete}
            disabled={isDeleting}
          />
        </StyledDeleteButtonWrapper>
      )}
    </StyledBlockWrapper>
  )
}
