import { ALL_ROLES, INTERNAL_ROLES } from "../../common/models/roles"

export const canRoleDeleteFirm = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
}

export const canRoleDeleteAttorney = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
}

export const canRoleSeeDownloadContracts = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
}

export const canRoleManageContracts = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
}

export const canRoleEditFirmManagers = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole || INTERNAL_ROLES.LEGALOPS_MANAGER === userRole
}

export const canRoleEditFirmHubspotId = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole || INTERNAL_ROLES.LEGALOPS_MANAGER === userRole
}

export const canRoleSetBasicPlusCreationOption = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
}

export const canRoleSetAutoAnnotation = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
}

export const canRoleSetManualAnnotation = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
}

export const canRoleSetIsInternal = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
}

export const canCreateNewContract = (userRole: ALL_ROLES): boolean => {
  return INTERNAL_ROLES.LEGALOPS_ADMIN === userRole
}
