import React, { useCallback, useContext } from "react"
import { RenderElementProps } from "slate-react"
import { CitationElement } from "../CustomEditor"
import { CitationsContext } from "../features/citations/CitationsContext"
import { ExhibitCitation } from "common/citation/ExhibitCitation"

interface CitationProps extends RenderElementProps {
  element: CitationElement
}

export const Citation: React.FC<CitationProps> = React.memo(function Citation({
  attributes,
  element,
  children,
}) {
  const { references, onClick } = useContext(CitationsContext)
  const index = references.findIndex(reference => reference.id === element.exhibit)

  const handleClick = useCallback(
    (page: Nullable<number>) => {
      onClick(element, page)
    },
    [element, onClick]
  )

  return (
    <span {...attributes} data-citation data-pages={element.pages} data-exhibit={element.exhibit}>
      {children}
      <ExhibitCitation index={index} pages={element.pages} onClick={handleClick} />
    </span>
  )
})
