import { captureMessage as sentryCaptureMessage } from "@sentry/browser"

export interface MessageProps {
  message: string
  level: "info" | "warning" | "error"
  extras?: Record<string, unknown>
}

function captureMessage({ message, level, extras }: MessageProps) {
  sentryCaptureMessage(message, {
    level,
    extra: extras,
  })
}

export function info(args: Omit<MessageProps, "level">) {
  captureMessage({ ...args, level: "info" })
}

export function warning(args: Omit<MessageProps, "level">) {
  captureMessage({ ...args, level: "warning" })
}

export function error(args: Omit<MessageProps, "level">) {
  captureMessage({ ...args, level: "error" })
}
