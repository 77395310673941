import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { ClearButton } from "./ClearButton"
import { BaseBoxProps } from "./types"
import { theme } from "app/theme"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BaseBox = ({ isClearable, children, ...props }: BaseBoxProps) => <Box {...props}>{children}</Box>

export const MultiSelectAutoCompleteWrapper = styled(BaseBox)<{ isClearable: boolean }>(
  ({ isClearable }) => ({
    fontSize: 13,
    [`&:hover ${ClearButton}`]: {
      visibility: isClearable ? "visible" : "hidden",
    },
    ".MuiInputAdornment-root": {
      color: theme.palette.text.primary,
    },
  })
)
