import React from "react"

import { FormSectionListItem, FormSectionListItemIcon, FormSectionListItemText } from "./styled"
import { Link } from "react-router-dom"
import { useTheme } from "@emotion/react"

interface ILeftNavCaseSection {
  label: string
  toLink: string
  dataTest: string
  Icon?: React.ElementType
}

export const LeftNavLink = ({ label, toLink, dataTest, Icon }: ILeftNavCaseSection): React.ReactElement => {
  const theme = useTheme()

  const isActive = location.pathname.includes(toLink)

  return (
    <FormSectionListItem component={Link} to={toLink} data-test={dataTest} active={isActive}>
      {Icon && (
        <FormSectionListItemIcon>
          <Icon sx={{ fontSize: theme.spacing(2.5) }} />
        </FormSectionListItemIcon>
      )}
      <FormSectionListItemText inset={!Icon} primary={label} />
    </FormSectionListItem>
  )
}
