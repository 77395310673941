import React from "react"
import { RenderLeafProps } from "slate-react"
import { useSuggestionAttributes } from "./hooks/useSuggestionAttributes"
import { useFlagAttributes } from "./hooks/useFlagAttributes"

export const Leaf: React.FC<RenderLeafProps> = React.memo(function Leaf({ attributes, leaf, children }) {
  const suggestionAttributes = useSuggestionAttributes(leaf)
  const flagAttributes = useFlagAttributes(leaf)

  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.highlight) {
    children = (
      <span style={{ background: "yellow" }} data-highlight>
        {children}
      </span>
    )
  }

  if (flagAttributes || suggestionAttributes) {
    children = (
      <span {...flagAttributes} {...suggestionAttributes} data-test="flag-leaf">
        {children}
      </span>
    )
  }

  return <span {...attributes}>{children}</span>
})
