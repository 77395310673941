import { StyledButtonBox, StyledTextButton, StyledTitleTypography } from "./styled"
import { STEP_CODES } from "requests/RequestForm/constants"
import { useRequestContext } from "../../../context"
import { Box, Typography } from "@mui/material"
import { theme } from "app/theme"
import { REQUEST_FORM_HEADER_DATA } from "./constants"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import { useWatch } from "react-hook-form"
import { RequestFields } from "requests/RequestForm/types"

export const RequestFormHeader = () => {
  const { setActiveStep } = useRequestContext()
  const type = useWatch<RequestFields, "type">({ name: "type" })
  const { additionTitleText, titleText, title } = REQUEST_FORM_HEADER_DATA[type]

  return (
    <>
      <StyledButtonBox>
        <StyledTextButton
          color="secondary"
          startIcon={<ChevronLeft />}
          variant="outlined"
          onClick={() => setActiveStep(STEP_CODES.DOCUMENT_TYPE)}
        >
          Edit Document Type
        </StyledTextButton>
      </StyledButtonBox>
      <Box sx={{ marginBottom: theme.spacing(2.5) }}>
        <StyledTitleTypography>{title}</StyledTitleTypography>
        <Typography sx={{ fontSize: theme.typography.pxToRem(20) }}>{titleText}</Typography>
        <Typography sx={{ fontSize: theme.typography.pxToRem(16) }}>{additionTitleText}</Typography>
      </Box>
    </>
  )
}
