import { isVariableHTMLNode } from "./queries/variables"

export const IFRAME_ID = "styles-iframe-id"

export const DEFAULT_STYLES = { textDecoration: "" } as CSSStyleDeclaration

export class StylesIFrame {
  private iFrameElement: null | HTMLIFrameElement

  constructor() {
    this.iFrameElement = null
  }

  private get body() {
    if (!this.iFrameElement || !this.iFrameElement.contentDocument) {
      throw Error("StylesIFrame: iframe should be mounted before getting acces to the body")
    }

    return this.iFrameElement.contentDocument.body
  }

  private get head() {
    if (!this.iFrameElement || !this.iFrameElement.contentDocument) {
      throw Error("StylesIFrame: iframe should be mounted before getting acces to the head")
    }

    return this.iFrameElement.contentDocument.head
  }

  private get getComputedStyle() {
    if (!this.iFrameElement || !this.iFrameElement.contentWindow) {
      throw Error("StylesIFrame: iframe should be mounted before getting acces to the getComputedStyle")
    }

    return this.iFrameElement.contentWindow.getComputedStyle
  }

  get bodyChildrenAsArray(): Element[] {
    return Array.from(this.body.children)
  }

  private createAndMountIFrame(): HTMLIFrameElement {
    const iFrameElement = document.createElement("iframe")

    iFrameElement.id = IFRAME_ID
    iFrameElement.style.height = "0px"
    iFrameElement.style.maxWidth = "100%"
    iFrameElement.style.position = "absolute"
    iFrameElement.style.visibility = "hidden"

    this.iFrameElement = iFrameElement

    document.body.append(iFrameElement)

    return iFrameElement
  }

  applyDOM(dom: Document): void {
    if (!this.iFrameElement) {
      this.createAndMountIFrame()
    }

    this.head.innerHTML = dom.head.innerHTML
    this.body.innerHTML = dom.body.innerHTML

    if (!this.body.children.length) {
      const rootParagraph = this.iFrameElement?.contentDocument?.createElement("p")

      if (rootParagraph) {
        rootParagraph?.append(...this.body.childNodes)
        this.body.append(rootParagraph)
      }
    }
  }

  getNodeStyles(node: HTMLElement): CSSStyleDeclaration {
    if (isVariableHTMLNode(node)) {
      const variableClass = Array.from(node.classList).reduce(
        (classes, currentClass) => `${classes}.${currentClass}`,
        ""
      )

      if (!variableClass) return DEFAULT_STYLES

      const variableNode = window.document.querySelector(variableClass)

      return variableNode ? window.getComputedStyle(variableNode) : DEFAULT_STYLES
    }

    const getComputedStyle = this.getComputedStyle

    return getComputedStyle(node)
  }

  clear(): void {
    this.head.innerHTML = ""
    this.body.innerHTML = ""
  }
}

export const stylesIFrame = new StylesIFrame()
