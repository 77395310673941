import styled from "@emotion/styled"
import { CircularProgress, FormControl, outlinedInputClasses } from "@mui/material"

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "240px",
  margin: theme.spacing(4, "auto"),

  label: {
    color: theme.palette.blue.primary,
  },

  [`& .${outlinedInputClasses.input}`]: {
    color: theme.palette.blue.primary,
  },

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.blue.primary,
  },
}))

export const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  left: `calc(100% + ${theme.spacing(1)})`,
  top: theme.spacing(1),
}))
