import Box from "@mui/material/Box"

import { useStyles } from "../styled"

import { LineListPassage } from "./types"
import { Entry } from "../types"

interface LineEntryListProps<T> {
  data: T[]
  title: string
  separator: string
  onEntryClick: (entry: Entry) => void
}

const LineEntryList = ({
  data,
  title,
  separator,
  onEntryClick,
}: LineEntryListProps<LineListPassage>): JSX.Element => {
  const { classes } = useStyles()
  if (!data.length) return <></>

  return (
    <Box display={"flex"}>
      <Box display={"flex"} flexDirection="row" flexWrap="wrap">
        <Box fontWeight={"bold"}>{title}&nbsp;</Box>
        {data.map((record: LineListPassage, index) => {
          const { value, entries } = record
          return (
            <Box
              display={"flex"}
              key={value}
              className={classes.entry}
              onClick={() => {
                onEntryClick(entries[0])
              }}
            >
              {value}
              {index !== data.length - 1 && <Box>{separator}&nbsp;</Box>}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export { LineEntryList as default }
