import { Appointment, APPOINTMENT_TAG_ACTIONS, AppointmentTag } from "documents/types"
import { DocumentData, GetState, SetState } from "./types"
import { documentsService } from "api/services/documents"
import { documentStateActions } from "./documentState"
import { debounce, pick } from "lodash"
import { GENERATED_SUMMARY_STATUSES } from "api/services/documents/types"
import { AuthorizedUser, NotAuthorizedUser } from "common/models/user"

export type AppointmentsSlice = Pick<DocumentData, "appointments"> & {
  currentAppointment?: Nullable<Appointment["id"]>
}

export const appointmentsSlice: AppointmentsSlice = {
  appointments: {},
  currentAppointment: null,
}

export const appointmentsSelectors = {
  getAppointmentById: (id: Appointment["id"]) => (state: AppointmentsSlice) => state.appointments[id],
  getAppointmentFields:
    <T extends keyof Appointment>(id: Appointment["id"], fields: T[]) =>
    (state: AppointmentsSlice) =>
      pick<Appointment, T>(appointmentsSelectors.getAppointmentById(id)(state), ...fields),
  getAppointments: (state: AppointmentsSlice) => Object.values(state.appointments),

  getAppointmentTagsForDisplay: (id: Appointment["id"]) => (state: AppointmentsSlice) => {
    const appointment = appointmentsSelectors.getAppointmentById(id)(state)

    appointment.tags.sort((a, b) => {
      const aDate = new Date(a.createdAt)
      const bDate = new Date(b.createdAt)
      const aSec = aDate.getTime()
      const bSec = bDate.getTime()
      return bSec - aSec
    })

    return appointment.tags
  },
  getAppointmentCompletedStatus: (appointmentId: Appointment["id"]) => (state: AppointmentsSlice) => {
    const tags = appointmentsSelectors.getAppointmentTagsForDisplay(appointmentId)(state)

    let completed: boolean = false

    for (const tag of tags) {
      if (tag.action === APPOINTMENT_TAG_ACTIONS.MARK_COMPLETED) {
        completed = true
        break
      }

      if (tag.action === APPOINTMENT_TAG_ACTIONS.MARK_INCOMPLETE) {
        break
      }
    }

    return completed
  },
  getAppointmentReviewTags: (appointmentId: Appointment["id"]) => (state: AppointmentsSlice) => {
    const tags = appointmentsSelectors.getAppointmentTagsForDisplay(appointmentId)(state)
    return tags.filter((tag: AppointmentTag) => tag.action === APPOINTMENT_TAG_ACTIONS.MARK_REVIEWED)
  },
}

interface AppointmentPayload {
  appointmentId: Appointment["id"]
}

interface AppointmentProviderPayload extends AppointmentPayload {
  providerId: Appointment["providerId"]
}

interface AppointmentDatePayload extends AppointmentPayload {
  dateOfService: Appointment["dateOfService"]
}

interface AppointmentTimePayload extends AppointmentPayload {
  timeOfService: Appointment["timeOfService"]
}

interface AppointmentSummaryPayload extends AppointmentPayload {
  summary: NonNullable<Appointment["summary"]>
}

interface AppointmentTagPayload {
  appointmentId: Appointment["id"]
  action: APPOINTMENT_TAG_ACTIONS
  user: AuthorizedUser | NotAuthorizedUser
}

export const appointmentsActions = (set: SetState<AppointmentsSlice>, get: GetState<AppointmentsSlice>) => {
  const setAppointment = (appointment: Appointment) => {
    set(({ appointments }) => ({
      appointments: { ...appointments, [appointment.id]: appointment },
    }))
  }
  const setAppointmentData = (appointmentId: Appointment["id"], data: Partial<Appointment>) => {
    const appointment = appointmentsSelectors.getAppointmentById(appointmentId)(get())
    setAppointment({ ...appointment, ...data })
  }
  const setAppointmentAsync = <T>(
    appointmentId: Appointment["id"],
    data: Partial<Appointment>,
    request: Promise<T>
  ) => {
    const appointment = appointmentsSelectors.getAppointmentById(appointmentId)(get())
    setAppointment({ ...appointment, ...data })
    request.catch(() => setAppointment(appointment))
    return request
  }
  const setAppointmentProvider = ({ appointmentId, providerId }: AppointmentProviderPayload) => {
    return documentStateActions.save(
      setAppointmentAsync(
        appointmentId,
        { providerId },
        documentsService.setAppointmentProvider({ appointmentId, providerId })
      ),
      "There was an error assigning provider to appointment."
    )
  }
  const setAppointmentDate = ({ appointmentId, dateOfService }: AppointmentDatePayload) => {
    return documentStateActions.save(
      setAppointmentAsync(
        appointmentId,
        { dateOfService },
        documentsService.setAppointmentDate({ appointmentId, dateOfService })
      ),
      "There was an error saving appointment date of service."
    )
  }

  const setAppointmentTime = ({ appointmentId, timeOfService }: AppointmentTimePayload) => {
    return documentStateActions.save(
      setAppointmentAsync(
        appointmentId,
        { timeOfService },
        documentsService.setAppointmentTime({ appointmentId, timeOfService })
      ),
      "There was an error saving appointment time of service."
    )
  }

  const setAppointmentSummary = debounce(({ appointmentId, summary }: AppointmentSummaryPayload) => {
    return documentStateActions.save(
      setAppointmentAsync(
        appointmentId,
        { summary },
        documentsService.setAppointmentSummary({ appointmentId, summary })
      ),
      "There was an error saving appointment summary."
    )
  }, 500)
  const revertAppointmentSummary = ({ appointmentId }: AppointmentPayload) => {
    return documentStateActions.save(
      setAppointmentAsync(
        appointmentId,
        { summary: null, generatedSummaryStatus: GENERATED_SUMMARY_STATUSES.COMPLETED },
        documentsService.revertAppointmentSummary({ appointmentId })
      ),
      "There was an error reverting appointment summary."
    )
  }
  const regenerateAppointmentSummary = ({ appointmentId }: AppointmentPayload) => {
    return documentStateActions.save(
      setAppointmentAsync(
        appointmentId,
        { generatedSummaryStatus: GENERATED_SUMMARY_STATUSES.PENDING },
        documentsService.regenerateAppointmentSummary({ appointmentId })
      ),
      "There was an error regenerating the appointment summary."
    )
  }
  const addAppointmentTag = ({ appointmentId, action, user }: AppointmentTagPayload) => {
    const currentTags = appointmentsSelectors.getAppointmentById(appointmentId)(get()).tags
    const newTag: AppointmentTag = {
      action,
      createdAt: new Date(),
      createdBy: {
        pk: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      },
    }

    return documentStateActions.save(
      setAppointmentAsync(
        appointmentId,
        { tags: [...currentTags, newTag] },
        documentsService.addAppointmentTag({ appointmentId, data: { action } })
      ),
      "There was an error adding appointment tag."
    )
  }
  const setCurrentAppointment = (appointmentId: Nullable<Appointment["id"]>) =>
    set({ currentAppointment: appointmentId })

  return {
    setAppointment,
    setAppointmentData,
    setAppointmentProvider,
    setAppointmentDate,
    setAppointmentTime,
    setAppointmentSummary,
    revertAppointmentSummary,
    setCurrentAppointment,
    regenerateAppointmentSummary,
    addAppointmentTag,
  }
}
