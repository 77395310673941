import { queryKeys as defaultQueryKeys } from "react-query/constants"
import { getUserSession } from "api"
import { AuthorizedUser, NotAuthorizedUser, UserDto } from "common/models/user"
import { create } from "zustand"
import { subscribeWithSelector } from "zustand/middleware"
import { queryClient } from "react-query/queryClient"

interface GetUserResponse {
  data: {
    user: UserDto
  }
}

export class NotAuthorizedError extends Error {
  message = "User is not authorized"
}

async function getUser(): Promise<AuthorizedUser | NotAuthorizedUser> {
  const request = getUserSession() as Promise<Nullable<GetUserResponse>>

  return request
    .then(response => {
      if (response === null) {
        throw new NotAuthorizedError()
      }

      const responseUser = response.data.user
      if (!responseUser?.pk || responseUser.pk < 0) {
        throw new NotAuthorizedError()
      }

      return AuthorizedUser.fromJSON(response.data.user)
    })
    .catch(() => new NotAuthorizedUser())
}

interface UserStore {
  user: AuthorizedUser | NotAuthorizedUser
  isLoaded: boolean
  error: boolean
  fetchPromise: Nullable<Promise<void>>
  refetchInterval: Nullable<number>
}

const defaultUserStoreData: UserStore = {
  user: new NotAuthorizedUser(),
  isLoaded: false,
  error: false,
  fetchPromise: null,
  refetchInterval: null,
}

export const useUserStore = create(subscribeWithSelector<UserStore>(() => defaultUserStoreData))

export const actions = {
  reset: (resetState?: Partial<Pick<UserStore, "user" | "isLoaded" | "error">>) => {
    queryClient.removeQueries([defaultQueryKeys.session])
    useUserStore.setState({
      ...defaultUserStoreData,
      ...resetState,
    })
  },
  setUser: (user: AuthorizedUser | NotAuthorizedUser) => {
    const state = useUserStore.getState()

    if (!state.user.isEqual(user)) {
      return useUserStore.setState({ user, isLoaded: true, error: false })
    }

    return useUserStore.setState({ ...state, isLoaded: true })
  },
  setError: () => {
    const state = useUserStore.getState()

    useUserStore.setState({
      user: state.user.isAuthorized ? new NotAuthorizedUser() : state.user,
      error: true,
      isLoaded: false,
    })
  },
  fetchUser: async () => {
    const currentPromise = useUserStore.getState().fetchPromise

    if (currentPromise) return currentPromise

    const fetchPromise = getUser()
      .then(user => {
        actions.setUser(user)
      })
      .catch(actions.setError)
      .finally(() => {
        useUserStore.setState({ fetchPromise: null })
      })

    useUserStore.setState({
      fetchPromise,
    })

    return fetchPromise
  },
}

export const resetUserState = actions.reset
