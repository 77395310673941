import { createReducer } from "common/state/createReducer"
import { FILE_UPLOAD_STATE_TRANSITIONS } from "./transitions"
import { FILE_UPLOAD_UPDATERS } from "./updaters"
import { FILE_UPLOAD_ACTIONS } from "./actions"

export const fileUploadReducer = createReducer(
  FILE_UPLOAD_STATE_TRANSITIONS,
  FILE_UPLOAD_UPDATERS,
  FILE_UPLOAD_ACTIONS.FAIL
)
