import { ReactNode, useEffect } from "react"
import { documentStateActions } from "documents/store/documentState"
import { usePageStore } from "common/pages/pageStore"
import { documentActions, useDocumentStore } from "documents/store"
import { createPortal } from "react-dom"
import { BASE_OUTER_PAGE_ID } from "common/pages/BasePage"
import { useSuspenseQuery } from "@suspensive/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { matterApiService } from "api/services/matter"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useDocumentContext } from "documents/context"
import { DocumentSkeleton } from "documents/skeletons/DocumentSkeleton"
import { GenericErrorForSuspense } from "common"
import { withSuspense } from "common/withSuspense"
import { MedChronFilters } from "../MedChronFilters"
import { BasicInfo } from "./components/BasicInfo"
import { VIEW_MED_CHRON_SECTIONS, VIEW_MED_CHRON_SECTION_KEYS } from "./constants"
import { ViewMedChronSection } from "./ViewMedChronSection"
import { FilteredSectionsLinks } from "./components/FilteredSectionsLinks"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"
import { documentsService } from "api/services/documents"
import { DocumentStructureComponent } from "api/services/documents/types"
import { PREMIUM_MEDCHRON_PRODUCT_TYPE } from "common/interfaces"

export const ViewMedicalChronology = withSuspense(
  (): ReactNode => {
    const { documentId } = useDocumentContext()
    const queryClient = useQueryClient()
    const scrollToElementId = useDocumentStore(state => state.scrollToElementId)

    const { data: flagsData } = useSuspenseQuery(
      [queryKeys.flags, documentId],
      () => matterApiService.getFlags({ documentId }),
      { meta: SILENT_QUERY_PARAMS.meta }
    )

    const { data: contentSections } = useSuspenseQuery(
      [queryKeys.documentStructureContentComponents, documentId],
      () => {
        return documentsService.getDocumentStructureOrder({ documentId: documentId })
      }
    )

    const { data: tileData } = useQuery([queryKeys.documentMedchronTile, documentId], () => {
      if (documentId) {
        return documentsService.getMedchronTile({ documentId: documentId })
      }
    })

    const questionnaireId = useDocumentStore(store => store.questionnaireId)

    useEffect(() => {
      documentActions.setFlags(flagsData)
    }, [flagsData])

    useEffect(() => {
      return () => {
        requestAnimationFrame(() => {
          queryClient.removeQueries([queryKeys.flags, documentId])
        })
      }
    }, [queryClient, documentId])

    useEffect(() => {
      if (!scrollToElementId) return

      const section = document.getElementById(scrollToElementId)

      if (!section) return

      documentActions.setScrollToElementId(null)

      // handled in the virtualized list component <ProviderSummaryTable>
      if (scrollToElementId === VIEW_MED_CHRON_SECTIONS.EXHIBIT_LIST) return

      section.scrollIntoView({ behavior: "smooth", block: "start" })
    }, [scrollToElementId])

    useEffect(() => {
      if (!tileData) return

      let title = "MedChron"

      if (tileData.questionnaireType === PREMIUM_MEDCHRON_PRODUCT_TYPE) {
        title = `Premium ${title}`
        documentStateActions.setTitleTags(["Nurse Reviewed", "Flags"])
      }
      documentStateActions.setTitle(title)
      documentStateActions.setShowExtraButtons(false)
      documentStateActions.setShowAnnotationButton(false)
      documentStateActions.setShowPlaintiff(false)
      usePageStore.setState({
        showBack: true,
        backTitle: "Back to Request",
        backHref: questionnaireId ? `/requests/${questionnaireId}` : "/requests",
      })

      amplitudeApm.trackEvent(
        new MedicalChronologyAnalyticEvent(
          MedicalChronologyAnalyticsEventTypes.WebViewMedicalChronologyViewed,
          { document_id: documentId, request_id: questionnaireId, documentType: tileData.questionnaireType }
        )
      )

      return () => {
        documentStateActions.unsetTitle()
        documentStateActions.setTitleTags([])
      }
    }, [documentId, questionnaireId, tileData])

    const portalSource = document.getElementById(BASE_OUTER_PAGE_ID)

    if (!portalSource) return null

    return (
      <>
        {createPortal(
          <MedChronFilters filterEmptyProviders>
            <FilteredSectionsLinks contentSections={contentSections} />
          </MedChronFilters>,
          portalSource
        )}
        <BasicInfo />
        {contentSections
          ? contentSections.map((section: DocumentStructureComponent) => {
              const sectionType = VIEW_MED_CHRON_SECTION_KEYS[section.componentKey]
              if (!sectionType) return null
              return <ViewMedChronSection key={sectionType} type={sectionType} />
            })
          : Object.values(VIEW_MED_CHRON_SECTIONS).map(sectionType => (
              <ViewMedChronSection key={sectionType} type={sectionType} />
            ))}
      </>
    )
  },
  <DocumentSkeleton />,
  <GenericErrorForSuspense />
)
