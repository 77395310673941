import { Box, IconButton, Popover, Typography } from "@mui/material"
import { theme } from "app/theme"
import { documentStoreSelectors, useDocumentStore } from "documents/store"
import { useShallow } from "zustand/react/shallow"
import Close from "@mui/icons-material/Close"
import { StyledLegendProvider } from "./styled"
import { MedicronCalendar } from "documents/exhibits/MedicronCalendar/MedicronCalendar"
import { useCallback, useState } from "react"
import { MedicronCalendarAppointment } from "documents/exhibits/MedicronCalendar/types"
import { formatDate } from "utils"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"
import { TreatmentCalendarDateProviderData } from "./TreatmentCalendarDateProviderData"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { documentsService } from "api/services/documents"

const getAppointmentDateTitle = (appointmentDate?: Date) => {
  if (!appointmentDate) return ""

  const month = appointmentDate.toLocaleString("en-us", { month: "long" })
  const monthAndDay = formatDate(appointmentDate, "dd, yyyy", true)

  return `${month} ${monthAndDay}`
}

export const TreatmentCalendar = (): JSX.Element => {
  const documentId = useDocumentStore(state => state.documentId)
  const requestId = useDocumentStore(state => state.questionnaireId)
  const filteredProviders = useDocumentStore(useShallow(documentStoreSelectors.getFilteredProvider))
  const appointmentData = useDocumentStore(useShallow(documentStoreSelectors.getAppointmentMedicronData))
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null)
  const [currentAppointments, setCurrentAppointments] = useState<MedicronCalendarAppointment[]>([])
  const providersData = useDocumentStore(
    useShallow(
      documentStoreSelectors.providersDataByAppointmentIds(
        currentAppointments.map(({ appointmentId }) => appointmentId)
      )
    )
  )

  const { data: medchronTile } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => {
      if (documentId) {
        return documentsService.getMedchronTile({ documentId: documentId })
      }
    },
    {
      enabled: !!documentId,
    }
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setCurrentAppointments([])
  }, [])

  const handleCalendarDayClick = useCallback<
    (appointments: MedicronCalendarAppointment[], element: HTMLElement) => void
  >(
    (appointments, element) => {
      if (!medchronTile) return
      amplitudeApm.trackEvent(
        new MedicalChronologyAnalyticEvent(
          MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyDateInCalendarClicked,
          { document_id: documentId, request_id: requestId, documentType: medchronTile.questionnaireType }
        )
      )
      setAnchorEl(element)
      setCurrentAppointments(appointments)
    },
    [documentId, requestId, medchronTile]
  )

  return (
    <>
      <MedicronCalendar
        appointmentData={appointmentData}
        hideLegend
        onCalendarDayClick={handleCalendarDayClick}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{ paper: { sx: { borderRadius: "12px" } } }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Box p={2} pr={1}>
          <Box display="flex" justifyContent="space-between" width="240px" alignItems="center" mb={0.5}>
            <Typography fontWeight={600} fontSize="16px">
              {getAppointmentDateTitle(currentAppointments?.[0]?.appointmentDate)}
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Box width="240px">
            {providersData.map(providerData => (
              <TreatmentCalendarDateProviderData
                key={providerData.id}
                providerData={providerData}
                onDateClick={handleClose}
              />
            ))}
          </Box>
        </Box>
      </Popover>

      <Typography fontSize="16px" fontWeight={700} mb={2.5}>
        Legend:
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {filteredProviders.map(({ name, color, id }) => (
          <StyledLegendProvider
            key={id}
            style={{ backgroundColor: color, color: theme.palette.getContrastText(color) }}
          >
            {name}
          </StyledLegendProvider>
        ))}
      </Box>
    </>
  )
}
