import { PropsWithChildren, useContext, useEffect, useMemo, useRef } from "react"
import { variablesService } from "api/services/variables"
import { Variable } from "common/types/variables"
import { useIsFetching, useIsMutating, useQuery, useQueryClient } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { CaseVariablesContext, CaseVariablesContextType } from "./context"

interface CaseVariablesProviderProps {
  caseId: PrimaryKey
  plaintiffId?: PrimaryKey
}

export function CaseVariablesProvider({
  caseId,
  plaintiffId,
  children,
}: PropsWithChildren<CaseVariablesProviderProps>): JSX.Element {
  const { variables: defaultVariables } = useContext(CaseVariablesContext)
  const queryClient = useQueryClient()
  const isFetching = useIsFetching({ queryKey: [queryKeys.steps] })
  const isMutating = useIsMutating({ mutationKey: [queryKeys.steps] })
  const hasActiveRequests = isFetching || isMutating
  const previousCaseVariables = useRef<Variable[]>()

  const { data: caseVariables, refetch: refetchCaseVariables } = useQuery(
    [queryKeys.caseVariables, caseId, plaintiffId],
    () => variablesService.getCaseVariables({ caseId, plaintiffId }),
    { ...SILENT_QUERY_PARAMS, staleTime: 0 }
  )

  const value: CaseVariablesContextType = useMemo(
    () => ({
      variables: caseVariables ?? defaultVariables,
      refetchCaseVariables,
    }),
    [defaultVariables, caseVariables, refetchCaseVariables]
  )

  useEffect(() => {
    if (previousCaseVariables.current && !hasActiveRequests) {
      queryClient.invalidateQueries([queryKeys.caseVariables])
    }

    if (!previousCaseVariables.current && caseVariables && !hasActiveRequests) {
      previousCaseVariables.current = caseVariables
    }
  }, [queryClient, previousCaseVariables, caseVariables, hasActiveRequests])

  return <CaseVariablesContext.Provider value={value}>{children}</CaseVariablesContext.Provider>
}
