import {
  addMinutes,
  subDays,
  subMonths,
  format,
  formatDistance,
  parse,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns"
import { ordinalSuffixOf } from "./utils"

export type ISODate = Flavor<string, "ISODate"> // YYYY-MM-DD
export type ISODateTimeMicros = Flavor<string, "ISODateTimeMicros"> // YYYY-MM-DDTHH:mm:ss.ssssssZ

export const formatTimeSinceNow = (timestamp: number | string): string => {
  return formatDistance(new Date(timestamp), new Date(), { addSuffix: true })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidDate(date: any): date is Date {
  return date instanceof Date && !isNaN(Number(date))
}

export const formatDate = (
  timestamp?: Nullable<number | string | Date>,
  formatString = "MMM do, yyy",
  ignoreLocalTimezone = false
): string => {
  const NOT_APPLICABLE = "N/A"
  if (!timestamp) {
    return NOT_APPLICABLE
  }

  const date = new Date(timestamp)
  if (ignoreLocalTimezone && !dateIsMidnight(date)) {
    return format(addMinutes(date, date.getTimezoneOffset()), formatString)
  }

  return format(date, formatString) ?? NOT_APPLICABLE
}

export const dateFormat = new Intl.DateTimeFormat("en-US")
export const timeFormat = new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
})

export const formatDateWithTime = (
  timestamp: Nullable<number | string | Date>,
  formatString = "MMM do, yyy",
  ignoreLocalTimezone = false
): string => {
  const NOT_APPLICABLE = "N/A"
  if (!timestamp) {
    return NOT_APPLICABLE
  }

  const formattedDate = formatDate(timestamp, formatString, ignoreLocalTimezone)
  const parsedTimestamp = typeof timestamp === "string" ? parseInt(timestamp) : timestamp
  const formattedTime = timeFormat.format(parsedTimestamp).replace(/\s/g, "").toLowerCase()

  return `${formattedDate} ${formattedTime}`
}

export const formatDateWithSuffix = (timestamp: number | string): string => {
  const formattedDate = formatDate(timestamp, "dd", true)
  const day = Number(formattedDate)
  return ordinalSuffixOf(day)
}

export const dateDisplay = (dateString?: Nullable<string>, defaultValue = "mm/dd/yyyy"): string => {
  if (!dateString) return defaultValue
  const date = parse(dateString, "yyyy-MM-dd", new Date())
  return date.toLocaleDateString()
}

/**
 * Returns the last day of the given month by year
 * @param {int} year - Year value
 * @param {int} month - Month value - NOTE: Month is offset by 1 (Jan = 0, Feb = 1, ...)
 * @returns int - day of the month
 */
export const getLastDayOfMonth = (year: number, month: number): number => {
  return new Date(year, month + 1, 0).getDate()
}

export const dateIsMidnight = (date: Date): boolean => {
  return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0
}

// 2022-12-25 --> // 12/25/2022
export const toUSDateString = (input: Nullable<string | string[]>): string => {
  const internationalDateStr = Array.isArray(input) ? input.join("-") : input
  const [year, month, day] = (internationalDateStr ?? "").split("-")
  return `${month}/${day}/${year}`
}

export const getStartOfPreviousMonth = (date: Date): Date => {
  return startOfMonth(subMonths(date, 1))
}

export const getEndOfPreviousMonth = (date: Date): Date => {
  return endOfMonth(subMonths(date, 1))
}

export const getStartOfPreviousWeek = (date: Date): Date => {
  return startOfWeek(subDays(date, 7))
}

export const getEndOfPreviousWeek = (date: Date): Date => {
  return endOfWeek(subDays(date, 7))
}
