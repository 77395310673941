import { Transforms, NodeEntry, Editor, Path, Node } from "slate"
import { v4 } from "uuid"
import { NODE_ID_FIELD_NAME } from "../constants"
import { CustomEditor } from "../CustomEditor"
import { getEditorRange } from "../queries"

export function assignIdToNode(editor: CustomEditor, at: Path): string {
  const id = v4()
  Transforms.setNodes(editor, { [NODE_ID_FIELD_NAME]: id }, { at })

  return id
}

export function getNodeById(editor: CustomEditor, id: string): NodeEntry<Node> | undefined {
  if (editor.selection) {
    const [nodeEntry] = Editor.nodes(editor, {
      at: editor.selection,
      match: node => (node as unknown as Record<string, unknown>)[NODE_ID_FIELD_NAME] === id,
    })

    if (nodeEntry) return nodeEntry
  }

  const [nodeEntry] = Editor.nodes(editor, {
    at: getEditorRange(editor),
    match: node => (node as unknown as Record<string, unknown>)[NODE_ID_FIELD_NAME] === id,
  })

  return nodeEntry
}

export function unassignIdFromNode(editor: CustomEditor, id: string): void {
  const nodeEntry = getNodeById(editor, id)

  if (!nodeEntry) return

  Transforms.setNodes(editor, { [NODE_ID_FIELD_NAME]: undefined }, { at: nodeEntry[1] })
}
