import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { getAuthHeaders } from "apiHelper"
import { Document, pdfjs } from "react-pdf"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import Hidden from "@mui/material/Hidden"
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api"
import { PdfLoadingPage } from "./PdfLoadingPage"
import { Alert, PagesWrapper, PageWrapper, PdfContainer, PdfDocumentContainer, PdfViewport } from "./styled"
import { PdfErrorPage } from "./PdfErrorPage"
import { PdfThumbnails } from "./Thumbnails"
import { PdfDocument } from "./PdfDocument"
import { PdfNavigationPanel } from "./PdfNavigationPanel"
import { PdfToolbar } from "./PdfToolbar"
import { PdfFooter } from "./PdfFooter"
import { ANNOTATIONS_FETCH_THRESHOLD, PAGES_TO_FETCH_ANNOTATIONS, US_LETTER_SIZE } from "./constants"
import { getPagesFromRanges } from "./utils"

import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { usePerformance } from "hooks/usePerformance"
import Snackbar from "@mui/material/Snackbar"
import { exhibitService } from "../../api/services/exhibit"

import {
  ANNOTATION_DEFAULT_COLOR,
  AnnotationColorsMapping,
  AnnotationsMapping,
  PdfAnnotationsType,
} from "./PdfAnnotations"
import Link from "@mui/material/Link"
import { usePermissions } from "permissions/usePermissions"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

interface PdfDocumentSource {
  url: string
}

export type PdfPagesRange = [number, number]

interface PdfDocumentSourceAuth {
  httpHeaders: Record<string, string>
  withCredentials: boolean
}

export interface PdfRendererProps {
  filename: string
  url: string
  withCredentials?: boolean
  currentFileName?: string
  page: number
  pageNumberAdjustment?: number
  deletedPages?: PdfPagesRange[]

  onPageChange?: (page: number) => void

  leftControls?: React.ReactElement
  rightControls?: React.ReactElement
  exhibitId?: string | null
  userExhibitId?: string | null
}

const annotationsCache: Record<string, PdfAnnotationsType[]> = {}

export function PdfRenderer({
  filename,
  url,
  withCredentials = false,
  currentFileName,
  page = 1,
  pageNumberAdjustment = 0,
  onPageChange,
  leftControls,
  rightControls,
  deletedPages: deletedPageRanges = [],
  exhibitId = null,
  userExhibitId = null,
}: PdfRendererProps): JSX.Element {
  const [currentPage, setCurrentPage] = useState(page)
  const [pdfDocument, setPdfDocument] = useState<Nullable<PDFDocumentProxy>>(null)
  const [pagesCount, setPagesCount] = useState(0)
  const [viewportWidth, setViewportWidth] = useState<Nullable<number>>(null)

  const [annotationsLoading, setAnnotationsLoading] = useState(false)
  const [annotationsError, setAnnotationsError] = useState(false)
  const [annotations, setAnnotations] = useState<Record<number, PdfAnnotationsType[]>>({})

  const { citationHighlightsEnabled } = usePermissions()

  const [initialCitationHighlightsFetch, setInitialCitationHighlightsFetch] = useState<boolean>(true)

  const pdfSource = useMemo<PdfDocumentSource | (PdfDocumentSource & PdfDocumentSourceAuth)>(() => {
    if (!withCredentials) {
      return { url }
    }

    return {
      url,
      httpHeaders: getAuthHeaders(),
      withCredentials: true,
    }
  }, [url, withCredentials])
  const viewportRef = useRef<HTMLDivElement>(null)
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        if (!entry) return

        setViewportWidth(Math.floor(entry.contentRect.width * 0.95))
      }),
    []
  )
  const deletedPages = useMemo<Set<number>>(
    () => getPagesFromRanges(deletedPageRanges, pageNumberAdjustment),
    [deletedPageRanges, pageNumberAdjustment]
  )

  const documentLoadPerformance = usePerformance("pdf_document_load", { initial: !!viewportWidth }, [
    viewportWidth,
    pdfSource,
  ])

  const handleDocumentLoadSuccess = useCallback(
    (pdfDocument: PDFDocumentProxy) => {
      setPdfDocument(pdfDocument)
      setPagesCount(pdfDocument.numPages)

      setInitialCitationHighlightsFetch(true)

      documentLoadPerformance.measure({ pagesCount: pdfDocument.numPages })
    },
    [documentLoadPerformance]
  )

  const handlePageChange = useCallback(
    (nextPage: number) => {
      setCurrentPage(nextPage + pageNumberAdjustment)
    },
    [pageNumberAdjustment]
  )

  const [mobileNav, setMobileNav] = useState(false)
  const handleNavigationToggle = useCallback(() => {
    setMobileNav(currentValue => !currentValue)
  }, [])

  const [scale, setScale] = useState<Nullable<number>>(null)
  const estimatedScale = viewportWidth ? Math.min(viewportWidth / US_LETTER_SIZE.WIDTH, 1) : 1
  const placeholderScale = scale === null ? estimatedScale : scale

  useEffect(() => {
    setCurrentPage(page)
  }, [page, url])

  useEffect(() => {
    onPageChange?.(currentPage)
  }, [currentPage, onPageChange])

  useEffect(() => {
    if (!viewportRef.current) return

    const target = viewportRef.current
    resizeObserver.observe(target)

    return () => resizeObserver.unobserve(target)
  }, [resizeObserver])

  const fetchAnnotations = useCallback(
    async (exhibitId: string | null, userExhibitId: string | null, currentPage: number) => {
      if (
        pagesCount === 0 ||
        ((!exhibitId || exhibitId?.trim() === "") && (!userExhibitId || userExhibitId?.trim() === ""))
      ) {
        return
      }
      setAnnotationsLoading(true)
      const lastPage = pagesCount + pageNumberAdjustment
      const startPage =
        currentPage <= PAGES_TO_FETCH_ANNOTATIONS
          ? 1
          : currentPage - Math.ceil(PAGES_TO_FETCH_ANNOTATIONS / 2)
      const endPage = Math.min(currentPage + PAGES_TO_FETCH_ANNOTATIONS, lastPage)

      const pagesToFetch = []
      for (let page = startPage; page <= endPage; page++) {
        if (annotationsCache[`${exhibitId}-${page}`] === undefined) {
          pagesToFetch.push(page)
        }
      }

      const getExhibitCitations = userExhibitId
        ? exhibitService.getUserExhibitCitations
        : exhibitService.getExhibitCitations

      if (pagesToFetch.length > ANNOTATIONS_FETCH_THRESHOLD || initialCitationHighlightsFetch) {
        try {
          const response = await getExhibitCitations(userExhibitId || exhibitId, {
            start: pagesToFetch.length === 0 ? 1 : Math.min(...pagesToFetch),
            size: pagesToFetch.length,
          })

          pagesToFetch.forEach(page => {
            const annotations: PdfAnnotationsType[] = []
            if (response && response[page]) {
              const highlights = response[page]
              highlights.forEach(highlight =>
                highlight.bounding_boxes?.forEach(box => {
                  annotations.push({
                    type: highlight.highlight_type,
                    shape:
                      AnnotationsMapping[highlight.highlight_type as keyof typeof AnnotationsMapping] ||
                      "UNKNOWN",
                    top: box.top * 100,
                    left: box.left * 100,
                    height: box.height * 100,
                    width: box.width * 100,
                    color:
                      AnnotationColorsMapping[highlight.highlight_type as keyof typeof AnnotationsMapping] ||
                      ANNOTATION_DEFAULT_COLOR,
                  })
                })
              )
              annotationsCache[`${exhibitId}-${page}`] = annotations
            } else {
              annotationsCache[`${exhibitId}-${page}`] = []
            }

            if (initialCitationHighlightsFetch) {
              setInitialCitationHighlightsFetch(false)
            }
          })
        } catch (error) {
          setAnnotationsError(true)
        }
      }

      const compiledData: Record<number, PdfAnnotationsType[]> = {}
      for (let page = startPage; page <= endPage; page++) {
        compiledData[page] = annotationsCache[`${exhibitId}-${page}`] || []
      }

      setAnnotations(compiledData)
      setAnnotationsLoading(false)
      setAnnotationsError(false)
    },
    [pagesCount, pageNumberAdjustment, initialCitationHighlightsFetch]
  )

  const handleFetchAnnotations = useCallback(() => {
    if (!citationHighlightsEnabled || (!exhibitId && !userExhibitId)) return

    fetchAnnotations(exhibitId, userExhibitId, currentPage)
  }, [citationHighlightsEnabled, exhibitId, fetchAnnotations, userExhibitId, currentPage])

  const currentDocumentPage: number = useMemo(() => {
    const adjustedPageNumber = Math.max(currentPage - pageNumberAdjustment, 1)
    return Math.min(adjustedPageNumber, pagesCount)
  }, [currentPage, pageNumberAdjustment, pagesCount])

  useEffect(() => {
    handleFetchAnnotations()
  }, [handleFetchAnnotations])

  return (
    <PdfViewport>
      <PdfToolbar
        title={filename}
        subtitle={currentFileName ? `Current filename: ${currentFileName}` : undefined}
        pages={pagesCount || 1}
        page={currentPage}
        pageNumberAdjustment={pageNumberAdjustment}
        onChangePage={handlePageChange}
        estimatedScale={estimatedScale}
        onScaleChange={setScale}
        leftControls={
          <>
            <Hidden smUp>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                size="small"
                onClick={handleNavigationToggle}
              >
                <MenuIcon fontSize="small" />
              </IconButton>
            </Hidden>
            {leftControls}
          </>
        }
        rightControls={<>{rightControls}</>}
      />
      <PdfContainer data-test="pdf-preview-wrapper">
        <Hidden smDown>
          <PdfNavigationPanel>
            {pdfDocument && (
              <PdfThumbnails
                key={url}
                onPageSelect={setCurrentPage}
                document={pdfDocument}
                currentPage={currentPage}
                pageNumberAdjustment={pageNumberAdjustment}
                deletedPages={deletedPages}
              />
            )}
          </PdfNavigationPanel>
        </Hidden>
        <Hidden mdUp>
          <Drawer open={mobileNav} variant="temporary" onClick={handleNavigationToggle}>
            <PdfNavigationPanel>
              A
              {pdfDocument && (
                <PdfThumbnails
                  key={url}
                  onPageSelect={setCurrentPage}
                  document={pdfDocument}
                  currentPage={currentPage}
                  pageNumberAdjustment={pageNumberAdjustment}
                  deletedPages={deletedPages}
                />
              )}
            </PdfNavigationPanel>
          </Drawer>
        </Hidden>
        <PdfDocumentContainer ref={viewportRef}>
          <Snackbar open={annotationsLoading} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <Alert severity="info" variant="filled" sx={{ width: "100%" }}>
              Citations currently loading
            </Alert>
          </Snackbar>
          <Snackbar open={annotationsError} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <Alert severity="warning" variant="filled" sx={{ width: "100%" }}>
              Citations loading error.{" "}
              <Link
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={handleFetchAnnotations}
              >
                Try again
              </Link>
            </Alert>
          </Snackbar>
          {viewportWidth && (
            <Document
              file={pdfSource}
              className="pdf-wrapper"
              onLoadSuccess={handleDocumentLoadSuccess}
              loading={
                <PagesWrapper>
                  <PageWrapper>
                    <PdfLoadingPage scale={placeholderScale} />
                  </PageWrapper>
                </PagesWrapper>
              }
              error={
                <PagesWrapper>
                  <PageWrapper>
                    <PdfErrorPage message="Failed to load pdf document..." scale={placeholderScale} />
                  </PageWrapper>
                </PagesWrapper>
              }
            >
              {pdfDocument && pagesCount > 0 && (
                <PdfDocument
                  annotations={annotations}
                  document={pdfDocument}
                  pagesCount={pagesCount}
                  currentPage={currentDocumentPage}
                  pageNumberAdjustment={pageNumberAdjustment}
                  onPageChange={handlePageChange}
                  viewportWidth={viewportWidth}
                  deletedPages={deletedPages}
                  scale={scale ?? undefined}
                />
              )}
            </Document>
          )}
        </PdfDocumentContainer>
      </PdfContainer>
      <PdfFooter
        pages={pagesCount || 1}
        page={currentPage}
        pageNumberAdjustment={pageNumberAdjustment}
        onChangePage={handlePageChange}
      />
    </PdfViewport>
  )
}
