import { EstimateFormValues } from "./types"

export const DEFAULT_VALUES = {
  description: "",
  case_type: null,
  state: null,
  county: null,
  contains_fracture: null,
  death_from_incident: null,
  insurance_carrier: null,
  commercial_defendant_involvement: null,
  medical_provider_types: [],
  injuries: [],
  treatments: [],
  most_severe_treatment_category: null,
  police_involvement: null,
  policy_limit: null,
  plaintiff_age: null,
  plaintiff_age_gte: null,
  plaintiff_age_lte: null,
  beginning_of_treatment: null,
  end_of_treatment: null,
  medical_expenses: null,
  medical_expenses_gte: "",
  medical_expenses_lte: "",
  number_of_medical_visits: null,
  number_of_visits_gte: null,
  number_of_visits_lte: null,
  policy_limit_gte: "",
  policy_limit_lte: "",
  settlement_lte: "",
  settlement_gte: "",
  settlement_date_gte: null,
  settlement_date_lte: null,
  treatment_length_gte: null,
  treatment_length_lte: null,
} satisfies Partial<EstimateFormValues>

export const MAX_SEARCH_CHARS = 200
export const MAX_DESCRIPTION_CHARS = 5_000
