import { createContext } from "react"
import { noop } from "lodash"
import { LibraryFiltersContext as LibraryFiltersContextType } from "./types"

export const LibraryFiltersContext = createContext<LibraryFiltersContextType>({
  attributes: null,
  filterValues: {
    section: null,
    attributeValues: {},
  },
  setFilterValues: noop,
})
