import styled from "@emotion/styled"
import Paper from "@mui/material/Paper"
import Chip from "@mui/material/Chip"
import Box from "@mui/material/Box"

export const SectionHeading = styled(Box)({
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: 1.89,
  letterSpacing: "-0.36px",
})

export const StyledConflictIndicator = styled(Box)(({ theme }) => ({
  position: "absolute",
  color: theme.palette.common.white,
  width: theme.spacing(2),
  height: theme.spacing(2),
  backgroundColor: theme.palette.error.main,
  borderRadius: "50%",
  textAlign: "center",
  margin: "auto 0",
  lineHeight: `${theme.spacing(2)}`,
  fontSize: "12px",
  right: "-30px",
  top: "50%",
  transform: "translateY(-50%)",
  "&::before": {
    content: "'!'",
    fontWeight: "bold",
  },
}))

export const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 6),
  borderTop: "2px solid black",
}))

export const UploadedFiles = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(6),
}))

export const NoItems = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(2),
}))

export const AppointmentsGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: theme.spacing(1),
}))

export const Label = styled(Box)({
  fontWeight: "bold",
})

export const Italic = styled(Box)({
  fontStyle: "italic",
})

export const CodeList = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexWrap: "wrap",
}))

export const CodeChip = styled(Chip)(() => ({
  width: "230px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  borderRadius: "4px",
}))

export const BoxChip = styled(Chip)(({ theme }) => ({
  borderRadius: "5px",
  fontStyle: "italic",
  fontSize: "10px",
  lineHeight: 1.4,
  letterSpacing: "-0.31px",
  marginRight: theme.spacing(1),
}))
