import styled from "@emotion/styled"
import { outlinedInputClasses } from "@mui/material"

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(5),
  alignItems: "start",

  [`.${outlinedInputClasses.input}`]: {
    fontSize: "12px",
  },
}))

export const InputContainer = styled.div(({ theme }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(3, 250px) 1fr",
  gap: theme.spacing(2.5),
}))
