import { isNil } from "lodash"
import { ExhibitCitation } from "common/citation/ExhibitCitation"
import { useDocumentStore } from "documents/store"
import { useExhibitsPreview } from "documents/exhibits/useExhibitPreview"
import { IcdCodeRelation, Partition } from "documents/types"
import { partitionsSelectors } from "documents/store/partitions"

interface PastMedicalVisitReferenceProps {
  relation: IcdCodeRelation
}

interface PastMedicalVisitReferenceComponentProps {
  partition: Partition
}

export const PastMedicalVisitReferenceComponent = ({
  partition,
}: PastMedicalVisitReferenceComponentProps) => {
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)
  const preview = useExhibitsPreview()
  const index = exhibitsOrder.indexOf(partition.exhibitId) + 1

  return (
    <ExhibitCitation
      index={index}
      pages={isNil(partition.startPage) ? [] : [partition.startPage]}
      onClick={() => preview(partition.exhibitId, partition.startPage)}
    />
  )
}

export const PastMedicalVisitReference = ({ relation }: PastMedicalVisitReferenceProps) => {
  const partition = useDocumentStore(partitionsSelectors.getPartitionById(relation.partitionId))

  if (!partition) return null

  return <PastMedicalVisitReferenceComponent partition={partition} />
}
