import { BaseEditor } from "slate"
import { VariablesEditor } from "../features/variables/types"
import { Variables } from "../features/variables"
import { CitationsEditor } from "../features/citations/types"
import { Citations } from "../features/citations"
import { FlagsEditor } from "../features/flags/types"
import { Flags } from "../features/flags"
import { SuggestionsEditor } from "../features/suggestions/types"
import { Suggestions } from "../features/suggestions/Suggestions"

export function isVariablesEditor(editor: BaseEditor): editor is BaseEditor & VariablesEditor {
  return "variables" in editor && (editor as unknown as VariablesEditor).variables instanceof Variables
}

export function isCitationsEditor(editor: BaseEditor): editor is BaseEditor & CitationsEditor {
  return "citations" in editor && (editor as unknown as CitationsEditor).citations instanceof Citations
}

export function isFlagsEditor(editor: BaseEditor): editor is BaseEditor & FlagsEditor {
  return "flags" in editor && (editor as unknown as FlagsEditor).flags instanceof Flags
}

export function isSuggestionsEditor(editor: BaseEditor): editor is BaseEditor & SuggestionsEditor {
  return (
    "suggestions" in editor && (editor as unknown as SuggestionsEditor).suggestions instanceof Suggestions
  )
}
