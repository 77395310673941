import { Tab, Typography } from "@mui/material"
import { memo, useCallback, useLayoutEffect, useRef, useState } from "react"
import { PopupContainer, StyledTabs, TitleButton } from "./styled"
import { ACTION_TYPE } from "./constants"
import { ActionView } from "./ActionView"
import { usePopupStore } from "./store"
import { useDndMonitor, useDraggable } from "@dnd-kit/core"
import { useTheme } from "@emotion/react"
import { HEADER_NAV_HEIGHT_SPACE_MULTIPLIER } from "app/constants"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"

// memo as a separate component to avoid re-renders while moving the popup
const Tabs = memo(function TestTabs({
  selectedView,
  handleTabChange,
}: {
  selectedView: ACTION_TYPE
  handleTabChange: (event: React.SyntheticEvent, value: ACTION_TYPE) => void
}) {
  return (
    <StyledTabs indicatorColor="secondary" value={selectedView} onChange={handleTabChange}>
      <Tab label="Extract" data-test="extract-tab" value={ACTION_TYPE.EXTRACT} />
      <Tab label="Combine" data-test="combine-tab" value={ACTION_TYPE.COMBINE} />
      <Tab label="Duplicate" data-test="duplicate-tab" value={ACTION_TYPE.DUPLICATE} />
      <Tab label="Delete" data-test="delete-tab" value={ACTION_TYPE.DELETE} />
      <Tab label="Download" data-test="download-tab" value={ACTION_TYPE.DOWNLOAD} />
    </StyledTabs>
  )
})

export function ActionPopup() {
  const [position, setPosition] = useState({ left: 0, top: 0 })
  const containerEl = useRef<HTMLDivElement>(null)
  const { userExhibitId, anchor, closePopup } = usePopupStore()
  const [selectedView, setSelectedView] = useState(ACTION_TYPE.EXTRACT)
  const exhibitIndex = useExhibitBuilderStore(
    useShallow(state => (userExhibitId ? state.userExhibitMap[userExhibitId]?.index : 0))
  )
  const theme = useTheme()

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: "user-exhibit-action-popup",
  })

  useDndMonitor({
    onDragEnd(event) {
      setPosition(({ left, top }) => ({
        left: left + event.delta.x,
        top: top + event.delta.y,
      }))
    },
  })

  useLayoutEffect(() => {
    if (!anchor || !containerEl.current) {
      return
    }

    const {
      left: anchorLeft,
      top: anchorTop,
      height: anchorHeight,
      width: anchorWidth,
    } = anchor.getBoundingClientRect()
    const { width: containerWidth, height: containerHeight } = containerEl.current.getBoundingClientRect()
    const navHeight = Number(theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER).replace("px", ""))

    const left = anchorLeft - containerWidth + anchorWidth
    const hasSpaceAbove = anchorTop - containerHeight - navHeight > 0
    const top = hasSpaceAbove ? anchorTop - containerHeight : anchorTop + anchorHeight

    setPosition({ left, top })
  }, [anchor, theme])

  const handleTabChange = useCallback((_event: React.SyntheticEvent, value: ACTION_TYPE) => {
    setSelectedView(value)
  }, [])

  if (!userExhibitId || !anchor) {
    return null
  }

  return (
    <PopupContainer
      ref={containerEl}
      style={{
        left: position?.left || 0,
        top: position?.top || 0,
        transform: `translate3d(${transform?.x || 0}px, ${transform?.y || 0}px, 0)`,
      }}
    >
      <TitleButton ref={setNodeRef} {...listeners} {...attributes}>
        <Typography variant="h2">Exhibit {exhibitIndex + 1} - Actions Menu</Typography>
      </TitleButton>

      <Tabs selectedView={selectedView} handleTabChange={handleTabChange} />
      <ActionView key={userExhibitId} type={selectedView} id={userExhibitId} onClose={closePopup} />
    </PopupContainer>
  )
}
