import { MouseEvent } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { PreviewDemandIcon } from "common/assets"
import { IcdCode, Partition } from "documents/types"
import { BlackTooltip } from "common/Tooltip"
import { useDocumentStore } from "documents/store"
import { partitionsSelectors } from "documents/store/partitions"
import { useExhibitPreview } from "documents/exhibits/useExhibitPreview"
import { ExhibitReferenceButton } from "./styled"
import { ReferenceChip } from "./IcdCodeTable/styled"

interface IcdCodeReferenceProps {
  partitionId: Partition["id"]
  page: Nullable<number>
}

function IcdCodeReference({ partitionId, page }: IcdCodeReferenceProps): JSX.Element {
  const partition = useDocumentStore(partitionsSelectors.getPartitionById(partitionId))
  const openPreview = useExhibitPreview(partition.exhibitId)

  return (
    <ExhibitReferenceButton size="small" color="primary" onClick={() => openPreview(partition, page)}>
      <PreviewDemandIcon />
    </ExhibitReferenceButton>
  )
}

interface IcdCodeProps {
  icdCode: IcdCode
  isEditing?: boolean
  onClick: (event: MouseEvent<HTMLDivElement>, icdCodeId: IcdCode["id"]) => void
  onDelete: (icdCodeId: IcdCode["id"]) => void
  readOnly?: boolean
}

export function IcdCodeComponent({
  icdCode,
  isEditing = false,
  onClick,
  onDelete,
  readOnly,
}: IcdCodeProps): JSX.Element {
  const { id } = icdCode

  return (
    <>
      <ReferenceChip
        key={id}
        onClick={readOnly ? undefined : e => onClick(e, id)}
        onDelete={readOnly ? undefined : () => onDelete(id)}
        label={
          <BlackTooltip arrow title={icdCode.description}>
            <Box display="flex" gap={1} alignItems="center">
              {icdCode.code}
              {isEditing && <Typography fontWeight={600}>Edit</Typography>}
            </Box>
          </BlackTooltip>
        }
      />
      {icdCode.relations.map(relation => (
        <IcdCodeReference
          key={`${relation.partitionId}_${relation.page}`}
          partitionId={relation.partitionId}
          page={relation.page}
        />
      ))}
    </>
  )
}
