import { CustomEditor } from "common/form-components/rich-text/CustomEditor"
import { getEditorRange } from "common/form-components/rich-text/queries"
import copy from "copy-to-clipboard"
import { Transforms } from "slate"
import { ReactEditor } from "slate-react"
import * as Sentry from "@sentry/browser"

const COPY_EVENT = "copy"

class EditorCopyError extends Error {
  name = "EditorCopyError"
}

export const copyToClipboardEditorText = (
  editorRef: Nullable<CustomEditor> | undefined,
  onSuccess?: () => void,
  onError?: () => void
) => {
  copy(COPY_EVENT, {
    format: "text/html",
    onCopy: clipboardData => {
      if (editorRef) {
        const range = getEditorRange(editorRef)

        if (range) {
          Transforms.select(editorRef, range)
        }

        try {
          ReactEditor.setFragmentData(editorRef, clipboardData as DataTransfer, COPY_EVENT)
        } catch (err) {
          const apmErr = new EditorCopyError(
            JSON.stringify(
              {
                error: err,
                editorData: editorRef.children,
                clipboardData,
              },
              null,
              2
            )
          )

          Sentry.captureException(apmErr)

          onError?.()
        }

        if (onSuccess) onSuccess()
      } else if (onError) {
        onError()
      }
    },
  })
}
