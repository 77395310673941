import { UseMutateAsyncFunction, useMutation, useQueryClient } from "@tanstack/react-query"
import { bulkDeleteExhibits, deleteExhibit, updateExhibit } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useMemo, useRef } from "react"
import { queryKeys } from "react-query/constants"

function useExhibitMutation<T>(
  endpoint: (args: T) => Promise<unknown>,
  success: Nullable<string> = null,
  error: Nullable<string> = null,
  invalidate = true
): UseMutateAsyncFunction<unknown, unknown, T, unknown> {
  const { showErrorMessage, showSuccessMessage } = useHandleMessages()
  const queryClient = useQueryClient()

  const mutation = useMutation(endpoint, {
    onSuccess: () => {
      success && showSuccessMessage(success)
      invalidate && queryClient.invalidateQueries([queryKeys.facts])
    },
    onError: err =>
      error &&
      showErrorMessage({
        message: error,
        error: err,
      }),
    meta: { disableLoader: true },
  })

  return mutation.mutateAsync
}

export function useExhibitMutations() {
  const bulkDeleteExhibitsMutation = useExhibitMutation(
    bulkDeleteExhibits,
    "Images were deleted.",
    "Error deleting images. Please try again shortly and if your problem persists contact a dev."
  )

  const deleteImageMutation = useExhibitMutation(
    deleteExhibit,
    "Image was deleted.",
    "Error deleting image. Please try again shortly and if your problem persists contact a dev."
  )

  const deleteFileMutation = useExhibitMutation(
    deleteExhibit,
    "File was deleted.",
    "Error deleting file. Please try again shortly and if your problem persists contact a dev."
  )

  const updateImageMutation = useExhibitMutation(updateExhibit, null, null, false)

  const callbacks = {
    bulkDeleteExhibitsMutation,
    updateImageMutation,
    deleteImageMutation,
    deleteFileMutation,
  }
  const mutations = useRef(callbacks)
  mutations.current = callbacks

  const proxy = useMemo(
    () =>
      new Proxy(mutations.current, {
        get(target, prop, receiver) {
          if (!(prop in target)) {
            return Reflect.get(target, prop, receiver)
          }

          return mutations.current[prop as keyof typeof callbacks]
        },
      }),
    [mutations]
  )

  return proxy
}
