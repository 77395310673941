import { isDevEnvironment, isTestEnvironment } from "infrastructure/env/getEnvironment"
import { logger } from "utils"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { reviewsActions, reviewsSelectors, reviewsSlice } from "./reviews"
import { GetState, ReviewArgs, ReviewInternalStatus, ReviewItemStatus, SetState } from "./types"
import { matterApiService } from "api/services/matter"
import { flagsActions, flagsSlice } from "./flags"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { EditorContent } from "common/form-components/rich-text"
import { Node } from "slate"
import { isEmptySpaceNode } from "common/form-components/rich-text/queries/inlines"
import { cloneDeep } from "lodash"
import { FlaggedText } from "common/form-components/rich-text/CustomEditor"

const createState = (matterId: string) => ({
  matterId,
  ...reviewsSlice,
  ...flagsSlice,
})

type ReviewStore = ReturnType<typeof createState>

const createReviewStore = (matterId: string) => {
  if (isDevEnvironment() && !isTestEnvironment()) {
    return create<ReviewStore>()(devtools(logger(() => createState(matterId))))
  }

  return create<ReviewStore>()(() => createState(matterId))
}

export const useReviewStore = createReviewStore("")

const actions = (set: SetState<ReviewStore>, get: GetState<ReviewStore>) => {
  const { setReview, updateReviewItem: updateReviewItemAction } = reviewsActions(set)
  const reset = (matterId: string) => set(createState(matterId))

  const checkStatusAndLoadReview = async (args: ReviewArgs, reviewRequestId: string) => {
    const review = reviewsSelectors.reviewItemByArgs(args)(get())

    setReview(args, { state: review?.state === "running" ? "running" : "loading" })
    const matterId = get().matterId
    const reviewData =
      review?.data ?? (await matterApiService.getReviewByArgs({ matterId, reviewRequestId, ...args }))

    if (
      !reviewData?.reviewRequestId ||
      localStorage.getItem(`review-request-${reviewData?.reviewRequestId}`) === "completed"
    ) {
      setReview(args, { data: null, state: "not_started" })
      return
    }

    setReview(args, { data: reviewData, state: review?.state === "running" ? "running" : "loading" })

    const { status } = await matterApiService
      .getReviewStatus({ matterId, reviewRequestId })
      .catch<{ status: ReviewInternalStatus }>(() => ({ status: "failed" }))

    if (status === "completed") {
      setReview(args, {
        data: await matterApiService.getReview({ matterId, reviewRequestId }),
        status,
        state: "loaded",
      })
    } else {
      setReview(args, { status, state: status !== "pending" && status !== "running" ? "failed" : "running" })
    }
  }

  const createReview = async (args: ReviewArgs) => {
    setReview(args, {
      completedContent: undefined,
      status: "pending",
      state: "creating",
      data: null,
    })
    const matterId = get().matterId
    const review = await matterApiService.createReview({ matterId, ...args }).catch(() => null)
    setReview(args, { data: review, state: review ? "running" : "not_started" })

    return review
  }

  function removeFlags(node: Node) {
    if ((node as FlaggedText).flags) {
      ;(node as FlaggedText).flags = undefined
    }

    if ("children" in node) {
      const children: Node[] = []

      for (const child of node.children) {
        if (!isEmptySpaceNode(child)) {
          removeFlags(child)
          children.push({ ...child })
        }
      }

      // @ts-expect-error Just remapping children before save
      node.children = children
    }
  }

  const completeReview = async (args: ReviewArgs, reviewRequestId: string) => {
    const review = reviewsSelectors.reviewItemByArgs(args)(get())
    const matterId = get().matterId

    if (review?.data) {
      const content = cloneDeep(review.data.resultContent ?? DEFAULT_VALUE)

      for (const node of content) {
        removeFlags(node)
      }

      const completedContent = new EditorContent(content)

      setReview(args, {
        state: "completing",
        completedContent,
      })

      await matterApiService.completeReview({
        matterId,
        review: { ...review.data, resultContent: completedContent.getNormalizedChildren() },
      })

      setReview(args, { status: null, data: null, state: "not_started" })
      localStorage.setItem(`review-request-${reviewRequestId}`, "completed")
    }
  }

  const cancelReview = async (args: ReviewArgs, reviewRequestId: string) => {
    const review = reviewsSelectors.reviewItemByArgs(args)(get())
    const matterId = get().matterId

    if (review?.data) {
      setReview(args, {
        completedContent: undefined,
        data: null,
        state: "not_started",
        status: "cancelled",
      })

      await matterApiService.cancelReview({ matterId, reviewRequestId })
    }
  }

  const updateReviewItemStatus = (args: ReviewArgs, reviewItemId: string, status: ReviewItemStatus) => {
    const review = reviewsSelectors.reviewItemByArgs(args)(get())
    const matterId = get().matterId
    const reviewItem = review?.data?.results.find(item => item.id === reviewItemId)

    if (reviewItem && review?.data) {
      const nextStatus: ReviewItemStatus =
        status === "accepted" && reviewItem.userModifiedText !== null ? "modified" : status

      updateReviewItemAction(args, reviewItemId, { status: nextStatus })
      matterApiService.updateReviewItemStatus({
        matterId,
        reviewRequestId: review.data.reviewRequestId,
        data: {
          id: reviewItemId,
          status: nextStatus,
          userModifiedText: nextStatus === "modified" ? reviewItem.userModifiedText : undefined,
        },
      })
    }
  }

  const acceptReviewItem = (args: ReviewArgs, reviewItemId: string) => {
    updateReviewItemStatus(args, reviewItemId, "accepted")
  }

  const rejectReviewItem = (args: ReviewArgs, reviewItemId: string) => {
    updateReviewItemStatus(args, reviewItemId, "rejected")
  }

  const modifyReviewItemSuggestion = async (args: ReviewArgs, reviewItemId: string, text: string) => {
    const review = reviewsSelectors.reviewItemByArgs(args)(get())
    const matterId = get().matterId

    if (review?.data) {
      updateReviewItemAction(args, reviewItemId, { status: "modified", userModifiedText: text })
      const reviewRun = await matterApiService.updateReviewItemData({
        matterId,
        reviewRequestId: review.data.reviewRequestId,
        data: { id: reviewItemId, status: "modified", userModifiedText: text },
      })
      setReview(args, { data: reviewRun })
    }
  }

  return {
    reset,
    checkStatusAndLoadReview,
    createReview,
    completeReview,
    cancelReview,
    acceptReviewItem,
    rejectReviewItem,
    modifyReviewItemSuggestion,
  }
}

export const reviewActions = {
  ...reviewsActions(useReviewStore.setState),
  ...flagsActions(useReviewStore.setState),
  ...actions(useReviewStore.setState, useReviewStore.getState),
}
