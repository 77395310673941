import styled from "@emotion/styled"
import { LoadingButton } from "@mui/lab"
import LinkButton from "common/buttons/LinkButton"

interface DocumentStructureTablesWrapperProps {
  showArchived: boolean
  hidden?: boolean
}

export const StyledFiltersWrapper = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: "flex",
  alignItems: "top",
}))

export const BaseActionButton = styled(LoadingButton)(({ theme }) => ({
  minWidth: theme.spacing(13),
  fontWeight: 600,
}))
BaseActionButton.defaultProps = {
  variant: "contained",
  disableElevation: true,
}

export const StyledCancelButton = styled(BaseActionButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}))

export const StyledAddButton = styled(BaseActionButton)({})
StyledAddButton.defaultProps = {
  color: "primary",
}

export const StyledPageSizeWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0, 1, 3),
}))

export const DocumentStructureTablesWrapper = styled("div")({
  width: "200%",
  overflow: "hidden",
  display: "flex",
  transition: "0.7s",
})

export const StyledTableWrapper = styled("div")<DocumentStructureTablesWrapperProps>(
  ({ showArchived, hidden }) => ({
    width: "100%",
    transform: showArchived ? "translateX(-100%)" : "",
    visibility: hidden ? "hidden" : undefined,
    display: "flex",
    transitionProperty: "transform",
    transitionDuration: "0.7s",
    overflow: "hidden",
    alignSelf: "flex-start",
  })
)

export const ArchivedDocumentStructureTableWrapper = styled("div")({
  width: "100%",
})

export const StyledSeparator = styled("div")(({ theme }) => ({
  height: theme.spacing(6),
}))

export const StyledPageOverlayWrapper = styled("div")({
  zIndex: 100,
  position: "absolute",
  width: "100%",
  minHeight: "100%",
  background: "red",
})

export const StyledBackButton = styled(LinkButton)(({ theme }) => ({
  minWidth: "65px",
  fontSize: "13px",
  alignSelf: "start",
  height: theme.spacing(6),
}))

export const StyledFirmName = styled("div")(({ theme }) => ({
  display: "inline-block",
  fontSize: "20px",
  fontWeight: "bold",
  marginLeft: theme.spacing(2),
  textTransform: "capitalize",
}))

export const StyledFirmHeaderWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}))
