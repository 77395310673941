import { ReactNode } from "react"
import { ExhibitName, HeaderContainer, ExhibitLink, FileIcon, CustomContent } from "./styled"

interface PartitionListHeaderProps {
  fileName: string
  startPage?: number
  endPage?: number
  totalPages?: number | null
  onPreview: () => void
  hideDocumentType?: boolean
  hideDateOfService?: boolean
  renderCustomContent?: () => ReactNode
}

export function PartitionListHeader({
  fileName,
  startPage,
  endPage,
  totalPages,
  onPreview,
  hideDocumentType,
  hideDateOfService,
  renderCustomContent,
  ...props
}: PartitionListHeaderProps) {
  return (
    <HeaderContainer {...props}>
      <ExhibitName>
        <FileIcon fontSize="small" />
        <ExhibitLink onClick={onPreview}>{fileName}</ExhibitLink>
      </ExhibitName>

      {renderCustomContent ? (
        <CustomContent>{renderCustomContent()}</CustomContent>
      ) : (
        <>
          {!hideDocumentType && <div />}
          {hideDateOfService ? <div /> : <div>Date of service</div>}
          {startPage && endPage && (
            <div>
              Pages {`(${startPage} - ${endPage})`}
              {Boolean(totalPages) && `/${totalPages}`}
            </div>
          )}
        </>
      )}
    </HeaderContainer>
  )
}
