import { useCallback } from "react"
import { DateField } from "common/form-components"

import { StyledGridRow } from "./styled"

export function ImpairmentSection({ startDate, endDate, errors, setValue, onClose }) {
  const handleChangeStartDate = useCallback(
    newStartDate => {
      setValue("start_of_impairment_date", newStartDate, { shouldDirty: true })
    },
    [setValue]
  )

  const handleChangeEndDate = useCallback(
    newEndDate => setValue("end_of_impairment_date", newEndDate, { shouldDirty: true }),
    [setValue]
  )

  return (
    <StyledGridRow>
      <DateField
        initialValue={startDate}
        onChange={handleChangeStartDate}
        onClose={onClose}
        label="Start of Impairment Date"
        placeholder="Start of Impairment Date"
        fieldProps={{ name: "start_of_impairment_date" }}
        error={errors?.start_of_impairment_date}
        helperText={errors?.start_of_impairment_date}
      />
      <DateField
        initialValue={endDate}
        onChange={handleChangeEndDate}
        onClose={onClose}
        label="End of Impairment Date"
        placeholder="End of Impairment Date"
        fieldProps={{ name: "end_of_impairment_date" }}
        error={errors?.end_of_impairment_date}
        helperText={
          errors?.end_of_impairment_date ? errors.end_of_impairment_date : "Usually date of retirement"
        }
      />
    </StyledGridRow>
  )
}
