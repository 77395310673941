import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { useIsMutating } from "@tanstack/react-query"
import { useWatch } from "react-hook-form"
import { formatTimeSinceNow } from "../utils"

const useStyles = makeStyles()(theme => ({
  progressWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > p": {
      marginLeft: theme.spacing(1),
    },
  },
}))

export function SavingIndicator() {
  const isMutating = useIsMutating() > 0
  const { classes } = useStyles()
  const lastModified = useWatch({ name: "updated_at" })

  return (
    <>
      {isMutating && (
        <Box className={classes.progressWrapper} data-test="saving-indicator">
          <CircularProgress size={20} color="secondary" />
          <span>&nbsp;</span>
          <Typography variant="caption" component="p">
            Saving...
          </Typography>
        </Box>
      )}
      {!isMutating && !lastModified && (
        <Typography variant="caption">{`Changes haven't been saved yet.`}</Typography>
      )}
      {!isMutating && lastModified && (
        <Typography variant="caption" data-test="saving-indicator-succeed" fontStyle="italic">
          Last saved: {formatTimeSinceNow(lastModified)}
        </Typography>
      )}
    </>
  )
}
