import { ReactNode, useCallback, useEffect } from "react"
import { useDocumentContext } from "../context"
import { useQueryClient } from "@tanstack/react-query"
import { documentActions, useDocumentStore } from "../store"
import { useSuspenseQuery } from "@suspensive/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { FlagPopover } from "./FlagPopover"
import { StyledFlagsWrapper } from "./styled"
import { matterApiService } from "api/services/matter"
import { flagsDataSelectors } from "../store/flags"
import { useDocumentStateStore } from "../store/documentState"
import { ReadOnlyFlagPopover } from "./ReadOnlyFlagPopover"
import { useConfirm } from "hooks/useConfirm"
import { Box, ButtonProps, Typography } from "@mui/material"
import { Flag } from "./types"
import { theme } from "app/theme"

interface FlagsControllerProps {
  children: ReactNode
  readOnly?: boolean
}

const confirmDeleteFlagProps = (flag: Nullable<Partial<Flag>>) => ({
  formContent: (
    <Box>
      <Typography component="p" className="instructions">
        Please confirm if you would like to <b>delete</b> the following flag:
      </Typography>
      {flag?.category && <Typography component="p">Flag Category: {flag?.category}</Typography>}
      {flag?.quote && <Typography component="p">Flag Quote: {flag?.quote}</Typography>}
      {flag?.reason && <Typography component="p">Flag Reason: {flag?.reason}</Typography>}
    </Box>
  ),
  PaperProps: { style: { borderRadius: "8px", padding: "16px 24px" } },
  header: (
    <Typography variant="dialogHeader" component="h3" className="header">
      Flag Deletion Confirmation
    </Typography>
  ),
  confirmationButtonProps: { sx: { background: theme.palette.blue.primary } } as ButtonProps,
  cancelButtonProps: { variant: "outlined", color: "secondary" } as ButtonProps,
  confirmationButtonContent: "Confirm",
})

export const FlagsController = ({ children, readOnly }: FlagsControllerProps) => {
  const { documentId } = useDocumentContext()
  const isSaving = useDocumentStateStore(state => state.isSaving)
  const currentFlagData = useDocumentStore(flagsDataSelectors.getCurrentFlag)
  const hoveredFlagId = useDocumentStore(flagsDataSelectors.getHoveredFlagId)
  const flagSelector = useDocumentStore(flagsDataSelectors.getFlagSelector)
  const queryClient = useQueryClient()
  const { data } = useSuspenseQuery(
    [queryKeys.flags, documentId],
    () => matterApiService.getFlags({ documentId }),
    { meta: SILENT_QUERY_PARAMS.meta }
  )

  const { confirm, dialog } = useConfirm(confirmDeleteFlagProps(currentFlagData))

  const handleDelete = useCallback(async () => {
    if (!currentFlagData?.id) return

    const confirmed = await confirm()

    if (confirmed) {
      documentActions.removeFlag(currentFlagData.id)
    }
  }, [confirm, currentFlagData])

  const handleClick = useCallback(() => {
    if (currentFlagData) return
    documentActions.setHoveredFlagId(null)
  }, [currentFlagData])

  useEffect(() => {
    documentActions.setFlags(data)
  }, [data])

  useEffect(() => {
    if (documentId) {
      return () => {
        requestAnimationFrame(() => {
          queryClient.removeQueries([queryKeys.flags, documentId])
        })
      }
    }
  }, [queryClient, documentId])

  return (
    <StyledFlagsWrapper hoveredFlagId={hoveredFlagId} onClick={handleClick}>
      {dialog}
      {currentFlagData &&
        (readOnly ? (
          <ReadOnlyFlagPopover
            currentFlagData={currentFlagData}
            flagSelector={flagSelector}
            onClose={documentActions.removeCurrentFlag}
          />
        ) : (
          <FlagPopover
            currentFlagData={currentFlagData}
            flagSelector={flagSelector}
            onFlagDataChanged={documentActions.updateCurrentFlagData}
            onClose={documentActions.removeCurrentFlag}
            onSave={documentActions.updateFlag}
            saving={isSaving}
            onDeleteFlag={handleDelete}
          />
        ))}
      {children}
    </StyledFlagsWrapper>
  )
}
