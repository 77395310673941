import { useState } from "react"
import { Box, Button, IconButton } from "@mui/material"
import styled from "@emotion/styled"

import { smartAdvocateCreateQuestionnaire } from "api"
import { useMutation } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import { useHandleMessages } from "common/messages/useHandleMessages"

import { InputField } from "common/form-components"
import { InsuranceProviderSelector } from "./InsuranceProviderSelector"
import { SmartAdvocateFormValues } from "./types"
import { CaseInfo } from "./CaseInfo"
import { CaseDocumentsTable } from "./CaseDocumentsTable"
import { Close as CloseIcon } from "@mui/icons-material"
import { Message } from "common/messages/Message"

interface SmartAdvocateProps {
  onCancelClick: () => void
  refetchRequestsList?: () => void
}

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gridColumn: "1 / 2",
})

const Title = styled(Box)({
  fontSize: "30px",
  fontWeight: 700,
})

const Description = styled(Box)({
  fontSize: "16px",
  fontWeight: 400,
})

const FormContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: theme.spacing(1),
}))

const FullRow = styled(Box)(({ theme }) => ({
  gridColumn: "1 / 3",
  gap: theme.spacing(2),
}))

const ButtonRow = styled(FullRow)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  marginTop: theme.spacing(2),
  marginLeft: "auto",
  gridColumn: "1 / 3",
  gap: theme.spacing(2),
}))

const ImportButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.blue.primary,
  "&:hover": {
    backgroundColor: theme.palette.blue.primary,
  },
}))

export const SmartAdvocate = ({ onCancelClick, refetchRequestsList }: SmartAdvocateProps) => {
  const { showErrorMessage, showMessage } = useHandleMessages()
  const [isImporting, setIsImporting] = useState<boolean>(false)

  const { control, handleSubmit, setValue, watch } = useForm<SmartAdvocateFormValues>({
    defaultValues: {
      caseNumber: null,
      selectedInsuranceOption: "",
      caseDocuments: [],
    },
  })

  const watchedCaseNumber = watch("caseNumber")

  const createQuestionnaireMutation = useMutation(smartAdvocateCreateQuestionnaire, {
    onSuccess: () => {
      onCancelClick()
      if (refetchRequestsList !== undefined) {
        refetchRequestsList()
      }
      const message: Message = {
        type: "info",
        message: "We'll email you once the data is imported and ready for review.",
        title: "Import in Progress",
      }
      showMessage({
        message,
        timeout: null,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      })
    },
    onError: error => {
      showErrorMessage({
        message: "There was an error importing the SmartAdvocate Case",
        error,
      })
    },
    onSettled: () => {
      setIsImporting(false)
    },
  })

  const handleImportClick = handleSubmit(data => {
    setIsImporting(true)
    createQuestionnaireMutation.mutate({
      caseNumber: data.caseNumber,
      insuranceOption: data.selectedInsuranceOption,
      documentIds: data.caseDocuments,
    })
  })

  return (
    <MainContainer>
      <Box mb={4}>
        <Box display="flex">
          <Title>Import Data from Smart Advocate</Title>
          <Box marginLeft="auto">
            <IconButton onClick={onCancelClick}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
        <Description>
          Help us import all of your data correctly by filling out the relevant fields below.
        </Description>
      </Box>

      {watchedCaseNumber && <CaseInfo caseNumber={watchedCaseNumber} />}

      <FormContainer>
        <InputField
          control={control}
          name="caseNumber"
          label="Case Number"
          variant="outlined"
          helperText="Your Case Number is a 7-digit number"
          rules={{
            required: true,
          }}
        />
        {!!watchedCaseNumber && (
          <InsuranceProviderSelector caseNumber={watchedCaseNumber} control={control} />
        )}

        <FullRow my={3}>
          {!!watchedCaseNumber && <CaseDocumentsTable caseNumber={watchedCaseNumber} setValue={setValue} />}
        </FullRow>

        <ButtonRow>
          <Button variant="outlined" onClick={onCancelClick}>
            Cancel
          </Button>
          <ImportButton
            variant="contained"
            onClick={handleImportClick}
            disabled={isImporting}
            data-test="modal-import-button"
          >
            {isImporting ? "Importing ..." : "Import"}
          </ImportButton>
        </ButtonRow>
      </FormContainer>
    </MainContainer>
  )
}
