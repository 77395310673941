import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Box from "@mui/material/Box"
import Badge from "@mui/material/Badge"
import ExpandMore from "@mui/icons-material/ExpandMore"
import IconDelete from "@mui/icons-material/Delete"
import { makeStyles } from "tss-react/mui"
import { compact, pick, startCase } from "lodash"
import { useFormContext } from "./FormContext"

const useStyles = makeStyles()(theme => ({
  injuryFacetContainer: {
    margin: theme.spacing(1, 0),
  },
  injuryText: {
    "& > span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  count: {
    margin: theme.spacing(0, 1),
  },
  actions: {
    "& > button": {
      fontSize: theme.typography.pxToRem(10),
    },
  },
}))

const InjurySelections = ({ selections, onHandleDelete }) => {
  const { classes } = useStyles()
  const getInjuryText = selection => {
    if (selection.injury_types) {
      return startCase(selection.injury_types).toLowerCase()
    }
    const nonLevelData = pick(selection, ["category", "problem"])
    return compact(Object.values(nonLevelData)).join(" // ")
  }
  const getSecondaryText = selection => {
    if (selection.injury_types) {
      return "injury name"
    }
    const levelsData = pick(selection, ["level_1", "level_2", "level_3", "level_4"])
    return (
      compact(Object.values(levelsData))
        .map(levelData => startCase(levelData).toLowerCase())
        .join(" > ") || "(no further levels)"
    )
  }

  return (
    <List dense>
      {selections.map((selection, index) => (
        <ListItem key={index}>
          <ListItemText
            className={classes.injuryText}
            primary={getInjuryText(selection)}
            secondary={getSecondaryText(selection)}
            primaryTypographyProps={{ variant: "body2" }}
            secondaryTypographyProps={{ variant: "caption" }}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="remove" onClick={() => onHandleDelete(index)} size="small">
              <IconDelete />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      <ListItem />
    </List>
  )
}

export default function InjuryOntologyTrigger({ openModal }) {
  const { classes } = useStyles()
  const { queryState, queryActions, handleQueryUpdate } = useFormContext()
  const selectedInjuries = queryState.query["injury_tags"] || []
  const selectedInjuryCount = selectedInjuries.length
  const removeFacet = facetIndex => {
    handleQueryUpdate(queryActions.REMOVE_FACET_BY_INDEX, { name: "injury_tags", value: facetIndex })
  }

  const removeAllInjuries = () => {
    handleQueryUpdate(queryActions.REMOVE_ALL_IN_FACET, "injury_tags")
  }

  return (
    <Box className={classes.injuryFacetContainer}>
      <Button
        variant="contained"
        size="small"
        onClick={openModal}
        startIcon={
          selectedInjuryCount ? (
            <Badge className={classes.count} badgeContent={selectedInjuryCount} color="secondary"></Badge>
          ) : null
        }
        endIcon={selectedInjuryCount ? <ExpandMore /> : null}
        color={"inherit"}
      >
        Set Injuries
      </Button>
      <Collapse in={selectedInjuryCount > 0}>
        <InjurySelections selections={selectedInjuries} onHandleDelete={removeFacet} />
        <ButtonGroup className={classes.actions} variant="outlined" size="small" disableElevation>
          <Button onClick={openModal}>Add More</Button>
          <Button color="secondary" onClick={removeAllInjuries}>
            Remove All
          </Button>
        </ButtonGroup>
      </Collapse>
    </Box>
  )
}
