import { getChunkedFile } from "common/file-resolver/resolver"
import { FileMetadata } from "common/form-components/files/types"
import { includesFile } from "common/form-components/files/utils"
import { FileToUpload } from "./upload-flow/types"
import { FileUploaderData, FileUploaderItem } from "./uploader-state/types"
import { FailedUploadData } from "./file-state/types"
import { Message } from "common/messages/Message"
import {
  DUPLICATE_UPLOAD_MATCH,
  EMPTY_FILE_MATCH,
  EMPTY_FILE_PREVALIDATION,
  PASSWORD_PROTECTED_MATCH,
  VALIDATION_MESSAGE,
} from "./constants"
import { FileVerificationProcessingStatus } from "api/services/file"

export function getDuplicatesFromState(currentState: FileUploaderData, files: File[]): FileMetadata[] {
  const recentlyAssignedFiles = Object.values(currentState)
    .filter(
      (fileState): fileState is Exclude<FileUploaderData[string], undefined> =>
        fileState?.state.current === "ASSIGNED"
    )
    .map(({ metadata }) => metadata)
  const duplicatedFiles = files.filter(file => includesFile(recentlyAssignedFiles, file))

  return duplicatedFiles
}

export function hasDuplicates(currentState: FileUploaderData, files: File[]): boolean {
  return getDuplicatesFromState(currentState, files).length > 0
}

type ChunkedFileWithMetadata = {
  data: FileToUpload
  metadata: FileMetadata
}

export function getChunkedFiles(files: File[]): ChunkedFileWithMetadata[] {
  return files.map(file => {
    const chunkedFile = getChunkedFile(file)

    return {
      data: {
        chunkCount: chunkedFile.chunkCount,
        chunks: chunkedFile.chunks,
        contentType: chunkedFile.file.type,
        size: chunkedFile.file.size,
      },
      metadata: chunkedFile.file,
    }
  })
}

export function getUploadFromState(
  state: FileUploaderData,
  id: Nullable<string>
): Nullable<FileUploaderItem> {
  if (id === null) return null

  return state[id] ?? null
}

export function getUploadIdFromState(state: FileUploaderData, id: Nullable<string>): Nullable<string> {
  const uploadState = getUploadFromState(state, id)

  if (!uploadState) return null

  const { data } = uploadState.state

  return "uploadId" in data ? data.uploadId : null
}

export function getUploadFailureReason(
  state: FileUploaderData,
  id: Nullable<string>
): Nullable<FailedUploadData> {
  const uploadState = getUploadFromState(state, id)

  if (!uploadState) return null

  const { data } = uploadState.state

  return "reason" in data ? data : null
}

export function getUploadInfo(state: FileUploaderData, id: Nullable<string>): Nullable<string> {
  const uploadState = getUploadFromState(state, id)

  if (!uploadState) return null

  const { data } = uploadState.state
  return data.info ?? null
}

export function getUploadFileProcessingStatus(
  state: FileUploaderData,
  id: Nullable<string>
): Nullable<FileVerificationProcessingStatus> {
  const uploadState = getUploadFromState(state, id)

  if (!uploadState) return null

  const { data } = uploadState.state
  if (!("processingStatus" in data)) {
    return null
  }
  return data.processingStatus ?? null
}

export function getUploadWarning(filename: string, message: string): Message {
  if (message.includes(PASSWORD_PROTECTED_MATCH)) {
    return {
      type: "warning",
      message: `File "${filename}" ${VALIDATION_MESSAGE.PASSWORD_PROTECTED}.`,
    }
  }

  return {
    type: "warning",
    message,
  }
}

export function getUploadError(filename: string, reason?: string | Error): string {
  let reasonMessage = String(reason)

  if (!reason) {
    reasonMessage = VALIDATION_MESSAGE.FAILED_TO_UPLOAD
  } else if (reasonMessage === EMPTY_FILE_PREVALIDATION) {
    reasonMessage = VALIDATION_MESSAGE.EMPTY_FILE
  } else if (reasonMessage.toLowerCase().includes(EMPTY_FILE_MATCH)) {
    reasonMessage = VALIDATION_MESSAGE.CORRUPT_FILE
  } else if (reasonMessage !== DUPLICATE_UPLOAD_MATCH) {
    reasonMessage = VALIDATION_MESSAGE.FAILED_VALIDATION
  }

  return `• "${filename}" ${reasonMessage}.`
}
