import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import useUser from "hooks/useUser"
import { UserServiceDeserializer } from "api/services/users/serializers"
import { getFirms } from "api"
import { getDocumentStructureTitle } from "settings/Library/Tabs/utils"
import { attributesService } from "api/services/attributes"
import { AttributeFiltersData } from "common/attributes-filter"

interface UseDocumentStructureTitleProps {
  firmId?: Nullable<PrimaryKey>
  attributeValues?: AttributeFiltersData
  documentName?: Nullable<string>
}

export const useDocumentStructureTitle = ({
  firmId,
  attributeValues,
  documentName,
}: UseDocumentStructureTitleProps): string => {
  const { user } = useUser()
  const { data: attributes } = useQuery([queryKeys.attributes, "document-structure"], async () =>
    attributesService.getAvailableDocumentStructureAttributes(null)
  )

  const { data: allFirms } = useQuery(
    [queryKeys.firms, "serialized"],
    async () => UserServiceDeserializer.fromFirmsUserManageJSON(await getFirms()),
    {
      enabled: user.isInternal,
    }
  )

  if (!allFirms || !attributes) return ""

  const type = attributeValues
    ? attributes
        .getAttributesTree(attributeValues)
        .map(({ value }) => ({ key: "", value: value?.value || "" }))
    : [{ key: "", value: "" }]

  const firmName = [{ key: "", value: allFirms?.firms.find(({ id }) => firmId === id)?.name || "" }]
  const documentStructureName = [{ key: "", value: documentName ?? "" }]

  const items = [...firmName, ...type, ...documentStructureName]

  return getDocumentStructureTitle(items)
}
