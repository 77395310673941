import { MouseEvent, forwardRef, useCallback, useState } from "react"
import { Button, Stack } from "@mui/material"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { IcdCode } from "documents/types"
import { IcdCodeComponent } from "./IcdCodeComponent"
import { IcdCodeForm } from "./IcdCodeForm"
import { AddIcdCodeIconButton } from "./styled"

export type IcdCodeChange = Pick<IcdCode, "code" | "relations"> & Partial<Pick<IcdCode, "id">>

export interface IcdCodesStackProps {
  icdCodes: IcdCode[]
  onChange: (icdCode: IcdCodeChange) => void
  onDelete: (icdCodeId: IcdCode["id"]) => void
  readOnly?: boolean
}

export const IcdCodesStack = forwardRef<HTMLDivElement, IcdCodesStackProps>(function IcdCodesStackComponent(
  { icdCodes, onChange, onDelete, readOnly },
  ref
) {
  const [anchorEl, setAnchorEl] = useState<Nullable<Nullable<HTMLButtonElement | HTMLDivElement>>>(null)
  const [currentEditingIcdCode, setCurrentEditingIcdCode] = useState<Nullable<IcdCode["id"]>>(null)

  const handleClick = useCallback((event: MouseEvent<Nullable<HTMLButtonElement | HTMLDivElement>>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setCurrentEditingIcdCode(null)
  }, [])

  const handleIcdCodeEditClick = useCallback(
    (event: MouseEvent<HTMLDivElement>, icdCodeId: IcdCode["id"]) => {
      setCurrentEditingIcdCode(icdCodeId)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleIcdCodeChange = useCallback(
    (icdCode: IcdCodeChange) => {
      onChange(icdCode)
      handleClose()
    },
    [onChange, handleClose]
  )

  const handleDelete = useCallback(
    (icdCodeId: IcdCode["id"]) => {
      onDelete(icdCodeId)
      handleClose()
    },
    [handleClose, onDelete]
  )

  return (
    <Stack
      flexWrap="wrap"
      direction="row"
      alignItems="center"
      spacing={1}
      position="relative"
      ref={ref}
      mb={readOnly ? 0 : 0.5}
    >
      {!readOnly &&
        (icdCodes.length ? (
          <AddIcdCodeIconButton color="secondary" size="small" onClick={handleClick}>
            <AddCircleOutlineOutlinedIcon />
          </AddIcdCodeIconButton>
        ) : (
          <Button
            sx={{ fontWeight: 700, paddingLeft: !icdCodes.length ? 1 : 0 }}
            color="secondary"
            size="small"
            onClick={handleClick}
            startIcon={<AddCircleOutlineOutlinedIcon sx={{ width: "24px", height: "24px" }} />}
          >
            {!icdCodes.length && "Add ICD Code"}
          </Button>
        ))}
      {icdCodes.map(icdCode => (
        <IcdCodeComponent
          key={icdCode.id}
          icdCode={icdCode}
          isEditing={icdCode.id === currentEditingIcdCode}
          onClick={handleIcdCodeEditClick}
          onDelete={handleDelete}
          readOnly={readOnly}
        />
      ))}
      {anchorEl && !readOnly && (
        <IcdCodeForm
          id={currentEditingIcdCode}
          anchorEl={anchorEl}
          open
          onClose={handleClose}
          onDelete={handleDelete}
          onSave={handleIcdCodeChange}
        />
      )}
    </Stack>
  )
})
