import { DataUpdatersMap } from "common/state/types"
import invariant from "invariant"
import { isUndefined } from "lodash"
import { fileUploadReducer, INITIAL_UPLOAD_STATE } from "../file-state"
import { FileUploaderAction, FILE_UPLOADER_ACTIONS_TYPE, FILE_UPLOADER_ACTIONS as ACTIONS } from "./actions"
import { FileUploaderData } from "./types"
import { AssignedUploadData } from "../file-state/types"

export function getFileStateForUploadId(uploadId: string, fileStates: FileUploaderData) {
  return Object.entries(fileStates)
    .filter(
      (fileState): fileState is [string, Exclude<FileUploaderData[string], undefined>] => !!fileState[1]
    )
    .find(
      ([, { state: fileUpload }]) => "uploadId" in fileUpload.data && fileUpload.data.uploadId === uploadId
    )
}

export const fileUploaderUpdaters: Required<
  DataUpdatersMap<FileUploaderData, FILE_UPLOADER_ACTIONS_TYPE, FileUploaderAction>
> = {
  [ACTIONS.UPLOAD_NEW_FILE]: function (state, payload) {
    invariant(isUndefined(state[payload.id]), "File with same id is already added to uploader")

    return {
      ...state,
      [payload.id]: {
        metadata: payload.metadata,
        state: INITIAL_UPLOAD_STATE,
      },
    }
  },
  [ACTIONS.UPDATE_UPLOAD_STATE]: function (state, payload) {
    const uploadState = state[payload.id]

    invariant(
      !isUndefined(uploadState) && !isUndefined(uploadState.state),
      `There is no such file to upload: ${JSON.stringify(payload)}`
    )

    const { state: fileState, metadata } = uploadState
    const nextFileState = { metadata, state: fileUploadReducer(fileState, payload.fileAction) }

    return { ...state, [payload.id]: nextFileState }
  },
  [ACTIONS.UNASSIGN_FILES]: function (state, { externalId }) {
    return Object.fromEntries(
      Object.entries(state)
        .filter(
          (fileState): fileState is [string, Exclude<FileUploaderData[string], undefined>] => !!fileState[1]
        )
        .map(([id, { state: fileState, metadata }]) => {
          if (externalId && (fileState.data as AssignedUploadData).externalId !== externalId) {
            return [id, { metadata, state: fileState }]
          }

          let possibleFileState = fileUploadReducer(fileState, {
            type: "UNASSIGN_FROM_EXTERNAL",
            payload: {},
          })

          if (possibleFileState.current !== "UNASSIGNED") {
            possibleFileState = fileUploadReducer(fileState, { type: "CANCEL", payload: {} })
          }

          return [id, { metadata, state: possibleFileState }]
        })
    )
  },
  [ACTIONS.DELETE_FILE]: function (state, payload) {
    const uploadState = state[payload.id]

    invariant(
      !isUndefined(uploadState) && !isUndefined(uploadState.state),
      `There is no such file to upload: ${JSON.stringify(payload)}`
    )

    const { state: fileState, metadata } = uploadState

    let possibleFileState = fileUploadReducer(fileState, { type: "DELETE", payload: {} })

    if (possibleFileState.current !== "DELETED") {
      possibleFileState = fileUploadReducer(fileState, { type: "CANCEL", payload: {} })
    }

    return {
      ...state,
      [payload.id]: { metadata, state: possibleFileState },
    }
  },
  [ACTIONS.ASSIGN_UPLOAD]: function (state, payload) {
    const { uploadId, externalId } = payload
    const entry = getFileStateForUploadId(uploadId, state)

    if (!entry) {
      return state
    }

    const [id, { metadata, state: fileState }] = entry
    const nextFileState = fileUploadReducer(fileState, {
      type: "ASSIGN_WITH_EXTERNAL",
      payload: { externalId },
    })

    return {
      ...state,
      [id]: { metadata, state: nextFileState },
    }
  },
}
