import { IconButton, Popover, Typography } from "@mui/material"
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { useMemo, useRef, useState } from "react"
import { formatDate } from "utils"
import EventIcon from "@mui/icons-material/Event"
import { ButtonContainer, Dash, InputContainer, PopupContent, StyledDateField } from "./styled"

enum SELECTED_DATE {
  START,
  END,
}

function isDateRangeValid(startDate: string, endDate: string) {
  return new Date(startDate) <= new Date(endDate)
}

export function DateRangePicker({
  startDate,
  endDate,
  readOnly,
  onChange,
}: {
  startDate: string
  endDate: string
  readOnly?: boolean
  onChange: (date: { start: string; end: string }) => void
}) {
  const endDateInputRef = useRef<HTMLInputElement>(null)
  const [selectedDate, setSelectedDate] = useState<SELECTED_DATE>(SELECTED_DATE.START)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [errorMessage, setErrorMessage] = useState("")
  const currentStartDate = useRef<string | Date | null>(startDate)
  const currentEndDate = useRef<string | Date | null>(endDate)

  const currentDate = useMemo(
    () => (selectedDate === SELECTED_DATE.START ? startDate : endDate),
    [selectedDate, startDate, endDate]
  )

  const openPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (readOnly) {
      return
    }
    setAnchorEl(event.currentTarget)
  }
  const closePopup = () => {
    setAnchorEl(null)
    setErrorMessage("")
  }

  const setDate = (start: string | Date, end: string | Date) => {
    const startDateString = formatDate(start, "yyyy-MM-dd", true)
    const endDateString = formatDate(end, "yyyy-MM-dd", true)

    if (isDateRangeValid(startDateString, endDateString)) {
      onChange({ start: startDateString, end: endDateString })
      setErrorMessage("")
    } else {
      setErrorMessage("End date must be after start date")
    }
  }

  const handleStartDateChange = (date: string | Date | null) => {
    currentStartDate.current = date
    if (date && currentEndDate.current) {
      setDate(date, currentEndDate.current)
    }
  }

  const handleEndDateChange = (date: string | Date | null) => {
    currentEndDate.current = date
    if (date && currentStartDate.current) {
      setDate(currentStartDate.current, date)
    }
  }

  const handleCalendarChange = (date: Date | null) => {
    if (!date) return

    if (selectedDate === SELECTED_DATE.START) {
      handleStartDateChange(date)
      endDateInputRef.current?.focus()
      setSelectedDate(SELECTED_DATE.END)
    } else {
      handleEndDateChange(date)
    }
  }

  const startDateText = formatDate(startDate, "MM/dd/yyyy", true)
  const endDateText = formatDate(endDate, "MM/dd/yyyy", true)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ButtonContainer>
        <IconButton disabled={readOnly} onClick={openPopup} aria-label="open date picker">
          <EventIcon fontSize="small" />
        </IconButton>
        <Typography variant="body2" data-test="display-date">
          {startDate === endDate ? startDateText : `${startDateText} - ${endDateText}`}
        </Typography>
      </ButtonContainer>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopupContent data-test="date-picker-content">
          <Typography display="block" variant="overline" mb={2}>
            Select Date of Service Range:
          </Typography>
          <InputContainer>
            <StyledDateField
              size="small"
              initialValue={startDate}
              onChange={handleStartDateChange}
              label="Start Date"
              onFocus={() => setSelectedDate(SELECTED_DATE.START)}
              disableOpenPicker
              isHighlighted={selectedDate === SELECTED_DATE.START}
            />
            <Dash />
            <StyledDateField
              size="small"
              initialValue={endDate}
              onChange={handleEndDateChange}
              label="End Date"
              onFocus={() => setSelectedDate(SELECTED_DATE.END)}
              inputRef={endDateInputRef}
              disableOpenPicker
              isHighlighted={selectedDate === SELECTED_DATE.END}
            />
          </InputContainer>
          {errorMessage && (
            <Typography variant="helper" color="error" component="div" mt={2}>
              {errorMessage}
            </Typography>
          )}
        </PopupContent>
        <DateCalendar value={new Date(`${currentDate} 00:00:00`)} onChange={handleCalendarChange} />
      </Popover>
    </LocalizationProvider>
  )
}
