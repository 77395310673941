import { useMemo } from "react"
import { ASSET_KEYS, AssetKey, INJURY_IMPACTS_KEYS } from "requests/constants"
import { NewAssetProps, NonEconomicProps } from "./types"
import { MultiSelectAutoComplete, OptionProps } from "evenup-ui/MultiSelectAutoComplete"
import { useRequestAssets } from "requests/RequestAssets"
import { getInjuryImpactDisplay, removeInjuryInInjuryImpactKey } from "./utils"
import { REQUEST_FORM_VARIANTS } from "./constants"
import { useController } from "react-hook-form"
import { REQUEST_FORM_FIELDS } from "../constants"

const MultiSelectAssets = ({
  formKey,
  label,
  options,
  activeTab,
  variant,
}: {
  formKey: string
  label: string
  options: OptionProps[]
  activeTab: number
  variant?: REQUEST_FORM_VARIANTS
}) => {
  const fieldName = `${REQUEST_FORM_FIELDS.PLAINTIFFS}.${activeTab}.${formKey}`
  const {
    field: { value, onChange },
  } = useController({
    name: fieldName,
  })

  const autoCompleteValues = useMemo(() => {
    return value.map((val: string) => options.find(option => option.value === val))
  }, [value, options])

  return (
    <MultiSelectAutoComplete
      label={`Impact on ${label}`}
      options={options}
      enableClear={true}
      noOptionsText="No results"
      autoCompletePlaceholderText=""
      value={autoCompleteValues}
      onChangeValue={newValue => onChange(newValue.map(({ value }) => value))}
      onClear={() => onChange([])}
      size={variant === REQUEST_FORM_VARIANTS.SINGLE_PAGE_REQUEST ? "small" : "medium"}
      name={fieldName}
    />
  )
}

export const NonEconomicDamages = ({ activeTab, variant }: NonEconomicProps) => {
  const assets = useRequestAssets()
  const newAssets = useMemo(() => {
    let tempAssets: NewAssetProps[] = []
    Object.entries(INJURY_IMPACTS_KEYS).forEach(([injuryKey, injuryValue]) => {
      if (!(injuryKey in ASSET_KEYS)) return

      const asset =
        assets[ASSET_KEYS[injuryKey as AssetKey]]?.map(({ display, key }) => ({
          label: display,
          value: key,
        })) ?? []

      const newInjuryKey = removeInjuryInInjuryImpactKey(injuryValue.key)

      tempAssets = [
        ...tempAssets,
        {
          label: getInjuryImpactDisplay(injuryValue.display),
          options: asset,
          formKey: newInjuryKey,
        },
      ]
    })
    return tempAssets
  }, [assets])

  return (
    <>
      {newAssets.map(({ label, options, formKey }: NewAssetProps) => (
        <MultiSelectAssets
          key={`${activeTab}-${formKey}`}
          label={label}
          options={options}
          formKey={formKey}
          activeTab={activeTab}
          variant={variant}
        />
      ))}
    </>
  )
}
