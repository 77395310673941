import React, { useCallback, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { VerticalCenterBox } from "common/FlexBox"
import { FileUploaderData } from "../uploader-state/types"
import { FILE_UPLOAD_STATE, FILE_UPLOAD_STATE_TYPE } from "../file-state/states"
import { FileUploadItem } from "./FileUploadItem"
import {
  CloseButton,
  FileList,
  FileListContainer,
  FilesAccordion,
  UploadSummary,
  UploadViewRoot,
  UploadViewRootProps,
} from "./styled"
import { getTitlesForItems } from "./utils"

export type FileUploadViewProps = {
  data: FileUploaderData
  uploadBoxHidden?: boolean
} & UploadViewRootProps

export const FileUploadView: React.FC<FileUploadViewProps> = ({
  data,
  uploadBoxPosition,
  uploadBoxHidden,
}) => {
  const [hiddenItems, setHiddenItems] = useState<string[]>([])
  const [isExpanded, setIsExpanded] = useState(false)
  const cacheKey = Object.keys(data).join(",")
  const entries = Object.entries(data).filter(
    (fileState): fileState is [string, Exclude<FileUploaderData[string], undefined>] => !!fileState[1]
  )
  const visibleEntries = entries.filter(([id]) => !hiddenItems.includes(id))
  const hasUploadingItems = visibleEntries.some(([, { state }]) => {
    const progressStates: (keyof FILE_UPLOAD_STATE_TYPE)[] = [
      FILE_UPLOAD_STATE.CREATING_UPLOAD,
      FILE_UPLOAD_STATE.PREPARING_FOR_UPLOAD,
      FILE_UPLOAD_STATE.UPLOADING_CHUNKS,
    ]

    return progressStates.includes(state.current)
  })

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded, setIsExpanded])

  const hideCompletedItems = useCallback(() => {
    !hasUploadingItems && setHiddenItems(Object.keys(data))
  }, [data, setHiddenItems, hasUploadingItems])

  useEffect(() => {
    setIsExpanded(true)
  }, [cacheKey])

  if (visibleEntries.length === 0) {
    return null
  }

  const titles = [...getTitlesForItems(visibleEntries)]
  if (uploadBoxHidden) {
    return undefined
  }
  return ReactDOM.createPortal(
    <UploadViewRoot uploadBoxPosition={uploadBoxPosition}>
      <FilesAccordion expanded={isExpanded}>
        <UploadSummary onClick={toggleIsExpanded}>
          <VerticalCenterBox>
            {!hasUploadingItems && (
              <CloseButton onClick={hideCompletedItems} data-test="close-uploading-info" />
            )}
            {titles.map((title, i) => (
              <span data-test="upload-completion" key={i}>
                {title}
              </span>
            ))}
          </VerticalCenterBox>
        </UploadSummary>

        <FileListContainer>
          <FileList>
            {visibleEntries.map(([id, { state, metadata }]) => (
              <FileUploadItem state={state} metadata={metadata} key={id} />
            ))}
          </FileList>
        </FileListContainer>
      </FilesAccordion>
    </UploadViewRoot>,
    document.body
  )
}
