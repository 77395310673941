import MuiAlert, { AlertColor } from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(theme => ({
  alert: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  title: string
  severity: AlertColor
  children: JSX.Element[] | JSX.Element | string
}

const Alert = ({ title, severity = "warning", children }: Props): JSX.Element => {
  const { classes } = useStyles()

  return (
    <MuiAlert severity={severity} className={classes.alert}>
      <AlertTitle>{title}</AlertTitle>
      {children}
    </MuiAlert>
  )
}

export { Alert as default }
