import { useState, useEffect, useRef } from "react"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import CloudUpload from "@mui/icons-material/CloudUpload"
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline"
import { makeStyles } from "tss-react/mui"
import splitFileName from "./form-components/files/splitFileName"

const useStyles = makeStyles()(theme => ({
  imageGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(120px, 360px));",
    gridGap: theme.spacing(2),

    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  imageContainer: {
    position: "relative",
  },
  removeImage: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  fileExtension: {
    position: "relative",
    top: "2em",
    marginLeft: "5px",
  },
}))

export default function ExhibitUploader({
  multiple = true,
  onUpload,
  filesWithPreview: images = [],
  setFilesWithPreview: setImages,
  buttonText = null,
}) {
  const { classes } = useStyles()
  const fileRef = useRef()
  const [imagePreviews, setImagePreviews] = useState([])

  useEffect(() => {
    // create image previews
    const filesWithPreview = images.map(image => {
      return {
        ...image,
        preview: URL.createObjectURL(image.file),
        name: image.file.name,
      }
    })
    setImagePreviews(filesWithPreview)

    return () => filesWithPreview.forEach(file => URL.revokeObjectURL(file.preview))
  }, [images])

  const removeImage = index => {
    const newImages = [...imagePreviews]
    newImages.splice(index, 1)
    setImages(newImages)
  }

  const addImage = event => {
    if (event.currentTarget.files.length) {
      const newImages = Array.from(event.currentTarget.files).map(file => {
        return {
          file,
        }
      })

      setImages([...images, ...newImages])
    }
  }

  const finishUploadingImages = async () => {
    typeof onUpload === "function" && (await onUpload(images))
    setImagePreviews([])
  }

  const setFileName = (e, index) => {
    const name = e.currentTarget.value
    setImages(prevImages => {
      return prevImages.map((image, i) => {
        if (i === index) {
          return { ...image, name }
        } else {
          return image
        }
      })
    })
  }

  return (
    <Box>
      <input
        type="file"
        accept="image/*"
        ref={fileRef}
        onChange={addImage}
        style={{ display: "none" }}
        multiple={multiple}
      />
      <Button
        onClick={() => fileRef.current.click()}
        variant="outlined"
        color="primary"
        startIcon={<CloudUpload />}
      >
        {buttonText || `${onUpload ? "Upload" : "Attach"} ${multiple ? "image(s)" : "image"}`}
      </Button>
      {!!imagePreviews?.length && (
        <>
          <Box className={classes.imageGrid}>
            {imagePreviews.map((file, index) => {
              const [fileName, ext] = splitFileName(file.file.name)

              return (
                <Box className={classes.imageContainer} key={`${file.file.name}-${index}`}>
                  <IconButton
                    className={classes.removeImage}
                    style={{ background: "white" }}
                    onClick={() => removeImage(index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <img src={file.preview}></img>
                  <TextField
                    label="File name"
                    name="name"
                    variant="outlined"
                    onChange={e => setFileName(e, index)}
                    InputLabelProps={{ shrink: true }}
                    defaultValue={fileName}
                  />
                  <span className={classes.fileExtension}>{ext}</span>
                </Box>
              )
            })}
          </Box>
          {onUpload && (
            <Button variant="contained" color="secondary" onClick={finishUploadingImages}>
              Done Adding Images
            </Button>
          )}
        </>
      )}
    </Box>
  )
}
