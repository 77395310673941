import React, { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { attributesService } from "api/services/attributes"
import { caseService } from "api/services/case"
import { useDemandSteps } from "demand/steps"
import { FORM_SECTION_ROUTES } from "demand/steps/constants"
import { CASE_ATTRIBUTES_STEP } from "demand/steps/types"
import { Templates } from "./Templates"
import { getFirmExtended } from "api"
import useUser from "hooks/useUser"
import { OSF } from "common/models/roles"

interface TemplatesProviderProps {
  lastVisited: React.MutableRefObject<string>
}

export function TemplatesProvider({ lastVisited }: TemplatesProviderProps): JSX.Element {
  const { user } = useUser()
  const { caseId } = useDemandSteps()
  const { data: attributes } = useQuery([queryKeys.attributes], async () =>
    attributesService.getAvailableAttributes(null)
  )
  const { data: caseInfo } = useQuery(
    [queryKeys.case, caseId, "-serialized"],
    () => caseService.getCase({ caseId: caseId as PrimaryKey }),
    { enabled: caseId !== null }
  )

  const hasFirm = caseInfo && caseInfo.firm !== null && caseInfo.firm.pk !== null
  const shouldRetrieveFirm = hasFirm && user.role !== OSF

  const { data: firmInfo } = useQuery([queryKeys.firm, caseInfo?.firm?.pk], getFirmExtended, {
    enabled: shouldRetrieveFirm,
  })

  useEffect(() => {
    lastVisited.current = FORM_SECTION_ROUTES[CASE_ATTRIBUTES_STEP]
  }, [lastVisited])

  if (!attributes || !caseInfo || (shouldRetrieveFirm && !firmInfo)) {
    return <></>
  }

  return <Templates attributes={attributes} caseInfo={caseInfo} firmInfo={firmInfo} />
}
