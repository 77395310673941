import { SortableNestedListItemProps } from "common/nested-list/SortableNestedListItem"
import { PropsWithChildren, useContext, useMemo } from "react"
import { DocumentStructureHeading } from "../types"
import { SectionContext, SectionContextType } from "../section/context"
import { StyledBlockListItem } from "../styled"

export function HeadingListItem({
  children,
  ...props
}: PropsWithChildren<SortableNestedListItemProps<DocumentStructureHeading>>): JSX.Element {
  const repeat = props.item.repeat
  const parentContext = useContext(SectionContext)
  const contextValue = useMemo<SectionContextType>(
    () => ({
      ...parentContext,
      sectionId: props.item.sectionId,
      repeat: parentContext.repeat || repeat,
    }),
    [props.item.sectionId, parentContext, repeat]
  )

  return (
    <StyledBlockListItem {...props}>
      <SectionContext.Provider value={contextValue}>{children}</SectionContext.Provider>
    </StyledBlockListItem>
  )
}
