import React, { useState } from "react"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"

import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { OutlinedButton } from "./styled"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { Button } from "@mui/material"
import { HeaderField } from "./HeaderField"
import { Variable } from "common/types/variables"
import { useCaseVariables } from "demand/Variables"

const useStyles = makeStyles()(theme => ({
  contentWrapper: {
    backgroundColor: theme.palette.blue.contentBox,
    padding: theme.spacing(1),
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.blue.contentBoxHover,
    },
  },
  readonlyWrapper: {
    cursor: "pointer",
  },
  textEditor: {
    "& > *": {
      background: "white",
    },
  },
}))

interface ResultContentProps {
  result: Nullable<EditorRoot>
  onSave: (content: EditorRoot, header?: string) => void
  header?: string
  onResultEdit?: (content: EditorRoot) => void
  draggableProps?: Nullable<DraggableProvidedDragHandleProps>
  actions?: JSX.Element | JSX.Element[]
  variables?: Variable[]
}

export const ResultContent = React.forwardRef<RichTextEditor, ResultContentProps>(function ResultContent(
  { result, header, onSave, onResultEdit, draggableProps, actions, variables: customVariables },
  ref
): JSX.Element {
  const { classes } = useStyles()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [editedResult, setEditedResult] = useState<Nullable<EditorRoot>>(result)
  const [editedHeader, setEditedHeader] = useState<string>(header ?? "")
  const { variables } = useCaseVariables(customVariables)

  const showHeader = header !== undefined

  const handleReadOnlyClick = () => {
    setIsEditing(true)
  }

  const handleOnChange = (value: EditorRoot) => {
    setEditedResult(value)
    onResultEdit && onResultEdit(value)
  }

  const handleOnChangeHeader = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEditedHeader(e.target.value)
  }

  const handleOnCancel = () => {
    setIsEditing(false)
    setEditedResult(result)
    setEditedHeader(header ?? "")
  }

  const handleOnSave = () => {
    setIsEditing(false)
    if (editedResult) {
      onSave(editedResult, editedHeader)
    }
  }

  if (!result) return <></>

  return (
    <Box className={classes.contentWrapper}>
      <Box display="flex" padding={1}>
        {!!draggableProps && (
          <Box
            {...draggableProps}
            mr={2}
            mt={1}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <DragIndicatorIcon />
          </Box>
        )}
        {isEditing && (
          <>
            <Box display="flex" flexDirection="column" flexGrow="1">
              {showHeader && (
                <Box mb={2} maxWidth="sm">
                  <HeaderField editing={true} header={editedHeader} onChange={handleOnChangeHeader} />
                </Box>
              )}
              <Box className={classes.textEditor}>
                <RichTextEditor
                  value={editedResult}
                  onChange={handleOnChange}
                  keepValue
                  withVariables
                  variables={variables}
                />
              </Box>
            </Box>
            <Box display="flex" ml="auto" mt={1} gap={2}>
              <Box>
                <Button onClick={handleOnCancel}>Cancel</Button>
              </Box>
              <Box>
                <OutlinedButton onClick={handleOnSave} variant="outlined">
                  Save
                </OutlinedButton>
              </Box>
            </Box>
          </>
        )}
        {!isEditing && (
          <>
            <Box className={classes.readonlyWrapper} onClick={handleReadOnlyClick} flexGrow="1">
              {header && showHeader && (
                <Box alignItems="center" fontSize="1rem" mt={1} data-wow="1">
                  <HeaderField editing={false} header={header} />
                </Box>
              )}
              <Box alignItems="center" fontSize="1rem" mt={1}>
                <RichTextEditor ref={ref} value={editedResult} withVariables variables={variables} readonly />
              </Box>
            </Box>
            <Box ml="auto" display="flex" alignItems="flex-start">
              {actions}
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
})
