import styled from "@emotion/styled"
import { Box, IconButton } from "@mui/material"
import TextButton from "common/buttons/TextButton"

export const DescriptionCell = styled(Box)(() => ({
  textOverflow: "wrap",
}))

export const InterventionalTreatmentEditableRowHeader = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr 2fr 5fr 1fr .5fr",
  marginBottom: theme.spacing(1),
  gap: theme.spacing(2),
}))

export const InterventionalTreatmentEditableRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr 2fr 5fr 1fr .5fr",
  marginBottom: theme.spacing(2),
  gap: theme.spacing(2),
  alignItems: "start",
}))

export const ReferencesEditableList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  gap: theme.spacing(1),
}))

export const ReferenceEditableRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "4fr 1fr",
  gap: theme.spacing(1),
}))

export const InterventionalTreatmentReadonlyRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 3fr 3fr",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
}))

export const AddReferenceButton = styled(TextButton)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

export const DeleteInterventionalTreatmentButton = styled(IconButton)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))
