import styled from "@emotion/styled"
import { Box, Typography } from "@mui/material"
import { theme } from "app/theme"

export const Row = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  display: "flex",
}))

export const SectionTitle = styled(Typography)(() => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}))

export const ReceiptGenerationContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey.A400}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(3),
  width: "fit-content",
}))

export const ReceiptGenerationContainerItem = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2.5),
  marginBottom: theme.spacing(2),
}))
