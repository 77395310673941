import { Range } from "./types"

export const displayPageRange = (startPage: number, endPage?: number | null): string => {
  if (startPage === undefined || startPage === null) {
    return ""
  }

  if (endPage === undefined || endPage === null) {
    return String(startPage)
  }

  if (endPage === startPage) {
    return String(startPage)
  }
  return `${startPage} - ${endPage}`
}

export const hasEndOverlap = (first: Range, second: Range): boolean =>
  first.end >= second.start && first.end <= second.end && first.start < second.start

export const isEncompassing = (large: Range, small: Range): boolean =>
  large.start <= small.start && large.end >= small.end

export const hasOverlap = (range1: Range, range2: Range): boolean => {
  return range1.start <= range2.end && range2.start <= range1.end
}
