import { useMemo, useReducer } from "react"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { makeStyles } from "tss-react/mui"
import { UserServiceDeserializer } from "api/services/users/serializers"
import { getFirms } from "api"
import { userService } from "api/services/users"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"
import useUser from "hooks/useUser"
import { canEditLibrary, canEditLibraryByFirmsUserManage } from "settings/permissions"
import { Skeleton } from "@mui/material"
import { sectionTemplateService } from "api/services/section-template"

import { DocumentStructureFirmTemplates } from "../TemplateForms/formData/document-structure/DocumentStructureFirmTemplates"
import { templatesTabReducer } from "../State/templatesReducer"
import { INITIAL_TEMPLATES_TAB_STATE, LibraryTabStateContext } from "../State/constants"
import { NewSectionTemplate } from "../Forms/NewSectionTemplate"
import { StyledBackButton, StyledFirmHeaderWrapper, StyledFirmName } from "./styled"
import { isNil } from "lodash"
import { FIRMS_SECTION_TEMPALATES } from "./constants"

interface FirmSectionTemplatesProps {
  firmId: PrimaryKey
  onBack: () => void
  page: string
}

const useStyles = makeStyles()(theme => ({
  newSectionTemplate: {
    marginBottom: theme.spacing(1),
  },
}))

export function FirmSectionTemplates({
  firmId,
  onBack,
  page,
}: FirmSectionTemplatesProps): Nullable<JSX.Element> {
  const [state, dispatch] = useReducer(templatesTabReducer, INITIAL_TEMPLATES_TAB_STATE)
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])
  const { classes } = useStyles()
  const { data: allFirms } = useQuery([queryKeys.firms, "serialized"], async () =>
    UserServiceDeserializer.fromFirmsUserManageJSON(await getFirms())
  )
  const { data: templates } = useQuery(
    [queryKeys.sectionTemplatesByFirm, firmId],
    () => sectionTemplateService.getTemplatesListByFirm({ firmId }),
    {
      enabled: !isNil(firmId),
    }
  )
  const initialTemplateType = new URLSearchParams(page.replace(FIRMS_SECTION_TEMPALATES, "")).get(
    "templateType"
  )
  const { user } = useUser()
  const firmTemplateFeature = isFeatureEnabled(FEATURES.FIRM_TEMPLATE)
  const { data: firmsUserManage } = useQuery(
    [queryKeys.firmsUserManage, user.id],
    () => userService.getFirmsUserManage(null),
    {
      enabled: user.isInternal && firmTemplateFeature,
    }
  )

  const canEdit = firmTemplateFeature
    ? canEditLibraryByFirmsUserManage(user.role, firmsUserManage)
    : canEditLibrary(user.role)

  const firmName = allFirms?.firms.find(({ id }) => id === firmId)?.name ?? ""

  if (!allFirms || !templates) return <Skeleton height={118} animation={false} />

  return (
    <LibraryTabStateContext.Provider value={contextValue}>
      <StyledFirmHeaderWrapper>
        <StyledBackButton onClick={onBack}>{"< Back"}</StyledBackButton>
        <StyledFirmName>{`${firmName} Templates`}</StyledFirmName>
      </StyledFirmHeaderWrapper>
      {canEdit && <NewSectionTemplate className={classes.newSectionTemplate} initialFirmId={firmId} />}
      {Object.entries(templates).map(([templatesType, sectionTemplates]) => (
        <DocumentStructureFirmTemplates
          key={templatesType}
          canEdit={canEdit}
          initialExpanded={initialTemplateType === templatesType}
          templatesType={templatesType}
          templates={sectionTemplates}
        />
      ))}
    </LibraryTabStateContext.Provider>
  )
}
