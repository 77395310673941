import { useMemo } from "react"
import { StyledSupportingFileName, StyledSupportingFileTable, SupportingFileTableCell } from "../styled"
import {
  Box,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material"
import { DescriptionOutlined } from "@mui/icons-material"
import { SupportingFileProps } from "../types"
import DownloadIcon from "@mui/icons-material/GetApp"
import { usePermissions } from "permissions/usePermissions"
import { formatDate } from "utils"
import { DownloadAllFilesButton } from "./DownloadAllFilesButton"
import { downloadRequestFiles } from "api"
import { UPLOADED } from "requests/constants"
import { assignedToUser } from "requests/utils"
import useUser from "hooks/useUser"
import { OSF } from "common/models/roles"
import { StartAnnotationButton } from "./StartAnnotationButton"
import { DOCUMENT_TYPES } from "requests/enums"
import { getFirstPlaintiffName } from "requests/plaintiffUtils"
import Paper from "evenup-ui/Paper"
import { useLocationStore } from "app/location/store"

function getFileName(firstName: string, lastName: string) {
  if (firstName && lastName) {
    return `${firstName}_${lastName}_files`
  }
  if (firstName || lastName) {
    return [firstName, lastName, "files"].filter(v => v).join("_")
  }
  return `evenup_demand_all_files`
}

export const SupportingFile = ({ files, request }: SupportingFileProps) => {
  const { user } = useUser()
  const theme = useTheme()
  const isCases = useLocationStore(location => location.pathname.startsWith("/cases"))

  const { medicalChronologyAutoAnnotationEnabled } = usePermissions()
  const totalPages = useMemo(
    () =>
      files.reduce((total, file) => {
        return total + (file.number_of_pages ?? 0)
      }, 0),
    [files]
  )

  const downloadAllFileName = useMemo(() => {
    const plaintiffName = getFirstPlaintiffName(request)
    return getFileName(plaintiffName.firstName, plaintiffName.lastName)
  }, [request])

  const isOSF = user.role === OSF
  const obscureAttachedFiles = isOSF && !assignedToUser({ user, request })
  const obscureAttachedFilesReason = "You must assign yourself to see the attached files list."
  const shouldShowStartAnnotationButton =
    !isCases &&
    !medicalChronologyAutoAnnotationEnabled &&
    !!request?.pk &&
    [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY, DOCUMENT_TYPES.PREMIUM_MEDCHRON].includes(request.type)

  return (
    <>
      {files.length > 0 && (
        <Box marginBottom={theme.spacing(2)}>
          <DownloadAllFilesButton
            requestId={request.pk}
            fileName={downloadAllFileName}
            downloadAllEndpoint={downloadRequestFiles}
            asyncZipAndDownload={true}
            type={UPLOADED}
            disabled={obscureAttachedFiles}
            text="Download All"
          />
        </Box>
      )}
      {obscureAttachedFiles && <Typography>{obscureAttachedFilesReason}</Typography>}
      {!obscureAttachedFiles && (
        <Paper sx={{ padding: 0 }}>
          <StyledSupportingFileTable data-test="supporting-files-table" aria-label="supporting files table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body2SemiBold">File Name</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2SemiBold">Date Uploaded</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2SemiBold">Pages</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2SemiBold">Download</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map(({ pk, name, number_of_pages, file_url, date_created }) => (
                <TableRow key={pk}>
                  <SupportingFileTableCell>
                    <StyledSupportingFileName>
                      <DescriptionOutlined />
                      {name}
                    </StyledSupportingFileName>
                  </SupportingFileTableCell>
                  <SupportingFileTableCell data-test="column-id-date_created" align="left">
                    {date_created ? formatDate(date_created) : "-"}
                  </SupportingFileTableCell>
                  <SupportingFileTableCell align="left">{number_of_pages}</SupportingFileTableCell>
                  <SupportingFileTableCell align="left">
                    <a download href={file_url}>
                      <IconButton>
                        <DownloadIcon />
                      </IconButton>
                    </a>
                  </SupportingFileTableCell>
                </TableRow>
              ))}
              <TableRow key="summary">
                <TableCell sx={{ border: "none" }}>
                  <strong>Total: {files?.length ?? 0} Files</strong>
                </TableCell>
                <TableCell sx={{ border: "none" }} />
                <TableCell sx={{ border: "none" }} align="left">
                  <strong>{totalPages} Pages</strong>
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  {shouldShowStartAnnotationButton && <StartAnnotationButton requestId={request.pk} />}
                </TableCell>
              </TableRow>
            </TableBody>
          </StyledSupportingFileTable>
        </Paper>
      )}
    </>
  )
}
