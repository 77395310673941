import { PaginationRenderItemParams } from "@mui/material/Pagination"
import { StyledCurrentPaginationItem, StyledPaginationArrowItem, StyledPaginationItem } from "./styled"

type PaginationItemProps = PaginationRenderItemParams

export function PaginationItem(props: PaginationItemProps): JSX.Element {
  if (props.type === "next" || props.type === "previous") {
    return <StyledPaginationArrowItem {...props} size="medium" />
  }

  if (props.selected) {
    return <StyledCurrentPaginationItem {...props} selected={false} />
  }

  return <StyledPaginationItem {...props} />
}
