import { RevisionRequest } from "./revision-request"
import { User } from "./user"

export interface RevisionRequestEventFields {
  internal: boolean
  comment: Nullable<string>
  revisionRequest: RevisionRequest
  creator: User
  createdAt: string
}

export class RevisionRequestEvent implements Required<BaseEntity>, RevisionRequestEventFields {
  constructor(
    readonly pk: PrimaryKey,
    readonly internal: RevisionRequestEventFields["internal"],
    readonly comment: RevisionRequestEventFields["comment"],
    readonly createdAt: RevisionRequestEventFields["createdAt"],
    readonly revisionRequest: RevisionRequestEventFields["revisionRequest"],
    readonly creator: RevisionRequestEventFields["creator"]
  ) {}
}

export class RevisionRequestEventList {
  constructor(readonly items: RevisionRequestEvent[]) {}

  private getLatestEvent(): RevisionRequestEvent | undefined {
    return this.items[this.items.length - 1]
  }

  get hasActiveEvent(): boolean {
    const latestEvent = this.getLatestEvent()

    return latestEvent ? latestEvent.revisionRequest.isActive : false
  }

  get activeEvent(): RevisionRequestEvent | undefined {
    return this.hasActiveEvent ? this.getLatestEvent() : undefined
  }

  get completedEvents(): RevisionRequestEvent[] {
    return this.items.filter(({ revisionRequest }) => revisionRequest.completedAt !== null).reverse()
  }
}
