import React, { KeyboardEvent } from "react"
import { makeStyles } from "tss-react/mui"
import { ExhibitGroup } from "./Exhibit"
import Button from "@mui/material/Button"
import DoneIcon from "@mui/icons-material/Done"
import { useForm } from "react-hook-form"
import { InputField } from "../../common/form-components"

const useStyles = makeStyles()(theme => ({
  form: {
    padding: theme.spacing(1),
  },
  groupTitleField: {
    marginRight: theme.spacing(1),
  },
}))

interface ExhibitsListGroupFormProps {
  item: ExhibitGroup
  onChange: (item: ExhibitGroup) => void
  onClose: () => void
}

export const ExhibitsListGroupForm: React.FC<ExhibitsListGroupFormProps> = ({ item, onChange, onClose }) => {
  const { classes } = useStyles()
  const { control, handleSubmit, getValues } = useForm<ExhibitGroup>({
    defaultValues: item,
    mode: "onSubmit",
  })

  const onSubmit = handleSubmit(() => {
    const updatedItem = {
      ...item,
      ...getValues(),
    }

    onChange(updatedItem)
  })

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      onClose()
    }
  }

  return (
    <form className={classes.form} onSubmit={onSubmit} onKeyDown={handleKeyDown}>
      <InputField
        control={control}
        name="name"
        type="text"
        variant="standard"
        size="small"
        className={classes.groupTitleField}
        autoFocus
      />
      <Button type="submit" size="small" startIcon={<DoneIcon />} variant="outlined">
        Rename
      </Button>
    </form>
  )
}
