import { SelectInput } from "common/form-components"
import { RequestFormModule } from "./RequestFormModule"
import { StyledEntityWrapper, StyledEntityRow } from "./styled"
import { useRequestContext } from "requests/context"
import useUser from "hooks/useUser"
import { useFormContext } from "react-hook-form"

export const FirmInformation = ({ readOnly }: { readOnly?: boolean }) => {
  const { firmsOptions } = useRequestContext()
  const { control } = useFormContext()
  const { user } = useUser()
  if (!user.isInternal) {
    return <></>
  }

  return (
    <RequestFormModule title="Firm Selection">
      <StyledEntityWrapper>
        <StyledEntityRow>
          <SelectInput
            name="firm_id"
            data-test="firm-id"
            size="small"
            options={firmsOptions ?? []}
            label="Firm"
            control={control}
            required
            readOnly={readOnly}
          />
        </StyledEntityRow>
      </StyledEntityWrapper>
    </RequestFormModule>
  )
}
