import AddToQueueIcon from "@mui/icons-material/AddToQueue"
import { Box, Divider, Typography } from "@mui/material"
import { DOCUMENT_TYPES } from "requests/enums"
import { ViewMedicalChronologyProps } from "../types"
import { StyledIconWrapper, StyledViewMedicalChronologyWrapper } from "./styled"
import { useQuery } from "@tanstack/react-query"
import { documentsService } from "api/services/documents"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
import { formatDate } from "utils"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { StyledMedChronButton } from "requests/ViewRequest/components/styled"
import { Link } from "react-router-dom"
import { INTAKE_STATUSES } from "requests/constants"
import { PREMIUM_MEDCHRON_PRODUCT_TYPE } from "common/interfaces"

export const ViewMedicalChronologySection = ({ request }: ViewMedicalChronologyProps) => {
  const primaryPlaintiff = request.plaintiffs[0] ?? {}

  const { data } = useQuery(
    [queryKeys.documentMedchronTile, request.document_id],
    () => {
      if (request.document_id) {
        return documentsService.getMedchronTile({ documentId: request.document_id })
      }
    },
    {
      ...SILENT_QUERY_PARAMS,
    }
  )

  if (![DOCUMENT_TYPES.MEDICAL_CHRONOLOGY, DOCUMENT_TYPES.PREMIUM_MEDCHRON].includes(request.type) || !data) {
    return null
  }

  return (
    <>
      <StyledViewMedicalChronologyWrapper>
        <Box display="flex" gap={5}>
          <StyledIconWrapper>
            <AddToQueueIcon sx={{ color: "white", fontSize: "76px" }} />
          </StyledIconWrapper>
          <Box margin="auto">
            <Typography fontWeight={700} fontSize="24px">
              {data.questionnaireType === PREMIUM_MEDCHRON_PRODUCT_TYPE ? "Premium" : null} MedChron:{" "}
              {primaryPlaintiff.first_name} {primaryPlaintiff.last_name}
            </Typography>
            <Typography fontSize="14px">
              Treatment period:{" "}
              <b>
                {formatDate(data.startDate, "MM/dd/yyyy", true)} -{" "}
                {formatDate(data.endDate, "MM/dd/yyyy", true)}
              </b>
            </Typography>
            <Typography fontSize="14px">
              Number of appointments: <b>{data.numberOfAppointments}</b>
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-end">
          <StyledMedChronButton
            component={Link}
            data-test="go-to-demand-button"
            to={`/documents/${request.document_id}/view-medical-chronology/`}
            disabled={request.intake_status !== INTAKE_STATUSES.completed}
          >
            <Typography fontSize="14px" fontWeight={500} mr={2}>
              View MedChron
            </Typography>{" "}
            <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
          </StyledMedChronButton>
        </Box>
      </StyledViewMedicalChronologyWrapper>
      <Divider />
    </>
  )
}
