import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../react-query/constants"
import { getFeaturePermissions } from "api"
import { FeaturePermission } from "settings/FeaturePermissions/types"
import { FEATURE_PERMISSIONS } from "permissions/enums"

export function useGetPermissionByName(name: FEATURE_PERMISSIONS): {
  data: FeaturePermission | undefined
  isLoading: boolean
} {
  const { data, isLoading } = useQuery([queryKeys.featurePermissions, name], async () => {
    const response = await getFeaturePermissions({ name })
    const results = response?.results
    if (!results) {
      return undefined
    }

    return results.find((result: FeaturePermission) => result.name === name)
  })

  return { data, isLoading }
}
