import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import { InputField } from "common/form-components"
import Alert from "demand/Alerts/Alert"
import { useAuth } from "hooks/useAuth"
import useUser from "hooks/useUser"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { updateUser } from "api"

const useStyles = makeStyles()(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    maxWidth: theme.spacing(50),
    gap: theme.spacing(4),
  },
  alertWrapper: {
    marginTop: theme.spacing(2),
    maxWidth: theme.spacing(100),
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      width: "100%",
    },
    "& > :last-child": {
      marginLeft: theme.spacing(2),
    },
  },
}))

const EMPTY_FORM_VALUES = {
  email: "",
  confirmEmail: "",
}

export function ChangeEmail() {
  const { classes } = useStyles()
  const { user: currentUser } = useUser()
  const navigate = useNavigate()
  const { logout } = useAuth()
  const { showSuccessMessage } = useHandleMessages()

  const {
    control,
    handleSubmit,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: EMPTY_FORM_VALUES,
  })

  const updateUserEmailMutation = useMutation(updateUser, {
    onSuccess: () => {
      showSuccessMessage("Email updated successfully. Logging you out... ")

      setTimeout(async () => {
        await logout()
      }, 1000)
    },
  })

  const submitForm = handleSubmit(async data => {
    const { email } = data
    await updateUserEmailMutation.mutateAsync({ userId: currentUser.id, data: { email } })
  })

  const emailFields = watch(["email", "confirmEmail"])
  const hasValue = emailFields.every(field => !!field)
  const isMatching = hasValue && emailFields[0] === emailFields[1]
  const hasError = dirtyFields.email && dirtyFields.confirmEmail && !isMatching

  return (
    <Box>
      <Typography variant="h4" component="h1">
        Change Email
      </Typography>
      <Box className={classes.alertWrapper}>
        <Alert severity="warning" title={`Currently using ${currentUser.email}`}>
          <Typography variant="body2" gutterBottom>
            Please ensure that you have access to the new email address, as you will need it to log in again.
          </Typography>
          <Typography variant="body2">
            You will be logged out and will be required to log in with the new email address.
          </Typography>
        </Alert>
      </Box>
      <form className={classes.formFields} onSubmit={submitForm} noValidate>
        <InputField
          name="email"
          type="text"
          control={control}
          label="New email"
          variant="outlined"
          error={hasError}
          helperText={hasError && "Email addresses must match"}
        />
        <InputField
          name="confirmEmail"
          type="text"
          control={control}
          label="Confirm email address"
          variant="outlined"
          error={hasError}
          helperText={hasError && "Please verify that the emails match"}
        />
        <Box className={classes.actions}>
          <Button
            disabled={!isMatching || updateUserEmailMutation.isLoading}
            type="submit"
            color="secondary"
            variant="contained"
          >
            {updateUserEmailMutation.isLoading ? "Working..." : "Change email"}
          </Button>
          <Button variant="text" onClick={() => navigate("../")}>
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  )
}
