import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { Review, ReviewArgs, ReviewItem, ReviewsSlice, SetState } from "./types"

export const reviewsSlice: ReviewsSlice = {
  reviews: {},
}

function getReviewKeyFromArgs(args: ReviewArgs): string {
  return `${args.templateType}_${args.sectionType}_${args.plaintiffId}_${args.providerId}`
}

export const reviewsSelectors = {
  reviewItemByArgs: (args: ReviewArgs) => (state: ReviewsSlice) => state.reviews[getReviewKeyFromArgs(args)],
  suggestionItemsByArgs:
    (args: ReviewArgs) =>
    (state: ReviewsSlice): ReviewItem[] => {
      const review = reviewsSelectors.reviewItemByArgs(args)(state)
      if (!review?.data) return []
      return review.data.results.filter(
        reviewItem => reviewItem.originalText !== "" || reviewItem.suggestedText !== ""
      )
    },
  informationItemsByArgs:
    (args: ReviewArgs) =>
    (state: ReviewsSlice): ReviewItem[] => {
      const review = reviewsSelectors.reviewItemByArgs(args)(state)
      if (!review?.data) return []
      return review.data.results.filter(
        reviewItem => reviewItem.originalText === "" && reviewItem.suggestedText === ""
      )
    },
  allReviewsAcked:
    (args: ReviewArgs) =>
    (state: ReviewsSlice): boolean => {
      const review = reviewsSelectors.reviewItemByArgs(args)(state)
      if (!review?.data) return false

      return review.data.results.length === 0
        ? false
        : review.data.results.every(reviewItem => reviewItem.status !== "unacked")
    },
  reviewItemCount: (state: ReviewsSlice): number => {
    return Object.keys(state.reviews).reduce(
      (acc: number, key: string) => acc + (state?.reviews[key]?.data?.results.length || 0),
      0
    )
  },
  firstReviewItem: (state: ReviewsSlice): Review | null => {
    const review = Object.values(state.reviews).find(review => review && review.data?.results.length)
    return review || null
  },
}

export const reviewsActions = (set: SetState<ReviewsSlice>) => {
  const setReview = (args: ReviewArgs, data: Partial<Review>) => {
    set(state => {
      const key = getReviewKeyFromArgs(args)
      const review = state.reviews[key]

      return {
        ...state,
        reviews: {
          ...state.reviews,
          [key]: {
            status: null,
            data: null,
            loading: false,
            ...review,
            ...data,
          } as Review,
        },
      }
    })
  }

  const updateReviewItem = (
    args: ReviewArgs,
    reviewItemId: string,
    data: Partial<Pick<ReviewItem, "status" | "userModifiedText">>
  ) => {
    set(state => {
      const key = getReviewKeyFromArgs(args)
      const review = state.reviews[key]

      if (!review?.data?.results) return state

      const reviewItems = review.data.results.map<ReviewItem>(reviewItem =>
        reviewItem.id !== reviewItemId
          ? reviewItem
          : {
              ...reviewItem,
              ...data,
            }
      )

      return {
        ...state,
        reviews: {
          ...state.reviews,
          [key]: {
            ...review,
            data: {
              ...review.data,
              results: reviewItems,
            },
          },
        },
      }
    })
  }

  const setReviewResultContent = (args: ReviewArgs, content: EditorRoot) => {
    set(state => {
      const key = getReviewKeyFromArgs(args)
      const review = state.reviews[key]

      if (!review?.data?.results) return state

      return {
        ...state,
        reviews: {
          ...state.reviews,
          [key]: {
            ...review,
            data: {
              ...review.data,
              resultContent: content,
            },
          },
        },
      }
    })
  }

  return {
    setReview,
    updateReviewItem,
    setReviewResultContent,
  }
}
