import { CSSProperties, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Page } from "react-pdf"
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist/types/src/display/api"
import { PageWrapper, StyledPage } from "./styled"
import { SCALE, US_LETTER_SIZE } from "./constants"
import { PdfLoadingPage } from "./PdfLoadingPage"
import { PdfErrorPage } from "./PdfErrorPage"
import { usePerformance } from "hooks/usePerformance"
import { noop } from "lodash"
import { enqueue } from "./queue"
import { subscribeToReload } from "./reload"
import { v4 } from "uuid"
import PdfAnnotations, { PdfAnnotationsType } from "./PdfAnnotations"

export interface PdfPageData {
  document: PDFDocumentProxy
  onPageLoaded(pageProxy: PDFPageProxy, scale: number): void
  scale: number
  isControlledScale: boolean
  viewportWidth: number
  deletedPages: Set<number>
  annotations: Record<number, PdfAnnotationsType[]>
  pageNumberAdjustment: number
}

export interface PdfPageProps {
  data: PdfPageData
  index: number
  style: CSSProperties
}

export const PdfPage = memo(function PdfPage({
  data: {
    document,
    onPageLoaded,
    scale: initialScale,
    viewportWidth,
    deletedPages,
    isControlledScale,
    annotations,
    pageNumberAdjustment = 0,
  },
  index,
  style,
}: PdfPageProps): JSX.Element {
  const pdfPageProxyRef = useRef<PDFPageProxy>()
  const pageNumber = index + 1

  const [isLoadingStarted, setIsLoadingStarted] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isQueued, setIsQueued] = useState(false)
  const renderKey = useMemo(() => v4(), [isLoadingStarted]) // eslint-disable-line react-hooks/exhaustive-deps

  const onRenderedRef = useRef(noop)
  const onPageErrorRef = useRef(noop)
  const cancelRef = useRef(noop)

  if (!isQueued) {
    setIsQueued(true)
    cancelRef.current = enqueue(
      document,
      pageNumber,
      ({ signal }) =>
        new Promise((resolve, reject) => {
          setIsLoadingStarted(true)
          onRenderedRef.current = resolve
          onPageErrorRef.current = reject

          if (signal) {
            signal.onabort = resolve
          }
        })
    ).cancel
  }

  const [scale, setScale] = useState(initialScale)
  const placeholderScale = isControlledScale
    ? scale / SCALE
    : Math.min(viewportWidth / US_LETTER_SIZE.WIDTH, 1)

  const pageRenderPerformance = usePerformance("pdf_page_render", { initial: true }, [viewportWidth])

  const onCanvasRef = useCallback((canvas: Nullable<HTMLCanvasElement>) => {
    if (canvas) {
      // First .getContext on canvas is defining the overall drawing behaviour
      // There is a rare bug when page is totally black on canvas
      // It happens mostly on Chrome-based browsers on laptops with some built-in GPUs
      // By default canvas is rendered with GPU so there is no control on the output result
      // By disabling GPU rendering - page is rendered correctly each time (but rendering can be slower that GPU)
      // willReadFrequently: true - Forces Chrome to use CPU for canvas rendering
      // Should not be a big issue as we deal with 1-3 pages at a time
      canvas.getContext("2d", { alfa: false, willReadFrequently: true })
    }
  }, [])

  const handlePageLoad = useCallback(
    (pageProxy: PDFPageProxy) => {
      const possibleWidth = pageProxy.getViewport({ scale: initialScale }).width
      const actualScale =
        !isControlledScale && possibleWidth / viewportWidth > 1
          ? initialScale / ((possibleWidth + 1) / viewportWidth)
          : initialScale

      setScale(actualScale)
      pdfPageProxyRef.current = pageProxy
      onPageLoaded(pageProxy, actualScale)
    },
    [viewportWidth, onPageLoaded, initialScale, isControlledScale]
  )

  const handlePageRender = useCallback(() => {
    onRenderedRef.current()
    pageRenderPerformance.measure()
    setIsLoaded(true)
  }, [pageRenderPerformance])

  const handlePageError = useCallback(() => {
    onPageErrorRef.current()
  }, [])

  useEffect(() => {
    if (pdfPageProxyRef.current) {
      handlePageLoad(pdfPageProxyRef.current)
    }
  }, [viewportWidth, handlePageLoad])

  useEffect(() => {
    return subscribeToReload(pageNumber, () => {
      setIsLoadingStarted(false)
      setIsQueued(false)
    })
  }, [pageNumber])

  useEffect(() => {
    return () => {
      onPageErrorRef.current()
      cancelRef.current()
    }
  }, [])

  const pageRef = useRef<HTMLDivElement>(null) // Ref for the Page component

  // Effect to adjust layering after page render
  useEffect(() => {
    if (pageRef.current && isLoaded) {
      const squareAnnotations = pageRef.current.querySelectorAll(".squareAnnotation")
      squareAnnotations.forEach(annotation => {
        if (annotation instanceof HTMLElement) {
          annotation.style.display = "none"
        }
      })
    }
  }, [isLoaded])

  return (
    <PageWrapper id={`pdf_page_${pageNumber}`} style={style}>
      <StyledPage deleted={deletedPages.has(pageNumber)} scale={scale}>
        {isLoadingStarted ? (
          <div ref={pageRef}>
            <Page
              pageNumber={pageNumber}
              key={`${renderKey}_${pageNumber}`}
              loading={<PdfLoadingPage scale={placeholderScale} />}
              error={<PdfErrorPage scale={placeholderScale} />}
              onLoadSuccess={handlePageLoad}
              onRenderSuccess={handlePageRender}
              onError={handlePageError}
              canvasRef={onCanvasRef}
              scale={scale}
            />
            <PdfAnnotations
              annotations={annotations[pageNumber + pageNumberAdjustment] ?? []}
              pageNumber={pageNumber}
            />
          </div>
        ) : (
          <PdfLoadingPage scale={placeholderScale} />
        )}
      </StyledPage>
    </PageWrapper>
  )
})
