import { capitalize } from "lodash"
import { EDITOR_FEATURES } from "./constants"
import { EditorFeatureProps } from "./types"

import DOMSelection = globalThis.Selection

export function getFeaturesFromProps(props: EditorFeatureProps): EDITOR_FEATURES[] {
  return Object.values(EDITOR_FEATURES).filter(feature => `with${capitalize(feature.toLowerCase())}` in props)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDefaultView = (value: any): Window | null => {
  return (value && value.ownerDocument && value.ownerDocument.defaultView) || null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDOMSelection = (value: any): value is DOMSelection => {
  const window = value && value.anchorNode && getDefaultView(value.anchorNode)
  return !!window && value instanceof window.Selection
}
