import { Button } from "@mui/material"
import { UserExhibit } from "exhibit-builder/store/types"
import { usePopupStore } from "./store"

export function ActionPopupButton({ id }: { id: UserExhibit["id"] }) {
  const { openPopup, userExhibitId, closePopup, anchor } = usePopupStore()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (userExhibitId === id && anchor) {
      closePopup()
      return
    }

    openPopup({
      anchor: event.currentTarget,
      userExhibitId: id,
    })
  }

  return (
    <Button onClick={handleClick} data-test="user-exhibit-actions-button">
      Actions
    </Button>
  )
}
