type AnnotationColor = Uint8ClampedArray & [number, number, number]

export function getAnnotationHexColor(color: AnnotationColor): string {
  const colorsArray = Array.from(color)
  const colorHexes = colorsArray.map(colorPart => colorPart.toString(16).padStart(2, "0"))

  return `#${colorHexes.join("")}`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAnnotationColors(annotations: any[]): string[] {
  const annotationsWithColors = annotations.filter((annotation): annotation is { color: AnnotationColor } => {
    return annotation.color instanceof Uint8ClampedArray && annotation.color.length === 3
  })
  const hexColors = annotationsWithColors.map(annotation => getAnnotationHexColor(annotation.color))

  return Array.from(new Set(hexColors))
}

type PageRange = [number, number]

export function* generatePagesFromRanges(ranges: PageRange[], adjustment: number): Generator<number> {
  for (const [start, end] of ranges) {
    for (let page = start; page <= end; page++) {
      yield page - adjustment
    }
  }
}

export function getPagesFromRanges(ranges: PageRange[], adjustment: number): Set<number> {
  return new Set(generatePagesFromRanges(ranges, adjustment))
}
