import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded"
import { Box, ButtonProps } from "@mui/material"
import { theme } from "app/theme"

export enum FORM_STATE_STATUS {
  IDLE = "idle",
  DATA_ERROR = "data_error",
  LACK_OF_DATA = "lack_of_data",
  API_ERROR = "api_error",
  SUCCESS = "success",
  SUCCESS_PUBLISH = "success_publish",
  UPLOAD_ERROR = "upload_error",
}

export const ERROR_STATUSES = [
  FORM_STATE_STATUS.API_ERROR,
  FORM_STATE_STATUS.DATA_ERROR,
  FORM_STATE_STATUS.UPLOAD_ERROR,
]

const BASIC_FORM_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  [FORM_STATE_STATUS.IDLE]: null,
  [FORM_STATE_STATUS.SUCCESS_PUBLISH]: null,
  [FORM_STATE_STATUS.UPLOAD_ERROR]: null,
  [FORM_STATE_STATUS.LACK_OF_DATA]: null,
  [FORM_STATE_STATUS.DATA_ERROR]: (
    <>
      <b>Template already exists.</b> Please input a different attribute.
    </>
  ),
  [FORM_STATE_STATUS.API_ERROR]: (
    <>
      <b>Something went wrong.</b> Please try again and if your issue persists contact our support team
    </>
  ),
  [FORM_STATE_STATUS.SUCCESS]: null,
}

export const CREATE_TEMPLATE_FORM_STATE_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  ...BASIC_FORM_MESSAGES,
  [FORM_STATE_STATUS.SUCCESS]: (
    <>
      <b>Success!</b> New template has been added below.
    </>
  ),
}

export const CREATE_DOCUMENT_STRUCTURE_FORM_STATE_MESSAGES: Record<
  FORM_STATE_STATUS,
  Nullable<JSX.Element>
> = {
  ...BASIC_FORM_MESSAGES,
  [FORM_STATE_STATUS.SUCCESS]: (
    <>
      <b>Success!</b> New document structure has been added below.
    </>
  ),
  [FORM_STATE_STATUS.UPLOAD_ERROR]: (
    <>
      <b>An error occurred when uploading a letterhead.</b> Please try again and make sure the file is a
      .docx. If it happens again contact support.
    </>
  ),
  [FORM_STATE_STATUS.DATA_ERROR]: (
    <>
      <b>Document structure already exists. with the given parameters.</b> Please input different ones.
    </>
  ),
}

export const CREATE_VARIABLE_FORM_STATE_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  ...BASIC_FORM_MESSAGES,
  [FORM_STATE_STATUS.DATA_ERROR]: (
    <>
      <b>A variable already exists with the given attributes and group.</b> Please input a different attribute
      or a different group.
    </>
  ),
}

export const UPDATE_TEMPLATE_FORM_STATE_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  ...BASIC_FORM_MESSAGES,
}

export const UPDATE_VARIABLE_FORM_STATE_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  ...BASIC_FORM_MESSAGES,
  [FORM_STATE_STATUS.DATA_ERROR]: (
    <>
      <b>A variable already exists with the given attributes and group.</b> Please input a different attribute
      or a different group.
    </>
  ),
}

export const UPDATE_DOCUMENT_STRUCTURE_STATE_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  ...BASIC_FORM_MESSAGES,
  [FORM_STATE_STATUS.SUCCESS]: (
    <>
      <b>Success!</b> Document structure has been updated.
    </>
  ),
  [FORM_STATE_STATUS.DATA_ERROR]: (
    <>
      <b>Document structure already exists with the given parameters.</b> Please input different ones.
    </>
  ),
  [FORM_STATE_STATUS.SUCCESS_PUBLISH]: (
    <>
      <b>Success!</b> All the latest changes for the document structure has been published.
    </>
  ),
  [FORM_STATE_STATUS.UPLOAD_ERROR]: (
    <>
      <b>An error occurred when uploading a letterhead.</b> Please try again and make sure the file is a
      .docx. If it happens again contact support.
    </>
  ),
}

export const ARCHIVE_DOCUMENT_STRUCTURE_STATE_MESSAGES: Record<FORM_STATE_STATUS, Nullable<JSX.Element>> = {
  ...BASIC_FORM_MESSAGES,
  [FORM_STATE_STATUS.SUCCESS]: (
    <>
      <b>Success!</b> Document structure has been unarchived.
    </>
  ),
  [FORM_STATE_STATUS.DATA_ERROR]: (
    <>
      Cannot unarchive this document structure. <b>An existing one already exists</b>
    </>
  ),
}

export const getDeleteVariableDialogProps = ({ variableName }: { variableName: string | undefined }) => ({
  formContent: (
    <Box mt={3}>
      This will affect any existing usage of these variables in templates for demands for any new generations.
      If the value is not found, the placeholder will remain as specified.
    </Box>
  ),
  PaperProps: { style: { borderRadius: 0, padding: "40px 30px 24px" } },
  header: (
    <Box alignItems="center" display="flex" gap={1}>
      <WarningAmberRoundedIcon color="warning" />
      <b>Are you sure you want to delete {variableName}?</b>
    </Box>
  ),
  confirmationButtonProps: { sx: { background: theme.palette.blue.primary } } as ButtonProps,
  cancelButtonProps: { variant: "outlined", color: "secondary" } as ButtonProps,
  confirmationButtonContent: "Delete",
})

export const getDeleteProviderTemplateDialogProps = ({
  templateName,
}: {
  templateName: string | undefined
}) => ({
  formContent: (
    <Box mt={3}>
      Deleting this template will make it unavailable for future use across all documents. Existing instances
      where this template has been used will remain unaffected.
    </Box>
  ),
  PaperProps: { style: { borderRadius: 0, padding: "40px 30px 24px" } },
  header: (
    <Box alignItems="center" display="flex" gap={1}>
      <WarningAmberRoundedIcon color="warning" />
      <b>Are you sure you want to delete {templateName} provider template?</b>
    </Box>
  ),
  confirmationButtonProps: { sx: { background: theme.palette.blue.primary } } as ButtonProps,
  cancelButtonProps: { variant: "outlined", color: "secondary" } as ButtonProps,
  confirmationButtonContent: "Delete",
})
