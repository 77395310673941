import { useState } from "react"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { useQueryClient, useMutation } from "@tanstack/react-query"

import useUser from "../../hooks/useUser"
import { useHandleMessages } from "../messages/useHandleMessages"

import { createCaseSummary } from "../../api"
import { queryKeys } from "../../react-query/constants"
import { HighlightedText } from "./CaseResult"
import { ElasticSearchCase } from "./interfaces"

const useStyles = makeStyles()(theme => ({
  newlineText: {
    whiteSpace: "pre-line",
  },
  sectionContainer: {
    margin: theme.spacing(2, 0),
  },
  textField: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  smallMarginRight: {
    marginRight: theme.spacing(1),
  },
  characterLimit: {
    marginLeft: "auto",
  },
  error: {
    color: "red",
  },
  helperText: {
    fontStyle: "italic",
  },
}))

interface CaseSummaryProps {
  data: ElasticSearchCase
}

const SUMMARY_MAX = 10000

const CaseSummary = ({ data }: CaseSummaryProps): JSX.Element => {
  const { classes, cx } = useStyles()
  const queryClient = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useHandleMessages()
  const { user } = useUser()

  const { id: elasticsearch_id } = data

  const [isEditingSummary, setIsEditingSummary] = useState(false)
  const [summary, setSummary] = useState(data?.summary?.summary_text || "")
  const hasSummary = !!data.summary

  const cleanHighlightText = (text: string) =>
    text.replaceAll("<split><highlight>", "").replaceAll("<highlight><split>", "")

  const { mutate: createCaseSummaryMutation } = useMutation(createCaseSummary, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.case])
      queryClient.invalidateQueries([queryKeys.documents])
      queryClient.invalidateQueries([queryKeys.searchResults])
      queryClient.invalidateQueries([queryKeys.favoriteCases])

      setIsEditingSummary(!isEditingSummary)
      showSuccessMessage(hasSummary ? "Case Summary was requested successfully." : "Case Summary was added.")
    },
    onError: error => {
      showErrorMessage({
        message: `There was an error submitting case summary. Please contact Engineering.`,
        error,
      })
    },
  })

  const handleSubmitSummary = () => {
    createCaseSummaryMutation({
      data: {
        elasticsearch_id: elasticsearch_id,
        summary_text: summary,
        creator_id: user.id,
      },
    })
  }

  const SummaryText = () => {
    if (!hasSummary && !isEditingSummary) {
      return (
        <Typography variant="body2" component="p" className={classes.newlineText}>
          No Case Summary
        </Typography>
      )
    }

    if (!isEditingSummary && hasSummary) {
      return (
        <Typography
          variant="body2"
          component="p"
          gutterBottom
          className={classes.newlineText}
          data-test="summary-text"
        >
          {data.summary?.summary_text}
        </Typography>
      )
    }

    return <></>
  }

  const SummaryButtons = () => {
    if (isEditingSummary) {
      return (
        <Box>
          <Button
            variant="outlined"
            onClick={handleSubmitSummary}
            size="small"
            data-test="submit-summary-request-button"
            className={classes.smallMarginRight}
            disabled={summary.length > SUMMARY_MAX}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setIsEditingSummary(!isEditingSummary)
            }}
          >
            Cancel
          </Button>
        </Box>
      )
    } else {
      return (
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            setIsEditingSummary(!isEditingSummary)
            // Pre-populate new summaries with the case_text
            if (!hasSummary) {
              setSummary(cleanHighlightText(data.case_text))
            }
          }}
          data-test="edit-summary-button"
        >
          {data.summary?.summary_text ? "Edit Summary" : "Add Summary"}
        </Button>
      )
    }
  }

  return (
    <>
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" component="h2">
          Case Summary
        </Typography>

        {isEditingSummary ? (
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              variant="outlined"
              multiline
              className={classes.textField}
              value={summary}
              onChange={e => {
                setSummary(e.target.value)
              }}
              placeholder="Case Summary"
              data-test="summary-text-field"
            />
            <Box display={"flex"} mb={1}>
              <Box className={classes.helperText}>
                Please ensure to keep the summary generic so they can be reused for other cases.
              </Box>
              <Box
                className={cx(classes.characterLimit, summary.length > SUMMARY_MAX && classes.error)}
                data-test="character-limit"
              >{`${summary.length} / ${SUMMARY_MAX}`}</Box>
            </Box>
          </Box>
        ) : (
          <SummaryText />
        )}
        <SummaryButtons />
      </Box>
      {!hasSummary && (
        <>
          <Typography variant="h6" component="h2">
            Facts of the Case
          </Typography>
          <Typography variant="body2" component="span" gutterBottom className={classes.newlineText}>
            <HighlightedText
              text={data.case_text}
              splitTag={data._split_tag}
              highlightTag={data._highlight_tag}
            />
          </Typography>
        </>
      )}
    </>
  )
}

export { CaseSummary as default }
