import { Suspense } from "react"
import { Controller } from "react-hook-form"
import { useQuery } from "@tanstack/react-query"
import { makeStyles } from "tss-react/mui"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Tooltip from "@mui/material/Tooltip"
import InputLabel from "@mui/material/InputLabel"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"

import { getUsStates } from "../api"
import { SILENT_QUERY_PARAMS, STALE_TIMEOUT, queryKeys } from "../react-query/constants"

const useStyles = makeStyles()(theme => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export function StateSelectComponent({
  control,
  selectName,
  errors,
  helperText,
  disabled = false,
  loading = false,
  tooltip = null,
  label = "State",
  size = "medium",
  required = true,
  readOnly = false,
}) {
  const { classes } = useStyles()
  const { data: states } = useQuery([queryKeys.usStates], getUsStates, {
    ...SILENT_QUERY_PARAMS,
    staleTime: STALE_TIMEOUT.STATIC_DATA,
    suspense: !loading,
    enabled: !loading,
  })

  const stateList = [...(states?.results || [])]
  if (!required) {
    stateList.unshift({ display: "Unknown", key: null })
  }

  return (
    <Controller
      name={selectName || "state"}
      control={control}
      render={({ field: { onChange, value, name } }) => {
        const StateSelector = (
          <FormControl className={classes.formControl} error={errors} size={size}>
            <InputLabel id="state-select-label" variant="outlined">
              {label}
            </InputLabel>
            <Select
              label={label}
              labelId="state-select-label"
              id={`state-select-${selectName}`}
              value={value ? value : ""}
              onChange={onChange}
              name={name}
              data-test="state-selector"
              disabled={disabled}
              readOnly={readOnly}
            >
              {loading && <MenuItem value={value ? value : ""}>Loading...</MenuItem>}
              {!loading &&
                stateList.map(item => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.display}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {required && errors ? "Please select a state from the list." : helperText}
            </FormHelperText>
          </FormControl>
        )

        if (tooltip) {
          return <Tooltip title={tooltip}>{StateSelector}</Tooltip>
        }

        return StateSelector
      }}
    />
  )
}

export function StateSelect(props) {
  return (
    <Suspense fallback={<StateSelectComponent {...props} disabled loading />}>
      <StateSelectComponent {...props} />
    </Suspense>
  )
}
