import { JsonCallProps, REQUEST_METHODS, makeApiCall } from "apiHelper"
import {
  CaseTypeDto,
  CountyDto,
  CptCodeDto,
  CptCodeSeverityTypeDto,
  IcdCodeDto,
  InsuranceCarrierDto,
  MedicalProviderTypeDto,
  SettlementStageDto,
  StateDto,
} from "./types"
import { handleEmptyResponse } from "api/utils"
import { MAX_SEARCH_CHARS } from "settlements/estimate-form/constants"

const BASE_PATH = "/settlement/asset/"

type AssetResponse<T> = {
  results: T[]
}

async function makeAssetRequest<TData>({
  path,
  query,
}: {
  path: string
  query?: Record<string, number | string | boolean>
}) {
  const apiOptions: JsonCallProps = {
    path: BASE_PATH + path,
    method: REQUEST_METHODS.GET,
    json: true,
    query,
  }
  const response = handleEmptyResponse(makeApiCall<AssetResponse<TData>>(apiOptions))
  return (await response).results
}

export function getCaseTypes() {
  return makeAssetRequest<CaseTypeDto>({ path: "case-types" })
}

export function getCounties() {
  return makeAssetRequest<CountyDto>({ path: "counties" })
}

export function getStates() {
  return makeAssetRequest<StateDto>({ path: "states" })
}

export function getSettlementStages() {
  return makeAssetRequest<SettlementStageDto>({ path: "settlement-stages" })
}

export function getMedicalProviderTypes() {
  return makeAssetRequest<MedicalProviderTypeDto>({ path: "medical-provider-types" })
}

export function getInsuranceCarriers(query: string) {
  const truncated = query.substring(0, MAX_SEARCH_CHARS)
  return makeAssetRequest<InsuranceCarrierDto>({ path: "insurance-carriers", query: { query: truncated } })
}

export function getIcdCodes(query: string) {
  const truncated = query.substring(0, MAX_SEARCH_CHARS)
  return makeAssetRequest<IcdCodeDto>({ path: "icd-codes", query: { query: truncated } })
}

export function getCptCodes(query: string) {
  const truncated = query.substring(0, MAX_SEARCH_CHARS)
  return makeAssetRequest<CptCodeDto>({ path: "cpt-codes", query: { query: truncated } })
}

export function getCptCodeSeverityTypes() {
  return makeAssetRequest<CptCodeSeverityTypeDto>({ path: "cpt-code-severity-types" })
}
