import styled from "@emotion/styled"

export const StyledDownload = styled("a")({
  position: "absolute",
  top: "0px",
  left: "0px",
  right: "0px",
  bottom: "0px",
})

export const StyledProviderName = styled("div")({
  fontSize: "14px",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  cursor: "pointer",

  ":hover": {
    textDecoration: "underline",
  },
})

export const StyledVerticalDivider = styled("div")({
  width: "1px",
  height: "46px",
  background: "rgba(0, 0, 0, 0.33)",
})

export const StyledText = styled("div")({
  color: "rgba(5, 3, 1, 0.60)",
  fontSize: "12px",
})

export const StyledSectionNavName = styled("div")({
  fontSize: "14px",
  cursor: "pointer",

  ":hover": {
    textDecoration: "underline",
  },
})

export const StyledFileName = styled("span")({
  cursor: "pointer",
  fontSize: "12px",
  fontWeight: 700,
  color: "rgba(0, 0, 0, 0.80)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.80)",
})

export const StyledLegendProvider = styled("div")(({ theme }) => ({
  display: "inline-block",
  padding: theme.spacing(0.25, 1.5),
  borderRadius: theme.spacing(1.5),
  whiteSpace: "nowrap",
  fontSize: "13px",
  lineHeight: "17px",
  fontWeight: 700,
}))
