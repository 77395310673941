import { FirmsUserManageModel } from "api/services/users/models"
import { INTERNAL_ROLES, ALL_ROLES } from "../common/models/roles"

export const canEditLibrary = (userRole: ALL_ROLES): boolean => {
  return userRole === INTERNAL_ROLES.LEGALOPS_MANAGER || userRole === INTERNAL_ROLES.LEGALOPS_ADMIN
}

export const canEditLibraryByFirmsUserManage = (
  userRole: ALL_ROLES,
  firmsUserManage?: FirmsUserManageModel
): boolean => {
  if (userRole === INTERNAL_ROLES.LEGALOPS_ADMIN) return true

  if (userRole === INTERNAL_ROLES.LEGALOPS_MANAGER || userRole === INTERNAL_ROLES.LEGALOPS) {
    return !!firmsUserManage?.hasAnyFirm()
  }

  return false
}
