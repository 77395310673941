import React, { useEffect, useState, PointerEvent, ReactElement, useId } from "react"
import {
  DndContext,
  PointerSensorOptions,
  useDndMonitor,
  useDraggable,
  useSensor,
  PointerSensor,
} from "@dnd-kit/core"

// @ts-expect-error to overwrite AbstractPointerSensorOptions type from @dnd-kit/core
class DraggablePointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown" as const,
      handler: (
        { nativeEvent }: PointerEvent,
        { target }: PointerSensorOptions & { target: Nullable<HTMLElement> }
      ) => {
        return target?.contains(nativeEvent.target as Node)
      },
    },
  ]
}

const DraggableInner = ({ children, target }: { children: ReactElement; target: Nullable<HTMLElement> }) => {
  const id = useId()
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const { listeners, setNodeRef, transform } = useDraggable({ id })

  const x = position.x + (transform?.x || 0)
  const y = position.y + (transform?.y || 0)
  const style = { transform: `translate3d(${x}px, ${y}px, 0)` }

  useEffect(() => {
    setNodeRef(target)
  }, [target, setNodeRef])

  useDndMonitor({
    onDragEnd(event) {
      setPosition(({ x, y }) => ({
        x: x + event.delta.x,
        y: y + event.delta.y,
      }))
    },
  })

  return (
    <div {...listeners} style={style}>
      {React.cloneElement(children, {
        style: { ...children.props.style, ...style },
      })}
    </div>
  )
}

export const Draggable = ({ children, target }: { children: ReactElement; target: HTMLElement | null }) => {
  const pointerSensor = useSensor(DraggablePointerSensor, { target })
  return (
    <DndContext sensors={[pointerSensor]}>
      <DraggableInner target={target}>{children}</DraggableInner>
    </DndContext>
  )
}
