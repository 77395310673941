import React, { useCallback, useState } from "react"
import Box from "@mui/material/Box"

import { WriteOff, Date } from "../Bills/types"
import { StackCell } from "../Bills/styled"
import MultipleEntriesItem from "./MultipleEntriesItem"

interface OverflowCellProps {
  data: (WriteOff | Date)[]
  renderInTableDisplay: (data: Date | WriteOff, index: number) => React.ReactNode
  renderPopover: (data: (WriteOff | Date)[]) => React.ReactNode
}
const OverflowCell = ({ data, renderInTableDisplay, renderPopover }: OverflowCellProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Nullable<EventTarget & HTMLElement>>(null)

  const handleOpenPopover = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null)
  }, [])

  if (!data?.length) {
    return <Box>——</Box>
  }

  const maxShow = 2
  const hasOverflow = data.length > maxShow
  const showData = data.slice(0, maxShow)

  return (
    <StackCell>
      {showData.map((data, index) => {
        return renderInTableDisplay(data, index)
      })}
      {hasOverflow && (
        <MultipleEntriesItem
          anchorEl={anchorEl}
          onOpen={handleOpenPopover}
          onClose={handleClosePopover}
          count={data.length - showData.length}
          renderPopover={renderPopover}
          data={data}
        />
      )}
    </StackCell>
  )
}

export { OverflowCell as default }
