import { ReferencePageDto, ReferencePage } from "common/types/providers"
import { InterventionalTreatment, InterventionalTreatmentDto } from "./types"

class ReferencePageDeserializer {
  static definitionFromJSON({ id, exhibit_id, partition_id, page_number }: ReferencePageDto): ReferencePage {
    return {
      id,
      exhibitId: exhibit_id,
      partitionId: partition_id,
      pageNumber: page_number,
    }
  }

  static fromListJSON(referencePages: ReferencePageDto[]): ReferencePage[] {
    return (referencePages || []).map(this.definitionFromJSON)
  }
}

class ReferencePageSerializer {
  static definitionToJSON({ id, exhibitId, partitionId, pageNumber }: ReferencePage): ReferencePageDto {
    return {
      id,
      exhibit_id: exhibitId,
      partition_id: partitionId,
      page_number: pageNumber,
    }
  }

  static toListJSON(referencePages: ReferencePage[]): ReferencePageDto[] {
    return (referencePages || []).map(this.definitionToJSON)
  }
}

export class InterventionalTreatmentDeserializer {
  static definitionFromJSON({
    id,
    provider_id,
    date,
    treatment,
    reference_pages,
  }: InterventionalTreatmentDto): InterventionalTreatment {
    return {
      id,
      providerId: provider_id,
      date,
      treatment,
      referencePages: ReferencePageDeserializer.fromListJSON(reference_pages),
    }
  }

  static fromListJSON(data: InterventionalTreatmentDto[]): InterventionalTreatment[] {
    return (data || []).map(this.definitionFromJSON)
  }
}

export class InterventionalTreatmentSerializer {
  static definitionToJSON({
    id,
    providerId,
    date,
    treatment,
    referencePages,
  }: InterventionalTreatment): InterventionalTreatmentDto {
    return {
      id,
      provider_id: providerId,
      date,
      treatment,
      reference_pages: ReferencePageSerializer.toListJSON(referencePages),
    }
  }

  static toListJSON(data: InterventionalTreatment[]): InterventionalTreatmentDto[] {
    return (data || []).map(this.definitionToJSON)
  }
}
