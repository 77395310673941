import styled from "@emotion/styled"
import Button, { ButtonProps } from "@mui/material/Button"
import { theme } from "app/theme"

export const StyledPrimaryButton = styled(Button)<
  ButtonProps & {
    target?: string
  }
>({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.blue.primary,
  textTransform: "none",
  fontSize: theme.typography.pxToRem(15),
  position: "relative",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.blue.primary,
  },
})
