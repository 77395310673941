import React from "react"
import Tooltip from "@mui/material/Tooltip"
import CircularProgress from "@mui/material/CircularProgress"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import ErrorIcon from "@mui/icons-material/Error"
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded"
import { CircularProgressWithLabel } from "common/CircularProgressWithLabel"
import { FileUploadState } from "../file-state"

export const FileUploadItemIcon: React.FC<{ state: FileUploadState }> = ({ state }) => {
  const { current: status, data } = state

  switch (status) {
    case "PREPARING_FOR_UPLOAD":
    case "CREATING_UPLOAD":
      return <CircularProgress size={25} />
    case "DELETED":
      return <CheckCircleRoundedIcon />
    case "CANCELLED":
      return <HighlightOffRoundedIcon />
    case "FAILURE":
      return (
        <Tooltip title={"An error has occured. Click save to try again"}>
          <ErrorIcon />
        </Tooltip>
      )
    case "UPLOADING_CHUNKS":
      return data.progress && data.progress < 100 ? (
        <CircularProgressWithLabel size={25} value={data.progress} />
      ) : (
        <CircularProgress size={25} color={data.progress ? "success" : "primary"} />
      )
    default:
      return <CheckCircleRoundedIcon />
  }
}
