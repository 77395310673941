import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { documentStructureService } from "api/services/document-structure"
import { Pagination } from "common/pagination"
import { Loading } from "common"
import { AttributeFiltersData } from "common/attributes-filter"
import { DocumentStructureViewModel } from "common/models/library"
import { attributesService } from "api/services/attributes"
import { ARCHIVE_DOCUMENT_STRUCTURE_STATE_MESSAGES } from "settings/Library/TemplateForms/constants"
import { useArchivedDocumentStructureForm } from "settings/Library/TemplateForms/hooks/useArchivedDocumentStructureForm"

import { LibraryTable } from "../LibraryTable"
import { ArchivedDocumentStructureTableWrapper } from "./styled"

const getCanEdit = () => false
const getDuplicateData = () => ({})

interface ArchivedDocumentStructureTableProps {
  pageSize: number
  attributeFilters?: AttributeFiltersData
  firmId: Nullable<number>
  documentName: Nullable<string>
}

export function ArchivedDocumentStructureTable({
  pageSize,
  attributeFilters,
  firmId,
  documentName,
}: ArchivedDocumentStructureTableProps) {
  const { data: attributes } = useQuery([queryKeys.attributes, "document-structure"], async () =>
    attributesService.getAvailableDocumentStructureAttributes(null)
  )
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { isFetching, data: archivedDocumentStructures } = useQuery(
    [queryKeys.archivedDocumentStructures, currentPage, pageSize, attributeFilters, firmId, documentName],
    () =>
      documentStructureService.getDocumentStructuresList({
        archived: true,
        page: currentPage,
        pageSize,
        attributes: attributeFilters,
      }),
    {
      enabled: Boolean(attributes),
      meta: { disableLoader: true },
      keepPreviousData: true,
    }
  )

  if (!archivedDocumentStructures || !attributes) {
    return <Loading show label="Loading document structures..." />
  }

  const templatesTableData = new DocumentStructureViewModel(archivedDocumentStructures.items, attributes)

  return (
    <ArchivedDocumentStructureTableWrapper>
      <LibraryTable
        tableData={templatesTableData}
        loading={isFetching}
        canDuplicate={false}
        getDuplicateData={getDuplicateData}
        getCanEdit={getCanEdit}
        getFormData={useArchivedDocumentStructureForm}
        entityName="archived document structures"
        formStatusMessageMap={ARCHIVE_DOCUMENT_STRUCTURE_STATE_MESSAGES}
      />
      {archivedDocumentStructures.count > 0 && (
        <Pagination
          pageCount={archivedDocumentStructures.pageCount}
          page={archivedDocumentStructures.page}
          count={archivedDocumentStructures.count}
          onChange={setCurrentPage}
        />
      )}
    </ArchivedDocumentStructureTableWrapper>
  )
}
