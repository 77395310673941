import { useCallback, useRef } from "react"
import { useMutation } from "@tanstack/react-query"
import { uploadExhibit } from "api"
import { FileUploadErrorResult, useFileUploader } from "common/file-uploader"
import { FileToUploadType } from "common/form-components/files/interfaces"
import { CASE_SECTIONS } from "common/types/sections"

type ValidFileToUploadType = FileToUploadType & { file: File }

function isValidFileToUpload(fileToUpload: FileToUploadType): fileToUpload is ValidFileToUploadType {
  return !!fileToUpload.file
}

interface UseUploadImagesProps {
  caseId: PrimaryKey
  section: CASE_SECTIONS
  images: FileToUploadType[]
  reset: () => void
}

type UseUploadImagesReturn = () => Promise<void>

export function useUploadImages({
  caseId,
  section,
  images,
  reset,
}: UseUploadImagesProps): UseUploadImagesReturn {
  const { uploadFiles, showUploadErrors } = useFileUploader()
  const uploadImageMutation = useMutation(uploadExhibit, {
    meta: { disableLoader: true },
  })
  const imagesRef = useRef(images)
  imagesRef.current = images
  const resetRef = useRef(reset)
  resetRef.current = reset

  const uploadImages = useCallback(async () => {
    const images = imagesRef.current

    if (!images.length) return

    const failedUploads: FileUploadErrorResult[] = []
    const imagePromises: Promise<unknown>[] = []
    const imagesToUpload: ValidFileToUploadType[] = []
    const testImageNode = document.createElement("img")

    for (const image of images) {
      if (!isValidFileToUpload(image)) {
        failedUploads.push({ name: image.name, reason: "File has no data", success: false })
      } else if (!image.questionnaireFileId) {
        imagesToUpload.push(image)
      } else {
        const imageFile = image.file
        // Check if file is valid image
        const imageObjectUrl = URL.createObjectURL(imageFile)
        await new Promise(resolve => {
          testImageNode.onload = () => resolve(imagesToUpload.push(image))
          testImageNode.onerror = () =>
            resolve(
              failedUploads.push({ name: imageFile.name, reason: "File is not an image", success: false })
            )
          testImageNode.src = imageObjectUrl
        })
        URL.revokeObjectURL(imageObjectUrl)
      }
    }

    const uploads = await uploadFiles(imagesToUpload.map(({ file }) => file))

    if (uploads.hasFailures) {
      showUploadErrors(uploads.items)
    }

    for (const [index, image] of imagesToUpload.entries()) {
      const upload = uploads.items[index]

      if (!upload?.success || !image.file) {
        return
      }

      const formData = new FormData()
      formData.append("name", image.name ? image.name : image.file.name)
      formData.append("type", "image")
      formData.append("section", section)
      formData.append("image_upload_id", upload.uploadId)

      imagePromises.push(uploadImageMutation.mutateAsync({ data: formData, caseId }))
    }

    const uploadResults = await Promise.allSettled(imagePromises)

    for (const [idx, result] of uploadResults.entries()) {
      if (result.status === "rejected") {
        failedUploads.push({
          success: false,
          name: images[idx].file?.name ?? images[idx].name,
          reason: result.reason || "failed to upload",
        })
      }
    }

    if (failedUploads.length) {
      showUploadErrors(failedUploads)
    }

    resetRef.current()
  }, [uploadImageMutation, showUploadErrors, uploadFiles, caseId, section])

  return uploadImages
}
