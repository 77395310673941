import { useCallback, useMemo, useRef, useState } from "react"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import DragIndicator from "@mui/icons-material/DragIndicator"
import { useFormContext } from "../context"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { updateFutureExpense } from "api"
import { getCommonMutateOptions } from "utils"
import { useForm } from "react-hook-form"
import { CurrencyField, InputField } from "common/form-components"

import { amountInDollars } from "../../utils"
import { FutureMedicalItem, InnerLayout, GridLayoutEditView, InnerContent } from "./FutureExpenseStyled"
import { UnstableRichTextField } from "../../common/form-components/rich-text"
import { Draggable } from "react-beautiful-dnd"
import TextButton from "common/buttons/TextButton"
import { isEmpty, isEqual } from "lodash"
import { CASE_STEP_STATUS } from "demand/steps/types"
import * as Sentry from "@sentry/react"

const DescriptionText = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  lineHeight: 1.42,
  color: theme.palette.grey[600],
}))

export function FutureExpenseEditor({ data, onCloseCallback, index }) {
  const initialData = useRef(data)
  const queryClient = useQueryClient()
  const [isSaving, setIsSaving] = useState(false)
  const { caseId, handleUpdateStepStatus, showErrorMessage, setSavedSuccessfully } = useFormContext()
  const { formState, control, reset, setValue, getValues, watch } = useForm({
    defaultValues: data,
  })

  const [cost, annual_frequency, number_of_years] = watch(["cost", "annual_frequency", "number_of_years"])
  const total = amountInDollars(cost * annual_frequency * number_of_years)

  const mutationOptions = useMemo(
    () =>
      getCommonMutateOptions({
        reset,
        setSavedSuccessfully,
        showErrorMessage,
        setValue,
      }),
    [reset, setSavedSuccessfully, setValue, showErrorMessage]
  )

  const updateMutation = useMutation(updateFutureExpense, { mutationOptions })

  const handleSave = useCallback(async () => {
    setIsSaving(true)
    initialData.current = getValues()

    try {
      await updateMutation.mutateAsync({ caseId, data: initialData.current })

      handleUpdateStepStatus({ status: CASE_STEP_STATUS.COMPLETED })
      queryClient.invalidateQueries([queryKeys.futureExpense, caseId])
      onCloseCallback?.()
    } catch (err) {
      Sentry.captureException(err)
      showErrorMessage("Failed to save future expense. Please try again.")
    } finally {
      setIsSaving(false)
    }
  }, [
    caseId,
    onCloseCallback,
    queryClient,
    getValues,
    handleUpdateStepStatus,
    updateMutation,
    showErrorMessage,
  ])

  const handleCancel = useCallback(() => {
    onCloseCallback?.()

    if (isEqual(initialData.current, getValues())) return

    updateMutation.mutateAsync({ caseId, data: initialData.current })

    reset(initialData.current)
  }, [caseId, onCloseCallback, reset, updateMutation, getValues])

  const handleOnBlur = useCallback(async () => {
    if (isEmpty(formState.dirtyFields)) return

    const currentData = getValues()

    reset(currentData)
  }, [formState.dirtyFields, getValues, reset])

  return (
    <Draggable key={data.pk} draggableId={String(data.pk)} index={index}>
      {draggableProvided => (
        <FutureMedicalItem
          key={data.pk}
          onBlur={handleOnBlur}
          {...draggableProvided.draggableProps}
          ref={draggableProvided.innerRef}
        >
          <Box>
            <GridLayoutEditView>
              <Box {...draggableProvided.dragHandleProps}>
                <DragIndicator />
              </Box>
              <Box>
                <InputField
                  name="procedure_name"
                  control={control}
                  type="text"
                  aria-describedby="procedure-heading"
                  disabled={isSaving}
                />
              </Box>
              <Box>
                <CurrencyField
                  name="cost"
                  control={control}
                  aria-describedby="cost-heading"
                  variant="standard"
                  disabled={isSaving}
                />
              </Box>
              <Box>
                <InputField
                  data-test="annual-frequency-input"
                  name="annual_frequency"
                  control={control}
                  type="number"
                  inputProps={{ min: 0 }}
                  aria-describedby="annual-frequency-heading"
                  disabled={isSaving}
                />
              </Box>
              <Box>
                <InputField
                  name="number_of_years"
                  control={control}
                  type="number"
                  inputProps={{ min: 0 }}
                  aria-describedby="years-heading"
                  disabled={isSaving}
                />
              </Box>
              <Box align="right">{total}</Box>
              <Box align="right">
                <TextButton textColor="grey" onClick={handleCancel} disabled={isSaving}>
                  Cancel
                </TextButton>
                <TextButton
                  textColor="blue"
                  data-test="done-adding-expense"
                  onClick={handleSave}
                  disabled={isSaving}
                >
                  Save
                </TextButton>
              </Box>
            </GridLayoutEditView>
            <InnerLayout>
              <InnerContent>
                <UnstableRichTextField
                  control={control}
                  name="description"
                  dataTest="procedure-description"
                  label="Notes, external source links, etc."
                />
                <DescriptionText>
                  This note will appear in the table in the final demand and will have to be moved into
                  footnotes.
                </DescriptionText>
              </InnerContent>
            </InnerLayout>
          </Box>
        </FutureMedicalItem>
      )}
    </Draggable>
  )
}
