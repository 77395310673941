import React, { useCallback, useMemo, useState } from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Chip from "@mui/material/Chip"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import styled from "@emotion/styled"
import MoreHoriz from "@mui/icons-material/MoreHoriz"
import GetApp from "@mui/icons-material/GetApp"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { downloadPartition, updateExhibitPartition } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { usePublish } from "message-broker/usePublish"
import { MESSAGE_TOPIC } from "message-broker/topics"

import { NO_AUTO_IMPORT_TEXT } from "./constants"
import ProviderMenu from "./ProviderMenu"
import { AnnotationFileIconButton, Text } from "./styled"
import { PartitionProvider, Partition, CaseId, DeleteAction, AnnotationStatus } from "./types"
import { DuplicateMenu, SplitMenu } from "./PartitionMenus"
import { displayPageRange } from "./rangeUtils"
import ExcerptTable from "./tables/ExcerptTable"
import DeletionTable from "./tables/DeletionTable"
import { getRelevantDeletions } from "./tables/getRelevantDeletions"
import { PreviewExhibitPayload } from "message-broker/types"
import { getExhibitPreviewPath } from "pdf-preview/utils"
import { openWindow } from "common/windows"
import { FORM_SECTIONS } from "../constants"
import { isUpperCase, toTitleCase } from "./AnnotationPartitionUtils"
import { PlaintiffSelector } from "demand/components/PlaintiffSelector"
import { useMutation } from "@tanstack/react-query"
import { queryClient } from "react-query/queryClient"
import { queryKeys } from "react-query/constants"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"
import { MismatchFlag } from "./MismatchFlag"

const SplitHeadingText = styled(Typography)({
  fontSize: "14px",
  fontWeight: "bold",
})

const AnnotationsPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
}))

const HidingExpandMore = styled(ExpandMore)({ visibility: "hidden" })

const PrimaryProviderBaseButton = styled("button")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  border: "none",
  backgroundColor: "inherit",
  cursor: "pointer",
  [`&:hover ${SplitHeadingText}`]: {
    textDecoration: "underline",
  },
  "&:disabled": {
    textDecoration: "none",
    color: theme.palette.common.black,
    cursor: "auto",
  },
  [`&:hover ${HidingExpandMore}`]: {
    visibility: "visible",
  },
}))

interface PrimaryProviderButtonProps {
  provider?: PartitionProvider
  onClick: React.MouseEventHandler<HTMLElement>
  disabled?: boolean
}

const PrimaryProviderButton: React.FC<PrimaryProviderButtonProps> = ({
  provider,
  onClick,
  disabled = false,
}) => {
  const theme = useTheme()
  return (
    <Box display="flex" alignItems="center">
      <SplitHeadingText>Primary Provider: </SplitHeadingText>
      <PrimaryProviderBaseButton onClick={onClick} role="button" disabled={disabled}>
        <span
          style={{
            backgroundColor: provider?.color ? `#${provider.color}` : undefined,
            padding: "8px",
            border: provider?.name ? "none" : `1px solid ${theme.palette.error.main}`,
          }}
        >
          {provider?.name ? (
            <SplitHeadingText>
              {isUpperCase(provider.name) ? toTitleCase(provider.name) : provider.name}
            </SplitHeadingText>
          ) : (
            <SplitHeadingText color="error">SELECT</SplitHeadingText>
          )}
        </span>
        <HidingExpandMore />
      </PrimaryProviderBaseButton>
    </Box>
  )
}

interface AnnotationSplitProps extends Partition {
  exhibitId: number
  caseId: CaseId
  fileName: string
  partitionProviders?: PartitionProvider[]
  onDeletePagesClick: (partitionId: Nullable<number>) => unknown
  deletions: DeleteAction[]
  status?: Nullable<AnnotationStatus>
  isDeleted: boolean
  plaintiffId: Nullable<number>
}

type AnnotationDuplicateProps = AnnotationSplitProps

interface AnnotationPartitionProps extends AnnotationSplitProps {
  duplicate?: boolean
}

const AnnotationPartition: React.FC<AnnotationPartitionProps> = ({
  excerpts,
  provider,
  start_page,
  end_page,
  pk,
  exhibitId,
  partitionProviders = [],
  caseId,
  duplicate = false,
  onDeletePagesClick,
  deletions,
  status,
  section,
  fileName,
  isDeleted,
  plaintiffId = null,
}) => {
  const [downloadingFile, setDownloadingFile] = useState<boolean>(false)
  const { showErrorMessage } = useHandleMessages()
  const [providerMenuAnchor, setProviderMenuAnchor] = useState<null | HTMLElement>(null)
  const [moreOptionsAnchor, setMoreOptionsAnchor] = useState<null | HTMLElement>(null)
  const { isFeatureEnabled } = useFeatures()
  const isSplittingEnabled = isFeatureEnabled(FEATURES.SPLIT_INTAKE_FILES)
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  const handleProviderMenuClose = () => setProviderMenuAnchor(null)
  const toggleProviderMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (providerMenuAnchor) {
      handleProviderMenuClose()
    } else {
      setProviderMenuAnchor(event.currentTarget)
    }
  }

  const handleMoreOptionsMenuClose = () => setMoreOptionsAnchor(null)
  const handleMoreOptionsMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreOptionsAnchor(event.currentTarget)
  }
  const handleDeletePagesClick = () => {
    handleMoreOptionsMenuClose()
    // we only want to pass up the pk if duplicate clicked
    // so we can use that info to restrict the validation to this duplicate's page ranges
    // and only show deletions related to this duplicate
    onDeletePagesClick(duplicate ? pk : null)
  }

  const handleDownload = () => {
    setDownloadingFile(true)
    downloadPartition({
      exhibitId,
      partitionId: pk,
    })
      .catch(error => {
        showErrorMessage({
          message:
            "Failed to download file. Try again shortly and if your problem persists, please report your issue.",
          error,
        })
      })
      .finally(() => setDownloadingFile(false))
  }

  const publish = usePublish()

  const handleOpenPreview = useCallback(
    (startPage?: number) => {
      const payload: PreviewExhibitPayload = {
        fileName,
        caseId,
        exhibitId,
        partitionId: pk,
        page: startPage,
      }
      publish(MESSAGE_TOPIC.PREVIEW_EXHIBIT, payload).catch(() => {
        const path = getExhibitPreviewPath(payload)
        if (path) {
          openWindow(`${path.base}/${path.route}`)
        }
      })
    },
    [caseId, exhibitId, pk, fileName, publish]
  )

  const updatePartitionMutation = useMutation(updateExhibitPartition, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.annotated_exhibits, caseId])
    },
  })

  const handlePlaintiffChange = useCallback(
    (plaintiffId: Nullable<number>) => {
      updatePartitionMutation.mutateAsync({
        exhibitId: exhibitId,
        partitionId: pk,
        data: {
          plaintiff_id: plaintiffId,
        },
      })
    },
    [exhibitId, pk, updatePartitionMutation]
  )

  const relevantDeletions = useMemo(
    () =>
      getRelevantDeletions({
        start_page,
        end_page,
        deletions,
        excerpts: status === AnnotationStatus.COMPLETE ? excerpts : [],
      }),
    [start_page, end_page, deletions, excerpts, status]
  )

  const splitHeadingText = isDeleted ? NO_AUTO_IMPORT_TEXT : section ?? NO_AUTO_IMPORT_TEXT

  const isProvider = !isDeleted && section === FORM_SECTIONS.PROVIDERS_SECTION
  const isNoAutoImport = section !== FORM_SECTIONS.PROVIDERS_SECTION || isDeleted

  return (
    <AnnotationsPaper elevation={1}>
      <Box padding={2} width="100%">
        <Box pl={0.1} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Box visibility={isSplittingEnabled ? "visible" : "hidden"}>
            {isProvider && (
              <>
                <PrimaryProviderButton
                  provider={provider}
                  onClick={toggleProviderMenu}
                  disabled={!isSplittingEnabled}
                />
                <ProviderMenu
                  anchorEl={providerMenuAnchor}
                  onClose={handleProviderMenuClose}
                  partitionProviders={partitionProviders}
                  exhibitId={exhibitId}
                  caseId={caseId}
                  partitionId={pk}
                  provider={provider}
                  plaintiffId={plaintiffId}
                  multiPlaintiffEnabled={multiPlaintiffEnabled}
                />
              </>
            )}
            {isNoAutoImport && <SplitHeadingText>{splitHeadingText}</SplitHeadingText>}
          </Box>
          {multiPlaintiffEnabled && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box mr={2} fontWeight={700}>
                Plaintiff:
              </Box>
              <PlaintiffSelector
                caseId={+caseId}
                plaintiff={plaintiffId}
                onChange={(plaintiffId: Nullable<number>) => handlePlaintiffChange(plaintiffId ?? null)}
                disabled={!provider}
                isLoading={updatePartitionMutation.isLoading}
              />
            </Box>
          )}
          {provider && (
            <MismatchFlag
              exhibitPartitionPlaintiffId={plaintiffId}
              partitionProviderPlaintiffId={provider?.plaintiff_id ?? null}
            />
          )}
          <Box display="flex" alignItems="center">
            <Box visibility={duplicate ? "visible" : "hidden"} mr={2}>
              <Chip label="Duplicate" variant="outlined" size="small" />
            </Box>
            <Box mr={1}>
              <Text>Pages: {displayPageRange(start_page, end_page)}</Text>
            </Box>
            {downloadingFile ? (
              <Box p={1}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <AnnotationFileIconButton onClick={handleDownload}>
                <GetApp />
              </AnnotationFileIconButton>
            )}
            {!isDeleted && (
              <AnnotationFileIconButton onClick={handleMoreOptionsMenuOpen}>
                <MoreHoriz />
              </AnnotationFileIconButton>
            )}
            {!isDeleted &&
              (duplicate ? (
                <DuplicateMenu
                  anchorEl={moreOptionsAnchor}
                  onClose={handleMoreOptionsMenuClose}
                  onDeleteDuplicateSuccess={handleMoreOptionsMenuClose}
                  onDeletePagesClick={handleDeletePagesClick}
                  exhibitId={exhibitId}
                  partitionId={pk}
                  caseId={caseId}
                  section={section}
                  isProvider={isProvider}
                  isNoAutoImport={isNoAutoImport}
                />
              ) : (
                <SplitMenu
                  anchorEl={moreOptionsAnchor}
                  onClose={handleMoreOptionsMenuClose}
                  onDeletePagesClick={handleDeletePagesClick}
                  exhibitId={exhibitId}
                  partitionId={pk}
                  caseId={caseId}
                  section={section}
                  isProvider={isProvider}
                  isNoAutoImport={isNoAutoImport}
                />
              ))}
          </Box>
        </Box>
        <ExcerptTable
          status={status}
          excerpts={excerpts}
          onPreview={handleOpenPreview}
          startPage={start_page}
          endPage={end_page}
        />
        {relevantDeletions.length > 0 && (
          <Box mt={2}>
            <DeletionTable
              relevantDeletions={relevantDeletions}
              exhibitId={exhibitId}
              caseId={caseId}
              duplicateId={duplicate ? pk : null}
              onPreview={handleOpenPreview}
            />
          </Box>
        )}
      </Box>
    </AnnotationsPaper>
  )
}

export const AnnotationSplit: React.FC<AnnotationSplitProps> = props => <AnnotationPartition {...props} />
export const AnnotationDuplicate: React.FC<AnnotationDuplicateProps> = props => (
  <AnnotationPartition {...props} duplicate />
)
