import { useEffect, useMemo, useRef } from "react"
import { useController, useFormContext, useWatch } from "react-hook-form"
import { queryKeys } from "react-query/constants"
import { useQuery } from "@tanstack/react-query"
import { Box, LinearProgress, Typography } from "@mui/material"
import _ from "lodash"

import { getUsers } from "api"
import { UserDto } from "common/models/user"
import { useRequestContext } from "requests/context"
import { StyledTypographyDescription } from "requests/RequestForm/AdditionalInfoRevamp/styled"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import { usePermissions } from "permissions/usePermissions"
import useUser from "hooks/useUser"
import MultiSelectAutoComplete from "evenup-ui/MultiSelectAutoComplete"
import { RequestFormModule } from "./RequestFormModule"

interface Collaborator {
  pk: number
  first_name: string
  last_name: string
  email: string
}

interface OptionProps {
  label: string
  value: string
  collaborator: Collaborator
  isSelected?: boolean
}

function usePreviousValue<T>(value: T) {
  const ref = useRef<T>(value)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const Collaborators = () => {
  const { requestId } = useRequestContext()
  const { getValues } = useFormContext()
  const submitter = useWatch({ name: "submitter" })
  const firmId = useWatch({ name: "firm_id" })
  const previousFirmId = usePreviousValue(firmId)

  const { user } = useUser({ suspense: true })

  const { collaboratorSelectionEnabled } = usePermissions({
    suspense: true,
    enabled: user.isAuthorized,
    firmId,
  })

  const {
    field: { value, onChange },
  } = useController({ name: "collaborators" })

  // If the firm ID changes, clear out collaborators.
  // Collaborators are supposed to only be from the selected firm
  useEffect(() => {
    if (previousFirmId !== firmId) {
      onChange([])
    }
  }, [firmId, previousFirmId, onChange])

  const { data: firmUsers, isFetching } = useQuery<unknown, unknown, UserDto[]>({
    queryKey: [queryKeys.users, firmId],
    queryFn: () => getUsers({ page: null, pageSize: null, searchQuery: null, minimal: null, firmId: firmId }),
    meta: { disableLoader: true },
    enabled: collaboratorSelectionEnabled,
  })

  const collaboratorsOptions = useMemo<OptionProps[]>(() => {
    return (
      firmUsers
        ?.filter(user => user.pk !== submitter?.pk)
        ?.map((user: UserDto) => ({
          collaborator: {
            pk: user.pk,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
          },
          label: `${user.first_name} ${user.last_name}`,
          value: user.pk.toString(),
          isSelected: value?.some((collab: Collaborator) => collab.pk === user.pk),
        })) || []
    )
  }, [firmUsers, value, submitter])

  const selectedCollaborators = collaboratorsOptions?.filter((val: OptionProps) => val && val.isSelected)

  const handleChangeValue = (value: OptionProps[]) => {
    const collaborators = value.map(optionProps => optionProps.collaborator)
    amplitudeApm.trackEvent(
      new RequestAnalyticEvent(RequestAnalyticsEventTypes.CollaboratorsUpdated, {
        firm_id: firmId,
        request_id: requestId,
        request_type: getValues("type"),
        collaborator_ids: collaborators.map(collaborator => collaborator.pk),
      })
    )
    onChange(collaborators)
  }

  if (!collaboratorSelectionEnabled || !requestId) {
    return <></>
  }

  return (
    <RequestFormModule title="Invite Collaborators">
      <StyledTypographyDescription mb={3}>
        Collaborators will be able to view, edit, and get notified about this request.
      </StyledTypographyDescription>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
        {!isFetching && (
          <MultiSelectAutoComplete
            label="Collaborator(s)"
            name="collaborators_list"
            dataTest="collaborators_dropdown"
            options={collaboratorsOptions}
            enableClear={true}
            size="small"
            noOptionsText="No users found in firm"
            autoCompletePlaceholderText=""
            value={selectedCollaborators}
            onChangeValue={handleChangeValue}
            onClear={() => onChange([])}
          />
        )}
        {isFetching && (
          <Box>
            <Typography variant="body1">Loading Collaborators...</Typography>
            <LinearProgress color="inherit" />
          </Box>
        )}
      </Box>
    </RequestFormModule>
  )
}

export default Collaborators
