import { EditableSelect } from "evenup-ui/EditableSelect"
import { Row, DateField, ExhibitLink } from "./styled"
import { useCallback } from "react"
import { Item } from "evenup-ui/EditableSelect/type"
import { DateRangePicker } from "./DateRangePicker/DateRangePicker"

type DateInput = Nullable<string> | { start: string; end: string }

export interface PartitionListItemProps<T extends DateInput> {
  providers: Item[]
  provider: Nullable<string>
  documentType?: string
  startPage: number
  endPage: number
  onPreview: () => void
  onProviderChange: (newProviderId: Nullable<string>) => void
  onProviderCreate: (name: string) => Promise<Item>
  onProviderRename: (providerId: string, newName: string) => void
  dateOfService: T
  onDateChange: (date: this["dateOfService"]) => void
  readOnly?: boolean
}

export function PartitionListItem<T extends DateInput>({
  provider,
  providers,
  documentType,
  startPage,
  endPage,
  dateOfService,
  onPreview,
  onProviderChange,
  onProviderCreate,
  onProviderRename,
  onDateChange,
  readOnly,
  ...props
}: PartitionListItemProps<T>) {
  const handleProviderCreate = useCallback((name: string) => onProviderCreate(name), [onProviderCreate])
  const handleProviderRename = useCallback(
    (providerId: string, name: string) => onProviderRename(providerId, name),
    [onProviderRename]
  )
  const handleProviderChange = useCallback(
    (providerId: Nullable<string>) => onProviderChange(providerId),
    [onProviderChange]
  )

  return (
    <Row {...props}>
      <EditableSelect
        itemType="provider"
        label="Provider Name"
        value={provider}
        emptyValueLabel="None to Exclude"
        onChange={handleProviderChange}
        onCreate={handleProviderCreate}
        onRename={handleProviderRename}
        options={providers}
        readOnly={readOnly}
      />
      {documentType && <div>{documentType}</div>}
      {!dateOfService || typeof dateOfService === "string" ? (
        <DateField
          dataTest="partition-list-item-date"
          required
          initialValue={dateOfService}
          onChange={onDateChange as (date: Nullable<string>) => void}
          fieldProps={{ name: "start_date", size: "small", margin: "normal" }}
          readOnly={readOnly}
        />
      ) : (
        <DateRangePicker
          startDate={dateOfService.start}
          endDate={dateOfService.end}
          onChange={onDateChange as (date: { start: string; end: string }) => void}
          readOnly={readOnly}
        />
      )}
      <ExhibitLink onClick={onPreview}>
        {startPage}-{endPage}
      </ExhibitLink>
    </Row>
  )
}
