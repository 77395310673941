import Chip from "@mui/material/Chip"
import { makeStyles } from "tss-react/mui"
import { startCase } from "lodash"

import { useFormContext } from "./FormContext"

const useStyles = makeStyles()(theme => ({
  facet: {
    margin: theme.spacing(0, 2),
  },
}))

export default function FacetList() {
  const AWARD_AMOUNT_FACET = "total_settlement_amount"
  const DATE_FACET = "case_decision_date"
  const INJURY_FACET = "injury_tags"
  const TREATMENTS = "treatments"
  const RANGE_FACETS = [AWARD_AMOUNT_FACET, DATE_FACET]
  const { classes } = useStyles()
  const { queryActions, queryState, handleQueryUpdate } = useFormContext()
  let facetValues = []

  const handleRemoveFacet = (key, value) => {
    if (key === INJURY_FACET) {
      handleQueryUpdate(queryActions.REMOVE_FACET_BY_INDEX, { name: INJURY_FACET, value })
    } else if (key === TREATMENTS) {
      handleQueryUpdate(queryActions.RESET_RANGE_FACET, key)
    } else {
      RANGE_FACETS.includes(key)
        ? handleQueryUpdate(queryActions.RESET_RANGE_FACET, key)
        : handleQueryUpdate(queryActions.REMOVE_FACET, { name: key, value })
    }
  }

  const facets = Object.entries(queryState.query).reduce((acc, [key, values]) => {
    if (RANGE_FACETS.includes(key)) {
      let facetLabel

      // handle award amount facet
      if (key === AWARD_AMOUNT_FACET) {
        facetLabel = "Reset award amount selection"
      }

      // handle date facet
      if (key === DATE_FACET) {
        facetLabel = values.join(" - ")
      }

      acc.push(
        <Chip
          className={classes.facet}
          variant="outlined"
          label={facetLabel}
          key={facetLabel}
          onDelete={() => handleRemoveFacet(key)}
          color="secondary"
        />
      )
    } else {
      if (key === INJURY_FACET) {
        facetValues = values.map((injury, index) => {
          return (
            <Chip
              className={classes.facet}
              variant="outlined"
              label={
                injury.injury_types
                  ? startCase(injury.injury_types).toLowerCase()
                  : `${injury.category} // ${injury.problem}`
              }
              key={`${key}-${index}`}
              onDelete={() => handleRemoveFacet(key, index)}
              color="secondary"
            />
          )
        })
      } else if (key === TREATMENTS) {
        facetValues = [
          <Chip
            className={classes.facet}
            variant="outlined"
            label={`Reset surgeries visibility`}
            key={key}
            onDelete={() => handleRemoveFacet(key)}
            color="secondary"
          />,
        ]
      } else {
        facetValues = values.map((value, index) => (
          <Chip
            className={classes.facet}
            variant="outlined"
            label={value}
            key={`${key}-${value}-${index}`}
            onDelete={() => handleRemoveFacet(key, value)}
            color="secondary"
          />
        ))
      }

      acc.push(...facetValues)
    }
    return acc
  }, [])

  return <>{facets}</>
}
