import { useCallback, useState } from "react"
import { Box, Button, IconButton } from "@mui/material"
import { makeStyles } from "tss-react/mui"
import { AutoAwesomeOutlined, Close } from "@mui/icons-material"
import styled from "@emotion/styled"

import { Link } from "react-router-dom"
import { blue, indigo } from "@mui/material/colors"
import { useLocalStorage } from "hooks/useLocalStorage"

const useStyles = makeStyles()(theme => ({
  gradientButton: {
    background: `linear-gradient(183deg, ${indigo[400]} 0%, ${blue[300]} 100%)`,
    color: theme.palette.common.white,
    marginLeft: "auto",
    textAlign: "center",
  },
}))

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.blue.banner,
  padding: theme.spacing(1, 2),
  border: `solid 2px ${theme.palette.blue.bannerStroke}`,
  borderRadius: "6px",
}))

const AutoAwesomeIcon = styled(AutoAwesomeOutlined)(() => ({
  color: blue[500],
}))

interface LinkBannerInterface {
  text?: string | JSX.Element
  to: string
  buttonText?: string
  icon?: JSX.Element
  localStorageHideKey?: string
  additionalClass?: string
  allowClose?: boolean
}

export const LinkBanner = ({
  to,
  text,
  buttonText = "Try it out",
  icon = <AutoAwesomeIcon />,
  localStorageHideKey,
  additionalClass,
  allowClose = true,
}: LinkBannerInterface): JSX.Element => {
  const { classes, cx } = useStyles()
  const { isValueHidden, hideKey } = useLocalStorage()

  const isHidden = isValueHidden(localStorageHideKey ?? "")
  const [hide, setHide] = useState(isHidden)

  const handleCloseClick = useCallback(() => {
    setHide(true)
    localStorageHideKey && hideKey(localStorageHideKey)
  }, [localStorageHideKey, hideKey])

  if (hide) return <></>

  return (
    <Container>
      <Box display="flex" flexDirection="row" gap={2} alignItems="center" width="-webkit-fill-available">
        {icon}
        <Box fontWeight="600">{text}</Box>
        <Button
          className={cx(classes.gradientButton, additionalClass)}
          variant="contained"
          component={Link}
          to={to}
          color="inherit"
        >
          {buttonText}
        </Button>

        {allowClose && (
          <IconButton onClick={handleCloseClick}>
            <Close />
          </IconButton>
        )}
      </Box>
    </Container>
  )
}
