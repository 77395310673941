import { Box } from "@mui/material"
import styled from "@emotion/styled"

interface IntegrationCardProps {
  label: string
  image: string
  onClick: () => void
}

const Container = styled(Box)(({ theme }) => ({
  height: "100px",
  maxWidth: "450px",
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "10px",
  boxShadow: "3px 3px 1px grey",
  display: "grid",
  gridTemplateColumns: "1fr 3fr",
  padding: theme.spacing(4),
  alignContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
    cursor: "pointer",
  },
}))

const Label = styled(Box)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 700,
  margin: theme.spacing(0, "auto"),
}))

export const IntegrationCard = ({ label, image, onClick }: IntegrationCardProps) => {
  return (
    <Container onClick={onClick}>
      <img src={image} width="100px" />
      <Label>{label}</Label>
    </Container>
  )
}
