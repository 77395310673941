import * as d3 from "d3"

import { MedicronCalendarAppointment } from "./types"

export function CreateAppointmentPieChartSvg(
  svgElement: SVGSVGElement,
  appointments: MedicronCalendarAppointment[],
  isHighlightedDay: boolean
) {
  const pieData = d3.pie().sort(null)(appointments.map(() => 1))

  const parentWidth = svgElement.clientWidth
  const parentHeight = svgElement.clientHeight

  const centerX = parentWidth / 2
  const centerY = parentHeight / 2
  const radius = Math.min(centerX, centerY)
  const hasAppointment = appointments.length > 0

  const arc = d3
    .arc()
    .innerRadius(isHighlightedDay && hasAppointment ? radius - 2 : 0)
    .outerRadius(radius)

  d3.select(svgElement)
    .selectAll("path")
    .data(pieData)
    .enter()
    .append("path")
    .attr("transform", `translate(${centerX}, ${centerY})`)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .attr("d", (d: any) => arc(d) as string)
    .attr("fill", (_: unknown, i: number) => appointments[i].providerColor)
}
