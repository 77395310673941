import { useCallback } from "react"
import { IconButton } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import { BlackTooltip } from "common/Tooltip"
import { DocumentStructureDefinition } from "common/types/documentStructure"
import { downloadLetterhead } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import splitFileName from "common/form-components/files/splitFileName"

import {
  StyledLetterheadNameWrapper,
  StyledLetterheadPostfixWrapper,
  StyledLetterheadPrefixWrapper,
} from "../styled"

interface LetterheadPreivewProps {
  letterhead: Nullable<DocumentStructureDefinition["letterhead"]>
  handleDeleteLetterhead: () => void
  error?: boolean
  deleting?: boolean
}

export function LetterheadPreivew({
  letterhead,
  error,
  handleDeleteLetterhead,
  deleting,
}: LetterheadPreivewProps): Nullable<JSX.Element> {
  const { showErrorMessage } = useHandleMessages()
  const clickHanlder = useCallback(() => {
    if (!letterhead?.fileUrl || !letterhead?.name) return

    downloadLetterhead({ fileName: letterhead.name, customUrl: letterhead.fileUrl }).catch(error => {
      showErrorMessage({
        message:
          "Could not download the letterhead. Try again shortly and file an issue if your problem persists.",
        error,
      })
    })
  }, [letterhead, showErrorMessage])

  if (!letterhead?.name) return null

  const [name, ext] = splitFileName(letterhead.name)

  return (
    <BlackTooltip placement="bottom" arrow title={letterhead.name}>
      <StyledLetterheadNameWrapper error={error}>
        <StyledLetterheadPrefixWrapper onClick={clickHanlder} link={!!letterhead.fileUrl}>
          {name}
        </StyledLetterheadPrefixWrapper>
        <StyledLetterheadPostfixWrapper onClick={clickHanlder} link={!!letterhead.fileUrl}>
          {ext}
        </StyledLetterheadPostfixWrapper>
        {!deleting && (
          <IconButton size="small" onClick={handleDeleteLetterhead}>
            <CancelIcon color="primary" />
          </IconButton>
        )}
      </StyledLetterheadNameWrapper>
    </BlackTooltip>
  )
}
