import { MIN_PAGE_NUMBER } from "../types"

type ValidationErrorKey = "start" | "end"

export type DuplicateValidationErrors = Partial<Record<ValidationErrorKey, Nullable<string>>>

interface ValidateDuplicateProps {
  start: number
  end: number
  numberOfPages: number
}

export const validateDuplicate = ({
  start,
  end,
  numberOfPages,
}: ValidateDuplicateProps): DuplicateValidationErrors => {
  const errors: DuplicateValidationErrors = {}

  if (Number.isNaN(start)) {
    errors.start = "Start page is required"
  }

  if (Number.isNaN(end)) {
    errors.end = "End page is required"
  }

  if (start > end) {
    errors.start = "Start page must be lower than or equal to end"
  }

  if (start < MIN_PAGE_NUMBER) {
    errors.start = `Must start from page ${MIN_PAGE_NUMBER} or higher`
  }

  if (end > numberOfPages) {
    errors.end = `Must end on page ${numberOfPages} or lower`
  }

  return errors
}
