import React, { useState } from "react"
import BaseTabs from "@mui/material/Tabs"
import BaseTabsHeader from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { TabPanel } from "./TabPanel"

const useStyles = makeStyles()(theme => ({
  tabsWrapper: {
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(2),
  },
  tabsHeader: {
    marginTop: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

interface TabsProps {
  className?: string
  children: React.ReactElement<{ title: string }>[]
}

export const Tabs: React.FC<TabsProps> = ({ className, children }: TabsProps) => {
  const { classes, cx } = useStyles()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const tabsData = React.Children.map(children, (tabElement, index) => ({
    index,
    label: tabElement.props.title,
  }))

  const handleActiveTabIndexChange = (_event: React.SyntheticEvent, nextIndex: number) => {
    setActiveTabIndex(nextIndex)
  }

  return (
    <Box className={cx(classes.tabsWrapper, className)}>
      <BaseTabs
        indicatorColor="secondary"
        textColor="inherit"
        value={activeTabIndex}
        onChange={handleActiveTabIndexChange}
        className={classes.tabsHeader}
      >
        {tabsData.map(({ label, index }) => (
          <BaseTabsHeader label={label} key={index} />
        ))}
      </BaseTabs>
      {React.Children.map(children, (tabContent, index) => (
        <TabPanel key={index} active={activeTabIndex === index}>
          {tabContent}
        </TabPanel>
      ))}
    </Box>
  )
}
