import { PaginatedOptions } from "api/PaginatedList"
import { BlobResponse, EmptyResponse } from "apiHelper"
import { PaginatedList } from "common/models/pagination"

export interface ApiServiceType {
  create<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse>

  call<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse>

  get<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse>

  getRaw<TFields>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<BlobResponse | EmptyResponse>

  getPaginatedList<TResponseItem>(
    path: string,
    query?: Nullable<string>,
    paginationOptions?: PaginatedOptions
  ): Promise<PaginatedList<TResponseItem>>

  update<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<Partial<TFields>>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse>

  replace<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse>

  delete<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse>

  submitForm<TFormData extends FormData, TResponse>(
    data: TFormData extends never ? never : Nullable<TFormData>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse>

  updateForm<TFormData extends FormData, TResponse>(
    data: TFormData extends never ? never : Nullable<TFormData>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse>
}

export class NonUniqueAttributesError {}
