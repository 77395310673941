import { LOCAL_STORAGE_LEFT_NAV_STATE } from "./constants"

export enum LeftNavStates {
  OPEN = "open",
  CLOSED = "closed",
}

export const getLeftNavStateFromLocalStorage = (): boolean => {
  const state = localStorage.getItem(LOCAL_STORAGE_LEFT_NAV_STATE)
  return state === LeftNavStates.OPEN
}

export const setLeftNavStateFromLocalStorage = (state: LeftNavStates): void => {
  localStorage.setItem(LOCAL_STORAGE_LEFT_NAV_STATE, state)
}
