export enum CASE_STEP {
  FIRM = "law_firm",
  PLAINTIFF = "plaintiff",
  INTRODUCTION = "introduction",
  CARRIER = "carrier",
  FACTS = "facts",
  PROVIDERS = "providers",
  PAIN_AND_SUFFERING = "pain_and_suffering",
  FUTURE_EXPENSES = "future_expenses",
  NON_ECONOMIC = "non_economic",
  INCOME_LOSS = "income_loss",
  HOUSEHOLD_LOSS = "household_loss",
  DAMAGES_SECTIONS = "damages_sections",
  CONCLUSION = "conclusion",
}

export enum DEMAND_SECTION {
  MISSING_DOCUMENTS = "missing_documents",
  EXHIBITS = "exhibits",
}
