import { Editor, Transforms, Node } from "slate"
import { CustomEditor, CustomElement } from "../CustomEditor"
import { ELEMENTS, LEAF_BLOCK_ELEMENTS } from "../elements"
import { isBlockNode } from "../queries"

export function isElementsOfType(editor: CustomEditor, type: ELEMENTS): boolean {
  const nodes = [...Editor.nodes<CustomElement>(editor)].filter(([node]) => Boolean(node.type))

  return nodes.length > 0 && nodes.every(([node]) => node.type === type)
}

export function setElementType(editor: CustomEditor, type: LEAF_BLOCK_ELEMENTS): void {
  Transforms.setNodes<CustomElement>(editor, { type })
}

export function isElementsOfTheSameType(editor: CustomEditor, nodeA: Node, nodeB: Node): boolean {
  if (!isBlockNode(editor, nodeA) || !isBlockNode(editor, nodeB)) return false

  return nodeA.type === nodeB.type
}
