import React, { useMemo } from "react"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import { makeStyles } from "tss-react/mui"
import LinkButton from "../../buttons/LinkButton"
import { ACCEPT_ALL_TYPE, ACCEPT_IMAGE_TYPE, ACCEPT_TYPE, DOCUMENTS, IMAGES } from "./constants"
import { fileTypeRestrictionText } from "./utils"

interface StyleProps {
  isDragActive: boolean
  isFocused: boolean
  disabled: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, { isDragActive, isFocused, disabled }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    gap: theme.spacing(2),

    border: disabled
      ? `2px dashed ${theme.palette.grey[400]}`
      : isDragActive || isFocused
        ? `2px dashed ${theme.palette.blue.main}`
        : `2px dashed ${theme.palette.grey[500]}`,
    borderRadius: "16px",
    backgroundColor: isDragActive ? theme.palette.blue.light : theme.palette.common.white,
  },
  clickShield: {
    position: "absolute",
    height: "100%",
    top: 0,
    background: "white",
    cursor: "pointer",
    transition: "opacity .2s",
    opacity: "0%",
    zIndex: "1", // Need to explicitly set so it gets higher priority than Avatar
    "&:hover": {
      opacity: "30%",
    },
  },
  clickShieldLeft: {
    left: 0,
    right: "calc(50% - 14px)",
  },
  clickShieldRight: {
    left: "calc(50% + 12px)", // overlap in case 50% gives us half a pixel
    right: 0,
  },
  fileDrop: {
    padding: theme.spacing(4),
    display: "flex",
    position: "relative",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    transition: "all .2s",
  },
  fileDropAvatar: {
    width: "56px",
    height: "56px",
    backgroundColor: disabled
      ? theme.palette.grey[600]
      : isDragActive
        ? theme.palette.blue.main
        : theme.palette.primary.main,
    color: disabled ? theme.palette.grey[200] : theme.palette.primary.contrastText,
    transition: "background-color .2s",
  },
  fileDropInstructions: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  fileDropDivider: {
    margin: "0px 12px",
    borderLeft: "1px solid black",
  },
  fileDropRestrictions: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[600],
  },
  fileBrowserButton: {
    cursor: disabled ? "not-allowed" : "pointer",
  },
  childrenContainer: {
    "&:hover": {
      cursor: "default",
    },
  },
}))

interface Props {
  isDragActive: boolean
  isFocused: boolean
  disabled: boolean
  children: React.ReactNode
  fileTypes?: ACCEPT_TYPE
  onChooseFileClick: React.MouseEventHandler<HTMLDivElement>
  onChooseFolderClick: React.MouseEventHandler<HTMLDivElement>
}

const getFileTypeStringByFileTypes = (fileTypes: ACCEPT_TYPE): string => {
  if (fileTypes === ACCEPT_IMAGE_TYPE) {
    return IMAGES
  }
  return DOCUMENTS
}

const getRestrictionsText = (fileTypes: ACCEPT_TYPE): string => {
  return fileTypeRestrictionText(fileTypes)
}

const getFileTypeIconByFileTypes = (fileTypes: ACCEPT_TYPE): JSX.Element => {
  if (fileTypes === ACCEPT_IMAGE_TYPE) {
    return <PhotoLibraryIcon fontSize="large" />
  }
  return <UploadFileIcon fontSize="large" />
}

const FileDropper = ({
  isDragActive,
  isFocused,
  disabled,
  children,
  fileTypes = ACCEPT_ALL_TYPE,
  onChooseFileClick,
  onChooseFolderClick,
}: Props): JSX.Element => {
  const { classes } = useStyles({ isDragActive, isFocused, disabled })

  const fileTypeString = useMemo(() => getFileTypeStringByFileTypes(fileTypes), [fileTypes])
  const icon = useMemo(() => getFileTypeIconByFileTypes(fileTypes), [fileTypes])
  const restrictionsText = useMemo(() => getRestrictionsText(fileTypes), [fileTypes])

  return (
    <Box className={classes.container}>
      <Box className={classes.fileDrop}>
        <Box
          className={`${classes.clickShield} ${classes.clickShieldLeft}`}
          onClick={ev => !disabled && onChooseFileClick(ev)}
        />
        <Box
          className={`${classes.clickShield} ${classes.clickShieldRight}`}
          onClick={ev => !disabled && onChooseFolderClick(ev)}
        />
        <Avatar className={classes.fileDropAvatar}>{icon}</Avatar>
        <Box>
          <Box className={classes.fileDropInstructions}>
            <Box>Drop {fileTypeString} here or </Box>
            <Box display="flex">
              <LinkButton
                data-test="choose_files_button"
                className={classes.fileBrowserButton}
                small
                disabled={disabled}
              >
                Choose Files
              </LinkButton>
              <Box className={classes.fileDropDivider} />

              <LinkButton
                data-test="choose_folders_button"
                className={classes.fileBrowserButton}
                small
                disabled={disabled}
              >
                Choose Folders
              </LinkButton>
            </Box>
          </Box>
          <Box className={classes.fileDropRestrictions}>{restrictionsText}</Box>
        </Box>
      </Box>
      {!!children && (
        <Box className={classes.childrenContainer} onClick={e => e.stopPropagation()}>
          {children}
        </Box>
      )}
    </Box>
  )
}

export default FileDropper
