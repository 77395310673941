import { PropsWithChildren, createContext, memo, useCallback, useContext } from "react"

export type ThumbnailCacheContextType = Partial<Record<number, string>>

const ThumbnailCacheContext = createContext<ThumbnailCacheContextType>({})

export const ThumbnailCacheProvider = memo<PropsWithChildren<{ value: ThumbnailCacheContextType }>>(
  function ThumbnailCacheProvider({ children, value }): JSX.Element {
    return <ThumbnailCacheContext.Provider value={value}>{children}</ThumbnailCacheContext.Provider>
  }
)

export function useThumbnailCache(page: number): [string | undefined, (value: string) => void, () => void] {
  const cache = useContext(ThumbnailCacheContext)

  const setCacheItem = useCallback(
    (value: string): void => {
      cache[page] = value
    },
    [page, cache]
  )

  const clearCachedItem = useCallback(() => {
    delete cache[page]
  }, [page, cache])

  return [cache[page], setCacheItem, clearCachedItem]
}
