import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { SectionTemplateServiceDeserializer } from "../section-template/serializers"
import { ApiServiceType } from "../types"
import { CaseServiceDeserializer, CaseServiceSerializer } from "./serializers"
import { getQuery } from "../utils"
import {
  CaseAnalyticsDto,
  CaseAttributeValuesDto,
  CaseFactsSectionUpdateDto,
  CaseFactsUpdateDto,
  CaseConclusionUpdateDto,
  CaseIntroductionUpdateDto,
  CasePainAndSufferingUpdateDto,
  ProviderTemplatedSectionDto,
  ProviderTemplatedSectionUpdateDto,
  ProviderTemplateUpdataDto,
} from "./types"

enum CASE_API_PATHS {
  BASE = "case",
  ANALYTICS = "analytics",
  ATTRIBUTES = "attributes",
  MATCHING_TEMPLATES = "matching_templates",
  FACTS = "facts",
  TEMPLATED_SECTIONS = "template_sections",
  TEMPLATED_PROVIDER_SECTIONS = "template-sections",
  TEMPLATED_SECTION_OVERRIDE = "confirm_override",
  TEMPLATED_PROVIDER_OVERRIDE = "confirm-override",
  PROVIDER = "provider",
  PLAINTIFF = "plaintiff",
  INTRODUCTION = "introduction",
  CONCLUSION = "conclusion",
  PAIN_AND_SUFFERING = "pain-and-suffering",
}

interface CaseServiceOptions {
  caseId: PrimaryKey
}

interface SelectProviderTemplateOptions {
  caseId: PrimaryKey
  providerId: PrimaryKey
  templateSectionId: PrimaryKey
}

class CaseService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(path?: CASE_API_PATHS | (CASE_API_PATHS | PrimaryKey)[]): string {
    const pathParts = Array.from<string | PrimaryKey>(["", CASE_API_PATHS.BASE]).concat(path ?? [])
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getCase = withResponseSerializer(CaseServiceDeserializer.fromJSON, ({ caseId }: CaseServiceOptions) => {
    return handleEmptyResponse(this.apiService.get(null, this.getPath([caseId])))
  })

  updataCaseAttributes = withRequestSerializer(
    CaseServiceSerializer.attributeValuesToJSON,
    withResponseSerializer(
      CaseServiceDeserializer.fromJSON,
      ({ data, caseId }: { data: CaseAttributeValuesDto } & CaseServiceOptions) => {
        return handleEmptyResponse(
          this.apiService.update(data, this.getPath([caseId, CASE_API_PATHS.ATTRIBUTES]))
        )
      }
    )
  )

  updateAddTitlePage = withRequestSerializer(
    CaseServiceSerializer.addTitlePageFromJSON,
    withResponseSerializer(
      CaseServiceDeserializer.fromJSON,
      ({ data, caseId }: { data: { add_title_page: boolean } } & CaseServiceOptions) => {
        return handleEmptyResponse(this.apiService.update(data, this.getPath([caseId])))
      }
    )
  )

  getMatchingTemplates = withResponseSerializer(
    SectionTemplateServiceDeserializer.templatesBySectionFromJSON,
    ({ caseId }: CaseServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath([caseId, CASE_API_PATHS.MATCHING_TEMPLATES]))
      )
    }
  )

  getCaseFacts = withResponseSerializer(
    CaseServiceDeserializer.caseFactsFromJSON,
    ({ caseId }: CaseServiceOptions) => {
      return handleEmptyResponse(this.apiService.get(null, this.getPath([caseId, CASE_API_PATHS.FACTS])))
    }
  )

  getCasePainAndSuffering = withResponseSerializer(
    CaseServiceDeserializer.casePainAndSufferingFromJSON,
    ({ caseId, plaintiffId }: { caseId: PrimaryKey; plaintiffId?: string }) => {
      const query = getQuery({ ["plaintiff_id"]: [plaintiffId] })
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath([caseId, CASE_API_PATHS.PAIN_AND_SUFFERING]), query)
      )
    }
  )

  getCaseIntroduction = withResponseSerializer(
    CaseServiceDeserializer.caseIntroductionFromJSON,
    ({ caseId }: CaseServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath([caseId, CASE_API_PATHS.INTRODUCTION]))
      )
    }
  )

  getCaseConclusion = withResponseSerializer(
    CaseServiceDeserializer.caseConclusionFromJSON,
    ({ caseId }: CaseServiceOptions) => {
      return handleEmptyResponse(this.apiService.get(null, this.getPath([caseId, CASE_API_PATHS.CONCLUSION])))
    }
  )

  selectCaseProviderTemplate = withRequestSerializer(
    CaseServiceSerializer.providerTemplatedSectionToJSON,
    ({
      caseId,
      providerId,
      templateSectionId,
      data,
    }: SelectProviderTemplateOptions & { data: ProviderTemplateUpdataDto }) => {
      return handleEmptyResponse<ProviderTemplatedSectionDto>(
        this.apiService.replace(
          data,
          this.getPath([
            caseId,
            CASE_API_PATHS.PROVIDER,
            providerId,
            CASE_API_PATHS.TEMPLATED_PROVIDER_SECTIONS,
            templateSectionId,
          ])
        )
      )
    }
  )

  createCaseProviderTemplate = withRequestSerializer(
    CaseServiceSerializer.providerTemplatedSectionToJSON,
    ({
      caseId,
      providerId,
      data,
    }: Pick<SelectProviderTemplateOptions, "caseId" | "providerId"> & {
      data: ProviderTemplateUpdataDto
    }) => {
      return handleEmptyResponse<ProviderTemplatedSectionDto>(
        this.apiService.create(
          data,
          this.getPath([
            caseId,
            CASE_API_PATHS.PROVIDER,
            providerId,
            CASE_API_PATHS.TEMPLATED_PROVIDER_SECTIONS,
          ])
        )
      )
    }
  )

  overrideProviderTemplate1 = withRequestSerializer(
    CaseServiceSerializer.providerTemplatedSectionToJSON,
    ({
      caseId,
      providerId,
      templateSectionId,
      data,
    }: SelectProviderTemplateOptions & { data: ProviderTemplateUpdataDto }) => {
      return handleEmptyResponse<ProviderTemplatedSectionDto>(
        this.apiService.replace(
          data,
          this.getPath([
            caseId,
            CASE_API_PATHS.PROVIDER,
            providerId,
            CASE_API_PATHS.TEMPLATED_PROVIDER_SECTIONS,
            templateSectionId,
          ])
        )
      )
    }
  )

  overrideProviderTemplate = withRequestSerializer(
    CaseServiceSerializer.resolvedProviderTemplatedSectionToJSON,
    ({
      data,
      caseId,
      providerId,
      templateSectionId,
    }: SelectProviderTemplateOptions & { data: ProviderTemplatedSectionUpdateDto }) => {
      return handleEmptyResponse<ProviderTemplatedSectionDto>(
        this.apiService.create(
          data,
          this.getPath([
            caseId,
            CASE_API_PATHS.PROVIDER,
            providerId,
            CASE_API_PATHS.TEMPLATED_PROVIDER_SECTIONS,
            templateSectionId,
            CASE_API_PATHS.TEMPLATED_PROVIDER_OVERRIDE,
          ])
        )
      )
    }
  )

  saveCaseFacts = withRequestSerializer(
    CaseServiceSerializer.caseFactsToJSON,
    withResponseSerializer(
      CaseServiceDeserializer.caseFactsFromJSON,
      ({ caseId, data }: CaseServiceOptions & { data: CaseFactsUpdateDto }) => {
        return handleEmptyResponse(this.apiService.create(data, this.getPath([caseId, CASE_API_PATHS.FACTS])))
      }
    )
  )
  saveCasePainAndSuffering = withRequestSerializer(
    CaseServiceSerializer.casePainAndSufferingToJSON,
    withResponseSerializer(
      CaseServiceDeserializer.casePainAndSufferingFromJSON,
      ({
        caseId,
        plaintiffId,
        data,
      }: { caseId: PrimaryKey } & { plaintiffId?: string } & { data: CasePainAndSufferingUpdateDto }) => {
        return handleEmptyResponse(
          this.apiService.create(
            { ...data, plaintiff_id: plaintiffId },
            this.getPath([caseId, CASE_API_PATHS.PAIN_AND_SUFFERING])
          )
        )
      }
    )
  )

  saveCaseIntroduction = withRequestSerializer(
    CaseServiceSerializer.caseIntroductionToJSON,
    withResponseSerializer(
      CaseServiceDeserializer.caseIntroductionFromJSON,
      ({ caseId, data }: CaseServiceOptions & { data: CaseIntroductionUpdateDto }) => {
        return handleEmptyResponse(
          this.apiService.create(data, this.getPath([caseId, CASE_API_PATHS.INTRODUCTION]))
        )
      }
    )
  )

  saveCaseConclusion = withRequestSerializer(
    CaseServiceSerializer.caseConclusionToJSON,
    withResponseSerializer(
      CaseServiceDeserializer.caseConclusionFromJSON,
      ({ caseId, data }: CaseServiceOptions & { data: CaseConclusionUpdateDto }) => {
        return handleEmptyResponse(
          this.apiService.create(data, this.getPath([caseId, CASE_API_PATHS.CONCLUSION]))
        )
      }
    )
  )

  overrideCaseSection = withRequestSerializer(
    CaseServiceSerializer.resolvedTemplatedSectionToJSON,
    withResponseSerializer(
      CaseServiceDeserializer.templatedSectionFromJSON,
      ({ caseId, data }: CaseServiceOptions & { data: CaseFactsSectionUpdateDto }) => {
        return handleEmptyResponse(
          this.apiService.create(
            data,
            this.getPath([
              caseId,
              CASE_API_PATHS.TEMPLATED_SECTIONS,
              data.pk,
              CASE_API_PATHS.TEMPLATED_SECTION_OVERRIDE,
            ])
          )
        )
      }
    )
  )

  getPlaintiffs = withResponseSerializer(
    CaseServiceDeserializer.plaintiffsFromJSON,
    ({ caseId }: CaseServiceOptions) => {
      return handleEmptyResponse(this.apiService.get(null, this.getPath([caseId, CASE_API_PATHS.PLAINTIFF])))
    }
  )

  getCaseAnalytics = withResponseSerializer(
    BaseServiceDeserializer.fromJSON,
    ({ caseId }: CaseServiceOptions) => {
      return handleEmptyResponse<CaseAnalyticsDto>(
        this.apiService.get(null, this.getPath([caseId, CASE_API_PATHS.ANALYTICS]))
      )
    }
  )
}

export const caseService = new CaseService(apiService)
