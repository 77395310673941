import { usePermissions } from "permissions/usePermissions"
import { CASE_STEP } from "demand/steps/enums"
import { ALL_CASE_STEPS } from "demand/steps/types"

type SkippedDResponse = {
  [key in ALL_CASE_STEPS]?: boolean
}

export function useSkipDemandSteps(): SkippedDResponse {
  const { introductionConclusionTemplatesEnabled, showPainAndSufferingTemplatesEnabled } = usePermissions()
  return {
    [CASE_STEP.INTRODUCTION]: introductionConclusionTemplatesEnabled,
    [CASE_STEP.CONCLUSION]: introductionConclusionTemplatesEnabled,
    [CASE_STEP.PAIN_AND_SUFFERING]: showPainAndSufferingTemplatesEnabled,
  }
}
