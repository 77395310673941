import React, { useRef } from "react"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Box from "@mui/material/Box"
import { Outlet } from "react-router-dom"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import { makeStyles } from "tss-react/mui"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { fetchLawFirmConfig } from "api"
import { queryKeys } from "react-query/constants"
import { STEP_STATUSES, SECTION_TO_EXPANDED_REQUESTS } from "demand/constants"
import { useFormContext } from "demand/context"
import { SavingIndicator } from "demand/SavingIndicator"
import { useDemandSteps } from "demand/steps"
import { CASE_STEP, DEMAND_SECTION } from "demand/steps/enums"
import { CASE_ATTRIBUTES_STEP, CASE_SECTIONS } from "demand/steps/types"

import { StyledTabs, StyledTab } from "requests/RequestForm/AdditionalInfoRevamp/styled"
import { caseService } from "api/services/case"
import MobileNavButton from "demand/LeftNav/MobileNavButton"
import { withSuspense } from "common/withSuspense"
import { Loading } from "common"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"

import { RequestPanelPageWrapper } from "requests/RequestPanelPageWrapper"
import { RequestViewDto } from "requests/ViewRequest/types"
import { Plaintiff } from "api/services/case/types"

const useStyles = makeStyles()(theme => ({
  actions: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    fontSize: "30px",
    lineHeight: "34px",
    fontWeight: "bold",
    letterSpacing: "-0.6px",
  },
  headerSubtitle: {
    fontSize: "16px",
    lineHeight: 1.75,
    letterSpacing: "-0.3px",
  },
  label: {
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: ".75rem",
  },
  notice: {
    margin: theme.spacing(4),
  },
}))

const STANDALONE_SECTIONS: CASE_SECTIONS[] = [DEMAND_SECTION.MISSING_DOCUMENTS]

const SkippedSection = () => {
  const { classes } = useStyles()
  return (
    <Alert severity="info" className={classes.notice}>
      <AlertTitle>Section skipped</AlertTitle>
      This section will not be included in the demand —{" "}
      <strong>Please toggle the button above to update this section.</strong>
    </Alert>
  )
}

const AUTOSAVED_SECTIONS: CASE_SECTIONS[] = [
  CASE_STEP.PROVIDERS,
  CASE_STEP.FUTURE_EXPENSES,
  DEMAND_SECTION.EXHIBITS,
]

interface FormProps {
  currentSection: CASE_SECTIONS
  handleSave: () => void
  handleNext: () => void
  onPlaintiffChange: (plaintiff: Plaintiff) => void
  request?: RequestViewDto | null
}

const Form = withSuspense(
  function Form({ currentSection, handleSave, handleNext, onPlaintiffChange, request = null }: FormProps) {
    const { handleUpdateStepStatus, caseId, currentPlaintiff } = useFormContext()
    const { section, currentStep } = useDemandSteps()
    const isSkipped = currentStep?.isSkipped ?? false
    const { classes } = useStyles()
    const saveRef = useRef<() => Promise<void>>(null)
    const { data: caseFirm } = useQuery(
      [queryKeys.lawFirmConfig, caseId],
      async () => await fetchLawFirmConfig(caseId)
    )
    const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)
    const expandedRequestGroups = SECTION_TO_EXPANDED_REQUESTS[section] ?? []

    // we need to call each forms' save function
    const onHandleSave = async () => {
      try {
        await saveRef.current?.()
        handleSave()
      } catch (error) {
        // do nothing, mutation handlers should deal with displaying errors to the user
      }
    }

    const onHandleNext = async () => {
      if (!isSkipped) {
        try {
          if (!AUTOSAVED_SECTIONS.includes(section)) {
            await saveRef.current?.()
          }
          handleNext()
        } catch (error) {
          // do nothing, mutation handlers should deal with displaying errors to the user
        }
      } else {
        handleNext()
      }
    }

    const onHandleSkip: React.ChangeEventHandler<HTMLInputElement> = async event => {
      if (event.target.checked) {
        handleUpdateStepStatus({ status: STEP_STATUSES.skipped })
        handleNext()
      } else {
        handleUpdateStepStatus({ status: STEP_STATUSES.notStarted })
      }
    }

    const lawFirmIsNotSelected = currentSection === CASE_ATTRIBUTES_STEP && caseFirm && !caseFirm.firm

    const queryClient = useQueryClient()
    const { data: plaintiffs } = useQuery(
      [queryKeys.plaintiffs, caseId],
      () => {
        return caseService.getPlaintiffs({ caseId })
      },
      {
        onSuccess: data => {
          if (!data.length) return

          onPlaintiffChange(data[0])
          queryClient.setQueryData([queryKeys.plaintiffs, caseId], data)
        },
        enabled: multiPlaintiffEnabled,
      }
    )

    const handlePlaintiffTabChange = (_: React.SyntheticEvent, newPlaintiffId: number) => {
      const newPlaintiff = plaintiffs?.find(plaintiff => plaintiff.id === newPlaintiffId)
      if (newPlaintiff) {
        onPlaintiffChange(newPlaintiff)
      }
    }

    const showPlaintiffTabs =
      multiPlaintiffEnabled &&
      !["facts", "carrier", "exhibits", "case_attributes", "introduction", "conclusion"].includes(
        currentSection
      ) &&
      currentPlaintiff

    return (
      <DndProvider backend={HTML5Backend}>
        <RequestPanelPageWrapper
          caseId={caseId.toString()}
          request={request}
          expandedSections={expandedRequestGroups?.expand ?? []}
          highlightedFields={expandedRequestGroups?.highlight ?? []}
          wrapInDragProvider={false}
        >
          {showPlaintiffTabs && (
            <Box mb={2}>
              <StyledTabs value={currentPlaintiff?.id} onChange={handlePlaintiffTabChange}>
                {plaintiffs?.map((plaintiff, index) => {
                  return (
                    <StyledTab
                      key={`tab-${index}`}
                      label={`${plaintiff.firstName} ${plaintiff.lastName}`}
                      value={plaintiff.id}
                    />
                  )
                })}
              </StyledTabs>
            </Box>
          )}
          <Box className={classes.header}>
            <Box display="flex" alignItems="center">
              <MobileNavButton />
              <Box>
                <Typography variant="h4" component="h1" className={classes.headerTitle}>
                  {currentStep?.title}
                </Typography>
                {currentStep?.subtitle ? (
                  <Typography className={classes.headerSubtitle}>{currentStep.subtitle}</Typography>
                ) : null}
              </Box>
            </Box>
            {currentStep?.optional && (
              <FormControlLabel
                classes={{ label: classes.label }}
                control={
                  <Switch color="secondary" checked={isSkipped} onChange={onHandleSkip} name="skip-section" />
                }
                label="Skip section"
              />
            )}
          </Box>
          <>
            {isSkipped ? <SkippedSection /> : <Outlet context={{ saveRef, handleSave, currentPlaintiff }} />}
          </>
          {!STANDALONE_SECTIONS.includes(currentSection) && (
            <Box className={classes.actions}>
              <SavingIndicator />
              {!([CASE_STEP.PROVIDERS, DEMAND_SECTION.EXHIBITS] as CASE_SECTIONS[]).includes(
                currentSection
              ) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={onHandleSave}
                  disabled={isSkipped || lawFirmIsNotSelected}
                  data-test="save-button"
                >
                  Save
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={onHandleNext}
                data-test="next-button"
                disabled={lawFirmIsNotSelected}
              >
                Next
              </Button>
            </Box>
          )}
        </RequestPanelPageWrapper>
      </DndProvider>
    )
  },
  <Loading show label="loading.." />
)

export default Form
