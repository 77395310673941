import { SetState, UserExhibit } from "./types"

export interface CollapseSlice {
  collapseStates: Record<UserExhibit["id"], boolean>
}

export const collapseSlice: CollapseSlice = {
  collapseStates: {},
}

export const collapseActions = (set: SetState<CollapseSlice>) => {
  const initializeIfNotExists = (id: string) => {
    set(state => ({
      collapseStates: {
        ...state.collapseStates,
        [id]: state.collapseStates[id] ?? false,
      },
    }))
  }

  const setCollapseState = (id: string, collapse: boolean) => {
    set(state => ({
      collapseStates: {
        ...state.collapseStates,
        [id]: collapse,
      },
    }))
  }

  const setCollapseStateForAll = (collapse: boolean) => {
    set(state => {
      const collapseStates = Object.keys(state.collapseStates).reduce<CollapseSlice["collapseStates"]>(
        (acc, id) => {
          acc[id] = collapse
          return acc
        },
        {}
      )

      return {
        collapseStates,
      }
    })
  }

  return {
    initializeIfNotExists,
    setCollapseState,
    setCollapseStateForAll,
  }
}
