import { UserExhibit } from "exhibit-builder/store/types"
import { Combine } from "./Combine/Combine"
import { Delete } from "./Delete/Delete"
import { Download } from "./Download"
import { Duplicate } from "./Duplicate"
import { Extract } from "./Extract/Extract"
import { ACTION_TYPE } from "./constants"
import { memo } from "react"

export const ActionView = memo(function ActionView({
  type,
  id,
  onClose,
}: {
  type: ACTION_TYPE
  id: UserExhibit["id"]
  onClose: () => void
}) {
  switch (type) {
    case ACTION_TYPE.EXTRACT: {
      return <Extract id={id} onClose={onClose} />
    }
    case ACTION_TYPE.COMBINE: {
      return <Combine id={id} onClose={onClose} />
    }
    case ACTION_TYPE.DUPLICATE: {
      return <Duplicate id={id} onClose={onClose} />
    }
    case ACTION_TYPE.DELETE: {
      return <Delete id={id} onClose={onClose} />
    }
    case ACTION_TYPE.DOWNLOAD: {
      return <Download id={id} onClose={onClose} />
    }
    default: {
      return null
    }
  }
})
