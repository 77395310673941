import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/Close"
import styled from "@emotion/styled"
import { IconButton, Typography } from "@mui/material"
import { create } from "zustand"

import { StyledDialog } from "app/styled"
import { StyledPrimaryButton } from "common/buttons/styled"

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: theme.palette.grey[500],
}))

const StyledDialogWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})

const Container = styled("div")({
  marginTop: "1rem",
})

type State = {
  open: boolean
  setOpen: (value: boolean) => void
}

export const useAccountNotFoundStore = create<State>(set => ({
  open: false,
  setOpen: value => set(() => ({ open: value })),
}))

export const AccountNotFoundDialog = () => {
  const open = useAccountNotFoundStore(state => state.open)
  const setOpen = useAccountNotFoundStore(state => state.setOpen)

  const onClick = () => {
    window.open("mailto:support@evenuplaw.com", "_blank")
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xs">
      <StyledDialogWrapper>
        <Typography variant="h4" textAlign="center" mb={2} fontSize="1.1rem" lineHeight={1.4}>
          Account not found
        </Typography>

        <Typography variant="body1" textAlign="center" fontSize="0.9rem" lineHeight={1.3}>
          Your account hasn&apos;t been set up yet.
          <Container>
            Please contact your firm admin or{" "}
            <a href="mailto:support@evenuplaw.com" target="_blank" rel="noopener noreferrer">
              support@evenuplaw.com
            </a>{" "}
            for assistance.
          </Container>
        </Typography>

        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>

        <Box display="flex" mt={3}>
          <StyledPrimaryButton onClick={onClick} data-test="email-support-button">
            Email Support
          </StyledPrimaryButton>
        </Box>
      </StyledDialogWrapper>
    </StyledDialog>
  )
}
