import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Chip, Typography } from "@mui/material"
import { STATUS_TO_TEXT } from "demand/Files/constants"
import { AnnotationStatus } from "demand/Files/types"
import { Row } from "exhibit-builder/Exhibit/styled"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { ExhibitPartition } from "exhibit-builder/store/types"
import { useShallow } from "zustand/react/shallow"

const AnnotationTag = styled(Chip)<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
  backgroundColor,
  color: theme.palette.common.white,
  fontWeight: 500,
  fontSize: "14px",
  width: "fit-content",
  borderRadius: theme.spacing(1),
}))

export function ExhibitPartitionChildrenPlaceholder({
  partitionId,
}: {
  partitionId: ExhibitPartition["id"]
}) {
  const theme = useTheme()
  const exhibitPartition = useExhibitBuilderStore(useShallow(state => state.exhibitPartitionMap[partitionId]))
  const annotationStatus = useExhibitBuilderStore(
    useShallow(state => state.annotationStatus[exhibitPartition.fileId]?.status)
  )

  if (!annotationStatus) {
    return null
  }

  return (
    <Row data-test="exhibit-partition-placeholder">
      {annotationStatus === AnnotationStatus.COMPLETE ? (
        <Typography variant="body2">
          {exhibitPartition.startPage} - {exhibitPartition.endPage} contains no annotation
        </Typography>
      ) : (
        <AnnotationTag
          size="small"
          label={STATUS_TO_TEXT[annotationStatus]}
          backgroundColor={theme.palette.annotation[annotationStatus]}
        />
      )}
    </Row>
  )
}
