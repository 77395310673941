import { isUndefined } from "lodash"

import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { apiService } from "api/services/ApiService"
import { ApiServiceType } from "api/services/types"
import {
  ObjectiveTestSerializer,
  ObjectiveTestDeserializer,
  ObjectiveTestFindingSerializer,
  ObjectiveTestFindingDeserializer,
} from "./serializers"
import { ObjectiveTestDto, ObjectiveTestFindingDto } from "./types"

type ProviderServiceOptions = {
  caseId: BaseEntity["pk"]
  providerId: BaseEntity["pk"]
  objectiveTestId: BaseEntity<string>["pk"]
  findingId: BaseEntity<string>["pk"]
}

enum OBJECTIVE_TEST_API_PATHS {
  ROOT = "objective-test",
  FINDING = "finding",
  CASE = "case",
  PROVIDER = "provider",
}

export class ProviderObjectiveTestService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options: Partial<ProviderServiceOptions>, path?: string): string {
    const pathParts = [
      "",
      OBJECTIVE_TEST_API_PATHS.CASE,
      options.caseId,
      !isUndefined(options.providerId) ? OBJECTIVE_TEST_API_PATHS.PROVIDER : undefined,
      options.providerId,
      OBJECTIVE_TEST_API_PATHS.ROOT,
      options.objectiveTestId,
      !isUndefined(options.findingId) ? OBJECTIVE_TEST_API_PATHS.FINDING : undefined,
      options.findingId,
      path,
    ]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  createProviderObjectiveTest = withResponseSerializer(
    ObjectiveTestDeserializer.definitionFromJSON,
    async (options: Pick<ProviderServiceOptions, "caseId" | "providerId">) => {
      return await handleEmptyResponse(this.apiService.create(null, this.getPath(options)))
    }
  )

  updateProviderObjectiveTest = withRequestSerializer(
    ObjectiveTestSerializer.definitionToJSON,
    withResponseSerializer(
      ObjectiveTestDeserializer.definitionFromJSON,
      async ({
        data,
        options,
      }: {
        data: ObjectiveTestDto
        options: Pick<ProviderServiceOptions, "caseId" | "providerId" | "objectiveTestId">
      }) => {
        return await handleEmptyResponse(this.apiService.replace(data, this.getPath(options)))
      }
    )
  )

  deleteProviderObjectiveTest = (
    options: Pick<ProviderServiceOptions, "caseId" | "providerId" | "objectiveTestId">
  ) => this.apiService.delete(null, this.getPath(options))

  createProviderObjectiveTestFinding = withResponseSerializer(
    ObjectiveTestFindingDeserializer.definitionFromJSON,
    async (options: Pick<ProviderServiceOptions, "objectiveTestId">) => {
      return await handleEmptyResponse(
        this.apiService.create(null, this.getPath(options, OBJECTIVE_TEST_API_PATHS.FINDING))
      )
    }
  )

  updateProviderObjectiveTestFinding = withRequestSerializer(
    ObjectiveTestFindingSerializer.definitionToJSON,
    withResponseSerializer(
      ObjectiveTestFindingDeserializer.definitionFromJSON,
      async ({
        data,
        options,
      }: {
        data: ObjectiveTestFindingDto
        options: Pick<ProviderServiceOptions, "objectiveTestId" | "findingId">
      }) => {
        return await handleEmptyResponse(this.apiService.replace(data, this.getPath(options)))
      }
    )
  )
}

export const providerObjectiveTestService = new ProviderObjectiveTestService(apiService)
