import { Path } from "slate"
import { Editor, Point } from "slate"

export interface CursorPositionInNode {
  isStart: boolean
  isEnd: boolean
}

export function getCursorPositionInNode(
  editor: Editor,
  cursorLocation: Point,
  nodePath: Path
): CursorPositionInNode {
  const nodeStartPoint = Editor.start(editor, nodePath)
  const nodeEndPoint = Editor.end(editor, nodePath)
  const isStart = Point.equals(cursorLocation, nodeStartPoint)
  const isEnd = Point.equals(cursorLocation, nodeEndPoint)

  return { isEnd, isStart }
}
