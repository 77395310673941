import {
  FieldDto,
  Field,
  GeneratorDto,
  GeneratorEntity,
  Generator,
  GeneratorFieldDto,
  GeneratorField,
  SummaryDto,
  Summary,
  SummarizeActionDto,
  SummarizeAction,
  Result,
  Output,
  OutputResponse,
  OutputResponseDto,
  SummaryRequestDto,
  SummaryRequest,
} from "./types"

export class ProviderSummariesDeserializer {
  static fromJSON(data: { results: GeneratorEntity[] }): Array<{ key: string; display: string }> {
    return (
      data.results?.map(generator => {
        return { key: generator.key, display: generator.name }
      }) ?? []
    )
  }
}

// TODO: Use `toCamelCase`
export const deserializeSummary = (summary: SummaryDto): Summary => {
  return {
    pk: summary.pk,
    result: deserializeResult(summary.result),
    outputs: deserializeOutput(summary.outputs),
    fields: deserializeFields(summary.fields),
    generatorKey: summary.generator_key,
    header: summary.header,
  }
}

const deserializeResult = (result: Result): Result => {
  return result
}

const deserializeOutput = (output: Output[]): Output[] => {
  return output
}

const deserializeFields = (fields: FieldDto[]): Field[] => {
  return fields.map((field: FieldDto) => {
    return {
      field: field.field,
      value: field.value,
      fieldType: field.field_type,
    }
  })
}

const serializeFields = (fields: Field[]): FieldDto[] => {
  return fields.map((field: Field) => {
    return {
      field: field.field,
      value: field.value,
      field_type: field.fieldType,
    }
  })
}

export class SummaryOutputDeserializer {
  static fromJSON(data: SummaryDto): Summary {
    return deserializeSummary(data)
  }
}

export class SummariesDeserializer {
  static fromJSON(data: SummaryDto): Summary {
    return deserializeSummary(data)
  }
}

export class SummariesSerializer {
  static newToJSON() {
    return {}
  }
}

const deserializeGenerator = (generator: GeneratorDto): Generator => {
  return {
    generatorKey: generator.generator_key,
    displayName: generator.display_name,
    generatorType: generator.generator_type,
    summaryGroup: generator.summary_group,
    helpText: generator.help_text,
    inputs: InputDeserializer.fromJSON(generator.inputs),
  }
}

export class GeneratorDeserializer {
  static fromJSON(data: { results: GeneratorDto[] }): Generator[] {
    return data.results.map((generator: GeneratorDto) => {
      return deserializeGenerator(generator)
    })
  }
}

export class InputDeserializer {
  static fromJSON(data: GeneratorFieldDto[]): GeneratorField[] {
    return data.map((field: GeneratorFieldDto) => {
      return {
        fieldId: field.field_id,
        fieldName: field.field_name,
        fieldType: field.field_type,
        defaultValue: field.default_value,
        choices: field.choices,
        value: field.value,
        required: field.required ?? true,
      }
    })
  }
}

const deserializeSummarizeAction = (summarizeAction: SummarizeActionDto): SummarizeAction => {
  return {
    generatorKey: summarizeAction.generator_key,
    inputs: deserializeFields(summarizeAction.inputs),
  }
}

const serializeSummarizeAction = (summarizeAction: SummarizeAction): SummarizeActionDto => {
  return {
    generator_key: summarizeAction.generatorKey,
    inputs: serializeFields(summarizeAction.inputs),
  }
}

export class SummaryActionSerializer {
  static fromJSON(data: SummarizeActionDto): SummarizeAction {
    return deserializeSummarizeAction(data)
  }

  static toJSON(data: SummarizeAction): SummarizeActionDto {
    return serializeSummarizeAction(data)
  }

  static newToJSON() {
    return {}
  }
}

export class SummaryOutputSerializer {
  static toJSON(data: Output): Output {
    return data
  }
}

export class SummaryOutputResponseDeserializer {
  static fromJSON(data: OutputResponseDto): OutputResponse {
    return {
      ...data,
      createdAt: data.created_at,
      editedBy: data.edited_by,
    }
  }
}

export class SummaryRequestDeserializer {
  static definitionFromJSON(summaryRequest: SummaryRequestDto): SummaryRequest {
    return {
      pk: summaryRequest.pk,
      firm: summaryRequest.firm,
      name: summaryRequest.name,
      requester: summaryRequest.requester,
      summaries: summaryRequest.summaries.map(SummariesDeserializer.fromJSON),
      matterId: summaryRequest?.matter_id,
    }
  }

  static fromListJSON(summaryRequests: SummaryRequestDto[]): SummaryRequest[] {
    return summaryRequests.map(SummaryRequestDeserializer.definitionFromJSON)
  }
}

interface OrderSummaryPayloadDto {
  new_index: number
}

interface OrderSummaryPayload {
  newIndex: number
}

export class OrderSummarySerializer {
  static toJson(data: OrderSummaryPayload): OrderSummaryPayloadDto {
    return {
      new_index: data.newIndex,
    }
  }
}

export class OrderSummaryDeserializer {
  static fromJson(data: OrderSummaryPayloadDto): OrderSummaryPayload {
    return {
      newIndex: data.new_index,
    }
  }
}
