import React, { ComponentProps, useCallback, useMemo, useRef, useState } from "react"
import { Virtuoso, VirtuosoHandle } from "react-virtuoso"

const VirtualListContext = React.createContext({})
const VirtualListScroller = React.forwardRef<
  HTMLDivElement,
  Pick<ComponentProps<"div">, "style" | "children">
>(function VirtualList({ children, ...props }, ref) {
  const listProps = React.useContext(VirtualListContext)

  return (
    <div ref={ref} {...props} role="listbox" {...listProps}>
      {children}
    </div>
  )
})

export const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent({ children, ...other }, ref) {
    const itemData: React.ReactElement[] = useMemo(
      () =>
        (children as React.ReactElement[]).flatMap(
          (item: React.ReactElement & { children?: React.ReactElement[] }) => [item, ...(item.children || [])]
        ),
      [children]
    )

    const virtuoso = useRef<VirtuosoHandle>(null)
    const [currentItemIndex, setCurrentItemIndex] = useState(-1)
    const listRef = useRef<Element | null>(null)
    const totalCount = itemData.length

    const keyDownCallback = useCallback(
      (e: KeyboardEvent) => {
        let nextIndex = null

        if (e.code === "ArrowUp") {
          nextIndex = Math.max(0, currentItemIndex - 1)
        } else if (e.code === "ArrowDown") {
          nextIndex = Math.min(totalCount, currentItemIndex + 1)
        }

        if (nextIndex !== null) {
          virtuoso.current?.scrollIntoView({
            index: nextIndex,
            behavior: "auto",
            done: () => {
              setCurrentItemIndex(nextIndex)
            },
          })
          e.preventDefault()
        }
      },
      [currentItemIndex, setCurrentItemIndex, totalCount]
    )

    const scrollerRef = React.useCallback(
      (element: HTMLElement | Window | null) => {
        if (element) {
          document.activeElement?.addEventListener("keydown", keyDownCallback as EventListener)
          listRef.current = document.activeElement
        } else {
          listRef.current?.removeEventListener("keydown", keyDownCallback as EventListener)
        }
      },
      [keyDownCallback]
    )

    return (
      <div ref={ref}>
        <VirtualListContext.Provider value={other}>
          <Virtuoso
            ref={virtuoso}
            scrollerRef={scrollerRef}
            style={{ height: "40vh" }}
            totalCount={totalCount}
            itemContent={index => itemData[index]}
            increaseViewportBy={200}
            components={{
              Scroller: VirtualListScroller,
            }}
          />
        </VirtualListContext.Provider>
      </div>
    )
  }
)
