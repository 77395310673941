import { Path, Node, NodeEntry } from "slate"
import { CustomEditor } from "../CustomEditor"

export function getPreviousSibling(editor: CustomEditor, path: Path): Nullable<NodeEntry> {
  let previousSiblingPath: Path

  try {
    previousSiblingPath = Path.previous(path)
  } catch {
    return null
  }

  if (Node.has(editor, previousSiblingPath)) {
    return [Node.get(editor, previousSiblingPath), previousSiblingPath]
  }

  return null
}

export function getNextSibling(editor: CustomEditor, path: Path): Nullable<NodeEntry> {
  let nextSiblingPath: Path

  try {
    nextSiblingPath = Path.next(path)
  } catch {
    return null
  }

  if (Node.has(editor, nextSiblingPath)) {
    return [Node.get(editor, nextSiblingPath), nextSiblingPath]
  }

  return null
}
