import { useMemo } from "react"
import { TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { documentStoreSelectors, useDocumentStore } from "documents/store"
import { isEmpty } from "lodash"
import { StyledTableHeadCell, StyledViewTable } from "documents/styled"
import { PastMedicalVisitReference } from "./PastMedicalVisitReference"

export const PastMedicalVisits = (): JSX.Element => {
  const pastMedicalVisits = useDocumentStore(documentStoreSelectors.filteredPastMedicalVisits)
  const totalVisits = useMemo(
    () => pastMedicalVisits.reduce((acc, cur) => acc + cur.visits, 0),
    [pastMedicalVisits]
  )

  return (
    <StyledViewTable>
      <TableHead>
        <TableRow>
          <StyledTableHeadCell width="30%">Provider</StyledTableHeadCell>
          <StyledTableHeadCell width="30%">Date of Service</StyledTableHeadCell>
          <StyledTableHeadCell width="25%">Visits</StyledTableHeadCell>
          <StyledTableHeadCell width="15%">Reference</StyledTableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(pastMedicalVisits) && (
          <TableRow>
            <TableCell align="center" colSpan={4}>
              <b>There are no past medical visits for the selected filters</b>
            </TableCell>
          </TableRow>
        )}
        {pastMedicalVisits.map(pastMedicalVisit => {
          return (
            <TableRow key={pastMedicalVisit.id}>
              <TableCell>{pastMedicalVisit.providerName}</TableCell>
              <TableCell>{pastMedicalVisit.dateOfService}</TableCell>
              <TableCell>{pastMedicalVisit.visits}</TableCell>
              <TableCell>
                {pastMedicalVisit.reference && (
                  <PastMedicalVisitReference relation={pastMedicalVisit.reference} />
                )}
              </TableCell>
            </TableRow>
          )
        })}
        <TableRow>
          <TableCell colSpan={2} padding="normal">
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#636C79",
              }}
            >
              Total
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#636C79",
              }}
            >
              {totalVisits}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </StyledViewTable>
  )
}
