import React, {
  ClipboardEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import { createEditor, Descendant } from "slate"
import { withHistory } from "slate-history"
import { withReact, Slate, Editable, RenderLeafProps, RenderElementProps } from "slate-react"
import styled from "@emotion/styled"
import { CopyTag } from "common/helpers/copy/constants"
import { DEFAULT_VALUE } from "./defaultValue"
import { Editor } from "./Editor"
import { RichTextToolbar } from "./render/toolbar"
import { CustomEditor, EditorRoot } from "./CustomEditor"
import { isInList } from "../rich-text/commands/lists/queries"
import { deserializeFromHtml, deserializeFromPlainText } from "./serializer/html/deserializer"
import { handleKeyDown } from "./utils"
import { EditorFeatureProps, EditorFeatureRendererProps } from "./features/types"
import { getFeaturesRenderer } from "./features/renderers"
import { getFeaturesWrapper } from "./features/wrappers"
import { SLATE_EDITOR_DATA_TYPE_ATTRIBUTE } from "./constants"
import { HEADER_NAV_HEIGHT_SPACE_MULTIPLIER } from "app/constants"
import { useRichTextPerformance } from "utils/perfomanceAnalytic"

const StyledEditor = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),

  "& [data-slate-editor='true']": {
    outline: "none",
  },

  "& p": {
    "&:first-of-type": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

const StyledToolbarWrapper = styled("div")<{ sticky?: boolean }>(({ theme, sticky }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  ...(sticky
    ? {
        position: "sticky",
        top: theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER),
        background: theme.palette.common.white,
        zIndex: 1,
      }
    : {}),
}))

const EditorWrapper: React.FC<PropsWithChildren<{ error?: boolean; className?: string }>> = ({
  className,
  children,
}) => {
  return (
    <div data-type={SLATE_EDITOR_DATA_TYPE_ATTRIBUTE} className={className}>
      {children}
    </div>
  )
}

const StyledWrapper = styled(EditorWrapper)<{ error?: boolean }>(({ theme, error }) => ({
  border: `1px solid ${error ? theme.palette.error.main : theme.palette.grey["400"]}`,
  borderRadius: theme.spacing(0.5),
}))

export interface RichTextEditableProps {
  value?: Nullable<EditorRoot>
  onChange: (nextValue: EditorRoot) => void
  onBlur?: React.FocusEventHandler
  onFocus?: React.FocusEventHandler
  renderElement: (props: RenderElementProps) => JSX.Element
  renderLeaf: (props: RenderLeafProps) => JSX.Element
  keepValue: boolean
  dataTest?: string
  name?: string
  stickyToolbar?: boolean
  customControls?: React.ReactNode
  error?: boolean
}

export const RichTextEditorEditable = React.forwardRef<
  CustomEditor,
  RichTextEditableProps & EditorFeatureProps
>(function RichTextEditorEditable(
  {
    value = DEFAULT_VALUE,
    onChange,
    onBlur,
    onFocus,
    renderElement,
    renderLeaf,
    keepValue = false,
    dataTest,
    stickyToolbar,
    error,
    customControls,
    ...featureProps
  },
  ref
): JSX.Element {
  const { track } = useRichTextPerformance()
  const editorValue = useMemo<EditorRoot>(() => {
    if (!value || value.length === 0) return DEFAULT_VALUE
    return value
  }, [value])
  const featureWrapper = useRef(getFeaturesWrapper(featureProps))
  const [editor] = useState<CustomEditor>(() =>
    withHistory(featureWrapper.current(withReact(createEditor())))
  )
  const featuresRenderer = useRef(getFeaturesRenderer(featureProps))
  const rendererProps = { ...featureProps, editor } as EditorFeatureRendererProps

  useImperativeHandle(ref, () => editor, [editor])

  useEffect(() => {
    if (!keepValue) return

    if (editor.children !== editorValue) {
      Editor.resetEditableState(editor, editorValue)
    }
  }, [keepValue, editor, editorValue])

  useEffect(() => {
    Editor.normalize(editor, { force: true })
  }, [editor])

  const handleOnChange = useCallback(
    (nextEditorValue: Descendant[]) => {
      if (editor.isSideEffectRunning) {
        return
      }

      if (nextEditorValue !== editor.children) {
        return
      }

      if (nextEditorValue !== editorValue && onChange) {
        onChange(nextEditorValue as EditorRoot)
      }
    },
    [editor, onChange, editorValue]
  )

  const handlePaste: ClipboardEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (event.clipboardData.types.length === 1 && event.clipboardData.types.includes("text/plain")) {
        event.preventDefault()

        const nodes = deserializeFromPlainText(event.clipboardData)

        Editor.pasteFragment(editor, nodes)

        return
      }

      const cursorInList =
        isInList(editor, editor.selection?.anchor.path) || isInList(editor, editor.selection?.focus.path)

      if (event.clipboardData.types.includes("application/x-slate-fragment") && !cursorInList) return false

      if (event.clipboardData.types.includes("text/html")) {
        event.preventDefault()

        const nodes = deserializeFromHtml(event.clipboardData)

        Editor.pasteFragment(editor, nodes)

        return false
      }
    },
    [editor]
  )

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<Element>) => {
      track()
      handleKeyDown(editor)(e)
    },
    [editor, track]
  )

  return (
    <StyledWrapper error={error}>
      <Slate editor={editor} initialValue={editorValue} onChange={handleOnChange}>
        <StyledToolbarWrapper sticky={stickyToolbar}>
          <RichTextToolbar customControls={customControls} />
        </StyledToolbarWrapper>
        <StyledEditor>
          {featuresRenderer.current({
            ...rendererProps,
            children: (
              <div data-copytag={CopyTag.SLATE_EDITOR}>
                <Editable
                  data-test={dataTest}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  onPaste={handlePaste}
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  spellCheck
                  onKeyDown={onKeyDown}
                />
              </div>
            ),
          })}
        </StyledEditor>
      </Slate>
    </StyledWrapper>
  )
})
