import { Appointment, Provider } from "documents/types"

export const MedicronCalendarTestAppointments: Record<
  string,
  Omit<Appointment, "icdCodes" | "summary" | "generatedSummary" | "generatedSummaryStatus">
> = {
  "bae65d81-7301-46e7-8996-cf8ff4262bcd": {
    partitions: [],
    id: "bae65d81-7301-46e7-8996-cf8ff4262bcd",
    dateOfService: "2021-12-21",
    providerId: "eaf14f39-dd8c-4628-a542-92c1012255e2",
    timeOfService: null,
    tags: [],
  },
  "5d328783-5e2d-427b-a431-4c6c15d8e19f": {
    partitions: [],
    id: "5d328783-5e2d-427b-a431-4c6c15d8e19f",
    dateOfService: "2023-05-05",
    providerId: "0590fb64-45e6-4d89-87e8-989fe68c5d52",
    timeOfService: null,
    tags: [],
  },
  "755c6366-86e1-4178-807d-5c1c23350b46": {
    partitions: [],
    id: "755c6366-86e1-4178-807d-5c1c23350b46",
    dateOfService: "2023-10-26",
    providerId: "c12db1c3-a8c6-4449-ac54-41ac9ae45f45",
    timeOfService: null,
    tags: [],
  },
  "5ba1b970-11c7-45cb-b51f-43a734b46fa5": {
    partitions: [],
    id: "5ba1b970-11c7-45cb-b51f-43a734b46fa5",
    dateOfService: "2023-10-23",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "521393d5-d9d4-492c-bb0b-bbc6ab8d095d": {
    partitions: [],
    id: "521393d5-d9d4-492c-bb0b-bbc6ab8d095d",
    dateOfService: "2023-09-25",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "67c77f3d-dde0-4c26-8d90-d4eee6d1c474": {
    partitions: [],
    id: "67c77f3d-dde0-4c26-8d90-d4eee6d1c474",
    dateOfService: "2023-10-18",
    providerId: "c12db1c3-a8c6-4449-ac54-41ac9ae45f45",
    timeOfService: null,
    tags: [],
  },
  "591b3dc3-2feb-4b09-96b1-62c59ce74940": {
    partitions: [],
    id: "591b3dc3-2feb-4b09-96b1-62c59ce74940",
    dateOfService: "2023-10-19",
    providerId: "c12db1c3-a8c6-4449-ac54-41ac9ae45f45",
    timeOfService: null,
    tags: [],
  },
  "7c3c78f4-e432-457a-bb19-1b5d34948dce": {
    partitions: [],
    id: "7c3c78f4-e432-457a-bb19-1b5d34948dce",
    dateOfService: "2023-10-10",
    providerId: "33f1db79-f02a-4ca3-91b4-3cd0d74ae0e3",
    timeOfService: null,
    tags: [],
  },
  "2fdb7d34-4b97-49f0-8073-b22b81c5023b": {
    partitions: [],
    id: "2fdb7d34-4b97-49f0-8073-b22b81c5023b",
    dateOfService: "2023-10-16",
    providerId: "a54fab7e-44c7-4a03-94f4-67b8f4cc003c",
    timeOfService: null,
    tags: [],
  },
  "ea2ce644-7b87-461a-88cf-f3e04d207dd3": {
    partitions: [],
    id: "ea2ce644-7b87-461a-88cf-f3e04d207dd3",
    dateOfService: "2023-10-18",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "62f99de8-6582-48e7-b34c-40a07dce1b4b": {
    partitions: [],
    id: "62f99de8-6582-48e7-b34c-40a07dce1b4b",
    dateOfService: "2023-10-19",
    providerId: "33f1db79-f02a-4ca3-91b4-3cd0d74ae0e3",
    timeOfService: null,
    tags: [],
  },
  "fc8da540-18f7-435b-9f1d-2da8a5c55884": {
    partitions: [],
    id: "fc8da540-18f7-435b-9f1d-2da8a5c55884",
    dateOfService: "2023-10-10",
    providerId: "c12db1c3-a8c6-4449-ac54-41ac9ae45f45",
    timeOfService: null,
    tags: [],
  },
  "20c726a1-2afd-46f0-848c-ec7c05aa0b9c": {
    partitions: [],
    id: "20c726a1-2afd-46f0-848c-ec7c05aa0b9c",
    dateOfService: "2023-10-16",
    providerId: "c12db1c3-a8c6-4449-ac54-41ac9ae45f45",
    timeOfService: null,
    tags: [],
  },
  "dabb814d-3d7f-4a1e-bfd7-26f0040aacd2": {
    partitions: [],
    id: "dabb814d-3d7f-4a1e-bfd7-26f0040aacd2",
    dateOfService: "2023-09-05",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "0f4f294e-38b7-41c7-b1e4-746d0afdf7f2": {
    partitions: [],
    id: "0f4f294e-38b7-41c7-b1e4-746d0afdf7f2",
    dateOfService: "2023-09-06",
    providerId: "a54fab7e-44c7-4a03-94f4-67b8f4cc003c",
    timeOfService: null,
    tags: [],
  },
  "955e2be0-a086-4c48-88f0-46852cac5f53": {
    partitions: [],
    id: "955e2be0-a086-4c48-88f0-46852cac5f53",
    dateOfService: "2023-09-25",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "787fd862-f6c8-43b7-bd07-8a0b890a445f": {
    partitions: [],
    id: "787fd862-f6c8-43b7-bd07-8a0b890a445f",
    dateOfService: "2023-09-21",
    providerId: "33f1db79-f02a-4ca3-91b4-3cd0d74ae0e3",
    timeOfService: null,
    tags: [],
  },
  "b1943be6-c87f-4592-a93c-5e9631efab97": {
    partitions: [],
    id: "b1943be6-c87f-4592-a93c-5e9631efab97",
    dateOfService: "2023-09-25",
    providerId: "33f1db79-f02a-4ca3-91b4-3cd0d74ae0e3",
    timeOfService: null,
    tags: [],
  },
  "2b9759c2-b32b-40cb-8dcc-274cf05f5dc5": {
    partitions: [],
    id: "2b9759c2-b32b-40cb-8dcc-274cf05f5dc5",
    dateOfService: "2023-10-23",
    providerId: "a54fab7e-44c7-4a03-94f4-67b8f4cc003c",
    timeOfService: null,
    tags: [],
  },
  "100b56c0-1e02-492b-83a2-792d964fee3e": {
    partitions: [],
    id: "100b56c0-1e02-492b-83a2-792d964fee3e",
    dateOfService: "2023-09-25",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "e839532a-9404-408e-8227-698e0582a407": {
    partitions: [],
    id: "e839532a-9404-408e-8227-698e0582a407",
    dateOfService: "2023-09-21",
    providerId: "33f1db79-f02a-4ca3-91b4-3cd0d74ae0e3",
    timeOfService: null,
    tags: [],
  },
  "5fbc0657-6e8d-4c21-be52-b028d085cd1e": {
    partitions: [],
    id: "5fbc0657-6e8d-4c21-be52-b028d085cd1e",
    dateOfService: "2023-09-08",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "630c954c-6fb9-44fc-8c5c-215e33e954d4": {
    partitions: [],
    id: "630c954c-6fb9-44fc-8c5c-215e33e954d4",
    dateOfService: "2023-09-08",
    providerId: "a54fab7e-44c7-4a03-94f4-67b8f4cc003c",
    timeOfService: null,
    tags: [],
  },
  "57a45692-30ec-4bd2-8733-5b3fe1b7a524": {
    partitions: [],
    id: "57a45692-30ec-4bd2-8733-5b3fe1b7a524",
    dateOfService: "2023-11-06",
    providerId: "a54fab7e-44c7-4a03-94f4-67b8f4cc003c",
    timeOfService: null,
    tags: [],
  },
  "2e6b84a4-0599-4bc5-9076-0a1b24663c44": {
    partitions: [],
    id: "2e6b84a4-0599-4bc5-9076-0a1b24663c44",
    dateOfService: "2023-11-06",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "330d7535-d282-4805-b318-f2c67d7fb018": {
    partitions: [],
    id: "330d7535-d282-4805-b318-f2c67d7fb018",
    dateOfService: "2023-09-25",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "e42eadf7-6b61-4fcc-a87d-7ee2083e7da1": {
    partitions: [],
    id: "e42eadf7-6b61-4fcc-a87d-7ee2083e7da1",
    dateOfService: "2023-09-21",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "82f5b214-8eb6-40bb-bcd6-4c095a9c440f": {
    partitions: [],
    id: "82f5b214-8eb6-40bb-bcd6-4c095a9c440f",
    dateOfService: "2023-12-06",
    providerId: "11377597-c62d-4667-96e3-e2165e39b382",
    timeOfService: null,
    tags: [],
  },
  "e42eadf7-6b61-4fcc-a87d-7ee2v83e7da1": {
    partitions: [],
    id: "e42eadf7-6b61-4fcc-a87d-7ee2v83e7da1",
    dateOfService: "2024-01-21",
    providerId: "33f1db79-f02a-4ca3-91b4-3cd0d74ae0e3",
    timeOfService: null,
    tags: [],
  },
  "e42eadf7-6b61-4fcc-a87d-7ee2v83e7da2": {
    partitions: [],
    id: "e42eadf7-6b61-4fcc-a87d-7ee2v83e7da1",
    dateOfService: "2024-08-21",
    providerId: "33f1db79-f02a-4ca3-91b4-3cd0d74ae0e3",
    timeOfService: null,
    tags: [],
  },
  "e42eadf7-6b61-4fcc-a87d-7ee2v83e7da3": {
    partitions: [],
    id: "e42eadf7-6b61-4fcc-a87d-7ee2v83e7da1",
    dateOfService: "2025-01-21",
    providerId: "33f1db79-f02a-4ca3-91b4-3cd0d74ae0e3",
    timeOfService: null,
    tags: [],
  },
}

export const MedicronCalendarTestProviders: Record<string, Provider> = {
  "a54fab7e-44c7-4a03-94f4-67b8f4cc003c": {
    id: "a54fab7e-44c7-4a03-94f4-67b8f4cc003c",
    name: "Sunny Imagers",
    color: "#FFEB3B",
  },
  "bd9cc029-74d3-46f9-8794-3220a6c4e7f0": {
    id: "bd9cc029-74d3-46f9-8794-3220a6c4e7f0",
    name: "Jesse County EMS",
    color: "#4CAE4F",
  },
  "33f1db79-f02a-4ca3-91b4-3cd0d74ae0e3": {
    id: "33f1db79-f02a-4ca3-91b4-3cd0d74ae0e3",
    name: "James County EMS",
    color: "#2196F3",
  },
  "60ca6bd6-715d-432d-b6a6-9d74cc3e6a5a": {
    id: "60ca6bd6-715d-432d-b6a6-9d74cc3e6a5a",
    name: "Quantum Health",
    color: "#AC5EBA",
  },
  "c12db1c3-a8c6-4449-ac54-41ac9ae45f45": {
    id: "c12db1c3-a8c6-4449-ac54-41ac9ae45f45",
    name: "Carl's Chiropractic",
    color: "#E91E63",
  },
  "8e951e0d-4b58-4b8d-90ed-415df8e8c10c": {
    id: "8e951e0d-4b58-4b8d-90ed-415df8e8c10c",
    name: "A potential really long provider name that should stretch to two lines",
    color: "#FF9800",
  },
  "a464e887-1e43-45d7-82bb-07d3fbc4c2c2": {
    id: "a464e887-1e43-45d7-82bb-07d3fbc4c2c2",
    name: "Northwestern Medical Solutions",
    color: "#CDDC39",
  },
  "5631f3f7-6caa-4087-8cd4-a32e43f1f828": {
    id: "5631f3f7-6caa-4087-8cd4-a32e43f1f828",
    name: "The Only Medical Group",
    color: "#78D9A2",
  },
  "0590fb64-45e6-4d89-87e8-989fe68c5d52": {
    id: "0590fb64-45e6-4d89-87e8-989fe68c5d52",
    name: "Prisma Health LA",
    color: "#F899FF",
  },
  "eaf14f39-dd8c-4628-a542-92c1012255e2": {
    id: "eaf14f39-dd8c-4628-a542-92c1012255e2",
    name: "Prisma Health LA Hospital",
    color: "#FFC105",
  },
  "11377597-c62d-4667-96e3-e2165e39b382": {
    id: "11377597-c62d-4667-96e3-e2165e39b382",
    name: "Telus Air Force Base Medical Clinic",
    color: "#F57851",
  },
}
