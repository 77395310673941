export function getTextDiff(original: string, suggested: string): [string, string, string, string] {
  if (original === suggested) return [original, "", "", ""]

  let i = 0
  let commonPartStart = ""
  let commonPartEnd = ""

  for (i = 0; i < original.length; i++) {
    if (original[i] !== suggested[i]) break
    commonPartStart += original[i]
  }

  for (i = 1; i <= original.length - commonPartStart.length; i++) {
    if (original[original.length - i] !== suggested[suggested.length - i]) break
    commonPartEnd = original[original.length - i] + commonPartEnd
  }

  const removedPart = original.slice(commonPartStart.length, -commonPartEnd.length || undefined)
  const addedPart = suggested.slice(commonPartStart.length, -commonPartEnd.length || undefined)

  return [commonPartStart, removedPart, addedPart, commonPartEnd]
}
