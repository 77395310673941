import { PLAINTIFF_STRING_ARRAY_VALUE_KEYS } from "requests/constants"
import { PlaintiffProps } from "./AdditionalInfoRevamp"
import { toParseableString } from "./AdditionalInfoRevamp/utils"
import { useParams } from "react-router-dom"
import invariant from "invariant"

export const formatPlaintiffsProperties = (plaintiffs: PlaintiffProps[]): PlaintiffProps[] => {
  function parseValueForKey<Key extends keyof PlaintiffProps>(
    plaintiff: PlaintiffProps,
    key: Key
  ): PlaintiffProps {
    return {
      ...plaintiff,
      [key]: JSON.parse(toParseableString(plaintiff[key] as string)),
    }
  }

  return plaintiffs.map(plaintiff => {
    let newPlaintiff = plaintiff
    PLAINTIFF_STRING_ARRAY_VALUE_KEYS.forEach(key => {
      newPlaintiff = parseValueForKey(newPlaintiff, key)
    })
    return newPlaintiff
  })
}

export function useGetIdFromParams(key: string): PrimaryKey | undefined {
  const param = useParams()
  const value = param[key]

  if (value === undefined) return undefined

  const id = parseInt(value)

  invariant(!isNaN(id), `Query parameter ${key} should be a valid primary key`)

  return id
}
