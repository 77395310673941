import { DROP_TARGET_TYPE } from "../constants"
import { ProjectionData, ProjectionItemData } from "../types"
import {
  removeFromParent,
  appendTo,
  appendBefore,
  appendAfter,
  ReorderOperation,
  ReorderOperationReturn,
} from "./reorder"

export function handleDrop<T>(
  data: ProjectionData<T>,
  itemId: string,
  targetId: string,
  dropType: DROP_TARGET_TYPE
): [ProjectionData<T>, Nullable<ReorderOperation>] {
  if (dropType === DROP_TARGET_TYPE.SELF) {
    return [data, null]
  }

  const updatedData = { ...data }
  const [parent] = removeFromParent(updatedData, itemId)
  data[parent.id] = parent as ProjectionItemData<T>

  let operationResult: ReorderOperationReturn<T>

  switch (dropType) {
    case DROP_TARGET_TYPE.EMPTY:
      operationResult = appendTo(updatedData, targetId, itemId)
      break
    case DROP_TARGET_TYPE.BEFORE:
      operationResult = appendBefore(updatedData, targetId, itemId)
      break
    case DROP_TARGET_TYPE.AFTER:
      operationResult = appendAfter(updatedData, targetId, itemId)
      break
  }

  const [updatedItem, operation] = operationResult

  if (updatedItem) {
    updatedData[updatedItem.id] = updatedItem as ProjectionItemData<T>

    return [updatedData, operation]
  }

  return [data, null]
}
