import { CircularProgress } from "@mui/material"
import GetApp from "@mui/icons-material/GetApp"
import { downloadExhibits } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useState } from "react"
import { StyledButton } from "./styled"

export function DownloadAll({ caseId }: { caseId: string }) {
  const [downloadingExhibits, setDownloadingExhibits] = useState(false)
  const { showErrorMessage } = useHandleMessages()

  const handleDownloadExhibits = () => {
    setDownloadingExhibits(true)
    downloadExhibits({ caseId })
      .catch(error => {
        showErrorMessage({
          message:
            "Could not download exhibits. Try again shortly and file an issue if your problem persists.",
          error,
        })
      })
      .finally(() => {
        setDownloadingExhibits(false)
      })
  }

  return (
    <StyledButton
      startIcon={downloadingExhibits ? <CircularProgress size={16} /> : <GetApp />}
      disableElevation
      onClick={handleDownloadExhibits}
      disabled={downloadingExhibits}
    >
      Download All
    </StyledButton>
  )
}
