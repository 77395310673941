import { RawModel, toCamelCase } from "common/helpers/object"
import { formatDate, formatDateWithTime } from "utils"

export class BaseServiceDeserializer {
  static fromJSON<T extends BaseObject>(data: T): T extends (infer U)[] ? RawModel<U>[] : RawModel<T> {
    return toCamelCase<T>(data)
  }

  static fromDateStringToDate(dateStr?: Nullable<string>): Nullable<Date> {
    if (!dateStr) return null

    const date = new Date(dateStr)

    return isNaN(date.valueOf()) ? null : date
  }

  static fromDateTimeString(dateStr?: Nullable<string>): string {
    return formatDateWithTime(BaseServiceDeserializer.fromDateStringToDate(dateStr))
  }

  static fromDateString(dateStr: string): string {
    return formatDate(dateStr, "MM/dd/yyyy")
  }
}
