import { useCallback } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { queryClient } from "react-query/queryClient"

import { useHandleMessages } from "common/messages/useHandleMessages"
import { documentsService } from "api/services/documents"

type UseSyncWithDocumentStructureProps = {
  documentId: string
  postSyncHandler?: (documentId: string) => void
  fetchOnMount?: boolean
}

export const useSyncWithDocumentStructure = ({
  documentId,
  postSyncHandler,
  fetchOnMount = true,
}: UseSyncWithDocumentStructureProps) => {
  const { showErrorMessage } = useHandleMessages()

  const { data } = useQuery(
    [queryKeys.documentMatchesDocumentStructure, documentId],
    async () => {
      return await documentsService.getMatchesDocumentStructure({ documentId })
    },
    { enabled: fetchOnMount }
  )

  const {
    mutateAsync: syncWithDocumentStructureMutation,
    isLoading,
    error,
  } = useMutation(documentsService.syncWithDocumentStructure, {
    onSuccess: matchingDocumentStructureResponse => {
      queryClient.invalidateQueries([queryKeys.case])

      if (matchingDocumentStructureResponse?.documentId) {
        postSyncHandler?.(matchingDocumentStructureResponse.documentId)
      }
    },
    onError: error => {
      showErrorMessage({
        message: "There was an error syncing with the document structure.",
        error,
      })
    },
  })

  const handleSyncDocumentStructure = useCallback(() => {
    syncWithDocumentStructureMutation({ documentId })
  }, [syncWithDocumentStructureMutation, documentId])

  return {
    isSyncDocumentStructureRequired: !data?.matchingDocumentStructure,
    isLoading,
    error,
    handleSyncDocumentStructure,
  }
}
