import { Chip, Tooltip } from "@mui/material"

interface MismatchFlagProps {
  partitionProviderPlaintiffId: Nullable<number>
  exhibitPartitionPlaintiffId: Nullable<number>
}

export const MismatchFlag = ({
  partitionProviderPlaintiffId,
  exhibitPartitionPlaintiffId,
}: MismatchFlagProps): JSX.Element => {
  if (partitionProviderPlaintiffId === exhibitPartitionPlaintiffId) return <></>

  return (
    <Tooltip title="Please reset the provider or plaintiff">
      <Chip color="error" label="Plaintiff Mismatch" style={{ marginLeft: 4 }} />
    </Tooltip>
  )
}
