import React from "react"

import { ThemeProvider, CacheProvider } from "@emotion/react"
import { TssCacheProvider } from "tss-react"
import createCache from "@emotion/cache"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { GlobalStyles } from "@mui/material"
import { queryClient as defaultQueryClient } from "../react-query/queryClient"
import { theme } from "./theme"
import { StytchProvider } from "@stytch/react"
import { ProvideAuth } from "hooks/useAuth"
import { PermissionFetcher } from "permissions/PermissionFetcher"
import { StytchUIClient } from "@stytch/vanilla-js"
import { BrowserRouter, MemoryRouter, MemoryRouterProps } from "react-router-dom"
import { HandleMessagesProvider } from "common/messages/HandleMessagesProvider"
import { IssueReporterProvider } from "common/issueReporter"
import { globalBeamerStyles } from "./globalBeamerStyles"
import { LocationStoreProvider } from "./location/LocationStoreProvider"

// Style caches
const muiCache = createCache({
  key: "mui",
  prepend: true,
})

const tssCache = createCache({
  key: "tss",
})

const PUBLIC_TOKEN = import.meta.env.VITE_STYTCH_PUBLIC_TOKEN
const stytch = new StytchUIClient(PUBLIC_TOKEN)

// provide overrides for the router component to allow for setting up routes inside of tests using MemoryRouter
// e.g, <MemoryRouter initialEntries={[yourPath]}>...</MemoryRouter>
interface ContextProvidersProps {
  children: React.ReactNode
  RouterComponent?: typeof BrowserRouter | typeof MemoryRouter
  routerProps?: MemoryRouterProps
  queryClient?: QueryClient
}
const ContextProviders: React.FC<ContextProvidersProps> = ({
  children,
  RouterComponent = BrowserRouter,
  routerProps = {},
  queryClient = defaultQueryClient,
}) => {
  return (
    <CacheProvider value={muiCache}>
      <TssCacheProvider value={tssCache}>
        <>
          <GlobalStyles
            styles={{
              "@keyframes mui-auto-fill": { from: { display: "block" } },
              "@keyframes mui-auto-fill-cancel": { from: { display: "block" } },
              ...globalBeamerStyles,
            }}
          />
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
              {/* Basic styling. */}
              <CssBaseline />
              <QueryClientProvider client={queryClient}>
                <RouterComponent {...routerProps}>
                  <LocationStoreProvider>
                    <StytchProvider stytch={stytch}>
                      <ProvideAuth>
                        <PermissionFetcher>
                          <IssueReporterProvider>
                            <HandleMessagesProvider>{children}</HandleMessagesProvider>
                          </IssueReporterProvider>
                        </PermissionFetcher>
                      </ProvideAuth>
                    </StytchProvider>
                  </LocationStoreProvider>
                </RouterComponent>
              </QueryClientProvider>
            </MuiThemeProvider>
          </ThemeProvider>
        </>
      </TssCacheProvider>
    </CacheProvider>
  )
}

export default ContextProviders
