import { FullDocumentStructureDefinition } from "common/types/documentStructure"
import React, { useContext, useReducer } from "react"
import { documentStructureTreeReducer } from "./reducer"
import { selectDocumentStructureItemTree } from "./selectors"
import { DocumentStructureTreeAction } from "./actions"
import { DocumentStructureStoreData } from "./types"
import { DocumentStructureStoreContext } from "./context"

export function useDocumentStructureItem(
  id: string
): [Nullable<FullDocumentStructureDefinition>, React.Dispatch<DocumentStructureTreeAction>] {
  const [state, dispatch] = useContext(DocumentStructureStoreContext)

  return [selectDocumentStructureItemTree(state, "items", id), dispatch]
}

export function useDocumentStructureItems(): [
  DocumentStructureStoreData,
  React.Dispatch<DocumentStructureTreeAction>,
] {
  const [state, dispatch] = useReducer(documentStructureTreeReducer, {
    items: {},
    sections: {},
    blocks: {},
    structure: {},
  })

  return [state, dispatch]
}
