import { useCallback } from "react"
import { documentActions, useDocumentStore } from "documents/store"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"
import { VIEW_MED_CHRON_SECTIONS } from "../constants"
import { StyledSectionNavName } from "./styled"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { documentsService } from "api/services/documents"

interface SectionNavListItemProps {
  sectionType: VIEW_MED_CHRON_SECTIONS
  entityCount: number
}

export const SectionNavListItem = ({ sectionType, entityCount }: SectionNavListItemProps): JSX.Element => {
  const documentId = useDocumentStore(state => state.documentId)
  const requestId = useDocumentStore(state => state.questionnaireId)
  const { data: medchronTile } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => {
      if (documentId) {
        return documentsService.getMedchronTile({ documentId: documentId })
      }
    },
    {
      enabled: !!documentId,
    }
  )

  const handleClick = useCallback(() => {
    if (!medchronTile) return

    amplitudeApm.trackEvent(
      new MedicalChronologyAnalyticEvent(
        MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyJumpedToSectionViaNavigation,
        {
          document_id: documentId,
          request_id: requestId,
          documentType: medchronTile.questionnaireType,
        }
      )
    )

    documentActions.setScrollToElementId(sectionType)
  }, [documentId, requestId, sectionType, medchronTile])

  return (
    <StyledSectionNavName key={sectionType} onClick={handleClick}>
      {sectionType} {`(${entityCount})`}
    </StyledSectionNavName>
  )
}
