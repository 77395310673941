import { DocumentStructureStoreData } from "../types"
import { deleteDocumentStructureElement } from "./delete"

export function resetDocumentStructureItem(
  state: DocumentStructureStoreData,
  id: string
): DocumentStructureStoreData {
  if (!state.items[id]) return state

  let nextState = { ...state }
  delete nextState.items[id]

  const children = nextState.structure[id] ?? []

  for (const child of children) {
    nextState = deleteDocumentStructureElement(nextState, child)
  }

  delete nextState.structure[id]

  return nextState
}
