import Box from "@mui/material/Box"

import { Entry } from "../types"
import { Link, PopoverContainer, Row } from "../styled"
import { toUSDateString } from "utils"

interface EntriesPopoverProps {
  date: Nullable<string>
  entries: Entry[]
  onEntryClick: (entry: Entry) => void
}

const EntriesPopover = ({ date, entries, onEntryClick }: EntriesPopoverProps): JSX.Element => {
  return (
    <PopoverContainer>
      {entries.map((entry, index) => {
        return (
          <Row key={`${entry.exhibit_id}-${index}`}>
            <Box>{toUSDateString(date)}</Box>
            <Link
              onClick={() => {
                onEntryClick(entry)
              }}
            >
              Page {entry.page}
            </Link>
          </Row>
        )
      })}
    </PopoverContainer>
  )
}

export { EntriesPopover as default }
