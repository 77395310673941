import { AdditionalInfoRevamp } from "requests/RequestForm/AdditionalInfoRevamp"
import { useRequestContext } from "requests/context"
import { usePermissions } from "permissions/usePermissions"
import { REQUEST_FORM_VARIANTS } from "requests/RequestForm/AdditionalInfoRevamp/constants"

export const AdditionalInformation = () => {
  const { requestId } = useRequestContext()
  const { requestRevampSinglePageFormEnabled } = usePermissions()

  if (!requestId) {
    return <></>
  }

  return (
    <AdditionalInfoRevamp
      variant={
        requestRevampSinglePageFormEnabled
          ? REQUEST_FORM_VARIANTS.SINGLE_PAGE_REQUEST
          : REQUEST_FORM_VARIANTS.MULTI_PAGE_REQUEST
      }
    />
  )
}
