import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import { Appointment } from "documents/types"
import { flagsDataSelectors } from "documents/store/flags"
import { useDocumentStore } from "documents/store"
import { FlagMark, FlagMarkProps } from "./FlagMark"
import { useShallow } from "zustand/react/shallow"
import { DATA_FLAG_ID, FLAG_IMAGE_SIZE } from "./types"
import { difference } from "lodash"
import { useDebouncedCallback } from "use-debounce"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"

export interface AppointmentFlagMarksProps {
  appointmentId: Appointment["id"]
  anchorRef: MutableRefObject<Nullable<HTMLDivElement>>
  editorRef: MutableRefObject<Nullable<HTMLDivElement>>
  content: EditorRoot
}

const FLAGS_IN_ROW = 4

// Flags indicators for entire appointment
export const AppointmentFlagMarks = ({
  appointmentId,
  content,
  editorRef,
  anchorRef,
}: AppointmentFlagMarksProps) => {
  const flagsInAppointment = useDocumentStore(
    useShallow(flagsDataSelectors.getFlagsIdInAppointment(appointmentId))
  )

  const [flagsMark, setFlagsMark] = useState<FlagMarkProps[]>([])

  const calculateFlagsMark = useCallback(() => {
    if (!anchorRef.current || !editorRef.current || !flagsInAppointment.length) {
      return setFlagsMark([])
    }

    const wrapperY = editorRef.current.getBoundingClientRect().y
    const anchorY = anchorRef.current.getBoundingClientRect().y
    const flagsElements = [...editorRef.current.querySelectorAll(`[${DATA_FLAG_ID}]`)]
    const existingFlagIds = flagsElements.map(flag => flag.getAttribute(DATA_FLAG_ID))
    const entireAppointmentFlagIds = difference(flagsInAppointment, existingFlagIds)

    if (entireAppointmentFlagIds.length) {
      const result: FlagMarkProps[] = []

      const haveAdjustment = entireAppointmentFlagIds.length > FLAGS_IN_ROW

      entireAppointmentFlagIds.forEach((id, i) => {
        const rowTopAdjustment = haveAdjustment ? Math.floor(i / FLAGS_IN_ROW) * FLAG_IMAGE_SIZE : 0
        const topMargin = haveAdjustment ? 42 : 48

        if (anchorRef.current && id) {
          result.push({
            flagId: id,
            top: wrapperY - anchorY + topMargin + rowTopAdjustment,
            right: (i % FLAGS_IN_ROW) * FLAG_IMAGE_SIZE,
          })
        }
      })

      setFlagsMark(result)
    } else {
      setFlagsMark([])
    }
  }, [anchorRef, editorRef, flagsInAppointment])

  const debouncedCalculateFlagsMark = useDebouncedCallback(calculateFlagsMark, 200)
  const debouncedCallback = useRef(debouncedCalculateFlagsMark)

  useEffect(() => {
    calculateFlagsMark()
  }, [flagsInAppointment, calculateFlagsMark])

  useEffect(() => {
    const callback = debouncedCallback.current

    callback()

    return () => {
      callback.cancel()
    }
  }, [flagsInAppointment, content])

  return flagsMark.map(flagData => <FlagMark key={flagData.flagId} {...flagData} />)
}
