import { QueryClient, QueryKey } from "@tanstack/react-query"
import { PermissionServiceDeserializer } from "api/services/permissions/serializers"

export function retryOrProvideDefaultPermissions(
  queryClient: QueryClient,
  queryKey: QueryKey
): (retryCount: number) => boolean {
  return retryCount => {
    if (retryCount < 3) return true

    queryClient.setQueryData(queryKey, PermissionServiceDeserializer.fromJSON({}))

    return false
  }
}
