import { useCallback, useEffect, useMemo, useState } from "react"
import { LibraryUseForm } from "settings/Library/TemplateForms/types"
import { StyledListItemButton } from "settings/Library/LibraryTable/styled"
import { useAttributes } from "settings/Library/Filters"
import { queryClient } from "react-query/queryClient"
import { getAttributeValues } from "common/attributes-filter/utils"
import { useMutation, useQuery } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { NonUniqueAttributesError } from "api/services/types"
import { libraryVariableService } from "api/services/library-variable"
import { LibraryVariableRowData } from "common/models/library"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"
import useUser from "hooks/useUser"
import { userService } from "api/services/users"
import { INTERNAL_ROLES } from "common/models/roles"
import { isNull } from "lodash"
import { SelectedAttributeValue } from "common/types/attributes"
import { useConfirm } from "hooks/useConfirm"

import { LibraryVariableFormData as LibraryVariableDataType } from "../types"
import { FORM_STATE_STATUS, getDeleteVariableDialogProps } from "../constants"
import { TemplateFormMessage } from "../TemplateFormMessage"
import { canRoleEditEntityByFirmId } from "../permissions"
import { LibraryVariableFormData } from "../formData/LibraryVariableFormData"
import { StyledFooterWrapper } from "./styled"

const mapAttribute = (attribute: SelectedAttributeValue) => [attribute.id, attribute.valueId]

export const useLibraryVariableForm: LibraryUseForm<LibraryVariableRowData> = ({
  onFinish,
  rowData,
  formStatusMessageMap,
}) => {
  const [formStatus, setFormStatus] = useState<FORM_STATE_STATUS>(FORM_STATE_STATUS.IDLE)
  const attributes = useAttributes()
  const [formData, setFormData] = useState<Nullable<LibraryVariableDataType>>(null)
  const { user } = useUser()
  const [errorInFirm, setErrorInFirm] = useState<boolean>(false)
  const shouldCheckFirm =
    isFeatureEnabled(FEATURES.FIRM_TEMPLATE) &&
    (user.role === INTERNAL_ROLES.LEGALOPS || user.role === INTERNAL_ROLES.LEGALOPS_MANAGER)

  const initialAttributeValues = useMemo(() => {
    if (!rowData.initialAttributes || !attributes) return undefined

    return getAttributeValues(attributes, Object.fromEntries(rowData.initialAttributes.map(mapAttribute)))
  }, [attributes, rowData])

  const onSuccess = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.SUCCESS)
  }, [])

  const onError = useCallback((error: unknown) => {
    if (error instanceof NonUniqueAttributesError) {
      setFormStatus(FORM_STATE_STATUS.DATA_ERROR)
    } else {
      setFormStatus(FORM_STATE_STATUS.API_ERROR)
    }
  }, [])

  const mutationOptions = useMemo(
    () => ({
      onSuccess: () => {
        onSuccess()
        onFinish()

        queryClient.invalidateQueries([queryKeys.libraryVariables])
        queryClient.invalidateQueries([queryKeys.libraryVariableGroups])
      },
      onError: (error: unknown) => onError(error),
    }),
    [onSuccess, onFinish, onError]
  )

  const createVariableMutation = useMutation(libraryVariableService.createVariable, mutationOptions)
  const updateVariableMutation = useMutation(libraryVariableService.updateVariable, mutationOptions)
  const deleteVariableMutation = useMutation(libraryVariableService.deleteVariable, mutationOptions)

  const clearForm = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.IDLE)
  }, [])

  const handleCancel = useCallback(() => {
    onFinish()
    clearForm()
  }, [onFinish, clearForm])

  const handleSubmit = useCallback(async () => {
    if (!formData) return
    if (!formData.content || !formData.name || !formData.sectionWithAttributes.attributeValues) {
      return setFormStatus(FORM_STATE_STATUS.LACK_OF_DATA)
    }

    if (shouldCheckFirm && isNull(formData.firmId)) {
      return setErrorInFirm(true)
    }

    if (typeof rowData.id === "undefined" && formData.groupId) {
      createVariableMutation.mutate({
        data: {
          firmId: formData.firmId,
          content: formData.content,
          groupId: formData.groupId,
          attributes: formData.sectionWithAttributes.attributeValues,
        },
      })

      return
    }

    if (formData.groupId) {
      updateVariableMutation.mutate({
        options: { variableId: rowData.id },
        data: {
          firmId: formData.firmId,
          groupId: formData.groupId,
          content: formData.content,
          attributes: formData.sectionWithAttributes.attributeValues,
        },
      })

      return
    }

    createVariableMutation.mutate({
      data: {
        content: formData.content,
        groupName: formData.name,
        firmId: formData.firmId,
        attributes: formData.sectionWithAttributes.attributeValues,
      },
    })
  }, [formData, rowData.id, createVariableMutation, updateVariableMutation, shouldCheckFirm])

  const message = useMemo(() => {
    if (formStatus === FORM_STATE_STATUS.IDLE) return null

    const messageText = formStatusMessageMap[formStatus]

    if (!messageText) return null

    return <TemplateFormMessage clear={clearForm} message={messageText} formStatus={formStatus} />
  }, [clearForm, formStatus, formStatusMessageMap])

  const viewForm = useMemo(() => {
    return (
      <div>
        <p>{rowData.initialVariableContent}</p>
      </div>
    )
  }, [rowData])

  const editForm = useCallback(
    (footer: Nullable<JSX.Element>) =>
      attributes ? (
        <>
          <LibraryVariableFormData
            attributes={attributes}
            initialAttributeValues={initialAttributeValues}
            initialVariableContent={rowData.initialVariableContent}
            initialGroupId={rowData.initialGroupId}
            initialFirmId={rowData.initialFirmId}
            initialGroupName={rowData.initialGroupName}
            onChange={setFormData}
            errorInFirm={errorInFirm}
            error={formStatus === FORM_STATE_STATUS.DATA_ERROR}
            highlightEmptyFields={formStatus === FORM_STATE_STATUS.LACK_OF_DATA}
          />
          <StyledFooterWrapper>{footer}</StyledFooterWrapper>
        </>
      ) : null,
    [
      attributes,
      initialAttributeValues,
      rowData.initialVariableContent,
      rowData.initialGroupId,
      rowData.initialFirmId,
      rowData.initialGroupName,
      formStatus,
      errorInFirm,
    ]
  )

  const errorForm = formStatus === FORM_STATE_STATUS.API_ERROR || formStatus === FORM_STATE_STATUS.DATA_ERROR

  useEffect(() => {
    clearForm()
  }, [formData, clearForm])

  const isLoading =
    createVariableMutation.isLoading || updateVariableMutation.isLoading || deleteVariableMutation.isLoading

  const { data: firmsUserManage } = useQuery(
    [queryKeys.firmsUserManage, user.id],
    () => userService.getFirmsUserManage(null),
    {
      enabled: user.isInternal,
      ...SILENT_QUERY_PARAMS,
    }
  )

  const canDeleteRow = canRoleEditEntityByFirmId(user.role, rowData.initialFirmId, firmsUserManage)

  const deleteVariableDialogProps = useMemo(
    () =>
      getDeleteVariableDialogProps({
        variableName: rowData.initialGroupName,
      }),
    [rowData.initialGroupName]
  )
  const { confirm: confirmDelete, dialog: deleteVariableDialog } = useConfirm(deleteVariableDialogProps)

  const handleDelete = useCallback(async () => {
    const confirmed = await confirmDelete()

    if (confirmed) {
      deleteVariableMutation.mutate({
        options: { variableId: rowData.id },
      })
    }
  }, [confirmDelete, deleteVariableMutation, rowData])

  const additionalRowButtons = canDeleteRow ? (
    <span>
      {deleteVariableDialog}
      <StyledListItemButton onClick={handleDelete}>Delete</StyledListItemButton>
    </span>
  ) : null

  return {
    message,
    editForm,
    handleSubmit,
    handleCancel,
    isLoading,
    viewForm,
    clearForm,
    errorForm,
    additionalRowButtons,
  }
}
