import React, {
  MouseEvent,
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import * as Sentry from "@sentry/browser"
import { Helmet } from "react-helmet"
import useUser from "hooks/useUser"
import { usePermissions } from "permissions/usePermissions"
import { useLocation } from "react-router-dom"
import { JIRA_REQUEST_TYPE_SUPPORT_BUG } from "./constants"
import { parseError } from "./errorUtils"

export interface IssueReporterMetadata {
  summary: string
  description: string
  email: string
  fullname: string
  affectedUrl: string
  error?: unknown
}

export interface IssueReporterTrigger {
  /**
   * Checks whether the issue reporter widget is available to use. You should use this
   * to conditionally show the "report an issue" button.
   */
  isAvailable: boolean
  /**
   * Updates the metadata that will be sent to the issue reporter widget when it is triggered.
   */
  updateMetadata: (metadata: Partial<IssueReporterMetadata>) => void
  /**
   * Trigger the widget to show the issue reporter UI.
   *
   * @param e Optionally, the click event that triggered the UI. This is used to prevent the default action.
   */
  triggerUI: (e?: MouseEvent<HTMLElement>) => void
}

interface IssueReporterContextType {
  isReady: boolean
  showCollectorDialog?: () => void
}

const defaultIssueReporterContext: IssueReporterContextType = {
  isReady: false,
}

export const IssueReporterContext = createContext<IssueReporterContextType>(defaultIssueReporterContext)

/**
 * Wraps the application and provides {@link IssueReporterContext} to be used by {@link useIssueReporter} hook.
 * This component checks the permission in a centralized way and loads the issue reporter widget as needed.
 */
export const IssueReporterProvider = memo<PropsWithChildren>(function IssueReporterProvider({ children }) {
  const [contextValue, setContextValue] = useState<IssueReporterContextType>(defaultIssueReporterContext)
  const { showReportIssueEnabled } = usePermissions({ enabled: false, suspense: false })
  const enabled = useRef(showReportIssueEnabled)
  enabled.current = showReportIssueEnabled

  // ensure we have the correct value for isReady whenever the permission changes
  useEffect(() => {
    setContextValue(old => ({
      ...old,
      isReady: showReportIssueEnabled && !!old.showCollectorDialog,
    }))
  }, [showReportIssueEnabled])

  // ensure ATL_JQ_PAGE_PROPS window object (where the Jira widget reads its config) is initialized
  useEffect(() => {
    if (!window.ATL_JQ_PAGE_PROPS) {
      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: function (showCollectorDialog: () => void) {
          setContextValue({
            isReady: enabled.current,
            showCollectorDialog,
          })
        },
        // initialize the fieldValues object with empty values
        fieldValues: {
          summary: "",
          description: "",
          email: "",
          fullname: "",
          customfield_10010: JIRA_REQUEST_TYPE_SUPPORT_BUG, // only valid value for the "Issue Type" field
          customfield_10082: "",
        },
      }
    }
  }, [])

  // WARNING, do not conditionally render the children here, it causes a race
  // condition with mount/remount on <Authenticate /> component in /authenticate
  return (
    <>
      {showReportIssueEnabled && (
        <Helmet defer>
          <script
            data-test="jira-issue-collector"
            type="text/javascript"
            src="https://evenup.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=191b7fec"
          />
        </Helmet>
      )}
      <IssueReporterContext.Provider value={contextValue}>{children}</IssueReporterContext.Provider>
    </>
  )
})

/**
 * Creates an instance of {@link IssueReporterTrigger} that can be used to trigger the issue reporter widget.
 * This hook also keeps the metadata up to date with the current user and page url, plus specific error details.
 *
 * @returns An instance of the issue reporter trigger.
 */
export function useIssueReporter(): IssueReporterTrigger {
  const issueReporterContext = useContext(IssueReporterContext)
  const [metadata, setMetadata] = useState<Partial<IssueReporterMetadata>>({})
  const metaRef = useRef(metadata)
  metaRef.current = metadata
  const { user } = useUser()
  const location = useLocation()

  const issueReportTrigger: IssueReporterTrigger = useMemo(() => {
    return {
      isAvailable: issueReporterContext.isReady,
      updateMetadata: (metadata: Partial<IssueReporterMetadata>) => {
        setMetadata(old => ({
          ...old,
          ...metadata,
        }))
      },
      triggerUI: async (e?: React.MouseEvent<HTMLElement>) => {
        e?.preventDefault()
        if (window.ATL_JQ_PAGE_PROPS) {
          const metadata = metaRef.current
          // we can't recreate the fieldValues object (it breaks the Jira widget)
          // so we need to update each property in it individually
          window.ATL_JQ_PAGE_PROPS.fieldValues.summary = metadata.summary ?? ""
          window.ATL_JQ_PAGE_PROPS.fieldValues.description = metadata.description ?? ""
          window.ATL_JQ_PAGE_PROPS.fieldValues.email = metadata.email ?? ""
          window.ATL_JQ_PAGE_PROPS.fieldValues.fullname = metadata.fullname ?? ""
          window.ATL_JQ_PAGE_PROPS.fieldValues.customfield_10082 = metadata.affectedUrl ?? ""

          if (metadata.error) {
            // if an error object is available, try to extract as much information as possible
            // and attach it to the ticket
            const parsedError = parseError(metadata.error)
            switch (parsedError?.type) {
              case "api": {
                const technicalDetails = [
                  parsedError.userFriendlyMessage,
                  [parsedError.status, parsedError.statusText].filter(v => v).join(" "),
                  [parsedError.method, parsedError.url].filter(v => v).join(" "),
                ]
                  .filter(v => v)
                  .join("\n")
                window.ATL_JQ_PAGE_PROPS.fieldValues.description += `\n\n*Technical Details:*\n${technicalDetails}`
                break
              }
            }
          }

          // actually trigger the widget
          const dialog = issueReporterContext.showCollectorDialog
          if (!dialog) {
            Sentry.captureMessage("showCollectorDialog() not available")
          } else {
            dialog()
          }
        }
      },
    }
  }, [issueReporterContext])

  // keep user data up to date
  useEffect(() => {
    setMetadata(old => ({
      ...old,
      email: user.email,
      fullname: [user.firstName, user.lastName].filter(v => v).join(" "),
    }))
  }, [user])

  // keep page url up to date
  useEffect(() => {
    setMetadata(old => ({
      ...old,
      affectedUrl: window.location.origin + location.pathname + location.search,
    }))
  }, [location])

  return issueReportTrigger
}
