import React, { ElementType, useCallback, useState } from "react"
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  Box,
  BoxProps,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material"
import { Controller, FieldValues, UseControllerProps, useWatch } from "react-hook-form"
import { CurrencyField, CurrencyFieldProps, InputField, InputFieldProps } from "common/form-components"
import { BODY_FONT_SIZE, H3_FONT_SIZE } from "../constants"
import { BaseAssetDto } from "settlements/api/types"
import { Search } from "@mui/icons-material"
import { useDebouncedCallback } from "use-debounce"
import { MAX_SEARCH_CHARS } from "./constants"

export const EstimateFormLabel: React.FC<TypographyProps<"label">> = props => (
  <Typography component="label" sx={{ fontSize: BODY_FONT_SIZE, fontWeight: 700, ...props.sx }} {...props} />
)

export const EstimateFormSectionHeading: React.FC<TypographyProps> = props => (
  <Typography variant="h3" {...props} sx={{ fontWeight: 600, fontSize: H3_FONT_SIZE, ...props.sx }} />
)

export const FormRow: React.FC<BoxProps> = props => (
  <Box {...props} sx={{ display: "flex", gap: 2, ...props.sx }} />
)

export const FormField: React.FC<BoxProps> = props => (
  <Box {...props} sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 0.25, ...props.sx }} />
)

export function EstimateFormInput<T extends FieldValues>(props: InputFieldProps<T>) {
  return <InputField InputProps={{ sx: { fontSize: BODY_FONT_SIZE } }} size="small" fullWidth {...props} />
}

export function EstimateFormAutocompleteInput(props: TextFieldProps) {
  return (
    <TextField
      size="small"
      fullWidth
      {...props}
      InputProps={{ ...props.InputProps, sx: { fontSize: BODY_FONT_SIZE, ...props?.InputProps?.sx } }}
    />
  )
}

export function EstimateFormCurrencyInput<T extends FieldValues>(props: CurrencyFieldProps<T>) {
  return (
    <CurrencyField
      InputProps={{ sx: { fontSize: BODY_FONT_SIZE } }}
      hideCurrency
      size="small"
      fullWidth
      {...props}
    />
  )
}

type EstimateFormRadioGroupProps<T extends FieldValues> = RadioGroupProps & UseControllerProps<T>

export function EstimateFormRadioGroup<T extends FieldValues>({
  control,
  name,
  ...props
}: EstimateFormRadioGroupProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => <RadioGroup row sx={{ pl: 1, ...props.sx }} {...props} {...field} />}
    />
  )
}

export function EstimateFormRadioOption(props: Omit<FormControlLabelProps, "control">) {
  return (
    <FormControlLabel
      {...props}
      control={<Radio color="secondary" />}
      label={<Typography sx={{ fontSize: BODY_FONT_SIZE }}>{props.label}</Typography>}
    />
  )
}

type RHFAutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ChipComponent extends ElementType<any>,
  TFieldValues extends FieldValues,
> = Omit<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>, "onChange"> &
  UseControllerProps<TFieldValues>

export function RHFAutocomplete<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ChipComponent extends ElementType<any>,
  TFieldValues extends FieldValues,
>({
  control,
  name,
  ...autocompleteProps
}: RHFAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent, TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete
          {...field}
          {...autocompleteProps}
          onChange={(_event, newValue) => {
            // the event supplied by the autocomplete onChange is not compatible with the event that Controller expects
            // so we need to make a fake one so onChange can set the newValue
            const fakeEvent = {
              target: {
                value: newValue,
              },
            }
            field.onChange(fakeEvent)
          }}
        />
      )}
    />
  )
}

export function AssetAutocomplete<
  Value extends BaseAssetDto,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ChipComponent extends ElementType<any>,
  TFieldValues extends FieldValues,
>({
  ...props
}: RHFAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent, TFieldValues>) {
  return (
    <RHFAutocomplete
      isOptionEqualToValue={(option, value) => option.key === value.key}
      getOptionLabel={option => {
        if (typeof option === "string") {
          return option
        }
        return option.display ?? option.key
      }}
      {...props}
    />
  )
}

interface SearchableAssetAutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ChipComponent extends ElementType<any>,
  TFieldValues extends FieldValues,
> extends Omit<
    RHFAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent, TFieldValues>,
    "options" | "loading" | "inputValue" | "onInputChange" | "filterOptions"
  > {
  initialOptions?: Value[]
  loadData: (inputValue: string) => Promise<Value[]>
  debounceDelayMs?: number
  maxSearchChars?: number
  onMaxSearchCharsExceeded?: (enteredValue: string, truncatedValue: string) => void
}

type InputChangeHandler = (
  event: React.SyntheticEvent<Element, Event>,
  value: string,
  reason: AutocompleteInputChangeReason
) => void

export function SearchableAssetAutocomplete<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ChipComponent extends ElementType<any>,
  TFieldValues extends FieldValues,
>({
  initialOptions = [],
  loadData,
  debounceDelayMs = 200,
  maxSearchChars = MAX_SEARCH_CHARS,
  onMaxSearchCharsExceeded,
  ...props
}: SearchableAssetAutocompleteProps<
  Value,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent,
  TFieldValues
>) {
  const [inputValue, setInputValue] = useState("")
  const [options, setOptions] = useState<Value[]>(initialOptions)
  const [loading, setLoading] = useState(false)
  const formValue: Value | Value[] | null | undefined = useWatch({
    control: props.control,
    name: props.name,
  })

  const handleLoadData = useDebouncedCallback(async (newInputValue: string) => {
    setLoading(true)
    try {
      const newOptions = await loadData(newInputValue)
      setOptions(newOptions)
    } finally {
      setLoading(false)
    }
  }, debounceDelayMs)

  const handleInputChange: InputChangeHandler = useCallback(
    (_event, newInputValue) => {
      const truncatedInputValue = newInputValue.substring(0, maxSearchChars)
      if (newInputValue.length > maxSearchChars) {
        onMaxSearchCharsExceeded?.(newInputValue, truncatedInputValue)
      }
      setInputValue(truncatedInputValue)
      handleLoadData(truncatedInputValue)
    },
    [handleLoadData, onMaxSearchCharsExceeded, maxSearchChars]
  )

  const handleFocus = useCallback(() => {
    if (!inputValue) {
      handleLoadData("")
    }
  }, [handleLoadData, inputValue])

  let autocompleteOptions: Value[] = options

  if (loading) {
    autocompleteOptions = []
  } else if (formValue && props.multiple && Array.isArray(formValue)) {
    // include currently selected options in the options array to stop MUI from complaining
    autocompleteOptions = [...formValue, ...options]
  } else {
    autocompleteOptions = options
  }

  return (
    <RHFAutocomplete
      loadingText="Searching..."
      noOptionsText="No results, try another search"
      popupIcon={<Search />}
      onFocus={handleFocus}
      {...props}
      // prevent Search icon from going upside down on open
      sx={{ "& .MuiAutocomplete-popupIndicator": { transform: "rotate(0)" }, ...props.sx }}
      // filterOptions should do nothing because the filtering happens on the server
      filterOptions={option => option}
      loading={loading}
      options={autocompleteOptions}
      inputValue={inputValue}
      onInputChange={handleInputChange}
    />
  )
}
