import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { RawModel, toCamelCase, toSnakeCase } from "common/helpers/object"
import { isUndefined } from "lodash"
import { apiService } from "./ApiService"
import { ApiServiceType } from "./types"
import { FormDataCallProps, REQUEST_METHODS, makeApiCall } from "apiHelper"

interface FileServiceOptions {
  uploadId?: string
}

type FileServiceArgs<T> = T extends null
  ? FileServiceOptions
  : FileServiceOptions & {
      data: T
    }

interface FileUploadData {
  chunk_count: number
  content_type: string
  size: number
}

interface FileUploadDto extends FileUploadData {
  id: string
  path: string
}

interface SignedFileUploadDto extends FileUploadDto {
  sign_url: string
}

// See "ExternalProcessingStatus" in lops_backend
export type FileVerificationProcessingStatus = "SUCCESS" | "WARNING" | "QUEUED" | "IN_PROGRESS" | "FAILURE"

interface VerificationDto {
  id: string
  processing_status: FileVerificationProcessingStatus
  processing_info?: Nullable<string>
}

export type FileUpload = RawModel<FileUploadDto>

export type SignedFileUpload = RawModel<SignedFileUploadDto>

export type FileVerificationStatus = RawModel<VerificationDto>

interface ChunkUpload {
  file: Blob
  offset: number
  upload: string
}

class FileUploadDeserializer {
  static fromJSON(fileUpload: FileUploadDto): FileUpload {
    return toCamelCase(fileUpload)
  }

  static signedFromJSON(fileUpload: SignedFileUploadDto): SignedFileUpload {
    return toCamelCase(fileUpload)
  }

  static verificationStatusFromJSON(status: VerificationDto): FileVerificationStatus {
    return toCamelCase(status)
  }
}

class FileUploadSerializer {
  static newToJSON(fileUpload: RawModel<FileUploadData & { file_name: string }>): FileUploadData {
    return toSnakeCase(fileUpload)
  }

  static chunkToFormData(chunk: ChunkUpload): FormData {
    const formData = new FormData()

    formData.append("file", chunk.file)
    formData.append("offset", String(chunk.offset))
    formData.append("upload", chunk.upload)

    return formData
  }
}

enum FILE_API_PATHS {
  BASE = "upload/upload",
  CHUNK = "uploadchunk",
  GENERATE_URL = "generate_url",
  VERIFY = "validate",
  VERIFICATION_STATUS = "status",
}

class FileService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options: FileServiceOptions, path?: FILE_API_PATHS): string {
    const pathParts = ["", FILE_API_PATHS.BASE, options.uploadId, path]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  createFileUpload = withRequestSerializer(
    FileUploadSerializer.newToJSON,
    withResponseSerializer(
      FileUploadDeserializer.fromJSON,
      ({ data, ...options }: FileServiceArgs<FileUploadData>) => {
        const path = this.getPath(options)
        return handleEmptyResponse(this.apiService.create(data, path))
      }
    )
  )

  createSignedFileUpload = withRequestSerializer(
    FileUploadSerializer.newToJSON,
    withResponseSerializer(
      FileUploadDeserializer.signedFromJSON,
      ({ data, ...options }: FileServiceArgs<FileUploadData>) => {
        const path = this.getPath(options, FILE_API_PATHS.GENERATE_URL)
        return handleEmptyResponse(this.apiService.create(data, path))
      }
    )
  )

  verifyFileUpload = withResponseSerializer(
    FileUploadDeserializer.verificationStatusFromJSON,
    (options: Required<FileServiceOptions>) => {
      const path = this.getPath(options, FILE_API_PATHS.VERIFY)
      return handleEmptyResponse(this.apiService.create(null, path))
    }
  )

  getVerificationStatus = withResponseSerializer(
    FileUploadDeserializer.verificationStatusFromJSON,
    (options: Required<FileServiceOptions>) => {
      const path = this.getPath(options, FILE_API_PATHS.VERIFICATION_STATUS)
      return handleEmptyResponse(this.apiService.get(null, path))
    }
  )

  uploadChunk = withRequestSerializer(
    FileUploadSerializer.chunkToFormData,
    ({ data, ...options }: Required<FileServiceArgs<FormData>>) => {
      const path = this.getPath(options, FILE_API_PATHS.CHUNK)
      return this.apiService.submitForm(data, path)
    }
  )

  signedUploadChunk = ({ chunk, url }: { chunk: Blob; url: string }) => {
    const options: FormDataCallProps = {
      path: "/",
      customUrl: url,
      method: REQUEST_METHODS.PUT,
      isFormData: true,
      json: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: chunk as any,
    }

    return makeApiCall(options)
  }
}

export const fileService = new FileService(apiService)
