import { CircularProgress } from "@mui/material"
import { useHandleMessages } from "common/messages/useHandleMessages"
import Button from "evenup-ui/Button"
import { ReactNode, useCallback, useMemo, useState } from "react"
import * as Sentry from "@sentry/react"

export function useAction({ action, name }: { name: string; action: () => Promise<void> | void }) {
  const [isLoading, setIsLoading] = useState(false)
  const { showErrorMessage } = useHandleMessages()

  const handleAction = useCallback(async () => {
    try {
      setIsLoading(true)
      await action()
    } catch (error) {
      showErrorMessage(`Failed to ${name}, please refresh and try again.`)
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }, [action, name, showErrorMessage])

  return useMemo(() => ({ isLoading, handleAction }), [isLoading, handleAction])
}

export function ActionButton({
  disabled,
  isLoading,
  label,
  onClick,
  ...props
}: {
  disabled?: boolean
  onClick?: () => void
  isLoading: boolean
  label: ReactNode
}) {
  return (
    <Button type="submit" disabled={disabled || isLoading} onClick={onClick} {...props}>
      {label} {isLoading && <CircularProgress size={20} color="secondary" />}
    </Button>
  )
}
