import { SnackbarOrigin } from "@mui/material"
import { useContext, useMemo } from "react"
import { HandleMessagesContext } from "./HandleMessagesContext"
import { Message } from "./Message"
import { DEFAULT_ANCHOR, DEFAULT_TIMEOUT, MESSAGE_ACTION_TYPE } from "./handleMessagesReducer"

interface CommonMessageOptions {
  /**
   * Whether to append this message to the existing ones (if any) or replace them.
   */
  append?: boolean
  /**
   * If null, the message will not be dismissed automatically.
   * If undefined, the {@link DEFAULT_TIMEOUT} will be used.
   */
  timeout?: Nullable<number>
  /**
   * If undefined, the {@link DEFAULT_ANCHOR} origin will be used.
   */
  anchorOrigin?: SnackbarOrigin
}

interface SimpleMessageOptions extends CommonMessageOptions {
  message: Message["message"]
}

interface ErrorMessageOptions extends SimpleMessageOptions {
  error?: Message["error"]
}

type SingleMessageOptions =
  | (CommonMessageOptions & { message: Message })
  | (SimpleMessageOptions & { type: Exclude<Message["type"], "error"> })
  | (ErrorMessageOptions & { type: "error" })

interface MultiMessageOptions extends CommonMessageOptions {
  messages: Message[]
}

type AllOptions = SingleMessageOptions | MultiMessageOptions

interface UseHandleMessagesCallbackReturn {
  /**
   * Displays a single {@link Message} to the user.
   */
  showMessage: (opts: SingleMessageOptions) => void
  /**
   * Displays a single error message to the user.
   * Pass the actual error object to provide more context to the downstream components.
   */
  showErrorMessage: (opts: ErrorMessageOptions | string) => void
  /**
   * Displays a single success message to the user.
   */
  showSuccessMessage: (opts: SimpleMessageOptions | string) => void
  /**
   * Displays a single informational message to the user.
   */
  showInfoMessage: (opts: SimpleMessageOptions | string) => void
  /**
   * Displays a single warning message to the user.
   */
  showWarningMessage: (opts: SimpleMessageOptions | string) => void
  /**
   * Displays multiple {@link Message}s to the user at the same time.
   */
  showMessages: (opts: MultiMessageOptions) => void
}

/**
 * Hook to show notification messages to the user (informational, errors, etc.).
 * The messages will be displayed in a consistent way across the application and should be used
 * for all user-facing "reactive" messages.
 * @returns A {@link UseHandleMessagesCallbackReturn} object with methods to show messages.
 */
export function useHandleMessages(): UseHandleMessagesCallbackReturn {
  const messagesContext = useContext(HandleMessagesContext)
  const dispatch = messagesContext?.dispatch

  return useMemo<UseHandleMessagesCallbackReturn>(() => {
    const handleMessages: (opts: AllOptions) => void = opts => {
      let messagePayload: Message[] = []
      if ("messages" in opts) {
        messagePayload = opts.messages
      } else if (typeof opts.message === "object" && "message" in opts.message) {
        messagePayload = [opts.message]
      } else {
        const type = "type" in opts ? opts.type : "error"
        const error = "error" in opts ? opts.error : undefined
        messagePayload = [{ message: opts.message, type, error }]
      }

      const append = "append" in opts ? opts.append : false
      if (dispatch) {
        dispatch({
          type: append ? MESSAGE_ACTION_TYPE.APPEND : MESSAGE_ACTION_TYPE.SHOW,
          payload: {
            messages: messagePayload,
            timeout: opts.timeout === undefined ? DEFAULT_TIMEOUT : opts.timeout,
            anchorOrigin: opts.anchorOrigin ?? DEFAULT_ANCHOR,
          },
        })
      }
    }
    const handleMessageWithoutType: (
      opts: SimpleMessageOptions | ErrorMessageOptions | string,
      type: Message["type"]
    ) => void = (opts, type) => {
      if (typeof opts === "string") {
        handleMessages({ message: opts, type })
      } else {
        handleMessages({ ...opts, type })
      }
    }
    return {
      showMessage: handleMessages,
      showErrorMessage: opts => handleMessageWithoutType(opts, "error"),
      showSuccessMessage: opts => handleMessageWithoutType(opts, "success"),
      showInfoMessage: opts => handleMessageWithoutType(opts, "info"),
      showWarningMessage: opts => handleMessageWithoutType(opts, "warning"),
      showMessages: handleMessages,
    }
  }, [dispatch])
}
