import { v4 } from "uuid"
import { FormRange } from "../types"

export type DeleteAction =
  | { type: "addRange" }
  | { type: "deleteRange"; formId: string }
  | { type: "editRange"; formId: string; start: number; end: number }

export interface DeleteState {
  ranges: FormRange[]
}

export const deleteReducer = (state: DeleteState, action: DeleteAction): DeleteState => {
  switch (action.type) {
    case "addRange": {
      const newRanges: FormRange[] = [...state.ranges, { formId: v4(), start: NaN, end: NaN }]
      return {
        ...state,
        ranges: newRanges,
      }
    }
    case "deleteRange": {
      const newRanges: FormRange[] = state.ranges.filter(range => range.formId !== action.formId)

      return {
        ...state,
        ranges: newRanges,
      }
    }
    case "editRange": {
      const newRanges: FormRange[] = state.ranges.map(range => {
        if (range.formId !== action.formId) {
          return range
        }
        return { ...range, start: action.start, end: action.end }
      })

      return {
        ...state,
        ranges: newRanges,
      }
    }
  }
}
