import React from "react"
import Chip from "@mui/material/Chip"
import { DOCUMENT_TYPES } from "requests/enums"

interface RequestTypeBadgeProps {
  type: DOCUMENT_TYPES
  basicToSimpleDemand?: boolean
}

export const RequestTypeBadge: React.FC<RequestTypeBadgeProps> = ({
  type,
  basicToSimpleDemand = false,
}): JSX.Element | null => {
  let label = ""

  switch (type) {
    case DOCUMENT_TYPES.STANDARD:
    case DOCUMENT_TYPES.MEDICAL_CHRONOLOGY:
      label = "Standard"
      break
    case DOCUMENT_TYPES.PREMIUM_MEDCHRON:
      label = "Premium"
      break
    case DOCUMENT_TYPES.BASIC_PLUS:
      if (basicToSimpleDemand) {
        label = "Simple"
      } else {
        label = "Basic +"
      }
      break
    case DOCUMENT_TYPES.SIMPLE:
      label = "Simple"
      break
    default:
      return null
  }

  return <Chip label={label} variant="outlined" color="primary" size="small" />
}
