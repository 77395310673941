// Colo(u)rs can be chosen later :)
export interface StatusStyle {
  text: string
  color: string
  filled: boolean
}
const LOADING_STATUS = "loading"
const PENDING_STATUS = "pending"
const TODO_STATUS = "TODO"
const statusToProperties: { [key: string]: StatusStyle } = {
  loading: {
    text: "Loading",
    color: "#bdbdbd",
    filled: true,
  },
  pending: {
    text: "Pending",
    color: "#292b2c",
    filled: false,
  },
  started: {
    text: "Started",
    color: "#5bc0de",
    filled: true,
  },
  automated: {
    text: "Automated Annotations Finished",
    color: "#5bc0de",
    filled: true,
  },
  review: {
    // To be removed, still here for old status files before the granularity change
    text: "Uploaded To Kili",
    color: "#5bc0de",
    filled: true,
  },
  uploaded: {
    text: "Uploaded To Kili",
    color: "#5bc0de",
    filled: true,
  },
  ongoing: {
    text: "Ongoing",
    color: "#f0ad4e",
    filled: true,
  },
  ongoing_processing: {
    text: "Ongoing - Processing",
    color: "#f0ad4e",
    filled: true,
  },
  in_review_processing: {
    text: "To Review - Processing",
    color: "#5bc0de",
    filled: true,
  },
  in_review: {
    text: "To Review",
    color: "#5bc0de",
    filled: true,
  },
  skipped: {
    text: "Skipped",
    color: "#d9534f",
    filled: false,
  },
  done_processing: {
    text: "Reviewed - Processing",
    color: "#5bc0de",
    filled: true,
  },
  done: {
    text: "Completed",
    color: "#5cb85c",
    filled: true,
  },
  error: {
    text: "Error",
    color: "#a10086",
    filled: true,
  },
}

const kiliStatusToProperties: { [key: string]: StatusStyle } = {
  TODO: {
    text: "Todo",
    color: "#BDBDBD",
    filled: true,
  },
  ONGOING: {
    text: "Ongoing",
    color: "#f0ad4e",
    filled: true,
  },
  LABELED: {
    text: "Labeled",
    color: "#5bc0de",
    filled: false,
  },
  TO_REVIEW: {
    text: "To Review",
    color: "#5bc0de",
    filled: true,
  },
  REVIEWED: {
    text: "Reviewed",
    color: "#5cb85c",
    filled: true,
  },
}

export { statusToProperties as default, kiliStatusToProperties, LOADING_STATUS, PENDING_STATUS, TODO_STATUS }
