import { toConstEnum } from "common/state/enumToConst"

enum FILE_UPLOAD_STATE_ENUM {
  PREPARING_FOR_UPLOAD,
  CREATING_UPLOAD,
  UPLOADING_CHUNKS,
  WAITING_FOR_ASSIGNMENT,
  ASSIGNED,
  UNASSIGNED,
  DELETED,
  CANCELLED,
  FAILURE,
}

export const FILE_UPLOAD_STATE = toConstEnum(FILE_UPLOAD_STATE_ENUM)

export type FILE_UPLOAD_STATE_TYPE = typeof FILE_UPLOAD_STATE
