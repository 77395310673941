import { BaseEditor } from "slate"
import { CitationsEditor, WithCitations } from "./types"
import { Citations } from "./Citations"

export function withCitations<T extends BaseEditor>(editor: T): ReturnType<WithCitations<T>> {
  const editorWithCitations = editor as T & CitationsEditor

  editorWithCitations.citations = new Citations()

  return editorWithCitations
}
