import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { Actions } from "./Actions/Actions"
import { useShallow } from "zustand/react/shallow"
import { CollapseButtons } from "./CollapseButtons"
import { useExhibitBuilderStore, exhibitBuilderActions } from "./store"
import { UserExhibitList } from "./UserExhibitList/UserExhibitList"
import { useEffect } from "react"
import { ExhibitBuilderSkeleton } from "./ExhibitBuilderSkeleton"
import { DeleteFiles } from "./DeletedFiles/DeletedFiles"
import { UnableToParse } from "./UnableToParse/UnableToParse"
import { ErrorBoundary } from "@sentry/react"
import { GenericErrorForSuspense } from "common"

export function ExhibitBuilder({ caseId, documentId }: { caseId: string; documentId: string }) {
  const userExhibitMap = useExhibitBuilderStore(useShallow(state => state.userExhibitMap))
  const userExhibitCount = Object.keys(userExhibitMap).length
  const isLoading = useExhibitBuilderStore(state => state.document.loading)

  useEffect(() => {
    exhibitBuilderActions.initialize({ caseId, documentId })
  }, [caseId, documentId])

  return (
    <ErrorBoundary fallback={<GenericErrorForSuspense />}>
      <Box width="100%" maxWidth={1200} position="relative" margin="0 auto" data-test="exhibit-builder">
        <Box mb={2}>
          <Typography variant="h6" mb={1.5}>
            Exhibit Builder
          </Typography>
          <Typography variant="body2" mb={1.5}>
            File count: {userExhibitCount}
          </Typography>
          <Actions caseId={caseId} />
        </Box>

        {isLoading ? (
          <ExhibitBuilderSkeleton />
        ) : (
          <>
            <CollapseButtons />
            <UserExhibitList />
            <DeleteFiles />
            <UnableToParse />
          </>
        )}
      </Box>
    </ErrorBoundary>
  )
}
