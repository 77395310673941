import { useState } from "react"
import { Menu, MenuItem } from "@mui/material"

import { AddObjectiveTestButton } from "../styled"

type AddNewFindingProps = {
  isDisabled: boolean
  disabledTitle: string
  onAddFinding: () => void
  onAddFindingReference: () => void
  objectiveTestId: string
}

const AddNewFinding = ({
  isDisabled,
  disabledTitle,
  onAddFinding,
  onAddFindingReference,
  objectiveTestId,
}: AddNewFindingProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAddFinding = () => {
    onAddFinding()
    handleClose()
  }

  const handleAddFindingReference = () => {
    onAddFindingReference()
    handleClose()
  }

  return (
    <div>
      <AddObjectiveTestButton
        id={objectiveTestId}
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        textColor="grey"
        disabled={isDisabled}
        title={isDisabled ? disabledTitle : undefined}
        onClick={handleClick}
      >
        + Add
      </AddObjectiveTestButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -35,
          horizontal: 0,
        }}
      >
        <MenuItem onClick={handleAddFindingReference}>Additional Reference Exhibit</MenuItem>
        <MenuItem onClick={handleAddFinding}>Additional Findings</MenuItem>
      </Menu>
    </div>
  )
}

export default AddNewFinding
