import { useMemo } from "react"
import { Box } from "@mui/material"
import { DocumentStructureComponent } from "api/services/documents/types"
import { documentStoreSelectors, useDocumentStore } from "documents/store"
import { VIEW_MED_CHRON_SECTIONS, VIEW_MED_CHRON_SECTION_KEYS } from "../constants"
import { SectionNavListItem } from "./SectionNavListItem"

type FilteredSectionsLinksProps = {
  contentSections: DocumentStructureComponent[]
}

export const FilteredSectionsLinks = ({ contentSections }: FilteredSectionsLinksProps): React.ReactNode => {
  const icdCodesCount = useDocumentStore(documentStoreSelectors.filteredIcdCodes)
  const flags = useDocumentStore(documentStoreSelectors.filteredFlags)
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)
  const pastMedicalVisits = useDocumentStore(documentStoreSelectors.filteredPastMedicalVisits)
  const totalVisits = useMemo(
    () => pastMedicalVisits.reduce((acc, cur) => acc + cur.visits, 0),
    [pastMedicalVisits]
  )

  const postfixByType: Record<VIEW_MED_CHRON_SECTIONS, number> = {
    [VIEW_MED_CHRON_SECTIONS.TREATMENT_TIMELINE]: totalVisits,
    [VIEW_MED_CHRON_SECTIONS.TREATMENT_CALENDAR]: totalVisits,
    [VIEW_MED_CHRON_SECTIONS.DIAGNOSTIC_HIGHLIGHTS]: icdCodesCount.length,
    [VIEW_MED_CHRON_SECTIONS.FLAGS]: flags.length,
    [VIEW_MED_CHRON_SECTIONS.PAST_MEDICAL_VISITS]: totalVisits,
    [VIEW_MED_CHRON_SECTIONS.MEDICAL_SUMMARIES]: totalVisits,
    [VIEW_MED_CHRON_SECTIONS.EXHIBIT_LIST]: exhibitsOrder.length,
  }

  return (
    <>
      <Box fontSize="16px" fontWeight={600} mb={2.5} mt={2}>
        Document Sections:
      </Box>
      <Box display="flex" gap={2} flexDirection="column">
        {contentSections
          ? contentSections.map((section: DocumentStructureComponent) => {
              const sectionType = VIEW_MED_CHRON_SECTION_KEYS[section.componentKey]
              if (!sectionType) return null

              return (
                <SectionNavListItem
                  key={sectionType}
                  sectionType={sectionType}
                  entityCount={postfixByType[sectionType]}
                />
              )
            })
          : Object.values(VIEW_MED_CHRON_SECTIONS).map(sectionType => (
              <SectionNavListItem
                key={sectionType}
                sectionType={sectionType}
                entityCount={postfixByType[sectionType]}
              />
            ))}
      </Box>
    </>
  )
}
