import { DocumentStructureComponent } from "api/services/documents/types"
import { COMPONENTS_KEYS } from "./constants"

export const isComponentInDocumentStructure = (
  documentStructureContent: DocumentStructureComponent[] | undefined,
  componentKey: COMPONENTS_KEYS
): boolean => {
  if (!documentStructureContent) return false

  return !!documentStructureContent.find(content => {
    return content.componentKey === componentKey
  })
}
