import { useCallback } from "react"
import { TextField } from "@mui/material"

import { ExhibitSelect } from "demand/Providers/Provider/ProviderForm/BillsSection/ExhibitSelect"
import { ReferenceEditableRow } from "../../styled"
import { getReferenceExhibitValue } from "../../utils"
import { NO_EXHIBIT_INFO } from "../../constants"
import { ReferencePageProps } from "./types"

export const ReferencePage = ({
  referencePage,
  isDisabled,
  onExhibitChange,
  onPageNumberChange,
  onBlur,
  provider,
  error,
}: ReferencePageProps) => {
  const referenceId = referencePage.id
  const exhibitValue = getReferenceExhibitValue(referencePage)
  const hasNoExhibit = !exhibitValue

  const handleExhibitChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onExhibitChange({ referenceId, value: e.target.value }),
    [onExhibitChange, referenceId]
  )
  const handlePageNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onPageNumberChange({ referenceId, value: e.target.value }),
    [onPageNumberChange, referenceId]
  )

  return (
    <ReferenceEditableRow key={referenceId}>
      <ExhibitSelect
        value={exhibitValue}
        provider={provider}
        onChange={handleExhibitChange}
        disabled={isDisabled}
        onBlur={onBlur}
        error={!!error}
        helperText={error?.message}
      />

      <TextField
        type="number"
        onChange={handlePageNumberChange}
        value={referencePage.pageNumber || ""}
        disabled={hasNoExhibit}
        title={hasNoExhibit ? NO_EXHIBIT_INFO : undefined}
        onBlur={onBlur}
      />
    </ReferenceEditableRow>
  )
}
