export const PRICING_MODEL_FORM_DEFAULT_VALUES = {
  pk: null,
  base_credit: null,
  medical_pages_cap: null,
  medical_pages_overage: null,
  medical_pages_overage_fee: null,
  verdict_fee: null,
  additional_claimant_credit: null,
  medical_pages_cap_revision: null,
  medical_pages_overage_revision: null,
  medical_pages_overage_fee_revision: null,
  notes: null,
  revision_credit: null,
}

export const STANDARD_DEMAND_PRICING_MODEL_FORM_DEFAULT_VALUES = {
  ...PRICING_MODEL_FORM_DEFAULT_VALUES,
  product_type: "standard_demand",
}
export const BASIC_PLUS_PRICING_MODEL_FORM_DEFAULT_VALUES = {
  ...PRICING_MODEL_FORM_DEFAULT_VALUES,
  product_type: "basic_plus",
}
export const SIMPLE_PRICING_MODEL_FORM_DEFAULT_VALUES = {
  ...PRICING_MODEL_FORM_DEFAULT_VALUES,
  product_type: "simple",
}
export const MEDICAL_CHRONOLOGY_PRICING_MODEL_FORM_DEFAULT_VALUES = {
  ...PRICING_MODEL_FORM_DEFAULT_VALUES,
  product_type: "medical_chronology",
}

export const MEDICAL_CHRONOLOGY_PREMIUM_PRICING_MODEL_FORM_DEFAULT_VALUES = {
  ...PRICING_MODEL_FORM_DEFAULT_VALUES,
  product_type: "medical_chronology_premium",
}

export const STATEMENT_OF_WORK_DATE_FORMAT = "yyyy-MM-dd"
