import { Path, Transforms } from "slate"
import { CustomEditor } from "../CustomEditor"
import { Editor } from "../Editor"

export function normalizePath(editor: CustomEditor, path: Path): void {
  const { selection } = editor

  if (
    selection &&
    Path.isDescendant(selection.anchor.path, path) &&
    !Editor.hasPath(editor, selection.anchor.path)
  ) {
    Transforms.select(editor, Editor.end(editor, path))
  }
}
