import { useCallback, useEffect, useState } from "react"
import * as _ from "lodash"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DownloadIcon from "@mui/icons-material/GetApp"
import DeleteIcon from "@mui/icons-material/Delete"
import DownloadDoneIcon from "@mui/icons-material/DownloadDone"

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { compareAsc, compareDesc } from "date-fns"

import useUser from "hooks/useUser"
import {
  deleteRequest,
  downloadRequestFiles,
  fetchRequest_DEPRECATED,
  getCaseByRequestId,
  updateFiles,
  updateRequest_DEPRECATED,
} from "api"
import { amountInDollars, formatDate, getObjectIds, pluralize } from "utils"
import {
  canSeeEditRequestButton,
  canUserDeleteRequest,
  canUserEditRequest,
  canUserInteractWithDemand,
  canUserViewCreditAmount,
  canUserEditCreditAmount,
  isInternalUser,
} from "../permissions/requestAction"
import { RequestContextProvider, useRequestContext } from "../context"
import { useHandleMessages } from "common/messages/useHandleMessages"

import EmptyState from "common/tables/EmptyState"
import { Loading } from "common/loading"
import { GenericError } from "common/GenericError"
import ConfirmDialog from "common/ConfirmDialog"
import Tooltip from "common/Tooltip"
import Table from "common/tables/Table"
import FileAttachment from "../FileAttachment"
import StatusActions from "../RequestStatus/StatusActions"
import requestStatusProperties from "../RequestStatus/requestStatusProperties"
import { OSF } from "common/models/roles"
import {
  ADDITIONAL_INFO,
  ADJUSTER,
  ALL,
  BASIC_INFORMATION,
  CARRIER_INFORMATION,
  CARRIER_NAME,
  CASE_FACTS,
  CASE_TYPE,
  CLAIM_NUMBER,
  DATE_OF_INCIDENT,
  DEFENDANT_TYPE_KEYS,
  DEFENDANTS,
  DEMAND_PACKAGE,
  FILES,
  FIRM_NAME,
  INDIVIDUAL,
  INTAKE_STATUSES,
  OTHER,
  PLAINTIFF_INFORMATION,
  POLICY_COVERAGE_TYPE,
  COMMERCIAL_POLICY_COVERAGE_TYPE_KEYS,
  POLICY_LIMIT,
  POLICY_NUMBER,
  POLICY_TYPE,
  POLICY_TYPE_KEYS,
  RECIPIENT_ADDRESS,
  RECIPIENT_EMAIL,
  SUBMITTER,
  UPLOADED,
  MISSING_DOCS,
  DEMAND_TYPE,
  INTENDED_CLAIM_AMOUNT,
  ASSIGNED_ATTORNEY,
  INDIVIDUAL_POLICY_COVERAGE_TYPE_KEYS,
  COMMERCIAL,
  STATE_OF_INCIDENT,
  RECIPIENT_FAX,
  RECIPIENT_TYPE,
  CASE_TYPES,
} from "../constants"
import { DEFAULT_QUERY_PARAMS, queryKeys } from "../../react-query/constants"
import { MEDICAL_FILE_OPTIONS, REQUEST_PANEL_EXPAND } from "../../demand/constants"
import { downloadFileBlob, getEditDisabledMessageByStatus } from "../utils"
import { getDefendantName } from "./utils"
import { getAnnotationTag } from "requests/utils"
import { isEmpty, noop, partition } from "lodash"
import CreditManagementForm from "./CreditManagementForm"
import { SUPPORT_EMAIL } from "common/constants"
import { TAG_LABEL_TYPE } from "common/TagLabel"
import { RequestStatusBlock } from "./RequestStatusBlock"
import { FileUploadProvider } from "common/file-uploader"
import { useRequestAssets } from "requests/RequestAssets"
import { Alert } from "@mui/material"
import { useTheme } from "@emotion/react"
import { generatePlaintiff } from "requests/RequestForm/AdditionalInfoRevamp/utils"
import { PlaintiffsViewRevamp } from "./PlaintiffsViewRevamp"
import { CARRIER_SECTION_FIELDS, CASE_FACTS_SECTION_FIELDS, PLAINTIFF_SECTION_FIELDS } from "./constants"
import { usePermissions, useUserPermissions } from "permissions/usePermissions"
import { RequestAssigneeSelector } from "./RequestAssigneeSelector"
import { useSaveInternalAssigneesRelationMutation } from "./hooks/useSaveInternalAssigneesRelationMutation"
import { RequestOperators } from "./RequestOperators"
import { receiptGenerationService } from "api/services/receipt-generation/services"
import { ViewRequestRevamp } from "./ViewRequestRevamp"
import { RequestDocumentButton } from "./components/RequestDocumentButton"
import { DOCUMENT_TYPES } from "requests/enums"
import { KiliButton } from "documents/Buttons"
import { DownloadAllFilesButton } from "./ViewRequestRevamp/components/DownloadAllFilesButton"
import { DemandPackageFile } from "./DemandPackageFile"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import { SettlementInfoButton } from "./components/SettlementInfoButton"
import { getFirstPlaintiffName } from "requests/plaintiffUtils"
import { parseError } from "common/errorUtils"

const useStyles = makeStyles()(theme => ({
  header: {
    display: "grid",
    gridTemplateColumns: "4fr 2fr 4fr",
    margin: theme.spacing(2, 0),
  },
  updatedAt: {
    margin: theme.spacing(2, 0),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  statusAndInfo: {
    display: "grid",
  },
  statusAndInfoInline: {
    display: "flex",
    "& > :first-child": {
      margin: theme.spacing(1, 3, 1, 0),
    },
  },
  assignees: {
    marginTop: theme.spacing(4),
    minWidth: theme.spacing(10),
  },
  deleteRequestButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  leftMostButton: {
    marginRight: theme.spacing(2),
  },
  startRequestDocumentButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  downloadAllButton: {
    margin: "auto",
  },
  smallMarginRight: {
    marginRight: theme.spacing(0.5),
  },
  defendantsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    lineHeight: "15px",
    marginBottom: theme.spacing(1),
  },
  defendantContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    margin: theme.spacing(1, 0),
    "&:first-of-type": {
      fontWeight: 700,
    },
  },
  fileLink: {
    textDecoration: "none",
    color: "black",
  },
  highlight: {
    backgroundColor: "yellow",
  },
  collapse: {
    width: "100%",
    borderTop: "1px #e0e0e0 solid",
  },
  collapseHeader: {
    display: "flex",
    flexDirection: "row",
    height: "3rem",
    padding: theme.spacing(1, 0.5),
    fontWeight: 600,
  },
  additionalNotesField: {
    width: "100%",
  },
  clearNotesButton: {
    color: "red",
  },
  fullWidth: {
    width: "100%",
  },
  accordionSummary: {
    fontWeight: "bold",
    padding: theme.spacing(1, 2),
    height: theme.spacing(7.5),
    "&.Mui-expanded": {
      minHeight: theme.spacing(7.5),
    },
  },
  fieldTitle: {
    fontWeight: 700,
  },
  fieldContentContent: {
    margin: theme.spacing(1, 0),
  },
}))

const getPolicyCoverageType = (request, policyType) => {
  const policyTypeLower = policyType.toLowerCase()
  switch (policyTypeLower) {
    case INDIVIDUAL:
      return INDIVIDUAL_POLICY_COVERAGE_TYPE_KEYS[request?.policy_coverage_type]?.display ?? OTHER
    case COMMERCIAL:
      return COMMERCIAL_POLICY_COVERAGE_TYPE_KEYS[request?.policy_coverage_type]?.display ?? OTHER
    default:
      return OTHER
  }
}

export const AccordionView = ({ summary, detailsChild, expanded = true, ...rest }) => {
  const { classes } = useStyles()
  const [overwriteExpanded, setOverwriteExpanded] = useState(null)
  const expand = overwriteExpanded === null ? expanded : overwriteExpanded

  return (
    <Accordion
      {...rest}
      expanded={expand}
      onChange={() => {
        setOverwriteExpanded(overwriteExpanded === null ? !expanded : !overwriteExpanded)
      }}
    >
      <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>{detailsChild}</AccordionDetails>
    </Accordion>
  )
}

const FieldContent = ({ children, title, id, highlight = false }) => {
  const { classes, cx } = useStyles()
  return (
    <Box className={cx(classes.fieldContentContainer, highlight && classes.highlight)} data-test={id}>
      {title && <Box className={classes.fieldTitle}>{title}</Box>}
      {children}
    </Box>
  )
}

const RequestSection = ({ fields }) => {
  const { highlightedFields } = useRequestContext()
  const { classes } = useStyles()

  const splitNewLines = content => {
    if (content && typeof content.split === "function") {
      return content.split("\n")
    } else return [content]
  }

  return fields.map(({ content, key, title, shouldRender = true }) => {
    if (!shouldRender) {
      return
    }

    return (
      <FieldContent
        data-test={"monkey-nuts"}
        key={key}
        highlight={highlightedFields.includes(key)}
        title={title}
        id={key}
      >
        {content &&
          splitNewLines(content).map((value, i) => (
            <div className={classes.fieldContentContent} key={i}>
              {value}
            </div>
          ))}
      </FieldContent>
    )
  })
}

const BasicInfo = ({ request }) => {
  const [searchParams] = useSearchParams()
  const { classes } = useStyles()
  const { user } = useUser()
  const { createBasicPlusEnabled } = useUserPermissions({ suspense: false })

  const requestedDate = formatDate(request?.date_requested)
  const submitter = request?.submitter
  const documentType = searchParams.get("document_type") || request?.type || ""

  const fields = [
    {
      key: DEMAND_TYPE,
      title: "Demand Type",
      shouldRender: createBasicPlusEnabled && request.type,
      content: `${documentType?.charAt(0).toUpperCase() + documentType?.slice(1)}`,
    },
    {
      key: FIRM_NAME,
      title: "Firm Name",
      content: `${request?.firm?.name}`,
    },
    {
      key: INTENDED_CLAIM_AMOUNT,
      title: "Desired Claim Amount",
      content: `${
        _.isNil(request?.intended_claim_amount) ? "-" : amountInDollars(request.intended_claim_amount, 0)
      }`,
    },
    {
      key: DEFENDANTS,
      title: "Defendants",
      shouldRender: request?.defendants?.length,
      content: (
        <Box className={classes.defendantsContainer}>
          <Box className={classes.defendantContainer} mb="2">
            <Box data-test="defendant-type">Type</Box>
            <Box data-test="defendant-name">Name</Box>
            <Box data-test="defendant-gender">Pronoun</Box>
          </Box>

          {request?.defendants?.map((defendant, index) => {
            const defendantType = DEFENDANT_TYPE_KEYS[defendant?.type]?.display ?? "N/A"

            return (
              <Box key={`defendant-${index}`} className={classes.defendantContainer}>
                <Box data-test={`defendant-${index}-type`}>{defendantType}</Box>
                <Box data-test={`defendant-${index}-name`}>{getDefendantName(defendant)}</Box>
                <Box data-test={`defendant-${index}-gender`}>{defendant.gender || "-"}</Box>
              </Box>
            )
          })}
        </Box>
      ),
    },
    {
      key: ADDITIONAL_INFO,
      title: "Additional Information",
      shouldRender: !!request?.additional_information,
      content: `${request?.additional_information}`,
    },
  ]

  if (user.role !== OSF) {
    fields.splice(3, 0, {
      key: SUBMITTER,
      title: "Submitter",
      content: (
        <Box data-test="request-submitter-information">
          {submitter?.first_name} {submitter?.last_name} ({submitter?.email}) on {requestedDate}
        </Box>
      ),
    })
  }

  return (
    <Box className={classes.fullWidth} data-test="basic-information">
      <RequestSection fields={fields} />
    </Box>
  )
}

const CarrierInformation = ({ request }) => {
  const policy_type = POLICY_TYPE_KEYS[request?.policy_type]?.display ?? OTHER
  const policy_coverage_type = getPolicyCoverageType(request, policy_type)
  const adjusterAddress = request?.adjuster_address
  const hasAddress =
    adjusterAddress == null
      ? false
      : adjusterAddress?.street ||
        adjusterAddress?.street_2 ||
        adjusterAddress?.city ||
        adjusterAddress?.state ||
        adjusterAddress?.zip_code

  // TODO: Make this more robust
  const getAddressString = useCallback(address => {
    if (!address) return null
    const { street, street_2, city, state, zip_code } = address

    return `${street ?? ""}${street_2 ? ` ${street_2}` : ""}${city ? `, ${city}` : ""}${
      state ? `, ${state}` : ""
    }${zip_code ? ` - ${zip_code}` : ""}`
  }, [])

  const fields = [
    {
      key: CARRIER_NAME,
      title: "Carrier Name",
      content: request?.carrier_name || "-",
    },
    {
      key: ADJUSTER,
      title: "Recipient Name",
      shouldRender: request?.adjuster_first_name && request?.adjuster_last_name,
      content: `${request?.adjuster_first_name} ${request?.adjuster_last_name}`,
    },
    {
      key: RECIPIENT_EMAIL,
      title: "Recipient Email",
      shouldRender: request?.adjuster_email,
      content: `${request?.adjuster_email}`,
    },
    {
      key: RECIPIENT_TYPE,
      title: "Recipient Type",
      content: request?.recipient_type || "-",
    },
    {
      key: POLICY_NUMBER,
      title: "Policy Number",
      shouldRender: request?.policy_number,
      content: `${request?.policy_number}`,
    },
    {
      key: POLICY_TYPE,
      title: "Policy Type",
      content: `${policy_type}`,
    },
    {
      key: POLICY_COVERAGE_TYPE,
      title: "Policy Coverage Type",
      content: `${policy_coverage_type}`,
    },
    {
      key: POLICY_LIMIT,
      title: "Policy Limit",
      shouldRender: request?.policy_limit,
      content: `${amountInDollars(request?.policy_limit) ?? "N/A"} `,
    },
    {
      key: CLAIM_NUMBER,
      title: "Claim Number",
      shouldRender: request?.claim_number,
      content: `${request?.claim_number}`,
    },
    {
      key: RECIPIENT_ADDRESS,
      title: "Recipient Address",
      shouldRender: hasAddress,
      content: getAddressString(adjusterAddress),
    },
    {
      key: RECIPIENT_FAX,
      title: "Recipient Fax Number",
      shouldRender: request?.adjuster_fax,
      content: `${request?.adjuster_fax}`,
    },
  ]

  return (
    <Box data-test="carrier-information">
      <RequestSection fields={fields} />
    </Box>
  )
}

const CaseFacts = ({ request }) => {
  const fields = [
    {
      key: ASSIGNED_ATTORNEY,
      title: "Assigned Attorney",
      shouldRender: request?.assigned_attorney,
      content: `${request?.assigned_attorney}`,
    },
    {
      key: STATE_OF_INCIDENT,
      title: "State of Incident",
      shouldRender: request?.state_of_incident,
      content: `${request?.state_of_incident}`,
    },
    {
      key: DATE_OF_INCIDENT,
      title: "Date of Incident",
      shouldRender: request?.date_of_incident,
      content: `${request?.date_of_incident}`,
    },
    {
      key: CASE_TYPE,
      title: "Case Type",
      shouldRender: request?.case_type,
      content: `${Object.values(CASE_TYPES).find(({ key }) => key === request?.case_type)?.display ?? OTHER}`,
    },
    {
      key: CASE_FACTS,
      title: "Facts",
      shouldRender: request?.case_facts,
      content: `${request?.case_facts}`,
    },
  ]

  return (
    <Box data-test="case-facts">
      <RequestSection fields={fields} />
    </Box>
  )
}

const PlaintiffInformation = ({ request }) => {
  const [searchParams] = useSearchParams()
  const documentType = searchParams.get("document_type") || request?.type || ""

  return (
    <Box data-test="personal-information">
      <PlaintiffsViewRevamp plaintiffs={request?.plaintiffs} documentType={documentType} />
    </Box>
  )
}

export const FileList = ({
  files,
  fileTypes,
  downloadAllFileName,
  downloadAllEndpoint,
  emptyMessage,
  showEmptyState,
  deleteMethod = noop,
  type = null,
  overrideOrder = true,
  hideFiles = false,
  hiddenFilesMessage = "",
  hideFileType = false,
  hideSummary = false,
  asyncZipAndDownload = false,
}) => {
  const { isPanel } = useRequestContext()
  if (isPanel) {
    return (
      <Box>
        <Box sx={{ fontStyle: "italic", fontSize: "0.75rem" }}>Drag and drop documents from below.</Box>
        {files.map(file => {
          return <FileAttachment pk={file.pk} key={file.pk} name={file.name} url={file.file_url} />
        })}
      </Box>
    )
  }

  return (
    <FileTable
      files={files}
      fileTypes={fileTypes}
      downloadAllFileName={downloadAllFileName}
      downloadAllEndpoint={downloadAllEndpoint}
      emptyMessage={emptyMessage}
      deleteMethod={deleteMethod}
      type={type}
      overrideOrder={overrideOrder}
      hideFiles={hideFiles}
      hiddenFilesMessage={hiddenFilesMessage}
      hideFileType={hideFileType}
      hideSummary={hideSummary}
      showEmptyState={showEmptyState}
      asyncZipAndDownload={asyncZipAndDownload}
    />
  )
}

const FileTable = ({
  files,
  fileTypes,
  downloadAllFileName,
  downloadAllEndpoint,
  emptyMessage,
  deleteMethod = null,
  type = null,
  overrideOrder = true,
  hideFiles = false,
  hiddenFilesMessage = "",
  hideFileType = false,
  hideSummary = false,
  asyncZipAndDownload = false,
  showEmptyState = true,
}) => {
  const { id: requestId } = useParams()
  const [sortDirection, setSortDirection] = useState("desc")
  const theme = useTheme()

  const orderedFiles = overrideOrder
    ? files.sort((a, b) => {
        const aDate = a.date_created || a.external_date_created
        const bDate = b.date_created || b.external_date_created
        return sortDirection === "asc"
          ? compareAsc(new Date(aDate), new Date(bDate))
          : compareDesc(new Date(aDate), new Date(bDate))
      })
    : files

  const columns = [
    {
      id: "name",
      title: "File Name",
      sx: { fontWeight: theme.typography.fontWeightSemiBold },
      cellComponent: file => {
        return (
          <Box
            sx={{
              fontWeight: theme.typography.fontWeightSemiBold,
              wordBreak: "break-word",
            }}
          >
            {file.name}
          </Box>
        )
      },
    },
    {
      id: "type",
      title: "Type",
      sx: { fontWeight: theme.typography.fontWeightSemiBold },
      cellComponent: file => {
        return <Box>{fileTypes[file.type] ?? OTHER}</Box>
      },
      hidden: hideFileType,
    },
    {
      id: "date_created",
      title: "Date Uploaded",
      sx: { fontWeight: theme.typography.fontWeightSemiBold },
      sortable: true,
      cellComponent: file => {
        const dateField = file.date_created || file.external_date_created
        const formattedDate = (dateField && formatDate(dateField, "MMM do, yyy hh:mm")) ?? "N/A"
        return <Box>{formattedDate}</Box>
      },
    },
    {
      id: "number_of_pages",
      title: "Pages",
      sx: { fontWeight: theme.typography.fontWeightSemiBold },
      cellComponent: file => {
        return <Box>{file.number_of_pages ?? "N/A"}</Box>
      },
    },
    {
      id: "actions",
      title: "Download",
      sx: { fontWeight: theme.typography.fontWeightSemiBold },
      cellComponent: file => {
        return (
          <Box>
            <a download href={file.file_url}>
              <IconButton>
                <DownloadIcon />
              </IconButton>
            </a>
            {deleteMethod && deleteMethod !== noop && (
              <IconButton
                onClick={() => {
                  deleteMethod && deleteMethod(file)
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        )
      },
    },
  ]

  const tableSummary = [
    {
      id: "name",
      cellComponent: files => {
        const totalFiles = files?.length ?? 0
        return (
          <Box>
            Total: {totalFiles}{" "}
            {pluralize({
              count: totalFiles,
              noun: "File",
            })}
          </Box>
        )
      },
    },
    {
      id: "type",
      cellComponent: () => {
        return null
      },
      hidden: hideFileType,
    },
    {
      id: "date_created",
      cellComponent: () => {
        return null
      },
    },
    {
      id: "number_of_pages",
      cellComponent: files => {
        const totalPages = files.reduce((total, file) => {
          return total + (file.number_of_pages ?? 0)
        }, 0)
        return <Box>{totalPages}</Box>
      },
    },
    {
      id: "actions",
      cellComponent: () => {
        return null
      },
    },
  ]

  if (orderedFiles.length === 0 && showEmptyState) {
    return <EmptyState message={emptyMessage} />
  }

  return (
    <Box>
      {!!files?.length && downloadAllEndpoint && (
        <DownloadAllFilesButton
          requestId={requestId}
          fileName={downloadAllFileName}
          downloadAllEndpoint={downloadAllEndpoint}
          asyncZipAndDownload={asyncZipAndDownload}
          type={type}
          disabled={hideFiles}
        />
      )}
      {hideFiles ? (
        <Box mt={2} pl={1}>
          {hiddenFilesMessage}
        </Box>
      ) : (
        <Table
          columns={columns}
          records={orderedFiles}
          sortDirection={sortDirection}
          summary={hideSummary ? [] : tableSummary}
          emptyMessage={showEmptyState ? "No Data" : ""}
          sortField={"external_date_created"}
          onSortChange={() => {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc")
          }}
        />
      )}
    </Box>
  )
}

const FileAttachments = ({
  request,
  files,
  type = ALL,
  hideFiles = false,
  hiddenFilesMessage = "",
  hideFileType = false,
}) => {
  const { classes } = useStyles()

  const downloadAllFileName = `${request?.plaintiff_first_name}_${request?.plaintiff_last_name}_files`
  return (
    <Box className={`${classes.centerItems} ${classes.listView}`} data-test="file-attachments">
      <FileList
        files={files}
        downloadAllEndpoint={downloadRequestFiles}
        fileTypes={MEDICAL_FILE_OPTIONS}
        downloadAllFileName={downloadAllFileName}
        emptyMessage="No Files"
        type={type}
        hideFiles={hideFiles}
        hiddenFilesMessage={hiddenFilesMessage}
        hideFileType={hideFileType}
        asyncZipAndDownload={true}
      />
    </Box>
  )
}

export function RequestView({
  request,
  isPanel = false,
  expandedGroups = [REQUEST_PANEL_EXPAND.ALL],
  highlightedFields = [],
  obscureAttachedFiles = false,
  obscureAttachedFilesReason = "",
}) {
  const assets = useRequestAssets()
  request = {
    ...request,
    plaintiffs: request.plaintiffs.map(plaintiff => generatePlaintiff(plaintiff, assets)),
  }

  const files = (request?.files ?? []).filter(file => {
    return (
      file?.missing_exhibit_event === undefined ||
      file?.missing_exhibit_event === null ||
      (file?.missing_exhibit_event !== null && file?.accepted === true)
    )
  })
  const [missingDocFiles, uploadedFiles] = partition(files, file => file?.missing_exhibit_event)

  const showAttachedFiles = uploadedFiles.length > 0
  const showMissingDocFiles = missingDocFiles.length > 0

  const showSection = fields => {
    if (!request) return false
    return Object.entries(request).some(([key, values]) => fields.includes(key) && !isEmpty(values))
  }

  const showCarrierInformation = showSection(CARRIER_SECTION_FIELDS)
  const showPlaintiffInformation = showSection(PLAINTIFF_SECTION_FIELDS)
  const showCaseFacts = showSection(CASE_FACTS_SECTION_FIELDS)

  const expandAll = expandedGroups.includes(ALL)

  return (
    <RequestContextProvider value={{ highlightedFields, isPanel }}>
      <AccordionView
        summary={"Basic Information"}
        detailsChild={<BasicInfo request={request} />}
        expanded={expandAll || expandedGroups.includes(BASIC_INFORMATION)}
      />
      {(showPlaintiffInformation || (request.plaintiffs && request.plaintiffs.length > 0)) && (
        <AccordionView
          summary={"Plaintiff Information"}
          detailsChild={<PlaintiffInformation request={request} />}
          expanded={expandAll || expandedGroups.includes(PLAINTIFF_INFORMATION)}
        />
      )}
      {showCarrierInformation && (
        <AccordionView
          summary={"Carrier Information"}
          detailsChild={<CarrierInformation request={request} />}
          expanded={expandAll || expandedGroups.includes(CARRIER_INFORMATION)}
        />
      )}
      {showCaseFacts && (
        <AccordionView
          summary={"Case Facts"}
          detailsChild={<CaseFacts request={request} assets={assets} />}
          expanded={expandAll || expandedGroups.includes(CASE_FACTS)}
        />
      )}
      {showAttachedFiles && (
        <AccordionView
          summary={"Attached Files"}
          detailsChild={
            <FileAttachments
              request={request}
              files={uploadedFiles}
              type={UPLOADED}
              hideFiles={obscureAttachedFiles}
              hiddenFilesMessage={obscureAttachedFilesReason}
              hideFileType
            />
          }
          expanded={expandAll || expandedGroups.includes(FILES)}
        />
      )}
      {showMissingDocFiles && (
        <AccordionView
          summary={"Missing Documents from Client"}
          detailsChild={<FileAttachments request={request} files={missingDocFiles} type={MISSING_DOCS} />}
          expanded={expandAll || expandedGroups.includes(FILES)}
        />
      )}
    </RequestContextProvider>
  )
}

export function Request({ request: requestData, revisionRequest, hasMissingDocs }) {
  const { id } = useParams()

  const { data: request, error } = useQuery([queryKeys.request, id], fetchRequest_DEPRECATED, {
    onError: error => {
      if (error?.response?.status === 404) {
        showMessage({
          type: "error",
          message:
            "You are not authorized to access this request, it might be unassigned from you or deleted",
          error,
        })
      }
    },
    enabled: !requestData,
    initialData: requestData,
    keepPreviousData: true,
  })
  const { canGenerateReceiptsEnabled } = usePermissions({
    firmId: request.firm?.pk,
    suspense: false,
  })

  const { user } = useUser()
  const { canPostSettlementsEnabled } = usePermissions({ suspense: true, enabled: user.isAuthorized })
  const theme = useTheme()
  const { showMessage } = useHandleMessages()
  const ids = getObjectIds(request?.internal_assignees)
  const [assignedSelf, setAssignedSelf] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showStatusChangeConfirmation, setShowStatusChangeConfirmation] = useState(false)
  const [pendingStatusChange, setPendingStatusChange] = useState(null)
  const [showMarkCompletedError, setShowMarkCompletedError] = useState(false)
  const [receiptGenerated, setReceiptGenerated] = useState(false)
  // It will only download if the backend thinks they're inside the feature flag
  const [receiptDownloaded, setReceiptDownloaded] = useState(false)

  useEffect(() => {
    if (request) {
      const ids = getObjectIds(request.internal_assignees)
      setAssignedSelf(user.isAuthorized && ids.includes(user.id))
    }
  }, [user, request])

  useEffect(() => {
    setAssignedSelf(user.isAuthorized && ids.includes(user.id))
  }, [user, ids])

  useEffect(() => {
    if (showMarkCompletedError && request.demand_package_files.length > 0) {
      setShowMarkCompletedError(false)
    }
  }, [request, showMarkCompletedError])

  const isOSF = user.role === OSF

  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const updateRequestMutation = useMutation(updateRequest_DEPRECATED, {
    onSuccess: data => {
      // Reloads the request with new changes
      queryClient.setQueryData([queryKeys.request, id], data)
    },
    onError: error => {
      if (user.isInternal) {
        showMessage({
          type: "error",
          message: String(error),
          error,
        })
      } else {
        showMessage({
          type: "error",
          message: `Could not update request. Please try again shortly and if your error persists contact ${SUPPORT_EMAIL}.`,
          error,
        })
      }
    },
  })
  const updateFilesMutation = useMutation(updateFiles, {
    onSuccess: data => {
      queryClient.setQueryData([queryKeys.request, id], data)
    },
  })

  const deleteRequestMutation = useMutation(deleteRequest, {
    onSuccess: () => {
      navigate("/requests", { state: { deleted: true } })
    },
  })

  const generateRequestReceiptMutation = useMutation(receiptGenerationService.generateRequestReceipt, {
    onSuccess: ({ data }) => {
      setReceiptGenerated(true)
      if (data) {
        setReceiptDownloaded(true)
        const plaintiffName = getFirstPlaintiffName(request)
        const filenamePieces = ["evenup-statement", plaintiffName.firstName, plaintiffName.lastName].filter(
          v => v
        )
        const fileName = filenamePieces.join("-").toLowerCase()
        downloadFileBlob(fileName, data, { base64: true, extension: "pdf" })
      } else {
        showMessage({
          type: "info",
          message: "Generated Receipt. Please check PandaDocs.",
        })
      }
      amplitudeApm.trackEvent(
        new RequestAnalyticEvent(RequestAnalyticsEventTypes.ReceiptDownloadComplete, {
          request_id: request.pk,
          plaintiff_name: `${request?.plaintiff_first_name} ${request?.plaintiff_last_name}`,
        })
      )
    },
    onError: response => {
      const error = parseError(response)

      showMessage({
        type: "error",
        message: error.userFriendlyMessage,
        error,
      })

      amplitudeApm.trackEvent(
        new RequestAnalyticEvent(RequestAnalyticsEventTypes.ReceiptDownloadError, {
          request_id: request.pk,
          plaintiff_name: `${request?.plaintiff_first_name} ${request?.plaintiff_last_name}`,
        })
      )
    },
  })

  const handleGenerateStatementOfWork = () => {
    const parsedFirmId = request?.firm.pk ? parseInt(request?.firm.pk) : null
    if (parsedFirmId) {
      amplitudeApm.trackEvent(
        new RequestAnalyticEvent(RequestAnalyticsEventTypes.ReceiptDownloadStarted, {
          request_id: request.pk,
          plaintiff_name: `${request?.plaintiff_first_name} ${request?.plaintiff_last_name}`,
        })
      )
      generateRequestReceiptMutation.mutate({ firm_id: parsedFirmId, questionnaire_id: id })
    }
  }

  const saveInternalAssigneesRelationMutation = useSaveInternalAssigneesRelationMutation({
    assignedSelf,
    setAssignedSelf,
  })

  const isReadyForTask = request?.intake_status === INTAKE_STATUSES.readyForTasker
  const { data: caseData } = useQuery([queryKeys.case, id], getCaseByRequestId, DEFAULT_QUERY_PARAMS)

  const { classes, cx } = useStyles()

  const handleUpdateStatus = (status, showConfirmationDialog = true) => {
    if (status === INTAKE_STATUSES.completed && request.demand_package_files.length === 0) {
      setShowMarkCompletedError(true)
      return
    }
    if (showConfirmationDialog) {
      setPendingStatusChange(status)
      setShowStatusChangeConfirmation(true)
    } else {
      updateRequestMutation.mutate({
        requestId: id,
        data: {
          intake_status: status,
        },
      })
    }
  }

  const handleUpdateFiles = () => {
    updateFilesMutation.mutate({
      requestId: id,
    })
  }

  const handleSelfAssign = () => {
    saveInternalAssigneesRelationMutation.mutate({ requestId: id, data: [] })
  }

  const { canOverrideRequestStatusEnabled } = usePermissions()

  if (error) {
    return <GenericError error={error} />
  }

  if (!request) {
    return <></>
  }

  const editDisabled = !canUserEditRequest(user, request)
  const disabledEditMessage = editDisabled ? getEditDisabledMessageByStatus(request.intake_status) : ``

  const canUserDeletePermission = canUserDeleteRequest(user.role)
  const canDeleteRequest =
    canUserDeletePermission ||
    (!canUserDeletePermission && request.intake_status === INTAKE_STATUSES.notRequested)
  const canUserInteractWithDemandPermission = canUserInteractWithDemand(user.role)
  const canUserViewCreditAmountPermission = canUserViewCreditAmount(user.role)

  const demandId = caseData?.pk ?? null

  let statusChangeConfirmationText = [
    `Are you sure you want to change this request's status to ${
      requestStatusProperties(pendingStatusChange, user.role)?.text || ""
    }?`,
  ]
  const isTaskerCompletingTask = user.role === OSF && pendingStatusChange === INTAKE_STATUSES.taskerCompleted
  if (isTaskerCompletingTask) {
    statusChangeConfirmationText.push(
      <Box fontWeight={700}>
        You will immediately lose access to this request and be redirected to the Request list
      </Box>
    )
  }

  const handleTaskerConfirm = () => {
    setShowStatusChangeConfirmation(false)
    updateRequestMutation.mutate(
      {
        requestId: id,
        data: {
          intake_status: pendingStatusChange,
        },
      },
      {
        onSuccess: () => {
          if (pendingStatusChange === INTAKE_STATUSES.completed) {
            amplitudeApm.trackEvent(
              new DemandAnalyticEvent(DemandAnalyticsEventTypes.DemandCompleted, {
                demand_id: demandId,
                request_id: request.pk,
                request_type: request.type,
              })
            )
          }
        },
      }
    )
    setPendingStatusChange(null)
    // moving this into the tasker confirm
    // since we don't want to update the requests list on every request update
    queryClient.invalidateQueries([queryKeys.requests])

    if (isTaskerCompletingTask) {
      navigate(`/requests/`)
    }
  }

  const tags = []
  if (request.revised) {
    tags.push({ type: TAG_LABEL_TYPE.INFO, label: "Revision" })
  }

  if (request.annotation_status && user.isInternal) {
    tags.push(getAnnotationTag(request.annotation_status))
  }

  const inlineStatus = user.role !== OSF && !canOverrideRequestStatusEnabled
  const isMedicalChronology = [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY, DOCUMENT_TYPES.PREMIUM_MEDCHRON].includes(
    request.type
  )

  return (
    <Box>
      <Loading showOnMutation={true} />
      <Box display="flex" mt={2} justifyContent="space-between">
        <Box className={classes.statusAndInfo}>
          <Box>
            <strong>Date Requested: </strong>
            {formatDate(request?.date_requested)}
          </Box>
          <Box className={cx(inlineStatus && classes.statusAndInfoInline)}>
            <RequestStatusBlock
              status={request.intake_status}
              user={user}
              onChange={handleUpdateStatus}
              demandId={demandId}
              editable={canOverrideRequestStatusEnabled}
              display={canOverrideRequestStatusEnabled ? "row" : "column"}
              tags={tags}
              revised={!!request.revised}
              hasMissingDocs={hasMissingDocs}
            />
            <StatusActions
              requestId={id || String(request.pk)}
              status={request.intake_status}
              onUpdateStatus={handleUpdateStatus}
              updatedFilesEnabled={!!request.sharepoint_link}
              onUpdateFiles={handleUpdateFiles}
              revisionRequest={revisionRequest}
            />
          </Box>
        </Box>
        <Box ml="100px" data-test="request-action-buttons">
          {isMedicalChronology && isInternalUser(user.role) && request?.document_id && (
            <KiliButton documentId={request.document_id} style={{ marginRight: theme.spacing(2) }} />
          )}
          {canGenerateReceiptsEnabled && (
            <Tooltip
              tooltipText={
                request.intake_status === INTAKE_STATUSES.completed
                  ? ""
                  : "You’ll be able to download a receipt once your request is complete."
              }
            >
              <span>
                <Button
                  data-test="generate-request-receipt"
                  className={classes.leftMostButton}
                  variant="outlined"
                  color={receiptDownloaded ? "success" : "primary"}
                  onClick={handleGenerateStatementOfWork}
                  disabled={receiptGenerated || request.intake_status !== INTAKE_STATUSES.completed}
                  startIcon={receiptDownloaded ? <DownloadDoneIcon /> : <DownloadIcon />}
                >
                  {receiptDownloaded ? "Receipt Downloaded" : "Download Receipt"}
                </Button>
              </span>
            </Tooltip>
          )}
          {isOSF && isReadyForTask && (
            <Button
              data-test="assign-myself-osf-button"
              className={classes.startRequestDocumentButton}
              variant="outlined"
              onClick={handleSelfAssign}
            >
              Assign to myself
            </Button>
          )}
          {canDeleteRequest && (
            <Button
              data-test="delete-request-button"
              className={classes.deleteRequestButton}
              variant="outlined"
              onClick={() => {
                setShowDeleteConfirmation(true)
              }}
            >
              Delete Request
            </Button>
          )}

          {canSeeEditRequestButton(user, request) && (
            <Tooltip
              tooltipText={disabledEditMessage}
              disabled={editDisabled}
              variant="outlined"
              color="secondary"
              component={Link}
              to={"edit"}
            >
              <Button data-test="edit-request-button">Edit Request</Button>
            </Tooltip>
          )}

          {canPostSettlementsEnabled && request.intake_status === INTAKE_STATUSES.completed && demandId && (
            <SettlementInfoButton demandId={demandId} firmId={request.firm_id} intake={request} />
          )}

          {(canUserInteractWithDemandPermission ||
            (user.role === OSF && request.intake_status === INTAKE_STATUSES.taskerAssigned)) && (
            <RequestDocumentButton request={request} demandId={demandId} />
          )}
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="4fr 5fr" margin={theme.spacing(2, 0)}>
        <RequestAssigneeSelector request={request} handleSelfAssign={handleSelfAssign} />
        <Box />
        <RequestOperators request={request} handleSelfAssign={handleSelfAssign} />
      </Box>
      {showMarkCompletedError && (
        <Alert severity="error" sx={{ marginBottom: theme.spacing(2) }}>
          You must have at least one file attached to the demand before you can mark it complete.
        </Alert>
      )}
      {canUserViewCreditAmountPermission && !!request?.firm?.current_contract && (
        <CreditManagementForm
          // use updated_at in the key to make sure default credit amount also gets updated on save
          // prevents old values from popping up on cancel
          key={`${request?.pk}_${request?.updated_at}`}
          requestId={request?.pk}
          contract={request?.firm?.current_contract}
          documentType={request?.type}
          manualCreditAmount={request?.manual_credit_amount}
          autoCreditAmount={request?.auto_credit_amount}
          creditComments={request?.credit_comments ?? null}
          editable={canUserEditCreditAmount(user.role)}
          filesPageCount={request?.files_page_count}
          plaintiffCount={request?.plaintiffs.length ?? 0}
          verdictSearch={request?.verdict_search}
          revisionFilesPageCount={request?.revision_files_page_count}
          manualFilesPageCount={request?.manual_files_page_count}
          manualPlaintiffCount={request?.manual_plaintiff_count}
          manualVerdictSearch={request?.manual_verdict_search}
          manualRevisionFilesPageCount={request?.manual_revision_files_page_count}
        />
      )}

      {/* Keep Demand Package out since RequestView is used on the form submission page */}
      {!isOSF && !isMedicalChronology && (
        <FileUploadProvider>
          <AccordionView
            data-test="demand-package-section"
            summary={DEMAND_PACKAGE}
            detailsChild={
              <DemandPackageFile
                request={request}
                updateRequestMutation={updateRequestMutation}
                primaryPlaintiff={caseData?.plaintiffs ? caseData.plaintiffs[0] : null}
              />
            }
            expanded={!!request?.demand_package_files?.length}
          />
        </FileUploadProvider>
      )}
      <ViewRequestRevamp request={request} />
      <ConfirmDialog
        open={showDeleteConfirmation}
        onClose={() => {
          setShowDeleteConfirmation(false)
        }}
        onConfirm={() => {
          setShowDeleteConfirmation(false)
          deleteRequestMutation.mutate({
            requestId: request.pk,
          })
        }}
        title="Delete Request?"
        body="Are you sure you want to delete this request? Once deleted, the request will not be available for any user."
        confirmText="Delete"
      />

      <ConfirmDialog
        open={showStatusChangeConfirmation}
        onClose={() => setShowStatusChangeConfirmation(false)}
        onConfirm={handleTaskerConfirm}
        title={"Change Status?"}
        body={statusChangeConfirmationText}
      />
    </Box>
  )
}
