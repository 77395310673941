import { Suspense } from "react"
import { useQuery } from "@tanstack/react-query"
import { ErrorBoundary } from "react-error-boundary"

import AutoCompleteFilter from "./AutoCompleteFilter"
import { getFirms } from "../../../api"
import { STALE_TIMEOUT, queryKeys } from "../../../react-query/constants"
import { FILTER_OPTION_ALL } from "../../../app/constants"

function FirmFilterComponent({ onChange, value, className = "" }) {
  const { data } = useQuery([queryKeys.firms], () => getFirms(), {
    staleTime: STALE_TIMEOUT.DEFAULT,
    suspense: true,
    meta: { disableLoader: true },
  })

  const options = [FILTER_OPTION_ALL]

  if (Array.isArray(data)) {
    options.push(
      ...data.map(field => {
        return {
          key: field.pk,
          display: field.name,
        }
      })
    )
  }

  const valueOption = options.find(option => option.key === value)

  return (
    <AutoCompleteFilter
      value={valueOption}
      onChange={onChange}
      options={options}
      label="Firm"
      className={className}
      id="firm-filter"
    />
  )
}

const FirmFilter = ({ onChange, value, className = "" }) => {
  return (
    <ErrorBoundary fallback={<></>}>
      <Suspense
        fallback={
          <AutoCompleteFilter className={className} label="Firm" value={null} options={[]} loading disabled />
        }
      >
        <FirmFilterComponent onChange={onChange} value={value} className={className} />
      </Suspense>
    </ErrorBoundary>
  )
}

export { FirmFilter as default }
