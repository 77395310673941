import Box from "@mui/material/Box"
import { Theme } from "@mui/material/styles"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { APP_CONTENT_ID, APP_HEADER_ID, APP_FOOTER_ID, APP_ID } from "app/constants"
import Alert, { alertClasses } from "@mui/material/Alert"
import { CSSProperties } from "react"
import { Dialog } from "@mui/material"

export const disableHeaderFooterStyles = css({
  html: {
    minHeight: "100vh",
  },

  [`#${APP_ID}`]: {
    paddingTop: 0,
    paddingBottom: 0,
    position: "static",
    minHeight: "auto",
  },
  [`#${APP_HEADER_ID}`]: {
    display: "none",
  },
  [`#${APP_FOOTER_ID}`]: {
    display: "none",
  },
  [`#${APP_CONTENT_ID}`]: {
    marginTop: 0,
    padding: 0,
    height: "100%",
  },
})

export const ActionButton = styled(Box)(({ theme }) => ({
  color: theme.palette.info.main,
  margin: theme.spacing(0, 0.5),
  "&:hover": {
    cursor: "pointer",
  },
}))

export const MainTitle = styled(Box)(({ theme }) => ({
  fontSize: "22px",
  lineHeight: 1.55,
  fontWeight: "bold",
  marginBottom: theme.spacing(1),
}))

export const Subtitle = styled(Box)(({ theme }) => ({
  fontWeight: 200,
  fontSize: "18px",
  marginBottom: theme.spacing(2),
  letterSpacing: "-0.36px",
}))

export const HeaderLeftWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
}))

export const VersionUpdateNotification = styled(Alert)(({ theme }) => ({
  alignItems: "center",

  [`& .${alertClasses.message}`]: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: 0,

    "& button": {
      fontWeight: 600,
      color: theme.palette.common.white,
    },
  },

  [`& .${alertClasses.action}`]: {
    paddingTop: 0,
  },
}))

function getServiceEnvBackground(env: string, theme: Theme): CSSProperties["backgroundColor"] {
  switch (env.toLowerCase()) {
    case "prod":
      return "none"
    case "dev":
      return theme.palette.info.main
    case "stage":
      return theme.palette.warning.dark
    case "preprod":
      return theme.palette.error.light
    default:
      return theme.palette.primary.main
  }
}

export const EnvNotification = styled(Box)<{ env: string }>(({ theme, env }) => ({
  position: "absolute",
  top: 0,
  left: "50%",
  boxSizing: "border-box",
  padding: theme.spacing(0, 3),
  fontSize: 10,
  display: "flex",
  justifyContent: "end",
  gap: theme.spacing(3),
  alignSelf: "center",
  background: getServiceEnvBackground(env, theme),
  color: theme.palette.common.white,
  transform: "translateX(-50%)",
}))

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& * .MuiDialog-paper": {
    padding: theme.spacing(4, 3),
    borderRadius: `12px`,
    width: "1000px",
  },
}))
