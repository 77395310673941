import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FileUploaderContext, FileUploaderContextType } from "../context"
import { FileUploaderData } from "../uploader-state/types"
import { FileUploadView, FileUploadViewProps } from "./FileUploadView"

export type FileUploaderProviderProps = {
  viewProps?: Omit<FileUploadViewProps, "data">
} & React.PropsWithChildren

export function FileUploadProvider({ children, viewProps }: FileUploaderProviderProps): JSX.Element {
  const [state, setState] = useState<FileUploaderData>({})
  const actualState = useRef(state)
  const updateState = useCallback(
    (stateUpdates: FileUploaderData) => {
      setState({
        ...actualState.current,
        ...stateUpdates,
      })
    },
    [actualState, setState]
  )
  const context = useMemo<FileUploaderContextType>(() => ({ updateState }), [updateState])

  useEffect(() => {
    actualState.current = state
  }, [state])

  return (
    <FileUploaderContext.Provider value={context}>
      {children}
      <FileUploadView data={state} {...viewProps} />
    </FileUploaderContext.Provider>
  )
}
