import { handleEmptyResponse } from "api/utils"
import { withResponseSerializer } from "api/withSerializers"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import { VariablesServiceDeserializer } from "./serializers"
import { VariablesDto } from "./types"
import { getQuery } from "../utils"

enum VARIABLES_API_PATHS {
  LIBRARY = "library/variable/default_variables",
  CASE = "case",
  CASE_VARIABLES = "variables",
}

class VariablesService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(path: VARIABLES_API_PATHS | (VARIABLES_API_PATHS | PrimaryKey)[]): string {
    const pathParts = Array.from<string | PrimaryKey>([""]).concat(path)
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getDefaultCaseVariables = withResponseSerializer(
    VariablesServiceDeserializer.fromCategoryJSON,
    async () => {
      const variables = await handleEmptyResponse(
        this.apiService.get<null, VariablesDto>(null, this.getPath(VARIABLES_API_PATHS.LIBRARY))
      )

      return variables
    }
  )

  getCaseVariables = withResponseSerializer(
    VariablesServiceDeserializer.fromCategoryJSON,
    async ({ caseId, plaintiffId }: { caseId: PrimaryKey; plaintiffId?: number }) => {
      const query = getQuery({ ["plaintiff_id"]: [plaintiffId] })
      const variables = await handleEmptyResponse(
        this.apiService.get<null, VariablesDto>(
          null,
          this.getPath([VARIABLES_API_PATHS.CASE, caseId, VARIABLES_API_PATHS.CASE_VARIABLES]),
          query
        )
      )

      return variables
    }
  )
}

export const variablesService = new VariablesService(apiService)
