export const enum DOCUMENT_STRUCTURE_ITEM_TYPE {
  SECTION = "section",
  BLOCK = "block",
}

export const enum DOCUMENT_STRUCTURE_BLOCK_TYPE {
  HEADING = "heading",
  TEMPLATE = "template",
  COMPONENT = "component",
}

export const enum HEADING_TYPE {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  SUBTITLE = "subtitle",
}
