import React, { useCallback, useEffect, useState } from "react"
import Grow from "@mui/material/Grow"
import { Attribute, Attributes as AttributesType } from "common/types/attributes"
import { AttributeFilter } from "./AttributeFilter"
import { getAttributeValues } from "./utils"
import { AttributeFiltersData, AttributeFilterValue } from "./types"
import { FilterButtons, FiltersBox, ResetButton } from "./styled"
import { isEmpty, isEqual } from "lodash"

export interface AdditionalFilter {
  order: number
  component: React.ReactNode
}

interface AttributesFilterProps {
  attributes: AttributesType
  values?: AttributeFiltersData
  onChange?: (values: AttributeFiltersData) => void
  onReset?: () => void
  error?: boolean
  showResetButton?: boolean
  children?: React.ReactNode
  additionalFilters?: AdditionalFilter[]
}

export function AttributesFilter({
  attributes,
  values,
  onChange,
  onReset,
  error,
  children,
  showResetButton = true,
  additionalFilters = [],
}: AttributesFilterProps): JSX.Element {
  const [attributeValues, setAttributeValues] = useState(getAttributeValues(attributes, values))
  const items = attributes.getAttributesList(attributeValues)

  const setNewAttributeValues = useCallback(
    (changedValues: Partial<AttributeFiltersData>) => {
      setAttributeValues(currentValues => {
        const mergedValues = (
          isEmpty(changedValues) ? changedValues : { ...currentValues, ...changedValues }
        ) as AttributeFiltersData
        const nextValues = getAttributeValues(attributes, mergedValues)
        const hasChanged = !isEqual(currentValues, nextValues)

        if (hasChanged && onChange) {
          onChange(nextValues)
        }

        return hasChanged ? nextValues : currentValues
      })
    },
    [onChange, attributes, setAttributeValues]
  )

  useEffect(() => {
    setNewAttributeValues(getAttributeValues(attributes, values))
  }, [setNewAttributeValues, attributes, values])

  const setItemValue = useCallback(
    (attribute: Attribute, value: AttributeFilterValue) => {
      setNewAttributeValues({
        [attribute.id]: value,
      })
    },
    [setNewAttributeValues]
  )

  const resetValues = useCallback(() => {
    setNewAttributeValues(getAttributeValues(attributes))
    onReset?.()
  }, [setNewAttributeValues, attributes, onReset])

  const mainFilters = items.map(item => (
    <Grow key={item.id} exit={false} unmountOnExit timeout={250}>
      <AttributeFilter
        error={error}
        attribute={item}
        value={attributeValues[item.id]}
        onChange={setItemValue}
      />
    </Grow>
  ))

  const filters = [{ component: mainFilters, order: 1 }, ...additionalFilters]
    .sort((a, b) => a.order - b.order)
    .map(({ component }) => component)

  return (
    <FiltersBox>
      <>{children}</>
      {filters}
      {showResetButton && (
        <FilterButtons>
          <ResetButton small onClick={resetValues}>
            Reset
          </ResetButton>
        </FilterButtons>
      )}
    </FiltersBox>
  )
}
