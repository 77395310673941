import { useCallback } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import useUser from "hooks/useUser"
import { revisionEventService } from "api/services/revision-event"
import { userService } from "api/services/users"
import { canUserCancelRevisionRequest } from "requests/permissions/requestAction"
import { INTAKE_STATUSES } from "requests/constants"
import { FileUploadProvider } from "common/file-uploader"
import { RevisionRequestHeader } from "./RevisionRequestHeader"
import { RevisionContainer } from "./styled"
import { RevisionRequest } from "./RevisionRequest"
import { getRevisionRequestStateByIntakeStatus } from "./utils"
import { CancelRevisionRequest } from "./CancelRevisionRequest"
import { RevisionList } from "./RevisionList"

interface RevisionRequestViewProps {
  requestId: string
  requestStatus: INTAKE_STATUSES
}

export function RevisionRequestView({ requestId, requestStatus }: RevisionRequestViewProps): JSX.Element {
  const queryClient = useQueryClient()
  const { user } = useUser()

  const handleChange = useCallback(() => {
    queryClient.invalidateQueries([queryKeys.requestRevisionEvents])
    queryClient.invalidateQueries([queryKeys.request])
  }, [queryClient])

  const { data: events, isSuccess: areEventsLoaded } = useQuery(
    [queryKeys.requestRevisionEvents, requestId],
    () => revisionEventService.getRevisionRequestEvents({ requestId })
  )
  const { data: trialData, isLoading: isCheckingForTrial } = useQuery([queryKeys.trial, user.id], () =>
    userService.getTrialData(null)
  )

  const isLoading = !areEventsLoaded || isCheckingForTrial
  const activeEvent = events && events.activeEvent
  const state = getRevisionRequestStateByIntakeStatus(Boolean(activeEvent), requestStatus)
  const canCancel = Boolean(activeEvent) && canUserCancelRevisionRequest(user.role, state)

  return (
    <FileUploadProvider>
      <RevisionContainer>
        <RevisionRequestHeader state={state} user={user}>
          {canCancel && activeEvent ? (
            <CancelRevisionRequest revisionRequest={activeEvent.revisionRequest} requestId={requestId} />
          ) : null}
        </RevisionRequestHeader>
        {!isLoading && (
          <RevisionRequest
            requestId={requestId}
            state={state}
            event={activeEvent}
            onChange={handleChange}
            showEventMessage={user.isExternal}
            isTrialing={trialData?.isTrialing}
          />
        )}
        {areEventsLoaded ? <RevisionList events={events} /> : null}
      </RevisionContainer>
    </FileUploadProvider>
  )
}
