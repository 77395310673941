import { useEffect, useState } from "react"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import Container from "@mui/material/Container"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import CircularProgress from "@mui/material/CircularProgress"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { useForm } from "react-hook-form"
import { makeStyles } from "tss-react/mui"

import {
  getCase,
  getExhibitsOrder,
  deleteExhibitGroup,
  deleteExhibitGroupsBySection,
  updateExhibitsOrder,
  updateShouldCombineExhibits,
  fetchPlaintiffInfoForCase,
} from "../api"

import { useFirm } from "hooks/useFirm"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"

import { SortableNestedList } from "../common/nested-list/SortableNestedList"
import { queryKeys, SILENT_QUERY_PARAMS } from "../react-query/constants"
import { SECTIONS } from "./Exhibits/enums"
import { deserializeExhibits, serializeExhibits, sortAndGroupExhibitsByOptions } from "./Exhibits/utils"
import { canDrag, canDrop, canDropAsChild } from "./Exhibits/dragDropUtils"
import { MainTitle } from "../app/styled"
import { ExhibitOrganizationSelectors } from "settings/Firm/ExhibitOrganizationSelectors"
import { EXHIBIT_GROUPING_VALUES, EXHIBIT_SORTING_VALUES } from "settings/Firm/enums"
import { ExhibitListItemEmptyPlaceholder } from "./Exhibits/ExhibitListItemEmptyPlaceholder"
import {
  ExhibitListItemComponent,
  ExhibitListItemContentComponent,
} from "./Exhibits/ExhibitListItemComponent"
import { caseService } from "../api/services/case"
import { Loading } from "common"
import * as Sentry from "@sentry/react"

const useStyles = makeStyles()(theme => ({
  smallMarginRight: {
    marginRight: theme.spacing(2),
  },
}))

const SortAndGroupContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingBottom: theme.spacing(3),
  borderBottom: `2px solid ${theme.palette.grey[500]}`,
}))

const SelectorContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(2),
  "& > *": {
    marginRight: theme.spacing(2),
  },
}))

const SortingLoaderContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, "auto", 2),
}))

const SortingLabel = styled(Box)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: "400",
  marginBottom: theme.spacing(1),
}))

const DEFAULT_SORTING_VALUE = EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS
const DEFAULT_GROUPING_VALUE = EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE

const ExhibitPage = () => {
  const { id: caseId } = useParams()
  const { showErrorMessage } = useHandleMessages()
  const queryClient = useQueryClient()
  const [caseFirm, setCaseFirm] = useState(null)
  const [isSorting, setIsSorting] = useState(false)
  const [combineExhibits, setCombineExhibits] = useState(false)
  const [addTitlePage, setAddTitlePage] = useState(false)
  const { classes } = useStyles()
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  const { isLoading, data: exhibitOrder } = useQuery(
    [queryKeys.exhibits, caseId],
    async () => getExhibitsOrder({ caseId }),
    {
      ...SILENT_QUERY_PARAMS,
      onSuccess: async data => {
        setExhibits(deserializeExhibits(data.data))
      },
    }
  )

  const [exhibits, setExhibits] = useState(exhibitOrder ? deserializeExhibits(exhibitOrder.data) : null)

  useQuery([queryKeys.case, caseId], getCase, {
    onSuccess: data => {
      setCaseFirm(data?.firm?.pk ?? null)
      setCombineExhibits(data?.combine_exhibits ?? false)
      setAddTitlePage(
        data?.add_title_page === null && data?.firm
          ? data?.firm.enable_add_title_page
          : !!data?.add_title_page
      )
    },
  })

  const { data: plaintiffs } = useQuery(
    [queryKeys.plaintiffs + "-global", caseId],
    () => fetchPlaintiffInfoForCase(caseId),
    {
      ...SILENT_QUERY_PARAMS,
      enabled: multiPlaintiffEnabled,
    }
  )

  const { firm } = useFirm(caseFirm)

  const { control, reset, watch } = useForm({
    defaultValues: {
      exhibit_sorting_option: firm?.exhibit_sorting_option ?? DEFAULT_SORTING_VALUE,
      exhibit_grouping_option: firm?.exhibit_grouping_option ?? DEFAULT_GROUPING_VALUE,
    },
    mode: "onChange",
  })

  useEffect(() => {
    reset({
      exhibit_sorting_option: firm?.exhibit_sorting_option ?? DEFAULT_SORTING_VALUE,
      exhibit_grouping_option: firm?.exhibit_grouping_option ?? DEFAULT_GROUPING_VALUE,
    })
  }, [firm, reset])

  const sortingOption = watch("exhibit_sorting_option")
  const groupingOption = watch("exhibit_grouping_option")

  const handleSortingError = error => {
    showErrorMessage({
      message:
        "There was an error during the sorting and grouping of exhibits. Please try again or contact Engineering if the issue continues.",
      error,
    })
  }

  const { mutateAsync: updateExhibitOrder } = useMutation(updateExhibitsOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.exhibits])
    },
    onError: handleSortingError,
    onSettled: () => {
      setIsSorting(false)
    },
  })

  const { mutateAsync: callDeleteExhibitGroup } = useMutation(deleteExhibitGroup, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.exhibits]),
  })

  const { mutateAsync: deleteExhibitGroupsBySectionMutation } = useMutation(deleteExhibitGroupsBySection, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.exhibits]),
    onError: () => {
      setIsSorting(false)
      handleSortingError()
    },
  })

  const { mutateAsync: setCombineExhibitsMutation } = useMutation(updateShouldCombineExhibits, {
    onSuccess: data => {
      setCombineExhibits(data.results?.combine_exhibits)
    },
  })

  const { mutateAsync: mutateAddTitlePage } = useMutation(
    async (...args) => {
      return await caseService.updateAddTitlePage(...args)
    },
    {
      onSuccess: data => {
        setAddTitlePage(!!data.addTitlePage)
      },
    }
  )

  const updateCombineExhibits = value => {
    return setCombineExhibitsMutation({ caseId, ce: value })
  }

  const handleAddTitlePage = value => {
    return mutateAddTitlePage({
      data: { addTitlePage: value },
      caseId,
    })
  }

  const deleteGroup = groupItem => {
    if (!groupItem.isNew) {
      callDeleteExhibitGroup({ caseId, groupId: groupItem.pk })
    }
    setExhibits(currentExhibits => {
      const groupSection = groupItem.section
      const sectionExhibitsIndex = currentExhibits.findIndex(value => value.section == groupSection)
      const sectionExhibits = currentExhibits[sectionExhibitsIndex]
      const sectionIndex = sectionExhibits.children.findIndex(
        value => (value.type == "group") & (value.pk == groupItem.pk)
      )
      sectionExhibits.children.splice(sectionIndex, 1)
      currentExhibits[sectionExhibitsIndex] = sectionExhibits

      return currentExhibits
    })
  }

  const handleExhibitSectionChange = updatedItem => {
    const itemIndex = exhibits.findIndex(exhibit => exhibit.pk === updatedItem.pk)
    const updatedExhibits = [...exhibits]

    updatedExhibits[itemIndex] = updatedItem

    setExhibits(updatedExhibits)
  }

  const handleExhibitGroupChange = async updatedItem => {
    for (const section of exhibits) {
      for (const [itemIndex, item] of section.children.entries()) {
        if (item.pk === updatedItem.pk) {
          section.children[itemIndex] = updatedItem
          const updatedExhibits = [...exhibits]
          setExhibits(updatedExhibits)
          const data = serializeExhibits(updatedExhibits)
          await updateExhibitOrder({ caseId, data })

          return
        }
      }
    }
  }

  const updateExhibits = async updatedExhibits => {
    const data = serializeExhibits(updatedExhibits)
    const result = await updateExhibitOrder({ caseId, data })

    setExhibits(deserializeExhibits(result.data))
  }

  const handleSortAndGroupClick = async () => {
    try {
      setIsSorting(true)
      const sortedExhibits = sortAndGroupExhibitsByOptions(
        exhibits,
        sortingOption,
        groupingOption,
        plaintiffs ?? []
      )

      await deleteExhibitGroupsBySectionMutation({ caseId, section: SECTIONS.PROVIDERS })
      updateExhibits(sortedExhibits)

      // Changing the value of the combine exhibits based on the dropdown
      if (groupingOption === EXHIBIT_GROUPING_VALUES.ONE_FILE && !combineExhibits) {
        updateCombineExhibits(true)
      } else if (groupingOption !== EXHIBIT_GROUPING_VALUES.ONE_FILE && combineExhibits) {
        updateCombineExhibits(false)
      }
    } catch (err) {
      Sentry.captureException(err)
      showErrorMessage({ message: "There was an error sorting the exhibits. Please try again." })
    } finally {
      setIsSorting(false)
    }
  }

  if (isLoading) {
    return <Loading />
  }

  const hasSortingAndGroupingOptions = sortingOption && groupingOption

  return (
    <Container maxWidth="lg" data-test="exhibits-list">
      {hasSortingAndGroupingOptions && (
        <>
          <SortAndGroupContainer>
            <SortingLabel>
              Select the sorting and combining option below and click &#8220;Sort And Combine Exhibits&#8221;.
              The firm defaults are pre-selected.
            </SortingLabel>
            <SelectorContainer>
              <ExhibitOrganizationSelectors
                control={control}
                watch={watch}
                className={classes.smallMarginRight}
              />

              <Box mt="auto" mb="auto">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSortAndGroupClick}
                  disabled={isSorting}
                >
                  Sort And Combine Exhibits
                </Button>
              </Box>
            </SelectorContainer>
          </SortAndGroupContainer>

          <Box pt={2}>
            <FormControlLabel
              data-test="reorder-exhibits"
              control={
                <Switch
                  checked={combineExhibits ? true : addTitlePage}
                  color="primary"
                  disabled={combineExhibits}
                />
              }
              label="Add Title Page to Every Exhibit"
              onChange={event => handleAddTitlePage(event.target.checked)}
            />
          </Box>
        </>
      )}

      <Box display="flex" flexDirection="column">
        {isSorting ? (
          <SortingLoaderContainer>
            <CircularProgress size="10rem" />
            <MainTitle mt={2}>Sorting Exhibits</MainTitle>
          </SortingLoaderContainer>
        ) : (
          <SortableNestedList
            items={exhibits}
            uniqueKey="targetId"
            onUpdate={updateExhibits}
            canDrag={canDrag}
            canDrop={canDrop}
            canDropAsChild={canDropAsChild}
            ItemComponent={ExhibitListItemComponent}
            ItemContentComponent={ExhibitListItemContentComponent}
            PlaceholderComponent={ExhibitListItemEmptyPlaceholder}
            contentProps={{
              onSectionChange: handleExhibitSectionChange,
              onGroupChange: handleExhibitGroupChange,
              onGroupDelete: deleteGroup,
            }}
          />
        )}
      </Box>
    </Container>
  )
}

export default ExhibitPage
