import { theme } from "app/theme"

export const BASE_DPI = 72 // default dpi when pdf is rendered

export const OPTIMAL_DPI = 96

export const SCALE = OPTIMAL_DPI / BASE_DPI

export const US_LETTER_SIZE = {
  WIDTH: 8.5 * OPTIMAL_DPI, // 8.5 inches width,
  HEIGHT: 11 * OPTIMAL_DPI, // 11 inches height
} as const

export const PAGE_SPACING = parseInt(theme.spacing(1))

export const DEFAULT_PAGE_HEIGHT = US_LETTER_SIZE.HEIGHT + PAGE_SPACING * 2

export const PREVIEW_SCALE = 100 / US_LETTER_SIZE.WIDTH

export const PREVIEW_SIZE = {
  WIDTH: Math.round(US_LETTER_SIZE.WIDTH * PREVIEW_SCALE),
  HEIGHT: Math.round(US_LETTER_SIZE.HEIGHT * PREVIEW_SCALE),
  PADDING: parseInt(theme.spacing(1)),
  MARGIN: 0,
} as const

export const PREVIEW_PAGE_INDICATOR_SIZE = {
  HEIGHT: parseInt(theme.spacing(1.5)),
  MARGIN: parseInt(theme.spacing(0.5)),
} as const

export const PREVIEW_ITEM_HEIGHT = PREVIEW_SIZE.HEIGHT + PREVIEW_SIZE.PADDING * 2 + PREVIEW_SIZE.MARGIN

export const PREVIEW_ITEM_INDICATOR_HEIGHT =
  PREVIEW_PAGE_INDICATOR_SIZE.HEIGHT + PREVIEW_PAGE_INDICATOR_SIZE.MARGIN

export const PAGES_TO_FETCH_ANNOTATIONS = 10

export const ANNOTATIONS_FETCH_THRESHOLD = 5
