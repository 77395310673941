import { BaseAnalyticsEvent } from "../types"

export enum LoginAnalyticsEventTypes {
  LoginSuccess = "Login Success",
  LoginFailed = "Login Failed",
  LoginLinkSent = "Login Link Sent",
  LoginPageViewed = "Login Page Viewed",
}

export class LoginAnalyticEvent<T extends LoginAnalyticsEventTypes> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: LoginAnalyticsEventDetails[T]
  ) {
    super()
  }
}

// Add new request event types here
export type LoginAnalyticsEventDetails = {
  [LoginAnalyticsEventTypes.LoginSuccess]: {
    firm_id?: string
    firm_name?: string
    login_source?: string
    user_email: string
    user_id: number
    login_id: Nullable<string>
  }
  [LoginAnalyticsEventTypes.LoginFailed]: {
    failure_reason: string
  }
  [LoginAnalyticsEventTypes.LoginLinkSent]: {
    request_id: string
    status_code: number
  }
  [LoginAnalyticsEventTypes.LoginPageViewed]: {
    login_id: string
    redirectTo: string
    search: Nullable<string>
  }
}
