import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { noop } from "lodash"

export type CaseFactsSaveHandler = () => void | Promise<void>

interface CaseFactsSaveHandlersContextType {
  addSaveCallback(callback: CaseFactsSaveHandler): void
  removeSaveCallback(callback: CaseFactsSaveHandler): void
  getSaveHandlers(): CaseFactsSaveHandler[]
}

const CaseFactsSaveHandlersContext = createContext<CaseFactsSaveHandlersContextType>({
  addSaveCallback: noop,
  removeSaveCallback: noop,
  getSaveHandlers: () => [],
})

export function useCaseFactsSave(onSave: CaseFactsSaveHandler): void {
  const context = useContext(CaseFactsSaveHandlersContext)

  const onSaveRef = useRef(onSave)
  onSaveRef.current = onSave

  const handleSave = useCallback(async () => {
    return onSaveRef.current()
  }, [])

  useEffect(() => {
    context.addSaveCallback(handleSave)

    return () => context.removeSaveCallback(handleSave)
  }, [context, handleSave])
}

export function useCaseFactsSaveHandlers(): CaseFactsSaveHandlersContextType["getSaveHandlers"] {
  const { getSaveHandlers } = useContext(CaseFactsSaveHandlersContext)

  return getSaveHandlers
}

const CaseFactsSaveProvider = React.memo(function CaseFactsSaveProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const callbacks = useRef<Set<CaseFactsSaveHandler>>(new Set())

  const contextValue = useMemo<CaseFactsSaveHandlersContextType>(
    () => ({
      addSaveCallback: callback => callbacks.current.add(callback),
      removeSaveCallback: callback => callbacks.current.delete(callback),
      getSaveHandlers: () => Array.from(callbacks.current),
    }),
    []
  )

  return (
    <CaseFactsSaveHandlersContext.Provider value={contextValue}>
      {children}
    </CaseFactsSaveHandlersContext.Provider>
  )
})

export function withCaseFactsSaveProvider<Props>(
  Component: React.ComponentType<Props>
): React.FunctionComponent<Props> {
  return function WithCaseFactsSaveProvider(props: Props) {
    return (
      <CaseFactsSaveProvider>
        <Component {...(props as JSX.IntrinsicAttributes & Props)} />
      </CaseFactsSaveProvider>
    )
  }
}
