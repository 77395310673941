import { noop } from "lodash"
import React, { Dispatch } from "react"
import { TemplateAction } from "./templatesActions"
import { LibraryTabState } from "./types"

export const INITIAL_TEMPLATES_TAB_STATE: LibraryTabState = {
  expandedRowIds: [],
  editingRowId: null,
  creatingData: undefined,
  page: null,
}

export const LibraryTabStateContext = React.createContext<{
  state: LibraryTabState
  dispatch: Dispatch<TemplateAction>
}>({ state: INITIAL_TEMPLATES_TAB_STATE, dispatch: noop })
