import { useCallback } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useSubscribe } from "message-broker/useSubscribe"
import { MESSAGE_TOPIC } from "message-broker/topics"
import {
  getExhibitPreviewPath,
  getPdfPreviewPath,
  getUserExhibitPartitionPreviewPath,
  getUserExhibitPreviewPath,
} from "./utils"
import {
  BasePublishPayload,
  PreviewExhibitPayload,
  PreviewUserExhibitPartitionPayload,
  PreviewUserExhibitPayload,
  RenderPdfPayload,
} from "message-broker/types"
import { usePinState } from "hooks/usePinState"

export function PreviewPage(): JSX.Element {
  const [pinned, togglePinned] = usePinState()
  const navigate = useNavigate()
  const { showErrorMessage } = useHandleMessages()

  const tryFocusPage = useCallback(
    (payload: BasePublishPayload) => {
      if (
        !window.opener ||
        !window.opener.focusChildWindow ||
        typeof window.opener.focusChildWindow !== "function"
      ) {
        togglePinned()
        throw new Error("Could not find parent window to focus page.")
      }

      if (payload.clientUuid && window.opener.CLIENT_UUID !== payload.clientUuid) {
        window.opener.closeChildWindow?.(CLIENT_UUID)
        throw new Error("Focus is available only from parent is active tab.")
      }

      window.opener.focusChildWindow(CLIENT_UUID)
    },
    [togglePinned]
  )

  useSubscribe(
    MESSAGE_TOPIC.RENDER_PDF,
    async ({ data }) => {
      const payload = (data.payload ?? {}) as RenderPdfPayload
      const path = getPdfPreviewPath(payload)

      tryFocusPage(payload)

      if (path) {
        navigate(path.route)
      } else {
        showErrorMessage("Unable to render pdf file: filename or url is not provided.")
      }
    },
    { enabled: !pinned }
  )

  useSubscribe(
    MESSAGE_TOPIC.PREVIEW_EXHIBIT,
    async ({ data }) => {
      const payload = (data.payload ?? {}) as PreviewExhibitPayload
      const path = getExhibitPreviewPath(payload)

      tryFocusPage(payload)

      if (path) {
        navigate(path.route)
      } else {
        showErrorMessage("Unable to preview exhibit")
      }
    },
    { enabled: !pinned }
  )

  useSubscribe(
    MESSAGE_TOPIC.PREVIEW_USER_EXHIBIT,
    async ({ data }) => {
      const payload = (data.payload ?? {}) as PreviewUserExhibitPayload
      const path = getUserExhibitPreviewPath(payload)

      tryFocusPage(payload)

      if (path) {
        navigate(path.route)
      } else {
        showErrorMessage("Unable to preview exhibit")
      }
    },
    { enabled: !pinned }
  )

  useSubscribe(
    MESSAGE_TOPIC.PREVIEW_USER_EXHIBIT_PARTITION,
    async ({ data }) => {
      const payload = (data.payload ?? {}) as PreviewUserExhibitPartitionPayload
      const path = getUserExhibitPartitionPreviewPath(payload)

      tryFocusPage(payload)

      if (path) {
        navigate(path.route)
      } else {
        showErrorMessage("Unable to preview exhibit")
      }
    },
    { enabled: !pinned }
  )

  return <Outlet />
}
