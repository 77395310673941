import { createContext, useContext } from "react"
import { RequestFormContextValues } from "./RequestForm/types"

export const RequestContext = createContext<RequestFormContextValues>({} as RequestFormContextValues)

export const RequestContextProvider = RequestContext.Provider

export function useRequestContext(): RequestFormContextValues {
  const context = useContext(RequestContext)

  if (!context) {
    throw new Error("useRequestContext must be used within a RequestContextProvider")
  }

  return context
}
