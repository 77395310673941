import { Variable } from "common/types/variables"
import { useMemo } from "react"
import { Provider } from "../types"

function getExhibitVariables(exhibits: Provider["exhibits"]): Variable[] {
  return exhibits
    ? exhibits.map((exhibit, index) => ({
        category: "Exhibits",
        type: "text",
        name: `Exhibit ${index + 1}`,
        value: exhibit.name,
      }))
    : []
}

export function useProviderVariables(provider: Provider): Variable[] {
  const variables = useMemo(() => getExhibitVariables(provider.exhibits), [provider.exhibits])

  return variables
}
