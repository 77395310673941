import { camelCase } from "lodash"
import { FEATURE_PERMISSIONS } from "permissions/enums"
import { FeaturePermissionKey, FeaturePermissions } from "permissions/types"
import { FEATURES_LIST } from "permissions/constants"
import { PermissionsDto } from "./types"

export class PermissionServiceDeserializer {
  static permissionKeyFromJSON<T extends FEATURE_PERMISSIONS>(permission: T): FeaturePermissionKey<T> {
    return camelCase(`${permission}_enabled`) as FeaturePermissionKey<T>
  }

  static permissionValueFromJSON(permissions: PermissionsDto, permission: FEATURE_PERMISSIONS): boolean {
    return permissions[permission] ?? false
  }

  static permissionValuesFromJSON(permissions: PermissionsDto): [keyof FeaturePermissions, boolean][] {
    return FEATURES_LIST.map(permission => [
      PermissionServiceDeserializer.permissionKeyFromJSON(permission),
      PermissionServiceDeserializer.permissionValueFromJSON(permissions, permission),
    ])
  }

  static fromJSON(permissions: PermissionsDto): FeaturePermissions {
    return Object.fromEntries(
      PermissionServiceDeserializer.permissionValuesFromJSON(permissions)
    ) as FeaturePermissions
  }
}
