import Button from "evenup-ui/Button"
import { ButtonGroup } from "./styled"
import { ReactNode } from "react"

export function BottomButtonGroup({
  onClose,
  actionButton,
}: {
  onClose: () => void
  actionButton: ReactNode
}) {
  return (
    <ButtonGroup>
      <Button onClick={onClose} color="secondary">
        Cancel
      </Button>
      {actionButton}
    </ButtonGroup>
  )
}
