import React, { useContext } from "react"
import { makeStyles } from "tss-react/mui"
import { LeftNavContext } from "demand/context"

interface StyleProps {
  leftNavTabIsExpanded: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, { leftNavTabIsExpanded }) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "grid",
    gridTemplateColumns: leftNavTabIsExpanded ? "1fr" : "1fr 1fr",
    gap: theme.spacing(3),
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "1fr",
    },
  },
}))

interface SectionGridContainerProps {
  children: React.ReactNode
}

const SectionGridContainer = ({ children }: SectionGridContainerProps): JSX.Element => {
  const { leftNavTabIsExpanded } = useContext(LeftNavContext)
  const { classes } = useStyles({ leftNavTabIsExpanded })
  return (
    <>
      <div className={classes.container}>{children}</div>
    </>
  )
}

export { SectionGridContainer as default }
