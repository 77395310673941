import { useEffect } from "react"
import { Plaintiff, Provider } from "./store/types"
import { exhibitBuilderActions, useExhibitBuilderStore } from "./store"
import { UserExhibitList } from "./UserExhibitList/UserExhibitList"
import { Box, FormControlLabel, Switch } from "@mui/material"
import { CollapseButtons } from "./CollapseButtons"

export function ProviderUserExhibits({
  providerId,
  plaintiffId,
}: {
  providerId?: Provider["id"]
  plaintiffId?: Plaintiff["id"]
}) {
  const exactProvderMatch = useExhibitBuilderStore(state => state.exactProviderMatch)

  useEffect(() => {
    if (!providerId || !plaintiffId) {
      return
    }

    exhibitBuilderActions.initializeProviderExhibits({ providerId, plaintiffId })

    return () => {
      exhibitBuilderActions.resetProviderExhibitsState()
    }
  }, [providerId, plaintiffId])

  if (!providerId || !plaintiffId) {
    return null
  }

  return (
    <Box maxWidth="90%">
      <Box>
        <FormControlLabel
          label="Hide all unrelated provider relations"
          control={
            <Switch
              size="small"
              data-test="hide-unrelated"
              color="secondary"
              checked={exactProvderMatch}
              onChange={(_, checked) => {
                exhibitBuilderActions.setExactProviderMatch(checked)
              }}
            />
          }
        />
        <CollapseButtons />
      </Box>
      <UserExhibitList />
    </Box>
  )
}
