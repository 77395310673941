import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"

const useStyles = (color: string, disabled: boolean) =>
  makeStyles()(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      color: disabled ? theme.palette.disabled.main : color,
    },
    topContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: theme.spacing(0.25),
    },
    dot: {
      height: theme.spacing(1),
      width: theme.spacing(1),
      borderRadius: "50%",
      margin: theme.spacing("auto", theme.spacing(0.5), "auto", theme.spacing(0)),
    },
    filledDot: {
      backgroundColor: disabled ? theme.palette.disabled.main : color,
    },
    nonFilledDot: {
      border: `0.17rem solid ${disabled ? theme.palette.disabled.main : color}`,
    },
    statusText: {
      color: disabled ? theme.palette.disabled.main : color,
    },
  }))

interface StatusLabelInterface {
  status: string
  text: string
  color: string
  filled: boolean
  showDot?: boolean
  subtext?: string
  disabled?: boolean
  dotColor?: string
}

const StatusLabel = ({
  status,
  text,
  color,
  filled,
  showDot = true,
  subtext = "",
  disabled = false,
  dotColor = undefined,
}: StatusLabelInterface): JSX.Element => {
  const { classes } = useStyles(color, disabled)()

  if (!dotColor) {
    dotColor = color
  }

  return (
    <Box
      className={classes.container}
      style={{
        color: disabled ? undefined : color,
      }}
    >
      <Box className={classes.topContainer} data-test={`status-label-${status}`}>
        {showDot && (
          <Box
            className={`${classes.dot} ${filled ? classes.filledDot : classes.nonFilledDot}`}
            style={{
              border: filled ? undefined : `0.17rem solid ${disabled ? "" : dotColor}`,
              backgroundColor: disabled || !filled ? undefined : dotColor,
            }}
          ></Box>
        )}
        <Box
          className={classes.statusText}
          style={{
            color: disabled ? undefined : color,
          }}
        >
          {text}
        </Box>
      </Box>
      {subtext && <Box>{subtext}</Box>}
    </Box>
  )
}

export { StatusLabel as default }
