import React, { ChangeEvent, useCallback } from "react"
import FormControlLabel from "@mui/material/FormControlLabel"
import CircularProgress from "@mui/material/CircularProgress"
import Checkbox from "@mui/material/Checkbox"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"

interface ProgressCheckboxProps {
  checked: boolean
  onChange: (checked: boolean) => void
  label: string | React.ReactElement
  isChanging?: boolean
  disabled?: boolean
}

const SPINNER_SIZE = "24px"
export const ProgressCheckbox = ({
  checked,
  onChange,
  label,
  isChanging = false,
  disabled = false,
}: ProgressCheckboxProps): React.ReactElement => {
  const onChangeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked)
    },
    [onChange]
  )

  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <Checkbox
          color="secondary"
          checked={checked}
          disabled={disabled}
          checkedIcon={isChanging ? <CircularProgress size={SPINNER_SIZE} /> : <CheckBoxIcon />}
          icon={isChanging ? <CircularProgress size={SPINNER_SIZE} /> : <CheckBoxOutlineBlankIcon />}
          onChange={onChangeCallback}
        />
      }
      label={label}
      data-test="progress-checkbox"
    ></FormControlLabel>
  )
}
