import { useState } from "react"
import Box from "@mui/material/Box"

import { useParams } from "react-router-dom"

import MissingDocumentItem from "../missing-docs/MissingDocumentItem"
import MissingDocumentForm from "../missing-docs/MissingDocumentForm"
import AddMissingDocumentButton from "../missing-docs/AddMissingDocumentButton"
import PendingMissingDocumentItem from "../missing-docs/PendingMissingDocumentItem"

import useUser from "../hooks/useUser"

import { MissingDocContainer, SectionTitle } from "../missing-docs/styled"
import { SECTIONS } from "../missing-docs/constants"
import { MissingExhibit, BaseMissingExhibit } from "../missing-docs/interfaces"
import { useFormContext as useDemandFormContext } from "./context"
import { NOT_SETUP_ROLE } from "../common/models/roles"

interface Props {
  missingDocs: MissingExhibit[]
  title: string
  section: SECTIONS
  providerId?: Nullable<number>
  canEditExistingProvider?: boolean
  canSelectProvider?: boolean
  pendingMissingDocs?: BaseMissingExhibit[]
  onCreatePending?: Nullable<(pending: BaseMissingExhibit) => void>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onApproveCallback?: Nullable<(data: any) => void>
}

const MissingDocumentSection = ({
  missingDocs,
  title,
  section,
  providerId = null,
  canEditExistingProvider = true,
  canSelectProvider = false,
  pendingMissingDocs = [],
  onCreatePending = null,
  onApproveCallback = null,
}: Props): JSX.Element => {
  const [creatingNew, setCreatingNew] = useState<boolean>(false)
  const { request } = useDemandFormContext()
  const { id: caseId } = useParams()
  const { user } = useUser()

  if (user.role === NOT_SETUP_ROLE) {
    return <></>
  }

  if (!request?.pk || !caseId) {
    return <></>
  }

  const handleOnPendingCreate = (pendingMissingDoc: BaseMissingExhibit) => {
    setCreatingNew(false)
    if (onCreatePending !== null) {
      onCreatePending(pendingMissingDoc)
    }
  }

  const Form = (
    <Box>
      <AddMissingDocumentButton
        disabled={creatingNew}
        onClick={() => {
          setCreatingNew(true)
        }}
      />

      {creatingNew && (
        <MissingDocContainer>
          <MissingDocumentForm
            providerId={providerId}
            section={section}
            callback={() => {
              setCreatingNew(false)
            }}
            canEditExistingProvider={canEditExistingProvider}
            canSelectProvider={canSelectProvider}
            onPendingCreate={onCreatePending ? handleOnPendingCreate : null}
          />
        </MissingDocContainer>
      )}
    </Box>
  )

  return (
    <Box mb={3}>
      <SectionTitle>{title}</SectionTitle>
      <div data-test="missing-document-list">
        {missingDocs?.map((missingDoc: MissingExhibit) => {
          return (
            <MissingDocumentItem
              key={missingDoc.pk}
              missingDoc={missingDoc}
              section={section}
              requestId={request.pk}
              caseId={+caseId}
              showStatus={true}
              canEditExistingProvider={canEditExistingProvider}
              onApproveCallback={onApproveCallback}
            />
          )
        })}
        {pendingMissingDocs?.map((missingDoc: BaseMissingExhibit, index: number) => {
          return <PendingMissingDocumentItem key={index} missingDoc={missingDoc} />
        })}
      </div>
      {Form}
    </Box>
  )
}

export { MissingDocumentSection as default }
