import { useCallback } from "react"
import { TextField } from "@mui/material"

import { ReferencePage as ReferencePageType } from "common/types/providers"
import { ExhibitSelect } from "demand/Providers/Provider/ProviderForm/BillsSection/ExhibitSelect"
import { ObjectiveTestFindingReferenceEditableRow } from "demand/Providers/Provider/ProviderForm/ObjectiveTests/styled"
import { FindingEditableItemProps } from "../FindingEditableItem"
import { getObjectiveTestReferenceExhibitValue } from "../utils"
import { NO_EXHIBIT_INFO } from "./constants"

type ReferencePageProps = {
  referencePage: ReferencePageType
  isDisabled: boolean
  onExhibitChange: (args: { referencePageId: ReferencePageType["id"]; value: string }) => void
  onPageNumberChange: (args: { referencePageId: ReferencePageType["id"]; value: string }) => void
  onBlur: () => void
} & Pick<FindingEditableItemProps, "provider">

const ReferencePage = ({
  referencePage,
  isDisabled,
  onExhibitChange,
  onPageNumberChange,
  onBlur,
  provider,
}: ReferencePageProps) => {
  const referencePageId = referencePage.id
  const exhibitValue = getObjectiveTestReferenceExhibitValue(referencePage)
  const hasNoExhibit = !exhibitValue

  const handleExhibitChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onExhibitChange({ referencePageId, value: e.target.value }),
    [onExhibitChange, referencePageId]
  )
  const handlePageNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onPageNumberChange({ referencePageId, value: e.target.value }),
    [onPageNumberChange, referencePageId]
  )

  return (
    <ObjectiveTestFindingReferenceEditableRow key={referencePageId}>
      <ExhibitSelect
        value={exhibitValue}
        provider={provider}
        onChange={handleExhibitChange}
        disabled={isDisabled}
        onBlur={onBlur}
        // TODO: fix validation
        error={false}
        helperText=""
      />
      <TextField
        type="number"
        onChange={handlePageNumberChange}
        value={referencePage.pageNumber || ""}
        disabled={hasNoExhibit}
        title={hasNoExhibit ? NO_EXHIBIT_INFO : undefined}
        onBlur={onBlur}
      />
    </ObjectiveTestFindingReferenceEditableRow>
  )
}

export default ReferencePage
