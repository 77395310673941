import { useMemo } from "react"
import { BlockTabs, Tab, TabPanel } from "common/tabs/BlockTabs"
import { useSearchState } from "hooks/useSearchState"
import { LibraryBox } from "./styled"
import { DEFAULT_LIBRARY_TAB, LIBRARY_TABS, LIBRARY_TAB_LABELS } from "./constants"
import { LibraryFiltersProvider } from "./Filters"
import { SectionTemplates, LibraryVariables, ProviderTemplates, DocumentStructure } from "./Tabs"
import { usePermissions } from "permissions/usePermissions"
import { Typography } from "@mui/material"
import useUser from "hooks/useUser"
import { useTheme } from "@emotion/react"
import { getSettingsPageOptions } from "settings/utils"
import { SETTINGS_ROUTE_PREFIX } from "settings/types"

export function Library(): JSX.Element {
  const [currentTab, setCurrentTab] = useSearchState("tab", DEFAULT_LIBRARY_TAB, "string")
  const { libraryDocumentStructuresEnabled } = usePermissions({ suspense: false })
  const theme = useTheme()
  const { user } = useUser()

  const tabs = useMemo(() => {
    const result = [
      <Tab
        key={LIBRARY_TABS.TEMPLATES}
        label={LIBRARY_TAB_LABELS[LIBRARY_TABS.TEMPLATES]}
        value={LIBRARY_TABS.TEMPLATES}
        data-test="library_templates_tab"
      />,
      <Tab
        key={LIBRARY_TABS.PROVIDERS}
        label={LIBRARY_TAB_LABELS[LIBRARY_TABS.PROVIDERS]}
        value={LIBRARY_TABS.PROVIDERS}
        data-test="library_providers_tab"
      />,
      <Tab
        key={LIBRARY_TABS.VARIABLES}
        label={LIBRARY_TAB_LABELS[LIBRARY_TABS.VARIABLES]}
        value={LIBRARY_TABS.VARIABLES}
        data-test="library_variables_tab"
      />,
    ]

    if (libraryDocumentStructuresEnabled) {
      result.push(
        <Tab
          key={LIBRARY_TABS.DOCUMENT_STRUCTURES}
          label={LIBRARY_TAB_LABELS[LIBRARY_TABS.DOCUMENT_STRUCTURES]}
          value={LIBRARY_TABS.DOCUMENT_STRUCTURES}
          data-test="library_document_structure"
        />
      )
    }

    return result
  }, [libraryDocumentStructuresEnabled])

  const tabPanels = useMemo(() => {
    const result = [
      <TabPanel key={LIBRARY_TABS.TEMPLATES} value={LIBRARY_TABS.TEMPLATES}>
        <SectionTemplates />
      </TabPanel>,
      <TabPanel key={LIBRARY_TABS.VARIABLES} value={LIBRARY_TABS.VARIABLES}>
        <LibraryVariables />
      </TabPanel>,
      <TabPanel key={LIBRARY_TABS.PROVIDERS} value={LIBRARY_TABS.PROVIDERS}>
        <ProviderTemplates />
      </TabPanel>,
    ]

    if (libraryDocumentStructuresEnabled) {
      result.push(
        <TabPanel key={LIBRARY_TABS.DOCUMENT_STRUCTURES} value={LIBRARY_TABS.DOCUMENT_STRUCTURES}>
          <DocumentStructure />
        </TabPanel>
      )
    }

    return result
  }, [libraryDocumentStructuresEnabled])

  const { pageTitle } = getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.LIBRARY, user.role)

  return (
    <LibraryFiltersProvider>
      <LibraryBox>
        <Typography marginBottom={theme.spacing(4)} variant="h4" component="h1">
          {pageTitle}
        </Typography>
        <BlockTabs currentTab={currentTab ?? DEFAULT_LIBRARY_TAB} setCurrentTab={setCurrentTab} tabs={tabs}>
          <>{tabPanels}</>
        </BlockTabs>
      </LibraryBox>
    </LibraryFiltersProvider>
  )
}
