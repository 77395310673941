import { formatDate } from "utils"
import { UPDATE_TYPES } from "../constants"
import { isEqual as isEqualDate } from "date-fns"

export interface UpdatedPassage {
  record_type: UPDATE_TYPES
  created_at: string
  date_of_service?: string
}

export const getUpdatesByRecordType = (
  updates: UpdatedPassage[],
  recordType: UPDATE_TYPES
): UpdatedPassage[] => {
  if (!updates?.length) return []

  return updates.filter(passage => passage.record_type === recordType)
}

export const getDateUpdatedByRecordType = (
  updates: UpdatedPassage[],
  recordType: UPDATE_TYPES
): Nullable<string> => {
  if (!updates?.length) return null

  const records = getUpdatesByRecordType(updates, recordType)
  if (!records.length) return null

  return formatDate(new Date(records[0].created_at), "MM/dd/yyyy")
}

export const getUpdatesWithSameDateOfService = (
  updates: UpdatedPassage[],
  dateOfService: string
): UpdatedPassage[] => {
  if (!updates?.length) return []

  return updates.filter(passage => {
    if (!passage?.date_of_service) return false
    return isEqualDate(new Date(passage.date_of_service), new Date(dateOfService))
  })
}
