import { useCallback, useRef, useState } from "react"
import { MultiSelectAutoCompleteProps, OptionProps } from "evenup-ui/MultiSelectAutoComplete"

interface UseFilterStateProps<T extends OptionProps> {
  initialValue: Nullable<string[]>
  options: T[]
  onChange: (ids: string[]) => void
}

type OnChangeHandler<T> = NonNullable<MultiSelectAutoCompleteProps<T>["onChangeValue"]>

interface UseFilterStateReturn<T extends OptionProps> {
  value: T[]
  onChangeValueHandler: OnChangeHandler<T>
  onClearHandler: () => void
}

export function useFilterState<T extends OptionProps = OptionProps>({
  initialValue,
  options,
  onChange,
}: UseFilterStateProps<T>): UseFilterStateReturn<T> {
  const onChangeRef = useRef(onChange)

  onChangeRef.current = onChange

  const [value, setValue] = useState(
    Array.isArray(initialValue) ? options.filter(({ value }) => initialValue.includes(value)) : []
  )

  const onChangeValueHandler = useCallback<OnChangeHandler<T>>((newValue: T[]) => {
    onChangeRef.current(newValue.map(({ value }) => value))
    setValue(newValue)
  }, [])

  const onClearHandler = useCallback(() => {
    onChangeRef.current([])
    setValue([])
  }, [])

  return {
    value,
    onChangeValueHandler,
    onClearHandler,
  }
}
