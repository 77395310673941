import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { PaginatedList } from "common/models/pagination"
import {
  DocumentStructureDefinition,
  DocumentStructureNameDefinition,
  FullDocumentStructureDefinition,
} from "common/types/documentStructure"
import {
  DocumentStructureLetterhead,
  DocumentStructureLetterheadDto,
  NewDocumentStructure,
  NewDocumentStructureDto,
} from "common/types/templates"
import { LibraryVariableServiceSerializer } from "../library-variable/serializers"
import { SectionTemplateServiceDeserializer } from "../section-template/serializers"
import {
  BLOCK_TYPES,
  DocumentStructureBaseBlockAttrsDto,
  DocumentStructureBaseBlockDto,
  DocumentStructureBlockDto,
  DocumentStructureComponentDto,
  DocumentStructureDto,
  DocumentStructureHeadingDto,
  DocumentStructureHeadingSectionDto,
  DocumentStructureNamesDto,
  DocumentStructureSectionDto,
  DocumentStructureSubSectionDto,
  DocumentStructureTemplateDto,
  DocumentStructureUpdatedResponseDto,
  FullDocumentStructureDto,
} from "./types"
import {
  DocumentStructureComponent,
  DocumentStructureHeading,
  DocumentStructureItem,
  DocumentStructureSection,
  DocumentStructureSubSectionAttrs,
  DocumentStructureTemplate,
} from "settings/Library/TemplateForms/formData/document-structure/types"
import invariant from "invariant"
import {
  DOCUMENT_STRUCTURE_BLOCK_TYPE,
  DOCUMENT_STRUCTURE_ITEM_TYPE,
} from "settings/Library/TemplateForms/formData/document-structure/enums"
import { REF_ID } from "settings/Library/TemplateForms/formData/document-structure/constants"
import { v4 } from "uuid"

export class DocumentStructureServiceDeserializer {
  static fromJSON(documentStructure: FullDocumentStructureDto): FullDocumentStructureDefinition {
    return {
      ...DocumentStructureServiceDeserializer.definitionFromJSON(documentStructure),
      sections: documentStructure.sections.map(DocumentStructureSectionServiceDeserializer.fromJSON),
    }
  }

  static definitionFromJSON(documentStructure: DocumentStructureDto): DocumentStructureDefinition {
    return {
      firm: documentStructure.firm,
      isDraft: documentStructure.is_draft,
      documentName: documentStructure.document_name,
      letterhead: documentStructure.letterhead
        ? {
            name: documentStructure.letterhead.name,
            fileUrl: documentStructure.letterhead.file_url,
          }
        : null,
      attributes: SectionTemplateServiceDeserializer.attributesDisplayFromListJSON(
        documentStructure.attributes
      ),
      id: documentStructure.pk,
      updatedAt: BaseServiceDeserializer.fromDateString(documentStructure.created_at),
      isDuplicated: documentStructure.is_duplicated,
    }
  }

  static toDefinitionJSON(data: NewDocumentStructure): NewDocumentStructureDto {
    const attributeIds = data.attributes
      ? LibraryVariableServiceSerializer.toAttributeValuesJSON(data.attributes)
      : null

    return {
      document_name: data.documentName ?? "",
      firm_id: data.firmId,
      attribute_ids: attributeIds,
      letterhead_upload_id: data.letterheadUploadId,
      letterhead_filename: data.letterheadFileName,
    }
  }

  static toLetterheadDefinitionJSON(data: DocumentStructureLetterhead): DocumentStructureLetterheadDto {
    return {
      letterhead_upload_id: data.letterheadUploadId,
      letterhead_filename: data.letterheadFileName,
    }
  }

  static fromListJSON(documentStructure: DocumentStructureDto[]): DocumentStructureDefinition[] {
    return documentStructure.map(DocumentStructureServiceDeserializer.definitionFromJSON)
  }

  static fromPaginatedListJSON(
    documentStructure: PaginatedList<DocumentStructureDto>
  ): PaginatedList<DocumentStructureDefinition> {
    return new PaginatedList(
      DocumentStructureServiceDeserializer.fromListJSON(documentStructure.items),
      documentStructure.count,
      documentStructure.pageSize,
      documentStructure.page
    )
  }

  static fromUpdateJSON(data: DocumentStructureUpdatedResponseDto): [null, PrimaryKey] {
    return [null, data.document_structure_id]
  }
}

export class DocumentStructureNamesServiceDeserializer {
  static definitionFromJSON(
    documentStructureNames: DocumentStructureNamesDto
  ): DocumentStructureNameDefinition[] {
    return documentStructureNames.document_names.map(({ document_name }) => ({ documentName: document_name }))
  }
}

export class DocumentStructureSectionServiceSerializer {
  static toJSON(section: DocumentStructureSection): Omit<DocumentStructureSectionDto, "children"> {
    invariant(section.id !== null, "Section Id can not be null")

    return {
      pk: section.id,
      title: section.title,
      repeat_for_multiple_plaintiffs: section.repeatForMultiplePlaintiffs,
    }
  }

  static newToJSON(section: DocumentStructureSection): Omit<DocumentStructureSectionDto, "pk" | "children"> {
    return {
      title: section.title,
      repeat_for_multiple_plaintiffs: false,
    }
  }

  static subSectionToJSON(
    subSection: DocumentStructureItem & DocumentStructureSubSectionAttrs
  ): Omit<DocumentStructureSubSectionDto, "children"> {
    invariant(subSection.sectionId !== null, "Heading Id can not be null")

    return {
      pk: subSection.sectionId,
      repeat_for_multiple_plaintiffs: subSection.repeat,
    }
  }
}

export class DocumentStructureSectionServiceDeserializer {
  static fromJSON(section: DocumentStructureSectionDto): DocumentStructureSection {
    return {
      id: section.pk,
      [REF_ID]: v4(),
      title: section.title,
      type: DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION,
      children: DocumentStructureSectionServiceDeserializer.sectionChildrenFromJSON(section.children),
      repeatForMultiplePlaintiffs: section.repeat_for_multiple_plaintiffs,
    }
  }

  static subSectionFromJSON(subsection: DocumentStructureSubSectionDto): DocumentStructureHeading {
    const [heading, ...children] = subsection.children
    const root = DocumentStructureHeadingServiceDeserializer.fromJSON({
      ...heading,
      section_id: subsection.pk,
      repeat: subsection.repeat_for_multiple_plaintiffs,
    })
    root.children = children.map(DocumentStructureSectionServiceDeserializer.sectionChildFromJSON)

    return root
  }

  static sectionChildFromJSON(
    child: Unpacked<DocumentStructureSectionDto["children"]> | DocumentStructureBlockDto
  ): Unpacked<DocumentStructureSection["children"]> {
    switch (child.type) {
      case undefined:
        return DocumentStructureSectionServiceDeserializer.subSectionFromJSON(child)
      case BLOCK_TYPES.HEADER:
        return DocumentStructureHeadingServiceDeserializer.fromJSON({ ...child, section_id: null })
      case BLOCK_TYPES.TEMPLATE_PLACEHOLDER:
        return DocumentStructureTemplateServiceDeserializer.fromJSON(child)
      case BLOCK_TYPES.COMPONENT_PLACEHOLDER:
        return DocumentStructureComponentServiceDeserializer.fromJSON(child)
    }
  }

  static sectionChildrenFromJSON(
    children: DocumentStructureSectionDto["children"]
  ): DocumentStructureSection["children"] {
    return children.map(DocumentStructureSectionServiceDeserializer.sectionChildFromJSON)
  }

  static fromUpdateJSON(
    section: DocumentStructureSectionDto & DocumentStructureUpdatedResponseDto
  ): [DocumentStructureSection, PrimaryKey] {
    return [DocumentStructureSectionServiceDeserializer.fromJSON(section), section.document_structure_id]
  }

  static fromListJSON(sections: DocumentStructureSectionDto[]): DocumentStructureSection[] {
    return sections.map(DocumentStructureSectionServiceDeserializer.fromJSON)
  }

  static fromDeleteResponseJSON(
    response: { success: boolean } & DocumentStructureUpdatedResponseDto
  ): [boolean, PrimaryKey] {
    return [response.success, response.document_structure_id]
  }
}

export class DocumentStructureHeadingServiceSerializer {
  static toJSON(
    heading: DocumentStructureHeading
  ): Omit<DocumentStructureHeadingDto, "children" | keyof DocumentStructureBaseBlockAttrsDto> {
    invariant(heading.id !== null, "Heading Id can not be null")

    return {
      pk: heading.id,
      name: heading.title,
      style: heading.headingType,
    }
  }

  static newToJSON(
    heading: DocumentStructureHeading
  ): Omit<DocumentStructureHeadingDto, "children" | keyof DocumentStructureBaseBlockDto> {
    return {
      name: heading.title,
      style: heading.headingType,
    }
  }
}

export class DocumentStructureTemplateServiceSerializer {
  static toJSON(template: DocumentStructureTemplate): DocumentStructureTemplateDto {
    invariant(template.id !== null, "template Id can not be null")

    return {
      type: BLOCK_TYPES.TEMPLATE_PLACEHOLDER,
      pk: template.id,
      template_type: template.templateType,
    }
  }

  static newToJSON(
    template: DocumentStructureTemplate
  ): Omit<DocumentStructureTemplateDto, keyof DocumentStructureBaseBlockDto> {
    return {
      template_type: template.templateType,
    }
  }
}

export class DocumentStructureTemplateServiceDeserializer {
  static fromJSON(template: DocumentStructureTemplateDto): DocumentStructureTemplate {
    return {
      id: template.pk,
      [REF_ID]: v4(),
      templateType: template.template_type,
      type: DOCUMENT_STRUCTURE_ITEM_TYPE.BLOCK,
      blockType: DOCUMENT_STRUCTURE_BLOCK_TYPE.TEMPLATE,
      children: [],
    }
  }

  static fromUpdateJSON(
    template: DocumentStructureTemplateDto & DocumentStructureUpdatedResponseDto
  ): [DocumentStructureTemplate, PrimaryKey] {
    return [DocumentStructureTemplateServiceDeserializer.fromJSON(template), template.document_structure_id]
  }

  static fromDeleteResponseJSON(
    response: { success: boolean } & DocumentStructureUpdatedResponseDto
  ): [boolean, PrimaryKey] {
    return [response.success, response.document_structure_id]
  }
}

export class DocumentStructureComponentServiceDeserializer {
  static fromJSON(component: DocumentStructureComponentDto): DocumentStructureComponent {
    return {
      id: component.pk,
      [REF_ID]: v4(),
      componentKey: component.component_key,
      type: DOCUMENT_STRUCTURE_ITEM_TYPE.BLOCK,
      blockType: DOCUMENT_STRUCTURE_BLOCK_TYPE.COMPONENT,
      children: [],
    }
  }

  static fromUpdateJSON(
    component: DocumentStructureComponentDto & DocumentStructureUpdatedResponseDto
  ): [DocumentStructureComponent, PrimaryKey] {
    return [
      DocumentStructureComponentServiceDeserializer.fromJSON(component),
      component.document_structure_id,
    ]
  }

  static fromDeleteResponseJSON(
    response: { success: boolean } & DocumentStructureUpdatedResponseDto
  ): [boolean, PrimaryKey] {
    return [response.success, response.document_structure_id]
  }
}

export class DocumentStructureComponentServiceSerializer {
  static toJSON(
    component: DocumentStructureComponent
  ): Omit<DocumentStructureComponentDto, keyof DocumentStructureBaseBlockAttrsDto> {
    invariant(component.id !== null, "component Id can not be null")

    return {
      pk: component.id,
      component_key: component.componentKey,
    }
  }

  static newToJSON(
    component: DocumentStructureComponent
  ): Omit<DocumentStructureComponentDto, keyof DocumentStructureBaseBlockDto> {
    return {
      component_key: component.componentKey,
    }
  }
}

export class DocumentStructureHeadingServiceDeserializer {
  static fromJSON(
    heading: DocumentStructureHeadingDto & DocumentStructureHeadingSectionDto
  ): DocumentStructureHeading {
    return {
      id: heading.pk,
      sectionId: heading.section_id,
      [REF_ID]: v4(),
      repeat: !!heading.repeat,
      title: heading.name,
      type: DOCUMENT_STRUCTURE_ITEM_TYPE.BLOCK,
      blockType: DOCUMENT_STRUCTURE_BLOCK_TYPE.HEADING,
      headingType: heading.style,
      children: [],
    }
  }

  static fromUpdateJSON(
    heading: DocumentStructureHeadingDto &
      DocumentStructureHeadingSectionDto &
      DocumentStructureUpdatedResponseDto
  ): [DocumentStructureHeading, PrimaryKey] {
    return [DocumentStructureHeadingServiceDeserializer.fromJSON(heading), heading.document_structure_id]
  }

  static fromDeleteResponseJSON(
    response: { success: boolean } & DocumentStructureUpdatedResponseDto
  ): [boolean, PrimaryKey] {
    return [response.success, response.document_structure_id]
  }
}
