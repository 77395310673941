import { isUndefined } from "lodash"

import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { apiService } from "api/services/ApiService"
import { ApiServiceType } from "api/services/types"
import { InterventionalTreatmentSerializer, InterventionalTreatmentDeserializer } from "./serializers"
import { InterventionalTreatmentDto } from "./types"

type ProviderServiceOptions = {
  caseId: BaseEntity["pk"]
  providerId: BaseEntity["pk"]
  interventionalTreatmentId: BaseEntity<string>["pk"]
}

enum OBJECTIVE_TEST_API_PATHS {
  ROOT = "interventional-treatment",
  CASE = "case",
  PROVIDER = "provider",
}

export class ProviderInterventionalTreatmentService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options: Partial<ProviderServiceOptions>, path?: string): string {
    const pathParts = [
      "",
      OBJECTIVE_TEST_API_PATHS.CASE,
      options.caseId,
      !isUndefined(options.providerId) ? OBJECTIVE_TEST_API_PATHS.PROVIDER : undefined,
      options.providerId,
      OBJECTIVE_TEST_API_PATHS.ROOT,
      options.interventionalTreatmentId,
      path,
    ]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  createProviderInterventionalTreatment = withResponseSerializer(
    InterventionalTreatmentDeserializer.definitionFromJSON,
    async (options: Pick<ProviderServiceOptions, "caseId" | "providerId">) => {
      return await handleEmptyResponse(this.apiService.create(null, this.getPath(options)))
    }
  )

  updateProviderInterventionalTreatment = withRequestSerializer(
    InterventionalTreatmentSerializer.definitionToJSON,
    withResponseSerializer(
      InterventionalTreatmentDeserializer.definitionFromJSON,
      async ({
        data,
        options,
      }: {
        data: InterventionalTreatmentDto
        options: Pick<ProviderServiceOptions, "caseId" | "providerId" | "interventionalTreatmentId">
      }) => {
        return await handleEmptyResponse(this.apiService.replace(data, this.getPath(options)))
      }
    )
  )

  deleteProviderInterventionalTreatment = (
    options: Pick<ProviderServiceOptions, "caseId" | "providerId" | "interventionalTreatmentId">
  ) => this.apiService.delete(null, this.getPath(options))
}

export const providerInterventionalTreatmentService = new ProviderInterventionalTreatmentService(apiService)
