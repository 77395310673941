import { Skeleton } from "@mui/material"
import styled from "@emotion/styled"

const TitleSkeleton = styled(Skeleton)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(4.5),
  height: theme.spacing(3),
}))

const ContentItemSkeleton = styled(Skeleton)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(4),
  height: theme.spacing(25),
}))

export const DOCUMENT_SKELETON = "document-skeleton"

export function DocumentSkeleton(): JSX.Element {
  return (
    <div data-test={DOCUMENT_SKELETON}>
      <TitleSkeleton variant="rounded" animation="wave" />
      <ContentItemSkeleton variant="rounded" animation="wave" />
      <ContentItemSkeleton variant="rounded" animation="wave" />
      <ContentItemSkeleton variant="rounded" animation="wave" />
    </div>
  )
}
