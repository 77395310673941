import React, { useCallback, useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import Cancel from "@mui/icons-material/Cancel"
import { useFormContext } from "demand/context"
import { DELETE_BILL, UPDATE_BILL } from "demand/Providers/reducer"
import { NumberInput } from "common/form-components/NumberInput"
import {
  DisplayOnHover,
  StyledCheckbox,
  StyledBillRow,
} from "demand/Providers/Provider/ProviderForm/BillsSection/styled"

export const BillRow = React.memo(function BillRow({
  bill,
  validationErrors,
  dispatch,
  disabled,
  hasCollateralSourceRule,
  exhibitSection,
  providerId,
}) {
  const { caseId } = useFormContext()
  const billId = bill.pk ?? bill.formId
  const [currentBill, updateCurrentBill] = useState(bill)
  const [isMouseOverRow, setIsMouseOverRow] = useState(false)

  const handleChangeDescription = useCallback(event => {
    updateCurrentBill(bill => ({ ...bill, description: event.target.value }))
  }, [])

  const handleSelectedChange = useCallback(
    event => {
      const newBill = {
        ...currentBill,
        selected: event.target.checked,
      }

      updateCurrentBill(newBill)
      dispatch({
        type: UPDATE_BILL,
        payload: { caseId, providerId, billId, updates: newBill },
      })
    },
    [caseId, providerId, billId, dispatch, currentBill]
  )

  const handleChangeBilledAmount = useCallback(numberFormatValues => {
    updateCurrentBill(bill => ({
      ...bill,
      billed_amount: numberFormatValues.valueAsNumber ?? 0,
    }))
  }, [])

  const handleChangeAdjustedAmount = useCallback(numberFormatValues => {
    updateCurrentBill(bill => ({
      ...bill,
      adjusted_amount: numberFormatValues.valueAsNumber ?? 0,
    }))
  }, [])

  const handleBlur = useCallback(() => {
    dispatch({
      type: UPDATE_BILL,
      payload: { caseId, providerId, billId, updates: currentBill },
    })
  }, [dispatch, caseId, providerId, billId, currentBill])

  const handleBillDelete = useCallback(() => {
    dispatch({
      type: DELETE_BILL,
      payload: { caseId, providerId, billId },
    })
  }, [dispatch, caseId, providerId, billId])

  useEffect(() => {
    updateCurrentBill(bill)
  }, [bill])

  return (
    <StyledBillRow
      onMouseOver={() => {
        setIsMouseOverRow(true)
      }}
      onMouseOut={() => {
        setIsMouseOverRow(false)
      }}
    >
      <DisplayOnHover my="auto" alwaysShow={currentBill.selected || isMouseOverRow}>
        <StyledCheckbox
          key={`${currentBill.formId}-${currentBill.pk}-${currentBill.selected}`}
          color="secondary"
          checked={currentBill.selected ?? false}
          onChange={handleSelectedChange}
          data-test="bill-selected"
          disabled={disabled}
        />
      </DisplayOnHover>
      <Box flexGrow={1}>
        <TextField
          fullWidth
          variant="outlined"
          value={currentBill.description ?? ""}
          data-test="bill-description"
          onChange={handleChangeDescription}
          disabled={disabled}
          error={!!validationErrors["description"]}
          helperText={validationErrors["description"]}
          onBlur={handleBlur}
        />
      </Box>
      <Box width={150}>
        <NumberInput
          value={currentBill.billed_amount ?? ""}
          data-test="billed-amount"
          onValueChange={handleChangeBilledAmount}
          currencySymbol="$"
          disabled={disabled}
          error={!!validationErrors["billed_amount"]}
          helperText={validationErrors["billed_amount"]}
          onBlur={handleBlur}
        />
      </Box>
      {!hasCollateralSourceRule && (
        <Box width={150}>
          <NumberInput
            value={currentBill.adjusted_amount ?? ""}
            data-test="adjusted-amount"
            currencySymbol="$"
            onValueChange={handleChangeAdjustedAmount}
            disabled={disabled}
            error={!!validationErrors["adjusted_amount"]}
            helperText={validationErrors["adjusted_amount"]}
            onBlur={handleBlur}
          />
        </Box>
      )}
      {exhibitSection}
      <Box>
        <IconButton onClick={handleBillDelete} disabled={disabled}>
          <Cancel />
        </IconButton>
      </Box>
    </StyledBillRow>
  )
})
