import { Bill } from "demand/Providers/types"
import { EXHIBIT_ID, FILE_TO_UPLOAD_ID, PARTITION_ID } from "./constants"
import * as Sentry from "@sentry/browser"

type EXHIBIT_TYPES = typeof EXHIBIT_ID | typeof FILE_TO_UPLOAD_ID | typeof PARTITION_ID

export const isValidType = (type: string): type is EXHIBIT_TYPES => {
  return type === EXHIBIT_ID || type === FILE_TO_UPLOAD_ID || type === PARTITION_ID
}

// uuid, the library we use for generating frontend form ids, does not use ":" so we are safe to use it for encoding
// e.g, "file_to_upload_id:some-long-uuid" --> ["file_to_upload_id", "some-long-uuid"]
export const decodeExhibitValue = (value: string): [EXHIBIT_TYPES, string | number] => {
  const [type, stringId] = value.split(":", 2)
  if (!isValidType(type)) {
    Sentry.captureMessage("Invalid Exhibit Type found in decodeExhibitValue", {
      level: "warning",
      extra: {
        type: type,
        stringId: stringId,
        originalValue: value,
      },
    })
    return [type as EXHIBIT_TYPES, stringId]
  }

  const id = type === EXHIBIT_ID ? parseInt(stringId) : stringId

  return [type, id]
}

// e.g, "file_to_upload_id", "some-long-uuid" --> "file_to_upload_id:some-long-uuid"
export const encodeExhibitValue = (type: string, id: string | number) => `${type}:${id}`

export const getBillExhibitValue = (bill: Bill) => {
  if (bill.exhibit_id) {
    return encodeExhibitValue(EXHIBIT_ID, bill.exhibit_id)
  }

  if (bill.partition_id) {
    return encodeExhibitValue(PARTITION_ID, bill.partition_id)
  }

  if (bill.file_to_upload_id) {
    return encodeExhibitValue(FILE_TO_UPLOAD_ID, bill.file_to_upload_id)
  }

  return ""
}
