import { ApiError, EmptyResponse } from "apiHelper"
import { DEFAULT_PAGE_SIZE, PaginatedList } from "common/models/pagination"
import { UnexpectedEmptyResponseError } from "./errors"
import { NotFoundEntity } from "./NotFoundEntity"
import { PaginatedResponseType, PaginatedOptions } from "./PaginatedList"

export async function handleEmptyResponse<T>(request: Promise<T | EmptyResponse>): Promise<T> {
  const response = await request

  if (response instanceof EmptyResponse) {
    throw new UnexpectedEmptyResponseError()
  }

  return response
}

export async function getPossibleEntity<T extends Required<BaseEntity>>(
  request: Promise<T | EmptyResponse>
): Promise<T | NotFoundEntity> {
  try {
    return await handleEmptyResponse(request)
  } catch (error) {
    if (error instanceof ApiError && error.response.status === 404) {
      return new NotFoundEntity()
    }

    throw error
  }
}

export async function handlePaginatedResponse<T>(
  request: Promise<PaginatedResponseType<T> | EmptyResponse>,
  { page = 1, pageSize = DEFAULT_PAGE_SIZE }: PaginatedOptions = {}
): Promise<PaginatedList<T>> {
  const response = await handleEmptyResponse(request)

  return new PaginatedList(response.results, response.count, pageSize, page)
}
