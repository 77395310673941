import { useMemo, useRef, useState } from "react"
import { Box, CircularProgress, InputLabel, MenuItem, Select } from "@mui/material"
import { SummaryContainer } from "summaries/styled"
import { makeStyles } from "tss-react/mui"

import { summariesGeneratorApiService } from "api/services/summaries"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"

import { Generator } from "api/services/summaries/types"
import { MultiLevelMenu } from "common/multilevel-menu"

const useStyles = makeStyles()(theme => ({
  descriptionPopover: {
    pointerEvents: "none",
  },
  descriptionPopoverContent: {
    pointerEvents: "auto",
  },
  selector: {
    "& * .MuiList-root": {
      width: "100px",
    },
    "& > .MuiMenuItem-root": {
      fontSize: "12px",
      width: "300px",
      "& * .MuiListItemIcon-root": {
        fontSize: "2em",
      },
      "&:hover": {
        backgroundColor: theme.palette.blue.contentBoxHover,
      },
    },
  },
}))

interface GeneratorSelectorProps {
  value: string
  onChange: (generator: Generator) => void
  caseId?: Nullable<PrimaryKey> | undefined
  matterId?: Nullable<string> | undefined
  generatorKeys?: string[]
}

export const GeneratorSelector = ({
  value,
  onChange,
  caseId,
  matterId,
  generatorKeys,
}: GeneratorSelectorProps): JSX.Element => {
  const { classes } = useStyles()
  const [filteredGenerators, setFilteredGenerators] = useState<Nullable<Generator[]>>(null)
  const [menuOpened, setMenuOpened] = useState<boolean>(false)

  const anchorRef = useRef(null)

  const { isLoading: isLoadingGenerators } = useQuery<Generator[]>(
    [queryKeys.generators, caseId],
    () => summariesGeneratorApiService.getGenerators({ caseId, matterId }),
    {
      retry: false,
      onSuccess: data => {
        if (generatorKeys && generatorKeys.length > 0) {
          setFilteredGenerators(data.filter(generator => generatorKeys.includes(generator.generatorKey)))
        } else {
          setFilteredGenerators(data)
        }
      },
      meta: {
        disableLoader: true,
      },
    }
  )

  const handleGeneratorChange = (generator: Generator) => {
    handleMenuClose()
    onChange(generator)
  }

  const handleMenuClose = () => {
    setMenuOpened(false)
  }

  const summaryGroupFormatter = (summaryGroup: Nullable<string>): Nullable<string> => {
    if (!summaryGroup) return summaryGroup
    if (summaryGroup === "facts") {
      return "Facts"
    }

    if (summaryGroup === "provider") {
      return "Medical Provider"
    }

    if (summaryGroup === "pain_and_suffering") {
      return "Pain and Suffering Narrative"
    }

    return summaryGroup
  }

  const formattedGenerators: Generator[] = useMemo(() => {
    if (!filteredGenerators) return []
    return filteredGenerators.map(generator => {
      return {
        ...generator,
        summaryGroup: summaryGroupFormatter(generator.summaryGroup) ?? "",
      }
    })
  }, [filteredGenerators])

  if (isLoadingGenerators)
    return (
      <Box display="flex" mt={2} ml={2}>
        <CircularProgress />
      </Box>
    )
  if (!filteredGenerators) return <></>

  return (
    <>
      <SummaryContainer fullWidth>
        <InputLabel id="summary-type-label">Summary Type</InputLabel>
        <Select
          labelId="summary-type-label"
          id="summary_type"
          label="Summary Types"
          onMouseDown={e => {
            e.preventDefault()
            e.stopPropagation()
            setMenuOpened(true)
          }}
          value={value}
          disabled={!filteredGenerators?.length}
          ref={anchorRef}
          open={false}
        >
          <MenuItem value={undefined}>
            <em>Please Select Generator</em>
          </MenuItem>
          {filteredGenerators?.map(generator => (
            <MenuItem key={generator.generatorKey} value={generator.generatorKey}>
              {generator.displayName}
            </MenuItem>
          ))}
        </Select>
      </SummaryContainer>

      <MultiLevelMenu
        items={formattedGenerators}
        anchorRef={anchorRef}
        open={menuOpened}
        group={"summaryGroup"}
        label={"summaryGroup"}
        displayLabel={"displayName"}
        onSelect={handleGeneratorChange}
        onClose={handleMenuClose}
        oneChildList={true}
        className={classes.selector}
      />
    </>
  )
}
