export const ACCEPT_ALL_TYPE = "*"
export const ACCEPT_IMAGE_TYPE = "image/*"

export type ACCEPT_TYPE = typeof ACCEPT_ALL_TYPE | typeof ACCEPT_IMAGE_TYPE

export const IMAGES = "images"
export const DOCUMENTS = "files and folders"

export const FILE_DROPPER = "file-dropper"
export const VALIDATE_FILE_ALERT_BODY = "validate-file-alert-body"
export const ALERT_CLOSE_BUTTON = "alert-close-button"
