import React, { useCallback } from "react"
import {
  FastForward as FastForwardIcon,
  FastRewind as FastRewindIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material"

import { PdfToolbarProps } from "../PdfToolbar"
import { Pagination, PaginationPagesIndicator, PaginationButton, PageInput } from "./styled"

type PdfToolbarPaginationProps = {
  hideArrows?: boolean
} & Pick<PdfToolbarProps, "page" | "pages" | "pageNumberAdjustment" | "onChangePage">

export function PdfToolbarPagination({
  pages = 1,
  page = 1,
  pageNumberAdjustment = 0,
  onChangePage,
  hideArrows,
}: PdfToolbarPaginationProps): React.ReactElement {
  const isFirstPage = page === 1
  const isLastPage = page === pages

  const handleOnPrevPage = useCallback(() => {
    if (isFirstPage) return

    onChangePage?.(page - 1)
  }, [page, onChangePage, isFirstPage])

  const handleOnNextPage = useCallback(() => {
    if (isLastPage) return

    onChangePage?.(page + 1)
  }, [page, onChangePage, isLastPage])

  const handleOnFirstPage = useCallback(() => {
    if (isFirstPage) return

    onChangePage?.(1)
  }, [onChangePage, isFirstPage])

  const handleOnLastPage = useCallback(() => {
    if (isLastPage) return

    onChangePage?.(pages)
  }, [pages, onChangePage, isLastPage])

  const handlePageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Math.max(1, Math.min(pages, parseInt(e.target.value, 10) || 1))
      onChangePage?.(value)
    },
    [pages, onChangePage]
  )

  return (
    <Pagination>
      {!hideArrows && (
        <>
          <PaginationButton onClick={handleOnFirstPage} disabled={isFirstPage} title="Go to first page">
            <FastRewindIcon fontSize="small" />
          </PaginationButton>
          <PaginationButton onClick={handleOnPrevPage} disabled={isFirstPage} title="Go to previous page">
            <PlayArrowIcon sx={{ transform: "rotate(180deg)" }} fontSize="small" />
          </PaginationButton>
        </>
      )}

      <PaginationPagesIndicator variant="helper">
        <PageInput
          aria-label="Current page"
          type="number"
          min={1}
          max={pages}
          value={page}
          onChange={handlePageChange}
        />
        &nbsp;/&nbsp;
        <span aria-label="Total pages">
          {pageNumberAdjustment > 0 ? `[${pageNumberAdjustment + 1}-${pages + pageNumberAdjustment}]` : pages}
        </span>
      </PaginationPagesIndicator>

      {!hideArrows && (
        <>
          <PaginationButton onClick={handleOnNextPage} disabled={isLastPage} title="Go to next page">
            <PlayArrowIcon fontSize="small" />
          </PaginationButton>
          <PaginationButton onClick={handleOnLastPage} disabled={isLastPage} title="Go to last page">
            <FastForwardIcon fontSize="small" />
          </PaginationButton>
        </>
      )}
    </Pagination>
  )
}
