import { NodeEntry, Transforms } from "slate"
import { createParagraph } from "../create"
import { CustomEditor } from "../CustomEditor"

export function normalizeEditor(editor: CustomEditor, [node]: NodeEntry<CustomEditor>): boolean {
  if (!node.children.length) {
    Transforms.insertNodes(editor, createParagraph(), { at: [0] })
    return true
  }

  return false
}
