import Box from "@mui/material/Box"
import styled from "@emotion/styled"

export const ContentHeader = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  fontWeight: "bold",
})

export const Content = styled(Box)({
  display: "grid",
  overflowY: "scroll",
})

export const Row = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  borderRadius: "4px",
})

export const WrapCell = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
})
