import React, { useMemo } from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { AutocompleteProps } from "@mui/material"
import { getUsStates } from "api"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"

export type USStateObject = {
  key: string
  display: string
}

type StateAutocompleteProps = Pick<
  AutocompleteProps<string, boolean | undefined, boolean | undefined, boolean | undefined>,
  "value" | "onChange" | "multiple" | "id" | "size"
> & {
  label?: string
}

const statesToMap = (states: USStateObject[]): Record<string, string> => {
  const map: Record<string, string> = {}
  states.forEach(state => (map[state.key] = state.display))
  return map
}

const statesToKeys = (states: USStateObject[]): string[] => states.map(state => state.key)

const StateAutocomplete: React.FC<StateAutocompleteProps> = ({
  value,
  multiple,
  id,
  onChange,
  size = "small",
  label,
}) => {
  const { isLoading, data, isError } = useQuery<{ results: USStateObject[] }>(
    [queryKeys.usStates],
    getUsStates
  )
  const states = data?.results
  const stateKeys = useMemo(() => statesToKeys(states ?? []), [states])
  const stateMap = useMemo(() => statesToMap(states ?? []), [states])

  const textFieldLabel = label ?? (multiple ? "Select states" : "Select a state")

  return (
    <Autocomplete<string, boolean | undefined, boolean | undefined, boolean | undefined>
      multiple={multiple}
      id={id}
      data-test={id}
      options={stateKeys}
      disabled={!states}
      loading={isLoading}
      value={value}
      onChange={onChange}
      size={size}
      renderInput={params => (
        <TextField
          {...params}
          label={textFieldLabel}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          helperText={isError && "Error loading states. Please try again shortly."}
        />
      )}
      getOptionLabel={option => stateMap[option] ?? ""}
    />
  )
}

export default StateAutocomplete
