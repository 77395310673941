import { useEffect, useState, useCallback } from "react"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormLabel from "@mui/material/FormLabel"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import { makeStyles } from "tss-react/mui"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Controller, useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"
import size from "lodash/size"
import { DateField } from "common/form-components"

import { StateSelect } from "../common/StateSelect"
import { queryKeys } from "../react-query/constants"
import useAutosave from "../hooks/useAutosave"
import { useFormContext } from "./context"
import {
  savePlaintiffInfo,
  updatePlaintiffInfo,
  fetchPlaintiffInfoForCaseAndPlaintiff,
  fetchPlaintiffInfoForCase,
} from "../api"
import { getChangedFields, getCommonMutateOptions } from "../utils"
import { InputField } from "../common/form-components"
import { formSectionsToRoutes, STEP_STATUSES } from "./constants"
import { Helmet } from "react-helmet"

import useCase from "hooks/useCase"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"

const useStyles = makeStyles()(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(4),
    justifyContent: "space-between",
  },
  actions: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  flex: {
    display: "flex",
  },
  w100: {
    width: "100%",
  },
  ageDisplay: {
    padding: "0 1.75rem",
    display: "flex",
    justifyContent: "center",
  },
  ageAlert: {
    marginTop: theme.spacing(1),
  },
}))

const INITIAL_STATE = {
  first_name: "",
  last_name: "",
  date_of_birth: "",
  gender: null,
  state: "",
}

const getAge = dateString => {
  if (!dateString) {
    return NaN
  }

  const today = new Date()
  const birthDate = new Date(dateString)
  const age = today.getFullYear() - birthDate.getFullYear()
  const monthDiff = today.getMonth() - birthDate.getMonth()
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1
  }
  return age
}

export function PlaintiffInfo({ lastVisited }) {
  const { classes } = useStyles()
  const { caseId, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus } = useFormContext()
  const { saveRef, currentPlaintiff } = useOutletContext()
  const [errors, setErrors] = useState(null)
  const { caseObj } = useCase(caseId)
  const usingTemplatedData = !!caseObj?.templated_sections?.length
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  const queryClient = useQueryClient()

  const { control, handleSubmit, reset, formState, register, getValues, setValue, watch } = useForm({
    defaultValues: INITIAL_STATE,
  })

  const dateOfBirth = watch("date_of_birth")
  const ageInYears = getAge(dateOfBirth)

  const handleChangeDateOfBirth = useCallback(
    newDateOfBirth => setValue("date_of_birth", newDateOfBirth, { shouldDirty: true }),
    [setValue]
  )

  useQuery(
    [queryKeys.plaintiff, caseId, currentPlaintiff?.id],
    () => {
      return fetchPlaintiffInfoForCaseAndPlaintiff(caseId, currentPlaintiff.id)
    },
    {
      onSuccess: data => {
        reset(data)
      },
      enabled: !!currentPlaintiff && multiPlaintiffEnabled,
    }
  )

  useQuery(
    [queryKeys.plaintiff, caseId],
    () => {
      return fetchPlaintiffInfoForCase(caseId)
    },
    {
      onSuccess: data => {
        reset(data[0])
      },
      enabled: !multiPlaintiffEnabled,
    }
  )

  const mutationOptions = getCommonMutateOptions({
    setErrors,
    reset,
    setSavedSuccessfully,
    showErrorMessage,
    setValue,
    onSuccessExtra: result => {
      handleUpdateStepStatus({ status: STEP_STATUSES.completed })

      if (!multiPlaintiffEnabled) return
      // Update the Tab Menu Names
      queryClient.setQueryData([queryKeys.plaintiffs, caseId], oldData => {
        return oldData.map(plaintiff => {
          if (plaintiff.id !== result.pk) return plaintiff
          return {
            firstName: result.first_name,
            lastName: result.last_name,
            id: result.pk,
            futureExpensesText: result.future_expenses_text,
          }
        })
      })
    },
  })
  const createMutation = useMutation(savePlaintiffInfo, mutationOptions)
  const updateMutation = useMutation(updatePlaintiffInfo, mutationOptions)
  // for autosave it should succeed or fail silently
  const autosaveMutation = useMutation(updatePlaintiffInfo)
  const pk = getValues("pk")

  const handleOnBlur = handleSubmit(async data => {
    const hasChangedFields = size(getChangedFields(data, formState)) > 0
    hasChangedFields && updateMutation.mutate({ caseId, data: { pk, ...data, case_id: caseId } })
  })

  const handleFormSubmit = handleSubmit(data => {
    return pk
      ? updateMutation.mutateAsync({ caseId, data: { pk, ...data, case_id: caseId } })
      : createMutation.mutateAsync({ caseId, data })
  })

  useEffect(() => {
    saveRef.current = handleFormSubmit
  }, [saveRef, handleFormSubmit])

  useAutosave({
    shouldAutosave: formState.isDirty && pk,
    save: () => {
      autosaveMutation.mutate({ caseId, data: { ...getValues(), case_id: caseId } })
    },
  })

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.plaintiff
  })

  return (
    <>
      <Helmet>
        <title>Demand: {getValues("first_name") + " " + getValues("last_name")} - EvenUp</title>
      </Helmet>
      <form
        className={classes.formFields}
        noValidate
        onBlur={pk ? handleOnBlur : null}
        onSubmit={handleFormSubmit}
      >
        <input type={"hidden"} {...register("pk")} />
        <InputField
          data-test="plaintiff-first-name"
          name="first_name"
          type="text"
          control={control}
          label="First Name"
          variant="outlined"
          error={errors?.first_name}
          helperText={errors?.first_name}
        />
        <InputField
          name="last_name"
          data-test="plaintiff-last-name"
          type="text"
          control={control}
          label="Last Name"
          variant="outlined"
          error={errors?.last_name}
          helperText={errors?.last_name}
        />
        <div>
          <div className={classes.flex}>
            <DateField
              initialValue={dateOfBirth}
              onChange={handleChangeDateOfBirth}
              label="Date of Birth"
              fieldProps={{ name: "date_of_birth" }}
              dataTest="plaintiff-date-of-birth"
              className={classes.w100}
              error={errors?.date_of_birth}
              helperText={errors?.date_of_birth || "When was the plaintiff born?"}
            />
            <FormControl component="fieldset" className={classes.ageDisplay} variant="standard">
              <FormLabel component="legend">Age</FormLabel>
              <Typography>{Number.isNaN(ageInYears) ? "" : ageInYears}</Typography>
            </FormControl>
          </div>

          {ageInYears > 100 && (
            <Alert className={classes.ageAlert} severity="warning">
              Age input is greater than 100 years old, is that correct?
            </Alert>
          )}
        </div>
        <Controller
          name="gender"
          control={control}
          render={({ field }) => {
            return (
              <FormControl component="fieldset" error={errors?.gender}>
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup aria-label="gender" name="gender" row {...field}>
                  <FormControlLabel
                    value="female"
                    control={<Radio color="secondary" />}
                    label="Female"
                    data-test="female-gender"
                  />
                  <FormControlLabel value="male" control={<Radio color="secondary" />} label="Male" />
                </RadioGroup>
                {errors?.gender && <FormHelperText>Please select one of the above options.</FormHelperText>}
              </FormControl>
            )
          }}
        />

        <StateSelect
          control={control}
          selectName="state"
          errors={errors?.state}
          helperText="State where incident took place"
          disabled={usingTemplatedData && !multiPlaintiffEnabled}
          tooltip={
            usingTemplatedData && !multiPlaintiffEnabled
              ? "State is set in the 'Select Templates' section"
              : null
          }
        />
      </form>
    </>
  )
}
