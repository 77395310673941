import { Contract } from "common/interfaces"
import { isBefore, isAfter, parse, isEqual, addMonths, subDays } from "date-fns"
import { getLastDayOfMonth } from "../../utils"

interface IsExpiredProps {
  contract: Pick<Contract, "end_date">
  today?: Date
}

export const isExpired = ({ contract, today = new Date() }: IsExpiredProps): boolean => {
  return isBefore(new Date(contract.end_date), today)
}

interface IsCancelledProps {
  contract: Pick<Contract, "cancellation_date">
  today?: Date
}

export const isCancelled = ({ contract, today = new Date() }: IsCancelledProps): boolean => {
  return !!contract.cancellation_date && isBefore(new Date(contract.cancellation_date), today)
}

export type ContractDatesObject = Pick<Contract, "pk" | "start_date" | "end_date" | "cancellation_date">

export const parseContractDate = (dateString: string): Date => parse(dateString, "yyyy-MM-dd", new Date())

interface HasOverlapProps {
  startDate: Nullable<Date>
  endDate: Nullable<Date>
  contract: ContractDatesObject
}

const hasOverlap = ({ startDate, endDate, contract }: HasOverlapProps): boolean => {
  const contractStartDate = parseContractDate(contract.start_date)
  // use cancellation date to determine overlap if it exists
  const contractEndDate = parseContractDate(contract.cancellation_date || contract.end_date)

  const hasSameStartOrEndDateAsContract =
    (!!startDate && (isEqual(startDate, contractStartDate) || isEqual(startDate, contractEndDate))) ||
    (!!endDate && (isEqual(endDate, contractEndDate) || isEqual(endDate, contractStartDate)))

  const isStartDateOverlapping =
    !!startDate && isAfter(startDate, contractStartDate) && isBefore(startDate, contractEndDate)

  const isEndDateOverlapping =
    !!endDate && isAfter(endDate, contractStartDate) && isBefore(endDate, contractEndDate)

  const areContractDatesInBetween =
    !!startDate && isAfter(contractStartDate, startDate) && !!endDate && isBefore(contractEndDate, endDate)

  return (
    hasSameStartOrEndDateAsContract ||
    isStartDateOverlapping ||
    isEndDateOverlapping ||
    areContractDatesInBetween
  )
}

interface FindContractOverlapProps {
  idToIgnore?: number
  startDate: Nullable<Date>
  endDate: Nullable<Date>
  contracts: ContractDatesObject[]
}

export const findContractOverlap = ({
  idToIgnore,
  startDate,
  endDate,
  contracts,
}: FindContractOverlapProps): ContractDatesObject[] => {
  const overlap: ContractDatesObject[] = []

  contracts.forEach(contract => {
    if (idToIgnore !== null && contract.pk === idToIgnore) {
      return
    }

    if (hasOverlap({ startDate, endDate, contract })) {
      overlap.push(contract)
    }
  })

  return overlap
}

export const getEndDate = (startDate: string, months: number): Date => {
  const date = new Date(startDate.replaceAll("-", "/"))
  const lastDayOfMonth = getLastDayOfMonth(date.getFullYear(), date.getMonth())

  if (date.getDate() === lastDayOfMonth) {
    const newDate = addMonths(date, months)
    const lastDateOfNewMonth = getLastDayOfMonth(newDate.getFullYear(), newDate.getMonth())
    newDate.setDate(lastDateOfNewMonth)
    return newDate
  }

  return subDays(addMonths(date, months), 1)
}
