import React from "react"
import { RenderElementProps } from "slate-react"
import { ListElement } from "../CustomEditor"

interface OrderedListProps extends RenderElementProps {
  element: ListElement
}

export const OrderedList: React.FC<OrderedListProps> = React.memo(function OrderedList({
  attributes,
  children,
}) {
  return <ol {...attributes}>{children}</ol>
})
