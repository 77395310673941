import { REQUEST_VIEW_TABS } from "./enums"

export const REQUEST_VIEW_URL_PREFIX: Record<REQUEST_VIEW_TABS, string> = {
  [REQUEST_VIEW_TABS.REQUEST]: "",
  [REQUEST_VIEW_TABS.MISSING_DOCS]: "missing-docs",
  [REQUEST_VIEW_TABS.REVISION]: "revision",
}

export const REQUEST_VIEW_HISTORY: Record<REQUEST_VIEW_TABS, string> = {
  [REQUEST_VIEW_TABS.REQUEST]: "Request Demand",
  [REQUEST_VIEW_TABS.MISSING_DOCS]: "Missing Documents",
  [REQUEST_VIEW_TABS.REVISION]: "Request Revision",
}

export const BE_DUPLICATE_ERROR_TEXT = "duplicate key value violates unique constraint "

export const STANDARD_DUPLICATE_ERROR =
  "Could not create a new demand, there is already a demand created for this request."

export const STANDARD_ERROR = "There was an error creating the demand for this request."

export const MEDICAL_CHRONOLOGY_DUPLICATE_ERROR =
  "Could not create a new medical chronology, there is already a medical chronology created for this request."

export const NO_MEDICAL_CHRONOLOGY_DOCUMENT =
  "No medical chronology found for the request. Please contact your admin."

export const CARRIER_SECTION_FIELDS = [
  "carrier_name",
  "defendants",
  "adjuster_first_name",
  "adjuster_last_name",
  "claim_number",
  "policy_number",
  "policy_limit",
]

export const PLAINTIFF_SECTION_FIELDS = [
  "education_level",
  "family_status",
  "dependent_status",
  "dependent_status_other",
  "household_start_of_impairment",
  "household_end_of_impairment",
  "household_percent_of_chores",
]

export const CASE_FACTS_SECTION_FIELDS = [
  "state_of_incident",
  "date_of_incident",
  "case_type",
  "case_facts",
  "assigned_attorney",
]

export const INJURY_SECTION_FIELDS = [
  "injury_impacts_recreation",
  "injury_impacts_recreation_other",
  "injury_impacts_home_activities",
  "injury_impacts_home_activities_other",
  "injury_impacts_self_care",
  "injury_impacts_self_care_other",
  "injury_impacts_entertainment",
  "injury_impacts_entertainment_other",
  "injury_impacts_social",
  "injury_impacts_social_other",
]
