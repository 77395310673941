import { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import { makeStyles } from "tss-react/mui"
import { Link } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../react-query/constants"

import { useFormContext } from "./context"
import { fetchDamagesSectionsByCase } from "../api"
import { formSectionsToRoutes, STEP_STATUSES } from "./constants"

import { useOutletContext } from "react-router-dom"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"

import { DamagesSection } from "./DamagesSection"
import SimpleDemandSkipSectionBanner from "./SimpleDemandBanner"

const useStyles = makeStyles()(theme => ({
  sectionBox: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  notice: {
    margin: theme.spacing(2, 0),
  },
}))

export function DamagesSections({ lastVisited }) {
  const { classes } = useStyles()
  const [sections, setSections] = useState([])
  const { caseId, handleUpdateStepStatus } = useFormContext()
  const { currentPlaintiff } = useOutletContext()
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  useQuery([queryKeys.damagesSections, caseId], fetchDamagesSectionsByCase, {
    onSuccess: async data => {
      if (data?.results?.length) {
        handleUpdateStepStatus({ status: STEP_STATUSES.completed })
      } else {
        handleUpdateStepStatus({ status: STEP_STATUSES.started })
      }
      setSections(data.results)
    },
  })

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.damages_sections
  })

  const getFilledSectionByTemplateAndPlaintiff = (templateId, filledList) => {
    if (!currentPlaintiff) return filledList

    if (!Array.isArray(filledList)) {
      return filledList
    }

    return (
      filledList.find(filledSection => {
        return filledSection?.plaintiff?.pk === currentPlaintiff.id && filledSection?.template === templateId
      }) || {}
    )
  }

  return (
    <Box>
      <SimpleDemandSkipSectionBanner caseId={caseId} />
      {sections?.length > 0 ? (
        <Box>
          {sections.map(section => (
            <Box
              key={`section-${section.pk}-plaintiff-${currentPlaintiff?.id}`}
              className={classes.sectionBox}
            >
              <DamagesSection
                caseId={caseId}
                section={
                  multiPlaintiffEnabled
                    ? getFilledSectionByTemplateAndPlaintiff(section.pk, section?.filled ?? [])
                    : section?.filled
                }
                template={section}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Alert
          className={classes.notice}
          severity="warning"
          action={
            <Button component={Link} to="/settings" color="inherit" size="small">
              Settings
            </Button>
          }
        >
          <AlertTitle>
            <strong>Custom Damages</strong> section not defined.
          </AlertTitle>
          Please go to the <strong>settings</strong> for the selected firm in the previous step to enable this
          section if needed.
        </Alert>
      )}
    </Box>
  )
}
