import { useMemo } from "react"
import { ReviewItem } from "./store/types"
import { getTextDiff } from "./utils"
import { AddedText, RemovedText, TextDiff } from "./styled"
import { Typography } from "@mui/material"

interface PlainTextDiffProps {
  reviewItem: ReviewItem
}

export function PlainTextDiff({ reviewItem }: PlainTextDiffProps) {
  const diff = useMemo<[string, string, string, string]>(() => {
    const suggestedText = reviewItem.userModifiedText ?? reviewItem.suggestedText
    return getTextDiff(reviewItem.originalText, suggestedText)
  }, [reviewItem])

  return (
    <TextDiff>
      <Typography variant="body2">
        {diff[0]}
        <RemovedText>{diff[1]}</RemovedText>
        <AddedText>{diff[2]}</AddedText>
        {diff[3]}
      </Typography>
    </TextDiff>
  )
}
