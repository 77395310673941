import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"
import { timeSpending } from "infrastructure/timeSpending"
import { INTAKE_STATUSES } from "requests/constants"

export const sendAnalytics = async (documentId: string, requestId: number, status: INTAKE_STATUSES) => {
  const exhibitManagementTimeSpent = await timeSpending.getTimeSpending({ documentId })
  const medicalChronologyTimeSpent = await timeSpending.getTimeSpending({
    type: MedicalChronologyAnalyticsEventTypes.MedicalSummaryPageTimeSpent,
    documentId,
  })

  if (exhibitManagementTimeSpent) {
    amplitudeApm.trackEvent(
      new MedicalChronologyAnalyticEvent(
        MedicalChronologyAnalyticsEventTypes.ExhibitManagementPageTimeSpent,
        {
          document_id: documentId,
          request_id: requestId,
          time: exhibitManagementTimeSpent,
          request_status: status,
        }
      )
    )
  }

  if (medicalChronologyTimeSpent) {
    amplitudeApm.trackEvent(
      new MedicalChronologyAnalyticEvent(MedicalChronologyAnalyticsEventTypes.MedicalSummaryPageTimeSpent, {
        document_id: documentId,
        request_id: requestId,
        request_status: status,
        time: medicalChronologyTimeSpent,
      })
    )
  }

  amplitudeApm.trackEvent(
    new MedicalChronologyAnalyticEvent(MedicalChronologyAnalyticsEventTypes.MedicalChronologySubmitted, {
      document_id: documentId,
      request_id: requestId,
    })
  )
}
