import { LastTreatmentEntity, TREATMENT_TIMELINE_ENTITY_TYPE } from "./types"
import { StyledLastTreatmentEntityTextWrapper, StyledLastTreatmentEntityText } from "./styled"

interface LastTreatmentEntityTextProps {
  entity: LastTreatmentEntity
}

export const LastTreatmentEntityText = ({ entity }: LastTreatmentEntityTextProps): JSX.Element => {
  return (
    <StyledLastTreatmentEntityTextWrapper>
      <StyledLastTreatmentEntityText
        rotated={entity.treatmentEntityType === TREATMENT_TIMELINE_ENTITY_TYPE.DAY}
      >
        {entity.text}
      </StyledLastTreatmentEntityText>
    </StyledLastTreatmentEntityTextWrapper>
  )
}
