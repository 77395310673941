import { createContext, useCallback, useMemo, useReducer, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { Loading } from "common"
import { Pagination } from "common/pagination"
import { PageSizeSelect } from "common/pagination/PageSizeSelect"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"
import { queryClient } from "react-query/queryClient"
import {
  DocumentStructureRowData,
  DocumentStructureViewModel,
  TableRowViewModel,
} from "common/models/library"
import { UPDATE_DOCUMENT_STRUCTURE_STATE_MESSAGES } from "settings/Library/TemplateForms/constants"
import { isNil, isUndefined, noop } from "lodash"
import useUser from "hooks/useUser"
import { documentStructureService } from "api/services/document-structure"
import { userService } from "api/services/users"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { DocumentStructureFormData } from "settings/Library/TemplateForms/types"
import { useDocumentStructureForm } from "settings/Library/TemplateForms/"
import { attributesService } from "api/services/attributes"
import { DialogButton } from "settings/Library/TemplateForms/buttons/DialogButton"
import { SectionWithAttributes } from "common/attributes-filter"
import { Collapse } from "@mui/material"
import { DocumentStructureDefinition } from "common/types/documentStructure"

import {
  useDocumentStructureItems,
  DocumentStructureStoreContext,
} from "../TemplateForms/formData/document-structure/store"
import { DOCUMENT_STRUCTURE_TREE_ACTION_TYPE } from "../TemplateForms/formData/document-structure/store/actions"
import { canEditLibrary, canEditLibraryByFirmsUserManage } from "../../permissions"
import { templatesTabReducer } from "../State/templatesReducer"
import { INITIAL_TEMPLATES_TAB_STATE, LibraryTabStateContext } from "../State/constants"
import { LibraryTable } from "../LibraryTable"
import { DocumentStructureFilters } from "../Filters/DocumentStructureFilters"
import { NewDocumentStructure } from "../Forms/NewDocumentStructure"
import { ArchivedDocumentStructureTable } from "./ArchivedDocumentStructureTable"
import { getDocumentStructureTitle } from "./utils"
import { createFinishEditTemplateAction, createRenderPageActionAction } from "../State/templatesActions"
import { canRoleEditEntityByFirmId } from "../TemplateForms/permissions"
import { FirmSectionTemplates } from "./FirmSectionTemplates"
import { FIRMS_SECTION_TEMPALATES } from "./constants"
import {
  DocumentStructureTablesWrapper,
  StyledFiltersWrapper,
  StyledPageSizeWrapper,
  StyledSeparator,
  StyledTableWrapper,
} from "./styled"

export const DocumentStructureCurrentFirmId = createContext<{
  setCurrentFirmId: (firmId: Nullable<PrimaryKey>) => void
  currentFirmId: Nullable<PrimaryKey>
}>({ setCurrentFirmId: noop, currentFirmId: null })

const INITIAL_SECTION_WITH_ATTIRIBUTES: SectionWithAttributes = { section: null, attributeValues: {} }

const getDuplicateData = (canManageInitialFirm: (rowData: DocumentStructureRowData) => boolean) => {
  return (rowData: DocumentStructureRowData): Partial<DocumentStructureRowData> => {
    return {
      initialAttributes: rowData.initialAttributes,
      initialDocumentName: rowData.initialDocumentName,
      initialFirmId: canManageInitialFirm(rowData) ? rowData.initialFirmId : null,
      initialLetterhead: rowData.initialLetterhead,
    }
  }
}

export function DocumentStructure(): Nullable<JSX.Element> {
  const [state, dispatch] = useReducer(templatesTabReducer, INITIAL_TEMPLATES_TAB_STATE)
  const [currentFirmId, setCurrentFirmId] = useState<Nullable<PrimaryKey>>(null)
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])
  const firmIdContextValue = useMemo(() => ({ setCurrentFirmId, currentFirmId }), [currentFirmId])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showArchived, setShowArchived] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const [sectionWithAttributes, setSectionWithAttributes] = useState(INITIAL_SECTION_WITH_ATTIRIBUTES)
  const [firmId, setFirmId] = useState<DocumentStructureFormData["firmId"]>(null)
  const [documentName, setDocumentName] = useState<DocumentStructureFormData["documentName"]>(null)
  const { user } = useUser()
  const { isFeatureEnabled } = useFeatures()
  const firmTemplateFeatureIsEnabled = isFeatureEnabled(FEATURES.FIRM_TEMPLATE)

  const firmTemplateFeature = isFeatureEnabled(FEATURES.FIRM_TEMPLATE)
  const { data: firmsUserManage } = useQuery(
    [queryKeys.firmsUserManage, user.id],
    () => userService.getFirmsUserManage(null),
    {
      enabled: user.isInternal && firmTemplateFeature,
    }
  )
  const canEditLibraryByFirm = canEditLibraryByFirmsUserManage(user.role, firmsUserManage)

  const getCanEdit = useCallback(
    (rowData: DocumentStructureRowData) =>
      firmTemplateFeatureIsEnabled
        ? canRoleEditEntityByFirmId(user.role, rowData.initialFirmId, firmsUserManage)
        : canEditLibrary(user.role),
    [firmTemplateFeatureIsEnabled, firmsUserManage, user.role]
  )

  const { data: attributes } = useQuery([queryKeys.attributes, "document-structure"], async () =>
    attributesService.getAvailableDocumentStructureAttributes(null)
  )

  const [documentStructures, dispatchDocumentStructureAction] = useDocumentStructureItems()

  const { isFetching, data: documentStructureList } = useQuery(
    [queryKeys.documentStructuresList, currentPage, pageSize, firmId, sectionWithAttributes, documentName],
    () =>
      documentStructureService.getDocumentStructuresList({
        firmId,
        attributes: sectionWithAttributes?.attributeValues,
        documentName,
        page: currentPage,
        pageSize,
      }),
    {
      enabled: Boolean(attributes),
      meta: { disableLoader: true },
      keepPreviousData: true,
      onSuccess: data => {
        dispatchDocumentStructureAction({
          type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.SET_ITEMS,
          payload: data.items.map(item => [item, DocumentStructureViewModel.idGetter.getRowId(item)]),
        })
      },
    }
  )

  const archiveDocumentStructure = useMutation(documentStructureService.archiveDocumentStructure, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.documentStructuresList])
    },
  })

  const restoreDocumentStructure = useMutation(documentStructureService.removeDraftDocumentStructure, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.documentStructuresList])
    },
  })

  const duplicateDocumentStructure = useMutation(documentStructureService.duplicateDocumentStructure, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.documentStructuresList])
    },
  })

  const handleArchive = useCallback(
    (documentStructureId?: number) => {
      if (isUndefined(documentStructureId)) return

      dispatch(createFinishEditTemplateAction())

      archiveDocumentStructure.mutate({ documentStructureId })
      queryClient.invalidateQueries([queryKeys.archivedDocumentStructures])
    },
    [archiveDocumentStructure]
  )

  const handleRestore = useCallback(
    (documentStructureId?: number) => {
      if (isUndefined(documentStructureId)) return

      dispatch(createFinishEditTemplateAction())

      restoreDocumentStructure.mutate({ documentStructureId })
    },
    [restoreDocumentStructure]
  )

  const handleReset = useCallback(() => {
    setFirmId(null)
    setDocumentName(null)
    setSectionWithAttributes(INITIAL_SECTION_WITH_ATTIRIBUTES)
  }, [])

  const handleDuplicate = useCallback(
    (documentStructure: DocumentStructureRowData) => {
      duplicateDocumentStructure.mutate({
        data: {
          firmId: null,
          attributes: null,
          documentName: null,
        },
        options: { documentStructureId: documentStructure.id },
      })
    },
    [duplicateDocumentStructure]
  )

  const getEditFormFooter = useCallback(
    (row: TableRowViewModel<DocumentStructureRowData>) => {
      return (
        <>
          <DialogButton
            title="Archive?"
            buttonText="Archive"
            confirmationText="Yes, Archive"
            question={
              <span>
                Are you sure you want to archive
                <br />
                <strong>{getDocumentStructureTitle(row.items)}?</strong>
              </span>
            }
            onAction={() => handleArchive(row.rowData.id)}
          />
          {row.rowData.isDraft && (
            <DialogButton
              title="Restore?"
              buttonText="Restore"
              confirmationText="Yes, Restore"
              question={
                <span>
                  Are you sure you want to restore the
                  <br />
                  <strong>{getDocumentStructureTitle(row.items)} </strong>
                  to previous published version?
                </span>
              }
              onAction={() => handleRestore(row.rowData.id)}
            />
          )}
        </>
      )
    },
    [handleArchive, handleRestore]
  )

  const handleBack = useCallback(() => {
    dispatch(createRenderPageActionAction({ page: null }))
  }, [dispatch])

  if (!documentStructureList || !attributes) {
    return (
      <LibraryTabStateContext.Provider value={contextValue}>
        <Loading show label="Loading document structures..." />
      </LibraryTabStateContext.Provider>
    )
  }

  const templatesTableData = new DocumentStructureViewModel(
    documentStructureList.items
      .map(item => documentStructures.items[DocumentStructureViewModel.idGetter.getRowId(item)])
      .filter((item): item is DocumentStructureDefinition => !!item),
    attributes
  )

  return (
    <LibraryTabStateContext.Provider value={contextValue}>
      <DocumentStructureStoreContext.Provider value={[documentStructures, dispatchDocumentStructureAction]}>
        <DocumentStructureCurrentFirmId.Provider value={firmIdContextValue}>
          <Collapse orientation="vertical" in={!!state.page}>
            {state.page?.startsWith(FIRMS_SECTION_TEMPALATES) && !isNil(currentFirmId) && (
              <FirmSectionTemplates page={state.page} firmId={currentFirmId} onBack={handleBack} />
            )}
          </Collapse>
          <Collapse orientation="vertical" in={!state.page}>
            <div>
              <StyledFiltersWrapper>
                <DocumentStructureFilters
                  documentName={documentName}
                  setFirmId={setFirmId}
                  setAttributes={setSectionWithAttributes}
                  setDocumentName={setDocumentName}
                  firmId={firmId}
                  attributes={sectionWithAttributes}
                  onReset={handleReset}
                />
                <StyledPageSizeWrapper>
                  <PageSizeSelect pageSize={pageSize} onChange={setPageSize} />
                </StyledPageSizeWrapper>
              </StyledFiltersWrapper>
              {canEditLibraryByFirm ? (
                <NewDocumentStructure showArchived={showArchived} setShowArchived={setShowArchived} />
              ) : (
                <StyledSeparator />
              )}
              <DocumentStructureTablesWrapper>
                <StyledTableWrapper hidden={showArchived} showArchived={showArchived}>
                  <LibraryTable
                    tableData={templatesTableData}
                    loading={isFetching}
                    getFormData={useDocumentStructureForm}
                    canDuplicate={true}
                    getCanEdit={getCanEdit}
                    getDuplicateData={getDuplicateData(getCanEdit)}
                    getEditFormFooter={getEditFormFooter}
                    entityName="document structures"
                    formStatusMessageMap={UPDATE_DOCUMENT_STRUCTURE_STATE_MESSAGES}
                    onDuplicateClick={handleDuplicate}
                  />
                </StyledTableWrapper>
                <StyledTableWrapper hidden={!showArchived} showArchived={showArchived}>
                  {showArchived && (
                    <ArchivedDocumentStructureTable
                      attributeFilters={sectionWithAttributes?.attributeValues}
                      pageSize={pageSize}
                      firmId={firmId}
                      documentName={documentName}
                    />
                  )}
                </StyledTableWrapper>
              </DocumentStructureTablesWrapper>
              {!showArchived && documentStructureList.count > 0 && (
                <Pagination
                  pageCount={documentStructureList.pageCount}
                  page={documentStructureList.page}
                  count={documentStructureList.count}
                  onChange={setCurrentPage}
                />
              )}
            </div>
          </Collapse>
        </DocumentStructureCurrentFirmId.Provider>
      </DocumentStructureStoreContext.Provider>
    </LibraryTabStateContext.Provider>
  )
}
