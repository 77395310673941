import { Range, Transforms } from "slate"
import { CustomEditor } from "../CustomEditor"
import { Editor } from "../Editor"
import { isEmptyNodeText } from "./text"
import { isBlockNode } from "../queries"

export function removeNodesAtRange(editor: CustomEditor): boolean {
  if (!editor.selection) return false

  let { focus, anchor } = editor.selection

  if (Range.isBackward(editor.selection)) {
    focus = editor.selection.anchor
    anchor = editor.selection.focus
  }

  const selectedBlocks = Editor.getActiveBlocks(editor).map(([, path]) => path)
  const { isStart: isStartOfAnchor } = Editor.getCursorPositionInNode(editor, anchor, selectedBlocks[0])
  const { isStart: isStartOfFocus } = Editor.getCursorPositionInNode(
    editor,
    focus,
    selectedBlocks[selectedBlocks.length - 1]
  )

  if (isStartOfAnchor && isStartOfFocus) {
    selectedBlocks
      .slice(0, -1)
      .reverse()
      .forEach(path => Transforms.removeNodes(editor, { at: path }))

    return true
  }

  return false
}

export function deleteFragmentAtEditorRange(editor: CustomEditor): boolean {
  if (!editor.selection) return false

  const [currentNode] = Editor.parent(editor, editor.selection.anchor.path)

  if (Range.isExpanded(editor.selection)) {
    return removeNodesAtRange(editor)
  }

  const currentBlockIsEmpty = isBlockNode(editor, currentNode) && isEmptyNodeText(editor, currentNode)
  const moreThanOneBlock = editor.children.length > 1

  if (currentBlockIsEmpty) editor.removeMark("flags")

  if (currentBlockIsEmpty && moreThanOneBlock) {
    const { selection } = editor
    const previousNodeEntry = Editor.previous(editor)
    const nextSelectionPoint = previousNodeEntry ? Editor.end(editor, previousNodeEntry[1]) : null
    Transforms.removeNodes(editor, { at: selection })

    if (nextSelectionPoint) {
      Transforms.select(editor, nextSelectionPoint)
    }

    if (Editor.isInList(editor)) {
      Editor.normalize(editor, { force: true })
    }

    return true
  }

  return false
}
