import { Box, Divider, Drawer, IconButton } from "@mui/material"
import { LeftNav } from "demand/LeftNav"
import {
  LeftNavStates,
  getLeftNavStateFromLocalStorage,
  setLeftNavStateFromLocalStorage,
} from "demand/LeftNav/utils"
import { LeftNavContext } from "demand/context"
import React, { ReactNode, useMemo, useState } from "react"
import Close from "@mui/icons-material/Close"
import FormSections from "demand/FormSections"
import { LeftNavCaseSection } from "demand/LeftNav/LeftNavCaseSection"
import { DemandPreview } from "demand/DemandPreview"
import { LeftNavLink } from "demand/LeftNav/LeftNavLink"
import { useQuery } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { getRequestByCase } from "api"

type DemandSidebarProps = {
  caseId: PrimaryKey
  children?: ReactNode
}

// MUST be wrapped in a `<DemandSteps>`.  This component is tightly coupled with the demand provider inside of it
const DemandSidebar: React.FC<DemandSidebarProps> = ({ caseId, children }) => {
  const getLeftNavInitialState = () => getLeftNavStateFromLocalStorage()
  const [leftNavTabIsExpanded, setLeftNavTabIsExpanded] = useState(getLeftNavInitialState)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const { data: request } = useQuery([queryKeys.request, caseId], getRequestByCase, SILENT_QUERY_PARAMS)

  const leftNavTabContextValue = useMemo(
    () => ({
      leftNavTabIsExpanded,
      setLeftNavTabIsExpanded: (value: boolean) => {
        setLeftNavStateFromLocalStorage(value ? LeftNavStates.OPEN : LeftNavStates.CLOSED)
        setLeftNavTabIsExpanded(value)
      },
      mobileNavIsOpen: mobileNavOpen,
      setMobileNavIsOpen: (value: boolean) => setMobileNavOpen(value),
    }),
    [leftNavTabIsExpanded, setLeftNavTabIsExpanded, mobileNavOpen]
  )

  return (
    <>
      {/* Left Nav Main View.  Also contains contracted view */}
      <LeftNavContext.Provider value={leftNavTabContextValue}>
        <LeftNav caseId={caseId} request={request} />
        {/* Mobile View */}
        <Drawer
          anchor="left"
          open={mobileNavOpen}
          onClose={() => setMobileNavOpen(false)}
          onClick={() => setMobileNavOpen(false)}
        >
          <Box display="flex" justifyContent="end">
            <IconButton>
              <Close />
            </IconButton>
          </Box>
          <Box px={2} pb={6}>
            <FormSections request={request} />
            <LeftNavCaseSection />
            <LeftNavLink label="Review and Generate Document" toLink="review" dataTest="case-review" />
            <Box mt={1} mb={1}>
              <Divider variant="middle" />
            </Box>
            <Box width="100%" display="flex" justifyContent="center">
              <DemandPreview caseId={caseId} extendedExhibits={false} request={request} />
            </Box>
          </Box>
        </Drawer>
        {children}
      </LeftNavContext.Provider>
    </>
  )
}

export default DemandSidebar
