import { noop } from "lodash"
import { MessageReply, OnErrorCallback, Publish } from "./types"
import { useMessageBroker } from "./useMessageBroker"
import { useCallback, useEffect, useRef } from "react"
import { REACTIVATED_EVENT_TOPIC } from "./topics"
import { getServiceWorker } from "./getServiceWorker"

interface UsePublishProps {
  onError?: OnErrorCallback
}

export function usePublish({ onError = noop }: UsePublishProps = {}): Publish {
  const [publish] = useMessageBroker()
  const onErrorRef = useRef(onError)
  onErrorRef.current = onError

  const abortControllerRef = useRef(new AbortController())

  useEffect(() => {
    function handleReactivate(event: Event) {
      if (event instanceof MessageEvent && event.data.topic === REACTIVATED_EVENT_TOPIC) {
        const controller = abortControllerRef.current
        abortControllerRef.current = new AbortController()
        controller.abort()
      }
    }
    navigator.serviceWorker.addEventListener("message", handleReactivate)

    return () => navigator.serviceWorker.removeEventListener("message", handleReactivate)
  }, [])

  const safePublish = useCallback<Publish>(
    (topic, payload) => {
      return new Promise((resolve, reject) => {
        async function tryPublish() {
          const serviceWorker = await Promise.race([
            getServiceWorker(),
            new Promise<null>(resolve => setTimeout(() => resolve(null), 300)),
          ])

          if (!serviceWorker) {
            return reject({
              success: false,
              error: "No service worker found",
            })
          }

          if (serviceWorker.state === "redundant") {
            return reject({
              success: false,
              error: "Found reduntant service worker",
            })
          }

          async function retry() {
            if ((await getServiceWorker()) !== serviceWorker) {
              setTimeout(() => tryPublish(), 100)
            } else {
              abortControllerRef.current.signal.addEventListener("abort", retry)
            }
          }

          const abortController = abortControllerRef.current
          abortController.signal.addEventListener("abort", retry)

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          publish(topic as any, payload as any)
            .catch<MessageReply>(reason => {
              onErrorRef.current(reason)
              return {
                success: false,
                error: reason,
              }
            })
            .then(reply => {
              abortController.signal.removeEventListener("abort", retry)
              abortControllerRef.current.signal.removeEventListener("abort", retry)

              if (reply.success) {
                resolve(reply)
              } else {
                reject(reply)
              }
            })
        }

        tryPublish()
      })
    },
    [publish]
  )

  return safePublish
}
