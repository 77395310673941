import styled from "@emotion/styled"
import TableRow from "@mui/material/TableRow"

export const StyledTableRow = styled(TableRow)(() => ({
  textDecoration: "none",
  "&:focus": {
    outline: "1px solid #1976d2",
    outlineOffset: "-1px",
  },
}))
