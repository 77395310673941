/* eslint-disable no-console */
import { StateCreator, StoreMutatorIdentifier } from "zustand"
import * as Sentry from "@sentry/react"
import { isDevEnvironment, isTestEnvironment } from "infrastructure/env/getEnvironment"

// see this for reference
// https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#middleware-that-doesnt-change-the-store-type
type Logger = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
  f: StateCreator<T, Mps, Mcs>,
  name?: string
) => StateCreator<T, Mps, Mcs>

type LoggerImpl = <T>(f: StateCreator<T, [], []>, name?: string) => StateCreator<T, [], []>

const blue = "#829ab1"
const green = "#a4b48f"
const red = "#ea5b49"

const log = (message: string, data: Record<string, unknown>) => {
  if (isTestEnvironment()) {
    return
  }

  if (isDevEnvironment()) {
    console.log(message, data)
  } else {
    Sentry.addBreadcrumb({
      category: "zustand",
      message,
      data,
    })
  }
}

const logAction = (state: unknown, value: unknown) => {
  try {
    if (typeof value === "function") {
      log("set state", { value: value(state) })
      return
    }

    log("set state", { value })
  } catch (error) {
    Sentry.captureMessage("Error logging zustand action", {
      level: "warning",
      extra: {
        state,
        value,
        error,
      },
    })
  }
}

const loggerImpl: LoggerImpl = f => (set, get, store) => {
  const shouldLogAdditionalInfo = isDevEnvironment() && !isTestEnvironment()

  const patchedSet = (...args: Parameters<typeof set>) => {
    if (shouldLogAdditionalInfo) {
      console.groupCollapsed("Demand Store Update")
      console.log(`%cOld State`, `color: ${red}`)
      console.log(get())
      console.log(`%cApplying:`, `color: ${blue};`)
    }

    logAction(get(), args[0])
    set(...args)

    if (shouldLogAdditionalInfo) {
      console.log(`%cNew State`, `color: ${green}`)
      console.log(get())
      console.groupEnd()
    }
  }
  store.setState = patchedSet
  return f(patchedSet, get, store)
}

export const logger = loggerImpl as unknown as Logger

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const actionsWithLogging = <T extends Record<string, (...args: any[]) => unknown>>(actions: T): T => {
  return new Proxy(actions, {
    get(target, prop, receiver) {
      const fn = Reflect.get(target, prop, receiver)
      if (typeof fn === "function") {
        return (...args: unknown[]) => {
          log(`action: ${prop.toString()}`, { args })
          return fn.apply(receiver, args)
        }
      }

      return fn
    },
  })
}
