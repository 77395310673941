import React, { useCallback, useRef, useState } from "react"
import { ReviewItem } from "./store/types"
import { inputBaseClasses, outlinedInputClasses, TextField } from "@mui/material"
import styled from "@emotion/styled"

const StyledTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.grey[50],
  borderRadius: 0,

  [`& textarea`]: {
    fontSize: 14,
  },

  [`& .${inputBaseClasses.root}`]: {
    padding: theme.spacing(1.25),
  },

  [`&, &.Mui-focused`]: {
    [`&& .${outlinedInputClasses.notchedOutline}`]: {
      borderRadius: 0,
      borderWidth: 1,
    },
  },
}))

interface PlainTextDiffEditProps {
  reviewItem: ReviewItem
  onChange: (text: string) => void
  disabled?: boolean
}

export function PlainTextDiffEdit({ reviewItem, onChange, disabled }: PlainTextDiffEditProps) {
  const [editedText, setEditedText] = useState(reviewItem.userModifiedText ?? reviewItem.suggestedText)
  const onChangeRef = useRef(onChange)
  onChangeRef.current = onChange

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const nextText = e.target.value
    setEditedText(nextText)
    onChangeRef.current(nextText)
  }, [])

  return (
    <StyledTextField
      id="user-suggestion"
      value={editedText}
      onChange={handleChange}
      disabled={disabled}
      multiline
      autoFocus
    />
  )
}
