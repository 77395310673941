import { ReactNode, useCallback } from "react"
import styled from "@emotion/styled"

const StyledCitationValue = styled("span")({
  letterSpacing: "-0.4px",
  color: "#01579B",
  fontWeight: 600,
  textDecoration: "underline",
  cursor: "pointer",

  "&:hover": {
    textDecoration: "none",
  },
})

export const CitationValue = ({
  onClick,
  range,
  index,
  children,
}: {
  onClick: (page: Nullable<number>) => void
  range: number[]
  index: number
  children: ReactNode
}): JSX.Element => {
  const firstPage = range[0]

  const handlePageOnClick = useCallback(() => {
    onClick(Number(firstPage))
  }, [onClick, firstPage])

  return (
    <>
      {index !== 0 && ", "}
      <StyledCitationValue onClick={handlePageOnClick}>
        {children}
        {range.length > 1 && firstPage + " - " + range[range.length - 1]}
        {range.length == 1 && firstPage}
      </StyledCitationValue>
    </>
  )
}
