import React from "react"
import { makeStyles } from "tss-react/mui"

import { APP_CONTENT_ID } from "./constants"
import { Outlet } from "react-router-dom"
import { withSuspense } from "common/withSuspense"
import { GenericErrorForSuspense } from "common"
import { DocumentSkeleton } from "documents/skeletons/DocumentSkeleton"

const useStyles = makeStyles()(theme => ({
  main: {
    // Force main's children to only occupy the "center" column.
    // The children can override it so they take up different columns if they want to
    "& > *": {
      gridColumn: 2,
    },
    gridTemplateColumns: `${theme.spacing(2)} auto ${theme.spacing(2)}`,
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: `${theme.spacing(6)} auto ${theme.spacing(6)}`,
    },
    display: "grid",
  },
}))

const MainContent = withSuspense(
  React.memo(function MainContent() {
    const { classes } = useStyles()
    return (
      <main className={classes.main} id={APP_CONTENT_ID}>
        <Outlet />
      </main>
    )
  }),
  <DocumentSkeleton />,
  <GenericErrorForSuspense />
)

export default function Main() {
  return <MainContent />
}
