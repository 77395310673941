import { Button } from "@mui/material"
import React, { useContext } from "react"
import Menu from "@mui/icons-material/Menu"
import { LeftNavContext } from "demand/context"
import styled from "@emotion/styled"
import { useDemandSteps } from "demand/steps"

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}))

const MobileNavButton: React.FC = () => {
  const { setMobileNavIsOpen } = useContext(LeftNavContext)
  const { items, currentStep } = useDemandSteps()
  const currentStepIndex = currentStep?.index ?? -1
  const totalStepCount = items.length
  return (
    <StyledButton variant="outlined" startIcon={<Menu />} onClick={() => setMobileNavIsOpen(true)}>
      {currentStepIndex + 1}/{totalStepCount}
    </StyledButton>
  )
}

export default MobileNavButton
