import { DOCUMENT_STRUCTURE_BLOCK_TYPE, DOCUMENT_STRUCTURE_ITEM_TYPE } from "../../enums"
import { DocumentStructureStoreData } from "../types"

export function deleteDocumentStructureElement(
  state: DocumentStructureStoreData,
  id: string
): DocumentStructureStoreData {
  if (!state.sections[id] && !state.blocks[id]) return state

  const item = state.sections[id] || state.blocks[id]
  let nextState = { ...state }

  delete nextState.sections[id]
  delete nextState.blocks[id]

  const parent = Object.entries(nextState.structure).find(([, children = []]) =>
    children.find(childId => childId === id)
  )
  const children = nextState.structure[id] ?? []

  if (!parent) {
    for (const child of children) {
      nextState = deleteDocumentStructureElement(nextState, child)
    }

    return nextState
  }
  const [parentId, parentChildren = []] = parent
  const index = parentChildren.indexOf(id)
  const [siblingsBefore, siblingsAfter] = [parentChildren.slice(0, index), parentChildren.slice(index + 1)]

  delete nextState.structure[id]

  if (
    item &&
    item.type === DOCUMENT_STRUCTURE_ITEM_TYPE.BLOCK &&
    item.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.HEADING
  ) {
    nextState.structure[parentId] = [...siblingsBefore, ...children, ...siblingsAfter]
  } else {
    for (const child of children) {
      nextState = deleteDocumentStructureElement(nextState, child)
    }
  }

  return nextState
}
