import { Editor, NodeEntry, Path } from "slate"
import { CustomEditor, CustomText, EmptySpaceElement, VariableElement } from "../../CustomEditor"
import { getStyleProps, getStyles, getWordDiff, WordDiff } from "./utils"
import { getEditorRange, isVariableNode, isVariablesEditor } from "../../queries"
import { createEmptySpace, createParagraph, createText } from "../../create"
import { NO_MATCH_SUGGESTION_FLAG } from "../../constants"

export function applyTextSuggestion(
  editor: CustomEditor,
  suggestionId: string,
  originalText: string,
  suggestedText: string
): number {
  const wordDiff = getWordDiff(originalText, suggestedText)

  if (!wordDiff) return 0

  const nodesIterator = Editor.nodes<CustomText | VariableElement | EmptySpaceElement>(editor, {
    at: getEditorRange(editor),
    match: node => {
      return "flags" in node && !!node.flags?.includes(suggestionId)
    },
  })

  const groups: NodeEntry<CustomText | VariableElement | EmptySpaceElement>[][] = []

  for (const nodeEntry of nodesIterator) {
    const [, path] = nodeEntry
    const lastGroup = groups[groups.length - 1]
    const previousNodeEntry = Editor.previous(editor, { at: path, mode: "lowest" })

    if (!lastGroup || !previousNodeEntry || previousNodeEntry[0] !== lastGroup[lastGroup.length - 1][0]) {
      groups.push([nodeEntry])
      continue
    }

    lastGroup.push(nodeEntry)
  }

  for (const group of groups) {
    const anchor = editor.start(group[0][1])
    const groupLastNode = group[group.length - 1][0]
    const possibleFocus = editor.end(group[group.length - 1][1])
    const isEndWithVariable = isVariableNode(groupLastNode)
    const focus = isEndWithVariable
      ? editor.start((editor.next({ at: possibleFocus }) as NodeEntry)[1])
      : possibleFocus
    const flags = group[0][0].flags

    if (!suggestedText) {
      const emptySpace = createEmptySpace()
      emptySpace.flags = flags
      editor.insertNode(emptySpace, { at: { anchor, focus } })
      continue
    }

    if (flags && flags.includes(NO_MATCH_SUGGESTION_FLAG)) {
      editor.withoutNormalizing(() => {
        editor.delete({ at: { anchor, focus } })
        editor.insertNode(createParagraph([createText({ text: suggestedText, flags })]), {
          at: Path.next([editor.children.length - 1]),
        })
      })
      continue
    }

    const { deleted: deletedText, inserted: insertedText } =
      isEndWithVariable && isVariablesEditor(editor)
        ? (getWordDiff(
            originalText.replaceAll(
              editor.variables.getItem(groupLastNode.name)?.value ?? `[${groupLastNode.name}]`,
              (value, index: number) => {
                return index + value.length >= wordDiff.offsetFromStart &&
                  index + wordDiff.offsetFromEnd <= originalText.length
                  ? ""
                  : value
              }
            ),
            suggestedText
          ) as WordDiff)
        : wordDiff

    editor.withoutNormalizing(() => {
      const anchorNode = group[0][0]
      const styles = getStyleProps(getStyles(anchorNode))

      if (deletedText === "") {
        const previousNodeEntry = editor.previous({ at: anchor }) as NodeEntry
        const previousEnd = editor.end(previousNodeEntry[1])
        editor.delete({ at: anchor })
        editor.insertNode({ text: insertedText, ...styles, flags }, { at: previousEnd, voids: true })
      } else {
        editor.insertNode({ text: insertedText, ...styles, flags }, { at: { anchor, focus }, voids: true })
      }
    })
  }

  return groups.length
}
