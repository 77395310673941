import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import MultiSelectAutoComplete, { MultiSelectAutoCompleteProps } from "evenup-ui/MultiSelectAutoComplete"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

export function DocType() {
  const selectedDocTypes = useExhibitBuilderStore(useShallow(state => state.selectedDocTypes))
  const docTypes = useExhibitBuilderStore(useShallow(state => state.docTypes))
  const options = useMemo(
    () =>
      Object.values(docTypes).map(docType => ({
        label: docType.name,
        value: docType.key,
      })),
    [docTypes]
  )

  const value = useMemo(
    () => options.filter(option => selectedDocTypes?.includes(option.value)),
    [selectedDocTypes, options]
  )

  const handleChange: MultiSelectAutoCompleteProps<(typeof options)[number]>["onChangeValue"] = options => {
    exhibitBuilderActions.selectDocTypes(options.map(option => option.value))
  }

  return (
    <MultiSelectAutoComplete
      size="small"
      label="Doc Type"
      value={value}
      options={options}
      onChangeValue={handleChange}
    />
  )
}
