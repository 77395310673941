import type { Editor, Node, NodeEntry } from "slate"
import { Transforms } from "slate"
import { isElementsOfTheSameType } from "../elementTypes"
import { getPreviousSibling } from "../siblings"
import { isInList, isList } from "./queries"

export function mergeListWithPreviousSiblingList(editor: Editor, [node, path]: NodeEntry<Node>): boolean {
  if (!isList(editor, node)) {
    // This function does not know how to normalize other nodes.
    return false
  }

  const previousSibling = getPreviousSibling(editor, path)

  if (!previousSibling) {
    // Nothing to merge with.
    return false
  }

  const [previousSiblingNode] = previousSibling

  if (!isList(editor, previousSiblingNode)) {
    return false
  }

  const isNestedList = isInList(editor, path)
  const isPreviousSiblingSameListType = isElementsOfTheSameType(editor, node, previousSiblingNode)

  if (!isPreviousSiblingSameListType && !isNestedList) {
    // If previous sibling "list" is of a different type, then this fix does not apply
    // unless we're working with nested "lists".
    return false
  }

  Transforms.mergeNodes(editor, { at: path })

  return true
}
