import styled from "@emotion/styled"
import { OutlinedInput, outlinedInputClasses } from "@mui/material"

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  margin: theme.spacing(-0.5, 0),
  flex: 1,
  letterSpacing: "-0.15px",
  color: theme.palette.grey[800],

  [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: 1,
  },
}))
