import styled from "@emotion/styled"
import Box from "@mui/material/Box"
import { default as MuiCard } from "@mui/material/Card"
import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import { theme } from "app/theme"
import { SelectableCardProps, StyledSelectableCardProps, TypographyProps } from "./types"

export const StyledBoxIcon = styled(Box)(({ paletteType }: Pick<SelectableCardProps, "paletteType">) => ({
  borderRadius: theme.spacing(3),
  fontSize: "0",
  "& .MuiSvgIcon-root": {
    color:
      paletteType === "primary" ? theme.palette.selectableCard.fontColor : theme.palette.background.default,
  },
}))

/**
 * isSelected and type destructured to prevent it being passed
 * into MuiCard and causing unknown attribute on DOM element
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BaseCard = ({ children, isSelected, type, size, ...props }: SelectableCardProps) => (
  <MuiCard {...props}> {children} </MuiCard>
)

function getBackgroundColour(type: StyledSelectableCardProps["paletteType"], disabled: boolean): string {
  if (disabled) {
    return `${theme.palette.disabled.background}`
  }
  switch (type) {
    case "primary":
      return `${theme.palette.background}`
    case "secondary":
      return `${theme.palette.selectableCard.secondaryBgColor}`
  }
}

function getBorder(isSelected: boolean, disabled: boolean): string {
  if (disabled) {
    return `1px solid ${theme.palette.disabled.border}`
  }
  if (isSelected) {
    return `1px solid ${theme.palette.selectableCard.selectedBorderColor}`
  }
  return `1px solid ${theme.palette.blue.bannerStroke}`
}

export const StyledSelectableCard = styled(BaseCard)<StyledSelectableCardProps>(
  ({ isSelected, isDisabled = false, paletteType, size, fullSize }) => ({
    position: "relative",
    borderRadius: theme.spacing(1),
    backgroundBlendMode: "multiply",
    backgroundColor: getBackgroundColour(paletteType, isDisabled),
    border: getBorder(isSelected, isDisabled),
    ...(size === "small"
      ? { minWidth: theme.spacing(32), maxWidth: theme.spacing(32) }
      : {
          minWidth: theme.spacing(64),
          maxWidth: theme.spacing(128),
        }),
    width: fullSize ? "100%" : "fit-content",
    ...(isSelected
      ? {
          boxShadow: `0px 4px 11px 0px ${theme.palette.selectableCard.boxShadowColor}`,
        }
      : {
          ":hover": isDisabled
            ? {}
            : {
                cursor: "pointer",
                boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 ${
                  theme.palette.selectableCard.boxShadowColor
                }`,
                backgroundColor:
                  paletteType === "primary"
                    ? `${theme.palette.selectableCard.primaryBgColor}`
                    : `${theme.palette.selectableCard.secondaryBgColor}`,
              },
        }),
  })
)

const BaseTypography = ({ children, component = "p", ...props }: TypographyProps) => (
  <Typography component={component} {...props}>
    {children}
  </Typography>
)

export const StyledTypographyTitle = styled(BaseTypography)<Pick<SelectableCardProps, "size" | "isDisabled">>(
  ({ size, isDisabled = false }) => ({
    color: isDisabled ? theme.palette.text.disabled : theme.palette.selectableCard.fontColor,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    position: "relative",
    marginBottom: size === "large" ? 0 : theme.spacing(1),
    textAlign: size === "large" ? "left" : "center",
    alignSelf: size === "large" ? "start" : "center",
  })
)

export const StyledTypographyDescription = styled(BaseTypography)<
  Pick<SelectableCardProps, "size" | "isDisabled">
>(({ size, isDisabled = false }) => ({
  color: isDisabled ? theme.palette.text.disabled : theme.palette.selectableCard.fontColor,
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.typography.pxToRem(18),
  textAlign: size === "large" ? "left" : "center",
}))

export const StyledWrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "100%",
}))

export const StyledEvenUp = styled(Chip)(({ disabled }) => ({
  position: "absolute",
  top: theme.spacing(1.5),
  right: theme.spacing(7),
  alignSelf: "end",
  backgroundColor: disabled ? theme.palette.disabled.darker : theme.palette.blue.main,
  "&.Mui-disabled": {
    opacity: 1,
  },
  color: theme.palette.background.default,
  fontSize: theme.typography.pxToRem(13),
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(1),
}))

export const StyledContent = styled(Box)({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(3),
})

export const TopPanelWrapper = styled(Box)({
  position: "absolute",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: theme.spacing(10.5),
  top: 0,
  left: 0,
  width: "100%",
  padding: theme.spacing(0.5, 2.5, 2.5),
  background: theme.palette.common.white,
  borderBottom: "1px solid #9DB6FF",
})

export const StyledColumnBox = styled(Box)({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(5, 4),
  maxWidth: theme.spacing(32),
})

export const StyledBadgeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
}))

export const StyledBadge = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: "100px",
  padding: "0 7px",
}))
