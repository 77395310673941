import { PropsWithChildren, memo, useCallback, useMemo } from "react"
import {
  DropdownContainer,
  DropdownLabel,
  Dropdowns,
  SortingProviderContainer,
  SortingProviderDisplay,
  UserExhibitItemContainer,
} from "./styled"
import {
  SortableNestedListItem,
  SortableNestedListItemProps,
} from "common/nested-list/SortableNestedListItem"
import { Collapse, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { plaintiffsSelectors } from "exhibit-builder/store/plaintiffs"
import { DndUserExhibitData, UserExhibit } from "exhibit-builder/store/types"
import { useShallow } from "zustand/react/shallow"
import { red } from "@mui/material/colors"
import { EditableSelect } from "evenup-ui/EditableSelect"
import CircleIcon from "@mui/icons-material/Circle"
import { PROVIDER_KEY, UNASSIGNED_KEY } from "exhibit-builder/store/constants"
import { PDFStatus } from "./PDFStatus"
import { Tag } from "./Tag"

const ItemValue = ({ name, value }: { name?: string; value?: string }) => {
  return !value || value === UNASSIGNED_KEY ? <span style={{ color: red[700] }}>Unassigned</span> : name
}

export const UserExhibitItem = memo(function UserExhibitItem({
  id,
  item,
  children,
  ...props
}: PropsWithChildren<SortableNestedListItemProps<DndUserExhibitData>> & { id: UserExhibit["id"] }) {
  const userExhibit = useExhibitBuilderStore(filesSelectors.getUserExhibitById(id))
  const docTypes = useExhibitBuilderStore(state => state.docTypes)
  const plaintiffs = useExhibitBuilderStore(plaintiffsSelectors.getPlaintiffs)
  const collapsed = useExhibitBuilderStore(useShallow(state => state.collapseStates[id]))
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const providers = useExhibitBuilderStore(useShallow(state => state.providers))

  const handleDocTypeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      exhibitBuilderActions.updateUserExhibit({ id, docType: event.target.value, subDocType: null })
    },
    [id]
  )

  const handleSubDocTypeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      exhibitBuilderActions.updateUserExhibit({
        id,
        subDocType: event.target.value,
      })
    },
    [id]
  )

  const handlePlaintiffChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      exhibitBuilderActions.updateUserExhibit({ id, plaintiffId: event.target.value })
    },
    [id]
  )

  const handleProviderCreate = useCallback((name: string) => exhibitBuilderActions.createProvier(name), [])

  const handleProviderRename = useCallback(
    (providerId: string, name: string) => exhibitBuilderActions.renameProvider({ providerId, name }),
    []
  )

  const handleSortingProviderChange = useCallback(
    (providerId: string | null) => {
      exhibitBuilderActions.updateUserExhibit({ id, sortingProviderId: providerId })
    },
    [id]
  )

  const selectedDocType = userExhibit.docType ? docTypes[userExhibit.docType] : null
  const showSortingProvider = userExhibit.docType === PROVIDER_KEY
  const sortingProvider = userExhibit.sortingProviderId
    ? providers[userExhibit.sortingProviderId]
    : Object.values(providers)[0]
  const oneRow = !showSortingProvider

  const subDocTypesMap = useMemo(() => {
    const subDocTypes: Record<string, { key: string; name: string }> = {}
    for (const subDocType of selectedDocType?.subDocTypes ?? []) {
      subDocTypes[subDocType.key] = subDocType
    }
    return subDocTypes
  }, [selectedDocType])

  return (
    <UserExhibitItemContainer data-test="user-exhibit-item" isHighlighted={userExhibit.isHighlighted}>
      <SortableNestedListItem {...props} item={item}>
        <Collapse in={!collapsed} mountOnEnter unmountOnExit data-test="user-exhibit-item-content">
          <PDFStatus id={id} />
          <Dropdowns oneRow={oneRow}>
            <DropdownContainer>
              <DropdownLabel>Doc Type:</DropdownLabel>
              <Select
                value={userExhibit.docType ?? ""}
                onChange={handleDocTypeChange}
                data-test="user-exhibit-doc-type-dropdown"
                displayEmpty
                renderValue={value => <ItemValue name={docTypes[value]?.name} value={docTypes[value]?.key} />}
                readOnly={isReadOnly}
              >
                {Object.values(docTypes).map((section, index) => (
                  <MenuItem key={index} value={section.key} data-test={section.name}>
                    {section.name}
                  </MenuItem>
                ))}
              </Select>
            </DropdownContainer>
            {Boolean(selectedDocType?.subDocTypes?.length) && (
              <DropdownContainer>
                <DropdownLabel>Sub-Doc Type:</DropdownLabel>
                <Select
                  value={userExhibit.subDocType ?? ""}
                  onChange={handleSubDocTypeChange}
                  renderValue={value => (
                    <ItemValue name={subDocTypesMap[value]?.name} value={subDocTypesMap[value]?.key} />
                  )}
                  data-test="user-exhibit-sub-doc-type-dropdown"
                  readOnly={isReadOnly}
                >
                  {selectedDocType?.subDocTypes?.map((subDocType, index) => (
                    <MenuItem key={index} value={subDocType.key}>
                      {subDocType.name}
                    </MenuItem>
                  ))}
                </Select>
              </DropdownContainer>
            )}
            {showSortingProvider && (
              <DropdownContainer data-test="sorting-provider">
                <DropdownLabel>Sorting Provider:</DropdownLabel>
                <SortingProviderContainer>
                  <EditableSelect
                    readOnly={isReadOnly}
                    sx={{ width: "100%" }}
                    value={userExhibit.sortingProviderId ?? ""}
                    options={Object.values(providers)}
                    onChange={handleSortingProviderChange}
                    onCreate={handleProviderCreate}
                    onRename={handleProviderRename}
                    startAdornment={
                      sortingProvider ? (
                        <SortingProviderDisplay>
                          <CircleIcon htmlColor={sortingProvider?.color} fontSize="small" />
                          {sortingProvider?.name}
                        </SortingProviderDisplay>
                      ) : (
                        "None"
                      )
                    }
                  />
                </SortingProviderContainer>
              </DropdownContainer>
            )}

            <DropdownContainer>
              <DropdownLabel>Plaintiff:</DropdownLabel>
              <Select
                value={userExhibit.plaintiffId ?? ""}
                onChange={handlePlaintiffChange}
                data-test="user-exhibit-plaintiff-dropdown"
                readOnly={isReadOnly}
              >
                {Object.values(plaintiffs).map((plaintiff, index) => (
                  <MenuItem key={index} value={plaintiff.id}>
                    {plaintiff.name}
                  </MenuItem>
                ))}
              </Select>
            </DropdownContainer>

            {userExhibit.tag && <Tag tag={userExhibit.tag} />}
          </Dropdowns>
          {children}
        </Collapse>
      </SortableNestedListItem>
    </UserExhibitItemContainer>
  )
})
