import styled from "@emotion/styled"
import { Alert, Box, Button } from "@mui/material"

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: theme.spacing(2),
}))

export const OutlinedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,
  borderRadius: "6px",
}))

export const HelpTextAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.blue.banner,
  border: `solid 2px ${theme.palette.blue.bannerStroke}`,
  "& .MuiSvgIcon-root": {
    color: theme.palette.secondary.main,
  },
}))

export const WarningText = styled("p")(() => ({
  fontSize: "12px",
}))
