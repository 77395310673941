import { FileVerificationProcessingStatus } from "api/services/file"
import { toConstEnum } from "common/state/enumToConst"
import { Action } from "common/state/types"

enum FILE_UPLOAD_ACTIONS_ENUM {
  CREATE_UPLOAD,
  PROCESS_UPLOAD,
  UPDATE_PROGRESS,
  COMPLETE_UPLOAD,
  ASSIGN_WITH_EXTERNAL,
  UNASSIGN_FROM_EXTERNAL,
  DELETE,
  CANCEL,
  FAIL,
}

export const FILE_UPLOAD_ACTIONS = toConstEnum(FILE_UPLOAD_ACTIONS_ENUM)
export type FILE_UPLOAD_ACTIONS_TYPE = typeof FILE_UPLOAD_ACTIONS

export type UpdateProgressAction = Action<FILE_UPLOAD_ACTIONS_TYPE["UPDATE_PROGRESS"], { chunkIndex: number }>
export type CreateUploadAction = Action<FILE_UPLOAD_ACTIONS_TYPE["CREATE_UPLOAD"], { chunkCount: number }>
export type ProcessUploadAction = Action<
  FILE_UPLOAD_ACTIONS_TYPE["PROCESS_UPLOAD"],
  { uploadId: string; processingStatus: FileVerificationProcessingStatus }
>
export type CompleteUploadAction = Action<
  FILE_UPLOAD_ACTIONS_TYPE["COMPLETE_UPLOAD"],
  { info?: Nullable<string>; processingStatus?: FileVerificationProcessingStatus }
>
export type AssignUploadAction = Action<
  FILE_UPLOAD_ACTIONS_TYPE["ASSIGN_WITH_EXTERNAL"],
  { externalId: PrimaryKey }
>
export type UnassignUploadAction = Action<
  FILE_UPLOAD_ACTIONS_TYPE["UNASSIGN_FROM_EXTERNAL"],
  Record<string, never>
>
export type CancelUploadAction = Action<FILE_UPLOAD_ACTIONS_TYPE["CANCEL"], Record<string, never>>
export type DeleteUploadAction = Action<FILE_UPLOAD_ACTIONS_TYPE["DELETE"], Record<string, never>>
export type FailUploadAction = Action<FILE_UPLOAD_ACTIONS_TYPE["FAIL"], { reason: string }>

export type FileUploadActions =
  | UpdateProgressAction
  | CreateUploadAction
  | ProcessUploadAction
  | CompleteUploadAction
  | AssignUploadAction
  | UnassignUploadAction
  | CancelUploadAction
  | DeleteUploadAction
  | FailUploadAction
