import styled from "@emotion/styled"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import { LoadingButton } from "@mui/lab"

interface UploadLetterheadWrapperProps {
  creating: boolean
}

interface StyledLetterheadNameWrapperProps {
  error?: boolean
}

interface StyledLettherheadDownloadProps {
  link?: boolean
}

export const StyledEditorWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export const UploadLetterheadWrapper = styled("div")<UploadLetterheadWrapperProps>(({ theme, creating }) => {
  if (creating) {
    return {
      position: "absolute",
      maxWidth: "210px",
      top: "25px",
      right: "30px",
    }
  }

  return {
    display: "flex",
    gap: theme.spacing(1),
  }
})

export const StyledLetterheadNameWrapper = styled("div")<StyledLetterheadNameWrapperProps>(
  ({ theme, error }) => ({
    marginTop: theme.spacing(0.5),
    maxWidth: "210px",
    display: "flex",
    color: error ? theme.palette.red.warning : "",
    alignItems: "center",
  })
)

export const StyledLetterheadPrefixWrapper = styled("span")<StyledLettherheadDownloadProps>(({ link }) => ({
  display: "inline-block",
  cursor: link ? "pointer" : undefined,
  maxWidth: "calc(100% - 74px)",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textWrap: "nowrap",
}))

export const StyledLetterheadPostfixWrapper = styled("span")<StyledLettherheadDownloadProps>(({ link }) => ({
  display: "inline-block",
  cursor: link ? "pointer" : undefined,
  width: "40px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textWrap: "nowrap",
}))

export const StyledUploadButton = styled(LoadingButton)(() => ({
  width: "210px",
}))
StyledUploadButton.defaultProps = {
  variant: "outlined",
  color: "primary",
  startIcon: <CloudUploadIcon />,
}

export const StyledUploadInput = styled("input")(() => ({
  display: "none",
}))
StyledUploadInput.defaultProps = {
  accept: ".docx",
  value: "",
  type: "file",
}
