import React from "react"
import { Box, Chip, Typography } from "@mui/material"
import { Provider } from "documents/types"
import { NO_PROVIDER_COLOR } from "./constants"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { pickTextColorBasedOnBgColor } from "./medicronCalendarUtilities"

type LegendProps = {
  providers: Record<string, Provider>
  showNoProviderLabel?: boolean
}

const ProviderChip = styled(Chip)<{ providercolor: string }>`
  width: fit-content;
  font-weight: bold;
  background-color: ${props => props.providercolor};
  color: ${props => pickTextColorBasedOnBgColor(props.providercolor, "#ffffff", "#000000")};
  overflow: auto;
`

export const MedicronCalendarLegend = function ({
  providers,
  showNoProviderLabel,
}: LegendProps): React.ReactElement {
  const theme = useTheme()
  return (
    <Box
      id="medicron-calendar-legend"
      paddingTop={theme.spacing(6)}
      borderTop="1px solid #0000001f"
      width="944px"
      data-test="medicron-calendar-legend"
    >
      <Typography marginBottom={theme.spacing(3)} fontWeight={theme.typography.fontWeightBold}>
        Legend:
      </Typography>
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={`12px ${theme.spacing(4)}`}>
        {Object.values(providers).map(provider => (
          <ProviderChip
            key={provider.name}
            label={provider.name}
            size="small"
            providercolor={provider.color}
            data-test="medicron-calendar-legend-provider"
          />
        ))}
        {showNoProviderLabel && (
          <ProviderChip label="No Provider" size="small" providercolor={NO_PROVIDER_COLOR} />
        )}
      </Box>
    </Box>
  )
}
