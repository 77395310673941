import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import {
  DocumentEntitiesDeserializer,
  DocumentEntitiesSerializer,
  DocumentGenerationServiceDeserializer,
  MedicronCalendarDeserializer,
  MedchronTileDeserializer,
  DocumentStructureDeserializer,
} from "./serializers"
import { handleEmptyResponse } from "api/utils"
import {
  BaseAppointmentOperationPayload,
  CreateAppointment,
  CreateIcdCode,
  DeleteIcdCode,
  ExhibitDto,
  ProviderDto,
  SetAppointmentDate,
  SetAppointmentTime,
  SetAppointmentProvider,
  SetAppointmentSummary,
  UpdateIcdCode,
} from "./types"
import { getQuery } from "../utils"
import { APPOINTMENT_TAG_ACTIONS } from "documents/types"

interface DocumentsServiceOptions {
  documentId: string
}

interface DocumentGenerationOptions extends DocumentsServiceOptions {
  extendedExhibits: boolean
}

interface MedicronCalendarDataOptions {
  nonce: string
}

enum DOCUMENTS_API_PATHS {
  BASE = "documents",
  GENERATE = "generate",
  EXHIBIT_MANAGEMENT = "exhibit_management",
  EXHIBIT_SORT = "sort_exhibits",
  APPOINTMENT = "appointment",
  PROVIDER = "provider",
  RENAME_PROVIDER = "rename",
  SET_PROVIDER = "set_provider",
  DATE_OF_SERVICE = "set_date_of_service",
  TIME_OF_SERVICE = "set_time_of_service",
  SUMMARY = "update_user_summary",
  ADD_ICD_CODE = "add_icd_code",
  UPDATE_ICD_CODE = "update_icd_code",
  DELETE_ICD_CODE = "delete_icd_code",
  REVERT_SUMMARY = "revert_user_summary",
  NONCED_CALENDAR_DATA = "nonce/medcron_calendar",
  MEDCHRON_TILE = "medchron_tile",
  MATCHES_DOCUMENT_STRUCTURE = "matches_document_structure",
  SYNC_WITH_DOCUMENT_STRUCTURE = "sync_with_document_structure",
  REGENERATE_GENERATED_SUMMARY = "regenerate_generated_summary",
  ADD_TAG = "add_tag",
  CONTENT_COMPONENTS = "content_components",
}

export class DocumentsService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(
    options?: DocumentsServiceOptions,
    paths: DOCUMENTS_API_PATHS | (DOCUMENTS_API_PATHS | PrimaryKey | string)[] = []
  ): string {
    const pathParts = [
      "",
      DOCUMENTS_API_PATHS.BASE,
      options?.documentId,
      ...(Array.isArray(paths) ? paths : [paths]),
    ]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getGenerationList = withResponseSerializer(
    DocumentGenerationServiceDeserializer.fromJSON,
    (options: DocumentsServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath(options, DOCUMENTS_API_PATHS.GENERATE))
      )
    }
  )

  generateDocument = withResponseSerializer(
    DocumentGenerationServiceDeserializer.fromGenerateJSON,
    (options: DocumentGenerationOptions) => {
      const query = getQuery({ ["extendedExhibits"]: [options.extendedExhibits] })
      return handleEmptyResponse(
        this.apiService.create(null, this.getPath(options, DOCUMENTS_API_PATHS.GENERATE), query)
      )
    }
  )

  getDocumentEntities = withResponseSerializer(
    DocumentEntitiesDeserializer.fromJSON,
    (options: DocumentsServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath(options, DOCUMENTS_API_PATHS.EXHIBIT_MANAGEMENT))
      )
    }
  )

  getMedicronCalendarData = withResponseSerializer(
    MedicronCalendarDeserializer.fromJSON,
    (options: MedicronCalendarDataOptions) => {
      const query = getQuery({ ["nonce"]: [options.nonce] })
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath(undefined, DOCUMENTS_API_PATHS.NONCED_CALENDAR_DATA), query)
      )
    }
  )

  sortDocumentExhibits = async (options: DocumentsServiceOptions) => {
    return (
      await handleEmptyResponse<{ exhibits: ExhibitDto["pk"][] }>(
        this.apiService.create(null, this.getPath(options, DOCUMENTS_API_PATHS.EXHIBIT_SORT))
      )
    ).exhibits
  }

  createAppointment = withResponseSerializer(
    DocumentEntitiesDeserializer.newAppointmentResponse,
    withRequestSerializer(
      DocumentEntitiesSerializer.newAppointmentToJSON,
      ({ data, ...options }: DocumentsServiceOptions & { data: CreateAppointment }) => {
        return handleEmptyResponse(
          this.apiService.create(data, this.getPath(options, [DOCUMENTS_API_PATHS.APPOINTMENT]))
        )
      }
    )
  )

  setAppointmentProvider = withResponseSerializer(
    DocumentEntitiesDeserializer.appointmentResponse,
    ({ appointmentId, providerId }: SetAppointmentProvider) => {
      return handleEmptyResponse(
        this.apiService.update(
          { provider_id: providerId },
          this.getPath(undefined, [
            DOCUMENTS_API_PATHS.APPOINTMENT,
            appointmentId,
            DOCUMENTS_API_PATHS.SET_PROVIDER,
          ])
        )
      )
    }
  )

  setAppointmentDate = withResponseSerializer(
    DocumentEntitiesDeserializer.appointmentResponse,
    ({ appointmentId, dateOfService }: SetAppointmentDate) => {
      return handleEmptyResponse(
        this.apiService.update(
          { date_of_service: dateOfService },
          this.getPath(undefined, [
            DOCUMENTS_API_PATHS.APPOINTMENT,
            appointmentId,
            DOCUMENTS_API_PATHS.DATE_OF_SERVICE,
          ])
        )
      )
    }
  )

  setAppointmentTime = withResponseSerializer(
    DocumentEntitiesDeserializer.appointmentResponse,
    ({ appointmentId, timeOfService }: SetAppointmentTime) => {
      return handleEmptyResponse(
        this.apiService.update(
          { time_of_service: timeOfService },
          this.getPath(undefined, [
            DOCUMENTS_API_PATHS.APPOINTMENT,
            appointmentId,
            DOCUMENTS_API_PATHS.TIME_OF_SERVICE,
          ])
        )
      )
    }
  )

  setAppointmentSummary = withResponseSerializer(
    DocumentEntitiesDeserializer.appointmentResponse,
    ({ appointmentId, summary }: SetAppointmentSummary) => {
      return handleEmptyResponse(
        this.apiService.update(
          { user_summary: summary },
          this.getPath(undefined, [
            DOCUMENTS_API_PATHS.APPOINTMENT,
            appointmentId,
            DOCUMENTS_API_PATHS.SUMMARY,
          ])
        )
      )
    }
  )

  revertAppointmentSummary = withResponseSerializer(
    DocumentEntitiesDeserializer.appointmentResponse,
    ({ appointmentId }: BaseAppointmentOperationPayload) => {
      return handleEmptyResponse(
        this.apiService.create(
          null,
          this.getPath(undefined, [
            DOCUMENTS_API_PATHS.APPOINTMENT,
            appointmentId,
            DOCUMENTS_API_PATHS.REVERT_SUMMARY,
          ])
        )
      )
    }
  )

  addAppointmentTag = ({
    appointmentId,
    data,
  }: BaseAppointmentOperationPayload & { data: { action: APPOINTMENT_TAG_ACTIONS } }) => {
    return handleEmptyResponse(
      this.apiService.create(
        data,
        this.getPath(undefined, [DOCUMENTS_API_PATHS.APPOINTMENT, appointmentId, DOCUMENTS_API_PATHS.ADD_TAG])
      )
    )
  }

  regenerateAppointmentSummary = withResponseSerializer(
    DocumentEntitiesDeserializer.appointmentResponse,
    ({ appointmentId }: BaseAppointmentOperationPayload) => {
      return handleEmptyResponse(
        this.apiService.create(
          null,
          this.getPath(undefined, [
            DOCUMENTS_API_PATHS.APPOINTMENT,
            appointmentId,
            DOCUMENTS_API_PATHS.REGENERATE_GENERATED_SUMMARY,
          ])
        )
      )
    }
  )
  getAppointment = withResponseSerializer(
    DocumentEntitiesDeserializer.appointmentFromJSON,
    (options: BaseAppointmentOperationPayload & DocumentsServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(
          null,
          this.getPath(options, [DOCUMENTS_API_PATHS.APPOINTMENT, options.appointmentId])
        )
      )
    }
  )

  getMedchronTile = withResponseSerializer(
    MedchronTileDeserializer.fromJSON,
    (options: DocumentsServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath(options, [DOCUMENTS_API_PATHS.MEDCHRON_TILE]))
      )
    }
  )

  deleteAppointment = ({ appointmentId }: BaseAppointmentOperationPayload) => {
    return this.apiService.delete(
      null,
      this.getPath(undefined, [DOCUMENTS_API_PATHS.APPOINTMENT, appointmentId])
    )
  }

  addIcdCode = withResponseSerializer(
    DocumentEntitiesDeserializer.icdCodeResponse,
    withRequestSerializer(
      DocumentEntitiesSerializer.newIcdCodeToJSON,
      ({ appointmentId, data }: BaseAppointmentOperationPayload & { data: CreateIcdCode }) => {
        return handleEmptyResponse(
          this.apiService.create(
            data,
            this.getPath(undefined, [
              DOCUMENTS_API_PATHS.APPOINTMENT,
              appointmentId,
              DOCUMENTS_API_PATHS.ADD_ICD_CODE,
            ])
          )
        )
      }
    )
  )

  updateIcdCode = withResponseSerializer(
    DocumentEntitiesDeserializer.icdCodeResponse,
    withRequestSerializer(
      DocumentEntitiesSerializer.icdCodeToJSON,
      ({ appointmentId, data }: BaseAppointmentOperationPayload & { data: UpdateIcdCode }) => {
        return handleEmptyResponse(
          this.apiService.create(
            data,
            this.getPath(undefined, [
              DOCUMENTS_API_PATHS.APPOINTMENT,
              appointmentId,
              DOCUMENTS_API_PATHS.UPDATE_ICD_CODE,
            ])
          )
        )
      }
    )
  )

  deleteIcdCode = withRequestSerializer(
    DocumentEntitiesSerializer.deleteIcdCodeToJSON,
    ({ appointmentId, data }: BaseAppointmentOperationPayload & { data: DeleteIcdCode }) => {
      return this.apiService.create(
        data,
        this.getPath(undefined, [
          DOCUMENTS_API_PATHS.APPOINTMENT,
          appointmentId,
          DOCUMENTS_API_PATHS.DELETE_ICD_CODE,
        ])
      )
    }
  )

  createProvider = withResponseSerializer(
    DocumentEntitiesDeserializer.providerResponse,
    ({ name, ...options }: DocumentsServiceOptions & { name: ProviderDto["name"] }) => {
      return handleEmptyResponse(
        this.apiService.create({ name }, this.getPath(options, [DOCUMENTS_API_PATHS.PROVIDER]))
      )
    }
  )

  renameProvider = withResponseSerializer(
    DocumentEntitiesDeserializer.providerResponse,
    ({
      name,
      providerId,
      ...options
    }: DocumentsServiceOptions & { providerId: ProviderDto["pk"]; name: ProviderDto["name"] }) => {
      return handleEmptyResponse(
        this.apiService.update(
          { name },
          this.getPath(options, [
            DOCUMENTS_API_PATHS.PROVIDER,
            providerId,
            DOCUMENTS_API_PATHS.RENAME_PROVIDER,
          ])
        )
      )
    }
  )

  getDocumentStructureOrder = withResponseSerializer(
    DocumentStructureDeserializer.fromJSON,
    (options: DocumentsServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath(options, DOCUMENTS_API_PATHS.CONTENT_COMPONENTS))
      )
    }
  )

  getMatchesDocumentStructure = withResponseSerializer(
    DocumentStructureDeserializer.matchesFromJSON,
    (options: DocumentsServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath(options, DOCUMENTS_API_PATHS.MATCHES_DOCUMENT_STRUCTURE))
      )
    }
  )

  syncWithDocumentStructure = withResponseSerializer(
    DocumentStructureDeserializer.syncFromJSON,
    (options: DocumentsServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.create({}, this.getPath(options, [DOCUMENTS_API_PATHS.SYNC_WITH_DOCUMENT_STRUCTURE]))
      )
    }
  )
}

export const documentsService = new DocumentsService(apiService)
