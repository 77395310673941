import { ExhibitBuilderData, Provider } from "../store/types"
import { SetState } from "./types"
import { exhibitBuilderService } from "../api/service"
import { DocumentSlice } from "./document"
import { GetState } from "review/store/types"

export type ProvidersSlice = Pick<ExhibitBuilderData, "providers">

export const providersSlice: ProvidersSlice = {
  providers: {},
}

export const providersActions = (
  set: SetState<ProvidersSlice>,
  get: GetState<ProvidersSlice & DocumentSlice>
) => {
  const createProvier = async (name: string) => {
    const newProvider = await exhibitBuilderService.createProvider({
      name,
      documentId: get().document.documentId,
    })

    set(state => ({ providers: { ...state.providers, [newProvider.id]: newProvider } }))

    return newProvider
  }

  const renameProvider = async ({ providerId, name }: { providerId: Provider["id"]; name: string }) => {
    const updatedProvider = await exhibitBuilderService.renameProvider({
      providerId,
      name,
      documentId: get().document.documentId,
    })

    set(state => ({ providers: { ...state.providers, [providerId]: updatedProvider } }))

    return updatedProvider
  }

  return {
    createProvier,
    renameProvider,
  }
}
