import React from "react"
import { useMediaQuery, useTheme } from "@mui/material"

import {
  StyledToolbar,
  ToolbarContainer,
  ToolbarOuterContainer,
  PdfTitle,
  StyledToolbarSpace,
  PdfSubTitle,
  PdfTitleWrapper,
} from "./styled"
import { ZoomControl } from "./ZoomControl"
import { PdfToolbarPagination } from "./components"

export interface PdfToolbarProps {
  title?: string
  subtitle?: string
  pages?: number
  page?: number
  pageNumberAdjustment?: number
  onChangePage?: (page: number) => void

  estimatedScale?: number
  onScaleChange: (value: Nullable<number>) => void

  leftControls?: React.ReactElement
  rightControls?: React.ReactElement
}

export function PdfToolbar({
  title,
  subtitle,
  pages = 1,
  page = 1,
  pageNumberAdjustment = 0,
  onChangePage,

  estimatedScale,
  onScaleChange,

  leftControls,
  rightControls,
}: PdfToolbarProps): React.ReactElement {
  const theme = useTheme()
  const isLessThanMdScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <ToolbarOuterContainer>
      <ToolbarContainer color="default" elevation={1}>
        <StyledToolbar variant="dense">
          <div data-test="pdf-toolbar-left">{leftControls}</div>
          <StyledToolbarSpace data-test="pdf-toolbar-middle">
            <PdfToolbarPagination
              page={page}
              pages={pages}
              pageNumberAdjustment={pageNumberAdjustment}
              onChangePage={onChangePage}
              hideArrows={isLessThanMdScreen}
            />
            <PdfTitleWrapper>
              <PdfTitle variant="body2">{title}</PdfTitle>
              {subtitle && <PdfSubTitle variant="body2">{subtitle}</PdfSubTitle>}
            </PdfTitleWrapper>
            <ZoomControl onChange={onScaleChange} estimatedZoomRatio={estimatedScale ?? 1} />
          </StyledToolbarSpace>
          <div data-test="pdf-toolbar-right">{rightControls}</div>
        </StyledToolbar>
      </ToolbarContainer>
    </ToolbarOuterContainer>
  )
}
