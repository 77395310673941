import styled from "@emotion/styled"
import { Box, Checkbox } from "@mui/material"

export const DisplayOnHover = styled(Box)(({ alwaysShow = false }: { alwaysShow?: boolean }) => ({
  opacity: alwaysShow ? 1 : 0,
  transition: "opacity 0.2s",
  "&:hover,&:focus-within": {
    opacity: 1,
  },
}))

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.grey[400],
}))

export const StyledBillRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
  paddingRight: theme.spacing(1.5),
  width: "100%",
}))
