import { noop } from "lodash"
import { createContext } from "react"
import { FileUploaderData } from "./uploader-state/types"

export interface FileUploaderContextType {
  updateState(state: FileUploaderData): void
}

export const FileUploaderContext = createContext<FileUploaderContextType>({
  updateState: noop,
})
