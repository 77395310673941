import React, { forwardRef, Ref, useCallback, useMemo } from "react"
import { NumberFormatValues, NumericFormat } from "react-number-format"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import Box from "@mui/material/Box"
import { InputAdornment } from "@mui/material"
import { isNull } from "lodash"

export type ExtendedNumberFormatValues = NumberFormatValues & {
  valueAsNumber: Nullable<number>
}

interface NumberInputProps {
  currencySymbol?: string
  onValueChange?: (data: ExtendedNumberFormatValues) => void
}

interface NumberFormatCustomProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any
  value?: string
}

interface GetNumberFormatCustomProps {
  onValueChange?: (data: ExtendedNumberFormatValues) => void
  decimalScale?: number
}

const coerceInputToNumber = (input: string) => {
  if (input === "") {
    return null
  }

  const inputNumber = Number(input)
  if (isNaN(inputNumber)) {
    return 0
  }

  return inputNumber
}

const getNumberFormatCustom = ({ onValueChange, decimalScale }: GetNumberFormatCustomProps) =>
  React.forwardRef(function NumberFormatCustom(props: NumberFormatCustomProps, ref) {
    const { onChange } = props

    const extendedOnValueChange = useCallback(
      (data: NumberFormatValues) => {
        const valueAsNumber = coerceInputToNumber(data.value)

        if (onValueChange) onValueChange({ ...data, valueAsNumber })
        if (onChange) onChange({ target: { value: valueAsNumber } })
      },
      [onChange]
    )

    const numericValue = Number(props.value)

    const value =
      props.value === "" || isNull(props.value) || isNaN(numericValue) ? props.value : numericValue

    return (
      <NumericFormat
        {...props}
        getInputRef={ref}
        onValueChange={extendedOnValueChange}
        onChange={undefined}
        value={value}
        decimalScale={decimalScale}
        thousandSeparator
      />
    )
  })

export const NumberInput = forwardRef(function NumberInput(
  { decimalScale, currencySymbol, ...props }: NumberInputProps & TextFieldProps & { decimalScale?: number },
  ref: Ref<HTMLInputElement>
) {
  const inputComponent = useMemo(
    () => getNumberFormatCustom({ onValueChange: props.onValueChange, decimalScale }),
    [props.onValueChange, decimalScale]
  )
  return (
    <Box>
      <TextField
        {...props}
        inputRef={ref}
        variant={props.variant ?? "outlined"}
        InputProps={{
          inputComponent,
          startAdornment: currencySymbol ? (
            <InputAdornment position="start">{currencySymbol}</InputAdornment>
          ) : undefined,
          ...props.InputProps,
        }}
      />
    </Box>
  )
})
