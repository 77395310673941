import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Autocomplete, AutocompleteInputChangeReason, TextField } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { getIcdCodes } from "api"

interface IcdCodeSource {
  code: string
  description: string
}

interface IcdCodeInputProps {
  code?: Nullable<string>
  onChange: (code: Nullable<string>) => void
}

export function IcdCodeInput({ code, onChange }: IcdCodeInputProps): JSX.Element {
  const [search, setSearch] = useState<Nullable<string>>(code ?? null)
  const { data, isFetching } = useQuery<{ results: IcdCodeSource[] }>(
    [queryKeys.icdCodes, search],
    () => getIcdCodes({ search: search ?? undefined }),
    {
      ...SILENT_QUERY_PARAMS,
      enabled: !!search,
    }
  )

  const results = useMemo(() => {
    return data?.results ?? []
  }, [data])

  const [value, setValue] = useState<Nullable<IcdCodeSource>>(code ? ({ code } as IcdCodeSource) : null)

  const options = useMemo(() => {
    if (!value) return results
    if (results.find(result => result.code === value.code)) return results
    return [value, ...results]
  }, [results, value])

  const handleChange = useCallback(
    (_: React.SyntheticEvent, value: Nullable<IcdCodeSource | string>) => {
      if (typeof value !== "string") {
        setValue(value)
        onChange(value ? value.code : null)
      }
    },
    [onChange]
  )

  const handleInputChange = useCallback(
    (_: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
      reason === "input" && setSearch(value)
    },
    []
  )

  useEffect(() => {
    if (!value || value.description) return

    const possibleValue = results.find(option => option.code === value.code)

    if (possibleValue) setValue(possibleValue)
  }, [results, value])

  return (
    <Autocomplete
      data-test="icd-codes-input"
      size="small"
      disablePortal
      fullWidth
      selectOnFocus
      clearOnBlur
      freeSolo
      loading={isFetching}
      id="icd-codes-input"
      options={options}
      getOptionLabel={option =>
        typeof option === "string"
          ? option
          : option.description
            ? `${option.code} (${option.description})`
            : option.code
      }
      isOptionEqualToValue={(option, value) => option.code === value.code}
      value={value}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={params => <TextField {...params} label="Search to add" />}
    />
  )
}
