import ListSubheader from "@mui/material/ListSubheader"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import Check from "@mui/icons-material/Check"
import { deletePartition } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import React, { PropsWithChildren } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { updateAnnotatedExhibits } from "./cacheUtils"
import useUpdatePartitionMutation from "./hooks/useUpdatePartitionMutation"
import { AnnotatedExhibit, CaseId, Section } from "./types"

interface CheckableMenuItemProps {
  checked: boolean
  disabled?: boolean
  onClick: React.MouseEventHandler<HTMLLIElement>
}

const CheckableMenuItem: React.FC<PropsWithChildren<CheckableMenuItemProps>> = ({
  children,
  checked,
  disabled = false,
  onClick,
}) => {
  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box mr={2}>{children}</Box>
        <Check fontSize="small" style={{ visibility: checked ? "visible" : "hidden" }} />
      </Box>
    </MenuItem>
  )
}

interface PartitionMenuProps {
  anchorEl: Nullable<HTMLElement>
  onClose: () => unknown
  onDeletePagesClick: () => unknown
  exhibitId: number
  partitionId: number
  caseId: CaseId
  section: Section
  isProvider: boolean
  isNoAutoImport: boolean
}

export const SplitMenu: React.FC<PartitionMenuProps> = ({
  anchorEl,
  onClose,
  onDeletePagesClick,
  caseId,
  exhibitId,
  partitionId,
  isProvider,
  isNoAutoImport,
}) => {
  const { isFeatureEnabled } = useFeatures()
  const isSplittingEnabled = isFeatureEnabled(FEATURES.SPLIT_INTAKE_FILES)
  const { update, isUpdating } = useUpdatePartitionMutation({
    caseId,
    exhibitId,
    partitionId,
    onSuccess: onClose,
  })

  const handleImportIntoOptionClick = (section: Section) => () => {
    update({ exhibitId, partitionId, data: { section } })
  }

  if (!isSplittingEnabled) return null

  return (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem disabled={isUpdating} onClick={onDeletePagesClick}>
        Delete Pages
      </MenuItem>
      <ListSubheader>Import Into</ListSubheader>
      <CheckableMenuItem
        disabled={isUpdating}
        checked={isProvider}
        onClick={handleImportIntoOptionClick("providers")}
      >
        Providers
      </CheckableMenuItem>
      <CheckableMenuItem
        disabled={isUpdating}
        checked={isNoAutoImport}
        onClick={handleImportIntoOptionClick(null)}
      >
        No Auto-Import
      </CheckableMenuItem>
    </Menu>
  )
}

interface DuplicateMenuProps extends PartitionMenuProps {
  onDeleteDuplicateSuccess: () => unknown
}

export const DuplicateMenu: React.FC<DuplicateMenuProps> = ({
  anchorEl,
  onClose,
  exhibitId,
  partitionId,
  caseId,
  onDeleteDuplicateSuccess,
  onDeletePagesClick,
  isProvider,
  isNoAutoImport,
}) => {
  const { isFeatureEnabled } = useFeatures()
  const isSplittingEnabled = isFeatureEnabled(FEATURES.SPLIT_INTAKE_FILES)
  const queryClient = useQueryClient()
  const { showErrorMessage } = useHandleMessages()

  const { update, isUpdating } = useUpdatePartitionMutation({
    caseId,
    exhibitId,
    partitionId,
    onSuccess: onClose,
  })

  const handleImportIntoOptionClick = (section: Section) => () => {
    update({ exhibitId, partitionId, data: { section } })
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: deletePartition,
    onSuccess: () => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: exhibit => ({ duplicates: exhibit.duplicates.filter(dup => dup.pk !== partitionId) }),
        })
      })
      onDeleteDuplicateSuccess()
    },
    onError: error => {
      showErrorMessage({
        message: "Could not delete duplicate. Try again shortly and file an issue if the problem persists.",
        error,
      })
    },
  })

  if (!isSplittingEnabled) return null

  return (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem
        disabled={isLoading}
        onClick={() => {
          if (confirm("Are you sure you want to remove this duplicate?")) {
            mutate({ exhibitId, partitionId })
          }
        }}
      >
        Remove Duplicate
      </MenuItem>
      <MenuItem onClick={onDeletePagesClick}>Delete Pages</MenuItem>
      <ListSubheader>Import Into</ListSubheader>
      <CheckableMenuItem
        disabled={isUpdating}
        checked={isProvider}
        onClick={handleImportIntoOptionClick("providers")}
      >
        Providers
      </CheckableMenuItem>
      <CheckableMenuItem
        disabled={isUpdating}
        checked={isNoAutoImport}
        onClick={handleImportIntoOptionClick(null)}
      >
        No Auto-Import
      </CheckableMenuItem>
    </Menu>
  )
}
