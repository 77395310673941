import { Box, Checkbox, Divider, FormControlLabel, Typography } from "@mui/material"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { ExhibitFile, UserExhibit } from "exhibit-builder/store/types"
import { FormEventHandler, useMemo, useState } from "react"
import { useShallow } from "zustand/react/shallow"
import { BottomButtonGroup } from "../BottomButtonGroup"
import { ExhibitFileDropdown } from "../ExhibitFileDropdown"
import { SortableRangeInput } from "../../../../evenup-ui/SortableRangeInput/SortableRangeInput"
import { RangeItem } from "../../../../evenup-ui/SortableRangeInput/types"
import { ActionButton, useAction } from "../../../ActionButton"

const DROPDOWN_LABEL = "Select file:"
export function Delete({ id, onClose }: { id: UserExhibit["id"]; onClose: () => void }) {
  const partitions = useExhibitBuilderStore(useShallow(filesSelectors.getUserExhibitPartitions(id)))
  const [selectedPartition, setSelectedPartition] = useState(partitions[0]?.id)
  const [selectedPageRanges, setSelectedPageRanges] = useState<RangeItem[]>([])
  const [deleteFile, setDeleteFile] = useState(false)
  const [deleteExhibit, setDeleteExhibit] = useState(false)

  const selectedPartitionRange = useMemo(() => {
    const partition = partitions.find(partition => partition.id === selectedPartition)
    return { start: partition?.startPage ?? 1, end: partition?.endPage ?? 1 }
  }, [partitions, selectedPartition])

  const handleFileChange = (id: ExhibitFile["id"]) => {
    setSelectedPartition(id)
  }

  const handleDeleteFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteFile(event.target.checked)
  }

  const handleRangeChange = (ranges: RangeItem[]) => {
    setSelectedPageRanges(ranges)
  }

  const handleDeleteExhibitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteExhibit(event.target.checked)
  }

  const isRangeValid =
    deleteExhibit ||
    deleteFile ||
    (selectedPageRanges.length > 0 &&
      selectedPageRanges.every(range => {
        return range.startPage && range.endPage && Object.values(range.errors).every(error => !error)
      }))

  const { handleAction, isLoading } = useAction({
    name: "delete page ranges",
    action: async () => {
      if (deleteExhibit) {
        return exhibitBuilderActions.deleteUserExhibit(id)
      }

      const pageRanges = deleteFile
        ? [{ startPage: selectedPartitionRange.start, endPage: selectedPartitionRange.end }]
        : selectedPageRanges

      return exhibitBuilderActions.deletePageRangesFromPartition({
        pageRanges,
        partitionId: selectedPartition,
        userExhibitId: id,
      })
    },
  })

  const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault()

    await handleAction()
    onClose()
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column">
          <Typography variant="caption">
            This action will delete the selected files or pages. Any pages no longer part of any exhibit will
            move to the deleted section.
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                data-test="delete-exhibit"
                size="small"
                value={deleteFile}
                onChange={handleDeleteExhibitChange}
              />
            }
            label={<Typography variant="body2">Delete full exhibit</Typography>}
          />
        </Box>

        <Divider sx={{ mb: 3 }}>or</Divider>

        <ExhibitFileDropdown
          partitions={partitions}
          selectedPartition={selectedPartition}
          label={DROPDOWN_LABEL}
          onChange={handleFileChange}
          disabled={deleteExhibit}
        />

        <FormControlLabel
          control={
            <Checkbox
              data-test="delete-file"
              name="deleteFile"
              size="small"
              value={deleteFile}
              onChange={handleDeleteFileChange}
              disabled={deleteExhibit}
            />
          }
          label={<Typography variant="body2">Delete entire file</Typography>}
        />

        <SortableRangeInput
          key={selectedPartition}
          range={selectedPartitionRange}
          onChange={handleRangeChange}
          disabled={deleteFile || deleteExhibit}
        />
        <BottomButtonGroup
          onClose={onClose}
          actionButton={
            <ActionButton
              data-test="delete-button"
              disabled={!isRangeValid}
              isLoading={isLoading}
              label="Delete"
            />
          }
        />
      </form>
    </div>
  )
}
