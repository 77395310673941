import { create } from "zustand"
import { subscribeWithSelector } from "zustand/middleware"
import { requestPanelSlice, requestPanelActions } from "./requestPanel"
import { ExtractState } from "common/types/zustand"
import { GetState, SetState } from "./types"

export const useRequestStore = create(
  subscribeWithSelector(() => ({
    ...requestPanelSlice,
  }))
)

type RequestStore = ExtractState<typeof useRequestStore>

export const requestStoreSelectors = {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const actions = (set: SetState<RequestStore>, get: GetState<RequestStore>) => {
  return {}
}

export const requestActions = {
  ...requestPanelActions(useRequestStore.setState, useRequestStore.getState),
  ...actions(useRequestStore.setState, useRequestStore.getState),
}
