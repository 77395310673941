import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { ViewRequestRevampProps } from "../types"
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"

export const CollaboratorsInformaion = ({ request }: ViewRequestRevampProps) => {
  return (
    <RequestFormModule title="Collaborators">
      {request.collaborators.length ? (
        <Table sx={{ width: "fit-content" }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {request.collaborators.map(collaborator => (
              <TableRow key={collaborator.pk}>
                <TableCell>{collaborator.first_name}</TableCell>
                <TableCell>{collaborator.last_name}</TableCell>
                <TableCell>{collaborator.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="body1">No Collaborators have been added to this request.</Typography>
      )}
    </RequestFormModule>
  )
}
