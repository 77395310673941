import { useMemo, useReducer, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { Loading } from "common"
import { Pagination } from "common/pagination"
import { PageSizeSelect } from "common/pagination/PageSizeSelect"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"
import { ProviderTemplateRowData, ProviderTemplatesTableViewModel } from "common/models/library"
import { useProviderTemplateForm } from "settings/Library/TemplateForms"
import { UPDATE_TEMPLATE_FORM_STATE_MESSAGES } from "settings/Library/TemplateForms/constants"
import { providerTemplateService } from "api/services/provider-template"
import useUser from "hooks/useUser"
import { canEditLibrary } from "settings/permissions"

import { templatesTabReducer } from "../State/templatesReducer"
import { INITIAL_TEMPLATES_TAB_STATE, LibraryTabStateContext } from "../State/constants"
import { NewProviderTemplate } from "../Forms/NewProviderTemplate"
import { LibraryTable } from "../LibraryTable"
import { StyledFiltersWrapper, StyledPageSizeWrapper, StyledSeparator } from "./styled"

const getDuplicateData = (rowData: ProviderTemplateRowData): Partial<ProviderTemplateRowData> => {
  return {
    initialContent: rowData.initialContent,
    initialTemplateName: rowData.initialTemplateName,
    initialGeneratorKey: rowData.initialGeneratorKey,
  }
}

export function ProviderTemplates(): Nullable<JSX.Element> {
  const [state, dispatch] = useReducer(templatesTabReducer, INITIAL_TEMPLATES_TAB_STATE)
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const { user } = useUser()
  const canEditLibraryPermission = canEditLibrary(user.role)

  const { isFetching, data: providerTemplates } = useQuery(
    [queryKeys.providerTemplates, currentPage, pageSize],
    () =>
      providerTemplateService.getProviderTemplatesList({
        page: currentPage,
        pageSize,
      }),
    {
      meta: { disableLoader: true },
      keepPreviousData: true,
    }
  )

  if (!providerTemplates) {
    return (
      <LibraryTabStateContext.Provider value={contextValue}>
        <Loading show label="Loading provider templates..." />
      </LibraryTabStateContext.Provider>
    )
  }

  const templatesTableData = new ProviderTemplatesTableViewModel(providerTemplates.items)

  return (
    <LibraryTabStateContext.Provider value={contextValue}>
      <StyledFiltersWrapper>
        <div />
        <StyledPageSizeWrapper>
          <PageSizeSelect pageSize={pageSize} onChange={setPageSize} />
        </StyledPageSizeWrapper>
      </StyledFiltersWrapper>
      {canEditLibraryPermission ? <NewProviderTemplate /> : <StyledSeparator />}
      <LibraryTable
        tableData={templatesTableData}
        loading={isFetching}
        getFormData={useProviderTemplateForm}
        canDuplicate={canEditLibraryPermission}
        getDuplicateData={getDuplicateData}
        entityName="provider templates"
        formStatusMessageMap={UPDATE_TEMPLATE_FORM_STATE_MESSAGES}
      />
      {providerTemplates.count > 0 && (
        <Pagination
          pageCount={providerTemplates.pageCount}
          page={providerTemplates.page}
          count={providerTemplates.count}
          onChange={setCurrentPage}
        />
      )}
    </LibraryTabStateContext.Provider>
  )
}
