import { findFurthestParent, findParent } from "common/nested-list/utils/projection"
import { ProjectionItemData, ProjectionData, ItemType } from "common/nested-list/types"
import { EXHIBIT_ITEM_TYPES } from "./enums"
import { ExhibitSection, NewExhibitGroup } from "./Exhibit"
import { ITEM_REF } from "common/nested-list/constants"

type ProjectedExhibitItemType = ProjectionItemData<ItemType<ExhibitSection>>
type ProjectedExhibits = ProjectionData<ItemType<ExhibitSection>>

export function isAtDisabledGroup(item: ProjectedExhibitItemType, data: ProjectedExhibits): boolean {
  const itemData = item[ITEM_REF]
  const groupItem = itemData.type === EXHIBIT_ITEM_TYPES.GROUP ? item : findParent(data, item.id)

  if (!groupItem) return false

  if (!(groupItem[ITEM_REF] as NewExhibitGroup).isNew) {
    return false
  }

  return groupItem.children?.length === 1
}

export function canDrag(item: ProjectedExhibitItemType, data: ProjectedExhibits): boolean {
  return !isAtDisabledGroup(item, data)
}

export function canDrop(
  item: ProjectedExhibitItemType,
  draggingItemId: string,
  data: ProjectedExhibits
): boolean {
  const draggingItem = data[draggingItemId]

  if (!draggingItem) {
    return false
  }

  const itemParent = findParent(data, item.id)
  const draggingItemParent = findParent(data, draggingItemId)

  if (
    (draggingItem.type === EXHIBIT_ITEM_TYPES.EXHIBIT ||
      draggingItem.type === EXHIBIT_ITEM_TYPES.PARTITION) &&
    isAtDisabledGroup(item, data)
  ) {
    return false
  }

  if (draggingItem.type === EXHIBIT_ITEM_TYPES.GROUP) {
    return itemParent === draggingItemParent || item.type === EXHIBIT_ITEM_TYPES.GROUP
  }

  if (draggingItem.type === EXHIBIT_ITEM_TYPES.SECTION) {
    return item.type === EXHIBIT_ITEM_TYPES.SECTION
  }

  if (
    draggingItem.type === EXHIBIT_ITEM_TYPES.EXHIBIT ||
    draggingItem.type === EXHIBIT_ITEM_TYPES.PARTITION
  ) {
    const draggingItemRoot = findFurthestParent(data, draggingItemId)
    const itemParentRoot = findFurthestParent(data, item.id)

    return item.type !== EXHIBIT_ITEM_TYPES.SECTION && itemParentRoot === draggingItemRoot
  }

  return false
}

export function canDropAsChild(
  item: ProjectedExhibitItemType,
  draggingItemId: string,
  data: ProjectedExhibits
): boolean | (() => void) {
  const draggingItem = data[draggingItemId]

  if (!draggingItem) {
    return false
  }

  if (draggingItem.type === EXHIBIT_ITEM_TYPES.GROUP && item.type === EXHIBIT_ITEM_TYPES.GROUP) {
    return false
  }

  return canDrop(item, draggingItemId, data)
}
