import { useMemo } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { Loading } from "common/loading"
import useUser from "../hooks/useUser"

interface LocationState {
  path?: string
}

interface Props {
  children: JSX.Element | JSX.Element[]
}

function useSearchQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

// A wrapper for <Route> that redirects to the root page if authenticated already
export default function RestrictAuth({ children }: Props): JSX.Element {
  const { user, isLoading, isInitialized, error } = useUser()
  const location = useLocation()
  const query = useSearchQuery()
  const state = location.state as LocationState

  if (isLoading || !isInitialized) return <Loading />

  let redirectPath = "/"
  if (query.get("redirectTo")) {
    redirectPath = query.get("redirectTo") as string
  } else if (state?.path) {
    redirectPath = state.path
  }

  if (user.isAuthorized && !isLoading && !error) {
    return <Navigate to={redirectPath} replace />
  }

  return <>{children}</>
}
