import { Chip } from "@mui/material"
import styled from "@emotion/styled"

const StyledChip = styled(Chip)<{ fontSize: number }>(({ theme, fontSize }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.common.white,
  fontWeight: 500,
  fontSize: `${fontSize}px`,
  boxShadow: "0px 3px 10px 1px rgba(67, 112, 245, 0.25);",
  "& .MuiChip-label": {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  padding: theme.spacing(1),
  height: `${fontSize * 2}px`,
  borderRadius: `${fontSize}px`,
}))

interface LittyChipProps {
  text?: string | JSX.Element
  size?: number
}

export const LittyChip = ({ text = "Litty", size = 33 }: LittyChipProps) => {
  return <StyledChip label={text} fontSize={size}></StyledChip>
}
