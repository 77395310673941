import React, { useState } from "react"
import { STATUS } from "../enums"
import { Box, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import StatusLabel from "common/status/StatusLabel"

interface FirmStatusSelectorProps {
  onChange: (value: STATUS) => void
  value: Nullable<STATUS>
}

const FirmStatusSelector: React.FC<FirmStatusSelectorProps> = ({ onChange, value }) => {
  const [status, setStatus] = useState(value)

  const labelPropsMap = {
    [STATUS.ACTIVE]: {
      color: "#5bc0de",
      text: "Active",
    },
    [STATUS.CHURNED]: {
      color: "#f0ad4e",
      text: "Churned",
    },
    [STATUS.CHURNED_DURING_PILOT]: {
      color: "#f0ad4e",
      text: "Churned during pilot",
    },
    [STATUS.FAILURE_TO_LAUNCH]: {
      color: "#d9534f",
      text: "Failure to launch",
    },
  }

  return (
    <>
      <InputLabel id="firm-status">Status</InputLabel>
      <Select
        sx={{ width: "250px" }}
        onChange={e => {
          const statusVal = e.target.value as STATUS
          onChange(statusVal)
          setStatus(statusVal)
        }}
        label="Status"
        labelId="firm-status"
        value={status}
        renderValue={v =>
          v ? (
            <StatusLabel text={labelPropsMap[v].text} color={labelPropsMap[v].color} filled status={v} />
          ) : (
            <em>Please select a status</em>
          )
        }
        native={false}
      >
        {[STATUS.ACTIVE, STATUS.CHURNED, STATUS.CHURNED_DURING_PILOT, STATUS.FAILURE_TO_LAUNCH].map(v => (
          <MenuItem value={v} key={v}>
            <StatusLabel text={labelPropsMap[v].text} color={labelPropsMap[v].color} filled status={v} />
          </MenuItem>
        ))}
      </Select>
      <Box pl={0.5}>
        <Typography variant="caption">* Saves automatically</Typography>
      </Box>
    </>
  )
}

export default FirmStatusSelector
