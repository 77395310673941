export const PASSWORD_PROTECTED_MATCH = "password protected"

export const EMPTY_FILE_MATCH = "file is empty"

export const EMPTY_FILE_PREVALIDATION = "This file is empty."

export const DUPLICATE_UPLOAD_MATCH = "File has already been uploaded."

export const enum VALIDATION_MESSAGE {
  EMPTY_FILE = "is inaccessible as it was dragged from a zip file. Extract the file using the 'Extract All' option by right-clicking the zip file, then try uploading again.",
  CORRUPT_FILE = "is corrupt",
  FAILED_VALIDATION = "failed to validate",
  PASSWORD_PROTECTED = "appear to be password protected - if possible, please remove password protections and re-upload this file",
  FAILED_TO_UPLOAD = "failed to upload",
}
