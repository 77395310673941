import React, { PropsWithChildren, useMemo } from "react"
import { NestedListItem } from "./NestedListItem"
import { ProjectionItemWithChildren } from "./types"

export function DefaultEmptyPlaceholder(): Nullable<React.ReactElement> {
  return null
}

function defaultShowPlaceholder<TData>(item: ProjectionItemWithChildren<TData>): boolean {
  return item.children.length === 0
}

export interface NestedListProps<TData, TContentProps, TPlaceholderProps> {
  items: ProjectionItemWithChildren<TData>[]
  contentProps: TContentProps
  placeholderProps: TPlaceholderProps
  readonly: boolean
  ItemComponent: React.ComponentType<
    PropsWithChildren<{
      item: ProjectionItemWithChildren<TData>
      isChild: boolean
      content: React.ReactElement
      inline?: boolean
      readonly: boolean
    }>
  >
  ItemContentComponent: React.ComponentType<
    TContentProps & { item: ProjectionItemWithChildren<TData>; compact?: boolean }
  >
  PlaceholderComponent: React.ComponentType<TPlaceholderProps & { item: ProjectionItemWithChildren<TData> }>
  showPlaceholder?: (item: ProjectionItemWithChildren<TData>) => boolean
}

export function NestedListItemComponent<
  TData,
  TContentProps extends BaseObject,
  TPlaceholderProps extends BaseObject,
>({
  item,
  isChild,
  contentProps = {} as TContentProps,
  placeholderProps = {} as TPlaceholderProps,
  ItemComponent,
  ItemContentComponent,
  PlaceholderComponent,
  readonly,
  showPlaceholder = defaultShowPlaceholder,
}: Omit<NestedListProps<TData, TContentProps, TPlaceholderProps>, "items"> & {
  item: ProjectionItemWithChildren<TData>
  isChild: boolean
}): JSX.Element {
  const shouldShowPlaceholder = useMemo(() => showPlaceholder(item), [item, showPlaceholder])

  return (
    <ItemComponent
      key={item.id}
      item={item}
      isChild={isChild}
      content={<ItemContentComponent {...contentProps} item={item} />}
      readonly={readonly}
    >
      {item.children.length > 0 &&
        item.children.map(childItem => (
          <NestedListItemComponent
            key={childItem.id}
            item={childItem as ProjectionItemWithChildren<TData>}
            contentProps={contentProps}
            placeholderProps={placeholderProps}
            ItemComponent={ItemComponent}
            ItemContentComponent={ItemContentComponent}
            PlaceholderComponent={PlaceholderComponent}
            isChild
            readonly={readonly}
            showPlaceholder={showPlaceholder}
          />
        ))}
      {shouldShowPlaceholder && <PlaceholderComponent {...placeholderProps} item={item} />}
    </ItemComponent>
  )
}

export function NestedList<TData, TContentProps extends BaseObject, TPlaceholderProps extends BaseObject>({
  items,
  contentProps,
  placeholderProps,
  ItemComponent = NestedListItem,
  ItemContentComponent,
  PlaceholderComponent = DefaultEmptyPlaceholder,
  readonly,
  showPlaceholder,
}: NestedListProps<TData, TContentProps, TPlaceholderProps>): JSX.Element {
  return (
    <>
      {items.map(item => (
        <NestedListItemComponent
          key={item.id}
          contentProps={contentProps}
          placeholderProps={placeholderProps}
          ItemComponent={ItemComponent}
          ItemContentComponent={ItemContentComponent}
          PlaceholderComponent={PlaceholderComponent}
          item={item}
          isChild={false}
          readonly={readonly}
          showPlaceholder={showPlaceholder}
        />
      ))}
    </>
  )
}
