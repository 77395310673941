import { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormLabel from "@mui/material/FormLabel"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import { makeStyles } from "tss-react/mui"
import { useMutation, useQuery } from "@tanstack/react-query"
import { CurrencyField, SelectInput } from "common/form-components"

import { useFormContext } from "./context"
import { queryKeys } from "../react-query/constants"
import { fetchCarrierInfoForCase, saveCarrierInfo, updateCarrierInfo } from "../api"
import { StateSelect } from "../common/StateSelect"

import { getCommonMutateOptions } from "../utils"
import useAutosave from "../hooks/useAutosave"
import { Controller, useForm } from "react-hook-form"
import isNumber from "lodash/isNumber"
import { useOutletContext } from "react-router-dom"
import { formSectionsToRoutes, STEP_STATUSES } from "./constants"
import {
  INDIVIDUAL,
  POLICY_COVERAGE_TYPE_MAP,
  POLICY_TYPES,
  PolicyCoverageTypeFilter,
} from "requests/constants"

const INITIAL_FORM_STATE = {
  first_name: "",
  last_name: "",
  gender: "",
  policy: {
    type: INDIVIDUAL,
    coverage_type: "",
    limit: "",
    adjuster_first_name: "",
    adjuster_last_name: "",
    adjuster_gender: "",
    adjuster_email: "",
    claim_number: "",
    policy_number: "",
  },
  carrier: {
    name: "",
    address: {
      street: "",
      street_2: "",
      city: "",
      state: "",
      zip_code: "",
    },
  },
}

const policyTypeWithOther = [...POLICY_TYPES, { key: "other", display: "Other" }]

const useStyles = makeStyles()(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(4),
  },
  fullWidth: {
    gridColumn: "1 / 4",
  },
}))

export function CarrierForm({ lastVisited }) {
  const { classes } = useStyles()
  const { saveRef } = useOutletContext()
  const { caseId, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus } = useFormContext()
  const [errors, setErrors] = useState(null)
  const { control, handleSubmit, reset, getValues, setValue, register, formState, watch } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  })
  // update state if any
  useQuery([queryKeys.carrier, caseId], () => fetchCarrierInfoForCase(caseId), {
    onSuccess: data => {
      if (data?.length) {
        reset(data[0])
      }
    },
  })

  const mutationOptions = getCommonMutateOptions({
    setErrors,
    reset,
    setValue,
    setSavedSuccessfully,
    showErrorMessage,
    onSuccessExtra: () => {
      handleUpdateStepStatus({ status: STEP_STATUSES.completed })
    },
  })
  const createMutation = useMutation(saveCarrierInfo, mutationOptions)
  const updateMutation = useMutation(updateCarrierInfo, mutationOptions)
  // succeed/fail silently
  const autosaveMutation = useMutation(updateCarrierInfo)

  const handleOnBlur = handleSubmit(async data => {
    let pk = getValues("pk")
    if (isNumber(pk)) {
      return updateMutation.mutateAsync({ caseId, data: { pk, ...data, case_id: caseId } })
    } else {
      return createMutation.mutateAsync({ caseId, data })
    }
  })

  useEffect(() => {
    saveRef.current = handleOnBlur
  }, [handleOnBlur, saveRef])

  useAutosave({
    shouldAutosave: formState.isDirty && getValues("pk"),
    save: () => {
      autosaveMutation.mutate({ caseId, data: { ...getValues(), case_id: caseId } })
    },
  })

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.carrier
  })

  const currentPolicyType = watch("policy.type")
  const coverageTypes = POLICY_COVERAGE_TYPE_MAP.get(currentPolicyType)?.filter(policyType =>
    PolicyCoverageTypeFilter(policyType, getValues("policy.coverage_type"))
  )

  return (
    <form className={classes.formFields} noValidate onBlur={handleOnBlur}>
      <input type={"hidden"} {...register("pk")} />
      <Controller
        name="first_name"
        control={control}
        render={({ field }) => (
          <TextField
            label="Defendant's First Name"
            variant="outlined"
            error={errors?.first_name}
            helperText={errors?.first_name}
            {...field}
          />
        )}
      />
      <Controller
        name="last_name"
        control={control}
        render={({ field }) => (
          <TextField
            label="Defendant's Last Name"
            variant="outlined"
            error={errors?.last_name}
            helperText={errors?.last_name}
            {...field}
          />
        )}
      />
      <Controller
        name="gender"
        control={control}
        render={({ field }) => (
          <FormControl component="fieldset" error={errors?.gender} variant="standard">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup aria-label="defendant's gender" name="gender" row {...field}>
              <FormControlLabel value="female" control={<Radio color="secondary" />} label="Female" />
              <FormControlLabel value="male" control={<Radio color="secondary" />} label="Male" />
            </RadioGroup>
            {errors?.gender && <FormHelperText>Please select one of the above options.</FormHelperText>}
          </FormControl>
        )}
      />
      <Controller
        name="carrier.name"
        control={control}
        render={({ field: { value, onChange, name } }) => (
          <TextField
            label="Name of carrier"
            variant="outlined"
            error={errors?.carrier?.name}
            helperText={errors?.carrier?.name}
            value={value}
            onChange={onChange}
            name={name}
          />
        )}
      />

      <CurrencyField
        name="policy.limit"
        control={control}
        label="Policy Limit"
        error={errors}
        helperText={errors?.policy?.limit}
      />
      <div />
      <SelectInput name="policy.type" control={control} label="Policy Type" options={policyTypeWithOther} />
      <SelectInput
        name="policy.coverage_type"
        control={control}
        label="Policy Coverage Type"
        options={coverageTypes}
      />
      <Controller
        name="policy.policy_number"
        control={control}
        render={({ field }) => (
          <TextField
            label="Policy Number"
            variant="outlined"
            error={errors?.policy?.policy_number}
            helperText={errors?.policy?.policy_number}
            {...field}
          />
        )}
      />
      <Controller
        name="policy.adjuster_first_name"
        control={control}
        render={({ field }) => (
          <TextField
            label="Adjuster's First Name"
            variant="outlined"
            error={errors?.policy?.adjuster_first_name}
            helperText={errors?.policy?.adjuster_first_name}
            {...field}
          />
        )}
      />
      <Controller
        name="policy.adjuster_last_name"
        control={control}
        render={({ field }) => (
          <TextField
            label="Adjuster's Last Name"
            variant="outlined"
            error={errors?.policy?.adjuster_last_name}
            helperText={errors?.policy?.adjuster_last_name}
            {...field}
          />
        )}
      />
      <Controller
        name="policy.adjuster_gender"
        control={control}
        render={({ field }) => (
          <FormControl component="fieldset" error={errors?.policy?.adjuster_gender} variant="standard">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup aria-label="adjuster's gender" row {...field}>
              <FormControlLabel value="female" control={<Radio color="secondary" />} label="Female" />
              <FormControlLabel value="male" control={<Radio color="secondary" />} label="Male" />
            </RadioGroup>
            {errors?.policy?.adjuster_gender && (
              <FormHelperText>Please select one of the above options.</FormHelperText>
            )}
          </FormControl>
        )}
      />
      <Controller
        name="policy.adjuster_email"
        control={control}
        render={({ field }) => (
          <TextField
            label="Adjuster's Email Address"
            variant="outlined"
            className={classes.fullWidth}
            error={errors?.policy?.adjuster_email}
            helperText={errors?.policy?.adjuster_email}
            {...field}
          />
        )}
      />
      <Controller
        name="policy.claim_number"
        control={control}
        render={({ field }) => (
          <TextField
            label="Claim number"
            variant="outlined"
            className={classes.fullWidth}
            error={errors?.policy?.claim_number}
            helperText={errors?.policy?.claim_number}
            {...field}
          />
        )}
      />
      <Controller
        name="carrier.address.street"
        control={control}
        render={({ field }) => (
          <TextField
            label="Carrier Street Address"
            variant="outlined"
            className={classes.fullWidth}
            error={errors?.carrier?.address?.street}
            helperText={errors?.carrier?.address?.street}
            {...field}
          />
        )}
      />
      <Controller
        name="carrier.address.street_2"
        control={control}
        render={({ field }) => (
          <TextField
            label="Street Address Line 2"
            variant="outlined"
            className={classes.fullWidth}
            error={errors?.carrier?.address?.street_2}
            helperText={errors?.carrier?.address?.street_2}
            {...field}
          />
        )}
      />
      <Controller
        name="carrier.address.city"
        control={control}
        render={({ field }) => (
          <TextField
            label="City"
            variant="outlined"
            error={errors?.carrier?.address?.city}
            helperText={errors?.carrier?.address?.city}
            {...field}
          />
        )}
      />
      <StateSelect
        control={control}
        selectName="carrier.address.state"
        errors={errors?.carrier?.address?.state}
      />
      <Controller
        name="carrier.address.zip_code"
        control={control}
        render={({ field }) => (
          <TextField
            label="Zip code"
            variant="outlined"
            error={errors?.carrier?.address?.zip_code}
            helperText={errors?.carrier?.address?.zip_code}
            {...field}
          />
        )}
      />
    </form>
  )
}
