import styled from "@emotion/styled"
import isValidProp from "@emotion/is-prop-valid"
import { Chip, IconButton, chipClasses } from "@mui/material"
import { Link } from "react-router-dom"

export const Container = styled("div")({
  position: "relative",
})

export const CollapseButton = styled(IconButton, {
  shouldForwardProp: prop => isValidProp(prop),
})<{ isCollapsed: boolean }>(({ theme, isCollapsed }) => ({
  position: "absolute",
  right: theme.spacing(2),
  height: "46px",
  width: "46px",
  transform: isCollapsed ? "rotate(180deg)" : "none",
  transition: "0.2s",
}))

export const DateLink = styled(Link)({
  color: "inherit",
})

export const Table = styled("table")(({ theme }) => ({
  display: "block",
  width: "100%",
  borderRadius: "6px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  marginBottom: theme.spacing(4),
}))

export const TableHead = styled("thead")<{ hideBorder?: boolean }>(({ hideBorder }) => ({
  display: "block",
  textAlign: "left",
  lineHeight: "46px",
  color: "#636C79",
  borderBottom: hideBorder ? "none" : "1px solid rgba(0, 0, 0, 0.12)",
}))

export const TableHeader = styled("th")(({ theme }) => ({
  padding: `0 ${theme.spacing(4)}`,
}))

export const TableBody = styled("tbody")<{ isCollapsed?: boolean }>(({ isCollapsed }) => ({
  display: "block",
  height: isCollapsed ? 0 : "auto",
  overflow: "hidden",
}))

export const Row = styled("tr")<{ hideBorder?: boolean }>(({ hideBorder }) => ({
  display: "grid",
  gridTemplateColumns: "200px 1fr 200px 300px",
  placeItems: "center start",

  "&:not(:last-of-type)": {
    borderBottom: hideBorder ? "none" : "1px solid rgba(0, 0, 0, 0.05)",
  },
}))

export const Cell = styled("td")(({ theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(4)}`,
}))

export const ReferenceChip = styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.label}`]: {
    padding: theme.spacing(0, 1.25),
  },
}))
ReferenceChip.defaultProps = {
  size: "small",
}
