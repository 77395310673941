import { SummaryRequestDto } from "../summaries/types"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import {
  ReviewItemStatus,
  ReviewItemTextType,
  ReviewItemType,
  SectionType,
  TemplateType,
} from "review/store/types"

export interface CreateMinimalMatter {
  firstName: string
  lastName: string
  firmId: number
  title: string
  initializeSummaryRequest: boolean
}

export interface CreateMinimalMatterDto {
  first_name: string
  last_name: string
  firm_id: number
  title: string
  initialize_summary_request: boolean
}

export interface MinimalCreateMatterResponse {
  pk: string
  summary_request?: SummaryRequestDto
}

export enum FLAG_CATEGORY {
  FLAG_LEFT_WITHOUT_TREATMENT = "Left Without Treatment",
  FLAG_REFUSED_TREATMENT = "Refused Treatment",
  FLAG_PATIENT_BEHAVIOR = "Patient Behavior",
  FLAG_FAILED_TO_SHOW_FOR_APPOINTMENT = "Failed to Show for Appointment",
  FLAG_INCONSISTENCY_CONTRADICTION_IN_RECORDS = "Inconsistency/Contradiction in Records",
  FLAG_FAILED_TO_FOLLOW_TREATMENT_RECOMMENDATIONS = "Failed to Follow Treatment Recommendations",
  FLAG_ALCOHOL_SUBSTANCE_USE = "Alcohol/Substance Use",
  FLAG_PRIOR_INJURY = "Prior Injury",
  FLAG_PRIOR_TREATMENT = "Prior Treatment",
  FLAG_SUBSEQUENT_INCIDENT = "Subsequent Incident",
  FLAG_CRIMINAL_HISTORY = "Criminal History",
  FLAG_MENTAL_HEALTH = "Mental Health",
  FLAG_SEXUAL_HEALTH_INFORMATION = "Sexual Health Information",
  FLAG_MISSING_RECORDS = "Missing Records",
  FLAG_MISSING_BILLS = "Missing Bills",
  FLAG_OTHER = "Other",
}

export interface FlagPartitionDTO {
  partition_id: PrimaryKey
  page_number: Nullable<number>
}

export interface FlagDTO {
  pk: string
  category: FLAG_CATEGORY
  appointment: string
  reason?: string
  quote?: string
  partitions?: FlagPartitionDTO[]
}

export type ReviewArgsDTO = {
  plaintiff_id?: PrimaryKey | string
} & EitherProps<
  {
    provider_id: PrimaryKey
    template_type: Extract<TemplateType, "provider">
  },
  {
    template_type: Exclude<TemplateType, "provider">
    section_type: SectionType
  }
>

export interface ReviewItemReferenceDTO {
  section: Nullable<string>
  field_identifier: Nullable<string>
  document_section_content_id: Nullable<string>
  value: Nullable<string | number>
  annotations?: null
  id: string
}

export interface ReviewItemDTO {
  id: string
  review_type: ReviewItemType
  status: ReviewItemStatus
  original_text: string
  suggested_text: string
  user_modified_text: Nullable<string>
  output_text_type: ReviewItemTextType
  category: string
  explanation: string
  start_index: Nullable<number>
  end_index: Nullable<number>
  confidence: Nullable<number>
  references: ReviewItemReferenceDTO[]
}

export type ReviewRunDTO = {
  review_request_id: string
  created_at?: string
  matter_id?: string
  content: EditorRoot<false>
  results: ReviewItemDTO[]
} & ReviewArgsDTO
