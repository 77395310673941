import { ReactElement, useRef } from "react"
import { Control, Controller, FieldErrors, FieldValues, Path, UseControllerProps } from "react-hook-form"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"

interface SwitchInputProps<TFields extends FieldValues> {
  control: Control<TFields>
  name: Path<TFields>
  rules?: UseControllerProps<TFields>["rules"]
  errors?: FieldErrors<TFields>
  label?: Nullable<string>
  className?: string
  disabled?: boolean
  color?: "primary" | "error" | "secondary" | "info" | "success" | "warning" | "default"
  labelProps?: object
}

export function SwitchInput<T extends FieldValues>({
  control,
  name,
  color = "primary",
  ...inputProps
}: SwitchInputProps<T>): ReactElement {
  const inputRef = useRef<Nullable<HTMLInputElement>>(null)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        type FieldType = typeof field
        const onChange: FieldType["onChange"] = e => {
          field.onChange(e)
          inputRef.current?.focus()
        }

        const SwitchInput = (
          <>
            <Switch {...field} {...inputProps} onChange={onChange} checked={field.value} color={color} />

            <input
              ref={inputRef}
              type="checkbox"
              defaultChecked={field.value}
              style={{ width: 0, height: 0, opacity: 0, padding: 0, margin: 0 }}
            />
          </>
        )

        if (!inputProps?.label) {
          return SwitchInput
        }

        return (
          <>
            <FormControlLabel
              control={SwitchInput}
              label={
                <Typography display="block" {...inputProps?.labelProps}>
                  {inputProps?.label}
                </Typography>
              }
            />
          </>
        )
      }}
    />
  )
}
