import { useEffect, useState } from "react"
import { Box, useTheme } from "@mui/material"
import AssigneePicker, { Segments } from "requests/AssigneePicker"
import { RequestViewDto } from "./types"
import { ObjectWithPk } from "utils/common"
import { useSaveOperatorMutation } from "./hooks/useSaveOperatorMutation"
import useUser from "hooks/useUser"

interface RequestOperatorsProps {
  request: RequestViewDto
  handleSelfAssign: () => void
}

// An operator is someone who has either drafted, reviewed, or reworked a request
export function RequestOperators({ request }: RequestOperatorsProps): JSX.Element {
  const theme = useTheme()
  const { user } = useUser()
  const [draftedBy, setDraftedBy] = useState(request.drafted_by)
  const [reviewedBy, setReviewedBy] = useState(request.reviewed_by)
  const [reworkedBy, setReworkedBy] = useState(request.reworked_by)

  useEffect(() => {
    setDraftedBy(request.drafted_by)
    setReviewedBy(request.reviewed_by)
    setReworkedBy(request.reworked_by)
  }, [request])

  const requestOperatorMutation = useSaveOperatorMutation()
  const handleOperatorChange =
    (fieldName: "drafted_by" | "reviewed_by" | "reworked_by") =>
    ({ newValues: value }: { newValues: ObjectWithPk }) => {
      requestOperatorMutation.mutate({ requestId: request.pk, [fieldName]: value ? value.pk : null })
    }

  if (!user.isInternal) {
    return <></>
  }

  return (
    <Box
      display="grid"
      gap={theme.spacing(1.5)}
      gridTemplateColumns="1fr 1fr 1fr"
      marginTop={theme.spacing(1.5)}
    >
      <AssigneePicker
        label="Drafted By"
        value={draftedBy}
        onChange={handleOperatorChange("drafted_by")}
        segment={Segments.INTERNAL}
        singleAssignee
      />
      <AssigneePicker
        label="Reviewed By"
        value={reviewedBy}
        onChange={handleOperatorChange("reviewed_by")}
        segment={Segments.INTERNAL}
        singleAssignee
      />
      <AssigneePicker
        label="Reworked By"
        value={reworkedBy}
        onChange={handleOperatorChange("reworked_by")}
        segment={Segments.INTERNAL}
        singleAssignee
      />
    </Box>
  )
}
