import { useCallback } from "react"
import { useMutation } from "@tanstack/react-query"
import { startDemand } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { Link, useNavigate, useParams } from "react-router-dom"

import { BE_DUPLICATE_ERROR_TEXT, STANDARD_DUPLICATE_ERROR, STANDARD_ERROR } from "../constants"
import { StyledRequestButton } from "./styled"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"
import { RequestFields } from "requests/RequestForm/types"

interface DemandButtonProps {
  demandId: Nullable<PrimaryKey>
  request: RequestFields
}

export const DemandButton = ({ demandId, request }: DemandButtonProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { showErrorMessage } = useHandleMessages()
  const shouldCreate = !demandId

  const startDemandMutation = useMutation(startDemand, {
    onSuccess: ({ case_id }) => {
      navigate(`/demands/${case_id}/form`)
      amplitudeApm.trackEvent(
        new DemandAnalyticEvent(DemandAnalyticsEventTypes.StartedDemand, {
          demand_id: case_id,
          request_id: `${request.pk}`,
          request_type: request.type,
        })
      )
    },
    onError: (error: { message: string }) => {
      if (error.message.includes(BE_DUPLICATE_ERROR_TEXT)) {
        showErrorMessage({
          message: STANDARD_DUPLICATE_ERROR,
          error,
        })
      } else {
        showErrorMessage({
          message: STANDARD_ERROR,
          error,
        })
      }
    },
  })

  const handleStartDemand = useCallback(
    () => startDemandMutation.mutate({ requestId: id }),
    [startDemandMutation, id]
  )

  return shouldCreate ? (
    <StyledRequestButton data-test="start-demand-button" onClick={handleStartDemand}>
      Start Demand
    </StyledRequestButton>
  ) : (
    <StyledRequestButton component={Link} data-test="go-to-demand-button" to={`/demands/${demandId}`}>
      Go To Demand
    </StyledRequestButton>
  )
}
