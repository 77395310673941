import { AnnotationStatus } from "./types"

export const EXHIBIT_DELETED_STATUS: Record<string, string> = {
  name: "deleted",
  display: "Deleted",
}

export const NO_AUTO_IMPORT_TEXT = "No Auto-Import"

export const PROVIDER_ACRONYMS = new Set<string>([
  "MD",
  "OD",
  "DO",
  "DDS",
  "DMD",
  "DPT",
  "DC",
  "RN",
  "PT",
  "PA-C",
  "PA",
  "NP",
])

export const AUX_WORDS_TO_TITLE_CASE = new Set<string>(["SAN", "LOS", "LAS", "NEW", "ST", "AVE"])

export const STATUS_TO_TEXT: Record<AnnotationStatus, string> = {
  pre_annotating: "Pre-Annotation In Progress",
  annotating: "Annotation In Progress",
  auto_complete: "Automated Annotation Completed",
  complete: "Annotation Completed",
  error: "Error",
}
