import { HierarchyAttribute, Attributes } from "common/models/attributes"
import { Attributes as AttributesType, AttributeValue } from "common/types/attributes"
import { AttributeDto, AttributeValueDto } from "./types"

export class AttributesServiceDeserializer {
  static attributeValueFromJSON({ value_id, value }: AttributeValueDto): AttributeValue {
    return {
      id: value_id,
      value,
    }
  }

  static fromJSON(attribute: AttributeDto): HierarchyAttribute {
    return {
      id: attribute.pk,
      title: attribute.title,
      parentValueId: attribute.origin_value,
      values: attribute.children.map(AttributesServiceDeserializer.attributeValueFromJSON),
    }
  }

  static fromListJSON(attributes: AttributeDto[]): AttributesType {
    return new Attributes(attributes.map(AttributesServiceDeserializer.fromJSON))
  }
}
