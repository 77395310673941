import { useCallback, useEffect, useReducer, useState } from "react"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { fetchProvidersByCase, getCase, fetchRequest_DEPRECATED } from "api"

import { FormProvider } from "./FormContext"
import { actions as queryActions, INITIAL_STATE, QueryReducer } from "./state"
import SearchResults from "./SearchResults"
import { Helmet } from "react-helmet"
import { FREE_TEXT_QUERY } from "./constants"
import { getSummaryData } from "demand/Providers/Summary"
import { noop } from "lodash"
import { getQueryParamsFromState, getSearchParamsFromQueryString } from "./utils"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import MobileNavButton from "demand/LeftNav/MobileNavButton"
import { DocumentSkeleton } from "documents/skeletons/DocumentSkeleton"
import { GenericErrorForSuspense } from "common"
import { withSuspense } from "common/withSuspense"

import { RequestPanelPageWrapper } from "requests/RequestPanelPageWrapper"

const useStyles = makeStyles()(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
}))

export const Search = withSuspense(
  function Search({ lastSearchParams, setLastSearchParams = noop }) {
    const { classes } = useStyles()
    const { id: caseId } = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const [text, setText] = useState("")
    const [isStateChanged, setIsStateChanged] = useState(false)
    const [isInitialStateApplied, setIsInitialStateApplied] = useState(false)

    const { data: caseInfo, isFetching } = useQuery([queryKeys.case, caseId], getCase, {
      enabled: !!caseId,
    })
    const { data: request } = useQuery(
      [queryKeys.request, caseInfo?.questionnaire_id],
      fetchRequest_DEPRECATED,
      {
        enabled: !!caseInfo?.questionnaire_id,
        meta: {
          disableLoader: true,
        },
      }
    )
    const { isFetching: providersLoading, data: providers } = useQuery(
      [queryKeys.providers, caseId],
      fetchProvidersByCase,
      {
        enabled: !!caseId,
      }
    )
    const [queryState, dispatch] = useReducer(QueryReducer, INITIAL_STATE)

    useEffect(() => {
      amplitudeApm.trackEvent(
        new DemandAnalyticEvent(DemandAnalyticsEventTypes.CaseSearchStarted, {
          demand_id: caseId,
          request_id: caseInfo?.questionnaire_id,
        })
      )

      return () => {
        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(DemandAnalyticsEventTypes.CaseSearchExited, {
            demand_id: caseId,
            request_id: caseInfo?.questionnaire_id,
          })
        )
      }
    }, [caseInfo, caseId])

    const handleQueryInputChange = useCallback(event => {
      setText(event.target.value)
    }, [])

    const handleQueryUpdate = useCallback(
      (action, payload) => {
        setIsStateChanged(true)
        dispatch({ action, payload })
      },
      [dispatch]
    )

    const handleSubmit = useCallback(
      event => {
        event.preventDefault()
        handleQueryUpdate(queryActions.UPDATE_QUERY_TEXT, text)
      },
      [handleQueryUpdate, text]
    )

    const applyInitialParams = useCallback(
      initialParams => {
        setIsInitialStateApplied(true)
        dispatch({ action: queryActions.SET_INITIAL_STATE_PARAMS, payload: initialParams })
      },
      [dispatch]
    )

    useEffect(() => {
      // should be called only once
      // priority of the params: location.search => lastSearch => params based on the corresponding demand info
      if (isInitialStateApplied) {
        return
      }

      if (location.search) {
        const paramsFromURLQuery = getSearchParamsFromQueryString(location.search)

        setText(paramsFromURLQuery[FREE_TEXT_QUERY])
        applyInitialParams(paramsFromURLQuery)

        return
      }

      if (isFetching || providersLoading) {
        return
      }

      const initialSearchParams = { [FREE_TEXT_QUERY]: "", query: {} }

      if (caseInfo?.state) {
        initialSearchParams.query.state = [caseInfo.state]
      }

      if (providers) {
        const { totalMedicalExpenses } = getSummaryData(providers)

        if (totalMedicalExpenses !== 0) {
          initialSearchParams.query.total_settlement_amount = `[[${Math.ceil(
            totalMedicalExpenses * 3
          )},${Math.ceil(totalMedicalExpenses * 100)}]]`
        }
      }

      if (lastSearchParams) {
        const paramsFromLastSearch = getSearchParamsFromQueryString(`?${lastSearchParams}`)

        initialSearchParams[FREE_TEXT_QUERY] = paramsFromLastSearch[FREE_TEXT_QUERY]
        initialSearchParams.query = { ...initialSearchParams.query, ...paramsFromLastSearch.query }

        setText(initialSearchParams.initialText)
        navigate(`?${lastSearchParams}`, { replace: true })
      }

      applyInitialParams(initialSearchParams)
    }, [
      applyInitialParams,
      caseInfo,
      isInitialStateApplied,
      isFetching,
      lastSearchParams,
      location.search,
      navigate,
      providers,
      providersLoading,
    ])

    useEffect(() => {
      if (!isStateChanged) return
      // sync state to URL
      const newParams = getQueryParamsFromState(queryState)

      if (newParams && location.search !== `?${newParams}`) {
        setLastSearchParams(newParams)
        navigate(`?${newParams}`)
      }
    }, [location.search, navigate, queryState, setLastSearchParams, isInitialStateApplied, isStateChanged])

    return (
      <>
        <Helmet>
          <title>Search - EvenUp</title>
        </Helmet>
        <Box component="main">
          <RequestPanelPageWrapper request={request} caseId={caseId}>
            <FormProvider value={{ queryState, caseInfo, handleQueryUpdate, queryActions }}>
              <form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
                <Box mb={1}>
                  <MobileNavButton />
                </Box>
                <Box maxWidth={"60%"}>
                  <TextField
                    id="query"
                    label="Case Facts / Injuries"
                    placeholder="Describe the facts or injuries for the case"
                    variant="outlined"
                    name="facts"
                    value={text}
                    onChange={handleQueryInputChange}
                    autoFocus
                    fullWidth
                    data-test="search-bar"
                  />
                </Box>
                <Button
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  type="submit"
                  data-test="search-button"
                  onClick={handleSubmit}
                >
                  Search
                </Button>
              </form>
              <SearchResults caseId={caseId} />
            </FormProvider>
          </RequestPanelPageWrapper>
        </Box>
      </>
    )
  },
  <DocumentSkeleton />,
  <GenericErrorForSuspense />
)
