import { DocumentTitle as DocumentTitleComponent, StyledTag } from "./styled"
import { useDocumentStore } from "./store"
import { useDocumentStateStore } from "./store/documentState"

export function DocumentTitle(): Nullable<JSX.Element> {
  const plaintiff = useDocumentStore(state => state.plaintiff)
  const title = useDocumentStateStore(state => state.title)
  const showPlaintiff = useDocumentStateStore(state => state.showPlaintiff)
  const titleTags = useDocumentStateStore(state => state.titleTags)

  return (
    <DocumentTitleComponent>
      <span>{title && `${title} ${plaintiff && showPlaintiff ? `for ${plaintiff}` : ""}`}</span>
      {titleTags.map(tag => (
        <StyledTag variant="labelBadge" key={tag}>
          {tag}
        </StyledTag>
      ))}
    </DocumentTitleComponent>
  )
}
