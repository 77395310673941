import { useMemo, useRef } from "react"
import { debounce, DebouncedFunc } from "lodash"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...args: any[]) => void

const DEBOUNCED_TIME = 800

export function useDebouncedCallback<T extends Callback>(
  callback: T,
  debounceTime = DEBOUNCED_TIME
): DebouncedFunc<T> {
  const ref = useRef<T>(callback)

  ref.current = callback

  return useMemo(
    () => debounce<T>(((...args) => ref.current(...args)) as T, debounceTime),
    [ref, debounceTime]
  )
}
