import {
  ExhibitGroup,
  ExhibitSection,
  ExhibitOrPartitionList,
  ExhibitOrPartition,
  ExhibitSectionChildren,
} from "./Exhibit"
import { SECTIONS } from "./enums"
import { EXHIBIT_ITEM_TYPES } from "./enums"

import { getUnixTime } from "date-fns"
import { BILL_TYPES, RECORD_TYPES, FILE_GROUP_TYPE, FILE_CATEGORY_OPTIONS } from "../../common/constants"
import { addNewGroup } from "./utils"
import { EXHIBIT_SORTING_VALUES } from "../../settings/Firm/enums"
import { PlaintiffDto } from "api/services/case/types"
import { groupBy } from "lodash"

const BLANK_PROVIDER_NAME = "Blank Provider"
const BLANK_GROUP_NAME = "New Group"

const getExhibitsFromGroup = (
  record: ExhibitGroup | ExhibitSection,
  exhibits: ExhibitOrPartitionList = []
): void => {
  record.children.forEach((value: ExhibitSectionChildren | ExhibitGroup | ExhibitSection) => {
    if (value.type === EXHIBIT_ITEM_TYPES.SECTION) {
      return getExhibitsFromGroup(value, exhibits)
    }

    if (value.type === EXHIBIT_ITEM_TYPES.GROUP) {
      return getExhibitsFromGroup(value, exhibits)
    }

    if (value.type === EXHIBIT_ITEM_TYPES.EXHIBIT) {
      exhibits.push(value)
    }

    if (value.type === EXHIBIT_ITEM_TYPES.PARTITION) {
      exhibits.push(value)
    }
  })
}

const getProviderExhibits = (
  sections: ExhibitSection[]
): { providerExhibits: Nullable<ExhibitOrPartitionList>; providerIndex: Nullable<number> } => {
  const providerExhibits: ExhibitOrPartitionList = []
  let providerIndex: Nullable<number> = null

  const section =
    sections.find((section: ExhibitSection, i: number) => {
      if (section.section === SECTIONS.PROVIDERS) {
        providerIndex = i
        return section
      }
    }) ?? null

  if (!section) {
    return {
      providerExhibits: null,
      providerIndex: null,
    }
  }

  getExhibitsFromGroup(section, providerExhibits)

  return {
    providerExhibits: providerExhibits,
    providerIndex: providerIndex,
  }
}

const groupExhibitsByPlaintiff = (
  exhibits: ExhibitOrPartitionList,
  plaintiffs: PlaintiffDto[]
): ExhibitOrPartitionList[] => {
  if (!plaintiffs.length) {
    return [exhibits]
  }

  const noPlaintiffKey = "no-plaintiff"

  const exhibitsByPlaintiff = groupBy(exhibits, exhibit => {
    return exhibit.plaintiff_id ?? exhibit.plaintiff ?? noPlaintiffKey
  })

  return plaintiffs
    .map(plaintiff => {
      return exhibitsByPlaintiff[plaintiff.pk] ?? []
    })
    .concat([exhibitsByPlaintiff[noPlaintiffKey] ?? []])
}

const splitAndOrderExhibitsPerProvider = (exhibits: ExhibitOrPartitionList): ExhibitOrPartitionList[] => {
  return (
    exhibits
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((currentSplit: any, currentExhibit: ExhibitOrPartition) => {
        const provider = currentExhibit.provider ?? 0
        const existingArray = currentSplit[provider] || []
        currentSplit[provider] = [...existingArray, currentExhibit]

        return currentSplit
      }, [])
      .sort((a: ExhibitOrPartitionList, b: ExhibitOrPartitionList) => {
        const aOrderIndex = a[0].provider_order_index ?? 0
        const bOrderIndex = b[0].provider_order_index ?? 0

        return (
          aOrderIndex - bOrderIndex ||
          getUnixTime(new Date(a[0].first_contact ?? 0)) - getUnixTime(new Date(b[0].first_contact ?? 0))
        )
      })
  )
}

const getFileTypePriority = (fileType: FILE_CATEGORY_OPTIONS, recordsFirst: boolean): number => {
  switch (fileType) {
    case FILE_CATEGORY_OPTIONS.MEDICAL:
      return recordsFirst ? 3 : 2
    case FILE_CATEGORY_OPTIONS.MEDICAL_BILL:
      return recordsFirst ? 2 : 3
    case FILE_CATEGORY_OPTIONS.MEDICAL_BILL_RECORD:
      return 1
    default:
      return 0
  }
}

const sortExhibitsByFileTypeGroup = (
  exhibits: ExhibitOrPartitionList,
  fileTypeGroup: FILE_GROUP_TYPE
): ExhibitOrPartitionList => {
  const recordsFirst = fileTypeGroup === RECORD_TYPES
  return exhibits.sort((a: ExhibitOrPartition, b: ExhibitOrPartition) => {
    const aSectionIndex = a.section_index ?? 0
    const bSectionIndex = b.section_index ?? 0
    return (
      getFileTypePriority(b.fileType, recordsFirst) - getFileTypePriority(a.fileType, recordsFirst) ||
      aSectionIndex - bSectionIndex ||
      getUnixTime(new Date(a.first_contact ?? 0)) - getUnixTime(new Date(b.first_contact ?? 0))
    )
  })
}

const sortExhibitsByProviderGroup = (exhibits: ExhibitOrPartitionList): ExhibitOrPartitionList => {
  return exhibits.sort((a: ExhibitOrPartition, b: ExhibitOrPartition) => {
    const aOrderIndex = a.provider_order_index ?? 0
    const bOrderIndex = b.provider_order_index ?? 0
    const aSectionIndex = a.section_index ?? 0
    const bSectionIndex = b.section_index ?? 0

    return (
      aOrderIndex - bOrderIndex ||
      aSectionIndex - bSectionIndex ||
      getUnixTime(new Date(a.first_contact ?? 0)) - getUnixTime(new Date(b.first_contact ?? 0))
    )
  })
}

const combineSplitGroups = (splitExhibits: ExhibitOrPartitionList[]): ExhibitOrPartitionList => {
  return splitExhibits.flat()
}

const sortPerProviderByFileTypeGroup = (
  sections: ExhibitSection[],
  fileTypeGroup: FILE_GROUP_TYPE,
  plaintiffs: PlaintiffDto[] = []
): ExhibitSection[] => {
  const { providerExhibits, providerIndex } = getProviderExhibits(sections)

  if (providerIndex === null || providerExhibits === null) {
    return sections
  }

  sections[providerIndex].children = groupExhibitsByPlaintiff(providerExhibits, plaintiffs).flatMap(
    exhibits => {
      const splitExhibits = splitAndOrderExhibitsPerProvider(exhibits)
      const sortedGroups = Object.values(splitExhibits).map((exhibitList: ExhibitOrPartitionList) => {
        return sortExhibitsByFileTypeGroup(exhibitList, fileTypeGroup)
      })

      return combineSplitGroups(sortedGroups)
    }
  )

  return sections
}

const splitAndOrderExhibitsPerFileType = (
  exhibits: ExhibitOrPartitionList,
  recordsFirst: boolean
): ExhibitOrPartitionList[] => {
  return (
    exhibits
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((currentSplit: any, currentExhibit: ExhibitOrPartition) => {
        const fileType = getFileTypePriority(currentExhibit.fileType, recordsFirst) ?? 0
        const existingArray = currentSplit[fileType] || []
        currentSplit[fileType] = [...existingArray, currentExhibit]
        return currentSplit
      }, [])
      .sort((a: ExhibitOrPartitionList, b: ExhibitOrPartitionList) => {
        return (
          getFileTypePriority(b[0].fileType, recordsFirst) -
            getFileTypePriority(a[0].fileType, recordsFirst) ||
          getUnixTime(new Date(a[0].first_contact ?? 0)) - getUnixTime(new Date(b[0].first_contact ?? 0))
        )
      })
  )
}

const sortByFileTypeGroup = (
  sections: ExhibitSection[],
  fileTypeGroup: FILE_GROUP_TYPE,
  plaintiffs: PlaintiffDto[] = []
): ExhibitSection[] => {
  const { providerExhibits, providerIndex } = getProviderExhibits(sections)
  const recordsFirst = fileTypeGroup === RECORD_TYPES

  if (providerIndex === null || providerExhibits === null) {
    return sections
  }

  sections[providerIndex].children = groupExhibitsByPlaintiff(providerExhibits, plaintiffs).flatMap(
    exhibits => {
      const splitExhibits = splitAndOrderExhibitsPerFileType(exhibits, recordsFirst)
      const sortedGroups = Object.values(splitExhibits).map((exhibitList: ExhibitOrPartitionList) => {
        return sortExhibitsByProviderGroup(exhibitList)
      })
      return combineSplitGroups(sortedGroups)
    }
  )

  return sections
}

const sortSections = (sections: ExhibitSection[]): ExhibitSection[] => {
  // All other sections are sent to bottom
  const PRIORITY_LIST = [SECTIONS.FACTS, SECTIONS.PROVIDERS]

  return sections.sort((a: ExhibitSection, b: ExhibitSection) => {
    const aIndex = PRIORITY_LIST.indexOf(a.section as SECTIONS)
    const bIndex = PRIORITY_LIST.indexOf(b.section as SECTIONS)

    if (aIndex === -1) return 1
    if (bIndex === -1) return -1

    return aIndex - bIndex
  })
}

/** SORTING */
export const sortPerProviderRecordsThenBills = (
  sections: ExhibitSection[],
  plaintiffs: PlaintiffDto[] = []
): ExhibitSection[] => {
  return sortSections(sortPerProviderByFileTypeGroup(sections, RECORD_TYPES, plaintiffs))
}

export const sortPerProviderBillsThenRecords = (
  sections: ExhibitSection[],
  plaintiffs: PlaintiffDto[] = []
): ExhibitSection[] => {
  return sortSections(sortPerProviderByFileTypeGroup(sections, BILL_TYPES, plaintiffs))
}

export const sortAllBillsThenRecords = (
  sections: ExhibitSection[],
  plaintiffs: PlaintiffDto[] = []
): ExhibitSection[] => {
  return sortSections(sortByFileTypeGroup(sections, BILL_TYPES, plaintiffs))
}

export const sortAllRecordsThenBills = (
  sections: ExhibitSection[],
  plaintiffs: PlaintiffDto[] = []
): ExhibitSection[] => {
  return sortSections(sortByFileTypeGroup(sections, RECORD_TYPES, plaintiffs))
}

/** GROUPING */
export const groupAsIndividualFile = (sections: ExhibitSection[]): ExhibitSection[] => {
  const { providerExhibits, providerIndex } = getProviderExhibits(sections)

  if (providerIndex === null || providerExhibits === null) {
    return sections
  }

  const flattenedExhibits = providerExhibits.flat()
  sections[providerIndex].children = flattenedExhibits

  return sections
}

export const groupAsPerProvider = (sections: ExhibitSection[]): ExhibitSection[] => {
  const { providerExhibits, providerIndex } = getProviderExhibits(sections)

  if (providerIndex === null || providerExhibits === null) {
    return sections
  }

  const groups: ExhibitGroup[] = []
  let currentGroup: ExhibitGroup = addNewGroup(
    SECTIONS.PROVIDERS,
    providerExhibits[0].provider_name ?? BLANK_GROUP_NAME
  )
  let lastProviderId: Nullable<number> = providerExhibits[0].provider ?? 0

  providerExhibits.forEach((exhibit: ExhibitOrPartition) => {
    if (exhibit.provider === lastProviderId) {
      currentGroup.children.push(exhibit)
    } else {
      lastProviderId = exhibit.provider ?? 0
      groups.push(currentGroup)
      currentGroup = addNewGroup(SECTIONS.PROVIDERS, exhibit.provider_name ?? BLANK_GROUP_NAME)
      currentGroup.children.push(exhibit)
    }
  })

  groups.push(currentGroup)
  sections[providerIndex].children = groups

  return sections
}

const addExhibitToGroupMap = (
  exhibit: ExhibitOrPartition,
  groupMap: Map<string, Nullable<ExhibitGroup>>,
  plaintiffs: PlaintiffDto[]
): void => {
  const exhibitType: string = [
    FILE_CATEGORY_OPTIONS.MEDICAL_BILL,
    FILE_CATEGORY_OPTIONS.MEDICAL,
    FILE_CATEGORY_OPTIONS.MEDICAL_BILL_RECORD,
  ].includes(exhibit.fileType)
    ? exhibit.fileType
    : FILE_CATEGORY_OPTIONS.OTHER
  if (!groupMap.get(exhibitType)) {
    let typeString: string
    switch (exhibitType) {
      case FILE_CATEGORY_OPTIONS.MEDICAL_BILL:
        typeString = "Bills"
        break
      case FILE_CATEGORY_OPTIONS.MEDICAL:
        typeString = "Records"
        break
      case FILE_CATEGORY_OPTIONS.MEDICAL_BILL_RECORD:
        typeString = "Bills and Records"
        break
      default:
        typeString = "Other"
    }

    let plaintiffName = ""
    if (plaintiffs && plaintiffs.length > 1) {
      const plaintiff: Nullable<PlaintiffDto> =
        plaintiffs.find(plaintiffItem => {
          return plaintiffItem.pk === exhibit?.plaintiff_id
        }) ?? null

      if (plaintiff) {
        // Extra space at the beginning to separate from provider name
        plaintiffName = ` (${plaintiff.first_name} ${plaintiff.last_name})`
      }
    }

    const groupName = `${exhibit.provider_name ?? BLANK_PROVIDER_NAME}${plaintiffName} - ${typeString}`
    const newGroup: ExhibitGroup = addNewGroup(SECTIONS.PROVIDERS, groupName)
    groupMap.set(exhibitType, newGroup)
  }
  groupMap.get(exhibitType)?.children.push(exhibit)
}

const createBlankGroupMap = (sortingOption: EXHIBIT_SORTING_VALUES): Map<string, Nullable<ExhibitGroup>> => {
  const recordsFirstMap = new Map([
    [FILE_CATEGORY_OPTIONS.MEDICAL, null],
    [FILE_CATEGORY_OPTIONS.MEDICAL_BILL, null],
    [FILE_CATEGORY_OPTIONS.MEDICAL_BILL_RECORD, null],
    [FILE_CATEGORY_OPTIONS.OTHER, null],
  ])
  const billsFirstMap = new Map([
    [FILE_CATEGORY_OPTIONS.MEDICAL_BILL, null],
    [FILE_CATEGORY_OPTIONS.MEDICAL, null],
    [FILE_CATEGORY_OPTIONS.MEDICAL_BILL_RECORD, null],
    [FILE_CATEGORY_OPTIONS.OTHER, null],
  ])

  switch (sortingOption) {
    case EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS:
      return recordsFirstMap
    case EXHIBIT_SORTING_VALUES.ALL_RECORDS_THEN_BILLS:
      return recordsFirstMap
    case EXHIBIT_SORTING_VALUES.PER_PROVIDER_BILLS_AND_RECORDS:
      return billsFirstMap
    case EXHIBIT_SORTING_VALUES.ALL_BILLS_THEN_RECORDS:
      return billsFirstMap
    default:
      throw new Error("Invalid Sorting Option")
  }
}

export const groupAsPerProviderAndFileType = (
  sections: ExhibitSection[],
  sortingOption: EXHIBIT_SORTING_VALUES,
  plaintiffs: PlaintiffDto[] = []
): ExhibitSection[] => {
  const { providerExhibits, providerIndex } = getProviderExhibits(sections)

  if (providerIndex === null || providerExhibits === null) {
    return sections
  }

  const groups: ExhibitGroup[] = []
  let lastProviderId: Nullable<number> = providerExhibits[0].provider ?? 0
  let groupMap: Map<string, Nullable<ExhibitGroup>> = createBlankGroupMap(sortingOption)

  providerExhibits.forEach((exhibit: ExhibitOrPartition) => {
    if (exhibit.provider !== lastProviderId) {
      lastProviderId = exhibit.provider ?? 0
      groupMap.forEach(group => {
        if (group) {
          groups.push(group)
        }
      })
      groupMap = createBlankGroupMap(sortingOption)
    }
    addExhibitToGroupMap(exhibit, groupMap, plaintiffs)
  })

  groupMap.forEach(group => {
    if (group) {
      groups.push(group)
    }
  })

  sections[providerIndex].children = groups

  return sections
}

export const groupAsOneFile = (sections: ExhibitSection[]): ExhibitSection[] => {
  const { providerExhibits, providerIndex } = getProviderExhibits(sections)

  if (providerIndex === null || providerExhibits === null) {
    return sections
  }

  const flattenedExhibits = providerExhibits.flat()
  sections[providerIndex].children = flattenedExhibits

  return sections
}
