import { Alert, AlertTitle, Box } from "@mui/material"
import { SUPPORT_EMAIL } from "common/constants"
import React from "react"

export const PageError: React.FC = () => {
  return (
    <Box sx={{ maxWidth: "525px", marginTop: "150px", marginX: "auto" }}>
      <Alert severity="error">
        <AlertTitle>Unexpected Error</AlertTitle>
        <Box>
          There was an error loading the page, please try and refresh the page. If the error persists contact{" "}
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </Box>
      </Alert>
    </Box>
  )
}
