import { useMemo } from "react"
import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { CaseInformationProps } from "../types"
import { StyledInformationWrapper } from "../styled"
import { InfoContainer } from "./InfoContainer"
import { CASE_TYPES } from "requests/constants"

export const CaseInformation = ({ dateOfIncident, caseType, stateOfIncident }: CaseInformationProps) => {
  const newCaseType = useMemo((): string => {
    return Object.values(CASE_TYPES).find(({ key }) => key === caseType)?.display ?? "-"
  }, [caseType])

  return (
    <RequestFormModule title="Case Information">
      <StyledInformationWrapper>
        <InfoContainer field="Date of Incident" value={dateOfIncident} />
        <InfoContainer field="Case Type" value={newCaseType} />
        <InfoContainer field="State of Incident" value={stateOfIncident} />
      </StyledInformationWrapper>
    </RequestFormModule>
  )
}
