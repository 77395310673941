import { AlertProps } from "@mui/material/Alert"
import { EventMessageText, EventMessageTitle, EventMessageBox } from "./styled"

interface EventMessageProps extends TestableComponentProps {
  severity?: AlertProps["severity"]
  message: string
  title?: string
  className?: string
}

export const EventMessage = ({
  severity = "info",
  message,
  title,
  className,
  ...rest
}: EventMessageProps): JSX.Element => {
  const icon = severity === "info" ? false : undefined

  return (
    <EventMessageBox icon={icon} severity={severity} className={className} {...rest}>
      {title ? <EventMessageTitle>{title}</EventMessageTitle> : null}
      <EventMessageText>{message}</EventMessageText>
    </EventMessageBox>
  )
}
