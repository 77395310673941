import { isEqual } from "lodash"
import { Editor, Path, Range, Transforms } from "slate"
import { DEFAULT_STYLES } from "../constants"
import { CustomEditor, CustomText } from "../CustomEditor"
import { INLINE_ELEMENTS, LEAF_BLOCK_ELEMENTS } from "../elements"
import { normalizeEditor } from "../normalize/editor"
import { normalizeWithDefault } from "../normalize/normalizeWithDefault"
import { normalizeParagraph } from "../normalize/paragraph"
import { TEXT_STYLES } from "../styles"
import { addMarkAtVariables, removeMarkAtVariables } from "./variables/addMarkAtVariables"
import { isBlockNode } from "../queries/elements"
import { isTextNode } from "../queries"

// NOTE: found bug in slate-react which leads to error when creating list
// TODO: verify once bug is fixed and remove this one
const selectFn = Transforms.select

Transforms.select = (...[editor, target]: Parameters<typeof selectFn>): void => {
  const { selection } = editor

  if (
    Range.isRange(target) &&
    Range.isRange(selection) &&
    Path.isDescendant(selection.anchor.path, target.anchor.path) &&
    Path.isDescendant(selection.focus.path, target.focus.path) &&
    selection.anchor.offset === target.anchor.offset &&
    selection.focus.offset === target.focus.offset
  ) {
    return
  }

  return selectFn(editor, target)
}
// NOTE: end of temporary fix

const inlineTypes = Object.values<string>(INLINE_ELEMENTS)
const voidTypes = [
  INLINE_ELEMENTS.SOFT_LINE_BREAK,
  INLINE_ELEMENTS.VARIABLE,
  INLINE_ELEMENTS.CITATION,
  INLINE_ELEMENTS.EMPTY_SPACE,
] as string[]

export function withDefaults<T extends CustomEditor>(editor: T): T {
  const { isInline, isVoid, normalizeNode, addMark, removeMark } = editor

  editor.isInline = element => {
    return inlineTypes.includes(element.type) ? true : isInline(element)
  }

  editor.isVoid = element => {
    return voidTypes.includes(element.type) ? true : isVoid(element)
  }

  editor.addMark = (style, value) => {
    addMark(style, value)
    addMarkAtVariables(editor, style as TEXT_STYLES)
  }

  editor.removeMark = style => {
    removeMark(style)
    removeMarkAtVariables(editor, style as TEXT_STYLES)
  }

  const normalizeEditorOrDefault = normalizeWithDefault(normalizeEditor, normalizeNode)
  const normalizeParagraphOrDefault = normalizeWithDefault(normalizeParagraph, normalizeNode, false)

  editor.normalizeNode = entry => {
    const [node, path] = entry

    // Normalize root node
    if (Editor.isEditor(node)) {
      return normalizeEditorOrDefault(editor, [node, path])
    }

    if (isTextNode(node)) {
      const nodeWithStyles: CustomText = {
        ...DEFAULT_STYLES,
        ...node,
      }

      for (const key of Object.values(TEXT_STYLES)) {
        const styleKey = key as TEXT_STYLES

        if (styleKey in node && node[styleKey] === false) {
          Transforms.setNodes(editor, { [styleKey]: undefined }, { at: path })
        }
      }

      const actualTextNode = Editor.node(editor, path)

      if (!isEqual(nodeWithStyles, actualTextNode)) {
        const parentNodeEntry = Editor.parent(editor, path)
        parentNodeEntry && normalizeNode(parentNodeEntry)
      }

      return
    }

    // Normalize block node
    if (isBlockNode(editor, node)) {
      // Normalize paragraph
      if (node.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH) {
        return normalizeParagraphOrDefault(editor, [node, path])
      }

      return normalizeNode(entry)
    }

    if (Editor.isInline(editor, node)) {
      return normalizeNode(entry)
    }
  }

  return editor
}
