import React, { useCallback, useMemo, useState } from "react"
import { useFieldArray, useWatch } from "react-hook-form"
import Box from "@mui/material/Box"
import { Divider, useTheme } from "@mui/material"

import { ConfirmDialog } from "common"
import { EMPTY_DEFENDANT, AT_FAULT_PARTY_COVERAGE_TYPES_INDIVIDUAL } from "requests/constants"
import { PolicyCoverageType, DefendantDto, RequestFieldsDto } from "requests/ViewRequest/types"
import { EMPTY_DEFENDANT_NAME, getDefendantName } from "requests/ViewRequest/utils"
import { PartialDefendant } from "requests/ViewRequest/ViewRequestRevamp/types"
import { REQUEST_FORM_FIELDS } from "requests/RequestForm/constants"
import { RequestFields } from "requests/RequestForm/types"
import { useRequestContext } from "requests/context"
import { RequestFormModuleProps } from "../../types"
import { RequestFormModule } from "../RequestFormModule"
import { DefendantForm } from "./DefendantForm"
import { StyledTextButton } from "../styled"

export const isAtFaultParty = (policyCoverageType: PolicyCoverageType): boolean => {
  return AT_FAULT_PARTY_COVERAGE_TYPES_INDIVIDUAL.includes(policyCoverageType)
}

const getRemoveDefendantMessage = (defendant: PartialDefendant | DefendantDto): string => {
  const specificDefendantName = getDefendantName(defendant)
  const defendantName =
    specificDefendantName === EMPTY_DEFENDANT_NAME ? "this defendant" : specificDefendantName

  return `Are you sure you want to remove ${defendantName}?  This cannot be undone.`
}

const AddDefendantButton: React.FC<{
  onClick: () => void
  policyCoverageType: PolicyCoverageType
}> = ({ onClick, policyCoverageType }) => {
  const defendants = useWatch<RequestFields, "defendants">({ name: "defendants" })

  const canAddNewDefendant = useMemo(
    () => !defendants.some(defendant => getDefendantName(defendant) === EMPTY_DEFENDANT_NAME),
    [defendants]
  )

  return (
    <StyledTextButton
      data-test="add_fault_party_or_defendant"
      disabled={!canAddNewDefendant}
      onClick={onClick}
    >
      {isAtFaultParty(policyCoverageType) ? "+ Add At-Fault Party" : "+ Add Defendant"}
    </StyledTextButton>
  )
}

export const DefendantInformation: React.FC<RequestFormModuleProps> = ({ readOnly }): JSX.Element | null => {
  const { requestId } = useRequestContext()
  const policyCoverageType = useWatch({ name: REQUEST_FORM_FIELDS.POLICY_COVERAGE_TYPE })
  const [defendantToRemove, setDefendantToRemove] = useState<number | null>(null)
  const theme = useTheme()

  const defendantsArrayProps = useFieldArray<RequestFieldsDto, "defendants">({ name: "defendants" })
  const defendants = defendantsArrayProps.fields

  const addEmptyDefendant = useCallback(() => {
    defendantsArrayProps.append(EMPTY_DEFENDANT)
  }, [defendantsArrayProps])

  const removeDefendantByIndex = useCallback(
    (index: number | null): void => {
      if (index === null) {
        return
      }
      defendantsArrayProps.remove(index)
      setDefendantToRemove(null)
    },
    [defendantsArrayProps]
  )

  if (!requestId || policyCoverageType === "commercial_general_liability") {
    return null
  }

  return (
    <RequestFormModule title="Defendant Information">
      <ConfirmDialog
        open={defendantToRemove !== null}
        onClose={() => setDefendantToRemove(null)}
        onConfirm={() => removeDefendantByIndex(defendantToRemove)}
        title="Remove Defendant?"
        body={defendantToRemove === null ? "" : getRemoveDefendantMessage(defendants[defendantToRemove])}
      />
      <Box mb={1}>
        {defendants.map(({ type, id }, index: number) => {
          return (
            <React.Fragment key={id}>
              {index > 0 && <Divider sx={{ marginBottom: theme.spacing(4), marginTop: theme.spacing(2) }} />}
              <DefendantForm
                type={type}
                index={index}
                removeDefendant={setDefendantToRemove}
                readOnly={readOnly}
                numberOfDefendants={defendants.length}
              />
            </React.Fragment>
          )
        })}
        <AddDefendantButton onClick={addEmptyDefendant} policyCoverageType={policyCoverageType} />
      </Box>
    </RequestFormModule>
  )
}
