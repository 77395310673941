import { User } from "common/models/user"
import { isControlledEnvironment, isDevEnvironment } from "infrastructure/env/getEnvironment"
import { getAPIServerURL } from "utils"

function withEnv(callback: () => void): void {
  const isCorrectEnv =
    !isControlledEnvironment() && (isDevEnvironment() || !getAPIServerURL().includes("localhost"))

  if (isCorrectEnv) {
    try {
      callback()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}

function setUserContext(user: User): void {
  withEnv(() => {
    // Vitally should be added globally by scripts in index.html
    // find it's type definition in src/global.d.ts
    Vitally?.account({
      // A unique ID assigned by your codebase to each account (i.e. a database ID)
      accountId: "lops-frontend",
      traits: {
        name: `${user.firstName} ${user.lastName}`,
      },
    })

    Vitally?.user({
      // A unique ID assigned by your codebase to each user (i.e. a database ID)
      userId: user.email,
      // Same account ID used in Vitally.account above - used to associate the user to the account
      accountId: "lops-frontend",
      traits: {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        firmId: `${user.firmId}`,
        stytchId: user.stytchUserId,
      },
    })

    showSurvey()
  })
}

let initialized = false
function showSurvey(): void {
  if (!initialized) {
    initialized = true
    Vitally?.nps("survey", {
      // Specify the name of your product. It will be injected into the question: "👋 there! Quick question - how likely are you to recommend {productName} to others?"
      productName: "EvenUp",
      // Optional - we'll default to a 5000ms (5 second) delay if the user is suppose to be displayed the survey. Increase or decrease based on the desired user experience.
      delay: 5000,
    })
  }
}

export const VitallyLib = { setUserContext }
