import { handleEmptyResponse } from "api/utils"
import { withResponseSerializer } from "api/withSerializers"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import { RevisionRequestEventDeserializer } from "./serializers"
import { RevisionRequestEventListDto, RevisionEventServiceOptions } from "./types"

enum REVISION_EVENT_API_PATHS {
  BASE = "intake",
  REQUEST_EVENT = "revision_request_event",
}

class RevisionEventService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(
    options: RevisionEventServiceOptions,
    path: REVISION_EVENT_API_PATHS = REVISION_EVENT_API_PATHS.REQUEST_EVENT
  ): string {
    const pathParts = ["", REVISION_EVENT_API_PATHS.BASE, options.requestId, path]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getRevisionRequestEvents = withResponseSerializer(
    RevisionRequestEventDeserializer.fromListJSON,
    (options: RevisionEventServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get<null, RevisionRequestEventListDto>(null, this.getPath(options))
      )
    }
  )
}

export const revisionEventService = new RevisionEventService(apiService)
