import { StateTransitionMap } from "common/state/types"
import { FILE_UPLOAD_ACTIONS_TYPE, FILE_UPLOAD_ACTIONS as ACTIONS } from "./actions"
import { FILE_UPLOAD_STATE_TYPE, FILE_UPLOAD_STATE as STATE } from "./states"

export const FILE_UPLOAD_STATE_TRANSITIONS: StateTransitionMap<
  FILE_UPLOAD_STATE_TYPE,
  FILE_UPLOAD_ACTIONS_TYPE
> = {
  [STATE.PREPARING_FOR_UPLOAD]: {
    [ACTIONS.CREATE_UPLOAD]: STATE.CREATING_UPLOAD,
    [ACTIONS.FAIL]: STATE.FAILURE,
    [ACTIONS.CANCEL]: STATE.CANCELLED,
  },
  [STATE.CREATING_UPLOAD]: {
    [ACTIONS.PROCESS_UPLOAD]: STATE.UPLOADING_CHUNKS,
    [ACTIONS.FAIL]: STATE.FAILURE,
    [ACTIONS.CANCEL]: STATE.CANCELLED,
  },
  [STATE.UPLOADING_CHUNKS]: {
    [ACTIONS.UPDATE_PROGRESS]: STATE.UPLOADING_CHUNKS,
    [ACTIONS.COMPLETE_UPLOAD]: STATE.WAITING_FOR_ASSIGNMENT,
    [ACTIONS.FAIL]: STATE.FAILURE,
    [ACTIONS.CANCEL]: STATE.CANCELLED,
  },
  [STATE.WAITING_FOR_ASSIGNMENT]: {
    [ACTIONS.ASSIGN_WITH_EXTERNAL]: STATE.ASSIGNED,
    [ACTIONS.DELETE]: STATE.UNASSIGNED,
  },
  [STATE.ASSIGNED]: {
    [ACTIONS.UNASSIGN_FROM_EXTERNAL]: STATE.UNASSIGNED,
    [ACTIONS.DELETE]: STATE.DELETED,
  },
  [STATE.UNASSIGNED]: {},
  [STATE.DELETED]: {},
  [STATE.CANCELLED]: {},
  [STATE.FAILURE]: {},
}
