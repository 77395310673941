import React, { useCallback, useEffect, useRef, useState } from "react"
import { PDFDocumentProxy, PDFPageProxy, RenderTask } from "pdfjs-dist/types/src/display/api"
import Skeleton from "@mui/material/Skeleton"
import { v4 } from "uuid"
import ReplayIcon from "@mui/icons-material/Replay"
import { noop } from "lodash"
import { PREVIEW_SIZE } from "./constants"
import {
  ThumbnailAnnotatedWrapper,
  ThumbnailButtons,
  ThumbnailLoading,
  ThumbnailPageIndicator,
  ThumbnailViewport,
  ThumbnailWrapper,
} from "./styled"
import { useThumbnailCache } from "./ThumbnailCache"
import { getAnnotationColors } from "./utils"
import { enqueue } from "./queue"
import { reload, subscribeToReload } from "./reload"
import { IconButton } from "@mui/material"

interface ThumbnailProps {
  document: PDFDocumentProxy
  page: number
  pageNumberAdjustment: number
  selected: boolean
  deleted: boolean
  onClick: (page: number) => void
}

export function Thumbnail({
  document,
  page,
  pageNumberAdjustment,
  selected,
  deleted,
  onClick,
}: ThumbnailProps): JSX.Element {
  const [renderKey, setRenderKey] = useState(v4())
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [pageProxy, setPageProxy] = useState<Nullable<PDFPageProxy>>(null)
  const [annotationColors, setAnnotationColors] = useState<Nullable<string[]>>(null)
  const [cachedItem, setCacheItem, clearCachedItem] = useThumbnailCache(page)
  const [isLoading, setIsLoading] = useState(!cachedItem)
  const isLoadingRef = useRef(isLoading)
  isLoadingRef.current = isLoading

  useEffect(() => {
    if (!renderKey || !pageProxy || !canvasRef.current || !isLoadingRef.current) return

    const viewport = pageProxy.getViewport({ scale: 1 })
    const scale = Math.min(PREVIEW_SIZE.WIDTH / viewport.width, PREVIEW_SIZE.HEIGHT / viewport.height)

    canvasRef.current.getContext("2d", { alfa: false, willReadFrequently: true })
    const canvasContext = canvasRef.current.getContext("2d")

    if (!canvasContext) return

    let cancel = noop
    let cancelTask = noop
    let raf = requestAnimationFrame(() => {
      cancel = enqueue(document, page, ({ signal }) => {
        return new Promise<void>((resolve, reject) => {
          let renderTask: RenderTask

          cancelTask = reject
          raf = requestAnimationFrame(() => {
            if (pageProxy.destroyed) cancelTask()

            renderTask = pageProxy.render({
              intent: "print",
              annotationMode: 0,
              canvasContext,
              viewport: pageProxy.getViewport({ scale }),
            })

            renderTask.promise.then(() => {
              resolve()
              setIsLoading(false)

              raf = requestAnimationFrame(() => {
                const dataUrl = canvasRef.current?.toDataURL()

                if (dataUrl) {
                  setCacheItem(dataUrl)
                }
              })
            }, noop)
          })

          if (signal) {
            signal.onabort = () => {
              cancelAnimationFrame(raf)

              if (renderTask) {
                renderTask.promise.catch(reject)
                renderTask.cancel()
              }
            }
          }
        }).catch(() => cancelTask())
      }).cancel
    })

    return () => {
      cancelAnimationFrame(raf)
      cancelTask()
      cancel()
    }
  }, [renderKey, document, page, pageProxy, canvasRef, isLoadingRef, setCacheItem])

  useEffect(() => {
    let cancelPageLoad: () => void = noop
    let unmounted = false

    new Promise<PDFPageProxy>((resolve, reject) => {
      cancelPageLoad = reject
      document.getPage(page).then(page => {
        !unmounted && resolve(page)
      }, noop)
    })
      .then(setPageProxy, noop)
      .catch(noop)

    return () => {
      unmounted = true
      cancelPageLoad()
    }
  }, [document, page])

  useEffect(() => {
    if (!pageProxy) return

    pageProxy.getAnnotations().then(getAnnotationColors).then(setAnnotationColors).catch(noop)
  }, [pageProxy])

  const handleThumbnailClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      onClick(page + pageNumberAdjustment)
    },
    [onClick, page, pageNumberAdjustment]
  )

  const handleReload = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      reload(document, page)
    },
    [document, page]
  )

  useEffect(() => {
    return subscribeToReload(page, () => {
      requestAnimationFrame(() => {
        setIsLoading(true)
        clearCachedItem()
        setRenderKey(v4())
      })
    })
  }, [page, clearCachedItem])

  return (
    <>
      <ThumbnailWrapper selected={selected} loading={isLoading}>
        <ThumbnailAnnotatedWrapper colors={annotationColors} as="a" onClick={handleThumbnailClick}>
          <ThumbnailViewport deleted={deleted}>
            {!cachedItem && (
              <canvas ref={canvasRef} width={PREVIEW_SIZE.WIDTH} height={PREVIEW_SIZE.HEIGHT} />
            )}
            {cachedItem && <img src={cachedItem} width={PREVIEW_SIZE.WIDTH} height={PREVIEW_SIZE.HEIGHT} />}
            {isLoading && (
              <ThumbnailLoading>
                <Skeleton variant="rectangular" animation="wave" />
                <Skeleton animation="wave" height={6} />
                <Skeleton width="60%" height={6} animation="wave" />
              </ThumbnailLoading>
            )}
            <ThumbnailButtons onClick={handleReload}>
              <IconButton>
                <ReplayIcon />
              </IconButton>
            </ThumbnailButtons>
          </ThumbnailViewport>
        </ThumbnailAnnotatedWrapper>
      </ThumbnailWrapper>
      <ThumbnailPageIndicator>{page + pageNumberAdjustment}</ThumbnailPageIndicator>
    </>
  )
}
