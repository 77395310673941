import { FieldValidationErrors, FormRange } from "../types"
import { addError } from "./validationUtils"

const hasOrderedOverlap = (current: FormRange, previous: FormRange): boolean => {
  if (current.start <= previous.end) {
    return true
  }
  return false
}

interface ValidateRangesProps {
  ranges: FormRange[]
}

export const validateRanges = ({ ranges }: ValidateRangesProps): FieldValidationErrors => {
  const errors: FieldValidationErrors = {}

  ranges.forEach((range, index) => {
    if (Number.isNaN(range.start)) {
      addError({
        formId: range.formId,
        error: { field: "start", message: "Start page is required" },
        errors,
      })
      // stop validating if start is not available
      return
    }

    if (range.start > range.end) {
      addError({
        formId: range.formId,
        error: { field: "start", message: "Start page must be lower than end" },
        errors,
      })
      // stop validating if start is out of bounds
      return
    }

    const prevRange = ranges[index - 1] as Nullable<FormRange>

    if (prevRange && hasOrderedOverlap(range, prevRange)) {
      addError({
        formId: range.formId,
        error: { field: "start", message: `Overlapping with previous split` },
        errors,
      })
    }
  })

  return errors
}
