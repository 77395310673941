import React from "react"
import ClickAwayListener from "@mui/material/ClickAwayListener"

import { AdditionalEntries } from "../styled"
import PointedPopover from "common/PointedPopover"

interface MultipleEntriesItemProps {
  anchorEl: Nullable<EventTarget & HTMLElement>
  onOpen: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onClose: () => void
  count: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderPopover: (data: any[]) => React.ReactNode
}

const MultipleEntriesItem = ({
  anchorEl,
  onOpen,
  onClose,
  count,
  renderPopover,
  data,
}: MultipleEntriesItemProps): JSX.Element => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <>
        <AdditionalEntries onClick={onOpen}>({count} Entries)</AdditionalEntries>
        <PointedPopover anchorEl={anchorEl} onClose={onClose}>
          {renderPopover(data)}
        </PointedPopover>
      </>
    </ClickAwayListener>
  )
}

export { MultipleEntriesItem as default }
