import { useContext, useMemo } from "react"
import { FlaggedText } from "../../CustomEditor"
import { SuggestionsContext } from "../../features/suggestions/SuggestionsContext"
import { NO_MATCH_SUGGESTION_FLAG } from "../../constants"

type DataAttrs = { [x: `data-${string}`]: string }

export function useSuggestionAttributes(node: FlaggedText): DataAttrs | undefined {
  const { suggestions } = useContext(SuggestionsContext)
  const attrs = useMemo<DataAttrs | undefined>(() => {
    if (!node.flags?.length || !suggestions.length) return

    const suggestionFlags = node.flags.filter(
      flagId => flagId === NO_MATCH_SUGGESTION_FLAG || suggestions.includes(flagId)
    )

    if (!suggestionFlags.length) return

    return suggestionFlags.reduce<DataAttrs>(
      (attrs, suggestionId) => ({
        ...attrs,
        [`data-suggestion-${suggestionId.toLowerCase()}`]: "true",
      }),
      {
        "data-suggestion": suggestionFlags.join(",").toLowerCase(),
      }
    )
  }, [node.flags, suggestions])

  return attrs
}
