import { handleEmptyResponse } from "api/utils"
import { withResponseSerializer } from "api/withSerializers"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import { PermissionServiceDeserializer } from "./serializers"
import { FEATURES_LIST } from "permissions/constants"
import { User } from "common/models/user"
import { PERMISSION_API_PATHS } from "./path"
import { FeaturePermissions } from "permissions/types"
import { LS_FEATURE_PERMISSIONS_KEY } from "./constants"
import * as Sentry from "@sentry/browser"

type PermissionServiceArgs = {
  firmId?: Nullable<PrimaryKey | string>
  userId: User["id"]
}

class PermissionServiceCache {
  private static TIMEOUT = 60_000

  private permissions: { [key: NonNullable<PermissionServiceArgs["firmId"]>]: FeaturePermissions }
  private lastFetch: { [key: NonNullable<PermissionServiceArgs["firmId"]>]: number }

  // keep instance() private to avoid caching instances around
  // since we should always read data from local storage
  // to avoid overriding between tabs
  private static instance(): PermissionServiceCache {
    const cached = localStorage.getItem(LS_FEATURE_PERMISSIONS_KEY)
    if (cached) {
      const parsed = JSON.parse(cached) as {
        permissions: PermissionServiceCache["permissions"]
        lastFetch: PermissionServiceCache["lastFetch"]
      }
      return new PermissionServiceCache(parsed.permissions, parsed.lastFetch)
    } else {
      return new PermissionServiceCache()
    }
  }

  static addToCache(firmId: PermissionServiceArgs["firmId"], permissions: FeaturePermissions) {
    this.instance().addPermissions(firmId, permissions)
  }

  static getCached(firmId: PermissionServiceArgs["firmId"]): {
    isValid: boolean
    permissions: FeaturePermissions | undefined
  } {
    const instance = this.instance()
    return {
      isValid: instance.isValid(firmId),
      permissions: instance.getPermissions(firmId),
    }
  }

  static clear() {
    localStorage.removeItem(LS_FEATURE_PERMISSIONS_KEY)
  }

  // keep constructor private to force usage through instance()
  private constructor(
    permissions?: PermissionServiceCache["permissions"],
    lastFetch?: PermissionServiceCache["lastFetch"]
  ) {
    this.permissions = permissions ?? {}
    this.lastFetch = lastFetch ?? {}
  }

  private getKey(firmId: PermissionServiceArgs["firmId"]): NonNullable<PermissionServiceArgs["firmId"]> {
    return firmId ?? ""
  }

  private addPermissions(firmId: PermissionServiceArgs["firmId"], permissions: FeaturePermissions) {
    const key = this.getKey(firmId)
    this.permissions[key] = permissions
    this.lastFetch[key] = Date.now()
    this.saveToLocalStorage()
  }

  private saveToLocalStorage() {
    try {
      localStorage.setItem(LS_FEATURE_PERMISSIONS_KEY, JSON.stringify(this))
    } catch (e) {
      Sentry.captureException(e)
      // blow up cache if we can't save to local storage to prevent stale data
      localStorage.removeItem(LS_FEATURE_PERMISSIONS_KEY)
    }
  }

  private getPermissions(firmId: PermissionServiceArgs["firmId"]): FeaturePermissions | undefined {
    const key = this.getKey(firmId)
    return this.permissions[key]
  }

  private isValid(firmId: PermissionServiceArgs["firmId"]): boolean {
    const key = this.getKey(firmId)
    const lastFetch = this.lastFetch[key]
    return !!lastFetch && lastFetch >= Date.now() - PermissionServiceCache.TIMEOUT
  }
}

class PermissionService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(path?: PERMISSION_API_PATHS): string {
    const pathParts = ["", PERMISSION_API_PATHS.BASE, path]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  checkPermissions = async (options: PermissionServiceArgs) => {
    const cached = PermissionServiceCache.getCached(options.firmId)
    if (cached.isValid && cached.permissions) {
      return cached.permissions
    } else {
      const response = await withResponseSerializer(
        PermissionServiceDeserializer.fromJSON,
        ({ firmId, userId }: PermissionServiceArgs) => {
          const path = this.getPath(PERMISSION_API_PATHS.CHECK)

          return handleEmptyResponse(
            this.apiService.create(
              {
                firm_id: firmId ?? null,
                userId,
                feature_permissions: FEATURES_LIST,
              },
              path
            )
          )
        }
      )(options)
      PermissionServiceCache.addToCache(options.firmId, response)
      return response
    }
  }

  public getCachedPermissions = (
    firmId?: PermissionServiceArgs["firmId"]
  ): FeaturePermissions | undefined => {
    return PermissionServiceCache.getCached(firmId).permissions
  }

  clearCache() {
    PermissionServiceCache.clear()
  }
}

export const permissionService = new PermissionService(apiService)
