import { useState } from "react"

import { Alert, Box, Button, Typography, styled } from "@mui/material"
import { FeaturePermissionsTable } from "./FeaturePermissionsTable"
import { syncPermissions } from "api"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { ApiError } from "apiHelper"
import { SubTitle } from "missing-docs/styled"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { FeaturePermissionTypes } from "./types"
import { useTheme } from "@emotion/react"
import { getSettingsPageOptions } from "settings/utils"
import { SETTINGS_ROUTE_PREFIX } from "settings/types"
import useUser from "hooks/useUser"

const SyncButtonContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const DescriptionText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

const GENERIC_ERROR = "There was an error synching feature permissions.  Please check the network tab."

export const FeaturePermissions = (): JSX.Element => {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const { user } = useUser()

  const { showSuccessMessage } = useHandleMessages()

  const [refetchErrorMessage, setRefetchErrorMessage] = useState<string | undefined>(undefined)

  const { refetch: callSync } = useQuery<Response, ApiError>([], () => syncPermissions(), {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: 0,
    onSuccess: () => {
      setRefetchErrorMessage(undefined)
      queryClient.invalidateQueries([queryKeys.permissions])
      queryClient.invalidateQueries([queryKeys.featurePermissions])
      showSuccessMessage("Successfully synched Feature Permissions")
    },
    onError: e => {
      if (e instanceof ApiError && e.validationErrors) {
        setRefetchErrorMessage(e.validationErrors.error || GENERIC_ERROR)
      } else {
        setRefetchErrorMessage(GENERIC_ERROR)
      }
    },
  })

  const handleSyncClick = () => {
    callSync()
  }

  const { pageTitle } = getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.FEATURE_PERMISSIONS, user.role)

  return (
    <Box>
      <Typography
        marginBottom={theme.spacing(4)}
        variant="h4"
        component="h1"
        data-test="feature-permission-title"
      >
        {pageTitle}
      </Typography>
      <DescriptionText variant="body2">
        Feature Permissions are a way to enable features for different categories of users
      </DescriptionText>
      <DescriptionText variant="body2">
        The Sync Features button below will sync the database to the constants defined in lops-backend. It is
        mostly useful when making changes on the backend, and for tests.
      </DescriptionText>
      <SyncButtonContainer>
        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={handleSyncClick}
            data-test="sync-button"
          >
            Sync Features
          </Button>
        </Box>
        {refetchErrorMessage && <Alert severity="error">Error: {refetchErrorMessage}</Alert>}
      </SyncButtonContainer>

      <SubTitle data-test="feature-flag-subtitle">Feature Flag</SubTitle>
      <Typography variant="body2">
        Feature Flags are intended to be temporary for testing & rollout purposes
      </Typography>
      <FeaturePermissionsTable featureType={FeaturePermissionTypes.FEATURE_FLAG} />

      <SubTitle data-test="access-level-control-subtitle">Access Level Control</SubTitle>
      <Typography variant="body2">
        Access Level Controls are intended to be long-lived, but only accessible to select users, firms or
        roles
      </Typography>
      <FeaturePermissionsTable featureType={FeaturePermissionTypes.ACCESS_LEVEL_CONTROL} />
    </Box>
  )
}
