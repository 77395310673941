import { StytchError, StytchEvent } from "@stytch/vanilla-js"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { LoginAnalyticEvent, LoginAnalyticsEventTypes } from "infrastructure/apm/events/loginEvents"
import { useCallback } from "react"

const useStytchEvents = () => {
  const onStytchEvent = useCallback((event: StytchEvent) => {
    const {
      data: { status_code, request_id },
    } = event

    if (status_code !== 200) return

    amplitudeApm.trackEvent(
      new LoginAnalyticEvent(LoginAnalyticsEventTypes.LoginLinkSent, {
        request_id,
        status_code,
      })
    )
  }, [])

  const onStytchLogin = useCallback((error: StytchError) => {
    amplitudeApm.trackEvent(
      new LoginAnalyticEvent(LoginAnalyticsEventTypes.LoginFailed, {
        failure_reason: error.message,
      })
    )
  }, [])

  return { onStytchEvent, onStytchLogin }
}

export default useStytchEvents
