import { TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useDocumentStore } from "documents/store"
import { StyledFileName } from "./styled"
import { StyledTableHeadCell, StyledViewTable } from "documents/styled"
import { isEmpty } from "lodash"
import splitFileName from "common/form-components/files/splitFileName"
import { useExhibitPreview } from "documents/exhibits/useExhibitPreview"

interface ExhibitListItemProps {
  exhibitId: number
  exhibitNo: number
}

const ExhibitListItem = ({ exhibitId, exhibitNo }: ExhibitListItemProps): JSX.Element => {
  const exhibits = useDocumentStore(state => state.exhibits)
  const openPreview = useExhibitPreview(exhibitId)

  return (
    <TableRow key={exhibitId}>
      <TableCell>{exhibitNo}</TableCell>
      <TableCell>
        <StyledFileName onClick={() => openPreview()}>
          {splitFileName(exhibits[exhibitId].name)[0]}
        </StyledFileName>
      </TableCell>
    </TableRow>
  )
}

export const ExhibitList = (): JSX.Element => {
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)

  return (
    <StyledViewTable>
      <TableHead>
        <TableRow>
          <StyledTableHeadCell width="20%">Exhibit No.</StyledTableHeadCell>
          <StyledTableHeadCell width="80%">Description</StyledTableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(exhibitsOrder) && (
          <TableRow>
            <TableCell align="center" colSpan={2}>
              <b>There are no exhibits</b>
            </TableCell>
          </TableRow>
        )}
        {exhibitsOrder.map((exhibitId, index) => (
          <ExhibitListItem key={exhibitId} exhibitId={exhibitId} exhibitNo={index + 1} />
        ))}
      </TableBody>
    </StyledViewTable>
  )
}
