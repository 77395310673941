import React, { ReactElement, useCallback } from "react"
import { FieldValues, Control, useController } from "react-hook-form"
import { Descendant } from "slate"
import { RichTextEditor } from "./RichTextEditor"
import { EditorRoot } from "./CustomEditor"
import { isNil } from "lodash"
import { FieldPathWithValue } from "../../types/helper"
import { EditorFeatureProps } from "./features/types"
import { DEFAULT_VALUE } from "./defaultValue"
import { RichTextWithActions } from "./RichTextWithActions"

export interface UnstableRichTextFieldProps<TFields extends FieldValues> {
  control: Control<TFields>
  name: FieldPathWithValue<TFields, Nullable<EditorRoot<false>>>
  label?: string
  helperText?: string
  dataTest?: string
  error?: boolean
  actions?: Nullable<React.ReactElement>
  customControls?: Nullable<React.ReactElement>
}

/**
 * @deprecated Use RichTextField component instead. This component is for legacy support only.
 * Main difference is value type:
 * - UnstableRichTextField uses old EditorRoot<false> type (array of nodes)
 * - RichTextField uses EditorContent class, it allows to optimize data cloning inside react-hook-form
 *   - It needs a bit more work to be done - serialize/deserialize json content into EditorContent
 */
export function UnstableRichTextField<T extends FieldValues>({
  control,
  name,
  label,
  helperText,
  dataTest,
  actions,
  error,
  customControls,
  ...featureProps
}: UnstableRichTextFieldProps<T> & EditorFeatureProps): ReactElement {
  const { field: jsonField } = useController({
    control,
    name,
  })
  const hasJsonValue = !isNil(jsonField.value)
  const handleChange = useCallback(
    (nextValue: Descendant[]): void => {
      jsonField.onChange({ target: { value: nextValue as EditorRoot<false> } })
    },
    [jsonField]
  )

  const value = hasJsonValue ? (jsonField.value as EditorRoot) : DEFAULT_VALUE
  const onBlur = jsonField.onBlur

  return (
    <RichTextWithActions label={label} actions={actions} helperText={helperText}>
      <RichTextEditor
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        keepValue={false}
        dataTest={dataTest}
        error={error}
        customControls={customControls}
        {...featureProps}
      />
    </RichTextWithActions>
  )
}
