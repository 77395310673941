import { ReactNode } from "react"
import { Box } from "@mui/material"
import { AbsentTreatmentTimelineEntity } from "./types"
import {
  StyledAbsentOfTreatment,
  StyledAbsentOfTreatmentText,
  StyledAbsentOfTreatmentBottomBorder,
  StyledTreatmentEntityText,
} from "./styled"

interface TreatmentTimelineEntityProps {
  entity: AbsentTreatmentTimelineEntity
  children: ReactNode
}

export const AbsentOfTreatment = ({ entity, children }: TreatmentTimelineEntityProps): JSX.Element => {
  return (
    <StyledAbsentOfTreatment>
      {children}
      <StyledAbsentOfTreatmentBottomBorder />
      <StyledTreatmentEntityText>{entity.startText}</StyledTreatmentEntityText>
      <StyledAbsentOfTreatmentText>
        <Box>Absence of treatment</Box>
        <Box>{entity.text}</Box>
      </StyledAbsentOfTreatmentText>
    </StyledAbsentOfTreatment>
  )
}
