import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { CollapseContext } from "./context"

type UseCollapseState = [boolean, (value: boolean) => void]

export const CollapseStateContext = createContext<Nullable<UseCollapseState>>(null)

export function useCollapseState(defaultCollapsed = true): UseCollapseState {
  const [upperCollapsed, setUpperCollapsed] = useContext(CollapseStateContext) ?? []
  const [collapsed, setCollapsed] = useState(upperCollapsed ?? defaultCollapsed)
  const { onCollapse } = useContext(CollapseContext)

  const handleCollapsedChange = useCallback(
    (value: boolean) => {
      setCollapsed(value)
      setUpperCollapsed?.(value)
    },
    [setUpperCollapsed]
  )

  useEffect(() => {
    return onCollapse(collapsed => handleCollapsedChange(collapsed))
  }, [onCollapse, handleCollapsedChange])

  return [upperCollapsed ?? collapsed, handleCollapsedChange]
}
