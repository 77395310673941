import { FormControl, FormHelperText } from "@mui/material"
import { useController, useFormContext } from "react-hook-form"
import React, { useEffect } from "react"

const FIELD_NAME = "reqHiddenInput"
const VALID = " "
const INVALID = ""
type RequiredHiddenInputProps = {
  hasInput: boolean
  errorMessage?: string
}
export const RequiredHiddenInput: React.FC<RequiredHiddenInputProps> = React.memo(
  function RequiredHiddenInput({
    hasInput,
    errorMessage = "This field may not be blank.",
  }: RequiredHiddenInputProps) {
    const {
      field,
      fieldState: { error },
    } = useController({
      name: FIELD_NAME,
      defaultValue: hasInput ? VALID : INVALID,
      rules: {
        validate: {
          requiresInput: (inputValue: string) => inputValue === VALID || errorMessage,
        },
      },
    })

    const { setValue } = useFormContext()
    useEffect(() => {
      setValue(FIELD_NAME, hasInput ? VALID : INVALID, {
        shouldValidate: hasInput,
      })
    }, [hasInput, setValue])

    return (
      <FormControl error={!!error}>
        <input
          {...field}
          style={{
            height: "0px",
            width: "100%",
            border: 0,
            outline: 0,
            padding: 0,
            // prevents focus
            visibility: "hidden",
          }}
          // should not be necessary
          onFocus={e => e.target.blur()}
        />
        {error?.message && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    )
  }
)
