import { isNil } from "lodash"

export const PYTHON_BOOLEAN: Map<boolean, string> = new Map([
  [true, "True"],
  [false, "False"],
])

type QueryValue = null | string | number | undefined | boolean

export function getQuery(data: Record<string, QueryValue | QueryValue[]>): string {
  const result = Object.keys(data).reduce<string>((query, key) => {
    const arrayValue = Array.isArray(data[key]) ? (data[key] as QueryValue[]) : [data[key]]

    const value = arrayValue
      .filter(i => !isNil(i))
      .map(dataValue => (typeof dataValue === "boolean" ? PYTHON_BOOLEAN.get(dataValue) : dataValue))
      .join(",")

    if (value.length) {
      const connector = query.length ? "&" : ""

      return `${query}${connector}${key}=${value}`
    }

    return query
  }, "")

  return result ? `?${result}` : ""
}
