import { useMemo } from "react"
import Chip from "@mui/material/Chip"
import styled from "@emotion/styled"
import { Provider } from "../types"

interface ProviderRowLabelProps {
  provider: Provider
  updates: Nullable<{ [key: string]: string }[]>
}

const BoxChip = styled(Chip)({
  borderRadius: "5px",
  fontStyle: "italic",
  fontSize: "10px",
  lineHeight: 1.4,
  letterSpacing: "-0.31px",
})

const NEW_IMPORT = "New Import"
const IMPORT_UPDATED = "Import Updated"

const ProviderRowLabel = ({ provider, updates }: ProviderRowLabelProps): JSX.Element => {
  const label = useMemo(() => {
    if (provider.newly_imported) {
      return NEW_IMPORT
    }

    if (updates?.length) {
      return IMPORT_UPDATED
    }

    return null
  }, [provider, updates])

  if (!label) {
    return <></>
  }

  return <BoxChip label={label} variant="outlined" size="small" />
}

export { ProviderRowLabel as default }
