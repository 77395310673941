import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import Box from "@mui/material/Box"
import LinkButton from "common/buttons/LinkButton"
import React from "react"
import { formatDate } from "utils"
import { displayPageRange } from "../rangeUtils"
import {
  AnnotationTableRow,
  AnnotationTableCell,
  AnnotationTableHeader,
  LastAnnotationTableCell,
  ExcerptMoreButton,
  EmptyAnnotationTableRow,
  Text,
} from "../styled"
import { AnnotationFileExcerpt, AnnotationStatus, RelationType } from "../types"

function formatServiceDateRange(firstDate: string, secondDate?: Nullable<string>): string {
  let str = `${formatDate(firstDate, "MM/dd/yyyy", true)}`
  if (secondDate) {
    str += ` - ${formatDate(secondDate, "MM/dd/yyyy", true)}`
  }
  return str
}

function typeToDisplay(relationType: RelationType) {
  switch (relationType) {
    case "bill":
      return "Bill"
    case "record":
      return "Medical Info"
    default:
      return "Unknown"
  }
}

interface ExcerptTableProps {
  excerpts: AnnotationFileExcerpt[]
  onPreview: (startPage?: number) => void
  status?: Nullable<AnnotationStatus>
  startPage: number
  endPage: number
}

const ExcerptTable: React.FC<ExcerptTableProps> = ({ excerpts, onPreview, status, startPage, endPage }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <AnnotationTableHeader>Name</AnnotationTableHeader>
          <AnnotationTableHeader>Type</AnnotationTableHeader>
          <AnnotationTableHeader>Date of Service</AnnotationTableHeader>
          <AnnotationTableHeader align="right">Page</AnnotationTableHeader>
          <AnnotationTableHeader align="right"></AnnotationTableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {status === AnnotationStatus.COMPLETE &&
          excerpts.length > 0 &&
          excerpts.map((excerpt, index) => (
            <AnnotationTableRow key={index} onClick={() => onPreview(excerpt.start_page)}>
              <AnnotationTableCell width="350px">{excerpt.provider_name}</AnnotationTableCell>
              <AnnotationTableCell>{typeToDisplay(excerpt.relation_type)}</AnnotationTableCell>
              <AnnotationTableCell>
                {formatServiceDateRange(excerpt.first_service_date, excerpt.last_service_date)}
              </AnnotationTableCell>
              <AnnotationTableCell align="right">
                {displayPageRange(excerpt.start_page, excerpt.end_page)}
              </AnnotationTableCell>
              <LastAnnotationTableCell align="right" width="30px">
                <ExcerptMoreButton />
              </LastAnnotationTableCell>
            </AnnotationTableRow>
          ))}
        {(status === AnnotationStatus.COMPLETE || status === AnnotationStatus.AUTO_COMPLETE) &&
          excerpts.length === 0 && (
            <EmptyAnnotationTableRow>
              {/* height 31px to match height created by button in populated rows */}
              <AnnotationTableCell colSpan={4} height="31px">
                <Box display="inline">{`Page(s) ${displayPageRange(
                  startPage,
                  endPage
                )} contain no annotations `}</Box>
                <LinkButton small onClick={() => onPreview(startPage)}>
                  <Text>View Section</Text>
                </LinkButton>
              </AnnotationTableCell>
              {/* empty cell for layout */}
              <LastAnnotationTableCell />
            </EmptyAnnotationTableRow>
          )}
        {!(status === AnnotationStatus.COMPLETE || status === AnnotationStatus.AUTO_COMPLETE) && (
          <EmptyAnnotationTableRow>
            <AnnotationTableCell colSpan={4} height="31px">
              Annotations in progress...{" "}
              <LinkButton small onClick={() => onPreview()}>
                <Text>View Section</Text>
              </LinkButton>
            </AnnotationTableCell>
            <LastAnnotationTableCell />
          </EmptyAnnotationTableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default ExcerptTable
