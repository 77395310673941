import { DocumentStructureDefinition, FullDocumentStructureDefinition } from "common/types/documentStructure"
import { DocumentStructureStoreData } from "../types"
import { resetDocumentStructureItem } from "./reset"
import { createDocumentStructureElement } from "./create"

export function setDocumentStructureItem(
  state: DocumentStructureStoreData,
  id: string,
  { sections, ...data }: FullDocumentStructureDefinition
): DocumentStructureStoreData {
  let nextState = resetDocumentStructureItem({ ...state }, id)
  nextState.items[id] = data

  for (const section of sections) {
    nextState = createDocumentStructureElement(nextState, section, id)
  }

  return nextState
}

export function setDocumentStructureItems(
  state: DocumentStructureStoreData,
  data: [DocumentStructureDefinition, string][]
): DocumentStructureStoreData {
  let nextState = state

  for (const [documentStructure, id] of data) {
    nextState = setDocumentStructureItem(nextState, id, {
      ...documentStructure,
      sections: [],
    })
  }

  return nextState
}
