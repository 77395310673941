import { isUndefined } from "lodash"
import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"

import { ApiServiceType } from "../types"
import { apiService } from "../ApiService"
import {
  GeneratorDeserializer,
  ProviderSummariesDeserializer,
  SummariesDeserializer,
  SummaryActionSerializer,
  SummaryOutputResponseDeserializer,
  SummaryOutputSerializer,
  OrderSummaryDeserializer,
  OrderSummarySerializer,
} from "./serializers"
import { getQuery } from "../utils"

enum SUMMARIES_API_PATHS {
  BASE = "summaries",
  TEMPLATE_KEYS = "llm/provider_template_keys",
  SUMMARIZE = "llm/summarize",
  GENERATOR = "generator",
  BASE_SUMMARIZE = "summarize",
  OUTPUT = "output",
  ORDER = "order",
  LIKE = "like",
  DISLIKE = "dislike",
}

interface ProviderSummariesServiceOptions {
  providerId: BaseEntity["pk"]
  refresh: boolean
}

class ProviderSummariesService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(paths: SUMMARIES_API_PATHS): string {
    const pathParts = ["", SUMMARIES_API_PATHS.BASE, paths]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getGeneratorTemplates = withResponseSerializer(ProviderSummariesDeserializer.fromJSON, () => {
    return handleEmptyResponse(this.apiService.get(null, this.getPath(SUMMARIES_API_PATHS.TEMPLATE_KEYS)))
  })

  getProviderSummary = ({ providerId, refresh = false }: ProviderSummariesServiceOptions) => {
    const query = getQuery({ ["provider"]: [providerId], ["refresh"]: [refresh || null] })

    return handleEmptyResponse(this.apiService.get(null, this.getPath(SUMMARIES_API_PATHS.SUMMARIZE), query))
  }
}

export const providerSummariesApiService = new ProviderSummariesService(apiService)

interface SummariesServiceOptions {
  summaryId: string
  caseId?: PrimaryKey
}

class SummariesService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options?: SummariesServiceOptions, paths?: SUMMARIES_API_PATHS): string {
    const pathParts = ["", SUMMARIES_API_PATHS.BASE, options?.summaryId, paths]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getSummary = withResponseSerializer(SummariesDeserializer.fromJSON, (id: string) => {
    return handleEmptyResponse(this.apiService.get(null, this.getPath(), id))
  })

  summarize = withRequestSerializer(
    SummaryActionSerializer.toJSON,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    withResponseSerializer(SummariesDeserializer.fromJSON, ({ data, ...options }: any) => {
      return handleEmptyResponse(
        this.apiService.create(data, this.getPath(options, SUMMARIES_API_PATHS.BASE_SUMMARIZE))
      )
    })
  )

  createOutput = withRequestSerializer(
    SummaryOutputSerializer.toJSON,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    withResponseSerializer(SummaryOutputResponseDeserializer.fromJSON, ({ data, ...options }: any) => {
      return handleEmptyResponse(
        this.apiService.create(data, this.getPath(options, SUMMARIES_API_PATHS.OUTPUT))
      )
    })
  )

  orderSummary = withRequestSerializer(
    OrderSummarySerializer.toJson,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    withResponseSerializer(OrderSummaryDeserializer.fromJson, ({ data, ...options }: any) => {
      return handleEmptyResponse(
        this.apiService.create(data, this.getPath(options, SUMMARIES_API_PATHS.ORDER))
      )
    })
  )

  updateSummary = withRequestSerializer(
    SummaryOutputSerializer.toJSON,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    withResponseSerializer(SummariesDeserializer.fromJSON, ({ data, ...options }: any) => {
      return handleEmptyResponse(this.apiService.update(data, this.getPath(options)))
    })
  )

  likeSummary = withResponseSerializer(SummaryOutputResponseDeserializer.fromJSON, (id: string) => {
    return handleEmptyResponse(
      this.apiService.create(null, this.getPath({ summaryId: id }), SUMMARIES_API_PATHS.LIKE + "/")
    )
  })

  dislikeSummary = withResponseSerializer(SummaryOutputResponseDeserializer.fromJSON, (id: string) => {
    return handleEmptyResponse(
      this.apiService.create(null, this.getPath({ summaryId: id }), SUMMARIES_API_PATHS.DISLIKE + "/")
    )
  })
}

export const summariesApiService = new SummariesService(apiService)

class SummariesGeneratorService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(): string {
    const pathParts = ["", SUMMARIES_API_PATHS.BASE, SUMMARIES_API_PATHS.GENERATOR]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getGenerators = withResponseSerializer(
    GeneratorDeserializer.fromJSON,
    ({ caseId, matterId }: { caseId?: Nullable<PrimaryKey>; matterId?: Nullable<string> }) => {
      let queryString = ""
      if (caseId != undefined) {
        queryString = `?case=${caseId}`
      }
      if (matterId != undefined) {
        queryString = `?matter=${matterId}`
      }
      return handleEmptyResponse(this.apiService.get(null, this.getPath(), queryString))
    }
  )
}

export const summariesGeneratorApiService = new SummariesGeneratorService(apiService)
