import * as _ from "lodash"
import { DOCUMENT_TYPES } from "requests/enums"
import { Contract } from "common/interfaces"
import { AVAILABLE_CONTRACT_TYPES_MAPPING } from "requests/constants"

export function isCreditTotalOverTrigger(
  contract: Contract,
  documentType: DOCUMENT_TYPES,
  creditsRequired: number
): boolean {
  const contractType = AVAILABLE_CONTRACT_TYPES_MAPPING[documentType]
  const pricingModel = _.find(contract.pricing_models, model => model.product_type === contractType)
  if (!pricingModel) {
    return false
  }
  return creditsRequired > pricingModel.base_credit
}
