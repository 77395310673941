import { useCallback, useEffect, useRef, useState } from "react"
import Collapse from "@mui/material/Collapse"
import { copyToClipboardEditorText } from "common/helpers/copy"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { Variable } from "common/types/variables"
import {
  ContentBlurHelper,
  ContentWrapper,
  ExpandWrapper,
  StyledTemplateHeader,
  TemplateWrapper,
  StyledExpandButton,
  StyledButtonWrapper,
  StyledSeparator,
} from "./styled"
import { COLLAPSED_SIZE, COPIED_FADE_TIME } from "./constants"

interface TemplatePreviewProps {
  template: EditorRoot
  variables?: Variable[]
  templateHeader?: string
}

export function TemplatePreview({
  template,
  variables,
  templateHeader = "New Template",
}: TemplatePreviewProps): JSX.Element {
  const [collapsed, setCollapsed] = useState(true)
  const [copied, setCopied] = useState(false)
  const editor = useRef<Nullable<RichTextEditor>>(null)
  const [shouldExpand, setShouldExpand] = useState(false)
  const wrapperRef = useRef(null)

  const handleHeaderClick = useCallback(() => {
    if (!shouldExpand) return
    setCollapsed(collapsed => !collapsed)
  }, [setCollapsed, shouldExpand])

  const handleCopy = useCallback(
    () => copyToClipboardEditorText(editor.current?.editorRef?.current, () => setCopied(false)),
    []
  )

  const handleExpand = useCallback(() => {
    if (!shouldExpand) return
    if (collapsed) setCollapsed(false)
  }, [shouldExpand, collapsed])

  useEffect(() => {
    const handler = () => setCopied(false)
    let timer: Nullable<NodeJS.Timeout> = null

    if (copied) {
      timer = setTimeout(handler, COPIED_FADE_TIME)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [copied])

  useEffect(() => {
    if (wrapperRef.current) {
      const editorElement = (wrapperRef.current as HTMLDivElement).firstElementChild

      if (editorElement && parseFloat(window.getComputedStyle(editorElement).height) > COLLAPSED_SIZE) {
        setShouldExpand(true)
      }
    }
  }, [])

  return (
    <TemplateWrapper>
      <StyledTemplateHeader collapsed={collapsed} onClick={handleHeaderClick} shouldExpand={shouldExpand}>
        {templateHeader} {shouldExpand && <ExpandMore />}
      </StyledTemplateHeader>
      {shouldExpand && collapsed && <ExpandWrapper onClick={handleExpand} />}
      <Collapse
        in={!shouldExpand || !collapsed}
        collapsedSize={shouldExpand ? COLLAPSED_SIZE : undefined}
        ref={wrapperRef}
      >
        <ContentWrapper>
          <RichTextEditor ref={editor} readonly value={template} withVariables variables={variables ?? []} />
        </ContentWrapper>
      </Collapse>
      {shouldExpand && collapsed && <ContentBlurHelper />}
      <StyledButtonWrapper>
        <StyledExpandButton onClick={handleCopy}>
          {copied ? "Copied!" : "Copy template text"}
        </StyledExpandButton>
        {shouldExpand && (
          <>
            <StyledSeparator>|</StyledSeparator>
            <StyledExpandButton onClick={handleHeaderClick}>
              {collapsed ? "View More" : "Close"}
            </StyledExpandButton>
          </>
        )}
      </StyledButtonWrapper>
    </TemplateWrapper>
  )
}
