import { useEffect, useRef, useState } from "react"
import { Box, CircularProgress, Typography } from "@mui/material"
import { formatTimeSinceNow } from "utils"
import { VerticalCenterBox } from "common"
import { useInterval } from "common/utils"
import { useDocumentStateStore } from "documents/store/documentState"
import { FloatingIndicator } from "./styled"

export function SavingIndicator(): JSX.Element {
  const isSaving = useDocumentStateStore(state => state.isSaving)

  const [lastSaved, setLastSaved] = useState<Nullable<number>>(null)
  const [formattedTimestamp, setFormattedTimestamp] = useState<Nullable<string>>(null)

  useEffect(() => {
    if (isSaving) {
      return () => setLastSaved(Date.now())
    }
  }, [isSaving])

  useInterval(
    () => setFormattedTimestamp(lastSaved ? formatTimeSinceNow(lastSaved) : null),
    60_000,
    !!lastSaved,
    true
  )

  // Fixed/standard position
  const [isFixed, setIsFixed] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const wrapperNode = ref.current

    if (!wrapperNode) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        entry && setIsFixed(!entry.isIntersecting)
      },
      { threshold: [1], rootMargin: "-18px" }
    )
    observer.observe(wrapperNode)

    return () => observer.unobserve(wrapperNode)
  }, [])

  return (
    <Box sx={{ minHeight: isSaving ? 20 : 0, minWidth: 100 }} ref={ref}>
      {isSaving && isFixed && (
        <FloatingIndicator>
          <VerticalCenterBox data-test="saving-indicator" sx={{ gap: 0.5 }}>
            <CircularProgress size={20} color="inherit" />
            <span>&nbsp;</span>
            <Typography variant="caption" component="span">
              Saving...
            </Typography>
          </VerticalCenterBox>
        </FloatingIndicator>
      )}

      {isSaving && !isFixed && (
        <VerticalCenterBox data-test="saving-indicator" sx={{ gap: 0.5 }}>
          <CircularProgress size={20} color="secondary" />
          <span>&nbsp;</span>
          <Typography variant="caption" component="span">
            Saving...
          </Typography>
        </VerticalCenterBox>
      )}
      {!isSaving && lastSaved && (
        <Typography variant="caption" data-test="saving-indicator-succeed">
          Last saved: {formattedTimestamp}
        </Typography>
      )}
    </Box>
  )
}
