import React, { ChangeEvent, useCallback, useRef, useState } from "react"
import { DocumentStructureRowData } from "common/models/library"
import { useFileUploader } from "common/file-uploader"
import { useMutation } from "@tanstack/react-query"
import { documentStructureService } from "api/services/document-structure"
import { DocumentStructureDefinition } from "common/types/documentStructure"
import { isNil } from "lodash"

import { LetteheadInfo } from "../../hooks/useDocumentStructureForm"
import { LetterheadPreivew } from "./LetterheadPreview"
import { StyledUploadButton, StyledUploadInput, UploadLetterheadWrapper } from "../styled"
import { DOCUMENT_STRUCTURE_TREE_ACTION_TYPE, useDocumentStructureItem } from "../document-structure/store"

interface UploadLetterheadProps {
  uuid: string
  onUloadLetterheadError: () => void
  setLetterheadToSave: (letteheadInfo: LetteheadInfo) => void
  initialLetterhead?: DocumentStructureRowData["initialLetterhead"]
  documentStructureId?: PrimaryKey
  onLoading: (callbacks?: (() => void)[]) => void
  resolveWaitingRef: React.MutableRefObject<(value: boolean) => void>
  creating?: boolean
}

export function UploadLetterhead({
  uuid,
  documentStructureId,
  setLetterheadToSave,
  onUloadLetterheadError,
  onLoading,
  resolveWaitingRef,
  initialLetterhead = null,
  creating = false,
}: UploadLetterheadProps): JSX.Element {
  const [, dispatch] = useDocumentStructureItem(uuid)

  const editingDocumentStructure = !isNil(documentStructureId)
  const fileInputRef = useRef<Nullable<HTMLInputElement>>(null)
  const [letterhead, setLetterhead] =
    useState<Nullable<DocumentStructureDefinition["letterhead"]>>(initialLetterhead)
  const [letterheadError, setLetterheadError] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const { uploadFiles, isUploading, cancelUpload } = useFileUploader()
  const uploadLetterheadMutation = useMutation(documentStructureService.uploadLetterhead, {
    onSuccess: data => {
      setLetterhead(data.letterhead)

      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE_ITEM,
        payload: {
          id: uuid,
          data: {
            ...data,
            prevId: documentStructureId,
          } as Partial<DocumentStructureDefinition>,
        },
      })
    },
    onError: (_, { data }) => {
      onUploadingError(data.letterheadFileName)
    },
  })

  const deleteLetterheadMutation = useMutation(documentStructureService.deleteLetterhead, {
    onSuccess: data => {
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE_ITEM,
        payload: {
          id: uuid,
          data: {
            ...data,
            prevId: documentStructureId,
          } as Partial<DocumentStructureDefinition>,
        },
      })

      resolveWaitingRef.current?.(true)
      setLetterheadError(false)
      setLetterhead(null)
      setDeleting(false)
    },
    onError: () => {
      resolveWaitingRef.current?.(false)
      setLetterheadError(true)
      setDeleting(false)
    },
  })

  const onUploadingError = useCallback(
    (fileName: string) => {
      setLetterhead({ name: fileName })
      onUloadLetterheadError()
      setLetterheadError(true)
      resolveWaitingRef.current?.(false)
    },
    [onUloadLetterheadError, resolveWaitingRef]
  )

  const uploadFile = useCallback(
    async (file: File) => {
      onLoading([cancelUpload])
      setLetterheadError(false)

      const {
        items: [upload],
      } = await uploadFiles([file])

      if (!upload.success) {
        return onUploadingError(file.name)
      }

      if (editingDocumentStructure) {
        if (upload.success && documentStructureId) {
          await uploadLetterheadMutation.mutateAsync({
            options: { documentStructureId },
            data: { letterheadUploadId: upload.uploadId, letterheadFileName: upload.name },
          })
        }
      } else {
        setLetterheadToSave({ letterheadUploadId: upload.uploadId, letterheadFileName: upload.name })
        setLetterhead({ name: upload.name })
      }

      resolveWaitingRef.current?.(true)
    },
    [
      onLoading,
      cancelUpload,
      uploadFiles,
      editingDocumentStructure,
      resolveWaitingRef,
      onUploadingError,
      documentStructureId,
      uploadLetterheadMutation,
      setLetterheadToSave,
    ]
  )

  const handleUploadFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.files?.length) {
        uploadFile(event.currentTarget.files[0])
      }
    },
    [uploadFile]
  )

  const handleUploadClick = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const handleDeleteFileClick = useCallback(async () => {
    if (editingDocumentStructure) {
      onLoading()
      setDeleting(true)
      deleteLetterheadMutation.mutate({ options: { documentStructureId } })
    } else {
      setLetterheadToSave({})
      setLetterhead(null)
    }
  }, [
    deleteLetterheadMutation,
    documentStructureId,
    editingDocumentStructure,
    setLetterheadToSave,
    onLoading,
  ])

  const buttonText = isUploading ? "uploading" : deleting ? "deleting" : "upload letterhead"

  return (
    <UploadLetterheadWrapper creating={creating}>
      <StyledUploadInput ref={fileInputRef} onChange={handleUploadFile} />
      <StyledUploadButton
        loadingPosition="start"
        loading={isUploading || deleting}
        disabled={!!letterhead}
        onClick={handleUploadClick}
      >
        {buttonText}
      </StyledUploadButton>
      <LetterheadPreivew
        deleting={deleting}
        error={letterheadError}
        handleDeleteLetterhead={handleDeleteFileClick}
        letterhead={letterhead}
      />
    </UploadLetterheadWrapper>
  )
}
