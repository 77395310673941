import { CancelableOptions, Flow } from "./types"

export function cancelableFlow<TData, TReturn>(
  options: CancelableOptions,
  callback: Flow<TData, TReturn>
): Flow<TData, TReturn | undefined> {
  const controller = new AbortController()
  const { signal } = controller
  const handleAborted = (e: Event) => {
    options.onAborted(e)
    controller.abort(e.target instanceof AbortSignal ? e.target.reason : e.target)
  }

  options.signal.addEventListener("abort", handleAborted)

  return async data => {
    try {
      return await callback(data, { signal })
    } catch (e) {
      options.onError(e instanceof Error ? e.message : `Unhandled error: ${e}`)
      controller.abort(e)
    } finally {
      options.signal.removeEventListener("abort", handleAborted)
    }
  }
}
