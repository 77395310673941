import React from "react"
import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import styled from "@emotion/styled"
import { Manager } from "../Firm"

const FirmManagerTag = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

interface FirmManagersProps {
  managers: Manager[]
}

const FirmManagers = ({ managers }: FirmManagersProps): React.ReactElement => {
  if (!managers.length) {
    return <Typography variant="body2">No Assignees</Typography>
  }

  return (
    <>
      {managers.map(assignee => (
        <FirmManagerTag
          size="small"
          key={assignee.pk}
          label={`${assignee.first_name} ${assignee.last_name}`}
        />
      ))}
    </>
  )
}

export { FirmManagers as default }
