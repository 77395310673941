import { ALL_ROLES, OSF } from "common/models/roles"
import {
  deprecatedStatuses,
  disabledByRevisionStatuses,
  IntakeStatusOptions,
  statusToPropertiesAll,
  statusToPropertiesExternal,
  taskerStatuses,
} from "../requestStatusProperties"
import { isInternalUser } from "../../permissions/requestAction"
import { INTAKE_STATUSES } from "../../constants"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"
import { getStatusOptionsForOSF } from "./getStatusOptionsForOSF"
import { disableStatusOptions } from "./disableStatusOptions"

export function getStatusOptions(
  role: ALL_ROLES,
  currentStatus: INTAKE_STATUSES,
  revised: boolean,
  demandId: Nullable<PrimaryKey>
): Partial<IntakeStatusOptions> {
  if (role === OSF) {
    return getStatusOptionsForOSF(currentStatus, demandId)
  }

  const statusOptions = isInternalUser(role) ? statusToPropertiesAll : statusToPropertiesExternal

  // Revision feature is disabled
  if (!isFeatureEnabled(FEATURES.REVISION)) {
    return disableStatusOptions(statusOptions, currentStatus, taskerStatuses)
  }

  // Revision feature is enabled
  if (currentStatus === INTAKE_STATUSES.completed || revised) {
    return disableStatusOptions(statusOptions, currentStatus, [
      ...taskerStatuses,
      ...deprecatedStatuses,
      ...disabledByRevisionStatuses,
    ])
  }

  return disableStatusOptions(statusOptions, currentStatus, [...taskerStatuses, ...deprecatedStatuses])
}
