import { PropsWithChildren, useCallback, useMemo } from "react"
import { CollapseContext, CollapseContextType } from "./context"

type Callback = (collapsed: boolean) => void

export function CollapseManager({ children }: PropsWithChildren) {
  const collapsibles = useMemo(() => new Set<Callback>(), [])

  const addCollapseHandler = useCallback(
    (callback: Callback) => {
      collapsibles.add(callback)
      return () => collapsibles.delete(callback)
    },
    [collapsibles]
  )

  const collapse = useCallback(() => {
    collapsibles.forEach(callback => callback(true))
  }, [collapsibles])

  const expand = useCallback(() => {
    collapsibles.forEach(callback => callback(false))
  }, [collapsibles])

  const contextValue: CollapseContextType = useMemo(
    () => ({
      onCollapse: addCollapseHandler,
      collapse,
      expand,
    }),
    [addCollapseHandler, collapse, expand]
  )

  return <CollapseContext.Provider value={contextValue}>{children}</CollapseContext.Provider>
}
