import { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { useForm } from "react-hook-form"
import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { getRequestByCase } from "../api"
import { StateSelect } from "../common"
import { SILENT_QUERY_PARAMS, queryKeys } from "../react-query/constants"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"
import MobileNavButton from "./LeftNav/MobileNavButton"
import { FavoriteCasesList } from "demand/FavoriteCasesList"
import { RequestPanelPageWrapper } from "requests/RequestPanelPageWrapper"

export function FavoriteCases() {
  const { id: caseId } = useParams()
  const [errors] = useState(null)
  const { control, getValues, reset } = useForm({
    defaultValues: { state: "" },
  })
  const [state, setState] = useState()
  const { data: request } = useQuery([queryKeys.request, caseId], getRequestByCase, SILENT_QUERY_PARAMS)

  const [page, setPage] = useState(0)

  useEffect(() => {
    if (request) {
      amplitudeApm.trackEvent(
        new DemandAnalyticEvent(DemandAnalyticsEventTypes.FavoriteCasesStarted, {
          demand_id: caseId,
          request_id: `${request.pk}`,
          request_type: request.type,
        })
      )

      return () =>
        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(DemandAnalyticsEventTypes.FavoriteCasesExited, {
            demand_id: caseId,
            request_id: `${request.pk}`,
            request_type: request.type,
          })
        )
    }
  }, [caseId, request])

  const handleChange = () => {
    const stateValue = getValues("state")
    if (!stateValue) return

    setState(stateValue)
    setPage(0)
  }

  return (
    <RequestPanelPageWrapper request={request} caseId={caseId}>
      <Box mb={1}>
        <MobileNavButton />
      </Box>
      <Box display={"flex"} alignItems="center">
        <form onBlur={handleChange}>
          <StateSelect control={control} selectName="state" errors={errors?.state} />
        </form>
        <Box ml={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              reset()
              setState(null)
              setPage(0)
            }}
            data-test="clear-state-button"
          >
            Clear
          </Button>
        </Box>
      </Box>
      <Box>
        <FavoriteCasesList page={page} setPage={setPage} state={state} />
      </Box>
    </RequestPanelPageWrapper>
  )
}
