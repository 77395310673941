import { useState } from "react"

import { Exhibit } from "./Providers/types"
import { downloadExhibit, updateExhibit } from "../api"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import UploadedFile from "../common/form-components/files/UploadedFile"
import { FileValidationError, validateFile } from "./validation"
import splitFileName from "common/form-components/files/splitFileName"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { usePublish } from "message-broker/usePublish"
import { exhibitService } from "api/services/exhibit"
import { getUrl } from "apiHelper"
import { debounce } from "lodash"
import { RenderPdfPayload } from "message-broker/types"
import { getPdfPreviewPath } from "pdf-preview/utils"
import { openWindow } from "common/windows"

interface Props {
  caseId: number
  exhibit: Exhibit
  onDeleteExhibit: () => void
  onSuccessfulSave: () => void
  fileTypeMap: Record<string, string>
}

const UploadedExhibit = ({
  exhibit,
  caseId,
  onDeleteExhibit,
  onSuccessfulSave,
  fileTypeMap,
}: Props): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [exhibitName, setExhibitName] = useState(exhibit.name)
  const [exhibitType, setExhibitType] = useState(exhibit.type)
  const [error, setError] = useState<FileValidationError>(null)
  const { showErrorMessage, showSuccessMessage } = useHandleMessages()

  const handleDownloadFile = async (fileName: string) => {
    try {
      await downloadExhibit({ caseId, exhibitId: exhibit.pk, fileName, downloadFile: true })
    } catch (error) {
      showErrorMessage({ message: "Error downloading exhibit.", error })
    }
  }

  const [, extension] = splitFileName(exhibitName ?? "")
  const isPdf = extension.toLowerCase() === ".pdf"

  const publish = usePublish()

  const handlePreviewFile = debounce(
    () => {
      const url = getUrl(exhibitService.getDownloadPath({ caseId, exhibitId: exhibit.pk }))

      const payload: RenderPdfPayload = {
        fileName: exhibitName,
        url,
        withCredentials: true,
      }

      publish(MESSAGE_TOPIC.RENDER_PDF, payload).catch(() => {
        const path = getPdfPreviewPath(payload)
        if (path) {
          openWindow(`${path.base}/${path.route}`)
        }
      })
    },
    300,
    { leading: true }
  )

  const handleReset = () => {
    setExhibitName(exhibit.name)
    setExhibitType(exhibit.type)
    setError(null)
    setIsEditing(false)
  }

  const handleExhibitSave = async () => {
    // reset any previous errors
    setError(null)

    // fileToUpload and exhibit share the same name + type requirements
    const validationError = validateFile({ name: exhibitName, type: exhibitType })

    if (validationError) {
      setError(validationError)
      return
    }

    setIsSaving(true)

    try {
      await updateExhibit({
        caseId,
        exhibitId: exhibit.pk,
        data: {
          case_id: caseId,
          pk: exhibit.pk,
          name: exhibitName,
          type: exhibitType,
        },
      })
    } catch (error) {
      // no possible validation errors since exhibit name is validated by front end and type is a select with no blank options
      showErrorMessage({
        message:
          "Error updating exhibit. Please try again shortly and if your problem persists contact a dev.",
        error,
      })
      setIsSaving(false)
      return
    }

    showSuccessMessage("Successfully updated exhibit.")
    setIsSaving(false)
    setIsEditing(false)
    onSuccessfulSave()
  }

  return (
    <UploadedFile
      id={exhibit.pk}
      editing={isEditing}
      fileName={exhibitName}
      fileType={exhibitType}
      fileTypeMap={fileTypeMap}
      onDelete={onDeleteExhibit}
      onDownload={handleDownloadFile}
      onClick={isPdf ? handlePreviewFile : undefined}
      disabled={isSaving}
      onFileNameChange={newName => setExhibitName(newName)}
      onFileTypeChange={newType => setExhibitType(newType)}
      onSave={handleExhibitSave}
      onEdit={() => setIsEditing(true)}
      onCancel={handleReset}
      validationError={error}
      highlightOnHover={isPdf}
      editable
    />
  )
}

export { UploadedExhibit as default }
