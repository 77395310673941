import { FullDocumentStructureDefinition } from "common/types/documentStructure"
import { DocumentStructureSection, DocumentStructureBlock } from "../../types"
import { DocumentStructureStoreData } from "../types"
import { omit } from "lodash"
import { setDocumentStructureItem } from "./set"
import { updateDocumentStructureElement, updateDocumentStructureItem } from "./update"
import { deleteDocumentStructureElement } from "./delete"
import { REF_ID } from "../../constants"

export function replaceDocumentStructureElement(
  state: DocumentStructureStoreData,
  id: string,
  data: DocumentStructureSection | DocumentStructureBlock
): DocumentStructureStoreData {
  const nextState = updateDocumentStructureElement(state, id, data, false)

  if (!data.children || data.children.length === 0) {
    return (nextState.structure[id] ?? []).reduce(
      (state, childId) => deleteDocumentStructureElement(state, childId),
      nextState
    )
  }

  const childIds = nextState.structure[id] ?? []
  nextState.structure[id] = []

  return (data.children as DocumentStructureBlock[]).reduce((state, child, idx) => {
    const childId = childIds[idx] ?? child[REF_ID]
    state.structure[id] = [...(state.structure[id] ?? []), childId]

    return replaceDocumentStructureElement(state, childId ?? child[REF_ID], child)
  }, nextState)
}

export function replaceDocumentStructureItem(
  state: DocumentStructureStoreData,
  id: string,
  data: FullDocumentStructureDefinition
): DocumentStructureStoreData {
  const item = state.items[id]

  if (!item) return setDocumentStructureItem(state, id, data)

  const nextState = updateDocumentStructureItem(state, id, omit(data, "children"))
  const sectionIds = nextState.structure[id] ?? []
  nextState.structure[id] = []

  return data.sections.reduce((state, section, idx) => {
    const sectionId = sectionIds[idx] ?? section[REF_ID]
    state.structure[id] = [...(state.structure[id] ?? []), sectionId]

    return replaceDocumentStructureElement(state, sectionId, section)
  }, nextState)
}
