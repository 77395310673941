import { VISIBLE_PLAINTIFF_FIELDS_PROPS } from "./types"

export const MAX_TEXT_LENGTH = 20

export const FAMILY_STATUS_KEY = "family_status"
export const DEPENDENT_STATUS_KEY = "dependent_status"

export const VISIBLE_PLAINTIFF_FIELDS: VISIBLE_PLAINTIFF_FIELDS_PROPS[] = [
  {
    key: "role",
    display: "Role",
  },
  {
    key: "pronoun",
    display: "Pronoun",
  },
  {
    key: DEPENDENT_STATUS_KEY,
    display: "Dependent Status",
  },
  {
    key: FAMILY_STATUS_KEY,
    display: "Family Status",
  },
  {
    key: "ongoing_complaints",
    display: "Ongoing Complaints",
  },
  {
    key: "future_treatments",
    display: "Future Treatments",
  },
  {
    key: "state_of_residence",
    display: "State of Residence",
  },
]
