import { useCallback, useEffect, useState } from "react"
import { Box } from "@mui/material"
import TextButton from "common/buttons/TextButton"
import FileDropzone from "common/form-components/files/FileDropzone"
import { ACCEPT_IMAGE_TYPE } from "common/form-components/files/constants"
import { queryKeys } from "react-query/constants"
import { FileToUploadType } from "common/form-components/files/interfaces"
import { CaseFacts } from "api/services/case/types"
import { CASE_SECTIONS } from "common/types/sections"
import { ExhibitDto } from "demand/Exhibits/Exhibit"
import { useQueryClient } from "@tanstack/react-query"
import { downloadExhibit } from "api"
import { useDialog } from "hooks/useDialog"
import DnDExhibitList from "common/DnDExhibitList"
import ConfirmDialog from "common/ConfirmDialog"
import { fileToUploadObject } from "../../utils"
import { useUploadImages } from "./useUploadImages"
import { useCaseFactsSave } from "../../CaseFactsSaveProvider"
import { PendingImages } from "./PendingImages"
import { useExhibitMutations } from "../useExhibitMutations"
import usePrompt from "hooks/usePrompt"

function getImageExhibits(exhibits: ExhibitDto[], section: CASE_SECTIONS): ExhibitDto[] {
  return exhibits.filter(exhibit => !!exhibit.image && exhibit.section == section)
}

interface CaseFactsSectionImagesProps {
  caseId: CaseFacts["pk"]
  section: CASE_SECTIONS
  exhibits: ExhibitDto[]
}

export function CaseFactsSectionImages({
  caseId,
  section,
  exhibits,
}: CaseFactsSectionImagesProps): JSX.Element {
  const { isOpen, openDialog, closeDialog } = useDialog()
  const [imagesToUpload, setImagesToUpload] = useState<FileToUploadType[]>([])
  const [images, setImages] = useState(getImageExhibits(exhibits, section))

  const queryClient = useQueryClient()
  const uploadImages = useUploadImages({
    caseId,
    section,
    images: imagesToUpload,
    reset: () => setImagesToUpload([]),
  })

  useCaseFactsSave(uploadImages)

  useEffect(() => {
    setImages(getImageExhibits(exhibits, section))
  }, [exhibits, section])

  const { bulkDeleteExhibitsMutation, deleteImageMutation, updateImageMutation } = useExhibitMutations()

  const reorderImages = useCallback(
    async (reorderedImages: ExhibitDto[]) => {
      setImages([...reorderedImages])
      await Promise.all(
        reorderedImages.map((exhibit, idx) =>
          updateImageMutation({
            caseId,
            exhibitId: exhibit.pk,
            data: { case_id: caseId, pk: exhibit.pk, section_index: idx },
          })
        )
      )
      queryClient.invalidateQueries([queryKeys.facts])
    },
    [updateImageMutation, caseId, queryClient]
  )

  const handleUploadImages = useCallback(async () => {
    await uploadImages()
    setImagesToUpload([])
    queryClient.invalidateQueries([queryKeys.facts])
  }, [uploadImages, queryClient])

  const handleImageDelete = useCallback(
    async (exhibitId: PrimaryKey) => {
      await deleteImageMutation({ exhibitId, caseId })
      setImages(images => images.filter(image => image.pk !== exhibitId))
    },
    [caseId, deleteImageMutation]
  )

  const handleExhibitDownload = useCallback(
    (exhibit: ExhibitDto) =>
      downloadExhibit({ caseId, exhibitId: exhibit.pk, fileName: exhibit.name, downloadFile: true }),
    [caseId]
  )

  const handleImageSaved = useCallback(() => queryClient.invalidateQueries([queryKeys.facts]), [queryClient])

  const handleDeleteAllImages = useCallback(async () => {
    closeDialog()
    await bulkDeleteExhibitsMutation({ caseId, ids: images.map(image => image.pk) })
    setImages([])
  }, [closeDialog, bulkDeleteExhibitsMutation, caseId, images])

  const handleImagesAdded = useCallback((newImages: (File | FileToUploadType)[]) => {
    setImagesToUpload(currentImages => [...currentImages, ...newImages.map(fileToUploadObject)])
  }, [])

  usePrompt(
    "You have images that are not yet uploaded. Are you sure you want to leave this page?",
    imagesToUpload.length > 0
  )

  return (
    <>
      <Box>
        {images.length > 0 && (
          <>
            <DnDExhibitList
              id={`${section}-images`}
              caseId={caseId}
              exhibits={images}
              onDelete={handleImageDelete}
              onMediaClick={handleExhibitDownload}
              onReorder={reorderImages}
              onSuccessfulSave={handleImageSaved}
            />
            <TextButton onClick={openDialog}>REMOVE ALL PHOTOS</TextButton>
          </>
        )}
      </Box>
      <Box mt={2}>
        <FileDropzone acceptedFileTypes={ACCEPT_IMAGE_TYPE} onDrop={handleImagesAdded} />
        <PendingImages
          pendingImages={imagesToUpload}
          setPendingImages={setImagesToUpload}
          onSubmit={handleUploadImages}
        />
      </Box>

      <ConfirmDialog
        open={isOpen}
        onClose={closeDialog}
        onConfirm={handleDeleteAllImages}
        title="Delete?"
        body="Are you sure you want to delete all images?"
        cancelText="Cancel"
        confirmText="Delete"
      />
    </>
  )
}
