import React from "react"
import getTextColorFromTheme, { ButtonColor } from "./getTextColorFromTheme"
import { omit } from "lodash"
import styled from "@emotion/styled"

type ButtonPropsProps = { disabled?: boolean; textColor?: ButtonColor; small: boolean }

const StyledButton = styled("button")<ButtonPropsProps>(({ theme, disabled, textColor, small }) => ({
  cursor: disabled ? "not-allowed" : "pointer",
  fontSize: "1rem",
  color: disabled ? theme.palette.grey[600] : getTextColorFromTheme({ theme, textColor }),
  border: "none",
  background: "none",
  textDecoration: "underline",

  ...(small
    ? {
        padding: 0,
      }
    : null),
}))

interface LinkButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  small?: boolean
  className?: string | undefined
  textColor?: ButtonColor
}

const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  className,
  disabled,
  small = false,
  textColor = "blue",
  ...buttonProps
}) => {
  return (
    <StyledButton
      type="button"
      className={className}
      textColor={textColor}
      small={small}
      disabled={disabled}
      {...omit(buttonProps, "in", "onExited")}
    >
      {children}
    </StyledButton>
  )
}

export default LinkButton
