import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { CALENDAR_CELL_SIZE } from "./constants"

export const ReactCalendarCell = styled(Box)({
  width: `${CALENDAR_CELL_SIZE}px`,
  height: `${CALENDAR_CELL_SIZE}px`,
  fontSize: "12px",
  textAlign: "center",
})
