import styled from "@emotion/styled"

const StyledAnchor = styled("a")(({ theme }) => ({
  textDecoration: "underlined",
  color: theme.palette.blue.main,
}))

const TERMS_OF_SERVICE_URL = "https://www.evenuplaw.com/terms-and-conditions"
export const TermsOfUseLink = (): JSX.Element => {
  return (
    <StyledAnchor href={TERMS_OF_SERVICE_URL} target="_blank" rel="noopener noreferrer">
      Terms of Use
    </StyledAnchor>
  )
}
