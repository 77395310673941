import { useMutation } from "@tanstack/react-query"
import { uploadExhibit } from "api"
import { useFileUploader } from "common/file-uploader"
import { FileToUploadType } from "common/form-components/files/interfaces"
import { useCallback, useRef } from "react"

type ValidFileToUploadType = FileToUploadType & { file: File; questionnaireFileId: PrimaryKey }

function isValidFileToUpload(fileToUpload: FileToUploadType): fileToUpload is ValidFileToUploadType {
  return !fileToUpload.questionnaireFileId && !!fileToUpload.file
}

interface UseUploadFilesProps {
  caseId: PrimaryKey
  files: FileToUploadType[]
  reset: () => void
}

type UseUploadFilesReturn = () => Promise<void>

export function useUploadFiles({ caseId, files, reset }: UseUploadFilesProps): UseUploadFilesReturn {
  const { uploadFiles, showUploadErrors } = useFileUploader()
  const uploadFileMutation = useMutation(uploadExhibit, {
    meta: { disableLoader: true },
  })
  const filesRef = useRef(files)
  filesRef.current = files
  const resetRef = useRef(reset)
  resetRef.current = reset

  const handleUploadFiles = useCallback(async () => {
    const files = filesRef.current

    if (!files.length) return

    const filesToUpload = Array.from(files.entries()).filter(([, file]) => isValidFileToUpload(file)) as [
      number,
      ValidFileToUploadType,
    ][]
    const uploads = await uploadFiles(filesToUpload.map(([, { file }]) => file))
    const filePromises = []

    if (uploads.hasFailures) {
      showUploadErrors(uploads.items)
    }

    for (const [index, file] of files.entries()) {
      const uploadIdx = filesToUpload.findIndex(([idx]) => index === idx)
      const upload = uploadIdx >= 0 ? uploads.items[uploadIdx] : null

      const formData = new FormData()
      formData.append("name", file.name || file.file?.name || "")
      formData.append("type", file.documentType ?? "")
      formData.append("section", "facts")

      if (file.questionnaireFileId) {
        formData.append("questionnaire_file_id", file.questionnaireFileId?.toString())
      } else if (upload?.success) {
        formData.append("upload_id", upload.uploadId)
      } else {
        continue
      }

      filePromises.push(uploadFileMutation.mutateAsync({ data: formData, caseId }))
    }

    await Promise.all(filePromises)

    resetRef.current()
  }, [caseId, showUploadErrors, uploadFiles, uploadFileMutation])

  return handleUploadFiles
}
