import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import { makeStyles } from "tss-react/mui"

import { useFormContext } from "./FormContext"

const useStyles = makeStyles()(theme => ({
  sort: {
    marginRight: theme.spacing(2),
    fontSize: theme.spacing(1),
  },
}))

const SORT_OPTIONS = {
  default: JSON.stringify({}),
  dollarsAsc: JSON.stringify({ total_settlement_amount: "asc" }),
  dollarsDesc: JSON.stringify({ total_settlement_amount: "desc" }),
  dateAsc: JSON.stringify({ case_decision_date: "asc" }),
  dateDesc: JSON.stringify({ case_decision_date: "desc" }),
}

export default function SortResults() {
  const { classes } = useStyles()
  const { queryState, queryActions, handleQueryUpdate } = useFormContext()

  const handleChange = event => {
    const sortValue = JSON.parse(event.target.value)
    handleQueryUpdate(queryActions.SET_SORT, sortValue)
  }

  return (
    <FormControl className={classes.sort} variant="standard">
      <InputLabel id="results-sort-select">Sort by:</InputLabel>
      <Select
        labelId="results-sort-select"
        id="results-sort-select"
        autoWidth
        value={JSON.stringify(queryState.sort)}
        onChange={handleChange}
      >
        <MenuItem value={SORT_OPTIONS.default}>
          <em>Default</em>
        </MenuItem>
        <MenuItem value={SORT_OPTIONS.dollarsAsc}>Settlement Amount (ASC)</MenuItem>
        <MenuItem value={SORT_OPTIONS.dollarsDesc}>Settlement Amount (DESC)</MenuItem>
        <MenuItem value={SORT_OPTIONS.dateAsc}>Decision Date (ASC)</MenuItem>
        <MenuItem value={SORT_OPTIONS.dateDesc}>Decision Date (DESC)</MenuItem>
      </Select>
    </FormControl>
  )
}
