import React, { useCallback } from "react"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import Cancel from "@mui/icons-material/Cancel"
import { ExtendedNumberFormatValues } from "common/form-components/NumberInput"
import EditableRange from "./EditableRange"
import { ALL_FIELDS } from "./validation/validationUtils"
import { DeleteAction } from "./reducers/deleteReducer"
import { FieldValidationErrors } from "./types"
import { Text } from "./styled"

interface RangeInputProps {
  formId: string
  start: number
  end: number
  disabled: boolean
  validationErrors: FieldValidationErrors
  dispatch: React.Dispatch<DeleteAction>
}

export const RangeInput = ({ validationErrors, disabled, formId, start, end, dispatch }: RangeInputProps) => {
  const allFieldsError = !!validationErrors[formId]?.find(error => error.field === ALL_FIELDS)
  const startError = !!validationErrors[formId]?.find(err => err.field === "start")
  const endError = !!validationErrors[formId]?.find(err => err.field === "end")

  const onStartChange = useCallback(
    (numberFormatValues: ExtendedNumberFormatValues) =>
      dispatch({ type: "editRange", formId, start: numberFormatValues.valueAsNumber ?? NaN, end }),
    [dispatch, end, formId]
  )

  const onEndChange = useCallback(
    (numberFormatValues: ExtendedNumberFormatValues) =>
      dispatch({ type: "editRange", formId, start, end: numberFormatValues.valueAsNumber ?? NaN }),
    [dispatch, formId, start]
  )

  const handleCancel = useCallback(() => dispatch({ type: "deleteRange", formId }), [dispatch, formId])

  return (
    <Box key={formId}>
      <Box display="flex" alignItems="center">
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <EditableRange
            start={start}
            end={end}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
            startError={allFieldsError || startError}
            endError={allFieldsError || endError}
            disabled={disabled}
          />
          <IconButton onClick={handleCancel} disabled={false}>
            <Cancel />
          </IconButton>
        </Box>
      </Box>
      {validationErrors[formId] && (
        <Box mt={1}>
          <Text color="error">
            {validationErrors[formId]?.map(validationErr => validationErr.message).join(", ")}
          </Text>
        </Box>
      )}
    </Box>
  )
}
