import styled from "@emotion/styled"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CloseIcon from "@mui/icons-material/Close"
import { FILE_UPLOAD_STATE_TYPE } from "../file-state/states"
import { getIconColorByStatus, getOpacityByStatus } from "./utils"

export interface UploadViewRootProps {
  uploadBoxPosition?: "bottom-right" | "bottom-left"
}

export const UploadViewRoot = styled(Box)<UploadViewRootProps>(
  ({ theme, uploadBoxPosition = "bottom-right" }) => ({
    minWidth: theme.spacing(40),
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    position: "fixed",
    right: uploadBoxPosition === "bottom-right" ? "2%" : undefined,
    left: uploadBoxPosition === "bottom-left" ? "2%" : undefined,
    bottom: "2%",
    zIndex: theme.zIndex.modal,
  })
)

export const FilesAccordion = styled(Accordion)({
  fontSize: "0.9em",
  "& .MuiAccordionDetails-root": {
    paddingTop: "0px",
  },
})

export const UploadSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "1em !important",
  "& .MuiAccordionSummary-content": {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  "& MuiButtonBase-root": {
    flexDirection: "row-reverse",
  },
  "& .MuiIconButton-root.MuiAccordionSummary-expandIcon": {
    paddingLeft: 0,
  },

  "& span:not(:first-of-type)": {
    marginLeft: theme.spacing(1),
  },
}))

UploadSummary.defaultProps = {
  expandIcon: <ExpandMoreIcon />,
}

export const CloseButton = styled(Button)(({ theme }) => ({
  minWidth: "10px",
  color: theme.palette.action.active,
  padding: 0,
  marginRight: theme.spacing(2),
}))

CloseButton.defaultProps = {
  children: <CloseIcon />,
  size: "small",
}

export const FileListContainer = styled(AccordionDetails)({
  maxHeight: "200px",
  maxWidth: "400px",
  width: "100%",
  overflowX: "auto",
  overflowY: "auto",
  "& .MuiList-root": {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
})

export const FileList = styled(List)({
  width: "100%",
})

FileList.defaultProps = {
  dense: true,
  "aria-label": "contacts",
}

interface FileListItemProps {
  status: keyof FILE_UPLOAD_STATE_TYPE
}

export const FileListItem = styled(ListItem)<FileListItemProps>(({ theme, status }) => ({
  paddingLeft: 0,
  "& .MuiTypography-body2": {
    fontSize: "0.9em",
  },
  opacity: getOpacityByStatus(status),

  "& .MuiListItemText-root": {
    textDecoration: status === "CANCELLED" ? "line-through" : "none",
  },

  "& .MuiListItemIcon-root": {
    color: getIconColorByStatus(status, theme),
  },
}))

FileListItem.defaultProps = {
  dense: true,
}

export const FileListItemIcon = styled(ListItemIcon)({
  minWidth: "1em",
  marginRight: "0.5em",
  fontSize: "0.6em",
  fontWeight: "bold",
})
