import React, { useState } from "react"
import Collapse from "@mui/material/Collapse"
import CircularProgress from "@mui/material/CircularProgress"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

import { useQuery } from "@tanstack/react-query"

import CommunicationItem from "./CommunicationItem"

import { MinorTitle } from "../styled"
import { MissingExhibitEvent } from "../interfaces"
import { queryKeys } from "../../react-query/constants"
import { getMissingExhibitEvents } from "../../api"
import { formatDate } from "../../utils"

interface Props {
  title: string
  caseId: number
  separateItems?: boolean
}

const CommunicationContainer = styled(Box)(({ theme }) => ({
  border: "solid 1px #E0E0E0",
  padding: theme.spacing(3, 3, 1, 3),
  marginTop: theme.spacing(3),
}))

const Header = styled(Box)({
  display: "flex",
})

const CommunicationEvents = ({ title, caseId, separateItems = false }: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)

  const { data: communicationEvents, isLoading } = useQuery<MissingExhibitEvent[]>(
    [queryKeys.missingExhibitEvents, caseId],
    async () => {
      return await getMissingExhibitEvents({ caseId })
    }
  )

  if (isLoading) {
    return <CircularProgress />
  }

  if (!communicationEvents?.length) {
    return <></>
  }

  return (
    <CommunicationContainer data-test="communication-events">
      <Header>
        <MinorTitle>{title}</MinorTitle>
        <Box m="auto 0 auto auto">
          <IconButton
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Header>

      <Collapse in={isExpanded}>
        {communicationEvents.map((event: MissingExhibitEvent, index: number) => {
          const creatorName = `${event.creator.first_name} ${event.creator.last_name}`
          const dateCreated = formatDate(event.created_at, "MMMM dd, HH:mm aaa")

          return (
            <React.Fragment key={index}>
              <CommunicationItem
                key={`communication-${index}`}
                title={`${dateCreated} by ${creatorName}`}
                description={event.comment}
                files={event.questionnaire_files}
              />
              {separateItems && <Divider />}
            </React.Fragment>
          )
        })}
      </Collapse>
    </CommunicationContainer>
  )
}

export { CommunicationEvents as default }
