import { ALL_ROLES, NOT_SETUP_ROLE, OSF } from "../models/roles"

export const isNotOSF = (role: ALL_ROLES): boolean => {
  return role !== OSF
}
export const isOSF = (role: ALL_ROLES): boolean => {
  return role === OSF
}
export const isNotNotSetupRole = (role: ALL_ROLES): boolean => {
  return role !== NOT_SETUP_ROLE
}

export const isNotOSFAndNotSetupRole = (role: ALL_ROLES): boolean => {
  return role !== OSF && role !== NOT_SETUP_ROLE
}
