import React from "react"

import { useTheme } from "@emotion/react"
import { Box, Typography } from "@mui/material"
import { DemandCalendarDay } from "./DemandCalendarDay"
import { ReactCalendarCell } from "./styled"
import { ReactCalendarBaseProps } from "./types"

const DAYS_OF_WEEK_SHORTNAMES = ["S", "M", "T", "W", "T", "F", "S"]

type DemandCalendarMonthProps<DayT> = {
  year: number
  month: number
} & ReactCalendarBaseProps<DayT>

export function DemandCalendarMonth<DayT>({
  year,
  month,
  ...props
}: DemandCalendarMonthProps<DayT>): React.ReactElement {
  const theme = useTheme()

  const firstDay = new Date(year, month - 1, 1)
  const lastDay = new Date(year, month, 0)
  const numDays = lastDay.getUTCDate()
  const days = Array.from({ length: numDays }, (_, i) => i + 1)

  const numberEmptyDays = firstDay.getUTCDay()
  const emptyDays = Array.from({ length: numberEmptyDays }, (_, i) => i)

  const monthName = firstDay.toLocaleString("default", { month: "long" })
  const isHighlightedMonth =
    props.highlightedDay.getUTCMonth() + 1 === month && props.highlightedDay.getUTCFullYear() === year
  const isAfterHighlightedMonth =
    (props.highlightedDay.getUTCMonth() + 1 <= month && props.highlightedDay.getUTCFullYear() === year) ||
    props.highlightedDay.getUTCFullYear() < year

  return (
    <Box
      sx={{
        background: isAfterHighlightedMonth ? `#FFF` : `rgba(0, 0, 0, 0.08)`,
        padding: `${theme.spacing(1)}`,
        borderRadius: theme.spacing(1),
        border: isHighlightedMonth ? `1px solid ${theme.palette.error.main}` : `none`,
      }}
    >
      <Typography textAlign="center" sx={{ marginBottom: theme.spacing(1.5) }}>
        {monthName}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
          gap: "2px",
        }}
      >
        {DAYS_OF_WEEK_SHORTNAMES.map((day, index) => (
          <ReactCalendarCell key={`${year}-${month}-header-${index}`}>{day}</ReactCalendarCell>
        ))}
        {emptyDays.map(day => (
          <ReactCalendarCell key={`${year}-${month}-blank-${day}`} />
        ))}
        {days.map(day => (
          <DemandCalendarDay key={`${year}-${month}-${day}`} year={year} month={month} day={day} {...props} />
        ))}
      </Box>
      {isHighlightedMonth && (
        <Typography
          color={theme.palette.error.main}
          fontWeight={theme.typography.fontWeightBold}
          textAlign="center"
          margin={`${theme.spacing(1)} 0`}
          fontSize={theme.typography.pxToRem(12)}
        >
          *Date of incident
        </Typography>
      )}
    </Box>
  )
}
