import { UserExhibit } from "exhibit-builder/store/types"
import { BottomButtonGroup } from "./BottomButtonGroup"
import { Box, Typography } from "@mui/material"
import { exhibitBuilderActions } from "exhibit-builder/store"
import { ActionButton, useAction } from "../../ActionButton"

export function Duplicate({ id, onClose }: { id: UserExhibit["id"]; onClose: () => void }) {
  const { handleAction, isLoading } = useAction({
    name: "duplicate exhibit",
    action: async () => {
      await exhibitBuilderActions.duplicateUserExhibit(id)
      onClose()
    },
  })

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="caption">This action will create a duplicate of this entire exhibit.</Typography>
      </Box>
      <BottomButtonGroup
        onClose={onClose}
        actionButton={
          <ActionButton
            data-test="duplicate-button"
            label="Duplicate"
            isLoading={isLoading}
            onClick={handleAction}
          />
        }
      />
    </Box>
  )
}
