import { useEffect, useState, useCallback, useMemo } from "react"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useOutletContext } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import { size } from "lodash"
import { DateField, SelectInput } from "common/form-components"

import { getNonEconomic, updateNonEconomic, calculateNonEconomic } from "../api"
import { queryKeys } from "../react-query/constants"
import { useFormContext } from "./context"
import { getChangedFields, getCommonMutateOptions } from "../utils"
import NonEconomicPreview from "./NonEconomicPreview"
import RelevantDatesTable from "./RelevantDatesTable"
import { formSectionsToRoutes, IMPAIRMENT_TIME_UNIT_MAP, STEP_STATUSES } from "./constants"
import { queryClient } from "../react-query/queryClient"
import { MissingNonEconomicAlert } from "./Alerts/MissingNonEconomicAlert"
import HelpOutline from "@mui/icons-material/HelpOutline"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"
import SimpleDemandSkipSectionBanner from "./SimpleDemandBanner"

const INITIAL_FORM_STATE = {
  hourly_rate_pain_suffering: 0,
  future_hourly_rate_pain_suffering: 0,
  waking_hours_per_day: 16,
  future_impaired_years: 0,
  future_impaired_days: 0,
  end_of_past_pain_suffering_date: "",
  impairment_timeframe: "years",
}

const useStyles = makeStyles()(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(4),
  },
  actions: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  fullWidth: {
    gridColumn: "1 / 4",
  },
}))

const fetchDataToFormData = fetchData => {
  if (!fetchData) return null

  const impairment_timeframe = fetchData.future_impaired_days ? "days" : "years"
  return {
    // when first loading the page the values returned from the api are null
    // we need to set them to the default values so the api call to calculate
    // works properly
    hourly_rate_pain_suffering:
      fetchData.hourly_rate_pain_suffering ?? INITIAL_FORM_STATE.hourly_rate_pain_suffering,
    future_hourly_rate_pain_suffering:
      fetchData.future_hourly_rate_pain_suffering ?? INITIAL_FORM_STATE.future_hourly_rate_pain_suffering,
    waking_hours_per_day: fetchData.waking_hours_per_day ?? INITIAL_FORM_STATE.waking_hours_per_day,
    future_impaired_years: fetchData.future_impaired_years ?? INITIAL_FORM_STATE.future_impaired_years,
    future_impaired_days: fetchData.future_impaired_days ?? INITIAL_FORM_STATE.future_impaired_days,
    end_of_past_pain_suffering_date:
      fetchData.end_of_past_pain_suffering_date ?? INITIAL_FORM_STATE.end_of_past_pain_suffering_date,
    impairment_timeframe,
  }
}

export function NonEconomic({ lastVisited }) {
  const { classes } = useStyles()
  const { saveRef, currentPlaintiff } = useOutletContext()
  const [isDirty, setIsDirty] = useState(false)
  const { caseId, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus } = useFormContext()
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)
  const { data: nonEconomicArrayData, isLoading: isNonEconomicDataLoading } = useQuery(
    [queryKeys.nonEconomic, caseId],
    () => getNonEconomic(caseId),
    {
      meta: { disableLoader: true },
    }
  )

  const nonEconomicData = useMemo(() => {
    if (!nonEconomicArrayData) return null
    let plaintiffData

    if (!currentPlaintiff || !multiPlaintiffEnabled) {
      plaintiffData = nonEconomicArrayData?.[0] ?? null
    } else {
      plaintiffData = nonEconomicArrayData.find(plaintiff => plaintiff.pk === currentPlaintiff.id)
    }

    return fetchDataToFormData(plaintiffData)
  }, [currentPlaintiff, multiPlaintiffEnabled, nonEconomicArrayData])

  const { control, handleSubmit, reset, formState, watch, setValue } = useForm({
    defaultValues: INITIAL_FORM_STATE,
    values: nonEconomicData,
  })
  const [errors, setErrors] = useState(null)

  const endDate = watch("end_of_past_pain_suffering_date")

  const handleChangeEndDate = useCallback(
    newEndDate => setValue("end_of_past_pain_suffering_date", newEndDate, { shouldDirty: true }),
    [setValue]
  )

  const impairmentTimeframe = watch("impairment_timeframe")

  const plaintiffId = currentPlaintiff?.id

  const { error, data, refetch } = useQuery(
    multiPlaintiffEnabled
      ? [queryKeys.calculateNonEconomic, caseId, plaintiffId, "results"]
      : [queryKeys.calculateNonEconomic, caseId, "results"],
    () => {
      setIsDirty(true)
      return calculateNonEconomic(caseId, plaintiffId)
    },
    {
      meta: {
        disableLoader: true,
      },
      onSuccess: () => {
        setIsDirty(false)
      },
      enabled: (multiPlaintiffEnabled && !!plaintiffId) || !multiPlaintiffEnabled,
    }
  )

  useEffect(() => {
    if (!multiPlaintiffEnabled) return
    refetch()
  }, [plaintiffId, refetch, multiPlaintiffEnabled])

  const mutationOptions = getCommonMutateOptions({
    setErrors,
    reset,
    setSavedSuccessfully,
    showErrorMessage,
    onSuccessExtra: () => {
      handleUpdateStepStatus({ status: STEP_STATUSES.completed })
      queryClient.invalidateQueries([queryKeys.calculateNonEconomic])
      queryClient.invalidateQueries([queryKeys.nonEconomic, caseId])
    },
  })
  const mutation = useMutation(updateNonEconomic, mutationOptions)

  const handleOnChange = handleSubmit(async data => {
    const changesFields = getChangedFields(data, formState)
    if (changesFields.impairment_timeframe === "years") {
      data.future_impaired_days = 0
      setValue("future_impaired_days", 0)
    }

    if (changesFields.impairment_timeframe === "days") {
      data.future_impaired_years = 0
      setValue("future_impaired_years", 0)
    }

    if (multiPlaintiffEnabled) {
      data.plaintiff_id = plaintiffId
    }

    if (size(changesFields) > 0) {
      return mutation.mutateAsync({ caseId, data })
    }
  })

  useEffect(() => {
    saveRef.current = handleOnChange
  }, [handleOnChange, saveRef])

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.non_economic
  })

  return (
    <>
      <SimpleDemandSkipSectionBanner caseId={caseId} />
      <MissingNonEconomicAlert caseId={caseId} currentPlaintiff={currentPlaintiff} />
      <RelevantDatesTable />
      <form className={classes.formFields} noValidate onBlur={handleOnChange}>
        <Controller
          name="hourly_rate_pain_suffering"
          control={control}
          render={({ field }) => (
            <TextField
              type="number"
              label="Initial Hourly Rate (P & S)"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: 0,
              }}
              disabled={isNonEconomicDataLoading}
              {...field}
            />
          )}
        />
        <Controller
          name="future_hourly_rate_pain_suffering"
          control={control}
          render={({ field }) => (
            <TextField
              type="number"
              label="Subsequent Hourly Rate (P & S)"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: 0,
              }}
              disabled={isNonEconomicDataLoading}
              {...field}
            />
          )}
        />
        <Controller
          name="waking_hours_per_day"
          control={control}
          render={({ field }) => (
            <TextField
              type="number"
              label="Waking Hours"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: 0,
                max: 25,
              }}
              disabled={isNonEconomicDataLoading}
              {...field}
            />
          )}
        />
        <DateField
          initialValue={endDate}
          onChange={handleChangeEndDate}
          onClose={handleOnChange}
          label="Initial P & S End Date"
          fieldProps={{ name: "end_of_past_pain_suffering_date" }}
          disabled={isNonEconomicDataLoading}
          helperText={errors?.end_of_past_pain_suffering_date}
        />
        <SelectInput
          name="impairment_timeframe"
          control={control}
          label="Impairment Timeframe"
          options={Array.from(IMPAIRMENT_TIME_UNIT_MAP.values())}
          disabled={isNonEconomicDataLoading}
          onChange={handleOnChange}
        />
        {impairmentTimeframe === "years" && (
          <Controller
            name="future_impaired_years"
            control={control}
            render={({ field }) => (
              <Tooltip placement="top" title={`Decimal values are accepted for years, i.e. "10.17"`}>
                <TextField
                  type="number"
                  label={
                    <Box display={"flex"}>
                      <Box>{`Subsequent Impaired ${IMPAIRMENT_TIME_UNIT_MAP.get("years")?.display}`}</Box>
                      <Box ml={1} mt={-0.5}>
                        <HelpOutline />
                      </Box>
                    </Box>
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: 0,
                  }}
                  error={errors?.future_impaired_years}
                  helperText={errors?.future_impaired_years}
                  disabled={isNonEconomicDataLoading}
                  {...field}
                />
              </Tooltip>
            )}
          />
        )}
        {impairmentTimeframe === "days" && (
          <Controller
            name="future_impaired_days"
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                label={`Subsequent Impaired ${IMPAIRMENT_TIME_UNIT_MAP.get("days")?.display}`}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: 0,
                }}
                error={errors?.future_impaired_days}
                helperText={errors?.future_impaired_days}
                disabled={isNonEconomicDataLoading}
                {...field}
              />
            )}
          />
        )}
      </form>
      <NonEconomicPreview {...{ isLoading: isDirty, error, data }} />
    </>
  )
}
