import Chip from "@mui/material/Chip"
import styled from "@emotion/styled"

interface FileTagProps {
  label: string
}

const BoxChip = styled(Chip)(({ theme }) => ({
  borderRadius: "5px",
  fontStyle: "italic",
  fontSize: "10px",
  lineHeight: 1.4,
  letterSpacing: "-0.31px",
  color: theme.palette.common.black,
  border: `0.7px solid ${theme.palette.common.black}`,
}))

const FileTag = ({ label }: FileTagProps): JSX.Element => {
  if (!label) {
    return <></>
  }

  return <BoxChip label={label} variant="outlined" size="small" />
}

export { FileTag as default }
