import { Box, CircularProgress } from "@mui/material"
import styled from "@emotion/styled"

const LoadingContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  backgroundColor: theme.palette.blue.banner,
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: "6px",
}))

export const ResultLoading = () => {
  return (
    <LoadingContainer>
      <CircularProgress size={"1.5rem"} />
      <Box>Litty is working on your summary...</Box>
    </LoadingContainer>
  )
}
