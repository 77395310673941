import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Box from "@mui/material/Box"

import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { getAnnotatedInjuryDetails } from "api"

import { Entry, PartitionEntry } from "../types"
import { getUpdatesWithSameDateOfService, UpdatedPassage } from "../../providerUpdatesUtils"

import {
  InjuryDetailRecordsWrapper,
  InjuryDetailContainer,
  InjuryDetailsContainer,
  BarWrapper,
  StyledScrollIndicator,
  INJURY_DETAIL_RECORD_MAX_HEIGHT,
} from "./styled"

import InjuryDetailRecord, { VisitType } from "./InjuryDetailRecord"
import LoadingState from "./LoadingState"
import ErrorState from "../ErrorState"
import LinkButton from "common/buttons/LinkButton"
import { DateToPassages } from "demand/Providers/Provider/Annotation/InjuryDetails/types"
import { useDebouncedCallback } from "use-debounce"
// import { SummaryDto } from "api/services/summaries/types"

type OpenMap = {
  [date: string]: boolean
}

interface InjuryDetailsProps {
  caseId: number
  providerId: number
  providerName: string
  maxHeight: number
  onEntryClick: (entry: PartitionEntry) => void
  updatedPassages: UpdatedPassage[]
  generatorKeys?: string[]
}

const EXPAND_LIMIT = 2

const InjuryDetails = ({
  caseId,
  providerId,
  providerName,
  maxHeight,
  onEntryClick,
  updatedPassages,
  generatorKeys,
}: InjuryDetailsProps): JSX.Element => {
  const containerRef = useRef<Nullable<HTMLDivElement>>(null)
  const [showTopBar, setShowTopBar] = useState(false)
  const [showBottomBar, setShowBottomBar] = useState(false)
  const [injuryDetails, setInjuryDetails] = useState<Nullable<DateToPassages>>(null)
  const [openMap, setOpenMap] = useState<OpenMap>({})
  // tracks the summaries and their status for each date of service
  // const [dosSummaries, setDosSummaries] = useState<Record<string, SummaryDto>>({})

  const handleEntryClick = useCallback(
    (entry: Entry) => {
      onEntryClick({
        exhibitId: entry.exhibit_id,
        partitionId: entry.partition_id,
        page: entry.page,
      })
    },
    [onEntryClick]
  )

  const handleToggleOpen = useCallback(
    (date: string) => {
      setOpenMap({ ...openMap, [date]: !openMap[date] })
    },
    [openMap]
  )

  const { isFetching, isError } = useQuery<DateToPassages>(
    [queryKeys.annotatedInjuryDetails, caseId, providerId],
    async () => {
      return await getAnnotatedInjuryDetails(caseId, providerId)
    },
    {
      meta: {
        disableLoader: true,
      },
      onSuccess: response => {
        setInjuryDetails(response)
      },
    }
  )
  // TODO if any dates of service have summaries that are still in progress
  // poll for them

  const datesOfService = useMemo(() => {
    if (!injuryDetails) return []

    const results = Object.keys(injuryDetails)
    results.sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime()
    })
    return results
  }, [injuryDetails])

  const setOpenMapToDefault = useCallback(() => {
    if (!injuryDetails) return

    setOpenMap(
      datesOfService.reduce<OpenMap>((result, date, index) => {
        result[date] = index === 0 || index === datesOfService.length - 1

        return result
      }, {})
    )
  }, [injuryDetails, datesOfService])

  const handleExpandAll = useCallback(() => {
    if (!injuryDetails) return

    setOpenMap(
      datesOfService.reduce<OpenMap>((result, date) => {
        result[date] = true

        return result
      }, {})
    )
  }, [injuryDetails, datesOfService])

  const isPassageUpdated = useCallback(
    (date: string) => {
      return getUpdatesWithSameDateOfService(updatedPassages, date).length > 0
    },
    [updatedPassages]
  )

  useEffect(() => {
    setOpenMapToDefault()
  }, [injuryDetails, setOpenMapToDefault])

  const showExpand = datesOfService.filter(date => openMap[date]).length <= EXPAND_LIMIT

  const handleExpand = useCallback(() => {
    if (showExpand) {
      handleExpandAll()
    } else {
      setOpenMapToDefault()
    }
  }, [handleExpandAll, setOpenMapToDefault, showExpand])

  const checkScroll = useDebouncedCallback(() => {
    const wrapperRef = containerRef.current && containerRef.current.firstElementChild

    if (wrapperRef) {
      setShowTopBar(!!wrapperRef.scrollTop)
      setShowBottomBar(wrapperRef.scrollHeight !== wrapperRef.scrollTop + wrapperRef.clientHeight)
    }
  }, 300)

  useEffect(() => {
    checkScroll()
  }, [checkScroll, openMap])

  if (isFetching) {
    return <LoadingState />
  }

  if (isError || injuryDetails === null) {
    return (
      <InjuryDetailsContainer>
        <InjuryDetailContainer m="auto">
          <ErrorState message="There was an error retrieving annotated injury details" />
        </InjuryDetailContainer>
      </InjuryDetailsContainer>
    )
  }

  if (!datesOfService.length) {
    return (
      <InjuryDetailsContainer>
        <Box fontSize="13px" fontWeight="600" mb={1}>
          Important Passages
        </Box>
        <InjuryDetailContainer>
          <Box p={2}>No important passages were annotated.</Box>
        </InjuryDetailContainer>
      </InjuryDetailsContainer>
    )
  }

  return (
    <InjuryDetailsContainer>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Box fontSize="13px" fontWeight="600">
          Important Passages
        </Box>
        {datesOfService.length > EXPAND_LIMIT && (
          <LinkButton onClick={handleExpand}>{showExpand ? "Expand All" : "Collapse All"}</LinkButton>
        )}
      </Box>
      <BarWrapper>
        <StyledScrollIndicator show={showTopBar} />
      </BarWrapper>
      <div ref={containerRef}>
        <InjuryDetailRecordsWrapper
          onScroll={checkScroll}
          maxHeight={
            maxHeight > INJURY_DETAIL_RECORD_MAX_HEIGHT ? maxHeight : INJURY_DETAIL_RECORD_MAX_HEIGHT
          }
        >
          {datesOfService.map((date: string, index: number) => {
            const visitType =
              index == 0
                ? VisitType.FIRST
                : index == datesOfService.length - 1
                  ? VisitType.LAST
                  : VisitType.SUBSEQUENT
            return (
              <InjuryDetailRecord
                key={date}
                date={date}
                injuryDetails={injuryDetails[date]}
                onEntryClick={handleEntryClick}
                open={openMap[date]}
                onToggleOpen={handleToggleOpen}
                annotationExtractUpdated={isPassageUpdated(date)}
                caseId={caseId}
                providerName={providerName}
                generatorKeys={generatorKeys}
                visitType={visitType}
              />
            )
          })}
        </InjuryDetailRecordsWrapper>
      </div>
      <BarWrapper>
        <StyledScrollIndicator show={showBottomBar} bottom />
      </BarWrapper>
    </InjuryDetailsContainer>
  )
}

export { InjuryDetails as default }
