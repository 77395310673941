import useCase from "hooks/useCase"
import { useFeatures } from "hooks/useFeatures"
import React from "react"
import { Navigate, useParams } from "react-router-dom"

const DemandHome: React.FC<Record<string, never>> = () => {
  const { id: caseId } = useParams()
  const { caseObj, isLoading } = useCase(caseId ?? "")
  const { isFeatureEnabled } = useFeatures()

  if (isLoading) return null

  if (isFeatureEnabled("annotation") && caseObj?.firm?.enable_annotations) {
    return <Navigate to="files" replace />
  }

  return <Navigate to="form" replace />
}

export default DemandHome
