import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { LibraryUseForm } from "settings/Library/TemplateForms/types"
import { attributesService } from "api/services/attributes"
import { NewDocumentStructure } from "common/types/templates"
import { queryClient } from "react-query/queryClient"
import { getAttributeValues } from "common/attributes-filter/utils"
import { useMutation, useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { NonUniqueAttributesError } from "api/services/types"
import { documentStructureService } from "api/services/document-structure"
import { DocumentStructureRowData, DocumentStructureViewModel } from "common/models/library"
import { noop, isNil } from "lodash"
import { BlackTooltip } from "common/Tooltip"
import { useConfirm } from "hooks/useConfirm"
import { DialogTitle } from "@mui/material"
import { LIBRARY_ROW_BUTTON_NAMES, defaultLibraryRowButtons } from "settings/Library/LibraryTable/constants"
import { ReorderOperation } from "common/nested-list/SortableNestedList"
import { SelectedAttributeValue } from "common/types/attributes"

import { DocumentStructureFormData as DocumentStructureFormDataType } from "../types"
import { FORM_STATE_STATUS } from "../constants"
import { TemplateFormMessage } from "../TemplateFormMessage"
import { DocumentStructureFormData } from "../formData/DocumentStructureFormData"
import { useDocumentStructureTitle } from "./useDocumentStructureTitle"
import {
  DocumentStructureBlock,
  DocumentStructureComponent,
  DocumentStructureHeading,
  DocumentStructureItem,
  DocumentStructureSection,
  DocumentStructureSubSectionAttrs,
  DocumentStructureTemplate,
} from "../formData/document-structure/types"
import { DocumentStructureDefinition } from "common/types/documentStructure"
import { DocumentStructureDetails } from "../formData/DocumentStructureDetails"
import {
  isDocumentStructureChangedForAutosave,
  isDocumentStructureChangedForConfirmationDialog,
} from "./utils"
import {
  StyledDetailsSkeleton,
  StyledChip,
  StyledFooterWrapper,
  StyledNewItemDetailsSkeleton,
} from "./styled"
import { useDocumentStructureItem } from "../formData/document-structure/store/useDocumentStructureItem"
import { DOCUMENT_STRUCTURE_TREE_ACTION_TYPE } from "../formData/document-structure/store"
import { REF_ID } from "../formData/document-structure/constants"
import { DOCUMENT_STRUCTURE_ITEM_TYPE } from "../formData/document-structure/enums"
import { addItemIntoDocumentStructuresCache } from "../utils"
import { LibraryTabStateContext } from "settings/Library/State/constants"
import { createStartEditTemplateAction } from "settings/Library/State/templatesActions"
import { theme } from "app/theme"

const mapAttribute = (attribute: SelectedAttributeValue) => [attribute.id, attribute.valueId]

export interface LetteheadInfo {
  letterheadUploadId?: string
  letterheadFileName?: string
}

export type DocStructureFormData = Nullable<DocumentStructureFormDataType & LetteheadInfo>

export const useDocumentStructureForm: LibraryUseForm<DocumentStructureRowData> = ({
  onFinish,
  rowData,
  formStatusMessageMap,
  show,
}) => {
  const [formStatus, setFormStatus] = useState<FORM_STATE_STATUS>(FORM_STATE_STATUS.IDLE)
  const [errorInFirm, setErrorInFirm] = useState<boolean>(false)
  const [formData, setFormData] = useState<DocStructureFormData>(null)
  const [runningAction, setRunningAction] = useState<Nullable<Promise<boolean>>>(null)
  const resolveWaitingRef = useRef<(value: boolean) => void>(noop)
  const [highlightEditButton, setHighlightEditButton] = useState(false)
  const handleMouseOver = useCallback(() => setHighlightEditButton(true), [])
  const handleMouseOut = useCallback(() => setHighlightEditButton(false), [])

  const [documentStructureItem, dispatch] = useDocumentStructureItem(rowData.uuid)
  const { dispatch: dispatchCommonAction } = useContext(LibraryTabStateContext)

  const onLoading = useCallback((callbacks?: (() => void)[]) => {
    setRunningAction(
      new Promise(resolve => {
        resolveWaitingRef.current = (result: boolean) => {
          if (result === false) callbacks?.forEach(callback => callback())

          resolve(result)
          setRunningAction(null)

          resolveWaitingRef.current = noop
        }
      })
    )
  }, [])

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries([queryKeys.documentStructure, rowData.uuid])
    }
  }, [rowData.uuid, rowData.id])

  const { data: attributes } = useQuery([queryKeys.attributes, "document-structure"], () =>
    attributesService.getAvailableDocumentStructureAttributes(null)
  )

  const currentDocumentStructureId = useRef(rowData.id)
  currentDocumentStructureId.current = rowData.id
  const { isFetched: areDetailsLoaded } = useQuery(
    [queryKeys.documentStructure, rowData.uuid],
    () =>
      documentStructureService.getDocumentStructure({
        documentStructureId: currentDocumentStructureId.current,
      }),
    {
      onSuccess: data => {
        dispatch({
          type: documentStructureItem
            ? DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.REPLACE_ITEM
            : DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.SET_ITEM,
          payload: {
            data,
            id: rowData.uuid,
          },
        })
      },
      enabled: !isNil(currentDocumentStructureId.current) && show,
      meta: {
        disableLoader: true,
      },
    }
  )

  const initialAttributeValues = useMemo(() => {
    if (!rowData.initialAttributes || !attributes) return undefined

    return getAttributeValues(attributes, Object.fromEntries(rowData.initialAttributes.map(mapAttribute)))
  }, [attributes, rowData])

  const onError = useCallback((error: unknown) => {
    if (error instanceof NonUniqueAttributesError) {
      setFormStatus(FORM_STATE_STATUS.DATA_ERROR)
    } else {
      setFormStatus(FORM_STATE_STATUS.API_ERROR)
    }
  }, [])

  const onUloadLetterheadError = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.UPLOAD_ERROR)
  }, [])

  const mutationOptions = useCallback(
    (status: FORM_STATE_STATUS = FORM_STATE_STATUS.SUCCESS) => ({
      onSuccess: () => {
        setFormStatus(status)
        onFinish()
      },
      onError: (error: unknown) => onError(error),
    }),
    [onFinish, onError]
  )

  const updateDocumentStructureItem = useCallback(
    (nextDocumentStructureId: PrimaryKey, data: Partial<DocumentStructureDefinition> = {}) => {
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE_ITEM,
        payload: {
          id: rowData.uuid,
          data: {
            ...data,
            id: nextDocumentStructureId,
            prevId: rowData.id,
          } as Partial<DocumentStructureDefinition>,
        },
      })
    },
    [dispatch, rowData.uuid, rowData.id]
  )

  const createDocumentStructureMutation = useMutation(documentStructureService.createDocumentStructure, {
    onSuccess: newItem => {
      mutationOptions(FORM_STATE_STATUS.SUCCESS).onSuccess()
      updateDocumentStructureItem(newItem.id, newItem)
      queryClient
        .getQueryCache()
        .findAll([queryKeys.documentStructuresList])
        .forEach(addItemIntoDocumentStructuresCache(newItem))

      const nextRowUuid = DocumentStructureViewModel.idGetter.getRowId({ id: newItem.id })
      dispatchCommonAction(createStartEditTemplateAction({ templateId: nextRowUuid }))
    },
    onError: mutationOptions(FORM_STATE_STATUS.SUCCESS).onError,
  })
  const updateDocumentStructureMutation = useMutation(documentStructureService.updateDocumentStructure, {
    onSuccess: data => updateDocumentStructureItem(data.id, data),
    onError: (error: unknown) => onError(error),
  })

  const publishDraftDocumentStructureMutation = useMutation(
    documentStructureService.publishDraftDocumentStructure,
    {
      onSuccess: data => {
        updateDocumentStructureItem(data.id, data)
        mutationOptions(FORM_STATE_STATUS.SUCCESS_PUBLISH).onSuccess()
      },
      onError: mutationOptions(FORM_STATE_STATUS.SUCCESS_PUBLISH).onError,
    }
  )

  // SECTION -----------------------------------------------------------
  const createSectionMutation = useMutation(documentStructureService.createDocumentStructureSection, {
    ...mutationOptions(),
    onSuccess: ([newSection, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE,
        payload: {
          id: data[REF_ID],
          data: newSection,
        },
      })
    },
  })
  const updateSectionMutation = useMutation(documentStructureService.updateDocumentStructureSection, {
    ...mutationOptions(),
    onSuccess: ([updatedSection, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE,
        payload: {
          id: data[REF_ID],
          data: {
            ...data,
            ...updatedSection,
            id: data.id,
          },
        },
      })
    },
  })
  const updateSubSectionMutation = useMutation(documentStructureService.updateDocumentStructureSubSection, {
    ...mutationOptions(),
    onSuccess: ([, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE,
        payload: {
          id: data[REF_ID],
          data: data as DocumentStructureBlock,
        },
      })
    },
  })
  const deleteSectionMutation = useMutation(documentStructureService.deleteDocumentStructureSection, {
    ...mutationOptions(),
    onSuccess: ([, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.DELETE,
        payload: {
          id: data.id,
        },
      })
    },
  })
  const reorderSectionsMutation = useMutation(documentStructureService.reorderDocumentStructureSections, {
    onError: mutationOptions().onError,
    onSuccess: ([, nextDocumentStructureId]) => updateDocumentStructureItem(nextDocumentStructureId),
  })
  const reorderContentMutation = useMutation(documentStructureService.reorderDocumentStructureContent, {
    onError: mutationOptions().onError,
    onSuccess: ([, nextDocumentStructureId]) => updateDocumentStructureItem(nextDocumentStructureId),
  })

  // HEADING -----------------------------------------------------------
  const createHeadingMutation = useMutation(documentStructureService.createDocumentStructureHeading, {
    ...mutationOptions(),
    onSuccess: ([newHeading, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE,
        payload: {
          id: data[REF_ID],
          data: newHeading,
        },
      })
    },
  })
  const updateHeadingMutation = useMutation(documentStructureService.updateDocumentStructureHeading, {
    ...mutationOptions(),
    onSuccess: ([updatedHeading, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE,
        payload: {
          id: data[REF_ID],
          data: {
            ...data,
            ...updatedHeading,
            id: data.id,
          },
        },
      })
    },
  })
  const deleteHeadingMutation = useMutation(documentStructureService.deleteDocumentStructureHeading, {
    ...mutationOptions(),
    onSuccess: ([, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.DELETE,
        payload: {
          id: data.id,
        },
      })
    },
  })

  // TEMPLATE -----------------------------------------------------------
  const createTemplateMutation = useMutation(documentStructureService.createDocumentStructureTemplate, {
    ...mutationOptions(),
    onSuccess: ([newTemplate, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE,
        payload: {
          id: data[REF_ID],
          data: newTemplate,
        },
      })
    },
  })
  const updateTemplateMutation = useMutation(documentStructureService.updateDocumentStructureTemplate, {
    ...mutationOptions(),
    onSuccess: ([updatedTemplate, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE,
        payload: {
          id: data[REF_ID],
          data: {
            ...data,
            ...updatedTemplate,
            id: data.id,
          },
        },
      })
    },
  })
  const deleteTemplateMutation = useMutation(documentStructureService.deleteDocumentStructureTemplate, {
    ...mutationOptions(),
    onSuccess: ([, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.DELETE,
        payload: {
          id: data.id,
        },
      })
    },
  })

  // COMPONENT -----------------------------------------------------------
  const createComponentMutation = useMutation(documentStructureService.createDocumentStructureComponent, {
    ...mutationOptions(),
    onSuccess: ([newComponent, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE,
        payload: {
          id: data[REF_ID],
          data: newComponent,
        },
      })
    },
  })
  const updateComponentMutation = useMutation(documentStructureService.updateDocumentStructureComponent, {
    ...mutationOptions(),
    onSuccess: ([updatedComponent, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE,
        payload: {
          id: data[REF_ID],
          data: {
            ...data,
            ...updatedComponent,
            id: data.id,
          },
        },
      })
    },
  })
  const deleteComponentMutation = useMutation(documentStructureService.deleteDocumentStructureComponent, {
    ...mutationOptions(),
    onSuccess: ([, nextDocumentStructureId], { data }) => {
      updateDocumentStructureItem(nextDocumentStructureId)
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.DELETE,
        payload: {
          id: data.id,
        },
      })
    },
  })

  const handleFormDataChange = useCallback(
    (data: DocumentStructureFormDataType) => {
      let isDataChanged = false

      setFormData(currentData => {
        isDataChanged = isDocumentStructureChangedForAutosave(currentData, data)

        return data
      })

      if (isDataChanged && !isNil(rowData.id)) {
        updateDocumentStructureMutation.mutate({
          options: { documentStructureId: rowData.id },
          data: {
            documentName: data.documentName,
            attributes: data.sectionWithAttributes?.attributeValues ?? null,
            firmId: data.firmId,
          },
        })
      }
    },
    [rowData.id, updateDocumentStructureMutation]
  )

  const currentDocumentStructureTitle = useDocumentStructureTitle({
    firmId: formData?.firmId,
    attributeValues: formData?.sectionWithAttributes?.attributeValues,
    documentName: formData?.documentName,
  })

  const { confirm, dialog } = useConfirm({
    formContent: (
      <span>
        Are you sure you want to change
        <br />
        <b>{rowData.documentStructureTitle}</b> to <b>{currentDocumentStructureTitle}</b> ?
        <br />
        You will replace what’s currenty there.
      </span>
    ),
    buttonPlacement: "center",
    PaperProps: { style: { borderRadius: 14, padding: "34px 80px 42px 80px" } },
    fullWidth: false,
    maxWidth: undefined,
    center: true,
    header: <DialogTitle fontWeight={700}>Replace?</DialogTitle>,
    cancelButtonProps: { sx: { backgroundColor: theme.palette.button.grey } },
    confirmationButtonContent: "yes, continue",
  })

  const clearForm = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.IDLE)
    setErrorInFirm(false)
  }, [])

  const handleCancel = useCallback(() => {
    resolveWaitingRef.current?.(false)
    onFinish()
    clearForm()
  }, [onFinish, clearForm])

  const handleSubmit = useCallback(async () => {
    if (!formData) return

    const data: NewDocumentStructure = {
      documentName: formData.documentName,
      attributes: formData.sectionWithAttributes?.attributeValues ?? null,
      firmId: formData.firmId,
    }

    if (typeof rowData.id === "undefined") {
      return createDocumentStructureMutation.mutateAsync({
        data: {
          ...data,
          letterheadFileName: formData.letterheadFileName,
          letterheadUploadId: formData.letterheadUploadId,
        },
      })
    } else {
      if (rowData.isDraft) {
        const isDataChanged = isDocumentStructureChangedForConfirmationDialog(formData, rowData)

        if (isDataChanged) {
          const confirmed = await confirm()

          if (!confirmed) return
        }

        return publishDraftDocumentStructureMutation.mutateAsync({
          documentStructureId: rowData.id,
        })
      }
    }
  }, [formData, createDocumentStructureMutation, rowData, publishDraftDocumentStructureMutation, confirm])

  const rowButtons = useMemo(
    () => ({
      ...defaultLibraryRowButtons,
      [LIBRARY_ROW_BUTTON_NAMES.EDIT]: { title: "edit", highlight: highlightEditButton },
      [LIBRARY_ROW_BUTTON_NAMES.ACTION]: { title: "publish", disabled: !rowData.isDraft },
      [LIBRARY_ROW_BUTTON_NAMES.CANCEL]: { title: "close edit" },
    }),
    [rowData, highlightEditButton]
  )

  // SECTION -----------------------------------------------------------
  const handleSectionSave = useCallback(
    async (section: DocumentStructureSection) => {
      if (section.id !== null) {
        await updateSectionMutation.mutateAsync({
          options: { documentStructureId: rowData.id },
          data: section,
        })
      } else {
        await createSectionMutation.mutateAsync({
          options: { documentStructureId: rowData.id },
          data: section,
        })
      }

      return
    },
    [createSectionMutation, updateSectionMutation, rowData.id]
  )

  const handleSectionDelete = useCallback(
    async (section: DocumentStructureSection) => {
      if (section.id) {
        await deleteSectionMutation.mutateAsync({
          options: { documentStructureId: rowData.id },
          data: { sectionId: section.id, id: section[REF_ID] },
        })
        return
      } else {
        dispatch({
          type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.DELETE,
          payload: {
            id: section[REF_ID],
          },
        })
      }
    },
    [dispatch, deleteSectionMutation, rowData.id]
  )

  const handleSubSectionSave = useCallback(
    async (section: DocumentStructureItem & DocumentStructureSubSectionAttrs) => {
      if (section.sectionId === null || section.id === null) return

      await updateSubSectionMutation.mutateAsync({
        options: { documentStructureId: rowData.id, id: section.id, sectionId: section.sectionId },
        data: section,
      })

      return
    },
    [updateSubSectionMutation, rowData.id]
  )

  const handleReorder = useCallback(
    async (operation: ReorderOperation<DocumentStructureSection>) => {
      if (!operation.item.id) return

      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.MOVE,
        payload: {
          id: operation.item[REF_ID],
          parentId: operation.parent?.[REF_ID] ?? rowData.uuid,
          index: operation.index,
        },
      })

      const index =
        !operation.parent || operation.parent.type === DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION
          ? operation.index
          : operation.index + 1

      const parentId =
        operation.parent && "sectionId" in operation.parent
          ? operation.parent.sectionId
          : operation.parent?.id ?? null

      const itemId =
        "sectionId" in operation.item ? operation.item.sectionId ?? operation.item.id : operation.item.id

      if (operation.item.type === DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION) {
        await reorderSectionsMutation.mutateAsync({
          options: { documentStructureId: rowData.id },
          data: {
            sectionId: itemId,
            parent: parentId,
            index,
          },
        })
      } else {
        await reorderContentMutation.mutateAsync({
          options: { documentStructureId: rowData.id },
          data: {
            id: itemId,
            parent: parentId,
            index,
          },
        })
      }
    },
    [dispatch, rowData.uuid, reorderSectionsMutation, reorderContentMutation, rowData.id]
  )

  // COMPONENT -----------------------------------------------------------
  const handleComponentSave = useCallback(
    async (component: DocumentStructureComponent, sectionId: PrimaryKey) => {
      if (component.id !== null) {
        await updateComponentMutation.mutateAsync({
          options: { documentStructureId: rowData.id, sectionId },
          data: component,
        })
      } else {
        await createComponentMutation.mutateAsync({
          options: { documentStructureId: rowData.id, sectionId },
          data: component,
        })
      }

      return
    },
    [createComponentMutation, rowData.id, updateComponentMutation]
  )

  const handleComponentDelete = useCallback(
    async (component: DocumentStructureComponent, sectionId: PrimaryKey) => {
      if (component.id) {
        await deleteComponentMutation.mutateAsync({
          options: { documentStructureId: rowData.id, sectionId: sectionId },
          data: { componentId: component.id, id: component[REF_ID] },
        })
        return
      } else {
        dispatch({
          type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.DELETE,
          payload: {
            id: component[REF_ID],
          },
        })
      }
    },
    [deleteComponentMutation, rowData.id, dispatch]
  )

  // TEMPLATE -----------------------------------------------------------
  const handleTemplateSave = useCallback(
    async (template: DocumentStructureTemplate, sectionId: PrimaryKey) => {
      if (template.id !== null) {
        await updateTemplateMutation.mutateAsync({
          options: { documentStructureId: rowData.id, sectionId },
          data: template,
        })
      } else {
        await createTemplateMutation.mutateAsync({
          options: { documentStructureId: rowData.id, sectionId },
          data: template,
        })
      }

      return
    },
    [createTemplateMutation, rowData.id, updateTemplateMutation]
  )

  const handleTemplateDelete = useCallback(
    async (template: DocumentStructureTemplate, sectionId: PrimaryKey) => {
      if (template.id) {
        await deleteTemplateMutation.mutateAsync({
          options: { documentStructureId: rowData.id, sectionId: sectionId },
          data: { templateId: template.id, id: template[REF_ID] },
        })
        return
      } else {
        dispatch({
          type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.DELETE,
          payload: {
            id: template[REF_ID],
          },
        })
      }
    },
    [dispatch, deleteTemplateMutation, rowData.id]
  )

  // HEADING -----------------------------------------------------------
  const handleHeadingSave = useCallback(
    async (heading: DocumentStructureHeading, sectionId: PrimaryKey) => {
      if (heading.id !== null) {
        await updateHeadingMutation.mutateAsync({
          options: { documentStructureId: rowData.id, sectionId: sectionId },
          data: heading,
        })
      } else {
        await createHeadingMutation.mutateAsync({
          options: { documentStructureId: rowData.id, sectionId: sectionId },
          data: heading,
        })
      }

      return
    },
    [createHeadingMutation, updateHeadingMutation, rowData.id]
  )

  const handleHeadingDelete = useCallback(
    async (heading: DocumentStructureHeading, sectionId: PrimaryKey) => {
      if (heading.id) {
        await deleteHeadingMutation.mutateAsync({
          options: { documentStructureId: rowData.id, sectionId: sectionId },
          data: { headingId: heading.id, id: heading[REF_ID] },
        })
        return
      } else {
        dispatch({
          type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.DELETE,
          payload: {
            id: heading[REF_ID],
          },
        })
      }
    },
    [dispatch, deleteHeadingMutation, rowData.id]
  )

  const handleItemCreate = useCallback(
    (item: DocumentStructureSection | DocumentStructureBlock) =>
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.CREATE,
        payload: {
          parentId: rowData.uuid,
          data: item,
        },
      }),
    [dispatch, rowData.uuid]
  )

  const message = useMemo(() => {
    if (formStatus === FORM_STATE_STATUS.IDLE) return null

    const messageText = formStatusMessageMap[formStatus]

    if (!messageText) return null

    return <TemplateFormMessage clear={clearForm} message={messageText} formStatus={formStatus} />
  }, [clearForm, formStatus, formStatusMessageMap])

  const viewForm = useMemo(() => {
    if (!areDetailsLoaded)
      return (
        <>
          <StyledDetailsSkeleton />
          <StyledDetailsSkeleton />
          <StyledDetailsSkeleton />
        </>
      )

    if (!documentStructureItem?.sections.length) return <></>

    return <DocumentStructureDetails sections={documentStructureItem.sections} />
  }, [areDetailsLoaded, documentStructureItem])

  const editForm = useCallback(
    (footer: Nullable<JSX.Element>) =>
      attributes ? (
        <>
          {dialog}
          <DocumentStructureFormData
            uuid={rowData.uuid}
            creating={typeof rowData.id === "undefined"}
            errorInFirm={errorInFirm}
            onLoading={onLoading}
            detailsLoading={!documentStructureItem}
            resolveWaitingRef={resolveWaitingRef}
            documentStructureId={rowData.id}
            onChange={handleFormDataChange}
            onCreate={handleItemCreate}
            onSaveSection={handleSectionSave}
            onDeleteSection={handleSectionDelete}
            onSaveSubSection={handleSubSectionSave}
            onReorder={handleReorder}
            onSaveHeading={handleHeadingSave}
            onSaveComponent={handleComponentSave}
            onDeleteComponent={handleComponentDelete}
            onSaveTemplate={handleTemplateSave}
            onDeleteTemplate={handleTemplateDelete}
            onDeleteHeading={handleHeadingDelete}
            onUloadLetterheadError={onUloadLetterheadError}
            initialAttributeValues={initialAttributeValues}
            initialFirmId={rowData.initialFirmId}
            initialLetterhead={rowData.initialLetterhead}
            initialDocumentName={rowData.initialDocumentName}
            initialSections={documentStructureItem?.sections ?? []}
          />
          {!documentStructureItem?.id && (
            <>
              <StyledNewItemDetailsSkeleton />
              <StyledNewItemDetailsSkeleton />
              <StyledNewItemDetailsSkeleton />
            </>
          )}
          {documentStructureItem?.id && !areDetailsLoaded && (
            <>
              <StyledDetailsSkeleton />
              <StyledDetailsSkeleton />
              <StyledDetailsSkeleton />
            </>
          )}
          <StyledFooterWrapper>{footer}</StyledFooterWrapper>
        </>
      ) : null,
    [
      attributes,
      dialog,
      rowData.uuid,
      rowData.id,
      rowData.initialFirmId,
      rowData.initialLetterhead,
      rowData.initialDocumentName,
      errorInFirm,
      onLoading,
      documentStructureItem,
      handleFormDataChange,
      handleItemCreate,
      handleSectionSave,
      handleSectionDelete,
      handleSubSectionSave,
      handleReorder,
      handleHeadingSave,
      handleComponentDelete,
      handleComponentSave,
      handleTemplateSave,
      handleTemplateDelete,
      handleHeadingDelete,
      onUloadLetterheadError,
      initialAttributeValues,
      areDetailsLoaded,
    ]
  )

  const errorForm = formStatus === FORM_STATE_STATUS.API_ERROR || formStatus === FORM_STATE_STATUS.DATA_ERROR

  useEffect(() => {
    clearForm()
  }, [formData, clearForm])

  const isLoading = createDocumentStructureMutation.isLoading || updateDocumentStructureMutation.isLoading

  const draftChip = useMemo(() => {
    if (!rowData.isDraft) return null
    return (
      <BlackTooltip
        onMouseMove={handleMouseOver}
        onMouseOut={handleMouseOut}
        arrow
        title={`The document structure is in draft status, click "Edit" and publish all the latest changes`}
      >
        <StyledChip label="Draft" variant="outlined" />
      </BlackTooltip>
    )
  }, [rowData.isDraft, handleMouseOver, handleMouseOut])

  const duplicateChip = useMemo(() => {
    if (!rowData.isDuplicated) return null
    return (
      <BlackTooltip
        onMouseMove={handleMouseOver}
        onMouseOut={handleMouseOut}
        arrow
        title={`The document structure was created via duplication. Until changes are made and published, the structure will not be used on document generation.`}
      >
        <StyledChip label="Duplicated" variant="outlined" />
      </BlackTooltip>
    )
  }, [rowData.isDuplicated, handleMouseOver, handleMouseOut])

  return {
    message,
    editForm,
    handleSubmit,
    handleCancel,
    isLoading,
    viewForm,
    clearForm,
    errorForm,
    runningAction,
    rowButtons,
    additionalRowButtons: duplicateChip ?? draftChip ?? null,
  }
}
