import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { ViewRequestRevampProps } from "../types"
import { StyledInformationWrapper } from "../styled"
import { InfoContainer } from "./InfoContainer"

export const AttorneyInformation = ({ request }: ViewRequestRevampProps) => {
  return (
    <RequestFormModule title="Attorney">
      <StyledInformationWrapper>
        <InfoContainer field="First Name" value={request.assigned_attorney_first_name} />
        <InfoContainer field="Last Name" value={request.assigned_attorney_last_name} />
      </StyledInformationWrapper>
    </RequestFormModule>
  )
}
