import { Box, Button, IconButton, Typography } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"
import { makeStyles } from "tss-react/mui"
import { useDialog } from "hooks/useDialog"
import { StyledDialog } from "app/styled"
import { useRequestContext } from "requests/context"
import { StyledPrimaryButton } from "common/buttons/styled"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  MultipleDocumentsSurveyEventFields,
  RequestAnalyticEvent,
  RequestAnalyticsEventTypes,
} from "infrastructure/apm/events/requestEvents"
import useUser from "hooks/useUser"

const useStyles = makeStyles()(() => ({
  container: {
    alignItems: "start",
    marginTop: "5%",
  },
}))

export const RequestMultipleDocumentsModal = () => {
  const { classes } = useStyles()
  const { isOpen, openDialog, closeDialog } = useDialog()
  const { firm, requestId } = useRequestContext()
  const { user } = useUser()

  const analyticEventProps: MultipleDocumentsSurveyEventFields = {
    user_email: user.email,
    user_role: user.role,
    firm_id: firm?.pk,
    firm_name: firm?.name,
    request_id: requestId,
  }

  return (
    <>
      <Box>
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          onClick={() => {
            amplitudeApm.trackEvent(
              new RequestAnalyticEvent(
                RequestAnalyticsEventTypes.RequestMultipleDocumentsClicked,
                analyticEventProps
              )
            )
            openDialog()
          }}
          data-test="request-multiple-docs-button"
        >
          Request Multiple Documents
        </Button>
      </Box>

      <StyledDialog
        open={isOpen}
        onClose={closeDialog}
        maxWidth="xs"
        classes={{ container: classes.container }}
      >
        <Box display="flex" flexDirection="column">
          <Box position="absolute" top={8} right={8}>
            <IconButton onClick={closeDialog} data-test="close-survey-modal">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Typography variant="h4" textAlign="center" mb={2} fontSize="1.1rem" lineHeight={1.4}>
            🗂️ Request Multiple Documents
          </Typography>
          <Typography variant="body1" textAlign="center" fontSize="0.9rem" lineHeight={1.3}>
            EvenUp wants to make it easy to request multiple documents per case. Fill out a 3-min survey and
            we&apos;ll notify you when this feature is available.
          </Typography>

          <Box mx="auto" mt={3}>
            <StyledPrimaryButton
              size="large"
              onClick={() => {
                amplitudeApm.trackEvent(
                  new RequestAnalyticEvent(
                    RequestAnalyticsEventTypes.MultiDocRequestWaitlistClicked,
                    analyticEventProps
                  )
                )
                window.open("https://forms.gle/aeZpSoWQspH77M4s8", "_blank")
              }}
              data-test="survey-button"
            >
              Join the waitlist
            </StyledPrimaryButton>
          </Box>
        </Box>
      </StyledDialog>
    </>
  )
}
