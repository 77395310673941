import { CSSProperties, memo, useCallback, useMemo } from "react"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { useStyles } from "../styled"

import { amountInDollars } from "utils"

import { Bill } from "./types"
import { Entry } from "../types"
import DatesCell from "./DatesCell"
import WritesOffCell from "./WriteOffsCell"
import { FixedSizeList } from "react-window"

const Container = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1, 0, 2),
}))

const Table = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
}))

const Row = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
}))

const SummaryRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
}))

const FileName = styled(Box)(() => ({
  fontSize: "11px",
  fontStyle: "italic",
  lineHeight: 1.64,
  letterSpacing: "0.2px",
}))

interface BillTableProps {
  bill: Bill
  onEntryClick: (entry: Entry) => void
}

interface BillRowRendererDataProps {
  charges: Bill["charges"]
  onEntryClick: (entry: Entry) => void
}

interface BillRowRendererProps {
  data: Required<BillRowRendererDataProps>
  index: number
  style: CSSProperties
}

const BillRowRenderer = memo(function BillRowRenderer({
  data: { charges, onEntryClick },
  index,
  style,
}: BillRowRendererProps) {
  const { classes, cx } = useStyles()
  const { dates, charge, write_offs } = charges[index]
  const chargeEntry = charge.entries[0]

  const handleAmountClick = useCallback(() => {
    chargeEntry && onEntryClick(chargeEntry)
  }, [onEntryClick, chargeEntry])

  return (
    <Row
      className={cx(index % 2 === 0 ? classes.darkRow : classes.lightRow)}
      key={`bill_${index}`}
      style={style}
    >
      <DatesCell dates={dates} onEntryClick={onEntryClick} />
      <Box>
        <Box onClick={handleAmountClick} className={classes.entry}>
          {amountInDollars(charge.amount)}
        </Box>
      </Box>
      <WritesOffCell writeOffs={write_offs} onEntryClick={onEntryClick} />
    </Row>
  )
})

const BillTable = ({ bill, onEntryClick }: BillTableProps): JSX.Element => {
  const { file_name, charges, total_billed, total_write_off } = bill

  const itemData = useMemo(
    () => ({
      charges,
      onEntryClick,
    }),
    [charges, onEntryClick]
  )

  return (
    <Container>
      <Table>
        <FixedSizeList
          height={charges.length > 10 ? 230 : charges.length * 22}
          width="100%"
          itemCount={charges.length}
          itemSize={22}
          itemData={itemData}
        >
          {BillRowRenderer}
        </FixedSizeList>
        <SummaryRow>
          <Box></Box>
          <Box>{amountInDollars(total_billed ?? 0)}</Box>
          <Box>{amountInDollars(total_write_off ?? 0)}</Box>
        </SummaryRow>
      </Table>
      <FileName>{file_name}</FileName>
    </Container>
  )
}

export { BillTable as default }
