import { Outlet, Link } from "react-router-dom"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import useUser from "../hooks/useUser"
import { useFirm } from "../hooks/useFirm"

import { ALL_ROLES, EXTERNAL_ROLES, INTERNAL_ROLES } from "../common/models/roles"
import { NavLink, SETTINGS_ROUTE_PREFIX } from "./types"
import { FirmDto } from "./Firm/Firm"
import { getSettingsPageOptions } from "./utils"
import { usePermissions } from "permissions/usePermissions"
import { useTheme } from "@mui/material/styles"
import { useLocationStore } from "app/location/store"

const useStyles = makeStyles()(theme => ({
  settingsContainer: {
    display: "grid",
    gridTemplateColumns: `${theme.spacing(28)} auto`,
    gridColumn: "span 2",
  },
  settingsList: {
    background: "#FDFDFD",
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
  },
  settingsMain: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  settingsIcon: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  selectedBox: {
    width: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    height: "2.5em",
  },
  selected: { background: theme.palette.blue.main },
}))

function getNavOptions(
  role: ALL_ROLES,
  firm: Nullable<FirmDto>,
  smartAdvocateIntegrationEnabled: boolean,
  settingsBillingTabEnabled: boolean
): NavLink[] {
  const defaultNavOptions: NavLink[] = [getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.PROFILE)]
  let navOptions: NavLink[] = []

  switch (role) {
    case INTERNAL_ROLES.LEGALOPS_ADMIN:
    case INTERNAL_ROLES.LEGALOPS_MANAGER:
      navOptions = [
        ...defaultNavOptions,
        getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.FIRMS, role),
        getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.ACCOUNTS, role),
        getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.LIBRARY, role),
        getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.FEATURE_PERMISSIONS, role),
      ]

      if (settingsBillingTabEnabled) {
        navOptions.push(getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.BILLING, role))
      }
      break
    case INTERNAL_ROLES.LEGALOPS:
      navOptions = [
        ...defaultNavOptions,
        getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.FIRMS, role),
        getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.LIBRARY, role),
      ]
      break
    case EXTERNAL_ROLES.FIRM_ADMIN:
      navOptions = [
        ...defaultNavOptions,
        getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.ACCOUNTS, role),
        getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.CREDITS, role),
      ]
      if (smartAdvocateIntegrationEnabled) {
        navOptions.push(getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.IMPORT))
      }
      if (settingsBillingTabEnabled) {
        navOptions.push(getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.BILLING, role))
      }
      break
    case EXTERNAL_ROLES.ATTORNEY:
    case EXTERNAL_ROLES.SUPPORT:
      navOptions = defaultNavOptions
      if (smartAdvocateIntegrationEnabled) {
        navOptions.push(getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.IMPORT))
      }

      break
    case EXTERNAL_ROLES.OFFICE_ADMIN:
      navOptions = defaultNavOptions
      if (settingsBillingTabEnabled) {
        navOptions.push(getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.BILLING, role))
      }
      break
    default:
      navOptions = defaultNavOptions
      break
  }

  const canFirmSeeCredits = firm?.can_client_view_credits ?? false
  if (!canFirmSeeCredits) {
    navOptions = navOptions.filter(option => {
      return option.link !== SETTINGS_ROUTE_PREFIX.CREDITS
    })
  }

  return navOptions
}

export function Settings(): JSX.Element {
  const { classes } = useStyles()
  const { user } = useUser()
  const theme = useTheme()
  const { firm } = useFirm(user.firmId)
  const { smartAdvocateIntegrationEnabled, settingsBillingTabEnabled } = usePermissions({
    suspense: false,
  })

  const navOptions = getNavOptions(
    user.role,
    firm,
    smartAdvocateIntegrationEnabled,
    settingsBillingTabEnabled
  )
  const pathname = useLocationStore(location => location.pathname)

  return (
    <Box className={classes.settingsContainer}>
      <List component="nav" className={classes.settingsList}>
        {navOptions.map(({ pageTitle, link, icon }) => {
          const isActive = pathname.includes(link)
          return (
            <ListItem
              button
              key={pageTitle}
              component={Link}
              to={link}
              sx={{ color: isActive ? theme.palette.common.black : theme.palette.grey[600] }}
            >
              <Box className={`${classes.selectedBox} ${isActive && classes.selected}`} />
              <Box className={classes.settingsIcon}>{icon}</Box>
              <ListItemText primary={pageTitle} />
            </ListItem>
          )
        })}
      </List>
      <Box className={classes.settingsMain}>
        <Outlet />
      </Box>
    </Box>
  )
}
