import { useCallback } from "react"
import { StyledAppointmentProviderCell, StyledAppointmentProviderName } from "../styled"
import { Appointment, Provider } from "documents/types"
import { documentActions, useDocumentStore } from "documents/store"
import { providersSelectors } from "documents/store/providers"
import { useShallow } from "zustand/react/shallow"
import { BlackTooltip } from "common/Tooltip"
import { EditableSelect } from "evenup-ui/EditableSelect"
import { noop } from "lodash"

interface ProviderCellProps {
  providerId: Provider["id"]
  appointmentId: Appointment["id"]
  readOnly?: boolean
}

export const ProviderCell = ({ providerId, appointmentId, readOnly }: ProviderCellProps) => {
  const { name, color } = useDocumentStore(providersSelectors.getProviderById(providerId))
  const handleProviderCreate = useCallback((name: string) => documentActions.createProvider({ name }), [])
  const providers = useDocumentStore(useShallow(providersSelectors.getProviders))
  const handleProviderRename = useCallback(
    (id: Provider["id"], name: Provider["name"]) => documentActions.renameProvider({ id, name }),
    []
  )
  const handleProviderChange = useCallback(
    (providerId: Provider["id"]) => {
      documentActions.setAppointmentProvider({ appointmentId, providerId })
    },
    [appointmentId]
  )

  return (
    <StyledAppointmentProviderCell>
      <EditableSelect
        itemType="provider"
        onRename={handleProviderRename}
        onCreate={handleProviderCreate}
        sx={{ width: "100%", marginTop: "10px" }}
        value={null}
        customInputRenderer={({ onClick }) => (
          <BlackTooltip arrow placement="bottom" title={name}>
            <span>
              <StyledAppointmentProviderName
                onClick={readOnly ? noop : onClick}
                color={color}
                style={{ cursor: readOnly ? "default" : "pointer" }}
                label={name}
              />
            </span>
          </BlackTooltip>
        )}
        disableEmpty
        onChange={handleProviderChange}
        options={providers}
      />
    </StyledAppointmentProviderCell>
  )
}
