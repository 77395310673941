import Chip from "@mui/material/Chip"
import { Theme } from "@mui/material/styles"
import styled from "@emotion/styled"

export enum TAG_LABEL_TYPE {
  IGNORE,
  POSTPONE,
  ACTION,
  INFO,
  WARNING,
  PENDING,
  ANNOTATION_IN_PROGRESS,
  ANNOTATION_COMPLETE,
  ANNOTATION_COMPLETE_WITH_ERRORS,
}

function getBackgroundColor(theme: Theme, type: TAG_LABEL_TYPE): string {
  switch (type) {
    case TAG_LABEL_TYPE.IGNORE:
      return theme.palette.label.ignore
    case TAG_LABEL_TYPE.POSTPONE:
      return theme.palette.label.postpone
    case TAG_LABEL_TYPE.ACTION:
      return theme.palette.label.action
    case TAG_LABEL_TYPE.INFO:
      return theme.palette.label.info
    case TAG_LABEL_TYPE.WARNING:
      return theme.palette.label.warning
    case TAG_LABEL_TYPE.PENDING:
      return theme.palette.label.pending
    case TAG_LABEL_TYPE.ANNOTATION_IN_PROGRESS:
      return theme.palette.annotation.annotating
    case TAG_LABEL_TYPE.ANNOTATION_COMPLETE_WITH_ERRORS:
      return theme.palette.annotation.error
    case TAG_LABEL_TYPE.ANNOTATION_COMPLETE:
      return theme.palette.annotation.complete
  }
}

export interface TagLabelData {
  type: TAG_LABEL_TYPE
  label: string
}

export const TagLabel = styled(Chip)(
  ({ type = TAG_LABEL_TYPE.INFO, theme }: { theme: Theme } & TagLabelData) => ({
    background: getBackgroundColor(theme, type),
    color: theme.palette.common.white,
    borderRadius: "5px",
    fontSize: "0.75rem",
    fontWeight: 500,
    letterSpacing: "-0.4px",
    padding: "5px",
    marginRight: theme.spacing(0.25),
  })
)

TagLabel.defaultProps = {
  size: "small",
}

interface TagLabelsProps {
  tags?: Nullable<TagLabelData[]>
}

export const TagLabels = ({ tags }: TagLabelsProps): JSX.Element => {
  return <>{tags?.map(tag => <TagLabel {...tag} key={tag.label} data-test={`tag-${tag.label}`} />)}</>
}
