import { ExhibitBuilderData } from "../store/types"

export type DocTypesSlice = Pick<ExhibitBuilderData, "docTypes">

export const docTypesSlice: DocTypesSlice = {
  docTypes: {},
}

export const docTypesSelectors = {
  getSubDocTypesByDocTypes: (docTypes: string[]) => (state: DocTypesSlice) =>
    docTypes.flatMap(docType => state.docTypes[docType]?.subDocTypes || []),
}
