import { useMemo } from "react"

const HIDE = "hide"

export const getValue = (key: string): Nullable<string> => {
  if (!key) return null
  return localStorage.getItem(key)
}

export const setValue = (key: string, value: string): void => {
  localStorage.setItem(key, value)
}

export const isValueHidden = (key: string): boolean => {
  return getValue(key) === HIDE
}

export const hideKey = (key: string): void => {
  setValue(key, HIDE)
}

interface Return {
  getValue: (key: string) => Nullable<string>
  setValue: (key: string, value: string) => void
  isValueHidden: (key: string) => boolean
  hideKey: (key: string) => void
}

export const useLocalStorage = (): Return => {
  const result = useMemo(() => ({ getValue, setValue, isValueHidden, hideKey }), [])

  return result
}
