import React, { ReactElement, ReactNode, Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { isNull } from "lodash"

export function withSuspense<Props>(
  Component: React.ComponentType<Props>,
  fallback?: ReactNode,
  errorFallback?: Nullable<ReactElement>
): React.FunctionComponent<Props> {
  return function WithSuspense(props: Props) {
    if (isNull(errorFallback)) {
      return (
        <Suspense fallback={fallback}>
          <Component {...(props as JSX.IntrinsicAttributes & Props)} />
        </Suspense>
      )
    }

    return (
      <ErrorBoundary fallback={errorFallback ?? <></>}>
        <Suspense fallback={fallback}>
          <Component {...(props as JSX.IntrinsicAttributes & Props)} />
        </Suspense>
      </ErrorBoundary>
    )
  }
}
