import React from "react"
import { Box, Tooltip } from "@mui/material"
import { UserDto } from "common/models/user"
import { FirmDto } from "settings/Firm/Firm"
import { ALL_ROLES, USER_ROLES } from "common/models/roles"
import { FeaturePermission } from "./types"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

const NONE = "None"
interface CellProps {
  data: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
}

type DisplayNameProps = { permission: FeaturePermission }
export const DisplayNameCell: React.FC<DisplayNameProps> = ({ permission }): JSX.Element => {
  const descriptionText = permission.description ?? "<No Description Provided>"
  return (
    <Tooltip title={descriptionText} arrow>
      <Box display="flex" alignItems="center" gap={0.5}>
        {permission.display_name}
        {permission.description && <MoreHorizIcon fontSize="small" />}
      </Box>
    </Tooltip>
  )
}

// This function takes in a list of data entries and if the list is too long,
// it removes the excess entries and replaces them with a "And X more" entry.
// It also calls mapFn on each remaining entry before returning them.
function truncateCellContents<T>(data: T[], maxLength: number, mapFn: (value: T) => string) {
  const dataList = (data.length > maxLength ? data.slice(0, maxLength) : data).map(mapFn)
  if (data.length > maxLength) {
    dataList.push(`and ${data.length - maxLength} more`)
  }
  return dataList
}

export const AuthorizedUsersCell: React.FC<CellProps> = ({ data }): JSX.Element => {
  const getUserName = (user: UserDto) => {
    return `${user.first_name} ${user.last_name}`
  }

  const userList = truncateCellContents<UserDto>(data, 2, getUserName)

  return <Box>{data.length ? userList.join(", ") : NONE}</Box>
}

export const AuthorizedFirmsCell: React.FC<CellProps> = ({ data }): JSX.Element => {
  const getFirmName = (firm: FirmDto) => {
    return `${firm.name}`
  }

  const firmList = truncateCellContents<FirmDto>(data, 2, getFirmName)

  return <Box>{data.length ? firmList.join(", ") : NONE}</Box>
}

export const AuthorizedRoleCell: React.FC<CellProps> = ({ data }): JSX.Element => {
  const getRoleName = (role: ALL_ROLES) => {
    return `${USER_ROLES[role].display}`
  }

  const roleList = truncateCellContents<ALL_ROLES>(data, 2, getRoleName)

  return <Box>{data?.length ? roleList.join(", ") : NONE}</Box>
}
