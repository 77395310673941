import { PropsWithChildren, useCallback, useContext, useMemo, useState } from "react"
import { SortableNestedListItemProps } from "common/nested-list/SortableNestedListItem"
import { ITEM_REF } from "common/nested-list/constants"
import { Collapse } from "@mui/material"

import { DocumentStructureBlock, DocumentStructureSection } from "../types"
import { SectionContext, SectionContextType } from "./context"
import { createComponent, createHeading, createTemplate } from "../utils"
import {
  StyledButton,
  StyledNewItemNote,
  StyledSectionChildren,
  StyledSectionFooter,
  StyledSectionListItem,
} from "./styled"

export type SectionListItemData = Omit<DocumentStructureSection, "children"> & { children: [] }

export type SectionListItemProps = SortableNestedListItemProps<SectionListItemData> & {
  onChildCreate: (child: DocumentStructureBlock) => void
}

export function SectionListItem({
  item,
  children,
  inline,
  readonly,
  onChildCreate,
  ...props
}: PropsWithChildren<SectionListItemProps>): JSX.Element {
  const section = item[ITEM_REF]
  const isNew = item[ITEM_REF].id === null
  const [open, setOpen] = useState<boolean>(isNew)

  const parentContext = useContext(SectionContext)
  const contextValue = useMemo<SectionContextType>(
    () => ({
      sectionId: section.id,
      repeat: parentContext.repeat || section.repeatForMultiplePlaintiffs,
      expanded: open,
      toggleExpanded: () => setOpen(opened => !opened),
    }),
    [section.id, open, parentContext.repeat, section.repeatForMultiplePlaintiffs]
  )

  const handleHeadingCreate = useCallback(() => {
    onChildCreate(createHeading())
  }, [onChildCreate])

  const handleTemplateCreate = useCallback(() => {
    onChildCreate(createTemplate())
  }, [onChildCreate])

  const handleComponentCreate = useCallback(() => {
    onChildCreate(createComponent())
  }, [onChildCreate])

  return (
    <SectionContext.Provider value={contextValue}>
      <StyledSectionListItem item={item} {...props} readonly={readonly} open={open}>
        {!inline && (
          <Collapse in={open}>
            <StyledSectionChildren empty={section.children.length === 0}>{children}</StyledSectionChildren>
            {!readonly && (
              <StyledSectionFooter>
                <StyledButton color="secondary" size="medium" disabled={isNew} onClick={handleHeadingCreate}>
                  + HEADER
                </StyledButton>
                <StyledButton color="secondary" size="medium" disabled={isNew} onClick={handleTemplateCreate}>
                  + TEMPLATE
                </StyledButton>
                <StyledButton
                  color="secondary"
                  size="medium"
                  disabled={isNew}
                  onClick={handleComponentCreate}
                >
                  + COMPONENT
                </StyledButton>
              </StyledSectionFooter>
            )}
          </Collapse>
        )}
      </StyledSectionListItem>
      {!inline && isNew && (
        <StyledNewItemNote>
          Note: “Section Name” is what will appear on Demand Gen’s left navigation panel and will NOT appear
          in the final demand.
        </StyledNewItemNote>
      )}
    </SectionContext.Provider>
  )
}
