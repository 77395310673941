import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  Typography,
} from "@mui/material"
import React from "react"
import { UseFormReturn } from "react-hook-form"
import { Row } from "./styled"
import { InputWithHeader } from "common/form-components/InputWithHeader"
import { CurrencyField, InputField } from "common/form-components"
import { useTheme } from "@emotion/react"
import { Error, ExpandLess, ExpandMore } from "@mui/icons-material"
import { NumberInput } from "common/form-components/NumberInput"
import { PricingModelFormData } from "./types"
import { PricingModel, ProductType } from "common/interfaces"
import { PRICING_MODEL_FORM_DEFAULT_VALUES } from "./constants"
import { useDialog } from "hooks/useDialog"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deletePricingModel } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { queryKeys } from "react-query/constants"

interface ProductFormProps {
  product?: Nullable<PricingModel>
  name: string
  description?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useFormReturn: UseFormReturn<PricingModelFormData, any>
  pricePerCredit: number
  required?: boolean
  productType: ProductType
  initiallyVisible?: boolean
}

const numericRules = { required: "Required", min: { value: 0, message: "Cannot be less than 0" } }

export const ProductForm: React.FC<ProductFormProps> = ({
  product,
  name,
  description,
  useFormReturn,
  pricePerCredit,
  required = false,
  productType,
  initiallyVisible = false,
}) => {
  const theme = useTheme()
  const [formVisible, setFormVisible] = React.useState(initiallyVisible)
  const [formActive, setFormActive] = React.useState(!!product || required)
  const { isOpen, openDialog, closeDialog } = useDialog()
  const { showErrorMessage } = useHandleMessages()
  const queryClient = useQueryClient()

  const deletePricingModelMutation = useMutation(deletePricingModel)

  const baseCredit = useFormReturn.watch("base_credit")
  const additionalClaimantCredit = useFormReturn.watch("additional_claimant_credit")
  const revisionCredit = useFormReturn.watch("revision_credit")

  const handleDialogConfirm = async () => {
    if (product) {
      try {
        await deletePricingModelMutation.mutateAsync(product.pk)
      } catch (error) {
        showErrorMessage({
          message:
            "An error occurred deleting the pricing model. Please try again shortly and if you error persists, contact eng team.",
          error,
        })
        return
      }
    }
    // set everything back to null except for product type, so we don't send the values to the backend
    useFormReturn.reset({ ...PRICING_MODEL_FORM_DEFAULT_VALUES, product_type: productType })
    setFormVisible(false)
    setFormActive(!formActive)
    queryClient.invalidateQueries([queryKeys.firmContracts])
    closeDialog()
  }

  return (
    <Box
      data-test={`${productType}-product-form`}
      sx={{ width: "100%", borderTop: `1px solid ${theme.palette.grey[300]}`, paddingTop: 2 }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex" }}>
          <Typography fontSize="16px" fontWeight="bold">
            {name}
          </Typography>
          {Object.keys(useFormReturn.formState.errors).length > 0 && (
            <Error sx={{ marginLeft: 1 }} color="error" />
          )}
        </Box>
        <Box>
          <Switch
            color="info"
            disabled={required}
            checked={formActive}
            onChange={() => {
              if (!formActive) {
                // form turning from inactive --> active
                setFormVisible(true)
                setFormActive(true)
              } else {
                // form turning from active --> inactive
                openDialog()
              }
            }}
          />
          <IconButton
            disabled={!formActive}
            onClick={() => {
              setFormVisible(!formVisible)
            }}
          >
            {formVisible ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Typography fontSize="13px">{description}</Typography>
      </Box>
      {/* unmountOnExit so that when the form is not active we don't run validation on the fields and prevent create/update */}
      <Collapse in={formVisible} mountOnEnter unmountOnExit={!formActive}>
        <Row>
          <InputWithHeader header="Base Credit">
            <InputField
              rules={numericRules}
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size="small"
              variant="outlined"
              name="base_credit"
              type="number"
            />
          </InputWithHeader>
          <InputWithHeader header="Base Cost">
            <NumberInput
              aria-readonly
              disabled
              size={"small"}
              name="base_cost"
              value={baseCredit !== null ? baseCredit * pricePerCredit : ""}
              currencySymbol="$"
            />
          </InputWithHeader>
          <InputWithHeader header="Medical Record Page Limit">
            <InputField
              rules={numericRules}
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size="small"
              variant="outlined"
              name="medical_pages_cap"
              type="number"
            />
          </InputWithHeader>
        </Row>
        <Row>
          <InputWithHeader header="Overage Pages">
            <InputField
              rules={numericRules}
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size="small"
              variant="outlined"
              name="medical_pages_overage"
              type="number"
            />
          </InputWithHeader>
          <InputWithHeader header="Overage Fees">
            <CurrencyField
              rules={numericRules}
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size="small"
              variant="outlined"
              name="medical_pages_overage_fee"
            />
          </InputWithHeader>
        </Row>
        <Row>
          <InputWithHeader header="Additional Claimant Credit">
            <InputField
              rules={numericRules}
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size="small"
              variant="outlined"
              name="additional_claimant_credit"
              type="number"
            />
          </InputWithHeader>
          <InputWithHeader header="Additional Claimant Fees">
            <NumberInput
              aria-readonly
              disabled
              value={additionalClaimantCredit !== null ? additionalClaimantCredit * pricePerCredit : ""}
              size="small"
              variant="outlined"
              name="additional_claimant_credit_fee"
              currencySymbol="$"
            />
          </InputWithHeader>
        </Row>
        <Row>
          <InputWithHeader header="Revision Credit">
            <InputField
              rules={numericRules}
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size="small"
              variant="outlined"
              name="revision_credit"
              type="number"
            />
          </InputWithHeader>
          <InputWithHeader header="Revision Fees">
            <NumberInput
              aria-readonly
              disabled
              value={revisionCredit !== null ? revisionCredit * pricePerCredit : ""}
              size="small"
              variant="outlined"
              name="revision_credit_fee"
              currencySymbol="$"
            />
          </InputWithHeader>
          <InputWithHeader header="Revision Medical Record Page Limit">
            <InputField
              rules={numericRules}
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size="small"
              variant="outlined"
              name="medical_pages_cap_revision"
              type="number"
            />
          </InputWithHeader>
        </Row>
        <Row>
          <InputWithHeader header="Revision Overage Pages">
            <InputField
              rules={numericRules}
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size="small"
              variant="outlined"
              name="medical_pages_overage_revision"
              type="number"
            />
          </InputWithHeader>
          <InputWithHeader header="Revision Overage Fees">
            <CurrencyField
              rules={numericRules}
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size="small"
              variant="outlined"
              name="medical_pages_overage_fee_revision"
            />
          </InputWithHeader>
        </Row>
        <Row>
          <InputWithHeader header="Verdict Fees">
            <CurrencyField
              rules={numericRules}
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size="small"
              variant="outlined"
              name="verdict_fee"
            />
          </InputWithHeader>
        </Row>
        <Row>
          <InputWithHeader fullWidth header="Notes">
            <InputField
              control={useFormReturn.control}
              errors={useFormReturn.formState.errors}
              size={"small"}
              variant="outlined"
              name="notes"
              type="text"
              multiline
              fullWidth
            />
          </InputWithHeader>
        </Row>
      </Collapse>

      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <Typography sx={{ marginBottom: 2 }}>
            Are you sure you want to delete the <strong>{name}</strong> pricing information?
          </Typography>
          <Typography>You cannot undo a delete.</Typography>
          <DialogActions>
            <Button
              disabled={deletePricingModelMutation.isLoading}
              variant="contained"
              color="inherit"
              onClick={closeDialog}
              disableElevation
            >
              Cancel
            </Button>
            <Button
              disabled={deletePricingModelMutation.isLoading}
              variant="outlined"
              color="error"
              onClick={handleDialogConfirm}
              disableElevation
            >
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
