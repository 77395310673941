import { Variable } from "common/types/variables"
import { FirmData } from "./Firm"
import {
  ALIGNMENT,
  CustomDamageSectionType,
  EXHIBIT_GROUPING_VALUES,
  EXHIBIT_SORTING_VALUES,
  FONTS,
} from "./enums"

export const FONT_DISPLAY_LABELS: Record<FONTS, string> = {
  [FONTS.ARIAL]: "Arial",
  [FONTS.CALIBRI]: "Calibri",
  [FONTS.CENTURY_GOTHIC]: "Century Gothic",
  [FONTS.CONSTANTIA]: "Constantia",
  [FONTS.GARAMOND]: "Garamond",
  [FONTS.TIMES_NEW_ROMAN]: "Times New Roman",
  [FONTS.CENTURY_SCHOOLBOOK]: "Century Schoolbook",
  [FONTS.COPPERPLATE_GOTHIC]: "Copperplate Gothic",
  [FONTS.BOOK_ANTIQUA]: "Book Antiqua",
  [FONTS.LUCINDA_GRANDE]: "Lucinda Grande",
  [FONTS.GOUDY_OLD_STYLE]: "Goudy Old Style",
  [FONTS.AVENIR]: "Avenir",
  [FONTS.AVENIR_NEXT]: "Avenir Next",
  [FONTS.ARIAL_NARROW]: "Arial Narrow",
  [FONTS.HELVETICA_NEUE]: "Helvetica Neue",
  [FONTS.ROBOTO]: "Roboto",
  [FONTS.LIBRE_BASKERVILLE]: "Libre Baskerville",
  [FONTS.VERDANA]: "Verdana",
  [FONTS.TAHOMA]: "Tahoma",
  [FONTS.GEORGIA]: "Georgia",
}

export const FONT_OPTIONS: FilterOption[] = Object.values(FONTS).map(font => ({
  key: font,
  display: FONT_DISPLAY_LABELS[font],
}))

export const ALIGNMENT_OPTIONS: FilterOption[] = Object.values(ALIGNMENT).map(alignment => ({
  key: alignment,
  display: alignment,
}))

export const INITIAL_STATE: FirmData = {
  name: "",
  logo: null,
  hubspot_id: null,
  demand_introduction: "",
  demand_conclusion: "",
  demand_introduction_json: null,
  demand_conclusion_json: null,
  demand_font: FONTS.TIMES_NEW_ROMAN,
  demand_alignment: ALIGNMENT.JUSTIFIED,
  has_collateral_source_rule: false,
  website: "",
  office_phone: "",
  fax_phone: "",
  address: {
    street: "",
    street_2: "",
    city: "",
    state: "",
    zip_code: "",
  },
  sections: [],
  deletedSections: [],
  template: null,
  header_text: "",
  header_text_2: "",
  can_client_view_credits: false,
  operating_in_states: [],
  exhibit_grouping_option: EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE,
  exhibit_sorting_option: EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS,
  can_create_basic_plus_requests: false,
  enable_annotations: true,
  enable_complete_annotation_pipeline: true,
  enable_automated_important_medical_passages_annotation: false,
  enable_automated_billing_table_annotation: true,
  enable_automated_provider_name_annotation: false,
  enable_add_title_page: false,
  is_internal: false,
  status: null,
  feature_permissions: {
    create_basic_plus: false,
  },
}

export const EXHIBIT_SORTING_LABELS: Record<EXHIBIT_SORTING_VALUES, string> = {
  [EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS]: "Per Provider: Records then Bills",
  [EXHIBIT_SORTING_VALUES.PER_PROVIDER_BILLS_AND_RECORDS]: "Per Provider: Bills then Records",
  [EXHIBIT_SORTING_VALUES.ALL_BILLS_THEN_RECORDS]: "All Bills then Records",
  [EXHIBIT_SORTING_VALUES.ALL_RECORDS_THEN_BILLS]: "All Records then Bills",
}

export const EXHIBIT_GROUPING_LABELS: Record<EXHIBIT_GROUPING_VALUES, string> = {
  [EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE]: "By File Type Per Provider",
  [EXHIBIT_GROUPING_VALUES.PER_PROVIDER]: "One File Per Provider",
  [EXHIBIT_GROUPING_VALUES.ONE_FILE]: "All Exhibits to One PDF",
  [EXHIBIT_GROUPING_VALUES.INDIVIDUAL_FILES]: "deprecated - All Exhibits in Individual Files",
}

export const EXHIBIT_SORTING_OPTIONS: FilterOption[] = Object.values(EXHIBIT_SORTING_VALUES).map(option => ({
  key: option,
  display: EXHIBIT_SORTING_LABELS[option],
}))

export const EXHIBIT_GROUPING_OPTIONS: FilterOption[] = Object.values(EXHIBIT_GROUPING_VALUES).map(
  option => ({
    key: option,
    display: EXHIBIT_GROUPING_LABELS[option],
  })
)

export enum AdjusterType {
  ADJUSTER = "adjuster",
  ATTORNEY = "attorney",
  REPRESENTATIVE = "representative",
}

export const RECIPIENT_TYPE_LABELS: Record<AdjusterType, string> = {
  [AdjusterType.ADJUSTER]: "Adjuster",
  [AdjusterType.ATTORNEY]: "Attorney",
  [AdjusterType.REPRESENTATIVE]: "Representative",
}

export const RECIPIENT_TYPE_OPTIONS = Object.values(AdjusterType).map(option => ({
  key: option,
  display: RECIPIENT_TYPE_LABELS[option],
}))

function createVariable(name: string, label: string, category: string): Variable {
  return {
    name,
    value: null,
    type: "text",
    category,
    label,
  }
}

export const CUSTOM_DAMAGE_SECTION_VARIABLES: Record<CustomDamageSectionType, Variable[]> = {
  [CustomDamageSectionType.INCOME]: [
    createVariable("income_loss", "Income Loss", "Loss of Income"),
    createVariable("future_income_loss", "Future Income Loss", "Loss of Income"),
    createVariable("past_income_loss", "Past Income Loss", "Loss of Income"),
  ],
  [CustomDamageSectionType.HOUSEHOLD]: [
    createVariable("past_adjusted_loss", "Past Adjasted Loss", "Loss of Household Services"),
    createVariable("future_adjusted_loss", "Future Adjasted Loss", "Loss of Household Services"),
    createVariable("total_adjusted_loss", "Total Adjasted Loss", "Loss of Household Services"),
    createVariable("starting_hh_hours", "Household Hours at Start", "Loss of Household Services"),
    createVariable("housekeeping_salary", "Salary for Housekeeper", "Loss of Household Services"),
    createVariable("end_of_impairment_date", "End of Impairment Date", "Loss of Household Services"),
    createVariable("start_of_impairment_date", "Start of Impairment Date", "Loss of Household Services"),
    createVariable("start_of_impairment_year", "Start of Impairment Year", "Loss of Household Services"),
    createVariable("past_impairment_rate", "Past Impairment Rate ", "Loss of Household Services"),
    createVariable("future_impairment_rate", "Future Impairment Rate", "Loss of Household Services"),
    createVariable("past_impaired_days", "Number of Past Impaired Days", "Loss of Household Services"),
    createVariable("future_impaired_days", "Number of Future Impaired Days", "Loss of Household Services"),
    createVariable("past_adjusted_loss", "Past Adjasted Loss", "Loss of Household Services"),
    createVariable("impaired_days", "Total Number of Impaired Days", "Loss of Household Services"),
  ],
  [CustomDamageSectionType.REL_CASE]: [
    createVariable("analyses_statement", "Comparable Verdicts", "Verdict Analysis"),
    createVariable("amount", "Total Amount for Damages", "Verdict Analysis"),
  ],
  [CustomDamageSectionType.MEDICAL]: [],
  [CustomDamageSectionType.FUTURE_EXPENSES]: [
    createVariable("future_procedures", "Future Procedures", "Future Medical Expenses"),
  ],
  [CustomDamageSectionType.PER_DIEM]: [
    createVariable("first_hourly_rate_pain_suffering", "Past Hourly Rate", "Per-Diem Analysis"),
    createVariable("second_hourly_rate_pain_suffering", "Future Hourly Rate", "Per-Diem Analysis"),
    createVariable("start_of_past_impairment", "Start Date of Past Impairment", "Per-Diem Analysis"),
    createVariable("end_of_past_impairment", "End of Past Impairment", "Per-Diem Analysis"),
    createVariable("start_of_future_impairment", "Start of Future Impairment", "Per-Diem Analysis"),
    createVariable("end_of_future_impairment", "End of Duture Impairment", "Per-Diem Analysis"),
    createVariable("amount", "Sum of Past and Future per diem Amounts", "Per-Diem Analysis"),
    createVariable("past_amount", "Past per diem Amount", "Per-Diem Analysis"),
    createVariable("future_amount", "Future per diem Amount", "Per-Diem Analysis"),
  ],
  [CustomDamageSectionType.CUSTOM]: [createVariable("amount", "Amount", "Custom Damage")],
}
