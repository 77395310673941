export enum TREATMENT_TIMELINE_ENTITY_TYPE {
  MONTH = "MONTH",
  YEAR = "YEAR",
  DAY = "DAY",
}

export enum TREATMENT_TIMELINE_INCIDENT_TYPE {
  PRIOR = "PRIOR",
  PAST = "PAST",
}

export const TREATMENT_TIMELINE_INCIDENT_MOMENT_TYPE = "TREATMENT_TIMELINE_INCIDENT_MOMENT_TYPE"
export const ABSENT_OF_TREATMENT = "ABSENT_OF_TREATMENT"
export const LAST_ENTITY_TYPE = "LAST_ENTITY_TYPE"

export interface TreatmentTimelineEntityPoint {
  percent: number
  id: string
  number?: number
}

export interface TreatmentTimelineEntityLine {
  color: string
  id: string
  points: TreatmentTimelineEntityPoint[]
}

export interface TreatmentTimelineIncident {
  type: TREATMENT_TIMELINE_INCIDENT_TYPE
}

export interface TreatmentTimelineIncidentMoment {
  type: typeof TREATMENT_TIMELINE_INCIDENT_MOMENT_TYPE
  percent: number
  text: string
}

export type IncidentType = TreatmentTimelineIncident | TreatmentTimelineIncidentMoment

interface BaseTreatmentTimelineEntity {
  text: string
  type: TREATMENT_TIMELINE_ENTITY_TYPE
  lines: TreatmentTimelineEntityLine[]
  incident: IncidentType
}

export interface AbsentTreatmentTimelineEntity {
  startText: string
  text: string
  type: typeof ABSENT_OF_TREATMENT
  incident: IncidentType
}

export interface LastTreatmentEntity {
  text: string
  type: typeof LAST_ENTITY_TYPE
  treatmentEntityType: TREATMENT_TIMELINE_ENTITY_TYPE
}

export type TreatmentTimelineEntity =
  | BaseTreatmentTimelineEntity
  | AbsentTreatmentTimelineEntity
  | LastTreatmentEntity
