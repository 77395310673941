import React from "react"
import { Box, TextField } from "@mui/material"
import { CopyTag } from "common/helpers/copy/constants"

interface ReadonlyHeaderFieldProps {
  header: string
  editing: false
  onChange?: never
}

interface EditableHeaderFieldProps {
  header: string
  editing: true
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

type HeaderFieldProps = ReadonlyHeaderFieldProps | EditableHeaderFieldProps

export const HeaderField = ({ editing, header, onChange }: HeaderFieldProps): JSX.Element => {
  const copyTag = CopyTag.HEADER_FIELD

  if (editing) {
    return (
      <Box data-copytag={copyTag}>
        <TextField value={header} onChange={onChange} label="Header" sx={{ background: "white" }} />
      </Box>
    )
  }

  return (
    <Box data-copytag={copyTag}>
      <b>{header}</b>
    </Box>
  )
}
