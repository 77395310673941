import { useCallback, useMemo } from "react"
import { ProjectionItemWithChildren } from "common/nested-list/types"
import { useExhibitPreview } from "./useExhibitPreview"
import { ExhibitItem } from "../types"
import { CollapseButton, ExhibitControls } from "./styled"
import { useCollapseState } from "common/CollapseManager/useCollapseState"
import { documentActions, documentStoreSelectors, useDocumentStore } from "documents/store"
import { exhibitSelectors } from "documents/store/exhibits"
import { useShallow } from "zustand/react/shallow"
import splitFileName from "common/form-components/files/splitFileName"
import { ExhibitContainer } from "exhibit-builder/Exhibit/ExhibitContainer"

export function ExhibitContent({ item }: { item: ProjectionItemWithChildren<ExhibitItem> }): JSX.Element {
  const exhibit = useDocumentStore(exhibitSelectors.getExhibitById(item.id))
  const exhibitDates = useDocumentStore(useShallow(documentStoreSelectors.exhibitDates(item.id)))
  const appointmentsCount = useDocumentStore(
    useShallow(documentStoreSelectors.exhibitAppointmentsCount(item.id))
  )
  const [collapsed, setCollapsed] = useCollapseState()

  const exhibits = useDocumentStore(state => state.exhibitsOrder)
  const exhibitIndex = useMemo(() => exhibits.findIndex(id => id === exhibit.id), [exhibit.id, exhibits])
  const openPreview = useExhibitPreview(exhibit.id)
  const [exhibitName, extension] = splitFileName(exhibit.name)

  const handleRename = useCallback(
    (nextFileName: string) => {
      documentActions.renameExhibit({ id: exhibit.id, name: nextFileName + extension })
    },
    [exhibit.id, extension]
  )

  const expandText = appointmentsCount ? `Expand to see ${appointmentsCount} appointments` : "Expand"

  return (
    <ExhibitContainer
      index={exhibitIndex}
      exhibitName={exhibitName}
      onPreview={openPreview}
      onUserExhibitRename={handleRename}
      exhibitReadyToBeViewed
      renderControls={() => (
        <ExhibitControls>
          <div>
            <CollapseButton collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? expandText : "Collapse"}
            </CollapseButton>
          </div>
          <div>Date range: {exhibitDates ? exhibitDates.join(" - ") : "—"}</div>
        </ExhibitControls>
      )}
    />
  )
}
