import { Editor, Path, Range, Transforms } from "slate"
import { HistoryEditor } from "slate-history"
import { CustomEditor } from "../../CustomEditor"
import { LIST_BLOCK_ELEMENTS } from "../../elements"
import { getClosestParagraph, isTextNode } from "../../queries"
import { convertToList } from "./convertToList"
import { isInList } from "./queries"
import { deleteListBackward } from "./remove"

const LIST_PATTERNS: Record<LIST_BLOCK_ELEMENTS, RegExp> = {
  // For ordered list we have patterns similar to Word/GoogleDocs:
  // "1.", "1)"
  [LIST_BLOCK_ELEMENTS.ORDERED_LIST]: /^1(\.|\))$/,
  [LIST_BLOCK_ELEMENTS.UNORDERED_LIST]: /^-$/,
}

export function createListFromHotkey(editor: CustomEditor): boolean {
  if (!editor.selection) return false

  if (Range.isExpanded(editor.selection)) {
    deleteListBackward(editor) || Transforms.delete(editor, { at: editor.selection, voids: true })
  }

  const { anchor } = editor.selection

  // Should convert to list only in case (should match all):
  // - cursor is not in actual list
  // - previous symbols match start-of-list pattern
  if (isInList(editor, anchor.path)) return false

  if (Path.hasPrevious(anchor.path) || anchor.offset === 0) return false

  const paragraphNodeEntry = getClosestParagraph(editor, anchor.path)

  if (!paragraphNodeEntry) return false

  const [paragraphNode, paragraphPath] = paragraphNodeEntry

  if (!Path.isChild(anchor.path, paragraphPath)) return false

  const [node] = paragraphNode.children

  if (!isTextNode(node)) return false

  const pattern = node.text.substring(0, anchor.offset)
  const listType = Object.values(LIST_BLOCK_ELEMENTS).find(type => LIST_PATTERNS[type].test(pattern))

  if (!listType) return false

  // Insert space to be able to undo list creation with preserving inserted space
  Editor.insertText(editor, " ")
  // Delete starting pattern with inserted space
  HistoryEditor.withoutMerging(editor, () => {
    Transforms.delete(editor, {
      at: {
        anchor: {
          path: anchor.path,
          offset: 0,
        },
        focus: {
          path: anchor.path,
          offset: anchor.offset + 1,
        },
      },
    })
  })
  convertToList(editor, listType)

  return true
}
