import { Alert, Box } from "@mui/material"
import { withSuspense } from "common/withSuspense"
import { useSuspenseQuery } from "@suspensive/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { useSearchParams } from "react-router-dom"
import { IMedicronCalendar, MedicronCalendar } from "./MedicronCalendar"
import { MedicronCalendarTestAppointments, MedicronCalendarTestProviders } from "./medicronCalendarSampleData"
import { documentsService } from "api/services/documents"
import { getMedicronCalendarSettingsFromUrlParams } from "./medicronCalendarUtilities"
import { Appointment } from "documents/types"

function useGetMedicronData(nonce: string) {
  const { data } = useSuspenseQuery(
    [queryKeys.documentMedicronCalendarData],
    () => documentsService.getMedicronCalendarData({ nonce }),
    { ...SILENT_QUERY_PARAMS, staleTime: Infinity }
  )
  return data
}

interface IMedicronCalendarDataWrapper extends Omit<IMedicronCalendar, "appointmentData"> {
  nonce: string
}

const MedicronCalendarDataFetcher = withSuspense(
  ({ nonce, ...props }: IMedicronCalendarDataWrapper) => {
    const appointmentData = useGetMedicronData(nonce)

    return <MedicronCalendar appointmentData={appointmentData} {...props} />
  },
  <Box>
    <Alert severity="info">Loading calendar...</Alert>
  </Box>,
  <Box>
    <Alert severity="error">Error fetching calendar data</Alert>
  </Box>
)

const MedicronCalendarWithSampleData = ({ ...props }: Omit<IMedicronCalendar, "appointmentData">) => {
  const appointmentData = {
    plaintiff: "John Doe",
    appointments: MedicronCalendarTestAppointments as Record<string, Appointment>,
    providers: MedicronCalendarTestProviders,
    dayOfIncident: new Date("October 09, 2023"),
  }

  return <MedicronCalendar appointmentData={appointmentData} {...props} />
}

/** URL Parameters **
 * nonce - string, required. Passed along to the endpoint to fetch the data
 *
 * startYear - number.  Filters out events that happened before the startYear
 * endYear - number.  Filters out events that happened after the endYear
 * hideLegend - whether or not to show the legend
 **/
export const MedicronCalendarDataWrapper = () => {
  const [params] = useSearchParams()
  const nonce = params.get("nonce")
  const calendarSettings = getMedicronCalendarSettingsFromUrlParams(params)

  if (!nonce) {
    return (
      <Box data-test="sample-warning-box">
        <Alert variant="outlined" severity="warning">
          <Box>SAMPLE CALENDAR</Box>
          <Box>nonce is required to display a medicron calendar</Box>
        </Alert>
        <MedicronCalendarWithSampleData {...calendarSettings} />
      </Box>
    )
  }

  return <MedicronCalendarDataFetcher nonce={nonce} />
}
