import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { AttributeFiltersData } from "common/attributes-filter"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { AttributeValue } from "common/types/attributes"
import { CASE_SECTIONS } from "common/types/sections"
import { isEqual, isNil } from "lodash"
import {
  CaseAddTitlePageDto,
  CaseAttributeValue,
  CaseAttributeValueDto,
  CaseAttributeValuesDto,
  CaseDto,
  CaseIntroduction,
  CaseIntroductionDto,
  CaseIntroductionUpdateDto,
  CasePainAndSuffering,
  CasePainAndSufferingDto,
  UpdatedCasePainAndSuffering,
  CaseFacts,
  CaseFactsDto,
  CaseFactsSectionUpdateDto,
  CaseFactsUpdateDto,
  CaseInfo,
  CaseTemplatedSection,
  CaseTemplatedSectionDto,
  Plaintiff,
  PlaintiffDto,
  ProviderTemplatedSectionOverride,
  ProviderTemplatedSectionUpdateDto,
  ProviderTemplateUpdataDto,
  UpdatedCaseFacts,
  UpdatedCaseIntroduction,
  CaseConclusion,
  CaseConclusionDto,
  UpdatedCaseConclusion,
  CaseConclusionUpdateDto,
  CasePainAndSufferingUpdateDto,
  EditorFields,
} from "./types"
import { EditorContent } from "common/form-components/rich-text"

type KeyOfType<T, TData> = {
  [K in keyof T as string]: T[K] extends TData ? K : never
}[string]

type CaseSectionTemplateContent = Partial<
  Record<NonNullable<(typeof CASE_SECTION_TO_FIELDS_MAPPING)[CASE_SECTIONS]["content"]>, EditorRoot<false>>
>

export const CASE_SECTION_TO_FIELDS_MAPPING: Record<
  CASE_SECTIONS,
  {
    content:
      | Nullable<KeyOfType<CaseFactsDto, Nullable<EditorRoot<false>>>>
      | Nullable<KeyOfType<CaseIntroductionDto, Nullable<EditorRoot<false>>>>
      | Nullable<KeyOfType<CaseConclusionDto, Nullable<EditorRoot<false>>>>
      | Nullable<KeyOfType<CasePainAndSufferingDto, Nullable<EditorRoot<false>>>>
    display: string
  }
> = {
  [CASE_SECTIONS.FACTS]: {
    content: "facts_json",
    display: "Facts",
  },
  [CASE_SECTIONS.LIABILITIES]: {
    content: "liability_details_json",
    display: "Liabilities",
  },
  [CASE_SECTIONS.INTRODUCTION]: {
    content: "content_json",
    display: "Introduction",
  },
  [CASE_SECTIONS.CONCLUSION]: {
    content: "content_json",
    display: "Conclusion",
  },
  [CASE_SECTIONS.FUTURE_MEDICAL_EXPENSES_PRE_TEXT]: {
    content: null,
    display: "Future Medical Expenses Pre Text",
  },
  [CASE_SECTIONS.FUTURE_MEDICAL_EXPENSES_POST_TEXT]: {
    content: null,
    display: "Future Medical Expenses Post Text",
  },
  [CASE_SECTIONS.LOSS_OF_INCOME_PRE_TEXT]: {
    content: null,
    display: "Loss of Income Pre Text",
  },
  [CASE_SECTIONS.LOSS_OF_INCOME_POST_TEXT]: {
    content: null,
    display: "Loss of Income Post Text",
  },
  [CASE_SECTIONS.LOSS_OF_HOUSEHOLD_SERVICES_PRE_TEXT]: {
    content: null,
    display: "Loss of Household Services Pre Text",
  },
  [CASE_SECTIONS.LOSS_OF_HOUSEHOLD_SERVICES_POST_TEXT]: {
    content: null,
    display: "Loss of Household Services Post Text",
  },
  [CASE_SECTIONS.PAST_MEDICAL_EXPENSES_PRE_TEXT]: {
    content: null,
    display: "Past Medical Expenses Pre Text",
  },
  [CASE_SECTIONS.PAST_MEDICAL_EXPENSES_POST_TEXT]: {
    content: null,
    display: "Past Medical Expenses Post Text",
  },
  [CASE_SECTIONS.PAST_AND_FUTURE_PAIN_AND_SUFFERING_PRE_TEXT]: {
    content: "pre_text_json",
    display: "Past and Future Pain and Suffering Expenses Pre Text",
  },
  [CASE_SECTIONS.PAIN_AND_SUFFERING_PLAINTIFF_DETAILS]: {
    content: "plaintiff_details_json",
    display: "Pain and Suffering Plainitff Details",
  },
  [CASE_SECTIONS.PAST_AND_FUTURE_PAIN_AND_SUFFERING_POST_TEXT]: {
    content: "post_text_json",
    display: "Past and Future Pain and Suffering Expenses Post Text",
  },
  [CASE_SECTIONS.PER_DIEM_ANALYSIS_PRE_TEXT]: {
    content: null,
    display: "Per Diem Analysis Pre Text",
  },
  [CASE_SECTIONS.PER_DIEM_ANALYSIS_POST_TEXT]: {
    content: null,
    display: "Per Diem Analysis Post Text",
  },
  [CASE_SECTIONS.VERDICT_ANALYSIS_PRE_TEXT]: {
    content: null,
    display: "Verdict Analysis Pre Text",
  },
  [CASE_SECTIONS.VERDICT_ANALYSIS_POST_TEXT]: {
    content: null,
    display: "Verdict Analysis Post Text",
  },
  [CASE_SECTIONS.SUMMARY_OF_INJURIES_PRE_TEXT]: {
    content: null,
    display: "Summary of Injuries Pre Text",
  },
}

export class CaseServiceDeserializer {
  static attributeValueFromJSON({
    attribute_id: id,
    value_id: valueId,
  }: CaseAttributeValueDto): CaseAttributeValue {
    return { id, valueId }
  }

  static attributeValuesFromJSON(attributeValues: CaseAttributeValueDto[]): AttributeFiltersData {
    return attributeValues.reduce(
      (values, value) => ({
        ...values,
        [value.attribute_id]: value.value_id,
      }),
      {}
    )
  }

  static fromJSON({ attributes, templated_sections, ...caseData }: CaseDto): CaseInfo {
    return {
      ...BaseServiceDeserializer.fromJSON(caseData),
      attributeValues: CaseServiceDeserializer.attributeValuesFromJSON(attributes),
      templatedSections: templated_sections.map(BaseServiceDeserializer.fromJSON),
    }
  }

  static templatedSectionDataFromJSON({
    section,
    content,
  }: {
    section: CaseTemplatedSectionDto
    content: Nullable<EditorRoot<false>>
  }): CaseTemplatedSection {
    const userContent = isEqual(content, DEFAULT_VALUE) ? null : content

    return {
      id: section.pk,
      caseId: section.case_id,
      section: section.section,
      content: new EditorContent(
        userContent ??
          section.custom_content ??
          section.template?.content ??
          (DEFAULT_VALUE as EditorRoot<false>)
      ),
      template: new EditorContent(section.template?.content ?? (DEFAULT_VALUE as EditorRoot<false>)),
      userActionRequired: section.user_action_required,
      reviewRequestId: section.current_review_run,
    }
  }

  static templatedSectionFromJSON(data: CaseTemplatedSectionDto): CaseTemplatedSection {
    return CaseServiceDeserializer.templatedSectionDataFromJSON({
      section: data,
      content: null,
    })
  }

  static caseFactsFromJSON(data: CaseFactsDto): CaseFacts {
    const { templated_sections, ...caseFacts } = data
    const sections: CaseFacts["sections"] = {}

    for (const section of templated_sections) {
      const { content } = CASE_SECTION_TO_FIELDS_MAPPING[section.section]
      sections[section.section] = CaseServiceDeserializer.templatedSectionDataFromJSON({
        section,
        content: content ? data[content as KeyOfType<CaseFactsDto, Nullable<EditorRoot<false>>>] : null,
      })
    }

    return {
      ...caseFacts,
      sections,
      facts_json: caseFacts.facts_json ? new EditorContent(caseFacts.facts_json) : null,
      liability_details_json: caseFacts.liability_details_json
        ? new EditorContent(caseFacts.liability_details_json)
        : null,
    }
  }
  static casePainAndSufferingFromJSON(data: CasePainAndSufferingDto): CasePainAndSuffering {
    const { templated_sections, ...casePainAndSuffering } = data
    const sections: CasePainAndSuffering["sections"] = {}

    for (const section of templated_sections) {
      const { content } = CASE_SECTION_TO_FIELDS_MAPPING[section.section]

      sections[section.section] = CaseServiceDeserializer.templatedSectionDataFromJSON({
        section,
        content: content
          ? data[content as KeyOfType<CasePainAndSufferingDto, Nullable<EditorRoot<false>>>]
          : null,
      })
    }
    return {
      ...casePainAndSuffering,
      sections,
      pre_text_json: casePainAndSuffering.pre_text_json
        ? new EditorContent(casePainAndSuffering.pre_text_json)
        : null,
      post_text_json: casePainAndSuffering.post_text_json
        ? new EditorContent(casePainAndSuffering.post_text_json)
        : null,
      plaintiff_details_json: casePainAndSuffering.plaintiff_details_json
        ? new EditorContent(casePainAndSuffering.plaintiff_details_json)
        : null,
    }
  }

  static caseIntroductionFromJSON(data: CaseIntroductionDto): CaseIntroduction {
    const { templated_sections, ...caseIntroduction } = data
    const sections: CaseIntroduction["sections"] = {}

    for (const section of templated_sections) {
      const { content } = CASE_SECTION_TO_FIELDS_MAPPING[section.section]

      sections[section.section] = CaseServiceDeserializer.templatedSectionDataFromJSON({
        section,
        content: content
          ? data[content as KeyOfType<CaseIntroductionDto, Nullable<EditorRoot<false>>>]
          : null,
      })
    }
    return {
      ...caseIntroduction,
      sections,
      content_json: caseIntroduction.content_json ? new EditorContent(caseIntroduction.content_json) : null,
    }
  }

  static caseConclusionFromJSON(data: CaseConclusionDto): CaseConclusion {
    const { templated_sections, ...caseConclusion } = data
    const sections: CaseConclusion["sections"] = {}

    for (const section of templated_sections) {
      const { content } = CASE_SECTION_TO_FIELDS_MAPPING[section.section]

      sections[section.section] = CaseServiceDeserializer.templatedSectionDataFromJSON({
        section,
        content: content ? data[content as KeyOfType<CaseConclusionDto, Nullable<EditorRoot<false>>>] : null,
      })
    }

    return {
      ...caseConclusion,
      sections,
      content_json: caseConclusion.content_json ? new EditorContent(caseConclusion.content_json) : null,
    }
  }

  static plaintiffsFromJSON(data: PlaintiffDto[]): Plaintiff[] {
    return data.map(plaintiff => {
      return CaseServiceDeserializer.plaintiffFromJSON(plaintiff)
    })
  }

  static plaintiffFromJSON(data: PlaintiffDto): Plaintiff {
    return {
      firstName: data.first_name,
      lastName: data.last_name,
      id: data.pk,
      futureExpensesText: data.future_expenses_text,
    }
  }
}

export class CaseServiceSerializer {
  static addTitlePageFromJSON(data: { addTitlePage: boolean }): CaseAddTitlePageDto {
    return {
      add_title_page: data.addTitlePage,
    }
  }

  static attributeValuesToJSON(data: AttributeFiltersData): CaseAttributeValuesDto {
    return {
      attribute_ids: Object.values(data).filter((value): value is AttributeValue["id"] => !isNil(value)),
    }
  }

  static templatedSectionToJSON(data: CaseTemplatedSection): CaseFactsSectionUpdateDto {
    const isTemplateEmpty = isEqual(data.template.children, DEFAULT_VALUE)
    const isContentEqual = isEqual(data.content.children, data.template.children)
    const shouldSaveCustomContent = !isContentEqual || isTemplateEmpty
    const content = shouldSaveCustomContent ? data.content.children : null

    return {
      pk: data.id,
      case_id: data.caseId,
      section: data.section,
      custom_content: content,
    }
  }

  static providerTemplatedSectionToJSON(data: {
    templateId: Nullable<PrimaryKey>
  }): ProviderTemplateUpdataDto {
    return { template_id: data.templateId }
  }

  static resolvedTemplatedSectionToJSON(data: CaseTemplatedSection): CaseFactsSectionUpdateDto {
    return {
      pk: data.id,
      case_id: data.caseId,
      section: data.section,
      custom_content: data.content.children,
    }
  }

  static resolvedProviderTemplatedSectionToJSON(
    data: ProviderTemplatedSectionOverride
  ): ProviderTemplatedSectionUpdateDto {
    return {
      custom_content: data.customContent,
    }
  }

  static caseTemplateToJSON(
    sections: Partial<Record<CASE_SECTIONS, CaseTemplatedSection>> | undefined
  ): CaseSectionTemplateContent & {
    templated_sections: CaseFactsSectionUpdateDto[]
  } {
    if (!sections) {
      return { templated_sections: [] }
    }
    const templatedSections: CaseFactsSectionUpdateDto[] = []

    const templateContents: CaseSectionTemplateContent = {}
    for (const section of Object.values(sections)) {
      const { content } = CASE_SECTION_TO_FIELDS_MAPPING[section.section]

      templatedSections.push(CaseServiceSerializer.templatedSectionToJSON(section))
      if (content != null) {
        templateContents[content] = section.content.children
      }
    }

    return {
      ...templateContents,
      templated_sections: templatedSections,
    }
  }

  static caseEditorFieldsToJSON<T extends BaseObject>(fields: T): EditorFields<T> {
    const result: EditorFields<T> = {}

    for (const section of Object.values(CASE_SECTIONS)) {
      const field = CASE_SECTION_TO_FIELDS_MAPPING[section].content

      if (field && field in fields) {
        const content = fields[field as keyof T] as EditorRoot | EditorContent | null | undefined

        if (content === undefined) continue

        result[field as keyof EditorFields<T>] =
          content instanceof EditorContent ? content.children : (content as Nullable<EditorRoot<false>>)
      }
    }

    return result
  }

  static caseFactsToJSON(data: UpdatedCaseFacts): CaseFactsUpdateDto {
    const { sections, ...caseFactsData } = data
    const caseFacts = caseFactsData as Omit<CaseFactsUpdateDto, "templated_sections">

    return {
      ...caseFacts,
      ...CaseServiceSerializer.caseEditorFieldsToJSON(caseFactsData),
      ...CaseServiceSerializer.caseTemplateToJSON(sections),
    }
  }

  static casePainAndSufferingToJSON(data: UpdatedCasePainAndSuffering): CasePainAndSufferingUpdateDto {
    const { sections, ...casePainAndSufferingData } = data
    const casePainAndSuffering = casePainAndSufferingData as unknown as Omit<
      CasePainAndSufferingUpdateDto,
      "templated_sections"
    >

    return {
      ...casePainAndSuffering,
      ...CaseServiceSerializer.caseEditorFieldsToJSON(casePainAndSufferingData),
      ...CaseServiceSerializer.caseTemplateToJSON(sections),
    }
  }

  static caseIntroductionToJSON(data: UpdatedCaseIntroduction): CaseIntroductionUpdateDto {
    const { sections, ...caseIntroductionData } = data
    const caseIntroduction = caseIntroductionData as Omit<CaseIntroductionUpdateDto, "templated_sections">

    return {
      ...caseIntroduction,
      ...CaseServiceSerializer.caseEditorFieldsToJSON(caseIntroductionData),
      ...CaseServiceSerializer.caseTemplateToJSON(sections),
    }
  }

  static caseConclusionToJSON(data: UpdatedCaseConclusion): CaseConclusionUpdateDto {
    const { sections, ...caseConclusionData } = data
    const caseConclusion = caseConclusionData as Omit<CaseConclusionUpdateDto, "templated_sections">

    return {
      ...caseConclusion,
      ...CaseServiceSerializer.caseEditorFieldsToJSON(caseConclusionData),
      ...CaseServiceSerializer.caseTemplateToJSON(sections),
    }
  }
}
