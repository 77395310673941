import { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import { StytchLogin } from "@stytch/react"
import { makeStyles } from "tss-react/mui"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { createLitifyRequest } from "api"
import { useTheme } from "@mui/material/styles"
import { OAuthProviders, Products } from "@stytch/vanilla-js"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"

const useStyles = makeStyles()(theme => ({
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    gap: theme.spacing(3),
    margin: theme.spacing(4, 0),
  },
  wrapper: {
    display: "grid",
    placeItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  contact: {
    marginTop: theme.spacing(1),
  },
  dividerWrapper: {
    padding: theme.spacing(1, 0),
  },
  error: {
    fontFamily: "monospace",
    fontSize: "0.8rem",
    marginTop: theme.spacing(1),
  },
}))

export default function Litify() {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [requiresAuth, setRequiresAuth] = useState(false)
  const matterId = params.get("matterId")
  let carrierId = params.get("carrierId")
  if (carrierId == "null") {
    carrierId = ""
  }
  const dest = params.get("dest")
  const firmId = params.get("firmId")
  const token = params.get("token")
  const env = params.get("env")
  const theme = useTheme()
  const domain = window.location.origin
  let path = `${domain}/litify?matterId=${matterId}&carrierId=${carrierId}`
  if (firmId) {
    path = path + `&firmId=${firmId}`
  }
  if (env) {
    path = path + `&env=${env}`
  }

  const { mutate: createRequest } = useMutation(createLitifyRequest, {
    onSuccess: data => {
      amplitudeApm.trackEvent(
        new RequestAnalyticEvent(RequestAnalyticsEventTypes.CreatedRequestFromIntegration, {
          integration_source: "litify",
          plaintiff_name: data.plaintiff_name,
          request_id: data.questionnaire_id,
        })
      )
      if (dest == "sdr") {
        navigate(`/requests/`)
      } else if (data.view === true) {
        navigate(`/requests/${data.questionnaire_id}`)
      } else {
        navigate(`/requests/${data.questionnaire_id}/edit`)
      }
    },
    onError: data => {
      if ([401, 403].includes(data.response.status)) {
        setRequiresAuth(true)
      } else {
        navigate(`/requests/`)
      }
    },
  })

  const stytchProps = {
    products: [Products.oauth],
    oauthOptions: {
      loginRedirectURL: path,
      providers: [
        {
          type: OAuthProviders.Salesforce,
          custom_scopes: ["id", "api", "refresh_token"],
        },
      ],
    },
  }

  const overrideStyles = {
    fontFamily: '"Inter", "Manrope", "Helvetica New", Helvetica, sans-serif',
    width: "321px",
    primaryColor: theme.palette.secondary.main,
    primaryTextColor: theme.palette.primary.dark,
    hideHeaderText: true,
  }

  useEffect(() => {
    createRequest({
      matterId: matterId,
      carrierId: carrierId,
      token: token,
      firmId: firmId,
      env: env,
      dest: dest,
    })
  }, [matterId, carrierId, token, firmId, env, dest, createRequest])

  if (requiresAuth) {
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <Box className={classes.loginWrapper}>
            <Box className={classes.loginContainer}>
              <Typography variant="h4" component="h1">
                Access to Litify Data required
              </Typography>
              <Box className={classes.copyContainer}>
                <Typography variant="body1" component="p" className={classes.copyMain}>
                  To create your EvenUp Request, we need access to your Litify data. Click &lsquo;Allow
                  Access&lsquo; in the following screen after logging in.
                </Typography>
              </Box>
              <StytchLogin config={stytchProps} styles={overrideStyles} />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <Typography>Importing case to EvenUp</Typography>
          <CircularProgress />
        </Box>
      </Box>
    )
  }
}
