import { Editor, Path, Range, Transforms } from "slate"
import { createListItem } from "../../create"
import { CustomEditor, ListItemElement } from "../../CustomEditor"
import { getCursorPositionInNode } from "../cursor"
import { decreaseListItemDepth } from "./decreaseListItemDepth"
import { isInList, isListItem } from "./queries"

interface SplitListItemOptions {
  keepSelection?: boolean
  keepInList?: boolean
  force?: boolean
}

export function splitListItem(editor: CustomEditor, options?: SplitListItemOptions): void {
  const currentSelection = editor.selection
  const keepSelection = Boolean(options?.keepSelection)

  splitListItemInternal(editor, Boolean(options?.force), Boolean(options?.keepInList))

  if (currentSelection && keepSelection) {
    Transforms.select(editor, currentSelection)
  }
}

function splitListItemInternal(editor: CustomEditor, force: boolean, keepInList: boolean): void {
  if (!editor.selection) {
    return
  }

  if (Range.isExpanded(editor.selection)) {
    Transforms.delete(editor)
  }

  if (!isInList(editor)) {
    return
  }

  if (force) {
    return Transforms.splitNodes(editor, {
      at: editor.selection,
      match: node => isListItem(editor, node),
      mode: "lowest",
    })
  }

  const [[listItemNode, listItemPath]] = Editor.nodes<ListItemElement>(editor, {
    match: node => isListItem(editor, node),
    mode: "lowest",
  })
  const listItemContentPath = [...listItemPath, 0]
  const { isEnd, isStart } = getCursorPositionInNode(editor, editor.selection.anchor, listItemContentPath)

  if (isStart && isEnd) {
    if (!keepInList || isInList(editor, Path.parent(listItemPath))) {
      return decreaseListItemDepth(editor, listItemPath)
    }
  }

  if (isStart) {
    return Transforms.insertNodes(editor, createListItem(), { at: listItemPath })
  }

  if (isEnd) {
    const newListItemPath = Path.next(listItemPath)
    Transforms.insertNodes(editor, createListItem(), { at: newListItemPath })
    Transforms.select(editor, newListItemPath)

    const [, nestedList] = listItemNode.children

    if (!nestedList) return

    return Transforms.moveNodes(editor, {
      at: [...listItemPath, 1],
      to: [...newListItemPath, 1],
    })
  }

  Transforms.splitNodes(editor, {
    at: editor.selection,
    match: node => isListItem(editor, node),
    mode: "lowest",
  })
}
