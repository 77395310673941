import { Control, Controller, FieldValues } from "react-hook-form"
import Radio from "@mui/material/Radio"
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import { makeStyles } from "tss-react/mui"
import { FieldPathWithValue } from "../types/helper"

const useStyles = makeStyles()(theme => ({
  label: {
    marginBottom: theme.spacing(1),
  },
}))

interface RadioButtonsProps<TFields extends FieldValues, TValue> extends RadioGroupProps {
  control: Control<TFields>
  name: FieldPathWithValue<TFields, TValue>
  options: ValueOptions<TValue>
  label?: Nullable<string>
  formControlClassName?: string
}

export function RadioButtons<TFields extends FieldValues, TValue>({
  control,
  name,
  options,
  label = null,
  formControlClassName = undefined,
  ...inputProps
}: RadioButtonsProps<TFields, TValue>): JSX.Element {
  const { classes } = useStyles()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl component="fieldset" className={formControlClassName} variant="standard">
          {label && (
            <FormLabel id={name} className={classes.label}>
              {label}
            </FormLabel>
          )}
          <RadioGroup {...inputProps} {...field} data-test={`radio-group-${name}`}>
            {options?.map(option => (
              <FormControlLabel
                key={option.key as unknown as string}
                control={<Radio color="secondary" />}
                label={option.display}
                value={option.key}
                data-test={`radio-option-${option.key}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  )
}
