import React, { useState } from "react"
import { ArrowDropDown, ArrowDropUp, Close } from "@mui/icons-material"
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteRenderOptionState,
  createFilterOptions,
} from "@mui/material/Autocomplete"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Popover from "@mui/material/Popover"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { MultiSelectAutoCompleteProps, OptionProps } from "./types"
import PopperComponent from "evenup-ui/MultiSelectAutoComplete/PopperComponent"
import { Checkbox, CircularProgress, FormControlLabel, ListItem } from "@mui/material"
import { theme } from "app/theme"
import { ClearButton } from "./ClearButton"
import { MultiSelectAutoCompleteWrapper } from "./MultiSelectAutoCompleteWrapper"
import { StyledBoxPopperWrapper, StyledTextFieldSearch } from "./styled"
import { noop } from "lodash"
import { ListboxComponent } from "./VirtualList"

export const SELECT_ALL_OPTION = "select-all"

export const MultiSelectAutoComplete = <T extends OptionProps>({
  label = "",
  name = "",
  options = [],
  enableClear,
  noOptionsText,
  autoCompletePlaceholderText,
  value = [],
  onChangeValue,
  onClear,
  disableSearch,
  optionRender,
  dataTest,
  className = "",
  size = "medium",
  readOnly,
  popperWidth = "auto",
  selectAllEnabled = true,
  virtualList = false,
  ...autocompleteProps
}: MultiSelectAutoCompleteProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLInputElement>>(null)
  const [sortedOptions, setSortedOptions] = useState<T[]>([])
  const isPopoverOpen = !!anchorEl

  const handleClose = () => {
    if (anchorEl) anchorEl.focus()
    setAnchorEl(null)
  }

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (readOnly) return
    event.stopPropagation()
    onClear && onClear()
  }

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: T) => option.label,
  })

  const handleAutoCompleteClose = (
    event: React.SyntheticEvent<Element, Event>,
    reason: AutocompleteCloseReason
  ) => {
    return reason === "escape" && handleClose()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePopoverClose = (_event: any, reason: "escapeKeyDown" | "backdropClick") => {
    reason === "escapeKeyDown" && handleClose()
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget)

    const newOptions = options
      .map(option => ({
        ...option,
        isSelected: value.some(({ value }) => value === option.value),
      }))
      .sort((a: T, b: T) => Number(b.isSelected) - Number(a.isSelected))

    setSortedOptions(
      selectAllEnabled && newOptions.length > 0
        ? [
            {
              label: "Select All",
              value: SELECT_ALL_OPTION,
            } as T,
            ...newOptions,
          ]
        : newOptions
    )
  }

  const handleValueChange = (
    _event: React.SyntheticEvent,
    value: T[],
    _reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => {
    if (!onChangeValue) return

    if (details?.option.value === SELECT_ALL_OPTION) {
      const selectAll = value.length !== options.length + 1
      onChangeValue(selectAll ? options : [], details.option)
      return
    }

    onChangeValue(value, details?.option)
  }

  const optionRenderer = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    { selected }: AutocompleteRenderOptionState
  ) => {
    const isChecked = option.value === SELECT_ALL_OPTION && options.length === value.length ? true : selected

    const label = optionRender && option.value !== SELECT_ALL_OPTION ? optionRender(option) : option.label

    return (
      <ListItem
        {...props}
        key={option.value}
        sx={{
          borderBottom: option.value === SELECT_ALL_OPTION ? "1px solid rgba(0,0,0,0.12)" : "",
        }}
        component={virtualList ? "div" : "li"}
        data-test={option.label}
      >
        <FormControlLabel
          control={
            <Checkbox
              sx={{ marginRight: theme.spacing(1), marginLeft: theme.spacing(0.5) }}
              size={size}
              checked={isChecked}
            />
          }
          label={label}
          sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis", pointerEvents: "none" }}
        />
      </ListItem>
    )
  }

  return (
    <>
      <MultiSelectAutoCompleteWrapper isClearable={value.length > 0}>
        <TextField
          data-test={dataTest || label.toLowerCase()}
          sx={{ minWidth: "150px", width: "100%" }}
          onClick={!autocompleteProps.disabled ? handlePopoverOpen : noop}
          aria-describedby="multi-select-auto-complete"
          label={label}
          name={name}
          size={size}
          variant="outlined"
          disabled={autocompleteProps.disabled}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end" sx={{ margin: "0px" }}>
                {autocompleteProps.loading && <CircularProgress size="16px" />}
                {!readOnly && (
                  <>
                    {enableClear && (
                      <ClearButton onClick={handleClear} data-test="clear-button">
                        <Close aria-hidden={true} sx={{ fontSize: "1.25rem" }} />
                      </ClearButton>
                    )}
                    <IconButton sx={{ padding: "2px" }}>
                      {isPopoverOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                    </IconButton>
                  </>
                )}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start" sx={{ margin: "0px", maxWidth: "calc(100% - 50px)" }}>
                {value.length >= 1 ? (
                  <Stack direction="row" spacing={1} alignItems="center" sx={{ overflow: "hidden" }}>
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{
                        maxWidth: value.length > 1 ? "calc(100% - 60px)" : undefined,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {value[0].label}
                    </Typography>
                    {value.length > 1 && (
                      <Typography variant="body1" component="p">
                        + {value.length - 1} more
                      </Typography>
                    )}
                  </Stack>
                ) : (
                  <Typography variant="body1" component="p">
                    None
                  </Typography>
                )}
              </InputAdornment>
            ),
          }}
        />
      </MultiSelectAutoCompleteWrapper>
      <Popover
        id="multi-select-auto-complete"
        open={isPopoverOpen && !readOnly}
        anchorEl={anchorEl}
        className={className}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <StyledBoxPopperWrapper width={popperWidth}>
            <Autocomplete
              disableCloseOnSelect
              filterOptions={filterOptions}
              getOptionLabel={(option: T) => option.label}
              isOptionEqualToValue={(option: T, value: T) => {
                return option.value === value.value
              }}
              multiple
              noOptionsText={noOptionsText ?? "No options"}
              onClose={handleAutoCompleteClose}
              open
              size={size}
              options={sortedOptions}
              onChange={handleValueChange}
              PopperComponent={PopperComponent}
              renderInput={params => (
                <StyledTextFieldSearch
                  label="Search"
                  size={size}
                  placeholder={autoCompletePlaceholderText}
                  ref={params.InputProps.ref}
                  inputProps={{ ...params.inputProps, "data-test": "search-input" }}
                  disableSearch={disableSearch}
                  autoFocus
                />
              )}
              renderOption={optionRenderer}
              renderTags={() => null}
              value={value}
              ListboxComponent={virtualList ? ListboxComponent : undefined}
              {...autocompleteProps}
            />
          </StyledBoxPopperWrapper>
        </ClickAwayListener>
      </Popover>
    </>
  )
}
