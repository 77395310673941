import { useCallback, useId, useState } from "react"
import { formatDate } from "utils"
import { useExhibitsPreview } from "documents/exhibits/useExhibitPreview"
import { ExhibitCitation } from "common/citation/ExhibitCitation"
import { documentStoreSelectors, useDocumentStore } from "documents/store"
import { KeyboardArrowDown } from "@mui/icons-material"
import { AddIcdCodeButton } from "./AddIcdCodeButton"
import {
  CollapseButton,
  Container,
  DateLink,
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  ReferenceChip,
} from "./styled"

const getAppointmentSearchParam = (appointmentId: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search)

  urlSearchParams.set("appointment", appointmentId)

  return urlSearchParams.toString()
}

export function IcdCodesTable() {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const tableId = useId()
  const icdCodes = useDocumentStore(documentStoreSelectors.icdCodes)

  const preview = useExhibitsPreview()

  const handleCollapseClick = useCallback(() => {
    setIsCollapsed(collapsed => !collapsed)
  }, [])

  if (!icdCodes.length) {
    return
  }

  return (
    <Container>
      <AddIcdCodeButton />
      <CollapseButton
        aria-label="close table"
        onClick={handleCollapseClick}
        isCollapsed={isCollapsed}
        aria-expanded={!isCollapsed}
        aria-controls={tableId}
      >
        <KeyboardArrowDown />
      </CollapseButton>
      <Table>
        <TableHead hideBorder={isCollapsed}>
          <Row hideBorder>
            <TableHeader>ICD Code</TableHeader>
            <TableHeader>Description</TableHeader>
            <TableHeader>First Diagnosed</TableHeader>
            <TableHeader>Reference</TableHeader>
          </Row>
        </TableHead>
        <TableBody
          data-test="icd-code-table"
          isCollapsed={isCollapsed}
          id={tableId}
          aria-hidden={isCollapsed}
        >
          {icdCodes.map((icdCode, index) => {
            return (
              <Row key={icdCode.code} hideBorder={isCollapsed}>
                <Cell data-test="code">
                  <ReferenceChip label={icdCode.code} />
                </Cell>
                <Cell>{icdCode.description}</Cell>
                <Cell>
                  <DateLink to={{ search: getAppointmentSearchParam(icdCode.appointmentId) }}>
                    {formatDate(icdCode.dateOfService, "MM/dd/yyyy", true)}
                  </DateLink>
                </Cell>
                <Cell>
                  <ExhibitCitation
                    key={index}
                    index={icdCode.reference.exhibitIndex}
                    pages={[icdCode.reference.page]}
                    onClick={() => preview(icdCode.reference.exhibitId, icdCode.reference.page)}
                  />
                </Cell>
              </Row>
            )
          })}
        </TableBody>
      </Table>
    </Container>
  )
}
