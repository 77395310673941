import { usePermissions } from "permissions/usePermissions"
import useCase from "./useCase"
import { CaseId } from "demand/Files/types"
import * as Sentry from "@sentry/browser"

interface UseMultiPlaintiffDemandGeneratorOptions {
  suspense?: boolean
}

export function useMultiPlaintiffDemandGenerator(
  caseId: CaseId,
  { suspense }: UseMultiPlaintiffDemandGeneratorOptions = { suspense: false }
): boolean {
  const { multiPlaintiffEnabled } = usePermissions({ suspense })
  const { caseObj } = useCase(caseId)

  if (!caseId) {
    Sentry.captureMessage("useMultiPlaintiffDemandGenerator called without a caseId", "warning")
    return false
  }

  return multiPlaintiffEnabled && !!caseObj?.document_id
}
