import { deserializeSummary } from "../summaries/serializers"
import { DOSSummary, DOSSummaryDto, UserExhibitDto, UserExhibitResponse } from "./types"

const deserializeDOSSummary = (dosSummary: DOSSummaryDto): DOSSummary => {
  return {
    pk: dosSummary.pk,
    summary: dosSummary.summary && deserializeSummary(dosSummary.summary),
    error: dosSummary.error,
  }
}

export class DOSSummaryDeserializer {
  static fromJSON(data: DOSSummaryDto): DOSSummary {
    return deserializeDOSSummary(data)
  }
}

export class UserExhibitSerializer {
  static fromJSON(data: UserExhibitDto): UserExhibitResponse {
    return {
      url: data.url,
      processingStatus: data.processing_status,
      error: data.error,
      name: data.name,
    }
  }
}
