import { COMPONENTS_KEYS } from "documents/constants"

export enum VIEW_MED_CHRON_SECTIONS {
  TREATMENT_TIMELINE = "Treatment Timeline",
  TREATMENT_CALENDAR = "Treatment Calendar",
  DIAGNOSTIC_HIGHLIGHTS = "Diagnostic Highlights",
  FLAGS = "Flags",
  PAST_MEDICAL_VISITS = "Past Medical Visits",
  MEDICAL_SUMMARIES = "Medical Summaries",
  EXHIBIT_LIST = "Exhibit List",
}

export const VIEW_MED_CHRON_SECTION_KEYS: Record<COMPONENTS_KEYS, VIEW_MED_CHRON_SECTIONS | null> = {
  [COMPONENTS_KEYS.TREATMENT_CALENDAR]: VIEW_MED_CHRON_SECTIONS.TREATMENT_CALENDAR,
  [COMPONENTS_KEYS.TREATMENT_TIMELINE]: VIEW_MED_CHRON_SECTIONS.TREATMENT_TIMELINE,
  [COMPONENTS_KEYS.FLAGS]: VIEW_MED_CHRON_SECTIONS.FLAGS,
  [COMPONENTS_KEYS.PROVIDER_SUMMARY_TABLE]: VIEW_MED_CHRON_SECTIONS.PAST_MEDICAL_VISITS,
  [COMPONENTS_KEYS.ICD_CODE_TABLE]: VIEW_MED_CHRON_SECTIONS.DIAGNOSTIC_HIGHLIGHTS,
  [COMPONENTS_KEYS.APPOINTMENT_SUMMARY_TABLE]: VIEW_MED_CHRON_SECTIONS.MEDICAL_SUMMARIES,
  [COMPONENTS_KEYS.EXHIBIT_LIST]: VIEW_MED_CHRON_SECTIONS.EXHIBIT_LIST,

  // Demand Keys, not used right now
  [COMPONENTS_KEYS.OBJECTIVE_TESTS_TABLE]: null,
  [COMPONENTS_KEYS.INTERVENTIONAL_TREATMENTS_TABLE]: null,
}
