import * as amplitude from "@amplitude/analytics-browser"
import { permissionService } from "api/services/permissions"
import { USER_ROLES } from "common/models/roles"
import { User } from "common/models/user"
import { BaseAnalyticsEvent, BaseAnalyticsService, BaseInfrastructureService } from "infrastructure/apm/types"
import { isControlledEnvironment, isDevEnvironment } from "infrastructure/env/getEnvironment"
import { queryKeys } from "react-query/constants"
import { queryClient } from "react-query/queryClient"
import { getAPIServerURL } from "utils"

let isInitialized = false

const isCorrectEnv =
  !isControlledEnvironment() && (isDevEnvironment() || !getAPIServerURL().includes("localhost"))
const isEnabled =
  isCorrectEnv && permissionService.getCachedPermissions()?.disableTelemetryAmplitudeEnabled !== true
const isImpersonating = () => queryClient.getQueryData<User>([queryKeys.session])?.impersonator

function withEnv(callback: () => void): void {
  if (isEnabled && !isImpersonating()) {
    try {
      callback()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}

function init(): void {
  withEnv(() => {
    const amplitudeApiKey = import.meta.env.VITE_AMPLITUDE_API_KEY
    if (!amplitudeApiKey) {
      return
    }
    amplitude.init(amplitudeApiKey, { defaultTracking: true })
    isInitialized = true
  })
}

/***
 *  Looking to add a new event?
 *  - Event domains are in the /events folder
 *  - Event typings live in AnalyticsEventDetails in those files
 ***/
function trackEvent(event: BaseAnalyticsEvent): void {
  withEnv(() => {
    if (!isInitialized) {
      init()
    }

    amplitude.track(event.name, event.fields)
  })
}

function setUserContext(user: User): void {
  withEnv(() => {
    if (!isInitialized) {
      init()
    }
    amplitude.setUserId(`${user.stytchUserId}`)

    const identifyEvent = new amplitude.Identify()
      .set("name", `${user.firstName} ${user.lastName}`)
      .set("email", `${user.email}`)
      .set("firmId", `${user.firmId}`)
      .set("roleId", `${user.role}`)
      .set("roleName", `${USER_ROLES[user.role].display}`)
      .set("uid", `${user.id}`)

    amplitude.identify(identifyEvent)
  })
}

function resetUserContext(): void {
  withEnv(() => {
    if (!isInitialized) {
      init()
    }
    amplitude.setUserId(undefined)
  })
}

export const amplitudeApm: BaseInfrastructureService & BaseAnalyticsService = {
  init,
  setUserContext,
  resetUserContext,

  trackEvent,
  // Performance and Page views are enabled automatically when defaultTracking is true
  trackPageView: () => {},
  trackPerformance: () => {},
}
