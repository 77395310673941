import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from "react"

import { v4 } from "uuid"

import useUser from "hooks/useUser"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { DUPLICATE_UPLOAD_MATCH } from "common/file-uploader/constants"

import { FileUploaderContext } from "./context"
import { createUploadFlow } from "./upload-flow/file"
import { fileUploaderReducer } from "./uploader-state/reducer"
import {
  getChunkedFiles,
  getUploadError,
  getUploadFailureReason,
  getUploadIdFromState,
  getUploadInfo,
  getUploadWarning,
  hasDuplicates,
} from "./utils"
import { FileUploaderItem } from "./uploader-state/types"

type FileUploadResult =
  | {
      success: true
      duplicate?: false
      name: string
      uploadId: string
      reason?: undefined
    }
  | {
      success: false
      duplicate?: boolean
      name: string
      uploadId: null
      reason?: string | Error
    }

type UploadFilesResult = {
  hasFailures: boolean
  items: FileUploadResult[]
}

type UploadFilesReturn = Promise<UploadFilesResult>
type UploadFiles = (
  files: File[],
  options?: {
    skipInfoMessages?: boolean
  }
) => UploadFilesReturn
type DuplicatedFile = (file: File) => boolean

export type FileUploadErrorResult = Pick<FileUploadResult, "name" | "success" | "reason">

export interface UseFileUploaderReturn {
  uploadFiles: UploadFiles
  isDuplicateFile: DuplicatedFile
  cancelUpload: () => void
  assignUploaded: (uploadId: string, externalId: PrimaryKey) => void
  unassignUploaded: (externalId: PrimaryKey) => void
  isUploading: boolean
  showUploadErrors: (results: FileUploadErrorResult[]) => void
  fileStates: Partial<Record<string, FileUploaderItem>>
}

export function useFileUploader(): UseFileUploaderReturn {
  const { user } = useUser()
  const { showMessages } = useHandleMessages()
  const [fileStates, dispatch] = useReducer(fileUploaderReducer, {})
  const uploadFlow = useMemo(() => createUploadFlow(dispatch), [])
  const actualState = useRef(fileStates)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const uploadingStack = useRef<string[]>([])
  const { updateState } = useContext(FileUploaderContext)
  const [, sync] = useState(Date.now())

  const stateSyncRequests = useRef<(() => void)[]>([])

  const startUpload = useCallback((): (() => void) => {
    const id = v4()
    uploadingStack.current.push(id)
    setIsUploading(true)

    return () => {
      uploadingStack.current = uploadingStack.current.filter(uploadingId => uploadingId !== id)
      setIsUploading(uploadingStack.current.length > 0)
    }
  }, [uploadingStack, setIsUploading])

  useEffect(() => {
    actualState.current = fileStates
    updateState(fileStates)
  }, [fileStates, actualState, updateState])

  useEffect(() => {
    return () => {
      uploadFlow.cancel()
      updateState(fileUploaderReducer(actualState.current, { type: "UNASSIGN_FILES", payload: {} }))
    }
  }, [actualState, updateState, uploadFlow])

  useEffect(() => {
    while (stateSyncRequests.current.length) {
      stateSyncRequests.current.pop()?.()
    }
  })

  const isDuplicateFile = useCallback<DuplicatedFile>(
    file => {
      return hasDuplicates(actualState.current, [file])
    },
    [actualState]
  )

  const uploadFiles = useCallback<UploadFiles>(
    async (files, options = {}) => {
      if (!files.length) {
        return {
          hasFailures: false,
          items: [],
        }
      }

      showMessages({ messages: [] })

      if (hasDuplicates(actualState.current, files)) {
        return {
          hasFailures: true,
          items: files
            .filter(file => hasDuplicates(actualState.current, [file]))
            .map<FileUploadResult>(file => ({
              success: false,
              duplicate: hasDuplicates(actualState.current, [file]),
              name: file.name,
              uploadId: null,
              reason: DUPLICATE_UPLOAD_MATCH,
            })),
        }
      }

      const finishUpload = startUpload()
      // Get files with chunks and upload
      const chunkedFiles = getChunkedFiles(files)
      const results = await Promise.allSettled(chunkedFiles.map(fileData => uploadFlow.run(fileData, {})))

      // Process upload results
      const ids = results.map(result => (result.status === "fulfilled" ? result.value : null))
      await new Promise<void>(resolve => {
        stateSyncRequests.current.push(resolve)
        sync(Date.now())
      })

      const uploadResults = ids.map<FileUploadResult>((id, idx) => {
        const uploadId = getUploadIdFromState(actualState.current, id)
        const info = getUploadInfo(actualState.current, id)
        if (info && !options.skipInfoMessages) {
          showMessages({
            messages: [getUploadWarning(files[idx].name, info)],
            append: true,
          })
        }

        if (uploadId) {
          return {
            success: true,
            uploadId,
            name: files[idx].name,
          }
        }

        const reason = getUploadFailureReason(actualState.current, id)?.reason ?? undefined

        return {
          success: false,
          uploadId: null,
          reason,
          name: files[idx].name,
        }
      })

      finishUpload()

      return {
        hasFailures: uploadResults.some(result => !result.success),
        items: uploadResults,
      }
    },
    [uploadFlow, actualState, startUpload, showMessages]
  )

  const cancelUpload = useCallback(() => {
    uploadFlow.cancel()
  }, [uploadFlow])

  const assignUploaded = useCallback(
    (uploadId: string, externalId: PrimaryKey) => {
      dispatch({ type: "ASSIGN_UPLOAD", payload: { uploadId, externalId } })
    },
    [dispatch]
  )

  const unassignUploaded = useCallback(
    (externalId: PrimaryKey) => {
      dispatch({ type: "UNASSIGN_FILES", payload: { externalId: externalId } })
    },
    [dispatch]
  )

  const showUploadErrors = useCallback(
    (results: FileUploadErrorResult[]) => {
      const failedUploads = results.filter(result => !result.success)

      if (failedUploads.length) {
        showMessages({
          messages: [
            {
              type: "error",
              message: (
                <>
                  Failed to upload files:
                  {failedUploads.map(failedUpload => (
                    <React.Fragment key={failedUpload.name}>
                      <div>{getUploadError(failedUpload.name, failedUpload.reason)}</div>
                    </React.Fragment>
                  ))}
                  {user.isExternal && (
                    <p>
                      If you experience continued upload issues, please contact legaloperations@evenuplaw.com
                    </p>
                  )}
                </>
              ),
            },
          ],
          append: true,
        })
      }
    },
    [showMessages, user.isExternal]
  )

  return {
    uploadFiles,
    isDuplicateFile,
    showUploadErrors,
    cancelUpload,
    assignUploaded,
    unassignUploaded,
    isUploading,
    fileStates,
  }
}
