import {
  TREATMENT_TIMELINE_INCIDENT_TYPE,
  TREATMENT_TIMELINE_INCIDENT_MOMENT_TYPE,
  IncidentType,
} from "./types"
import {
  StyledIncidentIndicator,
  StyledIncidentIndicatorText,
  StyledIncidentLine,
  StyledPriorIncidentIndicator,
} from "./styled"

interface TreatmentTimelineIncidentIndicatorProps {
  incident: IncidentType
}

export const TreatmentTimelineIncidentIndicator = ({
  incident,
}: TreatmentTimelineIncidentIndicatorProps): Nullable<JSX.Element> => {
  switch (incident.type) {
    case TREATMENT_TIMELINE_INCIDENT_TYPE.PRIOR: {
      return <StyledPriorIncidentIndicator />
    }
    case TREATMENT_TIMELINE_INCIDENT_MOMENT_TYPE: {
      return (
        <StyledIncidentIndicator width={`calc(${incident.percent}% + 1px)`}>
          <StyledIncidentLine>
            <StyledIncidentIndicatorText>
              <div>Date of incident</div>
              <div>{incident.text}</div>
            </StyledIncidentIndicatorText>
          </StyledIncidentLine>
        </StyledIncidentIndicator>
      )
    }
    default:
      return null
  }
}
