import { useCallback, useMemo } from "react"
import { NONE_VALUE, NONE_VALUE_OPTION } from "./utils"
import { AttributeSelect } from "./AttributeSelect"
import { CASE_SECTIONS } from "common/types/sections"
import { CASE_SECTION_TO_FIELDS_MAPPING } from "api/services/case/serializers"

interface SectionFilterProps {
  value: Nullable<CASE_SECTIONS>
  onChange: (section: Nullable<CASE_SECTIONS>) => void
  error?: boolean
  helperText?: string
  disabled?: boolean
}

export function SectionFilter({ value, onChange, ...rest }: SectionFilterProps): JSX.Element {
  const options = useMemo<ValueOptions<CASE_SECTIONS | typeof NONE_VALUE>>(() => {
    const sectionOptions = Object.values(CASE_SECTIONS).map(section => ({
      key: section,
      display: CASE_SECTION_TO_FIELDS_MAPPING[section].display,
    }))

    return [NONE_VALUE_OPTION, ...sectionOptions]
  }, [])

  const handleChange = useCallback(
    (nextValue: NonNullable<CASE_SECTIONS> | typeof NONE_VALUE) => {
      onChange(nextValue === NONE_VALUE ? null : (nextValue as Nullable<CASE_SECTIONS>))
    },
    [onChange]
  )
  return (
    <AttributeSelect
      value={value ?? NONE_VALUE_OPTION.key}
      label={"Template"}
      options={options}
      onChange={handleChange}
      required
      {...rest}
    />
  )
}
