import { useCallback } from "react"
import { BulletPointItem, BulletPointResult } from "api/services/summaries/types"
import { Alert, Box, Typography } from "@mui/material"
import FindInPage from "@mui/icons-material/FindInPage"
import { makeStyles } from "tss-react/mui"
import { getExhibitPreviewPath } from "../../../../../pdf-preview/utils"
import IconButton from "@mui/material/IconButton"
import { PreviewExhibitPayload } from "../../../../../message-broker/types"
import { MESSAGE_TOPIC } from "../../../../../message-broker/topics"
import { openWindow } from "../../../../../common/windows"
import { usePublish } from "../../../../../message-broker/usePublish"
import { isEmpty } from "lodash"

interface BulletPointSummaryProps {
  caseId: number
  bulletPoints: Nullable<BulletPointResult[]>
  parseError: boolean
}

const useStyles = makeStyles()(() => ({
  header: {
    textTransform: "capitalize",
    fontWeight: "700",
  },
  citationButton: {
    padding: "0",
  },
}))

const CitationButton = ({ caseId, exhibitId, partitionId, page }: PreviewExhibitPayload) => {
  const { classes } = useStyles()

  const publish = usePublish()

  const handleOpenPreview = useCallback(() => {
    const payload: PreviewExhibitPayload = {
      caseId: String(caseId),
      exhibitId,
      partitionId,
      page,
    }
    publish(MESSAGE_TOPIC.PREVIEW_EXHIBIT, payload).catch(() => {
      const path = getExhibitPreviewPath(payload)
      if (path) {
        openWindow(`${path.base}/${path.route}`)
      }
    })
  }, [publish, caseId, exhibitId, partitionId, page])
  return (
    <IconButton className={classes.citationButton} onClick={handleOpenPreview}>
      <FindInPage />
    </IconButton>
  )
}

const BulletPointSummary = ({ caseId, bulletPoints, parseError }: BulletPointSummaryProps) => {
  const { classes } = useStyles()

  return (
    <Box>
      {parseError && (
        <Alert variant="outlined" severity="error" sx={{ paddingX: 4, paddingY: 10 }}>
          There was an error parsing your summary information. Please contact eng team for more help.
        </Alert>
      )}
      {!parseError && bulletPoints === null && (
        <Alert variant="standard" severity="info" sx={{ paddingX: 4, paddingY: 10 }}>
          No summary information available. If there should be summary info please contact eng team for more
          help.
        </Alert>
      )}
      {!parseError &&
        bulletPoints &&
        bulletPoints.map((bulletPoint, sectionIndex) => {
          return (
            <Box key={`bulletsection-${sectionIndex}`}>
              <Typography className={classes.header}>{bulletPoint.header || "No Section Header"}</Typography>
              <Box component="ul" sx={{ margin: 0, paddingLeft: 2.5 }}>
                {isEmpty<BulletPointItem[]>(bulletPoint.values) && (
                  <li>
                    <i>Not Specified</i>
                  </li>
                )}
                {bulletPoint.values?.map((value, bulletIndex) => {
                  return (
                    <li key={`bulletsection-${sectionIndex}-bulletpoint-${bulletIndex}`}>
                      {value.text || "No Value"}
                      {value.citations.map((citation, citationIndex) => {
                        return (
                          <CitationButton
                            key={`bulletsection-${sectionIndex}-bulletpoint-${bulletIndex}-${citationIndex}`}
                            caseId={caseId}
                            exhibitId={citation.exhibit_id}
                            partitionId={citation.partition_id}
                            page={citation.page}
                          />
                        )
                      })}
                    </li>
                  )
                })}
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}

export default BulletPointSummary
