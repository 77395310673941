import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import MultiSelectAutoComplete, { MultiSelectAutoCompleteProps } from "evenup-ui/MultiSelectAutoComplete"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

export function Plaintiff() {
  const selectedPlaintiffs = useExhibitBuilderStore(useShallow(state => state.selectedPlaintiffs))
  const plaintiffs = useExhibitBuilderStore(useShallow(state => state.plaintiffs))
  const options = useMemo(
    () =>
      Object.values(plaintiffs).map(plaintiff => ({
        label: plaintiff.name,
        value: plaintiff.id,
      })),
    [plaintiffs]
  )

  const value = useMemo(
    () => options.filter(option => selectedPlaintiffs?.includes(option.value)),
    [selectedPlaintiffs, options]
  )

  const handleChange: MultiSelectAutoCompleteProps<(typeof options)[number]>["onChangeValue"] = options => {
    exhibitBuilderActions.selectPlaintiffs(options.map(option => option.value))
  }

  return (
    <MultiSelectAutoComplete
      size="small"
      label="Plaintiff"
      value={value}
      options={options}
      onChangeValue={handleChange}
    />
  )
}
