import React, { ChangeEvent, SyntheticEvent, WheelEvent, useCallback, useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import { DateField } from "common/form-components"
import { makeStyles } from "tss-react/mui"
import { Provider, ValidationErrors } from "demand/Providers/types"
import {
  Action,
  UPDATE_FIRST_CONTACT,
  UPDATE_LAST_CONTACT,
  UPDATE_VISIT_COUNT,
  UPDATE_ONGOING_APPOINTMENT,
} from "demand/Providers/reducer"

import { SectionHeading } from "../styled"
import Appointments from "../Annotation/Appointments/Appointments"
import { PartitionEntry } from "../Annotation/types"
import { useFormContext } from "demand/context"
import SectionContainer from "../SectionContainer"

interface Props {
  disabled: boolean
  provider: Provider
  dispatch: React.Dispatch<Action>
  validationErrors: ValidationErrors
  annotationDateUpdated: Nullable<string>
  onAnnotationClick: (entry: PartitionEntry) => void
}

const useStyles = makeStyles()(theme => ({
  appointmentControls: {
    display: "flex",
    gap: theme.spacing(2),
    flexWrap: "wrap",
    marginBottom: theme.spacing(1),
  },
  appointmentDate: {
    minWidth: "250px",
  },
}))

export const AppointmentSection = ({
  validationErrors,
  provider,
  disabled,
  dispatch,
  annotationDateUpdated,
  onAnnotationClick,
}: Props): JSX.Element => {
  const { classes } = useStyles()
  const [currentVisitCount, setCurrentVisitCount] = useState(Number(provider.visit_count ?? 0))
  const { caseId } = useFormContext()
  const hasPartitionProvider = !!provider.partition_provider

  const handleChangeFirstContact = useCallback(
    (date: Nullable<string>) => {
      dispatch({
        type: UPDATE_FIRST_CONTACT,
        payload: { providerId: provider.pk, firstContact: date },
      })
    },
    [provider, dispatch]
  )

  const handleChangeLastContact = useCallback(
    (date: Nullable<string>) => {
      dispatch({
        type: UPDATE_LAST_CONTACT,
        payload: { providerId: provider.pk, lastContact: date },
      })
    },
    [provider, dispatch]
  )

  const handleChangeVisitCount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setCurrentVisitCount(parseInt(event.target.value)),

    [setCurrentVisitCount]
  )

  const handleBlurVisitCount = useCallback(() => {
    dispatch({
      type: UPDATE_VISIT_COUNT,
      payload: { providerId: provider.pk, visitCount: Number(currentVisitCount) },
    })
  }, [currentVisitCount, dispatch, provider.pk])

  const handleChangeOngoingAppointment = useCallback(
    (event: SyntheticEvent) => {
      dispatch({
        type: UPDATE_ONGOING_APPOINTMENT,
        payload: { providerId: provider.pk, ongoingAppointment: (event.target as HTMLInputElement).checked },
      })
    },
    [provider, dispatch]
  )

  const onWheelHandler = useCallback((e: WheelEvent) => {
    if (window.document.activeElement === e.target) {
      const target = e.target as HTMLInputElement
      target.blur()
    }
  }, [])

  useEffect(() => {
    setCurrentVisitCount(Number(provider.visit_count ?? 0))
  }, [provider.visit_count])

  return (
    <>
      <Box mt={6} mb={6}>
        <Divider />
      </Box>
      <SectionContainer>
        <Box>
          <SectionHeading mb={4}>Appointments</SectionHeading>
          <Box className={classes.appointmentControls}>
            <DateField
              initialValue={provider.first_contact ?? null}
              onChange={handleChangeFirstContact}
              label={"Date of first appointment"}
              fieldProps={{ name: "first_contact" }}
              className={classes.appointmentDate}
              disabled={disabled}
              error={Boolean(validationErrors["first_contact"])}
              helperText={validationErrors["first_contact"]}
            />
            <DateField
              initialValue={provider.last_contact ?? null}
              onChange={handleChangeLastContact}
              label={"Date of last appointment"}
              fieldProps={{ name: "last_contact" }}
              className={classes.appointmentDate}
              disabled={disabled || !provider.first_contact}
              error={Boolean(validationErrors["last_contact"])}
              helperText={validationErrors["last_contact"]}
            />
            <TextField
              variant="outlined"
              type="number"
              data-test="visit-count"
              value={currentVisitCount}
              onChange={handleChangeVisitCount}
              onBlur={handleBlurVisitCount}
              label={"No. of visits"}
              disabled={disabled}
              error={Boolean(validationErrors["visit_count"])}
              helperText={validationErrors["visit_count"]}
              onWheel={onWheelHandler}
            />
            <FormControlLabel
              label="Treatment is ongoing"
              labelPlacement="end"
              control={<Checkbox color="secondary" />}
              disabled={disabled}
              checked={provider.is_ongoing_appointment ?? false}
              onChange={handleChangeOngoingAppointment}
            />
          </Box>
        </Box>

        {hasPartitionProvider && (
          <Box>
            <Appointments
              caseId={caseId}
              providerId={provider.pk}
              currentFirstAppointment={provider.first_contact ?? ""}
              currentLastAppointment={provider.last_contact ?? ""}
              currentNumberOfVisits={provider.visit_count ?? 0}
              onEntryClick={onAnnotationClick}
              dateUpdated={annotationDateUpdated ?? ""}
              allowImport
              dispatch={dispatch}
            />
          </Box>
        )}
      </SectionContainer>
    </>
  )
}
