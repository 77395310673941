import { Node } from "slate"
import { CitationElement } from "../CustomEditor"
import { INLINE_ELEMENTS } from "../elements"

export function isCitationNode(node: Node): node is CitationElement {
  return "type" in node && node.type === INLINE_ELEMENTS.CITATION
}

export function isCitationHTMLNode(node: HTMLElement): boolean {
  return node.hasAttribute("data-citation")
}
