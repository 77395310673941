import React from "react"
import Typography, { TypographyProps } from "@mui/material/Typography"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { omit } from "lodash"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import TextButton from "common/buttons/TextButton"
import { COLLAPSED_SIZE } from "./constants"

export const TemplateWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 0, 7.5),
  position: "relative",
}))

export const StyledExpandButton = styled(TextButton)({
  textDecoration: "underline",
  textTransform: "none",
})

export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderColor: theme.palette.grey[300],
  borderStyle: "solid",
  borderWidth: "1px",
  borderRadius: "4px",
}))

export const StyledButtonWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(-6),
  left: theme.spacing(1),
}))

export const StyledSeparator = styled("span")(({ theme }) => ({
  userSelect: "none",
  color: theme.palette.blue.main,
}))

export const StyledNoTemplate = styled("div")(({ theme }) => ({
  fontWeight: "bold",
  margin: theme.spacing(2),
}))

export const ContentBlurHelper = styled("div")(({ theme }) => ({
  height: theme.spacing(4),
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
}))

const TemplateHeader: React.FC<{ className?: string; collapsed?: boolean } & TypographyProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <Typography {...omit(props, ["collapsed", "shouldExpand"])} variant="h6" className={className}>
      {children}
    </Typography>
  )
}

export const StyledTemplateHeader = styled(TemplateHeader)<{ collapsed?: boolean; shouldExpand?: boolean }>(
  ({ collapsed, theme, shouldExpand }) => ({
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    letterSpacing: "-0.3px",
    textTransform: "uppercase",
    display: "inline-flex",
    cursor: shouldExpand ? "pointer" : undefined,

    "& > svg": {
      transform: `rotate(${collapsed ? 0 : -180}deg)`,
      transition: "transform .3s ease",
    },
  })
)

export const CaseAlertBox = styled(Alert)(({ theme }) => ({
  padding: theme.spacing(1, 5, 1, 3),
  borderRadius: "6px",
  position: "relative",

  "& .MuiAlert-message": {
    fontSize: "14px",
    lineHeight: "21px",
    color: theme.palette.common.black,
  },
}))

export const CaseAlertTitle = styled(AlertTitle)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: "bold",
  color: theme.palette.common.black,
  margin: 0,
}))

export const CaseAlertMessage = styled("div")({
  fontSize: "14px",
  lineHeight: "21px",
})

export const ExpandWrapper = styled("div")({
  position: "absolute",
  cursor: "pointer",
  zIndex: 1,
  bottom: "0px",
  left: "0px",
  height: `${COLLAPSED_SIZE}px`,
  width: "100%",
})
