import { Suspense, useMemo } from "react"
import { getInternalUsers } from "api"
import useUser from "hooks/useUser"
import { queryKeys } from "react-query/constants"
import { FILTER_OPTION_ALL } from "app/constants"
import { useSuspenseQuery } from "@suspensive/react-query"
import { ErrorBoundary } from "react-error-boundary"
import AutoCompleteFilter from "./AutoCompleteFilter"

const SELF_STRING = "(Me)"

const UserFilterComponent = ({
  onChange,
  value,
  label = "User",
  className = "",
  showCurrentUserInLabel = true,
}) => {
  const { user } = useUser()
  const {
    data: { data: assignees },
  } = useSuspenseQuery([queryKeys.internalUsers], getInternalUsers, {
    meta: { disableLoader: true },
  })

  const options = useMemo(() => {
    let selfAssignee = null
    const assigneeOptions = []

    for (const assignee of assignees) {
      if (showCurrentUserInLabel && user.id === assignee.pk) {
        selfAssignee = assignee
      } else {
        assigneeOptions.push({
          key: assignee.pk,
          display: `${assignee.first_name} ${assignee.last_name}`,
        })
      }
    }

    if (selfAssignee) {
      assigneeOptions.unshift({
        key: selfAssignee.pk,
        display: `${selfAssignee.first_name} ${selfAssignee.last_name} ${SELF_STRING}`,
      })
    }

    assigneeOptions.unshift(FILTER_OPTION_ALL)

    return assigneeOptions
  }, [showCurrentUserInLabel, user, assignees])

  const valueOption = options.find(option => option.key === value)

  return (
    <AutoCompleteFilter
      value={valueOption}
      onChange={onChange}
      options={options}
      label={label}
      className={className}
      id="user-filter"
    />
  )
}

const UserFilter = ({ onChange, value, label = "User", className = "", showCurrentUserInLabel = true }) => {
  return (
    <ErrorBoundary fallback={<></>}>
      <Suspense
        fallback={
          <AutoCompleteFilter
            className={className}
            label={label}
            value={null}
            options={[]}
            loading
            disabled
          />
        }
      >
        <UserFilterComponent
          onChange={onChange}
          value={value}
          label={label}
          className={className}
          showCurrentUserInLabel={showCurrentUserInLabel}
        />
      </Suspense>
    </ErrorBoundary>
  )
}

export { UserFilter as default }
