import {
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { LoadingIcon, PulseButton, StyledReviewButton } from "./styled"
import { ReviewArgs, ReviewInternalStatus } from "./store/types"
import { reviewActions, useReviewStore } from "./store"
import { reviewsSelectors } from "./store/reviews"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useInterval } from "common/utils"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { RosieIcon } from "./assets"
import { EditorContent } from "common/form-components/rich-text"
import { NotInterestedOutlined } from "@mui/icons-material"

type ReviewButtonProps = ReviewArgs & {
  reviewRequestId?: Nullable<string>
  onCreated: (reviewRequestId: string) => void
  onCompleted: (completedContent?: EditorContent) => void
  onCancelled: () => void
}

const PENDING_STATUSES: ReviewInternalStatus[] = ["pending", "running"]

export function ReviewButton({
  templateType,
  sectionType,
  plaintiffId,
  providerId,
  onCompleted,
  onCreated,
  onCancelled,
  reviewRequestId,
}: ReviewButtonProps) {
  const { showErrorMessage } = useHandleMessages()
  const reviewArgs = useMemo<ReviewArgs>(
    () =>
      ({
        templateType,
        sectionType,
        plaintiffId,
        providerId,
      }) as ReviewArgs,
    [templateType, sectionType, plaintiffId, providerId]
  )
  const review = useReviewStore(reviewsSelectors.reviewItemByArgs(reviewArgs))
  const matterId = useReviewStore(state => state.matterId)
  const isReviewInProgress =
    !!reviewRequestId && !!review?.status && !!review.data && PENDING_STATUSES.includes(review.status)

  const [menuOpened, setMenuOpened] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleStartReviewClick = useCallback(async () => {
    const createdReview = await reviewActions.createReview(reviewArgs)

    if (!createdReview) {
      showErrorMessage("There was an error creating Rosie review.")
    } else {
      onCreated(createdReview.reviewRequestId)
    }
  }, [showErrorMessage, reviewArgs, onCreated])

  const handleReviewComplete = useCallback(async () => {
    if (review?.data?.reviewRequestId) {
      try {
        await reviewActions.completeReview(reviewArgs, review.data.reviewRequestId)
        const completedReview = reviewsSelectors.reviewItemByArgs(reviewArgs)(useReviewStore.getState())
        onCompleted(completedReview?.completedContent)
      } catch {
        showErrorMessage({
          message: (
            <>
              There was an error completing Rosie review.
              <br />
              Please try again or contact Engineering if the issue continues.{" "}
            </>
          ),
        })
      }
    }
  }, [reviewArgs, review, onCompleted, showErrorMessage])

  const handleSectionUnlock = useCallback(async () => {
    setMenuOpened(false)
    if (!reviewRequestId) return
    try {
      await reviewActions.cancelReview(reviewArgs, reviewRequestId)
      onCancelled()
    } catch {
      showErrorMessage("There was an error unlocking section. Please contact Engineering team.")
    }
  }, [reviewArgs, reviewRequestId, showErrorMessage, onCancelled])

  useEffect(() => {
    if (reviewRequestId && !review && matterId) {
      reviewActions.checkStatusAndLoadReview(reviewArgs, reviewRequestId)
    }
  }, [reviewArgs, matterId, review, reviewRequestId])

  useEffect(() => {
    if (review?.status === "failed") {
      showErrorMessage("There was an error performing review. Please run Rosie review one more time.")
    }
  }, [showErrorMessage, review])

  useInterval(
    () => {
      reviewActions.checkStatusAndLoadReview(reviewArgs, reviewRequestId as string)
    },
    5_000,
    !!matterId && isReviewInProgress
  )

  const areAllReviewItemsActed =
    review?.state === "loaded" && review.data
      ? review.data.results.every(item => item.status !== "unacked")
      : false
  const completeButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const button = completeButtonRef.current
    if (areAllReviewItemsActed && button) {
      const raf = requestAnimationFrame(() => button.scrollIntoView({ behavior: "smooth", block: "center" }))
      return () => cancelAnimationFrame(raf)
    }
  }, [areAllReviewItemsActed])

  if (!matterId) {
    return null
  }

  if (!review || review.state === "loading" || review.state === "not_started" || review.state === "failed") {
    return (
      <StyledReviewButton
        variant="outlined"
        size="large"
        startIcon={review?.state === "loading" ? <LoadingIcon /> : <RosieIcon style={{ marginTop: -2 }} />}
        disabled={review?.state === "loading"}
        onClick={handleStartReviewClick}
      >
        <Typography variant="body2SemiBold">
          {review?.state === "failed" ? "Re-run Rosie Review" : "Rosie Review"}
        </Typography>
      </StyledReviewButton>
    )
  }

  if (review.state === "creating") {
    return (
      <StyledReviewButton variant="outlined" size="large" startIcon={<LoadingIcon />} disabled>
        <Typography variant="body2SemiBold">Creating Rosie Review</Typography>
      </StyledReviewButton>
    )
  }

  return (
    <>
      <ButtonGroup
        variant="contained"
        // ref={anchorRef}
        aria-label="Button group with a nested menu"
        disableElevation
      >
        {review.state === "running" ? (
          <StyledReviewButton variant="outlined" size="large" startIcon={<LoadingIcon />} disabled>
            <Typography variant="body2SemiBold">Rosie Review Running</Typography>
          </StyledReviewButton>
        ) : (
          <PulseButton
            ref={completeButtonRef}
            variant="contained"
            size="large"
            color="secondary"
            disableElevation
            onClick={handleReviewComplete}
            disabled={review.state === "completing"}
            startIcon={review.state === "completing" && <CircularProgress size={16} color="inherit" />}
            pulse={areAllReviewItemsActed}
            data-complete-button={review.data?.reviewRequestId}
          >
            <Typography variant="body2SemiBold">
              {review.state === "completing" ? "Completing" : "Complete Review"}
            </Typography>
          </PulseButton>
        )}

        {review.state === "running" ? (
          <StyledReviewButton
            ref={buttonRef}
            variant="outlined"
            size="large"
            sx={{ px: 1 }}
            onClick={() => setMenuOpened(open => !open)}
          >
            <KeyboardArrowDownIcon />
          </StyledReviewButton>
        ) : (
          <Button
            ref={buttonRef}
            variant="contained"
            size="large"
            color="secondary"
            disableElevation
            sx={{ px: 1 }}
            onClick={() => setMenuOpened(open => !open)}
          >
            <KeyboardArrowDownIcon />
          </Button>
        )}
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={menuOpened}
        anchorEl={buttonRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setMenuOpened(false)}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={handleSectionUnlock} dense sx={{ display: "flex", gap: 1 }}>
                    <NotInterestedOutlined fontSize="small" />
                    <Typography variant="body2">Cancel Rosie Review</Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
