import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { theme } from "app/theme"
import { ModuleCardProps } from "../types"
import { StyledCard } from "./styled"
import { Tooltip } from "@mui/material"
import HelpIcon from "@mui/icons-material/Help"

export const RequestFormModule = ({ title, children, tooltipTitle, id }: ModuleCardProps) => {
  return (
    <StyledCard id={id}>
      <Box sx={{ marginBottom: theme.spacing(2) }}>
        <Typography>
          <b>{title}</b>{" "}
          {tooltipTitle && (
            <Tooltip title={tooltipTitle} placement="top">
              <HelpIcon />
            </Tooltip>
          )}
        </Typography>
      </Box>
      {children}
    </StyledCard>
  )
}
