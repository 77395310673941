import retry from "async-retry"
import invariant from "invariant"
import { FileVerificationStatus, SignedFileUpload, fileService } from "api/services/file"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"
import { Flow, FileToUpload, Dispatch } from "./types"
import { FileUploadActions } from "../file-state"
import { queue } from "../queue"
import { EMPTY_FILE_PREVALIDATION } from "../constants"
import { withPerformanceMeasure } from "infrastructure/performance"
import { FileMetadata } from "common/form-components/files/types"

export function getChunksUploadFlow(
  dispatch: Dispatch<FileUploadActions>
): Flow<{ data: FileToUpload; metadata: FileMetadata }, void> {
  return async ({ data, metadata }, { signal }) => {
    const { chunkCount, chunks } = data

    dispatch({ type: "CREATE_UPLOAD", payload: { chunkCount } })
    invariant(data.size > 0, EMPTY_FILE_PREVALIDATION)

    const isSignedUploadEnabled = isFeatureEnabled(FEATURES.CDN_UPLOAD)
    const isVerifiedUploadEnabled = isSignedUploadEnabled && isFeatureEnabled(FEATURES.CDN_VERIFIED_UPLOAD)
    const createdUpload = await (isSignedUploadEnabled
      ? fileService.createSignedFileUpload({ data: { ...data, fileName: metadata.name } })
      : fileService.createFileUpload({ data: { ...data, fileName: metadata.name } }))
    const uploadId = createdUpload.id

    dispatch({ type: "PROCESS_UPLOAD", payload: { uploadId, processingStatus: "IN_PROGRESS" } })

    const tasks = chunks.map(chunk => {
      const task = () => {
        if (isSignedUploadEnabled) {
          return fileService.signedUploadChunk({
            chunk: chunk.file,
            url: (createdUpload as SignedFileUpload).signUrl,
          })
        } else {
          return fileService.uploadChunk({ data: { ...chunk, upload: uploadId }, uploadId })
        }
      }
      const retryableTask = () => retry(task, { retries: 5 })

      return queue.add(retryableTask, { signal })
    })

    for (const [i, task] of tasks.entries()) {
      task
        .then(() =>
          dispatch({
            type: "UPDATE_PROGRESS",
            payload: { chunkIndex: i },
          })
        )
        .catch(e =>
          dispatch({
            type: "FAIL",
            payload: { reason: e instanceof Error ? e.message : "Chunk uploading failed" },
          })
        )
    }

    await Promise.all(tasks)

    if (isVerifiedUploadEnabled) {
      try {
        await withPerformanceMeasure("file_upload_verification", async () => {
          await fileService.verifyFileUpload({ uploadId })
          const verificationTimeout = setTimeout(() => {
            throw new Error("Verification timeout error")
          }, 300_000)

          // eslint-disable-next-line no-constant-condition
          while (true) {
            const verificationStatus = await new Promise<FileVerificationStatus>((resolve, reject) => {
              setTimeout(() => {
                fileService.getVerificationStatus({ uploadId }).then(resolve, reject)
              }, 1000)
            })

            if (
              verificationStatus.processingStatus === "SUCCESS" ||
              verificationStatus.processingStatus === "WARNING"
            ) {
              dispatch({
                type: "COMPLETE_UPLOAD",
                payload: {
                  processingStatus: verificationStatus.processingStatus,
                  info: verificationStatus.processingInfo ?? null,
                },
              })
              clearTimeout(verificationTimeout)
              break
            } else if (verificationStatus.processingStatus === "FAILURE") {
              dispatch({
                type: "FAIL",
                payload: { reason: verificationStatus.processingInfo ?? "File verification failed" },
              })
              clearTimeout(verificationTimeout)
              break
            }
          }
        })
      } catch {
        dispatch({
          type: "FAIL",
          payload: { reason: "File verification failed" },
        })

        return
      }
    } else {
      dispatch({ type: "COMPLETE_UPLOAD", payload: {} })
    }
  }
}
