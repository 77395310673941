import { createContext } from "react"

export interface PermissionFetcherContextType {
  subscribe(): void
  unsubscribe(): void

  subscribeFirmId(firmId: PrimaryKey | string): void
  unsubscribeFirmId(firmId: PrimaryKey | string): void
}

export const PermissionFetcherContext = createContext<PermissionFetcherContextType>({
  subscribe: () => {
    throw Error("Permission Fetcher Context not initialized. ")
  },
  unsubscribe: () => {
    throw Error("Permission Fetcher Context not initialized. ")
  },
  subscribeFirmId: () => {
    throw Error("Permission Fetcher Context not initialized. ")
  },
  unsubscribeFirmId: () => {
    throw Error("Permission Fetcher Context not initialized. ")
  },
})
