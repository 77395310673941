import { hasOverlap } from "../rangeUtils"
import { FieldValidationErrors, FormRange } from "../types"
import { addError, ALL_FIELDS, displayRange } from "./validationUtils"

const hasRequiredFields = (range: FormRange) => !Number.isNaN(range.start) && !Number.isNaN(range.end)

interface ValidateRangesProps {
  ranges: FormRange[]
  startBoundary: number
  endBoundary: number
}

export const validateRanges = ({
  ranges,
  startBoundary,
  endBoundary,
}: ValidateRangesProps): FieldValidationErrors => {
  const errors: FieldValidationErrors = {}

  ranges.forEach((range, index) => {
    if (Number.isNaN(range.start)) {
      addError({
        formId: range.formId,
        error: { field: "start", message: "Start page is required" },
        errors,
      })
    }

    if (Number.isNaN(range.end)) {
      addError({
        formId: range.formId,
        error: { field: "end", message: "End page is required" },
        errors,
      })
    }

    if (!hasRequiredFields(range)) {
      // skip additional validation until both values are filled in
      return
    }

    if (range.start > range.end) {
      addError({
        formId: range.formId,
        error: { field: "start", message: "Start page cannot be greater than end page" },
        errors,
      })
      return
    }

    if (range.start < startBoundary) {
      addError({
        formId: range.formId,
        error: { field: "start", message: `Must start from page ${startBoundary} or after` },
        errors,
      })
    }

    if (range.end > endBoundary) {
      addError({
        formId: range.formId,
        error: { field: "end", message: `Must end on page ${endBoundary} or before` },
        errors,
      })
    }

    if (range.start < startBoundary || range.end > endBoundary) {
      // skip additional validation if range out ouf bounds
      return
    }

    // it does not overlap with other ranges
    ranges.forEach((otherRange, otherIndex) => {
      if (index === otherIndex || !hasRequiredFields(otherRange)) return

      if (hasOverlap(range, otherRange)) {
        addError({
          formId: range.formId,
          error: { field: ALL_FIELDS, message: `Overlapping with page range ${displayRange(otherRange)}` },
          errors,
        })
      }
    })
  })

  return errors
}
