import { makeStyles } from "tss-react/mui"
import List from "@mui/material/List"
import Box from "@mui/material/Box"
import CompletedIcon from "@mui/icons-material/CheckCircle"
import FileIcon from "@mui/icons-material/InsertDriveFile"
import SkippedIcon from "@mui/icons-material/NotInterested"
import { Link, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"

import { getMissingExhibits } from "../api"
import { queryKeys } from "../react-query/constants"
import useUser from "../hooks/useUser"
import { INTERNAL_ROLES_VALUES } from "../common/models/roles"
import { isNotOSFAndNotSetupRole } from "../common/permission"
import { useDemandSteps } from "./steps"
import { FORM_SECTION_ROUTES } from "./steps/constants"
import { CASE_STEP, DEMAND_SECTION } from "./steps/enums"
import { FormSectionListItem, FormSectionListItemIcon, FormSectionListItemText } from "./LeftNav/styled"
import { LeftNavLink } from "./LeftNav/LeftNavLink"
import FilePresentIcon from "@mui/icons-material/FilePresent"
import { useFeatures } from "hooks/useFeatures"
import { Divider } from "@mui/material"

import { useSkipDemandSteps } from "hooks/useSkipDemandSteps"

import useCase from "hooks/useCase"

const useStyles = makeStyles()(theme => ({
  formSections: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  listItem: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(2),
    },
  },
  icon: {
    fontSize: theme.spacing(2.5),
  },
  successIcon: {
    color: "#3E9D6F",
  },
  section: {
    textTransform: "uppercase",
    margin: theme.spacing(2, 0),

    "&:hover": {
      cursor: "pointer",
      fontWeight: "bold",
    },
  },
  infoIcon: {
    color: theme.palette.common.white,
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: "50%",
    textAlign: "center",
    margin: "auto 0",
    lineHeight: `${theme.spacing(2)}`,
    fontSize: "12px",
  },
  notification: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: "#FEA568",
    fontWeight: "bold",
  },
  alert: {
    backgroundColor: theme.palette.error.main,
    fontWeight: "bold",
  },
  floatRight: {
    marginLeft: "auto",
  },
  fileIcon: {
    fontSize: "15px",
    height: "1.25rem",
  },
}))

export default function FormSections({ includeMissingDocs = true, request }) {
  const { classes, cx } = useStyles()
  const { user } = useUser({ disableLoader: true })
  const { id: caseId } = useParams()
  const { isFeatureEnabled } = useFeatures()
  const demandSteps = useDemandSteps()

  const { caseObj: caseInfo } = useCase(caseId)
  const SKIPPED_STEPS_TO_FLAG = useSkipDemandSteps()
  const demandStepsItems = (demandSteps?.items ?? []).filter(demandStepItem => {
    const stepKey = demandStepItem.step

    if (SKIPPED_STEPS_TO_FLAG[stepKey] === undefined || !caseInfo?.document_id) return true
    return SKIPPED_STEPS_TO_FLAG[stepKey]
  })

  const hasCompletedPlaintiff = Boolean(
    demandSteps.items.find(({ step }) => step === CASE_STEP.PLAINTIFF)?.isCompleted
  )

  const { data: missingExhibits } = useQuery(
    [queryKeys.missingExhibits, caseId],
    async () => {
      return await getMissingExhibits({ caseId: caseId, onlyUnresolved: false })
    },
    {
      enabled: isNotOSFAndNotSetupRole(user.role) && !!request?.pk,
      meta: {
        disableLoader: true,
      },
    }
  )

  const missingExhibitMap = {}
  let total = 0
  if (missingExhibits) {
    missingExhibits.forEach(file => {
      if (file.instructions == "upload") {
        missingExhibitMap[`section-${file.section}`] = "Uploaded"
      } else if (file.is_resolved === false && missingExhibitMap[`section-${file.section}`] !== "Uploaded") {
        missingExhibitMap[`section-${file.section}`] = "Missing"
      }
      total++
    })
  }

  const showIntakeFiles = isFeatureEnabled("annotation")

  return (
    <List sx={{ padding: 0 }}>
      {showIntakeFiles && (
        <>
          <LeftNavLink label="Intake Files" toLink="files" dataTest="case-files" Icon={FilePresentIcon} />
          <Box mt={1} mb={1}>
            <Divider variant="middle" />
          </Box>
        </>
      )}
      {demandStepsItems.map(({ step, isCompleted, isSkipped, title, disabled, isReviewNeeded }) => (
        <FormSectionListItem
          active={demandSteps.section === step}
          key={step}
          component={Link}
          to={`form/${FORM_SECTION_ROUTES[step]}`}
          disabled={disabled}
          data-test={`section-${step}`}
        >
          {isCompleted || isSkipped ? (
            <>
              <FormSectionListItemIcon>
                {isCompleted && <CompletedIcon className={cx(classes.icon, classes.successIcon)} />}
                {isSkipped && <SkippedIcon className={classes.icon} />}
              </FormSectionListItemIcon>
              <FormSectionListItemText primary={title} />
            </>
          ) : (
            <FormSectionListItemText inset primary={title} />
          )}
          {missingExhibitMap[`section-${step}`] == "Missing" && (
            <FormSectionListItemIcon>
              <Box className={cx(classes.infoIcon, classes.warning, classes.floatRight)}>!</Box>
            </FormSectionListItemIcon>
          )}
          {missingExhibitMap[`section-${step}`] == "Uploaded" && (
            <FormSectionListItemIcon>
              <Box className={cx(classes.infoIcon, classes.notification, classes.floatRight)}>
                <FileIcon className={classes.fileIcon} />
              </Box>
            </FormSectionListItemIcon>
          )}
          {isReviewNeeded && (
            <FormSectionListItemIcon>
              <Box className={cx(classes.infoIcon, classes.alert, classes.floatRight)}>!</Box>
            </FormSectionListItemIcon>
          )}
        </FormSectionListItem>
      ))}
      <FormSectionListItem
        active={demandSteps.section === DEMAND_SECTION.EXHIBITS}
        component={Link}
        to="form/exhibit"
        data-test="arrange-exhibits"
        disabled={!hasCompletedPlaintiff}
      >
        <FormSectionListItemText inset primary="Arrange Exhibits" />
      </FormSectionListItem>
      {INTERNAL_ROLES_VALUES.includes(user.role) && request?.pk && includeMissingDocs && (
        <FormSectionListItem
          active={demandSteps.section === DEMAND_SECTION.MISSING_DOCUMENTS}
          component={Link}
          to={"form/" + FORM_SECTION_ROUTES[DEMAND_SECTION.MISSING_DOCUMENTS]}
          disabled={!hasCompletedPlaintiff}
        >
          <FormSectionListItemText inset primary={"Missing Documents"} />
          <FormSectionListItemIcon>
            <Box
              className={cx(classes.infoIcon, classes.notification, classes.floatRight)}
              data-test="missing-document-counter"
            >
              {missingExhibits && total}
            </Box>
          </FormSectionListItemIcon>
        </FormSectionListItem>
      )}
    </List>
  )
}
