import styled from "@emotion/styled"
import Typography from "@mui/material/Typography"

export const Pagination = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.typography.pxToRem(2),
}))

export const PaginationPagesIndicator = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.grey[700],
  minWidth: theme.typography.pxToRem(60),
  textAlign: "center",
  margin: `0 ${theme.spacing(0.5)}`,
  [theme.breakpoints.up("md")]: {
    margin: `0 ${theme.spacing(1)}`,
  },
}))

export const PaginationButton = styled("button")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: "none",
  background: "transparent",
  color: theme.palette.grey[700],
  width: theme.typography.pxToRem(20),
  padding: 0,
  cursor: "pointer",
  "&:disabled": {
    color: theme.palette.grey[400],
    cursor: "default",
  },
}))

export const PageInput = styled("input")(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.grey[700],
  border: `1px solid ${theme.palette.grey[400]}`,
  background: "transparent",
  minWidth: theme.typography.pxToRem(20),
  width: theme.typography.pxToRem(50),
  MozAppearance: "textfield",
  "&::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "&::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
}))
