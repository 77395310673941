import { DEFAULT_PREVIEW_WIDTH, DEFAULT_WINDOW_CONFIGURATIONS } from "app/constants"

const childWindows = new Set<Window>()

window.focusChildWindow = childClientUuid => {
  const childWindow = Array.from(childWindows.values()).find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    child => (child as any).CLIENT_UUID === childClientUuid
  )

  if (childWindow) {
    childWindow.focus()
  }
}

window.closeChildWindow = childClientUuid => {
  const childWindow = Array.from(childWindows.values()).find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    child => (child as any).CLIENT_UUID === childClientUuid
  )

  if (childWindow) {
    childWindow.close()
  }
}

export function openWindow(route: string): void {
  const width = Math.max(DEFAULT_PREVIEW_WIDTH, Math.round(window.screen.availWidth / 3))
  const height = window.screen.availHeight
  const left = window.screen.availWidth - width

  const childWindow = window.open(
    route,
    "_blank",
    `${DEFAULT_WINDOW_CONFIGURATIONS},width=${width}},height=${height},top=0,left=${left}`
  )

  if (childWindow) {
    childWindows.add(childWindow)
    childWindow.addEventListener("unload", () => {
      setTimeout(() => {
        try {
          if (!childWindow.location.href) {
            childWindows.delete(childWindow)
          }
        } catch {
          return
        }
      }, 100)
    })
  }
}
