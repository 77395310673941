import { FeatureProps } from "common/types/features"
import { ReviewArgs, ReviewItemStatus, ReviewInternalStatus } from "./store/types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { PropsByFeature } from "common/form-components/rich-text/features/types"
import { EDITOR_FEATURES } from "common/form-components/rich-text/features/constants"
import { reviewActions, useReviewStore } from "./store"
import { reviewsSelectors } from "./store/reviews"
import { Control, FieldValues, useForm, UseFormReturn } from "react-hook-form"
import { CustomEditor, EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { EditorContent } from "common/form-components/rich-text"
import { ReviewEditorWrapper } from "./styled"
import { createPortal } from "react-dom"
import { ReviewModal } from "./ReviewModal"
import { v4 } from "uuid"
import { getEditorRange } from "common/form-components/rich-text/queries"
import { applyStyleSuggestion, applyTextSuggestion } from "common/form-components/rich-text/commands"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { cloneDeep } from "lodash"
import { HistoryEditor } from "slate-history"
import { flagsSelectors } from "./store/flags"
import { useShallow } from "zustand/react/shallow"
import { ReviewInformation } from "./ReviewInformation"

type SuggestionFeatureProps = FeatureProps<Pick<PropsByFeature, EDITOR_FEATURES.SUGGESTIONS>>

type EditorFieldProps = {
  control: Control<FieldValues>
  name: string

  disabled?: boolean
}

type EditorInputProps = {
  value: EditorContent | null
  onChange: (e: { value: EditorRoot }) => void
}

type ReviewEditorProviderProps = ReviewArgs & {
  reviewRequestId?: Nullable<string>
  children: React.ReactElement<SuggestionFeatureProps & EitherProps<EditorFieldProps, EditorInputProps>>
  editorId?: Nullable<string>
}

interface EditorContentValues {
  content: EditorContent | null
}

function getChildProps(
  childProps: SuggestionFeatureProps & EitherProps<EditorFieldProps, EditorInputProps>,
  form: UseFormReturn<EditorContentValues>
) {
  if (childProps.control) {
    return {
      control: form.control,
      name: "content",
    }
  }

  const { getValues, setValue } = form

  return {
    value: getValues("content")?.children,
    onChange: ({ value }: { value: EditorRoot }) =>
      setValue("content", new EditorContent(value as EditorRoot<false>)),
  }
}

export function ReviewEditorProvider({
  children,
  templateType,
  sectionType,
  plaintiffId,
  providerId,
  reviewRequestId,
  editorId,
}: ReviewEditorProviderProps) {
  const id = useMemo(() => editorId || v4(), [editorId])
  const reviewArgs = useMemo<ReviewArgs>(
    () => ({ templateType, sectionType, plaintiffId, providerId }) as ReviewArgs,
    [templateType, sectionType, plaintiffId, providerId]
  )
  const review = useReviewStore(reviewsSelectors.reviewItemByArgs(reviewArgs))
  const suggestionItems = useReviewStore(useShallow(reviewsSelectors.suggestionItemsByArgs(reviewArgs)))
  const suggestionCount = useReviewStore(flagsSelectors.activeFlagsCount)
  const form = useForm({
    values: {
      content: review?.data?.content ?? review?.completedContent ?? null,
    },
  })
  const reviewContent = useMemo(() => {
    return cloneDeep(review?.data?.content ?? new EditorContent(DEFAULT_VALUE as EditorRoot<false>))
  }, [review?.data?.content])
  const ref = useRef<HTMLDivElement>(null)
  const editorRef = useRef<Nullable<CustomEditor>>(null)

  const [reviewItemIdx, setReviewItemIdx] = useState<Nullable<number>>(null)
  const [anchorRect, setAnchorRect] = useState<Nullable<DOMRect>>(null)
  const activeReviewItem =
    (review?.state === "loaded" && reviewItemIdx !== null && suggestionItems[reviewItemIdx]) || null

  const setEditorRef = useCallback((editor: Nullable<CustomEditor>) => {
    editorRef.current = editor
  }, [])

  const handleClose = useCallback(() => {
    setReviewItemIdx(null)
  }, [])

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (!suggestionItems.length) return

      const suggestionEl = (e.target as HTMLElement).closest("[data-suggestion]") as HTMLElement | null

      if (!suggestionEl) return

      const reviewItemIdx = suggestionItems.findIndex(item =>
        suggestionEl.attributes.getNamedItem(`data-suggestion-${item.id}`)
      )
      setReviewItemIdx(reviewItemIdx === -1 ? null : reviewItemIdx)
    },
    [suggestionItems]
  )

  const applySuggestions = useCallback(() => {
    const editor = editorRef.current
    const suggestionItems = reviewsSelectors.suggestionItemsByArgs(reviewArgs)(useReviewStore.getState())

    if (!editor || !suggestionItems.length) return

    HistoryEditor.withoutSaving(editor, () => {
      editor.delete({ at: getEditorRange(editor) })
      editor.unwrapNodes({ mode: "all", at: getEditorRange(editor) })
      editor.insertNodes(cloneDeep(reviewContent.children) as EditorRoot, {
        at: getEditorRange(editor),
        mode: "highest",
      })
      editor.removeNodes({ at: [0] })
    })

    const appliedStyleSuggestions = suggestionItems.filter(reviewItem => {
      if (reviewItem.outputTextType !== "styled_text") return false
      if (!reviewItem.originalText) return false
      return reviewItem.status === "accepted" || reviewItem.status === "modified"
    })

    for (const reviewItem of appliedStyleSuggestions) {
      applyStyleSuggestion(
        editor,
        reviewItem.id,
        reviewItem.originalText,
        reviewItem.userModifiedText ?? reviewItem.suggestedText
      )
    }

    const appliedTextSuggestions = suggestionItems.filter(reviewItem => {
      if (reviewItem.outputTextType !== "plain_text") return false
      if (!reviewItem.originalText) return false
      return reviewItem.status === "accepted" || reviewItem.status === "modified"
    })

    for (const reviewItem of appliedTextSuggestions) {
      applyTextSuggestion(
        editor,
        reviewItem.id,
        reviewItem.originalText,
        reviewItem.userModifiedText ?? reviewItem.suggestedText
      )
    }

    reviewActions.setReviewResultContent(reviewArgs, editor.children)
  }, [reviewContent, reviewArgs])

  const resultsRef = useRef(suggestionItems)
  resultsRef.current = suggestionItems

  const applySuggestionsRef = useRef(applySuggestions)
  applySuggestionsRef.current = applySuggestions

  const handleReviewItemChanged = useCallback(
    (status: Extract<ReviewItemStatus, "accepted" | "rejected">) => {
      const editor = editorRef.current

      if (!editor || !activeReviewItem || !review?.data) return

      if (status === "accepted") {
        reviewActions.acceptReviewItem(reviewArgs, activeReviewItem.id)
        reviewActions.changeFlagsCount(id, 1)
      } else {
        reviewActions.rejectReviewItem(reviewArgs, activeReviewItem.id)
        reviewActions.changeFlagsCount(id, -1)
      }

      applySuggestions()
    },
    [reviewArgs, id, activeReviewItem, review, applySuggestions]
  )

  const selectNextReviewItemIdx = useCallback(() => {
    setReviewItemIdx(currentIdx => {
      let nextIdx = resultsRef.current.findIndex(
        (item, idx) => idx > (currentIdx || 0) && item.status === "unacked"
      )

      if (nextIdx !== -1) return nextIdx

      nextIdx = resultsRef.current.findIndex(item => item.status === "unacked")

      return nextIdx === -1 ? null : nextIdx
    })
  }, [])

  const handleReviewItemAccepted = useCallback(() => {
    handleReviewItemChanged("accepted")
    requestAnimationFrame(() => selectNextReviewItemIdx())
  }, [handleReviewItemChanged, selectNextReviewItemIdx])

  const handleReviewItemRejected = useCallback(() => {
    handleReviewItemChanged("rejected")
    requestAnimationFrame(() => selectNextReviewItemIdx())
  }, [handleReviewItemChanged, selectNextReviewItemIdx])

  const handleReviewItemModified = useCallback(
    async (userModifiedText: string) => {
      if (!activeReviewItem) return

      await reviewActions.modifyReviewItemSuggestion(reviewArgs, activeReviewItem.id, userModifiedText)

      reviewActions.changeFlagsCount(id, 1)
      requestAnimationFrame(() => selectNextReviewItemIdx())
    },
    [id, reviewArgs, activeReviewItem, selectNextReviewItemIdx]
  )

  useEffect(() => {
    setReviewItemIdx(null)
  }, [review?.data?.reviewRequestId])

  useEffect(() => {
    const raf = requestAnimationFrame(() => {
      setAnchorRect(() => {
        if (!activeReviewItem?.id || !ref.current) return null

        const anchorEl = ref.current.querySelector(`[data-suggestion-${activeReviewItem.id}="true"]`)
        const rect = anchorEl?.getBoundingClientRect()

        if (!rect) return null

        const scrollTop = document.documentElement.scrollTop

        return rect
          ? {
              ...rect.toJSON(),
              top: rect.top + scrollTop,
              bottom: rect.bottom + scrollTop,
              y: rect.y + scrollTop,
            }
          : null
      })
    })

    return () => cancelAnimationFrame(raf)
  }, [activeReviewItem?.id, suggestionCount])

  useEffect(() => {
    if (suggestionItems.length) {
      let raf = requestAnimationFrame(() => {
        raf = requestAnimationFrame(() => applySuggestionsRef.current())
      })
      return () => cancelAnimationFrame(raf)
    }
  }, [suggestionItems.length, review?.data?.content])

  useEffect(() => {
    const reviewResults = resultsRef.current

    if (!reviewResults.length) {
      reviewActions.setFlagsCount(id, 0)
      return
    }

    const raf = requestAnimationFrame(() =>
      requestAnimationFrame(() => {
        if (!ref.current) {
          reviewActions.setFlagsCount(id, 0)
          return
        }

        const count = [
          ...new Set(
            [...ref.current.querySelectorAll("[data-suggestion]")].flatMap(node =>
              (node.getAttribute("data-suggestion") as string).split(",")
            )
          ),
        ].filter(
          id =>
            !!reviewResults.find(
              item => item.id === id && item.status !== "accepted" && item.status !== "modified"
            )
        ).length

        reviewActions.setFlagsCount(id, count)
      })
    )

    return () => {
      cancelAnimationFrame(raf)
      reviewActions.setFlagsCount(id, 0)
    }
  }, [id, suggestionItems.length])

  const isReviewRunning =
    review?.status && (["running", "pending"] as ReviewInternalStatus[]).includes(review.status)

  if (!reviewRequestId && !isReviewRunning) return children

  if (!form.getValues("content") || isReviewRunning) {
    return (
      <ReviewEditorWrapper key={`review-editor-loading-${reviewRequestId}`}>
        {React.cloneElement(children, {
          ...children.props,
          key: `review-editor-loading-rich-text-${reviewRequestId}`,
          // @ts-expect-error typescript fails to partially apply types here
          disabled: true,
        })}
      </ReviewEditorWrapper>
    )
  }

  return (
    <>
      {reviewItemIdx !== null &&
        activeReviewItem &&
        anchorRect &&
        createPortal(
          <ReviewModal
            reviewItem={activeReviewItem}
            anchorRect={anchorRect}
            itemsCount={suggestionItems.length}
            index={reviewItemIdx}
            onClose={handleClose}
            onItemIndexChange={setReviewItemIdx}
            onAccept={handleReviewItemAccepted}
            onModify={handleReviewItemModified}
            onReject={handleReviewItemRejected}
          />,
          document.body
        )}
      <ReviewInformation {...reviewArgs} />
      <ReviewEditorWrapper
        key={`review-editor-active-${reviewRequestId}`}
        ref={ref}
        onClick={handleClick}
        activeItemId={activeReviewItem?.id}
        data-suggestion-editor
      >
        {React.cloneElement(children, {
          ...children.props,
          key: `review-editor-active-rich-text-${reviewRequestId}`,
          withSuggestions: true,
          suggestions: suggestionItems.map(result => result.id),
          disabled: true,
          editorRef: setEditorRef,
          ...(getChildProps(children.props, form) as any), // eslint-disable-line
        })}
      </ReviewEditorWrapper>
    </>
  )
}
