import { useState } from "react"
import { SectionTemplateDefinition } from "common/types/templates"
import { StyledNoTemlates, StyledTableWrapper, StyledTemplateType, StyledTemplatesWrapper } from "./styled"
import { StyledExpandArrow } from "settings/Library/LibraryTable/styled"
import { LibraryTable } from "settings/Library/LibraryTable"
import { SectionTemplatesTableViewModel } from "common/models/library"
import { useSectionTemplateForm } from "settings/Library/TemplateForms"
import { useAttributes } from "settings/Library/Filters"
import { Loading } from "common"
import { getDuplicateData } from "settings/Library/Tabs/SectionTemplates"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"

import { UPDATE_TEMPLATE_FORM_STATE_MESSAGES } from "../../constants"

export interface DocumentStructureFirmTemplatesProps {
  templatesType: string
  canEdit: boolean
  templates: SectionTemplateDefinition[]
  initialExpanded?: boolean
}

export function DocumentStructureFirmTemplates({
  templatesType,
  templates,
  canEdit,
  initialExpanded = false,
}: DocumentStructureFirmTemplatesProps): JSX.Element {
  const [expanded, setexpanded] = useState(initialExpanded)
  const attributes = useAttributes()
  const firmTemplateFeature = isFeatureEnabled(FEATURES.FIRM_TEMPLATE)
  const freezeFirmId = true

  if (!attributes) return <Loading show label="Loading attributes" />

  const templatesTableData = new SectionTemplatesTableViewModel(
    templates,
    attributes,
    firmTemplateFeature,
    freezeFirmId
  )

  return (
    <>
      <StyledTemplatesWrapper>
        <b>Template</b>
        <StyledTemplateType>{templatesType}</StyledTemplateType>
        {templates.length ? (
          <StyledExpandArrow expanded={expanded} onClick={() => setexpanded(!expanded)} />
        ) : (
          <StyledNoTemlates>No Firm Template Exists</StyledNoTemlates>
        )}
      </StyledTemplatesWrapper>
      {expanded && (
        <StyledTableWrapper>
          <LibraryTable
            tableData={templatesTableData}
            getFormData={useSectionTemplateForm}
            getDuplicateData={getDuplicateData}
            entityName="templates"
            canDuplicate={canEdit}
            formStatusMessageMap={UPDATE_TEMPLATE_FORM_STATE_MESSAGES}
          />
        </StyledTableWrapper>
      )}
    </>
  )
}
