import React from "react"
import { DOCUMENT_GENERATION_STATUS } from "api/services/documents/types"
import { theme } from "app/theme"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { StyledStatusCell } from "./styled"

export const DOCUMENT_STATUSES_DESCRIPTION_MAP: Record<DOCUMENT_GENERATION_STATUS, React.ReactNode> = {
  [DOCUMENT_GENERATION_STATUS.COMPLETE]: (
    <StyledStatusCell>
      <CheckCircleOutlineIcon htmlColor={theme.palette.success.light} />
      Success. Ready for download.
    </StyledStatusCell>
  ),
  [DOCUMENT_GENERATION_STATUS.ERROR]: (
    <StyledStatusCell>
      <ErrorOutlineIcon htmlColor={theme.palette.red.warning} />
      Error: Syncing issue. Contact Admin.
    </StyledStatusCell>
  ),
  [DOCUMENT_GENERATION_STATUS.IN_PROGRESS]: (
    <StyledStatusCell>
      <InfoOutlinedIcon htmlColor={theme.palette.blue.info} />
      Document generation in process.
    </StyledStatusCell>
  ),
  [DOCUMENT_GENERATION_STATUS.NOT_STARTED]: (
    <StyledStatusCell>
      <InfoOutlinedIcon htmlColor={theme.palette.blue.info} />
      Document generation in process.
    </StyledStatusCell>
  ),
}
