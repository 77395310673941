import { CircularProgress } from "@mui/material"
import { Value } from "./styled"
import { isValue } from "./utils"
import { Item } from "./type"
import { EmptyValue } from "./constants"

interface ValueComponent {
  isCreating: boolean
  draftName: string
  value: Item | EmptyValue
}

export default function ValueComponent({ isCreating, draftName, value }: ValueComponent): JSX.Element {
  if (isCreating) {
    return <Value icon={<CircularProgress size={16} />} label={draftName} />
  }

  if (!isValue(value)) {
    return <>None</>
  }

  return <Value label={value.name} color={value.color} />
}
