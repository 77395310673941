import { noop } from "lodash"
import { createContext } from "react"

export interface CollapseContextType {
  onCollapse(callback: (collapsed: boolean) => void): () => void
  collapse(): void
  expand(): void
}

export const CollapseContext = createContext<CollapseContextType>({
  onCollapse: () => noop,
  collapse: noop,
  expand: noop,
})
