import { FileObject, FileValidationError, validateFile } from "../validation"
import { Provider } from "./types"

export type ProviderValidationErrors = {
  filesToUpload?: FileValidationError[]
} | null

export const validateFilesToUpload = (filesToUpload: FileObject[]): FileValidationError[] | null => {
  const filesToUploadErrors = filesToUpload.map(file => validateFile(file))

  if (filesToUploadErrors.some(err => err)) {
    return filesToUploadErrors
  }
  return null
}

// only validates some fields, we also get validation messages back from the backend
export const validate = (providerToValidate: Provider): ProviderValidationErrors => {
  const createValidationErrors: ProviderValidationErrors = {}

  if (providerToValidate.filesToUpload) {
    const filesToUploadErrors = validateFilesToUpload(providerToValidate.filesToUpload)

    if (filesToUploadErrors) {
      createValidationErrors.filesToUpload = filesToUploadErrors
    }
  }
  return Object.keys(createValidationErrors).length > 0 ? createValidationErrors : null
}
