import Button from "@mui/material/Button"
import styled from "@emotion/styled"

interface TemplatesButtonProps {
  dense?: boolean
}

export const StyledViewTemplatesButton = styled(Button)<TemplatesButtonProps>(({ theme, dense }) => ({
  margin: !dense ? theme.spacing(1, 1, 1, 0) : 0,
  color: theme.palette.blue.main,
  textTransform: "capitalize",
  textDecoration: "underline",
  verticalAlign: "middle",
  fontSize: "inherit",
  fontWeight: "normal",

  "&:hover": {
    textDecoration: "underline",
  },
}))
