import { LibraryData, TableRowViewModel, TableViewModel } from "common/models/library"
import { Loading } from "common"
import EmptyState from "common/tables/EmptyState"
import { FORM_STATE_STATUS } from "../TemplateForms/constants"
import { LibraryTableRow } from "./LibraryTableRow"
import { LibraryUseForm } from "../TemplateForms/types"
import {
  StyledTable,
  StyledTableHeaderRow,
  StyledTableHeaderItem,
  StyledTableBody,
  StyledLoadingRow,
} from "./styled"

interface LibraryTableProps<T> {
  tableData: Nullable<TableViewModel<T>>
  loading?: boolean
  getFormData: LibraryUseForm<T, { canDelete?: boolean }>
  formStatusMessageMap: Record<FORM_STATE_STATUS, Nullable<JSX.Element>>
  entityName: string
  getDuplicateData: (rowData: T) => Partial<T>
  canDuplicate?: boolean
  getCanEdit?: (rowData: T) => boolean
  getEditFormFooter?: (row: TableRowViewModel<T>) => Nullable<JSX.Element>
  canEdit?: boolean
  onDuplicateClick?: (rowData: T) => void
}

export function LibraryTable<T extends LibraryData>({
  tableData,
  loading,
  getFormData,
  formStatusMessageMap,
  entityName,
  getEditFormFooter,
  getDuplicateData,
  onDuplicateClick,
  getCanEdit = () => true,
  canDuplicate = true,
}: LibraryTableProps<T>): Nullable<JSX.Element> {
  if (!tableData) return null

  const columnWidth = Math.floor(75 / tableData.headers.length)

  return (
    <StyledTable>
      <thead>
        <StyledTableHeaderRow>
          {tableData.headers.map(header => (
            <StyledTableHeaderItem width={`${columnWidth}%`} key={header}>
              {header}
            </StyledTableHeaderItem>
          ))}
          <StyledTableHeaderItem width="25%" />
        </StyledTableHeaderRow>
      </thead>
      <StyledTableBody>
        {tableData.rows.map(row => (
          <LibraryTableRow
            key={row.rowId}
            row={row}
            getEditFormFooter={getEditFormFooter}
            canDuplicate={canDuplicate}
            canEdit={getCanEdit(row.rowData)}
            getDuplicateData={getDuplicateData}
            getFormData={getFormData}
            formStatusMessageMap={formStatusMessageMap}
            entityName={entityName}
            onDuplicateClick={onDuplicateClick}
          />
        ))}
        {tableData.rows.length === 0 && (
          <tr>
            <td colSpan={tableData.headers.length + 1}>
              <EmptyState message={`No ${entityName}`} imageSize={"40px"} fontSize="medium" />
            </td>
          </tr>
        )}
        {loading && (
          <StyledLoadingRow>
            <td colSpan={tableData.headers.length + 1}>
              <Loading show label={`Loading ${entityName}...`} inline />
            </td>
          </StyledLoadingRow>
        )}
      </StyledTableBody>
    </StyledTable>
  )
}
