import { KeyboardEvent } from "react"
import isHotkey from "is-hotkey"
import { cloneDeep } from "lodash"
import { Element, Node } from "slate"
import { Editor } from "./Editor"
import { CustomEditor, EditorRoot } from "./CustomEditor"
import { LIST_HOTKEYS, STYLE_HOTKEYS, TEXT_HOTKEYS, COMMON_HOTKEYS } from "./hotkeys"
import { INLINE_ELEMENTS, LEAF_BLOCK_ELEMENTS } from "./elements"

type ReplacementType = string | number
type Replacements = Record<string, ReplacementType>

export function replaceMatches(original: EditorRoot, replacements: Replacements): EditorRoot {
  const nodes = cloneDeep(original)
  const replacementItems = Object.entries(replacements)

  for (const node of nodes) {
    replaceWith(node, replacementItems)
  }

  return nodes
}

function replaceWith(node: Node, replacements: [string, ReplacementType][]) {
  if ("children" in node) {
    for (const child of node.children) {
      replaceWith(child, replacements)
    }
  }

  if ("text" in node) {
    let text = node.text.replaceAll("\\[", "[").replaceAll("\\]", "]")

    for (const [key, value] of replacements) {
      text = text.replaceAll(key, `${value}`)
    }

    node.text = text
  }
}

export const handleKeyDown =
  (editor: CustomEditor) =>
  (event: KeyboardEvent): void => {
    for (const hotkey in STYLE_HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault()
        event.stopPropagation()

        const textStyle = STYLE_HOTKEYS[hotkey as keyof typeof STYLE_HOTKEYS]
        return Editor.toggleTextStyle(editor, textStyle)
      }
    }

    for (const hotkey in TEXT_HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        return TEXT_HOTKEYS[hotkey](editor, event)
      }
    }

    for (const hotkey in LIST_HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        if (Editor.isInList(editor)) {
          return LIST_HOTKEYS[hotkey](editor, event)
        }
      }
    }

    for (const hotkey in COMMON_HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        return COMMON_HOTKEYS[hotkey](editor, event)
      }
    }
  }

export function matchVariables(text: string): Nullable<RegExpMatchArray> {
  return text.match(/\[\[(.|\n)+?\]\]/gim)
}

export function createVariableTextNode(variableText: string): Nullable<string> {
  const normalizedText = variableText.replaceAll(/\n/g, "")

  if (!matchVariables(variableText)) return null

  const variableName = normalizedText.slice(2, -2).trim()

  return variableName ? `<span data-variable-name="${variableName}">[[${variableName}]]</span>` : null
}

export const editorHasVariable = (nodes: EditorRoot, variableName: string): boolean => {
  if (!nodes) return false
  let variables: string[] = []

  nodes.forEach(node => {
    variables = getAllVariablesInNode(node, variables)
  })

  return variables.includes(variableName)
}

const getAllVariablesInNode = (node: Node, variables: string[]): string[] => {
  if ("children" in node) {
    for (const child of node.children) {
      variables = getAllVariablesInNode(child, variables)
    }
  }

  if (!Element.isElement(node)) {
    return variables
  }

  if (node.type === INLINE_ELEMENTS.VARIABLE) {
    variables.push(node.name)
  }

  return variables
}

export function isEditorEmpty(nodes: EditorRoot): boolean {
  if (nodes.length > 1) return false

  return nodes.every(isNodeEmpty)
}

export function isNodeEmpty(node: Node): boolean {
  // Check for editor root element
  if (Editor.isEditor(node)) return isEditorEmpty(node.children)

  // Check for leaf nodes
  if (!Element.isElement(node)) {
    return node.text === ""
  }

  // Check for paragraph
  if (node.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH) {
    return node.children.every(isNodeEmpty)
  }

  // All other cases: variables, line-breaks, lists
  return false
}

export const editorHasText = (nodes: EditorRoot, textToFind: string): boolean => {
  if (!nodes) return false
  let textList: string[] = []

  nodes.forEach(node => {
    textList = getAllTextInNode(node, textList)
  })

  return textList.some(text => text.includes(textToFind))
}

const getAllTextInNode = (node: Node, textList: string[]): string[] => {
  if ("children" in node) {
    for (const child of node.children) {
      textList = getAllTextInNode(child, textList)
    }
  }

  if ("text" in node) {
    textList.push(node.text as string)
  }

  return textList
}
