import { useEffect } from "react"
import Box from "@mui/material/Box"
import { Link as RouterLink, useParams } from "react-router-dom"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { makeStyles } from "tss-react/mui"
import { useQuery } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "../react-query/constants"

import { getCase, getRelevantDocuments, getRequestByCase } from "../api"

import CaseResult from "../common/case-result/CaseResult"
import { GenericError } from "../common"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"
import MobileNavButton from "./LeftNav/MobileNavButton"
import { RequestPanelPageWrapper } from "requests/RequestPanelPageWrapper"

const useStyles = makeStyles()(theme => ({
  emptyCases: {
    padding: theme.spacing(4),
  },
}))

const NoSavedCases = function ({ caseId }) {
  const { classes } = useStyles()

  return (
    <Paper className={classes.emptyCases}>
      <Typography gutterBottom variant="h5">
        No cases saved under this case name.
      </Typography>
      <Typography gutterBottom component="p">
        Find and add cases through <RouterLink to={`/demands/${caseId}/search`}>Search</RouterLink>!
      </Typography>
    </Paper>
  )
}

export function RelevantCases() {
  const { id: caseId } = useParams()

  const { isLoading, data: incident, error } = useQuery([queryKeys.case, caseId], getCase)
  const { data: request } = useQuery([queryKeys.request, caseId], getRequestByCase, SILENT_QUERY_PARAMS)
  const documentIds = incident?.relevant_documents.map(document => document.elasticsearch_id)

  useEffect(() => {
    if (request) {
      amplitudeApm.trackEvent(
        new DemandAnalyticEvent(DemandAnalyticsEventTypes.RelevantCasesStarted, {
          demand_id: caseId,
          request_id: `${request.pk}`,
          request_type: request.type,
        })
      )

      return () =>
        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(DemandAnalyticsEventTypes.RelevantCasesExited, {
            demand_id: caseId,
            request_id: `${request.pk}`,
            request_type: request.type,
          })
        )
    }
  }, [caseId, request])

  const {
    isFetching: loadingDocuments,
    data: documents,
    error: documentsError,
  } = useQuery([queryKeys.documents, documentIds], getRelevantDocuments, {
    enabled: !!documentIds?.length,
  })

  if (error) return <GenericError error={error} />

  return (
    <RequestPanelPageWrapper request={request} caseId={caseId}>
      <Box mb={1}>
        <MobileNavButton />
      </Box>
      {!isLoading && <>{!documentIds?.length && <NoSavedCases caseId={caseId} />}</>}
      {!loadingDocuments &&
        documents?.results?.map(document => {
          document._is_relevant = true
          document.relevant_document =
            incident?.relevant_documents?.find(relevant_document => {
              return relevant_document.elasticsearch_id === document.id
            }) || null
          return <CaseResult data={document} key={document.id} caseId={caseId} />
        })}
      {documentsError && "An error has occurred: " + documentsError.message}
    </RequestPanelPageWrapper>
  )
}
