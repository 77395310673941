import { useEffect, useState, useCallback, useRef } from "react"
import { NumericFormat } from "react-number-format"
import TextField from "@mui/material/TextField"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableFooter from "@mui/material/TableFooter"
import TableRow from "@mui/material/TableRow"
import TableContainer from "@mui/material/TableContainer"

import Tooltip from "@mui/material/Tooltip"
import Slider from "@mui/material/Slider"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import { makeStyles } from "tss-react/mui"
import HelpOutline from "@mui/icons-material/HelpOutline"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useOutletContext } from "react-router-dom"
import { DateField, SelectInput } from "common/form-components"

import {
  calculateHouseholdLoss,
  deleteExhibit,
  getHousehold,
  getSectionMissingExhibits,
  updateHousehold,
  uploadExhibit,
  fetchPlaintiffInfoForCase,
} from "../api"
import { queryKeys } from "../react-query/constants"
import { useFormContext } from "./context"
import {
  formSectionsToRoutes,
  HOUSEHOLD_SERVICE_FILE_OPTIONS,
  IMPAIRMENT_TIME_UNIT_MAP,
  STEP_STATUSES,
} from "./constants"

import { ExhibitList } from "../common"
import { FileUploader } from "./FileUploader"
import { Controller, useForm } from "react-hook-form"
import { getChangedFields, getCommonMutateOptions } from "../utils"
import useAutosave from "../hooks/useAutosave"
import size from "lodash/size"
import MissingLossOfHouseholdAlert from "./Alerts/MissingLossOfHouseholdAlert"
import { isEmpty } from "lodash"

import RelevantDatesTable from "./RelevantDatesTable"
import MissingDocumentSection from "./MissingDocumentSection"
import { SECTIONS } from "../missing-docs/constants"
import useUser from "../hooks/useUser"
import { isNotNotSetupRole } from "../common/permission"
import { getDifferenceInYears, isDateEarlier } from "./utils"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import { useFileUploader } from "common/file-uploader"
import { queryClient } from "../react-query/queryClient"

import { FormGroup } from "@mui/material"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"
import * as Sentry from "@sentry/browser"
import SimpleDemandSkipSectionBanner from "./SimpleDemandBanner"
import useCase from "hooks/useCase"

const INITIAL_FORM_STATE = {
  start_of_household_impairment_date: "",
  end_of_household_impairment_date: "",
  household_impairment_rate: 0,
  future_household_impaired_days: 0,
  future_household_impaired_years: 0,
  future_household_impairment_rate: 0,
  household_impairment_timeframe: "years",
}

const useStyles = makeStyles()(theme => ({
  formFields: {
    marginTop: theme.spacing(4),
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(4),
  },
  actions: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  fullWidth: {
    gridColumn: "1 / 4",
  },
  helpIcon: {
    fontSize: "1rem",
    verticalAlign: "bottom",
  },
  dateTableBox: {
    margin: theme.spacing(4, 2),
  },
  tableHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  calculationMessage: {
    margin: theme.spacing(2),
    textAlign: "center",
  },
  summaryFooterRow: {
    backgroundColor: "#757575",
    color: "white !important",
    "& > *": {
      color: "white",
      fontWeight: 700,
    },
  },
  ageAlert: {
    marginTop: theme.spacing(1),
  },
}))

function HouseholdLossesCalculation({
  startDate,
  endDate,
  state,
  subsequentImpairedAmount,
  householdImparmentRate,
  futureImparementRate,
}) {
  const { classes } = useStyles()
  const { caseId } = useFormContext()
  const [isDirty, setIsDirty] = useState(false)
  const { currentPlaintiff } = useOutletContext()
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  const { error, data, refetch } = useQuery(
    multiPlaintiffEnabled
      ? [queryKeys.calculateHousehold, caseId, currentPlaintiff?.id, "results"]
      : [queryKeys.calculateHousehold, caseId, "results"],
    () => {
      setIsDirty(true)
      return calculateHouseholdLoss(caseId, currentPlaintiff?.id)
    },
    {
      enabled:
        (!!startDate && !!endDate && !!state && !!currentPlaintiff?.id && multiPlaintiffEnabled) ||
        (!!startDate && !!endDate && !!state && !multiPlaintiffEnabled),
      meta: {
        disableLoader: true,
      },
      onSettled: () => {
        setIsDirty(false)
      },
    }
  )

  useEffect(() => {
    refetch()
  }, [
    startDate,
    endDate,
    state,
    subsequentImpairedAmount,
    householdImparmentRate,
    futureImparementRate,
    refetch,
  ])

  useEffect(() => {
    if (!multiPlaintiffEnabled) return
    queryClient.invalidateQueries([queryKeys.calculateHousehold, caseId, currentPlaintiff?.id, "results"])
  }, [currentPlaintiff?.id, caseId, multiPlaintiffEnabled])

  if (isDirty) return <Box align="center">Loading calculation...</Box>
  if (error) {
    return (
      <div className={classes.calculationMessage}>
        <Typography>There was an error getting the calculation.</Typography>
      </div>
    )
  }
  if (!data?.results || isEmpty(data?.results?.start_date))
    return (
      <div className={classes.calculationMessage}>
        <Typography>Fill out form to see loss calculation.</Typography>
      </div>
    )

  const dateFormat = new Intl.DateTimeFormat("en-US")
  const sum = (x, y) => x + y

  return (
    <TableContainer>
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell align="right">Hourly Rate</TableCell>
            <TableCell align="right">Hours/day</TableCell>
            <TableCell align="right">% Impaired</TableCell>
            <TableCell align="right">Net Loss</TableCell>
            <TableCell align="right">Time Adj. Net Loss</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data?.results.year).map(k => (
            <TableRow key={k}>
              <TableCell>
                <NumericFormat value={data.results.year[k]} displayType="text" />
              </TableCell>
              <TableCell>{dateFormat.format(Date.parse(data.results.start_date[k]))}</TableCell>
              <TableCell>{dateFormat.format(Date.parse(data.results.end_date[k]))}</TableCell>
              <TableCell align="right">
                <NumericFormat
                  value={parseFloat(data.results.hourly_rate[k]).toFixed(2)}
                  displayType="text"
                  decimalScale="2"
                  fixedDecimalScale="true"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  value={parseFloat(data.results.hours_per_day[k]).toFixed(2)}
                  displayType="text"
                  decimalScale="2"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  value={data.results.percent_impaired[k] * 100}
                  suffix="%"
                  displayType="text"
                  decimalScale="0"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  value={data.results.net_loss[k]}
                  prefix="$"
                  displayType="text"
                  decimalScale="2"
                  thousandSeparator=","
                  fixedDecimalScale="true"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  value={data.results.time_adj_net_loss[k]}
                  prefix="$"
                  displayType="text"
                  decimalScale="2"
                  thousandSeparator=","
                  fixedDecimalScale="true"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {!isEmpty(data?.results?.start_date) && !isEmpty(data?.results?.end_date) && (
          <TableFooter>
            <TableRow className={classes.summaryFooterRow}>
              <TableCell>
                <Typography>Total</Typography>
              </TableCell>
              <TableCell>{dateFormat.format(Date.parse(data.results.start_date["0"]))}</TableCell>
              <TableCell>
                {dateFormat.format(
                  Date.parse(data.results.end_date[Object.keys(data.results.end_date).length - 1])
                )}
              </TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right">
                <NumericFormat
                  value={
                    !isEmpty(data?.results?.net_loss)
                      ? Object.values(data?.results?.net_loss).reduce(sum).toFixed(2)
                      : 0
                  }
                  prefix="$"
                  displayType="text"
                  decimalScale="2"
                  thousandSeparator=","
                  fixedDecimalScale="true"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  value={
                    !isEmpty(data?.results?.time_adj_net_loss)
                      ? Object.values(data?.results?.time_adj_net_loss).reduce(sum).toFixed(2)
                      : 0
                  }
                  prefix="$"
                  displayType="text"
                  decimalScale="2"
                  thousandSeparator=","
                  fixedDecimalScale="true"
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}

export function HouseholdServices({ lastVisited }) {
  const { classes } = useStyles()
  const { saveRef, currentPlaintiff } = useOutletContext()
  const { control, handleSubmit, reset, formState, watch, getValues, setValue } = useForm({
    defaultValues: INITIAL_FORM_STATE,
  })
  const { caseId, queryClient, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus, request } =
    useFormContext()
  const [errors, setErrors] = useState(null)
  const [exhibits, setExhibits] = useState(null)
  const [fileToUpload, setFileToUpload] = useState(null)
  const [plaintiffInfo, setPlaintiffInfo] = useState(null)
  const { caseObj } = useCase(caseId)

  // Local state to hold the slider value temporarily
  const [localHouseholdImparmentRate, setLocalHouseholdImparmentRate] = useState(
    watch("household_impairment_rate")
  )
  const [localFutureHouseholdImpairmentRate, setLocalFutureHouseholdImpairmentRate] = useState(
    watch("future_household_impairment_rate")
  )

  const startDate = watch("start_of_household_impairment_date")
  const endDate = watch("end_of_household_impairment_date")
  const householdImpairmentTimeframe = watch("household_impairment_timeframe")

  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  const futureImpairmentError =
    householdImpairmentTimeframe === "years"
      ? errors?.future_household_impaired_years
      : errors?.future_household_impaired_days
  const futureImpairmentTooltipTitle =
    householdImpairmentTimeframe === "years" ? `Decimal values are accepted for years, i.e. "10.17"` : ""

  useEffect(() => {
    if (householdImpairmentTimeframe === "years") {
      setValue("future_household_impaired_days", 0, { shouldDirty: false })
    } else {
      setValue("future_household_impaired_years", 0, { shouldDirty: false })
    }
    // after selecting timeframe, focus on the amount textfield
    subsequentImpairedTextRef.current?.focus()
  }, [householdImpairmentTimeframe, setValue])

  const getFutureImpairmentLabel = timeframe => {
    const impairmentTimeLabel = `Subsequent Impaired ${IMPAIRMENT_TIME_UNIT_MAP.get(timeframe)?.display}`
    if (timeframe === "years") {
      return (
        <Box display={"flex"}>
          <Box>{impairmentTimeLabel}</Box>
          <Box ml={1} mt={-0.5}>
            <HelpOutline />
          </Box>
        </Box>
      )
    } else {
      return impairmentTimeLabel
    }
  }

  const handleStartDate = useCallback(
    newStartDate => {
      setValue("start_of_household_impairment_date", newStartDate, { shouldDirty: true })
    },
    [setValue]
  )

  const handleEndDate = useCallback(
    newEndDate => setValue("end_of_household_impairment_date", newEndDate, { shouldDirty: true }),
    [setValue]
  )

  const { uploadFiles } = useFileUploader()
  const { showMessage } = useHandleMessages()

  const { data: plaintiffsInfo } = useQuery([queryKeys.plaintiff, caseId], async () => {
    return await fetchPlaintiffInfoForCase(caseId)
  })

  useEffect(() => {
    if (!plaintiffsInfo) return

    if (multiPlaintiffEnabled && currentPlaintiff) {
      const selectedPlaintiff = plaintiffsInfo.find(plaintiff => plaintiff.pk === currentPlaintiff.id)
      setPlaintiffInfo(selectedPlaintiff)
    } else {
      setPlaintiffInfo(plaintiffsInfo[0])
    }
  }, [plaintiffsInfo, currentPlaintiff, multiPlaintiffEnabled])

  const startOfImpairmentDate = getValues("start_of_household_impairment_date")
  const isStartOfImpairmentDateOutOfRange =
    Boolean(plaintiffInfo) &&
    Boolean(startOfImpairmentDate) &&
    isDateEarlier(startOfImpairmentDate, plaintiffInfo.date_of_birth)
  const hasZeroHouseholdPeriods =
    Boolean(plaintiffInfo) &&
    Boolean(startOfImpairmentDate) &&
    !isStartOfImpairmentDateOutOfRange &&
    getDifferenceInYears(startOfImpairmentDate, plaintiffInfo.date_of_birth) < 15

  const { data: householdData } = useQuery([queryKeys.household, caseId], () => getHousehold(caseId))

  useEffect(() => {
    if (!householdData) return

    let plaintiffHouseholdData = householdData
    if (multiPlaintiffEnabled && currentPlaintiff) {
      plaintiffHouseholdData = householdData.find(
        plaintiff => plaintiff && plaintiff.pk === currentPlaintiff.id
      )
    } else if (multiPlaintiffEnabled) {
      plaintiffHouseholdData = householdData?.[0]
    }

    if (!plaintiffHouseholdData) {
      Sentry.captureMessage(
        `HouseholdServices data could not be found. MultiPlaintiff=${multiPlaintiffEnabled}; Current Plaintiff${currentPlaintiff?.id}`,
        "warning"
      )
      return
    }

    plaintiffHouseholdData.future_household_impaired_days =
      plaintiffHouseholdData.future_household_impaired_days ?? 0
    plaintiffHouseholdData.future_household_impaired_years =
      plaintiffHouseholdData.future_household_impaired_years ?? 0
    plaintiffHouseholdData.household_impairment_timeframe =
      plaintiffHouseholdData.future_household_impaired_days > 0 ? "days" : "years"

    reset(plaintiffHouseholdData)
    setExhibits(
      plaintiffHouseholdData?.exhibits?.map(exhibit => {
        return { ...exhibit, pk: exhibit.id }
      })
    )
  }, [householdData, currentPlaintiff, reset, multiPlaintiffEnabled, plaintiffInfo])

  const { user } = useUser()

  const { data: missingExhibits } = useQuery(
    [queryKeys.missingExhibits, caseId],
    async () => {
      return await getSectionMissingExhibits({ caseId: caseId, section: SECTIONS.HOUSEHOLD_LOSS })
    },
    {
      enabled: isNotNotSetupRole(user.role),
    }
  )

  const subsequentImpairedTextRef = useRef()

  const mutation = useMutation(
    updateHousehold,
    getCommonMutateOptions({
      setErrors,
      reset,
      setSavedSuccessfully,
      showErrorMessage,
      onSuccessExtra: () => {
        handleUpdateStepStatus({ status: STEP_STATUSES.completed })
        queryClient.invalidateQueries([queryKeys.calculateHousehold, caseId, currentPlaintiff?.id, "results"])
        queryClient.invalidateQueries([queryKeys.household])
      },
    })
  )

  // succeed/fail silently
  const autosaveMutation = useMutation(updateHousehold)

  const deleteFileMutation = useMutation(deleteExhibit, {
    onSuccess: () => {
      showMessage({
        type: "success",
        message: "Deleted file",
      })
      queryClient.invalidateQueries([queryKeys.household])
    },
    onError: error => {
      showMessage({
        type: "error",
        message:
          "Error occurred deleting file. Please try again shortly or contact a dev if your problem persists.",
        error,
      })
    },
  })
  const uploadFileMutation = useMutation(uploadExhibit, {
    onSuccess: () => {
      setFileToUpload(null)
      queryClient.invalidateQueries([queryKeys.household])
    },
    onError: error => {
      showMessage({
        type: "error",
        message:
          "Error occurred uploading file. Please try again shortly or contact a dev if your problem persists.",
        error,
      })
    },
  })
  const handleFileChange = async (file, remove = false) => {
    if (remove) {
      deleteFileMutation.mutate({ exhibitId: file.id, caseId })
    } else {
      const {
        items: [upload],
      } = await uploadFiles([file.file])

      if (upload.success) {
        const fileData = new FormData()
        const { documentType, name } = file

        fileData.append("upload_id", upload.uploadId)
        fileData.append("name", name)
        fileData.append("type", documentType)
        fileData.append("section", "household_loss")
        fileData.append("plaintiff_id", currentPlaintiff?.id ?? null)

        uploadFileMutation.mutate({ data: fileData, caseId })
      }
    }
  }

  const handleOnChange = handleSubmit(async data => {
    if (fileToUpload) {
      await handleFileChange(fileToUpload)
    }

    const changesFields = getChangedFields(data, formState)

    // checking if the only change is the household_impairment_timeframe dropdown, if so, we should not make network call
    // since there is no actual field for household_impairment_timeframe that is being saved
    // its only by future_household_impaired_years or future_household_impaired_days so we wait for user to enter
    if (size(changesFields) === 1 && !!changesFields.household_impairment_timeframe) return

    if (size(changesFields) > 0) {
      return mutation.mutateAsync({
        caseId,
        data: {
          ...data,
          plaintiff_id: currentPlaintiff?.id,
          future_household_impaired_years: parseFloat(data.future_household_impaired_years) || 0,
          future_household_impaired_days: parseFloat(data.future_household_impaired_days) || 0,
        },
      })
    }
  })

  useEffect(() => {
    saveRef.current = handleOnChange
  }, [handleOnChange, saveRef])

  useAutosave({
    shouldAutosave: formState.isDirty && getValues("pk"),
    save: () => {
      const data = {
        ...getValues(),
        case_id: caseId,
      }

      autosaveMutation.mutate({
        caseId,
        data: {
          ...data,
          future_household_impaired_years: parseFloat(data.future_household_impaired_years) || 0,
          future_household_impaired_days: parseFloat(data.future_household_impaired_days) || 0,
        },
      })
    },
  })

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.household_loss
  }, [lastVisited])

  return (
    <>
      <Box mb={2}>
        <SimpleDemandSkipSectionBanner caseId={caseId} />
      </Box>
      <MissingLossOfHouseholdAlert caseId={caseId} currentPlaintiff={currentPlaintiff} />
      <RelevantDatesTable />
      <Divider />
      {isStartOfImpairmentDateOutOfRange && (
        <Alert className={classes.ageAlert} severity="error" data-test="hh-plaintiff-age-error">
          Initial impairment date could not be before Plaintiff&apos;s birth date.
        </Alert>
      )}
      {hasZeroHouseholdPeriods && (
        <Alert className={classes.ageAlert} severity="warning" data-test="hh-plaintiff-age-warning">
          Plaintiff age is less than 15 for several periods. Hours/day will show 0 for those periods.
        </Alert>
      )}
      <form className={classes.formFields} noValidate onBlur={handleOnChange}>
        <DateField
          error={errors?.start_of_household_impairment_date}
          helperText={errors?.start_of_household_impairment_date}
          label="Initial Impairment Date"
          initialValue={startDate}
          onChange={handleStartDate}
          onClose={handleOnChange}
          fieldProps={{
            name: "start_of_household_impairment_date",
          }}
        />
        <DateField
          error={errors?.end_of_household_impairment_date}
          helperText={errors?.end_of_household_impairment_date}
          label="End of Initial Impairment Date"
          initialValue={endDate}
          onChange={handleEndDate}
          onClose={handleOnChange}
          fieldProps={{
            name: "end_of_household_impairment_date",
          }}
        />

        <Box>
          <Typography id="slider-label" gutterBottom>
            Past impairment Rate
          </Typography>

          <Controller
            name="household_impairment_rate"
            control={control}
            render={({ field }) => (
              <Slider
                size="small"
                aria-labelledby="slider-label"
                valueLabelDisplay="auto"
                {...field}
                onChange={(_, value) => {
                  setValue("household_impairment_rate", value, {
                    shouldDirty: true,
                  })
                }}
                onChangeCommitted={async (_, value) => {
                  await handleOnChange()
                  setLocalHouseholdImparmentRate(value)
                }}
              />
            )}
          />
        </Box>
        <SelectInput
          name="household_impairment_timeframe"
          control={control}
          label="Household Impairment Timeframe"
          options={Array.from(IMPAIRMENT_TIME_UNIT_MAP.values())}
        />
        <Controller
          key={`future_household_impaired_${householdImpairmentTimeframe}`}
          name={`future_household_impaired_${householdImpairmentTimeframe}`}
          control={control}
          render={({ field }) => (
            <Tooltip placement="top" title={futureImpairmentTooltipTitle}>
              <FormGroup>
                <TextField
                  inputRef={subsequentImpairedTextRef}
                  type="number"
                  label={getFutureImpairmentLabel(householdImpairmentTimeframe)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: 0,
                  }}
                  error={futureImpairmentError}
                  helperText={futureImpairmentError}
                  {...field}
                />
              </FormGroup>
            </Tooltip>
          )}
        />
        <Box>
          <Typography id="future-slider-label" gutterBottom>
            Future impairment Rate
          </Typography>
          <Controller
            name="future_household_impairment_rate"
            control={control}
            render={({ field }) => (
              <Slider
                size="small"
                aria-labelledby="future-slider-label"
                valueLabelDisplay="auto"
                {...field}
                onChange={(_, value) => {
                  setValue("future_household_impairment_rate", value, {
                    shouldDirty: true,
                  })
                }}
                onChangeCommitted={async (_, value) => {
                  await handleOnChange()
                  setLocalFutureHouseholdImpairmentRate(value)
                }}
              />
            )}
          />
        </Box>
        {!!exhibits?.length && (
          <Box className={classes.fullWidth}>
            <ExhibitList
              caseId={caseId}
              exhibits={exhibits}
              fileTypeMap={HOUSEHOLD_SERVICE_FILE_OPTIONS}
              onDelete={exhibit => handleFileChange(exhibit, true)}
              onSuccessfulSave={() => queryClient.invalidateQueries([queryKeys.household])}
            />
          </Box>
        )}
      </form>
      {!caseObj?.use_exhibit_builder && (
        <Box className={classes.fullWidth}>
          <FileUploader
            fileTypes={HOUSEHOLD_SERVICE_FILE_OPTIONS}
            fileToUpload={fileToUpload}
            setFileToUpload={setFileToUpload}
            handleFileChange={handleFileChange}
          />
        </Box>
      )}
      {request?.pk && (
        <Box className={classes.fullWidth} mt={2}>
          <MissingDocumentSection
            missingDocs={missingExhibits}
            title="Missing Documents List"
            section={SECTIONS.HOUSEHOLD_LOSS}
          />
        </Box>
      )}
      <HouseholdLossesCalculation
        householdImparmentRate={localHouseholdImparmentRate}
        futureImparementRate={localFutureHouseholdImpairmentRate}
        subsequentImpairedAmount={getValues(`future_household_impaired_${householdImpairmentTimeframe}`)}
        startDate={startDate}
        endDate={endDate}
        state={plaintiffInfo?.state}
        plaintiffId={plaintiffInfo?.pk}
      />
    </>
  )
}
