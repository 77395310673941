import React, { ReactNode, useCallback, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import Box from "@mui/material/Box"
import { DateField, SelectInput } from "common/form-components"
import { Alert, Button, CircularProgress } from "@mui/material"
import { formatDate, getEndOfPreviousMonth, getStartOfPreviousMonth } from "utils"
import styled from "@emotion/styled"

import { Typography } from "@mui/material"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { usePermissions } from "permissions/usePermissions"
import { theme } from "app/theme"
import { STATEMENT_OF_WORK_DATE_FORMAT } from "./constants"
import { ReceiptGenerationContainer, ReceiptGenerationContainerItem, SectionTitle } from "./styled"
import { downloadFileBlob } from "requests/utils"
import { useMutation } from "@tanstack/react-query"
import { receiptGenerationService } from "api/services/receipt-generation/services"
import { ApiError } from "apiHelper"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import useUser from "hooks/useUser"
import { BillingAnalyticEvent, BillingAnalyticsEventTypes } from "infrastructure/apm/events/BillingEvents"
import Tooltip from "common/Tooltip"
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns"
import { noop } from "lodash"
import { parseError } from "common/errorUtils"
import { FileFormat } from "api/services/receipt-generation/types"

interface StatementOfWorkProps {
  firmId: number | null
  children?: ReactNode
}

interface FormValues {
  file_format: FileFormat
}

const StyledDropdown = styled(SelectInput<FormValues, FileFormat>)(({ theme }) => ({
  width: `calc((100% - ${theme.spacing(2.5)}) / 2)`,
}))

export const StatementOfWorkGeneration: React.FC<StatementOfWorkProps> = ({ firmId, children }) => {
  const { canGenerateReceiptsEnabled } = usePermissions({ firmId: firmId, suspense: false })
  const [startDate, setStartDate] = useState<Nullable<string>>(
    formatDate(getStartOfPreviousMonth(new Date()), STATEMENT_OF_WORK_DATE_FORMAT)
  )
  const [endDate, setEndDate] = useState<Nullable<string>>(
    formatDate(getEndOfPreviousMonth(new Date()), STATEMENT_OF_WORK_DATE_FORMAT)
  )
  const { showMessage, showErrorMessage } = useHandleMessages()

  const { user } = useUser()
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { file_format: "pdf" },
  })

  const handleSetStartDate = (date: Nullable<string>) => {
    setStartDate(date)

    amplitudeApm.trackEvent(
      new BillingAnalyticEvent(BillingAnalyticsEventTypes.CollectiveStatementStartDateEdited, {
        user_id: user.id,
        firm_id: firmId,
        start_date: startDate,
      })
    )
  }
  const handleSetEndDate = (date: Nullable<string>) => {
    setEndDate(date)

    amplitudeApm.trackEvent(
      new BillingAnalyticEvent(BillingAnalyticsEventTypes.CollectiveStatementEndDateEdited, {
        user_id: user.id,
        firm_id: firmId,
        end_date: endDate,
      })
    )
  }

  const setPreviousWeek = useCallback(() => {
    const start = startDate ? new Date(startDate) : new Date()
    /**
     * "Start of week" is defined as 8 AM, but since we're converting between strings
     *  and dates, we lose the 'time' on each conversion so 'startOfWeek' will take us back one week
     **/
    setStartDate(formatDate(startOfWeek(start), STATEMENT_OF_WORK_DATE_FORMAT))
    setEndDate(formatDate(endOfWeek(start), STATEMENT_OF_WORK_DATE_FORMAT))

    amplitudeApm.trackEvent(
      new BillingAnalyticEvent(BillingAnalyticsEventTypes.CollectiveStatementPreviousWeekClicked, {
        user_id: user.id,
        firm_id: firmId,
      })
    )
  }, [firmId, user.id, startDate])

  const setPreviousMonth = useCallback(() => {
    const start = startDate ? new Date(startDate) : new Date()
    /**
     * "Start of month" is defined as 8 AM, but since we're converting between strings
     *  and dates, we lose the 'time' on each conversion so 'startOfMonth' will take us back one month
     **/
    setStartDate(formatDate(startOfMonth(start), STATEMENT_OF_WORK_DATE_FORMAT))
    setEndDate(formatDate(endOfMonth(start), STATEMENT_OF_WORK_DATE_FORMAT))

    amplitudeApm.trackEvent(
      new BillingAnalyticEvent(BillingAnalyticsEventTypes.CollectiveStatementPreviousMonthClicked, {
        user_id: user.id,
        firm_id: firmId,
      })
    )
  }, [firmId, user.id, startDate])

  const generateStatementOfWork = useMutation(receiptGenerationService.generateBulkReceipt, {
    onSuccess: ({ data }) => {
      downloadFileBlob(`evenup-statement-${startDate}-${endDate}`, data, {
        base64: true,
        extension: getValues("file_format"),
      })

      amplitudeApm.trackEvent(
        new BillingAnalyticEvent(BillingAnalyticsEventTypes.CollectiveStatementDownloadSuccessful, {
          user_id: user.id,
          start_date: startDate,
          end_date: endDate,
          firm_id: firmId,
          file_format: getValues("file_format"),
        })
      )
    },
    onError: (response: ApiError) => {
      const errorMessage = "There was an error generating your receipt."
      const error = parseError(response)

      showMessage({
        type: "error",
        message: error?.userFriendlyMessage || errorMessage,
        error,
      })

      amplitudeApm.trackEvent(
        new BillingAnalyticEvent(BillingAnalyticsEventTypes.CollectiveStatementDownloadError, {
          user_id: user.id,
          start_date: startDate,
          end_date: endDate,
          firm_id: firmId,
          file_format: getValues("file_format"),
        })
      )
    },
  })

  const handleGenerateStatementOfWork = useCallback(
    (startDate: string, endDate: string) => {
      generateStatementOfWork
        .mutateAsync({
          firm_id: firmId,
          start_date: startDate,
          end_date: endDate,
          file_format: getValues("file_format"),
        })
        .catch(noop)

      amplitudeApm.trackEvent(
        new BillingAnalyticEvent(BillingAnalyticsEventTypes.CollectiveStatementDownloadStarted, {
          user_id: user.id,
          start_date: startDate,
          end_date: endDate,
          firm_id: firmId,
          file_format: getValues("file_format"),
        })
      )
    },
    [firmId, generateStatementOfWork, user.id, getValues]
  )

  const handleGenerateStatement = useCallback(() => {
    if (!firmId) {
      showMessage({
        type: "error",
        message: "Please select a firm.",
      })
      return
    }

    if (!startDate || !endDate) {
      showErrorMessage("Please provide a start and end date.")
      return
    }

    handleGenerateStatementOfWork(startDate, endDate)
  }, [firmId, startDate, endDate, handleGenerateStatementOfWork, showMessage, showErrorMessage])

  //const isDownloadButtonDisabled = !firmId || !startDate || !endDate || generateStatementOfWork.isLoading

  const { disabled: isDownloadButtonDisabled, reason: downloadDisabledReason } = useMemo<{
    disabled: boolean
    reason?: string
  }>(() => {
    if (!firmId) {
      return { disabled: true, reason: "No firm selected" }
    }
    if (!startDate || !endDate) {
      return { disabled: true, reason: "Enter both start and end date" }
    }
    if (generateStatementOfWork.isLoading) {
      return { disabled: true, reason: "Generating statement of work" }
    }
    if (new Date(endDate).getTime() < new Date(startDate).getTime()) {
      return { disabled: true, reason: "Start date must be before end date" }
    }
    return { disabled: false }
  }, [firmId, startDate, endDate, generateStatementOfWork.isLoading])

  if (!canGenerateReceiptsEnabled) {
    return <></>
  }

  // Show warning for receipts generated before September 2023, since Billing system was changed in August
  const showInaccurateReceiptsWarning = new Date(startDate || "") < new Date("2023-09-01")

  return (
    <ReceiptGenerationContainer>
      <SectionTitle>Collective Work Statement</SectionTitle>
      <Typography fontSize={theme.typography.pxToRem(14)} mb={2}>
        Choose a date range to generate your Collective Work Statement.
      </Typography>
      {children}
      <ReceiptGenerationContainerItem>
        <DateField
          initialValue={startDate}
          onChange={date => handleSetStartDate(date)}
          label="Start Date"
          fieldProps={{ fullWidth: true }}
          size="small"
        />
        <DateField
          initialValue={endDate}
          onChange={date => handleSetEndDate(date)}
          label="End Date"
          fieldProps={{ fullWidth: true }}
          size="small"
        />
      </ReceiptGenerationContainerItem>
      <ReceiptGenerationContainerItem>
        <Button
          onClick={setPreviousWeek}
          variant="outlined"
          color="secondary"
          data-test="generate-statement-previous-week"
          sx={{ borderRadius: 2 }}
          size="small"
        >
          Previous Week
        </Button>
        <Button
          onClick={setPreviousMonth}
          variant="outlined"
          color="secondary"
          data-test="generate-statement-previous-month"
          sx={{ borderRadius: 2 }}
          size="small"
        >
          Previous Month
        </Button>
      </ReceiptGenerationContainerItem>
      {showInaccurateReceiptsWarning && (
        <Box mb={3} data-test="inaccurate-receipts-warning">
          <Alert severity="warning">Receipts from earlier than September 2023 may be inaccurate</Alert>
        </Box>
      )}
      <StyledDropdown
        name="file_format"
        label="File Format"
        size="small"
        options={[
          { display: "CSV", key: "csv" },
          { display: "PDF", key: "pdf" },
        ]}
        control={control}
        errors={errors}
      />
      <Box display="flex" justifyContent="end">
        <Tooltip
          tooltipText={downloadDisabledReason || ""}
          disabled={isDownloadButtonDisabled}
          onClick={handleGenerateStatement}
          data-test="generate-statement-of-work-download-button"
        >
          <Button
            variant="contained"
            sx={{
              background: theme.palette.blue.primary,
              "&:hover": {
                backgroundColor: theme.palette.blue.primary,
              },
            }}
          >
            {generateStatementOfWork.isLoading && (
              <CircularProgress size="1em" style={{ color: "white", marginRight: "8px" }} />
            )}
            Download
          </Button>
        </Tooltip>
      </Box>
    </ReceiptGenerationContainer>
  )
}
