import { SortableNestedListItemProps } from "common/nested-list/SortableNestedListItem"
import { ExhibitItem } from "../types"
import { StyledExhibitListItem } from "./styled"
import { ExhibitPartitions } from "./ExhibitPartitions"
import { CollapseStateContext, useCollapseState } from "common/CollapseManager/useCollapseState"
import { Collapse } from "@mui/material"
import { useDocumentStore } from "documents/store"
import { exhibitSelectors } from "documents/store/exhibits"

export type ExhibitListItemProps = SortableNestedListItemProps<ExhibitItem>

export function ExhibitListItem({ item, inline, ...props }: ExhibitListItemProps): JSX.Element {
  const [collapsed, setCollapsed] = useCollapseState()
  const exhibit = useDocumentStore(exhibitSelectors.getExhibitById(item.id))

  return (
    <CollapseStateContext.Provider value={[collapsed, setCollapsed]}>
      <StyledExhibitListItem item={item} {...props} state={exhibit.state}>
        {!inline && (
          <Collapse in={!collapsed} mountOnEnter>
            <ExhibitPartitions exhibitId={item.id} />
          </Collapse>
        )}
      </StyledExhibitListItem>
    </CollapseStateContext.Provider>
  )
}
