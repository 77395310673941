import React from "react"
import { getCalendarDataForDay } from "./demandCalendarUtilities"
import { ReactCalendarCell } from "./styled"
import { ReactCalendarBaseProps } from "./types"

type DemandCalendarDayProps<DayT> = {
  year: number
  month: number
  day: number
} & ReactCalendarBaseProps<DayT>

export function DemandCalendarDay<DayT>({
  dayComponent,
  groupedData,
  ...props
}: DemandCalendarDayProps<DayT>): React.ReactElement {
  const isHighlightedDay =
    props.highlightedDay.getUTCDate() === props.day &&
    props.highlightedDay.getUTCMonth() + 1 === props.month &&
    props.highlightedDay.getUTCFullYear() === props.year

  return (
    <ReactCalendarCell
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      data-test={`calendar-day-${props.year}-${props.month}-${props.day}`}
    >
      {dayComponent &&
        dayComponent({
          isHighlightedDay,
          dayData: getCalendarDataForDay(groupedData, props.year, props.month, props.day),
          ...props,
        })}
    </ReactCalendarCell>
  )
}
