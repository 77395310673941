import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import useUser from "../../hooks/useUser"
import { makeStyles } from "tss-react/mui"
import { Link } from "react-router-dom"

import { USER_ROLES } from "../../common/models/roles"

const useStyles = makeStyles()(theme => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  user: {
    padding: theme.spacing(2, 0),
    width: theme.spacing(60),
  },
  actions: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateColumns: "1fr 1fr",
    marginTop: theme.spacing(2),
  },
}))

export function Profile() {
  const { user } = useUser()
  const { classes } = useStyles()

  return (
    <>
      <Box className={classes.user}>
        <Box className={classes.header}>
          <Box>
            <Typography variant="h5" component="h2">
              {user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : "(No Name)"}
            </Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Box>
          <Chip label={USER_ROLES[user.role].display} />
        </Box>
        <Box className={classes.actions}>
          <Button color="primary" variant="outlined" component={Link} to={`/settings/profile/change-email`}>
            Change Email
          </Button>
        </Box>
      </Box>
    </>
  )
}
