import { useEffect, useState } from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Pagination from "@mui/material/Pagination"
import { useQuery } from "@tanstack/react-query"
import { makeStyles } from "tss-react/mui"

import { search } from "api"
import { queryKeys } from "react-query/constants"
import { GenericError } from "common"

import { useFormContext } from "./FormContext"
import CaseResult from "common/case-result/CaseResult"
import DateControls from "./DateControls"
import Facets from "./Facets"
import SortControls from "./SortControls"
import BoostControls from "./BoostControls"
import FacetList from "./FacetList"
import InjuriesOntology from "./InjuriesOntology"
import InjuryOntologyTrigger from "./InjuryOntologyTrigger"
import SurgeriesControl from "./SurgeriesControl"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"

const useStyles = makeStyles()(theme => ({
  container: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "20% 80%",
  },
  facets: {
    backgroundColor: `${theme.palette.background.paper}`,
    marginRight: theme.spacing(2),
  },
  results: {
    marginLeft: theme.spacing(2),
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  searchHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  emptyResults: {
    padding: theme.spacing(4),
  },
  facetList: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
}))

const NoResults = function () {
  const { classes } = useStyles()

  return (
    <Paper className={classes.emptyResults}>
      <Typography gutterBottom variant="h5">
        No results found matching your current search criteria.
      </Typography>
      <Typography gutterBottom component="p">
        Please try a different search term OR
      </Typography>
      <Typography gutterBottom component="p">
        Remove some of the facets applied below
      </Typography>
      <Box className={classes.facetList}>
        <FacetList />
      </Box>
    </Paper>
  )
}

export default function SearchResults({ caseId }) {
  const { classes } = useStyles()
  const [isEditingInjuries, setIsEditingInjuries] = useState(false)
  const { queryState, caseInfo, queryActions, handleQueryUpdate } = useFormContext()

  const { isLoading, isFetching, error, data } = useQuery(
    [
      queryKeys.searchResults,
      {
        query: queryState,
        page: queryState.page,
        caseId,
      },
    ],
    search
  )

  // Track when the search query goes out
  useEffect(() => {
    if (isFetching && caseInfo) {
      amplitudeApm.trackEvent(
        new DemandAnalyticEvent(DemandAnalyticsEventTypes.CaseSearchQueried, {
          demand_id: caseId,
          request_id: caseInfo?.questionnaire_id,
          query_string: JSON.stringify(queryState),
        })
      )
    }
  }, [isFetching, caseInfo, caseId, queryState])

  const handlePage = (event, page) => {
    // this isn't really a query update
    // but it helps to reset the page when a facet is selected
    handleQueryUpdate(queryActions.SET_PAGE, page - 1)
  }

  if (error) return <GenericError error={error} />

  if (!isLoading && !error && !data?.results?.length) {
    return <NoResults />
  }

  return (
    <>
      {!isLoading && (
        <Box>
          <Box className={classes.container}>
            <Box className={classes.facets}>
              {data?.results && <DateControls />}
              {data?.results && <SurgeriesControl />}
              <InjuriesOntology open={isEditingInjuries} onClose={() => setIsEditingInjuries(false)} />
              {data?.results && <InjuryOntologyTrigger openModal={() => setIsEditingInjuries(true)} />}
              <Facets facets={data?.facets} />
            </Box>
            <Box className={classes.results}>
              <Box className={classes.searchHeader}>
                {data?.results && <SortControls />}
                {data?.results && <BoostControls />}
                {data?.paging.total_results && (
                  <Typography variant="caption" display="block">
                    Total hits: {data?.paging.total_results}
                  </Typography>
                )}
              </Box>
              {data?.results.map(result => (
                <CaseResult
                  data={result}
                  key={result.id}
                  caseId={caseId}
                  queryState={queryState}
                  caseInfo={caseInfo}
                />
              ))}
            </Box>
          </Box>
          {data?.paging && (
            <Box className={classes.pagination}>
              <Pagination
                count={data.paging.total_pages}
                onChange={handlePage}
                page={queryState.page === 0 ? 1 : queryState.page + 1}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
