import { PropsWithChildren } from "react"
import { useTheme } from "@emotion/react"
import Box from "@mui/material/Box"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { makeStyles } from "tss-react/mui"

import { RequestViewDto } from "requests/ViewRequest/types"

import RequestPanel from "./RequestPanel"
import { useRequestStore } from "./store"
import { requestPanelSelectors } from "./store/requestPanel"
import { FLOATING_PANEL_WIDTH } from "demand/constants"

type Props = {
  caseId: string
  className?: string
  expandedSections?: string[]
  highlightedFields?: string[]
  request: RequestViewDto | null
  wrapInDragProvider?: boolean
}

const useStyles = makeStyles()(theme => ({
  layout: {
    display: "flex",
    flexDirection: "column",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  layoutShift: {
    width: `calc(100% - ${FLOATING_PANEL_WIDTH}px - ${theme.spacing(4)})`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${FLOATING_PANEL_WIDTH}px)`,
    },
  },
}))

export function RequestPanelPageWrapper({
  caseId,
  children,
  className,
  expandedSections = [],
  highlightedFields = [],
  request,
  wrapInDragProvider = true,
  ...rest
}: PropsWithChildren<Props>) {
  const theme = useTheme()
  const { classes, cx } = useStyles()
  const openRequestPanel = useRequestStore(requestPanelSelectors.getRequestPanelOpen)

  const panel = wrapInDragProvider ? (
    <DndProvider backend={HTML5Backend}>
      {request?.pk && (
        <RequestPanel
          caseId={caseId}
          request={request}
          expandedSections={expandedSections}
          highlightedFields={highlightedFields}
        />
      )}
    </DndProvider>
  ) : (
    <>
      {request?.pk && (
        <RequestPanel
          caseId={caseId}
          request={request}
          expandedSections={expandedSections}
          highlightedFields={highlightedFields}
        />
      )}
    </>
  )

  return (
    <Box
      marginTop={theme.spacing(6)}
      className={cx(classes.layout, openRequestPanel && classes.layoutShift, className)}
      {...rest}
    >
      {children}
      {panel}
    </Box>
  )
}
