import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { toCamelCase } from "common/helpers/object"
import { RevisionRequest, RevisionRequestData } from "common/models/revision-request"
import { User } from "common/models/user"
import { UploadedFile, UploadedFileDto } from "common/types/UploadedFile"
import {
  RevisionRequestFullDto,
  RevisionRequestDto,
  RevisionRequestDataFields,
  RevisionRequestPartialDto,
  RevisionRequestCancelledDto,
} from "./types"

function getFiles(files?: Nullable<UploadedFileDto[]>): UploadedFile[] {
  return files ? files.map(toCamelCase) : []
}

export class RevisionRequestDeserializer {
  static fromJSON(fields: RevisionRequestFullDto): RevisionRequest {
    return new RevisionRequest(
      fields.pk,
      fields.reason,
      fields.additional_information,
      User.fromJSON(fields.editor),
      fields.completed_at && BaseServiceDeserializer.fromDateTimeString(fields.completed_at),
      fields.cancelled_at && BaseServiceDeserializer.fromDateTimeString(fields.cancelled_at),
      getFiles(fields.questionnaire_files)
    )
  }

  static fromListJSON(items: RevisionRequestFullDto[]): RevisionRequest[] {
    return items.map(RevisionRequestDeserializer.fromJSON)
  }
}

export class RevisionRequestSerializer {
  static toJSON(revisionRequest: RevisionRequest<"full">): RevisionRequestDto {
    return {
      pk: revisionRequest.pk,
      reason: revisionRequest.reason,
      additional_information: revisionRequest.additionalInformation,
    }
  }

  static toPartialJSON(revisionRequest: RevisionRequestData): RevisionRequestPartialDto {
    return {
      pk: revisionRequest.pk,
      reason: revisionRequest.reason,
      additional_information: revisionRequest.additionalInformation,
      uploads: revisionRequest.getUploads(),
    }
  }

  static newToJSON(revisionRequest: RevisionRequest<"new">): RevisionRequestDataFields {
    return {
      reason: revisionRequest.reason,
      additional_information: revisionRequest.additionalInformation,
      uploads: revisionRequest.getUploads(),
    }
  }

  static toCancelledJSON(
    revisionRequest: RevisionRequest<"full"> | RevisionRequest<"data">
  ): RevisionRequestCancelledDto {
    return {
      pk: revisionRequest.pk,
      cancelled_at: new Date().toISOString(),
    }
  }
}
