import styled from "@emotion/styled"
import { Button, buttonBaseClasses } from "@mui/material"

interface StyledMedChronButtonWrapperProps {
  withTags: boolean
}

export const RequestButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.success.main,
  borderColor: theme.palette.success.main,
}))

RequestButton.defaultProps = {
  variant: "outlined",
}

export const StyledRequestButton = RequestButton as typeof Button

export const StyledMedChronButtonWrapper = styled("div")<StyledMedChronButtonWrapperProps>(
  ({ theme, withTags }) => ({
    display: "inline-block",
    position: "relative",
    marginTop: withTags ? theme.spacing(5) : "none",
  })
)

export const StyledLabelWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  right: "0px",
  top: theme.spacing(-4),
}))

export const StyledDisabledLabelWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  right: "0px",
  paddingLeft: theme.spacing(2),
  bottom: theme.spacing(-5),
}))

export const StyledDisabledDescriptionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  fontSize: "12px",
}))

export const MedChronButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  color: theme.palette.common.white,
  borderColor: theme.palette.blue.primary,
  background: theme.palette.blue.primary,

  ":hover": {
    background: theme.palette.blue.primary,
  },

  [`&.${buttonBaseClasses.disabled}`]: {
    color: theme.palette.disabled.main,
    background: theme.palette.disabled.buttonBackground,
  },
}))

RequestButton.defaultProps = {
  variant: "outlined",
}

export const StyledMedChronButton = MedChronButton as typeof Button
