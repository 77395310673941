import { ChangeCallback, LoadingId, LoadingStack, Unsubscribe } from "./types"

class LoadingEvent extends CustomEvent<LoadingId> {}

export class QueuedLoadingStack extends EventTarget implements LoadingStack {
  private activeLoaders: Set<LoadingId> = new Set()

  private get activeLoadingId(): LoadingId | undefined {
    return [...this.activeLoaders].reverse()[0]
  }

  private changeActiveLoadingId(callback: () => void): void {
    const currentLoadingId = this.activeLoadingId
    callback()
    const nextLoadingId = this.activeLoadingId

    if (currentLoadingId === nextLoadingId) return

    if (currentLoadingId) {
      this.dispatchEvent(new LoadingEvent("hideLoading", { detail: currentLoadingId }))
    }

    if (nextLoadingId) {
      this.dispatchEvent(new LoadingEvent("showLoading", { detail: nextLoadingId }))
    }
  }

  showLoading = (id: LoadingId): void => {
    this.changeActiveLoadingId(() => this.activeLoaders.add(id))
  }

  hideLoading = (id: LoadingId): void => {
    this.changeActiveLoadingId(() => this.activeLoaders.delete(id))
  }

  isLoadingActive = (id: LoadingId): boolean => {
    if (!this.activeLoaders.has(id)) return false

    const [lastLoader] = [...this.activeLoaders].reverse()

    return lastLoader === id
  }

  onLoadingChange = (id: LoadingId, onShow: ChangeCallback, onHide: ChangeCallback): Unsubscribe => {
    const handleShow = (evt: Event) => {
      const loadingId = (evt as LoadingEvent).detail
      if (loadingId === id) {
        onShow()
      }
    }
    const handleHide = (evt: Event) => {
      const loadingId = (evt as LoadingEvent).detail
      if (loadingId === id) {
        onHide()
      }
    }
    this.addEventListener("showLoading", handleShow)
    this.addEventListener("hideLoading", handleHide)

    return () => {
      this.removeEventListener("showLoading", handleShow)
      this.removeEventListener("hideLoading", handleHide)
    }
  }
}
