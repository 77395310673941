import React from "react"
import { useQuery } from "@tanstack/react-query"
import { FirmDto } from "settings/Firm/Firm"
import { getFirms } from "../../api"
import { queryKeys } from "../../react-query/constants"

import MultiAutocompleteSelector from "./MultiAutocompleteSelector"

interface FirmPickerProps {
  label?: string
  onChange: (data: FirmDto[]) => void
  value: FirmDto[]
}

export const FirmPicker: React.FC<FirmPickerProps> = ({ value, onChange, label = "Firms" }): JSX.Element => {
  const { data: firmData } = useQuery([queryKeys.firms], async () => getFirms())
  if (!firmData) return <></>

  const getFirmName = (firm: FirmDto): string => {
    return firm.name ?? ""
  }

  const formatFirmsToOptions = (firms: FirmDto[]) => {
    return firms.map(firm => {
      return {
        ...firm,
        label: getFirmName(firm),
      }
    })
  }

  const optionizedValue = formatFirmsToOptions(value)
  const handleChange = ({ newValues }: { newValues: FirmDto[] }) => {
    onChange(newValues)
  }

  // Get a list of options that are not already selected
  const options = formatFirmsToOptions(firmData).filter(option => {
    return !optionizedValue.find(value => {
      return value.pk === option.pk
    })
  })

  return (
    <MultiAutocompleteSelector
      dataTest="assignee-selector"
      options={options}
      value={optionizedValue}
      label={label}
      onChange={handleChange}
    />
  )
}
