import { createContext } from "react"
import { Citations } from "./Citations"
import { CitationElement } from "../../CustomEditor"
import { noop } from "lodash"

export interface CitationsContextType {
  references: Citations
  onClick: (element: CitationElement, page: Nullable<number>) => void
}

export const CitationsContext = createContext<CitationsContextType>({
  references: new Citations(),
  onClick: noop,
})
