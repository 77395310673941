import { AttributeFiltersData } from "common/attributes-filter"
import { CASE_SECTIONS } from "common/types/sections"
import { useSearchParams } from "react-router-dom"
import { encodeAttributeValueIdsFromQueryString } from "../utils"

export function useInitialAttributeValuesFromQuery(): AttributeFiltersData | undefined {
  const [params] = useSearchParams()
  const attributeValueIdsFromQuery = params.get("attribute_value_ids")

  if (!attributeValueIdsFromQuery) return undefined

  return encodeAttributeValueIdsFromQueryString(attributeValueIdsFromQuery)
}

export function useInitialSectionFromQuery(): Nullable<CASE_SECTIONS> {
  const [params] = useSearchParams()
  const sectionFromQuery = params.get("section")

  if (!sectionFromQuery || !Object.values(CASE_SECTIONS).includes(sectionFromQuery as CASE_SECTIONS))
    return null

  return sectionFromQuery as CASE_SECTIONS
}
