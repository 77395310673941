import { Box } from "@mui/material"
import { FolderOutlined as FolderIcon } from "@mui/icons-material"
import { theme } from "app/theme"

export const EmptyContentState = () => {
  return (
    <Box display="flex" alignItems="center" color={theme.palette.grey[600]}>
      <FolderIcon />
      <Box ml={1}>No content available</Box>
    </Box>
  )
}
