import { FirmsUserManage } from "./types"

export class FirmsUserManageModel {
  firms: FirmsUserManage

  constructor(firms: FirmsUserManage) {
    this.firms = firms
  }

  canUserManageTheFirm(firmId: PrimaryKey): boolean {
    return this.firms.some(firm => firm.id === firmId)
  }

  hasAnyFirm(): boolean {
    return !!this.firms.length
  }
}
