import { PropsWithChildren } from "react"
import {
  SortableNestedListItem,
  SortableNestedListItemProps,
} from "common/nested-list/SortableNestedListItem"
import { ExhibitGroup, ExhibitSection } from "./Exhibit"
import { ItemType, ProjectionItemWithChildren } from "common/nested-list/types"
import { EXHIBIT_ITEM_TYPES } from "./enums"
import { ITEM_REF } from "common/nested-list/constants"
import { ExhibitsListSection } from "./ExhibitsListSection"
import { ExhibitsListGroup } from "./ExhibitsListGroup"
import { ExhibitsListItem } from "./ExhibitsListItem"

function getBackgroundColor(item: ItemType<ExhibitSection>): string {
  switch (item.type) {
    case EXHIBIT_ITEM_TYPES.SECTION:
      return "#F4F3F5"
    case EXHIBIT_ITEM_TYPES.GROUP:
      return "#E1E1E1"
    case EXHIBIT_ITEM_TYPES.EXHIBIT:
    case EXHIBIT_ITEM_TYPES.PARTITION:
      return "#C5C5C6"
    default:
      return "#FFFFFF"
  }
}

export function ExhibitListItemComponent({
  item,
  ...props
}: PropsWithChildren<SortableNestedListItemProps<ExhibitSection>>): JSX.Element {
  return (
    <SortableNestedListItem item={item} {...props} backgroundColor={getBackgroundColor(item[ITEM_REF])} />
  )
}

export interface ExhibitItemCallbacks {
  onSectionChange: (section: ExhibitSection) => void
  onGroupChange: (group: ExhibitGroup) => void
  onGroupDelete: (group: ExhibitGroup) => void
}

export type ExhibitListItemContentComponentProps = {
  item: ProjectionItemWithChildren<ItemType<ExhibitSection>>
} & ExhibitItemCallbacks

export function ExhibitListItemContentComponent({
  item: projectedItem,
  onSectionChange,
  onGroupChange,
  onGroupDelete,
}: ExhibitListItemContentComponentProps): Nullable<JSX.Element> {
  const item = projectedItem[ITEM_REF]

  switch (item.type) {
    case EXHIBIT_ITEM_TYPES.SECTION:
      return <ExhibitsListSection item={item} onChange={onSectionChange} />
    case EXHIBIT_ITEM_TYPES.GROUP:
      return <ExhibitsListGroup item={item} onChange={onGroupChange} onDelete={onGroupDelete} />
    case EXHIBIT_ITEM_TYPES.EXHIBIT:
    case EXHIBIT_ITEM_TYPES.PARTITION:
      return <ExhibitsListItem item={item} />
    default:
      return null
  }
}
