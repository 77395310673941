import { BaseAnalyticsEvent } from "infrastructure/apm/types"
import { EstimateFormValues } from "settlements/estimate-form/types"
import { SubmitFrom } from "settlements/estimate/types"

export enum SettlementDataRepositoryAnalyticsEventTypes {
  ViewedEstimateSearchPage = "Viewed Estimate Search Page",
  EstimateSearchSubmitted = "Estimate Search Submitted",
  EstimateSearchReSubmitted = "Estimate Search Re-Submitted",
  UsefulnessFeedback = "Usefulness Feedback Submitted",
  UsefulnessComment = "Usefulness Comment Submitted",
  CaseSaved = "Case Saved",
  CaseForget = "Case Forget",
  SettlementDataCreated = "Settlement Data Created",
  SettlementDataUpdated = "Settlement Data Updated",
  EstimatePrinted = "Estimate Printed",
}

export class SettlementDataRepositoryAnalyticEvent<
  T extends SettlementDataRepositoryAnalyticsEventTypes,
> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: SettlementDataRepositoryAnalyticsEventDetails[T]
  ) {
    super()
  }
}

// Add new request event types here
export type SettlementDataRepositoryAnalyticsEventDetails = {
  [SettlementDataRepositoryAnalyticsEventTypes.ViewedEstimateSearchPage]: {
    firm_id: Nullable<PrimaryKey>
  }
  [SettlementDataRepositoryAnalyticsEventTypes.EstimateSearchSubmitted]: {
    properties: Partial<EstimateFormValues>
    version?: number
  }
  [SettlementDataRepositoryAnalyticsEventTypes.EstimateSearchReSubmitted]: {
    submitFrom: SubmitFrom
    properties: Partial<EstimateFormValues>
  }
  [SettlementDataRepositoryAnalyticsEventTypes.UsefulnessFeedback]: {
    useful: boolean
  }
  [SettlementDataRepositoryAnalyticsEventTypes.UsefulnessComment]: {
    comment: string
  }
  [SettlementDataRepositoryAnalyticsEventTypes.CaseSaved]: {
    estimate_id: string
    search_index: number
    custom_name?: Nullable<string>
    elastic_case_id: string
  }
  [SettlementDataRepositoryAnalyticsEventTypes.CaseForget]: {
    elastic_case_id: string
  }
  [SettlementDataRepositoryAnalyticsEventTypes.SettlementDataCreated]: {
    firm_id: Nullable<PrimaryKey>
    user_id: Nullable<PrimaryKey>
    case_id: Nullable<PrimaryKey>
  }
  [SettlementDataRepositoryAnalyticsEventTypes.SettlementDataUpdated]: {
    firm_id: Nullable<PrimaryKey>
    user_id: Nullable<PrimaryKey>
    case_id: Nullable<PrimaryKey>
  }
  [SettlementDataRepositoryAnalyticsEventTypes.EstimatePrinted]: {
    estimate_id: string
  }
}
