import { isEmpty } from "lodash"
import { useCallback, useRef } from "react"
import { useSearchParams } from "react-router-dom"

type NavigateOpts = { replace?: boolean; state?: unknown }
type Value = string | number | boolean | string[]
export type SetSearchState<T extends Value> = (nextValue: Nullable<T>, opts?: NavigateOpts) => void
export type ValueType = "string" | "number" | "boolean" | "string[]"
export type UseSearchStateReturn<T extends Value> = [Nullable<T>, SetSearchState<T>]
type ValueTypeReverse<T extends ValueType> = T extends "string"
  ? string
  : T extends "number"
    ? number
    : T extends "boolean"
      ? boolean
      : T extends "string[]"
        ? string[]
        : never

const duplicateSearchParams = (params: URLSearchParams): URLSearchParams =>
  new URLSearchParams(Object.fromEntries(params.entries()))

export const useSearchState = <T extends ValueType>(
  key: string,
  defaultValue?: ValueTypeReverse<T>,
  type: T = "string" as T
): UseSearchStateReturn<ValueTypeReverse<T>> => {
  const searchStateSet = useRef(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const setSearchState = useCallback<SetSearchState<ValueTypeReverse<T>>>(
    (nextValue, opts) => {
      searchStateSet.current = true

      const newParams = duplicateSearchParams(searchParams)
      const stringifiedNextValue = String(nextValue)

      if (isEmpty(stringifiedNextValue)) {
        newParams.delete(key)
      } else {
        newParams.set(key, stringifiedNextValue)
      }
      setSearchParams(newParams, opts)
    },
    [key, searchParams, setSearchParams]
  )

  let val = searchParams.get(key) as Nullable<Value>

  if (val === null && !searchStateSet.current) {
    return [defaultValue ?? null, setSearchState]
  }

  if (val === "null") {
    val = null
  }

  if (val !== null && type === "number") {
    val = Number(val)
  }

  if (val !== null && type === "string[]") {
    val = String(val).split(",")
  }

  if (val !== null && type === "boolean") {
    val = val === "false" ? false : Boolean(val)
  }

  return [val, setSearchState] as UseSearchStateReturn<ValueTypeReverse<T>>
}
