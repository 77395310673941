import { useEffect } from "react"
import { ALL, UPLOADED } from "../../../constants"
import Tooltip from "common/Tooltip"
import { Button, ButtonProps, CircularProgress } from "@mui/material"
import DownloadIcon from "@mui/icons-material/GetApp"
import { useHandleMessages } from "common/messages/useHandleMessages"
import useAsyncDownload from "hooks/useAsyncDownload"
import { useMutation } from "@tanstack/react-query"
import { downloadAllFiles } from "requests/utils"
import { downloadRequestDemandPackageFiles, downloadRequestFiles } from "api"

type DownloadAllFileTypes = typeof ALL | typeof UPLOADED

type DownloadAllFilesButtonProps = {
  requestId: number
  downloadAllEndpoint: typeof downloadRequestFiles | typeof downloadRequestDemandPackageFiles
  fileName: string
  text?: string
  disabled?: boolean
  disabledText?: string
  type?: DownloadAllFileTypes
  asyncZipAndDownload: boolean
  buttonTheme?: "casePage" | "default"
} & Omit<ButtonProps, "type">

export const DownloadAllFilesButton = ({
  requestId,
  downloadAllEndpoint,
  fileName = "files",
  text = "Download All",
  disabled = false,
  disabledText = "",
  type = ALL,
  asyncZipAndDownload = false,
  ...props
}: DownloadAllFilesButtonProps) => {
  const { showErrorMessage } = useHandleMessages()
  const { downloadError, downloadProcessing, startDownload } = useAsyncDownload()

  useEffect(() => {
    downloadError && showErrorMessage(downloadError)
  }, [downloadError, showErrorMessage])

  const downloadAllFilesMutation = useMutation(
    async () => {
      await downloadAllFiles(downloadAllEndpoint, fileName, requestId, type)
    },
    {
      onError: error => {
        showErrorMessage({
          message:
            "Something went wrong downloading all files. Try downloading individual files and report an issue if your problem persists.",
          error,
        })
      },
    }
  )

  return (
    <Tooltip
      onClick={() => {
        if (asyncZipAndDownload) {
          startDownload(requestId, type, fileName)
        } else {
          downloadAllFilesMutation.mutateAsync()
        }
      }}
      disabled={disabled || downloadProcessing}
      tooltipText={disabledText}
      data-test="download-all-files-button"
    >
      <Button
        variant="outlined"
        color="secondary"
        startIcon={downloadProcessing ? <CircularProgress size="1em" /> : <DownloadIcon />}
        {...props}
      >
        {text}
      </Button>
    </Tooltip>
  )
}
