import { SetState } from "./types"

export type DocumentSlice = {
  isReadOnly: boolean
  caseId: string
  document: {
    loading: boolean
    error: string | null
    documentId: string
    isReordering: boolean
  }
}

export const documentSlice: DocumentSlice = {
  isReadOnly: false,
  caseId: "",
  document: {
    loading: false,
    error: null,
    documentId: "",
    isReordering: false,
  },
}

export const documentSelectors = {
  getDemandLoading: (state: DocumentSlice) => state.document.loading,
}

export const documentActions = (set: SetState<DocumentSlice>) => {
  const setIsLoading = (loading: boolean) => {
    set(({ document }) => ({ document: { ...document, loading } }))
  }
  const setDocumentId = (documentId: string) =>
    set(({ document }) => ({ document: { ...document, documentId } }))

  const setIsReordering = (isReordering: boolean) => {
    set(({ document }) => ({ document: { ...document, isReordering } }))
  }

  const setCaseId = (caseId: string) => set({ caseId })

  const setReadOnly = (isReadOnly: boolean) => set({ isReadOnly })

  return {
    setCaseId,
    setIsLoading,
    setDocumentId,
    setIsReordering,
    setReadOnly,
  }
}
