import { FullStory, init as FullStoryInit } from "@fullstory/browser"
import { permissionService } from "api/services/permissions"
import { USER_ROLES } from "common/models/roles"
import { User } from "common/models/user"
import { SERVICE_USER_EMAILS } from "infrastructure/env/constants"
import getApmEnvironment, { isDevEnvironment, isProdEnvironment } from "infrastructure/env/getEnvironment"
import { getAPIServerURL } from "utils"
import { BaseAnalyticsEvent, BaseAnalyticsService, BaseInfrastructureService } from "./types"

export class FullStoryAnalyticsEvent extends BaseAnalyticsEvent {}

let isInitialized = false

const isCorrectEnv = isDevEnvironment() || !getAPIServerURL().includes("localhost")
const isEnabled =
  isCorrectEnv &&
  import.meta.env.VITE_FULLSTORY_ENABLED?.toLowerCase() === "true" &&
  permissionService.getCachedPermissions()?.disableTelemetryFullstoryEnabled !== true

function withEnv(callback: () => void): void {
  if (isEnabled) {
    try {
      callback()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}

function init(): void {
  withEnv(() => {
    FullStoryInit({
      orgId: import.meta.env.VITE_FULLSTORY_ORG_ID,
    })

    isInitialized = true
  })
}

function setUserContext(user: User): void {
  withEnv(() => {
    if (SERVICE_USER_EMAILS.includes(user.email)) {
      return
    }

    if (!isInitialized) {
      init()
    }

    FullStory("setIdentity", {
      uid: user.id.toString(),
      properties: {
        email: user.email,
        displayName: `${user.firstName} ${user.lastName}`.trim(),
        role: user.role,
        roleDisplay: USER_ROLES[user.role].display,
        environment: getApmEnvironment(),
      },
    })
  })
}

function resetUserContext(): void {
  withEnv(() => {
    FullStory("setIdentity", { anonymous: true })
  })
}

function trackPageView(route: string): void {
  withEnv(() => {
    FullStory("setProperties", {
      type: "page",
      properties: {
        route,
      },
    })
  })
}

function trackEvent(event: BaseAnalyticsEvent): void {
  withEnv(() => {
    FullStory("trackEvent", {
      name: event.name,
      properties: {
        ...event.fields,
        analytics: true,
      },
    })
  })
}

function trackPerformance(
  name: string,
  fields: {
    duration: DOMHighResTimeStamp
    trailingDuration: DOMHighResTimeStamp
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any
  }
): void {
  withEnv(() => {
    FullStory("trackEvent", {
      name: "$performance",
      properties: {
        ...fields,
        measure: name,
        duration: fields.duration,
        trailingDuration: fields.trailingDuration,
      },
    })
  })
}

export function getSessionUrl(): Nullable<string> {
  try {
    if (!isInitialized) return null

    return FullStory("getSession", { format: "url.now" }) ?? null
  } catch {
    return isProdEnvironment() ? null : `https://app.fullstory.com/`
  }
}

export const fullstoryApm: BaseInfrastructureService & BaseAnalyticsService = {
  init,
  setUserContext,
  resetUserContext,

  trackPageView,
  trackEvent,
  trackPerformance,
}
