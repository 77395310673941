import { useCallback, useEffect, useState, useRef } from "react"
import { Grid, IconButton } from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { ThumbUpOffAlt, ThumbDownOffAlt, ThumbUpAlt, ThumbDownAlt } from "@mui/icons-material"

import { useMutation } from "@tanstack/react-query"
import { useHandleMessages } from "common/messages/useHandleMessages"

import { summariesApiService } from "api/services/summaries"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { ResultContent } from "./ResultContent"

import { Output } from "api/services/summaries/types"
import { copyToClipboardEditorText } from "common/helpers/copy"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { LittyChip } from "summaries/shared/LittyChip"
import { LIKE, DISLIKE } from "summaries/constants"
import { WarningText } from "./styled"

interface SummaryResultProps {
  generating: boolean
  output: Nullable<Output>
  onSummaryResultChange: (newValue: EditorRoot) => void
  summaryId: Nullable<string>
  warningText?: string
  showFeedback?: boolean
}

export const SummaryResult = ({
  generating,
  output: existingOutput,
  onSummaryResultChange,
  summaryId,
  warningText,
  showFeedback = false,
}: SummaryResultProps): JSX.Element => {
  const editor = useRef<Nullable<RichTextEditor>>(null)
  const summaryResult = existingOutput?.value ?? null
  const [output, setOutput] = useState<Nullable<Output>>(existingOutput)
  const [editedSummaryResult, setEditedSummaryResult] = useState<Nullable<EditorRoot>>(output?.value ?? null)
  const { showSuccessMessage, showErrorMessage } = useHandleMessages()

  const wrapperRef = useRef(null)

  useEffect(() => {
    setEditedSummaryResult(summaryResult)
  }, [summaryResult, setEditedSummaryResult])

  const createOutputMutation = useMutation(summariesApiService.createOutput)

  const likeSummaryMutation = useMutation(summariesApiService.likeSummary, {
    onSuccess: (data: Output) => {
      setOutput(data)
      showSuccessMessage("Feedback recorded. Thank you for helping us improve your experience!")
    },
    onError: error => {
      showErrorMessage({
        message: "Error recording feedback. EvenUp has been alerted to this issue.",
        error,
      })
    },
  })
  const dislikeSummaryMutation = useMutation(summariesApiService.dislikeSummary, {
    onSuccess: (data: Output) => {
      setOutput(data)
      showSuccessMessage("Feedback recorded. Thank you for helping us improve your experience!")
    },
    onError: error => {
      showErrorMessage({
        message: "Error recording feedback. EvenUp has been alerted to this issue.",
        error,
      })
    },
  })

  const handleSaveClick = useCallback(() => {
    createOutputMutation.mutate({
      data: {
        value: editedSummaryResult,
      },
      summaryId: summaryId,
    })
  }, [createOutputMutation, summaryId, editedSummaryResult])

  const handleCopyClick = useCallback(() => {
    copyToClipboardEditorText(
      editor.current?.editorRef?.current,
      () => {
        showSuccessMessage("Summary copied")
      },
      () => {
        showErrorMessage("There was an error copying the summary")
      }
    )
  }, [showErrorMessage, showSuccessMessage])

  const handleLikeClick = useCallback(() => {
    if (showFeedback && summaryId) {
      likeSummaryMutation.mutate(summaryId)
    }
  }, [likeSummaryMutation, summaryId, showFeedback])

  const handleDislikeClick = useCallback(() => {
    if (showFeedback && summaryId) {
      dislikeSummaryMutation.mutate(summaryId)
    }
  }, [dislikeSummaryMutation, summaryId, showFeedback])

  if (!generating && !summaryResult) {
    return <></>
  }

  return (
    <Grid marginTop={"10px"} container spacing={2} direction={"row-reverse"}>
      <Grid item xs={12} ref={wrapperRef}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item display="flex" width="-webkit-fill-available" mb={2}>
            <LittyChip size={18} text={"Litty says:"} />
          </Grid>
          {!!summaryResult && (
            <Grid item display="flex" width="-webkit-fill-available" ml="auto" mb={2}>
              {showFeedback && (
                <>
                  <IconButton onClick={handleLikeClick}>
                    {output?.rating === LIKE ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
                  </IconButton>
                  <IconButton onClick={handleDislikeClick}>
                    {output?.rating === DISLIKE ? <ThumbDownAlt /> : <ThumbDownOffAlt />}
                  </IconButton>
                </>
              )}
              <IconButton onClick={handleCopyClick} data-selector="copy-summary">
                <ContentCopyIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <ResultContent
          ref={editor}
          result={summaryResult}
          onSave={handleSaveClick}
          onResultEdit={onSummaryResultChange}
        />
        {warningText && <WarningText>{warningText}</WarningText>}
      </Grid>
    </Grid>
  )
}
