import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Snackbar from "@mui/material/Snackbar"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { makeStyles } from "tss-react/mui"

import { fetchPlaintiffInfoForCase, getRequestByCase, updateStepStatus } from "../api"
import { SILENT_QUERY_PARAMS, queryKeys } from "../react-query/constants"
import { FormProvider } from "./context"

import { useTheme } from "@emotion/react"
import { logDemandSectionEvent } from "infrastructure/apm/events/demandSectionEvents"
import { Helmet } from "react-helmet"
import Form from "./Form/Form"
import { CaseVariablesProvider } from "./Variables/CaseVariablesProvider"
import { useDemandSteps } from "./steps"
import { FORM_SECTION_ROUTES } from "./steps/constants"
import { CASE_STEP_STATUS } from "./steps/types"

const useStyles = makeStyles()(theme => ({
  demand: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      columnGap: theme.spacing(2),
    },
  },
  center: {
    display: "grid",
    margin: "auto",
  },
  desktopNav: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}))

const MESSAGE_TYPES = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
}

function DemandContainer() {
  const navigate = useNavigate()
  const theme = useTheme()
  const { section, getStepStatus, nextStep, caseId } = useDemandSteps()
  const nextStepRef = useRef(nextStep)
  nextStepRef.current = nextStep
  const { classes } = useStyles()
  const [snackMessage, setSnackMessage] = useState("")
  const [severity, setSeverity] = useState(MESSAGE_TYPES.success)
  const [snackOpen, setSnackOpen] = useState(false)
  const [savedSuccessfully, setSavedSuccessfully] = useState(false)
  const [currentPlaintiff, setCurrentPlaintiff] = useState(null)

  const queryClient = useQueryClient()

  const stepMutation = useMutation(updateStepStatus, {
    mutationKey: [queryKeys.steps],
    onSuccess: latestStep => {
      queryClient.setQueryData(
        [queryKeys.steps, caseId],
        cachedData => {
          if (!cachedData) return cachedData

          const nextData = [...cachedData]
          const currentStepIndex = nextData.findIndex(step => step.step === latestStep.step)

          if (currentStepIndex === -1) {
            return [...nextData, latestStep]
          }

          nextData[currentStepIndex] = { ...nextData[currentStepIndex], ...latestStep }

          return nextData
        },
        {}
      )
    },
    onError: () => {
      showErrorMessage("Couldn't update status, please try again.")
    },
  })

  const { data: plaintiffs } = useQuery(
    [queryKeys.plaintiffs + "-global", caseId],
    () => fetchPlaintiffInfoForCase(caseId),
    SILENT_QUERY_PARAMS
  )

  const firstPlaintiff = useMemo(() => {
    return Array.isArray(plaintiffs) ? plaintiffs[0] : null
  }, [plaintiffs])

  const tabTitle = useMemo(() => {
    if (!plaintiffs || !firstPlaintiff) return ""

    const firstPlaintiffName = `${firstPlaintiff.first_name} ${firstPlaintiff.last_name}`
    if (Array.isArray(plaintiffs) && plaintiffs.length > 1) return `${firstPlaintiffName} et al.`
    return firstPlaintiffName
  }, [firstPlaintiff, plaintiffs])

  const { data: request } = useQuery([queryKeys.request, caseId], getRequestByCase, SILENT_QUERY_PARAMS)

  useEffect(() => {
    if (section && request) {
      logDemandSectionEvent(section, "entered", caseId, request.pk)
      return () => {
        logDemandSectionEvent(section, "exited", caseId, request.pk)
      }
    }
  }, [section, caseId, request])

  const handleUpdateStepStatus = ({ status, step }) => {
    const currentStep = step || section
    const currentStatus = getStepStatus(currentStep)

    if (
      currentStatus?.status === status ||
      (currentStatus?.status === CASE_STEP_STATUS.COMPLETED && status === CASE_STEP_STATUS.STARTED)
    ) {
      return
    }

    stepMutation.mutate({ data: { step: currentStep, status }, caseId })
  }

  const showSuccessMessage = useCallback(message => {
    setSnackMessage(message ?? "Save successful!")
    setSeverity(MESSAGE_TYPES.success)
    setSnackOpen(true)
  }, [])

  const handleNextStep = useCallback(async () => {
    showSuccessMessage()
    if (nextStepRef.current) {
      navigate(FORM_SECTION_ROUTES[nextStepRef.current.step])
    } else {
      // form is on the last section
      navigate("../review")
    }
  }, [navigate, showSuccessMessage])

  const closeSnack = () => {
    setSnackOpen(false)
  }

  const showErrorMessage = message => {
    const DEFAULT_ERROR = "Something went wrong."
    message ? setSnackMessage(message) : setSnackMessage(DEFAULT_ERROR)
    setSeverity(MESSAGE_TYPES.error)
    setSnackOpen(true)
  }

  const handlePlaintiffChange = newPlaintiff => {
    setCurrentPlaintiff(newPlaintiff)
  }

  return (
    <Box>
      <Helmet key={`tab-title-${tabTitle}`}>
        <title>Demand: {tabTitle} - EvenUp</title>
      </Helmet>
      <CaseVariablesProvider caseId={caseId} plaintiffId={currentPlaintiff?.id || firstPlaintiff?.pk}>
        <FormProvider
          value={{
            caseId,
            section,
            queryClient,
            handleUpdateStepStatus,
            showErrorMessage,
            savedSuccessfully,
            setSavedSuccessfully,
            request,
            currentPlaintiff,
          }}
        >
          <Box className={classes.demand}>
            {/* Snackbar.  Shows up in bottom right of page */}
            <Snackbar
              open={snackOpen}
              autoHideDuration={10000}
              onClose={closeSnack}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert onClose={closeSnack} severity={severity}>
                {snackMessage}
              </Alert>
            </Snackbar>
            {/* Demand Body */}
            <Box mt={theme.spacing(6)}>
              <Form
                currentSection={section}
                handleSave={showSuccessMessage}
                handleNext={handleNextStep}
                request={request}
                onPlaintiffChange={handlePlaintiffChange}
              />
            </Box>
          </Box>
        </FormProvider>
      </CaseVariablesProvider>
    </Box>
  )
}

export function Demand() {
  return <DemandContainer />
}
