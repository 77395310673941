import { useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Collapse from "@mui/material/Collapse"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Chip from "@mui/material/Chip"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import SaveIcon from "@mui/icons-material/Bookmark"
import UnSaveIcon from "@mui/icons-material/BookmarkBorder"
import UnFavoriteIcon from "@mui/icons-material/FavoriteBorder"
import FavoriteIcon from "@mui/icons-material/Favorite"
import CloseIcon from "@mui/icons-material/Close"
import { makeStyles } from "tss-react/mui"
import { PlaintiffSelector } from "demand/components/PlaintiffSelector"
import {
  markCaseRelevant,
  markCaseNotRelevant,
  updateCaseRelevantDocument,
  saveFavoriteCase,
  unsaveFavoriteCase,
} from "api"
import { queryKeys } from "react-query/constants"
import { useDialog } from "hooks/useDialog"
import { useHandleMessages } from "../messages/useHandleMessages"
import useUser from "hooks/useUser"

import CaseSummary from "./CaseSummary"
import UserDemandSelector from "../UserDemandSelector"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"

const useStyles = makeStyles()(theme => ({
  card: {
    marginTop: theme.spacing(2),
    maxWidth: "92vw",
    "& h2 + p": {
      padding: "0.5rem 0",
    },
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  name: {
    fontSize: "1.5rem",
  },
  givenNickname: {
    marginLeft: "5px",
    color: theme.palette.secondary.main,
    fontSize: "1.5rem",
  },
  caseContent: {
    marginTop: theme.spacing(1),
  },
  amount: {
    marginBottom: theme.spacing(1),
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  icdCodes: {
    display: "flex",
  },
  injuries: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tagItem: {
    margin: theme.spacing(1, 1, 1, 0),
  },
  newlineText: {
    whiteSpace: "pre-line",
  },
  highlightText: {
    backgroundColor: "#FFFF00",
  },
  smallMarginRight: {
    marginRight: theme.spacing(1),
  },
  characterLimit: {
    marginLeft: "auto",
  },
  error: {
    color: "red",
  },
}))

const replaceHeirarchyWithSymbol = text => {
  return text.replace(/\b(?:::|:::)\b/gi, " ➤ ")
}

const InjuriesList = ({ injuries, codes }) => {
  const { classes } = useStyles()
  let icdCodes = []
  if (codes?.length) {
    icdCodes = codes.map((code, index) => {
      const getLabel = code => (
        <>
          <span style={{ fontWeight: 500, marginRight: ".5rem" }}>[{code.code}]</span>
          {code.code_pretty_name ? code.code_pretty_name : code.injury}
        </>
      )
      return <Chip key={code.code + index} label={getLabel(code)} className={classes.tagItem} />
    })
  }
  const injuriesList = injuries.map((injury, index) => (
    <Chip key={index} label={replaceHeirarchyWithSymbol(injury)} className={classes.tagItem} />
  ))

  const injuriesWithCodes = [...icdCodes, ...injuriesList]
  return (
    <Box>
      {!!injuriesWithCodes.length && (
        <Typography variant="h6" component="h2">
          Injuries
        </Typography>
      )}
      <Box className={classes.injuries}>{injuriesWithCodes}</Box>
    </Box>
  )
}

const TagList = ({ items, title }) => {
  const { classes } = useStyles()
  const itemList = items.map((item, index) => (
    <Chip key={index} label={replaceHeirarchyWithSymbol(item)} className={classes.tagItem} />
  ))
  return (
    <Box>
      <Typography variant="h6" component="h2">
        {title}
      </Typography>
      <>{itemList}</>
    </Box>
  )
}

export const HighlightedText = ({ text, splitTag, highlightTag }) => {
  const { classes } = useStyles()
  return (
    <Box>
      {text
        .split(splitTag)
        .filter(x => x.length)
        .map((result, index) => {
          if (result.startsWith(highlightTag)) {
            return (
              <span key={index} className={classes.highlightText}>
                {result.replaceAll(highlightTag, "")}
              </span>
            )
          } else {
            return result
          }
        })}
    </Box>
  )
}

const DemandSelectorDialog = ({ searchCaseId, isOpen, close }) => {
  const [selectedDemand, setSelectedDemand] = useState(null)
  const { showSuccessMessage, showErrorMessage } = useHandleMessages()

  const setRelevanceMutation = useMutation(markCaseRelevant, {
    onSuccess: () => {
      showSuccessMessage(`Case was marked as relevant for ${selectedDemand.label}'s demand`)
      close()
    },
    onError: error => {
      showErrorMessage({
        message: "There was an error setting marking this case as relevant",
        error,
      })
    },
  })

  const linkCaseToDemand = () => {
    if (!selectedDemand) return
    setRelevanceMutation.mutate({ caseId: selectedDemand.id, id: searchCaseId })
  }

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>Select the demand this case is relevant to</DialogTitle>
      <DialogContent>
        <UserDemandSelector
          onChange={(_, value) => {
            setSelectedDemand(value)
          }}
          value={selectedDemand}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={!selectedDemand} onClick={linkCaseToDemand}>
          Set Case
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function SearchResultItem({ data, caseId = null, givenNickname = "", queryState, caseInfo }) {
  const { classes } = useStyles()
  const queryClient = useQueryClient()
  const [expandedCaseDetails, setExpandedCaseDetails] = useState(false)
  const [isFavorite, setIsFavorite] = useState(data?.is_favorite ?? false)
  const [plaintiffId, setPlaintiffId] = useState(data?.relevant_document?.plaintiff || "")
  const { user } = useUser()
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  const { showSuccessMessage, showErrorMessage } = useHandleMessages()

  const currencyFormat = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
  const caseLocation = data.county ? `${data.county}, ${data.state}` : `${data.state}`
  const decisionDate = data.case_decision_date?.length
    ? new Date(data.case_decision_date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "N/A"
  const splitTag = data._split_tag
  const highlightTag = data._highlight_tag

  const { mutateAsync: markRelevant } = useMutation(markCaseRelevant, {
    onSuccess: async () => {
      await queryClient.cancelQueries([queryKeys.searchResults])
      invalidateAllQueries()
    },
  })

  const { mutateAsync: markNotRelevant } = useMutation(markCaseNotRelevant, {
    onSuccess: () => invalidateAllQueries(),
  })

  const invalidateAllQueries = () => {
    queryClient.invalidateQueries([queryKeys.case])
    queryClient.invalidateQueries([queryKeys.documents])
    queryClient.invalidateQueries([queryKeys.searchResults])
    queryClient.invalidateQueries([queryKeys.favoriteCases])
  }

  const updateRelevantDocumentMutation = useMutation(updateCaseRelevantDocument, {
    onSuccess: data => {
      showSuccessMessage("Plaintiff Updated")
      setPlaintiffId(data?.plaintiff)
    },
  })

  const updatePlaintiffInRelevantDoc = (caseId, plaintiffId) => {
    updateRelevantDocumentMutation.mutate({
      caseId: caseId,
      documentId: data?.relevant_document?.pk,
      data: {
        plaintiff: plaintiffId,
      },
    })
  }

  const save = async () => {
    try {
      if (caseId && caseInfo) {
        await markRelevant({ caseId, id: data.id })

        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(DemandAnalyticsEventTypes.CaseSearchAddedToDemand, {
            demand_id: caseId,
            request_id: caseInfo?.questionnaire_id,
            case_name: data.case_name,
            query_string: JSON.stringify(queryState),
          })
        )
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("there was an error")
    }
  }

  const unsave = async () => {
    try {
      await markNotRelevant({ caseId, id: data.id })
      invalidateAllQueries()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("there was an error")
    }
  }

  const saveFavorite = () => {
    closeFavoriteDialog()
    saveFavoriteCase({ user, id: data.id, nickname }).then(() => {
      setIsFavorite(true)
    })
  }

  const favoriteCase = () => {
    openFavoriteDialog()
  }

  const unfavoriteCase = () => {
    unsaveFavoriteCase({ user, id: data.id }).then(() => {
      setIsFavorite(false)
    })
  }

  const {
    isOpen: isFavoriteOpen,
    openDialog: openFavoriteDialog,
    closeDialog: closeFavoriteDialog,
  } = useDialog()
  const [nickname, setNickname] = useState(givenNickname || data.case_name)

  const handleFavoriteClick = () => {
    return isFavorite === true ? unfavoriteCase() : favoriteCase()
  }

  const {
    isOpen: isDemandSelectorOpen,
    openDialog: openDemandSelectorDialog,
    closeDialog: closeDemandSelectorDialog,
  } = useDialog()

  return (
    <Card className={classes.card} variant="outlined" data-test="case-card" data-id={`${data.id}`}>
      <CardContent>
        <Box className={classes.header}>
          <Box>
            <Typography display="inline" className={classes.name}>
              {data.case_name}
            </Typography>
            {givenNickname && (
              <Typography display="inline" className={classes.givenNickname}>
                ({givenNickname})
              </Typography>
            )}
            <Typography color="textSecondary" gutterBottom>
              {caseLocation}
            </Typography>
          </Box>
          {data.case_decision_type && <Chip label={data.case_decision_type} />}
        </Box>
        <Box className={classes.header}>
          <Typography variant="h5" component="h1" className={classes.amount}>
            {currencyFormat.format(data.total_settlement_amount)}
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            {decisionDate}
          </Typography>
        </Box>

        {typeof data?.relevant_document !== "undefined" && !!caseId && (
          <>
            {multiPlaintiffEnabled && (
              <PlaintiffSelector
                caseId={caseId}
                plaintiff={plaintiffId}
                onChange={newPlaintiffId => {
                  updatePlaintiffInRelevantDoc(caseId, newPlaintiffId)
                }}
              ></PlaintiffSelector>
            )}
          </>
        )}

        <Box mt={2}>
          <Divider />
        </Box>

        <Box className={classes.caseContent}>
          <CaseSummary data={data} caseId={caseId} />

          <InjuriesList injuries={data.injury_types} codes={data.icd_codes_debug} />
          {!!data.treatments?.length && <TagList items={data.treatments} title={"Treatments & Procedures"} />}
          {!data.injury_types.length && data.high_level_categories && (
            <TagList items={data.high_level_categories} title="Categories" />
          )}
          {!!data.citation_info?.length && (
            <>
              <Typography variant="h6" component="h2">
                Citation
              </Typography>
              {data?.citation_info.split(",").map((result, index) => (
                <Typography key={index} variant="button" component="p" gutterBottom>
                  {data.case_name}, {result}
                </Typography>
              ))}
            </>
          )}
        </Box>

        <Collapse in={expandedCaseDetails}>
          {!!data?.summary?.summary_text?.length && (
            <>
              <Typography variant="h6" component="h2">
                Facts of the Case
              </Typography>
              <Typography variant="body2" component="span" gutterBottom className={classes.newlineText}>
                <HighlightedText text={data.case_text} splitTag={splitTag} highlightTag={highlightTag} />
              </Typography>
            </>
          )}
          <Typography variant="h6" component="h2">
            Injury details
          </Typography>
          <Typography variant="body2" component="span" gutterBottom className={classes.newlineText}>
            <HighlightedText text={data.injury_text} splitTag={splitTag} highlightTag={highlightTag} />
          </Typography>
          {data.result_text && (
            <Box>
              <Typography variant="h6" component="h2">
                Result
              </Typography>
              <Typography variant="body2" component="p" gutterBottom className={classes.newlineText}>
                {data.result_text}
              </Typography>
            </Box>
          )}
          {data.injury_types && data.high_level_categories && (
            <TagList items={data.high_level_categories} title="Categories" />
          )}
          <Box>
            <Typography variant="h6" component="h2">
              Full Case name
            </Typography>
            <Typography variant="body2" component="p" gutterBottom>
              {data.case_name}
            </Typography>
          </Box>
        </Collapse>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button size="small" onClick={() => setExpandedCaseDetails(!expandedCaseDetails)}>
          {expandedCaseDetails ? "Collapse Details" : "View Case Details"}
        </Button>

        <Box>
          <IconButton onClick={handleFavoriteClick}>
            {isFavorite ? (
              <FavoriteIcon data-test="favorited-case" />
            ) : (
              <UnFavoriteIcon data-test="unfavorited-case" />
            )}
          </IconButton>

          {data._is_relevant ? (
            <IconButton aria-label="Save for Later" color="primary" onClick={unsave}>
              <SaveIcon />
            </IconButton>
          ) : (
            <IconButton
              aria-label="Save for Later"
              data-test="bookmark"
              color="primary"
              onClick={() => {
                if (caseId) {
                  save().catch(error =>
                    showErrorMessage({
                      message: "There was an error saving a case.",
                      error,
                    })
                  )
                } else {
                  openDemandSelectorDialog()
                }
              }}
            >
              <UnSaveIcon />
            </IconButton>
          )}
        </Box>
      </CardActions>
      {isFavoriteOpen && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={isFavoriteOpen}
          onClose={closeFavoriteDialog}
          className={classes.dialog}
          data-test="favorite-case-dialog"
        >
          <DialogTitle>
            <IconButton onClick={closeFavoriteDialog} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
            Give the case a nickname
          </DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <TextField
                autoFocus
                fullWidth
                id="favorite-case"
                label="Nickname"
                value={nickname}
                onChange={e => setNickname(e.target.value)}
                variant="outlined"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button data-test="save-favorite-button" onClick={saveFavorite}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isDemandSelectorOpen && (
        <DemandSelectorDialog
          isOpen={isDemandSelectorOpen}
          close={closeDemandSelectorDialog}
          searchCaseId={data.id}
        />
      )}
    </Card>
  )
}
