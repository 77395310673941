import React from "react"
import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { DefendantInformationProps } from "../types"
import { StyledDivider, StyledInformationWrapper } from "../styled"
import { InfoContainer } from "./InfoContainer"
import { DEFENDANT_TYPE_KEYS } from "requests/constants"
import { getGender } from "../utils"
import { getDefendantName } from "requests/ViewRequest/utils"

export const DefendantInformation = ({ defendants }: DefendantInformationProps) => {
  return (
    <RequestFormModule title="Defendant Information">
      {defendants.map((defendant, index) => {
        return (
          <React.Fragment key={`${defendant.first_name} ${defendant.last_name}`}>
            {index > 0 && <StyledDivider />}
            <StyledInformationWrapper>
              <InfoContainer
                field="Entity Type"
                value={defendant.type ? DEFENDANT_TYPE_KEYS[defendant.type]?.display : "-"}
              />
              <InfoContainer field="Name" value={getDefendantName(defendant)} />
              <InfoContainer field="Pronoun" value={getGender(defendant.gender)} />
            </StyledInformationWrapper>
          </React.Fragment>
        )
      })}
    </RequestFormModule>
  )
}
