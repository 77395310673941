import { InputField, SelectInput } from "common/form-components"
import { RequestFormModule } from "./RequestFormModule"
import { StyledEntityWrapper, StyledEntityRow } from "./styled"
import { useRequestContext } from "requests/context"
import { RECIPIENT_TYPE_OPTIONS } from "settings/Firm/constants"
import { StateSelect } from "common"
import { useFormContext } from "react-hook-form"

export const RecipientInformation = ({ readOnly }: { readOnly?: boolean }) => {
  const { requestId } = useRequestContext()
  const { control } = useFormContext()

  if (!requestId) {
    return <></>
  }

  return (
    <RequestFormModule title="Recipient’s Information">
      <StyledEntityWrapper>
        <StyledEntityRow>
          <SelectInput
            name="recipient_type"
            options={RECIPIENT_TYPE_OPTIONS}
            label="Recipient Type"
            control={control}
            size="small"
            readOnly={readOnly}
          />

          <InputField
            control={control}
            size="small"
            name="adjuster_first_name"
            type="text"
            label="Recipient's First Name"
            variant="outlined"
            InputProps={{ readOnly }}
            rules={{ maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." } }}
          />

          <InputField
            control={control}
            size="small"
            name="adjuster_last_name"
            type="text"
            label="Recipient's Last Name"
            variant="outlined"
            InputProps={{ readOnly }}
            rules={{ maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." } }}
          />

          <InputField
            control={control}
            size="small"
            name="adjuster_email"
            type="text"
            label="Recipient E-Mail"
            variant="outlined"
            InputProps={{ readOnly }}
            rules={{
              pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/,
                message: `Invalid email address`,
              },
            }}
          />
        </StyledEntityRow>
        <StyledEntityRow>
          <InputField
            control={control}
            size="small"
            name="adjuster_address.street"
            type="text"
            label="Recipient Street"
            variant="outlined"
            InputProps={{ readOnly }}
            sx={{ gridColumn: "span 2" }}
          />
        </StyledEntityRow>
        <StyledEntityRow>
          <InputField
            control={control}
            size="small"
            name="adjuster_address.street_2"
            type="text"
            label="Recipient Street Line 2"
            variant="outlined"
            InputProps={{ readOnly }}
            sx={{ gridColumn: "span 2" }}
          />
        </StyledEntityRow>
        <StyledEntityRow>
          <InputField
            control={control}
            size="small"
            name="adjuster_address.city"
            type="text"
            label="Recipient City"
            variant="outlined"
          />

          <StateSelect
            control={control}
            size="small"
            selectName={`adjuster_address.state`}
            label="Recipient State"
            required={false}
            readOnly={readOnly}
          />

          <InputField
            control={control}
            size="small"
            name="adjuster_address.zip_code"
            type="text"
            label="Recipient Zip Code"
            variant="outlined"
            InputProps={{ readOnly }}
          />
        </StyledEntityRow>
        <StyledEntityRow>
          <InputField
            control={control}
            size="small"
            name="adjuster_fax"
            type="text"
            label="Fax Number"
            variant="outlined"
            InputProps={{ readOnly }}
            sx={{ gridColumn: "span 2" }}
          />
        </StyledEntityRow>
      </StyledEntityWrapper>
    </RequestFormModule>
  )
}
