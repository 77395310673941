import { ClipboardEvent } from "react"
import { Descendant } from "slate"
import wordFilter from "tinymce-word-paste-filter"
import {
  createCitation,
  createEmptyListItem,
  createList,
  createListItem,
  createListItemContent,
  createParagraph,
  createSoftLineBreak,
  createText,
  createVariable,
} from "../../create"
import {
  CitationElement,
  CustomText,
  EditorElementsType,
  EmptySpaceElement,
  ListElement,
  ListItemElement,
  ParagraphElement,
  RootElement,
  SoftLineBreak,
  StyledText,
  VariableElement,
} from "../../CustomEditor"
import { INLINE_ELEMENTS, LEAF_BLOCK_ELEMENTS, LIST_BLOCK_ELEMENTS } from "../../elements"
import { isEmptySpaceNode, isSoftLineBreakNode } from "../../queries/inlines"
import { isVariableHTMLNode, isVariableNode, getVariableHTMLNodeName } from "../../queries/variables"
import { isCitationHTMLNode, isCitationNode } from "../../queries/citations"
import { stylesIFrame } from "../../StylesIFrame"
import { createVariableTextNode, matchVariables } from "../../utils"
import { uniq } from "lodash"

const ZERO_WIDTH_NO_BREAK_SPACE = String.fromCharCode(65279)

const CONTENT_NODES: number[] = [
  Node.DOCUMENT_FRAGMENT_NODE,
  Node.DOCUMENT_NODE,
  Node.ELEMENT_NODE,
  Node.TEXT_NODE,
]

const listTypes: string[] = [LIST_BLOCK_ELEMENTS.ORDERED_LIST, LIST_BLOCK_ELEMENTS.UNORDERED_LIST]
const inlineTagNames = ["br", "b", "strong", "i", "em", "span", "a", "mark", "u"]

const getChildNodes = (node: ChildNode) => {
  const children = Array.from(node.childNodes)

  return children.filter(child => CONTENT_NODES.includes(child.nodeType))
}

export function deserializeTextNode(node: Node, style: StyledText): CustomText {
  return {
    ...style,
    text: node.textContent ? node.textContent.replace(/(\r\n)|(\n\r)/g, "\n").replace(/\r/g, "\n") : "",
  }
}

function hasHighlight(nodeStyles: CSSStyleDeclaration) {
  const isEmpty = Boolean(!nodeStyles.backgroundColor)

  if (isEmpty) {
    return false
  }

  const isTransparent = nodeStyles.backgroundColor === "transparent"
  const isRGBWhite = nodeStyles.backgroundColor === "rgb(255, 255, 255)"
  const isWhite = nodeStyles.backgroundColor === "white"
  const isRGBAWithZeroAlpha =
    nodeStyles.backgroundColor.startsWith("rgba(") && nodeStyles.backgroundColor.endsWith(", 0)")

  return !isTransparent && !isRGBAWithZeroAlpha && !isRGBWhite && !isWhite
}

export function getNodeStyle(node: HTMLElement, style: StyledText): StyledText {
  const nodeStyles = stylesIFrame.getNodeStyles(node)

  const bold = style.bold || ["700", "bold"].includes(nodeStyles.fontWeight)
  const italic = style.italic || nodeStyles.fontStyle === "italic"
  const underline = style.underline || nodeStyles.textDecoration.includes("underline")
  const highlight = style.highlight || hasHighlight(nodeStyles)

  return { bold, italic, underline, highlight }
}

export function generateDOM(html: string, isGoogleDocs: boolean, isMSWord: boolean): Document {
  let htmlForDOM = html

  if (isMSWord) {
    htmlForDOM = wordFilter(html)
  }

  const dom = new DOMParser().parseFromString(htmlForDOM, "text/html")

  if (isGoogleDocs || dom?.body?.firstElementChild?.id.includes("docs-internal-")) {
    const boldTagToReplace = dom.querySelector("body > b")

    if (boldTagToReplace) {
      dom.body.append(...boldTagToReplace.children)

      dom.body.removeChild(boldTagToReplace)
    }
  }

  return dom
}

function isWordContent(content: string) {
  return /class="?Mso|style="[^"]*\bmso-|style='[^'']*\bmso-|w:WordDocument/i.test(content)
}

function replaceVariables(element: ChildNode, parentElement: HTMLElement) {
  const innerTextContent = element?.textContent

  if (!innerTextContent || !parentElement) return

  const uniqueInnerVariablesText = uniq(matchVariables(innerTextContent))

  if (uniqueInnerVariablesText.length) {
    uniqueInnerVariablesText.forEach(variableText => {
      const variableTextNode = createVariableTextNode(variableText)

      if (variableTextNode) {
        parentElement.innerHTML = parentElement.innerHTML.replaceAll(variableText, variableTextNode)
      }
    })
  }
}

function walkThroughElementsAndReplaceVariables(element: ChildNode, parentElement: HTMLElement) {
  if (element.nodeType === element.TEXT_NODE) {
    if (!element.textContent || !parentElement) return

    replaceVariables(element, parentElement)

    return
  }

  const nodeChildeNodes = getChildNodes(element)

  for (const childNode of nodeChildeNodes) {
    walkThroughElementsAndReplaceVariables(childNode, element as HTMLElement)
  }
}

function prepareDom(clipboardData: ClipboardEvent["clipboardData"]) {
  let dom: Document

  if (clipboardData.types.length === 1 && clipboardData.types.includes("text/plain")) {
    let text = clipboardData.getData("text/plain")

    const variablesText = uniq(matchVariables(text))

    if (variablesText.length) {
      variablesText.forEach(variableText => {
        const variableTextNode = createVariableTextNode(variableText)

        if (variableTextNode) {
          text = text.replaceAll(variableText, variableTextNode)
        }
      })
    }

    const html = `<span>${text}</span>`

    dom = generateDOM(html, false, false)
  } else {
    const isGoogleDocs = clipboardData.types.some(type => type.includes("google-docs"))
    const html = clipboardData.getData("text/html")
    const isMSWord = isWordContent(html)

    dom = generateDOM(html, isGoogleDocs, isMSWord)

    walkThroughElementsAndReplaceVariables(dom.body, dom.body)
  }

  stylesIFrame.applyDOM(dom)
}

const shouldDelete = (element: RootElement): boolean => {
  const isParagraph = element.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH

  if (!isParagraph) return false

  if (element.children.length === 0) return true

  if (element.children.length === 1 && "text" in element.children[0] && element.children[0].text === "") {
    return true
  }

  return false
}

export function trimEmptyParagraphs(elements: (ParagraphElement | ListElement<true>)[]): void {
  if (elements.length <= 1) return

  for (let i = 0; i <= elements.length; i++) {
    if (!elements[i] || shouldDelete(elements[i])) {
      elements.splice(i, 1)
    } else {
      break
    }
  }

  if (elements.length <= 1) return

  for (let i = elements.length - 1; i > 0; i--) {
    if (shouldDelete(elements[i])) {
      elements.splice(i, 1)
    } else {
      break
    }
  }
}

export function deserializeFromHtml(clipboardData: ClipboardEvent["clipboardData"]): Descendant[] {
  prepareDom(clipboardData)

  const deserializedNodes = stylesIFrame.bodyChildrenAsArray.map(childNode => {
    return deserializeChildNode(childNode as HTMLElement)
  })
  const flatChildren = flattenChildren(deserializedNodes)
  const result = toBlockElements(flatChildren)

  stylesIFrame.clear()

  trimEmptyParagraphs(result)

  return result
}

export function deserializeFromPlainText(clipboardData: ClipboardEvent["clipboardData"]): Descendant[] {
  prepareDom(clipboardData)

  const deserializedNodes = stylesIFrame.bodyChildrenAsArray.map(childNode =>
    deserializeChildNode(childNode as HTMLElement)
  )
  const flatChildren = flattenChildren(deserializedNodes)
  const result = toBlockElements(flatChildren)

  stylesIFrame.clear()

  return result
}

function isListNode(node: HTMLElement): boolean {
  return ["UL", "OL"].includes(node.tagName)
}

function isListItemNode(node: HTMLElement): boolean {
  return node.tagName === "LI"
}

function getListType(node: HTMLElement): Nullable<LIST_BLOCK_ELEMENTS> {
  if (node.tagName === "UL") return LIST_BLOCK_ELEMENTS.UNORDERED_LIST
  if (node.tagName === "OL") return LIST_BLOCK_ELEMENTS.ORDERED_LIST

  return null
}

export function deserializeListNode(node: HTMLElement): Nullable<ListElement> {
  const listType = getListType(node)

  if (!listType) return null

  const list = createList([], listType)
  const listItems: ListItemElement[] = []

  for (const child of node.children) {
    const childElement = child as HTMLElement

    if (isListItemNode(childElement)) {
      listItems.push(...deserializeListItemNode(childElement))
    } else if (isListNode(childElement)) {
      const nestedList = deserializeListNode(childElement)

      if (!nestedList) {
        continue
      }

      const nextListItems = appendChildToListItem(
        listItems[listItems.length - 1] || createListItem(),
        nestedList
      )
      listItems.pop()
      listItems.push(...nextListItems)
    }
  }

  if (listItems.length) {
    list.children.push(...listItems)
  }

  return list.children.length ? list : null
}

export function appendChildToListItem(
  listItem: ListItemElement,
  child: ListElement | ParagraphElement
): ListItemElement[] {
  const listItems = [listItem]
  let currentListItem = listItem

  if (listItem.children.length === 2) {
    currentListItem = createListItem([createListItemContent([])])
    listItems.push(currentListItem)
  }

  if (listTypes.includes(child.type)) {
    if (!currentListItem.children[0].children.length) {
      currentListItem.children[0].children.push(createParagraph())
    }

    currentListItem.children[1] = child as ListElement

    return listItems
  }

  currentListItem.children[0].children.push(child as ParagraphElement)

  return listItems
}

export function toBlockElements(
  elements: (
    | ListElement
    | ParagraphElement
    | CustomText
    | CitationElement
    | VariableElement
    | SoftLineBreak
    | EmptySpaceElement
  )[]
): (ListElement | ParagraphElement)[] {
  const blockElements: (ListElement | ParagraphElement)[] = []
  const inlineElements: (LEAF_BLOCK_ELEMENTS | LIST_BLOCK_ELEMENTS | INLINE_ELEMENTS)[] = [
    INLINE_ELEMENTS.VARIABLE,
    INLINE_ELEMENTS.CITATION,
  ]
  for (const element of elements) {
    // Variable and Citation are not block elements
    if ("type" in element && !inlineElements.includes(element.type)) {
      blockElements.push(element as ListElement | ParagraphElement)
      continue
    }

    const textElement = element as CustomText
    const lastNode = blockElements[blockElements.length - 1]

    if (lastNode && lastNode.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH && !elements.includes(lastNode)) {
      const nextParagraphs = appendChildToParagraph(lastNode, textElement)
      blockElements.pop()
      blockElements.push(...nextParagraphs)
      continue
    }

    const nextParagraphs = appendChildToParagraph(createParagraph([]), textElement)
    blockElements.push(...nextParagraphs)
  }

  return blockElements
}

export function* normalizeListItemNodes(nodes: ChildNode[]): Generator<ChildNode> {
  for (const node of nodes) {
    if (inlineTagNames.includes(node.nodeName.toLowerCase()) && !node.textContent) continue

    if (node.nodeType !== Node.TEXT_NODE) {
      yield node
      continue
    }

    const span = document.createElement("span")
    span.appendChild(node)
    yield span
  }
}

export function deserializeListItemNode(node: HTMLElement): ListItemElement[] {
  if (!isListItemNode(node)) {
    return []
  }

  const childNodes = getChildNodes(node)

  const normalizedChildNodes = [...normalizeListItemNodes(childNodes)]

  if (normalizedChildNodes.length <= 1) {
    const firstNode = normalizedChildNodes[0] as HTMLElement
    const firstNodeIsText = firstNode?.nodeType === node.TEXT_NODE
    const firstNodeIsBreak = firstNode?.tagName?.toLowerCase() === "br"

    if ((!firstNode || firstNodeIsBreak) && !firstNodeIsText) {
      return [createEmptyListItem()]
    }
  }

  const children: (
    | ListElement
    | ParagraphElement
    | CustomText
    | VariableElement
    | CitationElement
    | SoftLineBreak
    | EmptySpaceElement
  )[] = []

  for (const childNode of normalizedChildNodes) {
    children.push(...deserializeChildNode(childNode as HTMLElement))
  }

  const listItems = [createListItem([createListItemContent([])])]

  for (const child of toBlockElements(children)) {
    const nextListItems = appendChildToListItem(listItems[listItems.length - 1] as ListItemElement, child)
    listItems.pop()
    listItems.push(...nextListItems)
  }

  return listItems
}

export function deserializeChildNode(
  node: HTMLElement
): (
  | ListElement
  | ParagraphElement
  | CustomText
  | VariableElement
  | CitationElement
  | SoftLineBreak
  | EmptySpaceElement
)[] {
  if (node.nodeType === node.TEXT_NODE) {
    return [deserializeTextNode(node as Node, {})]
  }

  if (isListNode(node)) {
    return flattenChildren([deserializeListNode(node)])
  }

  const childNodes = getChildNodes(node)

  if (childNodes.length <= 1) {
    const firstNode = childNodes[0] as HTMLElement
    const firstNodeIsText = firstNode?.nodeType === node.TEXT_NODE
    const firstNodeIsBreak = firstNode?.tagName?.toLowerCase() === "br"

    if ((!firstNode || firstNodeIsBreak) && !firstNodeIsText) {
      return [createParagraph()]
    }
  }

  const tagName = node.tagName?.toLowerCase()

  if (inlineTagNames.includes(tagName)) {
    return deserializeTextContentNode(node, {})
  }

  return deserializeParagraphNode(node)
}

export function flattenChildren<T extends Descendant>(children: Nullable<T | Nullable<T>[]>[]): T[] {
  return children.flat().filter(child => child !== null) as T[]
}

function checkSoftLineBrake(node: HTMLElement, style: StyledText): Nullable<EditorElementsType> {
  const tagName = node?.tagName?.toLowerCase()

  if (!node.childElementCount && node.textContent === ZERO_WIDTH_NO_BREAK_SPACE) {
    return [createText(style), createSoftLineBreak(), createText(style)]
  }

  if (tagName === "br") {
    if (
      !node.previousSibling &&
      !node.nextSibling &&
      inlineTagNames.includes(node.parentElement?.tagName?.toLowerCase() ?? "")
    ) {
      return [createText(style), createSoftLineBreak(), createText(style)]
    }

    if (!node.previousSibling || node.previousSibling.textContent?.endsWith("\n")) {
      return []
    }

    if (!node.nextSibling || node.nextSibling.textContent?.startsWith("\n")) {
      return []
    }

    return [createText(style), createSoftLineBreak(), createText(style)]
  }

  return null
}

export function deserializeTextContentNode(node: HTMLElement, style: StyledText): EditorElementsType {
  if (node.nodeType === node.TEXT_NODE) {
    return [deserializeTextNode(node as Node, style)]
  }

  const tagName = node?.tagName?.toLowerCase()

  const softLineBreak = checkSoftLineBrake(node, style)

  if (softLineBreak) return softLineBreak

  const currentStyle = getNodeStyle(node, style)

  if (isVariableHTMLNode(node)) {
    return [{ ...createVariable(getVariableHTMLNodeName(node)), ...currentStyle }]
  }

  if (isCitationHTMLNode(node)) {
    const { exhibit, pages } = node.dataset
    const pagesArray = pages?.split(",").map(Number)
    const exhibitNumber = Number(exhibit)
    if (!exhibitNumber || !pagesArray || !pagesArray.length) return []
    return [{ ...createCitation({ exhibit: exhibitNumber, pages: pagesArray }) }]
  }

  switch (tagName) {
    case "b":
    case "strong":
      currentStyle.bold = true
      break
    case "i":
    case "em":
      currentStyle.italic = true
      break
    case "u":
      currentStyle.underline = true
      break
    case "mark":
      currentStyle.highlight = true
      break
  }

  const childNodes = getChildNodes(node)

  const children = childNodes.map(childNode => {
    return deserializeTextContentNode(childNode as HTMLElement, currentStyle)
  })

  return flattenChildren(children)
}

export function appendChildToParagraph(
  paragraph: ParagraphElement,
  child: CustomText | VariableElement | CitationElement | SoftLineBreak | EmptySpaceElement
): ParagraphElement[] {
  const paragraphs = [paragraph]

  if (
    isCitationNode(child) ||
    isVariableNode(child) ||
    isSoftLineBreakNode(child) ||
    isEmptySpaceNode(child)
  ) {
    paragraph.children.push(child)

    return paragraphs
  }

  const [currentText, ...texts] = child.text.split("\n")

  if (currentText || !paragraph.children.length) {
    paragraph.children.push({ ...child, text: currentText })
  }

  if (child.text === "\n") {
    return paragraphs
  }

  for (const text of texts) {
    paragraphs.push(createParagraph([{ ...child, text }]))
  }

  return paragraphs
}

export function deserializeParagraphNode(node: HTMLElement): ParagraphElement[] {
  const paragraphs = [createParagraph([])]
  const children: EditorElementsType = []

  const childNodes = getChildNodes(node)

  for (const childNode of childNodes) {
    children.push(...deserializeTextContentNode(childNode as HTMLElement, {}))
  }

  for (const child of children) {
    const nextParagraphs = appendChildToParagraph(
      paragraphs[paragraphs.length - 1] as ParagraphElement,
      child
    )
    paragraphs.pop()
    paragraphs.push(...nextParagraphs)
  }

  return paragraphs
}
