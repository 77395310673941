import { useRef } from "react"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import DeleteIcon from "@mui/icons-material/Delete"
import CloudUpload from "@mui/icons-material/CloudUpload"
import { FILE_OPTIONS, MAX_FILE_NAME_LENGTH } from "./constants"
import { InputLabel } from "@mui/material"

const useStyles = makeStyles()(theme => ({
  fileToUpload: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fileName: {
    paddingRight: theme.spacing(2),
  },
  fileExtension: {
    position: "relative",
    display: "inline-block",
    margin: theme.spacing(0, 2, 0, -1),
  },
  fullWidth: {
    width: "100%",
  },
}))

const strippedFileName = name => {
  if (name) {
    return name.split(".").shift()
  }
  return ""
}

const fileExtension = fileToUpload => {
  if (fileToUpload?.file) {
    const parts = fileToUpload.file.name.split(".")
    if (parts.length > 1) {
      return "." + parts.pop()
    }
  }
  return ""
}

export function FileUploader({ handleFileChange, fileToUpload, setFileToUpload, fileTypes = FILE_OPTIONS }) {
  const { classes, cx } = useStyles()
  const fileRef = useRef()

  const setFile = event => {
    if (event.currentTarget.files.length) {
      setFileToUpload({ file: event.currentTarget.files[0], name: event.currentTarget.files[0].name })
    } else {
      setFileToUpload(null)
    }
  }

  const setFileName = event => {
    setFileToUpload({ ...fileToUpload, name: event.target.value + fileExtension(fileToUpload) })
  }

  const setFileType = event => {
    setFileToUpload({ ...fileToUpload, documentType: event.target.value })
  }

  const handleAddFile = () => {
    handleFileChange(fileToUpload)
    setFileToUpload(null)
  }

  const handleClearFile = () => {
    setFileToUpload(null)
    fileRef.current.value = ""
  }

  const extension = fileExtension(fileToUpload)
  return (
    <Box className={cx(classes.fileUploader, fileToUpload && classes.fullWidth)}>
      <input type="file" ref={fileRef} onChange={setFile} style={{ display: "none" }} />
      <Button
        onClick={() => fileRef.current.click()}
        variant="outlined"
        color="primary"
        startIcon={<CloudUpload />}
      >
        Upload File
      </Button>
      {fileToUpload && (
        <Box className={classes.fileToUpload} marginTop={1}>
          <InputLabel variant="outlined" size="small" className={classes.fileName}>
            {fileToUpload.file.name}
          </InputLabel>
          {/* <Typography variant="body1" component="p" className={classes.fileName}>
            {fileToUpload.file.name}
          </Typography> */}
          <Box>
            <TextField
              label="File name"
              name="filename"
              variant="outlined"
              size="small"
              onChange={setFileName}
              // slightly less than the max to account for file ext getting added automatically
              inputProps={{ maxLength: MAX_FILE_NAME_LENGTH - extension.length }}
              InputLabelProps={{ shrink: true }}
              value={strippedFileName(fileToUpload?.name || fileToUpload?.file.name)}
            />
            <InputLabel variant="outlined" size="small" className={classes.fileExtension}>
              {extension}
            </InputLabel>
          </Box>
          <FormControl className={classes.fileType}>
            <Select
              labelId="file-type-selector"
              value={fileToUpload.documentType || ""}
              onChange={setFileType}
              size="small"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.keys(fileTypes).map(key => (
                <MenuItem key={key} value={key}>
                  {fileTypes[key]}
                </MenuItem>
              ))}
            </Select>
            {!fileToUpload?.documentType && <FormHelperText>Please select the file type</FormHelperText>}
          </FormControl>
          <Button
            variant="outlined"
            onClick={handleAddFile}
            sx={{ lineHeight: 2 }}
            disabled={!fileToUpload?.documentType}
          >
            Add File
          </Button>
          <IconButton onClick={handleClearFile} size="medium">
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}
