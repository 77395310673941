export type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>

type BasicJSONType = string | number | boolean | null

export type RawModel<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends Array<infer U>
    ? RawModel<U>[]
    : T[K] extends BasicJSONType
      ? T[K]
      : T[K] extends BaseObject
        ? RawModel<T[K]>
        : T[K]
}

export function toCamelCase<T extends BaseObject>(
  data: T
): T extends (infer U)[] ? RawModel<U>[] : RawModel<T> {
  if (Array.isArray(data)) {
    return data.map(toCamelCase) as T extends (infer U)[] ? RawModel<U>[] : never
  }
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const [first, ...parts] = key.split("_")
      const camelCasePart: string[] = [
        first.toLowerCase(),
        ...parts.map(part => `${part[0].toUpperCase()}${part.substring(1).toLowerCase()}`),
      ]
      const camelCaseKey = camelCasePart.join("")

      // Recurse
      let newValue = value
      if (typeof value === "object" && value !== null) {
        newValue = toCamelCase(value)
      }
      return [camelCaseKey, newValue]
    })
  ) as T extends (infer U)[] ? RawModel<U>[] : RawModel<T>
}

export function toSnakeCase<T extends BaseObject>(data: RawModel<T>): T {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const snakeCaseKey = key
        .replace(/([A-Z]+)/g, ",$1")
        .replace(/^,/, "")
        .replaceAll(",", "_")
        .toLowerCase()

      return [snakeCaseKey, value]
    })
  ) as T
}
