import { useCallback, useState } from "react"
import { Box, Skeleton } from "@mui/material"
import styled from "@emotion/styled"

import { usePermissions } from "permissions/usePermissions"
import { useDialog } from "hooks/useDialog"

import { IntegrationCard } from "./IntegrationCard"

import { SmartAdvocate } from "./SmartAdvocate"
import smartAdvocateLogo from "./assets/smartadvocate.png"
import { StyledDialog } from "app/styled"
import { withSuspense } from "common/withSuspense"

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
})

const IntegrationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(4),
}))

const Title = styled(Box)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 700,
  marginBottom: theme.spacing(2),
}))

const Description = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  marginBottom: theme.spacing(2),
}))

enum INTEGRATIONS {
  SMART_ADVOCATE = "smart_advocate",
}

export const ImportData = withSuspense(
  () => {
    const [activeIntegration, setActiveIntegration] = useState<Nullable<INTEGRATIONS>>(null)
    const { isOpen, openDialog, closeDialog } = useDialog()

    const { smartAdvocateIntegrationEnabled } = usePermissions()

    const handleCloseDialog = useCallback(() => {
      setActiveIntegration(null)
      closeDialog()
    }, [closeDialog])

    const handleIntegrationClick = useCallback(
      (integration: INTEGRATIONS) => {
        setActiveIntegration(integration)
        openDialog()
      },
      [openDialog]
    )

    if (!smartAdvocateIntegrationEnabled) return <></>

    return (
      <MainContainer>
        <Box>
          <Title>Import Data</Title>
          <Description>Import data from other apps and files into EvenUp.</Description>
        </Box>

        <IntegrationContainer>
          <IntegrationCard
            image={smartAdvocateLogo}
            label="Smart Advocate"
            onClick={() => handleIntegrationClick(INTEGRATIONS.SMART_ADVOCATE)}
          />
        </IntegrationContainer>

        <StyledDialog open={isOpen} onClose={handleCloseDialog} disableEscapeKeyDown maxWidth="lg">
          {activeIntegration === INTEGRATIONS.SMART_ADVOCATE ? (
            <SmartAdvocate onCancelClick={handleCloseDialog} />
          ) : (
            <Box>N/A</Box>
          )}
        </StyledDialog>
      </MainContainer>
    )
  },
  <Box>
    <Skeleton width="400px" />
  </Box>
)
