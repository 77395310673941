import { useEffect, useState } from "react"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { Attributes as AttributesType } from "common/types/attributes"
import {
  AttributeFiltersData,
  SectionAndAttributesFilter,
  SectionWithAttributes,
} from "common/attributes-filter"
import { CASE_SECTIONS } from "common/types/sections"
import { Variable } from "common/types/variables"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"
import { FirmSelect } from "common/attributes-filter/FirmSelect"

import { SectionTemplateFormData as SectionTemplateFormDataType } from "../types"
import { StyledEditorWrapper } from "./styled"

interface SectionTemplateFormProps {
  attributes: AttributesType
  onChange: (data: SectionTemplateFormDataType) => void
  initialSection?: CASE_SECTIONS
  initialAttributeValues?: AttributeFiltersData
  initialContent?: EditorRoot
  initialFirmId: Nullable<PrimaryKey>
  variables?: Variable[]
  error?: boolean
  errorSection?: boolean
  errorInFirm?: boolean
  freezeFirmId?: boolean
}

const INITIAL_SECTION_WITH_ATTIRIBUTES: SectionWithAttributes = { section: null, attributeValues: {} }
const INITIAL_CONTENT: EditorRoot = []
const INITIAL_VARIABLES: Variable[] = []
const INITIAL_FIRM_ID = null

export function SectionTemplateFormData({
  attributes,
  onChange,
  error,
  errorSection,
  errorInFirm,
  initialContent,
  initialSection,
  initialFirmId,
  initialAttributeValues,
  variables,
  freezeFirmId,
}: SectionTemplateFormProps): JSX.Element {
  const [sectionWithAttributes, setSectionWithAttributes] = useState(INITIAL_SECTION_WITH_ATTIRIBUTES)
  const [content, setContent] = useState(initialContent ?? INITIAL_CONTENT)
  const [firmId, setFirmId] = useState(initialFirmId ?? INITIAL_FIRM_ID)

  useEffect(() => {
    onChange({ sectionWithAttributes, content, firmId })
  }, [onChange, sectionWithAttributes, content, firmId])

  return (
    <>
      <SectionAndAttributesFilter
        initialSection={initialSection}
        initialAttributeValues={initialAttributeValues}
        attributes={attributes}
        onChange={setSectionWithAttributes}
        error={error}
        errorSection={errorSection}
      >
        {isFeatureEnabled(FEATURES.FIRM_TEMPLATE) && (
          <FirmSelect
            onlyFirmsUserManage
            helperText={error ? "The field is required" : undefined}
            error={errorInFirm}
            firmId={firmId}
            disabled={freezeFirmId}
            setFirmId={setFirmId}
          />
        )}
      </SectionAndAttributesFilter>
      <StyledEditorWrapper>
        <RichTextEditor
          value={content}
          onChange={setContent}
          keepValue={false}
          withVariables
          variables={variables || INITIAL_VARIABLES}
        />
      </StyledEditorWrapper>
    </>
  )
}
