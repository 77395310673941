import { DOCUMENT_TYPES } from "requests/enums"
import { ViewRequestRevampProps } from "./types"
import { StyledViewRequestWrapper } from "./styled"
import { MedicalChronologyView } from "./MedicalChronologyView"
import { StandardBasicPlusView } from "./StandardBasicPlusView"

const ViewRequestByDocType = ({ request }: ViewRequestRevampProps) => {
  const { type: documentType } = request

  switch (documentType) {
    case DOCUMENT_TYPES.MEDICAL_CHRONOLOGY:
    case DOCUMENT_TYPES.PREMIUM_MEDCHRON:
      return <MedicalChronologyView request={request} />
    default: {
      return <StandardBasicPlusView request={request} />
    }
  }
}

export const ViewRequestRevamp = ({ request }: ViewRequestRevampProps) => {
  return (
    <StyledViewRequestWrapper>
      <ViewRequestByDocType request={request} />
    </StyledViewRequestWrapper>
  )
}
