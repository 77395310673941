import { withSuspense } from "common/withSuspense"
import React from "react"
import { Skeleton, useTheme } from "@mui/material"
import { PageError } from "./PageError"
import { ExecAnalyticsPageContainer } from "./styled"

const LazyPage = React.lazy(() => import("./Page"))

const PageFallback: React.FC = () => {
  const theme = useTheme()
  return (
    <ExecAnalyticsPageContainer sx={{ paddingTop: theme.spacing(7) }}>
      <Skeleton
        variant="rounded"
        sx={{
          borderRadius: "16px",
          width: "100%",
          height: "100dvh",
          marginX: "auto",
        }}
      />
    </ExecAnalyticsPageContainer>
  )
}

export const PageLoader = withSuspense(() => <LazyPage />, <PageFallback />, <PageError />)
