import { PlaintiffProps } from "requests/RequestForm/AdditionalInfoRevamp"
import { OptionProps } from "evenup-ui/MultiSelectAutoComplete"
import { INJURY_IMPACTS_KEYS } from "requests/constants"
import { removeInjuryInInjuryImpactKey } from "requests/RequestForm/AdditionalInfoRevamp/utils"
import { PlaintiffContent, StyledTypographyValue, StyledTypographyLabel } from "../styled"
import { InjuryImpactProps } from "../types"

export const NonEconomicImpacts = ({ plaintiff }: { plaintiff: PlaintiffProps }) => {
  const nonEconomicImpacts: InjuryImpactProps<Omit<OptionProps, "isSelected">[]>[] = []

  Object.entries(INJURY_IMPACTS_KEYS).forEach(([, value]) => {
    const injuryKey = removeInjuryInInjuryImpactKey(value.key)

    if (!plaintiff[injuryKey] || plaintiff[injuryKey]?.length === 0) return

    nonEconomicImpacts.push({
      display: value.display,
      value: plaintiff[injuryKey] as Omit<OptionProps, "isSelected">[],
    })
  })

  return (
    <>
      {nonEconomicImpacts.map(impact => (
        <PlaintiffContent key={impact.display} flexDirection="column">
          <StyledTypographyLabel>Impact on {impact.display}</StyledTypographyLabel>
          <StyledTypographyValue>{impact.value.map(asset => asset.label).join(", ")}</StyledTypographyValue>
        </PlaintiffContent>
      ))}
      <PlaintiffContent key="impacts-other" flexDirection="column">
        <StyledTypographyLabel>Other Impacts</StyledTypographyLabel>
        <StyledTypographyValue>{plaintiff.impacts_other}</StyledTypographyValue>
      </PlaintiffContent>
    </>
  )
}
