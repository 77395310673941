import { deleteListBackward } from "common/form-components/rich-text/commands/lists"
import { DRAFT_NODE_PROPERTY_NAME, NODE_ID_FIELD_NAME } from "common/form-components/rich-text/constants"
import { createVariable } from "common/form-components/rich-text/create"
import {
  CustomEditor,
  ParagraphElement,
  VariableElement,
} from "common/form-components/rich-text/CustomEditor"
import { LEAF_BLOCK_ELEMENTS } from "common/form-components/rich-text/elements"
import {
  getEditorRange,
  getNodeRange,
  getNodeStartRange,
  getVariables,
  isBlockNode,
} from "common/form-components/rich-text/queries"
import { Variable } from "common/types/variables"
import { Editor, NodeEntry, Path, Point, Range, Transforms } from "slate"
import { HistoryEditor } from "slate-history"
import { v4 } from "uuid"
import { DRAFT_VARIABLE_NAME } from "../constants"
import { VariablesEditor } from "../types"

function getVariableByNodeId(editor: CustomEditor, nodeId: string): Nullable<NodeEntry<VariableElement>> {
  const selection = editor.selection || getEditorRange(editor)

  if (!selection) return null

  const paragraphEntry = Editor.above<ParagraphElement>(editor, {
    at: selection,
    match: node => isBlockNode(editor, node) && node.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH,
    mode: "lowest",
  })
  const range: Range | undefined = paragraphEntry
    ? getNodeRange(editor, paragraphEntry[1])
    : getEditorRange(editor)

  if (!range) {
    return null
  }

  const variableElements = getVariables(editor, range)
  const variableNodeEntry = variableElements.find(
    ([node]) => (node as Record<string, unknown>)[NODE_ID_FIELD_NAME] === nodeId
  )

  return variableNodeEntry || null
}

function insertVariableAtPoint(
  editor: CustomEditor & VariablesEditor,
  variable: Variable,
  at: Point,
  { isDraft = false, assignNodeId = false } = {}
): void | string {
  const variableElement = createVariable(variable.name, isDraft)
  const nodeId = v4()

  if (assignNodeId) {
    Object.defineProperty(variableElement, NODE_ID_FIELD_NAME, {
      enumerable: false,
      value: nodeId,
    })
  }

  Transforms.insertNodes(editor, variableElement, { at })

  const variableNodeEntry = getVariableByNodeId(editor, nodeId)

  if (!variableNodeEntry) return

  const [, path] = variableNodeEntry
  const nextSiblingPath = Path.next(path)
  const nextSelection = getNodeStartRange(editor, nextSiblingPath)

  if (nextSelection) {
    Transforms.setSelection(editor, nextSelection)
  }

  if (assignNodeId) {
    return nodeId
  }
}

export function insertVariable(
  editor: CustomEditor & VariablesEditor,
  variable: Variable,
  { isDraft = false, assignNodeId = false, withoutSaving = false } = {}
): void | string {
  if (!editor.selection) return

  const previousSelection = editor.selection

  if (Range.isExpanded(previousSelection)) {
    deleteListBackward(editor) || Transforms.delete(editor, { at: previousSelection, voids: true })
  }

  const range = Range.isBackward(previousSelection) ? previousSelection.focus : previousSelection.anchor

  let result: void | string = void 0

  if (withoutSaving) {
    HistoryEditor.withoutSaving(editor, () => {
      result = insertVariableAtPoint(editor, variable, range, { isDraft, assignNodeId })
    })
  } else {
    result = insertVariableAtPoint(editor, variable, range, { isDraft, assignNodeId })
  }

  return result
}

export function insertDraftVariable(editor: CustomEditor & VariablesEditor): void {
  editor.isSideEffectRunning = true
  editor.isSideEffectUndoable = editor.selection
    ? Range.isExpanded(editor.selection) || getVariables(editor).length > 0
    : false

  const nodeId = insertVariable(
    editor,
    {
      category: "",
      name: DRAFT_VARIABLE_NAME,
      type: "text",
      value: null,
    },
    { isDraft: true, assignNodeId: true, withoutSaving: true }
  )

  HistoryEditor.withoutSaving(editor, () => {
    const variableNodeEntry = nodeId ? getVariableByNodeId(editor, nodeId) : null
    const previousSiblingPath = variableNodeEntry && Path.previous(variableNodeEntry[1])
    const nextSiblingPath = variableNodeEntry && Path.next(variableNodeEntry[1])

    const nextSelection: Nullable<Range> = variableNodeEntry && {
      anchor: Editor.end(editor, previousSiblingPath as Path),
      focus: Editor.start(editor, nextSiblingPath as Path),
    }

    if (nodeId) {
      editor.pendingNodes = editor.pendingNodes || []
      editor.pendingNodes.push(nodeId)
    }

    if (nextSelection) {
      Transforms.setSelection(editor, nextSelection)
    }
  })
}

export function updateDraftVariable(
  editor: CustomEditor & VariablesEditor,
  variable: Nullable<Variable>
): void {
  if (!editor.isSideEffectRunning || !editor.pendingNodes?.length) return

  for (const nodeId of editor.pendingNodes) {
    const variableNodeEntry = getVariableByNodeId(editor, nodeId)

    if (variableNodeEntry) {
      HistoryEditor.withoutSaving(editor, () => {
        Transforms.setNodes(
          editor,
          { name: variable ? variable.name : DRAFT_VARIABLE_NAME } as Partial<VariableElement>,
          {
            at: variableNodeEntry[1],
          }
        )
      })
    }
  }
}

export function clearDraftVariables(
  editor: CustomEditor & VariablesEditor,
  { undoSideEffect = false } = {}
): void {
  HistoryEditor.withoutSaving(editor, () => {
    for (const [node, path] of getVariables(editor, getEditorRange(editor)).reverse()) {
      if (DRAFT_NODE_PROPERTY_NAME in node) {
        Transforms.removeNodes(editor, { at: path, voids: true })
      }
    }

    if (editor.isSideEffectRunning && editor.isSideEffectUndoable && undoSideEffect) {
      HistoryEditor.undo(editor)
    }
  })

  editor.pendingNodes = []
  editor.isSideEffectRunning = false
  editor.isSideEffectUndoable = undefined
}

export function finalizeAddVariable(editor: CustomEditor & VariablesEditor, variable: Variable): void {
  if (!editor.selection) return

  clearDraftVariables(editor)

  const nodeId = insertVariable(editor, variable, { assignNodeId: true })

  if (!nodeId) return

  const variableNodeEntry = getVariableByNodeId(editor, nodeId)

  if (!variableNodeEntry) return

  const [, path] = variableNodeEntry
  const nextSiblingPath = Path.next(path)
  const nextSelection = getNodeStartRange(editor, nextSiblingPath)

  Transforms.setNodes(editor, { [NODE_ID_FIELD_NAME]: undefined }, { at: path })

  if (nextSelection) {
    Transforms.setSelection(editor, nextSelection)
  }
}
