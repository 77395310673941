import { PaginatedOptions } from "api/PaginatedList"
import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { ApiError } from "apiHelper"
import { AttributeFiltersData } from "common/attributes-filter"
import { DocumentStructureLetterheadDto, NewDocumentStructureDto } from "common/types/templates"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { LibraryVariableServiceSerializer } from "../library-variable/serializers"
import { ApiServiceType, NonUniqueAttributesError } from "../types"
import {
  DocumentStructureComponentServiceDeserializer,
  DocumentStructureComponentServiceSerializer,
  DocumentStructureHeadingServiceDeserializer,
  DocumentStructureHeadingServiceSerializer,
  DocumentStructureNamesServiceDeserializer,
  DocumentStructureSectionServiceDeserializer,
  DocumentStructureSectionServiceSerializer,
  DocumentStructureServiceDeserializer,
  DocumentStructureTemplateServiceDeserializer,
  DocumentStructureTemplateServiceSerializer,
} from "./serializers"
import { getQuery } from "../utils"
import {
  DocumentStructureComponentDto,
  DocumentStructureContentReorderDto,
  DocumentStructureHeadingDto,
  DocumentStructureSectionDto,
  DocumentStructureSectionsReorderDto,
  DocumentStructureSubSectionDto,
  DocumentStructureTemplateDto,
} from "./types"

export type DocumentStructuresListOptions = PaginatedOptions & {
  attributes?: AttributeFiltersData
  documentName?: Nullable<string>
  firmId?: Nullable<PrimaryKey>
  archived?: boolean
}

export type DocumentStructureNamesOptions = {
  attributes?: AttributeFiltersData
  firmId?: Nullable<PrimaryKey>
}

interface DocumentStructureServiceOptions {
  documentStructureId: BaseEntity["pk"]
}

export type DocumentStructureServiceEntityOptionsArg<
  TData,
  Exists extends boolean = false,
  TOptions = BaseObject,
> = {
  data: TData
} & (Exists extends true ? { options: DocumentStructureServiceOptions & TOptions } : { options?: never })

export type UploadLetterheadOptionsArg = {
  data: DocumentStructureLetterheadDto
} & { options: DocumentStructureServiceOptions }

enum DOCUMENT_STRUCTURE_API_PATHS {
  BASE = "library/document-structure",
  ARCHIVE = "archive",
  REMOVE_DRAFT = "remove_draft",
  PUBLISH_DRAFT = "publish",
  UNARCHIVE = "unarchive",
  DOCUMENT_NAMES = "document_names",
  UPLOAD_LETTERHEAD = "upload-letterhead",
  DELETE_LETTERHEAD = "delete-letterhead",
  SECTION = "section",
  HEADING = "header",
  CONTENT = "content",
  REORDER = "reorder",
  TEMPLATE_PLACEHOLDER = "template-placeholder",
  COMPONENT_PLACEHOLDER = "component-placeholder",
  COMPONENTS = "components",
  DUPLICATE = "duplicate",
}

export class DocumentStructureService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(
    options?: DocumentStructureServiceOptions,
    paths: DOCUMENT_STRUCTURE_API_PATHS | (DOCUMENT_STRUCTURE_API_PATHS | PrimaryKey)[] = []
  ): string {
    const pathParts = [
      "",
      DOCUMENT_STRUCTURE_API_PATHS.BASE,
      options?.documentStructureId,
      ...(Array.isArray(paths) ? paths : [paths]),
    ]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getDocumentStructuresList = withResponseSerializer(
    DocumentStructureServiceDeserializer.fromPaginatedListJSON,
    ({ firmId, documentName, archived, attributes, ...options }: DocumentStructuresListOptions) => {
      const attributeValues = attributes
        ? LibraryVariableServiceSerializer.toAttributeValuesJSON(attributes)
        : [null]

      const query = getQuery({
        ["attribute_value_ids"]: attributeValues,
        ["firm_id"]: [firmId],
        ["document_name"]: [documentName],
        ["archived"]: [archived],
      })

      return this.apiService.getPaginatedList(this.getPath(), query, options)
    }
  )

  getDocumentStructure = withResponseSerializer(
    DocumentStructureServiceDeserializer.fromJSON,
    async (options: DocumentStructureServiceOptions) => {
      return await handleEmptyResponse(this.apiService.get(null, this.getPath(options)))
    }
  )

  getDocumentStructureNames = withResponseSerializer(
    DocumentStructureNamesServiceDeserializer.definitionFromJSON,
    async ({ firmId, attributes }: DocumentStructureNamesOptions) => {
      const attributeValues = attributes
        ? LibraryVariableServiceSerializer.toAttributeValuesJSON(attributes)
        : [null]

      const query = getQuery({
        ["document_type_id"]: attributeValues,
        ["firm_id"]: [firmId],
      })

      return await handleEmptyResponse(
        this.apiService.get(null, this.getPath(undefined, DOCUMENT_STRUCTURE_API_PATHS.DOCUMENT_NAMES), query)
      )
    }
  )

  archiveDocumentStructure = ({ ...options }: DocumentStructureServiceOptions) => {
    return this.apiService.create({}, this.getPath(options, DOCUMENT_STRUCTURE_API_PATHS.ARCHIVE))
  }

  removeDraftDocumentStructure = ({ ...options }: DocumentStructureServiceOptions) => {
    return this.apiService.create({}, this.getPath(options, DOCUMENT_STRUCTURE_API_PATHS.REMOVE_DRAFT))
  }

  publishDraftDocumentStructure = withResponseSerializer(
    DocumentStructureServiceDeserializer.definitionFromJSON,
    ({ ...options }: DocumentStructureServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.create({}, this.getPath(options, DOCUMENT_STRUCTURE_API_PATHS.PUBLISH_DRAFT))
      )
    }
  )

  unarchiveDocumentStructure = async ({ ...options }: DocumentStructureServiceOptions) => {
    try {
      return await this.apiService.create({}, this.getPath(options, DOCUMENT_STRUCTURE_API_PATHS.UNARCHIVE))
    } catch (error) {
      if (error instanceof ApiError && error.response.status === 400) {
        throw new NonUniqueAttributesError()
      }

      throw error
    }
  }

  uploadLetterhead = withRequestSerializer(
    DocumentStructureServiceDeserializer.toLetterheadDefinitionJSON,
    withResponseSerializer(
      DocumentStructureServiceDeserializer.definitionFromJSON,
      async ({ data, options }: UploadLetterheadOptionsArg) => {
        return await handleEmptyResponse(
          this.apiService.create(data, this.getPath(options, DOCUMENT_STRUCTURE_API_PATHS.UPLOAD_LETTERHEAD))
        )
      }
    )
  )

  deleteLetterhead = withResponseSerializer(
    DocumentStructureServiceDeserializer.definitionFromJSON,
    async ({ options }: { options: DocumentStructureServiceOptions }) => {
      return await handleEmptyResponse(
        this.apiService.delete(null, this.getPath(options, DOCUMENT_STRUCTURE_API_PATHS.DELETE_LETTERHEAD))
      )
    }
  )

  createDocumentStructure = withRequestSerializer(
    DocumentStructureServiceDeserializer.toDefinitionJSON,

    withResponseSerializer(
      DocumentStructureServiceDeserializer.definitionFromJSON,
      async ({ data }: DocumentStructureServiceEntityOptionsArg<NewDocumentStructureDto, false>) => {
        try {
          return await handleEmptyResponse(this.apiService.create(data, this.getPath()))
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 400) {
            throw new NonUniqueAttributesError()
          }

          throw error
        }
      }
    )
  )

  updateDocumentStructure = withRequestSerializer(
    DocumentStructureServiceDeserializer.toDefinitionJSON,
    withResponseSerializer(
      DocumentStructureServiceDeserializer.definitionFromJSON,
      async ({ data, options }: DocumentStructureServiceEntityOptionsArg<NewDocumentStructureDto, true>) => {
        try {
          return await handleEmptyResponse(this.apiService.replace(data, this.getPath(options)))
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 400) {
            throw new NonUniqueAttributesError()
          }
          throw error
        }
      }
    )
  )

  createDocumentStructureSection = withRequestSerializer(
    DocumentStructureSectionServiceSerializer.newToJSON,
    withResponseSerializer(
      DocumentStructureSectionServiceDeserializer.fromUpdateJSON,
      ({
        data,
        options,
      }: DocumentStructureServiceEntityOptionsArg<Omit<DocumentStructureSectionDto, "pk">, true>) => {
        return handleEmptyResponse(
          this.apiService.create(data, this.getPath(options, DOCUMENT_STRUCTURE_API_PATHS.SECTION))
        )
      }
    )
  )

  updateDocumentStructureSection = withRequestSerializer(
    DocumentStructureSectionServiceSerializer.toJSON,
    withResponseSerializer(
      DocumentStructureSectionServiceDeserializer.fromUpdateJSON,
      ({ data, options }: DocumentStructureServiceEntityOptionsArg<DocumentStructureSectionDto, true>) => {
        return handleEmptyResponse(
          this.apiService.update(data, this.getPath(options, [DOCUMENT_STRUCTURE_API_PATHS.SECTION, data.pk]))
        )
      }
    )
  )

  deleteDocumentStructureSection = withResponseSerializer(
    DocumentStructureSectionServiceDeserializer.fromDeleteResponseJSON,
    ({
      data,
      options,
    }: DocumentStructureServiceEntityOptionsArg<{ sectionId: PrimaryKey; id: string }, true>) => {
      return handleEmptyResponse(
        this.apiService.delete(
          data,
          this.getPath(options, [DOCUMENT_STRUCTURE_API_PATHS.SECTION, data.sectionId])
        )
      )
    }
  )

  reorderDocumentStructureSections = withResponseSerializer(
    DocumentStructureSectionServiceDeserializer.fromUpdateJSON,
    ({
      data: { sectionId, ...data },
      options,
    }: DocumentStructureServiceEntityOptionsArg<DocumentStructureSectionsReorderDto, true>) => {
      return handleEmptyResponse(
        this.apiService.create(
          data,
          this.getPath(options, [
            DOCUMENT_STRUCTURE_API_PATHS.SECTION,
            sectionId,
            DOCUMENT_STRUCTURE_API_PATHS.REORDER,
          ])
        )
      )
    }
  )

  updateDocumentStructureSubSection = withRequestSerializer(
    DocumentStructureSectionServiceSerializer.subSectionToJSON,
    withResponseSerializer(
      DocumentStructureServiceDeserializer.fromUpdateJSON,
      ({
        data,
        options,
      }: DocumentStructureServiceEntityOptionsArg<
        Omit<DocumentStructureSubSectionDto, "children">,
        true,
        { sectionId: PrimaryKey; id: PrimaryKey }
      >) => {
        return handleEmptyResponse(
          this.apiService.update(
            { ...data, title: "\u200B" },
            this.getPath(options, [DOCUMENT_STRUCTURE_API_PATHS.SECTION, options.sectionId])
          )
        )
      }
    )
  )

  reorderDocumentStructureContent = withResponseSerializer(
    DocumentStructureServiceDeserializer.fromUpdateJSON,
    ({
      data: { id, ...data },
      options,
    }: DocumentStructureServiceEntityOptionsArg<DocumentStructureContentReorderDto, true>) => {
      return handleEmptyResponse(
        this.apiService.create(
          data,
          this.getPath(options, [
            DOCUMENT_STRUCTURE_API_PATHS.CONTENT,
            id,
            DOCUMENT_STRUCTURE_API_PATHS.REORDER,
          ])
        )
      )
    }
  )

  createDocumentStructureHeading = withRequestSerializer(
    DocumentStructureHeadingServiceSerializer.newToJSON,
    withResponseSerializer(
      DocumentStructureHeadingServiceDeserializer.fromUpdateJSON,
      ({
        data,
        options,
      }: DocumentStructureServiceEntityOptionsArg<
        Omit<DocumentStructureHeadingDto, "pk">,
        true,
        { sectionId: PrimaryKey }
      >) => {
        return handleEmptyResponse(
          this.apiService.create(
            data,
            this.getPath(options, [
              DOCUMENT_STRUCTURE_API_PATHS.SECTION,
              options.sectionId,
              DOCUMENT_STRUCTURE_API_PATHS.HEADING,
            ])
          )
        )
      }
    )
  )

  updateDocumentStructureHeading = withRequestSerializer(
    DocumentStructureHeadingServiceSerializer.toJSON,
    withResponseSerializer(
      DocumentStructureHeadingServiceDeserializer.fromUpdateJSON,
      ({
        data,
        options,
      }: DocumentStructureServiceEntityOptionsArg<
        DocumentStructureHeadingDto,
        true,
        { sectionId: PrimaryKey }
      >) => {
        return handleEmptyResponse(
          this.apiService.update(
            data,
            this.getPath(options, [
              DOCUMENT_STRUCTURE_API_PATHS.SECTION,
              options.sectionId,
              DOCUMENT_STRUCTURE_API_PATHS.HEADING,
              data.pk,
            ])
          )
        )
      }
    )
  )

  deleteDocumentStructureHeading = withResponseSerializer(
    DocumentStructureHeadingServiceDeserializer.fromDeleteResponseJSON,
    ({
      data,
      options,
    }: DocumentStructureServiceEntityOptionsArg<
      { headingId: PrimaryKey; id: string },
      true,
      { sectionId: PrimaryKey }
    >) => {
      return handleEmptyResponse(
        this.apiService.delete(
          data,
          this.getPath(options, [
            DOCUMENT_STRUCTURE_API_PATHS.SECTION,
            options.sectionId,
            DOCUMENT_STRUCTURE_API_PATHS.HEADING,
            data.headingId,
          ])
        )
      )
    }
  )

  createDocumentStructureTemplate = withRequestSerializer(
    DocumentStructureTemplateServiceSerializer.newToJSON,
    withResponseSerializer(
      DocumentStructureTemplateServiceDeserializer.fromUpdateJSON,
      ({
        data,
        options,
      }: DocumentStructureServiceEntityOptionsArg<
        Omit<DocumentStructureTemplateDto, "pk">,
        true,
        { sectionId: PrimaryKey }
      >) => {
        return handleEmptyResponse(
          this.apiService.create(
            data,
            this.getPath(options, [
              DOCUMENT_STRUCTURE_API_PATHS.SECTION,
              options.sectionId,
              DOCUMENT_STRUCTURE_API_PATHS.TEMPLATE_PLACEHOLDER,
            ])
          )
        )
      }
    )
  )

  updateDocumentStructureTemplate = withRequestSerializer(
    DocumentStructureTemplateServiceSerializer.toJSON,
    withResponseSerializer(
      DocumentStructureTemplateServiceDeserializer.fromUpdateJSON,
      ({
        data,
        options,
      }: DocumentStructureServiceEntityOptionsArg<
        DocumentStructureTemplateDto,
        true,
        { sectionId: PrimaryKey }
      >) => {
        return handleEmptyResponse(
          this.apiService.update(
            data,
            this.getPath(options, [
              DOCUMENT_STRUCTURE_API_PATHS.SECTION,
              options.sectionId,
              DOCUMENT_STRUCTURE_API_PATHS.TEMPLATE_PLACEHOLDER,
              data.pk,
            ])
          )
        )
      }
    )
  )

  deleteDocumentStructureTemplate = withResponseSerializer(
    DocumentStructureTemplateServiceDeserializer.fromDeleteResponseJSON,
    ({
      data,
      options,
    }: DocumentStructureServiceEntityOptionsArg<
      { templateId: PrimaryKey; id: string },
      true,
      { sectionId: PrimaryKey }
    >) => {
      return handleEmptyResponse(
        this.apiService.delete(
          data,
          this.getPath(options, [
            DOCUMENT_STRUCTURE_API_PATHS.SECTION,
            options.sectionId,
            DOCUMENT_STRUCTURE_API_PATHS.TEMPLATE_PLACEHOLDER,
            data.templateId,
          ])
        )
      )
    }
  )

  createDocumentStructureComponent = withRequestSerializer(
    DocumentStructureComponentServiceSerializer.newToJSON,
    withResponseSerializer(
      DocumentStructureComponentServiceDeserializer.fromUpdateJSON,
      ({
        data,
        options,
      }: DocumentStructureServiceEntityOptionsArg<
        Omit<DocumentStructureComponentDto, "pk">,
        true,
        { sectionId: PrimaryKey }
      >) => {
        return handleEmptyResponse(
          this.apiService.create(
            data,
            this.getPath(options, [
              DOCUMENT_STRUCTURE_API_PATHS.SECTION,
              options.sectionId,
              DOCUMENT_STRUCTURE_API_PATHS.COMPONENT_PLACEHOLDER,
            ])
          )
        )
      }
    )
  )

  updateDocumentStructureComponent = withRequestSerializer(
    DocumentStructureComponentServiceSerializer.toJSON,
    withResponseSerializer(
      DocumentStructureComponentServiceDeserializer.fromUpdateJSON,
      ({
        data,
        options,
      }: DocumentStructureServiceEntityOptionsArg<
        DocumentStructureComponentDto,
        true,
        { sectionId: PrimaryKey }
      >) => {
        return handleEmptyResponse(
          this.apiService.update(
            data,
            this.getPath(options, [
              DOCUMENT_STRUCTURE_API_PATHS.SECTION,
              options.sectionId,
              DOCUMENT_STRUCTURE_API_PATHS.COMPONENT_PLACEHOLDER,
              data.pk,
            ])
          )
        )
      }
    )
  )

  deleteDocumentStructureComponent = withResponseSerializer(
    DocumentStructureComponentServiceDeserializer.fromDeleteResponseJSON,
    ({
      data,
      options,
    }: DocumentStructureServiceEntityOptionsArg<
      { componentId: PrimaryKey; id: string },
      true,
      { sectionId: PrimaryKey }
    >) => {
      return handleEmptyResponse(
        this.apiService.delete(
          data,
          this.getPath(options, [
            DOCUMENT_STRUCTURE_API_PATHS.SECTION,
            options.sectionId,
            DOCUMENT_STRUCTURE_API_PATHS.COMPONENT_PLACEHOLDER,
            data.componentId,
          ])
        )
      )
    }
  )

  getComponents = () =>
    handleEmptyResponse(
      this.apiService.get<null, ValueOptions<string>>(
        null,
        this.getPath(undefined, DOCUMENT_STRUCTURE_API_PATHS.COMPONENTS)
      )
    )

  duplicateDocumentStructure = withRequestSerializer(
    DocumentStructureServiceDeserializer.toDefinitionJSON,
    withResponseSerializer(
      DocumentStructureServiceDeserializer.definitionFromJSON,
      async ({ data, options }: DocumentStructureServiceEntityOptionsArg<NewDocumentStructureDto, true>) => {
        try {
          return await handleEmptyResponse(
            this.apiService.create(data, this.getPath(options, DOCUMENT_STRUCTURE_API_PATHS.DUPLICATE))
          )
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 400) {
            throw new NonUniqueAttributesError()
          }
          throw error
        }
      }
    )
  )
}

export const documentStructureService = new DocumentStructureService(apiService)
