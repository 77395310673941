import { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { DateField } from "common/form-components"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { downloadAnnotationStats } from "./api"
import { Loading } from "common/loading"

const useStyle = makeStyles()(theme => ({
  formContainer: {
    margin: theme.spacing(10),
  },
  formField: {
    width: "100%",
  },
  submitBox: {
    display: "flex",
    marginTop: theme.spacing(2),
    flexDirection: "row-reverse",
  },
}))

interface FormValues {
  start_date: string
  end_date: string
}

export default function AnnotationStatsRequest(): JSX.Element {
  const { handleSubmit, watch, setValue } = useForm<FormValues>({
    defaultValues: { start_date: "", end_date: "" },
  })
  const { classes } = useStyle()
  const [downloading, setDownloading] = useState<boolean>(false)

  const startDate = watch("start_date")
  const endDate = watch("end_date")

  const handleChangeStartDate = useCallback(
    (newStartDate: Nullable<string>) => setValue("start_date", newStartDate ?? "", { shouldDirty: true }),
    [setValue]
  )

  const handleChangeEndDate = useCallback(
    (newEndDate: Nullable<string>) => setValue("end_date", newEndDate ?? "", { shouldDirty: true }),
    [setValue]
  )

  const download = async (data: FormValues) => {
    setDownloading(true)
    downloadAnnotationStats({
      start_date: data.start_date,
      end_date: data.end_date,
    })
      .then(() => {
        setDownloading(false)
      })
      .catch(() => {
        setDownloading(false)
      })
  }

  return (
    <Box className={classes.formContainer}>
      <Loading show={downloading} label="Creating Labeling Events..." />
      <Typography variant="h5">Pull stats for a time window</Typography>
      <form onSubmit={handleSubmit(download)}>
        <DateField
          initialValue={startDate}
          onChange={handleChangeStartDate}
          label="Start Date"
          fieldProps={{ name: "start_date", size: "small", margin: "normal" }}
          className={classes.formField}
        />
        <DateField
          initialValue={endDate}
          onChange={handleChangeEndDate}
          label="End Date"
          fieldProps={{ name: "end_date", size: "small", margin: "normal" }}
          className={classes.formField}
        />
        <Box className={classes.submitBox}>
          <Button variant="outlined" type="submit" color="primary">
            Download Stats
          </Button>
        </Box>
      </form>
    </Box>
  )
}
