import { createContext } from "react"
import { CASE_STEP } from "./enums"
import { CaseSteps } from "./types"

export const CaseStepsContext = createContext<CaseSteps>({
  caseId: 0,
  items: [],
  section: CASE_STEP.PLAINTIFF,
  currentStep: null,
  nextStep: null,
  getStepStatus: () => null,
})
