import { useCallback, useState, useRef } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import ButtonGroup from "@mui/material/ButtonGroup"
import Menu from "@mui/material/Menu"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import CachedIcon from "@mui/icons-material/Cached"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { CitationElement, EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { CitationSource } from "common/form-components/rich-text/features/citations/types"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import { Flag } from "documents/flags/types"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"

import {
  StyledEditorWrapper,
  StyledAiSummaryWrapper,
  StyledDeleteButton,
  StyledReviewActionMenuItem,
} from "../styled"
import { Circle } from "./styled"
import { APPOINTMENT_TAG_ACTIONS, Appointment } from "documents/types"
import { documentActions, useDocumentStore } from "documents/store"
import { makeStyles } from "tss-react/mui"
import { GENERATED_SUMMARY_STATUSES } from "api/services/documents/types"
import { usePermissions } from "permissions/usePermissions"
import { flagsDataSelectors } from "documents/store/flags"
import useUser from "hooks/useUser"

import { appointmentsSelectors } from "documents/store/appointments"

const OpenAISummaryText = "Open AI Summary"
const CloseAISummaryText = "Close AI Summary"

const useStyles = makeStyles()(theme => ({
  openAIButtonOpen: {
    right: "35%",
    marginLeft: "10%",
  },

  openAIButton: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "0",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "0",

    border: `1px solid ${theme.palette.grey[300]}`,
    borderRightStyle: "hidden",
    top: "50px",
    position: "relative",
    backgroundColor: theme.palette.disabled.background,

    maxWidth: "30px",
    maxHeight: "150px",
    minHeight: "150px",
    minWidth: "30px",

    marginBottom: "50px",
    ":hover": {
      backgroundColor: theme.palette.disabled.background,
    },
  },
  openAIText: {
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    padding: "10px 0 10px 0",
    color: "cornflowerblue",
    minHeight: "150px",
    textDecoration: "underline",
  },
}))

interface DraftingRichTextEditorProps {
  appointment: Appointment
  exhibitReferences: CitationSource[]
  flagIds: string[]
  handleCitationClick: (citation: CitationElement, page: Nullable<number>) => void
  handleRevertChanges: () => void
  handleDeleteAppointment: () => void
  handleSave: () => void
  handleAddFlag: (flagData: Partial<Flag>, callback?: (flag: Flag) => void) => void
}

export const DraftingRichTextEditor = ({
  appointment,
  exhibitReferences,
  flagIds,
  handleCitationClick,
  handleRevertChanges,
  handleDeleteAppointment,
  handleSave,
  handleAddFlag,
}: DraftingRichTextEditorProps) => {
  const { classes, cx } = useStyles()

  const { user } = useUser()
  const { medchronAppointmentTagsEnabled } = usePermissions()

  const currentFlag = useDocumentStore(flagsDataSelectors.getCurrentFlag)
  const [openAiSummaryBox, setOpenAiSummaryBox] = useState(false)
  const summaryText = openAiSummaryBox ? CloseAISummaryText : OpenAISummaryText

  const canRevertSummary = !!(appointment.summary && appointment.generatedSummary)
  const { medicalSummaryFlagsEnabled } = usePermissions()
  const summaryContent = useRef(appointment.summary ?? appointment.generatedSummary ?? DEFAULT_VALUE)

  const completed = useDocumentStore(appointmentsSelectors.getAppointmentCompletedStatus(appointment.id))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleMenuToggleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const saveAndSetAction = (action: APPOINTMENT_TAG_ACTIONS) => {
    documentActions.addAppointmentTag({ appointmentId: appointment.id, action, user })
    handleSave()
    handleClose()
  }

  const handleClickAway = useCallback(() => {
    if (!currentFlag) {
      handleSave()
    }
  }, [currentFlag, handleSave])

  const handleSummaryChanged = useCallback(
    (summary: EditorRoot) => {
      summaryContent.current = summary
      documentActions.setAppointmentSummary({ appointmentId: appointment.id, summary })
    },
    [appointment.id, summaryContent]
  )

  const handleSummaryButton = useCallback(() => {
    setOpenAiSummaryBox(!openAiSummaryBox)
  }, [openAiSummaryBox])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box mt={1} position="relative" display="flex" flexDirection="column">
        <Box mt={1} position="relative" display="flex" flexDirection="row">
          {[GENERATED_SUMMARY_STATUSES.NEW_VERSION_AVAILABLE, GENERATED_SUMMARY_STATUSES.COMPLETED].includes(
            appointment.generatedSummaryStatus
          ) &&
            appointment.generatedSummary && (
              <Button
                className={cx(classes.openAIButton, openAiSummaryBox && classes.openAIButtonOpen)}
                onClick={handleSummaryButton}
              >
                <b className={classes.openAIText}>{summaryText}</b>
              </Button>
            )}

          {openAiSummaryBox && (
            <StyledAiSummaryWrapper>
              <RichTextEditor
                value={appointment.generatedSummary}
                readonly={true}
                withCitations
                citations={exhibitReferences}
                onCitationClick={handleCitationClick}
              />
            </StyledAiSummaryWrapper>
          )}

          <StyledEditorWrapper editMode openAiSummaryBox={openAiSummaryBox}>
            {medicalSummaryFlagsEnabled ? (
              <RichTextEditor
                value={summaryContent.current}
                onChange={handleSummaryChanged}
                keepValue
                stickyToolbar
                withCitations
                citations={exhibitReferences}
                onCitationClick={handleCitationClick}
                withFlags
                flags={flagIds}
                onCreateFlag={handleAddFlag}
              />
            ) : (
              <RichTextEditor
                value={summaryContent.current}
                onChange={handleSummaryChanged}
                keepValue
                stickyToolbar
                withCitations
                citations={exhibitReferences}
                onCitationClick={handleCitationClick}
              />
            )}
            <Box mt={1} display="flex" justifyContent="space-between" flexGrow="1" alignItems="end">
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={handleRevertChanges}
                disabled={!canRevertSummary}
              >
                <CachedIcon fontSize="small" />
                <Box ml={0.5}>Revert to Generated Version</Box>
              </Button>
              {medchronAppointmentTagsEnabled ? (
                <>
                  <ButtonGroup size="small" variant="outlined" color="secondary">
                    <Button onClick={handleSave}>SAVE</Button>
                    <Button onClick={handleMenuToggleClick}>
                      <KeyboardArrowDownIcon />
                    </Button>
                  </ButtonGroup>

                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    transformOrigin={{
                      vertical: -10,
                      horizontal: "right",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <StyledReviewActionMenuItem width="250px" onClick={handleSave}>
                      Save
                    </StyledReviewActionMenuItem>
                    {completed ? (
                      <StyledReviewActionMenuItem
                        width="250px"
                        onClick={() => saveAndSetAction(APPOINTMENT_TAG_ACTIONS.MARK_INCOMPLETE)}
                      >
                        <Circle /> Save &amp; Mark Incomplete
                      </StyledReviewActionMenuItem>
                    ) : (
                      <StyledReviewActionMenuItem
                        width="250px"
                        onClick={() => saveAndSetAction(APPOINTMENT_TAG_ACTIONS.MARK_COMPLETED)}
                      >
                        <Circle completed /> Save &amp; Mark Complete
                      </StyledReviewActionMenuItem>
                    )}
                    <StyledReviewActionMenuItem
                      width="250px"
                      onClick={() => saveAndSetAction(APPOINTMENT_TAG_ACTIONS.MARK_REVIEWED)}
                    >
                      <Circle reviewed /> Save &amp; Mark Reviewed
                    </StyledReviewActionMenuItem>
                  </Menu>
                </>
              ) : (
                <Button color="secondary" variant="outlined" size="small" onClick={handleSave}>
                  SAVE
                </Button>
              )}
            </Box>
          </StyledEditorWrapper>
        </Box>
        <StyledDeleteButton
          onClick={handleDeleteAppointment}
          sx={{ left: openAiSummaryBox ? "38%" : "auto", marginLeft: openAiSummaryBox ? undefined : "30px" }}
        >
          <DeleteIcon fontSize="small" fontWeight={700} />
          <Box fontWeight={700} ml={0.5}>
            Delete Appointment
          </Box>
        </StyledDeleteButton>
      </Box>
    </ClickAwayListener>
  )
}
