import { FullDocumentStructureDefinition } from "common/types/documentStructure"
import { DocumentStructureSection, DocumentStructureBlock } from "../types"
import { DocumentStructureStoreData } from "./types"

export function selectDocumentStructureItemTree(
  state: DocumentStructureStoreData,
  entity: keyof Pick<DocumentStructureStoreData, "items">,
  id: string
): Nullable<FullDocumentStructureDefinition>
export function selectDocumentStructureItemTree(
  state: DocumentStructureStoreData,
  entity: keyof Pick<DocumentStructureStoreData, "sections">,
  id: string
): Nullable<DocumentStructureSection>
export function selectDocumentStructureItemTree(
  state: DocumentStructureStoreData,
  entity: keyof Pick<DocumentStructureStoreData, "blocks">,
  id: string
): Nullable<DocumentStructureBlock>
export function selectDocumentStructureItemTree<
  T extends keyof Pick<DocumentStructureStoreData, "blocks" | "items" | "sections">,
>(
  state: DocumentStructureStoreData,
  entity: T,
  id: string
): Nullable<FullDocumentStructureDefinition | DocumentStructureSection | DocumentStructureBlock> {
  const item = state[entity][id]

  if (!item) return null

  if (entity === "items") {
    const sectionIds = state.structure[id] ?? []

    return {
      ...item,
      sections: sectionIds
        .map(sectionId => selectDocumentStructureItemTree(state, "sections", sectionId))
        .filter(section => section !== null),
    } as FullDocumentStructureDefinition
  }

  const blockIds = state.structure[id] ?? []

  return {
    ...item,
    children: blockIds
      .map(blockId => selectDocumentStructureItemTree(state, "blocks", blockId))
      .filter(block => block !== null),
  } as DocumentStructureSection | DocumentStructureBlock
}
