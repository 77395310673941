import { withSuspense } from "common/withSuspense"
import React from "react"
import { SUPPORT_EMAIL } from "common/constants"
import { Alert, AlertTitle, Box, Skeleton } from "@mui/material"

const LazyPage = React.lazy(() => import("./CasesRoutes"))

const DefaultSkeleton: React.FC = () => {
  return (
    <Skeleton
      variant="rounded"
      sx={{
        borderRadius: "16px",
        width: "100%",
        height: "472px",
        maxWidth: "525px",
        marginTop: "150px",
        marginX: "auto",
      }}
    />
  )
}

const CasesPageError: React.FC = () => {
  return (
    <Box sx={{ maxWidth: "525px", marginTop: "150px", marginX: "auto" }}>
      <Alert severity="error">
        <AlertTitle>Unexpected Error</AlertTitle>
        <Box>
          There was an error loading the page, please try and refresh the page. If your error persists contact{" "}
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </Box>
      </Alert>
    </Box>
  )
}

const CasesPageFallback: React.FC = () => {
  return <DefaultSkeleton />
}

export default withSuspense(() => <LazyPage />, <CasesPageFallback />, <CasesPageError />)
