import React, { useCallback, useMemo } from "react"
import { Attribute } from "common/types/attributes"
import { AttributeFilterValue } from "./types"
import { getAttributeValueOptions, NONE_VALUE } from "./utils"
import { AttributeSelect } from "./AttributeSelect"
import { ATTRIBUTES_HELPER_TEXT_MAP } from "./constants"

interface AttributeFilterProps {
  attribute: Attribute
  value: AttributeFilterValue
  error?: boolean
  onChange: (attribute: Attribute, value: AttributeFilterValue) => void
}

export const AttributeFilter = React.forwardRef<HTMLDivElement, AttributeFilterProps>(
  function AttributeFilter(
    { attribute, value, onChange, error, ...rest }: AttributeFilterProps,
    ref
  ): JSX.Element {
    const attributeValues = useMemo(() => getAttributeValueOptions(attribute), [attribute])
    const selectedValue = value ?? NONE_VALUE
    const attributeNameLabel = attributeValues.items.get(NONE_VALUE) ?? ""
    const helperText = ATTRIBUTES_HELPER_TEXT_MAP[attributeNameLabel]

    const handleChange = useCallback(
      (nextValue: NonNullable<AttributeFilterValue> | typeof NONE_VALUE) => {
        onChange(attribute, nextValue === NONE_VALUE ? null : (nextValue as AttributeFilterValue))
      },
      [attribute, onChange]
    )

    return (
      <AttributeSelect
        ref={ref}
        dataTest={attributeNameLabel}
        error={error}
        value={selectedValue}
        helperText={helperText}
        label={attributeNameLabel}
        options={attributeValues.options}
        onChange={handleChange}
        {...rest}
      />
    )
  }
)
