import styled from "@emotion/styled"
import { DocumentStructureHeading } from "../types"
import { CheckboxInput, InputField, SelectInput } from "common/form-components"
import { HEADING_TYPE } from "../enums"
import { checkboxClasses, inputBaseClasses, selectClasses, typographyClasses } from "@mui/material"

interface StyledHeadingTitleProps {
  empty: boolean
}

export const StyledHeadingTitle = styled(InputField<DocumentStructureHeading>)<StyledHeadingTitleProps>(
  ({ theme, empty }) => ({
    "& input": {
      padding: theme.spacing(0.75, 2),
      minWidth: "300px",

      [`&.${inputBaseClasses.disabled}`]: {
        color: "initial",
        "-webkit-text-fill-color": "initial",
      },
    },

    [`& .${inputBaseClasses.root}`]: {
      fontSize: "14px",

      ...(empty && {
        "& input": {
          fontWeight: "normal",
          fontStyle: "italic",
        },
      }),
    },
  })
)

export const StyledHeadingTypeSelector = styled(SelectInput<DocumentStructureHeading, HEADING_TYPE>)(
  ({ theme }) => ({
    [`& .${selectClasses.select}`]: {
      fontSize: "14px",
      fontWeight: "bold",
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),

      color: "initial",
      "-webkit-text-fill-color": "initial",
    },

    "& fieldset": {
      borderColor: "transparent",
    },
  })
)

export const StyledHeading = styled("form")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  paddingRight: theme.spacing(1),
}))

export const StyledHeadingContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing(1),
}))

export const StyledHeadingCheckbox = styled(CheckboxInput)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 2),
  gap: theme.spacing(0.5),

  [`& .${typographyClasses.root}`]: {
    fontSize: "13px",
  },

  [`& .${checkboxClasses.root}`]: {
    padding: 0,
  },
})) as any as typeof CheckboxInput // eslint-disable-line @typescript-eslint/no-explicit-any
