import { DocumentData, GetState, SetState } from "./types"
import { DocumentRootData, Exhibit } from "documents/types"
import { exhibitService } from "api/services/exhibit"
import { documentsService } from "api/services/documents"
import { documentStateActions } from "./documentState"

export type ExhibitsSlice = Pick<DocumentData, "exhibitsOrder" | "exhibits">

export const exhibitsSlice: ExhibitsSlice = {
  exhibitsOrder: [],
  exhibits: {},
}

export const exhibitSelectors = {
  getExhibitById: (id: Exhibit["id"]) => (state: ExhibitsSlice) => state.exhibits[id],
  getExhibitList: (state: ExhibitsSlice) =>
    state.exhibitsOrder.map(id => exhibitSelectors.getExhibitById(id)(state)),
}

interface RenameExhibitPayload {
  id: Exhibit["id"]
  name: Exhibit["name"]
}

interface MarkExhibitPayload {
  id: Exhibit["id"]
  state: Exclude<Exhibit["state"], undefined>
}

interface ReorderExhibitsPayload {
  exhibits: DocumentRootData["exhibits"]
  id: Exhibit["id"]
  index: number
}

export const exhibitActions = (set: SetState<ExhibitsSlice>, get: GetState<ExhibitsSlice>) => {
  const setExhibit = (exhibit: Exhibit) => {
    set(({ exhibits }) => ({
      exhibits: { ...exhibits, [exhibit.id]: exhibit },
    }))
  }
  const renameExhibit = ({ id, name }: RenameExhibitPayload) => {
    const exhibit = exhibitSelectors.getExhibitById(id)(get())
    setExhibit({ ...exhibit, name })

    const request = exhibitService.renameExhibit({ exhibitId: id, name })
    request.catch(() => setExhibit(exhibit))
    documentStateActions.save(request, "There was an error renaming exhibit.")

    return request
  }
  const markExhibit = ({ id, state }: MarkExhibitPayload) => {
    const exhibit = exhibitSelectors.getExhibitById(id)(get())
    setExhibit({ ...exhibit, state })
  }
  const reorderExhibit = ({ exhibits, id, index }: ReorderExhibitsPayload) => {
    const { documentId, exhibitsOrder } = get()
    set(() => ({ exhibitsOrder: exhibits.map(({ id }) => id) }))

    const request = exhibitService.reorderExhibit({ documentId, exhibitId: id, index })
    request.catch(() => set({ exhibitsOrder }))
    documentStateActions.save(request, "There was an error reordering exhibits.")

    return request
  }
  const sortExhibits = () => {
    const { documentId } = get()
    const request = documentsService.sortDocumentExhibits({ documentId })
    request.then(exhibitsOrder => set({ exhibitsOrder }))
    documentStateActions.save(request, "There was an error sorting exhibits.")

    return request
  }

  return {
    renameExhibit,
    markExhibit,
    reorderExhibit,
    sortExhibits,
  }
}
