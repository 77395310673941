import { PlaintiffInformation } from "./components/PlaintiffInformation"
import { CaseInformation } from "./components/CaseInformation"
import { DefendantInformation } from "./components/DefendantInformation"
import { PolicyCarrierInformation } from "./components/PolicyCarrierInformation"
import { RecipientInformation } from "./components/RecipientInformation"
import { UploadFiles } from "./components/UploadFiles"
import { AttorneyInformation } from "./components/AttorneyInformation"
import { AdditionalInformation } from "./components/AdditionalInformation"
import { FirmInformation } from "./components/FirmInformation"
import { PlaintiffAdditionalInformation } from "./components/PlaintiffAdditionalInformation"
import { DOCUMENT_TYPES } from "requests/enums"
import { useWatch } from "react-hook-form"
import Collaborators from "./components/Collaborators"

export const StandardBasicPlusForm = () => {
  const type = useWatch({ name: "type" })
  const isBasicPlus = type === DOCUMENT_TYPES.BASIC_PLUS
  const isSimple = type === DOCUMENT_TYPES.SIMPLE

  return (
    <>
      <FirmInformation />
      <PlaintiffInformation />
      <CaseInformation />
      <PolicyCarrierInformation />
      <DefendantInformation />
      <RecipientInformation />
      <AttorneyInformation />
      <PlaintiffAdditionalInformation />
      {!isBasicPlus && !isSimple && <AdditionalInformation />}
      <UploadFiles />
      <Collaborators />
    </>
  )
}
