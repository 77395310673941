import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { UserAccountForm } from ".."
import { theme } from "app/theme"

export function NewUserAccount() {
  return (
    <Box>
      <Typography marginBottom={theme.spacing(3)} variant="h4" component="h1">
        Create New User
      </Typography>
      <UserAccountForm />
    </Box>
  )
}
