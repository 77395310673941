import { memo, useCallback } from "react"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { PartitionListItem } from "exhibit-builder/Exhibit/PartitionListItem"
import { ExhibitPartition, MedicalBill, MedicalRecord, UserExhibit } from "exhibit-builder/store/types"
import { useShallow } from "zustand/react/shallow"
import { useExhibitPartitionPreview } from "exhibit-builder/useUserExhibitPreview"

export const RecordOrBillItem = memo(function RecordOrBillItem({
  id,
  userExhibitId,
  partitionId,
}: {
  id: (MedicalRecord | MedicalBill)["id"]
  userExhibitId: UserExhibit["id"]
  partitionId: ExhibitPartition["id"]
}) {
  const item = useExhibitBuilderStore(useShallow(filesSelectors.getRecordOrBillById(id)))
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const providers = useExhibitBuilderStore(useShallow(state => state.providers))

  const handleProviderRename = useCallback(
    (providerId: string, name: string) => exhibitBuilderActions.renameProvider({ providerId, name }),
    []
  )
  const handleProviderChange = useCallback(
    (providerId: Nullable<string>) => {
      if (!item) {
        return
      }

      return exhibitBuilderActions.updateMedicalRecordOrBill({ ...item, providerId }, userExhibitId)
    },
    [item, userExhibitId]
  )

  const handleDateChange = useCallback(
    (date: Nullable<string> | { start: string; end: string }) => {
      if (!item) {
        return
      }

      if (item.type === "Medical Bill" && typeof date === "object" && date?.start && date?.end) {
        return exhibitBuilderActions.updateMedicalRecordOrBill(
          {
            ...item,
            dateOfFirstService: date.start,
            dateOfLastService: date.end,
          },
          userExhibitId
        )
      }

      if (item.type === "Medical Record" && typeof date === "string") {
        return exhibitBuilderActions.updateMedicalRecordOrBill(
          {
            ...item,
            dateOfService: date,
          },
          userExhibitId
        )
      }
    },
    [item, userExhibitId]
  )

  const preview = useExhibitPartitionPreview(partitionId, item?.startPage)

  if (!item) {
    return null
  }

  return (
    <PartitionListItem
      data-test="record-or-bill-item"
      provider={item.providerId}
      providers={Object.values(providers)}
      startPage={item.startPage}
      endPage={item.endPage ?? item.startPage}
      documentType={item.type === "Medical Bill" ? "Bills" : "Records"}
      onDateChange={handleDateChange}
      onProviderChange={handleProviderChange}
      onProviderCreate={exhibitBuilderActions.createProvier}
      onProviderRename={handleProviderRename}
      onPreview={preview}
      dateOfService={
        item.type === "Medical Bill"
          ? { start: item.dateOfFirstService, end: item.dateOfLastService }
          : item.dateOfService
      }
      readOnly={isReadOnly}
    />
  )
})
