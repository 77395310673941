import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Pagination from "@mui/material/Pagination"
import Box from "@mui/material/Box"
import { useQuery } from "@tanstack/react-query"
import { Link as RouterLink, useParams } from "react-router-dom"
import { getFavoriteCase, getRelevantDocuments } from "../api"
import CaseResult from "../common/case-result/CaseResult"
import { queryKeys } from "../react-query/constants"
import { EmptyCasesWrapper, FavoriteCasesPaginationWrapper } from "./styled"
import useCase from "hooks/useCase"

const NoSavedCases = function ({ caseId }) {
  return (
    <EmptyCasesWrapper>
      <Paper data-test="empty-favorite-cases">
        <Typography gutterBottom variant="h5">
          No cases saved under this case name.
        </Typography>
        <Typography gutterBottom component="p">
          Find and add cases through <RouterLink to={`/demands/${caseId}/search`}>Search</RouterLink>!
        </Typography>
      </Paper>
    </EmptyCasesWrapper>
  )
}

export function FavoriteCasesList({ page, setPage, state }) {
  const { id: caseId } = useParams()
  const { caseObj: caseInfo } = useCase(caseId)

  const {
    isLoading,
    data: documents,
    error,
  } = useQuery(
    [
      queryKeys.favoriteCases,
      {
        state: state,
        page: page,
      },
    ],
    getFavoriteCase
  )
  const documentIds = caseInfo?.relevant_documents.map(document => document.elasticsearch_id)

  const {
    isFetching: loadingRelevantDocuments,
    data: relevantDocuments,
    error: relevantDocumentsError,
  } = useQuery([queryKeys.documents, documentIds], getRelevantDocuments, {
    enabled: !!documentIds?.length,
  })

  const handlePageChange = (_event, page) => {
    setPage(page - 1)
  }

  if (error) return "An error has occurred: " + error.message
  if (relevantDocumentsError) return "An error has occurred: " + relevantDocumentsError.message

  return (
    <Box>
      {!isLoading && !loadingRelevantDocuments && !!documents?.results?.length ? (
        documents?.results?.map(document => {
          document._is_favorite = true
          document._is_relevant = relevantDocuments?.results?.some(
            relevantDocument => relevantDocument.id === document.id
          )
          document.favorite_document =
            documents?.favorite_documents?.find(favorite_document => {
              return favorite_document.elasticsearch_id === document.id
            }) || null

          return (
            <CaseResult
              data={document}
              key={document.id}
              caseId={caseId}
              givenNickname={document.nickname}
              caseInfo={caseInfo}
            />
          )
        })
      ) : (
        <NoSavedCases caseId={caseId} />
      )}
      {documents?.paging && (
        <FavoriteCasesPaginationWrapper>
          <Pagination count={documents.paging.total_pages} onChange={handlePageChange} page={page + 1} />
        </FavoriteCasesPaginationWrapper>
      )}
    </Box>
  )
}
