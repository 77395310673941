import styled from "@emotion/styled"
import autocompleteClasses from "@mui/material/Autocomplete/autocompleteClasses"
import omit from "lodash/omit"
import Box from "@mui/material/Box"
import { PopperComponentProps } from "evenup-ui/MultiSelectAutoComplete/types"
import { theme } from "app/theme"

const StyledAutoCompletePopper = styled(Box)({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: theme.spacing(2),
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "center",
      padding: theme.spacing(1),
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
  minWidth: theme.spacing(37.5),
  maxWidth: theme.spacing(50),
})

const PopperComponent = (props: PopperComponentProps) => {
  return <StyledAutoCompletePopper {...omit(props, ["disablePortal", "anchorEl", "open"])} />
}

export default PopperComponent
