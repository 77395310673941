import { PlaintiffProps } from "requests/RequestForm/AdditionalInfoRevamp"
import { ECONOMIC_INJURIES_FIELDS } from "requests/constants"
import { OptionProps } from "evenup-ui/MultiSelectAutoComplete"
import { PlaintiffContent, StyledTypographyValue, StyledTypographyLabel } from "../styled"
import { InjuryImpactProps } from "../types"

export const EconomicImpacts = ({ plaintiff }: { plaintiff: PlaintiffProps }) => {
  const economicImpacts: InjuryImpactProps<string>[] = []

  Object.values(ECONOMIC_INJURIES_FIELDS).forEach(impact => {
    if (!plaintiff[impact.key]) return

    economicImpacts.push({
      display: impact.display,
      value: (plaintiff[impact.key] as OptionProps).label,
    })
  })

  return (
    <>
      {economicImpacts.map(({ display, value }) => (
        <PlaintiffContent key={display}>
          <StyledTypographyLabel>{display}</StyledTypographyLabel>
          <StyledTypographyValue>{value}</StyledTypographyValue>
        </PlaintiffContent>
      ))}
      {plaintiff.household_impairment_rate !== null && (
        <PlaintiffContent>
          <StyledTypographyLabel>Household Impairment</StyledTypographyLabel>
          <StyledTypographyValue>{plaintiff.household_impairment_rate ?? ""}%</StyledTypographyValue>
        </PlaintiffContent>
      )}
      {plaintiff.additional_information && (
        <PlaintiffContent>
          <StyledTypographyLabel>Loss of Income Details</StyledTypographyLabel>
          <StyledTypographyValue>{plaintiff.additional_information}</StyledTypographyValue>
        </PlaintiffContent>
      )}
    </>
  )
}
