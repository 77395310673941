import { Descendant, Editor, Transforms } from "slate"
import { insertFragmentToListItem, isInList } from "./lists"

export function pasteFragment(editor: Editor, nodes: Descendant[]): void {
  if (!editor.selection) return

  if (isInList(editor, editor.selection.anchor.path) || isInList(editor, editor.selection.focus.path)) {
    try {
      insertFragmentToListItem(editor, nodes)
    } catch {
      Transforms.insertFragment(editor, nodes)
    }

    return
  }

  return Transforms.insertFragment(editor, nodes)
}
