import { TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { ExhibitCitation } from "common/citation/ExhibitCitation"
import { useExhibitsPreview } from "documents/exhibits/useExhibitPreview"
import { documentStoreSelectors, useDocumentStore } from "documents/store"
import { isEmpty } from "lodash"
import { formatDate } from "utils"
import { ReferenceChip } from "documents/medical-summary/components/IcdCodeTable/styled"
import { StyledTableHeadCell, StyledViewTable } from "documents/styled"

export const DiagnosticHighlights = (): JSX.Element => {
  const icdCodes = useDocumentStore(documentStoreSelectors.filteredIcdCodes)
  const preview = useExhibitsPreview()

  return (
    <StyledViewTable>
      <TableHead>
        <TableRow>
          <StyledTableHeadCell width="10%">ICD Code</StyledTableHeadCell>
          <StyledTableHeadCell width="55%">Description</StyledTableHeadCell>
          <StyledTableHeadCell width="20%">First Diagnosed</StyledTableHeadCell>
          <StyledTableHeadCell width="15%">Reference</StyledTableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(icdCodes) && (
          <TableRow>
            <TableCell align="center" colSpan={4}>
              <b>There are no icd codes for the selected filters</b>
            </TableCell>
          </TableRow>
        )}
        {icdCodes.map((icdCode, index) => {
          return (
            <TableRow key={icdCode.code}>
              <TableCell>
                <ReferenceChip label={icdCode.code} />
              </TableCell>
              <TableCell>{icdCode.description}</TableCell>
              <TableCell>{formatDate(icdCode.dateOfService, "MM/dd/yyyy", true)}</TableCell>
              <TableCell>
                <ExhibitCitation
                  key={index}
                  index={icdCode.reference.exhibitIndex}
                  pages={[icdCode.reference.page]}
                  onClick={() => preview(icdCode.reference.exhibitId, icdCode.reference.page)}
                />
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </StyledViewTable>
  )
}
