import { useQuery, UseQueryResult } from "@tanstack/react-query"
import MissingFieldsAlert from "./MissingFieldsAlert"

import { getHouseholdServicesValidationFields } from "../../api"
import { queryKeys } from "../../react-query/constants"
import { Plaintiff } from "api/services/case/types"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"

interface Props {
  caseId: number
  currentPlaintiff: Nullable<Plaintiff>
}

type ValidationFieldValue = Nullable<string>
interface ValidationFields {
  future_household_impaired_years: ValidationFieldValue
  future_household_impaired_days: ValidationFieldValue
  future_household_impairment_rate: ValidationFieldValue
  date_of_birth: ValidationFieldValue
  state: ValidationFieldValue
  pk: PrimaryKey
}
type ValidationFieldKey = keyof ValidationFields

const errorFields: ValidationFieldKey[] = ["state", "date_of_birth"]

const MissingLossOfHouseholdAlert = ({ caseId, currentPlaintiff }: Props) => {
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)
  const currentPlaintiffId = currentPlaintiff?.id

  const { data, isLoading }: UseQueryResult<ValidationFields, boolean> = useQuery(
    [queryKeys.householdValidation, caseId, currentPlaintiffId],
    async () => {
      const householdValidation: ValidationFields[] = await getHouseholdServicesValidationFields(caseId)

      if (multiPlaintiffEnabled && currentPlaintiffId) {
        return householdValidation.find(plaintiff => plaintiff.pk === currentPlaintiffId)
      }

      return householdValidation[0]
    }
  )

  if (isLoading || !data) return null

  return <MissingFieldsAlert data={data} errorFields={errorFields} />
}

export { MissingLossOfHouseholdAlert as default }
