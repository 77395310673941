import Box from "@mui/material/Box"
import { PopoverContainer, Row, Link, useStyles } from "../styled"

import { amountInDollars } from "utils"

import { WriteOff } from "./types"
import { Entry } from "../types"

import OverflowCell from "../common/OverflowCell"

interface WritesOffCellProps {
  writeOffs: WriteOff[]
  onEntryClick: (entry: Entry) => void
}
const WritesOffCell = ({ writeOffs, onEntryClick }: WritesOffCellProps): JSX.Element => {
  const { classes } = useStyles()

  return (
    <OverflowCell
      data={writeOffs}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderInTableDisplay={(writeOff: any, index) => {
        const data = writeOff as WriteOff
        return (
          <Box
            className={classes.entry}
            key={`date-${index}`}
            onClick={() => {
              if (writeOff?.entries?.length) {
                const entry = writeOff.entries[0]
                onEntryClick(entry)
              }
            }}
          >
            {amountInDollars(data.amount)}
          </Box>
        )
      }}
      renderPopover={writeOffs => {
        return <WriteOffPopover writeOffs={writeOffs as WriteOff[]} onEntryClick={onEntryClick} />
      }}
    />
  )
}

interface WriteOffPopoverProps {
  writeOffs: WriteOff[]
  onEntryClick: (entry: Entry) => void
}

const WriteOffPopover = ({ writeOffs, onEntryClick }: WriteOffPopoverProps): JSX.Element => {
  return (
    <PopoverContainer padding={2}>
      {writeOffs.map((writeOff: WriteOff, index) => {
        return (
          <Row key={index}>
            <Box>{amountInDollars(writeOff.amount)}</Box>
            <Link
              onClick={() => {
                if (writeOff?.entries?.length) {
                  onEntryClick(writeOff.entries[0])
                }
              }}
            >
              Page {writeOff.entries[0].page}
            </Link>
          </Row>
        )
      })}
    </PopoverContainer>
  )
}

export { WritesOffCell as default }
