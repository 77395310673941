import { ReactNode, useEffect, useMemo } from "react"
import { EditorFeatureRendererProps } from "../types"
import { Variables } from "./Variables"
import { EDITOR_FEATURES } from "../constants"
import { VariablesContext } from "./VariablesContext"
import { isVariablesEditor } from "../../queries"

export function EditableWithVariables({
  editor,
  variables,
  children,
}: EditorFeatureRendererProps<EDITOR_FEATURES.VARIABLES> & { children: ReactNode }): JSX.Element {
  const editorVariables = useMemo(
    () => new Variables(variables.map(variable => [variable.name, variable])),
    [variables]
  )

  useEffect(() => {
    if (isVariablesEditor(editor)) {
      editor.variables = editorVariables
    }
  }, [editor, editorVariables])

  return <VariablesContext.Provider value={editorVariables}>{children}</VariablesContext.Provider>
}
