import { ProjectionData, ProjectionItemData } from "common/nested-list/types"
import {
  DocumentStructureComponent,
  DocumentStructureHeading,
  DocumentStructureSection,
  DocumentStructureTemplate,
} from "./types"
import { ITEM_REF } from "common/nested-list/constants"
import { DOCUMENT_STRUCTURE_BLOCK_TYPE, DOCUMENT_STRUCTURE_ITEM_TYPE, HEADING_TYPE } from "./enums"
import { REF_ID } from "./constants"
import { v4 } from "uuid"

export function canDrag(item: ProjectionItemData<DocumentStructureSection>): boolean {
  if (item[ITEM_REF].id === null) {
    return false
  }

  return true
}

export function canDrop(
  item: ProjectionItemData<DocumentStructureSection>,
  draggingItemId: string,
  items: ProjectionData<DocumentStructureSection>
): boolean {
  const draggingItem = items[draggingItemId]

  if (item[ITEM_REF].id === null) {
    return false
  }

  return draggingItem.type === item.type
}

export function canDropAsChild(
  item: ProjectionItemData<DocumentStructureSection>,
  draggingItemId: string,
  items: ProjectionData<DocumentStructureSection>
): boolean {
  const draggingItem = items[draggingItemId]
  const itemData = item[ITEM_REF]

  if (itemData.id === null) {
    return false
  }

  if (draggingItem.type === DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION) {
    return false
  }

  if (itemData.type === DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION) {
    return true
  }

  return itemData.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.HEADING
}

export function createHeading(): DocumentStructureHeading {
  return {
    type: DOCUMENT_STRUCTURE_ITEM_TYPE.BLOCK,
    blockType: DOCUMENT_STRUCTURE_BLOCK_TYPE.HEADING,
    headingType: HEADING_TYPE.H1,
    repeat: false,
    title: "",
    children: [],
    id: null,
    sectionId: null,
    [REF_ID]: v4(),
  }
}

export function createTemplate(): DocumentStructureTemplate {
  return {
    type: DOCUMENT_STRUCTURE_ITEM_TYPE.BLOCK,
    blockType: DOCUMENT_STRUCTURE_BLOCK_TYPE.TEMPLATE,
    templateType: null,
    children: [],
    id: null,
    [REF_ID]: v4(),
  }
}

export function createComponent(): DocumentStructureComponent {
  return {
    type: DOCUMENT_STRUCTURE_ITEM_TYPE.BLOCK,
    blockType: DOCUMENT_STRUCTURE_BLOCK_TYPE.COMPONENT,
    componentKey: null,
    children: [],
    id: null,
    [REF_ID]: v4(),
  }
}
