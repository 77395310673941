import { create } from "zustand"

interface State {
  showBack: boolean
  backTitle: Nullable<string>
  backHref: string
}

export const usePageStore = create<State>(() => ({
  showBack: true,
  backTitle: null,
  backHref: "",
}))
