import { Node, NodeEntry } from "slate"
import { CustomEditor } from "../CustomEditor"

type NormalizeFn<T extends Node, TReturn> = (editor: CustomEditor, entry: NodeEntry<T>) => TReturn
type DefaultNormalizeFn = (entry: NodeEntry<Node>) => void

export function normalizeWithDefault<T extends Node>(
  normalize: NormalizeFn<T, boolean>,
  defaultNormalize: DefaultNormalizeFn,
  force = true
): NormalizeFn<T, void> {
  return (editor, entry) => {
    const normalized = normalize(editor, entry)

    if (!normalized || force) {
      defaultNormalize(entry)
    }
  }
}
