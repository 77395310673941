import { FC, ReactNode } from "react"
import { EDITOR_FEATURES } from "./constants"
import { EditorFeatureProps, EditorFeatureRendererProps } from "./types"
import { getFeaturesFromProps } from "./utils"
import { EditableWithVariables } from "./variables"
import { EditableWithCitations } from "./citations"
import { EditableWithFlags } from "./flags"
import { EditableWithSuggestions } from "./suggestions/"

type BaseEditorRendererProps = EditorFeatureRendererProps<EDITOR_FEATURES> & { children: ReactNode }
type EditorRenderer = (props: BaseEditorRendererProps) => JSX.Element

type EditorRenderers = Record<EDITOR_FEATURES, (props: BaseEditorRendererProps) => JSX.Element>

export const EDITOR_FEATURE_RENDERERS: EditorRenderers = {
  [EDITOR_FEATURES.VARIABLES]: EditableWithVariables as EditorRenderer,
  [EDITOR_FEATURES.CITATIONS]: EditableWithCitations as EditorRenderer,
  [EDITOR_FEATURES.FLAGS]: EditableWithFlags as EditorRenderer,
  [EDITOR_FEATURES.SUGGESTIONS]: EditableWithSuggestions as EditorRenderer,
}

export function getFeaturesRenderer(props: EditorFeatureProps): FC<BaseEditorRendererProps> {
  const features = getFeaturesFromProps(props)
  const renderers = features.map(feature => EDITOR_FEATURE_RENDERERS[feature])

  return function (renderProps) {
    return renderers.reduce(
      (children, renderer) => {
        renderer({ ...renderProps, children })

        return renderer({ ...renderProps, children })
      },
      <>{renderProps.children}</>
    )
  }
}
