import { isNil, memoize, pickBy } from "lodash"
import { isDevEnvironment } from "infrastructure/env/getEnvironment"

const isDev = isDevEnvironment()
export const getAPIServerURL = () => {
  if (!window?.location?.hostname) return import.meta.env.VITE_API_SERVER || ""

  const { hostname, protocol, pathname, search } = window.location

  // this is used for diagram rendering. the FE will be rendered in a docker container
  // so we need to do docker networking
  if (isDev) {
    const urlParams = new URLSearchParams(search)
    // setting this query param will allow you to develop new diagrams without using docker networking
    // a bit hacky but here we are.
    const disableDockerRouting = urlParams.has("disableDockerRouting")
    const paths = ["/diagrams/calendar"]
    const containsPath = paths.some(path => pathname.includes(path))
    if (containsPath && !disableDockerRouting) {
      return "http://host.docker.internal:8000"
    }
  }

  // Local development
  if (hostname === "localhost") {
    return "http://localhost:8000"
  }

  // E2E test run
  if (hostname.match(/\.e2e\.evenup\.law$/)) {
    return `${protocol}//${hostname.replace(/^lops-/, "api-lops-")}`
  }

  // Ephemeral environments will all be under eph.evenup.law
  // When detected, we know what the actual backend domain is.
  if (hostname.match(/\.eph\.evenup\.law$/)) {
    return `${protocol}//${hostname.split(".")[0]}-api.eph.evenup.law`
  }
  if (hostname.match(/\.int\.evenup\.law$/)) {
    return `${protocol}//${hostname.split(".")[0]}-api.int.evenup.law`
  }
  if (hostname.match(/\.rdprod\.evenup\.law$/)) {
    return `${protocol}//${hostname.split(".")[0]}-api.rdprod.evenup.law`
  }

  return import.meta.env.VITE_API_SERVER || ""
}

export const partialAddress = address => {
  if (
    !(address.street && address.city && address.state && address.zip_code) &&
    (address.street || address.city || address.state || address.zip_code)
  ) {
    return true
  }
  return false
}

export function getChangedFields(data, formState) {
  return pickBy(data, (value, key) => !!formState.dirtyFields[key])
}

export function getCommonMutateOptions({
  setErrors,
  reset,
  setValue,
  setSavedSuccessfully,
  showErrorMessage,
  onSuccessExtra,
  onSuccess,
}) {
  return {
    onMutate: () => {
      setErrors && setErrors(null)
    },
    onSuccess:
      onSuccess ??
      (data => {
        reset(data, { keepValues: true })
        if (data?.pk) {
          setValue && setValue("pk", data.pk)
        }
        setSavedSuccessfully && setSavedSuccessfully(true)
        onSuccessExtra && onSuccessExtra(data)
      }),
    onError: error => {
      if (error?.response?.status === 400) {
        let errorObject = isNil(error?.message) ? null : JSON.parse(error?.message)
        return setErrors && setErrors(errorObject)
      }
      if (error?.response?.status === 500) {
        // in dev env full HTML message will be too large to display on a snackbar
        return showErrorMessage && showErrorMessage()
      }
    },
  }
}

export const stringListToArray = value => {
  return value ? JSON.parse(value.replaceAll(/'/g, "")).map(item => item.toString()) : []
}

// memoize so result can be used in useEffect/useCallback dependency arrays
export const getObjectIds = memoize(objs => {
  if (objs) {
    return objs.map(obj => obj.pk)
  } else {
    return []
  }
})

export const reorderImmutable = (array, startIndex, endIndex) => {
  // duplicate array
  const result = Array.from(array)
  // swap the start and end index
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const getFileExtension = name => {
  if (name) {
    const parts = name.split(".")
    if (parts.length > 1) {
      return "." + parts.pop()
    } else {
      return ""
    }
  }
}

export function JSONparseWithDefaultValue(string, defaultValue = {}) {
  try {
    return JSON.parse(string)
  } catch (e) {
    return defaultValue
  }
}

export const setFormErrors = (error, setError) => {
  const errorObj = JSON.parse(error?.message) ?? {}
  for (const [key, value] of Object.entries(errorObj)) {
    let name = key
    let currentValue = value
    let errorMessage = currentValue[0]

    while (typeof errorMessage !== "string") {
      const newKey = Object.keys(currentValue)[0]
      name = `${name}.${newKey}`
      currentValue = currentValue[newKey]
      errorMessage = currentValue[0]
    }

    setError(name, { type: "custom", message: errorMessage })
  }
}
