import { useState, useContext, useEffect } from "react"
import { LoadingContext } from "./LoadingContext"
import { LoadingId } from "./types"

export function useLoading(id: LoadingId, isLoading: boolean): { isLoadingActive: boolean } {
  const [loadingId] = useState(id)
  const { showLoading, hideLoading, onLoadingChange } = useContext(LoadingContext)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    const removeLoadingChangeHandlers = onLoadingChange(
      loadingId,
      () => setIsActive(true),
      () => setIsActive(false)
    )

    return removeLoadingChangeHandlers
  }, [loadingId, setIsActive, onLoadingChange])

  useEffect(() => {
    isLoading ? showLoading(loadingId) : hideLoading(loadingId)
  }, [loadingId, isLoading, showLoading, hideLoading])

  return { isLoadingActive: isActive }
}
