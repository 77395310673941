import { create } from "zustand"
import { Location } from "react-router-dom"
import { subscribeWithSelector } from "zustand/middleware"

export const locationStore = create(
  subscribeWithSelector<Location & { isInitialized: boolean }>(() => ({
    state: null,
    hash: "",
    key: "",
    pathname: "",
    search: "",
    isInitialized: false,
  }))
)

export { locationStore as useLocationStore }
