import { useMemo } from "react"
import { OptionProps } from "evenup-ui/MultiSelectAutoComplete"
import { useSuspenseQuery } from "@suspensive/react-query"
import useUser from "hooks/useUser"
import { UserDto } from "common/models/user"
import { queryKeys } from "react-query/constants"
import { getInternalUsers } from "api"

import { StyledSelect, StyledFilterWrapper } from "requests/styled"
import { withSuspense } from "common/withSuspense"
import { useFilterState } from "hooks/useFilterState"
import { FilterSkeleton } from "./FilterSkeleton"

interface UserFilterProps {
  value: Nullable<string[]>
  onChange: (ids: string[]) => void
}

const SELF_STRING = "(Me)"

export const UserFilter = withSuspense(
  ({ onChange, value: initialValue }: UserFilterProps) => {
    const { user: currentUser } = useUser()

    const {
      data: { data: users },
    } = useSuspenseQuery<{ data: UserDto[] }>(
      [queryKeys.internalUsers],
      async () => getInternalUsers({ minimal: true }),
      {
        meta: { disableLoader: true },
      }
    )

    const options = useMemo<OptionProps[]>(() => {
      const selfOption = users.find(({ pk }) => pk === currentUser.id)

      const result = users
        .filter(user => user !== selfOption)
        .map(user => ({ label: `${user.first_name} ${user.last_name}`, value: String(user.pk) }))

      if (selfOption) {
        result.unshift({
          label: `${selfOption.first_name} ${selfOption.last_name}${SELF_STRING}`,
          value: String(selfOption.pk),
        })
      }

      return result
    }, [currentUser.id, users])

    const { value, onChangeValueHandler, onClearHandler } = useFilterState({
      initialValue,
      options,
      onChange,
    })

    return (
      <StyledFilterWrapper>
        <StyledSelect
          label="Assignee"
          dataTest="user-filter"
          options={options}
          enableClear
          size="small"
          noOptionsText="No results"
          autoCompletePlaceholderText="Assignees..."
          value={value}
          onChangeValue={onChangeValueHandler}
          onClear={onClearHandler}
          virtualList
        />
      </StyledFilterWrapper>
    )
  },
  <FilterSkeleton label="Assignee" />
)
