import { useMemo } from "react"
import { useSuspenseQuery } from "@suspensive/react-query"
import { FirmDto } from "settings/Firm/Firm"
import { StyledSelect, StyledFilterWrapper } from "requests/styled"
import { queryKeys } from "react-query/constants"
import { getFirms } from "api"
import { withSuspense } from "common/withSuspense"
import { useFilterState } from "hooks/useFilterState"
import { FilterSkeleton } from "./FilterSkeleton"

interface FirmFilterProps {
  value: Nullable<string[]>
  onChange: (ids: string[]) => void
}

export const FirmFilter = withSuspense(
  ({ onChange, value: initialValue }: FirmFilterProps) => {
    const { data: firms } = useSuspenseQuery<FirmDto<false>[]>([queryKeys.firms], () => getFirms(), {
      meta: { disableLoader: true },
    })

    const options = useMemo(() => firms.map(firm => ({ label: firm.name, value: String(firm.pk) })), [firms])

    const { value, onChangeValueHandler, onClearHandler } = useFilterState({
      initialValue,
      options,
      onChange,
    })

    return (
      <StyledFilterWrapper>
        <StyledSelect
          label="Firm"
          options={options}
          enableClear
          size="small"
          dataTest="firm-filter"
          noOptionsText="No results"
          autoCompletePlaceholderText="Firms..."
          value={value}
          onChangeValue={onChangeValueHandler}
          onClear={onClearHandler}
          virtualList
        />
      </StyledFilterWrapper>
    )
  },
  <FilterSkeleton label="Firm" />
)
