import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()({
  fullWidth: {
    gridColumn: "1 / 4",
  },
  indented: {
    marginLeft: "40px",
  },
  tight: {
    margin: "0px",
    fontSize: "10px",
  },
})

const Placeholders = (): JSX.Element => {
  const { classes } = useStyles()

  return (
    <>
      <div className={classes.fullWidth}>
        <div>
          <b>[[firstname]]</b> replaces first name of the client
        </div>
        <div>
          <b>[[lastname]]</b> replaces last name of the client
        </div>
        <div>
          <b>[[title]]</b> replaces title of the client (e.g. Mr / Mrs)
        </div>
        <div>
          <b>[[pronoun]]</b> replaces pronoun of the client (e.g. he / she)
        </div>
        <div>
          <b>[[pronounpossessive]]</b> replaces possessive pronoun of the client (e.g. his / her)
        </div>
        <div>
          <b>[[defendantfirstname]]</b> replaces first name of the defendant
        </div>
        <div>
          <b>[[defendantlastname]]</b> replaces last name of the defendant
        </div>
        <div>
          <b>[[defendanttitle]]</b> replaces title of the defendant (e.g. Mr / Mrs)
        </div>
        <div>
          <b>[[defendantpronoun]]</b> replaces pronoun of the defendant (e.g. he / she)
        </div>
        <div>
          <b>[[defendantpronounpossessive]]</b> replaces possessive pronoun of the defendant (e.g. his / her)
        </div>
        <div>
          <b>[[adjusterfirstname]]</b> replaces first name of the adjuster
        </div>
        <div>
          <b>[[adjusterlastname]]</b> replaces last name of the adjuster
        </div>
        <div>
          <b>[[adjusteremail]]</b> replaces E-mail address of recipient
        </div>
        <div>
          <b>[[adjustertitle]]</b> replaces title of the adjuster (e.g. Mr / Mrs)
        </div>
        <div>
          <b>[[adjusterpronoun]]</b> replaces pronoun of the adjuster (e.g. he / she)
        </div>
        <div>
          <b>[[adjusterpronounpossessive]]</b> replaces possessive pronoun of the adjuster (e.g. his / her)
        </div>
        <div>
          <b>[[insurancecarrier]]</b> replaces name of insurance carrier of defendant
        </div>
        <div>
          <b>[[insuranceaddress]]</b> replaces address of insurance carrier of defendant. Format:
          <div className={classes.indented}>
            <p className={classes.tight}>123 Main</p>
            <p className={classes.tight}>#123</p>
            <p className={classes.tight}>San Diego, CA 92057</p>
          </div>
        </div>
        <div>
          <b>[[policytype]]</b> replaces policy type
        </div>
        <div>
          <b>[[policylimit]]</b> Limit on insurance policy of defendant (e.g. $1,000)
        </div>
        <div>
          <b>[[policynumber]]</b> ID number of insurance policy of defendant
        </div>
        <div>
          <b>[[total_claim_amount]]</b> All damage values summed (e.g. $1,000)
        </div>
        <div>
          <b>[[incidentdate]]</b> replaces date of the incident (e.g. December 25, 2022)
        </div>
        <div>
          <b>[[filingdate]]</b> Current date of generated demand (e.g. December 25, 2022)
        </div>
        <div>
          <b>[[claimnumber]]</b> Claim number for insurance claim
        </div>
        <div>
          <b>[[assignedattorney]]</b> Replaces name of the assigned attorney on the request
        </div>
      </div>
    </>
  )
}

export default Placeholders
