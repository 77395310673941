import { AttributeFilterValue, SectionWithAttributes } from "common/attributes-filter"
import { Attribute } from "common/types/attributes"

export function encodeAttributeValueIdsFromQueryString(
  attributeValueIdsFromQuery: string
): Record<Attribute["id"], AttributeFilterValue> {
  return attributeValueIdsFromQuery
    .split("-")
    .reduce((attributeValueIds: Record<Attribute["id"], AttributeFilterValue>, keyValuePair: string) => {
      const [key, value] = keyValuePair.split("_")

      if (key && value) {
        attributeValueIds[Number(key)] = Number(value) as AttributeFilterValue
      }

      return attributeValueIds
    }, {})
}

export function decodeAttributeValueToQueryString(
  attributeValueIds: Record<Attribute["id"], AttributeFilterValue>
): string {
  return Object.entries(attributeValueIds).reduce((attributeValueIds: string, [key, value]) => {
    if (value !== null) {
      const prefix = attributeValueIds ? "-" : ""

      return attributeValueIds + prefix + String(key) + "_" + String(value)
    }

    return attributeValueIds
  }, "")
}

export function getNewParams(
  params: URLSearchParams,
  nextValues: SectionWithAttributes,
  isSectionChanged: boolean,
  areAttributesChanged: boolean
): Nullable<URLSearchParams> {
  let shouldSetParams = false

  if (isSectionChanged) {
    if (nextValues.section) {
      if (params.get("section") !== nextValues.section) {
        params.set("section", nextValues.section)

        shouldSetParams = true
      }
    } else {
      if (params.get("section")) {
        params.delete("section")

        shouldSetParams = true
      }
    }
  }

  if (areAttributesChanged) {
    if (Object.values(nextValues.attributeValues).some(value => value !== null)) {
      const attributeValueIds = decodeAttributeValueToQueryString(nextValues.attributeValues)

      if (attributeValueIds) {
        if (params.get("attribute_value_ids") !== attributeValueIds) {
          params.set("attribute_value_ids", attributeValueIds)

          shouldSetParams = true
        }
      }
    } else {
      if (params.get("attribute_value_ids")) {
        params.delete("attribute_value_ids")

        shouldSetParams = true
      }
    }
  }

  return shouldSetParams ? params : null
}
