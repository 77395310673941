import React from "react"
import TextField from "@mui/material/TextField"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import DeleteIcon from "@mui/icons-material/Delete"

import { FILE_OPTIONS, MAX_FILE_NAME_LENGTH } from "../constants"
import { FileToUploadType } from "../../common/form-components/files/interfaces"

const useStyles = makeStyles()(theme => ({
  fileToUpload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2, 1, 0),
  },
  fileExtension: {
    position: "relative",
    top: "2em",
    marginLeft: "5px",
  },
}))

const strippedFileName = (name: string | undefined): string => {
  if (!name) return ""

  const split = name.split(".")
  split.pop()
  return split.join(".")
}

const fileExtension = (fileToUpload: FileToUploadType) => {
  let name = ""
  if (fileToUpload?.file) {
    name = fileToUpload.file.name
  } else if (fileToUpload?.name) {
    name = fileToUpload.name
  }

  const parts = name.split(".")
  if (parts.length > 1) {
    return "." + parts.pop()
  }

  return ""
}

type FileOptionsType = typeof FILE_OPTIONS
type FileOptionsKey = keyof FileOptionsType
interface Props {
  filesToUpload: FileToUploadType[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilesToUpload: any
  fileTypes?: FileOptionsType
}

const PendingFileForm = ({
  filesToUpload,
  setFilesToUpload,
  fileTypes = FILE_OPTIONS,
}: Props): JSX.Element => {
  const { classes } = useStyles()

  const updateFiles = (key: string, value: string, index: number): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFilesToUpload((prevFiles: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return prevFiles.map((file: any, i: number) => {
        if (i === index) {
          file[key] = value
          return file
        } else {
          return file
        }
      })
    })
  }

  const setFileName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const name = event.currentTarget.value
    const extension = fileExtension(filesToUpload[index])
    updateFiles("name", `${name}${extension}`, index)
  }

  const setFileType = (event: SelectChangeEvent<string>, index: number) => {
    updateFiles("documentType", `${event.target.value}`, index)
  }

  const handleClearFile = (index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFilesToUpload((prevFiles: any[]) => {
      return prevFiles.filter((_, i: number) => {
        return i !== index
      })
    })
  }

  return (
    <Box>
      {filesToUpload?.map((fileToUpload: FileToUploadType, index) => {
        const extension = fileExtension(fileToUpload)
        const fileName = strippedFileName(fileToUpload?.name || fileToUpload?.file?.name)
        return (
          <Box className={classes.fileToUpload} marginTop={1} key={`pending-form-${index}`}>
            <Box>
              <TextField
                label="File name"
                name="filename"
                variant="outlined"
                onChange={e => {
                  setFileName(e, index)
                }}
                // slightly less than the max to account for file ext getting added automatically
                inputProps={{ maxLength: MAX_FILE_NAME_LENGTH - extension.length }}
                InputLabelProps={{ shrink: true }}
                value={fileName}
              />
              <span className={classes.fileExtension}>{extension}</span>
            </Box>
            <FormControl variant="standard">
              <Select
                labelId="file-type-selector"
                data-test="file-type-select"
                value={fileToUpload.documentType || ""}
                onChange={e => {
                  setFileType(e, index)
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {(Object.keys(fileTypes) as FileOptionsKey[]).map(key => (
                  <MenuItem key={key} value={key}>
                    {fileTypes[key]}
                  </MenuItem>
                ))}
              </Select>
              {!fileToUpload?.documentType && <FormHelperText>Please select the file type</FormHelperText>}
            </FormControl>
            <Box>
              <IconButton onClick={() => handleClearFile(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export { PendingFileForm as default }
