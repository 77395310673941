import Box from "@mui/material/Box"
import Tabs, { TabsProps } from "@mui/material/Tabs"
import Tab, { TabProps } from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import styled from "@emotion/styled"
import { theme } from "app/theme"

const BaseTabs = ({ children, ...props }: TabsProps) => <Tabs {...props}>{children}</Tabs>
const BaseTab = ({ children, ...props }: TabProps) => <Tab {...props}>{children}</Tab>

export const StyledTabs = styled(BaseTabs)(() => ({
  "& .MuiTabs-flexContainer": {
    gap: theme.spacing(4),
  },
  "& .Mui-selected": {
    fontWeight: theme.typography.fontWeightBold,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.blue.main,
    height: theme.spacing(0.5),
  },
}))

export const StyledTab = styled(BaseTab)({
  color: theme.palette.selectableCard.fontColor,
  fontSize: theme.typography.pxToRem(16),
  textTransform: "capitalize",
  padding: 0,
})

export const StyledTypographyFullName = styled(Typography)({
  color: theme.palette.primary.main,
  fontSize: theme.typography.pxToRem(16),
  textTransform: "capitalize",
  padding: 0,
  fontWeight: theme.typography.fontWeightBold,
  display: "flex",
  alignItems: "center",
})

export const StyledTypographyTitle = styled(Typography, {
  shouldForwardProp: prop => prop !== "requestRevampSinglePageFormEnabled",
})(({ requestRevampSinglePageFormEnabled }: { requestRevampSinglePageFormEnabled?: boolean }) => ({
  color: theme.palette.label.ignore,
  fontSize: requestRevampSinglePageFormEnabled ? theme.typography.pxToRem(16) : theme.typography.pxToRem(18),
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(2),
}))

export const StyledTypographyDescription = styled(Typography, {
  shouldForwardProp: prop => prop !== "requestRevampSinglePageFormEnabled",
})(({ requestRevampSinglePageFormEnabled }: { requestRevampSinglePageFormEnabled?: boolean }) => ({
  color: theme.palette.label.ignore,
  fontSize: requestRevampSinglePageFormEnabled ? theme.typography.pxToRem(14) : theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightRegular,
}))

export const StyledSliderContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(4),
  marginBottom: theme.spacing(4),
})

export const StyledTypographyTitleSlider = styled(Typography)({
  color: theme.palette.label.ignore,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightRegular,
})

export const StyledTypographySliderValue = styled(Typography)({
  color: theme.palette.label.ignore,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightBold,
  marginLeft: theme.spacing(2),
  width: theme.spacing(5.5),
})

export const StyledEconomicImpactInputsContainer = styled(Box)({
  display: "flex",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
})

export const StyledNonEconomicImpactInputsContainer = styled(Box)({
  display: "grid",
  gridTemplateColumns: `repeat(auto-fit, minmax(${theme.spacing(50)}, 1fr))`,
  gap: theme.spacing(2),
  rowGap: theme.spacing(3),
  marginTop: theme.spacing(4),
})

export const StyledAdditionalInfoWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: theme.spacing(2),
  gridColumn: "1/3",
})
