import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { VariableSizeList, ListOnScrollProps, ListOnItemsRenderedProps } from "react-window"
import { isUndefined } from "lodash"
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist/types/src/display/api"

import { PdfPage, PdfPageData } from "./PdfPage"
import { BASE_DPI, OPTIMAL_DPI, PAGE_SPACING, SCALE, US_LETTER_SIZE } from "./constants"
import { PagesWrapper } from "./styled"
import { PdfAnnotationsType } from "./PdfAnnotations"

function getPageVerticalSpace(height: number, scale: number): number {
  return height * scale + PAGE_SPACING * 2
}

interface PdfDocumentProps {
  document: PDFDocumentProxy
  pagesCount: number
  currentPage: number
  onPageChange(page: number): void
  viewportWidth: number
  scale?: number
  deletedPages?: Set<number>
  annotations: Record<number, PdfAnnotationsType[]>
  pageNumberAdjustment: number
}

export const PdfDocument = memo<PdfDocumentProps>(function PdfDocument({
  annotations,
  document,
  pagesCount,
  currentPage,
  onPageChange,
  viewportWidth,
  scale: controlledScale,
  deletedPages = new Set(),
  pageNumberAdjustment = 0,
}): JSX.Element {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [listHeight, setListHeight] = useState<Nullable<number>>(null)
  const offsetRef = useRef<Nullable<number>>(null)
  const listRef = useRef<VariableSizeList>(null)
  const itemsHeightRef = useRef<Partial<Record<number, number>>>({})
  const currentPageRef = useRef(-1)
  const visibleItemsRef = useRef<number[]>([])

  const scale = useMemo(() => {
    if (!isUndefined(controlledScale)) {
      return SCALE * controlledScale
    }

    return Math.min(viewportWidth / ((US_LETTER_SIZE.WIDTH / OPTIMAL_DPI) * BASE_DPI), SCALE) || 1
  }, [viewportWidth, controlledScale])
  const defaultPageHeight = (US_LETTER_SIZE.HEIGHT / OPTIMAL_DPI) * BASE_DPI * scale
  const initialScrollOffset = useRef(
    getPageVerticalSpace(US_LETTER_SIZE.HEIGHT, scale) * (Math.min(currentPage, pagesCount) - 1)
  )
  const initialPage = useRef<Nullable<number>>(currentPage)

  const getItemHeight = useCallback(
    (index: number): number => {
      const itemHeight = itemsHeightRef.current[index + 1]
      return getPageVerticalSpace(itemHeight ?? defaultPageHeight, itemHeight ? 1 : scale)
    },
    [defaultPageHeight, scale]
  )

  const handleResize = useCallback(() => {
    if (!wrapperRef.current) return

    setListHeight(wrapperRef.current.getBoundingClientRect().height)
  }, [])

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(entries => {
        if (!wrapperRef.current) return

        for (const entry of entries) {
          setListHeight(entry.contentRect.height - (offsetRef.current ?? 0))
        }
      }),
    []
  )

  const handlePageLoad = useCallback((pageProxy: PDFPageProxy, scale: number) => {
    itemsHeightRef.current[pageProxy.pageNumber] = pageProxy.getViewport({ scale }).height

    if (listRef.current) {
      listRef.current.resetAfterIndex(pageProxy.pageNumber - 1)
    }
  }, [])

  const pagesData = useMemo<PdfPageData>(
    () => ({
      document,
      onPageLoaded: handlePageLoad,
      scale,
      viewportWidth,
      deletedPages,
      isControlledScale: !isUndefined(controlledScale),
      // this is where all of the annotations will go that need to be rendered on the FE
      annotations: annotations,
      pageNumberAdjustment,
    }),
    [
      document,
      handlePageLoad,
      scale,
      viewportWidth,
      deletedPages,
      controlledScale,
      annotations,
      pageNumberAdjustment,
    ]
  )

  const handleItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }: ListOnItemsRenderedProps) => {
      visibleItemsRef.current = [visibleStartIndex]

      if (visibleStartIndex !== visibleStopIndex) {
        visibleItemsRef.current.push(visibleStartIndex + 1)
      }
    },
    []
  )

  const handleScroll = useCallback(
    ({ scrollOffset }: ListOnScrollProps): void => {
      if (!wrapperRef.current) return

      const actualValue = currentPageRef.current

      if (visibleItemsRef.current.length === 1) {
        currentPageRef.current = visibleItemsRef.current[0] + 1
      } else if (visibleItemsRef.current.length > 1) {
        const middlePosition = scrollOffset + Math.floor((listHeight ?? 0) / 2)
        const [firstPageIdx, secondPageIdx] = visibleItemsRef.current

        const secondPage = wrapperRef.current.querySelector(`#pdf_page_${secondPageIdx + 1}`)

        if (secondPage) {
          const secondPageTop = parseInt((secondPage as HTMLDivElement).style.getPropertyValue("top"))
          const pageIdx = secondPageTop <= middlePosition ? secondPageIdx : firstPageIdx

          currentPageRef.current = pageIdx + 1
        }
      }

      if (actualValue !== currentPageRef.current) {
        onPageChange(currentPageRef.current)
      }
    },
    [listHeight, onPageChange]
  )

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0)
    }
  }, [scale])

  useEffect(() => {
    if (wrapperRef.current) {
      offsetRef.current = wrapperRef.current.offsetTop
      resizeObserver.observe(window.document.documentElement)

      return () => resizeObserver.unobserve(window.document.documentElement)
    }
  }, [handleResize, resizeObserver])

  useEffect(() => {
    if (currentPage === currentPageRef.current) return

    currentPageRef.current = currentPage

    if (listRef.current) {
      listRef.current.scrollToItem(currentPage - 1, "start")
    }
  }, [listHeight, currentPage])

  useEffect(() => {
    if (listRef.current && initialPage.current !== null) {
      const list = listRef.current
      const page = initialPage.current
      initialPage.current = null

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          list.scrollToItem(page - 1, "start")
        })
      })
    }
  }, [listHeight])

  return (
    <PagesWrapper data-test="pdf-document-pages" ref={wrapperRef}>
      {listHeight !== null && (
        <VariableSizeList
          ref={listRef}
          height={listHeight}
          width={"100%"}
          itemCount={pagesCount}
          itemSize={getItemHeight}
          estimatedItemSize={getPageVerticalSpace(defaultPageHeight, scale)}
          itemData={pagesData}
          overscanCount={1}
          initialScrollOffset={initialScrollOffset.current}
          onItemsRendered={handleItemsRendered}
          onScroll={handleScroll}
        >
          {PdfPage}
        </VariableSizeList>
      )}
    </PagesWrapper>
  )
})
