import React from "react"
import { withSuspense } from "common/withSuspense"
import { Loading } from "common/loading"

import { GenericErrorForSuspense } from "common"
import ContextProviders from "./ContextProviders"
import AppRoutes from "./Routes"
import { MessagesSnackbar } from "common/messages/MessagesSnackbar"
import { AccountNotFoundDialog } from "common/AccountNotFoundDialog"

const App = withSuspense(
  React.memo(function App() {
    return (
      <ContextProviders>
        <AppRoutes />
        <MessagesSnackbar />
        <AccountNotFoundDialog />
      </ContextProviders>
    )
  }),
  <ContextProviders>
    <Loading />
  </ContextProviders>,
  <GenericErrorForSuspense />
)

export default App
