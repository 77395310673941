import React, { PropsWithChildren } from "react"
import { StyledListItem } from "./styled"

interface NestedListItemProps<T> {
  item: T
  content: React.ReactElement
}

export function NestedListItem<T>({ content, children }: PropsWithChildren<NestedListItemProps<T>>) {
  return (
    <StyledListItem>
      <div>{content}</div>
      <div>{children}</div>
    </StyledListItem>
  )
}
