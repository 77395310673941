import { SelectChangeEvent } from "@mui/material"
import { AuthorizedUser } from "common/models/user"
import { INTAKE_STATUSES } from "requests/constants"
import { TagLabelData } from "common/TagLabel"
import requestStatusProperties from "requests/RequestStatus/requestStatusProperties"
import { StatusSelector } from "requests/RequestStatus/StatusSelector"
import StatusLabel from "common/status/StatusLabel"
import { RequestTagLabels } from "./RequestTagLabels"
import { StatusRowContainer, StatusColumnContainer, RequestInlineStatus } from "./styled"

interface RequestStatusBlockProps {
  status: INTAKE_STATUSES
  user: AuthorizedUser
  editable: boolean
  display: "row" | "column"
  tags?: TagLabelData[]
  demandId: Nullable<PrimaryKey>
  revised: boolean
  onChange: (nextStatus: INTAKE_STATUSES) => void
  hasMissingDocs: boolean
}

export const RequestStatusBlock = ({
  status,
  user,
  editable,
  display,
  demandId,
  revised,
  onChange,
  tags,
  hasMissingDocs,
}: RequestStatusBlockProps): JSX.Element => {
  const statusStyle = requestStatusProperties(status, user.role)
  const Container = display === "row" ? StatusRowContainer : StatusColumnContainer

  return (
    <Container>
      {editable ? (
        <StatusSelector
          currentStatus={status}
          onChange={(e: SelectChangeEvent<INTAKE_STATUSES>) => onChange(e.target.value as INTAKE_STATUSES)}
          demandId={demandId}
          revised={revised}
          hasMissingDocs={hasMissingDocs}
        />
      ) : (
        <RequestInlineStatus>
          <strong>Status:</strong>
          <StatusLabel
            status={status}
            text={statusStyle.text}
            color={statusStyle.color}
            showDot={false}
            filled={false}
          />
        </RequestInlineStatus>
      )}
      <RequestTagLabels tags={tags} />
    </Container>
  )
}
