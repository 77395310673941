import { useMemo } from "react"

export enum FEATURES {
  SHOW_APP_VERSION = "show_app_version",
  REVISION = "revision",
  ANNOTATION = "annotation",
  ANNOTATED_EXHIBIT_PREVIEW = "annotated_exhibit_preview",
  SPLIT_INTAKE_FILES = "split_intake_files",
  CDN_UPLOAD = "cnd_upload",
  CDN_VERIFIED_UPLOAD = "cdn_verified_upload",
  FIRM_TEMPLATE = "firm_template",
  PRICING_MODELS_ENABLED = "pricing_models_enabled",
  REMOVE_IMPS = "remove_imps",
}

type FEATURE_LITERALS = `${FEATURES}`

type FeaturesReturn = {
  isFeatureEnabled: (feature: FEATURE_LITERALS, value?: string) => boolean
}

export function isFeatureEnabled(feature: FEATURE_LITERALS, value = "true"): boolean {
  const envProperty = `VITE_${feature.toUpperCase()}`
  const envValue = import.meta.env[envProperty] as string | undefined

  return envValue?.toLowerCase() === value.toLowerCase()
}

export function useFeatures(): FeaturesReturn {
  const result = useMemo(() => ({ isFeatureEnabled }), [])

  return result
}
