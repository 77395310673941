import { useCallback } from "react"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"

import { INTAKE_STATUSES } from "../../requests/constants"
import useUser from "../../hooks/useUser"

import CancelWithConfirmation from "../../requests/RequestStatus/CancelWithConfirmation"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { StatusInfo } from "./StatusInfo"
import { CancelRevisionRequest } from "requests/ViewRequest/Revision/CancelRevisionRequest"

const useStyles = makeStyles()(theme => ({
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1, 0),
    width: "fit-content",
  },
  descriptionContainer: {
    backgroundColor: theme.palette.blue.light,
    padding: theme.spacing(2),
    borderRadius: "3px",
  },
}))

export default function StatusActions({
  requestId,
  status,
  onUpdateStatus,
  onUpdateFiles,
  updatedFilesEnabled = false,
  revisionRequest = null,
}) {
  const { classes } = useStyles()
  const { user } = useUser()
  const { isFeatureEnabled } = useFeatures()
  const hasRevision = !!revisionRequest
  const handleCancel = useCallback(nextStatus => onUpdateStatus(nextStatus, false), [onUpdateStatus])

  const UpdateStatusButton = ({ status, text, dataTest = "update-status" }) => (
    <Button
      style={{ marginBottom: "6px" }}
      variant="contained"
      color="primary"
      onClick={() => onUpdateStatus(status)}
      data-test={dataTest}
    >
      {text}
    </Button>
  )

  const requestReworkButton = isFeatureEnabled(FEATURES.REVISION) ? null : (
    <UpdateStatusButton
      status={INTAKE_STATUSES.rework}
      text="Request Rework"
      onUpdateStatus={onUpdateStatus}
    />
  )

  const cancelRequestView = hasRevision ? (
    <Box>
      <CancelRevisionRequest
        revisionRequest={revisionRequest}
        requestId={requestId}
        variant="text"
        color="secondary"
        size="medium"
      />
    </Box>
  ) : (
    <CancelWithConfirmation onCancel={handleCancel} />
  )

  const statusInfo = <StatusInfo data-test="update-status" status={status} hasRevision={hasRevision} />

  const getNextStatus = () => {
    switch (status) {
      case INTAKE_STATUSES.requested:
        return user.isInternal ? (
          <>
            <UpdateStatusButton status={INTAKE_STATUSES.inProgress} text="Mark Request In Progress" />
            <UpdateStatusButton
              status={INTAKE_STATUSES.readyForTasker}
              text="Mark Request Ready for Tasker"
              dataTest="ready-for-tasker-button"
            />
          </>
        ) : (
          cancelRequestView
        )
      case INTAKE_STATUSES.preProcessing:
        return (
          user.isInternal && (
            <>
              <UpdateStatusButton
                status={INTAKE_STATUSES.inProgress}
                text="Mark Request In Progress"
                dataTest="mark-request-in-progress-button"
              />
              <UpdateStatusButton
                status={INTAKE_STATUSES.readyForTasker}
                text="Mark Request Ready for Tasker"
                dataTest="ready-for-tasker-button"
              />
            </>
          )
        )
      case INTAKE_STATUSES.readyForTasker:
      case INTAKE_STATUSES.taskerAssigned:
      case INTAKE_STATUSES.taskerCompleted:
        return (
          user.isInternal && (
            <UpdateStatusButton
              status={INTAKE_STATUSES.inProgress}
              text="Mark Request In Progress"
              dataTest="mark-request-in-progress-button"
            />
          )
        )

      case INTAKE_STATUSES.inProgress:
        return (
          user.isInternal && (
            <>
              <UpdateStatusButton status={INTAKE_STATUSES.completed} text="Mark Request Completed" />
              <UpdateStatusButton
                status={INTAKE_STATUSES.readyForTasker}
                text="Mark Request Ready for Tasker"
                dataTest="ready-for-tasker-button"
              />
              {updatedFilesEnabled && (
                <Button variant="contained" color="primary" onClick={() => onUpdateFiles()}>
                  Refetch files
                </Button>
              )}
            </>
          )
        )

      case INTAKE_STATUSES.completed:
        return user.isInternal ? (
          <>
            {updatedFilesEnabled && (
              <Button variant="contained" color="primary" onClick={() => onUpdateFiles()}>
                Refetch files
              </Button>
            )}
          </>
        ) : (
          requestReworkButton
        )
      case INTAKE_STATUSES.rework:
        return user.isInternal ? (
          <UpdateStatusButton status={INTAKE_STATUSES.redelivered} text="Mark request as re-delivered" />
        ) : null
      case INTAKE_STATUSES.redelivered:
        return !user.isInternal ? requestReworkButton : null
      case INTAKE_STATUSES.cancelled:
        return null
      default:
        return null
    }
  }

  return (
    <Box className={classes.actionsContainer}>
      {statusInfo}
      {getNextStatus()}
    </Box>
  )
}
