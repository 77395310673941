import Skeleton from "@mui/material/Skeleton"

import { Container } from "../Container"

import { Header } from "../styled"

const LoadingState = (): JSX.Element => {
  return (
    <Container>
      <Header>
        <Skeleton animation="wave" width="50%" />
      </Header>
      <Skeleton animation="wave" width="100%" />
      <Skeleton animation="wave" width="100%" />
    </Container>
  )
}

export { LoadingState as default }
