import EventEmitter from "eventemitter3"
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api"
import { clearQueue } from "./queue"

const reloadEmitter = new EventEmitter<"reload">()

export function reload(document: PDFDocumentProxy, page: number): void {
  clearQueue(document, page)
  reloadEmitter.emit("reload", page)
}

export function subscribeToReload(page: number, callback: () => void): () => void {
  const handleReload = (pageToReload: number) => {
    if (pageToReload === page) {
      callback()
    }
  }

  reloadEmitter.addListener("reload", handleReload)

  return () => reloadEmitter.removeListener("reload", handleReload)
}
