import { CASE_STEP, DEMAND_SECTION } from "./enums"

export const CASE_ATTRIBUTES_STEP = "case_attributes" as const

export type ALL_CASE_STEPS = CASE_STEP | typeof CASE_ATTRIBUTES_STEP

export type CASE_SECTIONS = ALL_CASE_STEPS | DEMAND_SECTION

export interface CaseStepConfig {
  key: ALL_CASE_STEPS
  display: string
  label?: string
  optional: boolean
}

export enum CASE_STEP_STATUS {
  NOT_STARTED = "not_started",
  STARTED = "started",
  SKIPPED = "skipped",
  COMPLETED = "completed",
  NEEDS_REVIEW = "error",
}

export interface CaseStepInfo {
  step: ALL_CASE_STEPS
  status: CASE_STEP_STATUS
  conflict: boolean
}

export interface CaseStepItem {
  step: ALL_CASE_STEPS
  title: string
  subtitle?: string
  optional: boolean
  disabled: boolean
  index: number
  isCompleted: boolean
  isSkipped?: boolean
  isReviewNeeded?: boolean
}

export interface CaseSteps {
  caseId: PrimaryKey
  items: CaseStepItem[]
  section: CASE_SECTIONS
  currentStep: Nullable<CaseStepItem>
  nextStep: Nullable<CaseStepItem>
  getStepStatus: (step: ALL_CASE_STEPS) => Nullable<CaseStepInfo>
}
