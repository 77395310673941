import React, { useCallback, useMemo } from "react"
import { DEFAULT_VALUE } from "./defaultValue"
import { CustomEditor, EditorRoot } from "./CustomEditor"
import { EditorFeatureProps } from "./features/types"
import { RichTextEditor } from "./RichTextEditor"
import { RichTextWithActions } from "./RichTextWithActions"

interface RichTextInputOnChangeArg {
  value: EditorRoot
}

export interface RichTextInputProps {
  value: Nullable<EditorRoot>
  dataTest?: string
  disabled?: boolean
  name?: string
  error?: boolean
  label?: string
  helperText?: string
  actions?: Nullable<React.ReactElement>
  customControls?: React.ReactNode
  editorRef?: (editor: CustomEditor | null) => void
  onChange: (values: RichTextInputOnChangeArg) => void
  onBlur?: () => void
  onFocus?: () => void
}

export function RichTextInput({
  value,
  onChange,
  dataTest,
  disabled,
  error,
  helperText,
  label,
  actions,
  customControls,
  onBlur,
  onFocus,
  name,
  ...featureProps
}: RichTextInputProps & EditorFeatureProps): JSX.Element {
  const editorValue = useMemo<EditorRoot>(() => {
    if (value && value.length > 0) return value
    return DEFAULT_VALUE
  }, [value])

  const handleChange = useCallback(
    (nextValue: EditorRoot) => {
      onChange({
        value: nextValue,
      })
    },
    [onChange]
  )

  if (disabled) {
    return <RichTextEditor value={editorValue} readonly name={name} {...featureProps} />
  }

  return (
    <RichTextWithActions label={label} actions={actions} helperText={helperText}>
      <RichTextEditor
        value={editorValue}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleChange}
        keepValue={false}
        customControls={customControls}
        dataTest={dataTest}
        name={name}
        error={error}
        {...featureProps}
      />
    </RichTextWithActions>
  )
}
