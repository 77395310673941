import { Box, Dialog, FormControlLabel, Switch, Typography } from "@mui/material"
import { Container, InputContainer } from "./styled"
import { useForm, useWatch } from "react-hook-form"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import { useFirm } from "hooks/useFirm"
import useCase from "hooks/useCase"
import { EXHIBIT_GROUPING_VALUES, EXHIBIT_SORTING_VALUES } from "settings/Firm/enums"
import { SelectInput } from "common/form-components"
import { EXHIBIT_GROUPING_LABELS, EXHIBIT_SORTING_OPTIONS } from "settings/Firm/constants"
import { ActionButton, useAction } from "exhibit-builder/ActionButton"
import { ArrangeExhibitOptions } from "exhibit-builder/store/types"
import { useEffect, useState } from "react"
import Button from "evenup-ui/Button"
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded"
import { PROVIDER_KEY, UNASSIGNED_KEY } from "exhibit-builder/store/constants"

const GROUPING_OPTIONS = [
  EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE,
  EXHIBIT_GROUPING_VALUES.PER_PROVIDER,
  EXHIBIT_GROUPING_VALUES.ONE_FILE,
]
const GROUPING_OPTIONS_ITEMS = GROUPING_OPTIONS.map(option => ({
  key: option,
  display: EXHIBIT_GROUPING_LABELS[option],
}))

function shouldShowOneFilePerProviderOption(sortingOption: EXHIBIT_SORTING_VALUES) {
  return [
    EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS,
    EXHIBIT_SORTING_VALUES.PER_PROVIDER_BILLS_AND_RECORDS,
  ].includes(sortingOption)
}

export function SortMenu() {
  const caseId = useExhibitBuilderStore(useShallow(state => state.caseId))
  const { caseObj } = useCase(caseId)
  const { firm } = useFirm(caseObj?.firm?.pk)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const invalidUserExhibits = useExhibitBuilderStore(state => {
    const exhibits: {
      index: number
      missingPlaintiff: boolean
      missingSubDocType: boolean
      missingSortingProvider: boolean
    }[] = []

    state.userExhibitOrder.forEach(exhibitId => {
      const exhibit = state.userExhibitMap[exhibitId]
      if (exhibit.docType !== PROVIDER_KEY) {
        return
      }

      const missingPlaintiff = !exhibit.plaintiffId
      const missingSubDocType = !exhibit.subDocType || exhibit.subDocType === UNASSIGNED_KEY
      const missingSortingProvider = !exhibit.sortingProviderId

      if (missingPlaintiff || missingSubDocType || missingSortingProvider) {
        exhibits.push({
          index: exhibit.index,
          missingPlaintiff,
          missingSubDocType,
          missingSortingProvider,
        })
      }
    })

    return exhibits
  })

  const firmSortingOption = firm?.exhibit_sorting_option as ArrangeExhibitOptions["sortingOption"] | undefined
  const firmGroupingOption = firm?.exhibit_grouping_option as
    | ArrangeExhibitOptions["groupingOption"]
    | undefined

  const { control, setValue } = useForm({
    defaultValues: {
      sortingOption: firmSortingOption ?? EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS,
      groupingOption:
        firmGroupingOption && GROUPING_OPTIONS.includes(firmGroupingOption)
          ? firmGroupingOption
          : EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE,
      addTitlePage: false,
      turnCaseFactsPhotographs: false,
      includeInlineImages: false,
    },
  })

  const selectedSortingOption = useWatch({ control, name: "sortingOption" })
  const selectedGroupingOption = useWatch({ control, name: "groupingOption" })

  const { isLoading, handleAction } = useAction({
    name: "arrange exhibits",
    action: async () =>
      exhibitBuilderActions.arrangeExhibits({
        groupingOption: selectedGroupingOption,
        sortingOption: selectedSortingOption,
      }),
  })

  const arrangeExhibit = async () => {
    if (invalidUserExhibits.length) {
      setShowErrorModal(true)
      return
    }

    exhibitBuilderActions.setIsLoading(true)
    await handleAction()
    exhibitBuilderActions.setIsLoading(false)
  }

  const closeErrorModal = () => {
    setShowErrorModal(false)
  }

  useEffect(() => {
    if (
      !shouldShowOneFilePerProviderOption(selectedSortingOption) &&
      selectedGroupingOption === EXHIBIT_GROUPING_VALUES.PER_PROVIDER
    ) {
      setValue("groupingOption", EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE)
    }
  }, [selectedSortingOption, selectedGroupingOption, setValue])

  const groupingOption = GROUPING_OPTIONS_ITEMS.filter(
    option =>
      option.key !== EXHIBIT_GROUPING_VALUES.PER_PROVIDER ||
      shouldShowOneFilePerProviderOption(selectedSortingOption)
  )

  return (
    <Container>
      <InputContainer>
        <SelectInput
          size="small"
          label="Exhibit Sorting Rule"
          control={control}
          name="sortingOption"
          options={EXHIBIT_SORTING_OPTIONS}
          data-test="sorting-option"
        />

        <SelectInput
          size="small"
          label="Group Exhibit Option"
          control={control}
          name="groupingOption"
          options={groupingOption}
          data-test="grouping-option"
        />

        <FormControlLabel
          control={<Switch color="secondary" />}
          label={<Typography variant="caption">Add Title Page to Every Exhibit</Typography>}
        />
      </InputContainer>

      <ActionButton
        label="Sort and Group Exhibits"
        isLoading={isLoading}
        onClick={arrangeExhibit}
        data-test="sort-button"
      />

      <Dialog open={showErrorModal} onClose={closeErrorModal} fullWidth data-test="error-dialog">
        <Box p={4}>
          <Box mb={2} display="flex" alignItems="center" gap={1}>
            <WarningAmberRoundedIcon fontSize="large" color="error" />
            <Typography variant="dialogHeader">Unable to Sort Exhibits</Typography>
          </Box>
          <Typography variant="body1" lineHeight={1.5}>
            Missing information required for sorting <br />
            {invalidUserExhibits.map(exhibit => (
              <Box key={exhibit.index}>
                <b>{`Exhibit ${exhibit.index + 1}: `}</b>
                {exhibit.missingPlaintiff && "Plaintiff not assigned "}
                {exhibit.missingSubDocType && "Sub-doc type not assigned "}
                {exhibit.missingSortingProvider && "Sorting Provider not assigned"}
              </Box>
            ))}
            <Box mt={4}>
              Please complete the missing information before attempting to sort and arrange the exhibits again
            </Box>
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2} gap={1}>
            <Button onClick={closeErrorModal} color="secondary">
              Close
            </Button>
          </Box>
          {isLoading && <Typography variant="caption">Loading...</Typography>}
        </Box>
      </Dialog>
    </Container>
  )
}
