import React, { useCallback, useContext, useMemo } from "react"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import DragIndicator from "@mui/icons-material/DragIndicator"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import LinkButton from "common/buttons/LinkButton"
import { useHandleMessages } from "common/messages/useHandleMessages"
import {
  SET_EDITING,
  TOGGLE_OPEN,
  Action,
  UPDATE_PROVIDER_FIELD,
  UPDATE_INJURY_DETAILS,
} from "demand/Providers/reducer"
import { amountInDollars, dateDisplay } from "utils"

import { Provider } from "../types"
import { LeftNavContext, useFormContext } from "demand/context"
import ProviderRowLabel from "./ProviderRowLabel"

import { useMutation } from "@tanstack/react-query"
import { setProviderReviewedAtDate } from "api"
import { DOCUMENT_TYPES } from "requests/enums"
import NoPlaintiffRowLabel from "./NoPlaintiffRowSection"
import { usePermissions } from "permissions/usePermissions"
import { ReviewButton } from "review"
import { EditorContent } from "common/form-components/rich-text"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"

interface Props {
  active: boolean
  provider: Provider
  dispatch: React.Dispatch<Action>
  dragHandleProps: DraggableProvidedDragHandleProps
  updates: Nullable<{ [key: string]: string }[]>
}

const useStyles = makeStyles()(theme => ({
  providerRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  activeProviderRow: {
    backgroundColor: theme.palette.blue.light,
  },
  providerDragIndicator: {
    fontSize: "1.9rem",
    marginRight: theme.spacing(4),
  },
  providerName: {
    fontWeight: "bold",
    width: "200px",
    marginRight: theme.spacing(4),
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  providerFirstContact: {
    marginRight: theme.spacing(6),
  },
  providerTotal: {
    marginRight: theme.spacing(4),
  },
  providerStatusText: {
    fontSize: "1rem",
    marginRight: theme.spacing(2),
  },
  editProvider: {
    marginRight: theme.spacing(2),
  },
  expandToggle: {
    color: theme.palette.text.primary,
  },
}))

export const ProviderCollapsedRow = ({
  dispatch,
  provider,
  dragHandleProps,
  active,
  updates,
}: Props): JSX.Element => {
  const { caseId, request, currentPlaintiff } = useFormContext()
  const { classes, cx } = useStyles()
  const { showErrorMessage } = useHandleMessages()
  const { setLeftNavTabIsExpanded } = useContext(LeftNavContext)
  const { caseSectionReviewEnabled } = usePermissions({ suspense: false })

  const setReviewedAtMutation = useMutation(setProviderReviewedAtDate, {
    onError: error => {
      showErrorMessage({
        message: "There was an error setting the reviewed at time for the Provider.",
        error,
      })
    },
  })

  const handleEditProvider = () => {
    setReviewedAtMutation.mutate({
      caseId: caseId,
      providerId: provider.pk,
    })
    setLeftNavTabIsExpanded(false)
    dispatch({
      type: SET_EDITING,
      payload: {
        id: provider.pk,
        caseId,
        request_id: `${request?.pk}`,
        request_type: request?.type as DOCUMENT_TYPES,
        provider_name: provider.name,
      },
    })
  }

  const handleOpen = () => {
    setReviewedAtMutation.mutate({
      caseId: caseId,
      providerId: provider.pk,
    })
    dispatch({
      type: TOGGLE_OPEN,
      payload: {
        id: provider.pk,
        demand_id: `${caseId}`,
        request_id: `${request?.pk}`,
        request_type: request?.type as DOCUMENT_TYPES,
        provider_name: provider.name,
      },
    })
  }

  const handleReviewIdChanged = useCallback(
    (reviewRequestId?: Nullable<string>) => {
      dispatch({
        type: UPDATE_PROVIDER_FIELD,
        payload: { providerId: provider.pk, field: "current_review_run", value: reviewRequestId },
      })
    },
    [dispatch, provider.pk]
  )

  const handleReviewComplete = useCallback(
    (completedContent?: EditorContent) => {
      if (completedContent) {
        dispatch({
          type: UPDATE_INJURY_DETAILS,
          payload: {
            providerId: provider.pk,
            value: null,
            customContent: completedContent.children as EditorRoot,
          },
        })
      }
      handleReviewIdChanged(null)
    },
    [dispatch, provider.pk, handleReviewIdChanged]
  )

  const billsSum = useMemo(() => {
    if (!provider.bills || !provider.bills.length) {
      return amountInDollars(0)
    }

    const sumOfBills = provider.bills.reduce((currentSum, bill) => {
      if (bill.billed_amount) {
        return currentSum + Number(bill.billed_amount)
      }

      return currentSum
    }, 0)
    return amountInDollars(sumOfBills)
  }, [provider.bills])

  return (
    <Box className={cx(classes.providerRow, active && classes.activeProviderRow)}>
      <Box display="flex" alignItems="center">
        <Box {...dragHandleProps} data-test="drag-indicator">
          <DragIndicator className={classes.providerDragIndicator} />
        </Box>
        <Typography className={classes.providerName}>{provider.name || "Name"}</Typography>
        <Typography className={classes.providerFirstContact}>
          {dateDisplay(provider.first_contact)}
        </Typography>
        <Typography className={classes.providerTotal}>{billsSum}</Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <NoPlaintiffRowLabel provider={provider} />
        <ProviderRowLabel provider={provider} updates={updates} />
        {caseSectionReviewEnabled && currentPlaintiff && (
          <ReviewButton
            templateType="provider"
            plaintiffId={currentPlaintiff.id}
            providerId={provider.pk}
            onCompleted={handleReviewComplete}
            onCreated={handleReviewIdChanged}
            onCancelled={handleReviewIdChanged}
            reviewRequestId={provider.current_review_run}
          />
        )}
        {active ? (
          <Box className={classes.providerStatusText}>Editing...</Box>
        ) : (
          <LinkButton
            className={cx(classes.editProvider)}
            onClick={handleEditProvider}
            data-test="edit-provider-button"
          >
            Edit Provider
          </LinkButton>
        )}
        <IconButton onClick={handleOpen} data-test="expand-toggle">
          {provider.open ? (
            <ExpandLess className={classes.expandToggle} fontSize="large" />
          ) : (
            <ExpandMore className={classes.expandToggle} fontSize="large" />
          )}
        </IconButton>
      </Box>
    </Box>
  )
}
