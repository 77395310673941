import { DemandCalendarDataByYearAndMonthAndDay } from "./types"

export function getCalendarDataForDay<DayT>(
  groupedData: DemandCalendarDataByYearAndMonthAndDay<DayT>,
  year: number,
  month: number,
  day: number
) {
  // Calendar days are 1-indexed
  return (groupedData[year] && groupedData[year][month] && groupedData[year][month][day]) || []
}

/**
 * Basically "string.trim()" but for "month rows".
 *    Example 1:  Months per row = 4.  One data entry in June.
 *                Row 1: Jan, Feb, Mar, Apr --> no data entries.  Trim.
 *                Row 2: May, Jun, Jul, Aug --> one data entry.  Don't trim.
 *                Row 3: Sep, Oct, Nov, Dec --> no data entries. Trim.
 *
 *    Example 2:  Months per row = 3.  One data entry in Jan, one in Sept.
 *                Row 1: Jan, Feb, Mar --> one data entry.  Don't trim.
 *                Row 2: Apr, May, Jun --> No data entries,
 *                    but this row lies "inside" two other rows with entries.  Don't trim.
 *                Row 3: Jul, Aug, Sep --> One dat entry.  Don't trim
 *                Row 4: Oct, Nov, Dec --> no data entries. Trim.
 **/

export function determineMonthsToRender<DayT>(
  groupedData: DemandCalendarDataByYearAndMonthAndDay<DayT>,
  year: number,
  monthsPerRow: number
): Array<number> {
  const months: Array<number> = []
  const rowsWithData = []
  for (let row = 0; row < Math.ceil(12 / monthsPerRow); row++) {
    const startMonth = row * monthsPerRow
    const endMonth = Math.min(startMonth + monthsPerRow, 12)

    const monthsInRow = Array.from({ length: endMonth - startMonth }, (_, i) => i + 1 + startMonth)
    // Do we have any data entries in this row?
    rowsWithData[row] = {
      hasData: monthsInRow.some(month => !!(groupedData[year] || {})[month]),
      monthsInRow,
    }
  }
  while (rowsWithData.length && !rowsWithData[rowsWithData.length - 1].hasData) {
    rowsWithData.pop()
  }
  while (rowsWithData.length && !rowsWithData[0].hasData) {
    rowsWithData.shift()
  }

  rowsWithData.forEach(({ monthsInRow }) => {
    months.push(...monthsInRow)
  })
  return months
}
