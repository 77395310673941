import { useCallback, useMemo, useReducer, useState } from "react"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"
import { queryKeys } from "react-query/constants"
import { useQuery } from "@tanstack/react-query"
import { Loading } from "common"
import { Pagination } from "common/pagination"
import { PageSizeSelect } from "common/pagination/PageSizeSelect"
import { LibraryVariableRowData, LibraryVariableTableViewModel } from "common/models/library"
import { useLibraryVariableForm } from "settings/Library/TemplateForms"
import { libraryVariableGroupService, libraryVariableService } from "api/services/library-variable"
import { UPDATE_VARIABLE_FORM_STATE_MESSAGES } from "settings/Library/TemplateForms/constants"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { LibraryVariableGroupDefinition } from "common/types/libraryVariables"
import useUser from "hooks/useUser"
import { canEditLibrary, canEditLibraryByFirmsUserManage } from "settings/permissions"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { FirmSelect } from "common/attributes-filter/FirmSelect"
import { userService } from "api/services/users"

import { LibraryTable } from "../LibraryTable"
import { LibraryFilters, useAttributes, useFilterValues } from "../Filters"
import { templatesTabReducer } from "../State/templatesReducer"
import { INITIAL_TEMPLATES_TAB_STATE, LibraryTabStateContext } from "../State/constants"
import { NewVariable } from "../Forms/NewVariable"
import { StyledFiltersWrapper, StyledPageSizeWrapper, StyledSeparator } from "./styled"
import { canRoleEditEntityByFirmId } from "../TemplateForms/permissions"

const getOptionLabel = (data: LibraryVariableGroupDefinition) => data.name
const renderInput = (params: TextFieldProps) => (
  <TextField
    {...params}
    fullWidth
    variant={"outlined"}
    margin="none"
    label="Variable group"
    style={{ minWidth: 210 }}
  />
)

const getDuplicateData = (canManageInitialFirm: (rowData: LibraryVariableRowData) => boolean) => {
  return (rowData: LibraryVariableRowData): Partial<LibraryVariableRowData> => {
    return {
      initialAttributes: rowData.initialAttributes,
      initialGroupName: rowData.initialGroupName,
      initialGroupId: rowData.initialGroupId,
      initialFirmId: canManageInitialFirm(rowData) ? rowData.initialFirmId : null,
      initialVariableContent: rowData.initialVariableContent,
    }
  }
}

export function LibraryVariables(): Nullable<JSX.Element> {
  const attributes = useAttributes()
  const [state, dispatch] = useReducer(templatesTabReducer, INITIAL_TEMPLATES_TAB_STATE)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { isFeatureEnabled } = useFeatures()
  const [firmId, setFirmId] = useState<Nullable<PrimaryKey>>(null)
  const [variableGroup, setVariableGroup] = useState<Nullable<number>>(null)
  const { attributeValues } = useFilterValues()
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const { user } = useUser()
  const firmTemplateFeatureIsEnabled = isFeatureEnabled(FEATURES.FIRM_TEMPLATE)
  const { data: firmsUserManage } = useQuery(
    [queryKeys.firmsUserManage, user.id],
    () => userService.getFirmsUserManage(null),
    {
      enabled: user.isInternal && firmTemplateFeatureIsEnabled,
    }
  )

  const canEditLibraryByFirm = firmTemplateFeatureIsEnabled
    ? canEditLibraryByFirmsUserManage(user.role, firmsUserManage)
    : canEditLibrary(user.role)

  const handleChangePageSize = useCallback((pageSize: number) => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }, [])
  const { data: variableGroups } = useQuery(
    [queryKeys.libraryVariableGroups],
    libraryVariableGroupService.getVariableGroupList
  )
  const { isFetching, data: libraryVariables } = useQuery(
    [queryKeys.libraryVariables, currentPage, pageSize, attributeValues, variableGroup, firmId],
    () =>
      libraryVariableService.getVariablesList({
        page: currentPage,
        pageSize,
        attributeValues,
        variableGroup,
        firmId,
      }),
    {
      meta: {
        disableLoader: true,
      },
      enabled: Boolean(attributes),
      keepPreviousData: true,
    }
  )

  const getCanEdit = useCallback(
    (rowData: LibraryVariableRowData) =>
      firmTemplateFeatureIsEnabled
        ? canRoleEditEntityByFirmId(user.role, rowData.initialFirmId, firmsUserManage)
        : canEditLibrary(user.role),
    [firmTemplateFeatureIsEnabled, firmsUserManage, user.role]
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeHandler = useCallback((_: any, newValue: Nullable<LibraryVariableGroupDefinition>) => {
    if (newValue === null) {
      setVariableGroup(null)

      return
    }

    setVariableGroup(newValue.id)
  }, [])

  if (!libraryVariables && !attributes) return null

  if (!attributes || !variableGroups) return <Loading show label="Loading attributes" />

  if (!libraryVariables) {
    return (
      <LibraryTabStateContext.Provider value={contextValue}>
        <StyledFiltersWrapper>
          <LibraryFilters />
        </StyledFiltersWrapper>
        <Loading show label="Loading variables..." />
      </LibraryTabStateContext.Provider>
    )
  }

  const templatesTableData = new LibraryVariableTableViewModel(
    libraryVariables.items,
    attributes,
    firmTemplateFeatureIsEnabled
  )

  return (
    <LibraryTabStateContext.Provider value={contextValue}>
      <StyledFiltersWrapper>
        <LibraryFilters showSection={false}>
          <Autocomplete
            style={{ width: "210px", marginRight: "8px", paddingTop: "8px" }}
            size="small"
            blurOnSelect
            clearOnBlur
            onChange={onChangeHandler}
            getOptionLabel={getOptionLabel}
            options={variableGroups}
            renderInput={renderInput}
          />
          {firmTemplateFeatureIsEnabled && <FirmSelect firmId={firmId} setFirmId={setFirmId} />}
        </LibraryFilters>
        <StyledPageSizeWrapper>
          <PageSizeSelect pageSize={pageSize} onChange={handleChangePageSize} />
        </StyledPageSizeWrapper>
      </StyledFiltersWrapper>
      {canEditLibraryByFirm ? <NewVariable /> : <StyledSeparator />}
      <LibraryTable
        tableData={templatesTableData}
        loading={isFetching}
        entityName="variables"
        canDuplicate={canEditLibraryByFirm}
        getCanEdit={getCanEdit}
        getDuplicateData={getDuplicateData(getCanEdit)}
        getFormData={useLibraryVariableForm}
        formStatusMessageMap={UPDATE_VARIABLE_FORM_STATE_MESSAGES}
      />
      {libraryVariables.count > 0 && (
        <Pagination
          pageCount={libraryVariables.pageCount}
          page={libraryVariables.page}
          count={libraryVariables.count}
          onChange={setCurrentPage}
        />
      )}
    </LibraryTabStateContext.Provider>
  )
}
