import { Box, FormControl, TextField, TextFieldProps, useTheme } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import React from "react"

type PageControlProps = React.PropsWithChildren<
  {
    onSearchQueryChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    searchQuery: string
  } & TextFieldProps
>

export const SettingsPageControls = ({
  onSearchQueryChange,
  searchQuery,
  children,
  ...props
}: PageControlProps) => {
  const theme = useTheme()

  return (
    <Box display="flex" alignItems="center" gap={theme.spacing(2)}>
      <FormControl>
        <TextField
          value={searchQuery}
          onChange={onSearchQueryChange}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon style={{ fill: theme.palette.grey[500], marginRight: theme.spacing(0.5) }} />
            ),
          }}
          placeholder="Search"
          {...props}
        />
      </FormControl>
      {children}
    </Box>
  )
}
