import { createContext } from "react"
import { QueryObserverResult } from "@tanstack/react-query"

import { Variable } from "common/types/variables"

export interface CaseVariablesContextType {
  variables: Variable[]
  refetchCaseVariables?: () => Promise<QueryObserverResult>
}

export const CaseVariablesContext = createContext<CaseVariablesContextType>({ variables: [] })
