import { handleEmptyResponse } from "api/utils"
import { REQUEST_METHODS, makeApiCall } from "apiHelper"
import { CaseData } from "cases/types/caseEdit"
import { CaseFlagDto } from "cases/types/caseFlags"

// In the future: Refactor this into `service.ts` similar to the other services
export const fetchCaseFlags = async ({
  demandId,
  published,
}: {
  demandId: PrimaryKey
  published?: boolean
}) => {
  let url = `/matter/case-flag/?case_id=${demandId}`
  if (published) {
    url += `&published=${published}`
  }
  const options = {
    path: url,
    method: REQUEST_METHODS.GET,
  }

  return handleEmptyResponse(makeApiCall<CaseFlagDto[]>(options))
}

export const fetchCase = async ({ caseId }: { caseId: PrimaryKey | undefined }): Promise<CaseData> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const options = {
    path: `/cases/v2/${caseId}`,
    method: REQUEST_METHODS.GET,
  }

  // return handleEmptyResponse(makeApiCall<CaseData>(options))

  return {
    pk: 6,
    firm_id: 1,
    case_type: "1",
    date_of_incident: "2020/12/12",
    state_of_incident: "CA",
    plaintiffs: [
      {
        first_name: "John",
        last_name: "Doe",
        pronoun: "he",
        role: "cyclist",
      },
      {
        first_name: "John",
        last_name: "Doe",
        pronoun: "he",
        role: "cyclist",
      },
    ],
  }
}

export const editCase = async ({ caseId, data }: { caseId: PrimaryKey; data: CaseData }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const options = {
    path: `/cases/v2/${caseId}`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  // return handleEmptyResponse(makeApiCall<CaseData>(options))

  return {
    caseId,
    ...data,
  }
}
