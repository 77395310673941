import React, { useMemo } from "react"
import { SelectInput } from "../../common/form-components"
import { Control, UseFormWatch } from "react-hook-form"
import { EXHIBIT_SORTING_OPTIONS } from "./constants"
import { EXHIBIT_GROUPING_VALUES } from "./enums"
import { FirmDetailDataFields } from "./FirmDetailFields"
import { ExhibitOrganization } from "./Firm"
import { getGroupingSettingOptions } from "./utils"

export type EOSFields = Partial<FirmDetailDataFields> &
  Pick<ExhibitOrganization, "exhibit_grouping_option" | "exhibit_sorting_option">

interface SelectorsProps {
  control: Control<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any> // eslint-disable-line @typescript-eslint/no-explicit-any

  className?: string
}

export const ExhibitOrganizationSelectors: React.FC<SelectorsProps> = ({
  control,
  watch,
  className,
}): JSX.Element => {
  const exhibitSortingOption = watch("exhibit_sorting_option")

  const groupingOptions = useMemo(
    () => getGroupingSettingOptions(exhibitSortingOption),
    [exhibitSortingOption]
  )

  return (
    <>
      <SelectInput
        control={control}
        name="exhibit_sorting_option"
        label="Exhibit Sorting Rule"
        options={EXHIBIT_SORTING_OPTIONS}
        className={className}
      />

      <SelectInput
        control={control}
        name="exhibit_grouping_option"
        label="Combine Exhibit Option"
        options={groupingOptions}
        className={className}
        defaultValue={EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE}
      />
    </>
  )
}
