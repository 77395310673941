import Paper from "@mui/material/Paper"
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/Close"
import styled from "@emotion/styled"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
  icon: {
    marginLeft: "auto",
  },
}))

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4, 4),
}))

const Title = styled(Box)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "2rem",
  margin: theme.spacing(0.5, "auto"),
}))

const Body = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1.45rem",
  textAlign: "center",
  margin: theme.spacing("1", "auto"),
}))

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  margin: "1rem auto",
  "& > button": {
    margin: theme.spacing(0, 1),
    width: "8rem",
  },
}))

interface Props {
  title: string
  description: string
  show: boolean
  onClose: () => void
  onConfirm: () => void
}

const MarkAsResolvedConfirmation = ({ show, onClose, onConfirm, title, description }: Props): JSX.Element => {
  const { classes } = useStyles()

  return (
    <Dialog open={show} onClose={onClose} PaperComponent={Paper}>
      <IconButton className={classes.icon} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Container>
        <Title>{title}</Title>
        <Body>{description}</Body>
        <ButtonContainer>
          <Button variant="contained" onClick={onClose} data-test="cancel-button">
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm} data-test="yes-button">
            Yes
          </Button>
        </ButtonContainer>
      </Container>
    </Dialog>
  )
}

export { MarkAsResolvedConfirmation as default }
