import { NodeEntry, Transforms } from "slate"
import { createText } from "../create"
import { CustomEditor, ParagraphElement } from "../CustomEditor"
import { Editor } from "../Editor"
import { isText } from "../queries/text"

export function normalizeParagraph(editor: CustomEditor, [node, path]: NodeEntry<ParagraphElement>): boolean {
  // Empty paragraph
  if (!node.children.length) {
    Transforms.insertNodes(editor, createText(), { at: [...path, 0] })
    return true
  }

  let result = false
  const firstChild = node.children[0]

  // First child in paragraph is not text (but should be, at least empty text node)
  if (!isText(editor, firstChild)) {
    Transforms.insertNodes(editor, createText(), { at: [...path, 0] })
    result = true
  }

  const [nextNode] = Editor.node(editor, path) as NodeEntry<ParagraphElement>
  const lastChildIndex = nextNode.children.length
  const lastChild = nextNode.children[lastChildIndex - 1]

  // Last child in paragraph is not text (but should be, at least empty text node)
  if (!isText(editor, lastChild) && lastChild !== firstChild) {
    Transforms.insertNodes(editor, createText(), { at: [...path, lastChildIndex] })
    result = true
  }

  return result
}
