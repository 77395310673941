export type RoundDownOptions = {
  decimalPlaces: number
}

const BASE_10 = 10

export const roundDown = (number: number, options?: RoundDownOptions): number => {
  const { decimalPlaces = 0 } = options ?? {}

  const decimalMover = Math.pow(BASE_10, decimalPlaces)
  const wholeInteger = Math.floor(number * decimalMover)
  const roundedNumber = wholeInteger / decimalMover
  return roundedNumber
}
