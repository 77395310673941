import { ReactNode, useEffect, useMemo, useRef } from "react"
import { EditorFeatureRendererProps } from "../types"
import { EDITOR_FEATURES } from "../constants"
import { FlagsContext, FlagsContextType } from "./FlagsContext"
import { isFlagsEditor } from "../../queries"
import { Flags } from "./Flags"
import { Flag } from "documents/flags/types"

export function EditableWithFlags({
  children,
  editor,
  flags,
  onCreateFlag,
}: EditorFeatureRendererProps<EDITOR_FEATURES.FLAGS> & { children: ReactNode }): JSX.Element {
  const handleClickRef = useRef(onCreateFlag)
  handleClickRef.current = onCreateFlag

  const editorFlags = useMemo<FlagsContextType>(() => {
    const references = new Flags()
    references.push(...flags)

    return {
      flags: references,
      onCreateFlag: (flagData: Partial<Flag>, callback?: (flag: Flag) => void) =>
        handleClickRef.current(flagData, callback),
    }
  }, [flags])

  useEffect(() => {
    if (isFlagsEditor(editor)) {
      editor.flags = editorFlags.flags
      editor.onCreateFlag = editorFlags.onCreateFlag
    }
  }, [editor, editorFlags])

  return <FlagsContext.Provider value={editorFlags}>{children}</FlagsContext.Provider>
}
