import { useCallback, useEffect, useState } from "react"
import Collapse from "@mui/material/Collapse"
import { FutureExpenseEditor } from "./FutureExpenseEditor"
import { FutureExpenseView } from "./FutureExpenseView"

export function FutureExpense({ futureExpense, initialEdit, saveCallback, index }) {
  const [isEditing, setIsEditing] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleEdit = useCallback(() => {
    setIsEditing(true)
  }, [])

  const handleOnExpandedClick = useCallback(() => {
    setIsExpanded(currentState => !currentState)
  }, [])

  const handleClose = useCallback(() => {
    saveCallback?.()
    setIsEditing(false)
    setIsExpanded(false)
  }, [saveCallback])

  useEffect(() => {
    if (initialEdit === true) {
      handleEdit()
    }
  }, [initialEdit, handleEdit])

  return (
    <>
      <Collapse in={isEditing} unmountOnExit>
        <FutureExpenseEditor onCloseCallback={handleClose} data={futureExpense} index={index} />
      </Collapse>

      <Collapse in={!isEditing} unmountOnExit>
        <FutureExpenseView
          futureExpense={futureExpense}
          handleEdit={handleEdit}
          index={index}
          isExpanded={isExpanded}
          onExpandedClick={handleOnExpandedClick}
        />
      </Collapse>
    </>
  )
}
