import { useCallback, useEffect, useMemo, useRef } from "react"
import { Box } from "@mui/material"
import { documentActions, documentStoreSelectors, useDocumentStore } from "documents/store"
import { useShallow } from "zustand/react/shallow"
import { useSearchParams } from "react-router-dom"
import { flagsDataSelectors } from "documents/store/flags"
import { Virtuoso, VirtuosoHandle } from "react-virtuoso"
import { useTheme } from "@emotion/react"
import { HEADER_NAV_HEIGHT_SPACE_MULTIPLIER } from "app/constants"
import { ProviderSummaryTableItem } from "./ProviderSummaryTableItem"
import {
  StyledProviderSummaryTable,
  StyledProviderSummaryTableHead,
  StyledProviderSummaryTableHeaderCell,
} from "./styled"
import { VIEW_MED_CHRON_SECTIONS } from "documents/view-medical-chronology/constants"

interface ProviderSummaryTableProps {
  readOnly?: boolean
  displayReviewActions?: boolean
}

export const ProviderSummaryTable = ({
  displayReviewActions,
  readOnly,
}: ProviderSummaryTableProps): JSX.Element => {
  const list = useRef<VirtuosoHandle | null>(null)
  const summaryItemKeys = useDocumentStore(useShallow(documentStoreSelectors.getSummaryItemKeys(readOnly)))
  const scrollToFlag = useDocumentStore(flagsDataSelectors.getScrollToFlag)
  const currentAppointment = useDocumentStore(state => state.currentAppointment)
  const [searchParams] = useSearchParams()
  const appointmentToScrollTo = searchParams.get("appointment")
  const scrollToElementId = useDocumentStore(state => state.scrollToElementId)
  const theme = useTheme()
  const scrollToAppointmentIndex = useMemo(() => {
    return appointmentToScrollTo
      ? summaryItemKeys.findIndex(key => key.endsWith(appointmentToScrollTo))
      : null
  }, [appointmentToScrollTo, summaryItemKeys])

  const scrollOptions = useMemo(() => {
    const navOffset = theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER * -1)
    const offset = Number(navOffset.split("px")[0])

    return { align: "start", offset } as const
  }, [theme])

  const scrollToIndex = useCallback(
    (index: number) => {
      list.current?.scrollToIndex({
        index,
        ...scrollOptions,
      })
    },
    [scrollOptions]
  )

  useEffect(() => {
    if (scrollToElementId !== VIEW_MED_CHRON_SECTIONS.EXHIBIT_LIST) return

    list.current?.scrollToIndex({ align: "end", index: summaryItemKeys.length })

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        document.getElementById(scrollToElementId)?.scrollIntoView({ behavior: "smooth", block: "start" })
      })
    })
  }, [scrollToElementId, summaryItemKeys])

  useEffect(() => {
    if (scrollToFlag) {
      const indexToScroll = summaryItemKeys.findIndex(item => item.includes(scrollToFlag.appointment))

      if (indexToScroll >= 0) {
        scrollToIndex(indexToScroll)
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            const flagMarkElement = document.getElementById(`flag-mark-${scrollToFlag.id}`)

            if (flagMarkElement) {
              flagMarkElement.scrollIntoView({ behavior: "smooth", block: "center" })
              documentActions.setHoveredFlagId(scrollToFlag.id)
              documentActions.setScrollToFlag(null)
            }
          })
        })
      }
    }
  }, [summaryItemKeys, scrollToFlag, scrollToIndex])

  useEffect(() => {
    if (scrollToAppointmentIndex && scrollToAppointmentIndex !== -1) scrollToIndex(scrollToAppointmentIndex)
  }, [scrollToAppointmentIndex, scrollToIndex])

  useEffect(() => {
    if (currentAppointment) {
      const index = summaryItemKeys.findIndex(item => item.includes(currentAppointment))

      if (index >= 0) {
        list.current?.scrollToIndex({ align: "center", index })

        requestAnimationFrame(() => {
          document.getElementById(currentAppointment)?.scrollIntoView({ behavior: "smooth", block: "center" })
        })

        setTimeout(() => {
          documentActions.setCurrentAppointment(null)
        }, 2000)
      }
    }
  }, [currentAppointment, scrollToIndex, summaryItemKeys, list])

  if (!summaryItemKeys.length) {
    return (
      <Box borderTop="1px solid rgba(0, 0, 0, 0.12)" p={3}>
        No providers
      </Box>
    )
  }

  return (
    <>
      <StyledProviderSummaryTableHead>
        <StyledProviderSummaryTableHeaderCell>Provider</StyledProviderSummaryTableHeaderCell>
        <StyledProviderSummaryTableHeaderCell>Date</StyledProviderSummaryTableHeaderCell>
        <StyledProviderSummaryTableHeaderCell>Summary</StyledProviderSummaryTableHeaderCell>
      </StyledProviderSummaryTableHead>

      <Virtuoso
        data-test="provider-summary-table"
        ref={list}
        increaseViewportBy={1000}
        useWindowScroll
        totalCount={summaryItemKeys.length}
        data={summaryItemKeys}
        components={{ Scroller: StyledProviderSummaryTable }}
        itemContent={(index, itemKey) => (
          <ProviderSummaryTableItem
            isLast={index === summaryItemKeys.length - 1}
            itemKey={itemKey}
            readOnly={readOnly}
            displayReviewActions={displayReviewActions}
          />
        )}
        // can't pass undefined to scrollToAppointmentIndex, see https://github.com/petyosi/react-virtuoso/issues/532
        {...(scrollToAppointmentIndex
          ? { initialTopMostItemIndex: { index: scrollToAppointmentIndex, ...scrollOptions } }
          : {})}
      />
    </>
  )
}
