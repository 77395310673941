export type ContractType = "subscription" | "one_time" | "pay_as_you_go" | "month_to_month"
export type ProductType =
  | "standard_demand"
  | "basic_plus"
  | "simple"
  | "medical_chronology"
  | "medical_chronology_premium"
export const PREMIUM_MEDCHRON_PRODUCT_TYPE = "medical_chronology_premium"

export declare interface Contract {
  pk: number
  start_date: string
  end_date: string
  term_length: number
  monthly_credits: number
  monthly_price: number
  medical_pages_cap: number
  medical_pages_overage_fee: number
  medical_pages_overage: number
  verdict_fee: number
  auto_renewal: boolean
  note: Nullable<string>
  cancellation_date: Nullable<string>
  cancellation_note: Nullable<string>
  active: boolean
  // an override for automatically calculated cost_per_credit
  // mostly for use in the credit form - prefer cost_per_credit
  price_per_credit: Nullable<number>
  contract_type: Nullable<ContractType>
  cost_per_credit: number
  pricing_models?: Nullable<PricingModel[]>
}

export interface PricingModel {
  pk: number
  product_type: ProductType
  base_credit: number
  medical_pages_cap: number
  medical_pages_overage: number
  medical_pages_overage_fee: number
  verdict_fee: number
  additional_claimant_credit: number
  revision_credit: number
  medical_pages_cap_revision: number
  medical_pages_overage_revision: number
  medical_pages_overage_fee_revision: number
  notes: Nullable<string>
}

export interface DetailedContract extends Contract {
  rollover_credits: number
  current_month_credits_used: number
  end_of_month: string
}
