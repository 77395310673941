import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { Warning } from "@mui/icons-material"

export const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  padding: theme.spacing(2.5),
}))

export const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(3.5),
}))

export const Title = styled(Box)({
  fontWeight: 700,
  fontSize: "24px",
})

export const Body = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3.5),
}))

export const Footer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
}))

export const WarningIcon = styled(Warning)(({ theme }) => ({
  color: theme.palette.warning.main,
}))
