import React, { useCallback, useState } from "react"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateFeaturePermission } from "api"

import { FeaturePermission, FeaturePermissionType, FeaturePermissionTypes } from "./types"
import AssigneePicker, { Segments } from "requests/AssigneePicker"
import { FirmPicker } from "common/MultiAutocompleteSelector/FirmPicker"
import { RolePicker } from "common/MultiAutocompleteSelector/RolePicker"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { UserDto } from "common/models/user"
import { FirmDto } from "settings/Firm/Firm"
import { queryKeys } from "react-query/constants"
import { ALL_ROLES } from "common/models/roles"
import { Alert, Box, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material"
import { EditPermissionContainer, EnableForAllChangeWarning } from "./styled"

interface EditPermissionsProps {
  featureType: FeaturePermissionType
  permission: FeaturePermission
  onClose: () => void
}

interface Option {
  label: string
  value: ALL_ROLES
}

export const EditPermissionsPopover: React.FC<EditPermissionsProps> = ({
  featureType,
  permission,
  onClose,
}): JSX.Element => {
  const queryClient = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useHandleMessages()

  const [authorizedUsers, setAuthorizedUsers] = useState<UserDto[]>(permission.authorized_users)
  const [authorizedFirms, setAuthorizedFirms] = useState<FirmDto[]>(permission.authorized_firms)
  const [authorizedRoles, setAuthorizedRoles] = useState<ALL_ROLES[]>(permission.authorized_roles)
  const [enableForAll, setEnableForAll] = useState<boolean>(permission.enable_for_all)
  const [description, setDescription] = useState<string | null>(permission.description)

  const { mutate: updateFeaturePermissionMutation } = useMutation(updateFeaturePermission, {
    onSuccess: () => {
      showSuccessMessage("The feature permission has been successfully updated")
      queryClient.invalidateQueries([queryKeys.permissions])
      queryClient.invalidateQueries([queryKeys.featurePermissions])
      onClose()
    },
    onError: error => {
      showErrorMessage({ message: `There was an error updating the feature permission: '${error}'`, error })
    },
  })

  const getIdArray = useCallback((fields: UserDto[] | FirmDto[]): number[] => {
    return fields.map(field => field.pk ?? 0)
  }, [])

  const handleAuthorizedUsersChange = ({ newValues: newUsers }: { newValues: UserDto[] }) => {
    setAuthorizedUsers(newUsers)
  }

  const handleAuthorizedFirmsChange = (newFirms: FirmDto[]) => {
    setAuthorizedFirms(newFirms)
  }

  const handleAuthorizedRolesChange = (newRoles: Option[]) => {
    setAuthorizedRoles(newRoles.map(role => role.value))
  }

  const handleEnableForAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableForAll(event.target.checked)
  }

  const handleSave = async () => {
    updateFeaturePermissionMutation({
      permissionId: permission.pk,
      data: {
        authorized_user_ids: getIdArray(authorizedUsers),
        authorized_firm_ids: getIdArray(authorizedFirms),
        authorized_roles: authorizedRoles,
        description,
        enable_for_all: enableForAll,
      },
    })
  }

  const isEnableForAllChanged = (): boolean => {
    return permission.enable_for_all !== enableForAll
  }

  const showAccessControllWarning = (): boolean => {
    if (featureType !== FeaturePermissionTypes.ACCESS_LEVEL_CONTROL) {
      return false
    }

    return !permission.enable_for_all && enableForAll
  }

  return (
    <EditPermissionContainer>
      <Box>
        <Typography variant="h5" textAlign="center">
          {permission.display_name}
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          ({permission.name})
        </Typography>
      </Box>
      <TextField
        label="Description"
        minRows={3}
        maxRows={3}
        multiline
        value={description}
        onChange={event => setDescription(event.target.value)}
      />
      <AssigneePicker
        label="Assignees"
        onChange={handleAuthorizedUsersChange}
        segment={Segments.ALL}
        value={authorizedUsers}
        showEmail
      />
      <FirmPicker label="Firms" onChange={handleAuthorizedFirmsChange} value={authorizedFirms} />
      <RolePicker label="Roles" onChange={handleAuthorizedRolesChange} value={authorizedRoles} />
      <FormControlLabel
        label="Enabled For Everyone"
        control={
          <Checkbox
            checked={enableForAll}
            onChange={handleEnableForAllClick}
            data-test="enable-for-everyone"
          />
        }
      />
      {showAccessControllWarning() && (
        <Alert severity="warning">
          This is an Access Level Control. The intention behind them is to control features on a firm / user /
          role level.
          <br />
          Are you sure you want to enable it for everyone?
        </Alert>
      )}
      {isEnableForAllChanged() && (
        <EnableForAllChangeWarning color="red">
          By proceeding, you are confirming that you have verified with the feature flag owner that it is safe
          to make this change to all users.
        </EnableForAllChangeWarning>
      )}
      <Box marginTop={2} display="flex" flexDirection="row">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ marginLeft: "auto" }}
          data-test="save_button"
        >
          Save
        </Button>
      </Box>
    </EditPermissionContainer>
  )
}
