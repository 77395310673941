import styled from "@emotion/styled"
import { Box, TextField, Typography } from "@mui/material"
import { ChangeEventHandler } from "react"
import { RangeItem } from "./types"

const Range = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
}))

const InputContainer = styled(Box)({
  position: "relative",
})

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  position: "absolute",
  top: `calc(100% + ${theme.spacing(0.5)})`,
  left: 0,
  whiteSpace: "nowrap",
}))

const Dash = styled("span")(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.text.primary}`,
  width: theme.spacing(3),
  height: "1px",
}))

const Input = styled(TextField)({
  width: "130px",
})

export function RangeInput({
  range,
  disabled,
  onChange,
}: {
  range: RangeItem
  disabled?: boolean
  onChange: (startPage: string, endPage: string) => void
}) {
  const updateStartPage: ChangeEventHandler<HTMLInputElement> = event => {
    onChange(event.target.value, range.endPage)
  }
  const updateEndPage: ChangeEventHandler<HTMLInputElement> = event => {
    onChange(range.startPage, event.target.value)
  }

  return (
    <Range>
      <InputContainer>
        <Input
          type="number"
          required
          variant="outlined"
          label="Range from"
          size="small"
          value={range.startPage}
          onChange={updateStartPage}
          disabled={disabled}
          inputProps={{
            "data-test": "range-from-input",
          }}
        />
        {range.errors.startPage && (
          <ErrorText variant="helper" data-test="range-start-error">
            {range.errors.startPage}
          </ErrorText>
        )}
      </InputContainer>
      <Dash />
      <InputContainer>
        <Input
          type="number"
          required
          variant="outlined"
          label="Range to"
          size="small"
          value={range.endPage}
          onChange={updateEndPage}
          disabled={disabled}
          inputProps={{
            "data-test": "range-to-input",
          }}
        />
        {range.errors.endPage && (
          <ErrorText variant="helper" data-test="range-end-error">
            {range.errors.endPage}
          </ErrorText>
        )}
      </InputContainer>
    </Range>
  )
}
