import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"

import { InjuryDetailContainer, InjuryDetailsContainer } from "./styled"

const LoadingState = (): JSX.Element => {
  return (
    <InjuryDetailsContainer>
      <Box>
        <Box fontSize="13px" fontWeight="600">
          <Skeleton animation="wave" width="33%" />
        </Box>
      </Box>
      <InjuryDetailContainer>
        <Box mb={2}>
          <Skeleton animation="wave" width="50%" />
        </Box>
        <Skeleton animation="wave" width="33%" />
        <Skeleton animation="wave" width="33%" />
        <Skeleton animation="wave" width="33%" />
      </InjuryDetailContainer>
    </InjuryDetailsContainer>
  )
}

export { LoadingState as default }
