export enum DOCUMENT_TYPES {
  STANDARD = "standard",
  BASIC_PLUS = "basic+",
  SIMPLE = "simple",
  MEDICAL_CHRONOLOGY = "medical_chronology",
  PREMIUM_MEDCHRON = "medical_chronology_premium",
}

export const SIMPLE_DEMAND = "simple"

export const DocumentTypeDetails: Record<DOCUMENT_TYPES | typeof SIMPLE_DEMAND, { title: string }> = {
  [DOCUMENT_TYPES.STANDARD]: {
    title: "Standard",
  },
  [DOCUMENT_TYPES.BASIC_PLUS]: {
    title: "Basic+",
  },
  [DOCUMENT_TYPES.SIMPLE]: {
    title: "Simple",
  },
  [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY]: {
    title: "MedChron",
  },
  [DOCUMENT_TYPES.PREMIUM_MEDCHRON]: {
    title: "Premium MedChron",
  },
}

export function getDocumentTypeTitle(documentType: DOCUMENT_TYPES) {
  // Unsure what to use for the fallback, so I went with "Standard" since I thought it was better than ""
  return DocumentTypeDetails[documentType].title || "Standard"
}

export const DEMAND_TYPES = [DOCUMENT_TYPES.STANDARD, DOCUMENT_TYPES.BASIC_PLUS, DOCUMENT_TYPES.SIMPLE]

export const MED_CHRON_TYPES = [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY, DOCUMENT_TYPES.PREMIUM_MEDCHRON]
