import { StyledSliderContainer, StyledTypographyTitleSlider, StyledTypographySliderValue } from "./styled"
import { ECONOMIC_INJURIES_FIELDS } from "requests/constants"
import { Autocomplete, Box, Slider, TextField } from "@mui/material"
import { EconomicProps } from "./types"
import { OptionProps } from "evenup-ui/MultiSelectAutoComplete"
import { theme } from "app/theme"
import { DOCUMENT_TYPES } from "requests/enums"
import { REQUEST_FORM_VARIANTS } from "./constants"
import { InputField } from "common/form-components"
import { RequestFields } from "../types"
import { StyledEntityRow } from "../SinglePageRequestForm/components/styled"
import { useController, useFormContext, useWatch } from "react-hook-form"
import { useState } from "react"

const ECONOMIC_OPTIONS: OptionProps<boolean>[] = [
  { label: "Yes", value: true },
  { label: "No", value: false },
]

type EconomicFieldProps = {
  economicInjuryField: { key: string; display: string }
  plaintiffIndex: number
  variant: string | undefined
}

type HouseholdServicesFieldProps = EconomicFieldProps & {
  fieldValue: boolean
  fieldOnChange: (value: boolean) => void
}

type AutocompleteFieldProps = {
  economicInjuryField: { key: string; display: string }
  variant: string | undefined
  fieldValue: boolean
  fieldOnChange: (value: boolean) => void
}

const AutcompleteField = ({
  economicInjuryField,
  variant,
  fieldValue,
  fieldOnChange,
}: AutocompleteFieldProps) => {
  return (
    <Autocomplete
      key={economicInjuryField.key}
      id={economicInjuryField.key}
      value={ECONOMIC_OPTIONS.find(option => option.value === fieldValue)}
      isOptionEqualToValue={(option: OptionProps<boolean>, value: OptionProps<boolean>) =>
        option.value === value.value
      }
      onChange={(_, value: OptionProps<boolean>) => fieldOnChange(value.value)}
      getOptionLabel={option => option.label}
      options={ECONOMIC_OPTIONS}
      sx={{ width: 300 }}
      renderInput={params => <TextField {...params} label={economicInjuryField.display} />}
      disableClearable
      size={variant === REQUEST_FORM_VARIANTS.SINGLE_PAGE_REQUEST ? "small" : "medium"}
    />
  )
}

const EconomicField = ({ economicInjuryField, plaintiffIndex, variant }: EconomicFieldProps) => {
  const fieldName = `plaintiffs.${plaintiffIndex}.${economicInjuryField.key}` as string
  const { field } = useController({ name: fieldName })

  return (
    <AutcompleteField
      variant={variant}
      economicInjuryField={economicInjuryField}
      fieldValue={field.value}
      fieldOnChange={field.onChange}
    />
  )
}

// Purpose of splitting this component out was to put a default value on the phantom field household_services
const HouseholdServicesField = ({
  economicInjuryField,
  variant,
  fieldValue,
  fieldOnChange,
}: HouseholdServicesFieldProps) => {
  return (
    <AutcompleteField
      variant={variant}
      economicInjuryField={economicInjuryField}
      fieldValue={fieldValue}
      fieldOnChange={fieldOnChange}
    />
  )
}

export const EconomicDamages = ({ activeTab, documentType, variant }: EconomicProps) => {
  const { control } = useFormContext()

  const { field: sliderField } = useController({ name: `plaintiffs.${activeTab}.household_impairment_rate` })
  const handleSliderChange = (event: Event, value: number | number[]) => {
    if (typeof value !== "number") return
    sliderField.onChange(value)
  }

  const isStandardDocument = documentType === DOCUMENT_TYPES.STANDARD
  const hasLossOfIncome = useWatch({ name: `plaintiffs.${activeTab}.has_loss_of_income` })

  const [hasHouseholdServices, setHasHouseholdServicesValue] = useState<boolean>(sliderField.value !== 0)
  const onChangeHasHouseholdServices = (value: boolean) => {
    setHasHouseholdServicesValue(value)
    sliderField.onChange(value ? 100 : 0)
  }

  return (
    <>
      <Box marginBottom={theme.spacing(4)}>
        <StyledEntityRow>
          <>
            {isStandardDocument &&
              Object.values(ECONOMIC_INJURIES_FIELDS).map(economicInjuryField => {
                if (economicInjuryField.key === "has_household_services") {
                  return (
                    <HouseholdServicesField
                      key={economicInjuryField.key}
                      economicInjuryField={economicInjuryField}
                      plaintiffIndex={activeTab}
                      variant={variant}
                      fieldValue={hasHouseholdServices}
                      fieldOnChange={onChangeHasHouseholdServices}
                    />
                  )
                }
                return (
                  <EconomicField
                    key={economicInjuryField.key}
                    economicInjuryField={economicInjuryField}
                    plaintiffIndex={activeTab}
                    variant={variant}
                  />
                )
              })}
            <Box />
            {hasLossOfIncome && (
              <Box gridColumn="span 2">
                <InputField<Partial<RequestFields>>
                  control={control}
                  name={`plaintiffs.${activeTab}.additional_information`}
                  data-test={`plaintiff-loss-of-income-details--${activeTab}`}
                  type="text"
                  label="Loss of income details"
                  variant="outlined"
                  size="small"
                  showCharacterCounter
                  multiline
                  fullWidth
                />
              </Box>
            )}
          </>
        </StyledEntityRow>
      </Box>

      {hasHouseholdServices && (
        <StyledSliderContainer>
          <StyledTypographyTitleSlider>Impairment of Household Services</StyledTypographyTitleSlider>
          <Box display={"flex"} alignItems={"center"} width={theme.spacing(53)}>
            <Slider
              sx={{ color: theme.palette.blue.main }}
              aria-label="impairment-of-household-services-slider"
              defaultValue={10}
              step={10}
              marks
              min={0}
              max={100}
              value={sliderField.value}
              onChange={handleSliderChange}
            />
            <StyledTypographySliderValue>{sliderField.value ?? 0}%</StyledTypographySliderValue>
          </Box>
        </StyledSliderContainer>
      )}
    </>
  )
}
