import { useCallback, useMemo } from "react"
import { isNull } from "lodash"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import useUser from "hooks/useUser"
import { AutocompleteInput, AutocompleteInputValue } from "common/form-components/AutocompleteInput"
import { UserServiceDeserializer } from "api/services/users/serializers"
import { getFirms } from "api"
import { userService } from "api/services/users"

interface FirmSelectProps {
  firmId: Nullable<PrimaryKey>
  setFirmId: (firmId: Nullable<PrimaryKey>) => void
  error?: boolean
  freeSolo?: boolean
  disabled?: boolean
  helperText?: string
  onlyFirmsUserManage?: boolean
}

export const FirmSelect = ({
  setFirmId,
  firmId,
  error,
  helperText,
  disabled,
  freeSolo = false,
  onlyFirmsUserManage = false,
}: FirmSelectProps): JSX.Element => {
  const { user } = useUser()

  const { data: allFirms, isFetching: loadingFirms } = useQuery(
    [queryKeys.firms, "serialized"],
    async () => UserServiceDeserializer.fromFirmsUserManageJSON(await getFirms()),
    {
      enabled: user.isInternal && !onlyFirmsUserManage,
    }
  )
  const { data: firmsUserManage, isFetching: loadingFrimsUserManage } = useQuery(
    [queryKeys.firmsUserManage, user.id],
    userService.getFirmsUserManage,
    {
      enabled: user.isInternal && onlyFirmsUserManage,
    }
  )

  const isLoading = loadingFirms || loadingFrimsUserManage
  const firms = onlyFirmsUserManage ? firmsUserManage : allFirms

  const handleChangeFirm = useCallback(
    (value: AutocompleteInputValue) => {
      if (isNull(value)) {
        return setFirmId(null)
      }

      setFirmId(Number(value))
    },
    [setFirmId]
  )

  const firmOptions = useMemo(() => {
    if (!firms?.firms) return []

    return firms.firms.map(firm => ({ key: firm.id, display: firm.name }))
  }, [firms])

  return (
    <AutocompleteInput
      error={error}
      helperText={helperText}
      label={isLoading ? "Loading..." : "Select Firm"}
      options={firmOptions}
      onChange={handleChangeFirm}
      disabled={!!disabled || isLoading}
      value={firmId}
      freeSolo={freeSolo}
    />
  )
}
