import { Editor, NodeEntry, Path } from "slate"
import { CustomEditor, ListItemElement } from "../../CustomEditor"
import { LEAF_BLOCK_ELEMENTS } from "../../elements"
import { getBlocks } from "../blocks"
import { assignIdToNode, getNodeById, unassignIdFromNode } from "../manageNodes"
import { increaseListItemDepth } from "./increaseListItemDepth"
import { isListItem } from "./queries"

export function increaseListDepth(editor: CustomEditor): void {
  if (!editor.selection) return

  const leafBlockEntries = getBlocks(editor, LEAF_BLOCK_ELEMENTS.PARAGRAPH)
  const listItemEntries = leafBlockEntries
    .map(([, path]) =>
      Editor.above<ListItemElement>(editor, { at: path, match: node => isListItem(editor, node) })
    )
    .filter(entry => Boolean(entry)) as NodeEntry<ListItemElement>[]
  const indentableListItemEntries = listItemEntries.filter(
    ([, listItemPath]) => listItemPath[listItemPath.length - 1] !== 0
  )
  const indentableListItemPaths = indentableListItemEntries.map(([, listItemPath]) => listItemPath)
  const listItemEntriesToIndent = indentableListItemEntries.filter(([, listItemPath]) => {
    const ancestors = Path.ancestors(listItemPath)
    return !ancestors.some(ancestor => indentableListItemPaths.includes(ancestor))
  })

  const listItemIds = listItemEntriesToIndent.map(([, listItemPath]) => assignIdToNode(editor, listItemPath))

  listItemIds.forEach(id => {
    const nodeEntry = getNodeById(editor, id)

    if (!nodeEntry) return

    unassignIdFromNode(editor, id)
    increaseListItemDepth(editor, nodeEntry[1])
  })
}
