import React from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Close from "@mui/icons-material/Close"
import { resetPartitionsAndActions } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { getPartitionProvidersFromPartitions, updateAnnotatedExhibits } from "./cacheUtils"
import { AnnotatedExhibit, PartitionProvider } from "./types"

interface ResetDialogProps {
  open: boolean
  onClose: () => unknown
  exhibitId: number
  fileName: string
  caseId: string | number
}

const ResetDialog: React.FC<ResetDialogProps> = ({ open, onClose, exhibitId, fileName, caseId }) => {
  const queryClient = useQueryClient()
  const { showErrorMessage } = useHandleMessages()

  const { mutate: reset, isLoading: isResetting } = useMutation({
    mutationFn: resetPartitionsAndActions,
    onSuccess: (data: AnnotatedExhibit) => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: data,
        })
      })
      // Add Partition Providers back to list
      queryClient.setQueryData<PartitionProvider[]>([queryKeys.partition_providers, caseId], oldData => {
        if (!oldData) return []
        return getPartitionProvidersFromPartitions([...data.splits, ...data.duplicates], oldData)
      })

      onClose()
    },
    onError: error => {
      showErrorMessage({
        message:
          "Failed to reset file. Try again shortly and if your problem persists, please report your issue.",
        error,
      })
    },
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={`partition-reset-dialog-title-${exhibitId}`}
      aria-describedby={`partition-reset-dialog-description-${exhibitId}`}
      fullWidth
      maxWidth="xs"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle id={`partition-reset-dialog-title-${exhibitId}`}>Reset Changes</DialogTitle>
        <Box mr={1}>
          <IconButton onClick={onClose} disabled={isResetting}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <DialogContentText id={`partition-reset-dialog-description-${exhibitId}`}>
          Reset changes to {fileName}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} disabled={isResetting}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isResetting}
          onClick={() => reset({ exhibitId, caseId })}
        >
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResetDialog
