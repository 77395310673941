import { ROOT_ITEM_KEY } from "../constants"
import { ProjectionData } from "../types"
import { findParent } from "./projection"

export function isDragging<T>(data: ProjectionData<T>, draggingItemId: string, itemId: string): boolean {
  if (draggingItemId === itemId) {
    return true
  }

  const parent = findParent(data, itemId)

  return !!parent && parent.id !== ROOT_ITEM_KEY && isDragging(data, draggingItemId, parent.id)
}
