import { Box, Typography } from "@mui/material"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import SouthOutlinedIcon from "@mui/icons-material/SouthOutlined"
import CloseIcon from "@mui/icons-material/Close"
import { useReviewStore } from "./store"
import { flagsSelectors } from "./store/flags"
import { StyledAlert, StyledReviewButton } from "./styled"
import { useCallback, useState, useMemo } from "react"
import { ReviewArgs } from "./store/types"
import { reviewsSelectors } from "./store/reviews"

type ReviewAlert = {
  editorId?: string
  isPageView?: boolean
} & ReviewArgs
export function ReviewAlert({
  editorId,
  templateType,
  sectionType,
  plaintiffId,
  isPageView = false,
}: ReviewAlert) {
  const reviewArgs = useMemo<ReviewArgs>(
    () => ({ templateType, sectionType, plaintiffId }) as ReviewArgs,
    [templateType, sectionType, plaintiffId]
  )
  const activeFlags = useReviewStore(flagsSelectors.activeFlagsObject)
  const firstReviewItem = useReviewStore(reviewsSelectors.firstReviewItem)
  const reviewFromArgs = useReviewStore(reviewsSelectors.reviewItemByArgs(reviewArgs))
  // Need to use review items instead of active flags since active flags only goes by what editors are visible to the user.
  const reviewItemCount = useReviewStore(reviewsSelectors.reviewItemCount)
  const areAllReviewsAcked = useReviewStore(reviewsSelectors.allReviewsAcked(reviewArgs))
  const [closeAlert, setCloseAlert] = useState(false)
  // If we are in page view, we want to get the first review from the store since we don't have enough information to get the review from the args.
  const review = isPageView ? firstReviewItem : reviewFromArgs
  // Check if the review is loaded, and only if it's loaded show the alert.
  const isReviewLoaded = review && review.data && review.state === "loaded"
  const isReviewInProgress = review && review.data && review.state === "running"
  // If pageview, show total active flags, else show active flags for the editor.
  const suggestionCount = isPageView ? reviewItemCount : editorId ? activeFlags[editorId] : 0
  const suggestionText =
    suggestionCount === 0 ? "Zero review items found." : `${suggestionCount} suggestions available`
  const handleViewSuggestionsClick = useCallback(() => {
    let element: HTMLElement | null = null
    if (review) {
      const reviewId = review?.data?.results[0]?.id
      element = document.body.querySelector<HTMLElement>(`[data-suggestion-${reviewId}]`)
    } else {
      element = document.body.querySelector<HTMLElement>("[data-suggestion]")
    }

    if (!element) return

    element.scrollIntoView({ behavior: "smooth" })
    element.click()
  }, [review])

  // If the review is in progress, this means we have a new review.
  // Therefore set the state of the alert back to the original state.
  if (isReviewInProgress && closeAlert) {
    setCloseAlert(false)
  }
  // If someone clicked the x or if the review is not loaded or if they have acknowledged all the reviews return null
  if (closeAlert || !isReviewLoaded || areAllReviewsAcked) return null
  return (
    <StyledAlert variant="outlined" icon={<ErrorOutlineOutlinedIcon sx={{ color: "#E040FB" }} />}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant="body2SemiBold">{suggestionText}</Typography>
        {suggestionCount !== 0 && !isPageView ? (
          <StyledReviewButton
            endIcon={<SouthOutlinedIcon />}
            size="small"
            variant="text"
            sx={{ marginLeft: "auto" }}
            style={{ height: "20px" }}
            onClick={handleViewSuggestionsClick}
          >
            <Typography variant="body2SemiBold">View suggestions</Typography>
          </StyledReviewButton>
        ) : (
          <StyledReviewButton
            endIcon={<CloseIcon />}
            size="small"
            variant="text"
            sx={{ marginLeft: "auto" }}
            style={{ height: "20px" }}
            onClick={() => {
              setCloseAlert(true)
            }}
          />
        )}
      </Box>
    </StyledAlert>
  )
}
