import { useCallback, useContext } from "react"
import { TransitionGroup } from "react-transition-group"
import Collapse from "@mui/material/Collapse"
import { Loading } from "common/loading"
import { FormFooter } from "./FormFooter"
import { LibraryTabStateContext } from "../State/constants"
import { useDocumentStructureForm } from "../TemplateForms"
import { CREATE_DOCUMENT_STRUCTURE_FORM_STATE_MESSAGES } from "../TemplateForms/constants"
import { createEndCreatingTemplateAction, createStartCreatingTemplateAction } from "../State/templatesActions"
import {
  StyledTitle,
  StyledLibraryFormWrapper,
  StyledLibraryForm,
  StyledLibraryAddButton,
  StyledMessageWrapper,
  StyledArchiveButton,
  StyledArchivedStructuresTitleWrapper,
  StyledDescription,
} from "./styled"

interface NewDocumentStructureProps {
  showArchived: boolean
  setShowArchived: (data: boolean) => void
}

export function NewDocumentStructure({
  setShowArchived,
  showArchived,
}: NewDocumentStructureProps): JSX.Element {
  const { state, dispatch } = useContext(LibraryTabStateContext)
  const disabled = !!(state.creatingData || state.editingRowId)
  const initialTemplateData = state.creatingData || {}

  const onFinish = useCallback(() => dispatch(createEndCreatingTemplateAction()), [dispatch])

  const { runningAction, message, editForm, isLoading, handleSubmit, handleCancel, clearForm, errorForm } =
    useDocumentStructureForm({
      onFinish,
      rowData: initialTemplateData,
      formStatusMessageMap: CREATE_DOCUMENT_STRUCTURE_FORM_STATE_MESSAGES,
      show: true,
    })

  const handleAddNewTemplate = useCallback(() => {
    clearForm()
    dispatch(createStartCreatingTemplateAction())
  }, [clearForm, dispatch])

  const handleClick = useCallback(() => {
    setShowArchived(!showArchived)
  }, [showArchived, setShowArchived])

  return (
    <>
      {message && <StyledMessageWrapper error={errorForm}>{message}</StyledMessageWrapper>}
      <StyledLibraryFormWrapper>
        {!showArchived && (
          <div>
            <TransitionGroup>
              {!!state.creatingData && (
                <Collapse appear>
                  <StyledLibraryForm documentStructure>
                    <StyledTitle>Add new document structure</StyledTitle>
                    <StyledDescription>
                      Fill out each section with the necessary Headers, Templates and Placeholders based on
                      your firm’s requirements.
                    </StyledDescription>
                    {editForm(
                      <FormFooter
                        runningAction={runningAction}
                        onCancel={handleCancel}
                        onAction={handleSubmit}
                      />
                    )}
                  </StyledLibraryForm>
                </Collapse>
              )}
            </TransitionGroup>
            <StyledLibraryAddButton onClick={handleAddNewTemplate} disabled={disabled}>
              + Add Document Structure
            </StyledLibraryAddButton>
          </div>
        )}
        <StyledArchiveButton showArchived={showArchived} onClick={handleClick}>
          {showArchived ? "< Back" : "View Archive"}
        </StyledArchiveButton>
        <StyledArchivedStructuresTitleWrapper showArchived={showArchived}>
          <StyledTitle>Archived Structures</StyledTitle>
        </StyledArchivedStructuresTitleWrapper>
      </StyledLibraryFormWrapper>
      <Loading show={isLoading} label="Creating document structure..." />
    </>
  )
}
