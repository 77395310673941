import styled from "@emotion/styled"

export const StyledLawFirmWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  display: "flex",
  gap: theme.spacing(2),
}))

export const LawFirmTitle = styled.div(({ theme }) => ({
  paddingTop: theme.spacing(2),
  fontSize: "14px",
  fontWeight: 600,
}))
