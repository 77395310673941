import { Control, Controller, FieldErrors, FieldValues, Path } from "react-hook-form"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"
import MuiCheckbox, { CheckboxProps } from "@mui/material/Checkbox"

export interface CheckboxInputProps<TFields extends FieldValues> extends CheckboxProps {
  control: Control<TFields>
  // TODO: move to FieldPathWithValue with strict value type check
  // Blocker - MissingDocumentClientView
  name: Path<TFields>
  label?: string
  errors?: FieldErrors<TFields>
}

export function CheckboxInput<TFields extends FieldValues>({
  control,
  name,
  label,
  className,
  ...inputProps
}: CheckboxInputProps<TFields>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControlLabel
            className={className}
            control={<MuiCheckbox color="secondary" checked={field.value} {...field} {...inputProps} />}
            label={
              <Typography variant="caption" display="block" sx={{ userSelect: "none" }}>
                {label}
              </Typography>
            }
          />
        )
      }}
    />
  )
}
