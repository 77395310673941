import { useMemo } from "react"
import { getRanges } from "../utils"
import { CitationValue } from "./CitationValue"

export function ExhibitCitation({
  index,
  pages,
  onClick,
}: {
  index: number
  pages: number[]
  onClick: (page: Nullable<number>) => void
}) {
  const ranges: number[][] = useMemo(() => getRanges(pages), [pages])
  return (
    <>
      {ranges.map(
        (range: number[], i: number) =>
          range.length > 0 && (
            <CitationValue
              onClick={onClick}
              range={range}
              index={i}
              key={`${range[0]}-${range[range.length - 1]}`}
            >
              {i === 0 ? `Exhibit ${index + 1} - p. ` : ""}
            </CitationValue>
          )
      )}
    </>
  )
}
