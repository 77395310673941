import React, { forwardRef, useCallback, useEffect, useReducer, useRef, useState } from "react"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import TextButton from "common/buttons/TextButton"
import { queryKeys } from "react-query/constants"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { setDeletions } from "api"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { usePublish } from "message-broker/usePublish"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { deleteReducer } from "./reducers/deleteReducer"
import { validateRanges } from "./validation/deleteValidation"
import { anyErrors } from "./validation/validationUtils"
import { updateAnnotatedExhibits } from "./cacheUtils"
import { deletionsToRanges, duplicateActionsUpdate, exhibitActionsUpdate } from "./utils"
import { RangeInput } from "./RangeInput"
import {
  CaseId,
  DeleteAction,
  FieldValidationErrors,
  MIN_PAGE_NUMBER,
  AnnotatedExhibit,
  Duplicate,
} from "./types"
import { EditBoxTitle, EditPaper, RangesWrapper, Text } from "./styled"

interface DeletePagesBoxProps {
  onCancel: () => unknown
  onSuccessfulSave: () => unknown
  deletions: DeleteAction[]
  exhibitId: number
  caseId: CaseId
  numberOfPages: number
  duplicate?: Nullable<Duplicate>
}

export default forwardRef<HTMLDivElement, DeletePagesBoxProps>(function DeletePagesBox(
  { onCancel, deletions, exhibitId, caseId, onSuccessfulSave, numberOfPages, duplicate },
  ref
): React.ReactElement {
  const startBoundary = duplicate ? duplicate.start_page : MIN_PAGE_NUMBER
  const endBoundary = duplicate ? duplicate.end_page : numberOfPages

  const [state, dispatch] = useReducer(deleteReducer, { ranges: deletionsToRanges(deletions) })
  const { showErrorMessage } = useHandleMessages()
  const queryClient = useQueryClient()
  const [validationErrors, setValidationErrors] = useState<FieldValidationErrors>({})
  const addButtonRef = useRef<HTMLButtonElement>(null)
  const publish = usePublish()

  const { mutate, isLoading } = useMutation({
    mutationFn: setDeletions,
    onSuccess: (data: DeleteAction[]) => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: duplicate
            ? duplicateActionsUpdate({ duplicateId: duplicate.pk, actions: data })
            : exhibitActionsUpdate({ actions: data }),
        })
      })
      onSuccessfulSave()
    },
    onError: error => {
      showErrorMessage({
        message: "Could not create deletions. Try again shortly and file an issue if the problem persists.",
        error,
      })
    },
  })

  const handleSave = useCallback(() => {
    const errors = validateRanges({ ranges: state.ranges, startBoundary, endBoundary })
    setValidationErrors(errors)

    if (anyErrors(errors)) return

    publish(MESSAGE_TOPIC.EXHIBIT_IS_UPDATED, { caseId, exhibitId, broadcast: true })

    mutate({
      exhibitId,
      data: {
        partition_id: duplicate?.pk,
        deletions: state.ranges.map(range => ({ start_page: range.start, end_page: range.end })),
      },
    })
  }, [caseId, duplicate?.pk, endBoundary, exhibitId, mutate, publish, startBoundary, state.ranges])

  useEffect(() => {
    // scroll window when additional range would push the add button out of view
    addButtonRef.current?.scrollIntoView({ block: "nearest" })
  }, [state.ranges])

  return (
    <EditPaper ref={ref}>
      <Box display="flex" alignItems="baseline">
        <EditBoxTitle>Delete Pages from {duplicate ? "Duplicate" : "whole Document"}</EditBoxTitle>&nbsp;
        <Text>
          (pg. {startBoundary} - {endBoundary})
        </Text>
      </Box>
      <RangesWrapper mt={2}>
        {state.ranges.map(({ formId, start, end }) => (
          <RangeInput
            key={formId}
            validationErrors={validationErrors}
            dispatch={dispatch}
            disabled={isLoading}
            formId={formId}
            start={start}
            end={end}
          />
        ))}
      </RangesWrapper>
      <Box mt={2} mb={1}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <TextButton ref={addButtonRef} onClick={() => dispatch({ type: "addRange" })} disabled={false}>
            + Add Deletion
          </TextButton>
        </Box>
        <Box>
          <TextButton onClick={onCancel} textColor="grey" disabled={false}>
            Cancel
          </TextButton>
          <TextButton onClick={handleSave} disabled={false}>
            Save
          </TextButton>
        </Box>
      </Box>
    </EditPaper>
  )
})
