type BaseItem = Record<string, unknown>

type WithChildren<T extends BaseItem> = {
  children: TreeLikeItem<T>[]
}

type TreeLikeItem<T extends BaseItem> = T & WithChildren<T>

type UniqueKey = symbol

type FlatMapItem<T extends BaseItem> = T & {
  children: UniqueKey[]
}

type FlatMap<T extends BaseItem> = Record<UniqueKey, FlatMapItem<T>>

type FlatMapOrder = UniqueKey[]

type ToFlatMapResult<T extends BaseItem> = {
  items: FlatMap<T>
  order: FlatMapOrder
}

export function toFlatMap<TData extends BaseItem>(data: TreeLikeItem<TData>[]): ToFlatMapResult<TData> {
  const result: ToFlatMapResult<TData> = { items: {}, order: [] }

  function* iterateNodes() {
    result.order = yield* toFlatMapInternal(data)
  }

  for (const [key, item] of iterateNodes()) {
    result.items[key] = item
  }

  return result
}

function* toFlatMapInternal<TData extends BaseItem>(
  data: TreeLikeItem<TData>[]
): Generator<[UniqueKey, FlatMapItem<TData>], UniqueKey[]> {
  const order: UniqueKey[] = []

  for (const itemData of data) {
    const key = Symbol("FlatMapItem" + itemData?.pk)
    const children = yield* toFlatMapInternal(itemData.children)
    const item: FlatMapItem<TData> = { ...itemData, children }

    order.push(key)

    yield [key, item]
  }

  return order
}

export function fromFlatMap<TData extends BaseItem>(
  items: FlatMap<TData>,
  order: FlatMapOrder
): TreeLikeItem<TData>[] {
  return Array.from(fromFlatMapInternal(items, order))
}

function* fromFlatMapInternal<TData extends BaseItem>(
  items: FlatMap<TData>,
  order: FlatMapOrder
): Generator<TreeLikeItem<TData>> {
  for (const key of order) {
    const item = items[key]
    const children = Array.from(fromFlatMapInternal(items, item.children))

    yield { ...item, children }
  }
}

export function findKeyInMap<TData extends BaseItem>(
  items: FlatMap<TData>,
  predicate: (item: TData) => boolean
): UniqueKey | null {
  return Object.getOwnPropertySymbols(items).find(key => predicate(items[key])) || null
}

export function updateInMap<TData extends BaseItem>(
  items: FlatMap<TData>,
  predicate: (item: TData) => boolean,
  values: TData
): FlatMap<TData> {
  const key = findKeyInMap(items, predicate)

  if (!key) {
    return items
  }

  return {
    ...items,
    [key]: {
      ...values,
      children: items[key].children,
    },
  }
}

export function updateInTree<TData extends BaseItem>(
  tree: TreeLikeItem<TData>[],
  predicate: (item: TData) => boolean,
  values: TData
): TreeLikeItem<TData>[] {
  const { items, order } = toFlatMap(tree)
  const updatedItems = updateInMap(items, predicate, values)

  return fromFlatMap(updatedItems, order)
}
