import { StyledAutocompletePopper } from "./styled"
import { PopperProps } from "@mui/material"

interface PopperComponentProps {
  anchorEl?: PopperProps["anchorEl"]
  disablePortal?: boolean
  open: boolean
}

export default function Popper(props: PopperComponentProps): JSX.Element {
  // eslint-disable-next-line
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}
