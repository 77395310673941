import { useCallback, useEffect, useMemo, useState, ReactNode, PropsWithChildren } from "react"
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown"
import CircleIcon from "@mui/icons-material/Circle"
import { Autocomplete, Box, Divider, TextField } from "@mui/material"
import {
  ResetButton,
  StyledFiltersWrapper,
  StyledTitle,
  StyledIconButton,
  StyledProviderFilterWrapper,
} from "./styled"
import { BASE_OUTER_PAGE_ID } from "common/pages/BasePage"
import { documentActions, documentStoreSelectors, useDocumentStore } from "documents/store"
import { DateField } from "common/form-components"
import MultiSelectAutoComplete from "evenup-ui/MultiSelectAutoComplete"
import { useShallow } from "zustand/react/shallow"
import { noop } from "lodash"
import { theme } from "app/theme"
import { MED_SUMMARY_SORTING, MED_SUMMARY_REVIEW_ACTIONS } from "documents/store/types"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"
import { usePermissions } from "permissions/usePermissions"
import { Circle } from "./medical-summary/components/styled"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { documentsService } from "api/services/documents"

export const MEDICAL_SUMMARY_FILTERS_WRAPPER_ID = "medical_summary_filters_wrapper_id"
const EXPANDED_PADDING = "280px"
const sortingOptions = [
  {
    label: MED_SUMMARY_SORTING.PROVIDER,
  },
  {
    label: MED_SUMMARY_SORTING.DATE,
  },
]

const reviewActionOptions = [
  {
    label: MED_SUMMARY_REVIEW_ACTIONS.INCOMPLETE,
    symbol: () => <Circle />,
  },
  {
    label: MED_SUMMARY_REVIEW_ACTIONS.COMPLETE,
    symbol: () => <Circle completed />,
  },
  {
    label: MED_SUMMARY_REVIEW_ACTIONS.REVIEWED,
    symbol: () => <Circle reviewed />,
  },
  {
    label: MED_SUMMARY_REVIEW_ACTIONS.PENDING_FINAL_REVIEW,
  },
]

type MedChronFiltersProps = {
  displayReviewActions?: boolean
  filterEmptyProviders?: boolean
}

export const MedChronFilters = ({
  displayReviewActions = false,
  filterEmptyProviders = false,
  children,
}: PropsWithChildren<MedChronFiltersProps>): ReactNode => {
  const [collapsed, setCollapsed] = useState(false)
  const documentId = useDocumentStore(state => state.documentId)
  const requestId = useDocumentStore(state => state.questionnaireId)
  const filtersStartDate = useDocumentStore(state => state.filtersStartDate)
  const filtersEndDate = useDocumentStore(state => state.filtersEndDate)
  const filtersProviderIds = useDocumentStore(state => state.filtersProviderIds)
  const filtersReviewAction = useDocumentStore(state => state.filtersReviewAction)
  const { medchronAppointmentTagsEnabled } = usePermissions()

  const sorting = useDocumentStore(state => state.sorting)
  const providers = useDocumentStore(useShallow(documentStoreSelectors.getProviders(filterEmptyProviders)))

  const { data: medchronTile } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => {
      if (documentId) {
        return documentsService.getMedchronTile({ documentId: documentId })
      }
    },
    {
      enabled: !!documentId,
    }
  )

  const toggleCollapse = useCallback(() => {
    const page = document.getElementById(BASE_OUTER_PAGE_ID)

    if (!page) return

    page.style.paddingLeft = collapsed ? EXPANDED_PADDING : ""

    setCollapsed(!collapsed)
  }, [collapsed])

  const handleChangeStartDate = useCallback(
    (date: null | string) => {
      if (!medchronTile) return

      amplitudeApm.trackEvent(
        new MedicalChronologyAnalyticEvent(
          MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyFilteredByTimeRange,
          {
            document_id: documentId,
            request_id: requestId,
            documentType: medchronTile.questionnaireType,
          }
        )
      )
      documentActions.setFiltersStartDate(date)
    },
    [documentId, requestId, medchronTile]
  )

  const handleChangeEndDate = useCallback(
    (date: null | string) => {
      if (!medchronTile) return

      amplitudeApm.trackEvent(
        new MedicalChronologyAnalyticEvent(
          MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyFilteredByTimeRange,
          {
            document_id: documentId,
            request_id: requestId,
            documentType: medchronTile.questionnaireType,
          }
        )
      )
      documentActions.setFiltersEndDate(date)
    },
    [documentId, requestId, medchronTile]
  )

  const handleChangeReviewAction = useCallback(
    (...[, nextValue]: [unknown, Nullable<{ label: MED_SUMMARY_REVIEW_ACTIONS }>]) => {
      documentActions.setFiltersReviewAction(nextValue ? nextValue.label : null)
    },
    []
  )

  const handleChangeProviderIds = useCallback(
    (nextValues: { value: string }[]) => {
      if (!medchronTile) return

      amplitudeApm.trackEvent(
        new MedicalChronologyAnalyticEvent(
          MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyFilteredByProvider,
          {
            document_id: documentId,
            request_id: requestId,
            documentType: medchronTile.questionnaireType,
          }
        )
      )
      documentActions.setFiltersProviderIds(nextValues.map(({ value }) => value))
    },
    [documentId, requestId, medchronTile]
  )

  const handleSortingChange = useCallback(
    (...[, nextValue]: [unknown, { label: MED_SUMMARY_SORTING }]) => {
      if (!medchronTile) return

      amplitudeApm.trackEvent(
        new MedicalChronologyAnalyticEvent(
          MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologySortedByProvider,
          {
            document_id: documentId,
            request_id: requestId,
            documentType: medchronTile.questionnaireType,
          }
        )
      )
      documentActions.setSorting(nextValue.label)
    },
    [documentId, requestId, medchronTile]
  )

  const options = useMemo(
    () => providers.map(({ id, name, color }) => ({ value: id, label: name, color })),
    [providers]
  )

  const selectedOptions = useMemo(
    () => options.filter(({ value }) => filtersProviderIds?.includes(value)),
    [options, filtersProviderIds]
  )

  useEffect(() => {
    const page = document.getElementById(BASE_OUTER_PAGE_ID)

    if (!page) return

    page.style.paddingLeft = EXPANDED_PADDING

    return () => {
      page.style.paddingLeft = ""
    }
  }, [])

  return (
    <StyledFiltersWrapper
      id={MEDICAL_SUMMARY_FILTERS_WRAPPER_ID}
      collapsed={collapsed}
      onClick={collapsed ? toggleCollapse : noop}
    >
      {!collapsed && <StyledTitle>Filter</StyledTitle>}
      <StyledIconButton
        onClick={toggleCollapse}
        sx={{
          right: collapsed ? theme.spacing(1) : 0,
          transform: `rotate(${collapsed ? -90 : 90}deg)`,
        }}
      >
        <ExpandCircleDownIcon sx={{ fontSize: "30px" }} htmlColor={collapsed ? "#191919" : "#002EB9"} />
      </StyledIconButton>
      {!collapsed && (
        <>
          <Box display="flex" gap={2.5} flexDirection="column" mb={2.5}>
            <DateField
              fieldProps={{ size: "small" }}
              label="Start Date"
              maxDate={filtersEndDate ? new Date(`${filtersEndDate} 00:00:00`) : undefined}
              initialValue={filtersStartDate}
              onChange={handleChangeStartDate}
            />
            <DateField
              fieldProps={{ size: "small" }}
              label="End Date"
              minDate={filtersStartDate ? new Date(`${filtersStartDate} 00:00:00`) : undefined}
              initialValue={filtersEndDate}
              onChange={handleChangeEndDate}
            />
            <MultiSelectAutoComplete
              size="small"
              label="Providers"
              options={options}
              value={selectedOptions}
              onChangeValue={handleChangeProviderIds}
              optionRender={option => (
                <StyledProviderFilterWrapper>
                  <CircleIcon htmlColor={option.color} />
                  <Box>{option.label}</Box>
                </StyledProviderFilterWrapper>
              )}
            />
            {medchronAppointmentTagsEnabled && displayReviewActions ? (
              <Autocomplete
                size="small"
                options={reviewActionOptions}
                renderOption={(props, option) => (
                  <li key={option.label} {...props}>
                    {option.symbol ? option.symbol() : null}
                    <Box>{option.label}</Box>
                  </li>
                )}
                onChange={handleChangeReviewAction}
                value={reviewActionOptions.find(({ label }) => label === filtersReviewAction) ?? null}
                renderInput={params => <TextField {...params} label="Status" />}
              />
            ) : null}
            <Autocomplete
              size="small"
              options={sortingOptions}
              disableClearable
              onChange={handleSortingChange}
              value={sortingOptions.find(({ label }) => sorting === label)}
              renderInput={params => <TextField {...params} label="Sort By" />}
            />
            <ResetButton onClick={documentActions.resetFilters}>Reset</ResetButton>
          </Box>
          <Divider />
          {children}
        </>
      )}
    </StyledFiltersWrapper>
  )
}
