import { ObjectiveTestFinding } from "api/services/provider-objective-test/types"
import { Provider } from "demand/Providers/types"
import { FindingEditableItem, FindingItemSkeleton } from "./"
import { ObjectiveTestFindingsEditableList } from "../styled"

export type FindingsEditableListProps = {
  findings: ObjectiveTestFinding[]
  isDisabled?: boolean
  disabledTitle?: string
  updateFinding: (finding: ObjectiveTestFinding) => void
  isCreatingNewItem: boolean
  provider: Provider
}

const FindingsEditableList = ({
  findings,
  isDisabled,
  disabledTitle,
  updateFinding,
  isCreatingNewItem,
  provider,
}: FindingsEditableListProps) => {
  return (
    <ObjectiveTestFindingsEditableList>
      {findings.map(finding => (
        <FindingEditableItem
          key={finding.id}
          finding={finding}
          isDisabled={isDisabled}
          disabledTitle={disabledTitle}
          updateFinding={updateFinding}
          provider={provider}
        />
      ))}
      {isCreatingNewItem && <FindingItemSkeleton />}
    </ObjectiveTestFindingsEditableList>
  )
}

export default FindingsEditableList
