import { ReviewItem } from "./store/types"
import { AddedHtml, RemovedHtml, TextDiff } from "./styled"
import { Typography } from "@mui/material"

interface HtmlStylesDiffProps {
  reviewItem: ReviewItem
}

export function HtmlStylesDiff({ reviewItem }: HtmlStylesDiffProps) {
  return (
    <TextDiff>
      <Typography variant="body2">
        <RemovedHtml dangerouslySetInnerHTML={{ __html: reviewItem.originalText }} />
        <AddedHtml
          dangerouslySetInnerHTML={{ __html: reviewItem.userModifiedText ?? reviewItem.suggestedText }}
        />
      </Typography>
    </TextDiff>
  )
}
