import { SelectableCardProps } from "./types"
import { StyledBoxIcon, StyledTypographyTitle, StyledTypographyDescription, StyledColumnBox } from "./styled"
import { theme } from "app/theme"

export const SmallSelectableCard = ({
  title,
  description,
  icon,
  paletteType,
  children,
  ...props
}: SelectableCardProps) => {
  return (
    <StyledColumnBox>
      <StyledBoxIcon marginBottom={theme.spacing(4)} paletteType={paletteType}>
        {icon}
      </StyledBoxIcon>
      <StyledTypographyTitle size={props.size} isDisabled={props.isDisabled}>
        {title}
      </StyledTypographyTitle>
      <StyledTypographyDescription component="p" isDisabled={props.isDisabled}>
        {description}
      </StyledTypographyDescription>
      {!!children && children}
    </StyledColumnBox>
  )
}
