import React from "react"

import { Box, Divider, List } from "@mui/material"

import SearchIcon from "@mui/icons-material/Search"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import { LeftNavLink } from "./LeftNavLink"

export const LeftNavCaseSection = (): React.ReactElement => {
  const caseLinks = [
    { label: "Case Search", toLink: "search", dataTest: "case-search", Icon: SearchIcon },
    { label: "Relevant Cases", toLink: "relevant", dataTest: "relevant-cases", Icon: StarBorderIcon },
    { label: "Favorite Cases", toLink: "favorite", dataTest: "favorite-cases", Icon: FavoriteBorderIcon },
  ]

  return (
    <Box mt={1} mb={1}>
      <Box mb={1}>
        <Divider variant="middle" />
      </Box>
      <List sx={{ padding: 0 }}>
        {caseLinks.map(caseLink => (
          <LeftNavLink
            key={caseLink.label}
            label={caseLink.label}
            toLink={caseLink.toLink}
            dataTest={caseLink.dataTest}
            Icon={caseLink.Icon}
          />
        ))}
      </List>
      <Box mt={1}>
        <Divider variant="middle" />
      </Box>
    </Box>
  )
}
