export type PdfAnnotationsType = RectangleHighlightAnnotation

interface Annotation {
  type: string
  shape: string
}

export enum AnnotationColorsMapping {
  "MEDICAL_PASSAGE" = "rgb(160, 244, 252)",
}

export const ANNOTATION_DEFAULT_COLOR = "rgb(255, 255, 0)"

export interface RectangleHighlightAnnotation extends Annotation {
  shape: "RECTANGLE"
  // these will be floats used as percentage
  top: number
  left: number
  height: number
  width: number
  color?: string
}

export enum AnnotationsMapping {
  "MEDICAL_PASSAGE" = "RECTANGLE",
}

const rectangleHighlightAnnotation = (
  annotation: RectangleHighlightAnnotation,
  pageNumber: number,
  annotationIndex: number
) => (
  <div
    key={`${pageNumber}-${annotationIndex}`}
    style={{
      position: "absolute",
      top: `${annotation.top}%`,
      left: `${annotation.left}%`,
      height: `${annotation.height}%`,
      width: `${annotation.width}%`,
      backgroundColor: annotation.color || ANNOTATION_DEFAULT_COLOR, // Yellow with transparency
      pointerEvents: "none", // Allows interaction with the PDF text under the box
      mixBlendMode: "multiply",
    }}
  />
)

const PdfAnnotations = ({
  annotations,
  pageNumber,
}: {
  annotations: PdfAnnotationsType[]
  pageNumber: number
}) => (
  <div id="pdf-annotations">
    {annotations.map((annotation, index) => {
      switch (annotation.shape) {
        case "RECTANGLE":
          return rectangleHighlightAnnotation(annotation, pageNumber, index)
        default:
          return null
      }
    })}
  </div>
)

export default PdfAnnotations
