import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import { SelectableCardProps } from "./types"
import { theme } from "app/theme"
import {
  StyledBoxIcon,
  StyledTypographyTitle,
  StyledTypographyDescription,
  StyledEvenUp,
  StyledContent,
  StyledBadgeContainer,
  StyledBadge,
  TopPanelWrapper,
} from "./styled"

export const LargeSelectableCard = ({
  title,
  description,
  icon,
  disabledIcon,
  paletteType,
  children,
  label,
  topPanel,
  badges,
  ...props
}: SelectableCardProps) => {
  const displayIcon = props.isDisabled && disabledIcon ? disabledIcon : icon
  const shouldShowDivider = (!!displayIcon || !!title || !!description) && !!children

  return (
    <>
      {label && <StyledEvenUp disabled={props.isDisabled} size="small" label={label} />}
      {topPanel && <TopPanelWrapper>{topPanel}</TopPanelWrapper>}
      <StyledContent
        sx={
          topPanel
            ? { position: "relative", padding: theme.spacing(13.5, 2.5, 2.5) }
            : { padding: theme.spacing(2.5) }
        }
      >
        <Box flex="1">
          {(displayIcon || title) && (
            <Box display="flex" alignItems="center" gap={theme.spacing(2)} marginBottom={theme.spacing(3)}>
              {displayIcon && <StyledBoxIcon paletteType={paletteType}>{displayIcon}</StyledBoxIcon>}
              {title && (
                <StyledTypographyTitle size={props.size} isDisabled={props.isDisabled}>
                  {title}
                </StyledTypographyTitle>
              )}
            </Box>
          )}
          {description && (
            <Box flex={1}>
              <StyledTypographyDescription component="p" size={props.size} isDisabled={props.isDisabled}>
                {description}
              </StyledTypographyDescription>
            </Box>
          )}
          {badges && (
            <StyledBadgeContainer>
              {badges.map(badge => {
                return <StyledBadge key={badge}>{badge}</StyledBadge>
              })}
            </StyledBadgeContainer>
          )}
        </Box>
        {shouldShowDivider && (
          <Divider
            sx={{
              borderColor: theme.palette.selectableCard.dividerColor,
              margin: "0",
            }}
            orientation="vertical"
            variant="middle"
            flexItem
          />
        )}
        <Box display="flex" flex={1}>
          {children}
        </Box>
      </StyledContent>
    </>
  )
}
