import { Descendant, Editor, NodeEntry, Range, Transforms } from "slate"
import { createListItem, createListItemContent } from "../../create"
import { CustomElement, ListItemElement, ParagraphElement } from "../../CustomEditor"
import { LEAF_BLOCK_ELEMENTS } from "../../elements"
import { getCursorPositionInNode } from "../cursor"
import { closestListItemNode, isInList, isList } from "./queries"
import { splitListItem } from "./splitListItem"

export function insertFragmentToListItem(editor: Editor, nodes: Descendant[]): void {
  if (!editor.selection) return

  if (!isInList(editor, editor.selection.anchor.path)) {
    return Transforms.insertFragment(editor, nodes)
  }

  if (Range.isExpanded(editor.selection)) {
    Transforms.delete(editor)
  }

  if (!nodes.some(node => (node as CustomElement).type)) {
    return Transforms.insertFragment(editor, nodes)
  }

  function getClosestListItem(): NodeEntry<ListItemElement> {
    return closestListItemNode(editor, (editor.selection as Range).anchor.path) as NodeEntry<ListItemElement>
  }

  const [, listItemPath] = getClosestListItem()
  const blockNodes = nodes as CustomElement[]
  const { isStart, isEnd } = getCursorPositionInNode(editor, editor.selection.anchor, [...listItemPath, 0])

  blockNodes.forEach((node, index) => {
    if (index === 0 && node.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH) {
      if (isStart && isEnd) {
        Editor.withoutNormalizing(editor, () => {
          Transforms.removeNodes(editor, {
            at: [...listItemPath, 0, 0],
          })
          Transforms.insertNodes(editor, node, {
            at: [...listItemPath, 0, 0],
          })
          Transforms.select(editor, [...listItemPath, 0, 0])
          Transforms.collapse(editor, {
            edge: "end",
          })
        })
        return
      }

      return Transforms.insertFragment(editor, [node])
    }

    const listItemEntry = getClosestListItem()

    if (!listItemEntry) {
      return Transforms.insertFragment(editor, [node])
    }

    const [currentListItemNode, currentListItemNodePath] = listItemEntry

    if (isList(editor, node)) {
      if (currentListItemNode.children.length === 2) {
        splitListItem(editor, { keepSelection: false, force: true })
      }

      const [, currentListItemNodePath] = getClosestListItem()
      return Transforms.insertNodes(editor, node, { at: [...currentListItemNodePath, 1] })
    }

    if (currentListItemNode.children.length === 2) {
      return Transforms.insertNodes(
        editor,
        createListItem([createListItemContent([node as ParagraphElement])]),
        {
          at: [
            ...currentListItemNodePath.slice(0, -1),
            (currentListItemNodePath[currentListItemNodePath.length - 1] as number) + 1,
          ],
        }
      )
    } else {
      splitListItem(editor, { keepSelection: false, keepInList: true })
      return Transforms.insertFragment(editor, [node])
    }
  })
}
