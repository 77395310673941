import { useMemo } from "react"
import { InputField, SelectInput, CurrencyField } from "common/form-components"
import { useRequestContext } from "requests/context"
import { RequestFormModule } from "./RequestFormModule"
import { StyledEntityWrapper, StyledEntityRow } from "./styled"
import {
  POLICY_TYPE_OPTIONS,
  INDIVIDUAL,
  INDIVIDUAL_POLICY_COVERAGE_TYPES,
  COMMERCIAL_POLICY_COVERAGE_TYPES,
  PolicyCoverageTypeFilter,
} from "requests/constants"
import { usePermissions } from "permissions/usePermissions"
import { RequestMultiPolicyDemandsModal } from "./RequestMultiPolicyDemandsModal"
import { DEFAULT_DEFENDANTS_VALUE } from "requests/RequestForm/constants"
import { useFormContext, useWatch } from "react-hook-form"

export const PolicyCarrierInformation = ({ readOnly }: { readOnly?: boolean }) => {
  const { requestId } = useRequestContext()
  const { control, setValue } = useFormContext()
  const policy_type = useWatch({ name: "policy_type" })
  const policy_coverage_type = useWatch({ name: "policy_coverage_type" })
  const { showMultiPolicySurveyEnabled } = usePermissions()

  const policyCoverageTypeOptions = useMemo(() => {
    return (
      policy_type === INDIVIDUAL ? INDIVIDUAL_POLICY_COVERAGE_TYPES : COMMERCIAL_POLICY_COVERAGE_TYPES
    ).filter(policyType => PolicyCoverageTypeFilter(policyType, policy_coverage_type))
  }, [policy_type, policy_coverage_type])

  if (!requestId) {
    return <></>
  }

  const handlePolicyCoverageTypeChange = () => {
    setValue("defendants", DEFAULT_DEFENDANTS_VALUE, { shouldDirty: true })
  }

  return (
    <RequestFormModule title="Policy and Carrier Information">
      <StyledEntityWrapper>
        <StyledEntityRow>
          <InputField
            control={control}
            size="small"
            name="carrier_name"
            data-test="carrier_name"
            type="text"
            label="Carrier Name"
            variant="outlined"
            showCharacterCounter
            InputProps={{ readOnly }}
          />
          <SelectInput
            control={control}
            size="small"
            name="policy_type"
            label="Policy Type"
            options={POLICY_TYPE_OPTIONS}
            readOnly={readOnly}
          />
          <SelectInput
            control={control}
            size="small"
            name="policy_coverage_type"
            label="Policy Coverage Type"
            options={policyCoverageTypeOptions}
            readOnly={readOnly}
            onChange={handlePolicyCoverageTypeChange}
          />
          <CurrencyField
            control={control}
            size="small"
            name="policy_limit"
            data-test="policy_limit"
            type="text"
            label="Policy Limit"
            variant="outlined"
            decimalScale={0}
            InputProps={{ readOnly }}
          />
          <InputField
            control={control}
            size="small"
            name="policy_number"
            data-test="policy_number"
            type="text"
            label="Policy Number"
            variant="outlined"
            InputProps={{ readOnly }}
          />
          <InputField
            control={control}
            size="small"
            name="claim_number"
            data-test="claim_number"
            type="text"
            label="Claim Number"
            variant="outlined"
            InputProps={{ readOnly }}
          />
        </StyledEntityRow>
      </StyledEntityWrapper>

      {showMultiPolicySurveyEnabled && <RequestMultiPolicyDemandsModal />}
    </RequestFormModule>
  )
}
