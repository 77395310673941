import { useMemo } from "react"
import { makeStyles } from "tss-react/mui"
import InputAdornment from "@mui/material/InputAdornment"
import Box from "@mui/material/Box"
import { InputField } from "common/form-components"

import { INPUT_PROPS } from "../constants"
import { StyledGridRow } from "../styled"

const useStyles = makeStyles()(theme => ({
  percentages: {
    gridColumn: "1 / 3",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: theme.spacing(2),
  },
}))

export function PercentagesForm({ control }) {
  const { classes } = useStyles()

  const inputProps = useMemo(() => {
    return {
      type: "number",
      variant: "outlined",
      inputProps: INPUT_PROPS.percentage,
      InputProps: { endAdornment: <InputAdornment position="end">%</InputAdornment> },
      shrinkLabel: true,
    }
  }, [])

  return (
    <StyledGridRow>
      <Box className={classes.percentages}>
        <InputField
          name="salary_growth_rate"
          control={control}
          label="Salary Growth Percentage"
          {...inputProps}
        />
        <InputField
          name="inflation_rate"
          control={control}
          label="Discount Rate Percentage"
          {...inputProps}
        />
        <InputField
          name="pre_judgement_rate"
          control={control}
          label="Pre-judgement Rate Percentage"
          {...inputProps}
        />
      </Box>
    </StyledGridRow>
  )
}
