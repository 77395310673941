import React, { useState } from "react"
import { makeStyles } from "tss-react/mui"
import { ExhibitGroup } from "./Exhibit"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { ExhibitsListGroupForm } from "./ExhibitsListGroupForm"

const useStyles = makeStyles()(theme => ({
  groupTitle: {
    padding: theme.spacing(1),
    fontWeight: "bold",
  },
}))

interface ExhibitsListGroupProps {
  item: ExhibitGroup
  onChange: (item: ExhibitGroup) => void
  onDelete: (item: ExhibitGroup) => void
}

export const ExhibitsListGroup: React.FC<ExhibitsListGroupProps> = ({ item, onChange, onDelete }) => {
  const { classes } = useStyles()
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const handleChange = (item: ExhibitGroup) => {
    onChange(item)
    setIsEditing(false)
  }
  const handleClose = () => setIsEditing(false)

  if (isEditing) {
    return <ExhibitsListGroupForm item={item} onChange={handleChange} onClose={handleClose} />
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent={"space-between"}>
      <Box>
        <span className={classes.groupTitle}>{item.name}</span>
        <IconButton size="medium" onClick={() => setIsEditing(true)}>
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton size="medium" onClick={() => onDelete(item)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  )
}
