import { useEffect } from "react"
import Typography from "@mui/material/Typography"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router"
import { queryKeys } from "../react-query/constants"
import { createCase } from "../api"
import { useHandleMessages } from "../common/messages/useHandleMessages"

export function NewDemand() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { showErrorMessage } = useHandleMessages()

  const { mutateAsync: handleCreateCase } = useMutation(createCase, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.cases])
    },
  })

  useEffect(() => {
    const createCase = async () => {
      const data = await handleCreateCase()
      navigate(`/demands/${data.pk}`, { replace: true })
    }
    createCase().catch(error =>
      showErrorMessage({
        message: "There was an error creating a new demand.",
        error,
      })
    )
  }, [handleCreateCase, navigate, showErrorMessage])

  return (
    <>
      {handleCreateCase.isLoading ? (
        <Typography>Creating New Demand... </Typography>
      ) : (
        <>
          {handleCreateCase.isError ? (
            <Typography>An error has occured. Please reload this page to try again.</Typography>
          ) : null}
        </>
      )}
    </>
  )
}
