import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import useUser from "hooks/useUser"
import { OSF } from "common/models/roles"
import Button from "@mui/material/Button"
import styled from "@emotion/styled"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import { PreviewDemandIcon } from "common/assets"
import IconButton from "@mui/material/IconButton"
import { makeStyles } from "tss-react/mui"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"
import { Document, Page, pdfjs } from "react-pdf"
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api"
import { useQueryClient } from "@tanstack/react-query"
import CircularProgress from "@mui/material/CircularProgress"
import Tooltip from "@mui/material/Tooltip"
import Divider from "@mui/material/Divider"
import { previewDemand, validateDemand } from "../api"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import { queryKeys } from "../react-query/constants"
import { DemandValidation, DemandValidationInfo } from "./DemandValidation"
import "react-pdf/dist/esm/Page/TextLayer.css"
import { hasValidationIssues } from "./utils"
import { RequestContext } from "./context"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const StyledButton = styled(Button)(({ theme }) => {
  return {
    color: "#000000",
    fontSize: "14px",
    backgroundColor: theme.palette.button.grey,
    width: "150px",
    marginTop: theme.spacing(3),
    fontWeight: 600,
  }
})

const useStyles = makeStyles()(theme => ({
  root: {
    "& .MuiDialog-paper": {
      minWidth: "450px",
    },
  },
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  loadingContent: {
    display: "flex",
    alignItems: "center",
  },
  loader: {
    marginRight: theme.spacing(1),
  },
}))

interface DemandPreviewDialogProps {
  open: boolean
  onClose: () => void
  caseId: number
  extendedExhibits: boolean
  request: RequestContext
}

function DemandPreviewDialog({
  open,
  onClose,
  caseId,
  extendedExhibits,
  request,
}: DemandPreviewDialogProps): JSX.Element {
  const { classes } = useStyles()

  const { showErrorMessage } = useHandleMessages()

  const queryClient = useQueryClient()
  const [numPages, setNumPages] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = useCallback(() => {
    setIsLoading(false)
    onClose()
  }, [setIsLoading, onClose])

  const onDocumentLoadSuccess = useCallback(
    (pdfData: PDFDocumentProxy) => {
      setNumPages(pdfData.numPages)
    },
    [setNumPages]
  )

  const [pdfData, setPdfData] = useState<Nullable<Uint8Array>>(null)
  const [validation, setValidation] = useState<Nullable<DemandValidationInfo>>(null)
  const hasValidationInfo =
    validation !== null &&
    (hasValidationIssues(validation.errors) ||
      hasValidationIssues(validation.infos) ||
      hasValidationIssues(validation.warnings))

  useEffect(() => {
    const fetchPreview = async () => {
      setIsLoading(true)

      try {
        const demandValidation = await queryClient.fetchQuery<DemandValidationInfo>(
          [queryKeys.validateDemand, caseId],
          validateDemand,
          { retry: false }
        )

        setValidation(demandValidation)

        if (hasValidationIssues(demandValidation.errors)) {
          setIsLoading(false)
          return
        }
        const response = (await previewDemand(caseId, extendedExhibits)) as Response
        const buffer = await response.arrayBuffer()
        const data = new Uint8Array(buffer)

        setPdfData(data)
        if (request) {
          amplitudeApm.trackEvent(
            new DemandAnalyticEvent(DemandAnalyticsEventTypes.PreviewDemandClicked, {
              demand_id: `${caseId}`,
              request_id: `${request.pk}`,
              request_type: request.type,
            })
          )
        }
      } catch (error) {
        setPdfData(null)
        onClose()
        showErrorMessage({
          message: "Error occurred while generating demand preview",
          error,
        })
      } finally {
        setIsLoading(false)
      }
    }

    if (open) {
      fetchPreview()
    }

    return () => {
      if (open) {
        setPdfData(null)
        setNumPages(0)
        setValidation(null)
      }
    }
  }, [
    open,
    onClose,
    showErrorMessage,
    setIsLoading,
    setPdfData,
    setValidation,
    caseId,
    extendedExhibits,
    queryClient,
    request,
  ])

  const file = useMemo(() => (pdfData ? { data: pdfData } : null), [pdfData])

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="preview-demand-dialog-title"
      open={open}
      maxWidth="md"
      className={classes.root}
    >
      <DialogTitle id="preview-demand-dialog-title" className={classes.title}>
        <Typography variant="h6">Demand Preview</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          data-test="close-demand-preview"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        {hasValidationInfo && <DemandValidation validations={validation} />}
        {isLoading && (
          <Typography
            variant="body1"
            gutterBottom
            className={classes.loadingContent}
            data-test="loading-preview"
          >
            <CircularProgress color="secondary" size="1em" className={classes.loader} />
            Loading preview
          </Typography>
        )}
        {file && (
          <div data-test="demand-preview-wrapper">
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
              {Array(numPages)
                .fill(true)
                .map((_, i) => (
                  <Fragment key={i + 1}>
                    <Page pageNumber={i + 1} key={i + 1} renderAnnotationLayer={false} />
                    <Divider />
                  </Fragment>
                ))}
            </Document>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

interface DemandPreviewProps {
  caseId: number
  extendedExhibits: boolean
  iconButton?: boolean
  request: RequestContext
}

export function DemandPreview({
  caseId,
  extendedExhibits,
  iconButton = false,
  request,
}: DemandPreviewProps): Nullable<JSX.Element> {
  const {
    user: { role },
  } = useUser()
  const [isDialogOpened, setIsDialogOpened] = useState(false)
  const handleClose = useCallback(() => setIsDialogOpened(false), [setIsDialogOpened])
  const handleOpen = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setIsDialogOpened(true)
    },
    [setIsDialogOpened]
  )

  if (role === OSF) return null

  if (iconButton) {
    return (
      <>
        <Tooltip title="Preview Demand" placement="bottom" arrow>
          <IconButton
            onClick={e => {
              handleOpen(e)
            }}
          >
            <PreviewDemandIcon color={"action"} />
          </IconButton>
        </Tooltip>
        <DemandPreviewDialog
          open={isDialogOpened}
          onClose={handleClose}
          caseId={caseId}
          extendedExhibits={extendedExhibits}
          request={request}
        />
      </>
    )
  }

  return (
    <>
      <StyledButton size="small" disabled={isDialogOpened} onClick={handleOpen} data-test="preview-demand">
        Preview Demand
      </StyledButton>
      <DemandPreviewDialog
        open={isDialogOpened}
        onClose={handleClose}
        caseId={caseId}
        extendedExhibits={extendedExhibits}
        request={request}
      />
    </>
  )
}
