import invariant from "invariant"
import { DEFAULT_TIME_SPENDING_INTERVAL, TIME_SPENDING_ANALYTIC, TIME_SPENDING_DB } from "./constants"
import { TimeSpendingEntity, getEntityName } from "./utils"

type TimeSpentDBData = {
  entityName: string
  time: number
}

class TimeSpending {
  private db: Nullable<IDBDatabase>

  constructor() {
    this.db = null

    this.openIndexDB()
  }

  private async getStoreData(entityName: string): Promise<Nullable<TimeSpentDBData>> {
    return new Promise(resolve => {
      if (!this.db) return resolve(null)

      const tx = this.db.transaction(TIME_SPENDING_ANALYTIC, "readonly")
      const store = tx.objectStore(TIME_SPENDING_ANALYTIC)
      const res = store.get(entityName)

      res.onsuccess = () => {
        resolve(res.result || null)
      }

      res.onerror = () => {
        resolve(null)
      }
    })
  }

  private updateData(data: TimeSpentDBData): void {
    if (!this.db) return

    const tx = this.db.transaction(TIME_SPENDING_ANALYTIC, "readwrite")
    const store = tx.objectStore(TIME_SPENDING_ANALYTIC)

    store.put(data)
  }

  private addData(data: TimeSpentDBData): void {
    if (!this.db) return

    const tx = this.db.transaction(TIME_SPENDING_ANALYTIC, "readwrite")
    const store = tx.objectStore(TIME_SPENDING_ANALYTIC)

    store.add(data)
  }

  private async openIndexDB(): Promise<void> {
    return new Promise(resolve => {
      const request = window.indexedDB.open(TIME_SPENDING_DB)

      request.onupgradeneeded = () => {
        this.db = request.result

        if (!this.db.objectStoreNames.contains(TIME_SPENDING_ANALYTIC)) {
          this.db.createObjectStore(TIME_SPENDING_ANALYTIC, { keyPath: "entityName" })
        }
      }

      request.onsuccess = () => {
        this.db = request.result
      }

      resolve()
    })
  }

  private async getCurrentData(entityName: string): Promise<Nullable<TimeSpentDBData>> {
    if (!this.db) {
      await this.openIndexDB()
    }

    const result = await this.getStoreData(entityName)

    return result
  }

  private async setCurrentTime(entityName: string, time: number): Promise<void> {
    const currentData = await this.getCurrentData(entityName)

    if (currentData) {
      this.updateData({ entityName, time: currentData.time + time })

      return
    }

    this.addData({ entityName, time })
  }

  public async addTime(entityName: string, time: number = DEFAULT_TIME_SPENDING_INTERVAL): Promise<void> {
    if (!this.db) {
      await this.openIndexDB()
    }

    this.setCurrentTime(entityName, time)
  }

  public async getTimeSpending(entity: TimeSpendingEntity): Promise<Nullable<number>> {
    const entityName = getEntityName(entity)

    if (!entityName) return null

    const currentTime = await this.getCurrentData(entityName)

    if (!currentTime) return null

    return currentTime.time
  }

  public startCounting(entityName: string, interval: number = DEFAULT_TIME_SPENDING_INTERVAL) {
    invariant(entityName, `TimeSpending: entityName should have at least 1 character`)

    const intervalId = setInterval(() => {
      if (document.hidden) {
        return
      }

      const timeInSeconds = interval / 1000

      this.addTime(entityName, timeInSeconds)
    }, interval)

    return () => clearInterval(intervalId)
  }
}

export const timeSpending = new TimeSpending()
