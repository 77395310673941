import { forwardRef, useCallback, useContext, useRef } from "react"
import { ReorderOperation, SortableNestedList } from "common/nested-list/SortableNestedList"
import { DocumentStructureListItem, DocumentStructureListItemContent } from "./DocumentStructureListItem"
import { canDrag, canDrop, canDropAsChild } from "./utils"
import { StyledListContainer } from "./styled"
import {
  DocumentStructureBlock,
  DocumentStructureComponent,
  DocumentStructureHeading,
  DocumentStructureItem,
  DocumentStructureSection,
  DocumentStructureSubSectionAttrs,
  DocumentStructureTemplate,
} from "./types"
import { DOCUMENT_STRUCTURE_BLOCK_TYPE, DOCUMENT_STRUCTURE_ITEM_TYPE } from "./enums"
import { REF_ID } from "./constants"
import { isNil } from "lodash"
import { DocumentStructureStoreContext } from "./store"

export interface DocumentStructureSectionsProps {
  sections: DocumentStructureSection[]
  onSaveSection: (section: DocumentStructureSection) => Promise<void>
  onDeleteSection: (section: DocumentStructureSection) => Promise<void>
  onSaveSubSection: (section: DocumentStructureItem & DocumentStructureSubSectionAttrs) => Promise<void>
  onSaveHeading: (heading: DocumentStructureHeading, sectionId: PrimaryKey) => Promise<void>
  onSaveTemplate: (template: DocumentStructureTemplate, sectionId: PrimaryKey) => Promise<void>
  onDeleteTemplate: (template: DocumentStructureTemplate, sectionId: PrimaryKey) => Promise<void>
  onDeleteComponent: (template: DocumentStructureComponent, sectionId: PrimaryKey) => Promise<void>
  onSaveComponent: (component: DocumentStructureComponent, sectionId: PrimaryKey) => Promise<void>
  onDeleteHeading: (heading: DocumentStructureHeading, sectionId: PrimaryKey) => Promise<void>
  onReorder: (operation: ReorderOperation<DocumentStructureSection>) => Promise<void>
}

export const DocumentStructureSections = forwardRef<HTMLDivElement, DocumentStructureSectionsProps>(
  function DocumentStructureSections(
    {
      sections,
      onSaveSection,
      onDeleteSection,
      onSaveSubSection,
      onReorder,
      onSaveHeading,
      onDeleteHeading,
      onSaveComponent,
      onDeleteTemplate,
      onDeleteComponent,
      onSaveTemplate,
    },
    ref
  ): JSX.Element {
    const [{ blocks }] = useContext(DocumentStructureStoreContext)
    const blocksRef = useRef(blocks)
    blocksRef.current = blocks

    const handleSave = useCallback(
      async (item: DocumentStructureSection | DocumentStructureBlock, sectionId?: Nullable<PrimaryKey>) => {
        if (item.type === DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION) {
          return await onSaveSection(item)
        }

        if (isNil(sectionId)) return

        if (
          "sectionId" in item &&
          (blocksRef.current[item[REF_ID]] as DocumentStructureSubSectionAttrs).repeat !== item.repeat
        ) {
          return await onSaveSubSection({ ...item, sectionId: sectionId })
        }

        if (item.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.HEADING) {
          return await onSaveHeading(item, sectionId)
        }

        if (item.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.TEMPLATE) {
          return await onSaveTemplate(item, sectionId)
        }

        if (item.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.COMPONENT) {
          return await onSaveComponent(item, sectionId)
        }
      },
      [onSaveSection, onSaveHeading, onSaveTemplate, onSaveComponent, onSaveSubSection]
    )

    const handleDelete = useCallback(
      async (item: DocumentStructureSection | DocumentStructureBlock, sectionId?: Nullable<PrimaryKey>) => {
        if (item.type === DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION) {
          return await onDeleteSection(item)
        }

        if (isNil(sectionId)) return

        if (item.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.HEADING) {
          return await onDeleteHeading(item, sectionId)
        }

        if (item.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.TEMPLATE) {
          return await onDeleteTemplate(item, sectionId)
        }

        if (item.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.COMPONENT) {
          return await onDeleteComponent(item, sectionId)
        }
      },
      [onDeleteSection, onDeleteHeading, onDeleteTemplate, onDeleteComponent]
    )

    const handleReorder = useCallback(
      async (
        items: (DocumentStructureSection | DocumentStructureBlock)[],
        operation: Nullable<ReorderOperation<DocumentStructureSection>>
      ) => {
        if (operation === null) return

        await onReorder(operation)
      },
      [onReorder]
    )

    return (
      <StyledListContainer ref={ref}>
        <SortableNestedList
          items={sections}
          uniqueKey={REF_ID}
          canDrag={canDrag}
          canDrop={canDrop}
          canDropAsChild={canDropAsChild}
          onUpdate={handleReorder}
          ItemComponent={DocumentStructureListItem}
          ItemContentComponent={DocumentStructureListItemContent}
          contentProps={{
            onSave: handleSave,
            onDelete: handleDelete,
          }}
          PlaceholderComponent={() => <></>}
        />
      </StyledListContainer>
    )
  }
)
