import { NodeEntry, Transforms } from "slate"
import { createListItemContent, createParagraph } from "../../create"
import { CustomEditor, InlineContent, ListItemContentElement, ParagraphElement } from "../../CustomEditor"
import { Editor } from "../../Editor"
import { LEAF_BLOCK_ELEMENTS, LIST_ITEM_BLOCK_ELEMENTS } from "../../elements"
import { isBlockNode } from "../../queries"

function normalizeEmpty(editor: CustomEditor, [node, path]: NodeEntry<ListItemContentElement>): boolean {
  if (!node.children.length) {
    Transforms.insertNodes(editor, createParagraph(), { at: [...path, 0] })
    Editor.normalizePath(editor, path)
  }

  return false
}

function normalizeInlineChildren(
  editor: CustomEditor,
  [node, path]: NodeEntry<ListItemContentElement>
): void {
  const nextNode = createListItemContent([])
  let inlineChildren: InlineContent[] = []

  for (const child of node.children) {
    const isInline = Editor.isInline(editor, child) || Editor.isText(editor, child)

    if (isInline) {
      inlineChildren.push(child as InlineContent)
      continue
    }

    nextNode.children.push(createParagraph(inlineChildren))
    nextNode.children.push(child)
    inlineChildren = []
  }

  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, { at: path })
    Transforms.insertNodes(editor, nextNode, { at: path })
  })

  Editor.normalizePath(editor, path)
}

function normalizeBlockChildren(editor: CustomEditor, nodeEntry: NodeEntry<ListItemContentElement>): void {
  const nextNode = createListItemContent([])
  const [, path] = nodeEntry
  const paragraphs = Editor.getBlocks<ParagraphElement>(editor, LEAF_BLOCK_ELEMENTS.PARAGRAPH, {
    anchor: Editor.start(editor, path),
    focus: Editor.end(editor, path),
  }).map(([node]) => node)

  if (!paragraphs.length) {
    nextNode.children.push(createParagraph())
  }
  nextNode.children.push(...paragraphs)

  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, { at: path })
    Transforms.insertNodes(editor, nextNode, { at: path })
  })

  Editor.normalizePath(editor, path)
}

function normalizeChildren(editor: CustomEditor, [node, path]: NodeEntry<ListItemContentElement>): boolean {
  if (node.children.some(node => Editor.isInline(editor, node) || Editor.isText(editor, node))) {
    normalizeInlineChildren(editor, [node, path])
    normalizeListItemContent(editor, Editor.node(editor, path))
    return true
  }

  if (node.children.some(node => node.type !== LEAF_BLOCK_ELEMENTS.PARAGRAPH)) {
    normalizeBlockChildren(editor, [node, path])
    return true
  }

  return false
}

export function normalizeListItemContent(editor: CustomEditor, [node, path]: NodeEntry): boolean {
  if (!isBlockNode(editor, node) || node.type !== LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM_CONTENT) {
    return false
  }

  normalizeEmpty(editor, [node, path])
  return normalizeChildren(editor, [node, path])
}
