import React, { useEffect, useMemo } from "react"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import Button from "@mui/material/Button"
import { SUPPORT_EMAIL } from "./constants"
import { useIssueReporter } from "./issueReporter"
import { parseError } from "./errorUtils"

const BaseGenericError: React.FC<{
  userFriendlyError?: string
  action?: React.ReactNode
}> = ({ userFriendlyError, action }) => {
  return (
    <Alert severity="error" action={action}>
      <AlertTitle>Error</AlertTitle>
      Something went wrong unexpectedly, try refreshing the page. If your error persists please contact{" "}
      <strong>
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
      </strong>
      .
      {userFriendlyError && (
        <>
          <br />
          <strong>Reason: </strong>
          {userFriendlyError}
        </>
      )}
    </Alert>
  )
}

/**
 * Use this component when you need an error message with extra hook capabilities (i.e. issue reporting).
 */
export const GenericError: React.FC<{ error: unknown }> = ({ error }) => {
  const userFriendlyError = useMemo(() => parseError(error)?.userFriendlyMessage, [error])
  const issueReporter = useIssueReporter()
  useEffect(() => {
    issueReporter.updateMetadata({
      summary: userFriendlyError,
      error,
    })
  }, [error, issueReporter, userFriendlyError])
  return (
    <BaseGenericError
      action={
        issueReporter.isAvailable && (
          <Button color="inherit" size="small" onClick={issueReporter.triggerUI}>
            Report issue
          </Button>
        )
      }
      userFriendlyError={userFriendlyError}
    />
  )
}

/**
 * Use this component when you need a barebone error message without any extra hooks (i.e. in a errorFallback of a Suspense).
 */
export const GenericErrorForSuspense: React.FC<{ error?: unknown }> = ({ error }) => {
  const userFriendlyError = useMemo(() => parseError(error)?.userFriendlyMessage, [error])
  return <BaseGenericError userFriendlyError={userFriendlyError} />
}
