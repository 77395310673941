import { LibraryTabState } from "./types"
import { TemplateAction, ActionType } from "./templatesActions"

export const templatesTabReducer = (state: LibraryTabState, action: TemplateAction): LibraryTabState => {
  const { type, payload } = action

  switch (type) {
    case ActionType.EXPAND_TEMPLATE: {
      const updates = { expandedRowIds: [...state.expandedRowIds, payload.templateId] }

      return { ...state, ...updates }
    }

    case ActionType.RENDER_PAGE: {
      return { ...state, page: payload.page }
    }

    case ActionType.COLLAPSE_TEMPLATE: {
      const updates = { expandedRowIds: state.expandedRowIds.filter(id => payload.templateId !== id) }

      return { ...state, ...updates }
    }

    case ActionType.START_EDIT_TEMPLATE: {
      const updates = {
        expandedRowIds: state.expandedRowIds.filter(id => payload.templateId !== id),
        editingRowId: payload.templateId,
      }

      return { ...state, ...updates }
    }

    case ActionType.END_EDIT_TEMPLATE: {
      const updates = { editingRowId: null }

      return { ...state, ...updates }
    }

    case ActionType.START_CREATING_TEMPLATE: {
      const initialTemplateData = payload || {}
      const updates = { creatingData: initialTemplateData }

      return { ...state, ...updates }
    }

    case ActionType.END_CREATING_TEMPLATE: {
      const updates = { creatingData: undefined }

      return { ...state, ...updates }
    }

    default: {
      return state
    }
  }
}
