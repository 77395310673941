import { useEffect } from "react"
import { Box, Button } from "@mui/material"
import { FormActionsContainer } from "./styled"
import { useTheme } from "@emotion/react"
import { useIsMutating } from "@tanstack/react-query"
import { useRequestContext } from "requests/context"
import { useFormContext, useWatch } from "react-hook-form"
import { RequestFields } from "requests/RequestForm/types"
import { useNavigate } from "react-router-dom"
import { ApiError } from "apiHelper"
import { SUPPORT_EMAIL } from "common/constants"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import {
  INTAKE_STATUSES,
  NO_CURRENT_CONTRACT_EXCEPTION,
  NO_CONTRACT_FOUND_EXCEPTION,
} from "requests/constants"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { SavingIndicator } from "requests/SavingIndicator"
import { RequestViewDto } from "requests/ViewRequest/types"
import { useSaveRequestForm } from "requests/RequestForm/Saving/useSaveRequestForm"

const AutoSaveRequestForm = ({
  debouncedSaveForm,
}: {
  debouncedSaveForm: ReturnType<typeof useSaveRequestForm>["debouncedSaveForm"]
}) => {
  // Monitor changes to any field in the form and start a debounced save data call
  const changedValues = useWatch<RequestViewDto>()
  useEffect(() => {
    debouncedSaveForm(changedValues)
  }, [changedValues, debouncedSaveForm])

  return null
}

export const SaveAndSubmitButtons = () => {
  const theme = useTheme()
  const isMutating = useIsMutating() > 0
  const { uploadingFiles, requestId, canEdit } = useRequestContext()
  const { saveRequestForm, debouncedSaveForm } = useSaveRequestForm(canEdit)
  const { handleSubmit, setValue, getValues, resetField } = useFormContext<RequestFields>()
  const navigate = useNavigate()
  const { showErrorMessage, showSuccessMessage } = useHandleMessages()

  const saveAndContinueLater = async () => {
    const values = getValues()
    await saveRequestForm(values)
      .then(() => {
        navigate("/requests")
      })
      .catch(() => {})
  }

  const formSubmission = async (data: RequestFields) => {
    try {
      if (data.intake_status === INTAKE_STATUSES.notRequested) {
        // it's important to save the value in the form state too, otherwise the form will not be dirty
        // and the save logic will not be triggered
        setValue("intake_status", INTAKE_STATUSES.requested, { shouldDirty: true })
        data.intake_status = INTAKE_STATUSES.requested
      }
      await saveRequestForm(data)

      amplitudeApm.trackEvent(
        new RequestAnalyticEvent(RequestAnalyticsEventTypes.RequestSubmitted, {
          credit_total: data.credit_amount ?? 0,
          firm_id: `${data.firm_id}`,
          firm_name: `${data.firm.name}`,
          plaintiff_name: `${data.plaintiffs[0]?.first_name} ${data.plaintiffs[0]?.last_name}`,
          request_id: `${data.pk}`,
          request_type: `${data.type}`,
        })
      )
      navigate(`/requests`)

      showSuccessMessage({
        message: "Request has successfully been submitted",
        timeout: 10000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      })
    } catch (error) {
      // reset intake_status to avoid sending it without pressing the submit button
      resetField("intake_status")

      let error_message: string | JSX.Element = `Unable to submit your request. ${
        error instanceof Error ? `Error: ${error.message}` : ""
      }`

      if (error instanceof ApiError) {
        const contractExceptionTypes = [NO_CURRENT_CONTRACT_EXCEPTION, NO_CONTRACT_FOUND_EXCEPTION]
        if (contractExceptionTypes.includes(error?.validationErrors?.type)) {
          error_message = (
            <Box>
              This firm does not have an active contract and is unable to submit requests at this time. If
              this is a mistake please email <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> and we
              will correct this issue as soon as possible.
            </Box>
          )
        } else {
          error_message = (
            <Box>
              There was an error submitting your request: {error.message}. If your error persists please
              contact <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
            </Box>
          )
        }
      }

      return showErrorMessage({
        message: error_message,
        error,
      })
    }
  }

  // Used for when the user wants to "submit" their request and get the demand started
  const handleRequestSubmission = handleSubmit(formSubmission)

  return (
    <Box display="flex" flexDirection="column" alignItems="end" gap={1.5}>
      <FormActionsContainer>
        <Button
          sx={{
            backgroundColor: theme.palette.common.white,
            "&:hover": { backgroundColor: theme.palette.grey[100] },
          }}
          disabled={!requestId || uploadingFiles || isMutating}
          onClick={saveAndContinueLater}
          variant="outlined"
          color="secondary"
        >
          Save and Continue Later
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleRequestSubmission}
          data-test="submit-button"
          disabled={!requestId || uploadingFiles || isMutating}
        >
          Submit
        </Button>
      </FormActionsContainer>
      <SavingIndicator />
      <AutoSaveRequestForm debouncedSaveForm={debouncedSaveForm} />
    </Box>
  )
}
