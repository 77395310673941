import React from "react"
import Box from "@mui/material/Box"
import { InputField, SelectInput, SwitchInput } from "../../common/form-components"
import { makeStyles } from "tss-react/mui"
import { FirmDemand, FirmDemandDataFields } from "./FirmDemand"
import { FONT_OPTIONS, ALIGNMENT_OPTIONS } from "./constants"
import { FirmData } from "./Firm"
import { Control, FieldErrors, UseFormWatch } from "react-hook-form"
import { ExhibitOrganizationSelectors } from "./ExhibitOrganizationSelectors"

const useStyles = makeStyles()(() => ({
  fullWidth: {
    gridColumn: "1 / 4",
  },
  large: {
    gridColumn: "1 / 3",
  },
}))

export type FirmDetailDataFields = Partial<FirmData> &
  Pick<
    FirmData,
    | "website"
    | "office_phone"
    | "fax_phone"
    | "address"
    | "demand_font"
    | "demand_alignment"
    | "has_collateral_source_rule"
  >

interface FirmDetailFieldsProps {
  control: Control<FirmDetailDataFields>
  disabled: boolean
  errors?: FieldErrors<FirmDetailDataFields>
  watch: UseFormWatch<FirmData>
}

export const FirmDetailFields: React.FC<FirmDetailFieldsProps> = ({ control, disabled, errors, watch }) => {
  const { classes } = useStyles()

  return (
    <>
      <InputField
        control={control}
        name="website"
        type="text"
        label="Website"
        variant="outlined"
        disabled={disabled}
        errors={errors}
      />
      <InputField
        control={control}
        name="office_phone"
        type="text"
        label="Primary phone number"
        variant="outlined"
        disabled={disabled}
        errors={errors}
      />
      <InputField
        control={control}
        name="fax_phone"
        type="text"
        label="Fax number"
        variant="outlined"
        disabled={disabled}
        errors={errors}
      />
      <InputField
        control={control}
        name="address.street"
        type="text"
        label="Firm street address"
        variant="outlined"
        className={classes.fullWidth}
        disabled={disabled}
        errors={errors}
      />
      <InputField
        control={control}
        name="address.street_2"
        type="text"
        label="Street address Line 2"
        variant="outlined"
        className={classes.fullWidth}
        disabled={disabled}
        errors={errors}
      />
      <InputField
        control={control}
        name="address.city"
        type="text"
        label="City"
        variant="outlined"
        disabled={disabled}
        errors={errors}
      />
      <InputField
        control={control}
        name="address.state"
        type="text"
        label="State"
        variant="outlined"
        disabled={disabled}
        errors={errors}
      />
      <InputField
        control={control}
        name="address.zip_code"
        type="text"
        label="Zip code"
        variant="outlined"
        disabled={disabled}
        errors={errors}
      />
      <SwitchInput
        control={control}
        name="has_collateral_source_rule"
        label="Collateral Source Rule"
        disabled={disabled}
        errors={errors}
      />

      <FirmDemand control={control as Control<FirmDemandDataFields>} />
      <SelectInput
        control={control}
        name="demand_font"
        label="Firm-specific font"
        className={classes.large}
        disabled={disabled}
        options={FONT_OPTIONS}
      />
      <SelectInput
        control={control}
        name="demand_alignment"
        label="Firm-specific alignment"
        className={classes.large}
        disabled={disabled}
        options={ALIGNMENT_OPTIONS}
      />

      <Box className={classes.large} fontWeight="bold" fontSize="1.1rem" ml={1}>
        Exhibit Organization Options
      </Box>

      <ExhibitOrganizationSelectors control={control} watch={watch} className={classes.large} />
    </>
  )
}
