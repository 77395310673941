import { amplitudeApm } from "infrastructure/apm/amplitude"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import { RequestViewDto } from "requests/ViewRequest/types"

import { GetState, SetState } from "./types"

export type RequestPanelSlice = {
  panelOpen: boolean
}

export const requestPanelSlice: RequestPanelSlice = {
  panelOpen: false,
}

export const requestPanelSelectors = {
  getRequestPanelOpen: (state: RequestPanelSlice) => state.panelOpen,
}

export const requestPanelActions = (set: SetState<RequestPanelSlice>, get: GetState<RequestPanelSlice>) => {
  const toggleRequestPanel = (caseId: string, request: RequestViewDto) => {
    const currentOpen = requestPanelSelectors.getRequestPanelOpen(get())
    set({ panelOpen: !currentOpen })
    amplitudeApm.trackEvent(
      new RequestAnalyticEvent(RequestAnalyticsEventTypes.OpenedIntakeRequestPanel, {
        demand_id: caseId,
        request_id: request?.pk.toString(),
        request_type: request?.type,
      })
    )
  }
  return {
    toggleRequestPanel,
  }
}
