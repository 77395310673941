import { createRoot } from "react-dom/client"
import { v4 } from "uuid"
import { fullstoryApm } from "infrastructure/apm"

import App from "./app/App"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { sentryApm } from "infrastructure/apm"

amplitudeApm.init()
sentryApm.init()
fullstoryApm.init()

// Unique identifier that helps to address parent-child windows
window.CLIENT_UUID = v4()

// Can also use with React Concurrent Mode
const root = document.getElementById("root")

if (root) {
  createRoot(root).render(<App />)
}
