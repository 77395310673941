import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

import AttorneyForm from "./AttorneyForm"

export function NewAttorney() {
  return (
    <Box>
      <Typography variant="h4" component="h1">
        Create New Attorney
      </Typography>
      <AttorneyForm />
    </Box>
  )
}
