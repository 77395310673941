export enum FONTS {
  ARIAL = "arial",
  CALIBRI = "calibri",
  CENTURY_GOTHIC = "century_gothic",
  CONSTANTIA = "constantia",
  GARAMOND = "garamond",
  TIMES_NEW_ROMAN = "times_new_roman",
  CENTURY_SCHOOLBOOK = "century_schoolbook",
  COPPERPLATE_GOTHIC = "copperplate_gothic",
  BOOK_ANTIQUA = "book_antiqua",
  LUCINDA_GRANDE = "lucinda_grande",
  GOUDY_OLD_STYLE = "goudy_old_style",
  AVENIR = "avenir",
  AVENIR_NEXT = "avenir_next",
  ARIAL_NARROW = "arial_narrow",
  HELVETICA_NEUE = "helvetica_neue",
  ROBOTO = "roboto",
  LIBRE_BASKERVILLE = "libre_baskerville",
  VERDANA = "verdana",
  TAHOMA = "tahoma",
  GEORGIA = "georgia",
}

export enum ALIGNMENT {
  LEFT = "Left",
  JUSTIFIED = "Justified",
  CENTER = "Center",
  RIGHT = "Right",
}

export enum CustomDamageSectionType {
  INCOME = "income",
  HOUSEHOLD = "household",
  PER_DIEM = "per_diem",
  MEDICAL = "medical",
  REL_CASE = "rel_case",
  FUTURE_EXPENSES = "future_expenses",
  CUSTOM = "custom",
}

export enum DamageType {
  ECONOMIC = "economic",
  NON_ECOMONIC = "non-economic",
}

export enum EXHIBIT_SORTING_VALUES {
  PER_PROVIDER_RECORDS_AND_BILLS = "1",
  PER_PROVIDER_BILLS_AND_RECORDS = "2",
  ALL_RECORDS_THEN_BILLS = "3",
  ALL_BILLS_THEN_RECORDS = "4",
}

export enum EXHIBIT_GROUPING_VALUES {
  PER_PROVIDER_AND_FILETYPE = "4",
  PER_PROVIDER = "2",
  ONE_FILE = "3",
  INDIVIDUAL_FILES = "1",
}

export enum STATUS {
  ACTIVE = "active",
  CHURNED = "churned",
  CHURNED_DURING_PILOT = "churned_during_pilot",
  FAILURE_TO_LAUNCH = "failure_to_launch",
}
