import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useHandleMessages } from "./messages/useHandleMessages"

export default function AccessDenied() {
  const { showErrorMessage } = useHandleMessages()

  useEffect(() => {
    showErrorMessage({
      message: "Requested page does not exists or you are trying to access invalid URL",
    })
  }, [showErrorMessage])

  return <Navigate to="/" replace={true} />
}
