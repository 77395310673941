import React, { Children } from "react"
import Box, { BoxProps } from "@mui/material/Box"
import styled from "@emotion/styled"

import { ActionButton } from "../app/styled"

const ButtonContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing("auto", 0, "auto", "auto"),
  display: "flex",
  flexDirection: "row",
  "& > *": {
    margin: theme.spacing(0, 0.25),
  },
}))

interface Props extends BoxProps {
  children: Nullable<JSX.Element>[]
}

const ActionButtonContainer = ({ children, ...otherProps }: Props): JSX.Element => {
  if (!children) return <></>
  const length = Children.count(children)

  let buttons = children
  if (length > 1) {
    const childrenArray = children as JSX.Element[]

    buttons = childrenArray
      .filter(child => !!child)
      .map((child: JSX.Element, index: number): JSX.Element => {
        if (!child) return <></>
        return (
          <React.Fragment key={index}>
            {index !== 0 && <Box>|</Box>}
            {child}
          </React.Fragment>
        )
      })
  }
  return <ButtonContainer {...otherProps}>{buttons}</ButtonContainer>
}

export { ActionButton as default, ActionButtonContainer }
