import { HTMLAttributes, MouseEvent, MouseEventHandler, useCallback, useMemo } from "react"
import { ListItem, ListItemMarker, ListItemName, ListItemNameContainer } from "./styled"
import { Item } from "./type"
import { EMPTY_VALUE_ID } from "./constants"
import { EditableText } from "evenup-ui/EditableText"

const stopPropagation = (e: MouseEvent) => {
  e.stopPropagation()
}

function normalizeName(name: string): string {
  return name.trim()
}

interface OptionItemProps extends HTMLAttributes<HTMLLIElement> {
  option: Item
  edit: boolean
  onEdit: (id: Nullable<Item["id"]>) => void
  onSave: (id: Item["id"], name: Item["name"]) => void
  readonly?: boolean
  options: Item[]
}

export function OptionItem({
  option,
  edit,
  onEdit,
  onSave,
  readonly = false,
  options,
  ...props
}: OptionItemProps): JSX.Element {
  const names = useMemo(() => new Set(options.map(({ name }) => normalizeName(name))), [options])
  const canSave = useCallback((name: string) => Boolean(name && !names.has(normalizeName(name))), [names])

  const handleEdit = useCallback(
    (isEditing: boolean) => {
      onEdit(isEditing ? option.id : null)
    },
    [onEdit, option.id]
  )

  const handleSave = useCallback(
    (value: string) => {
      onSave(option.id, value)
    },
    [onSave, option.id]
  )

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      const clickedButton = event.target instanceof Element && event.target.closest("button")

      if (clickedButton || edit) {
        event.stopPropagation()
      }
    },
    [edit]
  )

  return (
    <ListItem
      {...props}
      aria-readonly={readonly}
      onClick={readonly ? stopPropagation : props.onClick}
      onMouseDown={readonly ? stopPropagation : props.onMouseDown}
    >
      {option.id !== EMPTY_VALUE_ID && <ListItemMarker style={{ backgroundColor: option.color }} />}
      <ListItemNameContainer onClick={handleClick}>
        <EditableText
          value={option.name}
          displayValue={<ListItemName>{option.name}</ListItemName>}
          onSave={handleSave}
          isEditable={!readonly && option.id !== EMPTY_VALUE_ID}
          canSave={canSave}
          onEdit={handleEdit}
        />
      </ListItemNameContainer>
    </ListItem>
  )
}
