import { PropsWithChildren, useEffect } from "react"
import { useDocumentContext } from "./context"
import { useQueryClient } from "@tanstack/react-query"
import { documentActions, resetDocumentData, setDocumentData } from "./store"
import { useSuspenseQuery } from "@suspensive/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { documentsService } from "api/services/documents"

export function DocumentData({ children }: PropsWithChildren): Nullable<JSX.Element> {
  const { documentId } = useDocumentContext()
  const queryClient = useQueryClient()

  const { data } = useSuspenseQuery(
    [queryKeys.documentData, documentId],
    () => documentsService.getDocumentEntities({ documentId }),
    { meta: SILENT_QUERY_PARAMS.meta }
  )

  useEffect(() => {
    documentActions.setDocumentId(documentId)
  }, [documentId])

  useEffect(() => {
    setDocumentData(data)
  }, [data])

  useEffect(() => {
    if (documentId) {
      return () => {
        resetDocumentData()
        requestAnimationFrame(() => {
          queryClient.removeQueries([queryKeys.documentData, documentId])
        })
      }
    }
  }, [queryClient, documentId])

  return <>{children}</>
}
