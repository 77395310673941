import React, { useMemo } from "react"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Tooltip from "@mui/material/Tooltip"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"

import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../../react-query/constants"
import useUser from "../../hooks/useUser"
import { getFirmsCurrentContract } from "../../api"

import { formatDate } from "../../utils"

import { RadialProgress } from "../../common/RadialProgress/RadialProgress"
import HelpOutline from "@mui/icons-material/HelpOutline"
import { roundDown } from "common/math"

const PaperContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3, 3),
  width: "405px",
}))

const OverflowText = styled(Box)({
  fontSize: "14px",
  fontStyle: "italic",
  lineHeight: 1.5,
  textAlign: "center",
})

const SecondaryOverflowText = styled(Box)({
  fontSize: "12px",
  fontStyle: "italic",
  lineHeight: 1.33,
  letterSpacing: "-0.1px",
  textAlign: "center",
})

export const Credits = (): React.ReactElement => {
  const { user, isLoading } = useUser()

  const { data: contract } = useQuery(
    [queryKeys.firmContract],
    async () => {
      return getFirmsCurrentContract({ firmId: user.firmId })
    },
    {
      enabled: !!user.firmId && !isLoading,
    }
  )

  const usedAllCredits = useMemo(() => {
    if (!contract) return false

    return contract.current_month_credits_used >= contract.monthly_credits
  }, [contract])

  if (!contract) {
    return <></>
  }

  const endOfMonth = formatDate(contract.end_of_month, "MM/dd/yyyy", true)

  return (
    <Container data-test="credits-container">
      <Box fontSize="22px" fontWeight="bold" lineHeight={1.55} mb={4}>
        Credits
      </Box>
      <PaperContainer>
        <Box
          display="flex"
          alignItems="center"
          fontSize="20px"
          fontWeight="bold"
          lineHeight={1.7}
          mr={1}
          ml={1}
        >
          Credits Used This Month&nbsp;
          <Tooltip title="Credits shown below do not include rollover credits.">
            <HelpOutline />
          </Tooltip>
        </Box>
        <Box fontSize="16px" display="flex">
          <Box fontWeight="bold" mr={1} ml={1}>
            Next month&apos;s credits added on:
          </Box>
          <Box>{endOfMonth}</Box>
        </Box>
        <Box mt={4} display="flex" mr="auto" ml="auto" mb={4}>
          <RadialProgress
            used={roundDown(contract.current_month_credits_used, { decimalPlaces: 1 })}
            total={roundDown(contract.monthly_credits, { decimalPlaces: 1 })}
          />
        </Box>
        {usedAllCredits && (
          <Box>
            <OverflowText>Your firm has used all available credits for the month!</OverflowText>
            <Box ml={2} mr={2} mt={2}>
              <SecondaryOverflowText>
                You can keep sending requests. Expect the turnaround time to be longer than usual.
              </SecondaryOverflowText>
            </Box>
          </Box>
        )}
      </PaperContainer>
    </Container>
  )
}
