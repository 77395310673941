import { useState } from "react"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import ConfirmDialog from "../../common/ConfirmDialog"
import { INTAKE_STATUSES } from "../constants"

export default function CancelWithConfirmation({ onCancel }) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const confirmAndCancel = () => {
    onCancel(INTAKE_STATUSES.cancelled)
    handleClose()
  }

  return (
    <Box>
      <Button variant="text" color="secondary" onClick={handleClickOpen} data-test="cancel-request-button">
        Cancel request
      </Button>
      <ConfirmDialog
        open={open}
        onClose={handleClose}
        onConfirm={confirmAndCancel}
        cancelText={"I've changed my mind"}
        title={"Cancel this request?"}
        body={
          <>
            This request will be marked as <strong>Cancelled</strong>. No further action will be possible on
            this request.
            <br />
            <br />
            Are you sure you want to continue?
          </>
        }
      />
    </Box>
  )
}
