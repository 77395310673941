import { Box, TextField } from "@mui/material"
import { theme } from "app/theme"
import {
  StyledNonEconomicImpactInputsContainer,
  StyledTypographyDescription,
  StyledTypographyTitle,
} from "../styled"
import { NonEconomicDamages } from "../NonEconomicDamages"
import { usePermissions } from "permissions/usePermissions"
import { NonEconomicSectionProps } from "../types"
import { REQUEST_FORM_FIELDS } from "requests/RequestForm/constants"
import { REQUEST_FORM_VARIANTS } from "../constants"
import { useController } from "react-hook-form"

export const NonEconomicSection = ({ activeTab, variant }: NonEconomicSectionProps) => {
  const { requestRevampSinglePageFormEnabled } = usePermissions()

  const fieldName = `${REQUEST_FORM_FIELDS.PLAINTIFFS}.${activeTab}.impacts_other`

  const { field: additionalNonEconomicImpactsField } = useController({
    name: fieldName,
  })

  return (
    <>
      <Box marginBottom={theme.spacing(4)}>
        <StyledTypographyTitle requestRevampSinglePageFormEnabled={requestRevampSinglePageFormEnabled}>
          Non-Economic Impact
        </StyledTypographyTitle>
        <StyledTypographyDescription requestRevampSinglePageFormEnabled={requestRevampSinglePageFormEnabled}>
          For the non-economic loss components of the claim, please provide the following details:
        </StyledTypographyDescription>
      </Box>

      <StyledNonEconomicImpactInputsContainer>
        <NonEconomicDamages activeTab={activeTab} variant={variant} />
        <TextField
          {...additionalNonEconomicImpactsField}
          sx={{ gridColumn: "1 / -1" }}
          label="Additional Non-Economic impacts"
          multiline
          size={variant === REQUEST_FORM_VARIANTS.SINGLE_PAGE_REQUEST ? "small" : "medium"}
        />
      </StyledNonEconomicImpactInputsContainer>
    </>
  )
}
