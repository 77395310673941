import React, { PropsWithChildren } from "react"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
  hiddenTabContent: {
    height: 0,
    overflow: "hidden",
    visibility: "hidden",
  },
}))

interface TabPanelProps {
  active: boolean
}

export const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = ({ active, children }) => {
  const { classes, cx } = useStyles()

  return <Box className={cx(!active && classes.hiddenTabContent)}>{children}</Box>
}
