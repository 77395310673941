import { useCallback, useEffect, useMemo, useState } from "react"
import { LibraryUseForm } from "settings/Library/TemplateForms/types"
import { useAttributes } from "settings/Library/Filters"
import { NewSectionTemplate } from "common/types/templates"
import { queryClient } from "react-query/queryClient"
import { getAttributeValues } from "common/attributes-filter/utils"
import { useMutation, useQuery } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { variablesService } from "api/services/variables"
import { NonUniqueAttributesError } from "api/services/types"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { sectionTemplateService } from "api/services/section-template"
import { SectionTemplateTableRowData } from "common/models/library"
import { FEATURES, isFeatureEnabled } from "hooks/useFeatures"
import { isNull, isUndefined } from "lodash"
import useUser from "hooks/useUser"
import { userService } from "api/services/users"
import { INTERNAL_ROLES } from "common/models/roles"
import { SelectedAttributeValue } from "common/types/attributes"

import { SectionTemplateFormData as TemplateFormDataType } from "../types"
import { FORM_STATE_STATUS } from "../constants"
import { TemplateFormMessage } from "../TemplateFormMessage"
import { SectionTemplateFormData } from "../formData/SectionTemplateFormData"
import { DialogButton } from "../buttons/DialogButton"
import { canRoleDeleteEntityByFirmId } from "../permissions"
import { StyledFooterWrapper } from "./styled"

export interface SectionTemplateFormProps {
  canDelete?: boolean
}

const mapAttribute = (attribute: SelectedAttributeValue) => [attribute.id, attribute.valueId]

export const useSectionTemplateForm: LibraryUseForm<
  SectionTemplateTableRowData,
  SectionTemplateFormProps
> = ({ onFinish, rowData, formStatusMessageMap, canDelete = false }) => {
  const [formStatus, setFormStatus] = useState<FORM_STATE_STATUS>(FORM_STATE_STATUS.IDLE)
  const attributes = useAttributes()
  const [errorInSection, setErrorInSection] = useState<boolean>(false)
  const [errorInFirm, setErrorInFirm] = useState<boolean>(false)
  const [formData, setFormData] = useState<Nullable<TemplateFormDataType>>(null)

  const { data: variables } = useQuery(
    [queryKeys.defaultVariables],
    variablesService.getDefaultCaseVariables,
    SILENT_QUERY_PARAMS
  )
  const { user } = useUser()
  const shouldCheckFirm =
    isFeatureEnabled(FEATURES.FIRM_TEMPLATE) &&
    (user.role === INTERNAL_ROLES.LEGALOPS || user.role === INTERNAL_ROLES.LEGALOPS_MANAGER)
  const { data: firmsUserManage } = useQuery(
    [queryKeys.firmsUserManage, user.id],
    () => userService.getFirmsUserManage(null),
    {
      enabled: user.isInternal,
    }
  )
  const havePermissionToDeleteTemplate = canRoleDeleteEntityByFirmId(
    user.role,
    rowData.initialFirmId,
    firmsUserManage
  )

  const initialAttributeValues = useMemo(() => {
    if (!rowData.initialAttributes || !attributes) return undefined

    return getAttributeValues(attributes, Object.fromEntries(rowData.initialAttributes.map(mapAttribute)))
  }, [attributes, rowData])

  const onSuccess = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.SUCCESS)
  }, [])

  const onError = useCallback((error: unknown) => {
    if (error instanceof NonUniqueAttributesError) {
      setFormStatus(FORM_STATE_STATUS.DATA_ERROR)
    } else {
      setFormStatus(FORM_STATE_STATUS.API_ERROR)
    }
  }, [])

  const mutationOptions = useMemo(
    () => ({
      onSuccess: () => {
        onSuccess()
        onFinish()

        queryClient.invalidateQueries([queryKeys.sectionTemplates])
        queryClient.invalidateQueries([queryKeys.sectionTemplatesByFirm])
      },
      onError: (error: unknown) => onError(error),
    }),
    [onSuccess, onFinish, onError]
  )

  const createTemplateMutation = useMutation(sectionTemplateService.createTemplate, mutationOptions)
  const updateTemplateMutation = useMutation(sectionTemplateService.updateTemplate, mutationOptions)
  const deleteTemplateMutation = useMutation(sectionTemplateService.deleteTemplate, {
    onSuccess: () => {
      onFinish()
      queryClient.invalidateQueries([queryKeys.sectionTemplates])
      queryClient.invalidateQueries([queryKeys.sectionTemplatesByFirm])
    },
    onError: (error: unknown) => onError(error),
  })

  const handleFormDataChange = useCallback(
    (data: TemplateFormDataType) => {
      setFormData(data)

      if (data.sectionWithAttributes.section !== null) {
        setErrorInSection(false)
      }
    },
    [setFormData, setErrorInSection]
  )

  const clearForm = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.IDLE)
    setErrorInSection(false)
    setErrorInFirm(false)
  }, [])

  const handleCancel = useCallback(() => {
    onFinish()
    clearForm()
  }, [onFinish, clearForm])

  const handleDeleteTemplate = useCallback(() => {
    if (isUndefined(rowData.id)) return

    deleteTemplateMutation.mutate({ options: { templateId: rowData.id } })
  }, [deleteTemplateMutation, rowData.id])

  const handleSubmit = useCallback(() => {
    if (!formData) return

    if (shouldCheckFirm && isNull(formData.firmId)) {
      return setErrorInFirm(true)
    }

    if (!formData.sectionWithAttributes.section) {
      return setErrorInSection(true)
    }

    const data: NewSectionTemplate = {
      content: formData.content,
      section: formData.sectionWithAttributes.section,
      attributes: formData.sectionWithAttributes.attributeValues,
      firmId: formData.firmId,
    }

    if (typeof rowData.id === "undefined") {
      createTemplateMutation.mutate({ data })
    } else {
      updateTemplateMutation.mutate({ options: { templateId: rowData.id }, data })
    }
  }, [
    createTemplateMutation,
    updateTemplateMutation,
    formData,
    setErrorInSection,
    rowData.id,
    shouldCheckFirm,
  ])

  const message = useMemo(() => {
    if (formStatus === FORM_STATE_STATUS.IDLE) return null

    const messageText = formStatusMessageMap[formStatus]

    if (!messageText) return null

    return <TemplateFormMessage clear={clearForm} message={messageText} formStatus={formStatus} />
  }, [clearForm, formStatus, formStatusMessageMap])

  const viewForm = useMemo(() => {
    return <RichTextEditor readonly value={rowData.initialContent} />
  }, [rowData])

  const editForm = useCallback(
    (footer: Nullable<JSX.Element>) =>
      attributes ? (
        <>
          <SectionTemplateFormData
            attributes={attributes}
            initialAttributeValues={initialAttributeValues}
            initialContent={rowData.initialContent}
            initialSection={rowData.initialSection}
            initialFirmId={rowData.initialFirmId}
            freezeFirmId={rowData.freezeFirmId}
            onChange={handleFormDataChange}
            error={formStatus === FORM_STATE_STATUS.DATA_ERROR}
            errorSection={errorInSection}
            errorInFirm={errorInFirm}
            variables={variables}
          />
          <StyledFooterWrapper>
            {canDelete && havePermissionToDeleteTemplate && (
              <DialogButton
                title="Delete?"
                buttonText="Delete template"
                confirmationText="Yes, Delete"
                question={
                  <span>
                    Are you sure you want to delete the
                    <br />
                    <strong>template?</strong>
                  </span>
                }
                onAction={handleDeleteTemplate}
              />
            )}
            {footer}
          </StyledFooterWrapper>
        </>
      ) : null,
    [
      attributes,
      initialAttributeValues,
      rowData.initialContent,
      rowData.initialSection,
      rowData.initialFirmId,
      rowData.freezeFirmId,
      handleFormDataChange,
      formStatus,
      errorInSection,
      errorInFirm,
      variables,
      canDelete,
      havePermissionToDeleteTemplate,
      handleDeleteTemplate,
    ]
  )

  const errorForm = formStatus === FORM_STATE_STATUS.API_ERROR || formStatus === FORM_STATE_STATUS.DATA_ERROR

  useEffect(() => {
    clearForm()
  }, [formData, clearForm])

  const isLoading = createTemplateMutation.isLoading || updateTemplateMutation.isLoading

  return { message, editForm, handleSubmit, handleCancel, isLoading, viewForm, clearForm, errorForm }
}
