import { isEqual } from "lodash"
import { getNonEmptyAttributeFiltersData, getFiltersData } from "common/attributes-filter/utils"
import { DocStructureFormData } from "../useDocumentStructureForm"
import { DocumentStructureRowData } from "common/models/library"

export function isDocumentStructureChangedForAutosave(
  currentData: DocStructureFormData,
  newData: DocStructureFormData
) {
  // data is not specified yet
  if (currentData === null || newData === null) return false

  if (currentData.firmId !== newData.firmId) return true

  if (currentData.documentName !== newData.documentName) return true

  return !isEqual(
    getNonEmptyAttributeFiltersData(currentData.sectionWithAttributes?.attributeValues),
    getNonEmptyAttributeFiltersData(newData.sectionWithAttributes?.attributeValues)
  )
}

export function isDocumentStructureChangedForConfirmationDialog(
  formData: DocStructureFormData,
  rowData: DocumentStructureRowData
) {
  if (!formData) return false

  if (formData.firmId !== rowData.initialFirmId) return true

  return !isEqual(
    getNonEmptyAttributeFiltersData(formData.sectionWithAttributes?.attributeValues),
    getFiltersData(rowData.initialAttributes)
  )
}
