import { createEditor } from "slate"
import { EditorRoot } from "./CustomEditor"
import { DEFAULT_VALUE } from "./defaultValue"
import { withDefaults } from "./features/withDefaults"
import { withLists } from "./features/withLists"

type EditorContentChildrenPropValue<TRecursive extends boolean> =
  | EditorRoot<TRecursive>
  | { children: EditorRoot<TRecursive> }
type EditorContentChildrenProp = EditorContentChildrenPropValue<true> | EditorContentChildrenPropValue<false>

export class EditorContent {
  public children: EditorRoot<false>
  constructor(children: EditorContentChildrenProp) {
    this.children = this.#getChildren(children)
  }

  #getChildren(children: EditorContentChildrenProp): EditorRoot<false> {
    if (!Array.isArray(children)) {
      return (children.children ?? DEFAULT_VALUE) as EditorRoot<false>
    }

    return children as EditorRoot<false>
  }

  getNormalizedChildren(): EditorRoot<true>
  getNormalizedChildren(): EditorRoot<false>
  getNormalizedChildren(): EditorRoot<true> | EditorRoot<false> {
    const editor = withLists(withDefaults(createEditor()))
    editor.children = this.children as EditorRoot<true>
    editor.normalize({ force: true })

    this.children = editor.children as EditorRoot<false>
    return this.children
  }
}
