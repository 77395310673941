import React, { useCallback, useMemo } from "react"
import TextField from "@mui/material/TextField"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import { AttributeFiltersData } from "common/attributes-filter"
import { AttributeValueOptionKey, NONE_VALUE, getAttributeValueOptions } from "common/attributes-filter/utils"
import { RootAttributeTreeItem, AttributeTreeItem, AttributeValue } from "common/types/attributes"
import { AttributeField, AttributeTitle } from "./styled"
import { ATTRIBUTES_HELPER_TEXT_MAP } from "common/attributes-filter/constants"
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete"
import { DISABLED_ATTRIBUTE_TITLES } from "demand/Templates/constants"
interface TemplateAttributeProps {
  optional?: boolean
  isLoading?: boolean
  attribute: RootAttributeTreeItem | AttributeTreeItem
  onChange: (values: AttributeFiltersData) => void
}

export function TemplateAttribute({
  attribute,
  onChange,
  isLoading,
  optional = false,
}: TemplateAttributeProps): JSX.Element {
  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, value: Nullable<ValueOption<AttributeValueOptionKey>>) => {
      onChange({
        [attribute.attribute.id]:
          !value || value?.key === NONE_VALUE ? null : (value.key as AttributeValue["id"]),
      })
    },
    [onChange, attribute]
  )
  const valueOptions = getAttributeValueOptions(attribute.attribute)
  const helperText = ATTRIBUTES_HELPER_TEXT_MAP[attribute.attribute.title]
  const value = useMemo(
    () => valueOptions.options.find(({ key }) => (attribute.value?.id ?? NONE_VALUE) === key),
    [attribute.value?.id, valueOptions.options]
  )
  const isDisabled = DISABLED_ATTRIBUTE_TITLES.includes(attribute.attribute.title)

  const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
    return <TextField {...params} variant="outlined" />
  }, [])

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, data: ValueOption<AttributeValueOptionKey>) => {
      return (
        <li {...props}>
          <span data-test={data.display}>{data.display}</span>
        </li>
      )
    },
    []
  )

  const getOptionLabel = useCallback((option: ValueOption<AttributeValueOptionKey>) => option.display, [])

  const childrenRender = useCallback(
    (childAttribute: AttributeTreeItem) => {
      return (
        <TemplateAttribute
          key={childAttribute.attribute.id}
          attribute={childAttribute}
          onChange={onChange}
          optional
        />
      )
    },
    [onChange]
  )

  return (
    <>
      <AttributeField>
        <AttributeTitle>
          {attribute.attribute.title}
          {optional ? " (optional)" : null}
        </AttributeTitle>
        <FormControl size="small" variant="standard">
          <Autocomplete
            loading={isLoading}
            size="small"
            blurOnSelect
            style={{ minWidth: 210 }}
            value={value}
            data-test={attribute.attribute.title}
            options={valueOptions.options}
            renderInput={renderInput}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            onChange={handleChange}
            disabled={isDisabled}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </AttributeField>
      {"children" in attribute ? attribute.children.map(childrenRender) : null}
    </>
  )
}
