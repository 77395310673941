import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { ViewRequestRevampProps } from "../types"
import { StyledInformationWrapper } from "../styled"
import { InfoContainer } from "./InfoContainer"
import { Typography } from "@mui/material"
import { RECIPIENT_TYPE_LABELS } from "settings/Firm/constants"

export const RecipientInformation = ({ request }: ViewRequestRevampProps) => {
  return (
    <RequestFormModule title="Recipient Information">
      <StyledInformationWrapper>
        <InfoContainer field="Recipient Type" value={RECIPIENT_TYPE_LABELS[request.recipient_type]} />
        <InfoContainer field="First Name" value={request.adjuster_first_name} />
        <InfoContainer field="Last Name" value={request.adjuster_last_name} />
        <InfoContainer field="Email" value={request.adjuster_email} />
        <InfoContainer
          field="Address"
          value={
            request.adjuster_address && (
              <>
                <Typography> {request.adjuster_address.street} </Typography>
                <Typography> {request.adjuster_address.street_2}</Typography>
                <Typography>
                  {request.adjuster_address.city}
                  {request.adjuster_address.city && ", "}
                </Typography>
                <Typography>
                  {request.adjuster_address.state} {request.adjuster_address.zip_code}
                </Typography>
              </>
            )
          }
        />
        <InfoContainer field="Fax Number" value={request.adjuster_fax} />
      </StyledInformationWrapper>
    </RequestFormModule>
  )
}
