import { useRef } from "react"

import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { Box, CircularProgress } from "@mui/material"

const AutoCompleteFilter = ({
  value,
  onChange,
  options,
  label = "Autocomplete",
  className = null,
  id = null,
  disabled = false,
  loading = false,
}) => {
  const labelRef = useRef()

  return (
    <Autocomplete
      disabled={disabled}
      loading={loading}
      className={className}
      onChange={(e, newValue) => {
        e.target.value = newValue?.key ?? 0
        onChange(e)
      }}
      isOptionEqualToValue={(option, value) => !!option && !!value && option.key === value.key}
      getOptionLabel={option => `${option?.display}` || ""}
      renderOption={(props, option) => (
        <li {...props}>
          <span data-test="filter-value">{option?.display || ""}</span>
        </li>
      )}
      options={options}
      value={value}
      disableClearable={value?.key === 0}
      renderInput={params => {
        return (
          <TextField
            {...params}
            ref={labelRef}
            label={label}
            variant="outlined"
            fullWidth={false}
            InputProps={{
              ...params.InputProps,
              className: className,
              endAdornment: (
                <>
                  {loading ? (
                    <Box sx={{ display: "inline-flex" }}>
                      <CircularProgress color="inherit" size={20} />
                    </Box>
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              fullWidth: false,
            }}
            data-test={id}
          />
        )
      }}
    />
  )
}

export { AutoCompleteFilter as default }
