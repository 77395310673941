import { noop } from "lodash"
import { getServiceWorker } from "./getServiceWorker"
import { getWorkbox } from "./workbox"
import { CLAIM_SERVICE_WORKER_TOPIC } from "./topics"

export function initMessageBroker(): void {
  getWorkbox()
    .register({ immediate: true })
    .then(() => {
      getWorkbox()
        .update()
        .catch(error => {
          // eslint-disable-next-line no-console
          console.warn("Service Worker registration failed:", error)
        })
    })
    .then(async () => {
      const serviceWorker = await getServiceWorker()

      if (serviceWorker && !navigator.serviceWorker.controller) {
        serviceWorker.postMessage({ topic: CLAIM_SERVICE_WORKER_TOPIC })
      }
    })
    .catch(noop)
}
