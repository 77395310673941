import { v4 } from "uuid"

export const EMPTY_VALUE_ID = `null_value_${v4()}` as const
export const EMPTY_VALUE_COLOR = "transparent"
export const EMPTY_VALUE_DEFAULT_NAME = "None"

export type EmptyValue = {
  id: typeof EMPTY_VALUE_ID
  name: string
  color: typeof EMPTY_VALUE_COLOR
}
