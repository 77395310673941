import styled from "@emotion/styled"
import { Box, Button } from "@mui/material"
import { memo } from "react"

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}))

export const EditorHeader = memo(function Header({
  isReviewNeeded,
  handleResolve,
}: {
  isReviewNeeded: boolean
  handleResolve: () => void
}) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box fontSize="13px" fontWeight="600">
        Injury details
      </Box>
      <ActionsContainer>
        {isReviewNeeded ? (
          <Button variant="contained" disableElevation size="small" onClick={handleResolve}>
            Resolve
          </Button>
        ) : null}
      </ActionsContainer>
    </Box>
  )
})
