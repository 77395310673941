import React from "react"
import { Box, Paper } from "@mui/material"

export interface ListPaperProps extends React.HTMLAttributes<HTMLElement> {
  createButton?: React.ReactNode
}

export function ListPaper({ children, createButton, ...props }: ListPaperProps) {
  return (
    <Paper {...props}>
      {children}
      {createButton && <Box pt={1}>{createButton}</Box>}
    </Paper>
  )
}
