import { PropsWithChildren, ReactNode } from "react"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import { documentStoreSelectors, useDocumentStore } from "documents/store"
import { StyledAppointmentTagLinksList } from "./styled"
import { getSummaryItem } from "../utils"
import { isBefore, isAfter } from "date-fns"
import { formatDate } from "utils"
import { AppointmentTagLink } from "./AppointmentTagLink"
import { theme } from "app/theme"

export const AppointmentTagLinks = ({ children }: PropsWithChildren): ReactNode => {
  const appointments = useDocumentStore(state => state.appointments)
  const appointmentIds: string[] = useDocumentStore(documentStoreSelectors.getSummaryItemKeys())
    .filter(key => {
      const [type] = getSummaryItem(key)
      return type === "appointment"
    })
    .map(apptKey => {
      const [, , appointmentId] = getSummaryItem(apptKey)
      return appointmentId as string
    })

  const dateRange = appointmentIds.reduce<[string, string]>(
    (acc, appt, i) => {
      const appointment = appointments[appt]
      if (i === 0) {
        return [appointment.dateOfService, appointment.dateOfService]
      }

      if (isBefore(new Date(appointment.dateOfService), new Date(acc[0]))) {
        acc[0] = appointment.dateOfService
      }

      if (isAfter(new Date(appointment.dateOfService), new Date(acc[1]))) {
        acc[1] = appointment.dateOfService
      }

      return acc
    },
    ["", ""]
  )

  return (
    <>
      <Typography variant="body1SemiBold" component="p" mb={2.5} mt={2}>
        Medical Summaries:
      </Typography>
      {dateRange[0] && dateRange[1] ? (
        <Typography component="p" variant="listSubHeader" mb={2.5}>
          {formatDate(dateRange[0], "LLL d, y")} - {formatDate(dateRange[1], "LLL d, y")} (
          {appointmentIds.length})
        </Typography>
      ) : null}
      <StyledAppointmentTagLinksList component="ul" gap={1.75}>
        {appointmentIds.map(apptId => {
          const appointment = appointments[apptId]
          return <AppointmentTagLink key={apptId} appointment={appointment} />
        })}
      </StyledAppointmentTagLinksList>
      <Divider sx={{ marginBottom: theme.spacing(2) }} />
      {children}
    </>
  )
}
