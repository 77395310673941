import * as Sentry from "@sentry/browser"
import { User } from "common/models/user"
import { BaseInfrastructureService } from "./types"
import { getEnvironment, isControlledEnvironment } from "infrastructure/env/getEnvironment"
import { APP_VERSION } from "app/constants"
import { permissionService } from "api/services/permissions"

function init(): void {
  if (isControlledEnvironment()) {
    return
  }

  // only send stuff off to sentry for these environments
  // add "dev" here if you need to test sentry specific integrations
  // be aware, errors and transactions == $$$
  const tracedEnvironments = ["stage", "prod"]

  // These are the URL's in which sentry traces and baggage information will be sent to
  let propTargets = []
  switch (getEnvironment()) {
    case "prod":
      propTargets = ["https://evenup.law/", "https://lops-api.evenup.law/"]
      break
    case "stage":
      propTargets = ["https://lops.stage.evenup.law/", "https://lops-api.stage.evenup.law/"]
      break
    default:
      // "dev" and unknown, which is probably dev too
      propTargets = ["http://localhost", "http://localhost:8000", "http://localhost:3000"]
      break
  }

  const telemetryDisabled = permissionService.getCachedPermissions()?.disableTelemetrySentryEnabled ?? false

  Sentry.init({
    dsn: "https://994355b2d4695329b9c842181acbdb30@o530955.ingest.sentry.io/4506236355084288",
    release: APP_VERSION ?? "dev",

    environment: getEnvironment(),
    normalizeDepth: 10,
    integrations: telemetryDisabled ? [] : [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

    // Capture all traces for performance monitoring
    tracesSampleRate: telemetryDisabled ? 0.0 : 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: propTargets,

    replaysSessionSampleRate: 0.0, // No sampled replays
    replaysOnErrorSampleRate: telemetryDisabled ? 0.0 : 1.0, // Capture replays for all errors

    // event (errors) filtering
    beforeSend(event) {
      // filter out uninstrumented environments
      const env = getEnvironment()
      if (tracedEnvironments.indexOf(env) === -1) {
        return null
      }
      return event
    },

    // transaction (performance) filtering
    beforeSendTransaction(tx) {
      // filter out uninstrumented environments
      const env = getEnvironment()
      if (tracedEnvironments.indexOf(env) === -1) {
        return null
      }

      // Ignore user agents containing SyntheticCheck
      if (tx.request?.headers) {
        const userAgent = tx.request.headers["User-Agent"] || ""
        if (userAgent.indexOf("SyntheticCheck") !== -1) {
          return null
        }
      }

      // Otherwise, record...
      return tx
    },
  })
}

function setUserContext(user: User): void {
  try {
    Sentry.setUser({
      id: user.id,
      username: `${user.firstName} ${user.lastName}`.trim(),
      email: user.email,
    })
    Sentry.setContext("user-role", { role: user.role })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

function resetUserContext(): void {
  try {
    Sentry.setUser(null)
    Sentry.setContext("user-role", {})
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

export const configureApm: BaseInfrastructureService = {
  init,
  setUserContext,
  resetUserContext,
}
