import { useContext, useMemo } from "react"
import { DATA_FLAG_ID } from "documents/flags/types"
import { FlaggedText } from "../../CustomEditor"
import { FlagsContext } from "../../features/flags/FlagsContext"

type DataAttrs = { [x: `data-${string}`]: string }

export function useFlagAttributes(node: FlaggedText): DataAttrs | undefined {
  const { flags } = useContext(FlagsContext)
  const attrs = useMemo<DataAttrs | undefined>(() => {
    if (!node.flags?.length || !flags.length) return

    const nodeFlags = node.flags.filter(flagId => flags.includes(flagId))

    if (!nodeFlags.length) return

    return {
      "data-highlight-flag": String(nodeFlags.length),
      [DATA_FLAG_ID]: nodeFlags.join(","),
    }
  }, [node.flags, flags])

  return attrs
}
