import { BaseAnalyticsEvent } from "../types"

export enum UserAnalyticsEventTypes {
  UserAdded = "User Added",
}

export class UserAnalyticEvent<T extends UserAnalyticsEventTypes> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: UserAnalyticsEventDetails[T]
  ) {
    super()
  }
}

// Add new request event types here
export type UserAnalyticsEventDetails = {
  [UserAnalyticsEventTypes.UserAdded]: {
    firm_id: number
    firm_name: string
  }
}
