import { useCallback, useMemo } from "react"
import { useController, useFormContext, useWatch } from "react-hook-form"
import { StateSelect } from "common"
import { DateField, SelectInput } from "common/form-components"
import { StyledEntityWrapper, StyledEntityRow } from "./styled"
import { RequestFormModule } from "./RequestFormModule"
import { DOCUMENT_TYPES } from "requests/enums"
import { useRequestContext } from "requests/context"
import { CASE_TYPES } from "requests/constants"
import { CaseInformationProps, RequestFormModuleProps } from "../types"
import { createDateInPastValidator, requiredField } from "common/form-components/formValidationRules"
import { RequestFields } from "requests/RequestForm/types"

export const getCaseTypeOptions = (demandType: DOCUMENT_TYPES) => {
  return Object.values(CASE_TYPES).filter(
    ({ demandTypes, active }) => active && demandTypes.some(type => type === demandType)
  )
}

const dateValidator = createDateInPastValidator()

export const CaseInformation = ({
  readOnly,
  includeCaseType = true,
  includeStateOfIncident = true,
}: RequestFormModuleProps & CaseInformationProps) => {
  const { requestId } = useRequestContext()
  const { control } = useFormContext()

  const demandType = useWatch<RequestFields, "type">({ name: "type", defaultValue: DOCUMENT_TYPES.STANDARD })
  const dateOfIncident = useWatch({ name: "date_of_incident" })

  const { field: dateOfIncidentField, fieldState: dateOfIncidentFieldState } = useController({
    name: "date_of_incident",
    rules: { ...requiredField(), validate: { dateValidator } },
  })

  const handleChangeDateOfIncident = useCallback(
    (payload: Nullable<string>) => {
      dateOfIncidentField.onChange(payload ?? "")
    },
    [dateOfIncidentField]
  )

  const options = useMemo(() => getCaseTypeOptions(demandType), [demandType])

  if (!requestId) {
    return <></>
  }

  return (
    <RequestFormModule title="Case Information">
      <StyledEntityWrapper>
        <StyledEntityRow>
          {includeCaseType && (
            <SelectInput
              name="case_type"
              data-test="case-type"
              options={options}
              label="Case Type"
              control={control}
              readOnly={readOnly}
              size="small"
              required
              rules={{ ...requiredField() }}
            />
          )}
          <DateField
            {...dateOfIncidentField}
            initialValue={dateOfIncident}
            onChange={handleChangeDateOfIncident}
            label="Date of Incident"
            data-test="date-of-incident"
            required
            fieldProps={{ name: "date_of_incident", size: "small" }}
            error={!!dateOfIncidentFieldState?.error}
            onClickOpen
            helperText={dateOfIncidentFieldState?.error?.message}
            inputRef={dateOfIncidentField.ref}
          />
          {includeStateOfIncident && (
            <StateSelect
              control={control}
              selectName={`state_of_incident`}
              label="State of Incident"
              required={false}
              errors={undefined}
              readOnly={readOnly}
              size="small"
            />
          )}
        </StyledEntityRow>
      </StyledEntityWrapper>
    </RequestFormModule>
  )
}
