import styled from "@emotion/styled"
import { Link } from "react-router-dom"

import AddIcon from "@mui/icons-material/Add"
import { Button, ButtonProps } from "@mui/material"

const AddButton = styled(Button)(({ theme }) => ({
  background: theme.palette.blue.primary,
  textTransform: "none",
  fontSize: "15px",
}))

type AddButtonProps = {
  linkTo: string
  buttonText: string
} & ButtonProps

export const SettingsPageAddButton = ({ linkTo, buttonText, ...props }: AddButtonProps) => {
  return (
    <Link to={linkTo}>
      <AddButton variant="contained" startIcon={<AddIcon />} {...props}>
        {buttonText}
      </AddButton>
    </Link>
  )
}
