import { useCallback } from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Autocomplete from "@mui/material/Autocomplete"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(theme => ({
  container: {
    minWidth: theme.spacing(25),
    maxWidth: theme.spacing(100),
  },
}))

const MultiAutocompleteSelector = ({
  options,
  value,
  onChange,
  label,
  dataTest,
  variant = "standard",
  loading = false,
  multiple = true,
}) => {
  const { classes } = useStyles()

  const handleRenderInput = params => {
    return <TextField {...params} variant={variant} label={label} />
  }

  const getOptionLabel = useCallback(option => option.label, [])

  return (
    <Box className={classes.container}>
      <Autocomplete
        multiple={multiple}
        options={options}
        value={value}
        renderInput={handleRenderInput}
        getOptionLabel={getOptionLabel}
        data-test={dataTest}
        data-test-id={dataTest}
        onChange={(event, newValues, reason) => onChange({ event, newValues, reason })}
        loading={loading}
        loadingText="Loading..."
        disabled={loading}
      />
    </Box>
  )
}

export { MultiAutocompleteSelector as default }
