import { Container, ContainerProps } from "@mui/material"
import { MarkElement, MarkElementProps } from "@mui/x-charts"

export function ExecAnalyticsPageContainer(props: ContainerProps) {
  return <Container {...props} />
}

export function FilledMarkElement(props: MarkElementProps) {
  return <MarkElement {...props} sx={{ fill: props.color }} />
}
