import { noop } from "lodash"
import { createContext } from "react"

export interface SectionContextType {
  sectionId: Nullable<PrimaryKey>
  repeat: boolean
  expanded: boolean
  toggleExpanded: () => void
}

export const SectionContext = createContext<SectionContextType>({
  repeat: false,
  sectionId: null,
  expanded: false,
  toggleExpanded: noop,
})
