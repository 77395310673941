import Tooltip from "@mui/material/Tooltip"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"

import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded"

interface CommonLabelProps {
  includeAnnotationUpdated: boolean
  includeDeviation: boolean
  includeAnnotationExtractUpdated?: boolean
  dateUpdated?: string
}

interface AnnotationUpdatedLabelProps {
  includeAnnotationUpdated: true
  dateUpdated: string
  includeDeviation: boolean
}

interface AnnotationExtractUpdatedLabelProps {
  includeAnnotationUpdated?: never
  includeDeviation?: never
  dateUpdated?: never
  includeAnnotationExtractUpdated: boolean
}

type ContainerLabelProps = CommonLabelProps | AnnotationUpdatedLabelProps | AnnotationExtractUpdatedLabelProps

const ContainerLabel = (props: ContainerLabelProps): JSX.Element => {
  if (props.includeAnnotationUpdated) {
    return <AnnotationUpdatedContainer dateUpdated={props.dateUpdated} />
  }

  if (props.includeAnnotationExtractUpdated) {
    return <AnnotationExtractUpdatedContainer />
  }

  if (props.includeDeviation) {
    return <DeviationContainer />
  }

  return <></>
}

export const Label = styled(Box)({
  fontWeight: 500,
  fontSize: "11px",
  lineHeight: 1.27,
  letterSpacing: "0.2px",
  textAlign: "right",
})

export const DeviationNotice = styled(Label)({
  color: "#0039ce",
  fontStyle: "italic",
})

export const AnnotationUpdatedNotice = styled(Label)({
  color: "#d50000",
  fontWeight: "bold",
})

export const LabelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
  "& > *": {
    marginLeft: theme.spacing(0.5),
  },
  marginBottom: theme.spacing(0.25),
}))

export const DeviationContainer = (): JSX.Element => {
  return (
    <LabelContainer>
      <DeviationNotice>Deviation from Annotation</DeviationNotice>
      <Tooltip
        title="Input doesn't match annotation. Please review. Disregard if annotation was inaccurate."
        placement="bottom"
      >
        <HelpOutlineRoundedIcon fontSize="small" />
      </Tooltip>
    </LabelContainer>
  )
}

interface AnnotationUpdatedContainerProps {
  dateUpdated?: string
}
export const AnnotationUpdatedContainer = ({ dateUpdated }: AnnotationUpdatedContainerProps): JSX.Element => {
  return (
    <LabelContainer>
      <AnnotationUpdatedNotice>Annotation Updated</AnnotationUpdatedNotice>
      <Tooltip title={dateUpdated ? `Updated on: ${dateUpdated}` : ""} placement="bottom">
        <HelpOutlineRoundedIcon fontSize="small" />
      </Tooltip>
    </LabelContainer>
  )
}

export const AnnotationExtractUpdatedContainer = (): JSX.Element => {
  return (
    <LabelContainer>
      <AnnotationUpdatedNotice>Annotation Extract Updated</AnnotationUpdatedNotice>
      <Tooltip
        title={
          "The extracts for this date have changed. Review the summary and possibly regenerate the text."
        }
        placement="bottom"
      >
        <HelpOutlineRoundedIcon fontSize="small" />
      </Tooltip>
    </LabelContainer>
  )
}

export { ContainerLabel as default }
