import React from "react"
import { USER_ROLES, ALL_ROLES_VALUES, ALL_ROLES } from "common/models/roles"

import MultiAutocompleteSelector from "./MultiAutocompleteSelector"

interface RolePickerProps {
  label?: string
  onChange: (data: Option[]) => void
  value: ALL_ROLES[]
}

interface Option {
  label: string
  value: ALL_ROLES
}

export const RolePicker: React.FC<RolePickerProps> = ({ value, onChange, label = "Roles" }): JSX.Element => {
  const setRoleToOptionFormat = (role: ALL_ROLES) => {
    return {
      value: role,
      label: USER_ROLES[role].display,
    }
  }

  const optionizedValue = value.map(setRoleToOptionFormat)
  const roles = (ALL_ROLES_VALUES as ALL_ROLES[]).map(setRoleToOptionFormat)

  const handleChange = ({ newValues }: { newValues: Option[] }) => {
    onChange(newValues)
  }

  //Get a list of options that are not already selected
  const options = roles.filter(option => {
    return !optionizedValue.find((value: Option) => {
      return value.value === option.value
    })
  })

  return (
    <MultiAutocompleteSelector
      dataTest="assignee-selector"
      options={options}
      value={optionizedValue}
      label={label}
      onChange={handleChange}
    />
  )
}
