import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"

import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { cloneDeep } from "lodash"

import { useHandleMessages } from "common/messages/useHandleMessages"
import { InputField } from "common/form-components"

import { queryKeys } from "../../react-query/constants"
import { createAttorney, updateAttorney } from "../../api"

import { partialAddress, setFormErrors } from "../../utils"

const useStyles = makeStyles()(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(4),
  },
  fullWidth: {
    gridColumn: "1 / 3",
  },
  actions: {
    gridColumn: "1 / 3",
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
}))

const INITIAL_STATE = {
  first_name: "",
  last_name: "",
  office_phone: "",
  fax_phone: "",
  address: {
    street: "",
    street_2: "",
    city: "",
    state: "",
    zip_code: "",
  },
  firm_id: null,
}

export default function AttorneyForm({ data = {}, callback, navigateBack = true }) {
  const { id: firmId } = useParams()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const queryClient = useQueryClient()
  const { showErrorMessage } = useHandleMessages()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({ defaultValues: { ...cloneDeep(INITIAL_STATE), ...data } })

  const createMutation = useMutation(createAttorney, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.firm])
      callback?.()
      navigateBack && navigate(`../`)
    },
    onError: error => {
      setFormErrors(error, setError)
      showErrorMessage({ message: "There was an error creating the attorney.", error })
    },
  })

  const updateMutation = useMutation(updateAttorney, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.firm])
      queryClient.invalidateQueries([queryKeys.attorney])
      callback?.()
    },
    onError: error => {
      setFormErrors(error, setError)
      showErrorMessage({ message: "There was an error updating the attorney.", error })
    },
  })

  const submitForm = handleSubmit(async data => {
    let cont = true
    if (partialAddress(data.address)) {
      cont = confirm("Attorney address is incomplete, are you sure you want to save?")
    }

    if (cont) {
      if (data.pk) {
        updateMutation.mutate({ data, attorneyId: data.pk })
      } else {
        createMutation.mutate({ data, firmId })
      }
    } else {
      // N.B. Hack to make sure `showSaveMessage()` isn't called
      throw new Error("User Cancelled Save Request")
    }
  })

  return (
    <Box data-test="attorney-form">
      <form className={classes.formFields} onSubmit={submitForm}>
        <InputField
          control={control}
          errors={errors}
          label="First Name"
          name="first_name"
          variant="outlined"
        />
        <InputField control={control} errors={errors} label="Last name" name="last_name" variant="outlined" />
        <InputField
          control={control}
          errors={errors}
          name="office_phone"
          label="Primary phone number"
          variant="outlined"
        />
        <InputField
          control={control}
          errors={errors}
          name="fax_phone"
          label="Fax number"
          variant="outlined"
        />
        <InputField
          control={control}
          errors={errors}
          label="Street address"
          name="address.street"
          className={classes.fullWidth}
          variant="outlined"
        />
        <InputField
          control={control}
          errors={errors}
          label="Street address Line 2"
          name="address.street_2"
          className={classes.fullWidth}
          variant="outlined"
        />
        <InputField control={control} errors={errors} label="City" name="address.city" variant="outlined" />
        <InputField control={control} errors={errors} label="State" name="address.state" variant="outlined" />
        <InputField
          control={control}
          errors={errors}
          label="Zip code"
          name="address.zip_code"
          rules={{ maxLength: { value: 10, message: "Ensure this field has no more than 10 characters." } }}
          showCharacterCounter={true}
          variant="outlined"
        />

        <Box className={classes.actions}>
          <Button variant="contained" color="secondary" type="submit" data-test="save-attorney-button">
            Save Attorney
          </Button>
          <Button variant="outlined" color="primary" type="submit" onClick={callback}>
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  )
}
