import { StandardBasicPlusForm } from "./StandardBasicPlusForm"
import { DOCUMENT_TYPES } from "requests/enums"
import { MedicalChronologyForm } from "./MedicalChronologyForm"
import { useWatch } from "react-hook-form"

export const FormByType = () => {
  const documentType = useWatch({ name: "type" })

  switch (documentType) {
    case DOCUMENT_TYPES.STANDARD:
    case DOCUMENT_TYPES.BASIC_PLUS:
    case DOCUMENT_TYPES.SIMPLE:
      return <StandardBasicPlusForm />

    case DOCUMENT_TYPES.MEDICAL_CHRONOLOGY:
    case DOCUMENT_TYPES.PREMIUM_MEDCHRON:
      return <MedicalChronologyForm />

    default:
      return null
  }
}
