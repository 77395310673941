import { ROOT_ITEM_KEY } from "../constants"
import { ProjectionData, ProjectionItemData, RootProjectionItem } from "../types"
import { findParent } from "./projection"

export type ReorderItemReturn<T> = ProjectionItemData<T> | ProjectionItemData<RootProjectionItem<T>>

export type ReorderOperation = {
  itemId: string
  parentId: Nullable<string>
  index: number
}

export type ReorderOperationReturn<T> = [ReorderItemReturn<T>, ReorderOperation]

export function removeFromParent<T>(data: ProjectionData<T>, itemId: string): ReorderOperationReturn<T> {
  const parent = findParent(data, itemId) ?? data[ROOT_ITEM_KEY]
  parent.children = parent.children.filter(id => id !== itemId)

  return [
    parent,
    {
      itemId,
      parentId: parent.id === ROOT_ITEM_KEY ? null : parent.id,
      index: -1,
    },
  ]
}

export function appendTo<T>(
  data: ProjectionData<T>,
  parentId: string,
  itemId: string
): ReorderOperationReturn<T> {
  const parent = data[parentId] ?? data[ROOT_ITEM_KEY]
  parent.children = [...parent.children, itemId]

  return [
    parent,
    {
      itemId,
      parentId: parent.id === ROOT_ITEM_KEY ? null : parent.id,
      index: parent.children.length - 1,
    },
  ]
}

export function appendBefore<T>(
  data: ProjectionData<T>,
  previousItemId: string,
  itemId: string
): ReorderOperationReturn<T> {
  const parent = findParent(data, previousItemId) ?? data[ROOT_ITEM_KEY]
  const index = parent.children.findIndex(id => id === previousItemId)
  const childrenBefore = parent.children.slice(0, index)
  const childrenAfter = parent.children.slice(index)

  parent.children = [...childrenBefore, itemId, ...childrenAfter]

  return [
    parent,
    {
      itemId,
      parentId: parent.id === ROOT_ITEM_KEY ? null : parent.id,
      index,
    },
  ]
}

export function appendAfter<T>(
  data: ProjectionData<T>,
  previousItemId: string,
  itemId: string
): ReorderOperationReturn<T> {
  const parent = findParent(data, previousItemId) ?? data[ROOT_ITEM_KEY]
  const index = parent.children.findIndex(id => id === previousItemId)
  const childrenBefore = parent.children.slice(0, index + 1)
  const childrenAfter = parent.children.slice(index + 1)

  parent.children = [...childrenBefore, itemId, ...childrenAfter]

  return [
    parent,
    {
      itemId,
      parentId: parent.id === ROOT_ITEM_KEY ? null : parent.id,
      index: index + 1,
    },
  ]
}
