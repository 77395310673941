import { Editor, NodeEntry, Path, Range } from "slate"
import { Transforms } from "slate"
import { ListItemElement, ParagraphElement } from "../../CustomEditor"
import { getCursorPositionInNode } from "../cursor"
import { getPreviousSibling } from "../siblings"
import { decreaseListDepth } from "./decreaseListDepth"
import { closestListItemNode } from "./queries"

export function mergeListItemWithPreviousSibling(editor: Editor, at?: Path): boolean {
  if (!editor.selection || Range.isExpanded(editor.selection)) return false

  const listItemEntry = closestListItemNode(editor, at || editor.selection.anchor.path)

  if (!listItemEntry) return false

  const { isStart } = getCursorPositionInNode(editor, editor.selection.anchor, listItemEntry[1])
  const previousListItemEntry = getPreviousSibling(editor, listItemEntry[1]) as Nullable<
    NodeEntry<ListItemElement>
  >

  if (!isStart) return false

  if (!previousListItemEntry || previousListItemEntry[0].children.length === 2) {
    decreaseListDepth(editor)
    return true
  }

  Editor.withoutNormalizing(editor, () => {
    const [listItemNode, listItemPath] = listItemEntry
    const [previousListItemNode, previousListItemPath] = previousListItemEntry

    const targetParagraphIndex = previousListItemNode.children[0].children.length - 1
    const targetParagraph = previousListItemNode.children[0].children[
      targetParagraphIndex
    ] as ParagraphElement
    const targetParagraphPath = [...previousListItemPath, 0, targetParagraphIndex]
    const targetIndexInParagraph = targetParagraph.children.length

    // merge first paragraph in list item with last paragraph in previous list item
    listItemNode.children[0].children[0].children.forEach((_, index) => {
      Transforms.moveNodes(editor, {
        at: [...listItemPath, 0, 0, 0],
        to: [...targetParagraphPath, targetIndexInParagraph + index],
      })
    })

    // move all other paragraphs from list item to previous list item
    listItemNode.children[0].children.slice(1).forEach((_, index) => {
      Transforms.moveNodes(editor, {
        at: [...listItemPath, 0, 1],
        to: [...previousListItemPath, 0, targetParagraphIndex + index + 1],
      })
    })

    // move nested list from list item to previous list item
    if (listItemNode.children[1]) {
      Transforms.moveNodes(editor, {
        at: [...listItemPath, 1],
        to: [...previousListItemPath, 1],
      })
    }

    // remove list item
    Transforms.removeNodes(editor, { at: listItemPath })
  })

  return true
}
