import React, { useCallback } from "react"

import { Theme } from "@emotion/react"
import { Box } from "@mui/material"
import { useEffect, useRef } from "react"
import { CALENDAR_CELL_SIZE } from "../DemandCalendar/constants"
import { ReactCalendarCell } from "../DemandCalendar/styled"
import { DemandCalendarDayComponentProps } from "../DemandCalendar/types"
import { CreateAppointmentPieChartSvg } from "./AppointmentPieChart"
import { MedicronCalendarAppointment } from "./types"
import styled from "@emotion/styled"

type MedicronDayRendererComponentProps = DemandCalendarDayComponentProps<MedicronCalendarAppointment>

interface MedicronDayRendererProps {
  onClick?: (appointments: MedicronCalendarAppointment[], element: HTMLElement) => void
}

const dayClickableStyles = { ":hover": { fontWeight: 700 }, cursor: "pointer" }

function getDayTextColor(isHighlightedDay: boolean, hasAppointments: boolean, theme: Theme) {
  if (isHighlightedDay) return `${theme.palette.error.main}`
  if (hasAppointments) return `#FFF`
  return `rgba(0, 0, 0, 0.60)`
}

type CalendarDayProps = {
  isHighlightedDay: boolean
  hasAppointments: boolean
}
const StyledCalendarDay = styled(Box, {
  shouldForwardProp: prop => prop !== "isHighlightedDay" && prop !== "hasAppointments",
})<CalendarDayProps>(({ theme, isHighlightedDay, hasAppointments }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  color: getDayTextColor(isHighlightedDay, hasAppointments, theme),
  fontWeight: isHighlightedDay ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
}))

export function MedicronDayRenderer({ onClick }: MedicronDayRendererProps) {
  return function MedicronDayRendererComponent({
    day,
    dayData: appointments,
    isHighlightedDay,
  }: MedicronDayRendererComponentProps): React.ReactElement {
    const svgRef = useRef<SVGSVGElement>(null)
    const calendarDayRef = useRef<HTMLElement>(null)
    const hasAppointments = appointments.length > 0
    const isClickable = hasAppointments && onClick

    const onClickHandler = useCallback(() => {
      if (!onClick || !calendarDayRef.current || !appointments.length) return

      onClick(appointments, calendarDayRef.current)
    }, [appointments])

    useEffect(() => {
      if (svgRef.current) {
        CreateAppointmentPieChartSvg(svgRef.current, appointments, isHighlightedDay)
      }
    }, [svgRef, appointments, isHighlightedDay])

    return (
      <>
        <ReactCalendarCell position="absolute">
          {hasAppointments && (
            <svg
              className="container"
              data-test={`medicron-calendar-pie-chart`}
              ref={svgRef}
              width={CALENDAR_CELL_SIZE}
              height={CALENDAR_CELL_SIZE}
            />
          )}
        </ReactCalendarCell>
        <StyledCalendarDay
          onClick={onClickHandler}
          ref={calendarDayRef}
          sx={isClickable ? dayClickableStyles : undefined}
          hasAppointments={appointments.length > 0}
          isHighlightedDay={isHighlightedDay}
        >
          {day.toString().padStart(2, "0")}
          {isHighlightedDay && "*"}
        </StyledCalendarDay>
      </>
    )
  }
}
