import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { makeStyles } from "tss-react/mui"
import Alert from "@mui/material/Alert"

import { amountInDollars, formatDate } from "utils"
import LinkButton from "common/buttons/LinkButton"
import { Contract } from "common/interfaces"
import { FEATURES, useFeatures } from "hooks/useFeatures"

const useStyles = makeStyles()(() => ({
  title: {
    fontWeight: "bold",
  },
}))

const CardContainer = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
})

const VerticalDivider = styled(Box)(({ theme }) => ({
  borderLeft: "1px solid #d9d9d9",
  width: "0px",
  margin: theme.spacing(0, 3),
}))

const SpacedTitle = styled(Box)({
  color: "#7d7d7d",
  letterSpacing: "3.3px",
  lineHeight: 2,
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "uppercase",
})

interface Props {
  contract: Nullable<Contract>
  onEditClick: () => void
}

export const TitleAndValue = ({
  title,
  value,
  dataTest,
}: {
  title: string
  value: string | number | undefined
  dataTest?: string
}): JSX.Element => {
  const EMPTY_VALUE = `—`
  const { classes } = useStyles()
  return (
    <Box data-test={dataTest}>
      <span className={classes.title}>{title}:</span> {value !== undefined ? value : EMPTY_VALUE}
    </Box>
  )
}

export const ContractCard = ({ contract, onEditClick }: Props): JSX.Element => {
  const { isFeatureEnabled } = useFeatures()
  const pricingModelsEnabled = isFeatureEnabled(FEATURES.PRICING_MODELS_ENABLED)
  let medicalPagesCap = null
  let medicalPagesOverage = null
  let medicalPagesOverageFee = null
  let verdictFee = null

  if (pricingModelsEnabled) {
    const standardPricingModel = contract?.pricing_models?.find(
      pricingModel => pricingModel.product_type === "standard_demand"
    )
    medicalPagesCap = standardPricingModel?.medical_pages_cap
    medicalPagesOverage = standardPricingModel?.medical_pages_overage
    medicalPagesOverageFee = standardPricingModel?.medical_pages_overage_fee
    verdictFee = standardPricingModel?.verdict_fee
  } else {
    medicalPagesCap = contract?.medical_pages_cap
    medicalPagesOverage = contract?.medical_pages_overage
    medicalPagesOverageFee = contract?.medical_pages_overage_fee
    verdictFee = contract?.verdict_fee
  }

  const medicalOverageFee = amountInDollars(medicalPagesOverageFee ?? 0)
  const verdictFeeDisplay = amountInDollars(verdictFee ?? 0)
  return (
    <CardContainer data-test="current-contract-card">
      {contract?.cancellation_date && (
        <Alert severity="info">
          Scheduled for cancellation: {formatDate(contract?.cancellation_date, "MM/dd/yyyy", true)}
        </Alert>
      )}
      <Box display="flex" flexDirection="row" py={3} px={4}>
        <Box width="fit-content">
          <SpacedTitle>Monthly Price</SpacedTitle>
          <Box fontWeight={700} fontSize={"20px"} data-test="monthly-price-value">
            {amountInDollars(contract?.monthly_price ?? 0)}
          </Box>
          <Box lineHeight={1.71} mt={2.5}>
            <TitleAndValue
              title={"Term Start"}
              value={formatDate(contract?.start_date, "MM/dd/yyyy", true)}
            />
            <TitleAndValue title={"Term Ends"} value={formatDate(contract?.end_date, "MM/dd/yyyy", true)} />
            <TitleAndValue title={"Auto renewal"} value={!!contract?.auto_renewal === true ? "On" : "Off"} />
          </Box>
        </Box>
        <VerticalDivider />
        <Box display="flex" flexDirection="column" width="fit-content">
          <Box lineHeight={2.29}>
            <TitleAndValue
              title={"Credits per Month"}
              value={contract?.monthly_credits}
              dataTest="card-credits-per-month"
            />
            <TitleAndValue title={"Medical Record Page Limit"} value={medicalPagesCap} />
            <TitleAndValue title={"Overage Pages"} value={medicalPagesOverage} />
            <TitleAndValue
              title={"Overage Fees"}
              value={medicalOverageFee.includes("NaN") ? undefined : medicalOverageFee}
            />
            <TitleAndValue
              title={"Verdict Fees"}
              value={verdictFeeDisplay.includes("NaN") ? undefined : verdictFeeDisplay}
            />
          </Box>
        </Box>
        <Box ml={1}>
          <LinkButton small={true} onClick={onEditClick} data-test="edit-contract-card">
            Edit
          </LinkButton>
        </Box>
      </Box>
    </CardContainer>
  )
}
