import { Dialog, IconButton, Box, Paper } from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import { Close as CloseIcon } from "@mui/icons-material"

import { Container, Header, Title, Body, Footer, WarningIcon } from "./styled"
import { SyncDocumentStructureModalProps } from "./types"

export const SyncDocumentStructureModal = ({
  show,
  onClose,
  onConfirm,
  onCancel,
  title = "Updates Available",
  description,
  onConfirmText = "Sync Document Updates",
  onCancelText = "Generate Current Version",
  isConfirmationLoading,
  isCancelationLoading,
}: SyncDocumentStructureModalProps) => {
  const disabled = isConfirmationLoading || isCancelationLoading
  return (
    <Dialog open={show} onClose={onClose} PaperComponent={Paper}>
      <Container>
        <Header>
          <Box display="flex" width="100%" alignItems="center" gap={2}>
            <WarningIcon fontSize="large" />
            <Title>{title}</Title>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Header>
        <Body>
          {description ?? (
            <div>
              A new Document Structure is available - pressing &quot;Generate Demand&quot; will <b>not</b>{" "}
              include the most current version of the firm&apos;s base template. Click &quot;Sync Documents
              Update&quot; to render the most current version
            </div>
          )}
        </Body>
        <Footer>
          <LoadingButton
            variant="outlined"
            color="secondary"
            onClick={onCancel || onClose}
            disabled={disabled}
            loading={isCancelationLoading}
          >
            {onCancelText}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="secondary"
            onClick={onConfirm}
            disabled={disabled}
            loading={isConfirmationLoading}
          >
            {onConfirmText}
          </LoadingButton>
        </Footer>
      </Container>
    </Dialog>
  )
}
