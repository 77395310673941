import React, { useMemo } from "react"
import { Box, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import Table, { Column } from "common/tables/Table"
import { RequestFile } from "requests/types"
import styled from "@emotion/styled"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import { useTheme } from "@emotion/react"
import { VisibilityOutlined, WarningAmberOutlined } from "@mui/icons-material"
import { UseFileUploaderReturn } from "common/file-uploader"
import { getFileStateForUploadId } from "common/file-uploader/uploader-state/updaters"
import { getUploadFileProcessingStatus, getUploadInfo } from "common/file-uploader/utils"
import { FileVerificationProcessingStatus } from "api/services/file"

import { VALIDATION_MESSAGE_MAP } from "common/form-components/files/ValidateFileAlert"
import { SERVER_FILE_DELETE_BUTTON } from "./constants"

const TableWrapper = styled(Box)(({ theme }) => ({
  gridColumn: "1/3",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(0.5),
}))

interface ServerSideFilesListProps {
  files: RequestFile[]
  onDeleteFile: (file: RequestFile) => void
  onViewFile: (file: RequestFile) => void
  fileUploader: UseFileUploaderReturn
}

function FileSizeCell(file: RequestFile) {
  return <>{!!file.number_of_pages && <Box>{file.number_of_pages} pages</Box>}</>
}

const getFileVerificationStatusAndError = (
  file: RequestFile,
  fileUploader: UseFileUploaderReturn
): { status: Nullable<FileVerificationProcessingStatus>; info: Nullable<string>; isError: boolean } => {
  // Prioritize the data that's on the RequestFile
  if (file.upload) {
    if (file.upload?.upload_status && ["WARNING", "FAILURE"].includes(file.upload.upload_status)) {
      return {
        status: file.upload?.upload_status,
        info: file.upload?.upload_processing_info ?? "An unknown error occured during upload",
        isError: file.upload?.upload_status && ["WARNING", "FAILURE"].includes(file.upload?.upload_status),
      }
    }
  }

  // When the file is uploaded, the fileUploader is pinging for the verification status
  if (file.upload?.pk) {
    const fileState = getFileStateForUploadId(file.upload?.pk, fileUploader.fileStates)
    if (fileState) {
      const processingStatus = getUploadFileProcessingStatus(fileUploader.fileStates, fileState[0])
      const processingInfo = getUploadInfo(fileUploader.fileStates, fileState[0])
      return {
        status: processingStatus,
        info: processingInfo,
        isError: !!(processingStatus && ["WARNING", "FAILURE"].includes(processingStatus)),
      }
    }
  }

  return { status: null, info: null, isError: false }
}

function FileStatusIcon({
  file,
  fileUploader,
}: {
  file: RequestFile
  fileUploader: UseFileUploaderReturn
}): React.ReactElement {
  const { status: verificationStatus, info: verificationInfo } = getFileVerificationStatusAndError(
    file,
    fileUploader
  )

  switch (verificationStatus) {
    case "WARNING":
      return (
        <Tooltip title={verificationInfo}>
          <WarningAmberOutlined sx={{ color: "#ff9800" }} />
        </Tooltip>
      )
    case "FAILURE":
      return (
        <Tooltip title={verificationInfo}>
          <WarningAmberOutlined sx={{ color: "#ff9800" }} />
        </Tooltip>
      )
    case "IN_PROGRESS":
    case "QUEUED":
      return (
        <Tooltip title={verificationInfo || "File Verification in progress"}>
          <CircularProgress size={25} />
        </Tooltip>
      )
    case "SUCCESS":
    case null:
      return <DescriptionOutlinedIcon />
  }
}

export function ServerSideFilesList({
  files,
  onDeleteFile,
  onViewFile,
  fileUploader,
}: ServerSideFilesListProps) {
  const theme = useTheme()

  const FileNameCell = useMemo(() => {
    const FileNameCellContents = (file: RequestFile) => {
      const { info: verificationInfo, isError } = getFileVerificationStatusAndError(file, fileUploader)

      // Assumes that ServerSideFilesList is only used in the RequestFormFiles
      const isHandledByValidateFileAlert =
        verificationInfo && Boolean(VALIDATION_MESSAGE_MAP[verificationInfo])

      return (
        <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
          <FileStatusIcon file={file} fileUploader={fileUploader} />
          <Box display="flex" flexDirection="column">
            <Typography
              variant="body1"
              fontSize={theme.typography.pxToRem(14)}
              style={{ wordBreak: "break-word" }}
            >
              {file.name}
            </Typography>
            {isError && !isHandledByValidateFileAlert && (
              <Typography color="#ff9800" fontSize={theme.typography.pxToRem(14)}>
                {verificationInfo}
              </Typography>
            )}
          </Box>
        </Box>
      )
    }
    return FileNameCellContents
  }, [theme, fileUploader])

  const FileActionsCell = useMemo(() => {
    const FileActionsCellContents = (file: RequestFile) => (
      <>
        <IconButton
          aria-label="delete"
          onClick={() => onDeleteFile(file)}
          data-test={SERVER_FILE_DELETE_BUTTON}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="view" onClick={() => onViewFile(file)}>
          <VisibilityOutlined />
        </IconButton>
      </>
    )
    return FileActionsCellContents
  }, [onDeleteFile, onViewFile])

  const columns: Column[] = [
    {
      id: "file_name",
      title: "File Name",
      align: "left",
      cellComponent: FileNameCell,
    },
    {
      id: "file_size",
      title: "Page Count",
      align: "left",
      sx: { minWidth: "120px" },
      cellComponent: FileSizeCell,
    },
    {
      id: "actions",
      title: "",
      align: "left",
      sx: { minWidth: "120px" },
      cellComponent: FileActionsCell,
    },
  ]

  const tableSummary = [
    {
      id: "name",
      title: "File Name",
      cellComponent: (files: RequestFile[]) => {
        const totalFiles = files?.length ?? 0
        return <Box>Total: {totalFiles} Files</Box>
      },
    },
    {
      id: "number_of_pages",
      title: "Number of Pages",
      cellComponent: (files: RequestFile[]) => {
        const totalPages = files.reduce((total, file) => {
          return total + (file.number_of_pages ?? 0)
        }, 0)
        return <Box>{totalPages} Pages</Box>
      },
    },
    {
      id: "empty",
      title: "",
      cellComponent: () => <Box />,
    },
  ]

  return (
    <TableWrapper>
      <Table
        columns={columns}
        records={files}
        summary={tableSummary}
        dataTest="completed-upload-files-list"
      />
    </TableWrapper>
  )
}
