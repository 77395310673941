import Skeleton from "@mui/material/Skeleton"

import { Content, IcdCodeContainer } from "./styled"
import { Header } from "../styled"

const LoadingState = (): JSX.Element => {
  return (
    <IcdCodeContainer>
      <Header>
        <Skeleton animation="wave" width="40%" />
      </Header>
      <Content>
        <Skeleton animation="wave" width="25%" height="45px" />
        <Skeleton animation="wave" width="25%" height="45px" />
      </Content>
    </IcdCodeContainer>
  )
}

export { LoadingState as default }
