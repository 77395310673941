import { dataReducer } from "common/state/createReducer"
import { FileUploaderAction, FILE_UPLOADER_ACTIONS_TYPE } from "./actions"
import { FileUploaderData } from "./types"
import { fileUploaderUpdaters } from "./updaters"

type FileUploaderReducer = (currentData: FileUploaderData, action: FileUploaderAction) => FileUploaderData

export const fileUploaderReducer: FileUploaderReducer = (state, action) => {
  return dataReducer<
    FileUploaderData,
    FILE_UPLOADER_ACTIONS_TYPE,
    FileUploaderAction,
    typeof fileUploaderUpdaters
  >(state, action, fileUploaderUpdaters)
}
