import { useEffect } from "react"
import { useDocumentStateStore } from "./store/documentState"
import { useHandleMessages } from "common/messages/useHandleMessages"

export function DocumentErrors(): JSX.Element {
  const { errorMessage, error } = useDocumentStateStore(state => ({
    errorMessage: state.errorMessage,
    error: state.error,
  }))
  const { showErrorMessage } = useHandleMessages()

  useEffect(() => {
    if (errorMessage) {
      showErrorMessage({
        message: errorMessage,
        error,
      })
      useDocumentStateStore.setState({ errorMessage: null, error: null })
    }
  }, [error, errorMessage, showErrorMessage])

  return <></>
}
