import { isUndefined } from "lodash"
import { handleEmptyResponse } from "api/utils"

import { ApiServiceType } from "../types"
import { apiService } from "../ApiService"
import { RangeReceiptRequest, SingleReceiptRequest } from "./types"

enum RECEIPT_API_PATHS {
  SINGLE_RECEIPT = "single",
  BULK = "bulk",
}

const RECEIPT_API_PATH_BASE = "settings/receipt-generation"

type BulkResponse = {
  result: string
  data: Blob
}

class ReceiptGenerationService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(path: RECEIPT_API_PATHS): string {
    const pathParts = ["", RECEIPT_API_PATH_BASE, path]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  generateRequestReceipt = ({ firm_id, questionnaire_id }: SingleReceiptRequest) => {
    return handleEmptyResponse(
      this.apiService.call({ firm_id, questionnaire_id }, this.getPath(RECEIPT_API_PATHS.SINGLE_RECEIPT))
    )
  }

  generateBulkReceipt = ({ firm_id, start_date, end_date, file_format }: RangeReceiptRequest) => {
    return handleEmptyResponse<BulkResponse>(
      this.apiService.call(
        { firm_id, start_date, end_date, file_format },
        this.getPath(RECEIPT_API_PATHS.BULK)
      )
    )
  }
}

export const receiptGenerationService = new ReceiptGenerationService(apiService)
