import { StyledLibraryTableFormButtonsWrapper, StyledCancelButton, StyledAddButton } from "./styled"
import { useAwaitAction } from "hooks/useAwaitAction"

interface FormFooterProps {
  onCancel: () => void
  onAction: () => void
  runningAction?: Nullable<Promise<boolean>>
}

export const FormFooter = ({ onCancel, onAction, runningAction }: FormFooterProps): JSX.Element => {
  const [loading, addHandler] = useAwaitAction(onAction, runningAction)

  return (
    <StyledLibraryTableFormButtonsWrapper>
      <StyledCancelButton variant="contained" onClick={onCancel}>
        Cancel
      </StyledCancelButton>
      <StyledAddButton loading={loading} onClick={addHandler}>
        Add
      </StyledAddButton>
    </StyledLibraryTableFormButtonsWrapper>
  )
}
