import React, { useEffect, useState } from "react"

import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"

import { summariesGeneratorApiService } from "api/services/summaries"
import { Generator } from "api/services/summaries/types"
import MultiAutocompleteSelector from "../../common/MultiAutocompleteSelector"

interface GeneratorPickerProps {
  onChange?: (generatorKeys: string[]) => void
  originalValue?: string[]
  label?: string
  options?: string[]
}

interface Option {
  label: string
  value: string
}

export const GeneratorPicker: React.FC<GeneratorPickerProps> = ({
  onChange,
  originalValue,
  label = "Generator(s)",
}): JSX.Element => {
  const [values, setValues] = useState<Option[]>([])
  const [options, setOptions] = useState<Option[]>([])

  const { data: generators } = useQuery<Generator[]>(
    [queryKeys.generators],
    () => summariesGeneratorApiService.getGenerators({}),
    {
      retry: false,
      meta: {
        disableLoader: true,
      },
    }
  )

  useEffect(() => {
    setValues(
      originalValue?.map((generatorKey: string): Option => {
        return {
          label: generators?.find(generator => generator.generatorKey == generatorKey)?.displayName || "",
          value: generatorKey,
        }
      }) ?? []
    )
    setOptions(generators?.map(createOptionFromGenerator) ?? [])
  }, [generators, originalValue])

  const createOptionFromGenerator = (generator: Generator): Option => {
    return {
      label: generator.displayName,
      value: generator.generatorKey,
    }
  }

  const handleOnChange = ({ newValues }: { newValues: Option[] }) => {
    setValues(newValues)
    if (onChange) onChange(newValues.map(v => v.value))
  }

  return (
    <MultiAutocompleteSelector
      dataTest="generator-selector"
      options={options}
      value={values}
      label={label}
      variant="outlined"
      onChange={handleOnChange}
    />
  )
}
