import styled from "@emotion/styled"
import {
  OutlinedInput,
  inputBaseClasses,
  selectClasses,
  chipClasses,
  autocompleteClasses,
  Popper,
  outlinedInputClasses,
} from "@mui/material"
import { DynamicColorChip } from "common/DynamicColorChip"

export const EditableSelectInput = styled(OutlinedInput)<{ open: boolean; readOnly?: boolean }>(
  ({ theme, open, readOnly }) => ({
    cursor: readOnly ? "default" : "pointer",
    paddingRight: theme.spacing(1),
    width: "100%",

    [`& .${inputBaseClasses.input}`]: {
      opacity: 0,
      pointerEvents: "none",
      width: 0,
    },

    [`& .${selectClasses.icon}`]: {
      color: theme.palette.grey[600],
      transform: `rotate(${open ? 180 : 0}deg)`,
      transition: "transform 0.2s ease",
      marginLeft: "auto",
    },

    [`& .${chipClasses.root}, & .${selectClasses.icon}`]: {
      pointerEvents: "none",
    },
  })
)

export const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: theme.spacing(0, -2.5),
    padding: theme.spacing(0, 2.5),
    color: "inherit",
    fontSize: 13,
  },

  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    margin: theme.spacing(0, -2.5),
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },

  [`& .${autocompleteClasses.noOptions}`]: {
    padding: 0,
    display: "flex",
    height: theme.spacing(4),
    alignItems: "center",
  },
}))

export const StyledAutocompleteInput = styled(OutlinedInput)(({ theme }) => ({
  height: theme.spacing(4),
  fontSize: "14px",
  borderRadius: theme.spacing(1),
  width: "100%",
  marginBottom: theme.spacing(1),

  [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: 1,
  },
}))

export const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: theme.shadows[5],
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  background: theme.palette.common.white,
  padding: theme.spacing(2, 2.5),

  [`& .${autocompleteClasses.popper}`]: {
    minWidth: "100%",
    maxWidth: "100%",
  },
}))

export const ListItem = styled("li")(({ theme }) => ({
  [`&.${autocompleteClasses.option}`]: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    minHeight: theme.spacing(4),
    padding: theme.spacing(0.5, 2.5),

    '&[aria-readonly="true"]': {
      pointerEvents: "none",
    },

    '&[aria-selected="true"]': {
      fontWeight: "bold",
      backgroundColor: "transparent",
    },

    [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

export const ListItemMarker = styled("div")({
  display: "block",
  height: "14px",
  width: "14px",
  minWidth: "14px",
  borderRadius: "50%",
})

export const ListItemNameContainer = styled("div")({
  width: "100%",
})

export const ListItemName = styled("div")({
  width: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
})

export const Value = styled(DynamicColorChip)({
  maxWidth: "90%",
})
