import React from "react"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"

import { Contract, DetailedContract } from "../../../common/interfaces"
import { ContractCard } from "./ContractCard"
import { ContractLog } from "./ContractLog"
import LinkButton from "../../../common/buttons/LinkButton"
import { Paper } from "@mui/material"
import useUser from "hooks/useUser"
import { canCreateNewContract } from "../permissions"

export const SectionTitle = styled(Box)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "bold",
  lineHeight: 1.55,
  marginBottom: theme.spacing(1),
}))

export const SectionSubtitle = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: 1.36,
  marginBottom: theme.spacing(1),
}))

export const ReceiptGenerationContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(3),
  flexDirection: "column",
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  width: "fit-content",
}))

export const ReceiptGenerationContainerItem = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
}))

interface ContractInformationProps {
  activeContract: Nullable<DetailedContract>
  contracts: Contract[]
  onEditActiveClick: (id: Nullable<number>) => unknown
  onCreateNewClick: () => unknown
  onCancelAllClick: () => unknown
}

export const ContractInformation: React.FC<ContractInformationProps> = ({
  activeContract,
  contracts,
  onEditActiveClick,
  onCreateNewClick,
  onCancelAllClick,
}) => {
  const { user } = useUser()
  const canRoleCreateNewContract = canCreateNewContract(user.role)
  return (
    <Box mt={2} data-test="contract-information">
      <Box mb={6} data-test="active-contract">
        <Box display="flex" alignItems="baseline" justifyContent="space-between">
          <SectionTitle>Active Contract</SectionTitle>
          <LinkButton
            onClick={onCancelAllClick}
            textColor="secondary"
            data-test="cancel-all-contracts-button"
          >
            Cancel All Contracts
          </LinkButton>
        </Box>
        <Box mt={2}>
          <ContractCard
            contract={activeContract}
            onEditClick={() => {
              onEditActiveClick(activeContract?.pk ?? null)
            }}
          />
        </Box>
      </Box>
      <Divider />
      <Box mt={3}>
        <Box display="flex">
          <SectionTitle>Contract Log</SectionTitle>
          {canRoleCreateNewContract && (
            <Box ml="auto">
              <LinkButton small={true} onClick={onCreateNewClick} data-test="create-contract-button">
                Create New Contract
              </LinkButton>
            </Box>
          )}
        </Box>
        <SectionSubtitle>
          Contracts starting in the future will become active as of their start date and will replace the
          currently active contract.
        </SectionSubtitle>
        <Box mt={5}>
          <ContractLog contracts={contracts} onEditClick={onEditActiveClick} />
        </Box>
      </Box>
    </Box>
  )
}
