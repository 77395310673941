import Skeleton from "@mui/material/Skeleton"
import Hidden from "@mui/material/Hidden"
import { DefaultPage } from "./styled"

interface PdfLoadingPageProps {
  scale: number
}

export function PdfLoadingPage({ scale }: PdfLoadingPageProps): JSX.Element {
  return (
    <DefaultPage scale={scale}>
      <Skeleton variant="rectangular" height={118} animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton width="60%" animation="wave" />
      <Hidden smDown>
        <Skeleton height={118} animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton width="60%" animation="wave" />
      </Hidden>
    </DefaultPage>
  )
}
