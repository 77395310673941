import { DateField } from "common/form-components"
import { ProviderFilter } from "./ProviderFilter"
import { DocType } from "./DocType"
import { SubDocType } from "./SubDocType"
import { Plaintiff } from "./Plaintiff"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import { Box, Button, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { ExhibitTag } from "./ExhibitTag"
import { Files } from "./Files"
import styled from "@emotion/styled"

const FiltersContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: theme.spacing(2),
  maxWidth: "768px",
}))

export function Filters() {
  const selectedStartDate = useExhibitBuilderStore(useShallow(state => state.selectedStartDate))
  const selectedEndDate = useExhibitBuilderStore(useShallow(state => state.selectedEndDate))

  return (
    <Box position="relative">
      <Box sx={{ opacity: 0.6, marginBottom: 3.5 }}>
        <Typography variant="caption">
          The filters you set here will only affect what is displayed on this page and will not impact the
          generated demand exhibit list.
          <br />
          *Provider filter includes both sorting & import providers, meaning this will affects exhibits &
          annotation entities.
          <br />
          ** Filter dates will hide partitions and annotation entities that are out of range, in addition to
          exhibits.
        </Typography>
      </Box>
      <FiltersContainer>
        <ProviderFilter />
        <DocType />
        <SubDocType />
        <Plaintiff />
        <DateField
          dataTest="start-date"
          size="small"
          label="Date of Service start"
          initialValue={selectedStartDate}
          onChange={exhibitBuilderActions.selectStartDate}
        />
        <DateField
          dataTest="end-date"
          size="small"
          label="Date of Service end"
          initialValue={selectedEndDate}
          onChange={exhibitBuilderActions.selectEndDate}
        />
        <Box gridColumn="span 2">
          <Files />
        </Box>
        <ExhibitTag />
      </FiltersContainer>
      <Box position="absolute" bottom={0} right={0}>
        <Button color="secondary" onClick={exhibitBuilderActions.clearFilters} startIcon={<CloseIcon />}>
          Clear
        </Button>
      </Box>
    </Box>
  )
}
