import { v4 } from "uuid"
import { StyledText } from "./CustomEditor"
import { TEXT_STYLES } from "./styles"

export const NODE_ID_FIELD_NAME = v4()
export const DIRTY_PROPERTY_NAME = v4()
export const DRAFT_NODE_PROPERTY_NAME = v4()
export const SLATE_EDITOR_DATA_TYPE_ATTRIBUTE = "slate-editor-wrapper"

export const DEFAULT_STYLES: StyledText = {
  [TEXT_STYLES.BOLD]: undefined,
  [TEXT_STYLES.ITALIC]: undefined,
  [TEXT_STYLES.UNDERLINE]: undefined,
  [TEXT_STYLES.HIGHLIGHT]: undefined,
}

export const NO_MATCH_SUGGESTION_FLAG = "NO_SUGGESTION_MATCH_MARK"
