import { UserDto } from "common/models/user"
import Button from "evenup-ui/Button"
import useUser from "hooks/useUser"
import { getAPIServerURL } from "utils"

export function ImpersontateButton({ target }: { target: UserDto }) {
  const { user } = useUser()

  const hasHigherRoleThanTarget = Number(user.role) > Number(target.role)

  if (!hasHigherRoleThanTarget) {
    return null
  }

  return (
    <Button color="secondary" size="small" href={`${getAPIServerURL()}/impersonate/${target.pk}/`}>
      Impersonate
    </Button>
  )
}
