import { PropsWithChildren, useCallback, useContext } from "react"
import { SortableNestedListItemProps } from "common/nested-list/SortableNestedListItem"
import { ITEM_REF } from "common/nested-list/constants"
import { ItemType, ProjectionItemWithChildren } from "common/nested-list/types"

import { DocumentStructureBlock, DocumentStructureHeading, DocumentStructureSection } from "./types"
import { DOCUMENT_STRUCTURE_BLOCK_TYPE, DOCUMENT_STRUCTURE_ITEM_TYPE } from "./enums"
import { SectionContent } from "./section/SectionContent"
import { SectionListItem, SectionListItemData } from "./section/SectionListItem"
import { SectionContentReadonly } from "./section/SectionContentReadonly"
import { DOCUMENT_STRUCTURE_TREE_ACTION_TYPE, DocumentStructureStoreContext } from "./store"
import { REF_ID } from "./constants"
import { HeadingContent } from "./heading/HeadingContent"
import { DocumentStructureTemplateBlock } from "./DocumentStructureTemplateBlock"
import { DocumentStructureComponentBlock } from "./DocumentStructureComponentBlock"
import { StyledBlockListItem } from "./styled"
import { HeadingListItem } from "./heading/HeadingListItem"

export function DocumentStructureListItem({
  item,
  ...props
}: PropsWithChildren<SortableNestedListItemProps<DocumentStructureSection>>): JSX.Element {
  const [, dispatch] = useContext(DocumentStructureStoreContext)
  const itemData = item[ITEM_REF]
  const itemId = item[REF_ID]

  const handleChildCreate = useCallback(
    (child: DocumentStructureBlock) => {
      dispatch({
        type: DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.CREATE,
        payload: {
          data: child,
          parentId: itemId,
        },
      })
    },
    [dispatch, itemId]
  )

  if (itemData.type === DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION) {
    return (
      <SectionListItem
        item={item as ProjectionItemWithChildren<SectionListItemData>}
        {...props}
        onChildCreate={handleChildCreate}
      />
    )
  }

  if (itemData.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.HEADING) {
    return <HeadingListItem item={item as ProjectionItemWithChildren<DocumentStructureHeading>} {...props} />
  }

  return <StyledBlockListItem item={item} {...props} />
}

export type DocumentStructureItemEditableCallbacks = {
  onSave: (item: ItemType<DocumentStructureSection>, sectionId?: Nullable<PrimaryKey>) => Promise<void>
  onDelete: (item: ItemType<DocumentStructureSection>, sectionId?: Nullable<PrimaryKey>) => Promise<void>
}

type DocumentStructureItemReadonlyCallbacks = {
  [K in keyof DocumentStructureItemEditableCallbacks]?: never
}

type DocumentStructureItemCallbacks =
  | DocumentStructureItemEditableCallbacks
  | DocumentStructureItemReadonlyCallbacks

export type DocumentStructureListItemContentProps = {
  item: ProjectionItemWithChildren<ItemType<DocumentStructureSection>>
} & DocumentStructureItemCallbacks

export function DocumentStructureListItemContent({
  item,
  onSave,
  onDelete,
}: DocumentStructureListItemContentProps): Nullable<JSX.Element> {
  const itemData = item[ITEM_REF]

  if (itemData.type === DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION) {
    if (onSave) return <SectionContent item={itemData} onSave={onSave} onDelete={onDelete} />

    return <SectionContentReadonly item={itemData} />
  }

  if (itemData.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.HEADING) {
    if (onSave) return <HeadingContent item={itemData} onSave={onSave} onDelete={onDelete} />

    return <HeadingContent item={itemData} disabled />
  }

  if (itemData.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.TEMPLATE) {
    return <DocumentStructureTemplateBlock item={itemData} onSave={onSave} onDelete={onDelete} />
  }

  if (itemData.blockType === DOCUMENT_STRUCTURE_BLOCK_TYPE.COMPONENT) {
    return <DocumentStructureComponentBlock item={itemData} onSave={onSave} onDelete={onDelete} />
  }

  return null
}
