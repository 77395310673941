import { pickBy } from "lodash"
import { Range } from "slate"
import { Editor } from "slate"
import { CustomEditor, StyledText } from "../CustomEditor"
import { TEXT_STYLES } from "../styles"
import { getSelectedText } from "./text"
import { getVariables, isVariable } from "./variables"

const defaultAllMarks: Required<StyledText> = {
  [TEXT_STYLES.BOLD]: true,
  [TEXT_STYLES.ITALIC]: true,
  [TEXT_STYLES.UNDERLINE]: true,
  [TEXT_STYLES.HIGHLIGHT]: true,
}
const defaultTruthyMarks = Object.values(TEXT_STYLES)

function getEditorMarks(editor: CustomEditor): StyledText {
  if (!editor.selection) return {}

  if (Range.isCollapsed(editor.selection)) {
    const node = Editor.node(editor, editor.selection)

    if (node && isVariable(editor, node[0])) return defaultAllMarks
  }

  return Editor.marks(editor) || {}
}

export function getMarks(editor: CustomEditor): StyledText {
  if (!editor.selection) {
    return {}
  }

  const marks = getEditorMarks(editor)
  const variableNodeEntries = getVariables(editor)
  const text = getSelectedText(editor)

  if (!variableNodeEntries.length && text === "") return marks

  const enabledMarks = new Set<TEXT_STYLES>(
    text ? (Object.keys(pickBy(marks, Boolean)) as TEXT_STYLES[]) : defaultTruthyMarks
  )

  for (const [variable] of variableNodeEntries) {
    if (!enabledMarks.size) break

    for (const mark of enabledMarks) {
      if (!variable[mark]) enabledMarks.delete(mark)
    }
  }

  const result: StyledText = {}

  for (const mark of enabledMarks) {
    result[mark] = true
  }

  return result
}
