import { Node } from "slate"
import { CitationElement, CustomEditor, EmptySpaceElement, SoftLineBreak } from "../CustomEditor"
import { INLINE_ELEMENTS } from "../elements"
import { isInlineNode } from "./elements"

export function isSoftLineBreak(editor: CustomEditor, node: Node): node is SoftLineBreak {
  return isInlineNode(editor, node) && node.type === INLINE_ELEMENTS.SOFT_LINE_BREAK
}

export function isSoftLineBreakNode(node: Node): node is SoftLineBreak {
  return "type" in node && node.type === INLINE_ELEMENTS.SOFT_LINE_BREAK
}

export function isCitationNode(node: Node): node is CitationElement {
  return "type" in node && node.type === INLINE_ELEMENTS.CITATION
}

export function isEmptySpaceNode(node: Node): node is EmptySpaceElement {
  return "type" in node && node.type === INLINE_ELEMENTS.EMPTY_SPACE
}
