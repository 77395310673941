import { OptionProps } from "evenup-ui/MultiSelectAutoComplete"
import { PlaintiffDto } from "requests/ViewRequest/types"
import { DOCUMENT_TYPES } from "requests/enums"
import { REQUEST_FORM_VARIANTS } from "./constants"

export type INJURY_IMPACTS_RECREATION = "injury_impacts_recreation"
export type INJURY_IMPACTS_ENTERTAINMENT = "injury_impacts_entertainment"
export type INJURY_IMPACTS_HOME_ACTIVITIES = "injury_impacts_home_activities"
export type INJURY_IMPACTS_SOCIAL = "injury_impacts_social"
export type INJURY_IMPACTS_SELF_CARE = "injury_impacts_self_care"
export type NON_ECONOMIC_IMPACT_TYPES =
  | INJURY_IMPACTS_RECREATION
  | INJURY_IMPACTS_ENTERTAINMENT
  | INJURY_IMPACTS_HOME_ACTIVITIES
  | INJURY_IMPACTS_SOCIAL
  | INJURY_IMPACTS_SELF_CARE

export const enum ECONOMIC_IMPACT_TYPES {
  ECONOMIC_HAS_LOSS_OF_INCOME = "has_loss_of_income",
  ECONOMIC_HAS_OUT_OF_POCKET_EXPENSES = "has_out_of_pocket_expenses",
  ECONOMIC_HAS_HOUSEHOLD_SERVICES = "has_household_services",
}

export interface INJURY_IMPACTS_KEYS_PROPS<T> {
  [key: string]: {
    key: T
    display: string
    assetKey?: string
  }
}

export type NewAssetProps = {
  label: string
  options: OptionProps[]
  formKey: keyof NonEconomicInjuriesProps
}

export interface AdditionalInfoRevampProps {
  variant?: REQUEST_FORM_VARIANTS
}

export interface InjuryProp extends Partial<Omit<OptionProps, "isSelected">> {
  key?: string
  impact_percentage?: boolean
}

export interface PlaintiffProps extends PlaintiffDto, NonEconomicInjuriesProps, EconomicInjuriesProps {
  // Most of these are nullable.
  // TODO: Verify and move over to PlaintiffDto (or a companion type)
  dependent_status?: string | string[] | null
  family_status?: Nullable<string>
  future_treatments?: Nullable<string>
  ongoing_complaints?: Nullable<string>
  state_of_residence?: string
  household_impairment_rate?: number | null
  impacts_other?: Nullable<string>
  additional_information?: Nullable<string>
}

export interface EconomicInjuriesProps {
  has_loss_of_income?: OptionProps | boolean
  has_out_of_pocket_expenses?: OptionProps | boolean
  has_household_services?: OptionProps | boolean
}

export interface NonEconomicInjuriesProps {
  impacts_entertainment?: OptionProps[] | string | string[] | null
  impacts_home_activities?: OptionProps[] | string | string[] | null
  impacts_recreation?: OptionProps[] | string | string[] | null
  impacts_self_care?: OptionProps[] | string | string[] | null
  impacts_social?: OptionProps[] | string | string[] | null
}

export interface EconomicProps {
  documentType: DOCUMENT_TYPES
  activeTab: number
  variant?: REQUEST_FORM_VARIANTS
}

export interface NonEconomicProps {
  activeTab: number
  variant?: REQUEST_FORM_VARIANTS
}

export interface PlaintiffStatusOptionProps {
  familyStatus?: OptionProps[]
  dependentStatus?: OptionProps[]
}

export interface PlaintiffStatusProps {
  options?: OptionProps[]
  update: (key: string, value: string | string[] | null) => void
}

export interface NonEconomicSectionProps {
  activeTab: number
  variant?: REQUEST_FORM_VARIANTS
}
