import styled from "@emotion/styled"
import TextButton from "common/buttons/TextButton"
import { BaseActionButton } from "../Tabs/styled"
import LinkButton from "common/buttons/LinkButton"

interface ShowArchivedProps {
  showArchived: boolean
}

interface MessageWrapperProps {
  error?: boolean
}

interface StyledLibraryFormProps {
  documentStructure?: boolean
}

export const StyledTitle = styled("div")({
  fontSize: "20px",
  fontWeight: "bold",
  textTransform: "capitalize",
})

export const StyledDescription = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
}))

export const StyledLibraryFormWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(6),
  position: "relative",
  minHeight: "37px",
}))

export const StyledLibraryForm = styled("div")<StyledLibraryFormProps>(({ theme, documentStructure }) => ({
  position: "relative",
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(4, 10, 4, 4),
  marginTop: theme.spacing(documentStructure ? 7 : 3),
}))

export const StyledLibraryAddButton = styled(TextButton)(({ theme }) => ({
  color: theme.palette.blue.main,
}))

export const StyledLibraryTableFormButtonsWrapper = styled("div")(() => ({
  display: "flex",
  flexGrow: 1,
  justifyContent: "flex-end",
}))

export const StyledMessageWrapper = styled("div")<MessageWrapperProps>(({ theme, error }) => ({
  marginTop: error ? theme.spacing(3) : undefined,
  position: "relative",
  whiteSpace: "nowrap",

  "& > div": !error
    ? {
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        top: theme.spacing(2),
      }
    : {},
}))

export const StyledCancelButton = styled(BaseActionButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}))
StyledCancelButton.defaultProps = {
  color: "inherit",
  disableElevation: true,
}

export const StyledAddButton = styled(BaseActionButton)({})
StyledAddButton.defaultProps = {
  color: "primary",
  variant: "contained",
  disableElevation: true,
}

export const StyledArchivedStructuresTitleWrapper = styled("div")<ShowArchivedProps>(({ showArchived }) => ({
  position: "absolute",
  transition: "0.7s",
  minWidth: "210px",
  left: showArchived ? "80px" : "calc(100% + 40px)",
  top: "4px",
}))

export const StyledArchiveButton = styled(LinkButton)<ShowArchivedProps>(({ theme, showArchived }) => ({
  position: "absolute",
  right: showArchived ? "calc(100%)" : "0px",
  transform: showArchived ? "translateX(100%)" : "translateX(0)",
  minWidth: "65px",
  top: "-6px",
  transition: "0.7s",
  fontSize: "13px",
  alignSelf: "start",
  height: theme.spacing(6),
}))
