import { ParagraphElement } from "../../../CustomEditor"
import { isTextNode, isVariableNode } from "../../../queries"
import { isEmptySpaceNode, isSoftLineBreakNode } from "../../../queries/inlines"

const NON_WORD_CHARS = new Set([" ", ",", ".", "?", "!", ":"])

export interface WordDiff {
  offsetFromStart: number
  offsetFromEnd: number
  deleted: string
  inserted: string
}

export function getWordDiff(original: string, suggested: string): Nullable<WordDiff> {
  if (original === suggested) return null

  let wordStartIdx = 0
  let i = 0

  for (i = 0; i < original.length; i++) {
    if (
      (NON_WORD_CHARS.has(original[i]) && NON_WORD_CHARS.has(suggested[i])) ||
      (i > 0 && NON_WORD_CHARS.has(original[i - 1]))
    ) {
      wordStartIdx = i
    }

    if (original[i] !== suggested[i]) break
  }

  let wordEndIdx = original.length - 1

  for (i = 1; i <= original.length - wordStartIdx; i++) {
    if (
      NON_WORD_CHARS.has(original[original.length - i]) ||
      (i > 1 && NON_WORD_CHARS.has(original[original.length - i + 1]))
    ) {
      wordEndIdx = original.length - i
    }

    if (original[original.length - i] !== suggested[suggested.length - i]) break
  }

  if (original.length - i + 1 === wordStartIdx) {
    return {
      offsetFromStart: wordStartIdx,
      offsetFromEnd: original.length - wordStartIdx,
      deleted: "",
      inserted: suggested.slice(wordStartIdx, suggested.length - original.length + wordStartIdx),
    }
  }

  return {
    offsetFromStart: wordStartIdx,
    offsetFromEnd: original.length - wordEndIdx,
    deleted: original.slice(wordStartIdx, wordEndIdx + 1),
    inserted: suggested.slice(wordStartIdx, suggested.length - original.length + wordEndIdx + 1),
  }
}

export function getParagraphTextMask(paragraph: ParagraphElement): string {
  let text = ""

  for (const child of paragraph.children) {
    if (isTextNode(child)) {
      text += child.text
    } else if (isVariableNode(child)) {
      text += " "
    } else if (isSoftLineBreakNode(child)) {
      text += "\n"
    } else if (isEmptySpaceNode(child)) {
      text += " "
    }
  }

  return text
}
