import { Editor } from "./Editor"
import { CustomEditor } from "./CustomEditor"
import { TEXT_STYLES } from "./styles"
import { KeyboardEvent } from "react"

export type EditorHotkeyCallback = (editor: CustomEditor) => void
export type EditorPreventableHotkeyCallback = (editor: CustomEditor) => boolean
export type EditorHotkeyHandler = (editor: CustomEditor, event: KeyboardEvent) => void
export type EditorHotkeys = Record<string, EditorHotkeyHandler>

function createHotkeyHandler(callback: EditorHotkeyCallback): EditorHotkeyHandler {
  return (editor, event) => {
    event.preventDefault()
    event.stopPropagation()
    callback(editor)
  }
}

function createPreventableHotkeyHandler(callback: EditorPreventableHotkeyCallback): EditorHotkeyHandler {
  return (editor, event) => {
    const shouldPrevent = callback(editor)

    if (shouldPrevent) {
      event.preventDefault()
      event.stopPropagation()
    }
  }
}

export const STYLE_HOTKEYS = {
  "mod+b": TEXT_STYLES.BOLD,
  "mod+i": TEXT_STYLES.ITALIC,
  "mod+u": TEXT_STYLES.UNDERLINE,
  "mod+h": TEXT_STYLES.HIGHLIGHT,
}

export const TEXT_HOTKEYS: EditorHotkeys = {
  "mod+shift+s": createHotkeyHandler(Editor.insertSectionSymbol),
  "shift+enter": createHotkeyHandler(Editor.insertInlineSoftLineBreak),
}

export const LIST_HOTKEYS: EditorHotkeys = {
  enter: createHotkeyHandler(Editor.splitListItem),
  "shift+tab": createHotkeyHandler(Editor.decreaseListDepth),
  tab: createHotkeyHandler(Editor.increaseListDepth),
  backspace: createPreventableHotkeyHandler(Editor.deleteListBackward),
  delete: createPreventableHotkeyHandler(Editor.deleteListForward),
}

export const COMMON_HOTKEYS: EditorHotkeys = {
  delete: createPreventableHotkeyHandler(Editor.deleteFragmentAtEditorRange),
  backspace: createPreventableHotkeyHandler(Editor.deleteFragmentAtEditorRange),
  space: createPreventableHotkeyHandler(Editor.createListFromHotkey),
}
