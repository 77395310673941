import Button from "@mui/material/Button"
import styled from "@emotion/styled"
import { LoadingButton } from "@mui/lab"

interface ExpandArrowProps {
  expanded: boolean
  onClick: () => void
}

interface TableHeaderItemProps {
  width: string
}

interface StyledListItemButtonProps {
  highlight?: boolean
}

export const StyledTable = styled("table")({
  borderCollapse: "collapse",
  width: "100%",
  textAlign: "left",
})

export const StyledTableHeaderRow = styled("tr")(({ theme }) => ({
  height: theme.spacing(4),
}))

export const StyledLoadingRow = styled("tr")({
  "& td": {
    padding: 0,
    border: "none",
  },
})

export const StyledTableBody = styled("tbody")({
  position: "relative",
})

export const StyledTableHeaderItem = styled("th")<TableHeaderItemProps>(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  textTransform: "capitalize",
}))

export const StyledTableRow = styled("tr")({
  height: "70px",

  "& > td": {
    borderBottom: "none",
  },
})

export const StyledTableListItem = styled("td")(({ theme }) => ({
  position: "relative",
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingLeft: theme.spacing(4),

  "&:first-of-type": {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },

  "&:last-of-type": {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    textAlign: "right",
    paddingRight: theme.spacing(4),
  },
}))

export const StyledTableListItemData = styled(StyledTableListItem)({})

export const StyledLibraryTableItemDetails = styled("td")(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.grey[300]}`,
  borderRight: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(0, 15, 3, 4),

  "&:empty": {
    display: "none",
  },
}))

export const StyledEditWrapper = styled("div")({
  display: "inline-block",
  userSelect: "none",
  position: "relative",
  lineHeight: "20px",
  textAlign: "left",
})

export const StyledLibraryTableMessageWrapper = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

export const StyledLibraryTableItemDetailsWrapper = styled("tr")(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}))

export const StyledListItemButton = styled(Button)<StyledListItemButtonProps>(({ theme, highlight }) => ({
  "@keyframes highlight": {
    from: { backgroundColor: theme.palette.grey[50] },
    "50%": { backgroundColor: theme.palette.grey[300] },
    to: { backgroundColor: theme.palette.grey[50] },
  },
  animation: highlight ? "highlight 2s linear infinite" : undefined,
  color: theme.palette.blue.main,
  textTransform: "capitalize",
  textDecoration: "underline",
  verticalAlign: "middle",
  fontSize: "13px",
  fontWeight: "normal",

  "&:hover": {
    textDecoration: "underline",
  },
}))

export const StyledSaveButton = styled(LoadingButton)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.blue.main,
  marginLeft: theme.spacing(4.5),
}))

export const StyledCancelButton = styled(Button)(() => ({
  fontWeight: "bold",
}))

export const StyledExpandArrow = styled("div")<ExpandArrowProps>(({ expanded, theme }) => ({
  display: "inline-block",
  position: "relative",
  marginLeft: "4px",
  width: "36px",
  borderRadius: "3px",
  height: "36px",
  cursor: "pointer",
  verticalAlign: "middle",

  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },

  "&::before": {
    transition: "0.3s",
    content: "''",
    position: "absolute",
    width: "14px",
    height: "3px",
    top: "50%",
    left: "calc(50% - 11px)",
    backgroundColor: theme.palette.common.black,
    borderRadius: "3px",
    transform: !expanded ? "rotate(45deg)" : "rotate(135deg)",
  },
  "&::after": {
    transition: "0.3s",
    content: "''",
    position: "absolute",
    top: "50%",
    left: "calc(50% - 3px);",
    width: "14px",
    height: "3px",
    backgroundColor: theme.palette.common.black,
    borderRadius: "3px",
    transform: !expanded ? "rotate(-45deg)" : "rotate(-135deg)",
  },
}))
