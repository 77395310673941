import { Theme } from "@mui/material/styles"
import { FILE_UPLOAD_STATE, FILE_UPLOAD_STATE_TYPE } from "../file-state/states"
import { FileUploaderItem } from "../uploader-state/types"

export function getOpacityByStatus(status: keyof FILE_UPLOAD_STATE_TYPE): number {
  switch (status) {
    case "DELETED":
      return 0.7
    case "PREPARING_FOR_UPLOAD":
      return 0.6
    case "CREATING_UPLOAD":
      return 0.4
    default:
      return 1
  }
}

export function getIconColorByStatus(status: keyof FILE_UPLOAD_STATE_TYPE, theme: Theme): string {
  switch (status) {
    case "FAILURE":
      return theme.palette.error.main
    case "WAITING_FOR_ASSIGNMENT":
    case "ASSIGNED":
    case "UNASSIGNED":
      return theme.palette.success.main
    default:
      return "inherit"
  }
}

function getFilesCountText(count: number): string {
  if (count === 1) return "1 file"

  return `${count} files`
}

export function* getTitlesForItems(items: [string, FileUploaderItem][]): Generator<string> {
  const inProgressStates: (keyof FILE_UPLOAD_STATE_TYPE)[] = [
    FILE_UPLOAD_STATE.CREATING_UPLOAD,
    FILE_UPLOAD_STATE.PREPARING_FOR_UPLOAD,
    FILE_UPLOAD_STATE.UPLOADING_CHUNKS,
  ]
  const successStates: (keyof FILE_UPLOAD_STATE_TYPE)[] = [
    FILE_UPLOAD_STATE.WAITING_FOR_ASSIGNMENT,
    FILE_UPLOAD_STATE.ASSIGNED,
    FILE_UPLOAD_STATE.UNASSIGNED,
  ]
  const uploading = items.filter(([, { state }]) => inProgressStates.includes(state.current)).length
  const uploaded = items.filter(([, { state }]) => successStates.includes(state.current)).length
  const failed = items.filter(([, { state }]) => state.current === FILE_UPLOAD_STATE.FAILURE).length

  if (uploading) {
    yield `Uploading ${getFilesCountText(uploading)}.`
    return
  }

  if (uploaded) yield `${getFilesCountText(uploaded)} uploaded.`

  if (failed) yield `Failed to upload ${getFilesCountText(failed)}.`
}
