import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { User } from "common/models/user"

export interface GeneratorEntity {
  key: string
  name: string
}

export enum PROVIDER_SUMMARY_STATUS {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  SUCCESS = "success",
  ERROR = "error",
}

export type PROVIDER_SUMMARY_STATUS_LITERALS = `${PROVIDER_SUMMARY_STATUS}`

export interface ProviderSummaryResult {
  status: PROVIDER_SUMMARY_STATUS
  results?: Nullable<EditorRoot<true>>
  error?: string
}

export interface GeneratorFieldChoice {
  key: string
  display: string
}

export enum FIELD_TYPES {
  STRING = "str",
  TEXT = "text",
  INTEGER = "int",
  CHOICE = "choice",
  DATE = "date",
}

export interface GeneratorFieldDto {
  field_id: string
  field_name: string
  field_type: FIELD_TYPES
  default_value?: string
  choices?: GeneratorFieldChoice[]
  value?: string
  required: boolean
}

export interface GeneratorField {
  fieldId: string
  fieldName: string
  fieldType: FIELD_TYPES
  defaultValue?: string
  choices?: GeneratorFieldChoice[]
  value?: string
  required: boolean
}

export enum GENERATOR_TYPES {
  SUMMARIZE = "summarize",
  IMPROVE_WRITING = "improve_writing",
  ASK_ANYTHING = "ask_anything",
}

export interface GeneratorDto {
  generator_key: string
  display_name: string
  generator_type: GENERATOR_TYPES
  summary_group: string
  help_text?: string
  inputs: GeneratorFieldDto[]
}

export interface Generator {
  generatorKey: string
  displayName: string
  generatorType: GENERATOR_TYPES
  summaryGroup: string
  helpText?: string
  inputs: GeneratorField[]
}

export interface ExhibitLink {
  exhibit_id: number
  partition_id: Nullable<number>
  page: number
}

export interface BulletPointItem {
  text: string
  citations: ExhibitLink[]
}

export interface BulletPointResult {
  header?: string
  values?: BulletPointItem[]
}

export interface Result {
  pk: string
  result: string | BulletPointResult[]
  status: PROVIDER_SUMMARY_STATUS_LITERALS
  error?: Nullable<string>
}

export interface Output {
  pk: string
  value: EditorRoot
  rating?: string
}

export interface FieldDto {
  field: string
  value: string
  field_type: string
}

export interface Field {
  field: string
  value: string
  fieldType: string
}

export interface SummaryDto {
  pk: string
  result: Result
  outputs: Output[]
  fields: FieldDto[]
  generator_key: string
  header: string
}

export interface Summary {
  pk: string
  result?: Result
  outputs: Output[]
  fields: Field[]
  generatorKey: string
  header: string
}

export interface SummarizeActionDto {
  generator_key: string
  inputs: FieldDto[]
}

export interface SummarizeAction {
  generatorKey: string
  inputs: Field[]
}

export interface OutputResponseDto {
  created_at: string
  edited_by: number
  pk: string
  summary: string
  value: EditorRoot
  rating: string
}

export interface OutputResponse {
  createdAt: string
  editedBy: number
  pk: string
  summary: string
  value: EditorRoot
  rating: string
}

export interface MinimalFirm {
  pk: number
  name: string
}

export interface SummaryRequestDto {
  pk: string
  firm: MinimalFirm
  name: string
  requester: User
  summaries: SummaryDto[]
  matter_id: string
}

export interface SummaryRequest {
  pk: string
  firm: MinimalFirm
  name: string
  requester: User
  summaries: Summary[]
  matterId: string
}
