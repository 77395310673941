import { useEffect } from "react"
import { Outlet, useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { useQueryClient } from "@tanstack/react-query"
import { DEMAND_FLOW_WRAPPER, LEFT_NAV_PANEL_ORDER } from "./constants"
import { queryKeys } from "react-query/constants"
import { DemandSteps } from "./steps"
import DemandSidebar from "./LeftNav/DemandSidebar"

const useStyles = makeStyles()(() => ({
  demandFlowWrapper: {
    display: "flex",
  },
  demandFlowContainer: {
    flexGrow: 1,
    order: LEFT_NAV_PANEL_ORDER + 1,
  },
}))

export function DemandFlow() {
  const { classes } = useStyles()
  const queryClient = useQueryClient()
  const { id: caseId } = useParams()

  useEffect(() => {
    return () => {
      requestAnimationFrame(() => queryClient.invalidateQueries([queryKeys.case]))
    }
  }, [queryClient])

  return (
    <Box className={classes.demandFlowWrapper} id={DEMAND_FLOW_WRAPPER}>
      <Box className={classes.demandFlowContainer}>
        <DemandSteps>
          <DemandSidebar caseId={Number(caseId)}>
            <Outlet />
          </DemandSidebar>
        </DemandSteps>
      </Box>
    </Box>
  )
}
