import { useCallback, useContext, useMemo } from "react"
import Collapse from "@mui/material/Collapse"
import { TransitionGroup } from "react-transition-group"
import { LibraryData, TableRowViewModel } from "common/models/library"
import { Loading } from "common/loading"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { FORM_STATE_STATUS } from "../TemplateForms/constants"
import { LibraryUseForm } from "../TemplateForms/types"
import { createFinishEditTemplateAction, createStartCreatingTemplateAction } from "../State/templatesActions"
import { LibraryTabStateContext } from "../State/constants"
import { LibraryTableListItemButtons } from "./LibraryTableListItemButtons"
import { defaultLibraryRowButtons } from "./constants"
import {
  StyledTableRow,
  StyledTableListItemData,
  StyledLibraryTableItemDetailsWrapper,
  StyledLibraryTableItemDetails,
  StyledLibraryTableMessageWrapper,
} from "./styled"

interface LibraryTableRowProps<T> {
  row: TableRowViewModel<T>
  getFormData: LibraryUseForm<T, { canDelete?: boolean }>
  getDuplicateData: (rowData: T) => Partial<T>
  formStatusMessageMap: Record<FORM_STATE_STATUS, Nullable<JSX.Element>>
  entityName: string
  canDuplicate: boolean
  canEdit: boolean
  getEditFormFooter?: (row: TableRowViewModel<T>) => Nullable<JSX.Element>
  onDuplicateClick?: (rowData: T) => void
}

export function LibraryTableRow<T extends LibraryData>({
  row,
  getFormData,
  formStatusMessageMap,
  entityName,
  canDuplicate,
  canEdit,
  getDuplicateData,
  getEditFormFooter,
  onDuplicateClick,
}: LibraryTableRowProps<T>): JSX.Element {
  const { rowId, items, rowData } = row
  const { state, dispatch } = useContext(LibraryTabStateContext)
  const expanded = state.expandedRowIds.includes(rowId)
  const { isFeatureEnabled } = useFeatures()
  const editing = state.editingRowId === rowId
  const showDetails = editing || expanded

  const onFinish = useCallback(() => {
    dispatch(createFinishEditTemplateAction())
  }, [dispatch])

  const canDelete = isFeatureEnabled(FEATURES.FIRM_TEMPLATE)

  const {
    runningAction,
    message,
    editForm,
    viewForm,
    isLoading,
    handleSubmit,
    handleCancel,
    additionalRowButtons,
    rowButtons = defaultLibraryRowButtons,
  } = getFormData({
    onFinish,
    rowData,
    formStatusMessageMap,
    canDelete,
    show: showDetails,
  })

  const handleDuplicate = useCallback(() => {
    if (!canDuplicate) return

    // Override the default duplicate action
    if (onDuplicateClick) {
      onDuplicateClick(rowData)
      return
    }

    dispatch(createStartCreatingTemplateAction(getDuplicateData(rowData)))
  }, [canDuplicate, dispatch, getDuplicateData, rowData, onDuplicateClick])

  const editTemplateForm = useMemo(() => {
    if (editing) {
      return editForm(getEditFormFooter ? getEditFormFooter(row) : null)
    }

    return null
  }, [editing, editForm, getEditFormFooter, row])

  const viewTemplateForm = useMemo(() => (expanded ? viewForm : null), [expanded, viewForm])

  return (
    <>
      <StyledTableRow data-rowid={rowId}>
        {items.map(item => (
          <StyledTableListItemData key={item.key}>{item.value}</StyledTableListItemData>
        ))}
        <LibraryTableListItemButtons
          runningAction={runningAction}
          rowId={rowId}
          handleCancel={handleCancel}
          handleSave={handleSubmit}
          handleDuplicate={handleDuplicate}
          canDuplicate={canDuplicate}
          additionalButtons={additionalRowButtons}
          canEdit={canEdit}
          rowButtons={rowButtons}
        />
      </StyledTableRow>
      <StyledLibraryTableItemDetailsWrapper>
        <StyledLibraryTableItemDetails colSpan={items.length + 1}>
          {message && <StyledLibraryTableMessageWrapper>{message}</StyledLibraryTableMessageWrapper>}
          {showDetails && (
            <>
              <TransitionGroup>
                <Collapse appear>
                  {viewTemplateForm}
                  {editTemplateForm}
                </Collapse>
              </TransitionGroup>
              <Loading show={isLoading} label={`Updating ${entityName}...`} />
            </>
          )}
        </StyledLibraryTableItemDetails>
      </StyledLibraryTableItemDetailsWrapper>
    </>
  )
}
