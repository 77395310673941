import { DocumentData, GetState, SetState } from "./types"
import { IcdCode } from "documents/types"

export type IcdCodesSlice = Pick<DocumentData, "icdCodes">

export const icdCodesSlice: IcdCodesSlice = {
  icdCodes: {},
}

export const icdCodeSelectors = {
  getIcdCodeById: (id: IcdCode["id"]) => (state: IcdCodesSlice) => state.icdCodes[id],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const icdCodeActions = (set: SetState<IcdCodesSlice>, get: GetState<IcdCodesSlice>) => {
  return {}
}
