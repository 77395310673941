import React, { ForwardedRef } from "react"
import { Box, TextField, TextFieldProps } from "@mui/material"
import styled from "@emotion/styled"
import { theme } from "app/theme"

const BaseTextField = React.forwardRef(
  (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { disableSearch, ...props }: Omit<TextFieldProps, "variant"> & { disableSearch?: boolean },
    ref: ForwardedRef<HTMLDivElement>
  ) => <TextField ref={ref} variant="outlined" {...props} />
)
BaseTextField.displayName = "BaseTextField"

export const StyledTextFieldSearch = styled(BaseTextField)(({ disableSearch }) => ({
  width: "100%",
  minWidth: "100px",
  marginBottom: theme.spacing(1.25),
  ...(disableSearch && {
    visibility: "hidden",
    position: "fixed",
  }),
}))

export const StyledBoxPopperWrapper = styled(Box)<{ width?: string }>(({ width = "auto" }) => ({
  minWidth: theme.spacing(32.5),
  padding: theme.spacing(1.25),
  maxWidth: theme.spacing(50),
  width,
}))
