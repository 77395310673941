import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { docTypesSlice } from "./docTypes"
import { plaintiffsSlice } from "./plaintiffs"
import { providersSlice, providersActions } from "./providers"
import { documentSlice, documentActions } from "./document"
import { SetState } from "./types"
import { actionsWithLogging, logger } from "utils"
import { isDevEnvironment, isTestEnvironment } from "infrastructure/env/getEnvironment"
import { filtersActions, filtersSlice } from "./filters"
import { exhibitBuilderService } from "../api/service"
import { collapseSlice, collapseActions } from "./collapse"
import { filesSlice } from "./files/filesSlice"
import { filesActions } from "./files/filesActions"
import sentryMiddleware from "sentry-zustand-middleware"
import { PROVIDER_KEY } from "./constants"

const createState = () => ({
  ...documentSlice,
  ...filesSlice,
  ...plaintiffsSlice,
  ...providersSlice,
  ...docTypesSlice,
  ...filtersSlice,
  ...collapseSlice,
})

export type ExhibitBuilder = ReturnType<typeof createState>

const createExhibitBuilderStore = () => {
  if (isDevEnvironment() && !isTestEnvironment()) {
    return create<ExhibitBuilder>()(devtools(logger(createState), { name: "ExhibitBuilder" }))
  }

  return create<ExhibitBuilder>()(sentryMiddleware(logger(createState)))
}

export const useExhibitBuilderStore = createExhibitBuilderStore()

export const exhibitBuilderStoreSelectors = {}

const actions = (set: SetState<ExhibitBuilder>) => {
  const reset = () => set(createState())
  const initialize = ({ documentId, caseId }: { documentId: string; caseId: string }) => {
    if (!documentId) {
      return
    }

    reset()
    exhibitBuilderActions.setIsLoading(true)
    exhibitBuilderActions.setDocumentId(documentId)
    exhibitBuilderActions.setCaseId(caseId)
    exhibitBuilderActions.clearFilters()
    exhibitBuilderService.getExhibitBuilderData({ documentId }).then(data => {
      set(data)
      data.userExhibitOrder.forEach(id => collapseActions(set).initializeIfNotExists(id))
      exhibitBuilderActions.setIsLoading(false)
    })
  }

  const initializeProviderExhibits = ({
    providerId,
    plaintiffId,
  }: {
    providerId: string
    plaintiffId: string
  }) => {
    exhibitBuilderActions.setReadOnly(true)
    exhibitBuilderActions.clearFilters()
    exhibitBuilderActions.selectDocTypes([PROVIDER_KEY])
    exhibitBuilderActions.selectProviders([providerId])
    exhibitBuilderActions.selectPlaintiffs([plaintiffId])
    exhibitBuilderActions.setExactProviderMatch(true)
    exhibitBuilderActions.setCollapseStateForAll(true)
  }

  const resetProviderExhibitsState = () => {
    exhibitBuilderActions.setReadOnly(false)
    exhibitBuilderActions.clearFilters()
  }

  return {
    initialize,
    initializeProviderExhibits,
    reset,
    resetProviderExhibitsState,
  }
}

export const exhibitBuilderActions = actionsWithLogging({
  ...documentActions(useExhibitBuilderStore.setState),
  ...filesActions(useExhibitBuilderStore.setState, useExhibitBuilderStore.getState),
  ...providersActions(useExhibitBuilderStore.setState, useExhibitBuilderStore.getState),
  ...actions(useExhibitBuilderStore.setState),
  ...filtersActions(useExhibitBuilderStore.setState),
  ...collapseActions(useExhibitBuilderStore.setState),
})
