import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { DndContext, MouseSensor, useDndMonitor, useDraggable, useSensor, useSensors } from "@dnd-kit/core"
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers"
import { DragHandle, ReviewBlocks, ReviewBox, ReviewCategory, ReviewItemPaper, ReviewTitle } from "./styled"
import { Alert, Box, Button, IconButton, Typography } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import NotInterestedIcon from "@mui/icons-material/NotInterested"
import CloseIcon from "@mui/icons-material/Close"
import { ChevronRight, DragIndicator } from "@mui/icons-material"
import { ReviewItem } from "./store/types"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import { NO_MATCH_SUGGESTION_FLAG } from "common/form-components/rich-text/constants"
import { PlainTextDiff } from "./PlainTextDiff"
import { HtmlStylesDiff } from "./HtmlStylesDiff"
import { PlainTextDiffEdit } from "./PlainTextDiffEdit"

interface ReviewModalProps {
  reviewItem: ReviewItem
  anchorRect: DOMRect
  itemsCount: number
  index: number
  onClose: () => void
  onItemIndexChange: (index: number) => void
  onAccept: () => void
  onModify: (modifiedText: string) => void | Promise<void> | PromiseLike<void>
  onReject: () => void
}

function ReviewModalComponent({
  reviewItem,
  anchorRect,
  itemsCount,
  index,
  onClose,
  onItemIndexChange,
  onAccept,
  onModify,
  onReject,
}: ReviewModalProps) {
  const draggable = useDraggable({ id: reviewItem.id })
  const rootRef = useRef<Nullable<HTMLDivElement>>(null)
  const [isNoMatchReviewItem, setIsNoMatchReviewItem] = useState(false)

  const [position, setPosition] = useState({ top: anchorRect.bottom, left: anchorRect.left })

  const [edit, setEdit] = useState(false)
  const [saving, setIsSaving] = useState(false)
  const userModifiedText = useRef(reviewItem.userModifiedText ?? reviewItem.suggestedText)

  useLayoutEffect(() => {
    setPosition({ top: anchorRect.bottom, left: anchorRect.left })
  }, [anchorRect])

  useDndMonitor({
    onDragEnd: () =>
      setPosition(currentPosition => ({
        top: currentPosition.top + (draggable.transform?.y ?? 0),
        left: currentPosition.left + (draggable.transform?.x ?? 0),
      })),
  })

  const draggableSetNodeRef = draggable.setNodeRef

  const setNodeRef = useCallback(
    (element: HTMLDivElement | null) => {
      rootRef.current = element
      draggableSetNodeRef(element)
    },
    [draggableSetNodeRef]
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (rootRef.current) {
        rootRef.current.scrollIntoView({ behavior: "smooth" })
      }
    }, 200)

    return () => clearTimeout(timeout)
  }, [anchorRect])

  useEffect(() => {
    const raf = requestAnimationFrame(() => {
      const noMatchElement = document.querySelector(
        `[data-suggestion-${reviewItem.id}][data-suggestion-${NO_MATCH_SUGGESTION_FLAG}]`
      )
      const isNoMatchElement =
        !!noMatchElement &&
        !noMatchElement.parentElement?.querySelectorAll(
          `[data-suggestion-${reviewItem.id}]:not([data-suggestion-${NO_MATCH_SUGGESTION_FLAG}])`
        ).length

      setIsNoMatchReviewItem(isNoMatchElement)
    })

    return () => cancelAnimationFrame(raf)
  }, [reviewItem])

  useEffect(() => {
    userModifiedText.current = reviewItem.userModifiedText ?? reviewItem.suggestedText
  }, [reviewItem.userModifiedText, reviewItem.suggestedText])

  const handleModifiedTextChange = useCallback((value: string) => {
    userModifiedText.current = value
  }, [])

  const handleModifySave = useCallback(async () => {
    setIsSaving(true)
    await onModify(userModifiedText.current)
    setIsSaving(false)
    setEdit(false)
  }, [onModify])

  const handleModifyCancel = useCallback(() => {
    setEdit(false)
    userModifiedText.current = reviewItem.userModifiedText ?? reviewItem.suggestedText
  }, [reviewItem])

  const isAccepted = reviewItem.status === "accepted" || reviewItem.status === "modified"
  const isRejected = reviewItem.status === "rejected"

  return (
    <ReviewItemPaper
      style={{
        top: position.top,
        left: position.left,
        transform: `translate(${draggable.transform?.x ?? 0}px, ${draggable.transform?.y ?? 0}px)`,
      }}
      elevation={2}
      ref={setNodeRef}
      isDragging={draggable.isDragging}
    >
      <IconButton
        sx={{
          position: "absolute",
        }}
        style={{
          top: "18px",
          right: "36px",
        }}
        size="small"
        onClick={onClose}
      >
        <CloseIcon sx={{ width: "18px", height: "18px" }} />
      </IconButton>
      <DragHandle
        {...draggable.listeners}
        {...draggable.attributes}
        ref={draggable.setActivatorNodeRef}
        draggable
        data-test="drag-indicator"
        data-type="drag-indicator"
      >
        <DragIndicator />
      </DragHandle>
      <ReviewTitle>
        <Typography variant="h2">Rosie Review</Typography>
        <Typography
          variant="h4"
          sx={{ fontWeight: "normal", fontSize: "14px", marginBottom: "3px" }}
        >{`${index + 1}/${itemsCount}`}</Typography>
      </ReviewTitle>
      <ReviewBlocks>
        {isAccepted && (
          <ReviewBox>
            <Alert variant="outlined" severity="success" sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <Typography variant="body2">Review Item Accepted</Typography>
            </Alert>
          </ReviewBox>
        )}
        {isRejected && (
          <ReviewBox>
            <Alert variant="outlined" severity="error" sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <Typography variant="body2">Review Item Rejected</Typography>
            </Alert>
          </ReviewBox>
        )}
        {isNoMatchReviewItem && !!reviewItem.originalText && (
          <ReviewBox>
            <Alert variant="standard" severity="error" sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <Typography variant="body2">No actual match found</Typography>
            </Alert>
          </ReviewBox>
        )}
        <ReviewBox>
          <ReviewCategory>
            <Typography variant="body1SemiBold">{reviewItem.category.replaceAll("_", " ")}</Typography>
          </ReviewCategory>
          <Typography variant="body2">{reviewItem.explanation}</Typography>
        </ReviewBox>
        <ReviewBox>
          <Typography variant="body1" sx={{ color: "#757575" }}>
            {edit ? "Suggestion" : "Rewrite"}
          </Typography>
          {reviewItem.outputTextType === "plain_text" && <PlainTextDiff reviewItem={reviewItem} />}
          {reviewItem.outputTextType === "styled_text" && <HtmlStylesDiff reviewItem={reviewItem} />}
        </ReviewBox>
        {edit && reviewItem.outputTextType === "plain_text" && (
          <ReviewBox>
            <Typography variant="body1" sx={{ color: "#757575" }}>
              Rewrite
            </Typography>
            <PlainTextDiffEdit
              reviewItem={reviewItem}
              onChange={handleModifiedTextChange}
              disabled={saving}
            />
          </ReviewBox>
        )}
      </ReviewBlocks>
      <ReviewBlocks>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1.5 }}>
            {!edit ? (
              <>
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={onAccept}
                  disableElevation
                  disabled={isAccepted}
                  startIcon={isAccepted && <CheckIcon />}
                >
                  <Typography variant="body2SemiBold">{isAccepted ? "Accepted" : "Accept"}</Typography>
                </Button>
                {reviewItem.outputTextType === "plain_text" && (
                  <Button
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    onClick={() => setEdit(true)}
                    disableElevation
                  >
                    <Typography variant="body2SemiBold">Modify</Typography>
                  </Button>
                )}
                <Button
                  size="medium"
                  variant="outlined"
                  color="error"
                  onClick={onReject}
                  disableElevation
                  disabled={isRejected}
                  startIcon={isRejected && <NotInterestedIcon />}
                >
                  <Typography variant="body2SemiBold">{isRejected ? "Rejected" : "Reject"}</Typography>
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={handleModifySave}
                  disabled={saving}
                  disableElevation
                >
                  <Typography variant="body2SemiBold">Save Changes</Typography>
                </Button>
                <Button
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  onClick={handleModifyCancel}
                  disabled={saving}
                  disableElevation
                >
                  <Typography variant="body2SemiBold">Cancel Modify</Typography>
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ marginLeft: "auto" }}>
            <Button
              size="medium"
              variant="text"
              disabled={index === 0}
              onClick={() => onItemIndexChange(index - 1)}
              startIcon={<ChevronLeft sx={{ marginRight: -1 }} />}
            >
              <Typography variant="body2SemiBold">Previous</Typography>
            </Button>
            <Button
              size="medium"
              variant="text"
              disabled={index + 1 >= itemsCount}
              onClick={() => onItemIndexChange(index + 1)}
              endIcon={<ChevronRight sx={{ marginLeft: -1 }} />}
            >
              <Typography variant="body2SemiBold">Next</Typography>
            </Button>
          </Box>
        </Box>
      </ReviewBlocks>
    </ReviewItemPaper>
  )
}

export const ReviewModal = memo(function ReviewModal(props: ReviewModalProps) {
  const sensors = useSensors(useSensor(MouseSensor))
  const modifiers = useMemo(() => [restrictToFirstScrollableAncestor], [])

  return (
    <DndContext modifiers={modifiers} sensors={sensors}>
      <ReviewModalComponent {...props} />
    </DndContext>
  )
})
