import { useCallback, useContext, useMemo } from "react"
import { CREATE_TEMPLATE_FORM_STATE_MESSAGES } from "../TemplateForms/constants"
import { TransitionGroup } from "react-transition-group"
import Collapse from "@mui/material/Collapse"
import { Loading } from "common/loading"
import { useProviderTemplateForm } from "../TemplateForms"
import { FormFooter } from "./FormFooter"
import { LibraryTabStateContext } from "../State/constants"
import { createEndCreatingTemplateAction, createStartCreatingTemplateAction } from "../State/templatesActions"
import {
  StyledTitle,
  StyledLibraryFormWrapper,
  StyledLibraryForm,
  StyledLibraryAddButton,
  StyledMessageWrapper,
} from "./styled"

export function NewProviderTemplate(): JSX.Element {
  const { state, dispatch } = useContext(LibraryTabStateContext)
  const disabled = !!(state.creatingData || state.editingRowId)
  const initialTemplateData = state.creatingData || {}

  const onFinish = useCallback(() => dispatch(createEndCreatingTemplateAction()), [dispatch])

  const { message, editForm, isLoading, handleSubmit, handleCancel, clearForm, errorForm } =
    useProviderTemplateForm({
      onFinish,
      rowData: initialTemplateData,
      formStatusMessageMap: CREATE_TEMPLATE_FORM_STATE_MESSAGES,
      show: true,
    })

  const handleAddNewTemplate = useCallback(() => {
    clearForm()
    dispatch(createStartCreatingTemplateAction())
  }, [clearForm, dispatch])

  const footer = useMemo(
    () => <FormFooter onCancel={handleCancel} onAction={handleSubmit} />,
    [handleSubmit, handleCancel]
  )
  const addNewTemplateForm = useMemo(() => editForm(footer), [editForm, footer])

  return (
    <>
      {message && <StyledMessageWrapper error={errorForm}>{message}</StyledMessageWrapper>}
      <StyledLibraryFormWrapper>
        <TransitionGroup>
          {!!state.creatingData && (
            <Collapse appear>
              <StyledLibraryForm>
                <StyledTitle>Add new template</StyledTitle>
                {addNewTemplateForm}
              </StyledLibraryForm>
            </Collapse>
          )}
          <>
            <StyledLibraryAddButton onClick={handleAddNewTemplate} disabled={disabled}>
              + Add New Template
            </StyledLibraryAddButton>
          </>
        </TransitionGroup>
      </StyledLibraryFormWrapper>
      <Loading show={isLoading} label="Creating provider template..." />
    </>
  )
}
