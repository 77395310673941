import React, { useCallback, useRef, useState } from "react"
import { DialogFormProps, DialogForm } from "common/dialog/DialogForm"
import { BaseActionButton } from "settings/Library/Tabs/styled"
import styled from "@emotion/styled"
import { Button, ButtonProps } from "@mui/material"
import { noop } from "lodash"

const StyledCancelButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))

type UseConfirmProps<T> = Omit<DialogFormProps, "buttons" | "onClose" | "open"> & {
  confirmationButtonContent?: React.ReactNode
  confirmationButtonProps?: ButtonProps
  cancelButtonProps?: ButtonProps
  center?: boolean
  formContent?: React.ReactNode
} & (T extends null
    ? {
        formContent: React.ReactNode
        FormComponent?: never
      }
    : {
        formContent?: never
        FormComponent: React.ComponentType<React.PropsWithChildren<T>>
      })

type ConfirmationCallbacks = {
  resolve: (value: boolean) => void
}

type ConfirmCallback<T> = T extends null ? () => Promise<boolean> : (props: T) => Promise<boolean>

export function useConfirm<T = null>({
  formContent,
  FormComponent,
  buttonPlacement = "center",
  confirmationButtonProps = {},
  cancelButtonProps = {},
  ...props
}: UseConfirmProps<T>): {
  dialog: React.ReactNode
  confirm: ConfirmCallback<T>
} {
  const waitingForConfirmation = useRef<ConfirmationCallbacks>({ resolve: noop })
  const [open, setOpen] = useState<boolean>(false)
  const [componentProps, setComponentProps] = useState<Nullable<T>>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const confirm = useCallback<ConfirmCallback<T>>(
    ((componentProps?: T): Promise<boolean> => {
      setComponentProps(componentProps ?? null)
      setOpen(true)
      waitingForConfirmation.current.resolve(false)

      return new Promise<boolean>(resolve => {
        waitingForConfirmation.current = { resolve }
      })
    }) as ConfirmCallback<T>,
    []
  )

  const handleConfirm = useCallback(() => {
    setOpen(false)

    waitingForConfirmation.current.resolve(true)
  }, [waitingForConfirmation])

  const handleCancel = useCallback(() => {
    setOpen(false)

    waitingForConfirmation.current.resolve(false)
  }, [waitingForConfirmation])

  return {
    dialog: (
      <DialogForm
        {...props}
        buttonPlacement={buttonPlacement}
        buttons={[
          <StyledCancelButton
            key="cancel-button"
            color="primary"
            {...cancelButtonProps}
            onClick={handleCancel}
            data-test="confirm-cancel-button"
          >
            Cancel
          </StyledCancelButton>,
          <BaseActionButton
            key="confirm-button"
            variant="contained"
            color="secondary"
            {...confirmationButtonProps}
            onClick={handleConfirm}
            data-test="confirm-button"
          >
            {props.confirmationButtonContent}
          </BaseActionButton>,
        ]}
        onClose={handleCancel}
        open={open}
      >
        {open &&
          (FormComponent ? (
            <FormComponent {...(componentProps as JSX.IntrinsicAttributes & T)} />
          ) : (
            formContent
          ))}
      </DialogForm>
    ),
    confirm,
  }
}
