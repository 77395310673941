import { useMutation } from "@tanstack/react-query"
import { ApiError } from "apiHelper"
import { UpdateRequestInternalAssigneesVariables, updateRequestInternalAssignees } from "../api"
import { queryClient } from "react-query/queryClient"
import { queryKeys } from "react-query/constants"
import { INTERNAL_ROLES } from "common/models/roles"
import useUser from "hooks/useUser"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { JSONparseWithDefaultValue } from "utils"
import { useNavigate } from "react-router-dom"

type Props = {
  assignedSelf: boolean
  setAssignedSelf: (assignedSelf: boolean) => void
}
export function useSaveInternalAssigneesRelationMutation({ assignedSelf, setAssignedSelf }: Props) {
  const { user } = useUser()
  const { showErrorMessage } = useHandleMessages()
  const navigate = useNavigate()

  return useMutation<unknown, ApiError, UpdateRequestInternalAssigneesVariables>(
    updateRequestInternalAssignees,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.request])
        if (user.role === INTERNAL_ROLES.LEGALOPS) {
          setAssignedSelf(!assignedSelf)
        }
      },
      onError: error => {
        const status = error?.response?.status
        if ((status === 400 && error?.response?.code === "invalid_intake_status") || status === 404) {
          showErrorMessage({
            message: "Could not claim this request, it might be claimed by someone else.",
            error,
          })
          navigate(`/requests/`)
        }

        if (status === 400) {
          showErrorMessage({
            message:
              JSONparseWithDefaultValue(error?.message)?.error ||
              "An unknown error occured updating the assignee",
            error,
          })
        }
      },
    }
  )
}
