import { User } from "common/models/user"

export interface BaseInfrastructureService {
  init(): void

  setUserContext(user: User): void

  resetUserContext(): void
}

export interface BaseAnalyticsService {
  trackPageView(route?: string): void

  trackEvent(event: BaseAnalyticsEvent): void

  trackPerformance(
    name: string,
    fields: {
      duration: DOMHighResTimeStamp
      trailingDuration: DOMHighResTimeStamp
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [x: string]: any
    }
  ): void
}

export abstract class BaseAnalyticsEvent {
  public name: string = "BaseAnalyticsEvent"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fields: Record<string, any> = {}
}
