import { DataUpdatersMap } from "common/state/types"
import { FILE_UPLOAD_ACTIONS_TYPE, FILE_UPLOAD_ACTIONS as ACTIONS, FileUploadActions } from "./actions"
import { UploadData } from "./types"
import invariant from "invariant"
import { isNil } from "lodash"

export const FILE_UPLOAD_UPDATERS: DataUpdatersMap<UploadData, FILE_UPLOAD_ACTIONS_TYPE, FileUploadActions> =
  {
    [ACTIONS.CREATE_UPLOAD]: function (data, { chunkCount }) {
      invariant(!isNaN(chunkCount), "Chunks count is out of range")
      invariant(chunkCount > 0, "Can not upload empty file")
      invariant(data.uploadedChunks === null, "Can not create upload as it seems to be already created")
      invariant(data.progress === 0, "Can not create upload as it seems to be already in progress")

      return {
        ...data,
        chunkCount,
        progress: 0,
      }
    },

    [ACTIONS.PROCESS_UPLOAD]: function (data, { uploadId, processingStatus }) {
      invariant(uploadId !== "", "Invalid upload id")
      invariant(!("uploadId" in data), "Can not process upload as it seems to be already created")
      invariant(data.progress === 0, "Can not process upload as it seems to be already in progress")
      invariant(!isNil(data.chunkCount), "No chunks to upload")

      return {
        ...data,
        uploadId,
        chunkCount: data.chunkCount,
        uploadedChunks: new Array(data.chunkCount).fill(false),
        processingStatus,
      }
    },

    [ACTIONS.COMPLETE_UPLOAD]: function (data, { info, processingStatus }) {
      invariant(data.progress === 100, "Cannot complete upload - not all chunks were uploaded")

      return {
        uploadId: data.uploadId,
        uploadedChunks: null,
        chunkCount: null,
        progress: 100,
        info,
        processingStatus,
      }
    },

    [ACTIONS.UPDATE_PROGRESS]: function (data, { chunkIndex }) {
      const { chunkCount, uploadedChunks: previousChunks } = data

      invariant(typeof chunkCount === "number" && chunkCount, "Invalid chunks count", [chunkCount])
      invariant(previousChunks !== null, "Can not get previously uploaded chunks - no data")
      invariant(chunkIndex < chunkCount, "Chunk index is out of range", [data, chunkIndex])

      const chunks = previousChunks.slice(0, chunkCount)

      chunks[chunkIndex] = true

      const uploadedChunks = chunks.filter(Boolean)
      const uploadedChunksCount = uploadedChunks.length

      invariant(uploadedChunksCount <= chunkCount, "Too many uploaded chunks", [data, uploadedChunks])

      const progress = Math.floor((uploadedChunksCount / chunkCount) * 100)

      return {
        ...data,
        chunkCount,
        uploadedChunks: chunks,
        progress,
      }
    },

    [ACTIONS.ASSIGN_WITH_EXTERNAL]: function (data, { externalId }) {
      invariant(data.progress === 100, "Cannot assign upload - not all chunks where uploaded")
      invariant(!isNil(externalId) && !isNaN(externalId), "Invalid external id")

      return {
        ...data,
        externalId,
      }
    },

    [ACTIONS.UNASSIGN_FROM_EXTERNAL]: function (data) {
      invariant("uploadId" in data && !isNil(data.uploadId), "Cannot unassign upload")

      return {
        uploadId: data.uploadId,
        uploadedChunks: null,
        chunkCount: null,
        progress: 100,
      }
    },

    [ACTIONS.CANCEL]: function () {
      return {
        chunkCount: null,
        uploadedChunks: null,
        progress: 0,
      }
    },

    [ACTIONS.DELETE]: function () {
      return {
        chunkCount: null,
        uploadedChunks: null,
        progress: 0,
      }
    },

    [ACTIONS.FAIL]: function (_, { reason }) {
      return {
        reason,
        chunkCount: null,
        uploadedChunks: null,
        progress: 0,
      }
    },
  }
