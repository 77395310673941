import { useState } from "react"
import { useNavigate } from "react-router-dom"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { useForm, Controller } from "react-hook-form"
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query"
import { makeStyles } from "tss-react/mui"
import { isNil } from "lodash"

import { queryKeys } from "react-query/constants"
import { useHandleMessages } from "common/messages/useHandleMessages"

import { createUser, updateUser, getFirms } from "api"
import useUser from "hooks/useUser"
import { useAuth } from "hooks/useAuth"

import { getRoleOptionsForRole } from "app/constants"
import { INTERNAL_ROLES, OSF } from "common/models/roles"
import { ConfirmDialog, Loading } from "common"
import { SmartAdvocateAccountForm } from "./SmartAdvocateAccountForm"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { UserAnalyticEvent, UserAnalyticsEventTypes } from "infrastructure/apm/events/userEvents"
import { theme } from "app/theme"

const useStyles = makeStyles()(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(4),
  },
  spanAcross: {
    gridColumn: "1 / 3",
  },
  actionButton: {
    gridColumn: "1 / 2",
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: "flex",
    gridColumn: "1 / 3",
    "& > :last-child": {
      marginLeft: theme.spacing(2),
    },
    justifyContent: "end",
  },
  form: {
    border: "1px solid #DADADA",
    padding: "16px",
    maxWidth: "790px",
  },
}))

export function UserAccountForm({ formData = {}, callback = null }) {
  const { showErrorMessage, showSuccessMessage } = useHandleMessages()
  const [errors, setErrors] = useState(null)
  const [showWarning, setShowWarning] = useState(false)
  const [disableSave, setDisableSave] = useState(false)

  const { classes } = useStyles()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { logout } = useAuth()

  const { user } = useUser()
  const isEditingSelf = user.id === formData?.pk
  const emailChangeCopy = isEditingSelf
    ? "Are you sure you want to change your email? You will be logged out and will need to log back in with the new email."
    : "Are you sure you want to change the email for this user? They will be required to log in with the new email address."

  const { data: firms } = useQuery([queryKeys.firms], getFirms, { enabled: user.isInternal })

  const { control, handleSubmit, watch, getValues, setValue } = useForm({
    defaultValues: formData,
  })
  const roleOptions = getRoleOptionsForRole(user.role, getValues("role"))

  // firmField to used on the firm selector
  const firmField = formData?.firm_id ? "firm_id" : "firm.pk"
  const firmId = watch(firmField)

  const handleErrors = error => {
    if (error?.response?.status === 400) {
      let errorObject = isNil(error?.message) ? null : JSON.parse(error?.message)
      if ("error" in errorObject) {
        showErrorMessage({
          message: `${errorObject.error}`,
          error,
        })
      } else {
        showErrorMessage({
          message: `Unable to update the user. ${errorObject ? error?.message : ""}`,
          error,
        })
      }

      return setErrors?.(errorObject)
    }

    if (error?.response?.status === 500) {
      // in dev env full HTML message will be too large to display on a snackbar
      showErrorMessage({
        message: "Something went wrong. Please email support@evenuplaw.com for help.",
        error,
      })
      return
    }
  }

  const saveUserAccountMutation = useMutation(createUser, {
    onMutate: () => {
      setErrors(null)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.users])
      showSuccessMessage("User created successfully.")
      navigate(`/settings/accounts`)
    },
    onError: handleErrors,
  })

  const handleFormSubmit = e => {
    e.preventDefault()
    hasChangedEmail ? setShowWarning(true) : submitForm()
  }

  const updateUserAccountMutation = useMutation(updateUser, {
    onMutate: () => {
      setErrors(null)
    },
    onSuccess: () => {
      if (isEditingSelf && hasChangedEmail) {
        showSuccessMessage({
          message: "Email updated successfully. Logging you out... ",
          timeout: 1000,
        })

        setTimeout(async () => {
          await logout()
        }, 1000)
      }

      queryClient.invalidateQueries([queryKeys.userAccount])
      callback?.()
      showSuccessMessage("Changes saved successfully.")
    },
    onError: handleErrors,
  })

  const submitForm = handleSubmit(async data => {
    if (data?.firm?.pk) {
      data.firm_id = data.firm.pk
    }

    const pk = data.pk
    if (pk) {
      updateUserAccountMutation.mutate({ data: data, userId: pk })
    } else {
      saveUserAccountMutation.mutate(
        { data: data },
        {
          onSuccess: res => {
            if (res.firm) {
              amplitudeApm.trackEvent(
                new UserAnalyticEvent(UserAnalyticsEventTypes.UserAdded, {
                  firm_id: res.firm.pk,
                  firm_name: res.firm.name,
                })
              )
            }
          },
        }
      )
    }
  })

  const roleWatch = watch("role")
  const emailWatch = watch("email")
  const hasChangedEmail = formData.email && emailWatch !== formData?.email

  return (
    <>
      <Loading showOnMutation />
      <Box>
        <Box className={classes.form}>
          {formData.pk && (
            <Box fontWeight={600} fontSize="18px" mb={theme.spacing(2)}>
              EvenUp Account Settings
            </Box>
          )}
          <form className={classes.formFields} onSubmit={handleFormSubmit}>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    required
                    variant="outlined"
                    label="First Name"
                    error={errors?.first_name}
                    helperText={errors?.first_name}
                    {...field}
                  />
                )
              }}
            />
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    required
                    variant="outlined"
                    label="Last Name"
                    error={errors?.last_name}
                    helperText={errors?.last_name}
                    {...field}
                  />
                )
              }}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    required
                    variant="outlined"
                    label="Email"
                    error={errors?.email}
                    helperText={errors?.email}
                    {...field}
                  />
                )
              }}
            />
            {!isEditingSelf && !!roleOptions.length && (
              <Controller
                name="role"
                control={control}
                required
                render={({ field }) => (
                  <FormControl
                    disabled={
                      !user.isRole(INTERNAL_ROLES.LEGALOPS_ADMIN) &&
                      formData.role === INTERNAL_ROLES.LEGALOPS_ADMIN
                    }
                  >
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select labelId="role-label" label="Role" {...field} required data-test="role-selector">
                      {roleOptions.map(({ key, display }) => {
                        return (
                          <MenuItem key={key} value={key}>
                            {display}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            )}
            {firms &&
              user.isInternal &&
              roleWatch !== undefined &&
              roleWatch !== OSF &&
              !Object.values(INTERNAL_ROLES).includes(roleWatch) && (
                <Controller
                  required
                  name={firmField}
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <InputLabel id="firm-label">Firm</InputLabel>
                      <Select
                        labelId="firm-label"
                        label="Firm"
                        id="firm-selector"
                        {...field}
                        required
                        data-test="firm-selector"
                      >
                        {firms.map(f => (
                          <MenuItem key={f.pk} value={f.pk}>
                            {f.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              )}

            {firmId && (
              <Box className={classes.spanAcross}>
                <SmartAdvocateAccountForm
                  firmId={firmId}
                  control={control}
                  setDisableSave={setDisableSave}
                  setValue={setValue}
                  user={formData}
                />
              </Box>
            )}

            <Box className={classes.actions}>
              <Button
                variant="text"
                sx={{ color: theme.palette.blue.primary }}
                onClick={() => (callback ? callback() : navigate("../"))}
                data-test="cancel-button"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={disableSave}
                sx={{
                  background: theme.palette.blue.primary,
                }}
                data-test="save-user"
              >
                Save
              </Button>
            </Box>
          </form>
        </Box>
        <ConfirmDialog
          open={showWarning}
          onClose={() => setShowWarning(false)}
          onConfirm={() => {
            setShowWarning(false)
            submitForm()
          }}
          title={"User email was changed"}
          body={emailChangeCopy}
        />
      </Box>
    </>
  )
}
