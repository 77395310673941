import { FILE_CATEGORY_OPTIONS } from "common/constants"
import { ACCEPT_IMAGE_TYPE, ACCEPT_TYPE } from "./constants"
import { PendingFile, FileMetadata } from "./types"

export function isPossiblySameFile(file: FileMetadata, anotherFile: FileMetadata): boolean {
  if (file === anotherFile) {
    return true
  }

  return (
    file.name === anotherFile.name &&
    file.lastModified === anotherFile.lastModified &&
    file.type == anotherFile.type &&
    file.size === anotherFile.size
  )
}

export function includesFile(files: FileMetadata[], file: FileMetadata): boolean {
  const isSame = isPossiblySameFile.bind(null, file)
  return files.some(isSame)
}

export function mapFileToPending(file: File): PendingFile {
  return { file, name: file.name, type: FILE_CATEGORY_OPTIONS.OTHER }
}

export function mapPendingToFile(pendingFile: PendingFile): File {
  return pendingFile.file
}

export function fileTypeRestrictionText(accept: ACCEPT_TYPE): string {
  if (accept === ACCEPT_IMAGE_TYPE) {
    return "Only images allowed"
  }

  return "Any document, image, zip, audio, video file is allowed."
}
