import { Node } from "slate"
import { StyledText } from "../../../CustomEditor"
import { TEXT_STYLES } from "../../../styles"

export function getStyles(node: Node): Set<TEXT_STYLES> {
  return new Set(Object.values(TEXT_STYLES).filter(key => key in node && !!(node as StyledText)[key]))
}

export function getStyleProps(styles: Set<TEXT_STYLES>): StyledText {
  return Object.fromEntries([...styles].map(style => [style, true]))
}
