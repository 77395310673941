import { createContext } from "react"
import { noop } from "lodash"
import { Flags } from "./Flags"
import { Flag } from "documents/flags/types"

export interface FlagsContextType {
  onCreateFlag: (flag: Partial<Flag>, callback?: (flag: Flag) => void) => void
  flags: Flags
}

export const FlagsContext = createContext<FlagsContextType>({
  onCreateFlag: noop,
  flags: new Flags(),
})
