import { useMemo, useState } from "react"
import { useShallow } from "zustand/react/shallow"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { reviewsSelectors } from "./store/reviews"
import { ReviewArgs } from "./store/types"
import { useReviewStore } from "./store"

export function ReviewInformation({ templateType, sectionType, plaintiffId, providerId }: ReviewArgs) {
  const reviewArgs = useMemo<ReviewArgs>(
    () => ({ templateType, sectionType, plaintiffId, providerId }) as ReviewArgs,
    [templateType, sectionType, plaintiffId, providerId]
  )
  const informationItems = useReviewStore(useShallow(reviewsSelectors.informationItemsByArgs(reviewArgs)))
  const [activeItemId, setActiveItemId] = useState<Nullable<string>>(null)

  if (!informationItems.length) return null

  return (
    <Box sx={{ marginY: 2 }}>
      {informationItems.map((item, idx) => (
        <Accordion
          key={item.id}
          expanded={activeItemId === item.id}
          onChange={() => setActiveItemId(itemId => (itemId === item.id ? null : item.id))}
          disableGutters
          sx={{ border: "1px #E040FB80 solid", boxShadow: "none", marginTop: idx === 0 ? 0 : "-1px" }}
        >
          <AccordionSummary>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <InfoOutlinedIcon sx={{ fill: "#E040FB80", mt: "-2px" }} />
              <Typography variant="body2SemiBold" sx={{ flexShrink: 0, textTransform: "capitalize" }}>
                {item.category.replaceAll("_", " ")}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{item.explanation}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}
