import * as _ from "lodash"
import React, { useCallback } from "react"
import Box from "@mui/material/Box"
import RemoveIcon from "@mui/icons-material/Close"
import { CheckboxInput, InputField, SelectInput } from "common/form-components"
import { PlaintiffFormProps } from "../../types"
import { MultiSelectAutoComplete, OptionProps } from "evenup-ui/MultiSelectAutoComplete"
import { CHECKBOX_CENTERING_OFFSET } from "requests/RequestForm/constants"
import {
  PRONOUN_SELECT_OPTIONS,
  PLAINTIFF_ROLE_SELECT_OPTIONS,
  DEPENDENT_STATUS_KEY,
  EMPTY_PLAINTIFF,
} from "requests/constants"
import { StateSelect } from "common"
import { StyledEntityWrapper, StyledEntityRow, StyledRemoveIconButton } from "../styled"
import { theme } from "app/theme"
import { RequestFields } from "requests/RequestForm/types"
import { useFormContext, useWatch } from "react-hook-form"
import { requiredField } from "common/form-components/formValidationRules"
import { useIsMutating } from "@tanstack/react-query"

const RemovePlaintiffButton = ({
  showRemoveButton,
  handleRemovePlaintiff,
  index,
}: {
  showRemoveButton: boolean
  handleRemovePlaintiff: (index: number) => void
  index: number
}) => {
  const isSaving = useIsMutating() > 0

  if (showRemoveButton) {
    return (
      <StyledRemoveIconButton onClick={() => handleRemovePlaintiff(index)} disabled={isSaving}>
        <RemoveIcon />
      </StyledRemoveIconButton>
    )
  }

  return null
}

export const PlaintiffForm: React.FC<PlaintiffFormProps> = ({
  index,
  removePlaintiff,
  familyOptions,
  dependentOptions,
  minimalForm = false,
  numberOfPlaintiffs,
}): JSX.Element => {
  const dependantStatusKey = `plaintiffs.${index}.dependent_status`
  const dependentStatusValue = useWatch({ name: dependantStatusKey })

  const { setValue, getValues, control } = useFormContext()

  const handleOnIsMinorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentTarget = e.currentTarget
    const { name, checked } = currentTarget
    setValue(name, checked, { shouldDirty: true })
  }

  const handleRemovePlaintiff = useCallback(
    (indexToRemove: number) => {
      const plaintiffToRemove = getValues(`plaintiffs.${indexToRemove}`)

      removePlaintiff({
        plaintiffFirstName: plaintiffToRemove.first_name,
        plaintiffLastName: plaintiffToRemove.last_name,
        plaintiffIndex: indexToRemove,
      })
    },
    [removePlaintiff, getValues]
  )

  const handleDependentStatusChange = (allSelectedOptions: OptionProps[], selectedOption?: OptionProps) => {
    // The options "No Dependents" & "Dependent children/spouse/parents" should be mutually exclusive; i.e.
    // they should not be selected together. If one is selected, the other should be deselected.
    const newSelectedOptions =
      selectedOption?.value === DEPENDENT_STATUS_KEY.noDependents
        ? [selectedOption]
        : _.reject(allSelectedOptions, ["value", DEPENDENT_STATUS_KEY.noDependents])

    const stringifyNewDependentStatus = newSelectedOptions.map(({ value }) => value)
    setValue(dependantStatusKey, stringifyNewDependentStatus, { shouldDirty: true })
  }

  const handleDependentStatusClear = () => {
    setValue(dependantStatusKey, EMPTY_PLAINTIFF.dependent_status, { shouldDirty: true })
  }

  const dependentStatus = React.useMemo(() => {
    return dependentOptions.filter(
      ({ value: optionValue }) =>
        Array.isArray(dependentStatusValue) &&
        dependentStatusValue.length > 0 &&
        dependentStatusValue.some((value: string) => value === optionValue)
    )
  }, [dependentOptions, dependentStatusValue])

  const familyOptionsForSelectInput = React.useMemo(() => {
    return familyOptions.map(({ label, value }) => ({ display: label, key: value }))
  }, [familyOptions])

  const showRemoveButton = numberOfPlaintiffs > 1

  return (
    <Box mb={1}>
      <StyledEntityWrapper>
        <StyledEntityRow>
          <InputField<Partial<RequestFields>>
            control={control}
            name={`plaintiffs.${index}.first_name`}
            data-test={`plaintiff-first-name-${index}`}
            type="text"
            label="Plaintiff First Name"
            variant="outlined"
            size="small"
            required
            rules={{
              maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." },
              ...requiredField("This field may not be blank."), // Error message consistent with API error
            }}
            showCharacterCounter
          />
          <InputField
            control={control}
            name={`plaintiffs.${index}.last_name`}
            data-test={`plaintiff-last-name-${index}`}
            type="text"
            label="Plaintiff Last Name"
            variant="outlined"
            size="small"
            required
            rules={{
              maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." },
              ...requiredField("This field may not be blank."), // Error message consistent with API error
            }}
            showCharacterCounter
          />
          <SelectInput
            required
            control={control}
            name={`plaintiffs.${index}.pronoun`}
            data-test={`plaintiff-pronoun-${index}`}
            label="Pronoun"
            size="small"
            options={PRONOUN_SELECT_OPTIONS}
            sx={{ flex: 1 }}
            rules={{ ...requiredField() }}
          />
          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            {!minimalForm && (
              <SelectInput
                control={control}
                name={`plaintiffs.${index}.role`}
                label="Role"
                size="small"
                options={PLAINTIFF_ROLE_SELECT_OPTIONS}
                fullWidth
              />
            )}
            <RemovePlaintiffButton
              showRemoveButton={showRemoveButton}
              handleRemovePlaintiff={handleRemovePlaintiff}
              index={index}
            />
          </Box>
          {!minimalForm && (
            <>
              <StateSelect
                control={control}
                selectName={`plaintiffs.${index}.state_of_residence`}
                label="State of Residence"
                required={false}
                size="small"
              />
              <SelectInput
                control={control}
                name={`plaintiffs.${index}.family_status`}
                label="Family Status"
                options={familyOptionsForSelectInput}
                size="small"
              />
              <MultiSelectAutoComplete
                label="Dependent Status"
                name={`plaintiffs.${index}.dependent_status`}
                options={dependentOptions}
                enableClear
                noOptionsText="No results"
                autoCompletePlaceholderText=""
                value={dependentStatus}
                onChangeValue={handleDependentStatusChange}
                onClear={handleDependentStatusClear}
                disableSearch
                size="small"
                selectAllEnabled={false}
              />
              <Box marginBottom={CHECKBOX_CENTERING_OFFSET} display="flex" alignItems="center">
                <CheckboxInput
                  control={control}
                  name={`plaintiffs.${index}.is_minor`}
                  label="This plaintiff is a minor"
                  onChange={handleOnIsMinorChange}
                />
              </Box>
            </>
          )}
        </StyledEntityRow>
      </StyledEntityWrapper>
    </Box>
  )
}
