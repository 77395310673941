import { useCallback, useEffect, useState } from "react"
import Box from "@mui/material/Box"
import ExhibitList from "common/ExhibitList"
import FileDropzone from "common/form-components/files/FileDropzone"
import PendingFilesForm from "demand/DragAndDropFileUploader/PendingFilesForm"
import Button from "@mui/material/Button"
import { FILE_OPTIONS } from "demand/constants"
import { ExhibitDto } from "demand/Exhibits/Exhibit"
import { CaseFacts } from "api/services/case/types"
import { FileToUploadType } from "common/form-components/files/interfaces"
import { fileToUploadObject } from "../../utils"
import { useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { useExhibitMutations } from "../useExhibitMutations"
import { useUploadFiles } from "./useUploadFiles"
import { useCaseFactsSave } from "demand/CaseFacts/CaseFactsSaveProvider"
import usePrompt from "hooks/usePrompt"

function getFileExhibits(exhibits: ExhibitDto[]): ExhibitDto[] {
  return exhibits.filter(exhibit => !!exhibit.file)
}

interface CaseFactsFilesProps {
  caseId: CaseFacts["pk"]
  exhibits: ExhibitDto[]
}

export function CaseFactsFiles({ caseId, exhibits }: CaseFactsFilesProps): JSX.Element {
  const [filesToUpload, setFilesToUpload] = useState<FileToUploadType[]>([])
  const [files, setFiles] = useState(getFileExhibits(exhibits))

  const queryClient = useQueryClient()
  const uploadFiles = useUploadFiles({ caseId, files: filesToUpload, reset: () => setFilesToUpload([]) })

  useCaseFactsSave(uploadFiles)

  useEffect(() => {
    setFiles(getFileExhibits(exhibits))
  }, [exhibits])

  const { deleteFileMutation } = useExhibitMutations()

  const handleFileDelete = useCallback(
    async (exhibit: ExhibitDto) => {
      await deleteFileMutation({ exhibitId: exhibit.pk, caseId })
      setFiles(files => files.filter(file => file.pk !== exhibit.pk))
    },
    [caseId, deleteFileMutation]
  )

  const handleFilesAdded = useCallback((newFiles: (File | FileToUploadType)[]) => {
    setFilesToUpload(currentFiles => [...currentFiles, ...newFiles.map(fileToUploadObject)])
  }, [])

  const handleFilesSaved = useCallback(() => queryClient.invalidateQueries([queryKeys.facts]), [queryClient])

  const handleUploadFiles = useCallback(async () => {
    await uploadFiles()
    setFilesToUpload([])
    queryClient.invalidateQueries([queryKeys.facts])
  }, [uploadFiles, queryClient])

  usePrompt(
    "You have files that are not yet uploaded. Are you sure you want to leave this page?",
    filesToUpload.length > 0
  )

  return (
    <>
      {files.length > 0 && (
        <Box>
          <ExhibitList
            exhibits={files}
            caseId={caseId}
            fileTypeMap={FILE_OPTIONS}
            onDelete={handleFileDelete}
            onSuccessfulSave={handleFilesSaved}
          />
        </Box>
      )}

      <Box>
        <FileDropzone onDrop={handleFilesAdded} />
        {!!filesToUpload.length && (
          <>
            <PendingFilesForm filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} />
            <Button
              disabled={filesToUpload.some(file => !file.documentType)}
              onClick={handleUploadFiles}
              variant="outlined"
              data-test="add-file-button"
            >
              Add File{filesToUpload.length > 1 ? "s" : ""}
            </Button>
          </>
        )}
      </Box>
    </>
  )
}
