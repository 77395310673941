import { Box } from "@mui/material"
import { Appointment, Provider } from "documents/types"
import { formatDate } from "utils"
import { documentActions, useDocumentStore } from "documents/store"
import { useCallback } from "react"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { StyledProviderName } from "./styled"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { documentsService } from "api/services/documents"

interface TreatmentCalendarDateProviderDataProps {
  providerData: Provider & {
    appointmentId: Appointment["id"]
    timeOfService: Appointment["timeOfService"]
  }
  onDateClick: () => void
}

export const TreatmentCalendarDateProviderData = ({
  providerData,
  onDateClick,
}: TreatmentCalendarDateProviderDataProps): JSX.Element => {
  const { appointmentId, color, timeOfService, name } = providerData
  const documentId = useDocumentStore(state => state.documentId)
  const requestId = useDocumentStore(state => state.questionnaireId)
  const { data: medchronTile } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => {
      if (documentId) {
        return documentsService.getMedchronTile({ documentId: documentId })
      }
    },
    {
      enabled: !!documentId,
    }
  )

  const handleDateClick = useCallback(() => {
    onDateClick()

    documentActions.setCurrentAppointment(appointmentId)

    if (!medchronTile) return

    amplitudeApm.trackEvent(
      new MedicalChronologyAnalyticEvent(
        MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyDateInCalendarClicked,
        { document_id: documentId, request_id: requestId, documentType: medchronTile.questionnaireType }
      )
    )
  }, [appointmentId, onDateClick, documentId, requestId, medchronTile])

  return (
    <Box key={appointmentId} display="flex" justifyContent="flex-start" alignItems="center" gap={1} mb={0.5}>
      <Box width="14px" height="14px" sx={{ background: color, borderRadius: "50%" }} />
      <StyledProviderName onClick={handleDateClick}>
        {timeOfService ? `(${formatDate(`1 Jan ${timeOfService}`, "HH:mm aaa").toUpperCase()}) ` : ""}
        {name}
      </StyledProviderName>
    </Box>
  )
}
