import { DocumentStructureDefinition } from "common/types/documentStructure"
import { Query } from "@tanstack/react-query"

export const addItemIntoDocumentStructuresCache =
  <T extends DocumentStructureDefinition>(item: T) =>
  (query: Query) => {
    const currentData = query.state.data as { items: T[] }

    query.setData({
      ...currentData,
      items: [item, ...currentData.items],
    })
  }
