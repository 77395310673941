import { bulkDownloadDownloadFile, bulkDownloadGetStatus, downloadRequestFiles } from "api"
import { isString } from "lodash"
import { useRef, useState } from "react"
import { downloadFileBlob } from "requests/utils"

const ONE_SECOND = 1000
type DownloadStatus = "NOT_STARTED" | "IN_PROGRESS" | "SUCCESS" | "FAILURE"

const useAsyncDownload = () => {
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus | null>(null)
  const [downloadError, setDownloadError] = useState<string>("")
  const [downloadProcessing, setDownloadProcessing] = useState<boolean>(false)
  const downloadCancelled = useRef<boolean>(false)

  const startDownload = async (requestId: number, type: string, fileName: string) => {
    setDownloadProcessing(true)
    downloadCancelled.current = false
    try {
      // Step 1: Tell the backend to process the download
      const response = await downloadRequestFiles(requestId, type)
      const downloadTicketId = response.id
      setDownloadStatus(response.processing_status)

      // Step 2: Check download status
      while (!downloadCancelled.current) {
        // If it was already successful, don't get the status again
        const { processing_status } =
          response.processing_status === "SUCCESS"
            ? { processing_status: "SUCCESS" }
            : await bulkDownloadGetStatus(downloadTicketId)
        setDownloadStatus(processing_status)
        if (processing_status === "SUCCESS") {
          if (downloadCancelled.current) {
            return
          }
          // Step 3: Download file
          const response = await bulkDownloadDownloadFile(downloadTicketId)
          const fileBlob = await response.blob()
          downloadFileBlob(`${fileName}-${requestId}`, fileBlob)
          setDownloadProcessing(false)
          break
        } else if (processing_status === "IN_PROGRESS" || processing_status === "NOT_STARTED") {
          // Repeat the check if the download is still in progress
          await new Promise(resolve => setTimeout(resolve, ONE_SECOND)) // Wait for 1 second before checking again
        } else {
          // Expecting processing status to be "FAILURE"
          throw new Error()
        }
      }
    } catch (error) {
      if (isString(error)) {
        setDownloadError(error)
      }
      setDownloadError(
        "Something went wrong downloading all files. Try downloading individual files and report an issue if your problem persists."
      )
    }
  }

  const cancelDownload = () => {
    downloadCancelled.current = true
  }

  return { downloadStatus, downloadError, downloadProcessing, startDownload, cancelDownload }
}

export default useAsyncDownload
