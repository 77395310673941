import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { isUndefined } from "lodash"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import { RevisionRequestSerializer, RevisionRequestDeserializer } from "./serializers"
import {
  RevisionServiceOptions,
  RevisionServiceArgs,
  RevisionRequestDataFields,
  RevisionRequestFullDto,
  RevisionRequestPartialDto,
  RevisionRequestCancelledDto,
} from "./types"

enum REVISION_API_PATHS {
  BASE = "intake",
  REQUEST = "revision_request",
}

class RevisionService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(
    options: RevisionServiceOptions,
    path: REVISION_API_PATHS = REVISION_API_PATHS.REQUEST
  ): string {
    const pathParts = ["", REVISION_API_PATHS.BASE, options.requestId, path, options.id]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  createRevisionRequest = withRequestSerializer(
    RevisionRequestSerializer.newToJSON,
    withResponseSerializer(
      RevisionRequestDeserializer.fromJSON,
      ({ data, ...options }: RevisionServiceArgs<RevisionRequestDataFields>) => {
        return handleEmptyResponse(
          this.apiService.create<RevisionRequestDataFields, RevisionRequestFullDto>(
            data,
            this.getPath(options)
          )
        )
      }
    )
  )

  updateRevisionRequest = withRequestSerializer(
    RevisionRequestSerializer.toPartialJSON,
    withResponseSerializer(
      RevisionRequestDeserializer.fromJSON,
      ({ data, ...options }: RevisionServiceArgs<RevisionRequestPartialDto>) => {
        return handleEmptyResponse(
          this.apiService.update<RevisionRequestPartialDto, RevisionRequestFullDto>(
            data,
            this.getPath({ ...options, id: data.pk })
          )
        )
      }
    )
  )

  cancelRevisionRequest = withRequestSerializer(
    RevisionRequestSerializer.toCancelledJSON,
    withResponseSerializer(
      RevisionRequestDeserializer.fromJSON,
      ({ data, ...options }: Required<RevisionServiceArgs<RevisionRequestCancelledDto>>) => {
        return handleEmptyResponse(
          this.apiService.update<RevisionRequestCancelledDto, RevisionRequestFullDto>(
            data,
            this.getPath(options)
          )
        )
      }
    )
  )

  getRevisionRequests = withResponseSerializer(
    RevisionRequestDeserializer.fromListJSON,
    (options: RevisionServiceArgs<null>) => {
      return handleEmptyResponse(
        this.apiService.get<null, RevisionRequestFullDto[]>(null, this.getPath(options))
      )
    }
  )
}

export const revisionService = new RevisionService(apiService)
