import { Box, Dialog } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { getFeaturePermissions } from "api"
import LinkButton from "common/buttons/LinkButton"
import PaginatedTable from "common/tables/PaginatedTable"
import { Column } from "common/tables/types"
import { useDialog } from "hooks/useDialog"
import useUser from "hooks/useUser"
import { noop } from "lodash"
import { useState } from "react"
import { queryKeys } from "react-query/constants"
import { EditPermissionsPopover } from "./EditPermissionsPopover"
import {
  AuthorizedFirmsCell,
  AuthorizedRoleCell,
  AuthorizedUsersCell,
  DisplayNameCell,
} from "./FeaturePermissionsCells"
import { FeaturePermission, FeaturePermissionType } from "./types"

const getColumns = (onEditClick: (feature: FeaturePermission) => void) => {
  const items: Column<FeaturePermission>[] = [
    {
      id: "display_name",
      text: "Feature Permission",
      align: "left",
      cellContentComponent: ({ record }) => {
        return <DisplayNameCell permission={record} />
      },
    },
    {
      id: "name",
      text: "Lookup Name",
      align: "left",
      cellContentComponent: ({ record }) => {
        return record.name
      },
    },
    {
      id: "authorized_users",
      text: "Authorized Users",
      align: "left",
      cellContentComponent: ({ record }) => {
        if (record.enable_for_all) {
          return "All Users"
        }
        return <AuthorizedUsersCell data={record.authorized_users} />
      },
    },
    {
      id: "authorized_firms",
      text: "Authorized Firms",
      align: "left",
      cellContentComponent: ({ record }) => {
        if (record.enable_for_all) {
          return "All Firms"
        }
        return <AuthorizedFirmsCell data={record.authorized_firms} />
      },
    },
    {
      id: "authorized_role",
      text: "Authorized Roles",
      align: "left",
      cellContentComponent: ({ record }) => {
        if (record.enable_for_all) {
          return "All Roles"
        }
        return <AuthorizedRoleCell data={record.authorized_roles} />
      },
    },
    {
      id: "actions",
      text: "Actions",
      align: "right",
      cellContentComponent: ({ record }) => {
        return (
          <LinkButton onClick={() => onEditClick(record)} data-test="edit-button">
            Edit
          </LinkButton>
        )
      },
    },
  ]
  return items
}

interface Props {
  featureType: FeaturePermissionType
}

export const FeaturePermissionsTable = ({ featureType }: Props): JSX.Element => {
  const {
    isOpen: isEditingFormOpen,
    openDialog: openEditingFormDialog,
    closeDialog: closeEditingFormDialog,
  } = useDialog()
  const { user } = useUser()

  const [pageOn, setPageOn] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(50)
  const [editingPermission, setEditingPermission] = useState<Nullable<FeaturePermission>>(null)

  const { data: featurePermissionsData } = useQuery(
    [queryKeys.featurePermissions, { page: pageOn + 1, page_size: pageSize, feature_type: featureType }],
    async () => {
      return await getFeaturePermissions({
        page: pageOn + 1,
        page_size: pageSize,
        feature_type: featureType,
      })
    }
  )

  const featurePermissions = featurePermissionsData?.results ?? []

  const handleEditClick = (featurePermission: FeaturePermission) => {
    setEditingPermission(featurePermission)
    openEditingFormDialog()
  }

  return (
    <Box data-test={`table-container-${featureType}`}>
      <PaginatedTable
        columns={getColumns(handleEditClick)}
        records={featurePermissions}
        onSortClick={noop}
        sortDirection={"asc"}
        sortBy={"name"}
        page={pageOn}
        totalCount={featurePermissionsData?.count ?? 0}
        onPageChange={setPageOn}
        onRowsPerPageChange={setPageSize}
        userRole={user.role}
        pageSize={pageSize}
      />
      <Dialog open={isEditingFormOpen} onClose={closeEditingFormDialog} maxWidth="md" fullWidth={true}>
        <EditPermissionsPopover
          featureType={featureType}
          permission={editingPermission as FeaturePermission}
          onClose={closeEditingFormDialog}
        />
      </Dialog>
    </Box>
  )
}
