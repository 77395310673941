import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Publish from "@mui/icons-material/Publish"
import { makeStyles } from "tss-react/mui"
import React, { useEffect, useState } from "react"
import { firmService } from "api/services/firm"
import { FirmLogo as FirmLogoModel } from "common/models/firm"

const useStyles = makeStyles()(theme => ({
  logoWrapper: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    position: "relative",
  },
  logo: {
    width: "100%",
    height: "100%",
    "& > img": {
      objectFit: "contain",
    },
  },
  emptyLogo: {
    opacity: 0.2,
  },
  backdrop: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    top: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  upload: {
    background: "rgba(0,0,0,0.2)",
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    transition: "background 50ms ease, opacity 150ms ease",
    opacity: 0,
    "&:hover": {
      background: "rgba(0,0,0,0.38)",
      opacity: 1,
    },
  },
  uploadActive: {
    opacity: 1,
  },
  uploadIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.common.white,
  },
  uploadInput: {
    display: "none",
  },
}))

interface FirmLogoProps {
  firmId: Nullable<number>
  editable: boolean
  isUploading: boolean
  onChange: (file: File) => void
}

export const FirmLogo: React.FC<FirmLogoProps> = ({ firmId, onChange, editable, isUploading }) => {
  const { classes, cx } = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [preview, setPreview] = useState<Nullable<string>>(null)
  const [localPreview, setLocalPreview] = useState<Nullable<string>>(null)
  const [fileToUpload, setFileToUpload] = useState<Nullable<File>>(null)
  const isEmptyPreview = !isLoading && !preview && !localPreview
  const isLoaded = !isLoading && !isUploading

  const handleLogoChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.files?.length) {
      const [file] = event.target.files
      setFileToUpload(file)
      onChange && onChange(file)
    }
  }

  useEffect(() => {
    if (!fileToUpload) {
      return
    }

    const localUrl = URL.createObjectURL(fileToUpload)
    setLocalPreview(localUrl)

    return () => URL.revokeObjectURL(localUrl)
  }, [fileToUpload])

  useEffect(() => {
    let logo: Nullable<FirmLogoModel> = null

    async function fetchImage() {
      if (firmId === null) {
        return
      }

      setIsLoading(true)
      setPreview(null)

      try {
        logo = await firmService.getFirmLogo({ firmId })
        setPreview(logo.dataUrl)
      } catch (e) {
        setPreview(null)
      }

      setIsLoading(false)
    }

    fetchImage()

    return () => {
      if (logo) {
        logo.clear()
      }
    }
  }, [firmId])

  return (
    <Box className={classes.logoWrapper}>
      <Avatar
        className={cx(classes.logo, editable && isEmptyPreview && classes.emptyLogo)}
        variant="rounded"
        src={localPreview || preview || undefined}
        alt="logo"
      />
      {firmId && !isLoaded && (
        <div className={classes.backdrop}>
          <CircularProgress color="secondary" />
        </div>
      )}
      {editable && firmId && !isLoading && (
        <label className={cx(classes.backdrop, classes.upload, isEmptyPreview && classes.uploadActive)}>
          <input type="file" accept="image/*" onChange={handleLogoChange} className={classes.uploadInput} />
          <Publish className={classes.uploadIcon} />
        </label>
      )}
    </Box>
  )
}
