import { CustomEditor, CustomText, VariableElement } from "common/form-components/rich-text/CustomEditor"
import { isVariable } from "common/form-components/rich-text/queries"
import { uniq } from "lodash"
import { Editor, NodeEntry, Text, Transforms } from "slate"
import { Range } from "slate"
import { v4 } from "uuid"

export function getLeaves(editor: CustomEditor): NodeEntry<CustomText | VariableElement>[] {
  if (editor.selection) {
    return [
      ...Editor.nodes(editor, {
        at: editor.selection,
        match: (node): node is CustomText | VariableElement => Text.isText(node) || isVariable(editor, node),
      }),
    ]
  }

  return []
}

export function insertFlag(editor: CustomEditor, flagId: string): void {
  if (!editor.selection || Range.isCollapsed(editor.selection)) return

  const id = `${v4()}_${v4()}`

  editor.addMark("tempFlag", id)

  const leaves = getLeaves(editor)

  for (const [leaf, at] of leaves) {
    const flags = uniq((leaf.flags || []).concat(flagId))

    Transforms.setNodes(editor, { flags }, { at })
  }

  editor.removeMark("tempFlag")

  if (editor.selection) {
    const at = Range.isBackward(editor.selection) ? editor.selection.anchor : editor.selection.focus

    editor.select(at)
    editor.removeMark("flags")
  }
}
