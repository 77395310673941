import { useState, useEffect } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Fab from "@mui/material/Fab"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import SaveAlt from "@mui/icons-material/SaveAlt"
import Search from "@mui/icons-material/Search"
import CheckIcon from "@mui/icons-material/Check"
import ErrorIcon from "@mui/icons-material/Error"
import { makeStyles } from "tss-react/mui"
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "../react-query/constants"

import { getCase, getRelevantDocuments, generateDemand } from "../api"

import CaseResult from "../common/case-result/CaseResult"
import { GenericError } from "../common"

const STATES = {
  IDLE: "",
  DOWNLOADING: "inProgress",
  DONE: "success",
  ERROR: "error",
}

const useStyles = makeStyles()(theme => ({
  card: {
    marginTop: theme.spacing(2),
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  fabArea: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab: {
    position: "static",
    marginLeft: "10px",
  },
  emptyCases: {
    padding: theme.spacing(4),
  },
}))

const NoSavedCases = function () {
  const { classes } = useStyles()

  return (
    <Paper className={classes.emptyCases}>
      <Typography gutterBottom variant="h5">
        No cases saved under this case name.
      </Typography>
      <Typography gutterBottom component="p">
        Find and add cases through search!
      </Typography>
    </Paper>
  )
}

export function Case() {
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { id: caseId } = useParams()

  // we want to show different UI for these states
  const [status, setStatus] = useState({ state: STATES.IDLE, message: "" })

  const { isLoading, data: incident, error } = useQuery([queryKeys.case, caseId], getCase)
  const documentIds = incident?.relevant_documents.map(document => document.elasticsearch_id)

  const {
    isLoading: loadingDocuments,
    data: documents,
    error: documentsError,
  } = useQuery([queryKeys.documents, documentIds], getRelevantDocuments, {
    enabled: !!documentIds?.length,
  })

  const startSearchSession = caseId => {
    navigate(`/search/${caseId}`)
  }

  const getRelevantCase = data => {
    data._is_relevant = true
    return <CaseResult data={data} key={data.id} caseId={caseId} />
  }

  // clear the success or error states
  useEffect(() => {
    let timeout

    if (status.state === STATES.DONE || status.state === STATES.ERROR) {
      timeout = setTimeout(() => {
        setStatus({ state: STATES.IDLE })
      }, 3000)
    }

    return () => clearTimeout(timeout)
  }, [status])

  const getDemand = async caseId => {
    try {
      setStatus({ state: STATES.DOWNLOADING })
      const response = await generateDemand(caseId)

      // create file url based on blob
      const fileBlob = await response.blob()
      const url = window.URL.createObjectURL(new Blob([fileBlob]))
      // create a link element
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `${caseId}.docx`)

      // add link to body and click on it
      document.body.appendChild(link)
      link.click()
      // clean up the link
      link.parentNode.removeChild(link)
      setStatus({ state: STATES.DONE })
    } catch (err) {
      // we should throw an error here
      // eslint-disable-next-line no-console
      console.error(err)
      setStatus({ state: STATES.ERROR })
    }
  }

  if (error) return <GenericError error={error} />

  return (
    <Box>
      {!isLoading && (
        <>
          <Typography variant="h3">View Case</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell size="small">Case Name</TableCell>
                <TableCell>State</TableCell>
                <TableCell>County</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={incident.pk}>
                <TableCell>
                  <Typography noWrap={true}>{incident.date_of_incident}</Typography>
                </TableCell>
                <TableCell>{incident.name}</TableCell>
                <TableCell>{incident.state}</TableCell>
                <TableCell>{incident.county}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="h3">Relevant Cases</Typography>
          {!documentIds?.length && <NoSavedCases />}
        </>
      )}
      {!loadingDocuments && documents?.results?.map(getRelevantCase)}
      {documentsError && "An error has occurred: " + documentsError.message}
      <div className={classes.fabArea}>
        <Fab aria-label="add" color="secondary" className={classes.fab}>
          <Search onClick={() => startSearchSession(incident.pk)} />
        </Fab>
        <Fab aria-label="add" color="secondary" className={classes.fab}>
          {status.state === STATES.IDLE && <SaveAlt onClick={() => getDemand(incident.pk)} />}
          {status.state === STATES.DOWNLOADING && <CircularProgress />}
          {status.state === STATES.DONE && <CheckIcon />}
          {status.state === STATES.ERROR && <ErrorIcon />}
        </Fab>
      </div>
    </Box>
  )
}
