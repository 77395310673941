import { useCallback, useState } from "react"
import IconButton from "@mui/material/IconButton"
import { makeStyles } from "tss-react/mui"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { VerticalCenterBox } from "../../common/FlexBox"
import { CustomDamageSection } from "./Firm"
import { CustomDamageSectionType } from "./enums"
import { TemplateSectionForm } from "./TemplateSectionForm"
import { ProjectionItemWithChildren } from "common/nested-list/types"
import { ITEM_REF } from "common/nested-list/constants"

const useStyles = makeStyles()(theme => ({
  invalid: {
    border: "1px solid red",
    borderRadius: theme.shape.borderRadius,
    padding: "5px",
  },
  valid: {
    padding: "5px",
  },
  actionButton: {
    padding: theme.spacing(1),
  },
  card: {
    "& .section-controls": {
      transition: "50ms opacity ease",
      opacity: 0.4,
    },
    "&:hover .section-controls": {
      opacity: 1,
    },
  },
}))

type TemplateSectionProps = {
  item: ProjectionItemWithChildren<
    Omit<CustomDamageSection, "children"> & { children: CustomDamageSection[] }
  >
  onChange: (item: CustomDamageSection) => void
  sectionTypes: ValueOptions<CustomDamageSectionType>
  onDelete: (item: CustomDamageSection) => void
  compact?: boolean
}

export function TemplateSection({
  item,
  onDelete,
  onChange,
  sectionTypes,
  compact,
}: TemplateSectionProps): JSX.Element {
  const section = item[ITEM_REF]
  const { classes } = useStyles()
  const [isEditing, setIsEditing] = useState(!compact && section.title == null)

  const validate = useCallback((data: CustomDamageSection): boolean => {
    const valid = data.title !== null && data.title !== ""
    return valid
  }, [])

  const [isValid, setIsValid] = useState(validate(section))

  const handleSectionChange = useCallback(
    (section: CustomDamageSection) => {
      const isValid = validate(section)

      if (isValid) {
        setIsValid(true)
        setIsEditing(false)
        onChange(section)
      } else {
        setIsValid(false)
      }
    },
    [onChange, validate]
  )

  const handleSectionDelete = useCallback(() => {
    onDelete(section)
  }, [section, onDelete])

  return (
    <div className={isValid ? classes.valid : classes.invalid}>
      {isEditing ? (
        <TemplateSectionForm section={section} sectionTypes={sectionTypes} onChange={handleSectionChange} />
      ) : (
        <>
          <VerticalCenterBox sx={{ justifyContent: "space-between" }} className={classes.card}>
            {section.title}
            <div className="section-controls">
              <IconButton className={classes.actionButton} onClick={() => setIsEditing(true)}>
                <EditIcon />
              </IconButton>
              <IconButton className={classes.actionButton} onClick={handleSectionDelete}>
                <DeleteIcon />
              </IconButton>
            </div>
          </VerticalCenterBox>
        </>
      )}
    </div>
  )
}
