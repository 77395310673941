import styled from "@emotion/styled"
import Tab from "@mui/material/Tab"

interface StyledTabProps {
  active?: string
}

export const StyledTab = styled(Tab, { shouldForwardProp: prop => prop !== "active" })<StyledTabProps>(
  ({ theme, active }) => ({
    borderBottomWidth: active && "2px",
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
    opacity: active && "1",
  })
)

export const EmptyCasesWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(4),
}))

export const FavoriteCasesPaginationWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(2),
}))
