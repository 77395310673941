import { REQUEST_METHODS, makeApiCall } from "apiHelper"

// Update assignees on a request
export type UpdateRequestInternalAssigneesVariables = {
  requestId: number
  data: Array<number>
}
export const updateRequestInternalAssignees = async ({
  requestId,
  data,
}: UpdateRequestInternalAssigneesVariables) => {
  const options = {
    path: `/intake/${requestId}/assign/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return makeApiCall(options)
}

// Update operators on a request
// Operators are anyone who "drafted", "reviewed", or "reworked" a request
export type UpdateRequestOperatorsVariables = {
  requestId: number
  reviewed_by?: number
  drafted_by?: number
  reworked_by?: number
}

export const updateRequestOperators = async ({
  requestId,
  reviewed_by,
  drafted_by,
  reworked_by,
}: UpdateRequestOperatorsVariables) => {
  const options = {
    path: `/intake/${requestId}/operators/`,
    method: REQUEST_METHODS.POST,
    data: {
      reviewed_by,
      drafted_by,
      reworked_by,
    },
  }

  return makeApiCall(options)
}

// New functions below here
