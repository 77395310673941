import { Link, useParams } from "react-router-dom"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { fetchRequest_DEPRECATED } from "api"
import useUser from "../hooks/useUser"
import { UNEDITABLE_WARNING_ANNOTATION_OFF, UNEDITABLE_WARNING_ANNOTATION_ON_POST_SUBMIT } from "./constants"

const useStyles = makeStyles()(theme => ({
  container: {
    display: "grid",
    placeItems: "center",
    padding: theme.spacing(2),
  },
  copy: {
    textAlign: "center",
    "& > p": {
      margin: theme.spacing(2, 0),
    },
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(4),
  },
}))

export function Confirmation() {
  const { classes } = useStyles()
  const { id: requestId } = useParams()
  const { data: requestInfo } = useQuery([queryKeys.request, requestId], fetchRequest_DEPRECATED)
  const { user } = useUser()

  const annotationOn = Boolean(requestInfo?.firm?.enable_annotations)
  const warningText =
    annotationOn && user.isExternal
      ? UNEDITABLE_WARNING_ANNOTATION_ON_POST_SUBMIT
      : UNEDITABLE_WARNING_ANNOTATION_OFF

  return (
    <Box className={classes.container}>
      <Box className={classes.copy}>
        <Typography variant="h4" component="h1" data-test="thank-you-for-submitting">
          <strong>Request Submitted!</strong>
        </Typography>
        <Typography variant="body1" component="p">
          <strong>PLEASE NOTE</strong>
        </Typography>
        <Typography variant="body2" component="p">
          {warningText}
        </Typography>
        <Box className={classes.actions}>
          <Button to="../.." component={Link} variant="text" color="secondary">
            Back to request list
          </Button>
          <Button to="../" component={Link} variant="text">
            View request details
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
