import { useState, useEffect, useCallback, useMemo, useRef } from "react"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { makeStyles } from "tss-react/mui"
import HelpOutline from "@mui/icons-material/HelpOutline"
import { queryKeys } from "react-query/constants"
import {
  getFutureExpenses,
  createFutureExpense,
  reorderFutureExpenses,
  patchPlaintiffInfo,
  updateCase,
} from "api"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FutureExpense } from "./FutureExpense"
import EmptyState from "../../common/tables/EmptyState"
import { formSectionsToRoutes } from "../constants"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { amountInDollars, reorderImmutable } from "utils"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useFirm } from "hooks/useFirm"
import useUser from "hooks/useUser"
import { CustomDamageSectionType } from "settings/Firm/enums"
import { replaceMatches, editorHasVariable, editorHasText } from "common/form-components/rich-text/utils"
import { useDebouncedCallback } from "use-debounce"
import useCase from "hooks/useCase"
import { useFormContext } from "demand/context"
import { GridLayoutHeader } from "./FutureExpenseStyled"
import TextButton from "common/buttons/TextButton"
import { FUTURE_PROCEDURES, FUTURE_PROCEDURES_REPLACEMENT } from "./constants"
import { ReadOnlyCaseEditor } from "demand/components/CaseEditor"
import Alert from "@mui/material/Alert"
import { Link } from "react-router-dom"
import { INTERNAL_ROLES } from "common/models/roles"
import { CASE_STEP_STATUS } from "demand/steps/types"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { useOutletContext } from "react-router-dom"
import { SummaryItem } from "demand/Providers/SummaryItem"
import { theme } from "app/theme"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"
import SimpleDemandSkipSectionBanner from "demand/SimpleDemandBanner"

const useStyles = makeStyles()({
  helpIcon: {
    fontSize: "1rem",
    verticalAlign: "text-bottom",
  },
})

const PreviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}))

const PreviewBox = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}))

const PLACEHOLDER_STATE = {
  cost: 0.0,
  procedure_name: "New Expense",
  number_of_years: 0,
  annual_frequency: 0,
}

const HeaderWithTooltip = ({ title, tooltipText }) => {
  const { classes } = useStyles()
  return (
    <Box display="flex" flexDirection="row" justifyContent="right">
      <Box>{title}</Box>
      <Box display="flex" alignItems="center">
        <Tooltip placement="bottom" arrow title={tooltipText} data-test="tooltip">
          <HelpOutline className={classes.tooltip} />
        </Tooltip>
      </Box>
    </Box>
  )
}

export function FutureExpenses({ lastVisited }) {
  const queryClient = useQueryClient()
  const { caseId, handleUpdateStepStatus } = useFormContext()
  const [toEdit, setToEdit] = useState(false)
  const { showErrorMessage } = useHandleMessages()
  const { currentPlaintiff } = useOutletContext()
  const [expenses, setExpenses] = useState([])
  const [futureExpensesText, setFutureExpensesText] = useState(currentPlaintiff?.futureExpensesText ?? "")
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)
  const { classes } = useStyles()

  const updateStepStatus = useRef(handleUpdateStepStatus)
  updateStepStatus.current = handleUpdateStepStatus

  const { user } = useUser()
  const { caseObj, isLoading: isCaseLoading, updateCache: updateCaseCache } = useCase(caseId)
  const { firm, isLoading: isFirmLoading } = useFirm(caseObj?.firm?.pk)

  const createMutation = useMutation(createFutureExpense, {
    onSuccess: data => {
      if (data?.pk) {
        setToEdit(data.pk)
      }
      queryClient.invalidateQueries([queryKeys.futureExpense, caseId])
    },
  })
  const reorderMutation = useMutation(reorderFutureExpenses, {
    onError: error =>
      showErrorMessage({
        message:
          "Error re-ordering future expenses. Please try again shortly and contact support if your problem persists.",
        error,
      }),
  })

  const updatePlaintiffMutation = useMutation(patchPlaintiffInfo, {
    onError: error =>
      showErrorMessage({
        message:
          "Error updating future expenses description. Please try again shortly and contact support if your problem persists.",
        error,
      }),
    onSuccess: result => {
      queryClient.setQueryData([queryKeys.plaintiffs, caseId], oldData => {
        return oldData.map(plaintiff => {
          if (plaintiff.id !== result.pk) return plaintiff
          return {
            firstName: result.first_name,
            lastName: result.last_name,
            id: result.pk,
            futureExpensesText: result.future_expenses_text,
          }
        })
      })
    },
  })

  const updateCaseMutation = useMutation(updateCase, {
    onError: error =>
      showErrorMessage({
        message:
          "Error updating future expenses description. Please try again shortly and contact support if your problem persists.",
        error,
      }),
  })

  const debounceUpdateCase = useDebouncedCallback(text => {
    updateCaseMutation.mutate({
      id: caseId,
      updates: { future_expenses_text: text },
    })
  }, 500)

  const debounceUpdateFutureExpensesText = useDebouncedCallback((text, passedPlaintiff) => {
    updatePlaintiffMutation.mutate({
      caseId: caseId,
      plaintiffId: passedPlaintiff?.id,
      data: { future_expenses_text: text },
    })
  }, 500)

  const addExpense = () => {
    const data = { ...PLACEHOLDER_STATE, plaintiff_id: currentPlaintiff?.id }
    createMutation.mutateAsync({ caseId, data })
  }

  const saveCallback = () => {
    setToEdit(-1)
  }

  const { data: allExpenses, isLoading } = useQuery([queryKeys.futureExpense, caseId], getFutureExpenses, {
    enabled: (!!currentPlaintiff && multiPlaintiffEnabled) || !multiPlaintiffEnabled,
  })

  useEffect(() => {
    if (!allExpenses) {
      setExpenses([])
      return
    }

    let filteredExpenses = allExpenses
    if (!multiPlaintiffEnabled || !currentPlaintiff) {
      setExpenses(allExpenses)
    } else {
      filteredExpenses = allExpenses.filter(expense => expense && expense.plaintiff === currentPlaintiff.id)
      setExpenses(filteredExpenses)
    }
    queryClient.setQueryData([queryKeys.futureExpense, caseId, currentPlaintiff?.id], filteredExpenses)
  }, [currentPlaintiff, allExpenses, multiPlaintiffEnabled, queryClient, caseId])

  useEffect(() => {
    if (multiPlaintiffEnabled && currentPlaintiff) {
      setFutureExpensesText(currentPlaintiff?.futureExpensesText)
    } else {
      setFutureExpensesText(caseObj?.future_expenses_text ?? "")
    }
  }, [currentPlaintiff, multiPlaintiffEnabled, caseObj?.future_expenses_text])

  const totalFutureExpenses = useMemo(
    () =>
      expenses?.reduce(
        (total, expense) => total + expense.cost * expense.annual_frequency * expense.number_of_years,
        0
      ),
    [expenses]
  )

  useEffect(() => {
    if (expenses?.length) {
      updateStepStatus.current({ status: CASE_STEP_STATUS.COMPLETED })
    } else {
      updateStepStatus.current({ status: CASE_STEP_STATUS.STARTED })
    }
  }, [expenses])

  const handleDragEnd = useCallback(
    ({ destination, source }) => {
      // dropped outside the list or moved to same position
      if (!destination || destination.index === source.index) return

      const newExpenses = reorderImmutable(expenses, source.index, destination.index)
      reorderMutation.mutate({
        caseId,
        newIdsInOrder: newExpenses.map(expense => expense.pk),
        plaintiffId: currentPlaintiff?.id,
      })

      // Set the global cache to match the new order
      queryClient.setQueryData([queryKeys.futureExpense, caseId], prevAllExpenses => {
        const newOrder = [
          ...prevAllExpenses
            .map(expense => {
              if (currentPlaintiff?.id && expense?.plaintiff !== currentPlaintiff?.id) return expense
            })
            .filter(expense => !!expense),
          ...newExpenses,
        ]
        return newOrder
      })
      setExpenses(newExpenses)
    },
    [caseId, reorderMutation, expenses, queryClient, currentPlaintiff]
  )

  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.future_expenses
  })

  const futureMedicalExpensesSection = firm?.sections?.find(
    section => section.section_type === CustomDamageSectionType.FUTURE_EXPENSES
  )
  const templateTextData = useMemo(() => {
    if (!futureMedicalExpensesSection?.text && !futureMedicalExpensesSection?.text_json) {
      return null
    }

    return futureMedicalExpensesSection.text_json || DEFAULT_VALUE
  }, [futureMedicalExpensesSection])

  const templateHasFutureProcedures = useMemo(() => {
    return (
      Boolean(templateTextData) &&
      (editorHasVariable(templateTextData, FUTURE_PROCEDURES) ||
        editorHasText(templateTextData, FUTURE_PROCEDURES_REPLACEMENT))
    )
  }, [templateTextData])

  const canUserEditFirm = useMemo(() => {
    return [INTERNAL_ROLES.LEGALOPS_ADMIN, INTERNAL_ROLES.LEGALOPS_MANAGER].includes(user?.role)
  }, [user?.role])

  if (isLoading || isFirmLoading || isCaseLoading) return null
  return (
    <>
      <SimpleDemandSkipSectionBanner caseId={caseId} />
      {Boolean(templateTextData) && !templateHasFutureProcedures && (
        <Alert color="warning" data-test="missing-variable-warning">
          The selected firm does not use the {FUTURE_PROCEDURES_REPLACEMENT} term.{" "}
          {canUserEditFirm && (
            <>
              Click{" "}
              <Link data-test="link-to-firm" to={`/settings/firms/${caseObj?.firm?.pk}/edit`}>
                here
              </Link>{" "}
              to edit the firm.
            </>
          )}
        </Alert>
      )}
      <Box display="grid">
        <PreviewContainer>
          <PreviewBox mr={5}>
            <Box display="flex">
              <Typography component="label" variant="h6" htmlFor="future-expenses-text">
                Describe future expenses
              </Typography>
            </Box>
            <TextField
              id="future-expenses-text"
              fullWidth
              value={futureExpensesText}
              onChange={event => {
                const text = event.target.value
                if (!multiPlaintiffEnabled) {
                  updateCaseCache({ future_expenses_text: text })
                }
                setFutureExpensesText(text)
                if (!multiPlaintiffEnabled) {
                  debounceUpdateCase(text)
                } else {
                  debounceUpdateFutureExpensesText(text, currentPlaintiff)
                }
              }}
              multiline
              variant="outlined"
              minRows={6}
              helperText={`The text you type here will replace ${FUTURE_PROCEDURES_REPLACEMENT} in the preview on the right.`}
            />
          </PreviewBox>
          <PreviewBox>
            <Typography variant="h6">Preview</Typography>
            <Box>
              {templateTextData ? (
                <ReadOnlyCaseEditor
                  value={replaceMatches(templateTextData, {
                    [FUTURE_PROCEDURES_REPLACEMENT]:
                      caseObj?.future_expenses_text || FUTURE_PROCEDURES_REPLACEMENT,
                  })}
                />
              ) : (
                <div data-test="no-template-message">
                  No preview text available. This can be set in your selected firms settings by adding a
                  Future Medical Expenses section.
                </div>
              )}
            </Box>
          </PreviewBox>
        </PreviewContainer>
        <Box mt={4} mb={2}>
          <Divider />
        </Box>

        <SummaryItem
          sx={{ margin: theme.spacing(4, 0) }}
          label={
            <>
              Total Future Medical Expenses{" "}
              <Tooltip placement="top" arrow title="Sum of all future medical Expenses">
                <HelpOutline className={classes.helpIcon} />
              </Tooltip>
            </>
          }
          value={expenses?.length ? amountInDollars(totalFutureExpenses) : "N/A"}
        />

        {expenses?.length ? (
          <Box data-test="future-medical-expenses">
            <GridLayoutHeader data-test="future-medical-expenses-header">
              <Box gridColumn={2}>Procedure</Box>
              <Box align="right">Cost</Box>
              <Box>
                <HeaderWithTooltip title="# Per Year" tooltipText="Number of treatments per year." />
              </Box>
              <Box>
                <HeaderWithTooltip title="Years" tooltipText="Number of years the treatment will last." />
              </Box>
              <Box align="right">Total</Box>
            </GridLayoutHeader>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="futureExpensesList">
                {droppableProvided => (
                  <Box {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                    {expenses.map((expense, index) => {
                      const isEditing = expense.pk === toEdit
                      return (
                        <FutureExpense
                          key={expense.pk}
                          futureExpense={expense}
                          initialEdit={isEditing}
                          saveCallback={isEditing ? saveCallback : null}
                          index={index}
                        />
                      )
                    })}
                    {droppableProvided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        ) : (
          <EmptyState message="No Expenses" data-test="empty-state" />
        )}
      </Box>
      <Box m={2}>
        <TextButton onClick={addExpense} data-test="add-expense">
          + Add Expense
        </TextButton>
      </Box>
    </>
  )
}
