import React, { memo, useEffect, useMemo, useRef } from "react"
import { Route, RouteProps, Routes, RoutesProps } from "react-router-dom"
import { fullstoryApm } from "./fullstory"

function isRouteElement(child: React.ReactNode): child is React.ReactElement<RouteProps> {
  if (!React.isValidElement(child)) return false

  return child.type === Route
}

function ApmRouteComponent({
  path,
  children,
  isLeaf,
}: {
  path: string
  children: React.ReactNode
  isLeaf: boolean
}): JSX.Element {
  const isHitRef = useRef(false)
  const isLeafRef = useRef(isLeaf)
  const pathRef = useRef(path)
  pathRef.current = path
  isLeafRef.current = isLeaf

  useEffect(() => {
    if (isHitRef.current) {
      return
    }

    if (isLeafRef.current) {
      isHitRef.current = true
      fullstoryApm.trackPageView(pathRef.current)
    }
  }, [])

  return <>{children}</>
}

function wrapRoutesWithTransactionBoundary(children?: React.ReactNode, parentPath = ""): React.ReactNode {
  if (!children) return children

  return React.Children.map(children, child => {
    if (!isRouteElement(child)) return child

    const routeElement = child.props.element
    const path = child.props.path ? `${parentPath}/${child.props.path?.replace(/^\/+/, "")}` : parentPath
    const isLeafRoute = !child.props.children

    const ApmRoute = () => (
      <ApmRouteComponent path={path} isLeaf={isLeafRoute}>
        {routeElement}
      </ApmRouteComponent>
    )

    return React.cloneElement(child, {
      element: <ApmRoute />,
      children: wrapRoutesWithTransactionBoundary(child.props.children, path),
    })
  })
}

export const ApmRouter = memo(function ApmRoutes({
  children,
  location,
}: RoutesProps): React.ReactElement<typeof Routes> {
  const childrenWithTransition = useMemo(() => wrapRoutesWithTransactionBoundary(children), [children])

  return <Routes location={location}>{childrenWithTransition}</Routes>
})
