import { useCallback, useContext, useState } from "react"
import { DocumentStructureHeading } from "../types"
import { Resolver, useForm } from "react-hook-form"
import CancelIcon from "@mui/icons-material/Cancel"
import { DialogButton } from "settings/Library/TemplateForms/buttons/DialogButton"
import { CircularProgress } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import {
  StyledHeading,
  StyledHeadingTitle,
  StyledHeadingContent,
  StyledHeadingTypeSelector,
  StyledHeadingCheckbox,
} from "./styled"
import { REF_ID } from "../constants"
import { HEADING_TYPE } from "../enums"
import { SectionContext } from "../section/context"

const schema = yup
  .object({
    title: yup.string().trim().required(),
  })
  .required()

const HEADING_TYPE_OPTIONS: ValueOptions<HEADING_TYPE> = [
  { key: HEADING_TYPE.H1, display: "H1" },
  { key: HEADING_TYPE.H2, display: "H2" },
  { key: HEADING_TYPE.H3, display: "H3" },
  { key: HEADING_TYPE.SUBTITLE, display: "Subtitle" },
]

type HeadingContentActions = EitherProps<
  {
    onSave: (section: DocumentStructureHeading, sectionId: Nullable<PrimaryKey>) => Promise<void>
    onDelete: (section: DocumentStructureHeading, sectionId: Nullable<PrimaryKey>) => Promise<void>
  },
  {
    disabled: true
  }
>

export type HeadingContentProps = {
  item: DocumentStructureHeading
} & HeadingContentActions

export function HeadingContent({ item, onSave, onDelete, disabled }: HeadingContentProps): JSX.Element {
  const {
    control,
    reset,
    formState: { isSubmitting, errors, isDirty },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: item,
    mode: "all",
    resolver: yupResolver(schema) as unknown as Resolver<DocumentStructureHeading>,
  })

  const sectionContext = useContext(SectionContext)
  const sectionId = item.sectionId ?? sectionContext.sectionId

  const [isDeleting, setIsDeleting] = useState(false)
  const title = watch("title")
  const isTitleEmpty = !title

  const handleSave = handleSubmit(async heading => {
    if (disabled || isSubmitting || !isDirty) return

    const headingData: DocumentStructureHeading = {
      ...heading,
      [REF_ID]: item[REF_ID],
    }

    if (item.id !== null) {
      headingData.id = item.id
    }

    await onSave(headingData, sectionId)
    reset({}, { keepValues: true })
  })

  const handleDelete = useCallback(() => {
    if (disabled) return

    setIsDeleting(true)
    onDelete(item, sectionId)
  }, [disabled, item, onDelete, sectionId])

  const isViewOnly = disabled || isSubmitting

  return (
    <StyledHeading onSubmit={handleSave} onBlur={handleSave}>
      <StyledHeadingContent>
        <StyledHeadingTypeSelector
          control={control}
          name="headingType"
          options={HEADING_TYPE_OPTIONS}
          size="small"
          margin="none"
          disabled={isViewOnly}
        />
        <StyledHeadingTitle
          size="small"
          margin="none"
          control={control}
          name="title"
          placeholder="Header Title"
          required
          autoFocus={item.id === null}
          empty={isTitleEmpty}
          disabled={isViewOnly}
          error={!!errors.title}
        />
        {!sectionContext.repeat && (
          <StyledHeadingCheckbox
            control={control}
            name="repeat"
            size="small"
            label="Repeat for multiple plaintiffs"
            disabled={!item.sectionId || isViewOnly}
            onInput={handleSave}
          />
        )}
      </StyledHeadingContent>
      <StyledHeadingContent>
        {!disabled && (
          <DialogButton
            title="Delete?"
            icon={isDeleting ? <CircularProgress size={24} /> : <CancelIcon />}
            confirmationText="Yes, Delete"
            question={
              <span>
                Are you sure you want to delete
                <br />
                <strong>Header {title || item.title}?</strong>
              </span>
            }
            onAction={handleDelete}
            disabled={isDeleting}
          />
        )}
      </StyledHeadingContent>
    </StyledHeading>
  )
}
