export enum LIBRARY_ROW_BUTTON_NAMES {
  EDIT,
  DUPLICATE,
  ACTION,
  CANCEL,
  DELETE,
}

export const defaultLibraryRowButtons = {
  [LIBRARY_ROW_BUTTON_NAMES.EDIT]: { title: "edit" },
  [LIBRARY_ROW_BUTTON_NAMES.DUPLICATE]: { title: "Duplicate" },
  [LIBRARY_ROW_BUTTON_NAMES.ACTION]: { title: "save" },
  [LIBRARY_ROW_BUTTON_NAMES.CANCEL]: { title: "cancel" },
  [LIBRARY_ROW_BUTTON_NAMES.DELETE]: { title: "delete" },
}
