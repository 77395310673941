import { v4 } from "uuid"

export interface PerformanceMeasureEntry {
  duration: DOMHighResTimeStamp
  trailingDuration: DOMHighResTimeStamp
  name: string
}

export type PerformanceMeasureFn = () => PerformanceMeasureEntry | null

export type PerformanceMeasureHandler = (
  name: string,
  options: { initial: boolean }
) => {
  mark: () => void
  measure: PerformanceMeasureFn
}

export const getPerformance: PerformanceMeasureHandler = (name = v4(), options = { initial: true }) => {
  const key = `${name}_${v4()}`
  const initialKey = `${key}_initial`

  if (options.initial) {
    window.performance.mark(initialKey)
    window.performance.mark(key)
  }

  return {
    mark: () => window.performance.mark(key),
    measure: () => {
      try {
        const trailingMeasure = window.performance.measure(name, key)
        const overallMeasure = window.performance.measure(name, initialKey, key)

        const trailingDuration = Math.round(trailingMeasure.duration)
        const duration = Math.round(overallMeasure.duration) || trailingDuration

        return {
          name,
          duration,
          trailingDuration,
        }
      } catch {
        return null
      }
    },
  }
}
