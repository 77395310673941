import { AnnotatedExhibit, Duplicate, PartitionProvider, Split } from "./types"

type UpdateAnnotatedExhibitProps =
  | {
      oldData?: AnnotatedExhibit[]
      exhibitId: number
      update: Partial<AnnotatedExhibit> | ((exhibit: AnnotatedExhibit) => Partial<AnnotatedExhibit>)
    }
  | {
      oldData?: AnnotatedExhibit[]
      shouldExhibitUpdate: (exhibit: AnnotatedExhibit) => boolean
      update: Partial<AnnotatedExhibit> | ((exhibit: AnnotatedExhibit) => Partial<AnnotatedExhibit>)
    }

export const updateAnnotatedExhibits = (props: UpdateAnnotatedExhibitProps): AnnotatedExhibit[] => {
  // to satisfy typescript
  if (!props.oldData) return []

  if ("exhibitId" in props) {
    return props.oldData.map(exhibit => {
      if (exhibit.pk !== props.exhibitId) {
        return exhibit
      }
      const updatesToCommit = typeof props.update === "function" ? props.update(exhibit) : props.update
      return {
        ...exhibit,
        ...updatesToCommit,
      }
    })
  }
  return props.oldData.map(exhibit => {
    if (props.shouldExhibitUpdate(exhibit)) {
      const updatesToCommit = typeof props.update === "function" ? props.update(exhibit) : props.update
      return {
        ...exhibit,
        ...updatesToCommit,
      }
    }

    return exhibit
  })
}

type MapUpdatePartitionProps =
  | {
      partitions: Split[] | Duplicate[]
      partitionId: number
      update: Partial<Duplicate> | Partial<Split>
    }
  | {
      partitions: Split[] | Duplicate[]
      shouldPartitionUpdate: (partition: Split | Duplicate) => boolean
      update: Partial<Duplicate> | Partial<Split>
    }

export const mapUpdatePartition = (props: MapUpdatePartitionProps): Split[] | Duplicate[] => {
  if ("partitionId" in props) {
    return props.partitions.map(partition => {
      if (partition.pk !== props.partitionId) {
        return partition
      }
      return { ...partition, ...props.update }
    })
  }

  return props.partitions.map(partition => {
    if (props.shouldPartitionUpdate(partition)) {
      return { ...partition, ...props.update }
    }
    return partition
  })
}

export const getPartitionProvidersFromPartitions = (
  partitions: Split[] | Duplicate[],
  currentPartitionProviders: PartitionProvider[] = []
): PartitionProvider[] => {
  const partitionProviders = [...currentPartitionProviders]

  partitions.forEach(partition => {
    if (partition.provider && !partitionProviders.some(provider => provider.pk === partition.provider?.pk)) {
      partitionProviders.push(partition.provider)
    }
  })

  return partitionProviders
}

export const A_FIRST = -1
export const B_FIRST = 1
export const EQUAL = 0
type SortResult = typeof A_FIRST | typeof B_FIRST | typeof EQUAL
interface CompareablePageRange {
  start_page?: Nullable<number>
  end_page?: Nullable<number>
}
interface CompletePageRange {
  start_page: number
  end_page: number
}

const getCompletePageRange = (range: CompareablePageRange): CompletePageRange => ({
  start_page: range.start_page ?? NaN,
  end_page: range.end_page ?? NaN,
})

export const comparePageRanges = (rangeA: CompareablePageRange, rangeB: CompareablePageRange): SortResult => {
  const a = getCompletePageRange(rangeA)
  const b = getCompletePageRange(rangeB)

  if (Number.isNaN(a.start_page) && !Number.isNaN(b.start_page)) {
    return B_FIRST
  }

  if (!Number.isNaN(a.start_page) && Number.isNaN(b.start_page)) {
    return A_FIRST
  }

  if (a.start_page < b.start_page) {
    return A_FIRST
  }

  if (a.start_page > b.start_page) {
    return B_FIRST
  }

  if (a.end_page < b.end_page) {
    return A_FIRST
  }

  if (a.end_page > b.end_page) {
    return B_FIRST
  }

  if (Number.isNaN(a.end_page) && !Number.isNaN(b.end_page)) {
    return B_FIRST
  }

  if (!Number.isNaN(a.end_page) && Number.isNaN(b.end_page)) {
    return A_FIRST
  }

  return EQUAL
}
