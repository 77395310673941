import { ReactNode, useEffect, useMemo } from "react"
import { EditorFeatureRendererProps } from "../types"
import { EDITOR_FEATURES } from "../constants"
import { SuggestionsContext, SuggestionsContextType } from "./SuggestionsContext"
import { isSuggestionsEditor } from "../../queries"
import { Suggestions } from "./Suggestions"

export function EditableWithSuggestions({
  children,
  editor,
  suggestions,
}: EditorFeatureRendererProps<EDITOR_FEATURES.SUGGESTIONS> & { children: ReactNode }): JSX.Element {
  const editorSuggestions = useMemo<SuggestionsContextType>(() => {
    const editorSuggestions = new Suggestions()
    editorSuggestions.push(...suggestions)

    return {
      suggestions: editorSuggestions,
    }
  }, [suggestions])

  useEffect(() => {
    if (isSuggestionsEditor(editor)) {
      editor.suggestions = editorSuggestions.suggestions
    }
  }, [editor, editorSuggestions])

  return <SuggestionsContext.Provider value={editorSuggestions}>{children}</SuggestionsContext.Provider>
}
