import { ViewRequestRevampProps } from "./types"
import {
  AdditionalInformation,
  PlaintiffInformation,
  CaseInformation,
  PolicyCarrierInformation,
  AttorneyInformation,
  SupportingFile,
  FirmInformation,
  DefendantInformation,
  RecipientInformation,
  EconomicImpactInformation,
  CollaboratorsInformaion,
} from "./components"
import { AccordionView } from "../Request"
import useUser from "hooks/useUser"
import { usePermissions } from "permissions/usePermissions"

export const StandardBasicPlusView = ({ request }: ViewRequestRevampProps) => {
  const fileCount = request.files.length
  const { user } = useUser()

  const { collaboratorSelectionEnabled } = usePermissions({
    suspense: true,
    enabled: user.isAuthorized,
    firmId: request.firm ? request.firm.pk : null,
  })

  return (
    <>
      <AccordionView
        data-test="supporting-files-section"
        summary={`Supporting Files${fileCount > 0 ? ` (${fileCount})` : ""}`}
        detailsChild={<SupportingFile files={request.files} request={request} />}
      />

      <PlaintiffInformation plaintiffs={request.plaintiffs} />
      <CaseInformation
        dateOfIncident={request.date_of_incident}
        caseType={request.case_type}
        stateOfIncident={request.state_of_incident}
      />
      <DefendantInformation defendants={request.defendants} />
      <FirmInformation firm={request.firm} submitter={request.submitter} />
      <PolicyCarrierInformation request={request} />
      <RecipientInformation request={request} />
      <AttorneyInformation request={request} />
      <EconomicImpactInformation request={request} />
      <AdditionalInformation
        facts={request.case_facts ?? ""}
        additionalInformation={request.additional_information ?? ""}
      />
      {collaboratorSelectionEnabled && <CollaboratorsInformaion request={request} />}
    </>
  )
}
