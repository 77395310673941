import { v4 } from "uuid"

import { ReferencePage } from "common/types/providers"
import { EXHIBIT_ID, PARTITION_ID } from "demand/Providers/Provider/ProviderForm/BillsSection/constants"
import { encodeExhibitValue } from "demand/Providers/Provider/ProviderForm/BillsSection/utils"
import { LOCAL_REFERENCE_PAGE_ID } from "./constants"

export const getInitialReferencePageState = (): ReferencePage => ({
  id: `${LOCAL_REFERENCE_PAGE_ID}${v4()}`,
  exhibitId: null,
  partitionId: null,
  pageNumber: 0,
})

export const getReferenceExhibitValue = (reference: Nullable<ReferencePage>) => {
  if (!reference) return ""

  if (reference.exhibitId) {
    return encodeExhibitValue(EXHIBIT_ID, reference.exhibitId)
  }

  if (reference.partitionId) {
    return encodeExhibitValue(PARTITION_ID, reference.partitionId)
  }

  return ""
}
