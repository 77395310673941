import React from "react"
import { RenderElementProps } from "slate-react"
import { INLINE_ELEMENTS, LIST_BLOCK_ELEMENTS, LIST_ITEM_BLOCK_ELEMENTS } from "./elements"
import {
  ListItem,
  ListItemContent,
  OrderedList,
  Paragraph,
  SoftLineBreak,
  UnorderedList,
  Variable,
} from "./render"
import { Citation } from "./render/Citation"
import { EmptySpace } from "./render/EmptySpace"

export const Element: React.FC<RenderElementProps> = React.memo(function Element({
  attributes,
  children,
  element,
}) {
  switch (element.type) {
    case INLINE_ELEMENTS.VARIABLE:
      return (
        <Variable attributes={attributes} element={element}>
          {children}
        </Variable>
      )
    case INLINE_ELEMENTS.CITATION:
      return (
        <Citation attributes={attributes} element={element}>
          {children}
        </Citation>
      )
    case INLINE_ELEMENTS.SOFT_LINE_BREAK:
      return (
        <SoftLineBreak attributes={attributes} element={element}>
          {children}
        </SoftLineBreak>
      )
    case INLINE_ELEMENTS.EMPTY_SPACE:
      return (
        <EmptySpace attributes={attributes} element={element}>
          {children}
        </EmptySpace>
      )
    case LIST_BLOCK_ELEMENTS.ORDERED_LIST:
      return (
        <OrderedList attributes={attributes} element={element}>
          {children}
        </OrderedList>
      )
    case LIST_BLOCK_ELEMENTS.UNORDERED_LIST:
      return (
        <UnorderedList attributes={attributes} element={element}>
          {children}
        </UnorderedList>
      )
    case LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM:
      return (
        <ListItem attributes={attributes} element={element}>
          {children}
        </ListItem>
      )
    case LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM_CONTENT:
      return (
        <ListItemContent attributes={attributes} element={element}>
          {children}
        </ListItemContent>
      )
    default:
      return (
        <Paragraph attributes={attributes} element={element}>
          {children}
        </Paragraph>
      )
  }
})
