import { DOCUMENT_TYPES } from "requests/enums"
import { DefendantProps } from "./SinglePageRequestForm/types"

enum STEP_NAME {
  DOCUMENT_TYPE = "Document Type",
  CLIENT_INFORMATION = "Client Information",
}

export enum STEP_CODES {
  DOCUMENT_TYPE = 0,
  CLIENT_INFORMATION = 1,
}

export type STEP = { name: STEP_NAME; code: STEP_CODES }
const DOCUMENT_TYPE: STEP = {
  name: STEP_NAME.DOCUMENT_TYPE,
  code: STEP_CODES.DOCUMENT_TYPE,
}

const CLIENT_INFORMATION: STEP = {
  name: STEP_NAME.CLIENT_INFORMATION,
  code: STEP_CODES.CLIENT_INFORMATION,
}

export const STEPS_BY_DEMAND_TYPE: Record<DOCUMENT_TYPES, Array<STEP>> = {
  [DOCUMENT_TYPES.STANDARD]: [DOCUMENT_TYPE, CLIENT_INFORMATION],
  [DOCUMENT_TYPES.BASIC_PLUS]: [DOCUMENT_TYPE, CLIENT_INFORMATION],
  [DOCUMENT_TYPES.SIMPLE]: [DOCUMENT_TYPE, CLIENT_INFORMATION],
  [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY]: [DOCUMENT_TYPE, CLIENT_INFORMATION],
  [DOCUMENT_TYPES.PREMIUM_MEDCHRON]: [DOCUMENT_TYPE, CLIENT_INFORMATION],
}

export const CHECKBOX_CENTERING_OFFSET = "22px"

export const REQUEST_FORM_FIELDS = {
  DATE_OF_INCIDENT: "date_of_incident",
  DEFENDANTS: "defendants",
  PLAINTIFFS: "plaintiffs",
  POLICY_COVERAGE_TYPE: "policy_coverage_type",
}

export enum REQUEST_FORM_STEPS {
  SELECT_DOCUMENT = "select_document",
  SINGLE_PAGE_REQUEST_FORM = "single_page_request_form",
}

export const DEFAULT_DEFENDANTS_VALUE: DefendantProps[] = [
  { name: "", type: "individual", gender: "", last_name: "", first_name: "" },
]
