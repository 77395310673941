import { Navigate } from "react-router-dom"
import { Loading } from "common/loading"
import useUser from "../hooks/useUser"
import { ALL_ROLES } from "./models/roles"
import { memo } from "react"
import { useLocationStore } from "app/location/store"

interface RequireAuthProps {
  restrictAccess?: Nullable<ALL_ROLES[]>
  children: React.ReactNode
  loading?: boolean
}

// A wrapper for <Route> that redirects to the login
// screen if user is not yet authenticated.
const RequireAuth = memo(function RequireAuth({ restrictAccess = [], children, loading }: RequireAuthProps) {
  const { user, isLoading, isInitialized, error } = useUser()
  const isAuthorized = user.isAuthorized && !error

  const hasAccess = restrictAccess?.length ? restrictAccess.includes(user.role) : true
  const redirectPath = useLocationStore(location =>
    !isAuthorized
      ? `${location.pathname}${location.search ? "&search=" + window.btoa(location.search) : ""}`
      : ""
  )

  if (isLoading || !isInitialized || loading) return <Loading />

  if (isAuthorized) {
    return hasAccess ? children : <Navigate to="/access-denied" replace />
  }

  return <Navigate to={`/login?redirectTo=${redirectPath}`} replace state={{ path: `${redirectPath}` }} />
})

export default RequireAuth
