import { PaginatedOptions } from "api/PaginatedList"
import { handlePaginatedResponse } from "api/utils"
import {
  BlobCallProps,
  BlobResponse,
  EmptyResponse,
  FormDataCallProps,
  JsonCallProps,
  makeApiCall,
  REQUEST_METHODS,
} from "apiHelper"
import { DEFAULT_PAGE_SIZE, PaginatedList } from "common/models/pagination"
import { ApiServiceType } from "./types"

class ApiService implements ApiServiceType {
  private getPath(path: string, query?: Nullable<string>): string {
    return `${path}/${query ? query : ""}`
  }

  create<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse> {
    const apiOptions: JsonCallProps<TFields> = {
      path: this.getPath(path, query),
      method: REQUEST_METHODS.POST,
      json: true,
      data,
    }

    return makeApiCall(apiOptions)
  }

  call<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse> {
    const apiOptions: JsonCallProps<TFields> = {
      path: this.getPath(path, query),
      method: REQUEST_METHODS.POST,
      json: true,
      data,
    }

    return makeApiCall(apiOptions)
  }

  get<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse> {
    const apiOptions: JsonCallProps<TFields> = {
      path: this.getPath(path, query),
      method: REQUEST_METHODS.GET,
      json: true,
      data,
    }

    return makeApiCall(apiOptions)
  }

  getRaw<TFields>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<BlobResponse | EmptyResponse> {
    const apiOptions: BlobCallProps<TFields> = {
      path: this.getPath(path, query),
      method: REQUEST_METHODS.GET,
      json: false,
      blob: true,
      data,
    }

    return makeApiCall(apiOptions)
  }

  getPaginatedList<TResponseItem>(
    path: string,
    query?: Nullable<string>,
    { pageSize = DEFAULT_PAGE_SIZE, page = 1, orderBy = "" }: PaginatedOptions = {}
  ): Promise<PaginatedList<TResponseItem>> {
    const orderingQuery = orderBy ? `&ordering=${orderBy}` : ""
    const paginationQuery = `page_size=${pageSize}&page=${page}${orderingQuery}`

    const requestQuery = query ? `${query}&${paginationQuery}` : `?${paginationQuery}`

    const apiOptions: JsonCallProps = {
      path: this.getPath(path, requestQuery),
      method: REQUEST_METHODS.GET,
      json: true,
    }

    return handlePaginatedResponse(makeApiCall(apiOptions), { page, pageSize })
  }

  update<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<Partial<TFields>>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse> {
    const apiOptions: JsonCallProps<Partial<TFields>> = {
      path: this.getPath(path, query),
      method: REQUEST_METHODS.PATCH,
      json: true,
      data,
    }

    return makeApiCall(apiOptions)
  }

  replace<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse> {
    const apiOptions: JsonCallProps<TFields> = {
      path: this.getPath(path, query),
      method: REQUEST_METHODS.PUT,
      json: true,
      data,
    }

    return makeApiCall(apiOptions)
  }

  delete<TFields, TResponse>(
    data: TFields extends never ? never : Nullable<TFields>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse> {
    const apiOptions: JsonCallProps<TFields> = {
      path: this.getPath(path, query),
      method: REQUEST_METHODS.DELETE,
      data,
    }

    return makeApiCall(apiOptions)
  }

  submitForm<TFormData extends FormData, TResponse>(
    data: TFormData extends never ? never : Nullable<TFormData>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse> {
    const apiOptions: FormDataCallProps<TFormData> = {
      path: this.getPath(path, query),
      method: REQUEST_METHODS.POST,
      isFormData: true,
      json: false,
      data,
    }

    return makeApiCall(apiOptions)
  }

  updateForm<TFormData extends FormData, TResponse>(
    data: TFormData extends never ? never : Nullable<TFormData>,
    path: string,
    query?: Nullable<string>
  ): Promise<TResponse | EmptyResponse> {
    const apiOptions: FormDataCallProps<TFormData> = {
      path: this.getPath(path, query),
      method: REQUEST_METHODS.PATCH,
      isFormData: true,
      json: false,
      data,
    }

    return makeApiCall(apiOptions)
  }
}

export const apiService = new ApiService()

export class MockApiService extends ApiService {}
