import Box from "@mui/material/Box"
import isObject from "lodash/isObject"

export function VerticalCenterBox(props) {
  let sx = { display: "flex", flexDirection: "row", alignItems: "center" }

  if (isObject(props.sx)) {
    sx = { ...sx, ...props.sx }
  }

  return <Box {...props} sx={sx} />
}
