import { ReactNode } from "react"
import { theme } from "app/theme"
import styled from "@emotion/styled"
import { Alert, Box, Button, Card, IconButton, Typography } from "@mui/material"

export const StyledRootBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(5),
  marginBottom: theme.spacing(6),
})

const BaseBox = ({
  children,
  ...rest
}: {
  isSelectDocument?: boolean
  gridColumns?: string
  children: ReactNode
}) => <Box {...rest}>{children}</Box>

export const StyledRequiredIndicator = styled("span")({
  color: "red",
})

export const StyledFormBox = styled(BaseBox, { shouldForwardProp: prop => prop !== "gridColumns" })(
  ({ gridColumns }) => ({
    display: "grid",
    gridTemplateColumns: gridColumns,
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
    alignItems: "start",
  })
)

export const FormActionsContainer = styled(Box)({
  display: "flex",
  justifyContent: "end",
  flexDirection: "row",
  alignItems: "end",
  gap: theme.spacing(1.5),
})

export const StyledButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "start",
  marginBottom: theme.spacing(2),
})

export const StyledButton = styled(Button)({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.blue.main,
  "&:hover": { backgroundColor: theme.palette.grey[100] },
})

export const StyledTextButton = styled(StyledButton)({
  textTransform: "none",
})

export const StyledEntityWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(2),
  "& .MuiInputLabel-root": {
    "&.Mui-required": {
      display: "flex",
      flexDirection: "row-reverse",
    },
    "& .MuiFormLabel-asterisk": {
      color: "red",
      paddingRight: theme.spacing(0.5),
    },
  },
}))

export const StyledEntityRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "4fr 4fr",
    marginBottom: theme.spacing(4),
  },
  gap: theme.spacing(2),
}))

export const StyledRemoveIconButton = styled(IconButton)(({ theme }) => ({
  placeSelf: "start",
  [theme.breakpoints.down("md")]: {
    placeSelf: "start",
  },
}))

export const StyledCard = styled(Card)(() => ({
  boxShadow: "0px 0px 4px 0px #00000040",
  padding: theme.spacing(4),
}))

export const StyledTitleTypography = styled(Typography)({
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(28),
  lineHeight: theme.spacing(4),
  marginBottom: theme.spacing(1.5),
})

export const StyledExpensiveRequestNotificationContent = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  paddingRight: 2,
  gap: theme.spacing(2),
})

export const FullWidthMessageAlert = styled(Alert)({
  "& .MuiAlert-message": {
    width: "100%",
  },
})

export const SinglePageRequestFormContainer = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(123),
  margin: `${theme.spacing(2)} auto 0`,
}))
