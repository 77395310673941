import { useState, useEffect } from "react"
import { Controller } from "react-hook-form"
import Slider from "@mui/material/Slider"
import FormLabel from "@mui/material/FormLabel"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
  sliderContainer: {
    display: "flex",
    flexDirection: "column",
  },
}))

export function SliderInput({
  name,
  control,
  label,
  onChange,
  className = null,
  defaultValue = 0,
  ...inputProps
}) {
  const { classes, cx } = useStyles()
  const [value, setValue] = useState(defaultValue)
  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const updateValue = () => {
    onChange?.(name, value)
  }

  useEffect(() => {
    // apply the change if provided value changes
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box className={cx(classes.sliderContainer, className)}>
            {label && <FormLabel>{label}</FormLabel>}
            <Slider
              size="small"
              {...inputProps}
              {...field}
              value={value}
              onChange={handleChange}
              onChangeCommitted={updateValue}
            />
          </Box>
        )
      }}
    />
  )
}
