import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { AttributeFiltersData } from "common/attributes-filter"
import { PaginatedList } from "common/models/pagination"
import {
  NewLibraryVariable,
  LibraryVariableDefinition,
  LibraryVariableGroupDefinition,
  UpdateLibraryVariable,
  NewLibraryVariableInGroup,
} from "common/types/libraryVariables"
import {
  LibraryVariableAttributeDto,
  LibraryVariableDto,
  LibraryVariableGroupDto,
  LibraryVariableItemAttributeDto,
  LibraryVariableItemDto,
  NewLibraryVariableDto,
} from "./types"

export class LibraryVariableServiceDeserializer {
  static definitionFromJSON(variable: LibraryVariableItemDto): LibraryVariableDefinition {
    return {
      id: variable.pk,
      groupId: variable.variable_group_pk,
      groupName: variable.variable_group_name,
      content: variable.content,
      firm: variable.firm,
      updatedAt: BaseServiceDeserializer.fromDateString(variable.created_at),
      attributes: LibraryVariableServiceDeserializer.attributesDisplayFromListJSON(variable.attributes),
    }
  }

  static fromListJSON(variables: LibraryVariableItemDto[]): LibraryVariableDefinition[] {
    return variables.map(LibraryVariableServiceDeserializer.definitionFromJSON)
  }

  static fromPaginatedListJSON(
    variables: PaginatedList<LibraryVariableItemDto>
  ): PaginatedList<LibraryVariableDefinition> {
    return new PaginatedList(
      LibraryVariableServiceDeserializer.fromListJSON(variables.items),
      variables.count,
      variables.pageSize,
      variables.page
    )
  }

  static attributeDisplayFromJSON(
    attributeValue: LibraryVariableItemAttributeDto
  ): LibraryVariableDefinition["attributes"][number] {
    return {
      id: attributeValue.attribute_id,
      valueId: attributeValue.value_id,
      displayValue: attributeValue.value,
    }
  }

  static attributesDisplayFromListJSON(
    attributes: LibraryVariableItemAttributeDto[]
  ): LibraryVariableDefinition["attributes"] {
    return attributes.map(LibraryVariableServiceDeserializer.attributeDisplayFromJSON)
  }
}

export class LibraryVariableServiceSerializer {
  static toAttributeValuesJSON(values: AttributeFiltersData): number[] {
    return Object.values(values).filter((value): value is LibraryVariableAttributeDto["pk"] => value !== null)
  }

  static toDefinitionJSON(variable: UpdateLibraryVariable): LibraryVariableDto {
    const attributeValues = LibraryVariableServiceSerializer.toAttributeValuesJSON(variable.attributes)

    return {
      attribute_ids: attributeValues,
      content: variable.content,
      firm_id: variable.firmId,
      variable_group_id: variable.groupId,
    }
  }

  static newVariableToDefinitionJSON(
    variable: NewLibraryVariable | NewLibraryVariableInGroup
  ): NewLibraryVariableDto | LibraryVariableDto {
    const attributeValues = LibraryVariableServiceSerializer.toAttributeValuesJSON(variable.attributes)

    if ("groupName" in variable) {
      return {
        attribute_ids: attributeValues,
        content: variable.content,
        firm_id: variable.firmId,
        variable_group_name: variable.groupName,
      }
    }

    return {
      attribute_ids: attributeValues,
      firm_id: variable.firmId,
      content: variable.content,
      variable_group_id: variable.groupId,
    }
  }
}

export class LibraryVariableGroupServiceDeserializer {
  static definitionFromJSON(variableGroup: LibraryVariableGroupDto): LibraryVariableGroupDefinition {
    return {
      id: variableGroup.pk,
      name: variableGroup.name,
      variables: variableGroup.variables,
    }
  }

  static fromListJSON(variableGroups: LibraryVariableGroupDto[]): LibraryVariableGroupDefinition[] {
    return variableGroups.map(LibraryVariableGroupServiceDeserializer.definitionFromJSON)
  }
}
