import { handleEmptyResponse } from "api/utils"
import { apiService } from "../ApiService"
import { ApiServiceType } from "../types"
import { withResponseSerializer } from "../../withSerializers"
import { DOSSummaryDeserializer, UserExhibitSerializer } from "./serializers"
import { isNil } from "lodash"
import { AnnotatedExhibit } from "demand/Files/types"
import { BaseExhibitDto, ExhibitHighlightsDto, SummaryStatus } from "./types"
import { create, keyResolver } from "@yornaath/batshit"
import { getQuery } from "../utils"

export type ExhibitServiceOptions = EitherProps<{ caseId: PrimaryKey | string }, { documentId: string }> & {
  exhibitId: PrimaryKey | string
}

export type UserExhibitServiceOptions = EitherProps<
  { caseId: PrimaryKey | string },
  { documentId: string }
> & {
  userExhibitId: PrimaryKey | string
}

enum EXHIBIT_API_PATHS {
  BASE = "exhibit",
  ROOT = "exhibits",
  CASE = "case",
  DOCUMENT = "documents",
  DOWNLOAD = "download",
  SMART_DOWNLOAD = "smart-download",
  SORT = "sort",
  PARTITION = "partition",
  FIELDS = "fields",
  USER_EXHIBIT = "user-exhibit",
  USER_EXHIBIT_GEN = "generate_user_exhibit_pdf",
}

class ExhibitService {
  constructor(private readonly apiService: ApiServiceType) {}

  getPath(
    options: Pick<ExhibitServiceOptions, "exhibitId"> &
      Partial<Omit<ExhibitServiceOptions, "exhibitId"> & UserExhibitServiceOptions>,
    path: (string | number)[] = []
  ): string {
    const paths = [
      "",
      !isNil(options.caseId) ? EXHIBIT_API_PATHS.CASE : null,
      options.caseId,
      !isNil(options.documentId) ? EXHIBIT_API_PATHS.DOCUMENT : null,
      options.documentId,
      isNil(options.caseId) && isNil(options.documentId) ? EXHIBIT_API_PATHS.ROOT : EXHIBIT_API_PATHS.BASE,
      options.exhibitId,
      ...path,
    ]
    return paths.filter(path => !isNil(path)).join("/")
  }

  getDownloadPath = (options: ExhibitServiceOptions) =>
    this.getPath(options, [EXHIBIT_API_PATHS.SMART_DOWNLOAD])

  getUserExhibit = withResponseSerializer(
    UserExhibitSerializer.fromJSON,
    (data: { id: PrimaryKey | string; attachmentName?: string }) => {
      return handleEmptyResponse(
        this.apiService.get(
          null,
          `/exhibits/user-exhibit/${data.id}`,
          getQuery({ attachment_name: [data.attachmentName] })
        )
      )
    }
  )

  generateUserExhibit = withResponseSerializer(UserExhibitSerializer.fromJSON, (id: PrimaryKey | string) => {
    return handleEmptyResponse(
      this.apiService.create(null, `/exhibits/user-exhibit/${id}/generate_user_exhibit_pdf`)
    )
  })

  getPartitionDownloadPath = ({
    partitionId,
    ...options
  }: Pick<ExhibitServiceOptions, "exhibitId"> & { partitionId: PrimaryKey }) =>
    `${this.getPath(options, [
      EXHIBIT_API_PATHS.PARTITION,
      partitionId,
      EXHIBIT_API_PATHS.DOWNLOAD,
    ])}/?exclude_deletes=false`

  getExhibitBuilderPartitionPath = ({
    partitionId,
    ...options
  }: Pick<ExhibitServiceOptions, "exhibitId"> & { partitionId: string }) =>
    `${this.getPath(options, [EXHIBIT_API_PATHS.PARTITION, partitionId, EXHIBIT_API_PATHS.DOWNLOAD])}/?partition_type=user_exhibit_partition&exclude_deletes=false`

  getAnnotatedExhibit = (options: ExhibitServiceOptions) => {
    return handleEmptyResponse(
      this.apiService.get<null, AnnotatedExhibit>(
        null,
        this.getPath(options),
        `?${new URLSearchParams({
          annotations: "true",
        })}`
      )
    )
  }

  downloadExhibit = (options: ExhibitServiceOptions) => {
    return handleEmptyResponse(this.apiService.getRaw(null, this.getDownloadPath(options)))
  }

  getDOSSummary = withResponseSerializer(DOSSummaryDeserializer.fromJSON, (id: PrimaryKey) => {
    return handleEmptyResponse(this.apiService.get(null, `/exhibits/dos-summaries/${id}`))
  })

  getDOSSummaryStatus = create<SummaryStatus[], number, SummaryStatus>({
    fetcher: async ids =>
      handleEmptyResponse(
        this.apiService.call<number[], SummaryStatus[]>(ids, "/exhibits/dos-summaries/status")
      ),
    resolver: keyResolver("id"),
  }).fetch

  reorderExhibit = (options: ExhibitServiceOptions & { index: number }) => {
    return handleEmptyResponse<unknown>(
      this.apiService.update({ index: options.index }, this.getPath(options, [EXHIBIT_API_PATHS.SORT]))
    )
  }

  renameExhibit = (options: Pick<ExhibitServiceOptions, "exhibitId"> & Pick<BaseExhibitDto, "name">) => {
    return handleEmptyResponse(
      this.apiService.update<BaseExhibitDto, BaseExhibitDto>(
        { name: options.name },
        this.getPath(options, [EXHIBIT_API_PATHS.FIELDS])
      )
    )
  }

  getExhibitCitations = (exhibitId: string | null, { ...options }: { start: number; size: number }) => {
    if (!exhibitId) return
    return handleEmptyResponse(
      this.apiService.get<ExhibitHighlightsDto, ExhibitHighlightsDto>(
        null,
        `/exhibits/${exhibitId}/citation-highlights`,
        `?${new URLSearchParams({
          start: String(options.start),
          size: String(options.size),
        })}`
      )
    )
  }

  getUserExhibitCitations = (
    userExhibitId: string | null,
    { ...options }: { start: number; size: number }
  ) => {
    if (!userExhibitId) return
    return handleEmptyResponse(
      this.apiService.get<ExhibitHighlightsDto, ExhibitHighlightsDto>(
        null,
        `/exhibits/user-exhibit/${userExhibitId}/citation-highlights`,
        `?${new URLSearchParams({
          start: String(options.start),
          size: String(options.size),
        })}`
      )
    )
  }
}

export const exhibitService = new ExhibitService(apiService)
