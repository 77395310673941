import { BasePoint, createEditor, Editor, NodeEntry, Path, Point } from "slate"
import {
  BlockElement,
  CustomEditor,
  CustomText,
  EmptySpaceElement,
  FlaggedText,
  ParagraphElement,
  VariableElement,
} from "../../CustomEditor"
import { getEditorRange, isBlockNode, isTextNode } from "../../queries"
import {
  getHtmlBlocks,
  getParagraphHtml,
  getParagraphNodesFromHtml,
  getParagraphTextMask,
  getStyles,
  omitBlockTags,
} from "./utils"
import { LEAF_BLOCK_ELEMENTS } from "../../elements"
import { withDefaults } from "../../features/withDefaults"
import { TEXT_STYLES } from "../../styles"
import { isList } from "../lists"
import { NO_MATCH_SUGGESTION_FLAG } from "../../constants"
import { isCitationNode, isSoftLineBreakNode } from "../../queries/inlines"
import { withLists } from "../../features/withLists"

function applyStyles(editor: CustomEditor, styles: Set<TEXT_STYLES>): void {
  for (const style of Object.values(TEXT_STYLES)) {
    if (styles.has(style)) {
      editor.addMark(style, true)
    } else {
      editor.removeMark(style)
    }
  }
}

function areStylesEqual(styles: Set<TEXT_STYLES>, anotherStyles: Set<TEXT_STYLES>): boolean {
  if (styles.size !== anotherStyles.size) return false

  return Object.values(TEXT_STYLES).every(style => styles.has(style) === anotherStyles.has(style))
}

export function applyStyleSuggestion(
  editor: CustomEditor,
  suggestionId: string,
  originalHtml: string,
  suggestedHtml: string
): void {
  const nodesIterator = Editor.nodes<CustomText | VariableElement | EmptySpaceElement>(editor, {
    at: getEditorRange(editor),
    match: node => {
      return "flags" in node && !!node.flags?.includes(suggestionId)
    },
  })

  const groups: NodeEntry<CustomText | VariableElement | EmptySpaceElement>[][] = []

  for (const nodeEntry of nodesIterator) {
    const [, path] = nodeEntry
    const lastGroup = groups[groups.length - 1]
    const previousNodeEntry = Editor.previous(editor, { at: path, mode: "lowest" })

    if (!lastGroup || !previousNodeEntry || previousNodeEntry[0] !== lastGroup[lastGroup.length - 1][0]) {
      groups.push([nodeEntry])
      continue
    }

    lastGroup.push(nodeEntry)
  }

  const { originalBlocks, suggestedBlocks } = getHtmlBlocks(originalHtml, suggestedHtml)

  // Need to traverse in reverse order because each change shifts next nodes paths
  // And cached paths withing a group will lead to incorrect elements
  for (const [idx, group] of groups.reverse().entries()) {
    const groupIdx = Math.max(originalBlocks.length - idx - 1, 0)
    const anchor = Editor.start(editor, group[0][1])
    const focus = Editor.end(editor, group[group.length - 1][1])

    const paragraphEntry = editor.above({
      at: anchor,
      mode: "lowest",
      match: node => isBlockNode(editor, node) && node.type === LEAF_BLOCK_ELEMENTS.PARAGRAPH,
    })

    if (!paragraphEntry) continue

    // Parse html to editor nodes
    const suggestion = Array.isArray(suggestedBlocks) ? suggestedBlocks[groupIdx] : suggestedBlocks
    const original = Array.isArray(originalBlocks) ? originalBlocks[groupIdx] : originalBlocks
    const result = omitBlockTags(
      getParagraphHtml(paragraphEntry[0] as ParagraphElement).replaceAll(original, suggestion)
    )

    const initialFlags = group[0][0].flags

    if (initialFlags && initialFlags.includes(NO_MATCH_SUGGESTION_FLAG)) {
      const suggestedParagraph = getParagraphNodesFromHtml(suggestion)
      suggestedParagraph.children = suggestedParagraph.children.map(child => {
        if (isSoftLineBreakNode(child) || isCitationNode(child)) return child
        return { ...child, flags: initialFlags }
      })

      editor.withoutNormalizing(() => {
        editor.delete({ at: { anchor, focus }, voids: true })
        editor.insertNode(suggestedParagraph, {
          at: Path.next([editor.children.length - 1]),
        })
      })

      continue
    }

    // Prepare suggested changes and wrap with editor in order to navigate over nodes
    const suggestedParagraph = getParagraphNodesFromHtml(result)
    const suggestedEditor = withLists(withDefaults(createEditor()))
    suggestedEditor.children = [suggestedParagraph]
    suggestedEditor.normalize()

    // Calculate offsets from start of paragraph to flagged point

    // Select from start of paragraph with flagged point till that flag
    // Get fragment so that we can calculate character distance from the start
    const fragment = editor.fragment({
      anchor: editor.start(paragraphEntry[1]),
      focus: anchor,
    })[0] as BlockElement
    // Get lowest paragraph from fragment, that will be a node to calculate character distance
    const paragraph = isList(editor, fragment)
      ? fragment.children[0].children[0].children[0] // type safe, list -> list_item[0] -> list_item_content -> paragraph[0]
      : (fragment as ParagraphElement)
    const paragraphTextMask = getParagraphTextMask(paragraph)

    // Move selection in suggested nodes by the same number of characters.
    // Each variable/line break is a single character
    suggestedEditor.select(suggestedEditor.start([0]))
    suggestedEditor.move({ distance: paragraphTextMask.length, unit: "character" })

    // Edge case - we are at correct point but it is a point when style changes.
    // In this case selection will be at the end of one inline node
    // Which is equivalent of start of next inline node
    // Start of next inline node and end of current inline node is the same in terms of caret position
    // But different in terms of selecting the node to calculate styles
    if (
      suggestedEditor.isEnd(
        suggestedEditor.selection?.anchor as BasePoint,
        suggestedEditor.selection?.anchor.path as Path
      )
    ) {
      const nextNodeEntry = suggestedEditor.next()
      if (!nextNodeEntry) continue // just special check - technically it can be the end of suggested nodes
      suggestedEditor.select(suggestedEditor.start(nextNodeEntry[1]))
    }

    // Set selection to start of flagged node
    editor.select(anchor)

    // Keep track of current style in order to batch apply to a range instead of tweaking per-character
    let currentStyles = getStyles(suggestedEditor.node(suggestedEditor.selection?.anchor as BasePoint)[0])

    // Iteration over suggestion range
    // Need to apply style ranges from suggested nodes while keeping flags from original nodes
    // eslint-disable-next-line
    while (true) {
      // There are couple of edge cases when trying to understand if we are out of suggestion range
      const editorFocus = editor.selection?.focus as BasePoint
      const [editorFocusNode] = editor.node(editorFocus)
      const paragraphEnd = editor.end(paragraphEntry[1])
      const isAlreadyNextParagraph = Point.isAfter(editorFocus, paragraphEnd)
      const isParagraphEnd = Point.equals(paragraphEnd, editorFocus)
      const isAlreadyAfterFlagRange =
        !((editorFocusNode as FlaggedText).flags ?? []).includes(suggestionId) &&
        (!isTextNode(editorFocusNode) || editorFocusNode.text !== "")

      const isSuggestionEnd = isAlreadyNextParagraph || isParagraphEnd || isAlreadyAfterFlagRange

      if (isSuggestionEnd) {
        if (isAlreadyNextParagraph || isAlreadyAfterFlagRange) {
          editor.move({ distance: -1, unit: "character", edge: "focus" })
        }
        applyStyles(editor, currentStyles)
        break
      }

      const previousFocus = suggestedEditor.selection?.focus as BasePoint
      suggestedEditor.move({ distance: 1, unit: "character", edge: "focus" })
      const selectionFocus = suggestedEditor.selection?.focus as BasePoint
      const [node] = suggestedEditor.node(selectionFocus)

      const nextStyles = getStyles(node)

      if (areStylesEqual(currentStyles, nextStyles)) {
        editor.move({ distance: 1, unit: "character", edge: "focus" })
      } else {
        applyStyles(editor, currentStyles)

        suggestedEditor.select(previousFocus)
        suggestedEditor.move({ distance: 1, unit: "character", edge: "focus" })
        editor.select(editor.selection?.focus as BasePoint)
        editor.move({ distance: 1, unit: "character", edge: "focus" })

        currentStyles = nextStyles
      }
    }
  }
}
