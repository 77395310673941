import { useCallback, useState } from "react"
import {
  CircularProgress,
  Typography,
  buttonClasses,
  inputBaseClasses,
  outlinedInputClasses,
} from "@mui/material"
import { OutlinedButton } from "summaries/Summary/styled"
import styled from "@emotion/styled"
import { documentActions, useDocumentStore } from "documents/store"

const ExhibitListSortingContainer = styled("div")(({ theme }) => ({
  color: "rgba(0,0,0,0.6)",
  marginBottom: theme.spacing(2),
}))

const ExhibitListSortingFilters = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
  margin: theme.spacing(0.5, 0),

  [`& .${inputBaseClasses.root}`]: {
    height: theme.spacing(5),
    borderRadius: theme.spacing(1),
    fontSize: 14,

    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "rgba(0,0,0,0.6)",
      borderWidth: 1,
    },
  },

  [`& .${buttonClasses.root}`]: {
    color: theme.palette.blue.primary,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "6px",
    height: theme.spacing(5),
  },
}))

export function ExhibitListSorting(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  const exhibitIds = useDocumentStore(state => state.exhibitsOrder)

  const handleSortClick = useCallback(async () => {
    setIsLoading(true)
    await documentActions.sortExhibits()
    setIsLoading(false)

    exhibitIds.forEach(id => documentActions.markExhibit({ id, state: null }))
  }, [exhibitIds])

  return (
    <ExhibitListSortingContainer>
      <ExhibitListSortingFilters>
        <OutlinedButton
          disabled={isLoading}
          onClick={handleSortClick}
          startIcon={isLoading ? <CircularProgress size={14} /> : null}
        >
          Sort Exhibits
        </OutlinedButton>
      </ExhibitListSortingFilters>
      <Typography fontSize={12}>By first date of service per provider</Typography>
    </ExhibitListSortingContainer>
  )
}
