import { VIEW_MED_CHRON_SECTIONS } from "documents/view-medical-chronology/constants"
import { DocumentData, GetState, SetState } from "./types"
import { DocumentRootData } from "documents/types"

export type DocumentDataSlice = Pick<DocumentRootData, "documentId"> &
  Pick<DocumentData, "plaintiff"> &
  Pick<DocumentData, "dateOfIncident"> &
  Pick<DocumentData, "questionnaireId"> & { scrollToElementId: Nullable<VIEW_MED_CHRON_SECTIONS> }

export const documentDataSlice: DocumentDataSlice = {
  documentId: "",
  plaintiff: "",
  dateOfIncident: "",
  questionnaireId: 0,
  scrollToElementId: null,
}

// eslint-disable-next-line
export const documentDataActions = (set: SetState<DocumentDataSlice>, _: GetState<DocumentDataSlice>) => ({
  setDocumentId: (documentId: string) => set({ documentId }),
  setScrollToElementId: (scrollToElementId: Nullable<VIEW_MED_CHRON_SECTIONS>) => set({ scrollToElementId }),
})
