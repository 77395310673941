import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import Button from "@mui/material/Button"
import CloseIcon from "@mui/icons-material/Close"
import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: theme.palette.grey[500],
}))

const StyledDialogWrapper = styled(Box)(() => ({
  padding: "50px 80px",
  width: "512px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

const StyledTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 700,
  fontSize: "30px",
  textAlign: "center",
}))

const StyledBody = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: "16px",
  textAlign: "center",
}))

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.grey,
  borderRadius: "6px",
  padding: theme.spacing(1.25),
}))

const StyledDeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: `${theme.palette.common.white}`,
  borderRadius: "6px",
  padding: theme.spacing(1.25),
  "&:hover": {
    backgroundColor: theme.palette.grey[800],
  },
  "&:disabled": {
    color: theme.palette.common.white,
  },
}))

const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  body = "",
  cancelText = "Cancel",
  confirmText = "Confirm",
  loading = false,
}) => {
  const handleClose = () => {
    if (loading) return
    onClose()
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      data-test="confirm-dialog"
      PaperProps={{ style: { borderRadius: 6 } }}
    >
      <StyledDialogWrapper>
        <StyledTitle>{title}</StyledTitle>
        {body && (
          <DialogContent>
            <StyledBody data-test="confirm-dialog-body">{body}</StyledBody>
          </DialogContent>
        )}
        <StyledIconButton onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Box display="flex" gap={2}>
          <StyledCancelButton onClick={handleClose} data-test="cancel-dialog-button">
            {cancelText}
          </StyledCancelButton>
          <StyledDeleteButton onClick={onConfirm} data-test="confirm-dialog-button" disabled={loading}>
            {loading && (
              <CircularProgress size={24} color="inherit" style={{ color: "white", marginRight: "8px" }} />
            )}
            {confirmText}
          </StyledDeleteButton>
        </Box>
      </StyledDialogWrapper>
    </Dialog>
  )
}

export { ConfirmDialog as default }
