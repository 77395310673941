import { useMemo } from "react"
import { OptionProps } from "evenup-ui/MultiSelectAutoComplete"
import { usePermissions } from "permissions/usePermissions"

import { StyledSelect, StyledFilterWrapper } from "requests/styled"
import { useFilterState } from "hooks/useFilterState"
import { DOCUMENT_TYPES } from "requests/enums"
import { withSuspense } from "common/withSuspense"
import { FilterSkeleton } from "./FilterSkeleton"
import useUser from "hooks/useUser"

interface DocumentTypeFilterProps {
  value: Nullable<string[]>
  onChange: (ids: string[]) => void
}

const medChronOption = {
  label: "Medical Chronology",
  value: DOCUMENT_TYPES.MEDICAL_CHRONOLOGY,
}

const medChronPremiumOption = {
  label: "Premium Medical Chronology",
  value: DOCUMENT_TYPES.PREMIUM_MEDCHRON,
}

const simpleDemandOption = {
  label: "Simple Demand",
  value: DOCUMENT_TYPES.SIMPLE,
}

export const DocumentTypeFilter = withSuspense(
  ({ onChange, value: initialValue }: DocumentTypeFilterProps) => {
    const { user } = useUser()
    const { medicalChronologyPremiumEnabled, createSimpleEnabled } = usePermissions({
      firmId: user.firmId,
    })

    const options: OptionProps[] = useMemo(
      () => [
        {
          label: "Standard Demand",
          value: DOCUMENT_TYPES.STANDARD,
        },
        {
          label: "Basic+ Demand",
          value: DOCUMENT_TYPES.BASIC_PLUS,
        },
        ...(createSimpleEnabled ? [simpleDemandOption] : []),
        medChronOption,
        ...(medicalChronologyPremiumEnabled ? [medChronPremiumOption] : []),
      ],
      [medicalChronologyPremiumEnabled, createSimpleEnabled]
    )
    const { value, onChangeValueHandler, onClearHandler } = useFilterState({
      initialValue,
      options,
      onChange,
    })

    return (
      <StyledFilterWrapper>
        <StyledSelect<OptionProps>
          label="Document Type"
          dataTest="document-type-filter"
          options={options}
          size="small"
          enableClear
          noOptionsText="No results"
          autoCompletePlaceholderText="Document Types..."
          value={value}
          onChangeValue={onChangeValueHandler}
          onClear={onClearHandler}
        />
      </StyledFilterWrapper>
    )
  },
  <FilterSkeleton label="Document Type" />
)
