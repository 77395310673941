import React from "react"
import { makeStyles } from "tss-react/mui"
import { SECTIONS } from "./enums"
import { ExhibitSection } from "./Exhibit"
import Button from "@mui/material/Button"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { addNewGroup } from "./utils"

const useStyles = makeStyles()(theme => ({
  sectionTitle: {
    padding: theme.spacing(1),
  },
}))

interface ExhibitsListSectionProps {
  item: ExhibitSection
  onChange: (item: ExhibitSection) => void
}

export const ExhibitsListSection: React.FC<ExhibitsListSectionProps> = ({ item, onChange }) => {
  const { classes } = useStyles()

  const handleAddGroup = () => {
    const group = addNewGroup(item.section as SECTIONS)
    const updatedItem = {
      ...item,
      children: [group, ...item.children],
    }

    onChange(updatedItem)
  }

  return (
    <>
      <span className={classes.sectionTitle} data-test="exhibit-section-title">
        {item.section}
      </span>
      <Button variant="outlined" startIcon={<AddCircleIcon />} size="small" onClick={handleAddGroup}>
        Combine Exhibits
      </Button>
    </>
  )
}
