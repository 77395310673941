import styled from "@emotion/styled"
import { Box } from "@mui/material"
import { SortableNestedListItemProps } from "common/nested-list/SortableNestedListItem"
import { DndVirtualListData } from "exhibit-builder/store/types"
import { PropsWithChildren, forwardRef } from "react"
import { Components, Virtuoso } from "react-virtuoso"

// remove margin of the top level dnd list items and use padding instead
// react-virtuoso's height calculation doesn't work well with margin
const ListItemWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.25, 0),
  ['& [data-type="list-item"]:not([data-type="list-item-children"] [data-type="list-item"])']: {
    margin: 0,
  },
}))

const ListItem: Components["Item"] = forwardRef(function ListItem(props, ref) {
  return <ListItemWrapper {...props} ref={ref} />
})

export function VirtualList(props: PropsWithChildren<SortableNestedListItemProps<DndVirtualListData>>) {
  if (!Array.isArray(props.children)) {
    return null
  }

  const [items] = props.children

  return (
    <Virtuoso
      useWindowScroll
      data={items}
      itemContent={index => items[index]}
      increaseViewportBy={1000}
      components={{ Item: ListItem }}
    />
  )
}
