import { CASE_SECTIONS } from "demand/steps/types"
import { amplitudeApm } from "../amplitude"
import { BaseAnalyticsEvent } from "../types"

export enum DemandSectionAnalyticsEventTypes {
  CaseSearchStarted = "Case Search Started",
  CaseSearchQueried = "Case Search Queried",
  CaseSearchAddedToDemand = "Case Search Added to Demand",
  CaseSearchExited = "Case Search Exited",
}

type EventType = "entered" | "exited"

const sectionToEventTypes: Record<CASE_SECTIONS, { entered?: string; exited?: string }> = {
  case_attributes: {
    entered: "Select Templates Started",
    exited: "Select Templates Exited",
  },
  plaintiff: {
    entered: "Plaintiff Information Started",
    exited: "Plaintiff Information Exited",
  },
  introduction: {
    entered: "Introduction Started",
    exited: "Introduction Exited",
  },
  carrier: {
    entered: "Carrier Information Started",
    exited: "Carrier Information Exited",
  },
  facts: {
    entered: "Case Facts Started",
    exited: "Case Facts Exited",
  },
  providers: {
    entered: "Provider Section Started",
    exited: "Provider Section Exited",
  },
  pain_and_suffering: {
    entered: "Pain and Suffering Started",
    exited: "Pain and Suffering Exited",
  },
  future_expenses: {
    entered: "Future Medical Expenses Started",
    exited: "Future Medical Expenses Exited",
  },
  non_economic: {
    entered: "Per Diem Analysis Started",
    exited: "Per Diem Analysis Exited",
  },
  income_loss: {
    entered: "Loss of Income Started",
    exited: "Loss of Income Exited",
  },
  household_loss: {
    entered: "Loss of Household Services Started",
    exited: "Loss of Household Services Exited",
  },
  damages_sections: {
    entered: "Additional Damages Started",
    exited: "Additional Damages Exited",
  },
  exhibits: {
    entered: "Arrange Exhibits Started",
    exited: "Arrange Exhibits Exited",
  },
  missing_documents: {
    entered: "Missing Documents Started",
    exited: "Missing Documents Exited",
  },
  law_firm: {},
  conclusion: {
    entered: "Conclusion Started",
    exited: "Conclusion Exited",
  },
}

class DemandSectionEvent<T extends string> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: { request_id: string; demand_id: string; section?: string }
  ) {
    super()
  }
}

export function logDemandSectionEvent(
  section: CASE_SECTIONS,
  event: EventType,
  demandId: string,
  requestId: string
) {
  const sectionEvents = sectionToEventTypes[section] || {}
  const eventName = sectionEvents[event]
  if (eventName) {
    amplitudeApm.trackEvent(
      new DemandSectionEvent(eventName, {
        demand_id: demandId,
        request_id: requestId,
      })
    )
  } else {
    amplitudeApm.trackEvent(
      new DemandSectionEvent(`Unknown Demand Section ${event}`, {
        section,
        demand_id: demandId,
        request_id: requestId,
      })
    )
  }
}
