import { useDebouncedCallback } from "hooks/useDebouncedCallback"
import { UseFormSetError, useFormContext, useFormState } from "react-hook-form"
import { RequestViewDto } from "requests/ViewRequest/types"
import { DebouncedFunc, isEqual, omit, pickBy, size } from "lodash"
import { INTAKE_STATUSES } from "requests/constants"
import { useIntakeRequestMutation } from "./useIntakeRequestMutation"
import { useMemo, useRef } from "react"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { SUPPORT_EMAIL } from "common/constants"
import * as Sentry from "@sentry/browser"
import { parseError } from "common/errorUtils"

export type SaveRequestFormFunction = (data: RequestViewDto, options?: { autoSave: boolean }) => Promise<void>
type ApplyUpdateReturn = {
  applyUpdate: (data: RequestViewDto) => Promise<void>
}

type ErrorValue = string[] | { [key: string]: string[] }[] | unknown
interface Errors {
  [key: string]: ErrorValue
}

const setFormErrors = (
  errors: Errors,
  setError: UseFormSetError<RequestViewDto>,
  path: string | undefined = undefined
) => {
  for (const [key, value] of Object.entries(errors)) {
    const newPath = path ? `${path}.${key}` : key

    if (Array.isArray(value)) {
      value.forEach((error, index) => {
        if (typeof error === "object") {
          setFormErrors(error, setError, `${newPath}[${index}]`)
        } else {
          setError(newPath as keyof RequestViewDto, { type: "manual", message: error })
        }
      })
    } else if (typeof value === "object") {
      setFormErrors(value as unknown as Errors, setError, newPath)
    }
  }
}

type DirtyField = DirtyField[] | object | boolean | undefined
const checkDirty = (field: DirtyField): boolean => {
  if (field === undefined) {
    return false
  }
  if (Array.isArray(field)) {
    return field.some(checkDirty)
  }
  if (typeof field === "object") {
    return Object.values(field).some(checkDirty)
  }
  return field
}

const useApplyUpdate = (canEdit: boolean): ApplyUpdateReturn => {
  const apiMutation = useIntakeRequestMutation(canEdit)
  const lastUpdatePayloadRef = useRef({} as Partial<RequestViewDto>)

  // Using useFormState() to isolate re-rendering to hook level when watching dirtyFields
  const { dirtyFields } = useFormState()

  const applyUpdate = useMemo(
    () => async (data: RequestViewDto) => {
      if (apiMutation.isLoading) {
        return
      }
      const requestId = data.pk

      // TODO: T-4371 - Remove support for plaintiff_first_name & plaintiff_last_name
      data.plaintiff_first_name = data.plaintiffs[0].first_name
      data.plaintiff_last_name = data.plaintiffs[0].last_name

      if (!requestId) {
        // TODO, probably just move this to client side form validation
        if (!data.firm_id || !data.plaintiff_first_name || !data.plaintiff_last_name) {
          // No error.  There's only a few fields on the form, so we just want to stop the save
          return
        }
        data.intake_status = data?.intake_status ? data.intake_status : INTAKE_STATUSES.notRequested
        data.plaintiffs = data.plaintiffs?.map(plaintiff => ({
          ...plaintiff,
          household_impairment_rate: 100,
        }))
        delete data.adjuster_address
        await apiMutation.mutateAsync({ data })
      } else {
        const changedFields = pickBy(data, (_, key: keyof RequestViewDto) => checkDirty(dirtyFields[key]))
        if (changedFields.plaintiffs?.length === 0) {
          delete changedFields.plaintiffs
        }
        if (size(changedFields) > 0) {
          if (isEqual(lastUpdatePayloadRef.current, changedFields)) {
            // this extra check is in place to prevent infinite loops in making PATCH calls
            // ideally we can get rid of it once we feel comfortbale with the changes to the form management logic
            Sentry.captureMessage("Duplicate request to save the same data", {
              level: "error",
              extra: { requestId, payload: changedFields },
            })
            // TODO
            // Currently this will cause confirm delete dialog to never be dismissed
            // If the user adds and deletes the same plaintiff twice in a row
            return
          }
          await apiMutation.mutateAsync({ requestId, data: changedFields })
          lastUpdatePayloadRef.current = changedFields
        }
      }
    },
    [apiMutation, lastUpdatePayloadRef, dirtyFields]
  )

  return useMemo(
    () => ({
      applyUpdate,
    }),
    [applyUpdate]
  )
}

export const useSaveRequestForm = (canEdit: boolean) => {
  const { setError } = useFormContext()
  const { showErrorMessage } = useHandleMessages()
  const debouncedSaveFormRef = useRef<DebouncedFunc<(data: RequestViewDto) => Promise<void>>>()

  const { applyUpdate } = useApplyUpdate(canEdit)

  const { saveRequestForm } = useMemo(() => {
    const saveRequestForm: SaveRequestFormFunction = (data, options) => {
      if (debouncedSaveFormRef.current) {
        debouncedSaveFormRef.current.cancel()
      }

      return applyUpdate(data).catch((error: unknown) => {
        let message = `Unable to save your request.  Please try again or contact ${SUPPORT_EMAIL} if the issue persists.`
        if (error instanceof Error) {
          message = `Unable to save your request. ${error && error.message ? `Error: ${error.message}` : ""}`
        } else if (typeof error === "string") {
          message = `Unable to save your request. ${error}`
        }

        const parsedError = parseError(error)

        if (parsedError?.validationErrors?.type === "ValidationError") {
          const validationErrorsWithoutType = omit(parsedError.validationErrors, ["type"])
          setFormErrors(validationErrorsWithoutType, setError)

          message = options?.autoSave
            ? "Auto-save has paused. Please address the errors in the form above in order to proceed."
            : "Unable to save your request. Please address the errors in the form above in order to proceed."
        }

        if (parsedError && parsedError.status >= 500 && parsedError.status < 600) {
          // For non-validation errors or 500 errors
          message = `Unable to save your request.  Please contact ${SUPPORT_EMAIL} if the issue persists. Refreshing the page or navigating away will cause unsaved changes to be lost.`
          Sentry.captureException(error)
        }

        showErrorMessage({
          message,
          error,
        })

        throw error
      })
    }

    return { saveRequestForm }
  }, [setError, showErrorMessage, applyUpdate])

  // useMemo'd inside.  debouncedSaveForm only changes if saveRequestForm changes
  const debouncedSaveForm = useDebouncedCallback(
    async data => await saveRequestForm(data, { autoSave: true }).catch(() => {}),
    2000
  )
  debouncedSaveFormRef.current = debouncedSaveForm

  return useMemo(
    () => ({
      saveRequestForm,
      debouncedSaveForm,
    }),
    [saveRequestForm, debouncedSaveForm]
  )
}
