import { CaseInfo } from "api/services/case/types"
import { FORM_SECTION_ROUTES } from "./constants"
import { CASE_STEP } from "./enums"
import { CASE_SECTIONS, CASE_STEP_STATUS, CASE_ATTRIBUTES_STEP, CaseStepInfo } from "./types"

export function getCurrentCaseSection(
  possibleSection: CASE_SECTIONS,
  caseInfo: CaseInfo,
  stepStatuses: CaseStepInfo[]
): CASE_SECTIONS {
  // If plaintiff section is completed - no need to check for case attributes
  // Because current case/demand is either an old one or new one with all data set
  if (
    stepStatuses.find(({ step }) => step === CASE_STEP.PLAINTIFF)?.status === CASE_STEP_STATUS.COMPLETED &&
    FORM_SECTION_ROUTES[possibleSection]
  ) {
    return possibleSection
  }

  // Empty attributes or selected attributes step
  if (caseInfo.templatedSections.length === 0 || possibleSection === CASE_ATTRIBUTES_STEP) {
    return CASE_ATTRIBUTES_STEP
  }

  return CASE_STEP.PLAINTIFF
}
