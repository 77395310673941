import { FREE_TEXT_QUERY, TREATMENTS } from "./constants"
import { INITIAL_STATE, ONTOLOGY_FACET, RANGE_FACETS } from "./state"

export const getSearchParamsFromQueryString = queryString => {
  const queryStringParams = {}
  const URLParams = new URLSearchParams(queryString)
  const urlKeys = URLParams.keys()

  if (!Object.keys(urlKeys)) return {}

  for (const key of urlKeys) {
    if (key === ONTOLOGY_FACET) {
      const tags = []

      for (const tag of URLParams.getAll(key)) {
        tags.push(JSON.parse(tag))
      }

      queryStringParams[key] = tags

      continue
    }

    if (RANGE_FACETS.NUM_FACETS.includes(key)) {
      queryStringParams[key] = URLParams.get(key)

      continue
    }

    if (INITIAL_STATE.facets.includes(key) || RANGE_FACETS.STR_FACETS.includes(key)) {
      queryStringParams[key] = URLParams.getAll(key)

      continue
    }

    if (key === TREATMENTS) {
      queryStringParams[key] = URLParams.get(key)

      continue
    }
  }

  const query = { ...INITIAL_STATE.query, ...queryStringParams }

  return {
    query,
    [FREE_TEXT_QUERY]: URLParams.get(FREE_TEXT_QUERY) || INITIAL_STATE[FREE_TEXT_QUERY],
  }
}

export const getQueryParamsFromState = state => {
  const urlParams = new URLSearchParams()

  if (state[FREE_TEXT_QUERY]) {
    urlParams.set(FREE_TEXT_QUERY, state[FREE_TEXT_QUERY])
  }

  for (const facet in state.query) {
    const queryElement = state.query[facet]
    if (Array.isArray(queryElement)) {
      queryElement.forEach(value =>
        urlParams.append(facet, typeof value === "string" ? value : JSON.stringify(value))
      )
    } else {
      urlParams.append(facet, queryElement)
    }
  }

  const result = urlParams.toString()

  return result
}
