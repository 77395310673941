import { ApiError } from "apiHelper"
import { getErrorCodeMessage } from "common/errorCodes"

interface ParsedError {
  originalError: unknown
  userFriendlyMessage?: string
}

interface ParsedAPIError extends ParsedError {
  method: string
  status: number
  statusText: string
  url: string
  errorCode?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationErrors: Record<string, any> | null
}

type AllParsedErrors = ParsedAPIError & { type: "api" }

/**
 * Parses an error object and returns a {@link AllParsedErrors} structured representation that can be used
 * to display information in the UI.
 */
export const parseError = (error: unknown): AllParsedErrors | undefined => {
  if (error instanceof ApiError) {
    const errorCode = error.validationErrors?.["error_code"]

    const userFriendlyMessage =
      getErrorCodeMessage(errorCode) ??
      error.validationErrors?.["detail"] ??
      error.validationErrors?.["error"]

    // Only populate validationErrors if the error is a ValidationError
    const validationErrors =
      error.validationErrors?.["type"] === "ValidationError" ? error.validationErrors : null

    return {
      type: "api",
      originalError: error,
      userFriendlyMessage,
      method: error.method,
      status: error.response.status,
      statusText: error.response.statusText,
      url: error.response.url,
      errorCode,
      validationErrors,
    }
  }
  return undefined
}
