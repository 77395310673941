import { JsonCallProps, REQUEST_METHODS, makeApiCall } from "apiHelper"
import { SettlementInformationDto } from "./types"
import { handleEmptyResponse } from "../../api/utils"

export async function getSettlementInformation(firmId: number, caseId: number) {
  const options = {
    path: `/settlement/${caseId}/`,
    method: REQUEST_METHODS.GET,
    query: {
      firm_id: firmId,
    },
  }

  return handleEmptyResponse(makeApiCall<SettlementInformationDto>(options))
}

interface SettlementRequestBody extends SettlementInformationDto {
  firm_id: number
  case_id: number
}

export async function updateSettlementInformation(data: SettlementRequestBody) {
  const options: JsonCallProps<SettlementRequestBody> = {
    path: `/settlement/`,
    method: REQUEST_METHODS.POST,
    json: true,
    data,
  }

  return makeApiCall(options)
}
