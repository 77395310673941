import { Box, Button, Divider, Skeleton, TextField } from "@mui/material"
import styled from "@emotion/styled"

import { getFirmExtended } from "api"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { usePermissions } from "permissions/usePermissions"
import { InputField } from "common/form-components"
import { Control } from "react-hook-form"
import { withSuspense } from "common/withSuspense"
import { useSuspenseQuery } from "@suspensive/react-query"
import { theme } from "app/theme"
import { useState } from "react"
import { UserDto } from "common/models/user"

interface SmartAdvocateAccountFormProps {
  firmId: Nullable<number>
  control: Control<{
    smart_advocate_username: string
    smart_advocate_password: string
  }>
  setDisableSave: (value: boolean) => void
  setValue: (name: string, value: string) => void
  user: UserDto
}

const FullRow = styled(Box)(() => ({
  gridColumn: "1 / 3",
}))

export const SmartAdvocateAccountForm = withSuspense(
  ({ firmId, control, setDisableSave, setValue, user }: SmartAdvocateAccountFormProps) => {
    const { smartAdvocateIntegrationEnabled } = usePermissions()
    const getSmartAdvocateDetails = !!firmId && !!smartAdvocateIntegrationEnabled
    const { data: selectedFirmData } = useSuspenseQuery([queryKeys.firm, firmId], getFirmExtended, {
      ...SILENT_QUERY_PARAMS,
      enabled: getSmartAdvocateDetails,
    })

    const [updatePassword, setUpdatePassword] = useState(false)
    const [passwordsDontMatch, setPasswordsDontMatch] = useState(false)
    const smartAdvocateUrl = selectedFirmData?.smart_advocate_url ?? null

    if (!smartAdvocateUrl) {
      return <></>
    }

    const handlePasswordUpdate = (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value !== e.target.form?.new_smart_advocate_password.value) {
        setPasswordsDontMatch(true)
        setDisableSave(true)
      } else {
        setPasswordsDontMatch(false)
        setDisableSave(false)
        setValue("smart_advocate_password", e.target.value)
      }
    }

    const usernameField = <InputField control={control} name="smart_advocate_username" label="Username" />

    return (
      <FullRow>
        <Divider sx={{ marginBottom: theme.spacing(3) }} />
        <Box fontWeight={600} fontSize="18px" mb={theme.spacing(2)}>
          SmartAdvocate Credentials
        </Box>

        {!user.has_smart_advocate_credentials ? (
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
            {usernameField}
            <InputField control={control} name="smart_advocate_password" label="Password" type="password" />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" flexWrap="wrap">
            {usernameField}
            {!updatePassword && (
              <Button
                variant="text"
                sx={{
                  color: theme.palette.blue.primary,
                  justifyContent: "left",
                  marginTop: theme.spacing(1),
                  width: "fit-content",
                }}
                onClick={() => setUpdatePassword(true)}
              >
                Update Password
              </Button>
            )}
            {updatePassword && (
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4} marginTop={4}>
                <TextField name="new_smart_advocate_password" label="New Password" type="password" />
                <TextField
                  name="confirm_smart_advocate_password"
                  label="Confirm New Password"
                  type="password"
                  onBlur={handlePasswordUpdate}
                  error={passwordsDontMatch}
                  helperText={passwordsDontMatch ? "Please make sure your passwords match." : ""}
                />
              </Box>
            )}
          </Box>
        )}
      </FullRow>
    )
  },

  <Box gridColumn="1 / 3" display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr">
    <Skeleton width="400px" height="100px" />
    <Skeleton width="400px" height="100px" />
  </Box>
)
