import { BaseEditor } from "slate"
import { EDITOR_FEATURES } from "./constants"
import { EditorFeatureProps } from "./types"
import { getFeaturesFromProps } from "./utils"
import { withVariables } from "./variables"
import { withDefaults } from "./withDefaults"
import { withLists } from "./withLists"
import { withCitations } from "./citations"
import { withFlags } from "./flags"
import { withSuggestions } from "./suggestions"

type FeatureWrapper = <T extends BaseEditor>(editor: T) => T

export const EDITOR_FEATURE_WRAPPERS: Record<EDITOR_FEATURES, FeatureWrapper> = {
  [EDITOR_FEATURES.VARIABLES]: withVariables,
  [EDITOR_FEATURES.CITATIONS]: withCitations,
  [EDITOR_FEATURES.FLAGS]: withFlags,
  [EDITOR_FEATURES.SUGGESTIONS]: withSuggestions,
}

export function getFeaturesWrapper(props: EditorFeatureProps): FeatureWrapper {
  const features = getFeaturesFromProps(props)
  const defaultWrappers = [withDefaults, withLists] as FeatureWrapper[]
  const featureWrappers = features.map(feature => EDITOR_FEATURE_WRAPPERS[feature])
  const wrappers = [...defaultWrappers, ...featureWrappers]

  return function <T extends BaseEditor>(editor: T): T {
    return wrappers.reduce((wrappedEditor, wrapper) => {
      return wrapper(wrappedEditor)
    }, editor)
  }
}
