import { withSuspense } from "common/withSuspense"
import React from "react"
import { useMatch } from "react-router-dom"
import SettlementsEstimateSkeleton from "./estimate/SettlementsEstimateSkeleton"
import SettlementsGetEstimateSkeleton from "./SettlementsGetEstimateSkeleton"
import SettlementsHomeSkeleton from "./SettlmentsHomeSkeleton"
import SettlementsPageError from "./SettlementsPageError"

const LazyPage = React.lazy(() => import("./SettlementsPage"))

const SettlementsPageFallback: React.FC = () => {
  const loadingHome = useMatch("settlements")
  const loadingGetEstimate = useMatch("settlements/get-estimate")
  const loadingEstimate = useMatch("settlements/estimate")

  if (loadingHome) {
    return <SettlementsHomeSkeleton />
  }

  if (loadingGetEstimate) {
    return <SettlementsGetEstimateSkeleton />
  }

  if (loadingEstimate) {
    return <SettlementsEstimateSkeleton />
  }

  // default to home loader
  return <SettlementsHomeSkeleton />
}

export default withSuspense(() => <LazyPage />, <SettlementsPageFallback />, <SettlementsPageError />)
