export enum SECTIONS {
  CASE_FACTS = "facts",
  PROVIDERS = "providers",
  HOUSEHOLD_LOSS = "household_loss",
  INCOME_LOSS = "income_loss",
}

export enum INSTRUCTIONS {
  WAIT = "wait",
  PROCEED_WITHOUT = "proceed",
  UPLOAD = "upload",
  UNRESOLVED = "unresolved",
}
