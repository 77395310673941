import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { CircularProgress, IconButton, SxProps } from "@mui/material"
import { VerticalCenterBox } from "common/FlexBox"
import { StyledInput } from "./styled"
import TextButton from "common/buttons/TextButton"

interface EditableTextProps {
  value: string
  editingSuffix?: string
  isEditable?: boolean
  canSave?: (value: string) => boolean
  onEdit?: (isEditing: boolean) => void
  onSave: (value: string) => Promise<unknown> | unknown
  displayValue?: ReactNode
  sx?: SxProps
}

export function EditableText({
  value,
  editingSuffix,
  isEditable,
  canSave,
  onEdit,
  onSave,
  displayValue,
  sx,
  ...props
}: EditableTextProps): JSX.Element {
  const [isSaving, setIsSaving] = useState(false)
  const [edit, setEdit] = useState(false)
  const [inputValue, setInputValue] = useState(value)

  const inputValueRef = useRef(inputValue)
  inputValueRef.current = inputValue

  const isValid = canSave ? canSave(inputValue) : !!inputValue

  const setEditValue = useCallback(
    (value: boolean) => {
      setEdit(value)
      onEdit?.(value)
    },
    [onEdit]
  )

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }, [])

  const handleEditClick = useCallback(() => {
    setInputValue(value)
    setEditValue(true)
  }, [value, setEditValue])

  const handleCancel = useCallback(() => {
    setEditValue(false)
    setInputValue(value)
  }, [value, setEditValue])

  const handleSave = useCallback(async () => {
    if (inputValueRef.current) {
      setIsSaving(true)
      await onSave(inputValueRef.current)
      setIsSaving(false)
      setEditValue(false)
    }
  }, [onSave, setEditValue])

  const handleSaveOrEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") setEditValue(false)
      if (event.key === "Enter" && isValid) handleSave()
    },
    [handleSave, isValid, setEditValue]
  )

  useEffect(() => {
    if (!isSaving && edit) {
      document.addEventListener("keydown", handleSaveOrEscape)
      return () => document.removeEventListener("keydown", handleSaveOrEscape)
    }
  }, [isSaving, edit, handleSaveOrEscape])

  return (
    <VerticalCenterBox sx={{ gap: 1, ...sx }} {...props}>
      {!edit ? (
        <>
          {displayValue || value}
          {isEditable && (
            <IconButton size="small" onClick={handleEditClick} aria-label="edit item">
              <EditOutlinedIcon sx={{ height: 16, width: 16 }} />
            </IconButton>
          )}
        </>
      ) : (
        <>
          <StyledInput
            size="small"
            autoFocus
            value={inputValue}
            onChange={handleChange}
            disabled={isSaving}
          />
          {editingSuffix}
          <TextButton
            size="small"
            onClick={handleSave}
            disabled={isSaving || !isValid}
            startIcon={
              isSaving ? (
                <IconButton size="small">
                  <CircularProgress size={16} />
                </IconButton>
              ) : null
            }
          >
            SAVE
          </TextButton>
          <TextButton size="small" textColor="grey" onClick={handleCancel} disabled={isSaving}>
            CANCEL
          </TextButton>
        </>
      )}
    </VerticalCenterBox>
  )
}
