import { ReactNode } from "react"

import { SETTINGS_ROUTE_PREFIX } from "./types"
import PersonIcon from "@mui/icons-material/Person"
import ApartmentIcon from "@mui/icons-material/Apartment"
import PeopleIcon from "@mui/icons-material/People"
import FolderIcon from "@mui/icons-material/Folder"
import SettingsIcon from "@mui/icons-material/Settings"
import SwapVertIcon from "@mui/icons-material/SwapVert"
import { SvgIconOwnProps } from "@mui/material"
import { Payments, Receipt } from "@mui/icons-material"

const iconProps: { fontSize: SvgIconOwnProps["fontSize"] } = { fontSize: "small" }
export const SETTINGS_LINK_DETAILS: Record<
  SETTINGS_ROUTE_PREFIX,
  { label: string; altLabel?: string; icon: ReactNode }
> = {
  [SETTINGS_ROUTE_PREFIX.PROFILE]: { label: "Profile", icon: <PersonIcon {...iconProps} /> },
  [SETTINGS_ROUTE_PREFIX.FIRMS]: { label: "Firms List", icon: <ApartmentIcon {...iconProps} /> },
  [SETTINGS_ROUTE_PREFIX.ACCOUNTS]: {
    label: "User Settings",
    altLabel: "Manage Users",
    icon: <PeopleIcon {...iconProps} />,
  },
  [SETTINGS_ROUTE_PREFIX.BILLING]: { label: "Billing", icon: <Receipt {...iconProps} /> },
  [SETTINGS_ROUTE_PREFIX.CREDITS]: { label: "Credits", icon: <Payments {...iconProps} /> },
  [SETTINGS_ROUTE_PREFIX.LIBRARY]: { label: "Library", icon: <FolderIcon {...iconProps} /> },
  [SETTINGS_ROUTE_PREFIX.FEATURE_PERMISSIONS]: {
    label: "Feature Flags",
    icon: <SettingsIcon {...iconProps} />,
  },
  [SETTINGS_ROUTE_PREFIX.IMPORT]: { label: "Import Data", icon: <SwapVertIcon {...iconProps} /> },
}
