import { useMemo } from "react"
import { Box, Skeleton } from "@mui/material"

import { smartAdvocateGetCaseInformation } from "api"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"

import { withSuspense } from "common/withSuspense"
import { SmartAdvocateCase, SmartAdvocatePlaintiff } from "./types"
import { useSuspenseQuery } from "@suspensive/react-query"
import invariant from "invariant"

interface CaseInformation {
  caseType: string
  plaintiffNames: string
}

interface CaseInfoProps {
  caseNumber: Nullable<string>
}

export const CaseInfo = withSuspense(
  ({ caseNumber }: CaseInfoProps) => {
    invariant(!!caseNumber, "Valid Case Number should be provided")

    // TODO: Refactor to move serializer logic to API Service
    const { data: caseInformationResponse } = useSuspenseQuery(
      [queryKeys.smartAdvocateCaseInfo, caseNumber],
      async () => smartAdvocateGetCaseInformation(caseNumber),
      SILENT_QUERY_PARAMS
    )

    const caseInformation: Nullable<CaseInformation> = useMemo(() => {
      if (!caseInformationResponse || !caseInformationResponse?.case_information) return null

      const smartAdvocateCase: SmartAdvocateCase = caseInformationResponse.case_information
      const plaintiffs = smartAdvocateCase?.plaintiffs

      return {
        caseType: smartAdvocateCase.caseType,
        plaintiffNames: plaintiffs
          .map((plaintiff: SmartAdvocatePlaintiff) => {
            return plaintiff.name
          })
          .join(", "),
      }
    }, [caseInformationResponse])

    if (!caseInformation) return <></>

    return (
      <Box mb={3} display="flex" flexDirection="column">
        <Box>Plaintiffs: {caseInformation.plaintiffNames}</Box>
        <Box>Case Type: {caseInformation.caseType}</Box>
      </Box>
    )
  },
  <Skeleton width="400px" height="100px" />
)
