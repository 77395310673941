import styled from "@emotion/styled"
import { Box, Button, IconButton, Paper, Typography, Table, TableCell } from "@mui/material"
import { HEADER_NAV_HEIGHT_SPACE_MULTIPLIER } from "app/constants"

interface StyledFiltersWrapperProps {
  collapsed: boolean
}

export const StyledFiltersWrapper = styled("div")<StyledFiltersWrapperProps>(({ theme, collapsed }) => ({
  position: "fixed",
  left: 0,
  padding: theme.spacing(2, 3),
  top: theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER),
  height: "calc(100vh - 80px)",
  width: collapsed ? "60px" : "285px",
  border: "1px solid #EDECEC",
  borderLeftWidth: "0px",
  background: "#FDFDFD",
  transition: "0.2s",
  overflow: "hidden",
  textWrap: "nowrap",
  cursor: collapsed ? "pointer" : "auto",

  ":hover": {
    boxShadow: collapsed ? "1px 0px 6px 0px rgba(0, 0, 0, 0.14)" : "none",
  },
}))

export const StyledTitle = styled("div")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  paddingBottom: theme.spacing(3),
}))

export const StyledIconButton = styled(IconButton)({
  position: "absolute",
  top: "5px",
  transition: "0.2s",
})

export const StyledProviderFilterWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
}))

export const ResetButton = styled(Button)(({ theme }) => ({
  fontSize: "13px",
  textDecoration: "underline",
  color: theme.palette.blue.primary,
  marginLeft: "auto",

  ":hover": {
    textDecoration: "underline",
  },
}))

export const DocumentTitle = styled(Typography)(() => ({
  fontSize: "30px",
  fontWeight: "700",
  lineHeight: "20px",
  display: "flex",
  alignItems: "center",
  gap: "10px",
}))
DocumentTitle.defaultProps = {
  variant: "subtitle1",
}

export const StyledTag = styled(Typography)(({ theme }) => ({
  background: theme.palette.blue.light,
  borderRadius: "100px",
  padding: "0 7px",
}))

export const DocumentHeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(4.5),
  alignItems: "center",
  justifyContent: "space-between",
}))

type LinkButtonProps = { target?: string }
export const LinkButton = styled(Button)<LinkButtonProps>(({ theme }) => ({
  color: theme.palette.blue.primary,
  borderColor: theme.palette.secondary.main,
  borderRadius: "6px",
}))
LinkButton.defaultProps = {
  variant: "outlined",
  disableElevation: true,
  target: "_blank",
}

export const FloatingIndicator = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 20,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0, -2),

  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.contrastText,
}))

export const StyledTableHeadCell = styled(TableCell)`
  color: #636c79;
`

export const StyledViewTable = styled(Table)`
  margin-bottom: ${({ theme }) => theme.spacing(3.5)};
  border-radius: 4px;
  box-shadow: 0 0 0 1px #d8d8d8;

  thead td {
    border-bottom: 1px solid #d8d8d8;
  }

  tbody td {
    border-bottom-color: rgba(0, 0, 0, 0.05);
    vertical-align: top;
  }

  tbody > tr:last-of-type > td {
    border-bottom-width: 0px;
  }
`
StyledViewTable.defaultProps = {
  size: "medium",
}
