import React, { useCallback, useRef } from "react"
import { KeysFor } from "common/types/helper"
import { MenuItemText, MenuItem } from "./styled"

interface LeafMenuItemProps<T extends BaseObject> {
  item: T
  label: KeysFor<T, string>
  displayLabel?: KeysFor<T, Nullable<string> | undefined>
  onClick: (item: T) => void
  onHover?: (item: Nullable<T>, hoverRef?: React.RefObject<Element>) => void
}

export function LeafMenuItem<T extends BaseObject>({
  item,
  label,
  displayLabel,
  onClick,
  onHover,
}: LeafMenuItemProps<T>): JSX.Element {
  const displayValue = displayLabel && item[displayLabel]
  const value = displayValue || item[label]
  const ref = useRef(null)

  const handleMouseOver = useCallback(() => {
    onHover?.(item, ref)
  }, [item, onHover])

  const handleMouseOut = useCallback(() => {
    onHover?.(null, ref)
  }, [onHover])

  const handleClick = useCallback(() => {
    onClick(item)
  }, [item, onClick])

  return (
    <>
      <MenuItem onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} ref={ref}>
        <MenuItemText>{value as React.ReactNode}</MenuItemText>
      </MenuItem>
    </>
  )
}
