import { useCallback, useEffect, useRef, useState } from "react"
import { Navigate, useParams, useSearchParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useQueryClient } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { AnnotatedExhibit, Duplicate, Split } from "demand/Files/types"
import { GenericErrorForSuspense, Loading } from "common"
import { PdfPagesRange, PdfRenderer } from "common/pdf-renderer"
import { getUrl } from "apiHelper"
import { useSubscribe } from "message-broker/useSubscribe"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { PinControl } from "./PinControl"
import { PDF_FILENAME_QUERY_PARAM, PDF_PAGE_QUERY_PARAM } from "./constants"
import { ExhibitIsUpdatedPayload } from "message-broker/types"
import { usePerformance } from "hooks/usePerformance"
import { v4 } from "uuid"
import { ExhibitServiceOptions, exhibitService } from "api/services/exhibit"
import invariant from "invariant"
import { useSuspenseQuery } from "@suspensive/react-query"
import { withSuspense } from "common/withSuspense"
import { useTimeSpendingAnalytic } from "infrastructure/timeSpending/hooks"

function isDuplicate(partition: Duplicate | Split): partition is Duplicate {
  if ("partition_type" in partition && partition.partition_type === "duplicate") {
    return true
  } else {
    return false
  }
}

export const ExhibitPreviewPage = withSuspense(
  function ExhibitPreviewPage(): JSX.Element {
    const { caseId, exhibitId, documentId, partitionId: partitionIdKey } = useParams()
    const [searchParams] = useSearchParams()
    const queryClient = useQueryClient()
    const page = parseInt(searchParams.get(PDF_PAGE_QUERY_PARAM)?.toLowerCase() || "1") || 1
    const filename = searchParams.get(PDF_FILENAME_QUERY_PARAM)
    const { showErrorMessage } = useHandleMessages()

    const [performanceKey, setPerformanceKey] = useState(v4())
    const exhibitLoadPerformance = usePerformance("exhibit_fetch", { initial: true }, [
      exhibitId,
      performanceKey,
    ])

    invariant(
      (!!caseId && !documentId) || (!caseId && !!documentId),
      "Only documentId or caseId should be present at a time"
    )
    invariant(!!exhibitId, "Exhibit id should be passed")

    const { data: exhibit } = useSuspenseQuery<AnnotatedExhibit>({
      queryKey: [queryKeys.annotated_exhibits, caseId || documentId, exhibitId],
      queryFn: () =>
        exhibitService.getAnnotatedExhibit({ caseId, documentId, exhibitId } as ExhibitServiceOptions),
      staleTime: Infinity,
      keepPreviousData: false,
      meta: SILENT_QUERY_PARAMS.meta,
      retry: false,
      onSettled: () => exhibitLoadPerformance.measure(),
    })

    const invalidateExhibit = useCallback(() => {
      queryClient.invalidateQueries([queryKeys.annotated_exhibits, caseId || documentId])
      queryClient.invalidateQueries([queryKeys.exhibits, caseId || documentId])
      // Reset key so we can have individual measurements for each load
      setPerformanceKey(v4())
    }, [queryClient, caseId, documentId])
    const invalidateRef = useRef(invalidateExhibit)
    invalidateRef.current = invalidateExhibit

    useTimeSpendingAnalytic({ documentId })

    useSubscribe(
      MESSAGE_TOPIC.EXHIBIT_IS_UPDATED,
      async ({ data }) => {
        const payload = (data.payload ?? {}) as ExhibitIsUpdatedPayload
        const caseIdIsEqual = Number(payload.caseId) === Number(caseId)
        const exhibitIdIsEqual = Number(payload.exhibitId) === Number(exhibitId)

        if (caseIdIsEqual && exhibitIdIsEqual) {
          invalidateExhibit()
        }
      },
      {}
    )

    const partitionId = partitionIdKey ? parseInt(partitionIdKey) : null
    const partition =
      exhibit && partitionId !== null
        ? [...exhibit.duplicates, ...exhibit.splits].find(partition => partition.pk === partitionId) ?? null
        : null

    const partitionRef = useRef(partitionId)

    useEffect(() => {
      if (partitionId !== null && !partition) {
        if (partitionRef.current !== partitionId) {
          invalidateRef.current()
          partitionRef.current = partitionId
        } else {
          showErrorMessage({
            message: `Partition ${partitionId} not found.`,
          })
        }
      }
    }, [showErrorMessage, partitionId, partition])

    if (partitionId !== null && !partition && partitionRef.current === partitionId) {
      return <Navigate to="/exhibit-preview" replace />
    }

    const url = getUrl(
      partition
        ? exhibitService.getPartitionDownloadPath({ exhibitId: exhibit.pk, partitionId: partition.pk })
        : exhibitService.getDownloadPath({ caseId: caseId as string, exhibitId: exhibit.pk })
    )
    const pageNumberAdjustment = partition ? partition.start_page - 1 : 0
    const initialPage = Math.max(page, pageNumberAdjustment + 1)

    const actions = partition && isDuplicate(partition) ? partition.actions ?? [] : exhibit.actions
    const deletedPages: PdfPagesRange[] = actions.reduce<PdfPagesRange[]>((acc, action) => {
      if (action.action_type === "delete") {
        acc.push([action.start_page, action.end_page ?? action.start_page])
      }

      return acc
    }, [])

    return (
      <>
        <Helmet>
          <title>{exhibit.name || "Exhibit preview"}</title>
        </Helmet>
        <PdfRenderer
          key={url}
          exhibitId={exhibitId}
          filename={exhibit.name}
          currentFileName={filename ?? undefined}
          pageNumberAdjustment={pageNumberAdjustment}
          url={url}
          page={initialPage}
          withCredentials
          deletedPages={deletedPages}
          rightControls={<PinControl />}
        />
      </>
    )
  },
  <Loading show label="Loading exhibit data..." />,
  <GenericErrorForSuspense />
)
