import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import { caseService } from "api/services/case"
import { CASE_SECTIONS } from "common/types/sections"
import { CaseAlert } from "demand/components/CaseAlert"

import size from "lodash/size"
import { useSuspenseQuery } from "@suspensive/react-query"

import { getCommonMutateOptions } from "../utils/common"
import { getChangedFields } from "../utils/common"

import { CaseSection } from "./CaseSection"

import { withSuspense } from "common/withSuspense"

import { makeStyles } from "tss-react/mui"
import { useMutation } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"

import { SILENT_QUERY_PARAMS, queryKeys } from "../react-query/constants"
import useAutosave from "../hooks/useAutosave"
import { useFormContext } from "./context"

import { formSectionsToRoutes, STEP_STATUSES } from "./constants"
import { queryClient } from "react-query/queryClient"
import { CaseIntroductionDto } from "api/services/case/types"

const useStyles = makeStyles()(() => ({
  fieldBox: {
    width: "100%",
  },
}))

interface Props {
  lastVisited: React.MutableRefObject<Nullable<string>>
}

export const Introduction = withSuspense(
  function Introduction({ lastVisited }: Props): JSX.Element {
    const { classes } = useStyles()
    const { caseId, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus } = useFormContext()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { saveRef } = useOutletContext<{ saveRef: React.MutableRefObject<(...args: any[]) => void> }>()
    const [errors, setErrors] = useState(null)

    const { data: caseIntroduction } = useSuspenseQuery(
      [queryKeys.introduction, caseId],
      () => caseService.getCaseIntroduction({ caseId }),
      { meta: SILENT_QUERY_PARAMS.meta }
    )
    const userActionRequired = Object.values(caseIntroduction.sections).some(
      section => section.userActionRequired
    )

    const { control, handleSubmit, reset, formState, getValues, setValue } = useForm({
      values: caseIntroduction,
      resetOptions: { keepDirtyValues: true },
    })

    const contentId = caseIntroduction?.pk

    const mutationOptions = getCommonMutateOptions({
      setErrors,
      reset,
      setSavedSuccessfully,
      showErrorMessage,
      setValue,
      onSuccessExtra: (data: CaseIntroductionDto) => {
        handleUpdateStepStatus({ status: STEP_STATUSES.completed })
        queryClient.setQueryData([queryKeys.introduction, caseId], () => {
          return data
        })
      },
    })
    const createMutation = useMutation(caseService.saveCaseIntroduction, mutationOptions)

    const handleOnBlur = handleSubmit(async data => {
      const hasChangedFields = size(getChangedFields(data, formState)) > 0
      hasChangedFields && createMutation.mutate({ caseId, data: data })
    })

    const handleFormSubmit = handleSubmit(data => {
      return createMutation.mutateAsync({ caseId, data: data })
    })

    useEffect(() => {
      saveRef.current = handleFormSubmit
    }, [saveRef, handleFormSubmit])

    useAutosave({
      shouldAutosave: formState.isDirty && !!contentId,
      save: () => {
        const data = getValues()
        createMutation.mutate({
          caseId,
          data: data,
        })
      },
    })

    useEffect(() => {
      lastVisited.current = formSectionsToRoutes.introduction
    })

    return (
      <>
        {userActionRequired && (
          <CaseAlert
            title="New templates have been added to reflect the newly selected attributes."
            message="Ensure templates are modified appropriately below."
          />
        )}
        {errors && <span role="alert">{errors}</span>}
        <Box className={classes.fieldBox} onBlur={handleOnBlur}>
          <CaseSection
            control={control}
            section={CASE_SECTIONS.INTRODUCTION}
            caseId={caseId}
            exhibits={null}
          />
        </Box>
      </>
    )
  },
  <Skeleton height={70} animation="wave" variant="rounded" />
)
