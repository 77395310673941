import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import AnnotationKiliStatusEntry from "./AnnotationKiliStatusEntry"
import { kiliStatusToProperties, TODO_STATUS } from "./annotationStatusProperties"
import { AnnotationFile } from "./api"

interface AnnotationFileStatusTableInterface {
  file: AnnotationFile
}
export default function AnnotationFileStatusTable({ file }: AnnotationFileStatusTableInterface): JSX.Element {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>External ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Last Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(file?.status?.json?.kili_assets ?? {}).map(k => {
            const asset = file.status.json.kili_assets[k]
            let asset_status = kiliStatusToProperties[asset.status]
            if (asset_status === undefined) {
              asset_status = kiliStatusToProperties[TODO_STATUS]
            }
            return <AnnotationKiliStatusEntry asset={asset} asset_status={asset_status} key={asset.id} />
          })}
        </TableBody>
      </Table>
    </>
  )
}
