import styled from "@emotion/styled"

import { Box, Button, FormControl, Grid } from "@mui/material"
import { InputField, SelectInput } from "common/form-components"

export const SectionHeader = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "20px",
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  alignSelf: "center",
}))

export const StyledInputField = styled(InputField)(() => ({
  marginRight: "0px",
  "& > .MuiInputBase-root": {
    borderRadius: "8px",
  },
}))

export const StyledSelectField = styled(SelectInput)(() => ({
  "& * .MuiPaper-root": {
    display: "none",
  },
  "& > .MuiInputBase-root": {
    borderRadius: "8px",
  },
}))

export const SummaryContainer = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: "8px",
}))

export const InputContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    maxHeight: "100%",
  },
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}))

export const GenerateButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "14px",
  borderRadius: "6px",
  marginTop: theme.spacing(2),
}))
