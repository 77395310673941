import { EmptyTableIcon } from "./assets"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4, "20%"),
  },
  image: {
    margin: "auto",
  },
  message: {
    fontWeight: 500,
    fontSize: theme.spacing(4),
    margin: "auto",
    textAlign: "center",
    textTransform: "capitalize",
  },
  largeFont: {
    fontSize: theme.spacing(4),
  },
  midFont: {
    fontSize: theme.spacing(3),
  },
}))

const EmptyState = ({ message = "No Data", imageSize = "80px", color = "#BEBEBE", fontSize = "large" }) => {
  const { classes } = useStyles()
  const fontSizeClass = fontSize === "large" ? classes.largeFont : classes.midFont

  return (
    <div className={classes.container} data-test="empty-state">
      <div className={classes.image}>
        <EmptyTableIcon width={imageSize} height={imageSize} stroke={color} />
      </div>
      <div className={`${classes.message} ${fontSizeClass}`}>{message}</div>
    </div>
  )
}

export { EmptyState as default }
