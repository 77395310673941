import { pick } from "lodash"
import { IntakeStatusOptions, statusToPropertiesInternalOSFOnly } from "../requestStatusProperties"
import { INTAKE_STATUSES } from "../../constants"

export function getStatusOptionsForOSF(
  currentStatus: INTAKE_STATUSES,
  demandId: Nullable<PrimaryKey>
): Partial<IntakeStatusOptions> {
  if (currentStatus === INTAKE_STATUSES.taskerCompleted)
    return pick(statusToPropertiesInternalOSFOnly, [INTAKE_STATUSES.taskerCompleted])

  if (currentStatus === INTAKE_STATUSES.taskerAssigned) {
    const statuses = [INTAKE_STATUSES.taskerAssigned]

    if (demandId !== null) {
      statuses.push(INTAKE_STATUSES.taskerCompleted)
    }

    const statusToProperties = pick(statusToPropertiesInternalOSFOnly, statuses)
    const taskerAssignedProperties = statusToProperties[INTAKE_STATUSES.taskerAssigned]

    if (taskerAssignedProperties) {
      taskerAssignedProperties.disabled = true
    }

    return statusToProperties
  }

  return {}
}
