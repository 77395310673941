import styled from "@emotion/styled"
import { SortableNestedListItem } from "common/nested-list/SortableNestedListItem"
import { ExhibitName } from "exhibit-builder/Exhibit/styled"
import { DndExhibitPartitionData } from "exhibit-builder/store/types"
import { grey } from "@mui/material/colors"

export const StyledSortableNestedListItem = styled(SortableNestedListItem<DndExhibitPartitionData>)(
  ({ theme }) => ({
    "& [data-type='list-item']": {
      border: 0,
      padding: 0,
    },

    "& [data-type='list-item-content']": {
      position: "relative",
      padding: theme.spacing(0, 2),
      backgroundColor: grey[100],
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.divider}`,

      [`${ExhibitName}`]: {
        color: theme.palette.blue.primary,
        textDecoration: "underline",
        fontWeight: "bold",
      },
    },

    "& [data-type='list-item-children']": {
      padding: "0 1px",
    },

    "& [data-type=empty-placeholder]": {
      backgroundColor: "white",
      margin: 0,
      padding: 0,
    },
  })
)
