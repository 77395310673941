import Box from "@mui/material/Box"
import styled from "@emotion/styled"

export const StackCell = styled(Box)({
  display: "flex",
  flexDirection: "column",
})

export const Link = styled(Box)(({ theme }) => ({
  color: theme.palette.blue.main,
  textDecoration: "underline",
  "&:hover": {
    cursor: "pointer",
  },
}))
