import Chip, { ChipProps } from "@mui/material/Chip"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { makeStyles } from "tss-react/mui"

interface StylesProp {
  backgroundColor: string
  fontColor: string
}

const useStyles = (props: StylesProp) =>
  makeStyles()(theme => ({
    status: {
      "& .MuiChip-label": {
        padding: "0px",
      },
      backgroundColor: props.backgroundColor,
      color: props.fontColor,
      fontSize: "0.75rem",
      width: "107px",
      height: "24px",
      borderRadius: "0.25rem",
      margin: theme.spacing("auto", 2, "auto", 0),
    },
  }))

export const MissingDocContainer = styled(Box)(({ theme }) => ({
  minHeight: "70px",
  display: "flex",
  flexDirection: "column",
  border: "0.5px solid #BEBEBE",
  padding: theme.spacing(1.5, 2),
  transition: "all .35s ease-in-out",
}))

export const SubTitle = styled(Box)(({ theme }) => ({
  fontWeight: 200,
  fontSize: "1.5rem",
  marginBottom: theme.spacing(2),
}))

export const MinorTitle = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1.5rem",
  marginBottom: theme.spacing(2),
}))

interface StatusProps extends ChipProps {
  backgroundColor: string
  fontColor: string
}

export const Status = (props: StatusProps): JSX.Element => {
  const { backgroundColor, fontColor, ...otherProps } = props
  const { classes } = useStyles({
    backgroundColor,
    fontColor,
  })()
  return <Chip className={classes.status} {...otherProps} data-test="status" />
}

export const SectionTitle = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: 1.89,
  letterSpacing: "-0.36px",
}))
