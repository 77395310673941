import { DOCUMENT_STRUCTURE_TREE_ACTION_TYPE, DocumentStructureTreeAction } from "./actions"
import { DocumentStructureStoreData } from "./types"
import {
  createDocumentStructureElement,
  updateDocumentStructureElement,
  moveDocumentStructureElement,
  deleteDocumentStructureElement,
  setDocumentStructureItem,
  resetDocumentStructureItem,
  setDocumentStructureItems,
  updateDocumentStructureItem,
} from "./updaters"
import { replaceDocumentStructureItem } from "./updaters/replace"

export function documentStructureTreeReducer(
  state: DocumentStructureStoreData,
  action: DocumentStructureTreeAction
): DocumentStructureStoreData {
  switch (action.type) {
    case DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.CREATE:
      return createDocumentStructureElement(state, action.payload.data, action.payload.parentId)
    case DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE:
      return updateDocumentStructureElement(state, action.payload.id, action.payload.data)
    case DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.MOVE:
      return moveDocumentStructureElement(
        state,
        action.payload.id,
        action.payload.parentId,
        action.payload.index
      )
    case DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.DELETE:
      return deleteDocumentStructureElement(state, action.payload.id)
    case DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.SET_ITEM:
      return setDocumentStructureItem(state, action.payload.id, action.payload.data)
    case DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.SET_ITEMS:
      return setDocumentStructureItems(state, action.payload)
    case DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE_ITEM:
      return updateDocumentStructureItem(state, action.payload.id, action.payload.data)
    case DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.RESET_ITEM:
      return resetDocumentStructureItem(state, action.payload.id)
    case DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.REPLACE_ITEM:
      return replaceDocumentStructureItem(state, action.payload.id, action.payload.data)
  }
}
