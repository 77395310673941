import JSZip from "jszip"
import mimeMap from "./mimeMapping"
import splitFileName from "common/form-components/files/splitFileName"
import { isZipFile } from "requests/RequestForm/FileManagement/fileUtils"

type JSZipWrapper = [string, JSZip.JSZipObject]

function removePathFromFilename(pathName: string) {
  const separator = "/"
  const parts = pathName.split(separator)
  return parts[parts.length - 1]
}

async function getFileObject(jsZipWrapper: JSZipWrapper) {
  const fileName = jsZipWrapper[0]
  const jsZipObject = jsZipWrapper[1]
  return jsZipObject.async("blob").then(fileData => {
    // Note:  We're calculating mimeType from file extension.
    // This isn't any sort of guarantee against hostile files
    // But it should be good enough for a validation check before we submit to the BE
    const pathStrippedFilename = removePathFromFilename(fileName)
    const splitName = splitFileName(pathStrippedFilename)
    const extension = splitName[splitName.length - 1]
    const mimeType = extension && extension in mimeMap ? mimeMap[extension as keyof typeof mimeMap] : ""
    return new File([fileData], pathStrippedFilename, { type: mimeType })
  })
}

function filterMetaFiles(jsZipWrapper: JSZipWrapper) {
  if (jsZipWrapper[1].dir) {
    return false
  }
  if (jsZipWrapper[0].startsWith("__MACOSX")) {
    return false
  }
  if (jsZipWrapper[0].endsWith(".DS_Store")) {
    return false
  }
  return true
}

const GIGABYTE = 1_073_741_824

export function processZipFile(file: File): Promise<Array<File>> {
  if (!isZipFile(file)) {
    return Promise.reject(
      new Error("This file cannot be unzipped automatically, but may be able to be unzipped")
    )
  }

  if (file.size > GIGABYTE * 1.5) {
    return Promise.reject(
      new Error(
        "Cannot automatically unzip files larger than 1.5GB.  We may still be able to process it, but additional charges may occur."
      )
    )
  }

  const zip = new JSZip()
  return zip.loadAsync(file).then(async content => {
    if (Object.entries(content.files).length === 0) {
      throw new Error("This zip file is empty")
    }
    const unzippedFiles = await Promise.all(
      Object.entries(content.files).filter(filterMetaFiles).map(getFileObject)
    )
    return unzippedFiles
  })
}
