import React from "react"
import isEmpty from "lodash/isEmpty"
import { CSSObject } from "@mui/material"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import CopyIcon from "@mui/icons-material/ContentCopy"

import { ColumnWrapperProps } from "../types"
import { StyledColumnWrapperField, StyledColumnWrapperValue } from "../styled"

interface InfoContainerProps extends ColumnWrapperProps {
  styleOverrides?: CSSObject
}

export const InfoContainer = ({ field, value, isCopyable, styleOverrides }: InfoContainerProps) => {
  const isValueEmpty = isEmpty(value)
  const displayValue = isValueEmpty ? "-" : value
  const valueIsString = typeof value === "string"

  const valueElement = React.isValidElement(value) ? (
    value
  ) : (
    <StyledColumnWrapperValue data-test={`${field}-value`}>{displayValue}</StyledColumnWrapperValue>
  )

  const handleCopy = () => {
    navigator.clipboard.writeText(String(value))
  }

  const showCopyButton = isCopyable && !isValueEmpty && valueIsString

  return (
    <Box
      sx={{
        "&:hover .copyButton": {
          opacity: 1,
        },
        ...styleOverrides,
      }}
    >
      <StyledColumnWrapperField>{field}</StyledColumnWrapperField>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {valueElement}
        {showCopyButton && (
          <IconButton
            className="copyButton"
            onClick={handleCopy}
            sx={{
              padding: 0,
              marginLeft: 1,
              opacity: 0,
              transition: "opacity 0.3s ease",
            }}
          >
            <CopyIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}
