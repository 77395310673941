import React from "react"
import styled from "@emotion/styled"
import { Box, SxProps } from "@mui/material"

const SummaryContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  "&:first-of-type": {
    paddingLeft: 0,
  },
}))

const SummaryLabel = styled("span")({
  fontSize: "12px",
  textTransform: "uppercase",
})

const SummaryValue = styled("span")({
  fontSize: "24px",
  fontWeight: "bold",
})

type SummaryItemProps = {
  label: string
  value: string | number
  sx?: SxProps
}

export const SummaryItem: React.FC<SummaryItemProps> = ({ label, value, sx }) => {
  return (
    <SummaryContainer sx={sx}>
      <SummaryLabel>{label}</SummaryLabel>
      <SummaryValue>{value}</SummaryValue>
    </SummaryContainer>
  )
}
