import { memo, useCallback, useEffect, useMemo } from "react"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import SyncIcon from "@mui/icons-material/Sync"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { Link } from "react-router-dom"
import { getAnnotationTag } from "requests/utils"
import { RequestFields } from "requests/RequestForm/types"
import { Icon } from "@mui/material"
import { AnnotationStatus } from "demand/Files/types"

import { RequestTagLabels } from "../RequestTagLabels"
import { NO_MEDICAL_CHRONOLOGY_DOCUMENT } from "../constants"
import {
  StyledDisabledDescriptionWrapper,
  StyledDisabledLabelWrapper,
  StyledLabelWrapper,
  StyledMedChronButton,
  StyledMedChronButtonWrapper,
} from "./styled"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"
import { isNil } from "lodash"
import { usePermissions } from "permissions/usePermissions"
import { PROVIDER_RECONCILIATION_STATUSES } from "documents/types"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
import { useQuery } from "@tanstack/react-query"
import { documentsService } from "api/services/documents"

interface MedicalChronologyButtonProps {
  request: RequestFields
}

interface MedicalChronologyButtonComponentProps {
  request: Omit<RequestFields, "document_id"> & {
    document_id: NonNullable<RequestFields["document_id"]>
  }
}

export const MedicalChronologyButton = memo(function MedicalChronologyButton(
  props: MedicalChronologyButtonProps
) {
  if (!props.request.document_id) return null

  return <MedicalChronologyButtonComponent {...(props as MedicalChronologyButtonComponentProps)} />
})

export const MedicalChronologyButtonComponent = memo(function MedicalChronologyButtonComponent({
  request,
}: MedicalChronologyButtonComponentProps) {
  const { showErrorMessage } = useHandleMessages()
  const { medchronProviderReconciliationEnabled } = usePermissions({
    firmId: request.firm_id,
  })

  const MEDCHRON_REFETCH_INTERVAL = 10000

  const { data } = useQuery(
    [queryKeys.documentMedchronTile, request.document_id],
    () => {
      return documentsService.getMedchronTile({ documentId: request.document_id })
    },
    {
      refetchInterval: data => {
        if (!medchronProviderReconciliationEnabled) return false
        if (
          data &&
          [PROVIDER_RECONCILIATION_STATUSES.COMPLETE, PROVIDER_RECONCILIATION_STATUSES.FAILED].includes(
            data.providerReconciliationStatus
          )
        )
          return false
        return MEDCHRON_REFETCH_INTERVAL
      },
      ...SILENT_QUERY_PARAMS,
    }
  )

  const providerReconciliationCompleted = medchronProviderReconciliationEnabled
    ? data && data.providerReconciliationStatus !== PROVIDER_RECONCILIATION_STATUSES.PENDING
    : true
  const annotationCompleted =
    request.annotation_status && request.annotation_status === AnnotationStatus.COMPLETE
  const disabled = !(annotationCompleted && providerReconciliationCompleted)
  const tags = useMemo(
    () => (request.annotation_status ? [getAnnotationTag(request.annotation_status)] : []),
    [request.annotation_status]
  )

  const handleClick = useCallback(() => {
    if (!request.document_id || isNil(request.pk)) return

    amplitudeApm.trackEvent(
      new MedicalChronologyAnalyticEvent(MedicalChronologyAnalyticsEventTypes.ViewedMedicalChronology, {
        document_id: request.document_id,
        request_id: request.pk,
      })
    )
  }, [request.document_id, request.pk])

  useEffect(() => {
    if (!request.document_id) {
      showErrorMessage(NO_MEDICAL_CHRONOLOGY_DOCUMENT)
    }
  }, [request.document_id, showErrorMessage])

  if (!request.document_id) {
    return null
  }

  return (
    <StyledMedChronButtonWrapper withTags={!!tags.length}>
      <StyledLabelWrapper>
        <RequestTagLabels tags={tags} />
      </StyledLabelWrapper>
      <StyledMedChronButton
        disabled={disabled}
        component={Link}
        data-test="go-to-demand-button"
        onClick={handleClick}
        to={`/documents/${request.document_id}/exhibit-management/`}
      >
        Go To Medical Chronology
      </StyledMedChronButton>
      {!annotationCompleted && (
        <StyledDisabledLabelWrapper>
          <StyledDisabledDescriptionWrapper>
            <Icon>
              <InfoOutlinedIcon />
            </Icon>
            Annotation must be complete to begin Medical Chronology.
          </StyledDisabledDescriptionWrapper>
        </StyledDisabledLabelWrapper>
      )}
      {annotationCompleted && !providerReconciliationCompleted && (
        <StyledDisabledLabelWrapper>
          <StyledDisabledDescriptionWrapper>
            <Icon>
              <SyncIcon />
            </Icon>
            Provider reconciliation in progress.
          </StyledDisabledDescriptionWrapper>
        </StyledDisabledLabelWrapper>
      )}
      {medchronProviderReconciliationEnabled && annotationCompleted && !providerReconciliationCompleted && (
        <StyledDisabledLabelWrapper>
          <StyledDisabledDescriptionWrapper>
            <Icon>
              <SyncIcon />
            </Icon>
            Provider reconciliation in progress.
          </StyledDisabledDescriptionWrapper>
        </StyledDisabledLabelWrapper>
      )}
    </StyledMedChronButtonWrapper>
  )
})
