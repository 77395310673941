import { Container, ExhibitName, ExhibitNameContainer, FileName } from "./styled"
import { ExhibitLink } from "./styled"
import { ReactNode, useCallback } from "react"

interface ExhibitContainerProps {
  index: number
  exhibitName: string
  editable?: boolean
  displayName?: ReactNode
  onPreview: () => void
  renderControls?: () => JSX.Element
  onUserExhibitRename: (newFileName: string) => void
  exhibitReadyToBeViewed: boolean
}

export function ExhibitContainer({
  index,
  exhibitName,
  editable = true,
  displayName,
  renderControls,
  onPreview,
  onUserExhibitRename,
  exhibitReadyToBeViewed = true,
}: ExhibitContainerProps) {
  const handleRename = useCallback(
    (nextFileName: string) => onUserExhibitRename(nextFileName),
    [onUserExhibitRename]
  )

  return (
    <Container>
      <ExhibitNameContainer>
        <ExhibitName data-test="exhibit-name">
          <ExhibitLink disabled={!exhibitReadyToBeViewed} onClick={onPreview}>
            Exhibit {index + 1}
          </ExhibitLink>
        </ExhibitName>
        <FileName
          onSave={handleRename}
          value={exhibitName}
          displayValue={displayName}
          isEditable={editable}
          sx={{ flex: 1 }}
        />
      </ExhibitNameContainer>
      {renderControls && renderControls()}
    </Container>
  )
}
