import { Editor, Path, Element, Node, NodeEntry } from "slate"
import {
  CustomEditor,
  ListElement,
  ListItemContentElement,
  ListItemElement,
  ParagraphElement,
} from "../../CustomEditor"
import { LIST_BLOCK_ELEMENTS, LIST_ITEM_BLOCK_ELEMENTS, LEAF_BLOCK_ELEMENTS } from "../../elements"
import { isBlockNode } from "../../queries"
import { getBlocks } from "../blocks"

export function isList(editor: CustomEditor, node: Node): node is ListElement {
  if (!isBlockNode(editor, node)) return false

  const listNodeTypes: string[] = [LIST_BLOCK_ELEMENTS.ORDERED_LIST, LIST_BLOCK_ELEMENTS.UNORDERED_LIST]

  return listNodeTypes.includes(node.type)
}

export function isListItem(editor: CustomEditor, node: Node): node is ListItemElement {
  if (!isBlockNode(editor, node)) return false

  return node.type === LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM
}

export function isListItemContent(editor: CustomEditor, node: Node): node is ListItemContentElement {
  if (!isBlockNode(editor, node)) return false

  return node.type === LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM_CONTENT
}

export function closestListNode(editor: CustomEditor, at: Path): NodeEntry<ListElement> | undefined {
  return Editor.above(editor, {
    at,
    match: node => isList(editor, node as Element),
    mode: "lowest",
  })
}

export function closestListItemNode(editor: CustomEditor, at: Path): NodeEntry<ListItemElement> | undefined {
  return Editor.above(editor, {
    at,
    match: node => isListItem(editor, node as Element),
    mode: "lowest",
  })
}

export function isInList(editor: CustomEditor, path?: Path): boolean {
  if (path) {
    return Boolean(closestListNode(editor, path))
  }

  if (!editor.selection) return false

  const blocks = getBlocks(editor, LEAF_BLOCK_ELEMENTS.PARAGRAPH)

  const result = blocks.some(([, nodePath]) => {
    const nodeInList = isInList(editor, nodePath)

    return nodeInList
  })

  return result
}

export function isInListOfType(editor: CustomEditor, type: LIST_BLOCK_ELEMENTS): boolean {
  if (!editor.selection) return false

  const leafBlockEntries = getBlocks<ParagraphElement>(editor, LEAF_BLOCK_ELEMENTS.PARAGRAPH)

  return leafBlockEntries.every(([, path]) => {
    const listNodeEntry = closestListNode(editor, path)
    return listNodeEntry ? listNodeEntry[0].type === type : false
  })
}
