import { AUX_WORDS_TO_TITLE_CASE, PROVIDER_ACRONYMS } from "./constants"

export const isUpperCase = (stringToTest: string): boolean => {
  return stringToTest.toUpperCase() === stringToTest
}

const removePeriods = (stringToFilter: string): string => {
  return stringToFilter.replace(/\./g, "")
}

const isProviderAcronym = (stringToTest: string): boolean => {
  return PROVIDER_ACRONYMS.has(removePeriods(stringToTest))
}

export const toTitleCase = (stringToTitleCase: string): string => {
  const titleCasedStrings: string[] = []
  stringToTitleCase.split(" ").forEach((word: string, idx: number) => {
    if (isProviderAcronym(word)) {
      titleCasedStrings.push(word)
    } else if (idx > 0 && !AUX_WORDS_TO_TITLE_CASE.has(word) && word.length < 4) {
      titleCasedStrings.push(word.toLowerCase())
    } else {
      titleCasedStrings.push(word[0] + word.slice(1).toLowerCase())
    }
  })

  return titleCasedStrings.join(" ")
}
