export function filterItems(items: string[], search: string): string[]
export function filterItems<T extends BaseObject>(items: T[], search: string, key: keyof T): T[]
export function filterItems<T>(
  items: T[],
  search: string,
  key?: T extends BaseObject ? keyof T : never
): T[] {
  const resolveValue = (item: T): string => {
    if (key && item && typeof item === "object") {
      return item[key] as string
    }

    if (typeof item === "string") {
      return item
    }

    return ""
  }

  const searchText = search.toLowerCase()

  return searchText ? items.filter(item => resolveValue(item).toLowerCase().includes(searchText)) : items
}
