export function getEnvironment(): string {
  return import.meta.env.VITE_ENV ?? "local"
}

export function isTestEnvironment(): boolean {
  return import.meta.env.VITEST === "true"
}

export function isDevEnvironment(): boolean {
  const env = getEnvironment()
  return env !== "prod" && env !== "preprod" && env !== "stage" && env !== "test"
}

export function isProdEnvironment(): boolean {
  return getEnvironment() === "prod"
}

export default function getApmEnvironment(): string | undefined {
  if (isDevEnvironment()) {
    return `dev-${import.meta.env.VITE_SUB_ENV ?? "local"}`
  }

  return import.meta.env.VITE_ENV
}

export function isControlledEnvironment(): boolean {
  // navigator.webdriver is set to true when chrome is being controlled by automation like playwright
  if (navigator && navigator.webdriver === true) {
    return navigator.webdriver
  }

  try {
    return !!window.Cypress || !!window.parent.Cypress || !!process?.env?.VITEST_POOL_ID
  } catch {
    return false
  }
}
