import { Box, Button, Typography, colors } from "@mui/material"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import styled from "@emotion/styled"
import { PartitionListHeader } from "exhibit-builder/Exhibit/PartitionListHeader"
import { ExhibitName } from "exhibit-builder/Exhibit/styled"
import { exhibitBuilderService } from "exhibit-builder/api/service"
import { noop } from "lodash"

const Info = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.blue.contentBox,
  border: `1px solid ${theme.palette.blue.bannerStroke}`,
  borderRadius: theme.spacing(0.5),
  marginBottom: theme.spacing(1.5),
  maxWidth: "850px",
}))

const FileContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.25, 1.5),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
}))

const DeletedFile = styled(PartitionListHeader)({
  [`${ExhibitName} > *:hover`]: {
    textDecoration: "none",
    cursor: "default",
  },
})

export function UnableToParse() {
  const caseId = useExhibitBuilderStore(useShallow(state => state.caseId))
  const exhibits = useExhibitBuilderStore(
    useShallow(state => Object.values(state.files).filter(file => file.unableToParse))
  )

  const getDownloadUrl = (fileId: string) => {
    return exhibitBuilderService.getExhibitUrl({ caseId, fileId: fileId, asAttachment: true })
  }

  if (!exhibits.length) {
    return null
  }

  return (
    <Box data-test="unable-to-parse" mt={10} mb={4}>
      <Box color={colors.red[700]} pb={0.5} mb={2.5} borderBottom={`1px solid ${colors.red[700]}`}>
        <Box display="flex" alignItems="center" gap={1}>
          <InfoOutlinedIcon fontSize="small" />
          <Typography variant="body1SemiBold" display="flex">
            Unable To Parse
          </Typography>
        </Box>
      </Box>
      <Info>
        <Typography variant="body2">
          Thee files were unable to be parsed by our system and cannot be automatically imported or used in
          exhibit group when ordering. These files may be password protected or simply corrupt. You can
          manually download them and still use them
        </Typography>
      </Info>

      {exhibits.map(exhibit => (
        <FileContainer key={exhibit.id}>
          <DeletedFile
            fileName={exhibit.name}
            onPreview={noop}
            renderCustomContent={() => (
              <Box display="flex" justifyContent="end">
                <Button size="small" download href={getDownloadUrl(exhibit.id)}>
                  Download
                </Button>
              </Box>
            )}
          />
        </FileContainer>
      ))}
    </Box>
  )
}
