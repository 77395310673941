import { noop } from "lodash"
import PQueue from "p-queue"
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api"

type PdfDocumentQueue = Map<number, PQueue>

const pdfDocumentQueues = new WeakMap<PDFDocumentProxy, PdfDocumentQueue>()

interface EnqueueReturn {
  cancel: () => void
}

export type PdfDocumentPageTask = Parameters<PQueue["add"]>["0"]

export function enqueue(
  document: PDFDocumentProxy,
  page: number,
  task: Parameters<PQueue["add"]>["0"]
): EnqueueReturn {
  const controller = new AbortController()
  const documentQueue: PdfDocumentQueue = pdfDocumentQueues.get(document) ?? new Map()
  const pageQueue: PQueue =
    documentQueue.get(page) ?? new PQueue({ concurrency: 1, timeout: 30_000, throwOnTimeout: true })

  pageQueue.add(task, controller).catch(noop)
  documentQueue.set(page, pageQueue)
  pdfDocumentQueues.set(document, documentQueue)

  return {
    cancel: () => controller.abort(),
  }
}

export function clearQueue(document: PDFDocumentProxy, page: number): void {
  const documentQueue: PdfDocumentQueue = pdfDocumentQueues.get(document) ?? new Map()

  const pageQueue: PQueue = new PQueue({ concurrency: 1, timeout: 30_000, throwOnTimeout: true })

  documentQueue.get(page)?.clear()
  documentQueue.set(page, pageQueue)
  pdfDocumentQueues.set(document, documentQueue)
}
