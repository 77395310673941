import styled from "@emotion/styled"
import { Box, Divider, Table, TableCell, Typography } from "@mui/material"
import { theme } from "app/theme"

export const StyledInformationWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(6, 1fr)",
  gap: theme.spacing(2),
})

export const StyledViewRequestWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  margin: `${theme.spacing(2)} auto 0`,
})

export const StyledColumnWrapperValue = styled(Typography)({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.spacing(4),
  wordBreak: "break-word",
})

export const StyledColumnWrapperField = styled(Typography)({
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(14),
  lineHeight: theme.spacing(2.625),
})

export const StyledPlaintiffAdditionalInfo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(4),
  gap: theme.spacing(1),
})

export const StyledSupportingFileTable = styled(Table)(() => ({
  width: "100%",
}))

export const SupportingFileTableCell = styled(TableCell)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}))

export const StyledSupportingFileName = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightSemiBold,
  wordBreak: "break-word",
})

export const StyledInjuryImpactKeyWrapper = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
})

export const StyledDivider = styled(Divider)(() => ({
  margin: theme.spacing(3, 0),
}))

export const StyledAdditionalInformationWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 2,
  wordBreak: "break-all",
})

export const StyledSubmitMedicalChronologyDescription = styled(Box)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  lineHeight: theme.spacing(3),
  marginBottom: theme.spacing(1),
}))

export const StyledContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}))
