import { Editor } from "slate"
import { CustomEditor } from "../CustomEditor"
import { getMarks } from "../queries/marks"
import { TEXT_STYLES } from "../styles"

export function isTextStyleActive(editor: CustomEditor, style: TEXT_STYLES): boolean {
  const marks = getMarks(editor)

  return !!marks[style]
}

export function toggleTextStyle(editor: CustomEditor, style: TEXT_STYLES): void {
  const isActive = isTextStyleActive(editor, style)

  if (isActive) {
    Editor.removeMark(editor, style)
  } else {
    Editor.addMark(editor, style, true)
  }
}
