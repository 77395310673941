import { useCallback, useContext, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { documentStructureService } from "api/services/document-structure"
import { useForm } from "react-hook-form"
import { CircularProgress } from "@mui/material"
import { isNil } from "lodash"
import CancelIcon from "@mui/icons-material/Cancel"

import { DocumentStructureComponent } from "./types"
import { SectionContext } from "./section/context"
import { REF_ID } from "./constants"
import { DialogButton } from "../../buttons/DialogButton"
import {
  StyledBlockTitle,
  StyledAutocompleteWrapper,
  StyledBlockWrapper,
  StyledDeleteButtonWrapper,
  StyledBlockSelect,
} from "./styled"

interface DocumentStructureComponentBlockProps {
  onSave?: (section: DocumentStructureComponent, sectionId: Nullable<PrimaryKey>) => Promise<void>
  onDelete?: (section: DocumentStructureComponent, sectionId: Nullable<PrimaryKey>) => Promise<void>
  item: DocumentStructureComponent
}

export function DocumentStructureComponentBlock({
  item,
  onSave,
  onDelete,
}: DocumentStructureComponentBlockProps): JSX.Element {
  const { sectionId } = useContext(SectionContext)
  const [isDeleting, setIsDeleting] = useState(false)
  const { data } = useQuery([queryKeys.documentStructuresComponents], documentStructureService.getComponents)
  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: item,
    mode: "all",
  })
  const componentKey = watch("componentKey")

  const handleSave = handleSubmit(async template => {
    if (!onSave || !isDirty) return

    const componentBlockData: DocumentStructureComponent = {
      ...template,
      [REF_ID]: item[REF_ID],
    }

    if (item.id !== null) {
      componentBlockData.id = item.id
    }

    await onSave(componentBlockData, sectionId)
    reset({}, { keepValues: true })
  })

  const handleDelete = useCallback(() => {
    if (!onDelete) return

    setIsDeleting(true)
    onDelete(item, sectionId)
  }, [item, onDelete, sectionId])

  return (
    <StyledBlockWrapper onSubmit={handleSave} onBlur={handleSave}>
      <StyledBlockTitle>Component</StyledBlockTitle>
      {data && (
        <StyledAutocompleteWrapper>
          <StyledBlockSelect<DocumentStructureComponent, DocumentStructureComponent["componentKey"]>
            disabled={isSubmitting || !onSave}
            size="small"
            name="componentKey"
            control={control}
            label={isNil(componentKey) ? "Select component" : undefined}
            options={data}
          />
        </StyledAutocompleteWrapper>
      )}
      {onDelete && (
        <StyledDeleteButtonWrapper>
          <DialogButton
            title="Delete?"
            icon={isDeleting ? <CircularProgress size={24} /> : <CancelIcon />}
            confirmationText="Yes, Delete"
            question={
              <span>
                Are you sure you want to delete
                <br />
                <strong>the template placeholder?</strong>
              </span>
            }
            onAction={handleDelete}
            disabled={isDeleting}
          />
        </StyledDeleteButtonWrapper>
      )}
    </StyledBlockWrapper>
  )
}
