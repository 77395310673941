import { DependencyList, useCallback, useEffect, useMemo, useRef } from "react"
import { v4 } from "uuid"
import { getPerformance } from "infrastructure/performance"
import { fullstoryApm } from "infrastructure/apm"
import { omit } from "lodash"

function shallowEqualDeps(prev: DependencyList, next: DependencyList): boolean {
  if (prev.length !== next.length) return false

  return prev.every((value, idx) => value === next[idx])
}

export interface UsePerformanceOptions {
  initial?: boolean
  trailing?: boolean
}

export interface UsePerformanceReturn {
  mark: () => void
  measure: (extraFields?: Record<string, unknown>) => void
}

export function usePerformance(
  name: string,
  options: UsePerformanceOptions,
  deps: DependencyList
): UsePerformanceReturn {
  const depsRef = useRef(deps)
  const nameRef = useRef(name)
  const keyRef = useRef(v4())

  if (nameRef.current !== name || !shallowEqualDeps(depsRef.current, deps)) {
    keyRef.current = v4()
  }

  depsRef.current = deps
  nameRef.current = name

  const performance = useMemo(
    () => getPerformance(nameRef.current, { initial: !!options.initial }),
    [keyRef.current, options.initial] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const measure = useCallback(
    (extraFields: Record<string, unknown> = {}) => {
      const measurement = performance.measure()

      if (measurement) {
        fullstoryApm.trackPerformance(nameRef.current, {
          ...extraFields,
          ...omit(measurement, "name"),
        })
      }
    },
    [performance]
  )

  useEffect(() => {
    if (options.trailing) {
      return () => {
        measure()
      }
    }
  }, [measure, options.trailing])

  const returnValue = useMemo(
    () => ({
      mark: performance.mark,
      measure,
    }),
    [performance.mark, measure]
  )

  return returnValue
}
