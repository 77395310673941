import { PlaintiffInformation } from "./components/PlaintiffInformation"
import { CaseInformation } from "./components/CaseInformation"
import { UploadFiles } from "./components/UploadFiles"
import { FirmInformation } from "./components/FirmInformation"
import Collaborators from "./components/Collaborators"

export const MedicalChronologyForm = () => (
  <>
    <FirmInformation />
    <PlaintiffInformation allowMultiplePlaintiffs={false} minimalForm={true} />
    <CaseInformation includeCaseType={false} includeStateOfIncident={false} />
    <UploadFiles />
    <Collaborators />
  </>
)
