export const SUBSCRIBE_TOPIC = "subscribe" as const
export const UNSUBSCRIBE_TOPIC = "unsubscribe" as const
export const CLAIM_SERVICE_WORKER_TOPIC = "claim_service_worker" as const
export const REACTIVATE_TOPIC = "reactivate" as const
export const REACTIVATED_EVENT_TOPIC = "reactivated_event" as const
export const VERSION_EVENT_TOPIC = "version_event" as const

export enum MESSAGE_TOPIC {
  RENDER_PDF = "render_pdf",
  PREVIEW_EXHIBIT = "preview_exhibit",
  PREVIEW_USER_EXHIBIT = "preview_user_exhibit",
  PREVIEW_USER_EXHIBIT_PARTITION = "preview_user_exhibit_partition",
  EXHIBIT_IS_UPDATED = "exhibit_is_updated",
}
