import React from "react"
import { useTheme } from "@emotion/react"
import { Skeleton } from "@mui/material"

const SettlementsGetEstimateSkeleton: React.FC = () => {
  const theme = useTheme()
  return (
    <Skeleton
      variant="rounded"
      sx={{
        maxWidth: "958px",
        width: "100%",
        height: "1400px",
        marginInline: "auto",
        marginTop: theme.spacing(8),
        borderRadius: "16px",
      }}
    />
  )
}

export default SettlementsGetEstimateSkeleton
