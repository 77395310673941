import { useEffect } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { useNavigate, useSearchParams } from "react-router-dom"
import { makeStyles } from "tss-react/mui"
import { useTheme } from "@mui/material/styles"
import { StytchLogin } from "@stytch/react"
import { OAuthProviders, OneTapPositions, Products, StyleConfig, StytchLoginConfig } from "@stytch/vanilla-js"

import { EVENUP_LOGIN_ID, LS_LOGGED_IN_KEY } from "common/constants"
import useStytchEvents from "infrastructure/vendors/useStytch"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { LoginAnalyticEvent, LoginAnalyticsEventTypes } from "infrastructure/apm/events/loginEvents"
import { v4 } from "uuid"
import { info } from "infrastructure/apm/logger"

const useStyles = makeStyles()(theme => ({
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    gap: theme.spacing(3),
    margin: theme.spacing(4, 0),
  },
  copyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  copyMain: {
    fontWeight: "bold",
  },
  copySub: {
    fontStyle: "italic",
  },
}))

const LoginWithStytch = (): JSX.Element => {
  const [params] = useSearchParams()
  const path = params.get("redirectTo") || "/"
  const search = params.get("search")
  const navigate = useNavigate()
  const { classes } = useStyles()
  const domain = window.location.origin
  const theme = useTheme()
  const searchParam = search ? "&search=" + search : ""
  const { onStytchLogin, onStytchEvent } = useStytchEvents()

  const stytchProps: StytchLoginConfig = {
    products: [Products.emailMagicLinks, Products.oauth],
    emailMagicLinksOptions: {
      loginRedirectURL: `${domain}/authenticate?path=${path}${searchParam}`,
      signupRedirectURL: `${domain}/authenticate?path=${path}${searchParam}`,
    },
    oauthOptions: {
      loginRedirectURL: `${domain}/authenticate?oauth=true&path=${path}${searchParam}`,
      signupRedirectURL: `${domain}/authenticate?oauth=true&path=${path}${searchParam}`,
      providers: [
        {
          type: OAuthProviders.Google,
          one_tap: true,
          position: OneTapPositions.embedded,
        },
        {
          type: OAuthProviders.Microsoft,
        },
      ],
    },
  }

  const overrideStyles: StyleConfig = {
    fontFamily: '"Inter", "Manrope", "Helvetica New", Helvetica, sans-serif',
    colors: {
      primary: theme.palette.primary.dark,
    },
    buttons: {
      primary: {
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
      },
    },
    hideHeaderText: true,
  }

  useEffect(() => {
    function checkIsLoggedIn(event: StorageEvent) {
      if (event.key === LS_LOGGED_IN_KEY && event.newValue) {
        navigate(path)
      }
    }

    window.addEventListener("storage", checkIsLoggedIn)

    return () => {
      window.removeEventListener("storage", checkIsLoggedIn)
    }
  }, [navigate, path])

  useEffect(() => {
    // set a login_id and send a login page viewed amplitude event so we can easily check login conversion rate.
    // "Login Page Viewed" - "Login Success" events with login_id = failed conversions
    const login_id = v4()

    try {
      localStorage.setItem(EVENUP_LOGIN_ID, login_id)
    } catch (e) {
      // in rare cases, localStorage may be full or unavailable. We should still allow the user to log in during those cases so catch the DOMException.
      if (e instanceof DOMException) {
        info({
          message: `Failed to set ${EVENUP_LOGIN_ID} in localStorage, likely because of storage quota exceeded.`,
          extras: {
            error: e,
          },
        })
      } else {
        throw e
      }
    }
    amplitudeApm.trackEvent(
      new LoginAnalyticEvent(LoginAnalyticsEventTypes.LoginPageViewed, { login_id, redirectTo: path, search })
    )
  }, [path, search])

  return (
    <Box className={classes.loginWrapper}>
      <Box className={classes.loginContainer}>
        <Typography variant="h1">Log in to EvenUp</Typography>
        <Box className={classes.copyContainer}>
          <Typography variant="body1" component="p" className={classes.copyMain}>
            Enter your email address below to receive a secured link in your inbox or use your Google or
            Microsoft 365 account to log in.
          </Typography>
          <Typography variant="body1" component="p" className={classes.copySub}>
            Make sure to use the email address you normally use to log in to the EvenUp Law Portal.
          </Typography>
        </Box>
        <StytchLogin
          config={stytchProps}
          styles={overrideStyles}
          callbacks={{
            onEvent: onStytchEvent,
            onError: onStytchLogin,
          }}
        />
      </Box>
    </Box>
  )
}

export default LoginWithStytch
