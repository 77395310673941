import { Transforms, Node, Ancestor } from "slate"
import { createSoftLineBreak } from "../create"
import { CustomEditor } from "../CustomEditor"
import { isInlineNode } from "../queries"

export function insertSectionSymbol(editor: CustomEditor): void {
  if (!editor.selection) return

  Transforms.insertText(editor, "§")
}

export function insertInlineSoftLineBreak(editor: CustomEditor): void {
  if (!editor.selection) return

  Transforms.insertNodes(editor, createSoftLineBreak())

  const path = editor.selection.anchor.path
  const nodePath = path.slice(0, -2)
  const lineBreakIndex = path[path.length - 2] as number
  const nextPoint = { path: [...nodePath, lineBreakIndex + 1], offset: 0 }

  Transforms.setSelection(editor, {
    anchor: nextPoint,
    focus: nextPoint,
  })
}

export const isEmptyNodeText = (editor: CustomEditor, node: Ancestor): boolean => {
  const emptyText = Node.string(node) === ""

  if (node.children) {
    return emptyText && !node.children.some(child => isInlineNode(editor, child))
  }

  return emptyText && !isInlineNode(editor, node)
}
