import { useMemo } from "react"
import { Publish, Subscribe } from "./types"
import { publish } from "./publish"
import { subscribe } from "./subscribe"

export function useMessageBroker(): [Publish, Subscribe] {
  const handlers = useMemo<[Publish, Subscribe]>(() => [publish, subscribe], [])

  return handlers
}
