import { Fragment } from "react"
import {
  TreatmentTimelineEntity as TreatmentTimelineEntityType,
  ABSENT_OF_TREATMENT,
  LAST_ENTITY_TYPE,
  TREATMENT_TIMELINE_ENTITY_TYPE,
} from "./types"
import { AbsentOfTreatment } from "./AbsentOfTreatment"
import { LastTreatmentEntityText } from "./LastTreatmentEntityText"
import {
  StyledTreatmentEntityText,
  StyledBasicTreatmentTimelineEntity,
  StyledTreatmentTimelineLine,
  StyledPointNumber,
  StyledPoint,
} from "./styled"
import { TreatmentTimelineIncidentIndicator } from "./TreatmentTimelineIncidentIndicator"

interface TreatmentTimelineEntityProps {
  entity: TreatmentTimelineEntityType
}

export const TreatmentTimelineEntity = ({ entity }: TreatmentTimelineEntityProps): JSX.Element => {
  switch (entity.type) {
    case ABSENT_OF_TREATMENT:
      return (
        <AbsentOfTreatment entity={entity}>
          <TreatmentTimelineIncidentIndicator incident={entity.incident} />
        </AbsentOfTreatment>
      )
    case LAST_ENTITY_TYPE:
      return <LastTreatmentEntityText entity={entity} />
    default:
      return (
        <StyledBasicTreatmentTimelineEntity>
          <TreatmentTimelineIncidentIndicator incident={entity.incident} />
          <StyledTreatmentEntityText rotated={entity.type === TREATMENT_TIMELINE_ENTITY_TYPE.DAY}>
            {entity.text}
          </StyledTreatmentEntityText>
          {entity.lines.map(line => {
            return (
              <StyledTreatmentTimelineLine key={line.id}>
                {line.points.map(point => (
                  <Fragment key={point.id}>
                    {Number(point?.number) > 1 && (
                      <StyledPointNumber sx={{ left: `${point.percent}%`, color: line.color }}>
                        {point?.number}
                      </StyledPointNumber>
                    )}
                    <StyledPoint sx={{ left: `${point.percent}%` }} htmlColor={line.color} />
                  </Fragment>
                ))}
              </StyledTreatmentTimelineLine>
            )
          })}
        </StyledBasicTreatmentTimelineEntity>
      )
  }
}
