import { FieldValidationError, FieldValidationErrors, Range } from "../types"

interface AddErrorProps {
  formId: string
  errors: FieldValidationErrors
  error: FieldValidationError
}

export const addError = ({ formId, errors, error }: AddErrorProps): void => {
  if (!errors[formId]) {
    errors[formId] = [error]
  } else {
    errors[formId]?.push(error)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const anyErrors = (errors: Record<string, any>): boolean => Object.keys(errors).length > 0

export const displayRange = (range: Range): string => `${range.start} - ${range.end}`

export const ALL_FIELDS = "ALL_FIELDS"
