import { FileFormat } from "api/services/receipt-generation/types"
import { BaseAnalyticsEvent } from "infrastructure/apm/types"

export enum BillingAnalyticsEventTypes {
  BillingPageViewed = "Billing Page Viewed",
  CollectiveStatementDownloadStarted = "Collective Statement Download Started",
  CollectiveStatementDownloadSuccessful = "Collective Statement Download Successful",
  CollectiveStatementDownloadError = "Collective Statement Download Error",
  CollectiveStatementPreviousWeekClicked = "Collective Statement Previous Week Clicked",
  CollectiveStatementPreviousMonthClicked = "Collective Statement Previous Month Clicked",
  CollectiveStatementStartDateEdited = "Collective Statement Start Date Edited",
  CollectiveStatementEndDateEdited = "Collective Statement End Date Edited",
}

export class BillingAnalyticEvent<T extends BillingAnalyticsEventTypes> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: BillingAnalyticsEventDetails[T]
  ) {
    super()
  }
}

// Add new billing event types here
export type BillingAnalyticsEventDetails = {
  [BillingAnalyticsEventTypes.BillingPageViewed]: {
    user_id: number
    user_name: string
  }
  [BillingAnalyticsEventTypes.CollectiveStatementDownloadStarted]: {
    user_id: number
    firm_id: Nullable<number>
    start_date: Nullable<string>
    end_date: Nullable<string>
    file_format: FileFormat
  }
  [BillingAnalyticsEventTypes.CollectiveStatementDownloadSuccessful]: {
    user_id: number
    firm_id: Nullable<number>
    start_date: Nullable<string>
    end_date: Nullable<string>
    file_format: FileFormat
  }
  [BillingAnalyticsEventTypes.CollectiveStatementDownloadError]: {
    user_id: number
    firm_id: Nullable<number>
    start_date: Nullable<string>
    end_date: Nullable<string>
    file_format: FileFormat
  }
  [BillingAnalyticsEventTypes.CollectiveStatementPreviousWeekClicked]: {
    user_id: number
    firm_id: Nullable<number>
  }
  [BillingAnalyticsEventTypes.CollectiveStatementPreviousMonthClicked]: {
    user_id: number
    firm_id: Nullable<number>
  }
  [BillingAnalyticsEventTypes.CollectiveStatementStartDateEdited]: {
    user_id: number
    firm_id: Nullable<number>
    start_date: Nullable<string>
  }
  [BillingAnalyticsEventTypes.CollectiveStatementEndDateEdited]: {
    user_id: number
    firm_id: Nullable<number>
    end_date: Nullable<string>
  }
}
