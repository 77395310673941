import Skeleton from "@mui/material/Skeleton"
import Hidden from "@mui/material/Hidden"
import { Alert, DefaultPage } from "./styled"

interface PdfErrorPageProps {
  message?: string
  scale: number
}

export function PdfErrorPage({
  message = "Failed to load pdf page...",
  scale = 1,
}: PdfErrorPageProps): JSX.Element {
  return (
    <DefaultPage scale={scale}>
      <Alert severity="error">{message}</Alert>
      <Skeleton variant="rectangular" height={118} animation={false} />
      <Skeleton animation={false} />
      <Skeleton width="60%" animation={false} />
      <Hidden smDown>
        <Skeleton height={118} animation={false} />
        <Skeleton animation={false} />
        <Skeleton width="60%" animation={false} />
      </Hidden>
    </DefaultPage>
  )
}
