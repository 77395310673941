import { useMutation } from "@tanstack/react-query"
import { ApiError } from "apiHelper"
import { UpdateRequestOperatorsVariables, updateRequestOperators } from "../api"
import { queryClient } from "react-query/queryClient"
import { queryKeys } from "react-query/constants"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { JSONparseWithDefaultValue } from "utils"

export function useSaveOperatorMutation() {
  const { showErrorMessage } = useHandleMessages()

  return useMutation<unknown, ApiError, UpdateRequestOperatorsVariables>(updateRequestOperators, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.request])
    },
    onError: error => {
      showErrorMessage({
        message:
          JSONparseWithDefaultValue(error?.message)?.error ||
          "An unknown error occured updating the assignee",
        error,
      })
    },
  })
}
