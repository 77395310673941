import { SnackbarOrigin } from "@mui/material"
import { Message } from "./Message"

export const enum MESSAGE_ACTION_TYPE {
  SHOW = "showMessage",
  APPEND = "appendMessage",
  HIDE = "hideMessage",
}

interface ShowMessageAction {
  type: MESSAGE_ACTION_TYPE.SHOW
  payload: ShowMessagesState
}

interface AppendMessagesAction {
  type: MESSAGE_ACTION_TYPE.APPEND
  payload: ShowMessagesState
}

interface HideMessageAction {
  type: MESSAGE_ACTION_TYPE.HIDE
}

export type HandleMessagesAction = ShowMessageAction | AppendMessagesAction | HideMessageAction

interface ShowMessagesState {
  messages: Message[]
  timeout: Nullable<number>
  anchorOrigin?: SnackbarOrigin
}

export type HandleMessagesState = Nullable<ShowMessagesState>

type HandleMessagesReducer = (state: HandleMessagesState, action: HandleMessagesAction) => HandleMessagesState

export const handleMessagesReducer: HandleMessagesReducer = (state, action) => {
  switch (action.type) {
    case MESSAGE_ACTION_TYPE.SHOW:
      return { ...action.payload }
    case MESSAGE_ACTION_TYPE.APPEND:
      return {
        ...action.payload,
        messages: state ? [...state.messages, ...action.payload.messages] : action.payload.messages,
      }
    case MESSAGE_ACTION_TYPE.HIDE:
      return null
    default:
      return state
  }
}

export const DEFAULT_TIMEOUT = 10_000
export const DEFAULT_ANCHOR = { vertical: "bottom", horizontal: "center" } satisfies SnackbarOrigin
