export const FILE_TYPE = "FILE"

export const CONTRACT_INFORMATION = "contract_information"
export const LIST_OF_ATTORNEYS = "list_of_attorneys"
export const FIRM_DETAILS_LABELS = {
  [CONTRACT_INFORMATION]: "Contract Information",
  [LIST_OF_ATTORNEYS]: "List of Attorneys",
}

export enum FILE_TYPES {
  IMAGE = "image/*",
  PDF = "application/pdf",
  ZIP = "application/gzip, application/x-bzip, application/x-bzip2, application/zip, .zip, application/x-zip-compressed",
  WORD = "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .doc, .docx",
  VIDEO = "video/*",
  CSV = "text/csv, .csv",
  SPREADSHEET = "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx",
  TEXT = "text/rtf",
  CSS = "text/css",
  HTML = "text/html",
  MP3 = "audio/mpeg",
  MSG = "application/vnd.ms-outlook",
  PAGES = "application/vnd.apple.pages",
  RAR = "application/x-rar-compressed",
  RTF = "application/rtf",
  TXT = "text/plain",
  WAV = "audio/wav",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  PPT = "application/vnd.ms-powerpoint",
}

export const ALL_ACCEPTED_FILE_TYPES = Object.values(FILE_TYPES).join(", ")

export enum FILE_CATEGORY_OPTIONS {
  MEDICAL = "medical",
  MEDICAL_BILL = "medical_bill",
  MEDICAL_BILL_RECORD = "medical_bill_record",
  POLICE_REPORT = "police_report",
  INSURANCE = "insurance",
  LIEN = "lien",
  PLEADING = "pleading",
  TAX = "tax",
  EMPLOYER_LETTER = "employer_letter",
  LETTER = "letter",
  AFFIDAVIT = "affidavit",
  OTHER = "other",
}

export const BILL_TYPES = [FILE_CATEGORY_OPTIONS.MEDICAL_BILL, FILE_CATEGORY_OPTIONS.MEDICAL_BILL_RECORD]
export const RECORD_TYPES = [
  FILE_CATEGORY_OPTIONS.MEDICAL,
  FILE_CATEGORY_OPTIONS.POLICE_REPORT,
  FILE_CATEGORY_OPTIONS.INSURANCE,
  FILE_CATEGORY_OPTIONS.LIEN,
  FILE_CATEGORY_OPTIONS.PLEADING,
  FILE_CATEGORY_OPTIONS.TAX,
  FILE_CATEGORY_OPTIONS.EMPLOYER_LETTER,
  FILE_CATEGORY_OPTIONS.LETTER,
  FILE_CATEGORY_OPTIONS.AFFIDAVIT,
  FILE_CATEGORY_OPTIONS.OTHER,
]

export type FILE_GROUP_TYPE = typeof BILL_TYPES | typeof RECORD_TYPES

export type FileCategoryOptions = Record<FILE_CATEGORY_OPTIONS, string>

export const FILE_CATEGORY_DISPLAY_OPTIONS: FileCategoryOptions = {
  medical: "Medical Record",
  medical_bill: "Medical Bill",
  medical_bill_record: "Medical Bill and Record",
  police_report: "Police Report",
  insurance: "Insurance Document",
  lien: "Lien Document",
  pleading: "Pleading Document",
  tax: "Tax Document",
  employer_letter: "Employer Letter",
  letter: "Letter",
  affidavit: "Affidavit",
  other: "Other",
}

export function getFileCategoryOptions(categories: FILE_CATEGORY_OPTIONS[]): Partial<FileCategoryOptions> {
  return categories.reduce<Partial<FileCategoryOptions>>(
    (result, category) => ({
      ...result,
      [category]: FILE_CATEGORY_DISPLAY_OPTIONS[category],
    }),
    {}
  )
}

export const LS_LOGGED_IN_KEY = "evenup-isLoggedIn"
export const EVENUP_LOGIN_ID = "evenup-loginId"

export const SUPPORT_EMAIL = "support@evenuplaw.com"

/**
 * The JIRA request type for support bugs. Used by useIssueReporter().
 */
export const JIRA_REQUEST_TYPE_SUPPORT_BUG = "sp/bug"
