import styled from "@emotion/styled"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  outlinedInputClasses,
} from "@mui/material"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { ExhibitPartition } from "exhibit-builder/store/types"
import { useId } from "react"
import { useShallow } from "zustand/react/shallow"

const StyledSelect = styled(Select<string>)`
  &&& > .${outlinedInputClasses.input} {
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`

const FileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
`

const FileName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 400px;
`

const PageRange = styled(Typography)`
  width: 20%;
  color: ${({ theme }) => theme.palette.text.secondary};
`

export function ExhibitFileDropdown({
  label,
  partitions,
  selectedPartition,
  onChange,
  disabled,
}: {
  label: string
  partitions: ExhibitPartition[]
  selectedPartition: ExhibitPartition["id"]
  onChange: (fileId: string) => void
  disabled?: boolean
}) {
  const fileInputLabelId = useId()
  const filesMap = useExhibitBuilderStore(useShallow(state => state.files))

  const handleFileChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value)
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={fileInputLabelId}>{label}</InputLabel>
      <StyledSelect
        size="small"
        label={label}
        value={selectedPartition}
        labelId={fileInputLabelId}
        onChange={handleFileChange}
        data-test="exhibit-file-dropdown"
        disabled={disabled}
      >
        {partitions.map(partition => (
          <MenuItem key={partition.id} value={partition.id}>
            <FileItem>
              <FileName>{filesMap[partition.fileId].name}</FileName>
              <PageRange variant="body2">
                pg {partition.startPage} - {partition.endPage}
              </PageRange>
            </FileItem>
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  )
}
