import { useEffect, useMemo } from "react"
import { isNull } from "lodash"
import { TimeSpendingEntity, getEntityName } from "./utils"
import { timeSpending } from "./"

export const useTimeSpendingAnalytic = (entity: TimeSpendingEntity) => {
  const key = useMemo(() => getEntityName(entity), [entity])

  useEffect(() => {
    if (isNull(key)) return

    const stopCounting = timeSpending.startCounting(key)

    return () => stopCounting()
  }, [key])
}
