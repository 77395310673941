import { BaseAnalyticsEvent } from "infrastructure/apm/types"

export enum ApplicationPerformanceAnalyticsEventTypes {
  RichTextSymbolRendered = "Rich Text Symbol Rendered",
}

export class ApplicationPerformanceAnalyticEvent<
  T extends ApplicationPerformanceAnalyticsEventTypes,
> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: ApplicationPerformanceAnalyticsEventDetails[T]
  ) {
    super()
  }
}

// Add new request event types here
export type ApplicationPerformanceAnalyticsEventDetails = {
  [ApplicationPerformanceAnalyticsEventTypes.RichTextSymbolRendered]: {
    timeToRender50Pecentile: number
    timeToRender75Pecentile: number
    timeToRender90Pecentile: number
    timeToRender95Pecentile: number
    timeToRender99dot99Pecentile: number
  }
}
