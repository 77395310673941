import styled from "@emotion/styled"
import { Box, Chip } from "@mui/material"
import { PlaintiffDto } from "api/services/documents/types"
import { CircleBadge } from "common/CircleBadge"
import { BlackTooltip } from "common/Tooltip"
import { ReactElement } from "react"
import { DOCUMENT_TYPE_LIST } from "requests/RequestForm/SelectDocument/constants"
import { DOC_TYPE_TAG_BACKGROUND } from "requests/RequestList/tableCells/constants"
import { DOCUMENT_TYPES } from "requests/enums"
import { getFirstPlaintiffName } from "requests/plaintiffUtils"

export const PlaintiffNameCell = ({ record }: { record: { plaintiffs: PlaintiffDto[] } }) => {
  const plaintiffs = record.plaintiffs
  const { fullName } = getFirstPlaintiffName(record)

  return (
    <>
      <Box>{fullName}</Box>
      {plaintiffs.length > 1 && (
        <Box ml={1}>
          <BlackTooltip
            arrow
            title={plaintiffs.map((plaintiff, index) => {
              if (index === 0) {
                return
              }

              return (
                <Box key={plaintiff.pk}>
                  {plaintiff.first_name} {plaintiff.last_name}
                </Box>
              )
            })}
          >
            {/* Badges are not hoverable so need to wrap in a Box */}
            <Box>
              <CircleBadge label={`+${plaintiffs.length - 1}`} />
            </Box>
          </BlackTooltip>
        </Box>
      )}
    </>
  )
}

const DocumentTypeLabel = styled(Chip)<{ type: DOCUMENT_TYPES }>(({ type, theme }) => {
  return {
    background: DOC_TYPE_TAG_BACKGROUND[type],
    color: theme.palette.common.black,
    borderRadius: theme.spacing(0.75),
    fontSize: "0.75rem",
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: "-0.4px",
    padding: "5px",
    width: "100%",
    whiteSpace: "nowrap",
  }
})

type DocumentTypeCellProps<T> = {
  record: T extends { type: DOCUMENT_TYPES } ? T : never
  medicalChronologyPremiumEnabled?: boolean
  createSimpleEnabled?: boolean
}

export function DocumentTypeCell<T>({ record, ...props }: DocumentTypeCellProps<T>): ReactElement {
  const { type } = record
  const createSimpleEnabled = Boolean(props.createSimpleEnabled)
  const label = DOCUMENT_TYPE_LIST(true, createSimpleEnabled).find(item => item.value === type)?.pillTitle
  return <DocumentTypeLabel type={type} label={label} />
}
