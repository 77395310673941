import { isEmpty, isString } from "lodash"

export type TimeSpendingEntity = string | Record<string, string | null | undefined>

export const getEntityName = (entity: TimeSpendingEntity): Nullable<string> => {
  if (isString(entity)) return entity

  const filteredEntries = Object.entries(entity).filter(pair => pair[1])

  if (isEmpty(filteredEntries)) return null

  const sortedEntries = filteredEntries.sort(([keyA], [keyB]) => {
    if (keyA === keyB) return 0

    return keyA < keyB ? -1 : 1
  })

  const result = Object.fromEntries(sortedEntries)

  return JSON.stringify(result)
}
