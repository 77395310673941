import React from "react"
import Button from "@mui/material/Button"
import { Dialog, TypographyProps } from "@mui/material"
import { UploadFileOutlined } from "@mui/icons-material"
import styled from "@emotion/styled"
import Box, { BoxProps } from "@mui/material/Box"
import { useMutation, useQuery } from "@tanstack/react-query"
import Typography from "@mui/material/Typography"
import { CurrencyField, InputField, InputFieldProps } from "common/form-components"
import { FieldValues, useForm } from "react-hook-form"
import { BODY_FONT_SIZE } from "settlements/constants"
import { getSettlementStages } from "settlements/api/assets"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { AssetAutocomplete, EstimateFormAutocompleteInput } from "settlements/estimate-form/shared"
import { queryKeys } from "react-query/constants"
import { BaseAssetDto, SettlementInformationDto, SettlementStageDto } from "settlements/api/types"
import { useDialog } from "hooks/useDialog"
import useUser from "hooks/useUser"
import { queryClient } from "react-query/queryClient"
import { AnyObject } from "yup"
import { COMMERCIAL_POLICY_COVERAGE_TYPES, POLICY_TYPES } from "requests/constants"
import { getSettlementInformation, updateSettlementInformation } from "settlements/api/settlement"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  SettlementDataRepositoryAnalyticEvent,
  SettlementDataRepositoryAnalyticsEventTypes,
} from "infrastructure/apm/events/settlementDataRepositoryEvents"

const ShareButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.blue.main,
  borderColor: theme.palette.blue.main,
}))

ShareButton.defaultProps = {
  variant: "outlined",
}

const StyledDialog = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  width: "600px",
}))

const FormRow: React.FC<BoxProps> = props => <Box {...props} sx={{ display: "flex", gap: 2, ...props.sx }} />

const FormField: React.FC<BoxProps> = props => (
  <Box {...props} sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 0.25, ...props.sx }} />
)

const FormLabel: React.FC<TypographyProps<"label">> = props => (
  <Typography component="label" sx={{ fontSize: BODY_FONT_SIZE, fontWeight: 700, ...props.sx }} {...props} />
)

function FormInput<T extends FieldValues>(props: InputFieldProps<T>) {
  return <InputField InputProps={{ sx: { fontSize: 14 } }} size="small" fullWidth {...props} />
}

interface SettlementFormValues {
  first_settlement_date: string
  first_settlement_amount: number
  settlement_date: string
  settlement_amount: number
  adjuster_name: string
  carrier_name: string
  policy_id: number
  policy_limit: number
  settlement_stage: string
  settlement_stage_select: Nullable<SettlementStageDto>
  policy_type_select: Nullable<BaseAssetDto>
  policy_coverage_type_select: Nullable<BaseAssetDto>
}

interface SettlementInfoButtonProps {
  demandId: PrimaryKey
  firmId: PrimaryKey
  intake: AnyObject
}

export const SettlementInfoButton = ({ demandId, firmId, intake }: SettlementInfoButtonProps) => {
  const { user } = useUser()
  const { showErrorMessage, showSuccessMessage } = useHandleMessages()
  const [isUpdate, setIsUpdate] = React.useState(false)
  const [buttonText, setButtonText] = React.useState("Share Settlement Data")
  const { control, getValues, handleSubmit, setValue } = useForm<SettlementFormValues>({
    defaultValues: {
      policy_id: 0,
      settlement_stage_select: null,
      policy_type_select: null,
      policy_coverage_type_select: null,
    },
  })
  const searchParams = new URLSearchParams(document.location.search)
  const initialOpen = searchParams.get("showSettlementForm") === "true"
  const { isOpen, openDialog, closeDialog } = useDialog(initialOpen)

  const { data: settlementStagesData, isLoading: settlementStagesAreLoading } = useQuery({
    queryKey: ["settlement", "asset", "settlement-stages"],
    queryFn: () =>
      getSettlementStages().then(
        data => {
          if (getValues("settlement_stage")) {
            const initialStage = data.find(item => item.key === getValues("settlement_stage"))
            if (initialStage) {
              setValue("settlement_stage_select", initialStage)
            }
          }
          return data
        },
        err => {
          showErrorMessage("Could not load settlement stages, try refreshing the page.")
          throw err
        }
      ),
    meta: { disableLoader: true },
  })

  function setFormFromQuestionnaireData() {
    if (intake.policy_limit) {
      setValue("policy_limit", intake.policy_limit)
    }
    if (intake.adjuster_first_name || intake.adjuster_last_name) {
      setValue("adjuster_name", `${intake.adjuster_first_name} ${intake.adjuster_last_name}`)
    }
    if (intake.carrier_name) {
      setValue("carrier_name", intake.carrier_name)
    }
    if (intake.policy_type) {
      const policyType = POLICY_TYPES.find(type => type.key === intake.policy_type)
      if (policyType) {
        setValue("policy_type_select", policyType)
      }
    }
    if (intake.policy_coverage_type) {
      const policyCoverageType = COMMERCIAL_POLICY_COVERAGE_TYPES.find(
        type => type.key === intake.policy_coverage_type
      )
      if (policyCoverageType) {
        setValue("policy_coverage_type_select", policyCoverageType)
      }
    }
  }

  function setFormFromSettlementData(data: SettlementInformationDto) {
    if (settlementStagesData) {
      const settlementStage = settlementStagesData.find(stage => stage.key === data.settlement_stage)
      if (settlementStage) {
        setValue("settlement_stage_select", settlementStage)
      }
    }
    const policyType = POLICY_TYPES.find(type => type.key === data.policy_type)
    if (policyType) {
      setValue("policy_type_select", policyType)
    }
    const policyCoverageType = COMMERCIAL_POLICY_COVERAGE_TYPES.find(
      type => type.key === data.policy_coverage_type
    )
    if (policyCoverageType) {
      setValue("policy_coverage_type_select", policyCoverageType)
    }

    setValue("first_settlement_date", data.first_settlement_date)
    setValue("first_settlement_amount", data.first_settlement_amount)
    setValue("settlement_date", data.settlement_date)
    setValue("settlement_amount", data.settlement_amount)
    setValue("settlement_stage", data.settlement_stage)
    setValue("adjuster_name", data.adjuster_name)
    setValue("carrier_name", data.carrier_name)
    setValue("policy_id", data.policy_id)
    setValue("policy_limit", data.policy_limit)
  }

  const { isLoading: settlementDataLoading } = useQuery({
    queryKey: [queryKeys.settlements, { firmId: firmId, caseId: demandId }],
    queryFn: () =>
      getSettlementInformation(firmId, demandId).then(
        data => {
          setFormFromSettlementData(data)
          setButtonText("Edit Settlement Data")
          setIsUpdate(true)
          return data
        },
        err => {
          // 404 means no settlement data stored
          if (err.response.status === 404) {
            setFormFromQuestionnaireData()
          } else {
            showErrorMessage("Could not load settlement information, try refreshing the page.")
            throw err
          }
        }
      ),
    meta: { disableLoader: true },
  })

  const settlementMutation = useMutation(updateSettlementInformation, {
    onSuccess: () => {
      closeDialog()
      showSuccessMessage("Settlement information saved successfully.")
      queryClient.invalidateQueries([queryKeys.settlements, { firmId: firmId, caseId: demandId }])
      const eventType = isUpdate
        ? SettlementDataRepositoryAnalyticsEventTypes.SettlementDataUpdated
        : SettlementDataRepositoryAnalyticsEventTypes.SettlementDataCreated
      amplitudeApm.trackEvent(
        new SettlementDataRepositoryAnalyticEvent(eventType, {
          firm_id: firmId,
          user_id: user.id,
          case_id: demandId,
        })
      )
    },
    onError: () => {
      showErrorMessage("Could not save settlement information, try again.")
    },
  })

  const sendData = async (data: SettlementFormValues) => {
    settlementMutation.mutate({
      firm_id: firmId,
      case_id: demandId,
      settlement_amount: data.settlement_amount,
      settlement_date: data.settlement_date,
      settlement_stage: data.settlement_stage_select?.key ?? "",
      first_settlement_date: data.first_settlement_date,
      first_settlement_amount: data.first_settlement_amount,
      policy_id: data.policy_id,
      policy_limit: data.policy_limit,
      policy_type: data.policy_type_select?.key ?? "",
      policy_coverage_type: data.policy_coverage_type_select?.key ?? "",
      adjuster_name: data.adjuster_name,
      carrier_name: data.carrier_name,
    })
  }

  return (
    !!demandId && (
      <>
        <ShareButton startIcon={<UploadFileOutlined />} onClick={openDialog}>
          {buttonText}
        </ShareButton>
        <Dialog open={isOpen} onClose={closeDialog}>
          <StyledDialog>
            <Typography component="h1" variant="h5" paddingBottom="10px">
              <b>{buttonText}</b>
            </Typography>
            <form onSubmit={handleSubmit(sendData)}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <FormRow>
                  <FormField>
                    <FormLabel component="label" htmlFor="settlement_date">
                      Date of Settlement *
                    </FormLabel>
                    <FormInput
                      control={control}
                      type="date"
                      id="settlement_date"
                      name="settlement_date"
                      required
                    />
                  </FormField>
                  <FormField>
                    <FormLabel component="label" htmlFor="settlement_amount">
                      Settlement Amount *
                    </FormLabel>
                    <CurrencyField
                      control={control}
                      id="settlement_amount"
                      name="settlement_amount"
                      InputProps={{ sx: { fontSize: BODY_FONT_SIZE } }}
                      size="small"
                      hideCurrency
                      placeholder={"E.g., $25,000.00"}
                      fullWidth
                      required
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <FormLabel component="label" htmlFor="first_settlement_date">
                      Date of First Settlement Offer
                    </FormLabel>
                    <FormInput
                      control={control}
                      type="date"
                      id="first_settlement_date"
                      name="first_settlement_date"
                    />
                  </FormField>
                  <FormField>
                    <FormLabel component="label" htmlFor="first_settlement_amount">
                      Amount of First Settlement Offer
                    </FormLabel>
                    <CurrencyField
                      control={control}
                      id="first_settlement_amount"
                      name="first_settlement_amount"
                      InputProps={{ sx: { fontSize: BODY_FONT_SIZE } }}
                      size="small"
                      hideCurrency
                      placeholder={"E.g., $25,000.00"}
                      fullWidth
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <FormLabel component="label" htmlFor="settlement_stage_select">
                      Stage of Settlement
                    </FormLabel>
                    <AssetAutocomplete
                      control={control}
                      name="settlement_stage_select"
                      id="settlement_stage_select"
                      disablePortal
                      options={settlementStagesData ?? []}
                      loading={settlementStagesAreLoading}
                      size="small"
                      renderInput={params => (
                        <EstimateFormAutocompleteInput {...params} placeholder="Select a stage" />
                      )}
                    />
                  </FormField>
                  <FormField>
                    <FormLabel component="label" htmlFor="adjuster_name">
                      Full Name of Adjuster
                    </FormLabel>
                    <FormInput
                      control={control}
                      name="adjuster_name"
                      id="adjuster_name"
                      placeholder="E.g., Jane Doe"
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <FormLabel component="label" htmlFor="carrier_name">
                      Insurance Carrier
                    </FormLabel>
                    <FormInput
                      control={control}
                      id="carrier_name"
                      name="carrier_name"
                      placeholder="Enter a carrier"
                    />
                  </FormField>
                  <FormField>
                    <FormLabel component="label" htmlFor="policy_limit">
                      Policy Limit
                    </FormLabel>
                    <CurrencyField
                      control={control}
                      id="policy_limit"
                      name="policy_limit"
                      InputProps={{ sx: { fontSize: BODY_FONT_SIZE } }}
                      size="small"
                      hideCurrency
                      placeholder={"E.g., $25,000.00"}
                      fullWidth
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <FormLabel component="label" htmlFor="policy_type">
                      Policy Type
                    </FormLabel>
                    <AssetAutocomplete
                      control={control}
                      name="policy_type_select"
                      id="policy_type"
                      disablePortal
                      options={POLICY_TYPES}
                      loading={settlementDataLoading}
                      size="small"
                      renderInput={params => (
                        <EstimateFormAutocompleteInput {...params} placeholder="Select a policy type" />
                      )}
                    />
                  </FormField>
                  <FormField>
                    <FormLabel component="label" htmlFor="policy_coverage_type">
                      Policy Coverage Type
                    </FormLabel>
                    <AssetAutocomplete
                      control={control}
                      name="policy_coverage_type_select"
                      id="policy_coverage_type"
                      disablePortal
                      options={COMMERCIAL_POLICY_COVERAGE_TYPES}
                      loading={settlementDataLoading}
                      size="small"
                      renderInput={params => (
                        <EstimateFormAutocompleteInput
                          {...params}
                          placeholder="Select a policy coverage type"
                        />
                      )}
                    />
                  </FormField>
                </FormRow>
              </Box>
              <Box sx={{ textAlign: "right", mt: 3 }}>
                <Button variant="contained" color="secondary" type="submit">
                  Submit
                </Button>
              </Box>
            </form>
          </StyledDialog>
        </Dialog>
      </>
    )
  )
}
