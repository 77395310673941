import React from "react"
import { Control, Controller, ControllerProps, FieldValues, get as getValueByPath } from "react-hook-form"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl, { FormControlProps } from "@mui/material/FormControl"
import clsx from "clsx"
import { FieldPathWithValue } from "../types/helper"

type FieldError = {
  message?: Nullable<string>
}
type ErrorsFor<TFields extends FieldValues> = {
  [K in keyof TFields]?: Nullable<FieldError>
}

export interface SelectInputProps<TFields extends FieldValues, TValue> extends FormControlProps {
  control: Control<TFields>
  name: FieldPathWithValue<TFields, TValue>
  options: ValueOptions<TValue>
  label?: Nullable<string>
  // TODO: remove errors prop and replace with hook in component
  // const { errors } = useFormState({ control })
  errors?: Nullable<ErrorsFor<TFields>>
  onChange?: () => void
  rules?: ControllerProps<TFields>["rules"]
  native?: boolean
  required?: boolean
  readOnly?: boolean
}

// React.forwardRef type does not support of returning Generic components
// So we need to cast to "custom" type that is compatible with React.forwardRef and supports generics
export const SelectInput = React.forwardRef(SelectInputComponent) as unknown as <
  TFields extends FieldValues,
  TValue,
>(
  props: SelectInputProps<TFields, TValue> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof SelectInputComponent>

function SelectInputComponent<TFields extends FieldValues, TValue>(
  {
    control,
    name,
    label = "",
    options,
    onChange,
    errors = {},
    className = "",
    rules = {},
    disabled = false,
    size = "medium",
    native = false,
    readOnly = false,
    required,
    ...props
  }: SelectInputProps<TFields, TValue>,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
): JSX.Element {
  const hasLabel = Boolean(label)
  const editable = !disabled && !readOnly

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field }, fieldState: { error: fieldStateError } }) => {
        const error = fieldStateError ?? getValueByPath(errors, name)
        return (
          <FormControl
            error={Boolean(error)}
            className={clsx(className)}
            size={size}
            required={required}
            disabled={!editable}
            {...props}
            ref={forwardedRef}
          >
            {hasLabel && <InputLabel id={`${label}-label`}>{label}</InputLabel>}

            <Select
              labelId={`${label}-label`}
              id={`select-input-${name}`}
              data-test={`select-input-${name}`}
              label={hasLabel ? label : undefined}
              disabled={!editable}
              native={native}
              {...field}
              onChange={event => {
                if (onChange) onChange()

                field.onChange(event)
              }}
              inputRef={ref}
            >
              {options?.map(option =>
                native ? (
                  <option
                    key={option.key as unknown as string}
                    value={option.key as unknown as string}
                    disabled={Boolean(option.disabled)}
                  >
                    {option.display}
                  </option>
                ) : (
                  <MenuItem
                    key={option.key as unknown as string}
                    value={option.key as unknown as string}
                    disabled={Boolean(option.disabled)}
                  >
                    {option.display}
                  </MenuItem>
                )
              )}
            </Select>

            {error && (
              <FormHelperText>{error.message || "Please select an option from the list."}</FormHelperText>
            )}
          </FormControl>
        )
      }}
    />
  )
}
