import styled from "@emotion/styled"
import { Box, Typography, TypographyProps } from "@mui/material"
import { theme } from "app/theme"

export const PlaintiffContent = styled(Box)({
  overflowWrap: "break-word",
  display: "flex",
  marginBottom: theme.spacing(0.25),
  flexDirection: "column",
})

const BaseTypography = ({ children, ...props }: TypographyProps) => (
  <Typography component="span" {...props}>
    {children}
  </Typography>
)

export const StyledTypographyLabel = styled(BaseTypography)({
  fontSize: theme.spacing(1.75),
  textTransform: "initial",
  fontWeight: 600,
})

export const StyledTypographyValue = styled(BaseTypography)({
  fontSize: theme.spacing(1.75),
  paddingLeft: theme.spacing(2),
  display: "flex",
  gap: theme.spacing(1),
  flexWrap: "wrap",
})

export const StyledBoxFullName = styled(Box)({
  fontWeight: 700,
  fontSize: "1rem",
})
