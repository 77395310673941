import { useCallback, useEffect, useState, useMemo, Ref, forwardRef, ForwardedRef } from "react"
import { format } from "date-fns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { TextFieldProps } from "@mui/material/TextField"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import { isNull } from "lodash"
import { isValidDate } from "utils"

const DATE_FORMAT = "MM/dd/yyyy"
const DATE_FORMAT_DIVIDER = "/"
const DEFAULT_MIN_DATE = new Date("1900/01/01")
const DEFAULT_MAX_DATE = new Date("2300/01/01")

const BACKSPACE_KEY = "Backspace"
const ARROW_LEFT_KEY = "ArrowLeft"
const ARROW_RIGHT_KEY = "ArrowRight"

type DateValue = Nullable<Date>
type DateFieldValue = Nullable<string>

interface DateFieldProps {
  initialValue: DateFieldValue
  label?: Nullable<string>
  onChange: (date: DateFieldValue) => void
  onClose?: () => void
  onFocus?: () => void
  onBlur?: () => void
  inputRef?: Ref<HTMLInputElement>
  disableOpenPicker?: boolean
  disabled?: boolean
  className?: string
  // need to specify this type more accurate
  fieldProps?: {
    id?: string
    name?: string
    margin?: TextFieldProps["margin"]
    size?: TextFieldProps["size"]
    autoFocus?: boolean
    fullWidth?: boolean
    placeholder?: string
  }
  placeholder?: string
  error?: boolean
  helperText?: string
  dataTest?: string
  onClickOpen?: boolean
  required?: boolean
  fullWidth?: boolean
  size?: "small" | "medium"
  minDate?: Date
  maxDate?: Date
  readOnly?: boolean
}

export const DateField = forwardRef(
  (
    {
      initialValue,
      label,
      onChange,
      onClose,
      onFocus,
      onBlur,
      inputRef,
      disableOpenPicker,
      disabled = false,
      className,
      error,
      helperText,
      fieldProps,
      dataTest,
      onClickOpen,
      required,
      fullWidth,
      placeholder,
      size,
      minDate,
      maxDate,
    }: DateFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const formattedInitialValue = useMemo(() => {
      if (initialValue && initialValue.replaceAll) {
        return initialValue.replaceAll("-", "/")
      }

      return null
    }, [initialValue])

    const [open, setOpen] = useState(false)
    const [value, setValue] = useState<DateValue>(
      formattedInitialValue ? new Date(formattedInitialValue) : null
    )
    const [editing, setEditing] = useState<boolean>(false)
    const checkValueAndChange = useCallback(
      (newValue: Date) => {
        const year = newValue.getFullYear()

        if (year >= 1900 && year <= 2300) {
          onChange(format(newValue, "yyyy-MM-dd"))
        }
      },
      [onChange]
    )

    const handleChange = useCallback(
      (newValue: Nullable<Date>) => {
        setValue(newValue)

        if (!newValue) return onChange(null)

        if (newValue instanceof Date && newValue.valueOf()) {
          checkValueAndChange(newValue)
        }

        if (onClickOpen) {
          setOpen(false)
        }
      },
      [onChange, checkValueAndChange, onClickOpen]
    )
    const onInputFocus = useCallback(() => {
      setEditing(true)
      onFocus?.()
    }, [onFocus])

    const handleBlur = useCallback(() => {
      setEditing(false)
      onBlur?.()
    }, [onBlur])

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
      const input = e.currentTarget
      const { selectionStart, selectionEnd, value } = input

      if (isNull(selectionStart) || isNull(selectionEnd)) return

      switch (e.key) {
        case BACKSPACE_KEY: {
          if (selectionStart === selectionEnd && value[selectionStart - 1] !== DATE_FORMAT_DIVIDER) {
            e.preventDefault()
            const newSelectionStart = selectionStart - 1
            input.setRangeText("", newSelectionStart, selectionEnd)
            input.setSelectionRange(newSelectionStart, newSelectionStart)
          }
          break
        }

        case ARROW_LEFT_KEY: {
          if (selectionStart > 0) {
            input.setSelectionRange(selectionStart - 1, selectionStart - 1)
          }
          break
        }

        case ARROW_RIGHT_KEY: {
          if (selectionStart < value.length) {
            input.setSelectionRange(selectionStart + 1, selectionStart + 1)
          }
          break
        }
      }
    }, [])

    useEffect(() => {
      if (editing) return

      if (!initialValue && value) return setValue(null)

      if (!initialValue || !formattedInitialValue) return

      // no need to check for initialValue !== format(value, "yyyy-MM-dd")
      // because formattedInitialValue is made from initialValue
      // and is used as initial value for value state
      if (!value || !isValidDate(value)) {
        return setValue(new Date(formattedInitialValue))
      }
    }, [value, initialValue, editing, formattedInitialValue])

    useEffect(() => {
      setValue(formattedInitialValue ? new Date(formattedInitialValue) : null)
    }, [formattedInitialValue])

    const handleOnClick = useCallback(() => {
      if (!onClickOpen) return

      setOpen(true)
    }, [onClickOpen])

    const handleClose = useCallback(() => {
      setOpen(false)
      onClose?.()
    }, [onClose])

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputRef={inputRef}
          ref={ref}
          label={label}
          value={value}
          onChange={handleChange}
          onClose={handleClose}
          format={DATE_FORMAT}
          disabled={disabled}
          minDate={minDate || DEFAULT_MIN_DATE}
          maxDate={maxDate || DEFAULT_MAX_DATE}
          {...(onClickOpen ? { open, onOpen: () => setOpen(true) } : {})}
          slotProps={{
            textField: {
              placeholder: placeholder ?? "mm/dd/yyyy",
              InputLabelProps: { shrink: true },
              variant: "outlined",
              onBlur: handleBlur,
              onFocus: onInputFocus,
              onKeyDown: handleKeyDown,
              error,
              size,
              fullWidth,
              required,
              className,
              helperText,
              ...fieldProps,
              "aria-autocomplete": "none",
              autoComplete: "off",
              inputProps: { "data-test": dataTest },
              ...(onClickOpen ? { onClick: handleOnClick } : {}),
            },
          }}
          disableOpenPicker={disableOpenPicker}
        />
      </LocalizationProvider>
    )
  }
)

DateField.displayName = "DateField"
