import { DndExhibitPartitionData } from "exhibit-builder/store/types"
import { StyledSortableNestedListItem } from "./styled"
import { SortableNestedListItemProps } from "common/nested-list/SortableNestedListItem"

export const ExhibitPartitionItem = function ExhibitPartitionItem(
  props: SortableNestedListItemProps<DndExhibitPartitionData>
) {
  return (
    <div data-test="exhibit-partition-item">
      <StyledSortableNestedListItem {...props} backgroundColor="white" />
    </div>
  )
}
