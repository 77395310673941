import { useQuery, useQueryClient } from "@tanstack/react-query"
import { STALE_TIMEOUT, queryKeys as defaultQueryKeys } from "../react-query/constants"
import { getFirmExtended } from "../api"
import { ExtendedFirmDto } from "settings/Firm/Firm"
import invariant from "invariant"
import { isNil } from "lodash"

type InvalidFirmReturn = {
  firm: null
  hasValidFirmId: false
  isLoading: false
}

type FirmDataSuccessReturn = {
  firm: ExtendedFirmDto
  hasValidFirmId: true
  isLoading: true
}

type FirmDataReturn = {
  firm: Nullable<ExtendedFirmDto>
  hasValidFirmId: true
  isLoading: boolean
}

type FirmReturn = FirmDataReturn | InvalidFirmReturn

type UseFirmHookCallbacks = {
  updateCache: (firm: Partial<ExtendedFirmDto>) => void
}

function getFirm(firmId: number): Promise<ExtendedFirmDto> {
  return getFirmExtended({ queryKey: [null, firmId] })
}

type UseFirmOptions = {
  queryKey?: string
  suspense?: false
}

type UseFirmSuspenseOptions = Omit<UseFirmOptions, "suspense"> & {
  suspense: true
}

// if you do not need related information like contracts, etc... consider using the useMinimalFirm hook
export function useFirm(
  firmId: number,
  options: UseFirmSuspenseOptions
): FirmDataSuccessReturn & UseFirmHookCallbacks
export function useFirm(
  firmId: Nullable<number> | undefined,
  options?: UseFirmOptions
): FirmReturn & UseFirmHookCallbacks
export function useFirm(
  firmId: Nullable<number> | undefined,
  { queryKey = defaultQueryKeys.firm, suspense = false }: UseFirmOptions | UseFirmSuspenseOptions = {}
): (FirmDataSuccessReturn | FirmReturn) & UseFirmHookCallbacks {
  const queryClient = useQueryClient()
  const hasValidFirmId = !!firmId
  const queryKeys = [queryKey, firmId]

  const { data: firm, isFetching } = useQuery<ExtendedFirmDto>(
    queryKeys,
    () => {
      invariant(!isNil(firmId), "Firm Id can not be null or undefined")
      return getFirm(firmId)
    },
    {
      retry: false,
      enabled: hasValidFirmId,
      keepPreviousData: true,
      staleTime: STALE_TIMEOUT.DEFAULT,
      suspense: suspense,
      meta: {
        disableLoader: !!queryClient.getQueryData(queryKeys),
      },
    }
  )

  const updateCache = (newFirm: Partial<ExtendedFirmDto>) => {
    queryClient.setQueryData([queryKey, firmId], (oldFirm: Partial<ExtendedFirmDto> | undefined) => {
      if (oldFirm === undefined) return newFirm

      return {
        ...oldFirm,
        ...newFirm,
      }
    })
  }

  const hasValidData = hasValidFirmId && (isFetching || firm)

  if (suspense || hasValidData) {
    return {
      firm: firm ?? null,
      isLoading: isFetching,
      hasValidFirmId: true,
      updateCache,
    }
  }

  return {
    firm: null,
    isLoading: false,
    hasValidFirmId: false,
    updateCache,
  }
}
