import { User } from "common/models/user"
import { isControlledEnvironment, isDevEnvironment } from "infrastructure/env/getEnvironment"
import { getAPIServerURL } from "utils"

function withEnv(callback: () => void): void {
  const isCorrectEnv =
    !isControlledEnvironment() && (isDevEnvironment() || !getAPIServerURL().includes("localhost"))

  if (isCorrectEnv) {
    try {
      callback()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}

function setUserContext(user: User) {
  withEnv(() => {
    Beamer?.update({
      user_id: user.stytchUserId || undefined,
      user_email: user.email,
      user_firstname: user.firstName,
      user_lastname: user.lastName,
    })
  })
}

export const BeamerLib = {
  setUserContext,
  resetUserContext: () => {},
}
