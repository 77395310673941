import { fullstoryApm } from "infrastructure/apm"
import { getPerformance } from "./getPerformance"
import { omit } from "lodash"

type MeasureCallback<T> = () => Promise<T> | T

export async function withPerformanceMeasure<T>(name: string, callback: MeasureCallback<T>): Promise<T> {
  const { measure } = getPerformance(name, { initial: true })

  const result = await callback()
  const measurement = measure()

  fullstoryApm.trackPerformance(name, omit(measurement, "name"))

  return result
}
