import React, { useEffect, useState, useMemo } from "react"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"

import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "@tanstack/react-query"

import { InputField } from "../common/form-components"
import MissingDocumentSection from "./MissingDocumentSection"
import { MissingExhibit, MissingExhibitEvent } from "../missing-docs/interfaces"

import { useHandleMessages } from "../common/messages/useHandleMessages"
import { formSectionsToRoutes } from "./constants"
import { SECTIONS } from "../missing-docs/constants"
import CommunicationEvents from "../missing-docs/Events/CommunicationEvents"
import { createMissingExhibitEvent, getMissingExhibitEvents, getMissingExhibits } from "../api"
import { queryKeys } from "../react-query/constants"
import { useParams } from "react-router-dom"
import { queryClient } from "../react-query/queryClient"
import Alert from "@mui/material/Alert"
import { differenceInHours } from "date-fns"

interface FormValues {
  comment: string
}

const Subtitle = styled(Box)({
  fontWeight: 600,
  fontSize: "1rem",
})

const InfoText = styled(Box)({
  fontWeight: 400,
  fontSize: "0.80rem",
  fontStyle: "italic",
})

interface Props {
  lastVisited: React.MutableRefObject<Nullable<string>>
}

const defaultValues = {
  comment: "",
}

const ALERT_HOUR_LIMIT = 24
const MissingDocuments = ({ lastVisited }: Props): JSX.Element => {
  const { control, getValues, reset } = useForm<FormValues>({
    defaultValues: defaultValues,
  })
  useEffect(() => {
    lastVisited.current = formSectionsToRoutes.missing_docs
  })

  const { showErrorMessage } = useHandleMessages()
  const [commentsEnabled, setCommentsEnabled] = useState(true)
  const { id: caseId } = useParams()
  const { data: missingExhibits } = useQuery<MissingExhibit[]>(
    [queryKeys.missingExhibits, caseId],
    async () => {
      return await getMissingExhibits({ caseId: caseId, onlyUnresolved: false })
    }
  )

  const { data: communicationEvents } = useQuery<MissingExhibitEvent[]>(
    [queryKeys.missingExhibitEvents, caseId],
    async () => {
      return await getMissingExhibitEvents({ caseId: caseId })
    }
  )

  const unresolvedMissingExhibitsCount: number = useMemo(() => {
    if (!missingExhibits || !missingExhibits?.length) return 0
    return missingExhibits.filter(missingExhibit => !missingExhibit.is_resolved).length
  }, [missingExhibits])

  const showClientNotifiedAlert: boolean = useMemo(() => {
    if (!communicationEvents || !communicationEvents?.length) return false

    const lastEvent = communicationEvents[0]
    const hoursSinceLastEvent = differenceInHours(new Date(), new Date(lastEvent.created_at))
    if (lastEvent.internal === true && hoursSinceLastEvent < ALERT_HOUR_LIMIT) {
      return true
    }

    return false
  }, [communicationEvents])

  const createEventMutation = useMutation(createMissingExhibitEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.missingExhibitEvents])
      queryClient.invalidateQueries([queryKeys.missingExhibits])
      reset(defaultValues)
    },
    onError: error => {
      showErrorMessage({
        message: "There was an error notifying client. Please contact Engineering",
        error,
      })
    },
    onSettled: () => {
      setCommentsEnabled(true)
    },
  })

  const handleNotifyClient = () => {
    setCommentsEnabled(false)
    createEventMutation.mutate({
      caseId,
      data: {
        comment: getValues("comment"),
      },
    })
  }

  if (!missingExhibits || !caseId || !communicationEvents) return <></>

  const factsMissingExhibits: MissingExhibit[] = []
  const providersMissingExhibits: MissingExhibit[] = []
  const lossOfHouseholdServicesMissingExhibits: MissingExhibit[] = []
  const lossOfIncomeMissingExhibits: MissingExhibit[] = []

  missingExhibits.forEach((file: MissingExhibit) => {
    if (file.section === SECTIONS.CASE_FACTS) factsMissingExhibits.push(file)
    if (file.section === SECTIONS.PROVIDERS) providersMissingExhibits.push(file)
    if (file.section === SECTIONS.HOUSEHOLD_LOSS) lossOfHouseholdServicesMissingExhibits.push(file)
    if (file.section === SECTIONS.INCOME_LOSS) lossOfIncomeMissingExhibits.push(file)
  })

  return (
    <Box>
      <MissingDocumentSection
        missingDocs={factsMissingExhibits}
        title="Case Facts Section"
        section={SECTIONS.CASE_FACTS}
      />
      <MissingDocumentSection
        missingDocs={providersMissingExhibits}
        title="Providers Section"
        section={SECTIONS.PROVIDERS}
        canSelectProvider={true}
      />
      <MissingDocumentSection
        missingDocs={lossOfIncomeMissingExhibits}
        title="Loss of Income Section"
        section={SECTIONS.INCOME_LOSS}
      />
      <MissingDocumentSection
        missingDocs={lossOfHouseholdServicesMissingExhibits}
        title="Loss of Household Services Section"
        section={SECTIONS.HOUSEHOLD_LOSS}
      />

      <Box mt={2}>
        <Subtitle>Comments</Subtitle>
        <InputField
          control={control}
          name="comment"
          variant="outlined"
          multiline={true}
          minRows={3}
          fullWidth={true}
          disabled={!commentsEnabled}
        />
      </Box>
      <Box mt={1} display="flex">
        <Button
          onClick={handleNotifyClient}
          variant="contained"
          color="primary"
          disabled={!commentsEnabled || unresolvedMissingExhibitsCount === 0}
          data-test="notify-client-button"
        >
          Notify Client
        </Button>
        {showClientNotifiedAlert && (
          <Box ml={3} data-test="notify-client-alert">
            <Alert icon={false} severity="info">
              The client has been notified in the last {ALERT_HOUR_LIMIT} hours.
            </Alert>
          </Box>
        )}
      </Box>
      <Box mt={1}>
        <InfoText>
          When ready, click &quot;Notify Client&quot; and an email will be sent containing the information
          above.
        </InfoText>
      </Box>

      <CommunicationEvents title="Client Communications" caseId={+caseId} />
    </Box>
  )
}

export { MissingDocuments as default }
