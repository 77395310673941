import { useEffect } from "react"
import Box from "@mui/material/Box"
import MissingDocumentSection from "demand/MissingDocumentSection"
import { SECTIONS } from "missing-docs/constants"
import { useSuspenseQuery } from "@suspensive/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { CaseFacts } from "api/services/case/types"
import { getSectionMissingExhibits } from "api"
import Skeleton from "@mui/material/Skeleton"
import { withSuspense } from "common/withSuspense"
import { useQueryClient } from "@tanstack/react-query"

interface CaseFactsMissingDocumentsProps {
  caseId: CaseFacts["pk"]
}

export const CaseFactsMissingDocuments = withSuspense(
  function CaseFactsMissingDocuments({ caseId }: CaseFactsMissingDocumentsProps): JSX.Element {
    const queryClient = useQueryClient()
    const { data: missingExhibits } = useSuspenseQuery(
      [queryKeys.missingExhibits, caseId],
      async () => getSectionMissingExhibits({ caseId, section: SECTIONS.CASE_FACTS }),
      SILENT_QUERY_PARAMS
    )

    useEffect(() => {
      return () => {
        requestAnimationFrame(() => queryClient.invalidateQueries([queryKeys.missingExhibits]))
      }
    }, [queryClient])

    return (
      <Box mt={2}>
        <MissingDocumentSection
          missingDocs={missingExhibits}
          title="Missing Documents List"
          section={SECTIONS.CASE_FACTS}
        />
      </Box>
    )
  },
  <Skeleton height={70} animation="wave" variant="rounded" />
)
