import React, { ChangeEventHandler, useCallback, useRef, useState } from "react"
import { Box, Popover } from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Appointment } from "documents/types"
import { appointmentsSelectors } from "documents/store/appointments"
import { documentActions, useDocumentStore } from "documents/store"
import { format } from "date-fns"
import { formatDate } from "utils"
import {
  StyledAppointmentDateCell,
  StyledAppointmentDate,
  StyledDeleteTimeButton,
  StyledProviderDateFieldWrapper,
  StyledProviderDateWrapper,
  TimeWrapper,
} from "../styled"
import { StyledTextField } from "./styled"

interface DateCellProps {
  appointmentId: Appointment["id"]
  readOnly?: boolean
}

export const DateCell = ({ appointmentId, readOnly }: DateCellProps) => {
  const appointment = useDocumentStore(appointmentsSelectors.getAppointmentById(appointmentId))
  const [showTime, setShowTime] = useState(!!appointment.timeOfService)
  const [isFocused, setIsFocused] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const dateFieldRef = useRef<HTMLDivElement>(null)

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleClickAway = useCallback(() => {
    if (!isFocused) return

    setIsFocused(false)
    setShowTime(!!appointment.timeOfService)
  }, [appointment, isFocused])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const addTimeHandler = useCallback(() => {
    setShowTime(true)
    setIsFocused(true)
  }, [])

  const removeTimeHandler = useCallback(() => {
    documentActions.setAppointmentTime({
      appointmentId,
      timeOfService: null,
    })

    setIsFocused(false)
    setShowTime(false)
  }, [appointmentId])

  const handleChangeAppointmentTime = useCallback<ChangeEventHandler<HTMLInputElement>>(
    e => {
      const timeOfService = e.target.value

      if (!timeOfService || timeOfService === appointment.timeOfService) return

      documentActions.setAppointmentTime({
        appointmentId,
        timeOfService: timeOfService,
      })
    },
    [appointmentId, appointment.timeOfService]
  )

  const handleChangeAppointmentDate = useCallback(
    (dateOfService: Nullable<Date>) => {
      if (!dateOfService) return

      handleClose()
      documentActions.setAppointmentDate({
        appointmentId,
        dateOfService: format(dateOfService, "yyyy-MM-dd"),
      })
      requestAnimationFrame(() => {
        dateFieldRef.current?.scrollIntoView({ behavior: "smooth", block: "center" })
      })
    },
    [appointmentId, handleClose]
  )

  return (
    <StyledAppointmentDateCell>
      <StyledProviderDateWrapper ref={dateFieldRef}>
        <StyledAppointmentDate readOnly={readOnly} onClick={handleClick}>
          {formatDate(appointment.dateOfService, "MM/dd/yyyy", true)}
        </StyledAppointmentDate>
        {!readOnly && (
          <Popover
            open={Boolean(anchorEl)}
            sx={{ marginTop: "10px" }}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateCalendar
                onChange={handleChangeAppointmentDate}
                value={new Date(`${appointment.dateOfService} 00:00:00`)}
              />
            </LocalizationProvider>
          </Popover>
        )}
        <StyledProviderDateFieldWrapper>
          {readOnly ? (
            showTime && (
              <StyledTextField
                type="time"
                defaultValue={appointment.timeOfService ?? undefined}
                readOnly
                disabled
              />
            )
          ) : (
            <>
              {isFocused && (
                <StyledDeleteTimeButton color="error" onClick={removeTimeHandler}>
                  <ClearIcon fontSize="small" fontWeight={700} />
                </StyledDeleteTimeButton>
              )}
              {showTime ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <StyledTextField
                    type="time"
                    defaultValue={appointment.timeOfService ?? undefined}
                    onBlur={handleChangeAppointmentTime}
                    onClick={handleFocus}
                  />
                </ClickAwayListener>
              ) : (
                <TimeWrapper onClick={addTimeHandler}>
                  <Box>Add time</Box>
                  <Box>(Optional)</Box>
                </TimeWrapper>
              )}
            </>
          )}
        </StyledProviderDateFieldWrapper>
      </StyledProviderDateWrapper>
    </StyledAppointmentDateCell>
  )
}
