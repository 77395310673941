import { UserExhibit } from "exhibit-builder/store/types"
import { create } from "zustand"

interface PopupStore {
  userExhibitId: UserExhibit["id"] | null
  anchor: HTMLElement | null
  openPopup: (data: { anchor: HTMLElement | null; userExhibitId: UserExhibit["id"] }) => void
  closePopup: () => void
}

export const usePopupStore = create<PopupStore>()(set => ({
  userExhibitId: null,
  anchor: null,
  openPopup: ({ anchor, userExhibitId }) => set({ anchor, userExhibitId }),
  closePopup: () => set({ anchor: null }),
}))
