import React, { MouseEventHandler, useCallback, useContext } from "react"
import { RenderElementProps, useSelected } from "slate-react"
import styled from "@emotion/styled"
import { withStyles } from "tss-react/mui"
import Tooltip from "@mui/material/Tooltip"
import { VariableElement } from "../CustomEditor"
import { VariablesContext } from "../features/variables"
import { Variable as VariableType } from "common/types/variables"
import { useSuggestionAttributes } from "./hooks/useSuggestionAttributes"
import { useStyleAttributes } from "./hooks/useStyleAttributes"

interface VariableProps extends RenderElementProps {
  element: VariableElement
}

interface StyledProps {
  selected: boolean
  element: VariableElement
}

const VariableTooltip = withStyles(Tooltip, theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "0.75rem",
  },
}))

const StyledSpan = styled("span")<StyledProps>(({ theme, selected, element }) => ({
  backgroundColor: selected ? theme.palette.mint.main : element.highlight ? "yellow" : undefined,
  color: !selected ? theme.palette.mint.text : undefined,
  fontWeight: element.bold ? "bold" : undefined,
  fontStyle: element.italic ? "italic" : undefined,
  textDecoration: element.underline ? "underline" : undefined,
  transition: theme.transitions.create(["color", "background"], {
    easing: theme.transitions.easing.easeIn,
    duration: "80ms",
  }),

  "&:hover": {
    backgroundColor: element.highlight
      ? "yellow"
      : selected
        ? theme.palette.mint.main
        : theme.palette.mint.hover,
    color: theme.palette.text.primary,
  },
}))

const getVariablePlaceholder = (placeholder: string): string => `[[${placeholder}]]`

const VariableValue: React.FC<{ variable?: VariableType; name: string }> = React.memo(function VariableValue({
  variable,
  name,
}) {
  if (!variable) return <>{getVariablePlaceholder(name)}</>

  const { value, label } = variable
  const display = variable.value || name

  if (!value && !label) {
    return <>{getVariablePlaceholder(display)}</>
  }

  const tooltipLabel = label || name

  if (!value) {
    return (
      <VariableTooltip title={tooltipLabel} placement="top" arrow>
        <span>{getVariablePlaceholder(display)}</span>
      </VariableTooltip>
    )
  }

  return (
    <VariableTooltip title={tooltipLabel} placement="top" arrow>
      <span>{value}</span>
    </VariableTooltip>
  )
})

export const Variable: React.FC<VariableProps> = React.memo(function Variable({
  attributes,
  element,
  children,
}) {
  const selected = useSelected()
  const variables = useContext(VariablesContext)
  const name = element.name
  const variable = variables.getItem(name)
  const suggestionAttributes = useSuggestionAttributes(element)
  const styleAttributes = useStyleAttributes(element)

  const handleMouseEvents: MouseEventHandler<HTMLSpanElement> = useCallback(event => {
    event.preventDefault()
  }, [])

  return (
    <StyledSpan
      {...attributes}
      selected={selected}
      element={element}
      onClick={handleMouseEvents}
      onMouseDown={handleMouseEvents}
      data-variable-name={element.name}
      {...suggestionAttributes}
      {...styleAttributes}
    >
      {children}
      <VariableValue variable={variable} name={name} />
    </StyledSpan>
  )
})
