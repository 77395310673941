import { ReactNode, useCallback, useState } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Box, { BoxProps } from "@mui/material/Box"
import { Collapse, Divider, IconButton } from "@mui/material"
import styled from "@emotion/styled"

interface ExpandableSectionProps {
  title: ReactNode
  children: ReactNode
  wrapperProps?: BoxProps
  withDivider?: boolean
  defaultExpanded?: boolean
}

export const StyledIconButton = styled(IconButton)({
  transition: "0.2s",
})

export const ExpandableSection = ({
  children,
  title,
  wrapperProps = {},
  defaultExpanded = true,
  withDivider = true,
}: ExpandableSectionProps): JSX.Element => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  const toggleExpande = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  return (
    <>
      {withDivider && <Divider />}
      <Box mt={2} mb={2} display="flex" alignItems="center" justifyContent="space-between" {...wrapperProps}>
        {title}
        <StyledIconButton onClick={toggleExpande} sx={{ transform: `rotate(${expanded ? 180 : 0}deg)` }}>
          <ExpandMoreIcon />
        </StyledIconButton>
      </Box>
      <Collapse in={expanded} mountOnEnter unmountOnExit>
        {children}
      </Collapse>
    </>
  )
}
