import { useState, useMemo, useEffect, useCallback } from "react"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Chip from "@mui/material/Chip"
import styled from "@emotion/styled"
import Autocomplete from "@mui/material/Autocomplete"
import debounce from "lodash/debounce"
import { get, uniqWith } from "lodash"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { codeTypeDataMap } from "./constants"

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

const getOptionLabel = option => option.code

const uniqueCodes = (newValue, showErrorMessage, title) =>
  uniqWith(newValue, (a, b) => {
    const equal = a.code === b.code

    if (equal) {
      showErrorMessage(`${title.toUpperCase()} code already in list.`)
    }

    return equal
  })

export function CodesInput({ onChange, type, codes, fullWidth = true, disabled = false }) {
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [options, setOptions] = useState([])
  const { showErrorMessage } = useHandleMessages()
  const { title, api, path, label, search } = codeTypeDataMap[type]

  const changeHandler = useCallback(
    (_, newValue) => {
      setInputValue(newValue)
    },
    [setInputValue]
  )

  const handleInputChange = useMemo(() => debounce(changeHandler, 300), [changeHandler])

  const handleChange = useCallback(
    (_, newValue) => {
      onChange(uniqueCodes(newValue, showErrorMessage, title))
    },
    [onChange, showErrorMessage, title]
  )

  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        label={label}
        data-test={`add-${title}-code`}
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [loading, label, title]
  )

  const renderOption = useCallback(
    (props, option) => (
      <li {...props}>
        <Typography variant="caption" display="block" data-test={`${title}-code-description`}>
          {option.code} {`(${option.description})`}
        </Typography>
      </li>
    ),
    [title]
  )

  const renderTags = useCallback(
    (value, getTagProps) =>
      value.map((option, index) => (
        <Tooltip
          title={option.description ?? ""}
          placement="top"
          data-test={`${title}-code-display`}
          key={option.pk ?? index}
        >
          <Chip label={option.code} {...getTagProps({ index })} />
        </Tooltip>
      )),
    [title]
  )

  const fetchOptions = useCallback(async () => {
    setLoading(true)

    const data = await api({ [search]: inputValue })

    if (data) {
      setOptions(get(data, path))
    }

    setLoading(false)
  }, [api, inputValue, path, search])

  useEffect(() => {
    if (inputValue === "") return setOptions([])

    fetchOptions()
  }, [inputValue, api, fetchOptions])

  return (
    <StyledAutocomplete
      id={`${title}-codes-input`}
      freeSolo
      multiple
      autoComplete
      filterSelectedOptions
      // filterOptions needs to be overridden to do no filtering
      // since we will do all the filtering ourselves via api call
      filterOptions={option => option}
      value={codes}
      disabled={disabled}
      fullWidth={fullWidth}
      options={options}
      loading={loading}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={renderInput}
      renderTags={renderTags}
    />
  )
}
