import styled from "@emotion/styled"
import Select, { SelectProps } from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import BasePagination from "@mui/material/Pagination"
import BasePaginationItem from "@mui/material/PaginationItem"
import { FunctionComponent } from "react"

export const StyledPagination = styled(BasePagination)(({ theme }) => ({
  marginTop: theme.spacing(7),
  display: "flex",
  justifyContent: "center",
}))

export const StyledPaginationItem = styled(BasePaginationItem)(({ theme }) => ({
  fontWeight: 600,
  margin: theme.spacing(0, 1.5),
}))

export const StyledCurrentPaginationItem = styled(StyledPaginationItem)(({ theme }) => ({
  "&, &:hover": {
    cursor: "default",
    pointerEvents: "none",
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
  },
}))

export const StyledPaginationArrowItem = styled(StyledPaginationItem)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.contrastText,
  margin: theme.spacing(0, 3),

  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },

  "&.Mui-disabled": {
    backgroundColor: theme.palette.common.black,
    backgroundImage: "linear-gradient(0, rgba(255,255,255,0.82), rgba(255,255,255,0.82))",
    opacity: 1,
  },

  "& .MuiPaginationItem-icon": {
    fontSize: "1.5rem",
  },
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-selectMenu": {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
})) as unknown as FunctionComponent<SelectProps<number>>

export const StyledDropdownValue = styled("span")(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "13px",
  color: theme.palette.common.black,
}))

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& > .MuiInputBase-root": {
    padding: 0,
    borderRadius: "6px",
    background: theme.palette.button.grey,
  },
}))

export const StyledMenuItem = styled(MenuItem)({
  textTransform: "capitalize",
  fontSize: "13px",
  letterSpacing: "-0.5px",
})
