import { styled } from "@mui/material"
import MUIPaper, { PaperProps as MUIPaperProps } from "@mui/material/Paper"

export interface PaperProps extends MUIPaperProps {}

const StyledPaper = styled(MUIPaper)<PaperProps>(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paperDark,
  border: `1px solid ${theme.palette.divider}`,
}))

export function Paper({ children, ...props }: PaperProps): React.ReactElement {
  return (
    <StyledPaper variant="outlined" {...props}>
      {children}
    </StyledPaper>
  )
}
