import React from "react"

import { AnnotatedContainer } from "./styled"
import ContainerLabel from "./ContainerLabel"

interface ContainerProps {
  children: React.ReactNode
  includeDeviation?: boolean
  includeAnnotationUpdated?: boolean
  dateUpdated?: string
}

export const Container = ({
  includeDeviation = false,
  includeAnnotationUpdated = false,
  children,
  dateUpdated,
}: ContainerProps): JSX.Element => {
  return (
    <AnnotatedContainer>
      <ContainerLabel
        includeDeviation={includeDeviation}
        includeAnnotationUpdated={includeAnnotationUpdated}
        dateUpdated={dateUpdated}
      />
      {children}
    </AnnotatedContainer>
  )
}
