import { Typography } from "@mui/material"
import { useDocumentStore } from "documents/store"
import { providersSelectors } from "documents/store/providers"
import { APPOINTMENT_TAG_ACTIONS, Appointment } from "documents/types"
import { formatDate } from "utils"
import { Circle, StyledAppointmentTagItem } from "./styled"
import { appointmentsSelectors } from "documents/store/appointments"
import { useSearchParams } from "react-router-dom"

type AppointTagLinkProps = {
  appointment: Appointment
}

export const AppointmentTagLink = ({ appointment }: AppointTagLinkProps) => {
  const provider = useDocumentStore(providersSelectors.getProviderById(appointment.providerId ?? ""))
  const tagsForDisplay = useDocumentStore(appointmentsSelectors.getAppointmentTagsForDisplay(appointment.id))
  const completed = useDocumentStore(appointmentsSelectors.getAppointmentCompletedStatus(appointment.id))
  const reviewed = tagsForDisplay.filter(tag => tag.action === APPOINTMENT_TAG_ACTIONS.MARK_REVIEWED)
  const [, setSearchParams] = useSearchParams()

  return (
    <StyledAppointmentTagItem
      onClick={() => {
        setSearchParams({ appointment: appointment.id })
      }}
    >
      <Typography
        component="p"
        variant="labelBadge"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Circle reviewed={!!reviewed.length} completed={completed} top="2px" />{" "}
        {formatDate(appointment.dateOfService, "MM/dd/yyyy", true)}{" "}
        {provider ? <>&ndash; {provider.name}</> : ""}
      </Typography>
    </StyledAppointmentTagItem>
  )
}
