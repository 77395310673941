import { Box, Button } from "@mui/material"
import { ALL_ROLES, INTERNAL_ROLES } from "common/models/roles"
import AssigneePicker, { Segments } from "requests/AssigneePicker"
import { User, UserDto } from "common/models/user"

function getName(user: UserDto): string {
  return `${user.first_name} ${user.last_name}`
}

interface AssigneeSelectorProps {
  label?: string
  user: User
  assignedSelf: boolean
  internalAssignees: UserDto[]
  handleSelfAssign: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: ({ newValues }: { newValues: any }) => void
}

export function AssigneeSelector({
  assignedSelf,
  internalAssignees,
  user,
  handleSelfAssign,
  onChange,
}: AssigneeSelectorProps): JSX.Element {
  return (
    <Box>
      {([INTERNAL_ROLES.LEGALOPS_ADMIN, INTERNAL_ROLES.LEGALOPS_MANAGER] as ALL_ROLES[]).includes(
        user.role
      ) && <AssigneePicker value={internalAssignees} onChange={onChange} segment={Segments.INTERNAL} />}

      {user.role === INTERNAL_ROLES.LEGALOPS && (
        <>
          Assignees: {internalAssignees.map(getName).join(", ")}
          {assignedSelf ? (
            <Button
              sx={{ marginLeft: 4 }}
              variant="outlined"
              color="primary"
              onClick={() => handleSelfAssign()}
            >
              Unassign from myself
            </Button>
          ) : (
            <Button
              sx={{ marginLeft: 4 }}
              variant="outlined"
              color="primary"
              onClick={() => handleSelfAssign()}
            >
              Assign to myself
            </Button>
          )}
        </>
      )}
    </Box>
  )
}
