import { ProjectionItemWithChildren } from "common/nested-list/types"
import { RangeItem } from "./types"
import { ITEM_REF } from "common/nested-list/constants"
import { useCallback } from "react"
import { RangeInput } from "./RangeInput"
import { Box, IconButton } from "@mui/material"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import styled from "@emotion/styled"

const ListItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}))

export function ListItem({
  item,
  canDeleteRange,
  disabled,
  onChange,
  onRemove,
}: {
  item: ProjectionItemWithChildren<RangeItem>
  canDeleteRange: boolean
  disabled?: boolean
  onRemove: (id: string) => void
  onChange: (id: string, startPage: string, endPage: string) => void
}) {
  const pageRange = item[ITEM_REF]
  const updatePageRange = useCallback(
    (startPage: string, endPage: string) => onChange(pageRange.id, startPage, endPage),
    [pageRange.id, onChange]
  )

  return (
    <ListItemContainer>
      <RangeInput range={pageRange} onChange={updatePageRange} disabled={disabled} />
      {canDeleteRange && !disabled && (
        <IconButton onClick={() => onRemove(pageRange.id)} aria-label="delete range">
          <HighlightOffIcon />
        </IconButton>
      )}
    </ListItemContainer>
  )
}
