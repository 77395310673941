import React, { useCallback, useEffect, useMemo, useState } from "react"
import Box from "@mui/material/Box"

import { EMPTY_PLAINTIFF, ASSET_KEYS } from "requests/constants"
import { PlaintiffProps } from "requests/RequestForm/AdditionalInfoRevamp"
import { ConfirmDialog } from "common"
import { Divider, useTheme } from "@mui/material"
import { useRequestAssets } from "requests/RequestAssets"
import { RemovePlaintiff, RequestFormModuleProps } from "../../types"
import { PlaintiffForm } from "./PlaintiffForm"
import { RequestFormModule } from "../RequestFormModule"
import { StyledTextButton } from "../styled"
import { useFormContext, useWatch } from "react-hook-form"
import { PlaintiffDto } from "requests/ViewRequest/types"
import { useRequestContext } from "requests/context"
import { useIsMutating } from "@tanstack/react-query"
import { RequestFields } from "requests/RequestForm/types"

interface PlaintiffInformationProps {
  allowMultiplePlaintiffs?: boolean
  minimalForm?: boolean
}

const isPlaintiffFilled = (plaintiff: PlaintiffProps) => {
  return plaintiff.first_name && plaintiff.last_name && plaintiff.pk
}

const AddPlaintiffButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const plaintiffs = useWatch<RequestFields, "plaintiffs">({ name: "plaintiffs" })
  const requestPk = useWatch<RequestFields, "pk">({ name: "pk" })
  const isSaving = useIsMutating() > 0

  const canAddNewPlaintiff = useMemo(
    () => plaintiffs.every(isPlaintiffFilled) && Boolean(requestPk),
    [plaintiffs, requestPk]
  )

  return (
    <StyledTextButton disabled={!canAddNewPlaintiff || isSaving} onClick={onClick}>
      + Add Plaintiff
    </StyledTextButton>
  )
}

export const PlaintiffInformation: React.FC<RequestFormModuleProps & PlaintiffInformationProps> = ({
  allowMultiplePlaintiffs = true,
  minimalForm = false,
}): JSX.Element => {
  const assets = useRequestAssets()
  const [plaintiffToRemove, setPlaintiffToRemove] = useState<RemovePlaintiff | null>(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const { getValues } = useFormContext()
  const { plaintiffsArrayProps } = useRequestContext()
  const isMutating = useIsMutating() > 0

  const familyOptions = useMemo(() => {
    return assets[ASSET_KEYS.familyStatus]?.map(({ display: label, key: value }) => ({ label, value })) ?? []
  }, [assets])
  const dependentOptions = useMemo(() => {
    return (
      assets[ASSET_KEYS.dependentStatus]?.map(({ display: label, key: value }) => ({ label, value })) ?? []
    )
  }, [assets])

  const theme = useTheme()

  const addEmptyPlaintiff = useCallback(() => {
    plaintiffsArrayProps.append(EMPTY_PLAINTIFF)
  }, [plaintiffsArrayProps])

  const [isSaving, setIsSaving] = useState(false)

  const removePlaintiff = useCallback(
    (indexToRemove: null | number | undefined): void => {
      if (indexToRemove === null || indexToRemove === undefined) return

      const plaintiffs = getValues("plaintiffs") as PlaintiffDto[]

      plaintiffsArrayProps.remove(indexToRemove)

      if (plaintiffs[indexToRemove].pk === undefined) {
        setOpenConfirmDialog(false)
        setPlaintiffToRemove(null)
      } else {
        setIsSaving(true)
      }
    },
    [getValues, plaintiffsArrayProps]
  )

  useEffect(() => {
    if (!isMutating) {
      setIsSaving(false)
    }
  }, [isMutating])

  useEffect(() => {
    if (!isSaving) {
      setOpenConfirmDialog(false)
      setPlaintiffToRemove(null)
    }
  }, [isSaving])

  const removePlaintiffConfirmationText = `Are you sure you want to remove ${
    plaintiffToRemove?.plaintiffFirstName ?? ""
  } ${plaintiffToRemove?.plaintiffLastName ?? ""}?  This cannot be undone.`

  const handleRemovePlaintiff = useCallback((plaintiff: React.SetStateAction<RemovePlaintiff | null>) => {
    setPlaintiffToRemove(plaintiff)
    setOpenConfirmDialog(true)
  }, [])

  return (
    <RequestFormModule title="Plaintiff Information">
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={() => removePlaintiff(plaintiffToRemove?.plaintiffIndex)}
        title="Remove Plaintiff?"
        body={removePlaintiffConfirmationText}
        loading={isSaving}
      />
      <Box mb={1}>
        {plaintiffsArrayProps.fields.map(({ pk }, index: number) => {
          return (
            // Note: Using (pk ?? new) as key will cause menu items to collapse when you are
            // editing menu field items during a plaintiff creation save call.
            <React.Fragment key={pk ?? "new"}>
              {index > 0 && <Divider sx={{ marginBottom: theme.spacing(4), marginTop: theme.spacing(2) }} />}
              <PlaintiffForm
                index={index}
                removePlaintiff={handleRemovePlaintiff}
                familyOptions={familyOptions}
                dependentOptions={dependentOptions}
                minimalForm={minimalForm}
                numberOfPlaintiffs={plaintiffsArrayProps.fields.length}
              />
            </React.Fragment>
          )
        })}
        {allowMultiplePlaintiffs && <AddPlaintiffButton onClick={addEmptyPlaintiff} />}
      </Box>
    </RequestFormModule>
  )
}
