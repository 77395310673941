import { useMemo } from "react"
import { makeStyles } from "tss-react/mui"
import { RadioButtons } from "common/form-components"

import { AnnualSalaryForm } from "./AnnualSalaryForm"
import { HourlySalaryForm } from "./HourlySalaryForm"
import { NoSalaryForm } from "./NoSalaryForm"
import { PercentagesForm } from "./PercentagesForm"
import { SALARY_FORMS } from "../constants"

const useStyles = makeStyles()(theme => ({
  salarySelection: {
    marginTop: theme.spacing(2),
  },
}))

export const SalarySection = ({ control, watch, salaryInformationType }) => {
  const { classes } = useStyles()
  const salaryForm = useMemo(() => {
    switch (salaryInformationType) {
      case SALARY_FORMS.annual.key:
        return <AnnualSalaryForm control={control} />
      case SALARY_FORMS.hourly.key:
        return <HourlySalaryForm control={control} watch={watch} />
      case SALARY_FORMS.noSalary.key:
        return <NoSalaryForm control={control} />
      default:
        return null
    }
  }, [control, watch, salaryInformationType])

  const options = useMemo(
    () => [
      { key: "annual", display: "Annual Salary" },
      { key: "hourly", display: "Hourly Rate" },
      { key: "noSalary", display: "No Salary Information Available" },
    ],
    []
  )

  return (
    <>
      <RadioButtons
        name="salary_information_type"
        control={control}
        formControlClassName={classes.salarySelection}
        label="What salary information is available?"
        aria-label="salary section selection"
        options={options}
        row
      />
      {salaryForm}
      <PercentagesForm control={control} />
    </>
  )
}
