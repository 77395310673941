import { useState } from "react"
import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { StyledInformationWrapper } from "../styled"
import { InfoContainer } from "./InfoContainer"
import { Typography } from "@mui/material"
import { theme } from "app/theme"
import { PlaintiffTab } from "requests/RequestForm/AdditionalInfoRevamp/components/PlaintiffTab"
import { NonEconomicDamages } from "./NonEconomicDamages"
import { ViewRequestRevampProps } from "../types"
import { PlaintiffProps } from "requests/RequestForm/AdditionalInfoRevamp"
import { DOCUMENT_TYPES } from "requests/enums"
import { getFirstPlaintiffName } from "requests/plaintiffUtils"

export const EconomicImpactInformation = ({ request }: ViewRequestRevampProps) => {
  const [activeTab, setActiveTab] = useState<number>(0)

  const plaintiff: PlaintiffProps = request.plaintiffs[activeTab]
  const isBasicPlus = request.type === DOCUMENT_TYPES.BASIC_PLUS

  const { firstName, lastName } = getFirstPlaintiffName(request)

  return (
    <RequestFormModule>
      <PlaintiffTab
        plaintiffs={request.plaintiffs}
        plaintiff_first_name={firstName}
        plaintiff_last_name={lastName}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {!isBasicPlus && (
        <>
          <Typography fontWeight="bold" marginBottom={theme.spacing(1)}>
            Economic Impact
          </Typography>

          <StyledInformationWrapper>
            <InfoContainer field="Loss of Income" value={plaintiff.has_loss_of_income ? "Yes" : "No"} />
            <InfoContainer
              field="Out-of-Pocket Expenses"
              value={plaintiff.has_out_of_pocket_expenses ? "Yes" : "No"}
            />
            <InfoContainer
              field="Impairment of Household Services"
              value={
                plaintiff.household_impairment_rate !== null &&
                plaintiff.household_impairment_rate !== undefined
                  ? `${plaintiff.household_impairment_rate}%`
                  : "No"
              }
            />
          </StyledInformationWrapper>

          <InfoContainer field="Loss of Income Details" value={plaintiff.additional_information || "-"} />
        </>
      )}
      <Typography fontWeight="bold" marginTop={theme.spacing(4)} marginBottom={theme.spacing(1)}>
        Non-Economic Impact
      </Typography>

      <NonEconomicDamages plaintiff={plaintiff} documentType={request.type} />
    </RequestFormModule>
  )
}
