import { useCallback, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { Box, TextField, CircularProgress } from "@mui/material"
import { Cancel } from "@mui/icons-material"

import { providerObjectiveTestService } from "api/services/provider-objective-test"
import { ObjectiveTest, ObjectiveTestFinding } from "api/services/provider-objective-test/types"
import { useFormContext } from "demand/context"
import {
  Action,
  DELETE_OBJECTIVE_TEST,
  UPDATE_OBJECTIVE_TEST,
  ADD_OBJECTIVE_TEST_FINDING,
  UPDATE_OBJECTIVE_TEST_FINDING,
} from "demand/Providers/reducer"
import { Provider } from "demand/Providers/types"
import { DateField } from "common/form-components"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { AddNewFinding, FindingsEditableList } from "./components"
import { ObjectiveTestEditableRow, DeleteObjectiveTestButton } from "./styled"
import { NO_DESCRIPTION_INFO } from "./constants"
import { getInitialReferencePageState } from "./utils"

interface ObjectiveTestFormProps {
  objectiveTest: ObjectiveTest
  dispatch: React.Dispatch<Action>
  provider: Provider
}

export const ObjectiveTestForm = ({ objectiveTest, dispatch, provider }: ObjectiveTestFormProps) => {
  const providerId = provider.pk
  const [currentObjectiveTest, setCurrentObjectiveTest] = useState<ObjectiveTest>(objectiveTest)

  const { caseId } = useFormContext()
  const objectiveTestId = objectiveTest?.id || ""
  const isNoDescription = !currentObjectiveTest.description

  const { showErrorMessage } = useHandleMessages()

  const { mutate: updateObjectiveTest } = useMutation(
    providerObjectiveTestService.updateProviderObjectiveTest,
    {
      onSuccess: () => {
        dispatch({
          type: UPDATE_OBJECTIVE_TEST,
          payload: { providerId, objectiveTestId, updates: currentObjectiveTest },
        })
      },
      onError: error => showErrorMessage({ error, message: "Error updating Objective Test." }),
    }
  )

  const { mutate: deleteObjectiveTest, isLoading: isDeletingObjectiveTest } = useMutation(
    providerObjectiveTestService.deleteProviderObjectiveTest,
    {
      onSuccess: () => {
        dispatch({
          type: DELETE_OBJECTIVE_TEST,
          payload: { providerId, objectiveTestId },
        })
      },
      onError: error => showErrorMessage({ error, message: "Error deleting Objective Test." }),
    }
  )

  const { mutate: createProviderObjectiveTestFinding, isLoading: isProviderObjectiveTestFindingCreating } =
    useMutation(providerObjectiveTestService.createProviderObjectiveTestFinding, {
      onSuccess: data => {
        dispatch({
          type: ADD_OBJECTIVE_TEST_FINDING,
          payload: { providerId, objectiveTestId, finding: data },
        })
      },
      onError: error => showErrorMessage({ error, message: "Error adding new Finding." }),
    })

  const { mutate: updateProviderObjectiveTestFinding } = useMutation(
    providerObjectiveTestService.updateProviderObjectiveTestFinding,
    {
      onSuccess: data => {
        dispatch({
          type: UPDATE_OBJECTIVE_TEST_FINDING,
          payload: { providerId, objectiveTestId, findingId: data.id, updates: data },
        })
      },
      onError: error => showErrorMessage({ error, message: "Error updating Finding." }),
    }
  )

  const handleBlur = useCallback(() => {
    updateObjectiveTest({ data: currentObjectiveTest, options: { caseId, providerId, objectiveTestId } })
  }, [updateObjectiveTest, currentObjectiveTest, caseId, providerId, objectiveTestId])

  const handleDeleteObjectiveTest = useCallback(
    () => deleteObjectiveTest({ providerId, caseId, objectiveTestId }),
    [deleteObjectiveTest, providerId, caseId, objectiveTestId]
  )

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentObjectiveTest(prevState => ({ ...prevState, description: e.target.value }))
  }

  const handleDateChange = (date: Nullable<string>) => {
    const updatedObjectiveTest = { ...currentObjectiveTest, date }
    setCurrentObjectiveTest(updatedObjectiveTest)

    // onBlur is not triggered when the date is changed in some scenarios, so we need to manually update
    updateObjectiveTest({ data: updatedObjectiveTest, options: { caseId, providerId, objectiveTestId } })
  }

  const handleAddFinding = useCallback(() => {
    createProviderObjectiveTestFinding({ objectiveTestId })
  }, [createProviderObjectiveTestFinding, objectiveTestId])

  const handleAddFindingReference = useCallback(() => {
    dispatch({
      type: UPDATE_OBJECTIVE_TEST,
      payload: {
        providerId,
        objectiveTestId,
        updates: {
          ...objectiveTest,
          findings: objectiveTest.findings.map((finding, index) => {
            if (index !== objectiveTest.findings.length - 1) return finding

            return {
              ...finding,
              referencePages: [...finding.referencePages, getInitialReferencePageState()],
            }
          }),
        },
      },
    })
  }, [providerId, objectiveTestId, objectiveTest, dispatch])

  const updateFinding = useCallback(
    (finding: ObjectiveTestFinding) => {
      updateProviderObjectiveTestFinding({
        data: finding,
        options: { objectiveTestId, findingId: finding.id },
      })
    },
    [updateProviderObjectiveTestFinding, objectiveTestId]
  )

  return (
    <ObjectiveTestEditableRow>
      <TextField
        onChange={handleDescriptionChange}
        value={currentObjectiveTest.description ?? ""}
        onBlur={handleBlur}
        disabled={isDeletingObjectiveTest}
      />
      <DateField
        onChange={handleDateChange}
        onBlur={handleBlur}
        initialValue={currentObjectiveTest.date}
        disabled={isDeletingObjectiveTest}
      />

      <FindingsEditableList
        findings={objectiveTest.findings}
        isDisabled={isNoDescription || isDeletingObjectiveTest}
        disabledTitle={NO_DESCRIPTION_INFO}
        updateFinding={updateFinding}
        isCreatingNewItem={isProviderObjectiveTestFindingCreating}
        provider={provider}
      />

      <AddNewFinding
        onAddFinding={handleAddFinding}
        onAddFindingReference={handleAddFindingReference}
        isDisabled={isNoDescription || isDeletingObjectiveTest}
        disabledTitle={NO_DESCRIPTION_INFO}
        objectiveTestId={objectiveTestId}
      />

      <Box>
        <DeleteObjectiveTestButton
          disabled={isDeletingObjectiveTest}
          title={isDeletingObjectiveTest ? "Deleting..." : undefined}
          onClick={handleDeleteObjectiveTest}
        >
          {isDeletingObjectiveTest ? <CircularProgress color="inherit" size={20} /> : <Cancel />}
        </DeleteObjectiveTestButton>
      </Box>
    </ObjectiveTestEditableRow>
  )
}
