// need to sync with backend RANGE_FIELDS constants
export const DEFAULT_AWARD_AMOUNT_RANGES = [
  [10000, 100000],
  [100000, 500000],
  [500000, 1000000],
  [1000000, 10000000],
  [10000000, null],
]
export const ALL_BUILT_IN_RANGES = [[0, 10000], ...DEFAULT_AWARD_AMOUNT_RANGES]

export const DEFAULT_DECISION_TYPES = ["plaintiff verdict", "unknown", "other", ""]

export const TREATMENTS = "treatments"

export const FREE_TEXT_QUERY = "free_text_query"

export enum FACETS {
  state = "state",
  county = "county",
  high_level_categories = "high_level_categories",
  case_decision_type = "case_decision_type",
}

export const MAP_LABEL_TO_FACET = {
  [FACETS.state]: "Add State",
  [FACETS.county]: "Add County",
  [FACETS.case_decision_type]: "Add High Level Categories",
  [FACETS.high_level_categories]: "Add Case Decision Type",
}
