import { Range, BaseRange } from "slate"

export function getNormalizedSelection(selection: BaseRange): BaseRange {
  if (Range.isForward(selection)) return selection

  return {
    anchor: selection.focus,
    focus: selection.anchor,
  }
}
