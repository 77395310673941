import { create } from "zustand"
import { DocumentState } from "./types"
import * as Sentry from "@sentry/react"

export const useDocumentStateStore = create<DocumentState>(() => ({
  title: "",
  titleTags: [],
  showExtraButtons: true,
  showAnnotationButton: true,
  showPlaintiff: true,
  isSaving: false,
  errorMessage: null,
  error: null,
}))

export const documentStateActions = {
  save: <T>(request: Promise<T>, errorMessage: string) => {
    useDocumentStateStore.setState({ isSaving: true })

    request
      .catch(error => {
        Sentry.captureException(error, { extra: { errorMessage } })
        return useDocumentStateStore.setState({ errorMessage, error })
      })
      .finally(() => useDocumentStateStore.setState({ isSaving: false }))

    return request
  },
  setTitle: (title: string) => useDocumentStateStore.setState({ title }),
  setTitleTags: (titleTags: string[]) => useDocumentStateStore.setState({ titleTags }),
  unsetTitle: () => useDocumentStateStore.setState({ title: "" }),
  setShowExtraButtons: (showExtraButtons: boolean) => useDocumentStateStore.setState({ showExtraButtons }),
  setShowPlaintiff: (showPlaintiff: boolean) => useDocumentStateStore.setState({ showPlaintiff }),
  setShowAnnotationButton: (showAnnotationButton: boolean) =>
    useDocumentStateStore.setState({ showAnnotationButton }),
}
