export enum EXTERNAL_ROLES {
  SUPPORT = "1",
  ATTORNEY = "4",
  OFFICE_ADMIN = "6",
  FIRM_ADMIN = "8",
}

export enum INTERNAL_ROLES {
  LEGALOPS = "20",
  LEGALOPS_MANAGER = "22",
  LEGALOPS_ADMIN = "24",
}

export const INTERNAL_ROLES_VALUES = Object.values<ALL_ROLES>(INTERNAL_ROLES)
export const EXTERNAL_ROLES_VALUES = Object.values<ALL_ROLES>(EXTERNAL_ROLES)
export const OSF = "15"
export const ALL_ROLES_VALUES = Object.values<string>([
  ...INTERNAL_ROLES_VALUES,
  ...EXTERNAL_ROLES_VALUES,
  OSF,
])

export const NOT_SETUP_ROLE = "0"

export type EXTERNAL_ROLE_LITERALS = `${EXTERNAL_ROLES}`
export type INTERNAL_ROLE_LITERALS = `${INTERNAL_ROLES}`
export type ROLES = EXTERNAL_ROLE_LITERALS | INTERNAL_ROLE_LITERALS
export type ALL_ROLES = ROLES | typeof NOT_SETUP_ROLE | typeof OSF

interface UserRoleMapping {
  key: ALL_ROLES
  display: string
}

export const USER_ROLES: Record<ALL_ROLES, UserRoleMapping> = {
  [NOT_SETUP_ROLE]: { key: NOT_SETUP_ROLE, display: "Not Setup" },
  [EXTERNAL_ROLES.SUPPORT]: { key: EXTERNAL_ROLES.SUPPORT, display: "Support" },
  [EXTERNAL_ROLES.ATTORNEY]: { key: EXTERNAL_ROLES.ATTORNEY, display: "Attorney" },
  [EXTERNAL_ROLES.OFFICE_ADMIN]: { key: EXTERNAL_ROLES.OFFICE_ADMIN, display: "Office Admin" },
  [EXTERNAL_ROLES.FIRM_ADMIN]: { key: EXTERNAL_ROLES.FIRM_ADMIN, display: "Firm Admin" },
  [INTERNAL_ROLES.LEGALOPS]: { key: INTERNAL_ROLES.LEGALOPS, display: "LegalOps" },
  [INTERNAL_ROLES.LEGALOPS_MANAGER]: { key: INTERNAL_ROLES.LEGALOPS_MANAGER, display: "LegalOps Manager" },
  [INTERNAL_ROLES.LEGALOPS_ADMIN]: { key: INTERNAL_ROLES.LEGALOPS_ADMIN, display: "LegalOps Admin" },
  [OSF]: { key: OSF, display: "Tasker" },
}
