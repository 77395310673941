import { v4 } from "uuid"
import { FormRange } from "../types"

export type Action =
  | { type: "addRange"; numberOfPages: number }
  | { type: "deleteRange"; formId: string }
  | { type: "editRange"; formId: string; start: number }

export interface State {
  ranges: FormRange[]
}

export const splitReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "addRange": {
      const newRanges: FormRange[] = [
        ...state.ranges,
        { formId: v4(), start: NaN, end: action.numberOfPages },
      ]
      return {
        ...state,
        ranges: newRanges,
      }
    }
    case "deleteRange": {
      const newRanges: FormRange[] = []

      state.ranges.forEach((range, index) => {
        if (range.formId !== action.formId) {
          newRanges.push(range)
          return
        }
        // first range is not delete-able, so we can assume there will always be a range
        // at index - 1
        // also ensure new object is created so any react prop checking will still trigger a re-render
        newRanges[index - 1] = { ...newRanges[index - 1], end: range.end }
      })

      return {
        ...state,
        ranges: newRanges,
      }
    }
    case "editRange": {
      const newRanges: FormRange[] = []

      state.ranges.forEach((range, index) => {
        if (range.formId !== action.formId) {
          newRanges.push(range)
          return
        }
        const newRange: FormRange = { ...range, start: action.start }

        if (Number.isNaN(action.start)) {
          newRanges.push(newRange)
          return
        }

        // first range is not editable, so we can assume there will always be a prevRange
        const prevRange = newRanges[index - 1]
        const nextRange = state.ranges[index + 1] as Nullable<FormRange>
        // snap prev range's end to 1 below the range we are currently editing
        // only if start value is in-between prev range and end of current range
        if (action.start > prevRange.start && action.start <= range.end) {
          // ensure new object is created so any react prop checking will still trigger a re-render
          newRanges[index - 1] = { ...newRanges[index - 1], end: action.start - 1 }
        }

        if (nextRange && range.end >= nextRange.start) {
          newRange.end = nextRange.start - 1
        }
        newRanges.push(newRange)
      })

      return {
        ...state,
        ranges: newRanges,
      }
    }
  }
}
