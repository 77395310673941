import * as Sentry from "@sentry/browser"
import { DocumentData, MedicronCalendarData } from "documents/store/types"
import {
  AppointmentDto,
  PartitionDto,
  DocumentEntitiesDto,
  DocumentGenerationDTO,
  DocumentGenerationEntity,
  DocumentGenerationResponseEntity,
  ExhibitDto,
  ProviderDto,
  UpdateResponse,
  ICDCodeDto,
  MatchesDocumentStructureResponseDto,
  MatchesDocumentStructureResponse,
  CreateAppointment,
  CreateIcdCode,
  UpdateIcdCode,
  DeleteIcdCode,
  CreateAppointmentPartition,
  MedchronTileDTO,
  SyncWithDocumentStructureDTO,
  DocumentStructureDto,
  DocumentStructureComponent,
} from "./types"
import { format } from "date-fns"
import { Appointment, Exhibit, IcdCode, Partition, Provider } from "documents/types"
import { RawModel, toCamelCase } from "common/helpers/object"
import { BaseServiceDeserializer } from "api/BaseDeserializer"
export class DocumentGenerationServiceDeserializer {
  static fromJSON({
    results: documentGenerations,
  }: {
    results: [DocumentGenerationDTO]
  }): DocumentGenerationEntity[] {
    return documentGenerations.map((documentGeneration: DocumentGenerationDTO) => ({
      completed: documentGeneration.completed_at
        ? format(new Date(documentGeneration.completed_at), "MM/dd/yyyy h:mma")
        : "--",
      started: documentGeneration.requested_at
        ? format(new Date(documentGeneration.requested_at), "MM/dd/yyyy h:mma")
        : "--",
      downloadURL: documentGeneration.document_file,
      id: documentGeneration.pk,
      status: documentGeneration.status,
    }))
  }

  static fromGenerateJSON({
    results,
  }: {
    results: [DocumentGenerationDTO]
  }): DocumentGenerationResponseEntity {
    return {
      requestId: results[0].request_id,
    }
  }
}

export class DocumentEntitiesDeserializer {
  static fromJSON({
    plaintiff,
    exhibits,
    provider: providers,
    appointments,
    partitions,
    icd_codes: icdCodes,
    questionnaire_id: questionnaireId,
    date_of_incident: dateOfIncident,
  }: DocumentEntitiesDto): DocumentData {
    const documentData: DocumentData = {
      plaintiff: plaintiff ? `${plaintiff.first_name} ${plaintiff.last_name}` : "",
      exhibitsOrder: [],
      exhibits: {},
      partitions: {},
      appointments: {},
      providersOrder: [],
      providers: {},
      icdCodes: {},
      questionnaireId,
      dateOfIncident,
    }

    for (const provider of providers) {
      documentData.providers[provider.pk] = DocumentEntitiesDeserializer.providerFromJSON(provider)
      documentData.providersOrder.push(provider.pk)
    }

    for (const exhibit of exhibits) {
      documentData.exhibits[exhibit.pk] = DocumentEntitiesDeserializer.exhibitFromJSON(exhibit)
      documentData.exhibitsOrder.push(exhibit.pk)
    }

    for (const partitionData of partitions) {
      const partition = DocumentEntitiesDeserializer.partitionFromJSON(partitionData)

      if (documentData.exhibits[partition.exhibitId]) {
        documentData.partitions[partition.id] = partition
        documentData.exhibits[partition.exhibitId].partitionIds.push(partition.id)
      } else {
        Sentry.captureException("No exhibit for partition", { extra: { partition } })
      }
    }

    for (const appointmentData of appointments) {
      const appointment = DocumentEntitiesDeserializer.appointmentFromJSON(appointmentData)
      documentData.appointments[appointmentData.pk] = appointment

      for (const partitionId of appointmentData.partitions) {
        if (documentData.partitions[partitionId]) {
          documentData.partitions[partitionId].appointmentIds.push(appointmentData.pk)
        } else {
          Sentry.captureException("No patition for appointment", { extra: { appointment } })
        }
      }
    }

    for (const icdCodeData of icdCodes) {
      const icdCode = DocumentEntitiesDeserializer.icdCodeFromJSON(icdCodeData)
      const { relations } = icdCode
      icdCode.relations = []

      for (const relation of relations) {
        if (documentData.partitions[relation.partitionId]) {
          icdCode.relations.push(relation)
        } else {
          Sentry.captureException("No patition relation for icdCode", { extra: { icdCode } })
        }
      }

      if (documentData.appointments[icdCode.appointmentId]) {
        documentData.icdCodes[icdCode.id] = icdCode
        documentData.appointments[icdCode.appointmentId].icdCodes.push(icdCode.id)
      } else {
        Sentry.captureException("No appointment for icdCode", { extra: { icdCode } })
      }
    }

    return documentData
  }

  static exhibitFromJSON(exhibit: ExhibitDto): Exhibit {
    return {
      id: exhibit.pk,
      name: exhibit.name,
      fileName: exhibit.file_name,
      partitionIds: [],
      pageCount: exhibit.number_of_pages ?? "N/A",
    }
  }

  static appointmentFromJSON(appointment: AppointmentDto): Appointment {
    return {
      id: appointment.pk,
      dateOfService: appointment.date_of_service,
      providerId: appointment.provider_id,
      summary: appointment.user_summary,
      generatedSummary: appointment.generated_summary,
      partitions: appointment.partitions,
      icdCodes: [],
      generatedSummaryStatus: appointment.generated_summary_status,
      timeOfService: appointment.time_of_service,
      tags: appointment.tags.map(tag => toCamelCase(tag)),
    }
  }

  static partitionFromJSON(partition: PartitionDto): Partition {
    return {
      id: partition.pk,
      name: partition.name,
      startPage: Math.max(partition.start_page, 1),
      endPage: Math.max(partition.end_page, 1),
      appointmentIds: [],
      exhibitId: partition.exhibit,
    }
  }

  static providerFromJSON(provider: ProviderDto): Provider {
    return {
      id: provider.pk,
      name: provider.name,
      color: provider.color ? `#${provider.color}` : "#e0e0e0",
    }
  }

  static icdCodeFromJSON(icdCode: ICDCodeDto): IcdCode {
    return {
      id: icdCode.pk,
      appointmentId: icdCode.appointment_id,
      code: icdCode.icd_code,
      description: icdCode.description,
      relations: icdCode.partitions.map(relation => ({
        partitionId: relation.partition_id,
        page: relation.page_number,
      })),
    }
  }

  static appointmentResponse({ appointment }: UpdateResponse<{ appointment: AppointmentDto }>): Appointment {
    return DocumentEntitiesDeserializer.appointmentFromJSON(appointment)
  }

  static newAppointmentResponse({
    appointment: appointmentData,
    icd_codes,
    partitions: partitionsData,
  }: UpdateResponse<{ appointment: AppointmentDto; icd_codes: ICDCodeDto[]; partitions: PartitionDto[] }>): {
    appointment: Appointment
    icdCodes: IcdCode[]
    partitions: Partition[]
  } {
    const appointment = DocumentEntitiesDeserializer.appointmentFromJSON(appointmentData)
    const icdCodes = icd_codes.map(DocumentEntitiesDeserializer.icdCodeFromJSON)
    const partitions = partitionsData.map(DocumentEntitiesDeserializer.partitionFromJSON)

    for (const icdCode of icdCodes) {
      appointment.icdCodes.push(icdCode.id)
    }

    return { appointment, icdCodes, partitions }
  }

  static providerResponse({ provider }: UpdateResponse<{ provider: ProviderDto }>): Provider {
    return DocumentEntitiesDeserializer.providerFromJSON(provider)
  }

  static icdCodeResponse({ icd_code }: UpdateResponse<{ icd_code: ICDCodeDto }>): IcdCode {
    return DocumentEntitiesDeserializer.icdCodeFromJSON(icd_code)
  }
}

type CreateAppointmentPayload = RequiredNonNullableObj<Pick<Appointment, "dateOfService" | "providerId">> &
  Pick<Appointment, "summary"> & {
    icdCodes: Omit<IcdCode, "id" | "appointmentId">[]
    partitions: Pick<Partition, "exhibitId" | "startPage" | "endPage">[]
  }

export class DocumentEntitiesSerializer {
  static newAppointmentToJSON({
    dateOfService,
    providerId,
    icdCodes,
    summary,
    partitions,
  }: CreateAppointmentPayload): CreateAppointment {
    return {
      date_of_service: dateOfService,
      provider_id: providerId,
      icd_codes: icdCodes.map(DocumentEntitiesSerializer.newIcdCodeToJSON),
      user_summary: summary,
      partitions: partitions.map(DocumentEntitiesSerializer.newPartitionToJSON),
    }
  }

  static newIcdCodeToJSON(icdCode: Pick<IcdCode, "code" | "relations">): CreateIcdCode {
    return {
      icd_code: icdCode.code,
      partitions: icdCode.relations.map(relation => ({
        partition_id: relation.partitionId,
        page_number: relation.page,
      })),
    }
  }

  static icdCodeToJSON(icdCode: Pick<IcdCode, "id" | "code" | "relations">): UpdateIcdCode {
    return {
      id: icdCode.id,
      icd_code: icdCode.code,
      partitions: icdCode.relations.map(relation => ({
        partition_id: relation.partitionId,
        page_number: relation.page,
      })),
    }
  }

  static deleteIcdCodeToJSON(icdCode: Pick<IcdCode, "code">): DeleteIcdCode {
    return {
      icd_code: icdCode.code,
    }
  }

  static newPartitionToJSON(
    partition: Pick<Partition, "exhibitId" | "startPage" | "endPage">
  ): CreateAppointmentPartition {
    return {
      exhibit: partition.exhibitId,
      start_page: partition.startPage,
      end_page: partition.endPage,
    }
  }
}

export class MedicronCalendarDeserializer {
  static fromJSON({
    plaintiff,
    provider: providers,
    appointments,
    date_of_incident,
  }: DocumentEntitiesDto): MedicronCalendarData {
    const medicronCalendarData: MedicronCalendarData = {
      plaintiff: plaintiff ? `${plaintiff.first_name} ${plaintiff.last_name}` : "",
      appointments: {},
      providers: {},
      dayOfIncident: new Date(`${date_of_incident} 00:00`),
    }

    for (const provider of providers) {
      medicronCalendarData.providers[provider.pk] = DocumentEntitiesDeserializer.providerFromJSON(provider)
    }

    for (const appointment of appointments) {
      medicronCalendarData.appointments[appointment.pk] =
        DocumentEntitiesDeserializer.appointmentFromJSON(appointment)
    }

    return medicronCalendarData
  }
}

export class MedchronTileDeserializer {
  static fromJSON(data: MedchronTileDTO): RawModel<MedchronTileDTO> {
    return BaseServiceDeserializer.fromJSON(data)
  }
}

export class DocumentStructureDeserializer {
  static fromJSON(data: DocumentStructureDto[]): DocumentStructureComponent[] {
    return data.map((sec: DocumentStructureDto) => ({
      documentStructureId: sec.document_structure_id,
      componentKey: sec.component_key,
      pk: sec.pk,
      sectionId: sec.section_id,
      type: sec.type,
    }))
  }

  static matchesFromJSON(data: MatchesDocumentStructureResponseDto): MatchesDocumentStructureResponse {
    return {
      matchingDocumentStructure: data.matching_document_structure,
    }
  }

  static syncFromJSON(data: SyncWithDocumentStructureDTO): RawModel<SyncWithDocumentStructureDTO> {
    return BaseServiceDeserializer.fromJSON(data)
  }
}
