import { useTheme } from "@emotion/react"
import { Chip, ChipProps } from "@mui/material"

export function DynamicColorChip({
  color,
  ...props
}: { color?: string } & Omit<ChipProps, "color" | "size">) {
  const theme = useTheme()

  return (
    <Chip
      {...props}
      size="small"
      sx={{
        fontWeight: "bold",
        backgroundColor: color,
        color: theme.palette.getContrastText(color ?? theme.palette.common.white),
      }}
    />
  )
}
