import React, { memo, useCallback, useMemo, useRef, useState } from "react"
import styled from "@emotion/styled"
import { Box, CircularProgress, Collapse } from "@mui/material"
import { FieldValues, Path, useWatch } from "react-hook-form"
import { EditorContent, RichTextField, RichTextFieldProps } from "common/form-components/rich-text"
import { RichTextEditor, RichTextEditorReadonlyProps } from "common/form-components/rich-text/RichTextEditor"
import { PropsByFeature } from "common/form-components/rich-text/features/types"
import { EDITOR_FEATURES } from "common/form-components/rich-text/features/constants"
import { FeatureProps } from "common/types/features"
import { Variable } from "common/types/variables"
import { useCaseVariables } from "demand/Variables"
import { RichTextInput, RichTextInputProps } from "common/form-components/rich-text/RichTextInput"
import { CASE_SECTIONS } from "common/types/sections"
import { CaseTemplatedSection } from "api/services/case/types"
import Button from "@mui/material/Button"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { TemplatePreview } from "./TemplatePreview"
import { QueryKey, useIsFetching, useMutation, useQueryClient } from "@tanstack/react-query"
import { caseService } from "api/services/case"
import { isEqual } from "lodash"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { StyledNoTemplate } from "./styled"

import { AskAIButton } from "common/AskAI"
import { SummaryForm } from "summaries"
import { queryKeys } from "react-query/constants"
import { FieldPathWithValue } from "common/types/helper"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"
import { useFormContext } from "demand/context"

interface CaseVariablesProps {
  variables?: Variable[]
}

interface CaseAskAiProps {
  includeAskAI?: boolean
  caseId?: PrimaryKey
  askAiWarningBannerText?: string
  generatorKeys?: string[]
  displayEmptyState?: boolean
}

type CaseEditorFeatureProps = FeatureProps<Pick<PropsByFeature, EDITOR_FEATURES.SUGGESTIONS>>

type CaseEditorFieldProps<T extends FieldValues> = Omit<RichTextFieldProps<T>, "name"> & {
  name: FieldPathWithValue<T, Nullable<EditorContent>>
} & CaseEditorFeatureProps &
  CaseVariablesProps &
  CaseAskAiProps

type CaseEditorProps = RichTextEditorReadonlyProps &
  CaseEditorFeatureProps &
  CaseVariablesProps &
  CaseAskAiProps

export type CaseEditorInputProps = RichTextInputProps &
  CaseEditorFeatureProps &
  CaseVariablesProps &
  CaseAskAiProps

type CaseInvalidationProps = {
  invalidateQueryKeys?: QueryKey[]
}

export type CaseTemplatedSections = {
  sections: Partial<Record<CASE_SECTIONS, CaseTemplatedSection>>
}

const BorderedBox = styled(Box)(({ theme }) => ({
  border: `solid 1px ${theme.palette.grey[300]}`,
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: "6px",
}))

export function CaseEditorField<T extends FieldValues & CaseTemplatedSections>({
  variables: customVariables = [],
  section,
  invalidateQueryKeys = [],
  includeAskAI = false,
  caseId = undefined,
  generatorKeys = [],
  displayEmptyState = false,
  ...props
}: CaseEditorFieldProps<T> & {
  section?: CASE_SECTIONS
  includeAskAI?: boolean
  caseId?: PrimaryKey
} & CaseInvalidationProps): JSX.Element {
  const [openAskAi, setOpenAskAi] = useState(false)
  const { variables } = useCaseVariables(customVariables)
  const { showErrorMessage } = useHandleMessages()
  const { request } = useFormContext()

  const sectionTemplate = useWatch({
    control: props.control,
    name: `sections.${section}` as Path<T>,
    exact: true,
    disabled: !section,
  })

  const sectionTemplateRef = useRef(sectionTemplate)
  sectionTemplateRef.current = sectionTemplate

  const queryClient = useQueryClient()
  const { mutate: overrideCaseSection, isLoading } = useMutation(caseService.overrideCaseSection, {
    onSuccess: () => {
      for (const queryKey of invalidateQueryKeys) {
        queryClient.invalidateQueries(queryKey)
      }
    },
    onError: error => {
      showErrorMessage({
        message: "There was an error while trying to update content.",
        error,
      })
    },
  })

  const toggleAskAi = useCallback(() => {
    setOpenAskAi(openAskAi => !openAskAi)

    if (caseId && request) {
      amplitudeApm.trackEvent(
        new DemandAnalyticEvent(DemandAnalyticsEventTypes.AskAIClicked, {
          demand_id: `${caseId}`,
          request_id: `${request?.pk}`,
          request_type: request?.type,
        })
      )
    }
  }, [caseId, request])

  const fetchingQueriesCount = useIsFetching({
    predicate: query => !query.queryKey.includes(queryKeys.generators),
  })
  const isFetching =
    isLoading ||
    !!(fetchingQueriesCount && invalidateQueryKeys.some(queryKey => queryClient.isFetching({ queryKey })))

  const handleContentResolve = useCallback(() => {
    if (!section || !sectionTemplateRef.current) return

    const templatedSection = sectionTemplateRef.current as CaseTemplatedSection

    overrideCaseSection({ caseId: templatedSection.caseId, data: templatedSection })
  }, [section, overrideCaseSection])

  const customControls = useMemo(
    () => (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }} ml="auto">
        {isFetching && <CircularProgress size="24px" />}
        {includeAskAI && <AskAIButton onClick={toggleAskAi} />}
      </Box>
    ),
    [includeAskAI, isFetching, toggleAskAi]
  )

  if (section && sectionTemplate) {
    const templatedSection = sectionTemplate as CaseTemplatedSection
    const isReviewNeeded = templatedSection.userActionRequired
    const template = templatedSection.template
    const isInReview = isReviewNeeded && template

    return (
      <>
        <RichTextField<T>
          {...props}
          withVariables
          variables={variables}
          name={`sections.${section}.content` as RichTextFieldProps<T>["name"]}
          error={isReviewNeeded}
          actions={
            isReviewNeeded ? (
              <Button variant="contained" disableElevation size="small" onClick={handleContentResolve}>
                Resolve
              </Button>
            ) : null
          }
          customControls={customControls}
          displayEmptyState={displayEmptyState}
        />

        {includeAskAI && <AskAISection open={openAskAi} caseId={caseId} onClose={toggleAskAi} outlined />}

        {isInReview &&
          (isEqual(template, DEFAULT_VALUE) ? (
            <StyledNoTemplate>No template is returned</StyledNoTemplate>
          ) : (
            <TemplatePreview template={template.children as EditorRoot} variables={variables} />
          ))}
      </>
    )
  }

  return (
    <>
      <RichTextField<T>
        {...props}
        customControls={customControls}
        displayEmptyState={displayEmptyState}
        withVariables
        variables={variables}
      />

      {includeAskAI && (
        <AskAISection
          open={openAskAi}
          caseId={caseId}
          onClose={toggleAskAi}
          generatorKeys={generatorKeys}
          outlined
        />
      )}
    </>
  )
}

interface AskAiSectionProps {
  open: boolean
  onClose: () => void
  caseId: number | undefined
  defaultText?: string
  fieldDefaults?: Map<string, string>
  warningBannerText?: string
  generatorKeys?: string[]
  outlined?: boolean
  additionalInfo?: React.ReactNode
}
export const AskAISection = memo(function AskAISection({
  open,
  caseId,
  defaultText,
  fieldDefaults,
  warningBannerText,
  generatorKeys,
  onClose,
  outlined = false,
  additionalInfo,
}: AskAiSectionProps) {
  return (
    <Collapse in={open}>
      {outlined ? (
        <BorderedBox>
          <SummaryForm
            caseId={caseId}
            onClose={onClose}
            defaultText={defaultText}
            fieldDefaults={fieldDefaults}
            warningBannerText={warningBannerText}
            generatorKeys={generatorKeys}
            showFeedback={true}
            additionalInfo={additionalInfo}
          />
        </BorderedBox>
      ) : (
        <SummaryForm
          caseId={caseId}
          onClose={onClose}
          defaultText={defaultText}
          fieldDefaults={fieldDefaults}
          warningBannerText={warningBannerText}
          generatorKeys={generatorKeys}
          showFeedback={true}
          additionalInfo={additionalInfo}
        />
      )}
    </Collapse>
  )
})

export function ReadOnlyCaseEditor({
  variables: customVariables = [],
  ...props
}: CaseEditorProps): JSX.Element {
  const { variables } = useCaseVariables(customVariables)

  return <RichTextEditor {...props} readonly withVariables variables={variables} />
}

const DEFAULT_GENERATOR_KEYS: string[] = []

export function CaseEditorInput({
  variables: customVariables = [],
  includeAskAI = false,
  caseId = undefined,
  askAiWarningBannerText = undefined,
  generatorKeys = DEFAULT_GENERATOR_KEYS,
  ...props
}: CaseEditorInputProps): JSX.Element {
  const [openAskAi, setOpenAskAi] = useState(false)
  const { variables } = useCaseVariables(customVariables)

  const toggleAskAi = useCallback(() => {
    setOpenAskAi(!openAskAi)
  }, [setOpenAskAi, openAskAi])

  const customControls = useMemo(() => {
    return (
      <>
        {includeAskAI && (
          <Box display={"flex"} ml="auto">
            <AskAIButton onClick={toggleAskAi} />
          </Box>
        )}
      </>
    )
  }, [includeAskAI, toggleAskAi])

  return (
    <>
      <RichTextInput {...props} customControls={customControls} withVariables variables={variables} />
      {includeAskAI && !props.disabled && (
        <AskAISection
          open={openAskAi}
          caseId={caseId}
          onClose={toggleAskAi}
          warningBannerText={askAiWarningBannerText}
          generatorKeys={generatorKeys}
          outlined
        />
      )}
    </>
  )
}
