import { User } from "common/models/user"
import { INTAKE_STATUSES } from "requests/constants"
import { REVISION_REQUEST_INFO, REVISION_REQUEST_STATE } from "./constants"
import { RevisionRequestStateMessage } from "./types"

export function getRevisionRequestStateByIntakeStatus(
  hasActiveRevisionRequest: boolean,
  intakeStatus: INTAKE_STATUSES
): REVISION_REQUEST_STATE {
  if (!hasActiveRevisionRequest) {
    return REVISION_REQUEST_STATE.NOT_REQUESTED
  }

  switch (intakeStatus) {
    case INTAKE_STATUSES.notRequested:
      return REVISION_REQUEST_STATE.NOT_REQUESTED
    case INTAKE_STATUSES.rework:
    case INTAKE_STATUSES.requested:
      return REVISION_REQUEST_STATE.REQUESTED
    case INTAKE_STATUSES.completed:
    case INTAKE_STATUSES.redelivered:
      return REVISION_REQUEST_STATE.COMPLETED
    default:
      return REVISION_REQUEST_STATE.IN_PROGRESS
  }
}

export function getRevisionRequestInfo(state: REVISION_REQUEST_STATE, user: User): Nullable<string> {
  if (state !== REVISION_REQUEST_STATE.NOT_REQUESTED) {
    return null
  }

  if (user.isExternal) {
    return REVISION_REQUEST_INFO.EXTERNAL
  }

  if (user.isInternal) {
    return REVISION_REQUEST_INFO.INTERNAL
  }

  return null
}

export function getEventMessageDetails(
  eventMessage: RevisionRequestStateMessage,
  condition: boolean
): Omit<RevisionRequestStateMessage, "conditionalMessage"> {
  const { title, message, conditionalMessage } = eventMessage
  const fullMessage = condition && conditionalMessage ? `${message} ${conditionalMessage}` : message

  return {
    title,
    message: fullMessage,
  }
}
