import { useState, useCallback } from "react"
import { Link as RouterLink } from "react-router-dom"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import DeleteIcon from "@mui/icons-material/Delete"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { makeStyles } from "tss-react/mui"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

import useUser from "../../hooks/useUser"

import { queryKeys } from "../../react-query/constants"
import { getFirms, deleteFirm } from "../../api"
import { canRoleDeleteFirm } from "./permissions"
import { GenericError, Loading } from "../../common"
import { useSearchState } from "../../hooks/useSearchState"
import { Pagination } from "common/pagination"
import { PageSizeSelect } from "common/pagination/PageSizeSelect"
import { INTERNAL_ROLES } from "common/models/roles"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"
import { StyledPageSizeWrapper } from "../Library/Tabs/styled"
import { SettingsPageControls } from "settings/common/SettingsPageControls"
import { SettingsPageAddButton } from "settings/common/SettingsPageAddButton"
import { getSettingsPageOptions } from "settings/utils"
import { SETTINGS_ROUTE_PREFIX } from "settings/types"
import { useTheme } from "@mui/material"

const useStyles = makeStyles()(theme => ({
  alignRight: {
    display: "flex",
    justifyContent: "right",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "left",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  flexBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}))

const PaginationHelper = ({ handlePageChange, currentPage, data, pageSize, handlePageSizeChange }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.actionContainer}>
      <Pagination
        onChange={handlePageChange}
        page={currentPage}
        pageCount={Math.ceil(data.count / pageSize)}
      />
      {/* Dropdown menu for selecting the page size */}
      <StyledPageSizeWrapper className={classes.alignRight}>
        <PageSizeSelect pageSize={pageSize} onChange={handlePageSizeChange} />
      </StyledPageSizeWrapper>
    </Box>
  )
}

export function FirmList() {
  const queryClient = useQueryClient()
  const { classes } = useStyles()
  const theme = useTheme()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [searchQuery, setSearchQuery] = useSearchState("query", "", "string")
  const { isLoading, isFetching, data, error } = useQuery({
    queryKey: [
      queryKeys.firms,
      {
        currentPage,
        pageSize,
        searchQuery,
      },
    ],
    queryFn: () => getFirms({ page: currentPage, pageSize: pageSize, searchQuery: searchQuery }),
    meta: { disableLoader: true },
  })
  const { user } = useUser()

  const { mutate: handleDelete } = useMutation(deleteFirm, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.firms]),
  })

  function buildConfirmMessage(firmName, numberOfCases, numberOfAttorneys) {
    return `
      Are you sure you want to delete the firm, ${firmName}.

      You would also be deleting ${numberOfCases} cases(s) and ${numberOfAttorneys} attorney(s).
    `
  }

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  const handleSearchQueryChange = e => {
    handlePageChange(1)
    // don't replace history when value is empty
    // this creates a distinct entry when clearing input
    // so you can use back button to return to input before clearing
    setSearchQuery(e.target.value, { replace: e.target.value ? true : false })
  }

  const handlePageSizeChange = useCallback(pageSize => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }, [])

  if (error) return <GenericError error={error} />

  const canDeleteFirm = canRoleDeleteFirm(user.role)
  const canAddFirm = user.isRole(INTERNAL_ROLES.LEGALOPS_ADMIN)
  const { pageTitle } = getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.FIRMS, user.role)

  return (
    <Box>
      <Typography marginBottom={theme.spacing(3)} variant="h4" component="h1">
        {pageTitle}
      </Typography>
      <SettingsPageControls onSearchQueryChange={handleSearchQueryChange} searchQuery={searchQuery}>
        {canAddFirm && (
          <SettingsPageAddButton
            linkTo="/settings/firms/new"
            buttonText="Add Firm"
            data-test="add-new-firm-button"
          />
        )}
      </SettingsPageControls>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* This is where we render the items */}
          {!isLoading &&
            !isFetching &&
            data.results.map(row => (
              <TableRow key={row.pk}>
                <TableCell>
                  <RouterLink data-test="link-to-firm" to={"/settings/firms/" + row.pk}>
                    {row.name}
                  </RouterLink>
                </TableCell>
                <TableCell align="right">
                  <IconButton component={RouterLink} to={"/settings/firms/" + row.pk}>
                    <VisibilityIcon color="primary" />
                  </IconButton>
                  {canDeleteFirm && (
                    <IconButton
                      data-test="delete-firm-button"
                      onClick={() => {
                        if (
                          confirm(buildConfirmMessage(row.name, row.number_of_cases, row.number_of_attorneys))
                        ) {
                          handleDelete(row.pk)
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {(!data || isFetching || isLoading) && (
        <div className={classes.flexBox} data-test="firms-loading">
          <Loading show inline disableBackdrop label="" />
        </div>
      )}
      {/* Page bar to move between pages */}
      {!isLoading && data.count > 0 && (
        <PaginationHelper
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          data={data}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
        />
      )}
    </Box>
  )
}
