import { ComponentType, memo } from "react"
import { FileUploadProvider, FileUploaderProviderProps } from "./FileUploadProvider"

export function withFileUploadProvider<P = BaseObject>(
  Component: ComponentType<P>,
  providerProps: FileUploaderProviderProps = {}
): ComponentType<P & JSX.IntrinsicAttributes> {
  return memo(function WithFileUploadProvider(props) {
    return (
      <FileUploadProvider {...providerProps}>
        <Component {...props} />
      </FileUploadProvider>
    )
  })
}
