import { Editor, Path, Transforms } from "slate"
import { createListItemContent, createListItem, createList } from "../../create"
import { CustomEditor, ParagraphElement } from "../../CustomEditor"
import { LIST_BLOCK_ELEMENTS, LEAF_BLOCK_ELEMENTS } from "../../elements"
import { getBlocks } from "../blocks"
import { closestListNode } from "./queries"
import { toggleListNodeType } from "./toggleListNodeType"

export function wrapInList(editor: CustomEditor, at: Path, type: LIST_BLOCK_ELEMENTS): void {
  Editor.withoutNormalizing(editor, () => {
    Transforms.wrapNodes(editor, createListItemContent(), { at })
    Transforms.wrapNodes(editor, createListItem(), { at })
    Transforms.wrapNodes(editor, createList(undefined, type), { at })
  })
}

export function convertToList(editor: CustomEditor, type: LIST_BLOCK_ELEMENTS): void {
  if (!editor.selection) return

  Editor.withoutNormalizing(editor, () => {
    getBlocks<ParagraphElement>(editor, LEAF_BLOCK_ELEMENTS.PARAGRAPH).forEach(([, path]) => {
      const listNodeEntry = closestListNode(editor, path)

      if (listNodeEntry) {
        const [, listNodePath] = listNodeEntry
        return toggleListNodeType(editor, listNodePath, type)
      }

      return wrapInList(editor, path, type)
    })
  })
}
