import { useState, useCallback } from "react"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"

import VerifiedUser from "@mui/icons-material/VerifiedUser"
import Warning from "@mui/icons-material/Warning"
import DeleteIcon from "@mui/icons-material/Delete"

import { Link } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { makeStyles } from "tss-react/mui"

import { getUsers, deleteUser } from "api"
import { queryKeys } from "react-query/constants"

import useUser from "hooks/useUser"

import { StyledPageSizeWrapper } from "../Library/Tabs/styled"
import { GenericError } from "common"
import ConfirmDialog from "common/ConfirmDialog"
import { useSearchState } from "hooks/useSearchState"
import { Pagination } from "common/pagination"
import { PageSizeSelect } from "common/pagination/PageSizeSelect"
import { EXTERNAL_ROLES, INTERNAL_ROLES, USER_ROLES } from "common/models/roles"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"
import { IconButton, useTheme } from "@mui/material"
import { useDebounce } from "use-debounce"
import { getSettingsPageOptions } from "settings/utils"
import { SETTINGS_ROUTE_PREFIX } from "settings/types"
import { SettingsPageControls } from "settings/common/SettingsPageControls"
import { SettingsPageAddButton } from "settings/common/SettingsPageAddButton"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { ImpersontateButton } from "impersonation/ImpersontateButton"
import { usePermissions } from "permissions/usePermissions"

const useStyles = makeStyles()(theme => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  deleteIcon: {
    margin: "auto",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(10),
  },
  alignRight: {
    display: "flex",
    justifyContent: "right",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "left",
  },
  verticalCenterChildren: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  addButton: {
    background: theme.palette.blue.primary,
    textTransform: "none",
    fontSize: "15px",
  },
}))

const PaginationHelper = ({ handlePageChange, currentPage, data, pageSize, handlePageSizeChange }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.actionContainer}>
      <Pagination
        onChange={handlePageChange}
        page={currentPage}
        pageCount={Math.ceil(data.count / pageSize)}
      />
      {/* Dropdown menu for selecting the page size */}
      <StyledPageSizeWrapper className={classes.alignRight}>
        <PageSizeSelect pageSize={pageSize} onChange={handlePageSizeChange} />
      </StyledPageSizeWrapper>
    </Box>
  )
}

export function UserTable({ users, invalidateUsers }) {
  const { user: authedUser } = useUser()
  const { classes } = useStyles()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [currentUserId, setCurrentUserId] = useState(null)
  const { showSuccessMessage, showErrorMessage } = useHandleMessages()
  const { canImpersonateUsersEnabled } = usePermissions()

  const deleteUserMutation = useMutation(deleteUser, {
    onSuccess: () => {
      showSuccessMessage({
        message: "User has been deleted",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      })
      invalidateUsers()
    },
    onError: error => {
      showErrorMessage({
        message: "Error deleting user. Please contact your system administrator.",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        error,
      })
    },
  })

  const userCanDeleteThisUser = userRole => {
    switch (authedUser.role) {
      case INTERNAL_ROLES.LEGALOPS_ADMIN:
        return true
      case EXTERNAL_ROLES.FIRM_ADMIN:
        return true
      case EXTERNAL_ROLES.OFFICE_ADMIN:
        return ![EXTERNAL_ROLES.FIRM_ADMIN, EXTERNAL_ROLES.OFFICE_ADMIN].includes(userRole)
      default:
        return false
    }
  }

  const canDelete = [
    INTERNAL_ROLES.LEGALOPS_ADMIN,
    EXTERNAL_ROLES.FIRM_ADMIN,
    EXTERNAL_ROLES.OFFICE_ADMIN,
  ].includes(authedUser.role)

  const handleDeleteConfirmation = () => {
    deleteUserMutation.mutate({
      userId: currentUserId,
    })

    handleCloseDeleteDialog()
  }

  const handleCloseDeleteDialog = () => {
    setShowDeleteDialog(false)
    setCurrentUserId(null)
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell data-test="name-cell">Name</TableCell>
            {authedUser.isInternal && <TableCell data-test="firm-cell">Firm</TableCell>}
            <TableCell data-test="email-cell">Email</TableCell>
            <TableCell data-test="role-cell">Role</TableCell>
            {authedUser.isInternal && <TableCell data-test="stytch-status-cell">Can Login?</TableCell>}
            {canDelete && <TableCell data-test="delete-cell"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => {
            const isAuthedUser = authedUser.id === user.pk
            const userCanDeleteThisUserPermission = userCanDeleteThisUser(user.role)
            return (
              <TableRow key={user.pk} data-test={`user-row-${user.pk}`}>
                <TableCell data-test={`user-name-cell`}>
                  <Link to={`/settings/accounts/${user.pk}`}>
                    {user.first_name} {user.last_name}
                  </Link>
                </TableCell>
                {authedUser.isInternal && (
                  <TableCell>
                    {user?.firm ? (
                      <Link to={`/settings/firms/${user.firm?.pk}`}>{user?.firm?.name}</Link>
                    ) : (
                      user?.firm?.name
                    )}
                  </TableCell>
                )}
                <TableCell>
                  <Link to={`/settings/accounts/${user.pk}`}>{user.email}</Link>
                </TableCell>
                <TableCell>{USER_ROLES[user.role].display}</TableCell>
                {authedUser.isInternal && (
                  <TableCell>
                    {user.stytch_user_id ? (
                      <VerifiedUser color="success" fontSize="small" />
                    ) : (
                      <Warning color="secondary" fontSize="small" />
                    )}
                  </TableCell>
                )}
                {canImpersonateUsersEnabled && (
                  <TableCell>
                    <ImpersontateButton target={user} />
                  </TableCell>
                )}
                {userCanDeleteThisUserPermission && !isAuthedUser ? (
                  <TableCell data-test="user-delete-cell">
                    <IconButton
                      onClick={() => {
                        setShowDeleteDialog(true)
                        setCurrentUserId(user.pk)
                      }}
                    >
                      <DeleteIcon className={classes.deleteIcon} />
                    </IconButton>
                  </TableCell>
                ) : (
                  <TableCell></TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <ConfirmDialog
        open={showDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteConfirmation}
        title={"Are you sure?"}
        body={
          "Are you sure you want to delete this user? Once confirmed, this user will no longer be able to login to EvenUp Law."
        }
      />
    </>
  )
}

export function UserAccounts() {
  const { classes } = useStyles()
  const theme = useTheme()
  const { user: authedUser } = useUser()
  const queryClient = useQueryClient()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [searchQuery, setSearchQuery] = useSearchState("query", "", "string")
  const [debouncedSearchQuery] = useDebounce(searchQuery, 250)
  const { isLoading, isFetching, data, error } = useQuery({
    queryKey: [
      queryKeys.users,
      {
        currentPage,
        pageSize,
        searchQuery: debouncedSearchQuery,
      },
    ],
    queryFn: () => getUsers({ page: currentPage, pageSize: pageSize, searchQuery: debouncedSearchQuery }),
    meta: { disableLoader: true },
  })
  const invalidateUsers = () => {
    queryClient.invalidateQueries([queryKeys.users])
  }
  const handlePageChange = page => {
    setCurrentPage(page)
  }
  const handleSearchQueryChange = e => {
    handlePageChange(1)
    // don't replace history when value is empty
    // this creates a distinct entry when clearing input
    // so you can use back button to return to input before clearing
    setSearchQuery(e.target.value, { replace: e.target.value ? true : false })
  }
  const showAddUser = ![INTERNAL_ROLES.LEGALOPS, EXTERNAL_ROLES.SUPPORT].includes(authedUser.role)

  const handlePageSizeChange = useCallback(pageSize => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }, [])

  if (error) return <GenericError error={error} />

  const { pageTitle } = getSettingsPageOptions(SETTINGS_ROUTE_PREFIX.ACCOUNTS, authedUser.role)

  return (
    <Box>
      <Typography marginBottom={theme.spacing(3)} variant="h4" component="h1">
        {pageTitle}
      </Typography>
      <SettingsPageControls
        onSearchQueryChange={handleSearchQueryChange}
        searchQuery={searchQuery}
        data-test="search-users"
      >
        {showAddUser && (
          <SettingsPageAddButton
            linkTo="/settings/accounts/new"
            buttonText="Add User"
            data-test="add-new-user-button"
          />
        )}
      </SettingsPageControls>
      {!isLoading && !isFetching && <UserTable users={data.results} invalidateUsers={invalidateUsers} />}
      {(isFetching || isLoading) && (
        <div className={classes.alignCenter}>{<CircularProgress color="secondary" />}</div>
      )}
      {/* Page bar to move between pages */}
      {!isLoading && data.count > 0 && (
        <PaginationHelper
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          data={data}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
        />
      )}
    </Box>
  )
}
