import styled from "@emotion/styled"
import { DocumentStructureSection } from "./types"
import { SortableNestedListItem } from "common/nested-list/SortableNestedListItem"
import { SelectInput } from "common/form-components"

export const StyledBlockListItem = styled(SortableNestedListItem<DocumentStructureSection>)(({ theme }) => ({
  position: "relative",
  padding: 0,
  marginTop: theme.spacing(1),

  "& > [data-type='drop-indicator']": {
    "&:first-of-type": {
      transform: `translateY(${theme.spacing(-0.5)})`,
    },

    "&:last-of-type": {
      transform: `translateY(${theme.spacing(0.5)})`,
    },
  },

  "& [data-type='list-item-content']": {
    background: "transparent",

    "&:hover": {
      transition: "background 0.05s ease",
      background: theme.palette.grey.A200,
      padding: theme.spacing(1, 0, 1, 2),
      margin: theme.spacing(-1, -2),
      boxSizing: "border-box",
      width: `calc(100% + ${theme.spacing(2)})`,
    },
  },

  "& [data-type='list-item']": {
    margin: 0,
    paddingBottom: 0,
  },

  "& [data-type='list-item-children']": {
    paddingTop: theme.spacing(1),
  },

  "& [data-type='list-item'] [data-type='empty-placeholder']": {
    position: "absolute",
    left: theme.spacing(-1),
    right: theme.spacing(-1),
    top: 0,
    bottom: theme.spacing(-1),
    zIndex: -1,
    visibility: "hidden",
    opacity: 0,
    minHeight: "auto",
    marginTop: 0,

    "&[data-is-over='true']": {
      zIndex: 100,
      visibility: "visible",
      opacity: 0.5,
    },
  },
}))

export const StyledListContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
}))

export const StyledTemplatesWrapper = styled("div")(({ theme }) => ({
  border: "1px solid #E0E0E0",
  background: "#F6F6F6",
  height: "50px",
  padding: theme.spacing(0, 4),
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}))

export const StyledBlockSelect = styled(SelectInput)(({ theme }) => ({
  "& .MuiSelect-select": {
    fontSize: "14px",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    minWidth: "140px",
  },
  "> label": {
    fontSize: "14px",
    top: theme.spacing(-0.5),
  },
})) as any as typeof SelectInput // eslint-disable-line @typescript-eslint/no-explicit-any

export const StyledDeleteButtonWrapper = styled("div")(({ theme }) => ({
  marginLeft: "auto",
  marginRight: theme.spacing(1),
}))

export const StyledTemplateType = styled("div")(({ theme }) => ({
  flexGrow: 1,
  display: "inline-block",
  marginLeft: theme.spacing(2),
  textTransform: "capitalize",
}))

export const StyledNoTemlates = styled("div")(() => ({
  display: "inline-block",
  fontStyle: "italic",
}))

export const StyledTableWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(8),
}))

export const StyledBlockTitle = styled("b")(({ theme }) => ({
  fontSize: "14px",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(1),
}))

export const StyledBlockWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  gap: theme.spacing(1),
}))

export const StyledAutocompleteWrapper = styled("div")(() => ({
  display: "inline-block",
}))
