import { ReactNode, useCallback } from "react"
import { useDocumentStore } from "documents/store"
import { useQuery } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { documentsService } from "api/services/documents"
import { formatDate } from "utils"
import GetAppIcon from "@mui/icons-material/GetApp"
import { Box, Button, Skeleton } from "@mui/material"
import { theme } from "app/theme"
import { StyledDownload, StyledText, StyledVerticalDivider } from "./styled"
import { DOCUMENT_GENERATION_STATUS } from "api/services/documents/types"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"
import { useHandleMessages } from "common/messages/useHandleMessages"

export const BasicInfo = (): ReactNode => {
  const documentId = useDocumentStore(state => state.documentId)
  const plaintiff = useDocumentStore(state => state.plaintiff)
  const requestId = useDocumentStore(state => state.questionnaireId)
  const { showMessages } = useHandleMessages()

  const { data: documentGenerations } = useQuery(
    [queryKeys.documentGenerations, documentId],
    () => documentsService.getGenerationList({ documentId }),
    {
      meta: SILENT_QUERY_PARAMS.meta,
      enabled: !!documentId,
    }
  )

  const { data: medchronTile } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => {
      if (documentId) {
        return documentsService.getMedchronTile({ documentId: documentId })
      }
    },
    {
      enabled: !!documentId,
    }
  )

  const handleSendAnalytic = useCallback(() => {
    if (!medchronTile) return

    amplitudeApm.trackEvent(
      new MedicalChronologyAnalyticEvent(
        MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyPDFDownloaded,
        {
          document_id: documentId,
          request_id: requestId,
          documentType: medchronTile.questionnaireType,
        }
      )
    )
  }, [documentId, requestId, medchronTile])

  const handleShare = useCallback(() => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      showMessages({
        messages: [{ message: "URL is copied to clipboard", type: "success" }],
        append: true,
      })
    })

    if (!medchronTile) return

    amplitudeApm.trackEvent(
      new MedicalChronologyAnalyticEvent(
        MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyPDFShared,
        {
          document_id: documentId,
          request_id: requestId,
          documentType: medchronTile.questionnaireType,
        }
      )
    )
  }, [documentId, requestId, showMessages, medchronTile])

  if (!medchronTile || !documentGenerations)
    return <Skeleton variant="rectangular" height={50} animation={false} />

  const downloadURL = documentGenerations.find(
    ({ status }) => status === DOCUMENT_GENERATION_STATUS.COMPLETE
  )?.downloadURL

  return (
    <Box display="flex" justifyContent="space-between" mb={4.5}>
      <Box display="flex" alignItems="center" gap={2.5}>
        <Box fontSize="24px" fontWeight={700}>
          {plaintiff}
        </Box>
        <StyledVerticalDivider />
        <Box display="flex" gap={0.5} flexDirection="column">
          <StyledText>Date Range of Treatment</StyledText>
          <b>
            {formatDate(medchronTile.startDate, "MM/dd/yyyy", true)} -{" "}
            {formatDate(medchronTile.endDate, "MM/dd/yyyy", true)}
          </b>
        </Box>
        <StyledVerticalDivider />
        <Box display="flex" gap={0.5} flexDirection="column">
          <StyledText>Total Visits</StyledText>
          <b>{medchronTile.numberOfAppointments}</b>
        </Box>
      </Box>
      <Box display="flex" gap={1.5} alignItems="center">
        <Button color="secondary" variant="outlined" onClick={handleShare}>
          Share
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<GetAppIcon />}
          sx={{ background: theme.palette.blue.primary }}
          disabled={!downloadURL}
          onClick={handleSendAnalytic}
        >
          {downloadURL && <StyledDownload download href={downloadURL} />}
          Download
        </Button>
      </Box>
    </Box>
  )
}
