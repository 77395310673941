import Accordion from "@mui/material/Accordion"
import Typography from "@mui/material/Typography"
import styled from "@emotion/styled"

export const AttributesGroup = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  marginTop: theme.spacing(3),

  "&:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  display: "flex",
  gap: theme.spacing(2),
}))

export const AttributeTitle = styled("div")({
  fontSize: "14px",
  fontWeight: 600,
})

export const AttributeField = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}))

export const TemplatesTitle = styled(Typography)({
  fontSize: "20px",
  lineHeight: "34px",
  fontWeight: "bold",
  letterSpacing: "-0.4px",
})
TemplatesTitle.defaultProps = {
  variant: "h2",
}

export const TemplatesWrapper = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(4),
  marginTop: theme.spacing(5),
  borderTop: `1px solid ${theme.palette.divider}`,
}))

export const TemplatesContainer = styled("div")(({ theme }) => ({
  position: "relative",
  minHeight: "70px",
  marginTop: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  "&:before": {
    display: "none",
  },
}))

export const TemplateRowItem = styled("div")(({ theme }) => ({
  padding: theme.spacing(1.5, 0.5),
  fontSize: "14px",
  fontWeight: "bold",
  lineHeight: "21px",
  textTransform: "capitalize",
}))
