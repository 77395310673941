import { Element, Text } from "slate"
import { DRAFT_NODE_PROPERTY_NAME } from "./constants"
import {
  CitationElement,
  EmptySpaceElement,
  ListElement,
  ListItemContentElement,
  ListItemElement,
  ParagraphElement,
  SoftLineBreak,
  VariableElement,
} from "./CustomEditor"
import {
  INLINE_ELEMENTS,
  LEAF_BLOCK_ELEMENTS,
  LIST_BLOCK_ELEMENTS,
  LIST_ITEM_BLOCK_ELEMENTS,
} from "./elements"
import { Citation } from "./features/citations/types"

export const createText = (options?: Partial<Text>): Text => {
  if (!options) {
    return { text: "" }
  }

  return {
    text: "",
    ...options,
  }
}

type BlockCreator<T extends Element, K extends unknown[] = []> = (children?: T["children"], ...args: K) => T

export const createParagraph: BlockCreator<ParagraphElement> = children => {
  return {
    type: LEAF_BLOCK_ELEMENTS.PARAGRAPH,
    children: children ? children : [createText()],
  }
}

export const createListItemContent: BlockCreator<ListItemContentElement> = children => {
  return {
    type: LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM_CONTENT,
    children: children ? children : [createParagraph()],
  }
}

export const createListItem: BlockCreator<ListItemElement> = children => {
  return {
    type: LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM,
    children: children ? children : [createListItemContent()],
  }
}

export function createEmptyListItem(): ListItemElement {
  return {
    type: LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM,
    children: [
      {
        type: LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM_CONTENT,
        children: [
          {
            type: LEAF_BLOCK_ELEMENTS.PARAGRAPH,
            children: [createText()],
          },
        ],
      },
    ],
  }
}

export const createList: BlockCreator<ListElement, [LIST_BLOCK_ELEMENTS]> = (
  children,
  type: LIST_BLOCK_ELEMENTS
) => {
  return {
    type,
    children: children ? children : [createListItem()],
  }
}

export const createSoftLineBreak = (): SoftLineBreak => {
  return { type: INLINE_ELEMENTS.SOFT_LINE_BREAK, children: [{ text: "" }] }
}

export const createEmptySpace = (): EmptySpaceElement => {
  return { type: INLINE_ELEMENTS.EMPTY_SPACE, children: [{ text: "" }] }
}

export const createVariable = (name: string, isDraft = false): VariableElement => {
  const variable: VariableElement = { type: INLINE_ELEMENTS.VARIABLE, children: [{ text: "" }], name }

  if (isDraft) {
    Object.defineProperty(variable, DRAFT_NODE_PROPERTY_NAME, {
      enumerable: false,
      value: true,
    })
  }

  return variable
}

export const createCitation = ({ exhibit, pages }: Citation): CitationElement => {
  const citation: CitationElement = {
    type: INLINE_ELEMENTS.CITATION,
    children: [{ text: "" }],
    exhibit,
    pages,
  }

  return citation
}
