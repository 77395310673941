import { CaseInfo } from "api/services/case/types"
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { FORM_SECTION_ROUTES } from "./constants"
import { CaseStepsContext } from "./context"
import { CASE_STEP } from "./enums"
import { getCurrentCaseSection } from "./getCaseSection"
import {
  CASE_STEP_STATUS,
  CaseStepConfig,
  CASE_SECTIONS,
  CaseStepItem,
  CaseSteps,
  CASE_ATTRIBUTES_STEP,
  CaseStepInfo,
  ALL_CASE_STEPS,
} from "./types"
import { useSkipDemandSteps } from "hooks/useSkipDemandSteps"
import useCase from "hooks/useCase"

export interface DemandStepsProviderProps {
  caseInfo: CaseInfo
  statuses: CaseStepInfo[]
  steps: CaseStepConfig[]
  section: CASE_SECTIONS
  doCurrentStepNavigation?: boolean
}

export const DemandStepsProvider: React.FC<PropsWithChildren<DemandStepsProviderProps>> = ({
  caseInfo,
  statuses,
  steps,
  section,
  children,
  doCurrentStepNavigation = false,
}) => {
  const navigate = useNavigate()
  const SKIPPED_STEPS_TO_FLAG = useSkipDemandSteps()
  const { caseObj } = useCase(caseInfo.pk)

  useEffect(() => {
    const currentStep = getCurrentCaseSection(section, caseInfo, statuses)
    if (currentStep !== section && doCurrentStepNavigation) {
      navigate(FORM_SECTION_ROUTES[currentStep])
    }
  }, [section, caseInfo, statuses, navigate, doCurrentStepNavigation])

  const items = useMemo<CaseStepItem[]>(
    () =>
      steps.map((stepConfig, index) => {
        const stepInfo: CaseStepInfo | undefined = statuses.find(({ step }) => step === stepConfig.key)

        const sectionsThatCanConflict = [
          CASE_STEP.FACTS.toString(),
          CASE_STEP.PROVIDERS.toString(),
          CASE_STEP.INTRODUCTION.toString(),
          CASE_STEP.CONCLUSION.toString(),
          CASE_STEP.PAIN_AND_SUFFERING.toString(),
        ]

        const isReviewNeeded = stepInfo?.conflict && sectionsThatCanConflict.includes(stepConfig.key)

        return {
          step: stepConfig.key,
          title: stepConfig.display,
          subtitle: stepConfig.label,
          index,
          optional: Boolean(stepConfig.optional),
          isCompleted:
            stepConfig.key === CASE_ATTRIBUTES_STEP
              ? caseInfo.templatedSections.length > 0
              : stepInfo?.status === CASE_STEP_STATUS.COMPLETED,
          isSkipped: stepInfo?.status === CASE_STEP_STATUS.SKIPPED,
          isReviewNeeded,
          disabled: getCurrentCaseSection(stepConfig.key, caseInfo, statuses) !== stepConfig.key,
        }
      }),
    [steps, statuses, caseInfo]
  )

  const currentItem = useMemo(() => items.find(item => item.step === section) ?? null, [items, section])

  const nextItem = useMemo<Nullable<CaseStepItem>>((): Nullable<CaseStepItem> => {
    const nextStepItem = currentItem ? items[currentItem.index + 1] ?? null : null

    // If the next step is skipped, move down the line to the next step, step must be in SKIPPED_STEPS_TO_FLAG
    if (
      nextStepItem?.step &&
      nextStepItem?.step in SKIPPED_STEPS_TO_FLAG &&
      (!caseObj?.document_id || SKIPPED_STEPS_TO_FLAG[nextStepItem.step] === false)
    ) {
      return currentItem ? items[currentItem.index + 2] ?? null : null
    }
    return nextStepItem
  }, [items, currentItem, SKIPPED_STEPS_TO_FLAG, caseObj])

  const getStepStatus = useCallback(
    (step: ALL_CASE_STEPS) => statuses.find(({ step: stepWithStatus }) => stepWithStatus === step) ?? null,
    [statuses]
  )

  const context = useMemo<CaseSteps>(
    () => ({
      caseId: caseInfo.pk,
      items,
      section,
      getStepStatus,
      currentStep: currentItem,
      nextStep: nextItem,
    }),
    [items, currentItem, nextItem, section, caseInfo, getStepStatus]
  )

  return <CaseStepsContext.Provider value={context}>{children}</CaseStepsContext.Provider>
}
