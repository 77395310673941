import { useCallback, useEffect, useRef } from "react"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  ApplicationPerformanceAnalyticEvent,
  ApplicationPerformanceAnalyticsEventTypes,
} from "infrastructure/apm/events/applicationPerformanceEvents"
import percentile from "percentile"
import { useDebouncedCallback } from "use-debounce"

export const useRichTextPerformance = () => {
  const symbolsRenderTimings = useRef<number[]>([])

  const sendDebouncedAnalytic = useDebouncedCallback(() => {
    if (!symbolsRenderTimings.current.length) return

    amplitudeApm.trackEvent(
      new ApplicationPerformanceAnalyticEvent(
        ApplicationPerformanceAnalyticsEventTypes.RichTextSymbolRendered,
        {
          timeToRender50Pecentile: Number(percentile(50, symbolsRenderTimings.current)),
          timeToRender75Pecentile: Number(percentile(75, symbolsRenderTimings.current)),
          timeToRender90Pecentile: Number(percentile(90, symbolsRenderTimings.current)),
          timeToRender95Pecentile: Number(percentile(95, symbolsRenderTimings.current)),
          timeToRender99dot99Pecentile: Number(percentile(99.99, symbolsRenderTimings.current)),
        }
      )
    )

    symbolsRenderTimings.current = []
  }, 2000)

  const sendAnalytic = useCallback(() => {
    sendDebouncedAnalytic()

    if (symbolsRenderTimings.current.length > 200) {
      sendDebouncedAnalytic.flush()
    }
  }, [sendDebouncedAnalytic])

  const track = useCallback(() => {
    const timeStart = performance.now()

    requestAnimationFrame(() => {
      requestAnimationFrame(timeEnd => {
        symbolsRenderTimings.current.push(timeEnd - timeStart)
        sendAnalytic()
      })
    })
  }, [sendAnalytic])

  useEffect(
    () => () => {
      sendDebouncedAnalytic.flush()
    },
    [sendDebouncedAnalytic]
  )

  return { track }
}
