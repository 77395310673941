import styled from "@emotion/styled"
import { Box, Theme } from "@mui/material"

export const PainAndSufferingContainer = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
}))

export const FieldBox = styled(Box)(({ theme, readOnly = false }: { theme: Theme; readOnly?: boolean }) => ({
  width: "100%",
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 1, 1, 1),
  marginTop: theme.spacing(1),
  "&:hover": {
    backgroundColor: readOnly ? theme.palette.blue.contentBoxHover : "inherit",
    cursor: readOnly ? "pointer" : "inherit",
    borderRadius: "4px",
  },
}))
