import { NodeEntry } from "slate"
import { CustomEditor } from "../../CustomEditor"
import { getNextSibling } from "../siblings"
import { mergeListWithPreviousSiblingList } from "./mergeListWithPreviousSiblingList"
import { normalizeListItem } from "./normalizeListItem"
import { normalizeListItemContent } from "./normalizeListItemContent"

export function normalizeListElement(editor: CustomEditor, entry: NodeEntry): boolean {
  const normalized = mergeListWithPreviousSiblingList(editor, entry)

  if (normalized) {
    return true
  }

  const [, path] = entry
  const nextSibling = getNextSibling(editor, path)

  if (!nextSibling) {
    return false
  }

  return mergeListWithPreviousSiblingList(editor, nextSibling)
}

export function normalizeList(editor: CustomEditor, entry: NodeEntry): boolean {
  return (
    normalizeListElement(editor, entry) ||
    normalizeListItem(editor, entry) ||
    normalizeListItemContent(editor, entry)
  )
}
