import { useCallback, useEffect, useState } from "react"
import { Box } from "@mui/material"
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags"
import { documentActions, documentStoreSelectors, useDocumentStore } from "documents/store"
import { DraggablePopover } from "evenup-ui/DraggablePopover"
import { formatDate } from "utils"
import { FlagRelation } from "./FlagRelation"
import { Flag } from "./types"

interface ReadOnlyFlagPopoverProps {
  currentFlagData: Partial<Flag>
  flagSelector: Nullable<string>
  onClose: () => void
}

export const ReadOnlyFlagPopover = ({ currentFlagData, flagSelector, onClose }: ReadOnlyFlagPopoverProps) => {
  const [flagElement, setFlagElement] = useState<Element | undefined | null>(undefined)
  const flags = useDocumentStore(documentStoreSelectors.flagsWithProviderAndDOS)
  const flagWithProviderAndDOS = flags.find(bib => bib.id === currentFlagData.id)

  const handleClosePopup = useCallback(() => {
    onClose()
  }, [onClose])

  const handleMouseEnter = useCallback(() => {
    if (currentFlagData.id) documentActions.setHoveredFlagId(currentFlagData.id)
  }, [currentFlagData.id])

  useEffect(() => {
    if (!flagSelector || !currentFlagData) return setFlagElement(undefined)

    setFlagElement(document.getElementById(flagSelector))
  }, [flagSelector, currentFlagData])

  useEffect(() => {
    return () => documentActions.setHoveredFlagId(null)
  }, [])

  return (
    <DraggablePopover
      open
      onClose={handleClosePopup}
      anchorEl={flagElement}
      onMouseEnter={handleMouseEnter}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      title="Flag"
      titleIcon={<EmojiFlagsIcon sx={{ fontSize: "30px", color: "#E53935" }} />}
    >
      <Box mb={3}>
        {flagWithProviderAndDOS && (
          <Box mb={2}>
            {formatDate(flagWithProviderAndDOS.dateOfService, "MM/dd/yyyy", true)} -{" "}
            {flagWithProviderAndDOS.providerName}
          </Box>
        )}
        {currentFlagData.category}
      </Box>
      {currentFlagData.quote && (
        <Box width="440px" mb={3}>
          {`“${currentFlagData.quote}”`}
        </Box>
      )}
      {currentFlagData.reason && (
        <Box width="440px" mb={1.5}>
          {currentFlagData.reason}
        </Box>
      )}
      <Box display="flex" gap={1}>
        {currentFlagData.relations?.map(relation => (
          <FlagRelation key={`${relation.partitionId}_${relation.page}`} relation={relation} />
        ))}
      </Box>
    </DraggablePopover>
  )
}
