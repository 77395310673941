import { useCallback, useState } from "react"
import Box from "@mui/material/Box"
import { RevisionRequest as RevisionRequestModel } from "common/models/revision-request"
import { EventMessage } from "requests/components/EventMessage"
import { canUserEditRevisionRequest } from "requests/permissions/requestAction"
import useUser from "hooks/useUser"
import { RevisionRequestEvent } from "common/models/revision-request-event"
import { CreateRevisionRequest } from "./CreateRevisionRequest"
import { REVISION_REQUEST_STATE, REVISION_REQUEST_STATE_MESSAGES } from "./constants"
import { UpdateRevisionRequest } from "./UpdateRevisionRequest"
import { getEventMessageDetails } from "./utils"
import { ActiveRevisionRequestEventDetails } from "./ActiveRevisionRequestEventDetails"

interface RevisionRequestProps {
  event?: RevisionRequestEvent
  state: REVISION_REQUEST_STATE
  requestId: string
  onChange: (revision: RevisionRequestModel) => void
  showEventMessage?: boolean
  isTrialing?: boolean
}

enum VIEW_STATE {
  NEW,
  VIEW,
  EDIT,
}

function getViewState(event: RevisionRequestEvent | undefined, state: REVISION_REQUEST_STATE): VIEW_STATE {
  if (!event || state === REVISION_REQUEST_STATE.NOT_REQUESTED) {
    return VIEW_STATE.NEW
  }

  return VIEW_STATE.VIEW
}

export const RevisionRequest = ({
  requestId,
  event,
  state,
  onChange,
  showEventMessage,
  isTrialing = false,
}: RevisionRequestProps): JSX.Element => {
  const { user } = useUser()
  const [viewState, setViewState] = useState<VIEW_STATE>(getViewState(event, state))
  const onUpdated = useCallback(
    (request: RevisionRequestModel) => {
      onChange(request)
      setViewState(VIEW_STATE.VIEW)
    },
    [onChange]
  )
  const onEdit = useCallback(() => setViewState(VIEW_STATE.EDIT), [])
  const onCancel = useCallback(() => setViewState(VIEW_STATE.VIEW), [])

  if (viewState === VIEW_STATE.NEW || !event) {
    return <CreateRevisionRequest requestId={requestId} onCreated={onUpdated} />
  }

  const eventMessage = REVISION_REQUEST_STATE_MESSAGES[state]
  const editable = canUserEditRevisionRequest(user.role, state)

  if (viewState === VIEW_STATE.EDIT) {
    return (
      <UpdateRevisionRequest
        requestId={requestId}
        request={event.revisionRequest}
        onUpdated={onUpdated}
        onCancel={onCancel}
      />
    )
  }

  return (
    <Box>
      {showEventMessage && eventMessage && (
        <EventMessage {...getEventMessageDetails(eventMessage, !isTrialing)} />
      )}
      {editable ? (
        <ActiveRevisionRequestEventDetails event={event} editable onEdit={onEdit} />
      ) : (
        <ActiveRevisionRequestEventDetails event={event} />
      )}
    </Box>
  )
}
