import React, { useCallback } from "react"
import MuiTabs from "@mui/material/Tabs"
import MuiTab, { TabTypeMap } from "@mui/material/Tab"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import { TabContext, TabPanel as MuiTabPanel } from "@mui/lab"
import styled from "@emotion/styled"

const Tabs = styled(MuiTabs)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  "& .MuiTabs-scroller": {
    borderRadius: theme.shape.borderRadius,
  },
}))
Tabs.defaultProps = {
  indicatorColor: "secondary",
  textColor: "inherit",
}

const TabBox = styled(MuiTab)(({ theme }) => ({
  "&:not(:last-of-type)": {
    borderRightWidth: 0,
  },
  "&:first-of-type": {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  "&:last-child": {
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  textTransform: "none",
  border: "solid 1px #d9d9d9",
  "&.Mui-selected": {
    color: "white",
    zIndex: 1,
    boxShadow: "1px 0px 0px 0px black",
    height: "100%",
    backgroundColor: "black",
    transition: "color 0.7s",
    borderStyle: "none",
    borderWidth: 0,
  },
  "&.Mui-disabled": {
    border: "solid 1px grey",
    color: "grey",
  },
}))

export const Tab = ({ ...inputProps }: TabTypeMap["props"]): JSX.Element => {
  return <TabBox {...inputProps} />
}

export const TabPanel = styled(MuiTabPanel)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: 0,
}))

interface TabBoxProps {
  currentTab: string
  setCurrentTab: (tabName: string) => void
  tabs: React.ReactElement[]
  children: React.ReactChild
}

export const BlockTabs = ({ currentTab, setCurrentTab, tabs, children }: TabBoxProps): JSX.Element => {
  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, newValue: string) => {
      setCurrentTab(newValue)
    },
    [setCurrentTab]
  )

  return (
    <TabContext value={`${currentTab}`}>
      <Box display="flex" mb={2}>
        <Tabs value={currentTab} onChange={handleChange}>
          {tabs}
        </Tabs>
      </Box>
      <Divider />
      {children}
    </TabContext>
  )
}
