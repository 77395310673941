import React, { useCallback } from "react"
import { makeStyles } from "tss-react/mui"
import { ExhibitOrPartition, Partition } from "./Exhibit"
import Typography from "@mui/material/Typography"
import { usePublish } from "message-broker/usePublish"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { useParams } from "react-router-dom"
import splitFileName from "common/form-components/files/splitFileName"
import { PreviewLink } from "./styled"
import { getExhibitPreviewPath } from "pdf-preview/utils"
import { openWindow } from "common/windows"
import { PreviewExhibitPayload } from "message-broker/types"

const useStyles = makeStyles()(theme => ({
  itemTitle: {
    padding: theme.spacing(1),
  },
  strong: {
    padding: theme.spacing(1),
    color: theme.palette.secondary.dark,
  },
}))

interface ExhibitsListItemProps {
  item: ExhibitOrPartition
}

function isPartition(item: ExhibitOrPartition): item is Partition {
  return Boolean((item as Partition).exhibit_id)
}

export const ExhibitsListItem: React.FC<ExhibitsListItemProps> = ({ item }) => {
  const { id: caseId = "" } = useParams()
  const { classes } = useStyles()
  const title = `${item.name} ${
    item?.provider_name ? ` | Provider:${item.provider_name} | First Contact: ${item?.first_contact}` : ""
  }`

  const publish = usePublish()
  const openPreview = useCallback(() => {
    const payload: PreviewExhibitPayload = {
      caseId,
      exhibitId: isPartition(item) ? (item.exhibit_id as number) : item.pk,
      partitionId: isPartition(item) ? item.pk : undefined,
      fileName: item.name,
    }
    publish(MESSAGE_TOPIC.PREVIEW_EXHIBIT, payload).catch(() => {
      const path = getExhibitPreviewPath(payload)
      if (path) {
        openWindow(`${path.base}/${path.route}`)
      }
    })
  }, [caseId, item, publish])

  const [, extension] = splitFileName(item.name.split("|")[0].trim())
  const isPdf = extension.toLowerCase() === ".pdf"

  if (isPdf) {
    return (
      <PreviewLink onClick={openPreview}>
        <Typography className={classes.itemTitle}>{title}</Typography>
      </PreviewLink>
    )
  }

  return (
    <>
      <Typography className={classes.itemTitle}>{title}</Typography>
    </>
  )
}
