import { RevisionRequestEvent, RevisionRequestEventList } from "common/models/revision-request-event"
import { User } from "common/models/user"
import { BaseServiceDeserializer } from "api/BaseDeserializer"
import { RevisionRequestDeserializer } from "../revision/serializers"
import { RevisionRequestEventDto, RevisionRequestEventListDto } from "./types"

export class RevisionRequestEventDeserializer {
  static fromJSON(fields: RevisionRequestEventDto): RevisionRequestEvent {
    return new RevisionRequestEvent(
      fields.pk,
      fields.internal,
      fields.comment,
      BaseServiceDeserializer.fromDateTimeString(fields.created_at),
      RevisionRequestDeserializer.fromJSON(fields.revision_request),
      User.fromJSON(fields.creator)
    )
  }

  static fromListJSON({ data: items }: RevisionRequestEventListDto): RevisionRequestEventList {
    return new RevisionRequestEventList(items.map(RevisionRequestEventDeserializer.fromJSON))
  }
}
