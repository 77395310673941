import { Transforms } from "slate"
import { CustomEditor } from "../../CustomEditor"
import { getVariables } from "../../queries/variables"
import { TEXT_STYLES } from "../../styles"

export function addMarkAtVariables(editor: CustomEditor, style: TEXT_STYLES): void {
  if (!editor.selection) return

  const variableNodeEntries = getVariables(editor)

  for (const [, path] of variableNodeEntries) {
    Transforms.setNodes(editor, { [style]: true }, { at: path })
  }
}

export function removeMarkAtVariables(editor: CustomEditor, style: TEXT_STYLES): void {
  if (!editor.selection) return

  const variableNodeEntries = getVariables(editor)

  for (const [, path] of variableNodeEntries) {
    Transforms.setNodes(editor, { [style]: false }, { at: path })
  }
}
