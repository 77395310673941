import { getServiceWorker } from "./getServiceWorker"
import { CLAIM_SERVICE_WORKER_TOPIC } from "./topics"
import { Publish } from "./types"

export const publish: Publish = (topic, payload) => {
  return new Promise((resolve, reject) => {
    const channel = new MessageChannel()

    channel.port1.onmessageerror = reject
    channel.port1.onmessage = event => {
      if (event.data.success) {
        resolve({ success: event.data.success, payload: event.data.payload })
      } else {
        reject(event.data.error)
      }
    }

    Promise.race([getServiceWorker(), new Promise<null>(resolve => setTimeout(() => resolve(null), 300))])
      .then(serviceWorker => {
        if (!serviceWorker) {
          throw new Error("No service worker found.")
        }

        serviceWorker.postMessage({ topic: CLAIM_SERVICE_WORKER_TOPIC })

        setTimeout(() => {
          serviceWorker.postMessage({ topic, payload: { ...payload, clientUuid: CLIENT_UUID } }, [
            channel.port2,
          ])
        })
      })
      .catch(reason => reject(reason))
  })
}
