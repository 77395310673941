import { useCallback, useEffect, useMemo, useState } from "react"
import { LibraryUseForm } from "settings/Library/TemplateForms/types"
import { StyledListItemButton } from "settings/Library/LibraryTable/styled"
import { queryClient } from "react-query/queryClient"
import { useMutation, useQuery } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { variablesService } from "api/services/variables"
import { NonUniqueAttributesError } from "api/services/types"
import { providerSummariesApiService } from "api/services/summaries"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { NewProviderTemplate } from "common/types/providerTemplates"
import { providerTemplateService } from "api/services/provider-template"
import useUser from "hooks/useUser"
import { useConfirm } from "hooks/useConfirm"
import { canEditLibrary } from "settings/permissions"

import { ProviderTemplateRowData } from "common/models/library"
import { ProviderTemplateFormData as ProviderTemplateFormDataType } from "../types"
import { FORM_STATE_STATUS, getDeleteProviderTemplateDialogProps } from "../constants"
import { TemplateFormMessage } from "../TemplateFormMessage"
import { ProviderTemplateFormData } from "../formData/ProviderTemplateFormData"
import { StyledFooterWrapper } from "./styled"

export const useProviderTemplateForm: LibraryUseForm<ProviderTemplateRowData> = ({
  onFinish,
  rowData,
  formStatusMessageMap,
}) => {
  const [formStatus, setFormStatus] = useState<FORM_STATE_STATUS>(FORM_STATE_STATUS.IDLE)
  const [formData, setFormData] = useState<Nullable<ProviderTemplateFormDataType>>(null)
  const { user } = useUser()

  const { data: variables } = useQuery(
    [queryKeys.defaultVariables],
    variablesService.getDefaultCaseVariables,
    SILENT_QUERY_PARAMS
  )

  const { data: generatorKeys } = useQuery(
    [queryKeys.generatorKeys],
    providerSummariesApiService.getGeneratorTemplates,
    {
      retry: false,
    }
  )

  const onSuccess = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.SUCCESS)
  }, [])

  const onError = useCallback((error: unknown) => {
    if (error instanceof NonUniqueAttributesError) {
      setFormStatus(FORM_STATE_STATUS.DATA_ERROR)
    } else {
      setFormStatus(FORM_STATE_STATUS.API_ERROR)
    }
  }, [])

  const mutationOptions = useMemo(
    () => ({
      onSuccess: () => {
        onSuccess()
        onFinish()

        queryClient.invalidateQueries([queryKeys.providerTemplates])
      },
      onError: (error: unknown) => onError(error),
    }),
    [onSuccess, onFinish, onError]
  )

  const createTemplateMutation = useMutation(providerTemplateService.createProviderTemplate, mutationOptions)
  const updateTemplateMutation = useMutation(providerTemplateService.updateProviderTemplate, mutationOptions)
  const deleteTemplateMutation = useMutation(providerTemplateService.deleteProviderTemplate, mutationOptions)

  const clearForm = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.IDLE)
  }, [])

  const handleCancel = useCallback(() => {
    onFinish()
    clearForm()
  }, [onFinish, clearForm])

  const handleSubmit = useCallback(() => {
    if (!formData?.templateName) {
      return setFormStatus(FORM_STATE_STATUS.LACK_OF_DATA)
    }

    const data: NewProviderTemplate = {
      content: formData.content,
      templateName: formData.templateName,
      possibleGeneratorKeys: formData.possibleGeneratorKeys,
    }

    if (typeof rowData.id === "undefined") {
      createTemplateMutation.mutate({ data })
    } else {
      updateTemplateMutation.mutate({ options: { templateId: rowData.id }, data })
    }
  }, [createTemplateMutation, updateTemplateMutation, formData, rowData.id])

  const message = useMemo(() => {
    if (formStatus === FORM_STATE_STATUS.IDLE) return null

    const messageText = formStatusMessageMap[formStatus]

    if (!messageText) return null

    return <TemplateFormMessage clear={clearForm} message={messageText} formStatus={formStatus} />
  }, [clearForm, formStatus, formStatusMessageMap])

  const viewForm = useMemo(() => {
    return <RichTextEditor readonly value={rowData.initialContent} />
  }, [rowData])

  const editForm = useCallback(
    (footer: Nullable<JSX.Element>) => (
      <>
        <ProviderTemplateFormData
          initialContent={rowData.initialContent}
          initialTemplateName={rowData.initialTemplateName}
          initialGeneratorKey={rowData.initialGeneratorKey}
          initialPossibleGeneratorKeys={rowData.initialPossibleGeneratorKeys}
          onChange={setFormData}
          error={formStatus === FORM_STATE_STATUS.DATA_ERROR}
          variables={variables}
          generatorKeys={generatorKeys}
          highlightEmptyFields={formStatus === FORM_STATE_STATUS.LACK_OF_DATA}
        />
        <StyledFooterWrapper>{footer}</StyledFooterWrapper>
      </>
    ),
    [formStatus, rowData, variables, generatorKeys]
  )

  const errorForm = formStatus === FORM_STATE_STATUS.API_ERROR || formStatus === FORM_STATE_STATUS.DATA_ERROR

  useEffect(() => {
    clearForm()
  }, [formData, clearForm])

  const isLoading = createTemplateMutation.isLoading || updateTemplateMutation.isLoading

  const canDeleteRow = canEditLibrary(user.role)
  const deleteVariableDialogProps = useMemo(
    () =>
      getDeleteProviderTemplateDialogProps({
        templateName: rowData.initialTemplateName,
      }),
    [rowData.initialTemplateName]
  )
  const { confirm: confirmDelete, dialog: deleteVariableDialog } = useConfirm(deleteVariableDialogProps)

  const handleDelete = useCallback(async () => {
    const confirmed = await confirmDelete()

    if (confirmed) {
      deleteTemplateMutation.mutate({
        options: { templateId: rowData.id },
      })
    }
  }, [confirmDelete, deleteTemplateMutation, rowData])

  const additionalRowButtons = canDeleteRow ? (
    <span>
      {deleteVariableDialog}
      <StyledListItemButton onClick={handleDelete}>Delete</StyledListItemButton>
    </span>
  ) : null

  return {
    message,
    editForm,
    handleSubmit,
    handleCancel,
    isLoading,
    viewForm,
    clearForm,
    errorForm,
    additionalRowButtons,
  }
}
