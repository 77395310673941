import { useTheme } from "@emotion/react"
import { SkeletonProps, Skeleton, Box, Typography } from "@mui/material"

const EstimatePaperSkeleton = (props: SkeletonProps) => {
  const theme = useTheme()
  return (
    <Skeleton
      variant="rounded"
      {...props}
      sx={{ width: "100%", height: "215px", borderRadius: theme.spacing(1.25), ...props.sx }}
    />
  )
}
const SettlementsEstimateSkeleton = () => {
  const theme = useTheme()
  return (
    <Box sx={{ paddingTop: theme.spacing(5), maxWidth: "1273px", marginInline: "auto", width: "100%" }}>
      <Box sx={{ mb: theme.spacing(3) }}>
        <Typography variant="h1" sx={{ fontWeight: 700, fontSize: "30px", margin: 0 }}>
          Generating estimate...
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(4),
          [theme.breakpoints.down("lg")]: { flexDirection: "column" },
        }}
      >
        <EstimatePaperSkeleton />
        <EstimatePaperSkeleton />
      </Box>
      <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 3 }}>
        <Skeleton
          variant="rounded"
          sx={{ width: "100%", height: "820px", borderRadius: theme.spacing(1.25) }}
        />
        <Skeleton
          variant="rounded"
          sx={{ width: "100%", height: "620px", borderRadius: theme.spacing(1.25) }}
        />
      </Box>
    </Box>
  )
}

export default SettlementsEstimateSkeleton
