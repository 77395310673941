import React from "react"
import MuiDialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/Close"
import styled from "@emotion/styled"

const CustomDialogTitle = styled(DialogTitle)<{ center?: boolean }>(({ theme, center }) => ({
  paddingRight: theme.spacing(1),
  padding: center ? "0" : undefined,
}))

const LeftDialogActions = styled(DialogActions)(() => ({
  justifyContent: "flex-start",
}))

const SplitDialogActions = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}))

const CenterDialogActions = styled(DialogActions)(() => ({
  justifyContent: "space-between",
}))

const FlexButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: theme.palette.grey[500],
}))

type ButtonPlacement = "left" | "right" | "first-left" | "center"

export interface DialogFormProps extends MuiDialogProps {
  header: React.ReactNode
  buttonPlacement?: ButtonPlacement
  buttons: React.ReactNode[]
  onClose: () => unknown
  center?: boolean
}

interface ActionsProps {
  buttonPlacement: ButtonPlacement
  buttons: React.ReactNode[]
}

const Actions: React.FC<ActionsProps> = ({ buttonPlacement, buttons }) => {
  switch (buttonPlacement) {
    case "left":
      return (
        <LeftDialogActions>
          <FlexButtonBox>{buttons}</FlexButtonBox>
        </LeftDialogActions>
      )
    case "first-left":
      return (
        <SplitDialogActions>
          <FlexButtonBox>{buttons[0]}</FlexButtonBox>
          <FlexButtonBox>{buttons.slice(1)}</FlexButtonBox>
        </SplitDialogActions>
      )
    case "center":
      return <CenterDialogActions>{buttons}</CenterDialogActions>
    case "right":
    default:
      return (
        <DialogActions>
          <FlexButtonBox>{buttons}</FlexButtonBox>
        </DialogActions>
      )
  }
}

export const DialogForm: React.FC<DialogFormProps> = ({
  header,
  children,
  buttonPlacement = "right",
  buttons,
  onClose,
  maxWidth = "sm",
  fullWidth = true,
  center = false,
  ...rest
}) => {
  return (
    <MuiDialog maxWidth={maxWidth} fullWidth={fullWidth} onClose={onClose} {...rest}>
      <StyledIconButton onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <CustomDialogTitle center={center}>
        <Box display="flex" justifyContent={center ? "center" : "space-between"} alignItems="baseline">
          <Box>{header}</Box>
        </Box>
      </CustomDialogTitle>
      <DialogContent>
        <Box textAlign={center ? "center" : undefined} pb={2}>
          {children}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: center ? "center" : undefined }}>
        <Actions buttonPlacement={buttonPlacement} buttons={buttons} />
      </DialogActions>
    </MuiDialog>
  )
}

export default DialogForm
