import React, { ReactElement } from "react"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import { makeStyles } from "tss-react/mui"

import { Control, Controller, FieldErrors, FieldValues, Path, UseControllerProps } from "react-hook-form"
import { get } from "lodash"

const useStyles = makeStyles()(() => ({
  characterCounter: {
    textAlign: "right",
  },
}))

interface CustomInputFieldProps<TFields extends FieldValues> {
  control: Control<TFields>
  name: Path<TFields>
  rules?: UseControllerProps<TFields>["rules"]
  errors?: FieldErrors<TFields>
  shrinkLabel?: boolean
  showCharacterCounter?: boolean
}

type ValidationRuleObject = {
  value: number
  message: string
}

export type InputFieldProps<T extends FieldValues> = CustomInputFieldProps<T> & Partial<TextFieldProps>

export function InputField<T extends FieldValues>({
  control,
  name,
  rules,
  errors = {},
  shrinkLabel = false,
  showCharacterCounter = false,
  ...inputProps
}: InputFieldProps<T>): ReactElement {
  const { classes, cx } = useStyles()
  const { type } = inputProps
  const isDate = type === "date"

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field }, fieldState: { error: fieldStateError } }) => {
        const error = fieldStateError ?? get(errors, name)
        const errorMessage = error?.message

        const inputLength = field.value && !isDate ? (field.value as string).length : 0
        let characterLimit = null

        if (rules?.maxLength && showCharacterCounter && inputLength) {
          characterLimit = `${inputLength} / ${(rules.maxLength as ValidationRuleObject)?.value}`
        }

        return (
          <TextField
            InputLabelProps={isDate || shrinkLabel ? { shrink: true } : {}}
            error={!!error}
            inputRef={ref}
            {...inputProps}
            {...field}
            helperText={(errorMessage as React.ReactNode) || characterLimit || inputProps.helperText}
            value={field.value ?? ""}
            FormHelperTextProps={{
              className: cx(!errorMessage && characterLimit && classes.characterCounter),
            }}
          />
        )
      }}
    />
  )
}
