import { useState, useEffect, useMemo } from "react"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import styled from "@emotion/styled"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "../react-query/constants"
import { useForm } from "react-hook-form"
import { amountInDollars } from "../utils"

import { addDamagesSection, updateDamagesSection } from "../api"
import useAutosave from "../hooks/useAutosave"
import { replaceMatches } from "../common/form-components/rich-text/utils"
import { CurrencyField } from "common/form-components"
import { ReadOnlyCaseEditor, CaseEditorField } from "./components/CaseEditor"
import { useCaseVariables } from "./Variables"
import { isEditorEmpty } from "common/form-components/rich-text/utils"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { EditorContent } from "common/form-components/rich-text"

import { useOutletContext } from "react-router-dom"
import { Plaintiff } from "api/services/case/types"
import { TextVariable } from "common/types/variables"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"

const useStyles = makeStyles()(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  innerSectionBox: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
    mariginTop: theme.spacing(2),
  },
  amountFieldBox: {
    width: "50%",
    margin: theme.spacing(2, 0),
  },
  fieldBox: {
    width: "100%",
    marginRight: theme.spacing(2),
  },
  renderedSection: {
    borderLeftWidth: "1px",
    borderLeftColor: theme.palette.divider,
    borderLeftStyle: "solid",
    margin: theme.spacing(2),
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },
}))

const PreviewContainer = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}))

const INITIAL_SECTION_DATA = {
  damage_amount: 0,
  details_json: null,
}

interface DamagesSectionProps {
  caseId: PrimaryKey
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  template: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  section: any
  showPreview?: boolean
  showTitle?: boolean
  showDetails?: boolean
}

export function DamagesSection({
  caseId,
  template,
  section,
  showPreview = true,
  showTitle = true,
  showDetails = true,
}: DamagesSectionProps) {
  const { classes } = useStyles()
  const queryClient = useQueryClient()
  const [, setErrors] = useState(null)
  const { currentPlaintiff }: { currentPlaintiff: Plaintiff } = useOutletContext()
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  const detailsJson = useMemo(() => {
    return section?.details_json ? new EditorContent(section.details_json) : null
  }, [section])

  const { control, handleSubmit, getValues, watch, formState, reset } = useForm({
    defaultValues: {
      ...INITIAL_SECTION_DATA,
      ...section,
      details_json: detailsJson,
    },
  })

  const detailsJsonContent = watch("details_json")?.children ?? null

  useEffect(() => {
    reset({
      ...INITIAL_SECTION_DATA,
      ...section,
      details_json: detailsJson,
    })
  }, [reset, section, detailsJson])

  const damage_amount = watch("damage_amount")
  const damage_dollars = amountInDollars(damage_amount)

  const updateMutation = useMutation(updateDamagesSection, {
    onMutate: () => {
      setErrors(null)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.damagesSections])
      reset({}, { keepValues: true })
    },
    onError: (error: Error) => {
      const errObject = !!error?.message.length && JSON.parse(error.message)
      setErrors(errObject || null)
    },
  })
  // succeed/fail silently
  const autosaveMutation = useMutation(updateDamagesSection, {
    onSuccess: () => reset({}, { keepValues: true }),
  })

  const createMutation = useMutation(addDamagesSection, {
    onMutate: () => {
      setErrors(null)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.damagesSections])
    },
    onError: (error: Error) => {
      const errObject = !!error?.message.length && JSON.parse(error.message)
      setErrors(errObject || null)
    },
  })

  const dirtyFields = new Set(Object.keys(formState.dirtyFields))
  const isDirty = dirtyFields.size > 0

  const handleOnBlur = handleSubmit(async data => {
    if (!isDirty) return

    const details = data.details_json ? data.details_json.children : null
    const existingPlaintiffId = section?.plaintiff?.pk ?? null
    const dataToSend = {
      ...data,
      template: template.pk,
      case: caseId,
      details_json: details,
      plaintiff_id: multiPlaintiffEnabled ? currentPlaintiff.id : existingPlaintiffId,
    }

    return section?.pk
      ? await updateMutation.mutateAsync({
          caseId,
          sectionId: section.pk,
          data: dataToSend,
        })
      : await createMutation.mutateAsync({
          caseId,
          data: dataToSend,
        })
  })

  useAutosave({
    shouldAutosave: isDirty && section?.pk,
    save: () => {
      const data = { ...getValues() }
      const details = data.details_json ? data.details_json.children : null

      autosaveMutation.mutate({
        caseId,
        sectionId: section.pk,
        data: { template: template.pk, case: caseId, ...data, details_json: details },
      })
    },
  })

  // TODO: should be moved to data store (backend + frontend) later
  const damagesVariables: TextVariable[] = useMemo(
    () => [
      {
        name: "amount",
        type: "text",
        value: damage_dollars ?? null,
        category: "Damages",
      },
    ],
    [damage_dollars]
  )
  const { variables } = useCaseVariables(damagesVariables)

  const replacements = useMemo(
    () =>
      Object.fromEntries(
        variables.map(variable => {
          const name = `[[${variable.name}]]`
          return [name, variable.value ?? name]
        })
      ),
    [variables]
  )

  const templateTextData = useMemo(() => {
    if (!template?.text_json) {
      return null
    }

    return replaceMatches(template.text_json || DEFAULT_VALUE, replacements)
  }, [template, replacements])

  const detailsData = useMemo(() => {
    return replaceMatches(detailsJsonContent || DEFAULT_VALUE, replacements)
  }, [detailsJsonContent, replacements])

  const postTextData = useMemo(() => {
    if (!template?.posttext_json) {
      return null
    }

    return template.posttext_json || DEFAULT_VALUE
  }, [template?.posttext_json])

  return (
    <form noValidate onBlur={handleOnBlur}>
      {showTitle && (
        <>
          <Typography variant="h5">{template?.title}</Typography>
          <Divider />
        </>
      )}
      <Box className={classes.innerSectionBox}>
        <Box className={classes.fieldBox}>
          <CurrencyField
            name="damage_amount"
            control={control}
            label="Amount"
            className={classes.amountFieldBox}
          />
          {showDetails && (
            <CaseEditorField
              control={control}
              name="details_json"
              label="Details"
              variables={variables}
              includeAskAI={true}
              caseId={caseId}
            />
          )}
        </Box>
        {showPreview && (
          <Box className={classes.renderedSection}>
            <Typography variant="h5">Preview</Typography>
            <PreviewContainer variant="body1">
              {templateTextData && !isEditorEmpty(templateTextData) && (
                <ReadOnlyCaseEditor value={templateTextData} variables={variables} readonly={true} />
              )}
              {detailsData && !isEditorEmpty(detailsData) && (
                <ReadOnlyCaseEditor
                  value={detailsData}
                  variables={variables}
                  readonly={true}
                  key={`"plaintiff"-${currentPlaintiff?.id}}-readonly-${detailsJson}`}
                />
              )}
              {postTextData && !isEditorEmpty(postTextData) && (
                <ReadOnlyCaseEditor value={postTextData} variables={variables} readonly={true} />
              )}
            </PreviewContainer>
          </Box>
        )}
      </Box>
    </form>
  )
}
