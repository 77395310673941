import React from "react"
import { makeStyles } from "tss-react/mui"
import Main from "./Main"
import Header from "./Header"
import Footer from "./Footer"
import {
  APP_ID,
  FOOTER_HEIGHT_MULTIPLIER,
  FOOTER_MARGIN_BOTTOM_MULTIPLIER,
  FOOTER_MARGIN_TOP_MULTIPLIER,
  HEADER_NAV_HEIGHT_SPACE_MULTIPLIER,
} from "./constants"

const useStyles = makeStyles()(theme => ({
  app: {
    paddingTop: theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER),
    paddingBottom: theme.spacing(
      FOOTER_HEIGHT_MULTIPLIER + FOOTER_MARGIN_TOP_MULTIPLIER + FOOTER_MARGIN_BOTTOM_MULTIPLIER
    ),
    position: "relative",
    minHeight: "100vh",
    // Display grid lets the children take up 100% height without needing to hard code a height
    display: "grid",
    /* override styles for printing a page */
    "@page": {
      size: "auto" /* auto is the initial value */,
      /* setting margin top and bottom to 0 prevents chrome from outputting it's ugly timestamps on a printed page */
      marginTop: "0mm",
      marginBottom: "0mm",
    },
    // remove header padding when printing
    "@media print": {
      paddingTop: 0,
    },
  },
}))

const PortalBaseLayout: React.FC = () => {
  const { classes } = useStyles()
  return (
    <div className={classes.app} id={APP_ID}>
      <Header />
      <Main />
      <Footer />
    </div>
  )
}

export default PortalBaseLayout
