import styled from "@emotion/styled"
import { Box, Paper, Tabs } from "@mui/material"

export const PopupContainer = styled(Paper)(({ theme }) => ({
  position: "fixed",
  padding: theme.spacing(2),
  width: "600px",
  maxHeight: "800px",
  zIndex: theme.zIndex.modal,
  boxShadow: theme.shadows[5],
  overflow: "auto",
}))

export const TitleButton = styled("button")({
  border: "none",
  background: "none",
  cursor: "grab",
  width: "100%",
  textAlign: "left",
})

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

export const ButtonGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(1.5),
}))

export const SortableListContainer = styled(Box)(({ theme }) => ({
  "& [data-type='list-item']": {
    border: "none",
    padding: 0,
    margin: theme.spacing(3, 0),
  },
}))
