import Box from "@mui/material/Box"
import styled from "@emotion/styled"

import { AnnotatedContainer } from "../styled"
import { CodeChip } from "../../styled"

export const Content = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(0.5),
  overflowY: "scroll",
  "& > *": {},
}))

export const IcdCodeContainer = styled(AnnotatedContainer)(() => ({
  lineHeight: 1.67,
  letterSpacing: "-0.09px",
}))

export const SmallCodeChip = styled(CodeChip)(({ theme }) => ({
  width: "70px",
  "& > *": {
    padding: theme.spacing(0.25, 0),
  },
  "&:hover": {
    backgroundColor: theme.palette.blue.light,
    cursor: "pointer",
  },
}))
