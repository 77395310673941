import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"

export function RequestSkeleton(): JSX.Element {
  return (
    <Box sx={{ margin: 5 }}>
      <Skeleton animation="wave" height={80} style={{ marginBottom: 6, marginTop: 40 }} />
      <Skeleton animation="wave" height={40} width="30%" style={{ marginBottom: 16 }} />
      <Skeleton animation="wave" height={40} width="60%" style={{ marginBottom: 8 }} />
      <Skeleton animation="wave" height={200} width="60%" style={{ marginBottom: 8 }} />
    </Box>
  )
}
