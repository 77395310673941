import { useState } from "react"
import {
  Details,
  DetailsRow,
  DetailsRowItems,
  RevisionDate,
  RevisionDescription,
  RevisionFiles,
  DownloadAll,
} from "./styled"
import { REVISION_REQUEST_REASON_LABELS } from "./constants"
import { RevisionRequestEvent } from "common/models/revision-request-event"
import LinkButton from "common/buttons/LinkButton"
import { FileList } from "common/form-components/files/FileList"
import { Loading } from "common/loading"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useParams } from "react-router-dom"
import { downloadRevisionFiles } from "api"

export type RevisionRequestEventDetailsProps = {
  event: RevisionRequestEvent
} & EitherProps<
  {
    editable?: false
    onEdit?: undefined
  },
  {
    editable: true
    onEdit: () => void
  }
>

export const RevisionRequestEventDetails = ({
  event: { revisionRequest, createdAt },
  editable,
  onEdit,
}: RevisionRequestEventDetailsProps): JSX.Element => {
  const { id: requestId } = useParams()
  const [isDownloadingRevFiles, setIsDownloadingRevFiles] = useState(false)
  const { showErrorMessage } = useHandleMessages()

  const handleDownloadingRevFiles = async () => {
    setIsDownloadingRevFiles(true)
    try {
      // create file url based on blob
      const response = await downloadRevisionFiles(requestId, revisionRequest.pk)
      const fileBlob = await response.blob()
      const url: string = window.URL.createObjectURL(new Blob([fileBlob]))

      // create a link element
      const link: HTMLAnchorElement = document.createElement("a")
      link.href = url
      link.setAttribute("download", `request${requestId}_revision${revisionRequest.pk}_files.zip`)

      // add link to body and click on it
      document.body.appendChild(link)
      link.click()

      // clean up the link
      link.parentNode?.removeChild(link)
    } catch (error) {
      showErrorMessage({
        message: "There was an error downloading the contract data.",
        error,
      })
    } finally {
      setIsDownloadingRevFiles(false)
    }
  }

  return (
    <Details>
      <Loading show={isDownloadingRevFiles} label="Working..." />
      <DetailsRow>
        <DetailsRowItems>
          <RevisionDate>{createdAt}</RevisionDate>
          {editable ? <LinkButton onClick={onEdit}>Edit Request</LinkButton> : null}
        </DetailsRowItems>
      </DetailsRow>
      <DetailsRow data-test="revision-details-reason">
        <strong>Reason for Revision:</strong> {REVISION_REQUEST_REASON_LABELS[revisionRequest.reason]}
      </DetailsRow>
      {revisionRequest.additionalInformation ? (
        <DetailsRow>
          <RevisionDescription>{revisionRequest.additionalInformation}</RevisionDescription>
        </DetailsRow>
      ) : null}
      {revisionRequest.uploadedFiles?.length ? (
        <>
          <DownloadAll>
            <LinkButton onClick={handleDownloadingRevFiles} disabled={isDownloadingRevFiles}>
              Download All
            </LinkButton>
          </DownloadAll>
          <DetailsRow>
            <RevisionFiles>
              <FileList files={revisionRequest.uploadedFiles} />
            </RevisionFiles>
          </DetailsRow>
        </>
      ) : null}
    </Details>
  )
}
