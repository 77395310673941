import { FieldErrors, useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import { InputField, SelectInput } from "common/form-components"
import {
  NewRevisionRequest,
  RevisionRequest,
  RevisionRequestData,
  RevisionRequestMainData,
} from "common/models/revision-request"
import { revisionRequestReasonOptions } from "./constants"
import { Caption, DetailsRow, FormButtons, FormContainer, FormLine, FormLineHalfWidth } from "./styled"
import { DragAndDropFileField } from "common/form-components/files/DragAndDropFileField"
import { PendingFile } from "common/form-components/files/types"
import { FileList } from "common/form-components/files/FileList"
import { Loading } from "common"

interface RevisionRequestFormProps {
  request?: RevisionRequest
  onSubmit: (data: NewRevisionRequest | RevisionRequestData, files: PendingFile[]) => Promise<void>
  onCancel?: () => void
}

export const RevisionRequestForm = ({
  request,
  onSubmit,
  onCancel,
}: RevisionRequestFormProps): JSX.Element => {
  const {
    control,
    formState: { isValid, isDirty, isSubmitting, errors },
    getValues,
    handleSubmit,
  } = useForm<RevisionRequestMainData & { pendingFiles: PendingFile[] }>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { ...request, pendingFiles: [] },
  })

  const handleFormSubmit = handleSubmit(data => {
    if (isSubmitting || !isDirty || !isValid) {
      return
    }

    const nextRequest = request
      ? new RevisionRequestData(request.pk, data.reason, data.additionalInformation)
      : new NewRevisionRequest(data.reason, data.additionalInformation)

    return onSubmit(nextRequest, data.pendingFiles)
  })

  return (
    <>
      {isSubmitting && (
        <Loading show label={request ? "Saving Revision Request..." : "Creating Revision Request..."} />
      )}
      <FormContainer>
        <form onSubmit={handleFormSubmit}>
          <FormLineHalfWidth>
            <Caption>Reason for Revision</Caption>
            <SelectInput
              size="small"
              control={control}
              rules={{ required: true }}
              label={!getValues("reason") ? "Select reason" : ""}
              name="reason"
              options={revisionRequestReasonOptions}
              required
              disabled={isSubmitting}
              errors={errors as FieldErrors<RevisionRequestMainData>}
            />
          </FormLineHalfWidth>
          <FormLine>
            <Caption>
              Please share as much detail about your Revision request as possible. Then, upload any
              supplemental records as needed.
            </Caption>
            <InputField
              control={control}
              name="additionalInformation"
              variant="outlined"
              size="small"
              minRows={5}
              multiline
              disabled={isSubmitting}
            />
          </FormLine>
          {request?.uploadedFiles?.length ? (
            <FormLine>
              <Caption>Recently uploaded files</Caption>
              <DetailsRow>
                <FileList files={request.uploadedFiles} />
              </DetailsRow>
            </FormLine>
          ) : null}
          <FormLine>
            <DragAndDropFileField
              control={control}
              name={"pendingFiles"}
              errors={errors}
              disabled={isSubmitting}
            />
          </FormLine>
          <FormLine>
            <FormButtons>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={!isDirty || isSubmitting}
                data-test="revision-submit-button"
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
              {request && onCancel ? (
                <Button
                  variant="text"
                  color="primary"
                  size="large"
                  disabled={isSubmitting}
                  onClick={onCancel}
                  data-test="revision-cancel-edit-button"
                >
                  Cancel
                </Button>
              ) : null}
            </FormButtons>
          </FormLine>
        </form>
      </FormContainer>
    </>
  )
}
