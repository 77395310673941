import React, { useEffect } from "react"
import { useMutation } from "@tanstack/react-query"
import { useOutletContext } from "react-router-dom"
import { useFormContext } from "demand/context"
import { caseService } from "api/services/case"
import { STEP_STATUSES } from "demand/constants"
import { useCaseFactsSaveHandlers } from "./CaseFactsSaveProvider"
import { Control, UseFormHandleSubmit, UseFormReset, useFormState } from "react-hook-form"
import { CaseFacts, UpdatedCaseFacts } from "api/services/case/types"
import useAutosave from "hooks/useAutosave"
import { isEmpty, omit, pick } from "lodash"
import { CASE_SECTIONS } from "common/types/sections"

type CaseFactsAutoSaveFields = Omit<CaseFacts, "exhibits">

interface UseCaseFactsSaveProps {
  control: Control<CaseFactsAutoSaveFields>
  handleSubmit: UseFormHandleSubmit<CaseFactsAutoSaveFields>
  reset: UseFormReset<CaseFactsAutoSaveFields>
}

type UseCaseFactsSaveReturn = () => Promise<void>

export function useCaseFactsSave({
  control,
  handleSubmit,
  reset,
}: UseCaseFactsSaveProps): UseCaseFactsSaveReturn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { saveRef } = useOutletContext<{ saveRef: React.MutableRefObject<(...args: any[]) => void> }>()
  const { caseId, handleUpdateStepStatus } = useFormContext()
  const formState = useFormState({ control })

  const factsMutation = useMutation(caseService.saveCaseFacts, {
    onSuccess: (_, { data }) => {
      reset(data, { keepValues: true })
      handleUpdateStepStatus({ status: STEP_STATUSES.completed })
    },
  })

  const getSaveHandlers = useCaseFactsSaveHandlers()

  const handleSave = handleSubmit(async data => {
    const { dirtyFields } = formState

    if (!isEmpty(dirtyFields)) {
      const fields = Object.keys(omit(formState.dirtyFields, ["sections"])) as (keyof Omit<
        CaseFactsAutoSaveFields,
        "sections"
      >)[]
      const payload = pick(data, fields) as UpdatedCaseFacts

      if (dirtyFields.sections) {
        for (const sectionKey in dirtyFields.sections) {
          const section = sectionKey as CASE_SECTIONS
          const sectionData = data.sections[section]

          if (!dirtyFields.sections[section] || !sectionData) continue
          if (!payload.sections) payload.sections = {}

          payload.sections[section] = sectionData
        }
      }

      if (!isEmpty(payload)) {
        factsMutation.mutate({ caseId, data: payload })
      }
    }
  })

  const handleUploadAndSave = handleSubmit(async () => {
    await Promise.all(getSaveHandlers().map(handler => handler()))
    return handleSave()
  })

  useAutosave({
    shouldAutosave: formState.isDirty,
    save: handleSave,
  })

  useEffect(() => {
    saveRef.current = handleUploadAndSave
  }, [handleUploadAndSave, saveRef])

  return handleSave
}
