import React, { useEffect } from "react"
import { useSuspenseQuery } from "@suspensive/react-query"
import { caseService } from "api/services/case"
import { useFormContext } from "demand/context"
import { useForm } from "react-hook-form"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { CaseFactsFields, CaseFactsForm } from "./styled"
import { CaseDetailFields } from "./CaseDetailFields"
import { formSectionsToRoutes } from "demand/constants"
import { Divider } from "@mui/material"
import { CaseAlert } from "demand/components/CaseAlert"
import { withCaseFactsSaveProvider } from "./CaseFactsSaveProvider"
import { CaseSection } from "../CaseSection"
import { CASE_SECTIONS } from "common/types/sections"
import { CaseFactsFiles } from "./exhibits/files/CaseFactsFiles"
import { CaseFactsMissingDocuments } from "./CaseFactsMissingDocuments"
import { useQueryClient } from "@tanstack/react-query"
import { CaseFactsPageSkeleton } from "./skeleton/CaseFactsPageSkeleton"
import { withSuspense } from "common/withSuspense"
import { withFileUploadProvider } from "common/file-uploader"
import { useCaseFactsSave } from "./useCaseFactsSave"
import SimpleDemandSkipSectionBanner from "demand/SimpleDemandBanner"
import { usePermissions } from "permissions/usePermissions"
import useCase from "hooks/useCase"

interface PageProps {
  lastVisited: React.MutableRefObject<Nullable<string>>
}

export const CaseFactsPage = withSuspense(
  withFileUploadProvider(
    withCaseFactsSaveProvider(
      React.memo(function CaseFactsPage({ lastVisited }: PageProps): JSX.Element {
        const { caseId, request, currentPlaintiff } = useFormContext()
        const queryClient = useQueryClient()
        const { caseObj } = useCase(caseId)

        const { caseSectionReviewEnabled } = usePermissions({ suspense: false })

        useEffect(() => {
          lastVisited.current = formSectionsToRoutes.facts
        })

        useEffect(() => {
          return () => {
            requestAnimationFrame(() => queryClient.removeQueries([queryKeys.facts]))
          }
        }, [queryClient])

        const {
          data: { exhibits, ...caseFacts },
        } = useSuspenseQuery(
          [queryKeys.facts, caseId],
          () => caseService.getCaseFacts({ caseId }),
          SILENT_QUERY_PARAMS
        )

        const { control, reset, handleSubmit } = useForm({
          values: caseFacts,
          resetOptions: { keepDirtyValues: true },
        })

        const handleSave = useCaseFactsSave({
          control,
          handleSubmit,
          reset,
        })

        const isUsingTemplatedData = Object.values(caseFacts.sections).length > 0
        const userActionRequired = Object.values(caseFacts.sections).some(
          section => section.userActionRequired
        )

        return (
          <>
            <CaseFactsForm noValidate onBlur={handleSave}>
              <CaseFactsFields>
                <CaseDetailFields control={control} isTemplated={isUsingTemplatedData} />
              </CaseFactsFields>

              <Divider />

              <SimpleDemandSkipSectionBanner caseId={caseId} />

              {userActionRequired && (
                <CaseAlert
                  title="New templates have been added to reflect the newly selected attributes."
                  message="Ensure templates are modified appropriately below."
                />
              )}
              <CaseSection
                control={control}
                section={CASE_SECTIONS.FACTS}
                caseId={caseId}
                exhibits={exhibits}
                reviewEnabled={caseSectionReviewEnabled && !!currentPlaintiff?.id}
                plaintiffId={currentPlaintiff?.id}
              />

              <Divider />

              <CaseSection
                control={control}
                section={CASE_SECTIONS.LIABILITIES}
                caseId={caseId}
                exhibits={exhibits}
                reviewEnabled={caseSectionReviewEnabled && !!currentPlaintiff?.id}
                plaintiffId={currentPlaintiff?.id}
              />

              <Divider />

              {!caseObj?.use_exhibit_builder && <CaseFactsFiles caseId={caseId} exhibits={exhibits} />}

              {request?.pk && <CaseFactsMissingDocuments caseId={caseId} />}
            </CaseFactsForm>
          </>
        )
      })
    )
  ),
  <CaseFactsPageSkeleton />
)
