import { useCallback, useEffect, useState } from "react"
import { makeStyles } from "tss-react/mui"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableFooter from "@mui/material/TableFooter"
import TableRow from "@mui/material/TableRow"

import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import { NumericFormat } from "react-number-format"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import { SwitchInput } from "common/form-components"
import { calculateLossOfIncome, getLossOfIncomeColumns, updateLossOfIncomeColumns } from "api"
import { queryKeys } from "react-query/constants"
import { useFormContext } from "demand/context"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"
import { COLUMNS, SALARY_FORMS, COLUMN_TITLES } from "../constants"

const useStyles = makeStyles()(theme => ({
  tableContainer: {
    margin: theme.spacing(4, 2),
    display: "flex",
    flexDirection: "column",
  },
  toggleDescription: {
    fontSize: "0.75rem",
    fontStyle: "italic",
    margin: theme.spacing(0.5, "auto"),
  },
  lossTable: {
    width: "max-content",
  },
  headerCell: {
    display: "flex",
    flexDirection: "column",
  },
  switchInput: {
    margin: "auto",
  },
  summaryFooterRow: {
    backgroundColor: "#757575",
    color: "white !important",
    "& > *": {
      color: "white",
      fontWeight: 700,
    },
  },
  textCenter: {
    textAlign: "center",
  },
}))

export function IncomeLossTable({ salaryType, plaintiffId }) {
  const { classes } = useStyles()
  const [isDirty, setIsDirty] = useState(false)
  const { caseId, showErrorMessage, queryClient } = useFormContext()
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  const { error, data, refetch } = useQuery(
    multiPlaintiffEnabled
      ? [queryKeys.incomeLossCalculation, caseId, plaintiffId, "results"]
      : [queryKeys.incomeLossCalculation, caseId, "results"],
    () => {
      setIsDirty(true)
      return calculateLossOfIncome(caseId, plaintiffId)
    },
    {
      meta: { disableLoader: true },
      onSuccess: () => {
        setIsDirty(false)
      },
      enabled: (!!plaintiffId && multiPlaintiffEnabled) || !multiPlaintiffEnabled,
    }
  )

  useEffect(() => {
    if (!multiPlaintiffEnabled) return
    refetch()
  }, [plaintiffId, refetch, multiPlaintiffEnabled])

  const getErrorMessage = useCallback(error => {
    try {
      return JSON.parse(error.message).error
    } catch {
      return error.message
    }
  }, [])

  const { data: lossColumns } = useQuery([queryKeys.incomeLossColumns, caseId], () =>
    getLossOfIncomeColumns(caseId)
  )

  const {
    control,
    handleSubmit,
    getValues,
    reset: resetForm,
  } = useForm({
    defaultValues: lossColumns ?? COLUMNS.reduce((prev, current) => ({ ...prev, [current]: true }), {}),
  })

  useEffect(() => {
    resetForm(lossColumns)
  }, [lossColumns, resetForm])

  const updateColumnsMutation = useMutation(updateLossOfIncomeColumns, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.incomeLossColumns])
    },
  })

  const handleOnChange = handleSubmit(data => {
    updateColumnsMutation.mutate({ data: data, caseId: caseId })
  })

  if (error && salaryType === SALARY_FORMS.noSalary.key) {
    return <div className={classes.textCenter}>No income information to use.</div>
  }

  if (error) {
    showErrorMessage(getErrorMessage(error))
    return <div className={classes.textCenter}>Could not calculate income loss results.</div>
  }

  if (isDirty) return <div className={classes.textCenter}>Calculating income loss...</div>

  if (!data?.results)
    return <div className={classes.textCenter}>Fill out salary info for loss calculation.</div>

  const sum = (x, y) => x + y

  const isToggleDisabled = name => {
    const allColumnsMinusCurrent = COLUMNS.filter(key => key !== name)

    return allColumnsMinusCurrent.every(key => {
      return getValues(key) === false
    })
  }

  return (
    <form onChange={handleOnChange}>
      <Box className={classes.tableContainer}>
        <Divider />
        <Box className={classes.toggleDescription}>
          Toggle which columns you would like in the generated demand.
        </Box>
        <Table align="center" size="small" className={classes.lossTable}>
          <TableHead>
            <TableRow>
              {COLUMNS.map(key => {
                return (
                  <TableCell key={key} align="right">
                    <Box className={classes.headerCell}>
                      <SwitchInput
                        className={classes.switchInput}
                        control={control}
                        name={key}
                        value={getValues(key)}
                        size="small"
                        disabled={isToggleDisabled(key)}
                      />
                      {COLUMN_TITLES[key]}
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data.results.year).map(key => (
              <TableRow key={key}>
                <TableCell align="right">{data.results.start_date[key]}</TableCell>
                <TableCell align="right">{data.results.end_date[key]}</TableCell>
                <TableCell align="right">
                  <NumericFormat
                    value={data.results.loss_of_income[key]}
                    displayType="text"
                    decimalScale="2"
                    fixedDecimalScale
                    thousandSeparator=","
                    prefix="$"
                  />
                </TableCell>
                <TableCell align="right">
                  <NumericFormat
                    value={data.results.loss_of_supplemental_income[key]}
                    displayType="text"
                    decimalScale="2"
                    fixedDecimalScale
                    thousandSeparator=","
                    prefix="$"
                  />
                </TableCell>
                <TableCell align="right">
                  <NumericFormat
                    value={data.results.net_loss[key]}
                    displayType="text"
                    decimalScale="2"
                    fixedDecimalScale
                    thousandSeparator=","
                    prefix="$"
                  />
                </TableCell>
                <TableCell align="right">
                  <NumericFormat
                    value={data.results.time_adj_net_loss[key]}
                    displayType="text"
                    decimalScale="2"
                    fixedDecimalScale
                    thousandSeparator=","
                    prefix="$"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow className={classes.summaryFooterRow}>
              <TableCell align="right">{data.results.start_date[0]}</TableCell>
              <TableCell align="right">
                {data.results.end_date[Object.keys(data.results.end_date).length - 1]}
              </TableCell>
              <TableCell align="right" data-test="loss-of-income">
                <NumericFormat
                  value={Object.values(data.results.loss_of_income).reduce(sum, 0)}
                  displayType="text"
                  decimalScale="2"
                  fixedDecimalScale
                  thousandSeparator=","
                  prefix="$"
                />
              </TableCell>
              <TableCell align="right" data-test="loss-of-supplemental-income">
                <NumericFormat
                  value={Object.values(data.results.loss_of_supplemental_income).reduce(sum, 0)}
                  displayType="text"
                  decimalScale="2"
                  fixedDecimalScale
                  thousandSeparator=","
                  prefix="$"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  value={Object.values(data.results.net_loss).reduce(sum, 0)}
                  displayType="text"
                  decimalScale="2"
                  fixedDecimalScale
                  thousandSeparator=","
                  prefix="$"
                />
              </TableCell>
              <TableCell align="right">
                <NumericFormat
                  value={Object.values(data.results.time_adj_net_loss).reduce(sum, 0)}
                  displayType="text"
                  decimalScale="2"
                  fixedDecimalScale
                  thousandSeparator=","
                  prefix="$"
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </form>
  )
}
