import { LibraryFirm } from "common/types/library"
import { SectionTemplateItemAttributeDto } from "../section-template/types"
import { HEADING_TYPE } from "settings/Library/TemplateForms/formData/document-structure/enums"
import { CASE_SECTIONS } from "common/types/sections"

export enum BLOCK_TYPES {
  HEADER = "header",
  TEMPLATE_PLACEHOLDER = "template_placeholder",
  COMPONENT_PLACEHOLDER = "component_placeholder",
}

export interface DocumentStructureBaseBlockAttrsDto {
  type: BLOCK_TYPES
}

export type DocumentStructureBaseBlockDto = Required<BaseEntity> & DocumentStructureBaseBlockAttrsDto

export interface DocumentStructureSubSectionDto extends Required<BaseEntity> {
  type?: never
  repeat_for_multiple_plaintiffs: boolean
  children: [DocumentStructureHeadingDto, ...(DocumentStructureBlockDto | DocumentStructureSubSectionDto)[]]
}

export interface DocumentStructureHeadingDto extends DocumentStructureBaseBlockDto {
  type: BLOCK_TYPES.HEADER
  style: HEADING_TYPE
  name: string
  children: (DocumentStructureSubSectionDto | DocumentStructureLeafBlockDto)[]
}

export type DocumentStructureBlockDto =
  | DocumentStructureHeadingDto
  | DocumentStructureTemplateDto
  | DocumentStructureComponentDto

export type DocumentStructureLeafBlockDto = Exclude<DocumentStructureBlockDto, DocumentStructureHeadingDto>

export interface DocumentStructureHeadingSectionDto {
  section_id: Nullable<PrimaryKey>
  repeat?: boolean
}

export interface DocumentStructureTemplateDto extends DocumentStructureBaseBlockDto {
  type: BLOCK_TYPES.TEMPLATE_PLACEHOLDER
  template_type: Nullable<CASE_SECTIONS>
}

export interface DocumentStructureComponentDto extends DocumentStructureBaseBlockDto {
  type: BLOCK_TYPES.COMPONENT_PLACEHOLDER
  component_key: Nullable<string>
}

export interface DocumentStructureSectionDto extends Required<BaseEntity> {
  title: string
  children: (DocumentStructureLeafBlockDto | DocumentStructureSubSectionDto)[]
  repeat_for_multiple_plaintiffs: boolean
}

export interface DocumentStructureDto extends Required<BaseEntity> {
  firm: LibraryFirm
  is_draft: boolean
  is_duplicated: boolean
  document_name: string
  attributes: SectionTemplateItemAttributeDto[]
  created_at: string
  letterhead: Nullable<{ full_name: string; name: string; file_url: string }>
}

export interface FullDocumentStructureDto extends DocumentStructureDto {
  sections: DocumentStructureSectionDto[]
}

export interface DocumentStructureNamesDto {
  document_names: { document_name: string }[]
}

export interface DocumentStructureSectionsReorderDto {
  sectionId: PrimaryKey
  parent: Nullable<PrimaryKey>
  index: number
}

export interface DocumentStructureContentReorderDto {
  id: PrimaryKey
  parent: Nullable<PrimaryKey>
  index: number
}

export interface DocumentStructureBlockReorderDto {
  blockId: PrimaryKey
  sectionId: PrimaryKey
  index: number
}

export interface DocumentStructureUpdatedResponseDto {
  document_structure_id: DocumentStructureDto["pk"]
}
