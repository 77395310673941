import { RevisionRequest } from "common/models/revision-request"
import { RevisionRequestFormController } from "./RevisionRequestFormController"

interface UpdateRevisionRequestProps {
  requestId: string
  request: RevisionRequest
  onUpdated: (revision: RevisionRequest) => void
  onCancel: () => void
}

export const UpdateRevisionRequest = ({
  requestId,
  request,
  onUpdated,
  onCancel,
}: UpdateRevisionRequestProps): JSX.Element => {
  return (
    <RevisionRequestFormController
      requestId={requestId}
      request={request}
      onSubmitted={onUpdated}
      onCancel={onCancel}
    />
  )
}
