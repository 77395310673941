import { useTheme } from "@emotion/react"
import { Box, Divider, Typography } from "@mui/material"
import React from "react"
import { getFirstAppointmentOfYear, getLastAppointmentOfYear } from "./medicronCalendarUtilities"
import { MedicronCalendarAppointment, MedicronCalendarAppointmentByYearAndMonthAndDay } from "./types"

export function getAppointmentGapStartAndEnd(
  year: number,
  groupedAppointments: MedicronCalendarAppointmentByYearAndMonthAndDay,
  monthsPerRow: number
): undefined | { start: MedicronCalendarAppointment; end: MedicronCalendarAppointment } {
  const lastAppointmentOfYear = getLastAppointmentOfYear(year, groupedAppointments)
  const sortedYears = Object.keys(groupedAppointments).sort((a, b) => a.localeCompare(b))
  // Sort the years, check if the current year is not the last one
  const nextAppointmentYearIndex = sortedYears.findIndex(yearString => yearString === `${year}`)
  const anotherYearExistsAfterThisOne =
    nextAppointmentYearIndex === -1 ? false : nextAppointmentYearIndex < sortedYears.length - 1

  if (!lastAppointmentOfYear || !anotherYearExistsAfterThisOne) {
    // There is no appointment this year, or there is no appointment after this year. No gap.
    return undefined
  }
  const nextAppointmentYear = parseInt(sortedYears[nextAppointmentYearIndex + 1])

  if (isNaN(nextAppointmentYear)) {
    // Shouldn't happen, but better to code defensively
    return undefined
  }
  const nextAppointmentAfterThisYear = getFirstAppointmentOfYear(nextAppointmentYear, groupedAppointments)

  // there's an appointment this year, and there's a year in the future
  if (!nextAppointmentAfterThisYear) {
    // Shouldn't happen, but coding defensively.
    return undefined
  }

  if (
    nextAppointmentAfterThisYear.appointmentDate.getUTCFullYear() >
    lastAppointmentOfYear.appointmentDate.getUTCFullYear() + 1
  ) {
    // > 1 year gap -- show the gap
    return { start: lastAppointmentOfYear, end: nextAppointmentAfterThisYear }
  }

  const lastAppointmentRow = Math.floor(lastAppointmentOfYear.appointmentDate.getUTCMonth() / monthsPerRow)
  const firstAppointmentRow = Math.floor(
    nextAppointmentAfterThisYear.appointmentDate.getUTCMonth() / monthsPerRow
  )

  // If the last appointment of the year is in the last row, and the first appointment of the next year is in the first row, don't show the gap.
  if (lastAppointmentRow === 12 / monthsPerRow - 1 && firstAppointmentRow === 0) {
    return undefined
  }

  return { start: lastAppointmentOfYear, end: nextAppointmentAfterThisYear }
}

const DATE_DISPLAY: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
  timeZone: "UTC",
}

export type EndOfYearGapProps = {
  groupedAppointments: MedicronCalendarAppointmentByYearAndMonthAndDay
  year: number
  monthsPerRow?: number
}

export default function MedicronCalendarEndOfYearGap({
  year,
  groupedAppointments,
  monthsPerRow = 4,
}: EndOfYearGapProps): React.ReactElement {
  const theme = useTheme()

  const gap = getAppointmentGapStartAndEnd(year, groupedAppointments, monthsPerRow)
  if (!gap) {
    return <></>
  }

  const gapStart = gap.start.appointmentDate.toLocaleDateString("en-US", DATE_DISPLAY)
  const gapEnd = gap.end.appointmentDate.toLocaleDateString("en-US", DATE_DISPLAY)

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr auto 1fr"
      alignItems="center"
      gap={theme.spacing(3.5)}
      marginTop={theme.spacing(6)}
    >
      <Divider sx={{ background: theme.palette.text.primary }} />
      <Typography fontSize={theme.typography.pxToRem(18)} fontWeight={theme.typography.fontWeightBold}>
        Absence of Treatment {gapStart} - {gapEnd}
      </Typography>
      <Divider sx={{ background: theme.palette.text.primary }} />
    </Box>
  )
}
