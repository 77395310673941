import { ReactElement, useCallback, useEffect, useState } from "react"
import { Box } from "@mui/material"
import { StyledTab, StyledTabs } from "./AdditionalInfoRevamp/styled"
import { useWatch } from "react-hook-form"
import { useRequestContext } from "requests/context"

interface PlaintiffTabSelectorProps {
  children: (plaintiffIndex: number) => ReactElement
}

const PlaintiffLabel = ({ index }: { index: number }) => {
  const firstName = useWatch({ name: `plaintiffs.${index}.first_name` })
  const lastName = useWatch({ name: `plaintiffs.${index}.last_name` })
  return <span>{`${firstName} ${lastName}`}</span>
}

export const PlaintiffTabSelector: React.FC<PlaintiffTabSelectorProps> = ({ children }): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0)
  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, newValue: number) => setSelectedTab(newValue),
    []
  )
  const { plaintiffsArrayProps } = useRequestContext()
  const { fields: plaintiffs } = plaintiffsArrayProps

  useEffect(() => {
    if (selectedTab >= plaintiffs.length && plaintiffs.length > 0) {
      setSelectedTab(plaintiffs.length - 1)
    }
  }, [plaintiffs.length, selectedTab])

  return (
    <Box>
      <StyledTabs value={selectedTab} onChange={handleTabChange}>
        {plaintiffs.map(({ pk }, index) => (
          <StyledTab key={pk ?? "new"} label={<PlaintiffLabel index={index} />} value={index} />
        ))}
      </StyledTabs>
      <Box>
        {plaintiffs.map(({ pk }, index) => (
          <Box key={pk ?? "new"} sx={{ display: index === selectedTab ? "inherit" : "none" }}>
            {children(index)}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
