import { ALL_ROLES, EXTERNAL_ROLES } from "common/models/roles"
import { SETTINGS_LINK_DETAILS } from "./constants"
import { NavLink, SETTINGS_ROUTE_PREFIX } from "./types"

function getLabel(route: SETTINGS_ROUTE_PREFIX, userRole?: ALL_ROLES): string {
  if (userRole === EXTERNAL_ROLES.FIRM_ADMIN) {
    return SETTINGS_LINK_DETAILS[route].altLabel || SETTINGS_LINK_DETAILS[route].label
  }

  return SETTINGS_LINK_DETAILS[route].label
}

export function getSettingsPageOptions(route: SETTINGS_ROUTE_PREFIX, userRole?: ALL_ROLES): NavLink {
  return {
    pageTitle: getLabel(route, userRole),
    icon: SETTINGS_LINK_DETAILS[route].icon,
    link: route,
  }
}
