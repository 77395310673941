import { Box, styled } from "@mui/material"

export const PageContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
}))

export const Row = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr 2fr 3fr 3fr 3fr 1fr",
  gap: theme.spacing(2),
  padding: theme.spacing(2, 0),
}))

export const RowButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginLeft: "auto",
  "& > *": {
    margin: theme.spacing("auto", 0, "auto", "auto"),
  },
}))

export const EditPermissionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4),
  gap: theme.spacing(1),
}))

export const EnableForAllChangeWarning = styled(Box)(({ theme }) => ({
  color: theme.palette.red.warning,
  fontWeight: 700,
}))
