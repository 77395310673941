import React, { useState } from "react"
import Box from "@mui/material/Box"

import { useMutation } from "@tanstack/react-query"
import { deleteAttorney } from "api"
import { queryKeys } from "react-query/constants"
import { queryClient } from "react-query/queryClient"

import { useDialog } from "hooks/useDialog"
import useUser from "hooks/useUser"

import Table, { Alignment } from "common/tables/Table"
import ActionButton, { ActionButtonContainer } from "missing-docs/ActionButton"
import ConfirmDialog from "common/ConfirmDialog"
import AttorneyForm from "../AttorneyForm"

import { Attorney } from "../Firm.d"
import { canRoleDeleteAttorney } from "../permissions"

interface Props {
  attorneys: Attorney[]
  firmId: number
}

const AttorneyTable = ({ attorneys, firmId }: Props): React.ReactElement => {
  const { user } = useUser()
  const canDeleteAttorney = canRoleDeleteAttorney(user.role)

  const { isOpen: isDeleteOpen, openDialog: openDeleteDialog, closeDialog: closeDeleteDialog } = useDialog()
  const [editingAttorneyId, setEditingAttorneyId] = useState<Nullable<number>>(null)

  const [pendingAttorneyToDelete, setPendingAttorneyToDelete] = useState<Nullable<number>>(null)
  const deleteAttorneyMutation = useMutation(deleteAttorney, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.firm])
    },
    onSettled: () => {
      setPendingAttorneyToDelete(null)
      closeDeleteDialog()
    },
  })

  const handleOnDeleteClick = (attorneyId: number): void => {
    setPendingAttorneyToDelete(attorneyId)
    openDeleteDialog()
  }

  const columns = [
    {
      id: "name",
      title: "Name",
      align: "left" as Alignment,
      cellComponent: (attorney: Attorney) => {
        return `${attorney.first_name} ${attorney.last_name}`
      },
    },
    {
      id: "cases",
      title: "Cases",
      align: "left" as Alignment,
      cellComponent: (attorney: Attorney) => {
        return attorney.current_number_of_cases
      },
    },
    {
      id: "total",
      title: "Total",
      align: "left" as Alignment,
      cellComponent: (attorney: Attorney) => {
        return attorney.number_of_cases
      },
    },
    {
      id: "actions",
      title: "Actions",
      align: "right" as Alignment,
      cellComponent: (attorney: Attorney) => {
        return (
          <ActionButtonContainer justifyContent={"right"}>
            <ActionButton onClick={() => setEditingAttorneyId(attorney.pk)} data-test="edit-attorney-button">
              Edit
            </ActionButton>
            {canDeleteAttorney ? (
              <ActionButton
                onClick={() => {
                  handleOnDeleteClick(attorney.pk)
                }}
                data-test="delete-attorney-button"
              >
                Delete
              </ActionButton>
            ) : null}
          </ActionButtonContainer>
        )
      },
    },
  ]

  return (
    <Box mb={2} data-test="attorney-table">
      {editingAttorneyId === null ? (
        <Table columns={columns} records={attorneys} emptyMessage="Add attorney's names here"></Table>
      ) : (
        <AttorneyForm
          data={{
            ...attorneys.find(attorney => attorney.pk === editingAttorneyId),
            firm_id: firmId,
          }}
          callback={() => {
            setEditingAttorneyId(null)
          }}
        />
      )}

      <ConfirmDialog
        open={isDeleteOpen}
        onClose={() => {
          setPendingAttorneyToDelete(null)
          closeDeleteDialog()
        }}
        onConfirm={() =>
          deleteAttorneyMutation.mutate({
            attorneyId: pendingAttorneyToDelete,
            firmId: firmId,
          })
        }
        title="Are you sure you want to delete this attorney?"
        body={""}
      />
    </Box>
  )
}

export { AttorneyTable as default }
