import { useMemo } from "react"
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { FileStatus, RequestFileToUpload } from "../types"
import Table, { Alignment } from "common/tables/Table"
import { humanFileSize } from "./fileUtils"
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled"
import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import CircularProgress from "@mui/material/CircularProgress"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"

interface ClientSideFilesListProps {
  files: RequestFileToUpload[]
  onDeleteFile: (file: RequestFileToUpload) => void
  handleUpdateFiles: (updatedFiles: RequestFileToUpload[]) => void
}

function getIconForStatus(status: FileStatus) {
  switch (status) {
    case FileStatus.Processing:
      return <AccessTimeFilledIcon />
    case FileStatus.Unzipping:
    case FileStatus.Uploading:
    case FileStatus.ReadyToUpload:
      return <CircularProgress size={25} />
    case FileStatus.UnzippingError:
    case FileStatus.UploadError:
      return <ReportProblemIcon color="error" />
    case FileStatus.UnzippingComplete:
    case FileStatus.UploadComplete:
      return <CheckCircleRoundedIcon />
  }
}

function StatusCell(file: RequestFileToUpload) {
  return <Tooltip title={file.status}>{getIconForStatus(file.status)}</Tooltip>
}

function FileSizeCell(file: RequestFileToUpload) {
  return <Box>{humanFileSize(file.file.size)}</Box>
}

export function ClientSideFilesList({ files, onDeleteFile, handleUpdateFiles }: ClientSideFilesListProps) {
  const theme = useTheme()

  const FileNameCell = useMemo(() => {
    const FileNameCell = (file: RequestFileToUpload) => (
      <Box>
        <Box>{file.file.name}</Box>
        <Box color={theme.palette.error.main} marginLeft={theme.spacing(2)}>
          {file.error}
        </Box>
      </Box>
    )
    return FileNameCell
  }, [theme])

  const FileActionsCell = useMemo(() => {
    const FileActionsCell = (file: RequestFileToUpload) => (
      <Box display="flex">
        {[FileStatus.UploadError, FileStatus.UnzippingError].includes(file.status) && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              handleUpdateFiles([
                {
                  ...file,
                  status: FileStatus.ReadyToUpload,
                },
              ])
            }
          >
            {file.status === FileStatus.UnzippingError ? "Upload" : "Retry"}
          </Button>
        )}
        <IconButton aria-label="delete" onClick={() => onDeleteFile(file)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    )
    return FileActionsCell
  }, [onDeleteFile, handleUpdateFiles])

  const columns = useMemo(
    () => [
      {
        id: "status",
        title: "",
        align: "center" as Alignment,
        cellComponent: StatusCell,
      },
      {
        id: "file_name",
        title: "File Name",
        align: "left" as Alignment,
        cellComponent: FileNameCell,
      },
      {
        id: "file_size",
        title: "File Size",
        align: "left" as Alignment,
        cellComponent: FileSizeCell,
      },
      {
        id: "actions",
        title: "",
        align: "left" as Alignment,
        cellComponent: FileActionsCell,
      },
    ],
    [FileNameCell, FileActionsCell]
  )

  return <Table columns={columns} records={files} dataTest="in-progress-upload-files-list" />
}
