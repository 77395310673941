import styled from "@emotion/styled"
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { ElementType } from "react"

interface IFormSectionListItem {
  component: ElementType
  to: string
  active?: boolean
}

export const FormSectionListItem = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== "active",
})<IFormSectionListItem>(({ theme, active }) => ({
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    paddingRight: theme.spacing(2),
  },
  "&, &:hover": active
    ? {
        backgroundColor: theme.palette.grey[300],
      }
    : {},
  display: "flex",
  gap: theme.spacing(1),
}))

export const FormSectionListItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  ":first-of-type": {
    paddingLeft: theme.spacing(4),
  },

  "&, & > span": {
    fontSize: "14px",
  },
}))

export const FormSectionListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: theme.spacing(3),
}))
