import React, { useCallback, useMemo } from "react"
import RedoIcon from "@mui/icons-material/Redo"
import UndoIcon from "@mui/icons-material/Undo"
import { useSlate } from "slate-react"
import { StyledButton, StyledToolbarGroup } from "../styled"

export const UndoRedoButtons = (): JSX.Element => {
  const editor = useSlate()

  const hasUndos = Boolean(editor.history.undos.length)
  const hasRedos = Boolean(editor.history.redos.length)

  const handleUndo = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      hasUndos && editor.undo()
    },
    [editor, hasUndos]
  )

  const handleRedo = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      hasRedos && editor.redo()
    },
    [editor, hasRedos]
  )

  return useMemo(
    () => (
      <StyledToolbarGroup>
        <StyledButton onMouseDown={handleUndo} disabled={!hasUndos}>
          <UndoIcon />
        </StyledButton>
        <StyledButton onMouseDown={handleRedo} disabled={!hasRedos}>
          <RedoIcon />
        </StyledButton>
      </StyledToolbarGroup>
    ),
    [handleRedo, handleUndo, hasRedos, hasUndos]
  )
}
