import { Editor, NodeEntry } from "slate"
import { CustomEditor, ListItemElement } from "../../CustomEditor"
import { LEAF_BLOCK_ELEMENTS } from "../../elements"
import { getBlocks } from "../blocks"
import { decreaseListItemDepth } from "./decreaseListItemDepth"
import { isListItem } from "./queries"

export function decreaseListDepth(editor: CustomEditor): void {
  if (!editor.selection) return

  const leafBlockEntries = getBlocks(editor, LEAF_BLOCK_ELEMENTS.PARAGRAPH).reverse()
  const listItemEntries = leafBlockEntries
    .map(([, path]) =>
      Editor.above<ListItemElement>(editor, { at: path, match: node => isListItem(editor, node) })
    )
    .filter(entry => Boolean(entry)) as NodeEntry<ListItemElement>[]

  listItemEntries.forEach(([, listItemPath]) => decreaseListItemDepth(editor, listItemPath))
}
