import { Provider } from "../types"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"
import { useFormContext } from "demand/context"
import { BoxChip } from "./styled"
import { isNull } from "lodash"
import { Box, Tooltip } from "@mui/material"
import { Warning } from "@mui/icons-material"
import styled from "@emotion/styled"
import { amber } from "@mui/material/colors"
import { PlaintiffSelector } from "demand/components/PlaintiffSelector"
import { useMutation } from "@tanstack/react-query"
import { updateProvider } from "api"
import { queryClient } from "react-query/queryClient"
import { queryKeys } from "react-query/constants"
import { useHandleMessages } from "common/messages/useHandleMessages"
import * as Sentry from "@sentry/browser"

interface NoPlaintiffProviderRowSectionProps {
  provider: Provider
}

const WarningIcon = styled(Warning)(() => ({
  color: amber[600],
}))

const NO_PLAINTIFF_ASSIGNED = "No Plaintiff Assigned"

const NoPlaintiffProviderRowSection = ({ provider }: NoPlaintiffProviderRowSectionProps): JSX.Element => {
  const { caseId } = useFormContext()
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)
  const { showSuccessMessage, showErrorMessage } = useHandleMessages()

  const setPlaintiffMutation = useMutation(updateProvider, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.providers, caseId])
      showSuccessMessage("Provider successfully assigned to plaintiff.")
    },
    onError: e => {
      Sentry.captureMessage(`error setting plaintiff for provider: ${e}`, "error")
      showErrorMessage(
        "There was an error assigning a provider to the plaintiff - please contact engineering."
      )
    },
  })

  if (!multiPlaintiffEnabled || !isNull(provider.plaintiff)) {
    return <></>
  }

  return (
    <>
      <Box display={"flex"}>
        <PlaintiffSelector
          caseId={caseId}
          plaintiff={null}
          onChange={newPlaintiffId => {
            setPlaintiffMutation.mutate({
              caseId: caseId,
              data: {
                ...provider,
                plaintiff_id: newPlaintiffId,
              },
              providerId: provider.pk,
            })
          }}
        />
      </Box>

      <Tooltip
        title={
          "This provider does not have a plaintiff linked to it. When generating, if there is not a plaintiff linked, the data for this provider will not appear."
        }
      >
        <BoxChip
          icon={<WarningIcon color="inherit" />}
          label={NO_PLAINTIFF_ASSIGNED}
          variant="outlined"
          size="small"
        />
      </Tooltip>
    </>
  )
}

export { NoPlaintiffProviderRowSection as default }
