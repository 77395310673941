import { DetailedContract } from "../interfaces"

export interface MinimalFirm {
  pk: number
  name: string
}

export interface FirmWithContract extends MinimalFirm {
  current_contract: DetailedContract
}

export class FirmLogo {
  readonly dataUrl: string

  constructor(blob: Blob) {
    this.dataUrl = URL.createObjectURL(blob)
  }

  clear(): void {
    URL.revokeObjectURL(this.dataUrl)
  }
}
