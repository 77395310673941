import Skeleton from "@mui/material/Skeleton"
import Box from "@mui/material/Box"
import { Divider } from "@mui/material"
import { CASE_SECTIONS } from "common/types/sections"
import { CaseFactsFields, CaseFactsForm } from "../styled"
import { CaseSectionLayout } from "../../CaseSection"

export function CaseFactsPageSkeleton(): JSX.Element {
  return (
    <CaseFactsForm noValidate>
      <CaseFactsFields>
        <Skeleton variant="rounded" animation="wave" height={56} />
        <Skeleton variant="rounded" animation="wave" height={56} />
      </CaseFactsFields>

      <Divider />

      <CaseSectionLayout
        section={CASE_SECTIONS.FACTS}
        editor={<Skeleton variant="rounded" animation="wave" height={100} sx={{ marginBottom: 2 }} />}
        images={<Skeleton variant="rounded" animation="wave" height={150} />}
      />

      <Divider />

      <CaseSectionLayout
        section={CASE_SECTIONS.LIABILITIES}
        editor={<Skeleton variant="rounded" animation="wave" height={100} sx={{ marginBottom: 2 }} />}
        images={<Skeleton variant="rounded" animation="wave" height={150} />}
      />

      <Divider />

      <Box>
        <Skeleton variant="text" animation="wave" height={40} />
        <Skeleton variant="text" animation="wave" height={40} />
        <Skeleton variant="text" animation="wave" height={40} />
      </Box>
    </CaseFactsForm>
  )
}
