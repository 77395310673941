import React from "react"
import styled from "@emotion/styled"

import { Button, ButtonProps } from "@mui/material"
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined"

interface AskAIProps extends ButtonProps {
  onClick?: () => void
  label?: string
}

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: "14px",
  borderRadius: "6px",
  padding: "6px 10px",
  "& > .MuiButton-startIcon": {
    fontSize: "14px",
  },
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.black,
  "&:hover": {
    backgroundColor: theme.palette.grey[800],
  },
}))

export const AskAIButton = ({ onClick, label = "Ask AI", ...otherProps }: AskAIProps): JSX.Element => {
  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onClick?.()
  }

  return (
    <StyledButton
      data-selector="ask-ai-btn"
      onClick={handleButtonClick}
      startIcon={<AutoAwesomeOutlinedIcon />}
      {...otherProps}
    >
      {label}
    </StyledButton>
  )
}
