import { Appointment, DocumentRootData, Exhibit, IcdCode, Partition, Provider } from "documents/types"
import { Flag } from "documents/flags/types"

export interface DocumentData {
  plaintiff: string
  exhibitsOrder: Exhibit["id"][]
  exhibits: Record<Exhibit["id"], Exhibit>
  partitions: Record<Partition["id"], Partition>
  appointments: Record<Appointment["id"], Appointment>
  providersOrder: Provider["id"][]
  providers: Record<Provider["id"], Provider>
  icdCodes: Record<IcdCode["id"], IcdCode>
  questionnaireId: PrimaryKey
  dateOfIncident: string
}

export enum MED_SUMMARY_SORTING {
  PROVIDER = "Provider",
  DATE = "Appointment Date",
}

export enum MED_SUMMARY_REVIEW_ACTIONS {
  COMPLETE = "Complete",
  INCOMPLETE = "Incomplete",
  REVIEWED = "Reviewed",
  PENDING_FINAL_REVIEW = "Pending Final Review",
}

export interface FiltersData {
  filtersStartDate: Nullable<string>
  filtersEndDate: Nullable<string>
  filtersReviewAction: Nullable<MED_SUMMARY_REVIEW_ACTIONS>
  filtersProviderIds: Nullable<Provider["id"][]>
  sorting: MED_SUMMARY_SORTING
}

export interface FlagsData {
  currentFlag: Nullable<Partial<Flag>>
  flagSelector: Nullable<string>
  flags: Record<string, Flag>
  hoveredFlagId: Nullable<Flag["id"]>
  scrollToFlag: Nullable<Flag>
}

export type MedicronCalendarData = {
  dayOfIncident: Date
} & Pick<DocumentData, "plaintiff" | "appointments" | "providers">

export type DocumentState = {
  title: string
  titleTags: string[]
  showExtraButtons: boolean
  showAnnotationButton: boolean
  showPlaintiff: boolean
  isSaving: boolean
  errorMessage: Nullable<string>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: Nullable<any>
}

export type StoreICDCode = Record<
  string,
  {
    code: string
    dateOfService: string
    description: string
    reference: { exhibitId: number; page: number; exhibitIndex: number }
    appointmentId: Appointment["id"]
  }
>

export type GetState<T> = () => T & Pick<DocumentRootData, "documentId">
export type SetState<T> = (
  partialState: Partial<T> | ((state: T & Pick<DocumentRootData, "documentId">) => Partial<T>)
) => void

export type SummaryItemProviderKey = `provider:${string}`
export type SummaryItemAppointmentKey = `appointment:${string}:${string}`
export type SummaryItemKey = SummaryItemProviderKey | SummaryItemAppointmentKey
