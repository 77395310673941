const MISSING_CONTRACT_RECEIPT_ERROR =
  "We don't have a contract stored in our system for this date range. Please contact support@evenuplaw.com for help."

const ERROR_CODES_MAPPING: { [key: string]: string } = {
  E00001: MISSING_CONTRACT_RECEIPT_ERROR,
  E00002: MISSING_CONTRACT_RECEIPT_ERROR,
}

export const getErrorCodeMessage = (code: string | undefined): string | undefined => {
  if (code) {
    return ERROR_CODES_MAPPING[code]
  }
  return undefined
}
