import React, { useState, useEffect, useMemo } from "react"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import Autocomplete from "@mui/material/Autocomplete"
import { capitalize, debounce, noop, startCase } from "lodash"

import { useHandleMessages } from "../common/messages/useHandleMessages"
import { fetchInjuries } from "../api"

export default function InjuriesSearch({ onChange = null }) {
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const { showErrorMessage } = useHandleMessages()

  useEffect(() => {
    let active = true

    if (inputValue === "") {
      setOptions(value ? [value] : [])
      setLoading(false)
      return undefined
    }

    const fetchData = async () => {
      setLoading(true)
      try {
        const results = await fetchInjuries(inputValue)

        if (active) {
          // we need to only run this on a fresh trigger of the useEffect hook
          let newOptions = []
          if (value) {
            // add any existing value to the top of the list
            newOptions = [value]
          }

          if (results) {
            // we need to group by the type of result
            // i.e. injury, problem, level_1, etc.
            const resultKeys = Object.keys(results).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
            const groupedResults = resultKeys.reduce((acc, key) => {
              const values = results[key].map(value => {
                // renaming the "injury_name" key to "injury" for comptability with the ontology component
                return { value: value, association: key === "injury_names" ? "injury" : key }
              })
              return [...acc, ...values]
            }, [])
            newOptions = [...newOptions, ...groupedResults]
          }

          setOptions(newOptions)
          setLoading(false)
        }
      } catch (error) {
        showErrorMessage({
          message: "Something went wrong, please refresh and try again.",
          error,
        })
        setLoading(false)
      }
    }

    fetchData().catch(noop)

    return () => {
      active = false
    }
  }, [value, inputValue, showErrorMessage])

  const debounceSetInputValue = useMemo(
    () =>
      debounce(value => {
        setInputValue(value)
      }, 300),
    []
  )

  return (
    <Autocomplete
      loading={loading}
      value={value}
      options={options}
      getOptionLabel={option => option.value}
      filterOptions={options => {
        // we need to override the default filterOptions to show all results
        return options || []
      }}
      groupBy={option => capitalize(startCase(option.association))}
      onChange={(event, newValue) => {
        setValue(newValue)
        setOptions(newValue ? [newValue, ...options] : options)
        onChange(event, newValue)
      }}
      onInputChange={(event, newInputValue) => {
        // debounce the input change such that it doesn't trigger the fetchData on each keypress
        debounceSetInputValue(newInputValue)
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Search for injuries"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
