import { useCallback, useRef } from "react"
import { useSearchParams } from "react-router-dom"

type PinValue = boolean
type SetPinValue = () => void

type UsePinStateReturn = [PinValue, SetPinValue]

export const PAGE_PINNED_QUERY_PARAM = "pinned"

export function usePinState(): UsePinStateReturn {
  const [searchParams, setSearchParams] = useSearchParams()
  const pinned = searchParams.get(PAGE_PINNED_QUERY_PARAM)?.toLowerCase() === "true"

  const pinnedRef = useRef(pinned)
  pinnedRef.current = pinned

  const searchParamsRef = useRef(searchParams)
  searchParamsRef.current = searchParams

  const handlePinnedChange = useCallback(() => {
    const nextSearchParams = new URLSearchParams(Object.fromEntries(searchParamsRef.current.entries()))

    nextSearchParams.set(PAGE_PINNED_QUERY_PARAM, String(!pinnedRef.current))
    setSearchParams(nextSearchParams)
  }, [setSearchParams])

  return [pinned, handlePinnedChange]
}

export function getPinState(target: Window): boolean {
  const searchParams = new URLSearchParams(target.location.search)
  const pinned = searchParams.get(PAGE_PINNED_QUERY_PARAM)?.toLowerCase() === "true"

  return pinned
}
