import { useMemo, useReducer, useState, useCallback } from "react"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { Loading } from "common"
import { Pagination } from "common/pagination"
import { PageSizeSelect } from "common/pagination/PageSizeSelect"
import { DEFAULT_PAGE_SIZE } from "common/models/pagination"
import { SectionTemplateTableRowData, SectionTemplatesTableViewModel } from "common/models/library"
import { useSectionTemplateForm } from "settings/Library/TemplateForms"
import { sectionTemplateService } from "api/services/section-template"
import { UPDATE_TEMPLATE_FORM_STATE_MESSAGES } from "settings/Library/TemplateForms/constants"
import useUser from "hooks/useUser"
import { canEditLibraryByFirmsUserManage, canEditLibrary } from "settings/permissions"
import { FirmSelect } from "common/attributes-filter/FirmSelect"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { userService } from "api/services/users"

import { templatesTabReducer } from "../State/templatesReducer"
import { INITIAL_TEMPLATES_TAB_STATE, LibraryTabStateContext } from "../State/constants"
import { NewSectionTemplate } from "../Forms/NewSectionTemplate"
import { LibraryTable } from "../LibraryTable"
import { useAttributes, LibraryFilters, useFilterValues } from "../Filters"
import { StyledFiltersWrapper, StyledPageSizeWrapper, StyledSeparator } from "./styled"

export const getDuplicateData = (
  rowData: SectionTemplateTableRowData
): Partial<SectionTemplateTableRowData> => {
  return {
    initialAttributes: rowData.initialAttributes,
    initialFirmId: rowData.initialFirmId,
    initialSection: rowData.initialSection,
    initialContent: rowData.initialContent,
  }
}

export function SectionTemplates(): Nullable<JSX.Element> {
  const [state, dispatch] = useReducer(templatesTabReducer, INITIAL_TEMPLATES_TAB_STATE)
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])
  const { user } = useUser()
  const [firmId, setFirmId] = useState<Nullable<PrimaryKey>>(null)
  const attributes = useAttributes()
  const { section, attributeValues } = useFilterValues()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const { isFeatureEnabled } = useFeatures()
  const firmTemplateFeature = isFeatureEnabled(FEATURES.FIRM_TEMPLATE)
  const { data: firmsUserManage } = useQuery(
    [queryKeys.firmsUserManage, user.id],
    () => userService.getFirmsUserManage(null),
    {
      enabled: user.isInternal && firmTemplateFeature,
    }
  )
  const canEdit = firmTemplateFeature
    ? canEditLibraryByFirmsUserManage(user.role, firmsUserManage)
    : canEditLibrary(user.role)

  const { isFetching, data: sectionTemplates } = useQuery(
    [queryKeys.sectionTemplates, currentPage, pageSize, section, attributeValues, firmId],
    () =>
      sectionTemplateService.getTemplatesList({
        page: currentPage,
        pageSize,
        section,
        attributeValues,
        firmId,
      }),
    {
      meta: {
        disableLoader: true,
      },
      enabled: Boolean(attributes),
      keepPreviousData: true,
    }
  )

  const resetCurrentPage = () => {
    setCurrentPage(1)
  }

  const handleChangeFilters = useCallback(() => {
    resetCurrentPage()
  }, [])

  const handleChangePageSize = useCallback((pageSize: number) => {
    resetCurrentPage()
    setPageSize(pageSize)
  }, [])

  if (!sectionTemplates && !attributes) return null

  if (!attributes) return <Loading show label="Loading attributes" />

  if (!sectionTemplates) {
    return (
      <LibraryTabStateContext.Provider value={contextValue}>
        <StyledFiltersWrapper>
          <LibraryFilters />
        </StyledFiltersWrapper>
        <Loading show label="Loading templates..." />
      </LibraryTabStateContext.Provider>
    )
  }

  const templatesTableData = new SectionTemplatesTableViewModel(
    sectionTemplates.items,
    attributes,
    firmTemplateFeature
  )

  return (
    <LibraryTabStateContext.Provider value={contextValue}>
      <StyledFiltersWrapper>
        <LibraryFilters onFilterChange={handleChangeFilters}>
          {firmTemplateFeature && <FirmSelect firmId={firmId} setFirmId={setFirmId} />}
        </LibraryFilters>
        <StyledPageSizeWrapper>
          <PageSizeSelect pageSize={pageSize} onChange={handleChangePageSize} />
        </StyledPageSizeWrapper>
      </StyledFiltersWrapper>
      {canEdit ? <NewSectionTemplate /> : <StyledSeparator />}
      <LibraryTable
        tableData={templatesTableData}
        loading={isFetching}
        getFormData={useSectionTemplateForm}
        getDuplicateData={getDuplicateData}
        entityName="templates"
        canDuplicate={canEdit}
        formStatusMessageMap={UPDATE_TEMPLATE_FORM_STATE_MESSAGES}
      />
      {sectionTemplates.count > 0 && (
        <Pagination
          pageCount={sectionTemplates.pageCount}
          page={sectionTemplates.page}
          count={sectionTemplates.count}
          onChange={setCurrentPage}
        />
      )}
    </LibraryTabStateContext.Provider>
  )
}
