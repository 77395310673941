interface SerializableDataType<T> {
  data: T
}

type SerializableOptions<TOptions, TModel> = {
  [K in keyof TOptions]: K extends "data" ? TModel : TOptions[K]
}

export function withRequestSerializer<TModel, TData, TOptions extends SerializableDataType<TData>, TReturn>(
  serializer: (model: TModel) => TData,
  callback: (options: TOptions) => TReturn
): (options: SerializableOptions<TOptions, TModel>) => TReturn {
  return options => {
    return callback({ ...options, data: serializer(options.data) } as TOptions)
  }
}

export function withResponseSerializer<TModel, TData, TArgs>(
  serializer: (data: TData) => TModel,
  callback: (options: TArgs) => Promise<TData>
): (options: TArgs) => Promise<TModel> {
  return async options => {
    return serializer(await callback(options))
  }
}
