import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { InfoContainer } from "./InfoContainer"
import { AdditionalInformationProps } from "../types"
import { StyledAdditionalInformationWrapper } from "../styled"

export const AdditionalInformation = ({ facts, additionalInformation }: AdditionalInformationProps) => {
  return (
    <RequestFormModule title="Additional Information">
      <StyledAdditionalInformationWrapper>
        <InfoContainer field="Facts" value={facts ?? "-"} />
        <InfoContainer field="Additional Information" value={additionalInformation ?? "-"} />
      </StyledAdditionalInformationWrapper>
    </RequestFormModule>
  )
}
