import { DEFAULT_NONE_VALUE, LAST_EDITED_COLUMN, TableViewModelCellData } from "common/models/library"

export const getDocumentStructureTitle = (items: TableViewModelCellData[]) => {
  return items.reduce<string>((result, item) => {
    if (item.key === LAST_EDITED_COLUMN) {
      return result
    }

    if (item.value === DEFAULT_NONE_VALUE || item.value === "") {
      return result
    }

    return result ? `${result} - ${item.value}` : `${item.value}`
  }, "")
}
