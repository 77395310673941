import React, { useState, useEffect } from "react"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline"
import { makeStyles } from "tss-react/mui"
import { downloadGenericFile } from "../../api"

const useStyles = makeStyles()(theme => ({
  imageGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(120px, 360px));",
    gridGap: theme.spacing(2),

    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  imageContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  removeImage: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  fileExtension: {
    position: "relative",
    top: "2em",
  },
  imageName: {
    marginTop: theme.spacing(2),
  },
  imageNameField: {
    marginLeft: theme.spacing(1),
    width: "60%",
  },
}))

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pendingImages: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPendingImages: any
}

interface ImageToUpload {
  preview: string
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file: any
  questionnaireFileId?: number
  downloadUrl?: string
}

export default function PendingImageForm({ pendingImages, setPendingImages }: Props): JSX.Element {
  const { classes } = useStyles()
  const [imagePreviews, setImagePreviews] = useState<ImageToUpload[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const getPreviewImage = async (image: ImageToUpload) => {
      setIsLoading(true)
      if (!image.file && image.downloadUrl) {
        const downloadedFile = await downloadQuestionnaireImage(image.downloadUrl, image.name)
        if (downloadedFile) {
          image.file = downloadedFile
        }
      }

      let fileName = ""
      if (image?.name) {
        fileName = image.name
      } else if (image?.file?.name) {
        fileName = image.file.name
      }

      return {
        ...image,
        preview: image?.file ? URL.createObjectURL(image.file) : "",
        name: fileName,
      }
    }

    // create image previews
    const filesWithPreview = pendingImages.map(async (image: ImageToUpload) => {
      return await getPreviewImage(image)
    })

    Promise.allSettled(filesWithPreview).then((files: PromiseSettledResult<ImageToUpload>[]) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const previewFiles = files.map((file: any) => {
        return file.value
      })

      setImagePreviews(previewFiles)
      setIsLoading(false)
    })
  }, [pendingImages])

  const downloadQuestionnaireImage = async (downloadUrl: string, name: string) => {
    const response = await downloadGenericFile({ downloadURL: downloadUrl })
    const data = await response.blob()
    const metadata = {
      type: response.type,
    }
    return new File([data], name, metadata)
  }

  const removeImage = (index: number) => {
    const newImages = [...imagePreviews]
    newImages.splice(index, 1)
    setPendingImages(newImages)
  }

  const fileExtension = (name: string) => {
    if (name) {
      const parts = name.split(".")
      if (parts.length > 1) {
        return "." + parts.pop()
      } else {
        return ""
      }
    }
  }

  const strippedFileName = (name: string) => {
    if (name) {
      return name.split(".").shift()
    }
  }

  const setFileName = (event: React.ChangeEvent<HTMLInputElement>, index: number, fileExtension: string) => {
    const name = event.currentTarget.value
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setPendingImages((prevImages: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return prevImages.map((image: any, i: number) => {
        if (i === index) {
          return { ...image, name: `${name}${fileExtension}` }
        } else {
          return image
        }
      })
    })
  }

  return (
    <Box className={classes.imageGrid}>
      {isLoading && <CircularProgress />}
      {imagePreviews.map((file: ImageToUpload, index) => (
        <Box className={classes.imageContainer} key={index}>
          <IconButton
            className={classes.removeImage}
            style={{ background: "white" }}
            onClick={() => removeImage(index)}
          >
            <RemoveIcon />
          </IconButton>
          {/* TODO: Add consistent style constrains */}
          <img src={file.preview}></img>
          <Box className={classes.imageName}>
            <TextField
              label="File name"
              name="name"
              variant="outlined"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => setFileName(e, index, fileExtension(file?.file?.name) ?? "")}
              InputLabelProps={{ shrink: true }}
              defaultValue={strippedFileName(file?.file?.name)}
              className={classes.imageNameField}
            />
            <span className={classes.fileExtension}>{fileExtension(file?.file?.name)}</span>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
