import { FLAG_CATEGORY } from "api/services/matter/types"
import { IcdCodeRelation } from "documents/types"

export interface Flag {
  id: string
  category: FLAG_CATEGORY
  appointment: string
  reason?: string
  quote?: string
  relations?: IcdCodeRelation[]
}

export type FlagTableObject = Flag & {
  providerName?: string
  dateOfService: string
}

export function isFlagForSaving(flag: Partial<Flag>): flag is Omit<Flag, "id"> {
  return !!flag.category
}

export function isFlagForUpdating(flag: Partial<Flag>): flag is Flag {
  return !!flag.category && !!flag.id
}

export const DATA_FLAG_ID = "data-flag-id"

export const FLAG_IMAGE_SIZE = 14
