import { DOCUMENT_TYPES } from "requests/enums"

import { MedicalChronologyButton } from "./MedicalChronologyButton"
import { DemandButton } from "./DemandButton"
import { RequestFields } from "requests/RequestForm/types"

interface RequestDocumentButtonProps {
  request: RequestFields
  demandId: Nullable<PrimaryKey>
}

export const RequestDocumentButton = ({ request, demandId }: RequestDocumentButtonProps) => {
  const { type: documentType } = request

  return [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY, DOCUMENT_TYPES.PREMIUM_MEDCHRON].includes(documentType) ? (
    <MedicalChronologyButton request={request} />
  ) : (
    <DemandButton demandId={demandId} request={request} />
  )
}
