import React, { useState } from "react"
import styled from "@emotion/styled"
import Popover, { PopoverOrigin } from "@mui/material/Popover"
import MenuItem from "@mui/material/MenuItem"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import { AnnotatedExhibit, CaseId, Duplicate, PartitionProvider, Split } from "./types"
import useAutocomplete from "@mui/material/useAutocomplete"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { createPartitionProvider, updatePartitionProvider } from "api"
import { queryKeys } from "react-query/constants"
import { mapUpdatePartition, updateAnnotatedExhibits } from "./cacheUtils"
import useUpdatePartitionMutation from "./hooks/useUpdatePartitionMutation"
import MoreHoriz from "@mui/icons-material/MoreHoriz"
import TextButton from "common/buttons/TextButton"
import { getFilteredPartitionProviders } from "./utils"

const StyledInput = styled("input")(({ theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: "100%",
  justifyContent: "space-between",
  paddingRight: theme.spacing(1),
  // make sure the background gets highlighted when navigating menu items via keyboard
  "&[data-focus]": {
    backgroundColor: theme.palette.grey[200],
  },
}))

const ProviderNameBox = styled(Box)({
  wordBreak: "break-word",
  whiteSpace: "normal",
})

const ANCHOR_OPTIONS: PopoverOrigin = { vertical: "bottom", horizontal: "left" }
const CREATE_PK = -1
const CREATE_PREFIX = "Create: "
const CREATE_VALUE_WRAPPING_CHAR = '"'

const getValueFromCreateString = (createStr: string) => {
  let value: string = createStr.replaceAll(CREATE_PREFIX, "")
  value = value.replaceAll(CREATE_VALUE_WRAPPING_CHAR, "")
  return value
}

interface DuplicateNameProps {
  partitionProviders: PartitionProvider[]
  newProviderName: string
  plaintiffId: Nullable<number>
}

const isDuplicateName = ({ partitionProviders, newProviderName, plaintiffId = null }: DuplicateNameProps) => {
  return partitionProviders.some(
    provider =>
      provider.name.trim().toLowerCase() === newProviderName.trim().toLowerCase() &&
      provider.plaintiff_id === plaintiffId
  )
}

interface ProviderAutocompleteProps {
  options: PartitionProvider[]
  onChange: (value: PartitionProvider) => unknown
  onCreate: (newProviderName: string) => unknown
  disabled?: boolean
  caseId: CaseId
  onClose: () => unknown
}

// autocomplete must be placed in it's own component when using open: true as an option
// if rendered inside of a Popper or Popover with open: true, some of the refs won't
// exist when the useAutocomplete does all it's stuff under the hood
const ProviderAutocomplete: React.FC<ProviderAutocompleteProps> = ({
  options,
  onChange,
  onCreate,
  disabled,
  caseId,
  onClose,
}) => {
  const [value, setValue] = useState<string>("")
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null)
  const [activeOption, setActiveOption] = useState<Nullable<PartitionProvider>>(null)
  const { showErrorMessage } = useHandleMessages()
  const queryClient = useQueryClient()

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions: autocompleteGroupedOptions,
  } = useAutocomplete({
    value: null,
    inputValue: value,
    open: true,
    freeSolo: false,
    options,
    getOptionLabel: opt => opt.name,
    includeInputInList: true,
    onInputChange: (event, value, reason) => {
      // "reset" is used to change the input on select of an option
      // we don't want to fill the input in this case, we would just close the autocomplete
      if (reason === "reset") {
        return
      }
      setValue(value)
    },
    onChange: (event, value, reason) => {
      // ignore onChange event for things like "clear" and "create-option"
      if (!disabled && reason === "selectOption" && value) {
        if (value?.pk === CREATE_PK) {
          onCreate(getValueFromCreateString(value.name))
        } else {
          onChange(value)
        }
      }
    },
  })

  // No grouping was added, so type should be correct
  // TODO: refactor this code to avoid data modification
  const groupedOptions = autocompleteGroupedOptions as PartitionProvider[]

  const { mutate, isLoading } = useMutation(updatePartitionProvider, {
    onSuccess: (data: PartitionProvider) => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          shouldExhibitUpdate: exhibit =>
            exhibit.splits.some(split => split.provider?.pk === activeOption?.pk) ||
            exhibit.duplicates.some(duplicate => duplicate.provider?.pk === activeOption?.pk),
          update: exhibit => {
            if (!activeOption) return exhibit // satisfy typescript
            return {
              splits: mapUpdatePartition({
                partitions: exhibit.splits,
                shouldPartitionUpdate: split => split.provider?.pk === activeOption.pk,
                update: { provider: data },
              }),
              duplicates: mapUpdatePartition({
                partitions: exhibit.duplicates,
                shouldPartitionUpdate: duplicates => duplicates.provider?.pk === activeOption.pk,
                update: { provider: data } as Partial<Duplicate>,
              }),
            } as Partial<AnnotatedExhibit>
          },
        })
      })
      // 2) update fetched list of partition providers
      queryClient.setQueryData<PartitionProvider[]>([queryKeys.partition_providers, caseId], oldData => {
        if (!oldData) return []

        return oldData.map(provider => {
          if (provider.pk === data.pk) {
            return data
          }
          return provider
        })
      })
      handleClose()
      onClose()
    },
    onError: error => {
      showErrorMessage({
        message:
          "Error updating provider name. Please try again shortly then report your issue if it persists.",
        error,
      })
    },
  })

  const handleOpen = (option: PartitionProvider) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setActiveOption({ ...option })
  }

  const handleClose = () => {
    if (isLoading) return

    setAnchorEl(null)
    setActiveOption(null)
  }

  if (value) {
    groupedOptions.push({
      pk: CREATE_PK,
      name: `${CREATE_PREFIX}${CREATE_VALUE_WRAPPING_CHAR}${value}${CREATE_VALUE_WRAPPING_CHAR}`,
      color: "",
    })
  }

  // when there is a value in the input box, we will append the "Create" option
  const noOptions = value === "" ? groupedOptions.length === 0 : groupedOptions.length === 1
  return (
    <>
      <Box maxWidth="400px" {...getRootProps()}>
        <Box py={1.5} px={1}>
          <StyledInput autoFocus disabled={disabled} {...getInputProps()} placeholder="Search/Create" />
        </Box>
        <Box maxHeight="240px" overflow="scroll">
          <List disablePadding {...getListboxProps()}>
            {noOptions && <MenuItem>No providers</MenuItem>}
            {groupedOptions.map((option, index) => {
              return (
                <React.Fragment key={option.pk}>
                  <StyledMenuItem disabled={disabled} {...getOptionProps({ option, index })}>
                    <Box display="flex" alignItems="center" mr={2}>
                      {!noOptions && (
                        <Box
                          style={{ backgroundColor: option.color ? `#${option.color}` : undefined }}
                          width="16px"
                          height="16px"
                          mr={1}
                        />
                      )}
                      <ProviderNameBox>{option.name}</ProviderNameBox>
                    </Box>
                    {option.pk !== CREATE_PK && (
                      <IconButton disabled={disabled} onClick={handleOpen(option)} size="small">
                        <MoreHoriz />
                      </IconButton>
                    )}
                  </StyledMenuItem>
                </React.Fragment>
              )
            })}
          </List>
        </Box>
      </Box>
      {/* Place this popover outside of the autocomplete because getRootProps() hijacks click handlers and only allows the autocomplete input to be focused */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={ANCHOR_OPTIONS}
      >
        <Box paddingY={1} paddingX={1}>
          <form
            onSubmit={event => {
              event.preventDefault()
              if (
                options.some(
                  provider =>
                    provider.name.trim().toLowerCase() === activeOption?.name.trim().toLowerCase() &&
                    provider.pk !== activeOption?.pk
                )
              ) {
                showErrorMessage({
                  message: `A provider already exists with name: "${
                    activeOption?.name.trim() ?? ""
                  }". Select the existing one or enter a new name.`,
                })
                return
              }
              if (activeOption) {
                mutate({
                  caseId,
                  partitionProviderId: activeOption.pk,
                  data: { name: activeOption.name.trim() },
                })
              }
            }}
          >
            <Box marginBottom={1}>
              <label htmlFor="intake-files-edit-provider-name">
                <Typography style={{ fontSize: "14px", fontWeight: 600 }}>Edit Provider Name</Typography>
              </label>
            </Box>
            <StyledInput
              id="intake-files-edit-provider-name"
              value={activeOption?.name || ""}
              disabled={isLoading}
              autoFocus
              required
              onChange={event => {
                // check activeOption to satisfy typescript, it should always be set since we set it when the menu is opened
                if (activeOption) {
                  setActiveOption({ ...activeOption, name: event.target.value })
                }
              }}
              style={{
                minWidth: "200px",
              }}
            />
            <Box mt={1} display="flex" justifyContent="flex-end">
              <TextButton
                size="small"
                type="submit"
                disabled={
                  isLoading ||
                  !!options.find(
                    provider => provider.pk === activeOption?.pk && provider.name === activeOption.name
                  )
                }
              >
                Update
              </TextButton>
            </Box>
          </form>
        </Box>
      </Popover>
    </>
  )
}

interface ProviderMenuProps {
  anchorEl: Nullable<HTMLElement>
  onClose: () => unknown
  partitionProviders: PartitionProvider[]
  exhibitId: number
  caseId: CaseId
  partitionId: number
  provider?: PartitionProvider
  plaintiffId?: Nullable<number>
  multiPlaintiffEnabled: boolean
}

const ProviderMenu: React.FC<ProviderMenuProps> = ({
  anchorEl,
  onClose,
  partitionProviders,
  exhibitId,
  caseId,
  partitionId,
  provider,
  plaintiffId,
  multiPlaintiffEnabled = false,
}) => {
  const queryClient = useQueryClient()
  const { showErrorMessage } = useHandleMessages()

  const { isUpdating, update } = useUpdatePartitionMutation({
    caseId,
    exhibitId,
    partitionId,
    onSuccess: onClose,
  })

  const filteredPartitionProviders = getFilteredPartitionProviders(
    partitionProviders,
    multiPlaintiffEnabled ? plaintiffId : null
  )

  const { mutate: create, isLoading: isCreating } = useMutation({
    mutationFn: createPartitionProvider,
    onSuccess: (data: PartitionProvider) => {
      // 1) update annotated exhibit query
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: exhibit => {
            const split = exhibit.splits.find(split => split.pk === partitionId)
            const update: Partial<Duplicate> | Partial<Split> = { provider: data }

            if (split) {
              return {
                splits: mapUpdatePartition({ partitions: exhibit.splits, partitionId, update }),
              } as Partial<AnnotatedExhibit>
            }
            // must be a duplicate if could not find partition id in splits
            return {
              duplicates: mapUpdatePartition({ partitions: exhibit.duplicates, partitionId, update }),
            } as Partial<AnnotatedExhibit>
          },
        })
      })
      // 2) update fetched list of partition providers
      queryClient.setQueryData<PartitionProvider[]>([queryKeys.partition_providers, caseId], oldData => {
        if (!oldData) return []

        return [...oldData, data]
      })

      onClose()
    },
    onError: error => {
      showErrorMessage({
        message: "Could not create provider. Try again shortly and file an issue if the problem persists.",
        error,
      })
    },
  })

  const handleCreate = (newProviderName: string, plaintiffId: Nullable<number>) => {
    if (isDuplicateName({ partitionProviders, newProviderName, plaintiffId })) {
      showErrorMessage({
        message: `A provider already exists with name: "${newProviderName.trim()}". Select the existing one or enter a new name.`,
      })
      return
    }

    create({ caseId, data: { name: newProviderName.trim(), partition_id: partitionId } })
  }

  return (
    <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose} anchorOrigin={ANCHOR_OPTIONS}>
      <ProviderAutocomplete
        options={filteredPartitionProviders}
        onChange={partitionProvider => {
          if (partitionProvider.pk === provider?.pk) {
            onClose()
            return
          }
          update({ exhibitId, partitionId, data: { partition_provider_id: partitionProvider?.pk } })
        }}
        onCreate={newProviderName => {
          handleCreate(newProviderName, plaintiffId ?? null)
        }}
        disabled={isUpdating || isCreating}
        caseId={caseId}
        onClose={onClose}
      />
    </Popover>
  )
}

export default ProviderMenu
