import { useCallback } from "react"
import { Autocomplete, TextField } from "@mui/material"
import { StyledFormControl } from "./styled"

const DEFAULT_AUTOCOMPLETE_STYLES = { minWidth: 210 }

type StateObject = {
  key: number | string
  display: string
}

export type AutocompleteInputValue = string | number | null

interface AutocompleteInputProps {
  options: StateObject[]
  label?: Nullable<string>
  error?: boolean
  disabled?: boolean
  freeSolo?: boolean
  dataTest?: string
  value: AutocompleteInputValue
  helperText?: string
  onChange: (data: AutocompleteInputValue) => void
}

const optionsToMap = (options: StateObject[]): Record<string, string> =>
  options.reduce<Record<string, string>>((result, state) => ({ ...result, [state.key]: state.display }), {})

const optionsToKeys = (options: StateObject[]): (number | string)[] => options.map(state => state.key)

export function AutocompleteInput({
  label = "",
  options,
  onChange,
  error,
  disabled,
  dataTest,
  value,
  helperText,
  freeSolo = true,
}: AutocompleteInputProps): JSX.Element {
  const stateKeys = optionsToKeys(options ?? [])
  const stateMap = optionsToMap(options ?? [])

  const onChangeHandler = useCallback(
    (_: unknown, data: AutocompleteInputValue) => {
      onChange(data)
    },
    [onChange]
  )

  const validatedValue = options.find(({ key }) => key === value) ? value : null

  return (
    <StyledFormControl error={error}>
      <Autocomplete
        options={stateKeys}
        data-test={dataTest}
        freeSolo={freeSolo}
        clearOnBlur
        blurOnSelect
        value={validatedValue}
        style={DEFAULT_AUTOCOMPLETE_STYLES}
        disabled={disabled}
        getOptionLabel={option => stateMap[option] || ""}
        onChange={onChangeHandler}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
            disabled={disabled}
            variant="outlined"
            size="small"
            error={Boolean(error)}
            helperText={helperText}
          />
        )}
      />
    </StyledFormControl>
  )
}
