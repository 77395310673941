import { DateField } from "common/form-components"
import { documentActions, useDocumentStore } from "documents/store"
import { useShallow } from "zustand/react/shallow"
import { providersSelectors } from "documents/store/providers"
import { Appointment, Provider } from "documents/types"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Alert } from "@mui/material"
import { formatDate } from "utils"
import { EditableSelect } from "evenup-ui/EditableSelect"

interface ProviderInputsProps {
  initialProviderId?: Nullable<Provider["id"]>
  onChange: (data: {
    isValid: boolean
    providerId: Nullable<Provider["id"]>
    dateOfService: Nullable<Appointment["dateOfService"]>
  }) => void
  renderProviderInput: (element: JSX.Element) => JSX.Element
  renderDateOfServiceInput: (element: JSX.Element) => JSX.Element
  renderError: (element: JSX.Element) => JSX.Element
}

export function ProviderInputs({
  initialProviderId,
  onChange,
  renderProviderInput,
  renderDateOfServiceInput,
  renderError,
}: ProviderInputsProps) {
  const [providerId, setProvider] = useState<Nullable<Appointment["providerId"]>>(initialProviderId ?? null)
  const [dateOfService, setDateOfService] = useState<Nullable<Appointment["dateOfService"]>>(null)

  const providers = useDocumentStore(useShallow(providersSelectors.getProviders))
  const appointments = useDocumentStore(useShallow(state => state.appointments))

  const areRequiredFieldsFilled = !!providerId && !!dateOfService
  const isValid = useMemo(() => {
    if (!areRequiredFieldsFilled) return false
    return !Object.values(appointments).find(
      appointment => appointment.dateOfService === dateOfService && appointment.providerId === providerId
    )
  }, [areRequiredFieldsFilled, appointments, dateOfService, providerId])

  const handleProviderCreate = useCallback((name: string) => documentActions.createProvider({ name }), [])
  const handleProviderRename = useCallback(
    (id: Provider["id"], name: Provider["name"]) => documentActions.renameProvider({ id, name }),
    []
  )

  useEffect(() => {
    onChange({ isValid, providerId, dateOfService })
  }, [isValid, providerId, dateOfService, onChange])

  return (
    <>
      {renderProviderInput(
        <EditableSelect
          itemType="provider"
          onRename={handleProviderRename}
          onCreate={handleProviderCreate}
          sx={{ width: "100%" }}
          value={providerId}
          onChange={setProvider}
          options={providers}
        />
      )}
      {renderDateOfServiceInput(
        <DateField
          fieldProps={{ size: "small", fullWidth: true }}
          initialValue={dateOfService}
          onChange={setDateOfService}
          label=""
        />
      )}
      {areRequiredFieldsFilled &&
        !isValid &&
        renderError(
          <Alert variant="outlined" severity="error" data-test="appointment-error">
            Appointment at {formatDate(dateOfService, "MM/dd/yyyy", true)} for &quot;
            {providers.find(provider => provider.id === providerId)?.name}&quot; already exists
          </Alert>
        )}
    </>
  )
}
