import { Link as RouterLink } from "react-router-dom"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import VisibilityIcon from "@mui/icons-material/Visibility"
import DeleteIcon from "@mui/icons-material/Delete"
import { makeStyles } from "tss-react/mui"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "../../react-query/constants"
import { deleteCase } from "../../api"

import { formatDate } from "../../utils"
import { Column } from "common/tables/types"
import { Case } from "case/types"

const NOT_APPLICABLE = "N/A"

const useStyles = makeStyles()(theme => ({
  hideHyperlinks: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      fontWeight: 500,
    },
  },
}))

const CaseNameCell = ({ record }: { record: Case }) => {
  const { classes } = useStyles()
  return (
    <RouterLink className={classes.hideHyperlinks} to={"/demands/" + record.pk}>
      {record.name}
    </RouterLink>
  )
}

const FirmCell = ({ record }: { record: Case }) => {
  return record?.firm?.name || NOT_APPLICABLE
}

const CreatedByCell = ({ record }: { record: Case }) => {
  return record?.creator ? `${record.creator?.first_name} ${record.creator?.last_name}` : NOT_APPLICABLE
}

const ActionsCell = ({ record }: { record: Case }) => {
  const queryClient = useQueryClient()
  const { mutate: handleDelete } = useMutation(deleteCase, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.cases]),
  })

  return (
    <>
      <IconButton component={RouterLink} to={"/demands/" + record.pk}>
        <VisibilityIcon color="primary" />
      </IconButton>
      <IconButton component={RouterLink} to={`/demands/${record.pk}/search`}>
        <SearchIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          if (confirm("Delete the case?")) {
            handleDelete(record.pk)
          }
        }}
      >
        <DeleteIcon />
      </IconButton>
    </>
  )
}

export const columns: Column<Case>[] = [
  {
    id: "case_name",
    text: "Case Name",
    align: "left",
    cellContentComponent: CaseNameCell,
  },
  {
    id: "firm",
    text: "Firm",
    align: "left",
    cellContentComponent: FirmCell,
  },
  {
    id: "created_by",
    text: "Created By",
    align: "left",
    cellContentComponent: CreatedByCell,
  },
  {
    id: "state",
    text: "State",
    align: "left",
    cellContentComponent: ({ record }) => {
      return record.state ?? NOT_APPLICABLE
    },
  },
  {
    id: "county",
    text: "County",
    align: "left",
    cellContentComponent: ({ record }) => {
      return record.county ?? NOT_APPLICABLE
    },
  },
  {
    id: "created_at",
    text: "Date Created",
    sortable: true,
    cellContentComponent: ({ record }) => {
      return <Typography noWrap={true}>{formatDate(record.created_at) ?? NOT_APPLICABLE}</Typography>
    },
  },
  {
    id: "actions",
    text: "Actions",
    align: "right",
    cellContentComponent: ActionsCell,
  },
]
