import React from "react"
import { useNavigate } from "react-router-dom"
import { FirmData } from "./Firm"

import { FirmFormController } from "./FirmFormController"

export function NewFirm(): React.ReactElement {
  const navigate = useNavigate()
  const onCreate = (firm: FirmData) => {
    if (firm.pk) {
      navigate(`/settings/firms/${firm.pk}/edit`, { replace: true })
    }
  }

  return <FirmFormController onCreate={onCreate} />
}
