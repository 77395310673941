import { MedicronCalendarAppointmentByYearAndMonthAndDay } from "documents/exhibits/MedicronCalendar/types"
import { groupBy, last } from "lodash"
import { MedicronCalendarData } from "documents/store/types"
import {
  ABSENT_OF_TREATMENT,
  IncidentType,
  LAST_ENTITY_TYPE,
  TREATMENT_TIMELINE_ENTITY_TYPE,
  TREATMENT_TIMELINE_INCIDENT_MOMENT_TYPE,
  TREATMENT_TIMELINE_INCIDENT_TYPE,
  TreatmentTimelineEntity,
  TreatmentTimelineEntityLine,
  TreatmentTimelineEntityPoint,
} from "./types"
import { formatDate } from "utils"

const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate()
}

export const getTreatmentTimelineDayText = (
  month: number | string,
  year: number | string,
  day: number | string
): string => {
  const shortMonthName = new Date(1900, +month - 1).toLocaleString("default", { month: "short" })

  return `${+day + 1}-${shortMonthName}-${year}`
}

export const getTreatmentTimelineMonthText = (month: number | string, year: number | string): string => {
  const shortMonthName = new Date(1900, +month - 1).toLocaleString("default", { month: "short" })

  return `${shortMonthName}-${String(year).slice(-2)}`
}

export const shouldInsertMonthlyAbsentOfTreatments = (
  previous: { month: number | string; year: number | string },
  current: { month: number | string; year: number | string }
): boolean => new Date(+previous.year, +previous.month, 1) < new Date(+current.year, +current.month - 1, 1)

export const shouldInsertYearlyAbsentOfTreatments = (
  previousYear: number | string,
  currentYear: number | string
): boolean => +currentYear - +previousYear > 1

const getIncidentData = ({
  start,
  end,
  dateOfIncident,
}: {
  start: Date
  end: Date
  dateOfIncident: Date
}): IncidentType => {
  const validatedDateOfIncident = new Date(`${formatDate(dateOfIncident, "MM/dd/yyyy", true)} 00:00`)

  if (end.valueOf() < validatedDateOfIncident.valueOf()) {
    return { type: TREATMENT_TIMELINE_INCIDENT_TYPE.PRIOR }
  }

  if (start.valueOf() > validatedDateOfIncident.valueOf()) {
    return { type: TREATMENT_TIMELINE_INCIDENT_TYPE.PAST }
  }

  const percent = (validatedDateOfIncident.valueOf() - start.valueOf()) / (end.valueOf() - start.valueOf())

  return {
    type: TREATMENT_TIMELINE_INCIDENT_MOMENT_TYPE,
    percent: Math.round(percent * 100),
    text: formatDate(dateOfIncident, "MM/dd/yyyy", true),
  }
}

const getIncidentDataForDays = ({
  currentDate,
  dateOfIncident,
}: {
  currentDate: Date
  dateOfIncident: Date
}): IncidentType => {
  const validatedDateOfIncident = new Date(`${formatDate(dateOfIncident, "MM/dd/yyyy", true)} 00:00`)

  if (currentDate.valueOf() < validatedDateOfIncident.valueOf()) {
    return { type: TREATMENT_TIMELINE_INCIDENT_TYPE.PRIOR }
  }

  if (currentDate.valueOf() > validatedDateOfIncident.valueOf()) {
    return { type: TREATMENT_TIMELINE_INCIDENT_TYPE.PAST }
  }

  return {
    type: TREATMENT_TIMELINE_INCIDENT_MOMENT_TYPE,
    percent: 0,
    text: formatDate(validatedDateOfIncident, "MM/dd/yyyy", true),
  }
}

export const getTreatmentTimelineEntitiesForDays = (
  appointments: MedicronCalendarAppointmentByYearAndMonthAndDay,
  medicronData: MedicronCalendarData
) => {
  const result: (TreatmentTimelineEntity & { month: string; year: string; day: string })[] = []

  Object.entries(appointments).forEach(([year, months]) => {
    Object.entries(months).forEach(([month, days]) => {
      Object.entries(days).forEach(([day, appointments]) => {
        const lines: TreatmentTimelineEntityLine[] = Object.values(medicronData.providers).map(provider => {
          const ungroupedPoints: TreatmentTimelineEntityPoint[] = []

          appointments.forEach(appointment => {
            const appointmentData = medicronData.appointments[appointment.appointmentId]

            if (appointmentData.providerId === provider.id) {
              const percent = appointmentData.timeOfService
                ? Math.round((+appointmentData.timeOfService.slice(0, 2) / 24) * 100)
                : 50

              ungroupedPoints.push({ id: appointment.appointmentId, percent })
            }
          })

          const groupedPoints = groupBy(ungroupedPoints, point => point.percent)
          const points: TreatmentTimelineEntityPoint[] = Object.values(groupedPoints).map(group => ({
            ...group[0],
            number: group.length,
          }))

          return { id: provider.id, color: provider.color, points }
        })

        const text = getTreatmentTimelineDayText(month, year, +day - 1)

        result.push({
          year,
          month,
          day,
          text,
          type: TREATMENT_TIMELINE_ENTITY_TYPE.DAY,
          lines,
          incident: getIncidentDataForDays({
            currentDate: new Date(+year, +month - 1, +day),
            dateOfIncident: medicronData.dayOfIncident,
          }),
        })
      })
    })
  })

  const lastResult = last(result)

  if (lastResult) {
    const text = getTreatmentTimelineDayText(+lastResult.month, lastResult.year, +lastResult.day)

    result.push({
      ...lastResult,
      type: LAST_ENTITY_TYPE,
      text,
      treatmentEntityType: TREATMENT_TIMELINE_ENTITY_TYPE.DAY,
    })
  }

  return result
}

export const getTreatmentTimelineEntitiesForMonths = (
  appointments: MedicronCalendarAppointmentByYearAndMonthAndDay,
  medicronData: MedicronCalendarData
) => {
  const result: (TreatmentTimelineEntity & { month: string; year: string })[] = []

  Object.entries(appointments).forEach(([year, months]) => {
    Object.entries(months).forEach(([month, days]) => {
      const lastResult = last(result)
      const shouldInsertAbsent =
        lastResult && shouldInsertMonthlyAbsentOfTreatments(lastResult, { month, year })

      if (shouldInsertAbsent) {
        const startText = getTreatmentTimelineMonthText(+lastResult.month + 1, lastResult.year)
        const text = `${startText}-${getTreatmentTimelineMonthText(month, year)}`

        result.push({
          year,
          month,
          startText,
          text,
          type: ABSENT_OF_TREATMENT,
          incident: getIncidentData({
            start: new Date(+lastResult.year, +lastResult.month, 1),
            end: new Date(+year, +month - 1, 1),
            dateOfIncident: medicronData.dayOfIncident,
          }),
        })
      }

      const lines = Object.values(medicronData.providers).map(provider => {
        const ungroupedPoints: TreatmentTimelineEntityPoint[] = []

        Object.entries(days).forEach(([day, appointments]) => {
          appointments.forEach(appointment => {
            const appointmentData = medicronData.appointments[appointment.appointmentId]

            if (appointmentData.providerId === provider.id) {
              const numberOfAppointmentsInTheDay = appointments.filter(
                ({ appointmentId }) => medicronData.appointments[appointmentId].providerId === provider.id
              ).length
              const percent = Math.round((+day / daysInMonth(+month, +year)) * 100)

              ungroupedPoints.push({
                number: numberOfAppointmentsInTheDay,
                id: appointment.appointmentId,
                percent,
              })
            }
          })
        })

        const groupedPoints = groupBy(ungroupedPoints, point => point.id)
        const points: TreatmentTimelineEntityPoint[] = Object.values(groupedPoints).map(group => ({
          ...group[0],
        }))

        return { id: provider.id, color: provider.color, points }
      })

      const text = getTreatmentTimelineMonthText(month, year)

      result.push({
        year,
        month,
        text,
        type: TREATMENT_TIMELINE_ENTITY_TYPE.MONTH,
        lines,
        incident: getIncidentData({
          start: new Date(+year, +month - 1, 1),
          end: new Date(+year, +month, 1),
          dateOfIncident: medicronData.dayOfIncident,
        }),
      })
    })
  })

  const lastResult = last(result)

  if (lastResult) {
    const text = getTreatmentTimelineMonthText(+lastResult.month + 1, lastResult.year)

    result.push({
      ...lastResult,
      type: LAST_ENTITY_TYPE,
      text,
      treatmentEntityType: TREATMENT_TIMELINE_ENTITY_TYPE.MONTH,
    })
  }

  return result
}

export const getTreatmentTimelineEntitiesForYears = (
  appointments: MedicronCalendarAppointmentByYearAndMonthAndDay,
  medicronData: MedicronCalendarData
) => {
  const result: (TreatmentTimelineEntity & { year: string })[] = []

  Object.entries(appointments).forEach(([year, months]) => {
    const lastResult = last(result)
    const shouldInsertAbsent = lastResult && shouldInsertYearlyAbsentOfTreatments(lastResult.year, year)

    if (shouldInsertAbsent) {
      result.push({
        year,
        startText: String(+lastResult.year + 1),
        text: `${+lastResult.year + 1}-${year}`,
        type: ABSENT_OF_TREATMENT,
        incident: getIncidentData({
          start: new Date(+lastResult.year + 1, 0, 1),
          end: new Date(+year, 0, 1),
          dateOfIncident: medicronData.dayOfIncident,
        }),
      })
    }

    const lines: TreatmentTimelineEntityLine[] = Object.values(medicronData.providers).map(provider => {
      const points: TreatmentTimelineEntityPoint[] = []

      Object.entries(months).forEach(([month, days]) => {
        Object.entries(days).forEach(([day, appointments]) => {
          appointments.forEach(appointment => {
            const appointmentData = medicronData.appointments[appointment.appointmentId]

            if (appointmentData.providerId === provider.id) {
              const numberOfAppointmentsInTheDay = appointments.filter(
                ({ appointmentId }) => medicronData.appointments[appointmentId].providerId === provider.id
              ).length
              const percent =
                Math.round(((+month - 1) / 12) * 100) +
                Math.round((+day / daysInMonth(+month, +year) / 12) * 100)

              points.push({ id: appointment.appointmentId, number: numberOfAppointmentsInTheDay, percent })
            }
          })
        })
      })

      return { id: provider.id, color: provider.color, points }
    })

    result.push({
      year,
      text: year,
      type: TREATMENT_TIMELINE_ENTITY_TYPE.YEAR,
      lines,
      incident: getIncidentData({
        start: new Date(+year, 0, 1),
        end: new Date(+year + 1, 0, 1),
        dateOfIncident: medicronData.dayOfIncident,
      }),
    })
  })

  const lastResult = last(result)

  if (lastResult) {
    result.push({
      ...lastResult,
      type: LAST_ENTITY_TYPE,
      text: String(+lastResult.year + 1),
      treatmentEntityType: TREATMENT_TIMELINE_ENTITY_TYPE.YEAR,
    })
  }

  return result
}
