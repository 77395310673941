import { useCallback } from "react"
import { useMutation } from "@tanstack/react-query"
import { RevisionRequestForm } from "./RevisionRequestForm"
import {
  NewRevisionRequest,
  RevisionRequest,
  RevisionRequestData,
  RevisionRequestUpload,
} from "common/models/revision-request"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { revisionService } from "api/services/revision"
import { PendingFile } from "common/form-components/files/types"
import { useFileUploader } from "common/file-uploader/useFileUploader"

interface RevisionRequestFormControllerProps {
  requestId: string
  request?: RevisionRequest
  onSubmitted?: (request: RevisionRequest) => void
  onUpdated?: (request: RevisionRequest) => void
  onFailed?: () => void
  onCancel?: () => void
}

export const RevisionRequestFormController = ({
  requestId,
  request,
  onSubmitted,
  onFailed,
  onCancel,
}: RevisionRequestFormControllerProps): JSX.Element => {
  const { showErrorMessage } = useHandleMessages()
  const createRevisionRequestMutation = useMutation(revisionService.createRevisionRequest)
  const updateRevisionRequestMutation = useMutation(revisionService.updateRevisionRequest)
  const { uploadFiles, showUploadErrors } = useFileUploader()
  const handleSubmit = useCallback(
    async (nextRevisionRequest: NewRevisionRequest | RevisionRequestData, files: PendingFile[]) => {
      let revisionRequest: Awaited<ReturnType<typeof revisionService.createRevisionRequest>>

      try {
        const { hasFailures, items: uploadResults } = await uploadFiles(files.map(({ file }) => file))

        if (hasFailures) {
          showUploadErrors(uploadResults)
          throw new Error("Upload error")
        }

        if (files.length) {
          nextRevisionRequest.setUploads(
            files
              .map(({ file, type }, i) =>
                uploadResults[i].success
                  ? {
                      upload: uploadResults[i].uploadId as string,
                      name: file.name,
                      type,
                    }
                  : null
              )
              .filter((upload): upload is NonNullable<RevisionRequestUpload> => !!upload)
          )
        }

        if (nextRevisionRequest instanceof NewRevisionRequest) {
          revisionRequest = await createRevisionRequestMutation.mutateAsync({
            requestId,
            data: nextRevisionRequest,
          })
        } else {
          revisionRequest = await updateRevisionRequestMutation.mutateAsync({
            requestId,
            data: nextRevisionRequest,
          })
        }
        // "Catch clause variable type annotation must be 'any' or 'unknown' if specified"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        let message = error?.response?.error || "Unable to create or update Revision Request"
        try {
          const errorMessage = JSON.parse(error?.message)
          if (errorMessage?.error) {
            message = errorMessage.error
          }
        } catch {
          // do nothing
        }
        showErrorMessage({ message: message, error })

        return onFailed && onFailed()
      }

      onSubmitted && onSubmitted(revisionRequest)
    },
    [
      requestId,
      showErrorMessage,
      createRevisionRequestMutation,
      updateRevisionRequestMutation,
      onFailed,
      onSubmitted,
      uploadFiles,
      showUploadErrors,
    ]
  )

  return <RevisionRequestForm onSubmit={handleSubmit} request={request} onCancel={onCancel} />
}
