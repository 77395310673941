import { DEPENDENT_STATUS_KEY, FAMILY_STATUS_KEY } from "../constants"
import { PlaintiffProps } from "requests/RequestForm/AdditionalInfoRevamp"
import { useRequestAssets } from "requests/RequestAssets"
import { StyledTypographyValue } from "../styled"
import { VisiblePlaintiffFieldDisplay } from "../types"
import { Chip } from "@mui/material"

export const VisibilePlaintiffFieldDisplay = ({ plaintiff, field }: VisiblePlaintiffFieldDisplay) => {
  const { [`family-status`]: familyStatusOptions, [`dependent-status`]: dependentStatusOptions } =
    useRequestAssets()

  switch (field) {
    case DEPENDENT_STATUS_KEY as keyof PlaintiffProps:
      return (
        <StyledTypographyValue>
          {dependentStatusOptions
            ?.filter(({ key: optionKey }) => (plaintiff[field] as string[]).includes(optionKey))
            ?.map(({ display }) => <Chip key={display} label={display} />)}
        </StyledTypographyValue>
      )
    case FAMILY_STATUS_KEY:
      return (
        <StyledTypographyValue>
          {
            familyStatusOptions?.find(({ key: optionKey }) => optionKey === plaintiff[field])
              ?.display as string
          }
        </StyledTypographyValue>
      )
    default:
      return <StyledTypographyValue>{plaintiff[field] as string}</StyledTypographyValue>
  }
}
