import React from "react"
import { RenderElementProps } from "slate-react"
import { EmptySpaceElement } from "../CustomEditor"
import { useSuggestionAttributes } from "./hooks/useSuggestionAttributes"

interface EmptySpaceProps extends RenderElementProps {
  element: EmptySpaceElement
}

export const EmptySpace: React.FC<EmptySpaceProps> = React.memo(function EmptySpace({
  attributes,
  element,
  children,
}) {
  const suggestionAttributes = useSuggestionAttributes(element)

  return (
    <span {...attributes} {...suggestionAttributes} data-empty-space>
      {children}
    </span>
  )
})
