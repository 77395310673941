import { useCallback, useEffect, useMemo, useState } from "react"
import { makeStyles } from "tss-react/mui"
import {
  useSensors,
  useSensor,
  DndContext,
  DragOverlay,
  MouseSensor,
  closestCorners,
  KeyboardCode,
  DragStartEvent,
  DragEndEvent,
  CollisionDetection,
} from "@dnd-kit/core"
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers"
import { NestedList, NestedListProps } from "./NestedList"
import { SortableNestedListItem } from "./SortableNestedListItem"
import { SortableEmptyPlaceholder } from "./SortableEmptyPlaceholder"
import { DROP_TARGET_TYPE, ITEM_REF } from "./constants"
import {
  getFlatDataProjection,
  getDataFromProjection,
  normalizeFromProjectionWithRef,
} from "./utils/projection"
import { isDragging } from "./utils/drag"
import { handleDrop } from "./utils/drop"
import { ItemType, ProjectionData, ProjectionItemData } from "./types"
import { SortableContext, SortableContextType } from "./SortableContext"
import { createPortal } from "react-dom"
import { useTheme } from "@emotion/react"

const useStyles = makeStyles()(() => ({
  dragOverlay: {
    opacity: 0.9,
    cursor: "grab",
  },
}))

function DefaultEmptyPlaceholder(): JSX.Element {
  return <div>Drop items here</div>
}

function defaultCanDrag() {
  return true
}

export interface ReorderOperation<TData> {
  parent: Nullable<ItemType<TData>>
  item: ItemType<TData>
  index: number
}

export interface DndProps<TData> {
  readonly?: false

  collisionDetection?: CollisionDetection
  canDrag?: (item: ProjectionItemData<TData>, items: ProjectionData<TData>) => boolean
  canDrop?: (item: ProjectionItemData<TData>, draggingItemId: string, items: ProjectionData<TData>) => boolean
  canDropAsChild?: (
    item: ProjectionItemData<TData>,
    draggingItemId: string,
    items: ProjectionData<TData>
  ) => boolean

  onUpdate: (
    items: ItemType<TData>[],
    operation: Nullable<ReorderOperation<TData>>
  ) => void | PromiseLike<void>
}

type ReadonlyProps<TData> = {
  [K in keyof Omit<DndProps<TData>, "readonly">]?: never
} & {
  readonly: true
}

export type SortableNestedListProps<TData, TContentProps, TPlaceholderProps> = Omit<
  NestedListProps<TData, TContentProps, TPlaceholderProps>,
  "items" | "readonly" | "PlaceholderComponent" | "ItemComponent" | "contentProps" | "placeholderProps"
> & {
  items: TData[]
  uniqueKey: Exclude<keyof TData, "children">
  PlaceholderComponent?: NestedListProps<TData, TContentProps, TPlaceholderProps>["PlaceholderComponent"]
  ItemComponent?: NestedListProps<TData, TContentProps, TPlaceholderProps>["ItemComponent"]
} & (DndProps<TData> | ReadonlyProps<TData>) &
  (Record<string, never> extends TContentProps ? { contentProps?: null } : { contentProps: TContentProps }) &
  (Record<string, never> extends TPlaceholderProps
    ? { placeholderProps?: null }
    : { placeholderProps: TPlaceholderProps })

export function SortableNestedList<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData extends Record<string, any>,
  TContentProps extends BaseObject,
  TPlaceholderProps extends BaseObject,
>({
  items,
  uniqueKey,

  canDrag: checkIdCanDrag = defaultCanDrag,
  canDrop: checkIfCanDrop,
  canDropAsChild,
  collisionDetection = closestCorners,

  contentProps: contentPropsArg,
  placeholderProps: placeholderPropsArg,
  PlaceholderComponent = DefaultEmptyPlaceholder,
  showPlaceholder,
  ItemComponent = SortableNestedListItem,
  ItemContentComponent,

  onUpdate,

  readonly,
}: SortableNestedListProps<TData, TContentProps, TPlaceholderProps>) {
  const { classes } = useStyles()
  const [draggingItemId, setDraggingItemId] = useState<Nullable<string>>(null)
  const projectedData = getFlatDataProjection(items, uniqueKey)
  const renderData = normalizeFromProjectionWithRef(projectedData)
  const theme = useTheme()

  // Type cast is pretty safe here because this prop is optional only in case when TContentProps is empty object
  const contentProps = contentPropsArg ?? ({} as TContentProps)
  // Type cast is pretty safe here because this prop is optional only in case when TContentProps is empty object
  const placeholderProps = placeholderPropsArg ?? ({} as TPlaceholderProps)

  useEffect(() => {
    if (draggingItemId && !projectedData[draggingItemId]) {
      document.dispatchEvent(new KeyboardEvent("keydown", { code: KeyboardCode.Esc }))
    }
  }, [projectedData, draggingItemId])

  const sensors = useSensors(useSensor(MouseSensor))
  const handleDragStart = (event: DragStartEvent) => setDraggingItemId(String(event.active.id))
  const handleDragEnd = (event: DragEndEvent) => {
    if (readonly) return

    setDraggingItemId(null)

    if (event.over) {
      const targetItemData = event.over.data.current as { id?: string; type?: DROP_TARGET_TYPE } | undefined

      if (
        !targetItemData?.id ||
        !targetItemData?.type ||
        !Object.values<string | undefined>(DROP_TARGET_TYPE).includes(targetItemData?.type)
      ) {
        return
      }

      const currentItemId = String(event.active.id)
      const [updatedData, operation] = handleDrop(
        projectedData,
        currentItemId,
        targetItemData.id,
        targetItemData.type
      )

      onUpdate(
        getDataFromProjection(updatedData),
        operation
          ? {
              item: updatedData[operation.itemId][ITEM_REF],
              parent: operation.parentId !== null ? updatedData[operation.parentId][ITEM_REF] : null,
              index: operation.index,
            }
          : null
      )
    }
  }

  const canDragCallback = useCallback(
    (sourceId: string): boolean => {
      return checkIdCanDrag(projectedData[sourceId], projectedData)
    },
    [checkIdCanDrag, projectedData]
  )

  const canDropCallback = useCallback(
    (targetId: string): boolean => {
      const canDropToItem = draggingItemId !== null && !isDragging(projectedData, draggingItemId, targetId)

      return (
        canDropToItem &&
        (!checkIfCanDrop || checkIfCanDrop(projectedData[targetId], draggingItemId, projectedData))
      )
    },
    [checkIfCanDrop, draggingItemId, projectedData]
  )

  const canDropAsChildCallback = useCallback(
    (targetId: string): boolean => {
      const canDropToItem = draggingItemId !== null && !isDragging(projectedData, draggingItemId, targetId)

      return (
        canDropToItem &&
        (!canDropAsChild || canDropAsChild(projectedData[targetId], draggingItemId, projectedData))
      )
    },
    [canDropAsChild, draggingItemId, projectedData]
  )

  const sortableContextValue = useMemo<SortableContextType>(
    () => ({
      canDrag: canDragCallback,
      canDrop: canDropCallback,
      canDropAsChild: canDropAsChildCallback,

      readonly: !!readonly,
    }),
    [canDropCallback, canDragCallback, canDropAsChildCallback, readonly]
  )

  return (
    <DndContext
      modifiers={[restrictToFirstScrollableAncestor]}
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      collisionDetection={collisionDetection}
    >
      <SortableContext.Provider value={sortableContextValue}>
        <NestedList
          items={renderData}
          contentProps={contentProps}
          placeholderProps={{ ...placeholderProps, ContentComponent: PlaceholderComponent }}
          ItemComponent={ItemComponent}
          ItemContentComponent={ItemContentComponent}
          PlaceholderComponent={
            SortableEmptyPlaceholder as NestedListProps<
              TData,
              TContentProps,
              TPlaceholderProps & {
                ContentComponent: NestedListProps<
                  TData,
                  TContentProps,
                  TPlaceholderProps
                >["PlaceholderComponent"]
              }
            >["PlaceholderComponent"]
          }
          showPlaceholder={showPlaceholder}
          readonly={!!readonly}
        />
        {createPortal(
          <DragOverlay className={classes.dragOverlay} style={{ zIndex: theme.zIndex.modal }}>
            {draggingItemId && projectedData[draggingItemId] && (
              <ItemComponent
                item={{ ...projectedData[draggingItemId], children: [] }}
                isChild={false}
                inline
                content={
                  <ItemContentComponent
                    {...contentProps}
                    item={{ ...projectedData[draggingItemId], children: [] }}
                    compact
                  />
                }
                readonly={!!readonly}
              />
            )}
          </DragOverlay>,
          document.body
        )}
      </SortableContext.Provider>
    </DndContext>
  )
}
