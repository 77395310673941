import { DocumentStructureRowData } from "common/models/library"
import { REF_ID } from "./document-structure/constants"
import { StyledListContainer } from "./document-structure/styled"
import { SortableNestedList } from "common/nested-list/SortableNestedList"
import {
  DocumentStructureListItem,
  DocumentStructureListItemContent,
} from "./document-structure/DocumentStructureListItem"

interface DocumentStructureDetailsProps {
  sections: DocumentStructureRowData["initialSections"]
}

export function DocumentStructureDetails({ sections }: DocumentStructureDetailsProps): JSX.Element {
  return (
    <StyledListContainer>
      <SortableNestedList
        items={sections}
        uniqueKey={REF_ID}
        readonly
        ItemComponent={DocumentStructureListItem}
        ItemContentComponent={DocumentStructureListItemContent}
        contentProps={null}
        PlaceholderComponent={() => null}
      />
    </StyledListContainer>
  )
}
