import React, { useEffect, useMemo } from "react"
import { getIntakeAsset } from "api"
import { STALE_TIMEOUT, queryKeys } from "react-query/constants"
import { useQueries, useQueryClient } from "@tanstack/react-query"
import { AssetsContext, AssetsContextType } from "./context"
import { useParams } from "react-router-dom"
import { ASSET_TYPES } from "requests/constants"
import { useLocationStore } from "app/location/store"

const assetQueries = ASSET_TYPES.map(assetType => {
  return {
    queryKey: [queryKeys.intakeAsset, assetType],
    queryFn: getIntakeAsset,
    staleTime: STALE_TIMEOUT.STATIC_DATA,
    meta: {
      disableLoader: true,
    },
  }
})

export function RequestAssets({ children }: { children: React.ReactNode }): JSX.Element {
  const { id } = useParams()
  const isNewRequest = useLocationStore(location => location.pathname.startsWith("/requests/new"))
  const isRequest = !!id || isNewRequest

  const assetResults = useQueries({
    queries: assetQueries.map(query => ({
      ...query,
      enabled: isRequest,
      suspense: isRequest,
    })),
  })

  const queryClient = useQueryClient()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isRequest) {
        assetQueries.forEach(query => queryClient.prefetchQuery(query))
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [queryClient, isRequest])

  const assets = useMemo(() => {
    return assetResults.reduce<AssetsContextType>((assetMap, asset, index) => {
      if (asset.isSuccess) {
        const assetKey = ASSET_TYPES[index]
        assetMap[assetKey] = asset?.data?.results ?? []
      }
      return assetMap
    }, {})
  }, [assetResults])

  return <AssetsContext.Provider value={assets}>{children}</AssetsContext.Provider>
}
