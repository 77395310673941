export const BODY_FONT_SIZE_NUM = 14
export const BODY_FONT_SIZE = `${BODY_FONT_SIZE_NUM}px`
export const H2_FONT_SIZE = "24px"
export const H3_FONT_SIZE = "18px"
export const H3_FONT_SIZE_PRINT = "16px"
export const ESTIMATE_NUMBER_FONT_SIZE = "36px"
export const ESTIMATE_NUMBER_FONT_SIZE_PRINT = "16px"
export const BADGE_FONT_SIZE = "16px"
export const BADGE_LABEL_FONT_SIZE = "10px"
export const H1_FONT_SIZE = "30px"

export const SETTLEMENTS_SUPPORT_EMAIL = "reposupport@evenuplaw.com"

export const SETTLEMENT_IMPORT_INSTRUCTION_URL =
  "https://storage.googleapis.com/evenup-static-files/documents/Settlement%20Upload%20Instructions.pdf"
