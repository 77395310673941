import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { Link } from "react-router-dom"
import { makeStyles } from "tss-react/mui"
import Tooltip from "@mui/material/Tooltip"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Popover from "@mui/material/Popover"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { DocumentIcon, MissingDocsIcon } from "common/assets"
import { APP_ID, HEADER_NAV_HEIGHT_SPACE_MULTIPLIER, HEADER_NAV_Z_INDEX } from "app/constants"
import { Loading } from "common"

import { LeftNavContext, RequestContext } from "../context"
import FormSections from "../FormSections"
import { DemandPreview } from "../DemandPreview"
import { ResetDemand } from "../ResetDemand"
import { DEMAND_FLOW_WRAPPER, LEFT_NAV_PANEL_ORDER } from "../constants"
import { DEMAND_SECTION } from "../steps/enums"
import { FORM_SECTION_ROUTES } from "../steps/constants"
import { LeftNavStates, setLeftNavStateFromLocalStorage } from "./utils"
import { useUserPermissions } from "permissions/usePermissions"
import { LeftNavCaseSection } from "./LeftNavCaseSection"
import { LeftNavLink } from "./LeftNavLink"
import useUser from "hooks/useUser"
import { isNotOSF } from "common/permission"
import Button from "evenup-ui/Button"

const useStyles = makeStyles<LeftNavStyleProps>()((theme, { open }) => ({
  demandFlowWrapperWithSidebar: {
    order: LEFT_NAV_PANEL_ORDER,
    minWidth: open ? "300px" : "80px",
    marginLeft: theme.spacing(-6),
    transition: "width 0.25s",
    paddingTop: theme.spacing(5),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      margin: theme.spacing(0, 8, 0, -2),
    },
  },
  avatarWrapper: {
    width: "100%",
    marginBottom: open ? theme.spacing(6) : 0,
  },
  innerWrapper: {
    overflowY: "auto",
    height: "100%",
    width: "100%",
    paddingBottom: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
  },
  container: {
    top: 0,
    left: 0,
    position: "fixed",
    bottom: 0,
    overflow: "visible",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    marginTop: theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER),
    zIndex: HEADER_NAV_Z_INDEX - 1,
    width: open ? "300px" : "80px",
    transition: "width 0.25s",
    backgroundColor: theme.palette.grey[100],
  },
  icon: {
    margin: open ? theme.spacing(2, "-12px", 1, "auto") : theme.spacing(2, "auto"),
    position: open ? "absolute" : "inherit",
    right: open ? 0 : "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  popover: {
    marginLeft: theme.spacing(3),
  },
  openCloseIcon: {
    color: theme.palette.getContrastText(theme.palette.common.black),
    backgroundColor: theme.palette.common.black,
    width: theme.spacing(3),
    height: theme.spacing(3),
    zIndex: HEADER_NAV_Z_INDEX - 1,
  },
  flex: {
    display: "flex",
  },
  link: {
    color: theme.palette.background.paper,
    textDecoration: "none",
  },
}))

interface LeftNavStyleProps {
  open: boolean
}

interface ILeftNav {
  caseId?: number
  request: RequestContext
}

export const LeftNav = ({ caseId, request }: ILeftNav): Nullable<JSX.Element> => {
  const barRef = useRef<HTMLDivElement>(null)
  const { user } = useUser()
  const { leftNavTabIsExpanded, setLeftNavTabIsExpanded } = useContext(LeftNavContext)
  const { classes, cx } = useStyles({ open: leftNavTabIsExpanded })
  const [menuPopoverAnchor, setMenuPopoverAnchor] = useState<Nullable<EventTarget & HTMLElement>>(null)
  const { canResetDemandEnabled } = useUserPermissions({
    suspense: false,
  })
  const [demandFlowWrapperElement, setDemandFlowWrapperElement] = useState<HTMLElement | null>()
  const [appElement, setAppElement] = useState<HTMLElement | null>()

  const handleToggleOpenClick = useCallback(() => {
    setLeftNavStateFromLocalStorage(leftNavTabIsExpanded ? LeftNavStates.CLOSED : LeftNavStates.OPEN)
    setLeftNavTabIsExpanded(!leftNavTabIsExpanded)
  }, [leftNavTabIsExpanded, setLeftNavTabIsExpanded])

  const showMenuPopover = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setMenuPopoverAnchor(e.currentTarget)
  }, [])

  const hideMenuPopover = useCallback(() => {
    setMenuPopoverAnchor(null)
  }, [])

  useEffect(() => {
    setDemandFlowWrapperElement(document.getElementById(DEMAND_FLOW_WRAPPER))
    setAppElement(document.getElementById(APP_ID))
  }, [setDemandFlowWrapperElement, setAppElement])

  if (!caseId || !request) {
    return <Loading />
  }

  const showReviewAndGenerate = isNotOSF(user.role)
  if (!demandFlowWrapperElement || !appElement) return null

  return (
    <>
      {ReactDOM.createPortal(
        <div ref={barRef} className={classes.demandFlowWrapperWithSidebar} />,
        demandFlowWrapperElement
      )}
      {ReactDOM.createPortal(
        <div className={classes.container}>
          <div className={classes.innerWrapper}>
            <div className={classes.avatarWrapper}>
              <Avatar
                className={cx(classes.icon, classes.openCloseIcon)}
                onClick={handleToggleOpenClick}
                data-test="left-nav-menu"
              >
                {leftNavTabIsExpanded ? (
                  <KeyboardArrowLeftIcon />
                ) : (
                  <KeyboardArrowRightIcon data-test="left-nav-menu-open" />
                )}
              </Avatar>
            </div>
            {leftNavTabIsExpanded ? (
              <>
                <FormSections request={request} />
                <LeftNavCaseSection />
                {showReviewAndGenerate && (
                  <>
                    <Box m={2} sx={{ display: "flex", justifyContent: "center" }}>
                      <Button color="info">
                        <Link className={classes.link} to="review" data-test="case-review">
                          Review and Generate Document
                        </Link>
                      </Button>
                    </Box>

                    <Box mt={1} mb={1}>
                      <Divider variant="middle" />
                    </Box>
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  className={cx(classes.icon)}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    showMenuPopover(e)
                  }}
                >
                  <Box mt={1}>
                    <DocumentIcon color={"action"} />
                  </Box>
                </div>

                <ClickAwayListener onClickAway={hideMenuPopover}>
                  <>
                    <Popover
                      open={!!menuPopoverAnchor}
                      anchorEl={menuPopoverAnchor}
                      onClose={hideMenuPopover}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className={classes.popover}
                    >
                      <Box onClick={hideMenuPopover} pb={1}>
                        <FormSections request={request} includeMissingDocs={false} />
                        <LeftNavCaseSection />
                        {showReviewAndGenerate && (
                          <LeftNavLink
                            label="Review and Generate Document"
                            toLink="review"
                            dataTest="case-review"
                          />
                        )}
                      </Box>
                    </Popover>
                  </>
                </ClickAwayListener>
              </>
            )}

            {!leftNavTabIsExpanded && (
              <>
                <Box mt={1} mb={1}>
                  <Divider variant="middle" />
                </Box>
                <Link
                  className={cx(classes.icon)}
                  to={`form/${FORM_SECTION_ROUTES[DEMAND_SECTION.MISSING_DOCUMENTS]}`}
                >
                  <Tooltip title="Missing Documents" placement="bottom" arrow>
                    <IconButton>
                      <MissingDocsIcon color="action" />
                    </IconButton>
                  </Tooltip>
                </Link>
              </>
            )}

            {caseId && (
              <Box ml="auto" mr="auto">
                <DemandPreview
                  caseId={caseId}
                  request={request}
                  extendedExhibits={false}
                  iconButton={!leftNavTabIsExpanded}
                />
              </Box>
            )}

            {caseId && canResetDemandEnabled && (
              <Box className={classes.flex} ml="auto" mr="auto" mt={!leftNavTabIsExpanded ? "1rem" : "none"}>
                <ResetDemand caseId={caseId} iconButton={!leftNavTabIsExpanded} />
              </Box>
            )}
          </div>
        </div>,
        appElement
      )}
    </>
  )
}
