import { useRequestContext } from "requests/context"
import { usePermissions } from "permissions/usePermissions"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { StyledAdditionalInfoWrapper, StyledTypographyDescription, StyledTypographyTitle } from "./styled"
import { theme } from "app/theme"
import { AdditionalInfoRevampProps } from "./types"
import { EconomicDamages } from "./EconomicDamages"
import { DOCUMENT_TYPES } from "requests/enums"
import { PlaintiffTabSelector } from "../PlaintiffTabSelector"
import { NonEconomicSection } from "./components/NonEconomicSection"
import { useWatch } from "react-hook-form"

export const AdditionalInfoRevamp = ({ variant }: AdditionalInfoRevampProps) => {
  const { firm, plaintiffsArrayProps } = useRequestContext()
  const { requestRevampSinglePageFormEnabled } = usePermissions({
    firmId: firm?.pk,
    suspense: false,
  })
  const formType = useWatch({ name: "type" })

  const isBasicPlus = formType === DOCUMENT_TYPES.BASIC_PLUS
  const isSimple = formType === DOCUMENT_TYPES.SIMPLE

  if (plaintiffsArrayProps.fields.length <= 0) {
    return <></>
  }

  return (
    <StyledAdditionalInfoWrapper>
      <Card
        sx={{
          boxShadow: "0px 0px 4px 0px #00000040",
          ...(requestRevampSinglePageFormEnabled ? { padding: theme.spacing(1) } : {}),
        }}
        data-test="economic-impact"
      >
        <CardContent>
          {!isBasicPlus && !isSimple && (
            <>
              <Box marginBottom={theme.spacing(2)}>
                <StyledTypographyTitle
                  requestRevampSinglePageFormEnabled={requestRevampSinglePageFormEnabled}
                >
                  Economic Impact
                </StyledTypographyTitle>
                <StyledTypographyDescription
                  requestRevampSinglePageFormEnabled={requestRevampSinglePageFormEnabled}
                >
                  For the preparation of economic loss aspects of the claim (excluding past and future medical
                  expenses), please provide the following information:
                </StyledTypographyDescription>
              </Box>

              <PlaintiffTabSelector>
                {plaintiffIndex => (
                  <Box marginTop={4}>
                    <EconomicDamages documentType={formType} activeTab={plaintiffIndex} variant={variant} />
                    <NonEconomicSection activeTab={plaintiffIndex} variant={variant} />
                  </Box>
                )}
              </PlaintiffTabSelector>
            </>
          )}
        </CardContent>
      </Card>
    </StyledAdditionalInfoWrapper>
  )
}
