import styled from "@emotion/styled"

export const StyledViewMedicalChronologyWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(3.5),
  borderRadius: theme.spacing(0.5),
  border: "1px solid rgba(0, 0, 0, 0.12)",
  background: "#FBFBFB",
}))

export const StyledIconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: theme.spacing(1.5),
  background: "#1E88E5",
  width: "120px",
  height: "120px",
  verticalAlign: "center",
}))
