import { ItemType } from "common/nested-list/types"
import { ExhibitItemType } from "./Exhibit"
import { EXHIBIT_ITEM_TYPES } from "./enums"

export function ExhibitListItemEmptyPlaceholder({
  item,
}: {
  item: ItemType<ExhibitItemType>
}): JSX.Element | false {
  if (item.type === EXHIBIT_ITEM_TYPES.GROUP) {
    return (
      <div>
        <i>Add exhibits here to combine them.</i>
      </div>
    )
  }

  return false
}
