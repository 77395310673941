import { Box } from "@mui/material"
import React, { useCallback } from "react"
import { StyledTab, StyledTabs, StyledTypographyFullName } from "../styled"
import { theme } from "app/theme"
import { PlaintiffTabProps } from "requests/ViewRequest/ViewRequestRevamp/types"

export const PlaintiffTab = ({
  plaintiffs,
  plaintiff_first_name,
  plaintiff_last_name,
  activeTab,
  setActiveTab,
}: PlaintiffTabProps) => {
  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setActiveTab(newValue)
    },
    [setActiveTab]
  )
  return (
    <Box marginBottom={theme.spacing(plaintiffs.length > 1 ? 4 : 3)}>
      {plaintiffs.length > 1 ? (
        <StyledTabs value={activeTab} onChange={handleTabChange} aria-label="plaintiff-tab">
          {plaintiffs.map(({ pk, first_name, last_name }) => (
            <StyledTab key={pk} label={`${first_name} ${last_name}`} />
          ))}
        </StyledTabs>
      ) : (
        <StyledTypographyFullName>
          {plaintiff_first_name ?? plaintiffs[0].first_name} {plaintiff_last_name ?? plaintiffs[0].last_name}
        </StyledTypographyFullName>
      )}
    </Box>
  )
}
