import { useRef, useState } from "react"
import { Control } from "react-hook-form"
import { CasePainAndSuffering } from "api/services/case/types"
import { CaseSection } from "demand/CaseSection"
import { CASE_SECTIONS } from "common/types/sections"
import { FieldBox } from "./styled"
import { useOutsideClick } from "hooks/useOutsideClick"
import { theme } from "app/theme"

interface ClickInToEditorProps {
  control: Control<CasePainAndSuffering>
  section: CASE_SECTIONS
  caseId: PrimaryKey
  keyValue: string
  onBlur: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>
}

export const ClickInToEditor = ({
  control,
  section,
  caseId,
  keyValue,
  onBlur,
}: ClickInToEditorProps): JSX.Element => {
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true)
  const editorRef = useRef(null)
  useOutsideClick(editorRef, () => {
    setIsReadOnly(true)
    onBlur()
  })

  const handleClickIntoEditor = () => {
    if (isReadOnly) {
      setIsReadOnly(false)
    }
  }

  return (
    <FieldBox
      theme={theme}
      onClick={handleClickIntoEditor}
      readOnly={isReadOnly}
      onBlur={onBlur}
      ref={editorRef}
    >
      <CaseSection
        control={control}
        section={section}
        caseId={caseId}
        key={keyValue}
        includeAskAI={false}
        disabled={isReadOnly}
        displayEmptyState={true}
      />
    </FieldBox>
  )
}
