import { useCallback } from "react"
import { DOCUMENT_GENERATION_STATUS, DocumentGenerationEntity } from "api/services/documents/types"
import DownloadIcon from "@mui/icons-material/Download"
import { CircularProgress, IconButton } from "@mui/material"
import { StyledProgressCell } from "./styled"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"

interface GeneratedDocumentsDownloadCellProps {
  documentGeneration: DocumentGenerationEntity
  documentId: string
  requestId: PrimaryKey
}

export const GeneratedDocumentsDownloadCell = ({
  documentGeneration,
  documentId,
  requestId,
}: GeneratedDocumentsDownloadCellProps): JSX.Element => {
  const { status, downloadURL, id } = documentGeneration

  const downloadHandler = useCallback(() => {
    if (!downloadURL || status !== DOCUMENT_GENERATION_STATUS.COMPLETE) {
      return
    }

    amplitudeApm.trackEvent(
      new MedicalChronologyAnalyticEvent(MedicalChronologyAnalyticsEventTypes.MedicalChronologyDownloaded, {
        document_id: documentId,
        generation_id: id,
        request_id: requestId,
      })
    )

    window.open(downloadURL, "_parent")
  }, [downloadURL, status, documentId, id, requestId])

  switch (status) {
    case DOCUMENT_GENERATION_STATUS.COMPLETE:
    case DOCUMENT_GENERATION_STATUS.ERROR:
      return (
        <IconButton type="submit" size="small" disabled={!downloadURL} onClick={downloadHandler}>
          <DownloadIcon />
        </IconButton>
      )
    default: {
      return (
        <StyledProgressCell>
          <CircularProgress size={32} color="inherit" />
        </StyledProgressCell>
      )
    }
  }
}
