import { PropsWithChildren, memo, useCallback, useMemo, useState } from "react"
import { QueryOptions, useQueries, useQueryClient } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, STALE_TIMEOUT, queryKeys } from "react-query/constants"
import { permissionService } from "api/services/permissions"
import { PermissionFetcherContextType, PermissionFetcherContext } from "./PermissionFetcherContext"
import useUser from "hooks/useUser"
import { retryOrProvideDefaultPermissions } from "./utils"
import { FeaturePermissions } from "./types"

type FirmIdsStore = Partial<Record<string, number>>

const PermissionFetcherContent = memo(function PermissionFetcherContent({ children }: PropsWithChildren) {
  return <>{children}</>
})

export const PermissionFetcher = memo(function PermissionFetcher({
  children,
}: PropsWithChildren): JSX.Element {
  const { user } = useUser()
  const [firmIds, setFirmIds] = useState<FirmIdsStore>({})
  const [subscriptionCount, setSubscriptionCount] = useState(0)
  const queryClient = useQueryClient()

  const subscribe = useCallback<PermissionFetcherContextType["subscribe"]>(() => {
    setSubscriptionCount(count => count + 1)
  }, [])

  const unsubscribe = useCallback<PermissionFetcherContextType["unsubscribe"]>(() => {
    setSubscriptionCount(count => Math.max(count - 1, 0))
  }, [])

  const subscribeFirmId = useCallback<PermissionFetcherContextType["subscribeFirmId"]>(firmIdKey => {
    const firmId = String(firmIdKey)

    setFirmIds(({ [firmId]: firmIdCount, ...firmIds }) => {
      return {
        ...firmIds,
        [firmId]: (firmIdCount ?? 0) + 1,
      }
    })
  }, [])

  const unsubscribeFirmId = useCallback<PermissionFetcherContextType["unsubscribeFirmId"]>(firmIdKey => {
    const firmId = String(firmIdKey)

    setFirmIds(({ [firmId]: firmIdCount, ...firmIds }) => {
      const nextFirmIds = {
        ...firmIds,
        [firmId]: (firmIdCount ?? 1) - 1,
      }

      if ((nextFirmIds[firmId] ?? 0) <= 0) {
        delete nextFirmIds[firmId]
      }

      return nextFirmIds
    })
  }, [])

  const contextValue = useMemo<PermissionFetcherContextType>(
    () => ({
      subscribe,
      unsubscribe,
      subscribeFirmId,
      unsubscribeFirmId,
    }),
    [subscribe, unsubscribe, subscribeFirmId, unsubscribeFirmId]
  )

  useQueries<QueryOptions<FeaturePermissions>[]>({
    queries: user.isAuthorized
      ? [
          {
            queryKey: [queryKeys.allPermissions, ""],
            queryFn: () => permissionService.checkPermissions({ userId: user.id }),
            // SILENT_QUERY_PARAMS has `staleTime` option which enables data sharing between same queries in different places
            // So when data will refetched - it will be shared across other queries with same query keys
            // It works very like context data but without any react binding around it
            ...SILENT_QUERY_PARAMS,
            refetchInterval: subscriptionCount ? STALE_TIMEOUT.SHORT : STALE_TIMEOUT.DEFAULT,
            enabled: user.isAuthorized,
            retry: retryOrProvideDefaultPermissions(queryClient, [queryKeys.allPermissions, ""]),
            useErrorBoundary: () => false,
          },
          ...Object.entries(firmIds)
            .filter(([, count]) => !!count)
            .map(([firmId]) => ({
              queryKey: [queryKeys.allPermissions, firmId],
              queryFn: () => permissionService.checkPermissions({ firmId, userId: user.id }),
              // SILENT_QUERY_PARAMS has `staleTime` option which enables data sharing between same queries in different places
              // So when data will refetched - it will be shared across other queries with same query keys
              // It works very like context data but without any react binding around it
              ...SILENT_QUERY_PARAMS,
              refetchInterval: STALE_TIMEOUT.SHORT,
              enabled: user.isAuthorized,
              retry: retryOrProvideDefaultPermissions(queryClient, [queryKeys.allPermissions, firmId]),
              useErrorBoundary: () => false,
            })),
        ]
      : [],
  })

  return (
    <PermissionFetcherContext.Provider value={contextValue}>
      <PermissionFetcherContent>{children}</PermissionFetcherContent>
    </PermissionFetcherContext.Provider>
  )
})
