import { AddReferenceButton } from "../styled"

type AddNewReferenceProps = {
  isDisabled: boolean
  disabledTitle: string
  onAddReference: () => void
}

export const AddNewReference = ({ isDisabled, disabledTitle, onAddReference }: AddNewReferenceProps) => {
  return (
    <div>
      <AddReferenceButton
        disabled={isDisabled}
        title={isDisabled ? disabledTitle : undefined}
        textColor="grey"
        onClick={onAddReference}
      >
        + Add
      </AddReferenceButton>
    </div>
  )
}
