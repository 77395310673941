import { DocumentStructureDefinition, FullDocumentStructureDefinition } from "common/types/documentStructure"
import { DocumentStructureBlock, DocumentStructureSection } from "../types"

export enum DOCUMENT_STRUCTURE_TREE_ACTION_TYPE {
  SET_ITEM = "set_item",
  SET_ITEMS = "set_items",
  RESET_ITEM = "reset_item",
  REPLACE_ITEM = "replace_item",
  CREATE = "create",
  UPDATE = "update",
  UPDATE_ITEM = "update_item",
  MOVE = "move",
  DELETE = "delete",
}

interface DocumentStructureTreeBaseAction<T extends DOCUMENT_STRUCTURE_TREE_ACTION_TYPE, P> {
  type: T
  payload: P
}

type DocumentStructureTreeSetItemAction = DocumentStructureTreeBaseAction<
  DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.SET_ITEM,
  {
    data: FullDocumentStructureDefinition
    id: string
  }
>

type DocumentStructureTreeReplaceItemAction = DocumentStructureTreeBaseAction<
  DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.REPLACE_ITEM,
  {
    data: FullDocumentStructureDefinition
    id: string
  }
>

type DocumentStructureTreeUpdateItemAction = DocumentStructureTreeBaseAction<
  DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE_ITEM,
  {
    data: Partial<DocumentStructureDefinition>
    id: string
  }
>

type DocumentStructureTreeSetItemsAction = DocumentStructureTreeBaseAction<
  DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.SET_ITEMS,
  [DocumentStructureDefinition, string][]
>

type DocumentStructureTreeResetItemAction = DocumentStructureTreeBaseAction<
  DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.RESET_ITEM,
  {
    id: string
  }
>

type DocumentStructureTreeCreateAction = DocumentStructureTreeBaseAction<
  DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.CREATE,
  {
    data: DocumentStructureSection | DocumentStructureBlock
    parentId: string
  }
>

type DocumentStructureTreeUpdateAction = DocumentStructureTreeBaseAction<
  DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.UPDATE,
  {
    id: string
    data: DocumentStructureSection | DocumentStructureBlock
  }
>

type DocumentStructureTreeMoveAction = DocumentStructureTreeBaseAction<
  DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.MOVE,
  { id: string; parentId: string; index: number }
>

type DocumentStructureTreeDeleteAction = DocumentStructureTreeBaseAction<
  DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.DELETE,
  { id: string }
>

export type DocumentStructureTreeAction =
  | DocumentStructureTreeSetItemsAction
  | DocumentStructureTreeSetItemAction
  | DocumentStructureTreeReplaceItemAction
  | DocumentStructureTreeUpdateItemAction
  | DocumentStructureTreeResetItemAction
  | DocumentStructureTreeCreateAction
  | DocumentStructureTreeUpdateAction
  | DocumentStructureTreeMoveAction
  | DocumentStructureTreeDeleteAction
