import Box from "@mui/material/Box"
import React from "react"
import { BoldText } from "./styled"
import { ExtendedNumberFormatValues, NumberInput } from "common/form-components/NumberInput"

interface EditableRangeProps {
  start: number
  end: number
  onStartChange: (data: ExtendedNumberFormatValues) => void
  onEndChange: (data: ExtendedNumberFormatValues) => void
  disabled?: boolean
  startError?: boolean
  endError?: boolean
}

const EditableRange: React.FC<EditableRangeProps> = ({
  start,
  end,
  onStartChange,
  onEndChange,
  disabled,
  startError = false,
  endError = false,
}) => {
  const startValue = Number.isNaN(start) ? "" : start
  const endValue = Number.isNaN(end) ? "" : end

  return (
    <Box display="flex" alignItems="center">
      <BoldText color={startError ? "error" : "initial"}>From page</BoldText>
      <Box maxWidth="80px" ml={1} mr={1}>
        <NumberInput
          variant="outlined"
          size="small"
          value={startValue}
          onValueChange={onStartChange}
          disabled={disabled}
          error={startError}
        />
      </Box>
      <BoldText color={endError ? "error" : "initial"}>to</BoldText>
      <Box maxWidth="80px" ml={1} mr={1}>
        <NumberInput
          variant="outlined"
          size="small"
          value={endValue}
          onValueChange={onEndChange}
          disabled={disabled}
          error={endError}
        />
      </Box>
    </Box>
  )
}

export default EditableRange
