import styled from "@emotion/styled"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import LinkButton from "common/buttons/LinkButton"
import ExcerptMoreButtonComponent from "./ExcerptMoreButton"

export const ExcerptMoreButton = styled(ExcerptMoreButtonComponent)({
  visibility: "hidden",
})

export const AnnotationFileIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
}))

export const Text = styled(Typography)(() => ({
  fontSize: "12px",
}))

export const BoldText = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "bold",
}))

export const EditBoxTitle = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "bold",
}))

export const EditPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3, 5, 2, 5),
  border: "1px solid black",
  width: "600px",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "-20px",
}))

export const RangesWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}))

export const AnnotationTableHeader = styled(TableCell)(({ theme }) => ({
  textTransform: "uppercase",
  fontWeight: "bold",
  fontSize: "12px",
  paddingTop: 0,
  paddingBottom: 0,
  "&:first-of-type": {
    paddingLeft: theme.spacing(1),
  },
  "&:last-child": {
    paddingRight: 0,
  },
}))

export const AnnotationTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  paddingTop: 0,
  paddingBottom: 0,
  "&:first-of-type": {
    paddingLeft: theme.spacing(1),
  },
}))

export const LastAnnotationTableCell = styled(AnnotationTableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  "&:last-child": {
    paddingRight: 0,
  },
}))

export const AnnotationTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[200],
  },

  "&:hover": {
    backgroundColor: theme.palette.blue.light,
    cursor: "pointer",
  },
}))

export const EmptyAnnotationTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[200],
  },
}))

export const TableLinkButton = styled(LinkButton)(() => ({
  fontSize: "14px",
}))

export const Header = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  "& > *": {
    alignSelf: "center",
  },
  "&:hover": {
    cursor: "pointer",
  },
}))

export const SectionBox = styled(Box)(({ theme }) => ({
  justifyContent: "space-between",
  maxWidth: "1100px",
  margin: theme.spacing(0, "auto"),
}))
