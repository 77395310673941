import { ReferencePageDto, ReferencePage } from "common/types/providers"
import { ObjectiveTestFindingDto, ObjectiveTestFinding, ObjectiveTestDto, ObjectiveTest } from "./types"

class FindingReferencePageDeserializer {
  static definitionFromJSON({ id, exhibit_id, partition_id, page_number }: ReferencePageDto): ReferencePage {
    return {
      id,
      exhibitId: exhibit_id,
      partitionId: partition_id,
      pageNumber: page_number,
    }
  }

  static fromListJSON(referencePages: ReferencePageDto[]): ReferencePage[] {
    return (referencePages || []).map(this.definitionFromJSON)
  }
}

export class ObjectiveTestFindingDeserializer {
  static definitionFromJSON({
    id,
    objective_test_id,
    finding,
    reference_pages,
  }: ObjectiveTestFindingDto): ObjectiveTestFinding {
    return {
      id,
      objectiveTestId: objective_test_id,
      finding,
      referencePages: FindingReferencePageDeserializer.fromListJSON(reference_pages),
    }
  }

  static fromListJSON(objectiveTestFindings: ObjectiveTestFindingDto[]): ObjectiveTestFinding[] {
    return (objectiveTestFindings || []).map(this.definitionFromJSON)
  }
}

export class ObjectiveTestDeserializer {
  static definitionFromJSON({
    id,
    provider_id,
    date,
    description,
    findings,
  }: ObjectiveTestDto): ObjectiveTest {
    return {
      id,
      providerId: provider_id,
      date,
      description,
      findings: ObjectiveTestFindingDeserializer.fromListJSON(findings),
    }
  }

  static fromListJSON(objectiveTests: ObjectiveTestDto[]): ObjectiveTest[] {
    return (objectiveTests || []).map(this.definitionFromJSON)
  }
}

class FindingReferencePageSerializer {
  static definitionToJSON({ id, exhibitId, partitionId, pageNumber }: ReferencePage): ReferencePageDto {
    return {
      id,
      exhibit_id: exhibitId,
      partition_id: partitionId,
      page_number: pageNumber,
    }
  }

  static toListJSON(referencePages: ReferencePage[]): ReferencePageDto[] {
    return (referencePages || []).map(this.definitionToJSON)
  }
}

export class ObjectiveTestFindingSerializer {
  static definitionToJSON({
    id,
    objectiveTestId,
    finding,
    referencePages,
  }: ObjectiveTestFinding): ObjectiveTestFindingDto {
    return {
      id,
      objective_test_id: objectiveTestId,
      finding,
      reference_pages: FindingReferencePageSerializer.toListJSON(referencePages),
    }
  }

  static toListJSON(objectiveTestFindings: ObjectiveTestFinding[]): ObjectiveTestFindingDto[] {
    return (objectiveTestFindings || []).map(this.definitionToJSON)
  }
}

export class ObjectiveTestSerializer {
  static definitionToJSON({ id, providerId, date, description, findings }: ObjectiveTest): ObjectiveTestDto {
    return {
      id,
      provider_id: providerId,
      date,
      description,
      findings: ObjectiveTestFindingSerializer.toListJSON(findings),
    }
  }
}
