import { useCallback, useEffect, useRef, useState } from "react"
import { Box, Button, PopoverProps } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { DraggablePopover } from "evenup-ui/DraggablePopover"
import { IcdCode, IcdCodeRelation } from "documents/types"
import { useDocumentStore } from "documents/store"
import { InputBox } from "./styled"
import { IcdCodeInput } from "./IcdCodeInput"
import { range } from "lodash"
import { v4 } from "uuid"
import { Relations } from "./Relations"

export function getRelationIds(id: string | undefined, count: number = 1) {
  return {
    id,
    uuids: range(0, Math.max(count, 1)).map(() => `${id}_${v4()}`),
  }
}

type IcdCodeData = Pick<IcdCode, "code" | "relations"> & Partial<Pick<IcdCode, "id">>

interface IcdCodeFormProps {
  id?: Nullable<IcdCode["id"]>
  anchorEl?: PopoverProps["anchorEl"]
  open: boolean
  onClose: () => void
  onDelete: (icdCode: IcdCode["id"]) => void
  onSave: (icdCodeFields: IcdCodeData) => void
  onChange?: (icdCodeFields: {
    code: Nullable<IcdCode["code"]>
    relations: Nullable<IcdCodeRelation>[]
  }) => void
  renderAdditionalFields?: () => JSX.Element
  isAdditionalFieldsValid?: boolean
}

export function IcdCodeForm({
  id,
  open,
  anchorEl,
  onClose,
  onDelete,
  onSave,
  onChange,
  renderAdditionalFields,
  isAdditionalFieldsValid,
}: IcdCodeFormProps): JSX.Element {
  const icdCode = useDocumentStore(state => (id ? state.icdCodes[id] : null))
  const [code, setCode] = useState(icdCode ? icdCode.code : null)
  const [relations, setRelations] = useState<Nullable<IcdCodeRelation>[]>(
    icdCode ? icdCode.relations : [null]
  )
  const isValid = code !== null && (!renderAdditionalFields || isAdditionalFieldsValid)
  const relationUuids = useRef(getRelationIds(icdCode?.id, icdCode?.relations?.length))

  useEffect(() => {
    onChange?.({ code, relations })
  }, [code, relations, onChange])

  useEffect(() => {
    if (!open || !icdCode) {
      setCode(null)
      setRelations([null])
    } else if (open && icdCode) {
      setCode(icdCode.code)
      setRelations(icdCode.relations.length ? icdCode.relations : [null])

      if (icdCode.id !== relationUuids.current.id) {
        relationUuids.current = getRelationIds(icdCode.id, icdCode?.relations?.length)
      }
    }
  }, [open, icdCode])

  const handleDelete = useCallback(() => {
    if (icdCode) onDelete(icdCode.id)
  }, [icdCode, onDelete])

  const handleSave = useCallback(() => {
    if (!isValid) return

    onSave({
      id: id ?? undefined,
      code,
      relations: relations.filter((relation): relation is IcdCodeRelation => !!relation),
    })
  }, [id, isValid, onSave, code, relations])

  return (
    <DraggablePopover
      open={open}
      sx={{ marginTop: "10px" }}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      title={icdCode ? "Edit ICD code" : "Add ICD Code"}
    >
      <Box width={460}>
        <Box>
          <InputBox mb={3.75}>
            <Box mb={1} fontWeight={600}>
              ICD code
            </Box>
            <IcdCodeInput code={code} onChange={setCode} />
          </InputBox>

          {renderAdditionalFields && renderAdditionalFields()}

          <Relations id={icdCode?.id} relations={relations} onChange={setRelations} />
        </Box>
        <Box mt={3} display="flex" justifyContent="space-between">
          <Box>
            {icdCode && (
              <Button color="error" size="small" onClick={handleDelete}>
                <CloseIcon fontSize="small" fontWeight={700} />
                <Box fontWeight={700} ml={0.5}>
                  Remove ICD code
                </Box>
              </Button>
            )}
          </Box>
          <Box display="flex" gap={1.5}>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              disabled={!isValid}
              onClick={handleSave}
              data-test="add-icd-code-button"
            >
              {icdCode ? "Save ICD code" : "Add ICD code"}
            </Button>
          </Box>
        </Box>
      </Box>
    </DraggablePopover>
  )
}
