import { ALL_ROLES } from "common/models/roles"
import { UserDto } from "common/models/user"
import { FirmDto } from "settings/Firm/Firm"

export interface FeaturePermission {
  pk: number
  name: string
  display_name: string
  enable_for_all: boolean
  description: string | null
  authorized_users: UserDto[]
  authorized_firms: FirmDto[]
  authorized_roles: ALL_ROLES[]
}

export enum FeaturePermissionTypes {
  FEATURE_FLAG = "feature_flag",
  ACCESS_LEVEL_CONTROL = "access_level_control",
}

export type FeaturePermissionType =
  | FeaturePermissionTypes.FEATURE_FLAG
  | FeaturePermissionTypes.ACCESS_LEVEL_CONTROL
