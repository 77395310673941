import { Appointment } from "documents/types"
import { useDocumentStore } from "documents/store"
import { appointmentsSelectors } from "documents/store/appointments"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { GENERATED_SUMMARY_STATUSES } from "api/services/documents/types"
import {
  StyledErrorWrapper,
  StyledSuccessWrapper,
  StyledUpdateButton,
  StyledWarningWrapper,
  StyledErrorButton,
} from "./styled"

interface AppointmentStatusProps {
  appointmentId: Appointment["id"]
  handleRevertChanges: () => void
  handleRegenerateSummary: () => void
}

export const AppointmentStatus = ({
  appointmentId,
  handleRevertChanges,
  handleRegenerateSummary,
}: AppointmentStatusProps) => {
  const appointment = useDocumentStore(appointmentsSelectors.getAppointmentById(appointmentId))

  switch (appointment.generatedSummaryStatus) {
    case GENERATED_SUMMARY_STATUSES.PENDING:
    case GENERATED_SUMMARY_STATUSES.NOT_REQUESTED:
    case GENERATED_SUMMARY_STATUSES.DEDUPING_BPS: {
      return (
        <StyledWarningWrapper>
          <AccessTimeIcon sx={{ width: "22px", height: "22px" }} htmlColor="#ED6C02" />
          AI Summary Pending.
        </StyledWarningWrapper>
      )
    }
    case GENERATED_SUMMARY_STATUSES.FAILED_OR_HUNG: {
      return (
        <StyledErrorWrapper>
          <ErrorOutlineIcon sx={{ width: "22px", height: "22px" }} htmlColor="#661b2a" />
          <span>
            AI Summary Could Not Generate.
            <StyledErrorButton onClick={handleRegenerateSummary}>Regenerate Again</StyledErrorButton>
          </span>
        </StyledErrorWrapper>
      )
    }
    case GENERATED_SUMMARY_STATUSES.DEDUPE_BPS_FAILED: {
      return (
        <StyledWarningWrapper>
          <ErrorOutlineIcon sx={{ width: "22px", height: "22px" }} htmlColor="#661b2a" />
          Dedupe Bullet Point Summaries Failed
        </StyledWarningWrapper>
      )
    }
    case GENERATED_SUMMARY_STATUSES.NEW_VERSION_AVAILABLE: {
      return (
        <StyledSuccessWrapper>
          <ErrorOutlineIcon sx={{ width: "22px", height: "22px" }} htmlColor="#2E7D32" />
          <span>
            New AI Summary available.
            <StyledUpdateButton onClick={handleRevertChanges}>Update to Generated Version</StyledUpdateButton>
          </span>
        </StyledSuccessWrapper>
      )
    }
    default: {
      return null
    }
  }
}
