import { FILE_CATEGORY_OPTIONS } from "common/constants"
import { UploadedFile } from "common/types/UploadedFile"
import { User } from "./user"

export enum REVISION_REQUEST_REASONS {
  ERROR_IN_DEMAND = "error_in_demand",
  NEW_DOCUMENTS = "new_documents",
  OTHER = "other",
}

export interface RevisionRequestMainData {
  reason: REVISION_REQUEST_REASONS
  additionalInformation: Nullable<string>
}

export interface RevisionRequestFields extends RevisionRequestMainData, Required<BaseEntity> {
  completedAt: Nullable<string>
  cancelledAt: Nullable<string>
  uploadedFiles: Nullable<UploadedFile[]>
  editor: User
}

export interface RevisionRequestUpload {
  upload: string
  name: string
  type: FILE_CATEGORY_OPTIONS
}

export class RevisionRequest<T extends "new" | "data" | "full" = "full"> implements BaseEntity {
  protected uploads: RevisionRequestUpload[] | undefined

  constructor(
    readonly pk: T extends "new" ? undefined : RevisionRequestFields["pk"],
    readonly reason: T extends "data"
      ? RevisionRequestFields["reason"] | undefined
      : RevisionRequestFields["reason"],
    readonly additionalInformation: T extends "data"
      ? RevisionRequestFields["additionalInformation"] | undefined
      : RevisionRequestFields["additionalInformation"],
    readonly editor: T extends "full" ? RevisionRequestFields["editor"] : undefined,
    readonly completedAt: T extends "full" ? RevisionRequestFields["completedAt"] : undefined,
    readonly cancelledAt: T extends "full" ? RevisionRequestFields["cancelledAt"] : undefined,
    readonly uploadedFiles: T extends "full" ? RevisionRequestFields["uploadedFiles"] : undefined
  ) {}

  get isActive(): boolean {
    return this.completedAt === null && this.cancelledAt === null
  }

  getUploads(): RevisionRequestUpload[] | undefined {
    return this.uploads
  }
}

export class NewRevisionRequest extends RevisionRequest<"new"> {
  constructor(
    reason: RevisionRequestFields["reason"],
    additionalInformation: RevisionRequestFields["additionalInformation"]
  ) {
    super(undefined, reason, additionalInformation, undefined, undefined, undefined, undefined)
  }

  setUploads(uploads: RevisionRequestUpload[]): void {
    this.uploads = uploads
  }
}

export class RevisionRequestData extends RevisionRequest<"data"> {
  constructor(
    pk: RevisionRequestFields["pk"],
    reason: RevisionRequestFields["reason"] | undefined,
    additionalInformation: RevisionRequestFields["additionalInformation"] | undefined
  ) {
    super(pk, reason, additionalInformation, undefined, undefined, undefined, undefined)
  }

  setUploads(uploads: RevisionRequestUpload[]): void {
    this.uploads = uploads
  }
}
