import React from "react"
import { Skeleton } from "@mui/material"

const SettlementsHomeSkeleton: React.FC = () => {
  return (
    <Skeleton
      variant="rounded"
      sx={{
        borderRadius: "16px",
        width: "100%",
        height: "472px",
        maxWidth: "525px",
        marginTop: "150px",
        marginX: "auto",
      }}
    />
  )
}

export default SettlementsHomeSkeleton
