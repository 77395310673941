import styled from "@emotion/styled"
import { Box, IconButton } from "@mui/material"
import TextButton from "common/buttons/TextButton"

export const ObjectiveTestContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column" as const,
}))

export const ObjectiveTestReadonlyRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 2fr",
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
}))

export const ObjectiveTestFindingsReadonlyList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  gap: theme.spacing(1),
}))

export const ObjectiveTestFindingsReadonlyRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 2fr",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1),
}))

export const DescriptionCell = styled(Box)(() => ({
  textOverflow: "wrap",
}))

export const ObjectiveTestEditableRowHeader = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr 2fr 3fr 2fr 1fr 1fr .5fr",
  marginBottom: theme.spacing(1),
  gap: theme.spacing(2),
  alignItems: "start",
}))

export const ObjectiveTestFindingEditableList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  gap: theme.spacing(2),
}))

export const ObjectiveTestEditableRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr 2fr 6fr 1fr .5fr",
  marginBottom: theme.spacing(2),
  gap: theme.spacing(2),
  alignItems: "start",
}))

export const ObjectiveTestFindingsEditableList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  gap: theme.spacing(1),
}))

export const ObjectiveTestFindingEditableRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr 3fr",
  gap: theme.spacing(2),
}))

export const ObjectiveTestFindingReferencesEditableList = styled(Box)(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  gap: theme.spacing(1),
}))

export const ObjectiveTestFindingReferenceEditableRow = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gap: theme.spacing(1),
}))

export const AddObjectiveTestButton = styled(TextButton)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

export const DeleteObjectiveTestButton = styled(IconButton)(({ theme }) => ({
  marginTop: theme.spacing(1),
}))
