import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { docTypesSelectors } from "exhibit-builder/store/docTypes"
import MultiSelectAutoComplete, { MultiSelectAutoCompleteProps } from "evenup-ui/MultiSelectAutoComplete"
import { useEffect, useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

export function SubDocType() {
  const selectedDocTypes = useExhibitBuilderStore(useShallow(state => state.selectedDocTypes))
  const selectedSubDocTypes = useExhibitBuilderStore(useShallow(state => state.selectedSubDocTypes))
  const subDocTypes = useExhibitBuilderStore(
    useShallow(docTypesSelectors.getSubDocTypesByDocTypes(selectedDocTypes))
  )
  const options = useMemo(
    () =>
      Object.values(subDocTypes).map(docType => ({
        label: docType.name,
        value: docType.key,
      })),
    [subDocTypes]
  )

  useEffect(() => {
    const validSubSections = subDocTypes.filter(subSection => selectedSubDocTypes.includes(subSection.key))
    exhibitBuilderActions.selectSubSections(validSubSections.map(subSection => subSection.key))
  }, [subDocTypes, selectedSubDocTypes])

  const value = useMemo(
    () => options.filter(option => selectedSubDocTypes?.includes(option.value)),
    [selectedSubDocTypes, options]
  )

  const handleChange: MultiSelectAutoCompleteProps<(typeof options)[number]>["onChangeValue"] = options => {
    exhibitBuilderActions.selectSubSections(options.map(option => option.value))
  }

  return (
    <MultiSelectAutoComplete
      disabled={options.length === 0}
      size="small"
      label="Sub-Doc Type"
      value={value}
      options={options}
      onChangeValue={handleChange}
    />
  )
}
