import React, { useContext } from "react"
import { makeStyles } from "tss-react/mui"
import { useDroppable } from "@dnd-kit/core"
import { DROP_TARGET_TYPE } from "./constants"
import { ProjectionItemWithChildren } from "./types"
import { SortableContext } from "./SortableContext"

const useStyles = makeStyles()(theme => ({
  emptyDropIndicator: {
    background: theme.palette.grey["100"],
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
  },
  active: {
    background: theme.palette.dragging.main,
    zIndex: 100,
  },
}))

interface SortableEmptyPlaceholderBaseProps<T> {
  item: ProjectionItemWithChildren<T>
}

interface SortableEmptyPlaceholderContentProps<T> {
  item: ProjectionItemWithChildren<T>
  canDrop: boolean
  isOver: boolean
}

type PlaceholderContentComponent<TData, TPlaceholderProps> = React.FunctionComponent<
  TPlaceholderProps & SortableEmptyPlaceholderContentProps<TData>
>

export type SortableEmptyPlaceholderContent<TData, TPlaceholderProps> = {
  ContentComponent: PlaceholderContentComponent<TData, Omit<TPlaceholderProps, "ContentComponent">>
}

export function SortableEmptyPlaceholder<
  TData,
  TPlaceholderProps extends SortableEmptyPlaceholderContent<TData, TPlaceholderProps>,
>({
  item,
  ContentComponent,
  ...props
}: SortableEmptyPlaceholderBaseProps<TData> & TPlaceholderProps): Nullable<JSX.Element> {
  const { classes, cx } = useStyles()
  const { id } = item
  const sortableContext = useContext(SortableContext)
  const canDrop = sortableContext.canDropAsChild(id)
  const droppable = useDroppable({
    data: { type: DROP_TARGET_TYPE.EMPTY, id },
    id: `droppable-${id}-inner`,
    disabled: !canDrop,
  })

  const contentProps: SortableEmptyPlaceholderContentProps<TData> &
    Omit<TPlaceholderProps, "ContentComponent"> = {
    ...(props as Omit<TPlaceholderProps, "ContentComponent">),
    item: item,
    canDrop,
    isOver: droppable.isOver,
  }

  if (typeof ContentComponent !== "function" || !ContentComponent(contentProps)) {
    return null
  }

  const content = <ContentComponent {...contentProps} />

  return (
    <div
      ref={droppable.setNodeRef}
      className={cx(classes.emptyDropIndicator, droppable.isOver && classes.active)}
      data-type="empty-placeholder"
      data-is-over={droppable.isOver ? "true" : "false"}
    >
      {content}
    </div>
  )
}
