import styled from "@emotion/styled"
import { Alert, alertClasses, Button, ButtonBase, buttonClasses, Paper } from "@mui/material"
import AutorenewIcon from "@mui/icons-material/Autorenew"

export const StyledReviewButton = styled(Button)(({ theme }) => ({
  borderColor: "#E040FB80",
  color: "#E040FB",
  borderRadius: "6px",

  height: "40px",

  "&:hover, &:active": {
    borderColor: "#E040FB",
    background: "none",
  },

  [`&:disabled .${buttonClasses.startIcon} svg *`]: {
    stroke: theme.palette.disabled.main,
  },
}))

export const LoadingIcon = styled(AutorenewIcon)({
  animation: "spin 1.2s linear infinite",
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
})

export const PulseButton = styled(Button, { shouldForwardProp: prop => prop !== "pulse" })<{
  pulse?: boolean
}>(({ theme, pulse, color }) => ({
  flex: "1 0 33%;",
  ["&&"]: {
    boxShadow:
      !pulse || !color || color === "inherit"
        ? "none"
        : `0 0 0 0 #f0f0f0, 0 0 0 0 ${theme.palette[color].main}80`,

    animation:
      !pulse || !color || color === "inherit"
        ? "none"
        : "pulse 1.25s infinite cubic-bezier(0.66, 0.33, 0, 1);",
    "@keyframes pulse": {
      to: {
        boxShadow:
          !color || color === "inherit"
            ? "none"
            : `0 0 0 4px transparent, 0 0 0 12px ${theme.palette[color].main}00`,
      },
    },
  },
}))

export const ReviewEditorWrapper = styled("div")<{ activeItemId?: string }>(({ activeItemId, theme }) => ({
  position: "relative",

  "& [data-suggestion]": {
    "&": {
      backgroundColor: "#E040FB4D",
      transition: "background-color .1s ease",
    },

    "&:hover": {
      "&": {
        backgroundColor: "#E040FB66",
      },
    },

    [`&[data-suggestion-${activeItemId}]`]: {
      "&": {
        backgroundColor: "#E040FB80",
      },
    },

    "&[data-empty-space]": {
      padding: "0 3px",
      margin: "0 -3px",
      position: "relative",
      zIndex: 1,
      cursor: "pointer",
    },

    "& [data-highlight]": {
      background: "#ffff0050 !important",
    },

    "&[data-highlight]": {
      background: "linear-gradient(#ffff0080, #ffff0050), linear-gradient(#E040FB4D, #E040FB4D)",
    },
  },

  "& [data-type='slate-editor-wrapper']": {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.disabled.border}`,
    borderRadius: "4px",
    backgroundColor: "#fafafa",
  },
}))

export const ReviewItemPaper = styled(Paper, {
  shouldForwardProp: prop => prop !== "isDragging",
})<{ isDragging?: boolean }>(({ theme, isDragging }) => ({
  position: "absolute",
  zIndex: 1,
  borderRadius: "12px",
  padding: theme.spacing(2.5, 5),

  boxSizing: "border-box",
  minWidth: "590px",
  maxWidth: "770px",

  display: "flex",
  flexDirection: "column",
  gap: "30px",

  transition: isDragging ? "none" : "all ease .2s",
}))

export const ReviewTitle = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "end",
}))

export const ReviewBlocks = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
})

export const ReviewBox = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
})

export const ReviewCategory = styled("div")(({ theme }) => ({
  width: "fit-content",
  borderBottom: `2px solid ${theme.palette.disabled.border}`,
  textTransform: "capitalize",
}))

export const TextDiff = styled("div")(({ theme }) => ({
  padding: theme.spacing(1.25),
  background: theme.palette.grey[50],
}))

export const RemovedText = styled("span")({
  backgroundColor: "#EF9A9A4D",
  textDecoration: "line-through",
  padding: "0 2px",
})

export const AddedText = styled("span")({
  backgroundColor: "#2E7D321A",
  padding: "0 2px",
})

export const HtmlWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),

  "& *": {
    backgroundColor: "none",
    textDecoration: "none",
    fontStyle: "normal",
    fontWeight: "normal",
  },

  "& mark": {
    backgroundColor: "yellow",
  },

  "& b, & b *": {
    fontWeight: "bold",
  },

  "& i, & i *": {
    fontStyle: "italic",
  },

  "& var": {
    backgroundColor: "#3cd5ad4d",
    color: "#000000de",
  },
}))

export const RemovedHtml = styled(HtmlWrapper)({
  backgroundColor: "#EF9A9A4D",
})

export const AddedHtml = styled(HtmlWrapper)({
  backgroundColor: "#2E7D321A",
})

export const DragHandle = styled(ButtonBase)({
  position: "absolute",
  left: "12px",
  top: "20px",
  padding: "4px 0",
  borderRadius: "6px",
  cursor: "grab",

  opacity: 0.38,
  transition: "opacity .1s ease",

  "&:hover, &:active": {
    opacity: 0.5,
  },
})

export const StyledAlert = styled(Alert)({
  borderColor: "#E040FB",
  backgroundColor: "#E040FB0D",
  borderRadius: "4px",
  paddingTop: 0,
  paddingBottom: 0,
  marginBottom: 10,
  marginTop: 5,
  flex: "2 0 66%",

  [`& .${alertClasses.message}`]: {
    width: "100%",
  },
})
