import React from "react"
import { LinkButton } from "./styled"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import RateReviewIcon from "@mui/icons-material/RateReview"
import { config } from "common/config"
import { useNavigate } from "react-router-dom"

interface KiliButtonProps {
  documentId: string | undefined
  title?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  otherProps?: any
}

interface KiliButtonProps {
  documentId: string | undefined
  title?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  otherProps?: any
}

export const KiliButton = ({ documentId, title = "Annotation Tool", ...otherProps }: KiliButtonProps) => {
  if (!documentId) return <></>

  return (
    <LinkButton
      endIcon={<OpenInNewIcon />}
      href={`${config.api.host}/documents/${documentId}/kili/`}
      {...otherProps}
    >
      {title}
    </LinkButton>
  )
}

export const MedicalSummaryButton = ({ documentId, ...otherProps }: KiliButtonProps) => {
  const navigate = useNavigate()
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(`/documents/${documentId}/medical-summary/`)
  }

  if (!documentId) return <></>

  return (
    <LinkButton endIcon={<RateReviewIcon />} {...otherProps} onClick={handleClick}>
      Medical Summary
    </LinkButton>
  )
}
