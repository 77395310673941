import { DndProps, SortableNestedList } from "common/nested-list/SortableNestedList"
import { UserExhibitListContent } from "./UserExhibitListContent"
import { UserExhibitListItem } from "./UserExhibitListItem"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import {
  DndExhibitPartitionData,
  DndRecordOrBillData,
  DndUserExhibitData,
  DndVirtualListData,
  ExhibitPartition,
  UserExhibit,
} from "exhibit-builder/store/types"
import { ActionPopup } from "./ActionPopup/ActionPopup"
import { DndContext } from "@dnd-kit/core"
import { useCallback, useMemo } from "react"
import { Box } from "@mui/material"
import { UserExhibitListPlaceholder } from "./UserExhibitListPlaceholder"
import * as Sentry from "@sentry/react"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { filtersSelectors } from "exhibit-builder/store/filters"

function useListData(): DndVirtualListData {
  const exhibitPartitionOrder = useExhibitBuilderStore(useShallow(state => state.exhibitPartitionOrder))
  const exhibitPartitionMap = useExhibitBuilderStore(useShallow(state => state.exhibitPartitionMap))
  const userExhibitOrder = useExhibitBuilderStore(useShallow(state => state.userExhibitOrder))
  const userExhibitMap = useExhibitBuilderStore(useShallow(state => state.userExhibitMap))
  const recordsAndBillsMap = useExhibitBuilderStore(useShallow(state => state.recordsAndBillsMap))
  const recordsAndBillsOrder = useExhibitBuilderStore(useShallow(state => state.recordsAndBillsOrder))

  const selectedProviders = useExhibitBuilderStore(useShallow(state => state.selectedProviders))
  const selectedDocTypes = useExhibitBuilderStore(useShallow(state => state.selectedDocTypes))
  const selectedSubDocTypes = useExhibitBuilderStore(useShallow(state => state.selectedSubDocTypes))
  const selectedPlaintiffs = useExhibitBuilderStore(useShallow(state => state.selectedPlaintiffs))
  const selectedStartDate = useExhibitBuilderStore(useShallow(state => state.selectedStartDate))
  const selectedEndDate = useExhibitBuilderStore(useShallow(state => state.selectedEndDate))
  const selectedExhibitTags = useExhibitBuilderStore(useShallow(state => state.selectedExhibitTags))
  const selectedFiles = useExhibitBuilderStore(useShallow(state => state.selectedFiles))
  const hasFiltersApplied = useExhibitBuilderStore(useShallow(filtersSelectors.hasFiltersApplied))
  const exactProvderMatch = useExhibitBuilderStore(useShallow(state => state.exactProviderMatch))

  function filteredPartitionRecordsAndBills(
    partitionId: ExhibitPartition["id"],
    userExhibitId: UserExhibit["id"]
  ) {
    const filteredData: DndRecordOrBillData[] = []
    recordsAndBillsOrder[partitionId]?.forEach(recordOrBillId => {
      const recordOrBill = recordsAndBillsMap[recordOrBillId]

      if (!recordOrBill) {
        return
      }

      const startDate = new Date(
        recordOrBill.type === "Medical Bill" ? recordOrBill.dateOfFirstService : recordOrBill.dateOfService
      )
      const endDate = new Date(
        recordOrBill.type === "Medical Bill" ? recordOrBill.dateOfLastService : recordOrBill.dateOfService
      )

      const isProviderInSelection =
        selectedProviders.length === 0 ||
        selectedProviders.some(
          provider =>
            provider === recordOrBill.providerId ||
            (provider === userExhibitMap[userExhibitId].sortingProviderId && !exactProvderMatch)
        )
      const isStartDateInFilterRange = !selectedStartDate || startDate >= new Date(selectedStartDate)
      const isEndDateInFilterRange = !selectedEndDate || endDate <= new Date(selectedEndDate)

      const showRecordOrBill = isProviderInSelection && isStartDateInFilterRange && isEndDateInFilterRange

      if (showRecordOrBill) {
        filteredData.push({
          id: recordOrBillId,
          uniqueKey: `${recordOrBill.type}-${recordOrBillId}`,
          type: "recordOrBill",
          userExhibitId,
          partitionId,
        })
      }
    })

    return filteredData
  }

  function filteredUserExhibitPartitions(userExhibitId: UserExhibit["id"]) {
    const filteredData: DndExhibitPartitionData[] = []
    exhibitPartitionOrder[userExhibitId]?.forEach(partitionId => {
      const exhibitPartition = exhibitPartitionMap[partitionId]

      const matchFileFilter = selectedFiles.length === 0 || selectedFiles.includes(exhibitPartition.fileId)

      if (!matchFileFilter) {
        return
      }

      const partitionChildren = filteredPartitionRecordsAndBills(partitionId, userExhibitId)

      if (hasFiltersApplied && selectedFiles.length === 0 && partitionChildren.length === 0) {
        return
      }

      filteredData.push({
        id: partitionId,
        uniqueKey: `exhibit-partition-${partitionId}`,
        type: "exhibitPartition",
        children: partitionChildren,
      })
    })

    return filteredData
  }

  function filteredUserExhibits() {
    const filteredData: DndUserExhibitData[] = []
    userExhibitOrder?.forEach(userExhibitId => {
      const userExhibit = userExhibitMap[userExhibitId]
      const matchDocType =
        selectedDocTypes.length === 0 || selectedDocTypes.includes(userExhibit.docType || "")

      const matchSubDocType =
        selectedSubDocTypes.length === 0 || selectedSubDocTypes.includes(userExhibit.subDocType || "")

      const matchPlaintiff =
        selectedPlaintiffs.length === 0 || selectedPlaintiffs.includes(userExhibit.plaintiffId || "")

      const matchExhibitTag =
        selectedExhibitTags.length === 0 || selectedExhibitTags.includes(userExhibit.tag)

      const showUserExhibit = matchDocType && matchSubDocType && matchPlaintiff && matchExhibitTag

      if (!showUserExhibit) return

      const userExhibitChildren = filteredUserExhibitPartitions(userExhibitId)

      if (hasFiltersApplied && userExhibitChildren.length === 0) {
        return
      }

      filteredData.push({
        id: userExhibitId,
        uniqueKey: `user-exhibit-${userExhibitId}`,
        type: "userExhibit",
        children: userExhibitChildren,
      })
    })

    return filteredData
  }

  return { uniqueKey: "virtual-list", type: "virtualList", children: filteredUserExhibits() }
}

export function UserExhibitList() {
  const listData = useListData()
  const isReordering = useExhibitBuilderStore(useShallow(state => state.document.isReordering))
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const { showErrorMessage } = useHandleMessages()

  const handleReorder: DndProps<DndVirtualListData>["onUpdate"] = useCallback(
    async (_items, operation) => {
      if (!operation) {
        return
      }

      exhibitBuilderActions.setIsReordering(true)

      const { item, index } = operation
      if (item.type === "userExhibit") {
        try {
          await exhibitBuilderActions.reorderUserExhibit(item.id, index)
        } catch (err) {
          Sentry.captureException(err)
          showErrorMessage("There was an error reordering exhibits. Please refresh the page and try again.")
        }
      }

      if (item.type === "exhibitPartition" && operation.parent?.type === "userExhibit") {
        exhibitBuilderActions.setIsReordering(true)
        try {
          await exhibitBuilderActions.reorderExhibitPartition(item.id, index, operation.parent.id)
        } catch (err) {
          Sentry.captureException(err)
          showErrorMessage("There was an error reordering partitions. Please refresh the page and try again.")
        }
      }

      exhibitBuilderActions.setIsReordering(false)
    },
    [showErrorMessage]
  )

  const canDrop: NonNullable<DndProps<DndVirtualListData>["canDrop"]> = useCallback(
    (item, draggingItemId, items) => {
      const draggingItem = items[draggingItemId]
      const isMovingUserExhibit = draggingItem.type === "userExhibit" && item.type === "userExhibit"
      const isMovingExhibitPartition =
        draggingItem.type === "exhibitPartition" && item.type === "exhibitPartition"

      return isMovingUserExhibit || isMovingExhibitPartition
    },
    []
  )

  const listProps = useMemo(() => {
    if (isReadOnly) {
      return { readonly: true as const }
    }

    return { canDrag: () => !isReordering, canDrop, onUpdate: handleReorder }
  }, [isReadOnly, isReordering, handleReorder, canDrop])

  if (!listData.children.length) {
    return <div>no results</div>
  }

  return (
    <Box data-test="user-exhibit-list">
      <SortableNestedList
        items={[listData]}
        uniqueKey="uniqueKey"
        ItemComponent={UserExhibitListItem}
        ItemContentComponent={UserExhibitListContent}
        contentProps={null}
        placeholderProps={null}
        PlaceholderComponent={UserExhibitListPlaceholder}
        {...listProps}
      />
      <DndContext>
        <ActionPopup />
      </DndContext>
    </Box>
  )
}
