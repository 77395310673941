import { DOCUMENT_STRUCTURE_ITEM_TYPE } from "../../enums"
import { DocumentStructureSection, DocumentStructureBlock } from "../../types"
import { DocumentStructureStoreData } from "../types"

export function createDocumentStructureElement(
  state: DocumentStructureStoreData,
  { children = [], ...data }: DocumentStructureSection | DocumentStructureBlock,
  parentId: string
): DocumentStructureStoreData {
  let nextState = { ...state }

  if (data.type === DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION) {
    nextState.sections[data.REF_ID] = data
  } else {
    nextState.blocks[data.REF_ID] = data
  }

  if (!nextState.structure[parentId]) {
    nextState.structure[parentId] = []
  }

  nextState.structure[parentId]?.push(data.REF_ID)

  for (const child of children) {
    nextState = createDocumentStructureElement(nextState, child, data.REF_ID)
  }

  return nextState
}
