import React, { ReactElement, useCallback } from "react"
import { FieldValues, Control, useController } from "react-hook-form"
import { Descendant } from "slate"
import { RichTextEditor } from "./RichTextEditor"
import { CustomEditor, EditorRoot } from "./CustomEditor"
import { isNil } from "lodash"
import { FieldPathWithValue } from "../../types/helper"
import { EditorFeatureProps } from "./features/types"
import { DEFAULT_VALUE } from "./defaultValue"
import { RichTextWithActions } from "./RichTextWithActions"
import { EditorContent } from "./EditorContent"

export interface RichTextFieldProps<TFields extends FieldValues> {
  control: Control<TFields>
  name: FieldPathWithValue<TFields, Nullable<EditorContent>>
  label?: string
  helperText?: string
  dataTest?: string
  error?: boolean
  actions?: Nullable<React.ReactElement>
  disabled?: boolean
  customControls?: Nullable<React.ReactElement>
  editorRef?: (editor: CustomEditor | null) => void
  displayEmptyState?: boolean
}

export function RichTextField<T extends FieldValues>({
  control,
  name,
  label,
  helperText,
  dataTest,
  actions,
  error,
  customControls,
  disabled,
  displayEmptyState = false,
  ...featureProps
}: RichTextFieldProps<T> & EditorFeatureProps): ReactElement {
  const { field: jsonField } = useController({
    control,
    name,
  })
  const hasJsonValue = !isNil(jsonField.value)
  const handleChange = useCallback(
    (nextValue: Descendant[]): void => {
      jsonField.onChange({ target: { value: new EditorContent(nextValue as EditorRoot<false>) } })
    },
    [jsonField]
  )

  const value = hasJsonValue ? ((jsonField.value as EditorContent).children as EditorRoot) : DEFAULT_VALUE
  const onBlur = jsonField.onBlur

  return (
    <RichTextWithActions label={label} actions={actions} helperText={helperText}>
      {disabled ? (
        <RichTextEditor
          value={value}
          dataTest={dataTest}
          readonly
          displayEmptyState={displayEmptyState}
          {...featureProps}
        />
      ) : (
        <RichTextEditor
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          keepValue={false}
          dataTest={dataTest}
          error={error}
          customControls={customControls}
          {...featureProps}
        />
      )}
    </RichTextWithActions>
  )
}
