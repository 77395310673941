import { MouseEventHandler, useRef, useState } from "react"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { Box, Button } from "@mui/material"
import { IcdCodeForm } from "../IcdCodeForm"
import { IcdCode, IcdCodeRelation } from "documents/types"
import { noop } from "lodash"
import { documentActions } from "documents/store"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import { ProviderInputs } from "../ProviderInputs"

export function AddIcdCodeButton() {
  const providerIdRef = useRef<string | null>(null)
  const dateOfServiceRef = useRef<string | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isProviderValid, setIsProviderValid] = useState(false)
  const [isRelationValid, setIsRelationValid] = useState(false)
  const isValid = isProviderValid && isRelationValid

  const openForm: MouseEventHandler<HTMLButtonElement> = event => setAnchorEl(event.currentTarget)
  const closeForm = () => setAnchorEl(null)

  const handleProviderInputsChange = ({
    isValid,
    providerId,
    dateOfService,
  }: {
    isValid: boolean
    providerId: Nullable<string>
    dateOfService: Nullable<string>
  }) => {
    providerIdRef.current = providerId
    dateOfServiceRef.current = dateOfService
    setIsProviderValid(isValid)
  }

  const handleSave = async (icdCode: Pick<IcdCode, "code" | "relations">) => {
    if (!isValid) {
      return
    }

    documentActions.createAppointment({
      dateOfService: dateOfServiceRef.current as string,
      providerId: providerIdRef.current as string,
      icdCodes: [{ ...icdCode, description: "" }],
      summary: DEFAULT_VALUE,
      relations: icdCode.relations,
    })
    closeForm()
  }

  const handleChange = ({
    relations,
  }: {
    code: Nullable<IcdCode["code"]>
    relations: Nullable<IcdCodeRelation>[]
  }) => {
    setIsRelationValid(
      relations.every(relation => relation && relation.page !== null && relation.partitionId !== null) &&
        relations.length > 0
    )
  }

  const isOpen = Boolean(anchorEl)

  return (
    <Box mb={1}>
      <Button
        sx={{ fontWeight: 700 }}
        color="secondary"
        size="small"
        onClick={openForm}
        startIcon={<AddCircleOutlineOutlinedIcon sx={{ width: "24px", height: "24px" }} />}
      >
        Add ICD Code
      </Button>

      <IcdCodeForm
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closeForm}
        onSave={handleSave}
        onDelete={noop}
        onChange={handleChange}
        isAdditionalFieldsValid={isValid}
        renderAdditionalFields={() => {
          return (
            <>
              <ProviderInputs
                onChange={handleProviderInputsChange}
                renderProviderInput={input => (
                  <Box mb={3.75}>
                    <Box mb={1} fontWeight={600}>
                      Provider
                    </Box>
                    {input}
                  </Box>
                )}
                renderDateOfServiceInput={input => (
                  <Box mb={3.75}>
                    <Box mb={1} fontWeight={600}>
                      First date of service
                    </Box>
                    {input}
                  </Box>
                )}
                renderError={error => <Box mb={3.75}>{error}</Box>}
              />
            </>
          )
        }}
      />
    </Box>
  )
}
