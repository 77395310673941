import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useIsMutating, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
// External Components
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { makeStyles } from "tss-react/mui"
import useUser from "hooks/useUser"

// Internal Components
import { Loading } from "common/loading"
import PaginatedTable from "common/tables/PaginatedTable"
import TableActionsHeader from "common/tables/TableActionsHeader"
import SearchInput from "common/SearchInput"
import Tooltip from "common/Tooltip"
import { StatusFilter } from "../RequestStatus/StatusFilter"
import { RequesterFilter } from "./filters/RequesterFilter"
import { getColumns } from "./tableCells"

// Helpers
import { RequestContextProvider, useRequestContext } from "../context"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { getRequestsForFirm, updatePageSize } from "api"
import {
  canUserSeeCreditsUsedOnList,
  canUserFilterByAssignee,
  canUserFilterByFirm,
  canUserRequestNewDemand,
  isExternalUser,
  userHasSmartAdvocateCredentials,
} from "../permissions/requestAction"
import { GenericError } from "common"
import { useSearchState } from "hooks/useSearchState"
import { CreditTracker } from "./CreditTracker"
import { useFirm } from "hooks/useFirm"
import { FirmFilter } from "./filters/FirmFilter"
import { UserFilter } from "./filters/UserFilter"
import { DocumentTypeFilter } from "./filters/DocumentTypeFilter"
import { usePermissions } from "permissions/usePermissions"
import { useDialog } from "hooks/useDialog"
import { StyledDialog } from "app/styled"
import { SmartAdvocate } from "settings/ImportData/SmartAdvocate"
import { useDebouncedCallback } from "use-debounce"
import { StyledTableRow } from "common/tables/PaginatedTableStyled"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { prefetchOnHover } from "common/prefetchOnHover"
import { getPrefetchQueries } from "requests/ViewRequest/ViewRequest"
import { StyledButton } from "evenup-ui/Button/Button"
import { useLocationStore } from "app/location/store"
import { useTheme } from "@mui/material"

const useStyles = makeStyles()(theme => ({
  requestsWrapper: {
    margin: theme.spacing(2, 0),
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  importButton: {
    color: theme.palette.blue.primary,
    backgroundColor: theme.palette.common.white,
    fontSize: "15px",
    lineHeight: 1.62,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textTransform: "none",
  },
  allRequestsButton: {
    minWidth: theme.spacing(25),
  },
  hideHyperlinks: {
    textDecoration: "none",
    color: "black",
  },
  linkToRequest: {
    display: "flex",
    width: "100%",
    transition: "font-weight 0.2s ease-out",
    "&:hover": {
      fontWeight: "bold",
    },
  },
  filterSelector: {
    width: theme.spacing(25),
    marginRight: theme.spacing(4),
  },
  filterRight: {
    display: "flex",
    gap: theme.spacing(1.5),
  },
  searchBar: {
    width: "250px",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  filterContainer: {
    minWidth: theme.spacing(20),
  },
}))

const TableFilters = ({
  setPage,
  setFilterFirmIds,
  setFilterStatusIds,
  setFilterAssigneeIds,
  setFilterRequesterIds,
  setFilterDocumentType,
  filterDocumentType,
  setSearchQuery,
  filterFirmIds,
  filterStatusIds,
  filterAssigneeId,
  filterRequesterIds,
  searchQuery,
  refetchRequestsList,
}) => {
  const theme = useTheme()
  const [searchText, setSearchText] = useState(searchQuery)
  const { classes } = useStyles()
  const { firm } = useRequestContext()
  const { user } = useUser()
  const canUserFilterByFirmPermission = canUserFilterByFirm(user.role)
  const canUserFilterByAssigneePermission = canUserFilterByAssignee(user.role)
  const canUserSeeCreditsUsed = canUserSeeCreditsUsedOnList(user.role, firm)
  const { smartAdvocateIntegrationEnabled } = usePermissions({
    suspense: false,
  })

  // Import button is visible if feature flag is on and user is external
  const showImportButton = smartAdvocateIntegrationEnabled && isExternalUser(user.role)

  // Import button is disabled if user doesn't have SmartAdvocate credentials set up, otherwise it's enabled
  const disableImportButton = !userHasSmartAdvocateCredentials(user)
  const disabledImportButtonReason =
    "Your SmartAdvocate credentials weren't set up properly. Please email support@evenuplaw.com for help."
  const {
    isOpen: isImportModalOpen,
    openDialog: openImportModal,
    closeDialog: closeImportModal,
  } = useDialog()

  const handleFirmIdFilterChange = useCallback(
    ids => {
      setPage(0)
      setFilterFirmIds(ids)
    },
    [setFilterFirmIds, setPage]
  )

  const handleStatusFilterChange = useCallback(
    ids => {
      setPage(0)
      setFilterStatusIds(ids)
    },
    [setFilterStatusIds, setPage]
  )

  const handleAssigneesFilterChange = useCallback(
    ids => {
      setPage(0)
      setFilterAssigneeIds(ids)
    },
    [setFilterAssigneeIds, setPage]
  )

  const handleDocumentTypeFilterChange = useCallback(
    ids => {
      setPage(0)
      setFilterDocumentType(ids)
    },
    [setFilterDocumentType, setPage]
  )

  const debouncedSetSearchQuery = useDebouncedCallback(
    useCallback(
      value => {
        setPage(0)
        // don't replace history when value is empty
        // this creates a distinct entry when clearing input
        // so you can use back button to return to input before clearing
        setSearchQuery(value, { replace: Boolean(value) })
      },
      [setPage, setSearchQuery]
    ),
    500
  )

  const handleSearchQueryChange = useCallback(
    e => {
      setSearchText(e.target.value)
      debouncedSetSearchQuery(e.target.value)
    },
    [debouncedSetSearchQuery, setSearchText]
  )

  const handleRequesterFilterChange = useCallback(
    requesterIds => {
      setPage(0)
      setFilterRequesterIds(requesterIds)
    },
    [setFilterRequesterIds, setPage]
  )

  const handleOnImportClick = useCallback(() => {
    openImportModal()
  }, [openImportModal])

  const handleCloseImportModal = useCallback(() => {
    closeImportModal()
  }, [closeImportModal])

  return (
    <Box>
      <TableActionsHeader>
        <>
          {canUserFilterByFirmPermission && (
            <FirmFilter onChange={handleFirmIdFilterChange} value={filterFirmIds} />
          )}
          <StatusFilter onChange={handleStatusFilterChange} value={filterStatusIds} />
          {canUserFilterByAssigneePermission && (
            <UserFilter onChange={handleAssigneesFilterChange} value={filterAssigneeId} />
          )}

          <DocumentTypeFilter value={filterDocumentType} onChange={handleDocumentTypeFilterChange} />
          <RequesterFilter value={filterRequesterIds} onChange={handleRequesterFilterChange} />

          {canUserSeeCreditsUsed && <CreditTracker contract={firm.current_contract} />}
        </>

        <>
          <FormControl>
            <SearchInput
              value={searchText ?? ""}
              onChange={handleSearchQueryChange}
              placeholder="Search by plaintiff's name"
              width="260px"
            />
          </FormControl>

          {canUserRequestNewDemand(user.role) && (
            <StyledButton
              startIcon={<AddIcon />}
              data-test="new-request-button"
              size="medium"
              component={Link}
              sx={{ "& .MuiButton-startIcon": { marginRight: theme.spacing(0.5) } }}
              variant="contained"
              to="new"
            >
              Request
            </StyledButton>
          )}

          {showImportButton && (
            <Tooltip
              tooltipText={disableImportButton ? disabledImportButtonReason : ""}
              disabled={disableImportButton}
              onClick={handleOnImportClick}
            >
              <Button
                data-test="import-request-button"
                className={classes.importButton}
                size="large"
                color="secondary"
                variant="outlined"
              >
                Import
              </Button>
            </Tooltip>
          )}
        </>
      </TableActionsHeader>

      <StyledDialog
        open={isImportModalOpen}
        onClose={handleCloseImportModal}
        disableEscapeKeyDown
        maxWidth="lg"
      >
        <SmartAdvocate onCancelClick={handleCloseImportModal} refetchRequestsList={refetchRequestsList} />
      </StyledDialog>
    </Box>
  )
}

const FiltersAndTable = () => {
  const { queryClient } = useRequestContext()
  const { user } = useUser()
  const { createSimpleEnabled } = usePermissions({
    firmId: user.firmId,
  })

  const DEFAULT_SORT_FIELD = "updated_at"
  const ASC = "asc"
  const DESC = "desc"
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_FIELD)
  const [sortDirection, setSortDirection] = useState(ASC)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(user.rowsPerPage)

  const [filterFirmIds, setFilterFirmIds] = useSearchState("firmId", [], "string[]")
  const [filterStatusIds, setFilterStatusIds] = useSearchState("status", [], "string[]")
  const [filterAssigneeId, setFilterAssigneeIds] = useSearchState("assignee", [], "string[]")
  const [filterDocumentType, setFilterDocumentType] = useSearchState("documentType", [], "string[]")
  const [filterRequesterIds, setFilterRequesterIds] = useSearchState("requester", [], "string[]")
  const [searchQuery, setSearchQuery] = useSearchState("query", "", "string")

  const {
    data,
    error: requestError,
    isFetching,
    refetch,
  } = useQuery(
    [
      queryKeys.requests,
      {
        page: page + 1,
        pageSize,
        sortBy,
        sortDirection,
        firmIds: filterFirmIds,
        statusIds: filterStatusIds,
        assigneeId: filterAssigneeId,
        submitterId: filterRequesterIds,
        searchQuery,
        documentType: filterDocumentType,
      },
      user.role,
    ],
    getRequestsForFirm,
    { meta: SILENT_QUERY_PARAMS.meta }
  )

  const requests = data?.results ?? []
  const totalCount = data?.count ?? 0

  const mutateInternalUser = useMutation(updatePageSize, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.session])
    },
  })

  const onPageSizeChange = value => {
    setPageSize(value)
    return mutateInternalUser.mutateAsync(value)
  }

  const onSortClick = id => {
    if (id !== sortBy) {
      setPage(0)
      setSortBy(id)
    } else {
      setSortDirection(sortDirection === DESC ? ASC : DESC)
    }
  }

  useEffect(() => {
    // once user is loaded, the state to their current configured page size
    setPageSize(user.rowsPerPage)
  }, [user.rowsPerPage])

  const columns = getColumns({ user })

  if (requestError) {
    return <GenericError error={requestError} />
  }

  return (
    <>
      <Typography variant="h1">
        <strong>Requests</strong>
      </Typography>
      <TableFilters
        setPage={setPage}
        setFilterFirmIds={setFilterFirmIds}
        setFilterStatusIds={setFilterStatusIds}
        setFilterAssigneeIds={setFilterAssigneeIds}
        setFilterRequesterIds={setFilterRequesterIds}
        setSearchQuery={setSearchQuery}
        setFilterDocumentType={setFilterDocumentType}
        filterFirmIds={filterFirmIds}
        filterStatusIds={filterStatusIds}
        filterAssigneeId={filterAssigneeId}
        filterDocumentType={filterDocumentType}
        filterRequesterIds={filterRequesterIds}
        searchQuery={searchQuery}
        refetchRequestsList={refetch}
      />

      <PaginatedTable
        columns={columns}
        records={requests}
        userRole={user.role}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSortClick={onSortClick}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onRowsPerPageChange={onPageSizeChange}
        totalCount={totalCount}
        emptyMessage="No Requests"
        recordsLoading={isFetching}
        RenderRow={({ children, record }) => {
          return (
            <StyledTableRow
              hover
              data-test={record?.pk ? `table-row-${record.pk}` : `table-row`}
              {...prefetchOnHover(
                queryClient,
                getPrefetchQueries(record.pk.toString(), record.firm.pk, user.id)
              )}
            >
              {children}
            </StyledTableRow>
          )
        }}
        createSimpleEnabled={createSimpleEnabled}
      />
    </>
  )
}

export function RequestsList() {
  const { classes } = useStyles()

  const { user } = useUser()
  const { firm } = useFirm(user.firmId)

  const isDeleted = useLocationStore(location => Boolean(location.state?.deleted))
  const { showSuccessMessage } = useHandleMessages()

  useEffect(() => {
    if (isDeleted) {
      // Removing the 'deleted' flag from the window state
      delete window.history.state?.usr?.deleted
      window.history.replaceState(window.history.state, document.title)
      // show success message
      showSuccessMessage({
        message: "Request successfully deleted",
        timeout: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      })
    }
  }, [isDeleted, showSuccessMessage])

  const queryClient = useQueryClient()
  const isMutating = useIsMutating() > 0

  return (
    <Box className={classes.requestsWrapper} data-test="request-list-page">
      {isMutating && <Loading show label="Saving..." />}
      <RequestContextProvider value={{ user, queryClient, firm }}>
        <FiltersAndTable />
      </RequestContextProvider>
    </Box>
  )
}
