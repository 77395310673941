import React from "react"
import styled from "@emotion/styled"
import Box from "@mui/material/Box"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import { SelectableCard as UISelectableCard } from "evenup-ui/SelectableCard"
import { theme } from "app/theme"

const BaseTypography = ({
  children,
  component,
  ...props
}: {
  children: React.ReactNode
  component: React.ElementType
}) => (
  <Typography component={component} {...props}>
    {children}
  </Typography>
)

export const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2.5),
  margin: `0 auto`,
  maxWidth: theme.spacing(128),
  gridColumn: "1/3",
})

export const TopTitleBadgesContainer = styled(Box)({
  position: "absolute",
  display: "flex",
  top: "47px",
  left: "67px",
  gap: theme.spacing(0.5),
})

export const SelectableCard = styled(UISelectableCard)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(4),
  maxWidth: "auto",
  cursor: "pointer",
})

export const RightContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
})

export const RightContentSubTitle = styled(BaseTypography)(({ isDisabled }: { isDisabled: boolean }) => ({
  color: isDisabled ? theme.palette.text.disabled : theme.palette.selectableCard.fontColor,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.spacing(2),
}))

export const StyledListItem = styled(ListItem)(({ isDisabled }: { isDisabled: boolean }) => ({
  color: isDisabled ? theme.palette.text.disabled : theme.palette.selectableCard.listItemColor,
  display: "list-item",
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.spacing(2),
  padding: theme.spacing(0),
}))
