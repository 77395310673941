import { useState } from "react"
import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import { green, lightBlue } from "@mui/material/colors"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import CheckCircle from "@mui/icons-material/CheckCircle"
import RemoveCircle from "@mui/icons-material/RemoveCircle"
import { Circle } from "./styled"

import { documentActions, useDocumentStore } from "documents/store"
import { appointmentsSelectors } from "documents/store/appointments"
import { APPOINTMENT_TAG_ACTIONS, Appointment, AppointmentTag } from "documents/types"
import useUser from "hooks/useUser"
import { formatDate } from "utils"

import {
  StyledReviewActionCellWrapper,
  StyledReviewActionCell,
  StyledReviewActionMenuItem,
  StyledTagList,
  StyledTagItem,
} from "../styled"

interface ReviewActionCellProps {
  appointmentId: Appointment["id"]
}

const ReviewActionCellTag = ({ tag }: { tag: AppointmentTag }) => {
  const { firstName, lastName, email } = tag.createdBy
  const name = firstName && lastName ? `${firstName} ${lastName}` : email
  return (
    <li>
      <StyledTagItem variant="helper">
        {tag.action === APPOINTMENT_TAG_ACTIONS.MARK_INCOMPLETE ? (
          <RemoveCircle
            sx={{
              fontSize: "16px",
              marginRight: "10px",
              color: "#00000080",
            }}
          />
        ) : (
          <CheckCircle
            sx={{
              fontSize: "16px",
              marginRight: "10px",
              color: tag.action === APPOINTMENT_TAG_ACTIONS.MARK_COMPLETED ? green[600] : lightBlue[700],
            }}
          />
        )}{" "}
        {name} {formatDate(tag.createdAt, "MM/dd/yyyy-h:mmaaa")}
      </StyledTagItem>
    </li>
  )
}

export const ReviewActionCell = ({ appointmentId }: ReviewActionCellProps) => {
  const { user } = useUser()
  const tags = useDocumentStore(appointmentsSelectors.getAppointmentTagsForDisplay(appointmentId))
  const completed = useDocumentStore(appointmentsSelectors.getAppointmentCompletedStatus(appointmentId))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const setAction = (action: APPOINTMENT_TAG_ACTIONS) => {
    documentActions.addAppointmentTag({ appointmentId, action, user })
    handleClose()
  }

  return (
    <StyledReviewActionCellWrapper>
      <Divider />
      <StyledReviewActionCell>
        <Button
          onClick={handleClick}
          variant="outlined"
          color="secondary"
          endIcon={<KeyboardArrowDownIcon />}
        >
          Status
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: -10,
            horizontal: 0,
          }}
        >
          {completed ? (
            <StyledReviewActionMenuItem onClick={() => setAction(APPOINTMENT_TAG_ACTIONS.MARK_INCOMPLETE)}>
              <Circle />
              Mark Incomplete
            </StyledReviewActionMenuItem>
          ) : (
            <StyledReviewActionMenuItem onClick={() => setAction(APPOINTMENT_TAG_ACTIONS.MARK_COMPLETED)}>
              <Circle completed />
              Mark Complete
            </StyledReviewActionMenuItem>
          )}
          <StyledReviewActionMenuItem onClick={() => setAction(APPOINTMENT_TAG_ACTIONS.MARK_REVIEWED)}>
            <Circle reviewed />
            Mark Reviewed
          </StyledReviewActionMenuItem>
        </Menu>
        <StyledTagList>
          {tags.map(tag => (
            <ReviewActionCellTag tag={tag} key={`${tag.createdAt}`} />
          ))}
        </StyledTagList>
      </StyledReviewActionCell>
    </StyledReviewActionCellWrapper>
  )
}
