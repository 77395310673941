import { useCallback, useMemo, useRef, useState } from "react"
import { LibraryUseForm } from "settings/Library/TemplateForms/types"
import { DocumentStructureRowData } from "common/models/library"
import { isNil, noop } from "lodash"
import { useMutation, useQuery } from "@tanstack/react-query"
import { documentStructureService } from "api/services/document-structure"
import { queryClient } from "react-query/queryClient"
import { queryKeys } from "react-query/constants"
import { StyledListItemButton } from "settings/Library/LibraryTable/styled"
import { NonUniqueAttributesError } from "api/services/types"
import { FORM_STATE_STATUS } from "../constants"
import { TemplateFormMessage } from "../TemplateFormMessage"
import { StyledDetailsSkeleton } from "./styled"
import { DocumentStructureDetails } from "../formData/DocumentStructureDetails"
import {
  DOCUMENT_STRUCTURE_TREE_ACTION_TYPE,
  useDocumentStructureItem,
} from "../formData/document-structure/store"

export const useArchivedDocumentStructureForm: LibraryUseForm<DocumentStructureRowData> = ({
  formStatusMessageMap,
  rowData,
}) => {
  const handleCancel = noop
  const [formStatus, setFormStatus] = useState<FORM_STATE_STATUS>(FORM_STATE_STATUS.IDLE)
  const [documentStructureItem, dispatch] = useDocumentStructureItem(rowData.uuid)
  const handleSubmit = noop
  const editForm = useCallback(() => null, [])
  const errorForm = false
  const isLoading = false

  const onError = useCallback((error: unknown) => {
    if (error instanceof NonUniqueAttributesError) {
      setFormStatus(FORM_STATE_STATUS.DATA_ERROR)
    } else {
      setFormStatus(FORM_STATE_STATUS.API_ERROR)
    }
  }, [])

  const onSuccess = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.SUCCESS)
  }, [])

  const unarchiveDocumentStructure = useMutation(documentStructureService.unarchiveDocumentStructure, {
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries([queryKeys.documentStructuresList])
      queryClient.invalidateQueries([queryKeys.archivedDocumentStructures])
    },
    onError: (error: unknown) => onError(error),
  })

  const handleUnarchive = useCallback(
    () => unarchiveDocumentStructure.mutate({ documentStructureId: rowData.id }),
    [unarchiveDocumentStructure, rowData.id]
  )

  const currentDocumentStructureId = useRef(rowData.id)
  currentDocumentStructureId.current = rowData.id
  const { isFetched: areDetailsLoaded } = useQuery(
    [queryKeys.documentStructure, rowData.uuid],
    () =>
      documentStructureService.getDocumentStructure({
        documentStructureId: currentDocumentStructureId.current,
      }),
    {
      onSuccess: data => {
        dispatch({
          type: documentStructureItem
            ? DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.REPLACE_ITEM
            : DOCUMENT_STRUCTURE_TREE_ACTION_TYPE.SET_ITEM,
          payload: {
            data,
            id: rowData.uuid,
          },
        })
      },
      enabled: !isNil(currentDocumentStructureId.current),
      meta: {
        disableLoader: true,
      },
    }
  )

  const viewForm = useMemo(() => {
    if (!areDetailsLoaded)
      return (
        <>
          <StyledDetailsSkeleton />
          <StyledDetailsSkeleton />
          <StyledDetailsSkeleton />
        </>
      )

    if (!documentStructureItem?.sections.length) return <></>

    return <DocumentStructureDetails sections={documentStructureItem.sections} />
  }, [areDetailsLoaded, documentStructureItem?.sections])

  const clearForm = useCallback(() => {
    setFormStatus(FORM_STATE_STATUS.IDLE)
  }, [])

  const message = useMemo(() => {
    if (formStatus === FORM_STATE_STATUS.IDLE) return null

    const messageText = formStatusMessageMap[formStatus]

    if (!messageText) return null

    return <TemplateFormMessage clear={clearForm} message={messageText} formStatus={formStatus} />
  }, [clearForm, formStatus, formStatusMessageMap])

  return {
    message,
    editForm,
    handleSubmit,
    handleCancel,
    isLoading,
    viewForm,
    clearForm,
    errorForm,
    additionalRowButtons: <StyledListItemButton onClick={handleUnarchive}>Un-Archive</StyledListItemButton>,
  }
}
