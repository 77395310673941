import { DocumentTypesProps } from "./types"
import EvenupLogoDisabled from "./evenupLogoDisabled.svg?react"
import EvenupLogoBlue from "./evenupLogoBlue.svg?react"
import HospitalIcon from "./hospitalIcon.svg?react"
import { DOCUMENT_TYPES } from "requests/enums"
import { Box } from "@mui/material"
import { PremiumMedChronTopPanel } from "./PremiumMedChronTopPanel"

const STANDARD_CATEGORIES = [
  [
    "Introduction",
    "Facts and Liability Narrative",
    "ICD Codes",
    "Medical Summary",
    "Past Medical Expenses",
    "Future Medical Expenses",
    "Loss of Earnings",
    "Loss of Household Services",
  ],
  ["Pain and Suffering Analysis", "Documents Organized into Exhibits"],
]

const BASIC_CATEGORIES = [
  [
    "Introduction",
    "Facts and Liability Narrative",
    "ICD Codes",
    "Past Medical Expenses",
    "Pain and Suffering Analysis (Per-Diem Analysis)",
    "Documents Organized into Exhibits",
  ],
  [],
]

const SIMPLE_CATEGORIES = [
  [
    "Introduction",
    "Liability Statement (No Subjective Analysis)",
    "Interventional Treatments",
    "Positive Objective Tests",
  ],
  ["ICD Codes", "Past Medical Expenses", "Documents Organized into Exhibits"],
]

const MEDCHRON_CATEGORIES = [
  "Treatment Timeline",
  "Treatment Calendar",
  "Diagnostic Highlights",
  "Past Medical Visits",
  "Documents Organized into Exhibits",
  "Interactive Web View",
]

export const DOCUMENT_TYPE_LIST = (
  medicalChronologyPremiumEnabled: boolean = false,
  simpleDemandsEnabled: boolean = false
): DocumentTypesProps[] => {
  const documentTypes: DocumentTypesProps[] = [
    {
      title: "Standard Demand",
      pillTitle: "Standard Demand",
      type: "demand",
      value: DOCUMENT_TYPES.STANDARD,
      size: "large",
      paletteType: "secondary",
      description: (
        <>
          <p>Our core, comprehensive demand.</p>
          <p>
            Offers a full-scale review of your claim with a focus on maximizing recovery. Features a detailed
            medical chronology and a thorough assessment of all elements of loss, capable of adapting to many
            case types.
          </p>
        </>
      ),
      icon: <EvenupLogoBlue />,
      disabledIcon: <EvenupLogoDisabled />,
      rightContent: STANDARD_CATEGORIES,
    },
    {
      title: "Basic+ Demand",
      pillTitle: "Basic+ Demand",
      value: DOCUMENT_TYPES.BASIC_PLUS,
      size: "large",
      paletteType: "secondary",
      type: "demand",
      disabledLabel: "To create Basic+ Demands, contact",
      description: (
        <>
          <p>Designed for simplicity and impact.</p>
          <p>
            Expedited solution for smaller or easy tender cases that exposes facts, liability, ICD codes,
            medical expenses, and pain and suffering concisely and persuasively.
          </p>
        </>
      ),
      icon: <EvenupLogoBlue />,
      disabledIcon: <EvenupLogoDisabled />,
      rightContent: BASIC_CATEGORIES,
    },
    {
      title: "MedChron",
      pillTitle: "MedChron",
      value: DOCUMENT_TYPES.MEDICAL_CHRONOLOGY,
      size: "large",
      paletteType: "secondary",
      type: "medchron",
      description: (
        <>
          <p>
            Quickly learn the key case facts, without sifting through hundreds of records, so you can create a
            strong case strategy and never be caught off guard.
          </p>
          <p>Ideal for cases with a straightforward medical history.</p>
        </>
      ),
      disabledLabel: "To create Medical Chronology, contact",
      icon: <HospitalIcon />,
      disabledIcon: <EvenupLogoDisabled />,
      rightContent: [MEDCHRON_CATEGORIES],
    },
  ]

  if (medicalChronologyPremiumEnabled) {
    const premiumMedChron: DocumentTypesProps = {
      pillTitle: "Premium MedChron",
      value: DOCUMENT_TYPES.PREMIUM_MEDCHRON,
      paletteType: "secondary",
      size: "large",
      type: "medchron",
      topPanel: <PremiumMedChronTopPanel />,
      description: (
        <>
          <Box>
            All the benefits of an EvenUp MedChron, with a nurse review and flags, so you can have additional
            case insights and confidence.
          </Box>
          <p>Ideal for cases with litigation preparation or a complex, lengthy medical history.</p>
          <p>Contact your Customer Success Manager for more detail.</p>
        </>
      ),
      disabledLabel: "To create Premium MedChron, contact",
      disabledIcon: <EvenupLogoDisabled />,
      rightContent: [MEDCHRON_CATEGORIES, ["Nurse Reviewed", "Flags"]],
    }

    documentTypes.splice(documentTypes.length - 2, 0, premiumMedChron)
  }

  if (simpleDemandsEnabled) {
    const simpleDemand: DocumentTypesProps = {
      title: "Simple Demand",
      pillTitle: "Simple Demand",
      value: DOCUMENT_TYPES.SIMPLE,
      size: "large",
      paletteType: "secondary",
      type: "demand",
      disabledLabel: "To create Simple Demands, contact",
      description: (
        <>
          <p>Designed for low-complexity cases with minimal damages.</p>
          <p>
            Concentrates on key elements such as diagnoses, objective test positive results, invasive
            treatments, and past medical expenses, ensuring quicker and more targeted settlement negotiations.
            This does not include subjective analyses.
          </p>
        </>
      ),
      icon: <EvenupLogoBlue />,
      disabledIcon: <EvenupLogoDisabled />,
      rightContent: SIMPLE_CATEGORIES,
    }

    documentTypes.splice(documentTypes.length - 1, 0, simpleDemand)
  }

  return documentTypes
}
