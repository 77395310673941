import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"
import Inventory2Icon from "@mui/icons-material/Inventory2"
import OpenInNew from "@mui/icons-material/OpenInNew"
import { Filters } from "./Filters/Filters"
import { useState } from "react"
import { ActionContainer, ButtonContainer, StyledButton } from "./styled"
import { NewExhibit } from "./NewExhibit/NewExhibit"
import { SortMenu } from "./SortMenu/SortMenu"
import { DownloadAll } from "./DownloadAll"
import { config } from "common/config"
import { Tooltip } from "@mui/material"
import { Reset } from "./Reset/Reset"

enum ACTION {
  NEW = "NEW",
  FILTER = "FILTER",
  SORT = "SORT",
}

export function Actions({ caseId }: { caseId: string }) {
  const [selectedAction, setSelectedAction] = useState<Nullable<ACTION>>(null)

  const handleClick = (action: ACTION) => {
    setSelectedAction(action === selectedAction ? null : action)
  }

  return (
    <div>
      <ButtonContainer>
        <Tooltip
          title="Create a new exhibit: choose from existing files or upload new ones"
          arrow
          placement="top"
        >
          <StyledButton
            isActive={selectedAction === ACTION.NEW}
            startIcon={<FileUploadOutlinedIcon />}
            onClick={() => handleClick(ACTION.NEW)}
          >
            New Exhibit
          </StyledButton>
        </Tooltip>
        <Tooltip
          title="Filter your exhibits below. This will not affect generated demand or other sections of the portal"
          arrow
          placement="top"
        >
          <StyledButton
            isActive={selectedAction === ACTION.FILTER}
            startIcon={selectedAction === ACTION.FILTER ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}
            onClick={() => handleClick(ACTION.FILTER)}
            data-test="filter-button"
          >
            Filters
          </StyledButton>
        </Tooltip>
        <Tooltip title="Sort and group your exhibits to meet firm requirements" arrow placement="top">
          <StyledButton
            isActive={selectedAction === ACTION.SORT}
            startIcon={selectedAction === ACTION.SORT ? <Inventory2Icon /> : <Inventory2OutlinedIcon />}
            onClick={() => handleClick(ACTION.SORT)}
            data-test="sort-menu-button"
          >
            Open Exhibit Sorting Menu
          </StyledButton>
        </Tooltip>
        <Tooltip
          title="Download all exhibits below. The files will be downloaded with no annotations,
 and in the exact state they appear in generated demand"
          arrow
          placement="top"
        >
          <DownloadAll caseId={caseId} />
        </Tooltip>
        <Tooltip
          title="Reset all exhibit manipulations and return to original intake view "
          arrow
          placement="top"
        >
          <Reset />
        </Tooltip>
        <Tooltip title="Opens to the Kili annotation program " arrow placement="top">
          <StyledButton
            startIcon={<OpenInNew />}
            target="_blank"
            href={`${config.api.host}/case/${caseId}/kili/`}
          >
            Annotation Tool
          </StyledButton>
        </Tooltip>
      </ButtonContainer>
      {selectedAction && (
        <ActionContainer>
          {selectedAction === ACTION.FILTER && <Filters />}
          {selectedAction === ACTION.SORT && <SortMenu />}
          {selectedAction === ACTION.NEW && <NewExhibit />}
        </ActionContainer>
      )}
    </div>
  )
}
