import { useCallback } from "react"
import FileIcon from "@mui/icons-material/InsertDriveFileOutlined"
import CancelIcon from "@mui/icons-material/Cancel"
import { ArrayPath, Control, FieldErrors, FieldValues, useController, useFieldArray } from "react-hook-form"
import { FieldPathWithValue } from "common/types/helper"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { isNull } from "lodash"
import FileDropzone, { FileDropzoneProps } from "./FileDropzone"
import { PendingFile } from "./types"
import { includesFile, mapFileToPending, mapPendingToFile } from "./utils"
import { FileAttributes, FileNameLabel, FilesContainer, FileWrapper, ZipFileErrorMessage } from "./styled"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"

interface DragAndDropFileFieldFormProps<TFields extends FieldValues> {
  control: Control<TFields>
  name: FieldPathWithValue<TFields, PendingFile[]>
  errors: FieldErrors<TFields>
}

type DragAndDropFileFieldProps<TFields extends FieldValues> = DragAndDropFileFieldFormProps<TFields> &
  Omit<FileDropzoneProps, "onDrop">

export function DragAndDropFileField<TFields extends FieldValues>({
  control,
  name,
  disabled,
  ...dropZoneProps
}: DragAndDropFileFieldProps<TFields>): JSX.Element {
  const { fields, append, remove } = useFieldArray({ name: name as unknown as ArrayPath<TFields>, control })
  const { field: pendingFilesField } = useController({ control, name })

  const handleDrop = useCallback<FileDropzoneProps["onDrop"]>(
    droppedFiles => {
      const pendingFiles = pendingFilesField.value as PendingFile[]
      const pendingRawFiles = pendingFiles.map(mapPendingToFile)
      const newFiles: File[] = droppedFiles
        .map(file => ("file" in file ? file.file : file))
        .filter(file => !isNull(file)) as File[]
      const filesToAdd = newFiles.filter(file => !includesFile(pendingRawFiles, file))

      if (filesToAdd.length) {
        // Too hard to resolve correct typings for react-hook-form
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        append(filesToAdd.map(mapFileToPending) as any[])
      }
    },
    [append, pendingFilesField]
  )

  const handleFileRemove = (index: number) => () => {
    remove(index)
  }

  const pendingFiles = pendingFilesField.value as PendingFile[]

  return (
    <DndProvider backend={HTML5Backend}>
      <FileDropzone onDrop={handleDrop} {...dropZoneProps} disabled={disabled}>
        {dropZoneProps.children}
        {pendingFiles.length ? (
          <FilesContainer>
            {pendingFiles.map((file, i) => {
              const isZipFile = file.file.type === "application/zip"
              return fields[i] ? (
                <>
                  <FileWrapper key={fields[i].id}>
                    <FileAttributes>
                      <FileIcon />
                      <FileNameLabel>{file.name}</FileNameLabel>
                    </FileAttributes>
                    <FileAttributes>
                      <Box>
                        <IconButton disabled={disabled} data-file-index={i} onClick={handleFileRemove(i)}>
                          <CancelIcon />
                        </IconButton>
                      </Box>
                    </FileAttributes>
                  </FileWrapper>
                  {isZipFile && (
                    <Box gridColumn="1 / -1" marginLeft="40px">
                      <ZipFileErrorMessage>
                        Uploading a folder instead of zipped files can speed up the generation of your demand
                      </ZipFileErrorMessage>
                    </Box>
                  )}
                </>
              ) : null
            })}
          </FilesContainer>
        ) : null}
      </FileDropzone>
    </DndProvider>
  )
}
