import { useCallback } from "react"
import { useMutation } from "@tanstack/react-query"
import { Box } from "@mui/material"

import { providerInterventionalTreatmentService } from "api/services/provider-interventional-treatment"
import { useFormContext } from "demand/context"
import { ADD_INTERVENTIONAL_TREATMENT, Action } from "demand/Providers/reducer"
import { Provider } from "demand/Providers/types"
import TextButton from "common/buttons/TextButton"
import { InterventionalTreatmentForm } from "./InterventionalTreatmentForm"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { InterventionalTreatmentItemSkeleton } from "./components"
import { SectionHeading } from "../../styled"
import { InterventionalTreatmentEditableRowHeader, ReferenceEditableRow } from "./styled"

interface InterventionalTreatmentsSectionProps {
  disabled: boolean
  dispatch: React.Dispatch<Action>
  provider: Provider
}

export const InterventionalTreatmentsSection = ({
  disabled,
  dispatch,
  provider,
}: InterventionalTreatmentsSectionProps) => {
  const { caseId } = useFormContext()
  const providerId = provider.pk

  const { showErrorMessage } = useHandleMessages()

  const { mutate: createNewInterventionalTreatment, isLoading: isCreatingNewInterventionalTreatment } =
    useMutation(providerInterventionalTreatmentService.createProviderInterventionalTreatment, {
      onSuccess: data => {
        dispatch({
          type: ADD_INTERVENTIONAL_TREATMENT,
          payload: { providerId, interventionalTreatment: data },
        })
      },
      onError: error =>
        showErrorMessage({
          message: "Error adding new Objective Test.",
          error,
        }),
    })

  const handleAddInterventionalTreatment = useCallback(
    () => createNewInterventionalTreatment({ providerId, caseId }),
    [createNewInterventionalTreatment, providerId, caseId]
  )

  return (
    <Box mb={3}>
      <SectionHeading>Interventional Treatment</SectionHeading>

      <InterventionalTreatmentEditableRowHeader>
        <Box>Treatment name</Box>
        <Box>Date</Box>
        <ReferenceEditableRow>
          <Box>Reference Exhibit</Box>
          <Box>Page Number</Box>
        </ReferenceEditableRow>
      </InterventionalTreatmentEditableRowHeader>

      {provider.interventional_treatments?.map(interventionalTreatment => (
        <InterventionalTreatmentForm
          key={interventionalTreatment.id}
          interventionalTreatment={interventionalTreatment}
          dispatch={dispatch}
          provider={provider}
        />
      ))}

      {isCreatingNewInterventionalTreatment && <InterventionalTreatmentItemSkeleton />}

      <TextButton onClick={handleAddInterventionalTreatment} disabled={disabled}>
        + ADD NEW INTERVENTIONAL TREATMENT
      </TextButton>
    </Box>
  )
}
