import { InputAdornment, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"

const SearchInput = ({
  value,
  onChange,
  placeholder,
  width = "250px",
}: {
  value: unknown
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder: string
  label?: string
  width?: string
}) => {
  return (
    <TextField
      size="small"
      value={value}
      onChange={onChange}
      variant="outlined"
      placeholder={placeholder}
      data-test="searchbar"
      style={{ width }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  )
}

export { SearchInput as default }
