import { FocusEventHandler, PropsWithChildren, useCallback, useContext, useMemo } from "react"
import { VerticalCenterBox } from "common"
import { FirmSelect } from "common/attributes-filter/FirmSelect"
import { AutocompleteInput, AutocompleteInputValue } from "common/form-components/AutocompleteInput"
import { attributesService } from "api/services/attributes"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import {
  AttributeFiltersData,
  SectionAndAttributesFilter,
  SectionWithAttributes,
} from "common/attributes-filter"
import { isNil, isNull } from "lodash"
import { TextField } from "@mui/material"
import { StyledFormControl } from "common/form-components/styled"
import { DocumentStructureFormData } from "settings/Library/TemplateForms/types"
import { documentStructureService } from "api/services/document-structure"
import { LibraryVariableServiceSerializer } from "api/services/library-variable/serializers"
import { LibraryTabStateContext } from "../State/constants"
import { createRenderPageActionAction } from "../State/templatesActions"
import { StyledViewTemplatesButton } from "./styled"
import { FIRMS_SECTION_TEMPALATES } from "../Tabs/constants"

interface LibraryFiltersProps {
  onlyFirmsUserManage?: boolean
  creating?: boolean
  errorInFirm?: boolean
  onReset?: () => void
  setFirmId: (firmId: Nullable<PrimaryKey>) => void
  setAttributes: (attribues: SectionWithAttributes) => void
  setDocumentName: (name: Nullable<string>) => void
  documentName: DocumentStructureFormData["documentName"]
  initialAttributeValues?: AttributeFiltersData
  firmId: DocumentStructureFormData["firmId"]
  attributes: DocumentStructureFormData["sectionWithAttributes"]
  showResetButton?: boolean
}

export function DocumentStructureFilters({
  onlyFirmsUserManage,
  setFirmId,
  setAttributes,
  setDocumentName,
  documentName,
  firmId,
  attributes,
  onReset,
  initialAttributeValues,
  showResetButton,
  errorInFirm = false,
  creating = false,

  children,
}: PropsWithChildren<LibraryFiltersProps>): Nullable<JSX.Element> {
  const { dispatch } = useContext(LibraryTabStateContext)
  const documentNameDisabled = !firmId
  const { data } = useQuery([queryKeys.attributes, "document-structure"], async () =>
    attributesService.getAvailableDocumentStructureAttributes(null)
  )

  const { data: documentStructureNames } = useQuery(
    [queryKeys.documentStructureNames, firmId, attributes],
    () =>
      documentStructureService.getDocumentStructureNames({
        attributes: attributes?.attributeValues,
        firmId,
      }),
    {
      initialData: [],
      enabled:
        Boolean(firmId) &&
        Boolean(
          attributes?.attributeValues &&
            LibraryVariableServiceSerializer.toAttributeValuesJSON(attributes.attributeValues).length
        ),
    }
  )

  const documentNameOptions = useMemo(() => {
    if (!documentStructureNames) return []

    return documentStructureNames?.map(({ documentName }) => ({ key: documentName, display: documentName }))
  }, [documentStructureNames])

  const handleSelectDocumentName = useCallback(
    (value: AutocompleteInputValue) => {
      if (isNull(value)) setDocumentName(value)

      setDocumentName(String(value))
    },
    [setDocumentName]
  )

  const handleChangeDocumentName = useCallback<FocusEventHandler<HTMLInputElement>>(
    e => {
      setDocumentName(e.target.value)
    },
    [setDocumentName]
  )

  const handleViewTemplates = useCallback(() => {
    dispatch(createRenderPageActionAction({ page: FIRMS_SECTION_TEMPALATES }))
  }, [dispatch])

  const additionalFilters = useMemo(() => {
    return [
      {
        order: 0,
        component: (
          <FirmSelect
            key="firm_select_filter"
            firmId={firmId}
            setFirmId={setFirmId}
            onlyFirmsUserManage={onlyFirmsUserManage}
            error={errorInFirm}
            helperText={errorInFirm ? "Firm is required" : undefined}
          />
        ),
      },
      {
        order: 998,
        component: creating ? (
          <StyledFormControl key="document_name_filter">
            <TextField
              defaultValue={documentName}
              disabled={documentNameDisabled}
              label="Document name"
              onBlur={handleChangeDocumentName}
              size="small"
            />
          </StyledFormControl>
        ) : (
          <AutocompleteInput
            freeSolo={false}
            key="document_name_filter"
            label="Document name"
            disabled={documentNameDisabled}
            options={documentNameOptions}
            onChange={handleSelectDocumentName}
            value={documentName}
          />
        ),
      },
      {
        order: 999,
        component: !isNil(firmId) && creating && (
          <StyledViewTemplatesButton key="view_template_button" onClick={handleViewTemplates}>
            View Templates
          </StyledViewTemplatesButton>
        ),
      },
    ]
  }, [
    creating,
    documentName,
    documentNameDisabled,
    errorInFirm,
    firmId,
    handleChangeDocumentName,
    handleSelectDocumentName,
    onlyFirmsUserManage,
    setFirmId,
    documentNameOptions,
    handleViewTemplates,
  ])

  if (!data) return null

  return (
    <VerticalCenterBox sx={{ flexGrow: 1, justifyContent: "space-between" }}>
      <SectionAndAttributesFilter
        onReset={onReset}
        showResetButton={showResetButton}
        initialAttributeValues={initialAttributeValues}
        attributes={data}
        onChange={setAttributes}
        showSection={false}
        additionalFilters={additionalFilters}
      />
      <VerticalCenterBox sx={{ height: "40px", margin: "8px 0", alignSelf: "flex-start" }}>
        {children}
      </VerticalCenterBox>
    </VerticalCenterBox>
  )
}
