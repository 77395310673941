import {
  PLAINTIFF_NAME,
  ADJUSTER,
  POLICY_NUMBER,
  POLICY_TYPE,
  POLICY_COVERAGE_TYPE,
  POLICY_LIMIT,
  CLAIM_NUMBER,
  BASIC_INFORMATION,
  CARRIER_INFORMATION,
  CASE_FACTS,
  PLAINTIFF_INFORMATION,
  FILES,
  INJURY_IMPACTS,
  ALL,
} from "../requests/constants"

export const EXHIBIT_SECTION = "exhibit"
export const DEMAND_FLOW_WRAPPER = "demand-flow-wrapper"
export const LEFT_NAV_PANEL_ORDER = 1
export const MISSING_DOCS_SECTION = "missing_docs"

export const FORM_SECTIONS = {
  PLAINTIFF: "plaintiff",
  INTRODUCTION: "introduction",
  CARRIER: "carrier",
  FACTS: "facts",
  PROVIDERS_SECTION: "providers",
  FUTURE_EXPENSES_SECTION: "future_expenses",
  NON_ECONOMIC: "non_economic",
  INCOME_LOSS: "income_loss",
  HOUSEHOLD_LOSS: "household_loss",
  LAW_FIRM: "law_firm",
  PAIN_AND_SUFFERING: "pain_and_suffering",
  DAMAGES_SECTIONS: "damages_sections",
  INTAKE_FILES: "intake_files",
  CONCLUSION: "conclusion",
}

export const formSectionsToRoutes = {
  [FORM_SECTIONS.PLAINTIFF]: "plaintiff",
  [FORM_SECTIONS.INTRODUCTION]: "introduction",
  [FORM_SECTIONS.CARRIER]: "carrier",
  [FORM_SECTIONS.FACTS]: "facts",
  [FORM_SECTIONS.PROVIDERS_SECTION]: "providers",
  [FORM_SECTIONS.FUTURE_EXPENSES_SECTION]: "future-expenses",
  [FORM_SECTIONS.NON_ECONOMIC]: "non-economic",
  [FORM_SECTIONS.INCOME_LOSS]: "income-loss",
  [FORM_SECTIONS.HOUSEHOLD_LOSS]: "other",
  [FORM_SECTIONS.LAW_FIRM]: "firm",
  [FORM_SECTIONS.PAIN_AND_SUFFERING]: "pain-and-suffering",
  [FORM_SECTIONS.DAMAGES_SECTIONS]: "damages",
  [MISSING_DOCS_SECTION]: "missing-docs",
  [EXHIBIT_SECTION]: "exhibit",
  [FORM_SECTIONS.INTAKE_FILES]: "intake-files",
  [FORM_SECTIONS.CONCLUSION]: "conclusion",
}

export const FILE_OPTIONS = {
  medical: "Medical Record",
  police_report: "Police Report",
  insurance: "Insurance Document",
  lien: "Lien Document",
  pleading: "Pleading Document",
  other: "Other",
}

export const MEDICAL_FILE_OPTIONS = {
  medical: "Medical Record",
  medical_bill: "Medical Bill",
  medical_bill_record: "Medical Bill and Record",
  police_report: "Police Report",
  insurance: "Insurance Document",
  lien: "Lien Document",
  pleading: "Pleading Document",
  other: "Other",
}

export const INCOME_FILE_OPTIONS = {
  tax: "Tax Document",
  employer_letter: "Employer Letter",
  other: "Other",
}

export const HOUSEHOLD_SERVICE_FILE_OPTIONS = {
  letter: "Letter",
  affidavit: "Affidavit",
  other: "Other",
}

export const STEP_STATUSES = {
  notStarted: "not_started",
  started: "started",
  completed: "completed",
  skipped: "skipped",
  error: "error",
}

export const REQUEST_PANEL_EXPAND = {
  BASIC_INFORMATION: BASIC_INFORMATION,
  CARRIER_INFORMATION: CARRIER_INFORMATION,
  CASE_FACTS: CASE_FACTS,
  FILES: FILES,
  INJURY_IMPACTS: INJURY_IMPACTS,
  PLAINTIFF_INFORMATION: PLAINTIFF_INFORMATION,
  ALL: ALL,
}

export const REQUEST_PANEL_HIGHLIGHT = {
  PLAINTIFF_NAME: PLAINTIFF_NAME,
  ADJUSTER: ADJUSTER,
  POLICY_TYPE: POLICY_TYPE,
  POLICY_COVERAGE_TYPE: POLICY_COVERAGE_TYPE,
  POLICY_NUMBER: POLICY_NUMBER,
  POLICY_LIMIT: POLICY_LIMIT,
  CLAIM_NUMBER: CLAIM_NUMBER,
}

export const MAX_FILE_NAME_LENGTH = 500
export const FLOATING_PANEL_WIDTH = 375

export const SECTION_TO_EXPANDED_REQUESTS = {
  [FORM_SECTIONS.PLAINTIFF]: {
    expand: [REQUEST_PANEL_EXPAND.BASIC_INFORMATION],
    highlight: [REQUEST_PANEL_HIGHLIGHT.PLAINTIFF_NAME],
  },
  [FORM_SECTIONS.CARRIER]: {
    expand: [REQUEST_PANEL_EXPAND.BASIC_INFORMATION, REQUEST_PANEL_EXPAND.CARRIER_INFORMATION],
    highlight: [
      REQUEST_PANEL_HIGHLIGHT.ADJUSTER,
      REQUEST_PANEL_HIGHLIGHT.POLICY_TYPE,
      REQUEST_PANEL_HIGHLIGHT.POLICY_COVERAGE_TYPE,
      REQUEST_PANEL_HIGHLIGHT.POLICY_NUMBER,
      REQUEST_PANEL_HIGHLIGHT.POLICY_LIMIT,
      REQUEST_PANEL_HIGHLIGHT.CLAIM_NUMBER,
    ],
  },
  [FORM_SECTIONS.FACTS]: {
    expand: [REQUEST_PANEL_EXPAND.CASE_FACTS, REQUEST_PANEL_EXPAND.FILES],
    highlight: [],
  },
  [FORM_SECTIONS.PROVIDERS_SECTION]: {
    expand: [REQUEST_PANEL_EXPAND.CASE_FACTS, REQUEST_PANEL_EXPAND.FILES],
    highlight: [],
  },
  [FORM_SECTIONS.FUTURE_EXPENSES_SECTION]: {
    expand: [REQUEST_PANEL_EXPAND.INJURY_IMPACTS],
    highlight: [],
  },
  [FORM_SECTIONS.NON_ECONOMIC]: {
    expand: [REQUEST_PANEL_EXPAND.CASE_FACTS, REQUEST_PANEL_EXPAND.INJURY_IMPACTS],
    highlight: [],
  },
  [FORM_SECTIONS.INCOME_LOSS]: {
    expand: [REQUEST_PANEL_EXPAND.CARRIER_INFORMATION],
    highlight: [],
  },
  [FORM_SECTIONS.HOUSEHOLD_LOSS]: {
    expand: [REQUEST_PANEL_EXPAND.PLAINTIFF_INFORMATION, REQUEST_PANEL_EXPAND.INJURY_IMPACTS],
    highlight: [],
  },
  [FORM_SECTIONS.LAW_FIRM]: {
    expand: [],
    highlight: [],
  },
  [FORM_SECTIONS.DAMAGES_SECTIONS]: {
    expand: [REQUEST_PANEL_EXPAND.ALL],
    highlight: [],
  },
  [FORM_SECTIONS.INTAKE_FILES]: {
    expand: [REQUEST_PANEL_EXPAND.CASE_FACTS],
    highlight: [],
  },
}

export const WORKING_HOURS_TIMEFRAME = {
  week: "Week",
  month: "Month",
  year: "Year",
}

export const WORKING_HOURS_TIMEFRAME_OPTIONS = Object.keys(WORKING_HOURS_TIMEFRAME).map(key => {
  return {
    key: key,
    display: WORKING_HOURS_TIMEFRAME[key],
  }
})

export const IMPAIRMENT_TIME_UNIT_MAP = new Map([
  ["days", { key: "days", display: "Days" }],
  ["years", { key: "years", display: "Years" }],
])
