import { RequestFormModule } from "requests/RequestForm/SinglePageRequestForm/components/RequestFormModule"
import { DemandPackageFile } from "requests/ViewRequest/DemandPackageFile"
import { StyledSubmitMedicalChronologyDescription } from "../styled"
import { ViewRequestRevampProps } from "../types"
import { MEDICAL_CHRONOLOGY_PACKAGE_FILE_TYPES } from "requests/constants"

export const SubmitMedicalChronology = ({ request }: ViewRequestRevampProps): JSX.Element => {
  return (
    <RequestFormModule title="Submit Medical Chronology">
      <StyledSubmitMedicalChronologyDescription>
        After generating and editing the downloaded Medical Chronology. Upload here for Client submission.
        <br />
        Please change status to “Complete” when finished.
      </StyledSubmitMedicalChronologyDescription>
      <DemandPackageFile
        request={request}
        showEmptyState={false}
        fileTypes={MEDICAL_CHRONOLOGY_PACKAGE_FILE_TYPES}
      />
    </RequestFormModule>
  )
}
