import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { Attributes, RootAttributeTreeItem } from "common/types/attributes"
import { DocumentStructureDefinition } from "common/types/documentStructure"
import { LibraryVariableDefinition } from "common/types/libraryVariables"
import { ProviderTemplateDefinition } from "common/types/providerTemplates"
import { CASE_SECTIONS } from "common/types/sections"
import { SectionTemplateDefinition } from "common/types/templates"
import { upperFirst } from "lodash"
import { getDocumentStructureTitle } from "settings/Library/Tabs/utils"
import { DocumentStructureSection } from "settings/Library/TemplateForms/formData/document-structure/types"
import { v4 } from "uuid"

export const TEMPLATE_NAME_COLUMN = "Template Name"
export const FIRM_NAME_COLUMN = "Firm Name"
export const DOCUMENT_TYPE_COLUMN = "Document type"
export const DOCUMENT_NAME_COLUMN = "Document name"
export const VARIABLE_NAME_COLUMN = "Variable Name"

export const LAST_EDITED_COLUMN = "Last Edited"
export const DEFAULT_NONE_VALUE = "\u200B—\u200B"

export interface TableViewModelCellData {
  key: string | number
  value: Nullable<string | number>
}

export interface SectionTemplateTableRowData {
  id?: PrimaryKey
  initialAttributes?: SectionTemplateDefinition["attributes"]
  initialFirmId: Nullable<PrimaryKey>
  initialSection?: CASE_SECTIONS
  initialContent?: EditorRoot
  freezeFirmId?: boolean
}

export interface LibraryVariableRowData {
  id?: PrimaryKey
  initialAttributes?: LibraryVariableDefinition["attributes"]
  initialGroupName?: string
  initialGroupId?: PrimaryKey
  initialFirmId: Nullable<PrimaryKey>
  initialVariableContent?: string
}

export interface DocumentStructureRowData {
  uuid: string
  id?: PrimaryKey
  initialAttributes?: DocumentStructureDefinition["attributes"]
  initialLetterhead?: DocumentStructureDefinition["letterhead"]
  initialFirmId: Nullable<PrimaryKey>
  initialDocumentName?: Nullable<string>
  initialSections: DocumentStructureSection[]
  documentStructureTitle?: string
  isDraft: boolean
  isDuplicated: boolean
}

export interface ProviderTemplateRowData {
  id?: PrimaryKey
  initialContent?: EditorRoot
  initialTemplateName?: string
  initialGeneratorKey?: string
  initialPossibleGeneratorKeys?: string[]
}

export type LibraryData =
  | SectionTemplateTableRowData
  | LibraryVariableRowData
  | ProviderTemplateRowData
  | DocumentStructureRowData

export class TableRowViewModel<T> {
  readonly rowId: string
  readonly items: TableViewModelCellData[]
  readonly rowData: T

  constructor(rowId: string, items: TableViewModelCellData[], rowData: T) {
    this.rowId = rowId
    this.items = items
    this.rowData = rowData
  }
}

export class TemplateTableRowViewModel<T> extends TableRowViewModel<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(rowId: string, items: TableViewModelCellData[], rowData: any) {
    super(rowId, items, rowData)
  }
}

class IdGetter {
  idMap = {} as Record<PrimaryKey, string>

  getRowId(entity: { id: PrimaryKey; prevId?: PrimaryKey }) {
    if (this.idMap[entity.id]) {
      return this.idMap[entity.id]
    }

    if (entity.prevId && this.idMap[entity.prevId]) {
      this.idMap[entity.id] = this.idMap[entity.prevId]
      return this.idMap[entity.prevId]
    }

    const id = v4()

    this.idMap[entity.id] = id

    return id
  }
}

export abstract class TableViewModel<T> {
  abstract readonly headers: string[]
  abstract readonly rows: TableRowViewModel<T>[]
  static idGetter: IdGetter
}

function getAttributeValue(
  attribute: RootAttributeTreeItem | undefined,
  templateAttributes: SectionTemplateDefinition["attributes"]
): string {
  if (!attribute) {
    return DEFAULT_NONE_VALUE
  }

  const value = attribute.value?.value

  if (!value) {
    const templateAttributeValue = templateAttributes.find(
      item => item.id === attribute.attribute.id
    )?.displayValue

    return templateAttributeValue ?? DEFAULT_NONE_VALUE
  }

  const childrenValue = attribute.children
    .map(child => child.value?.value)
    .filter((value): value is string => typeof value === "string")
    .join(", ")

  if (!childrenValue) return value

  return `${value} - ${childrenValue}`
}

export class SectionTemplatesTableViewModel extends TableViewModel<SectionTemplateTableRowData> {
  readonly headers: string[]
  readonly rows: TemplateTableRowViewModel<SectionTemplateTableRowData>[]

  static idGetter = new IdGetter()

  constructor(
    templates: SectionTemplateDefinition[],
    attributes: Attributes,
    showFirmInfo: boolean,
    freezeFirmId?: boolean
  ) {
    super()
    const rootAttributes = attributes.getAttributesList({})

    this.headers = [
      TEMPLATE_NAME_COLUMN,
      ...rootAttributes.map(attribute => attribute.title),
      LAST_EDITED_COLUMN,
    ]

    if (showFirmInfo) {
      this.headers.splice(1, 0, FIRM_NAME_COLUMN)
    }

    this.rows = templates.map(template => {
      const attributesTree = attributes.getAttributesTree(
        Object.fromEntries(template.attributes.map(attribute => [attribute.id, attribute.valueId]))
      )

      const items = [
        { key: TEMPLATE_NAME_COLUMN, value: upperFirst(template.section) },
        ...rootAttributes.map(attribute => {
          const item = attributesTree.find(item => item.attribute.id === attribute.id)

          return {
            key: attribute.id,
            value: getAttributeValue(item, template.attributes),
          }
        }),
        { key: LAST_EDITED_COLUMN, value: template.updatedAt },
      ]

      if (showFirmInfo) {
        items.splice(1, 0, { key: FIRM_NAME_COLUMN, value: template.firm?.name ?? DEFAULT_NONE_VALUE })
      }

      const rowData = {
        id: template.id,
        initialContent: template.content,
        initialAttributes: template.attributes,
        initialSection: template.section,
        initialFirmId: template.firm?.pk ?? null,
        freezeFirmId: !!freezeFirmId,
      }

      return new TemplateTableRowViewModel(
        SectionTemplatesTableViewModel.idGetter.getRowId(template),
        items,
        rowData
      )
    })
  }
}

export class ProviderTemplatesTableViewModel extends TableViewModel<ProviderTemplateRowData> {
  readonly headers: string[]
  readonly rows: TemplateTableRowViewModel<ProviderTemplateRowData>[]
  static idGetter = new IdGetter()

  constructor(providers: ProviderTemplateDefinition[]) {
    super()
    this.headers = [TEMPLATE_NAME_COLUMN, LAST_EDITED_COLUMN]
    this.rows = providers.map(provider => {
      const items = [
        { key: TEMPLATE_NAME_COLUMN, value: provider.templateName },
        { key: LAST_EDITED_COLUMN, value: provider.updatedAt },
      ]

      const rowData = {
        id: provider.id,
        initialContent: provider.content,
        initialTemplateName: provider.templateName,
        initialGeneratorKey: provider.generatorKey,
        initialPossibleGeneratorKeys: provider.possibleGeneratorKeys,
      }

      return new TemplateTableRowViewModel(
        ProviderTemplatesTableViewModel.idGetter.getRowId(provider),
        items,
        rowData
      )
    })
  }
}

export class LibraryVariableTableViewModel extends TableViewModel<LibraryVariableRowData> {
  readonly headers: string[]
  readonly rows: TemplateTableRowViewModel<LibraryVariableRowData>[]
  static idGetter = new IdGetter()

  constructor(variables: LibraryVariableDefinition[], attributes: Attributes, showFirmInfo: boolean) {
    super()
    const rootAttributes = attributes.getAttributesList({})

    this.headers = [
      VARIABLE_NAME_COLUMN,
      ...rootAttributes.map(attribute => attribute.title),
      LAST_EDITED_COLUMN,
    ]

    if (showFirmInfo) {
      this.headers.splice(1, 0, FIRM_NAME_COLUMN)
    }

    this.rows = variables.map(variable => {
      const attributesTree = attributes.getAttributesTree(
        Object.fromEntries(variable.attributes.map(attribute => [attribute.id, attribute.valueId]))
      )

      const items = [
        { key: "name", value: variable.groupName },
        ...rootAttributes.map(attribute => {
          const item = attributesTree.find(item => item.attribute.id === attribute.id)

          return {
            key: attribute.id,
            value: getAttributeValue(item, variable.attributes),
          }
        }),
        { key: LAST_EDITED_COLUMN, value: variable.updatedAt },
      ]

      if (showFirmInfo) {
        items.splice(1, 0, { key: FIRM_NAME_COLUMN, value: variable.firm?.name ?? DEFAULT_NONE_VALUE })
      }

      const rowData = {
        id: variable.id,
        initialGroupName: variable.groupName,
        initialGroupId: variable.groupId,
        initialVariableContent: variable.content,
        initialAttributes: variable.attributes,
        initialFirmId: variable.firm?.pk ?? null,
      }

      return new TemplateTableRowViewModel(
        LibraryVariableTableViewModel.idGetter.getRowId(variable),
        items,
        rowData
      )
    })
  }
}

export class DocumentStructureViewModel extends TableViewModel<DocumentStructureRowData> {
  readonly headers: string[]
  readonly rows: TemplateTableRowViewModel<DocumentStructureRowData>[]

  static idGetter = new IdGetter()

  constructor(documentStructures: DocumentStructureDefinition[], attributes: Attributes) {
    super()
    const rootAttributes = attributes.getAttributesList({})

    this.headers = [FIRM_NAME_COLUMN, DOCUMENT_TYPE_COLUMN, DOCUMENT_NAME_COLUMN, LAST_EDITED_COLUMN]
    this.rows = documentStructures.map(documentStructure => {
      const attributesTree = attributes.getAttributesTree(
        Object.fromEntries(documentStructure.attributes.map(attribute => [attribute.id, attribute.valueId]))
      )

      const items = [
        { key: FIRM_NAME_COLUMN, value: documentStructure.firm?.name ?? DEFAULT_NONE_VALUE },
        ...rootAttributes.map(attribute => {
          const item = attributesTree.find(item => item.attribute.id === attribute.id)

          return {
            key: attribute.id,
            value: getAttributeValue(item, documentStructure.attributes),
          }
        }),
        { key: DOCUMENT_NAME_COLUMN, value: documentStructure.documentName || DEFAULT_NONE_VALUE },
        { key: LAST_EDITED_COLUMN, value: documentStructure.updatedAt },
      ]

      const uuid = DocumentStructureViewModel.idGetter.getRowId(documentStructure)

      const rowData: DocumentStructureRowData = {
        uuid,
        id: documentStructure.id,
        initialLetterhead: documentStructure.letterhead,
        initialDocumentName: documentStructure.documentName,
        initialAttributes: documentStructure.attributes,
        initialFirmId: documentStructure.firm?.pk ?? null,
        initialSections: [],
        isDraft: documentStructure.isDraft,
        isDuplicated: documentStructure.isDuplicated,
        documentStructureTitle: getDocumentStructureTitle(items),
      }

      return new TemplateTableRowViewModel(uuid, items, rowData)
    })
  }
}
