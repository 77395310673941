import { useContext, useMemo, useEffect } from "react"

import { Variable } from "common/types/variables"
import { CaseVariablesContext } from "./context"

export interface UseCaseVariablesReturn {
  variables: Variable[]
}

export function useCaseVariables(customVariables: Variable[] = []): UseCaseVariablesReturn {
  const { variables, refetchCaseVariables } = useContext(CaseVariablesContext)
  const variablesMap = useMemo(() => {
    const entries = [...variables, ...customVariables].map<[Variable["name"], Variable]>(variable => [
      variable.name,
      variable,
    ])
    return new Map(entries)
  }, [variables, customVariables])

  const result = useMemo<UseCaseVariablesReturn>(
    () => ({ variables: [...variablesMap.values()] }),
    [variablesMap]
  )

  // Refetch case variables on each hook invocation to ensure the latest values are retrieved
  useEffect(() => {
    refetchCaseVariables?.()
  }, [refetchCaseVariables])

  return result
}
