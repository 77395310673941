import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import MoreHoriz from "@mui/icons-material/MoreHoriz"
import { removeAction } from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { usePublish } from "message-broker/usePublish"
import React, { MouseEvent, useCallback, useMemo, useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { updateAnnotatedExhibits } from "../cacheUtils"
import { AnnotatedExhibit, CaseId } from "../types"

const removeActionInDuplicate =
  ({ duplicateId, actionId }: { duplicateId: number; actionId: number }) =>
  (exhibit: AnnotatedExhibit): Partial<AnnotatedExhibit> => {
    const newDuplicates = exhibit.duplicates.map(dup => {
      if (dup.pk !== duplicateId) {
        return dup
      }
      return { ...dup, actions: dup.actions?.filter(action => action.pk !== actionId) }
    })

    return {
      duplicates: newDuplicates,
    }
  }

const removeActionInExhibit =
  ({ actionId }: { actionId: number }) =>
  (exhibit: AnnotatedExhibit): Partial<AnnotatedExhibit> => ({
    actions: exhibit.actions.filter(action => action.pk !== actionId),
  })

interface DeletionMoreButtonProps {
  className?: string
  caseId: CaseId
  exhibitId: number
  duplicateId?: Nullable<number>
  actionId: number
}

const DeletionMoreButton: React.FC<DeletionMoreButtonProps> = ({
  className,
  caseId,
  exhibitId,
  duplicateId,
  actionId,
}) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null)
  const { showErrorMessage } = useHandleMessages()
  const publish = usePublish()
  const queryClient = useQueryClient()

  const handleOpen: React.MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    // stop propagation to prevent click from making it's way to parent table row's click
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleBackdropClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()

      handleClose()
    },
    [handleClose]
  )

  const { mutate, isLoading } = useMutation({
    mutationFn: removeAction,
    onSuccess: () => {
      queryClient.setQueryData<AnnotatedExhibit[]>([queryKeys.annotated_exhibits, caseId], oldData => {
        return updateAnnotatedExhibits({
          oldData,
          exhibitId,
          update: duplicateId
            ? removeActionInDuplicate({ duplicateId, actionId })
            : removeActionInExhibit({ actionId }),
        })
      })
      handleClose()
    },
    onError: error => {
      showErrorMessage({
        message:
          "Error when trying to undo deletion. Try again shortly and file an issue if the problem persists.",
        error,
      })
    },
  })

  const handleUndoDelete = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()

      mutate({ exhibitId, actionId })

      publish(MESSAGE_TOPIC.EXHIBIT_IS_UPDATED, { caseId, exhibitId, broadcast: true })
    },
    [actionId, caseId, exhibitId, mutate, publish]
  )

  const backdropProps = useMemo(
    () => ({ onClick: handleBackdropClick, invisible: true }),
    [handleBackdropClick]
  )

  return (
    <>
      <IconButton disabled={isLoading} onClick={handleOpen} className={className} size="small">
        <MoreHoriz />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} BackdropProps={backdropProps}>
        <MenuItem disabled={isLoading} onClick={handleUndoDelete}>
          Undo Deletion
        </MenuItem>
      </Menu>
    </>
  )
}

export default DeletionMoreButton
