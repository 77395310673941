import { BaseAnalyticsEvent } from "../types"

export enum DemandAnalyticsEventTypes {
  CaseSearchStarted = "Case Search Started",
  CaseSearchQueried = "Case Search Queried",
  CaseSearchAddedToDemand = "Case Search Added to Demand",
  CaseSearchExited = "Case Search Exited",
  DemandGenerated = "Demand Generated",
  AskAIClicked = "AskAI Clicked",
  MissingDocumentCreated = "Missing Document Created",
  PreviewDemandClicked = "Preview Demand Clicked",
  ProviderDeleted = "Provider Deleted",
  ProviderDetailsEditStarted = "Provider Details Edit Started",
  ProviderDetailsEditEnded = "Provider Details Edit Ended",
  ProviderDetailsClosed = "Provider Details Closed",
  ProviderDetailsOpened = "Provider Details Opened",
  ReviewAndGenerateStarted = "Review & Generate Started",
  ReviewAndGenerateExited = "Review & Generate Exited",
  StartedDemand = "Started Demand",
  DemandCompleted = "Demand Completed",
  FavoriteCasesStarted = "Favorite Cases Started",
  FavoriteCasesExited = "Favorite Cases Exited",
  RelevantCasesStarted = "Relevant Cases Started",
  RelevantCasesExited = "Relevant Cases Exited",
}

export class DemandAnalyticEvent<T extends DemandAnalyticsEventTypes> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: DemandAnalyticsEventDetails[T]
  ) {
    super()
  }
}

type BaseCaseSearchFields = {
  demand_id: string
  request_id: string
  request_type?: string
}
export type DemandAnalyticsEventDetails = {
  [DemandAnalyticsEventTypes.CaseSearchStarted]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.CaseSearchQueried]: BaseCaseSearchFields & {
    query_string: string
  }
  [DemandAnalyticsEventTypes.CaseSearchAddedToDemand]: BaseCaseSearchFields & {
    query_string: string
    case_name: string
  }
  [DemandAnalyticsEventTypes.CaseSearchExited]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.DemandGenerated]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.AskAIClicked]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.MissingDocumentCreated]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.PreviewDemandClicked]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.ProviderDeleted]: BaseCaseSearchFields & {
    provider_name: string
    provider_type?: string
  }
  [DemandAnalyticsEventTypes.ProviderDetailsEditStarted]: BaseCaseSearchFields & {
    provider_name: string
    provider_type?: string
  }
  [DemandAnalyticsEventTypes.ProviderDetailsEditEnded]: BaseCaseSearchFields & {
    provider_name: string
    provider_type?: string
    exit_type?: string
  }
  [DemandAnalyticsEventTypes.ProviderDetailsClosed]: BaseCaseSearchFields & {
    provider_name: string
  }
  [DemandAnalyticsEventTypes.ProviderDetailsOpened]: BaseCaseSearchFields & {
    provider_name: string
  }
  [DemandAnalyticsEventTypes.ReviewAndGenerateStarted]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.ReviewAndGenerateExited]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.StartedDemand]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.DemandCompleted]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.FavoriteCasesStarted]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.FavoriteCasesExited]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.RelevantCasesStarted]: BaseCaseSearchFields
  [DemandAnalyticsEventTypes.RelevantCasesExited]: BaseCaseSearchFields
}
