import { Workbox } from "@vite-pwa/workbox-window"
import { SERVICE_WORKER_PATH } from "./constants"

let workbox: Workbox | undefined = undefined
export function getWorkbox(): Workbox {
  if (!workbox) {
    workbox = new Workbox(SERVICE_WORKER_PATH)
  }
  return workbox
}
