import React from "react"
import Box from "@mui/material/Box"
import { ordinalSuffixOf } from "utils/utils"

import { EconomicImpacts } from "./PlaintiffsViewRevamp/EconomicImpacts"
import { NonEconomicImpacts } from "./PlaintiffsViewRevamp/NonEconomicImpacts"
import { PlaintiffContent, StyledBoxFullName, StyledTypographyLabel } from "./styled"
import { PlaintiffsViewProps } from "./types"
import { VISIBLE_PLAINTIFF_FIELDS } from "./constants"
import { theme } from "app/theme"
import { Chip } from "@mui/material"
import { INJURY_IMPACTS_KEYS } from "requests/constants"
import { removeInjuryInInjuryImpactKey } from "requests/RequestForm/AdditionalInfoRevamp/utils"
import { VisibilePlaintiffFieldDisplay } from "./PlaintiffsViewRevamp/VisibilePlaintiffFieldDisplay"
import { DOCUMENT_TYPES } from "requests/enums"

export const PlaintiffsViewRevamp = ({ plaintiffs, documentType }: PlaintiffsViewProps): React.ReactNode => {
  const isBasicPlus = documentType === DOCUMENT_TYPES.BASIC_PLUS
  return (
    <>
      {plaintiffs.map((plaintiff, index) => {
        const prefix = index === 0 ? "Primary" : ordinalSuffixOf(index + 1)
        const { first_name, last_name, is_minor } = plaintiff
        const hasNonEconomicImpacts =
          plaintiff.impacts_other ||
          Object.entries(INJURY_IMPACTS_KEYS).some(([, values]) => {
            const injuryKey = removeInjuryInInjuryImpactKey(values.key)
            if (!injuryKey) return false
            return plaintiff[injuryKey] && (plaintiff[injuryKey] ?? []).length > 0
          })

        return (
          <Box key={plaintiff.pk} data-test={`plaintiff-${index}-info`} marginBottom={theme.spacing(2)}>
            <StyledBoxFullName>
              {`${first_name} ${last_name} ${index === 0 ? `(${prefix})` : ""}`}{" "}
              {is_minor && <Chip label="Minor" size="small" />}
            </StyledBoxFullName>
            {VISIBLE_PLAINTIFF_FIELDS.map(
              ({ key, display }) =>
                ((Array.isArray(plaintiff[key]) && (plaintiff[key] as string[])?.length > 0) ||
                  (!Array.isArray(plaintiff[key]) && plaintiff[key])) && (
                  <PlaintiffContent key={key} flexDirection={"column"}>
                    <StyledTypographyLabel>{display}</StyledTypographyLabel>
                    <VisibilePlaintiffFieldDisplay plaintiff={plaintiff} field={key} />
                  </PlaintiffContent>
                )
            )}

            {!isBasicPlus && (
              <Box>
                <StyledTypographyLabel>Economic Impact</StyledTypographyLabel>
                <Box paddingLeft={theme.spacing(2)}>
                  <EconomicImpacts plaintiff={plaintiff} />
                </Box>
              </Box>
            )}
            {hasNonEconomicImpacts && !isBasicPlus && (
              <Box>
                <StyledTypographyLabel>Non-Economic Impact</StyledTypographyLabel>
                <Box paddingLeft={theme.spacing(2)}>
                  <NonEconomicImpacts plaintiff={plaintiff} />
                </Box>
              </Box>
            )}
          </Box>
        )
      })}
    </>
  )
}
