import { DocumentData, GetState, SetState } from "./types"
import { Provider } from "documents/types"
import { documentsService } from "api/services/documents"
import { documentStateActions } from "./documentState"

export type ProvidersSlice = Pick<DocumentData, "providersOrder" | "providers">

export const providersSlice: ProvidersSlice = {
  providersOrder: [],
  providers: {},
}

export const providersSelectors = {
  getProviders: (state: ProvidersSlice) =>
    state.providersOrder.map(providerId => providersSelectors.getProviderById(providerId)(state)),
  getProviderById: (id: Provider["id"]) => (state: ProvidersSlice) => state.providers[id],
}

interface CreateProviderPayload {
  name: Provider["name"]
}

interface EditProviderPayload {
  id: Provider["id"]
  name: Provider["name"]
}

export const providersActions = (set: SetState<ProvidersSlice>, get: GetState<ProvidersSlice>) => {
  const setProvider = (provider: Provider) => {
    set(({ providers }) => ({ providers: { ...providers, [provider.id]: provider } }))
  }
  const createProvider = ({ name }: CreateProviderPayload) => {
    const { documentId } = get()
    const request = documentsService.createProvider({ documentId, name })

    request.then(provider =>
      set(({ providers, providersOrder }) => ({
        providers: { ...providers, [provider.id]: provider },
        providersOrder: [...providersOrder, provider.id],
      }))
    )

    return request
  }
  const renameProvider = ({ name, id }: EditProviderPayload) => {
    const { documentId } = get()
    const provider = providersSelectors.getProviderById(id)(get())
    setProvider({ ...provider, name })

    const request = documentsService.renameProvider({ documentId, providerId: id, name })
    request.catch(() => setProvider(provider))

    documentStateActions.save(request, "There was an error renaming provider.")

    return request
  }

  return {
    createProvider,
    renameProvider,
  }
}
