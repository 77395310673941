import { UndrawCancel } from "common/assets"
import { Box, Button, Typography } from "@mui/material"

import { useNavigate } from "react-router-dom"
import { parseError } from "common/errorUtils"

const REQUEST_NOT_FOUND_ERROR = `Sorry, it looks like you don’t have access to this page. \nContact your firm admin to get access.`
const SOMETHING_WENT_WRONG = `Sorry something went wrong. If problem persists, please contact support.`

const ErrorMap: { [key: string]: { header: string; message: string; picture: JSX.Element } } = {
  notFound: {
    header: "Request Unavailable",
    message: REQUEST_NOT_FOUND_ERROR,
    picture: <UndrawCancel width="100%" height="250" />,
  },
  default: {
    header: "Something went wrong",
    message: SOMETHING_WENT_WRONG,
    picture: <UndrawCancel width="100%" height="250" />,
  },
}

const getError = (type: unknown) => {
  if (typeof type !== "string") return ErrorMap.default

  return ErrorMap[type] || ErrorMap.default
}

export const ViewRequestFallback: React.FC<{ error: Error; resetErrorBoundary: () => void }> = ({
  error,
}) => {
  const navigate = useNavigate()

  const parsedError = parseError(error)

  let errorType = "default"
  if (parsedError?.type && parsedError.status === 404) {
    errorType = "notFound"
  }

  const { header, message, picture } = getError(errorType)

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
        flexDirection: "column",
      }}
    >
      <Box>{picture}</Box>
      <Box textAlign="center" mt={2} sx={{ whiteSpace: "pre-wrap" }}>
        <Typography variant="h2">{header}</Typography>
        <Typography variant="body1" mt={2}>
          {message}
        </Typography>
      </Box>
      <Box textAlign="center" mt={2}>
        <Button variant="contained" onClick={() => navigate("/requests")} sx={{ bgcolor: "blue.primary" }}>
          Take me to Requests
        </Button>
      </Box>
    </Box>
  )
}
