import React, { useCallback, useState } from "react"
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  IconButton,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { BaseActionButton } from "settings/Library/Tabs/styled"
import {
  StyledDeleteButtonWrapper,
  StyledDialogWrapper,
  StyledCancelButton,
  StyledIconButton,
} from "../styled"
import { StyledTextButton } from "./styled"

type DeleteButtonViewOptions =
  | {
      buttonText: string
      icon?: never
    }
  | {
      buttonText?: never
      icon: JSX.Element
    }

type DeleteButtonProps = {
  title: string
  onAction: () => void
  confirmationText: string
  question: React.ReactNode
  disabled?: boolean
} & DeleteButtonViewOptions

export const DialogButton = ({
  question,
  onAction,
  buttonText,
  icon,
  title,
  confirmationText,
  disabled,
}: DeleteButtonProps): JSX.Element => {
  const [opened, setOpened] = useState(false)

  const handleOpenDialog = useCallback(() => {
    setOpened(true)
  }, [])

  const handleCloseDialog = useCallback(() => {
    setOpened(false)
  }, [])

  const handleAction = useCallback(() => {
    handleCloseDialog()
    onAction()
  }, [handleCloseDialog, onAction])

  return (
    <>
      <Dialog open={opened} onClose={handleCloseDialog} PaperProps={{ style: { borderRadius: 14 } }}>
        <StyledDialogWrapper>
          <DialogTitle fontWeight={700}>
            {title}
            <StyledIconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </StyledIconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText color={"inherit"} textAlign={"center"}>
              {question}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <StyledCancelButton color="primary" onClick={handleCloseDialog}>
              Cancel
            </StyledCancelButton>
            <BaseActionButton variant="contained" onClick={handleAction}>
              {confirmationText}
            </BaseActionButton>
          </DialogActions>
        </StyledDialogWrapper>
      </Dialog>
      <StyledDeleteButtonWrapper>
        {icon ? (
          <IconButton disabled={disabled} onClick={handleOpenDialog}>
            {icon}
          </IconButton>
        ) : (
          <StyledTextButton disabled={disabled} onClick={handleOpenDialog}>
            {buttonText}
          </StyledTextButton>
        )}
      </StyledDeleteButtonWrapper>
    </>
  )
}
