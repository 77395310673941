import { ProductType } from "common/interfaces"
import { DOCUMENT_TYPES } from "./enums"
import {
  ECONOMIC_IMPACT_TYPES,
  INJURY_IMPACTS_KEYS_PROPS,
  NON_ECONOMIC_IMPACT_TYPES,
  PlaintiffProps,
} from "./RequestForm/AdditionalInfoRevamp"
import { DefendantProps } from "./RequestForm/SinglePageRequestForm/types"
import { PolicyCoverageType, PolicyType } from "./ViewRequest/types"

export const INDIVIDUAL = "individual"
export const COMMERCIAL = "commercial"

export const DEMAND_TYPE_RADIO_OPTIONS = [
  { key: DOCUMENT_TYPES.STANDARD, display: "Standard" },
  { key: DOCUMENT_TYPES.BASIC_PLUS, display: "Basic+" },
  { key: DOCUMENT_TYPES.SIMPLE, display: "Simple" },
]

export const AVAILABLE_CONTRACT_TYPES_MAPPING: Record<DOCUMENT_TYPES, ProductType> = {
  [DOCUMENT_TYPES.STANDARD]: "standard_demand",
  [DOCUMENT_TYPES.BASIC_PLUS]: "basic_plus",
  [DOCUMENT_TYPES.SIMPLE]: "simple",
  [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY]: "medical_chronology",
  [DOCUMENT_TYPES.PREMIUM_MEDCHRON]: "medical_chronology_premium",
}

export const ASSET_KEYS = {
  caseTypes: "case-types",
  familyStatus: "family-status",
  dependentStatus: "dependent-status",
  educationLevel: "education-level",
  recreation: "recreation",
  homeActivities: "home-activities",
  selfCare: "self-care",
  entertainment: "entertainment",
  socialActivities: "social-activities",
}

export const ASSET_TYPES = Object.values(ASSET_KEYS)

export type AssetKey = keyof typeof ASSET_KEYS

export const CASE_TYPE_KEY = {
  motorVehicleAccident: "1",
  premiseLiability: "2",
  slipAndFall: "3",
  medicalMalpractice: "4",
  dogBite: "5",
  intentionalTort: "6",
  other: "7",
} as const
export type CASE_TYPE_KEY = (typeof CASE_TYPE_KEY)[keyof typeof CASE_TYPE_KEY]

export const FAMILY_STATUS_KEY = {
  single: "1",
  livesWithSignificantOther: "2",
  married: "3",
} as const
export type FAMILY_STATUS_KEY = (typeof FAMILY_STATUS_KEY)[keyof typeof FAMILY_STATUS_KEY]

export const DEPENDENT_STATUS_KEY = {
  noDependents: "1",
  dependentChildren: "2",
  dependentSpouse: "3",
  dependentParents: "4",
} as const
export type DEPENDENT_STATUS_KEY = (typeof DEPENDENT_STATUS_KEY)[keyof typeof DEPENDENT_STATUS_KEY]

export const EDUCATION_LEVEL_KEY = {
  notAHighSchoolGraduate: "1",
  highSchoolGraduate: "2",
  someCollegeOrAssociatesDegree: "3",
  bachelorsDegree: "4",
  graduateOrProfessionalDegree: "5",
} as const
export type EDUCATION_LEVEL_KEY = (typeof EDUCATION_LEVEL_KEY)[keyof typeof EDUCATION_LEVEL_KEY]

export const RECREATION_KEY = {
  goingToTheGym: "1",
  walking: "2",
  bikeRiding: "3",
  running: "4",
  hiking: "5",
  swimming: "6",
  fishing: "7",
} as const
export type RECREATION_KEY = (typeof RECREATION_KEY)[keyof typeof RECREATION_KEY]

export const HOME_ACTIVITIES_KEY = {
  yardwork: "1",
  laundry: "2",
  dishes: "3",
  groceryShopping: "4",
  takingOutTrash: "5",
  cleaning: "6",
  carMaintenance: "7",
  gardening: "8",
  shovelingSnowLeaves: "9",
  relationshipWithSpouse: "10",
  relationshipWithChildren: "11",
  relationshipWithGrandchildren: "12",
} as const
export type HOME_ACTIVITIES_KEY = (typeof HOME_ACTIVITIES_KEY)[keyof typeof HOME_ACTIVITIES_KEY]

export const SELF_CARE_KEY = {
  sleeping: "1",
  bathing: "2",
  dressing: "3",
} as const
export type SELF_CARE_KEY = (typeof SELF_CARE_KEY)[keyof typeof SELF_CARE_KEY]

export const ENTERTAINMENT_KEY = {
  goingToMovieTheater: "1",
  bowling: "2",
  sportingEvents: "3",
  diningOut: "4",
  longTrips: "5",
} as const
export type ENTERTAINMENT_KEY = (typeof ENTERTAINMENT_KEY)[keyof typeof ENTERTAINMENT_KEY]

export const SOCIAL_ACTIVITIES_KEY = {
  parties: "1",
  churchReligiousEvents: "2",
  volunteerOpportunities: "3",
} as const
export type SOCIAL_ACTIVITIES_KEY = (typeof SOCIAL_ACTIVITIES_KEY)[keyof typeof SOCIAL_ACTIVITIES_KEY]

export type AssetOptionKey =
  | CASE_TYPE_KEY
  | FAMILY_STATUS_KEY
  | DEPENDENT_STATUS_KEY
  | EDUCATION_LEVEL_KEY
  | RECREATION_KEY
  | HOME_ACTIVITIES_KEY
  | SELF_CARE_KEY
  | ENTERTAINMENT_KEY
  | SOCIAL_ACTIVITIES_KEY

export const CASE_TYPES: Record<
  string,
  {
    // "active" controls whether the case type should be displayed as an option in the UI
    active: boolean
    display: string
    demandTypes: DOCUMENT_TYPES[]
    key: CASE_TYPE_KEY
  }
> = {
  motor_vehicle: {
    active: true,
    display: "Motor Vehicle Collision",
    demandTypes: [DOCUMENT_TYPES.STANDARD, DOCUMENT_TYPES.BASIC_PLUS, DOCUMENT_TYPES.SIMPLE],
    key: CASE_TYPE_KEY.motorVehicleAccident,
  },
  premise_liability: {
    active: true,
    display: "Premises Liability",
    demandTypes: [DOCUMENT_TYPES.STANDARD],
    key: CASE_TYPE_KEY.premiseLiability,
  },
  slip_and_fall: {
    active: true,
    display: "Slip & Fall",
    demandTypes: [DOCUMENT_TYPES.STANDARD],
    key: CASE_TYPE_KEY.slipAndFall,
  },
  med_malpractice: {
    active: false,
    display: "Medical Malpractice",
    demandTypes: [],
    key: CASE_TYPE_KEY.medicalMalpractice,
  },
  dog_bites: {
    active: true,
    display: "Dog Bite",
    demandTypes: [DOCUMENT_TYPES.STANDARD],
    key: CASE_TYPE_KEY.dogBite,
  },
  tort: {
    active: false,
    display: "Intentional Tort",
    demandTypes: [],
    key: CASE_TYPE_KEY.intentionalTort,
  },
  other: {
    active: true,
    display: "Other",
    demandTypes: [DOCUMENT_TYPES.STANDARD],
    key: CASE_TYPE_KEY.other,
  },
} as const

export const PLAINTIFF_STRING_ARRAY_VALUE_KEYS: (keyof PlaintiffProps)[] = [
  "dependent_status",
  "impacts_entertainment",
  "impacts_home_activities",
  "impacts_recreation",
  "impacts_social",
  "impacts_self_care",
]

export const INJURY_IMPACTS_KEYS: INJURY_IMPACTS_KEYS_PROPS<NON_ECONOMIC_IMPACT_TYPES> = {
  recreation: {
    key: "injury_impacts_recreation",
    display: "Recreation",
    assetKey: "recreation",
  },
  entertainment: {
    key: "injury_impacts_entertainment",
    display: "Entertainment",
    assetKey: "entertainment",
  },
  homeActivities: {
    key: "injury_impacts_home_activities",
    display: "Home Activities",
    assetKey: "home-activities",
  },
  socialActivities: {
    key: "injury_impacts_social",
    display: "Social Time",
    assetKey: "social-activities",
  },
  selfCare: {
    key: "injury_impacts_self_care",
    display: "Self Care",
    assetKey: "self-care",
  },
}

export const ECONOMIC_INJURIES_FIELDS: INJURY_IMPACTS_KEYS_PROPS<ECONOMIC_IMPACT_TYPES> = {
  has_loss_of_income: {
    key: "has_loss_of_income" as ECONOMIC_IMPACT_TYPES,
    display: "Loss of Income",
  },
  has_out_of_pocket_expenses: {
    key: "has_out_of_pocket_expenses" as ECONOMIC_IMPACT_TYPES,
    display: "Out-of-Pocket Expenses",
  },
  has_household_services: {
    // phantom field that doesn't exist on form
    key: "has_household_services" as ECONOMIC_IMPACT_TYPES,
    display: "Household Services",
  },
}

export const FAMILY_STATUS_KEYS = {
  familyStatus: {
    key: "family_status",
    display: "Status",
  },
  dependentStatus: {
    key: "dependent_status",
    display: "Dependents",
  },
}

export const EMPTY_PLAINTIFF: PlaintiffProps = {
  pk: undefined, // important to have it so react-hook-form can track new vs existing plaintiffs
  first_name: "",
  last_name: "",
  role: null,
  pronoun: null,
  is_minor: false,
  state_of_residence: "",
  ongoing_complaints: null,
  future_treatments: null,
  dependent_status: [],
  family_status: null,
  has_loss_of_income: false,
  additional_information: null,
  has_out_of_pocket_expenses: false,
  household_impairment_rate: 100, // default the same as backend
  impacts_recreation: [],
  impacts_home_activities: [],
  impacts_self_care: [],
  impacts_entertainment: [],
  impacts_social: [],
  impacts_other: null,
}

export const EMPTY_DEFENDANT: DefendantProps = {
  first_name: "",
  gender: "",
  last_name: "",
  name: "",
  type: INDIVIDUAL,
}

export const INITIAL_STATE_REQUIRED = {
  pk: undefined, // important to have it so react-hook-form can track new vs existing requests
  plaintiff_first_name: "",
  plaintiff_last_name: "",
  carrier_name: "",
  additional_information: "",
  firm_id: "",
  defendants: [EMPTY_DEFENDANT],
  policy_type: "individual",
  policy_coverage_type: "bodily_injury",
  recipient_type: "adjuster",
  plaintiffs: [EMPTY_PLAINTIFF],
  files: [],
}

export const INITIAL_STATE_FULL = {
  ...INITIAL_STATE_REQUIRED,
  case_type: "",
  date_of_incident: "",
  adjuster_first_name: "",
  adjuster_last_name: "",
  policy_number: "",
  policy_limit: "",
  case_facts: "",
  claim_number: "",
  ongoing_complaints: "",
  files: [],
  future_treatments: "",
  family_status: "",
  dependent_status: [],
  dependent_status_other: "",
  household_start_of_impairment: "",
  household_end_of_impairment: "",
  household_percent_of_chores: 0,
  education_level: "",
  injury_impacts_recreation: [],
  injury_impacts_recreation_other: null,
  injury_impacts_home_activities: [],
  injury_impacts_home_activities_other: null,
  injury_impacts_self_care: [],
  injury_impacts_self_care_other: null,
  injury_impacts_entertainment: [],
  injury_impacts_entertainment_other: null,
  injury_impacts_social: [],
  injury_impacts_social_other: null,
  injury_impacts_recreation_impact_percentage_3: 50,
  intake_status_last_modified: "",
  type: DOCUMENT_TYPES.STANDARD,
  plaintiffs: [],
  collaborators: [],
}

export enum INTAKE_STATUSES {
  notRequested = "1",
  requested = "2",
  preProcessing = "13",
  inProgress = "3",
  cancelled = "4",
  completed = "5",
  rework = "6",
  redelivered = "7",
  readyForTasker = "8",
  taskerAssigned = "9",
  taskerCompleted = "10",
  missingDocuments = "11",
  documentsUploaded = "12",
  onHold = "14",
  readyToResume = "15",
  readyForReview = "16",
  inReview = "17",
  readyToSubmit = "18",
  approvalRequired = "19",
  missingDocsPartialResponse = "20",
}

export const INTAKE_STATUSES_TEXT: Record<INTAKE_STATUSES, string> = {
  [INTAKE_STATUSES.notRequested]: "Not Requested",
  [INTAKE_STATUSES.requested]: "Requested",
  [INTAKE_STATUSES.preProcessing]: "Pre-Processing",
  [INTAKE_STATUSES.inProgress]: "In Progress",
  [INTAKE_STATUSES.cancelled]: "Cancelled",
  [INTAKE_STATUSES.completed]: "Completed",
  [INTAKE_STATUSES.rework]: "Rework",
  [INTAKE_STATUSES.redelivered]: "Redelivered",
  [INTAKE_STATUSES.readyForTasker]: "Ready for Tasker",
  [INTAKE_STATUSES.taskerAssigned]: "Tasker assigned",
  [INTAKE_STATUSES.taskerCompleted]: "Tasker completed",
  [INTAKE_STATUSES.missingDocuments]: "Missing Documents",
  [INTAKE_STATUSES.documentsUploaded]: "Documents Uploaded",
  [INTAKE_STATUSES.onHold]: "On Hold",
  [INTAKE_STATUSES.readyToResume]: "Ready to Resume",
  [INTAKE_STATUSES.readyForReview]: "Ready for Review",
  [INTAKE_STATUSES.inReview]: "In Review",
  [INTAKE_STATUSES.readyToSubmit]: "Review Complete - Ready to Submit",
  [INTAKE_STATUSES.approvalRequired]: "Review Complete - Approval Required",
  [INTAKE_STATUSES.missingDocsPartialResponse]: "Missing Docs - Partial Response",
}

export const INTAKE_NEW_STATUSES_TEXT: Record<INTAKE_STATUSES, string> = {
  ...INTAKE_STATUSES_TEXT,
  [INTAKE_STATUSES.documentsUploaded]: "Missing Docs - Resolved",
}

export const INTAKE_STATUS_ORDER: INTAKE_STATUSES[] = [
  INTAKE_STATUSES.notRequested,
  INTAKE_STATUSES.requested,
  INTAKE_STATUSES.preProcessing,
  INTAKE_STATUSES.inProgress,
  INTAKE_STATUSES.cancelled,
  INTAKE_STATUSES.completed,
  INTAKE_STATUSES.rework,
  INTAKE_STATUSES.redelivered,
  INTAKE_STATUSES.readyForTasker,
  INTAKE_STATUSES.taskerAssigned,
  INTAKE_STATUSES.taskerCompleted,
  INTAKE_STATUSES.missingDocuments,
  INTAKE_STATUSES.documentsUploaded,
]
export const INTAKE_NEW_STATUS_ORDER: INTAKE_STATUSES[] = [
  INTAKE_STATUSES.notRequested,
  INTAKE_STATUSES.requested,
  INTAKE_STATUSES.preProcessing,
  INTAKE_STATUSES.readyForTasker,
  INTAKE_STATUSES.taskerAssigned,
  INTAKE_STATUSES.taskerCompleted,
  INTAKE_STATUSES.inProgress,
  INTAKE_STATUSES.readyForReview,
  INTAKE_STATUSES.inReview,
  INTAKE_STATUSES.readyToSubmit,
  INTAKE_STATUSES.approvalRequired,
  INTAKE_STATUSES.completed,
  INTAKE_STATUSES.missingDocuments,
  INTAKE_STATUSES.documentsUploaded,
  INTAKE_STATUSES.missingDocsPartialResponse,
  INTAKE_STATUSES.onHold,
  INTAKE_STATUSES.readyToResume,
  INTAKE_STATUSES.cancelled,
]

export const EDITABLE_STATUSES: INTAKE_STATUSES[] = [
  INTAKE_STATUSES.notRequested,
  INTAKE_STATUSES.missingDocuments,
]

export const DOWNLOADABLE_DEMAND_PACKAGE_STATUSES: INTAKE_STATUSES[] = [
  INTAKE_STATUSES.completed,
  INTAKE_STATUSES.rework,
  INTAKE_STATUSES.redelivered,
]

export const MISSING_DOCUMENT_STATUSES: INTAKE_STATUSES[] = [
  INTAKE_STATUSES.missingDocuments,
  INTAKE_STATUSES.missingDocsPartialResponse,
]

export const DEMAND_PACKAGE = "Demand Package"
export const MEDICAL_CHRONOLOGY_PACKAGE = "Medical Chronology Package"
export const DEMAND_LETTER = "Demand Letter"
export const EXHIBIT = "Exhibit"
export const OTHER = "Other"

export const DEMAND_PACKAGE_KEY = "demand_package"
export const DEMAND_LETTER_KEY = "demand_letter"

export const DEMAND_PACKAGE_FILE_TYPES = {
  [DEMAND_PACKAGE_KEY]: DEMAND_PACKAGE,
  [DEMAND_LETTER_KEY]: DEMAND_LETTER,
  exhibit: EXHIBIT,
  other: OTHER,
}
export const MEDICAL_CHRONOLOGY_PACKAGE_FILE_TYPES = {
  demand_package: MEDICAL_CHRONOLOGY_PACKAGE,
}

export const POLICY_TYPE_KEYS: Record<PolicyType, { key: PolicyType; display: string }> = {
  individual: { key: INDIVIDUAL, display: "Individual" },
  commercial: { key: COMMERCIAL, display: "Commercial" },
}
export const COVERAGE_TYPE_SELF_INSURED = "self_insured"
export const COVERAGE_TYPE_BODILY_INJURY = "bodily_injury"

export const POLICY_COVERAGE_TYPE_OPTIONS: { key: PolicyCoverageType; display: string }[] = [
  { key: "bodily_injury", display: "Bodily Injury (3rd Party)" },
  { key: "underinsured_motorist", display: "Underinsured Motorist (1st Party)" },
  { key: "uninsured_motorist", display: "Uninsured Motorist (1st Party)" },
  { key: COVERAGE_TYPE_SELF_INSURED, display: "Self-Insured" },
  { key: "other", display: "Other" },
]

type PolicyCoverageKeyToDisplay = {
  // If it's hidden, it shouldn't show up in "select" components, but it should show up when used as a "view" field
  [key: string]: { key: PolicyCoverageType; display: string; hidden?: boolean }
}

export const INDIVIDUAL_POLICY_COVERAGE_TYPE_KEYS: PolicyCoverageKeyToDisplay = {
  bodily_injury: { key: "bodily_injury", display: "Bodily Injury (3rd Party)" },
  underinsured_motorist: { key: "underinsured_motorist", display: "Underinsured Motorist (1st Party)" },
  uninsured_motorist: { key: "uninsured_motorist", display: "Uninsured Motorist (1st Party)" },
  self_insured: { key: COVERAGE_TYPE_SELF_INSURED, display: "Self-Insured" },
  other: { key: "other", display: "Other" },
}
export const COMMERCIAL_POLICY_COVERAGE_TYPE_KEYS: PolicyCoverageKeyToDisplay = {
  bodily_injury: { key: "bodily_injury", display: "Bodily Injury (3rd Party)" },
  underinsured_motorist: { key: "underinsured_motorist", display: "Underinsured Motorist (1st Party)" },
  uninsured_motorist: { key: "uninsured_motorist", display: "Uninsured Motorist (1st Party)" },
  self_insured: { key: COVERAGE_TYPE_SELF_INSURED, display: "Self-Insured" },
  commercial_general_liability: {
    key: "commercial_general_liability",
    display: "Commercial General Liability (CGL)",
    hidden: true,
  },
  other: { key: "other", display: "Other" },
}

export const AT_FAULT_PARTY_COVERAGE_TYPES_INDIVIDUAL = [
  "underinsured_motorist",
  "uninsured_motorist",
  "other",
]

export const NO_DEFENDANT_COVERAGE_TYPES_INDIVIDUAL = [
  "underinsured_motorist",
  "uninsured_motorist",
  COVERAGE_TYPE_SELF_INSURED, // overriden in requestRevamp
  "other",
]

export const AT_FAULT_PARTY_COVERAGE_TYPES_COMMERCIAL = ["underinsured_motorist", "uninsured_motorist"]

export const NO_DEFENDANT_COVERAGE_TYPES_COMMERCIAL = [
  "underinsured_motorist",
  "uninsured_motorist",
  COVERAGE_TYPE_SELF_INSURED, // overriden in requestRevamp
  "commercial_general_liability",
  "other",
]

export const ENTITY = "entity"
export const DEFENDANT_TYPE_KEYS = {
  individual: { key: INDIVIDUAL, display: "Individual" },
  entity: { key: ENTITY, display: "Entity" },
}
export const POLICY_TYPE_OPTIONS = [
  {
    key: INDIVIDUAL,
    display: "Individual",
  },
  {
    key: COMMERCIAL,
    display: "Commercial",
  },
]

export const POLICY_TYPES = Object.values(POLICY_TYPE_KEYS)
export const INDIVIDUAL_POLICY_COVERAGE_TYPES = Object.values(INDIVIDUAL_POLICY_COVERAGE_TYPE_KEYS)
export const COMMERCIAL_POLICY_COVERAGE_TYPES = Object.values(COMMERCIAL_POLICY_COVERAGE_TYPE_KEYS)
export const DEFENDANT_TYPES = Object.values(DEFENDANT_TYPE_KEYS)
export const POLICY_COVERAGE_TYPE_MAP = new Map([
  [INDIVIDUAL, INDIVIDUAL_POLICY_COVERAGE_TYPES],
  [COMMERCIAL, COMMERCIAL_POLICY_COVERAGE_TYPES],
  ["other", [{ key: "other", display: "Other" }]],
])

export const PolicyCoverageTypeFilter = (
  policyType: { key: string; hidden?: boolean },
  selectedCoverageType: string
) => !policyType.hidden || policyType.key === selectedCoverageType

export const BASIC_INFORMATION = "basicInfo"
export const CARRIER_INFORMATION = "carrierInfo"
export const CASE_FACTS = "caseFacts"
export const PLAINTIFF_INFORMATION = "plaintiffInfo"
export const FILES = "files"
export const INJURY_IMPACTS = "injuryImpacts"
export const ALL = "all"

export const DEMAND_TYPE = "demandType"
export const PLAINTIFF_NAME = "plaintiffName"
export const CARRIER_NAME = "carrierName"
export const FIRM_NAME = "firmName"
export const SUBMITTER = "submitter"
export const ASSIGNED_ATTORNEY = "assignedAttorney"
export const DEFENDANTS = "defendants"
export const ADDITIONAL_INFO = "additionalInfo"
export const INTENDED_CLAIM_AMOUNT = "intendedClaimAmount"

export const ADJUSTER = "adjuster"
export const RECIPIENT_EMAIL = "recipientEmail"
export const POLICY_NUMBER = "policyNumber"
export const POLICY_TYPE = "policyType"
export const POLICY_COVERAGE_TYPE = "policyCoverageType"
export const POLICY_LIMIT = "policyLimit"
export const CLAIM_NUMBER = "claimNumber"
export const RECIPIENT_ADDRESS = "recipientAddress"
export const RECIPIENT_FAX = "recipientFax"
export const RECIPIENT_TYPE = "recipientType"

export const STATE_OF_INCIDENT = "stateOfIncident"
export const DATE_OF_INCIDENT = "dateOfIncident"
export const CASE_TYPE = "caseType"
export const ONGOING_COMPLAINTS = "ongoingComplaints"
export const FUTURE_TREATMENTS = "futureTreatments"

export const EDUCATION_LEVEL = "educationLevel"
export const FAMILY_STATUS = "familyStatus"
export const DEPENDANT_STATUS = "dependantStatus"
export const HOUSEHOLD_IMPACT = "householdImpact"
export const HOUSEHOLD_IMPACT_PERCENTAGE = "householdImpactPercentage"

export const UPLOADED = "uploaded"
export const MISSING_DOCS = "missing_docs"

export const UNEDITABLE_WARNING_ANNOTATION_ON =
  "Once submitted, this request cannot be modified or cancelled."
export const UNEDITABLE_WARNING_ANNOTATION_ON_POST_SUBMIT = "This request cannot be modified or cancelled."
export const UNEDITABLE_WARNING_ANNOTATION_OFF = `Once this request has been marked as "In Progress", it cannot be modified or cancelled.`

export const PRONOUN_SELECT_OPTIONS = [
  {
    key: "she",
    display: "She",
  },
  {
    key: "he",
    display: "He",
  },
  {
    key: "they",
    display: "They",
  },
]

export const PLAINTIFF_ROLE_SELECT_OPTIONS = [
  {
    key: "cyclist",
    display: "Cyclist",
  },
  {
    key: "driver",
    display: "Driver",
  },
  {
    key: "passenger",
    display: "Passenger",
  },
  {
    key: "pedestrian",
    display: "Pedestrian",
  },
  {
    key: "other",
    display: "Other",
  },
]

export const NO_CURRENT_CONTRACT_EXCEPTION = "NoCurrentContractException"
export const NO_CONTRACT_FOUND_EXCEPTION = "NoContractFoundException"
