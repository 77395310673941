import { useCallback, useContext, useEffect, useState } from "react"
import { DocumentStructureSection } from "../types"
import { useForm } from "react-hook-form"
import DeleteIcon from "@mui/icons-material/Delete"
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded"
import { StyledExpandArrow } from "settings/Library/LibraryTable/styled"
import {
  StyledSectionTitle,
  StyledButton,
  StyledSectionContent,
  StyledItemContentNote,
  StyledSectionContentEnd,
} from "./styled"
import { SectionContext } from "./context"
import { REF_ID } from "../constants"
import { DialogButton } from "settings/Library/TemplateForms/buttons/DialogButton"
import { Box, CircularProgress } from "@mui/material"
import { SwitchInput } from "common/form-components"

export interface SectionProps {
  item: DocumentStructureSection
  onSave: (section: DocumentStructureSection) => Promise<void>
  onDelete: (section: DocumentStructureSection) => Promise<void>
}

export function SectionContent({ item, onSave, onDelete }: SectionProps): JSX.Element {
  const {
    control,
    reset,
    formState: { dirtyFields, isSubmitting },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: item,
  })

  const [touched, setTouched] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const isEmpty = !watch("title")
  const hasChildren = !!item.children.length
  const disabled = isDeleting || isSubmitting

  const { expanded, toggleExpanded } = useContext(SectionContext)

  useEffect(() => {
    if (dirtyFields.title || dirtyFields.repeatForMultiplePlaintiffs) {
      setTouched(true)
    }
  }, [dirtyFields.title, dirtyFields.repeatForMultiplePlaintiffs])

  const handleSave = handleSubmit(async section => {
    const sectionData: DocumentStructureSection = {
      ...section,
      [REF_ID]: item[REF_ID],
    }

    if (item.id !== null) {
      sectionData.id = item.id
    }

    await onSave(sectionData)
    reset({}, { keepValues: true })
    setTouched(false)
  })

  const handleCancel = useCallback(() => {
    reset()
    setTouched(false)
  }, [reset])

  const handleDelete = useCallback(() => {
    setIsDeleting(true)
    onDelete(item)
  }, [item, onDelete])

  return (
    <StyledSectionContent>
      <Box display="flex">
        <StyledSectionTitle
          size="small"
          margin="none"
          control={control}
          name="title"
          placeholder="Section Title"
          required
          empty={isEmpty}
          touched={touched}
          autoFocus={isEmpty}
          disabled={disabled}
        />

        <StyledSectionContent>
          <Box ml={3} mr="auto">
            <SwitchInput
              name="repeatForMultiplePlaintiffs"
              control={control}
              color="secondary"
              label={"Repeat for Multiple Plaintiffs"}
              disabled={disabled}
              labelProps={{ fontSize: "12px" }}
            />
          </Box>
        </StyledSectionContent>

        {!isDeleting && touched && (
          <>
            <StyledButton
              variant="text"
              size="small"
              color="secondary"
              onClick={handleSave}
              disabled={isEmpty}
              loading={isSubmitting}
            >
              Save
            </StyledButton>
            <StyledButton variant="text" size="small" onClick={handleCancel}>
              Cancel
            </StyledButton>
          </>
        )}
      </Box>

      <StyledSectionContentEnd>
        {!hasChildren && (
          <StyledItemContentNote>
            <ErrorIcon
              color="error"
              sx={{ fontSize: "18px", verticalAlign: "text-bottom", marginRight: "8px" }}
            />
            Add at least (1) header, template or placeholder
          </StyledItemContentNote>
        )}
        <StyledSectionContent>
          <DialogButton
            title="Delete?"
            icon={isDeleting ? <CircularProgress size={24} /> : <DeleteIcon />}
            confirmationText="Yes, Delete"
            question={
              <span>
                Are you sure you want to delete
                <br />
                <strong>Section {item.title}?</strong>
              </span>
            }
            onAction={handleDelete}
            disabled={isDeleting}
          />
          <StyledExpandArrow expanded={expanded} onClick={toggleExpanded} />
        </StyledSectionContent>
      </StyledSectionContentEnd>
    </StyledSectionContent>
  )
}
