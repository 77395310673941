import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { makeStyles } from "tss-react/mui"
import List from "@mui/material/List"
import Box from "@mui/material/Box"
import DragHandle from "@mui/icons-material/DragHandle"
import { VerticalCenterBox } from "./FlexBox"

const useStyles = makeStyles()(theme => ({
  draggingListItem: {
    background: theme.palette.dragging.main,
  },
  sectionHeading: {
    padding: theme.spacing(2),
  },
  nodeBox: {
    borderRadius: theme.shape.borderRadius,
    border: "1px solid lightgray",
    margin: theme.spacing(5),
  },
}))

function DraggableNestedListNode({ item, index, getChildren, dropKey, itemRenderFunction, handleProps }) {
  const { classes } = useStyles()
  const items = getChildren(item)
  return (
    <Draggable key={dropKey(item)} draggableId={String(dropKey(item))} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={snapshot.isDragging ? classes.draggingListItem : classes.nodeBox}
        >
          <Box
            {...provided.dragHandleProps}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <DragHandle {...handleProps} />
            <VerticalCenterBox>
              <VerticalCenterBox>{itemRenderFunction(item, index)}</VerticalCenterBox>
            </VerticalCenterBox>
            <VerticalCenterBox>
              <VerticalCenterBox className={classes.subsectionBox}>
                <p className={classes.sectionHeading}>Subsections</p>
                <Droppable droppableId={String(dropKey(item))}>
                  {provided => (
                    <List dense>
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {(!items || items.length == 0) && <p>Place new subsections here.</p>}
                        {items &&
                          items.length > 0 &&
                          items.map((item, index) => (
                            <DraggableNestedListNode
                              key={dropKey(item)}
                              item={item}
                              index={index}
                              getChildren={getChildren}
                              itemRenderFunction={itemRenderFunction}
                              handleProps={handleProps}
                              provided={provided}
                              dropKey={dropKey}
                            />
                          ))}
                        {provided.placeholder}
                      </div>
                    </List>
                  )}
                </Droppable>
              </VerticalCenterBox>
            </VerticalCenterBox>
          </Box>
        </div>
      )}
    </Draggable>
  )
}

function DraggableNestedList({
  id,
  items,
  getChildren,
  dropKey,
  onDragEnd,
  itemRenderFunction,
  handleProps,
}) {
  const findItem = (key, items) => {
    for (let item of items) {
      if (dropKey(item) == key) return item
      let recursivelyFound = findItem(key, getChildren(item))
      if (recursivelyFound) {
        return recursivelyFound
      }
    }
    return null
  }
  const onDragEndInner = ({ destination, source }) => {
    // dropped outside the list, let items handle delete some other way
    if (!destination) return
    // this probably shouldn't happen, but just in case
    if (!source) return

    let destItems = null
    let destParent = null
    if (destination.droppableId == id + "-root") {
      destItems = items
    } else {
      destParent = findItem(destination.droppableId, items)
      destItems = getChildren(destParent)
    }
    let sourceItems = null
    if (source.droppableId == id + "-root") {
      sourceItems = items
    } else {
      sourceItems = getChildren(findItem(source.droppableId, items))
    }

    // dropping an item to itself removes/hides it - don't do this
    const targetItem = sourceItems[source.index]
    if (targetItem && destParent && dropKey(targetItem) == dropKey(destParent)) {
      return
    }
    const [movedItem] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, movedItem)
    onDragEnd(items)
  }

  return (
    <DragDropContext onDragEnd={onDragEndInner}>
      <Droppable droppableId={String(id) + "-root"}>
        {provided => (
          <List dense>
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {(!items || items.length == 0) && <p>Place new subsections here.</p>}
              {items &&
                items.length > 0 &&
                items.map((item, index) => {
                  return (
                    <DraggableNestedListNode
                      key={dropKey(item)}
                      item={item}
                      index={index}
                      getChildren={getChildren}
                      itemRenderFunction={itemRenderFunction}
                      handleProps={handleProps}
                      provided={provided}
                      dropKey={dropKey}
                    />
                  )
                })}
              {provided.placeholder}
            </div>
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableNestedList
