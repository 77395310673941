import { createContext } from "react"
import { Suggestions } from "./Suggestions"

export interface SuggestionsContextType {
  suggestions: Suggestions
}

export const SuggestionsContext = createContext<SuggestionsContextType>({
  suggestions: new Suggestions(),
})
