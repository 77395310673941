import styled from "@emotion/styled"
import { Button, Dialog, IconButton, MenuItem, buttonBaseClasses } from "@mui/material"

interface StyledFlagMarkProps {
  top: number
  right?: number
  hovered?: boolean
}

export const StyledButton = styled("span")({
  cursor: "pointer",
  fontWeight: 500,
  userSelect: "none",

  ":hover": {
    textDecoration: "underline",
  },

  ":active": {
    color: "#1361a6",
  },
})

export const StyledCollapseButton = styled(IconButton)<{ isCollapsed: boolean }>(
  ({ theme, isCollapsed }) => ({
    position: "absolute",
    right: theme.spacing(2),
    height: "46px",
    width: "46px",
    transform: isCollapsed ? "rotate(180deg)" : "none",
    transition: "0.2s",
  })
)

const flagsWrapperStyles: Record<string, { backgroundColor: string } | string> = {
  position: "relative",
}

// Array of 20 to calculate multiple flags background color. There will be maximum 2-3 flags in the same
// piece of text, so 20 is more than enough
Array.from({ length: 20 }).forEach((_, i) => {
  const flagCount = i + 1

  if (flagCount < 6) {
    flagsWrapperStyles[`[data-highlight-flag='${i}']`] = {
      backgroundColor: `rgba(211, 47, 47, ${0.08 * flagCount})`,
    }
  } else {
    flagsWrapperStyles[`[data-highlight-flag='${i}']`] = {
      backgroundColor: `rgba(211, 47, 47, ${0.6})`,
    }
  }
})

export const StyledFlagsWrapper = styled("div")(({ hoveredFlagId }: { hoveredFlagId: Nullable<string> }) => ({
  ...flagsWrapperStyles,
  ...(hoveredFlagId
    ? {
        [`[data-flag-id*='${hoveredFlagId}']`]: {
          backgroundColor: `rgba(211, 47, 47, ${0.7})`,
          transition: "0.25s",
        },
      }
    : {}),
}))

export const StyledTextarea = styled("textarea")(({ theme }) => ({
  borderRadius: theme.spacing(1),
  border: "1.5px solid #4370F5",
  resize: "none",
  padding: theme.spacing(1.5, 1),
  width: "100%",
  height: "95px",
}))

export const StyledFlagProviderName = styled("div")(({ theme }) => ({
  display: "inline-block",
  position: "relative",
  maxWidth: `calc(100% - ${theme.spacing(1)})`,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: theme.spacing(0.25, 1.5),
  borderRadius: theme.spacing(1.5),
  color: theme.palette.common.white,
  fontSize: "13px",
  lineHeight: "13px",
  fontWeight: 700,
  width: "auto",
}))

export const StyledFlagButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "105px",
  color: "#1E88E5",
  gap: theme.spacing(1),
  justifyContent: "flex-start",
  alignItems: "flex-start",
}))

export const StyledFlagItemInfo = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
  alignItems: "flex-start",
}))

export const StyledFlagMark = styled("div")<StyledFlagMarkProps>(({ top, right, hovered }) => ({
  position: "absolute",
  top: `${top}px`,
  right: right ? `${right + 10}px` : "10px",
  cursor: "pointer",
  color: hovered ? "#891310" : "#E53935",
  transition: "0.25s",
  transitionProperty: "color",
  zIndex: "1",

  ":before": {
    content: "''",
    position: "absolute",
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}))

export const StyledDateFlagButton = styled(Button)(() => ({
  borderRadius: "100px",
}))

export const StyledMenuItem = styled(MenuItem)<{ width?: string }>(({ width = "220px" }) => ({
  width,
  maxWidth: "100%",
}))

export const StyledKabob = styled(IconButton)({
  position: "relative",
  right: "-10px",
})

export const StyledDialog = styled(Dialog)({
  "& .MuiDialogContent-root": {
    padding: "40px 30px 30px",

    ".header": {
      marginBottom: "20px",
    },

    ".instructions": {
      marginBottom: "20px",
    },
  },
  "& .MuiDialogActions-root": {
    padding: "0 30px 27px",
  },
})

export const StyledConfirmButton = styled(Button)(({ theme }) => ({
  ...buttonBaseClasses,
  color: theme.palette.common.white,
  borderColor: theme.palette.blue.primary,
  background: theme.palette.blue.primary,

  ":hover": {
    background: theme.palette.blue.primary,
  },

  [`&.${buttonBaseClasses.disabled}`]: {
    color: theme.palette.disabled.main,
    background: theme.palette.disabled.buttonBackground,
  },
}))
