import { Range } from "slate"
import { CustomEditor } from "../../CustomEditor"
import { getCursorPositionInNode } from "../cursor"
import { removeNodesAtRange } from "../removeNodes"
import { mergeListItemWithPreviousSibling } from "./mergeListItemWithPreviousSibling"
import { closestListItemNode } from "./queries"

export function deleteListBackward(editor: CustomEditor): boolean {
  if (!editor.selection) return false

  if (Range.isExpanded(editor.selection)) {
    return removeNodesAtRange(editor)
  }

  return mergeListItemWithPreviousSibling(editor)
}

export function deleteListForward(editor: CustomEditor): boolean {
  if (!editor.selection) return false

  if (Range.isExpanded(editor.selection)) {
    return removeNodesAtRange(editor)
  }

  const listItemEntry = closestListItemNode(editor, editor.selection.anchor.path)

  if (!listItemEntry) return false

  const { isEnd } = getCursorPositionInNode(editor, editor.selection.anchor, [...listItemEntry[1], 0])

  return isEnd
}
