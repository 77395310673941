import { NO_PROVIDER_COLOR } from "./constants"
import {
  MedicronAppointments,
  MedicronCalendarAppointment,
  MedicronCalendarAppointmentByYearAndMonthAndDay,
  MedicronProviders,
} from "./types"

export function groupAppointments(appointments: MedicronCalendarAppointment[]): {
  sortedAppointments: MedicronCalendarAppointment[]
  groupedAppointments: MedicronCalendarAppointmentByYearAndMonthAndDay
} {
  const groupedAppointments: MedicronCalendarAppointmentByYearAndMonthAndDay = {}
  const sortedAppointments = appointments.sort(
    (a, b) => a.appointmentDate.getTime() - b.appointmentDate.getTime()
  )

  sortedAppointments.forEach(appointment => {
    const appointmentDate = appointment.appointmentDate
    const appointmentYear = appointmentDate.getUTCFullYear()
    const appointmentMonth = appointmentDate.getUTCMonth() + 1
    const appointmentDateDay = appointmentDate.getUTCDate()

    if (!groupedAppointments[appointmentYear]) {
      groupedAppointments[appointmentYear] = []
    }
    if (!groupedAppointments[appointmentYear][appointmentMonth]) {
      groupedAppointments[appointmentYear][appointmentMonth] = []
    }
    if (!groupedAppointments[appointmentYear][appointmentMonth][appointmentDateDay]) {
      groupedAppointments[appointmentYear][appointmentMonth][appointmentDateDay] = []
    }

    groupedAppointments[appointmentYear][appointmentMonth][appointmentDateDay].push(appointment)
  })

  return { sortedAppointments, groupedAppointments }
}

export function transformAppointments(
  appointments: MedicronAppointments,
  providers: MedicronProviders
): MedicronCalendarAppointmentByYearAndMonthAndDay {
  const appointmentList = Object.values(appointments)
  const ungroupedAppointments = appointmentList.map(appointment => {
    const providerColor = `${
      (appointment.providerId && providers[appointment.providerId]?.color) || NO_PROVIDER_COLOR
    }`

    const appointmentDate = new Date(appointment.dateOfService)

    return {
      appointmentId: appointment.id,
      appointmentDate: appointmentDate,
      providerColor: providerColor,
    }
  })

  const { groupedAppointments } = groupAppointments(ungroupedAppointments)

  return groupedAppointments
}

export function getYearsToDisplayInCalendar(
  groupedAppointments: MedicronCalendarAppointmentByYearAndMonthAndDay,
  startYear?: Date,
  endYear?: Date
): Array<number> {
  return Object.keys(groupedAppointments)
    .filter(year => {
      if (startYear && new Date(parseInt(year), 0, 1).getUTCFullYear() < startYear.getUTCFullYear()) {
        return false
      }
      if (endYear && new Date(parseInt(year), 0, 1).getUTCFullYear() > endYear.getUTCFullYear()) {
        return false
      }
      return true
    })
    .map(Number)
    .sort((a, b) => a - b)
}

export function getFirstAppointmentOfYear(
  year: number,
  groupedAppointments: MedicronCalendarAppointmentByYearAndMonthAndDay
): MedicronCalendarAppointment | undefined {
  if (!groupedAppointments[year]) {
    return undefined
  }
  const firstMonthOfYear = Object.keys(groupedAppointments[year])
    .map(Number)
    .sort((a, b) => a - b)[0]
  const firstDayOfYear = Object.keys(groupedAppointments[year][firstMonthOfYear])
    .map(Number)
    .sort((a, b) => a - b)[0]

  return groupedAppointments[year][firstMonthOfYear][firstDayOfYear][0]
}

export function getLastAppointmentOfYear(
  year: number,
  groupedAppointments: MedicronCalendarAppointmentByYearAndMonthAndDay
): MedicronCalendarAppointment | undefined {
  if (!groupedAppointments[year]) {
    return undefined
  }
  const numberSort = (a: number, b: number) => a - b
  const lastMonthOfYear = Object.keys(groupedAppointments[year]).map(Number).sort(numberSort).reverse()[0]
  const lastDayOfYear = Object.keys(groupedAppointments[year][lastMonthOfYear])
    .map(Number)
    .sort(numberSort)
    .reverse()[0]

  const appointments = groupedAppointments[year][lastMonthOfYear][lastDayOfYear]
  return appointments[appointments.length - 1]
}

// Credit: https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export function pickTextColorBasedOnBgColor(bgColor: string, lightColor: string, darkColor: string) {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor
}

export function getMedicronCalendarSettingsFromUrlParams(params: URLSearchParams) {
  const startYear = params.get("startYear")
  const endYear = params.get("endYear")
  const hideLegend = params.get("hideLegend")

  const startYearInt = startYear ? parseInt(startYear) : undefined
  const endYearInt = endYear ? parseInt(endYear) : undefined

  return {
    startYear: startYearInt ? new Date(Number(startYearInt), 0, 1) : undefined,
    endYear: endYearInt ? new Date(Number(endYearInt), 0, 1) : undefined,
    hideLegend: hideLegend !== undefined && hideLegend !== null,
  }
}
