import { theme } from "./theme"

export const globalBeamerStyles = {
  ".beamerAnnouncementSnippetText": { color: `${theme.palette.button.darkGrey} !important` },
  ".beamerAnnouncementSnippet": { padding: `${theme.spacing(1)} !important` },
  ".beamerAnnouncementBar": {
    backgroundColor: `${theme.palette.blue.contentBox} !important`,
    color: `${theme.palette.label.ignore} !important`,
  },
  ".beamerAnnouncementBarClose svg": { fill: `${theme.palette.button.darkGrey} !important` },
  ".beamerAnnouncementBarTag": {
    color: `${theme.palette.background.default} !important`,
    backgroundColor: `${theme.palette.blue.primary} !important`,
  },
  ".beamerAnnouncementBarTag::after": {
    borderColor: `transparent transparent transparent ${theme.palette.blue.primary} !important`,
  },
}
