import { createContext, useContext } from "react"
import { DocumentContextValues } from "./types"
import invariant from "invariant"

export const DocumentContext = createContext<Nullable<DocumentContextValues>>(null)

export const DocumentContextProvider = DocumentContext.Provider

export function useDocumentContext(): DocumentContextValues {
  const context = useContext(DocumentContext)

  invariant(context !== null, "useDocumentContext must be used within a DocumentContextProvider")

  return context
}
