import { EXHIBIT_GROUPING_OPTIONS } from "./constants"
import { EXHIBIT_GROUPING_VALUES, EXHIBIT_SORTING_VALUES } from "./enums"

export const EXHIBIT_GROUPING_OPTIONS_WITHOUT_PER_PROVIDER = EXHIBIT_GROUPING_OPTIONS.filter(
  (value: FilterOption) => {
    return value.key !== EXHIBIT_GROUPING_VALUES.PER_PROVIDER
  }
)

export const getGroupingSettingOptions = (sortingOption: string): FilterOption[] => {
  if (
    ![
      EXHIBIT_SORTING_VALUES.PER_PROVIDER_BILLS_AND_RECORDS,
      EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS,
    ].includes(sortingOption as EXHIBIT_SORTING_VALUES)
  ) {
    return EXHIBIT_GROUPING_OPTIONS_WITHOUT_PER_PROVIDER
  }

  return EXHIBIT_GROUPING_OPTIONS
}
