import React from "react"
import { StyledFooter, FooterContainer, FooterOuterContainer, StyledFooterSpace } from "./styled"
import { PdfToolbarPagination } from "./components"

export interface PdfFooterProps {
  pages?: number
  page?: number
  pageNumberAdjustment?: number
  onChangePage?: (page: number) => void

  leftControls?: React.ReactElement
  rightControls?: React.ReactElement
}

export function PdfFooter({
  pages = 1,
  page = 1,
  pageNumberAdjustment = 0,
  onChangePage,

  leftControls,
  rightControls,
}: PdfFooterProps): React.ReactElement {
  return (
    <FooterOuterContainer>
      <FooterContainer color="default" elevation={1}>
        <StyledFooter variant="dense">
          <div data-test="pdf-toolbar-left">{leftControls}</div>
          <StyledFooterSpace data-test="pdf-toolbar-middle">
            <PdfToolbarPagination
              page={page}
              pages={pages}
              pageNumberAdjustment={pageNumberAdjustment}
              onChangePage={onChangePage}
            />
          </StyledFooterSpace>
          <div data-test="pdf-toolbar-right">{rightControls}</div>
        </StyledFooter>
      </FooterContainer>
    </FooterOuterContainer>
  )
}
