import { CollapseButton } from "common/CollapseManager/CollapseButton"
import { CollapseButtonContainer } from "./styled"
import { exhibitBuilderActions } from "exhibit-builder/store"
import { useCallback } from "react"

export function CollapseButtons() {
  const handleClick = useCallback((collapse: boolean) => {
    exhibitBuilderActions.setCollapseStateForAll(collapse)
  }, [])

  return (
    <CollapseButtonContainer>
      <CollapseButton onClick={handleClick} />
    </CollapseButtonContainer>
  )
}
