import { SERVICE_WORKER_PATH } from "./constants"
import { getWorkbox } from "./workbox"

export function getServiceWorker(): Promise<ServiceWorker> {
  return new Promise<ServiceWorker>((resolve, reject) => {
    Promise.race([
      navigator.serviceWorker.getRegistration(SERVICE_WORKER_PATH).then(registration => {
        if (!registration || !registration.active || registration.waiting || registration.installing) {
          return getWorkbox().getSW()
        }

        return registration.active
      }),
      getWorkbox()
        .getSW()
        .then(serviceWorker => {
          if (serviceWorker.state === "redundant") {
            return navigator.serviceWorker.getRegistration(SERVICE_WORKER_PATH).then(registration => {
              if (!registration || !registration.active || registration.waiting || registration.installing) {
                return serviceWorker
              }

              return registration.active
            })
          } else {
            return serviceWorker
          }
        }),
    ]).then(serviceWorker => {
      if (serviceWorker) {
        resolve(serviceWorker)
      } else {
        reject("No service worker found")
      }
    })
  })
}
