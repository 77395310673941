import splitFileName from "../common/form-components/files/splitFileName"

export type FileObject = {
  name?: Nullable<string>
  type?: Nullable<string>
}

export type FileValidationError = {
  name?: string
  type?: string
} | null

export const validateFile = (file: FileObject): FileValidationError => {
  const fileErrors: FileValidationError = {}
  // get file name without extension
  const [fileName] = splitFileName(file.name ?? "")
  if (!fileName) {
    fileErrors.name = "File name is required"
  }
  if (!file.type) {
    fileErrors.type = "File type is required"
  }
  return Object.keys(fileErrors).length ? fileErrors : null
}
