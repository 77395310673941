import { handleEmptyResponse } from "api/utils"
import { withResponseSerializer } from "api/withSerializers"
import { BlobResponse } from "apiHelper"
import { FirmLogo } from "common/models/firm"
import { isUndefined } from "lodash"
import { apiService } from "./ApiService"
import { ApiServiceType } from "./types"

interface FirmServiceOptions {
  firmId: number
}

class FirmLogoDeserializer {
  static async fromBlob(response: BlobResponse): Promise<FirmLogo> {
    return new FirmLogo(await response.blob())
  }
}

enum FIRM_API_PATHS {
  BASE = "firm",
  LOGO = "logo",
}

class FirmService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(options: FirmServiceOptions, path?: FIRM_API_PATHS): string {
    const pathParts = ["", FIRM_API_PATHS.BASE, options.firmId, path]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getFirmLogo = withResponseSerializer(FirmLogoDeserializer.fromBlob, (options: FirmServiceOptions) => {
    const path = this.getPath(options, FIRM_API_PATHS.LOGO)
    return handleEmptyResponse(this.apiService.getRaw(null, path))
  })
}

export const firmService = new FirmService(apiService)
