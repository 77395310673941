import Button from "evenup-ui/Button"
import { BottomButtonGroup } from "./BottomButtonGroup"
import { UserExhibit } from "exhibit-builder/store/types"
import { exhibitService } from "api/services/exhibit"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import { Box, Typography } from "@mui/material"

export function Download({ id, onClose }: { id: UserExhibit["id"]; onClose: () => void }) {
  const exhibitName = useExhibitBuilderStore(useShallow(state => state.userExhibitMap[id]?.name))

  const handleDownload = async () => {
    const fileName = exhibitName.endsWith(".pdf") ? exhibitName : `${exhibitName}.pdf`
    const userExhibit = await exhibitService.getUserExhibit({ id, attachmentName: fileName })
    window.open(userExhibit.url)
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="caption">This action will download a copy of this entire exhibit.</Typography>
      </Box>
      <BottomButtonGroup
        onClose={onClose}
        actionButton={<Button onClick={handleDownload}>Download</Button>}
      />
    </Box>
  )
}
