import styled from "@emotion/styled"
import Box from "@mui/material/Box"

export const StyledInputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  flexWrap: "wrap",
  marginBottom: theme.spacing(5),
  justifyContent: "space-between",
}))
