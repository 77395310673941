import { States } from "app/constants"
import {
  Attributes as AttributesType,
  Attribute,
  AttributeValue,
  SelectedAttribute,
} from "common/types/attributes"
import { v4 } from "uuid"
import { JURISDICTION } from "./constants"
import { AttributeFiltersData, AttributeFilterValue } from "./types"
import { isNil } from "lodash"

const StateNamesByTitle = Object.entries(States).reduce<Record<string, string>>((result, stateEntry) => {
  const [stateName, stateTitle] = stateEntry

  result[stateTitle] = stateName.replaceAll("_", " ").toLowerCase()

  return result
}, {})

export function getAttributeValues(
  attributes: AttributesType,
  currentValues: AttributeFiltersData = {}
): AttributeFiltersData {
  const nextValues: AttributeFiltersData = {}
  const items = attributes.getAttributesList(currentValues)

  for (const item of items) {
    nextValues[item.id] = item.values.some(({ id }) => id === currentValues[item.id])
      ? currentValues[item.id]
      : null
  }

  return nextValues
}

export const NONE_VALUE = `none_value_${v4()}` as const

export type AttributeValueOptionKey = NonNullable<AttributeFilterValue> | typeof NONE_VALUE

interface AttributeValueOptionsCache {
  items: Map<AttributeValueOptionKey, AttributeValue["value"]>
  options: ValueOptions<AttributeValueOptionKey>
}

export const NONE_VALUE_OPTION: ValueOption<typeof NONE_VALUE> = {
  key: NONE_VALUE,
  display: "None",
}

export function getAttributeValueOptions(attribute: Attribute): AttributeValueOptionsCache {
  const optionsCache: AttributeValueOptionsCache = {
    items: new Map([[NONE_VALUE_OPTION.key, attribute.title]]),
    options: [NONE_VALUE_OPTION],
  }

  for (const { id, value } of attribute.values) {
    optionsCache.items.set(id, value)
    optionsCache.options.push({
      key: id,
      display: value,
    })
  }

  return optionsCache
}

export function getJurisdictionByState(attributes: AttributesType, state?: States): AttributeFiltersData {
  if (!state) return {}

  const items = attributes.getAttributesList({})

  const stateName = StateNamesByTitle[state]
  const jurisdictionAttribute = items.find(attribute => attribute.title === JURISDICTION)

  if (stateName && jurisdictionAttribute) {
    const attributeValue = jurisdictionAttribute.values.find(value => value.value.toLowerCase() === stateName)

    if (attributeValue) return { [jurisdictionAttribute.id]: attributeValue.id }
  }

  return {}
}

export const getNonEmptyAttributeFiltersData = (
  attributes?: Nullable<AttributeFiltersData>
): AttributeFiltersData => {
  if (!attributes) return {}

  return Object.entries(attributes).reduce((values, [key, value]) => {
    if (!isNil(value)) {
      return {
        ...values,
        [key]: value,
      }
    }

    return values
  }, {})
}

export const getFiltersData = (attributes?: SelectedAttribute[]): AttributeFiltersData => {
  if (!attributes) return {}

  return attributes.reduce((values, value) => ({ ...values, [value.id]: value.valueId }), {})
}
