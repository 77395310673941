import { useQuery, UseQueryResult } from "@tanstack/react-query"
import MissingFieldsAlert from "./MissingFieldsAlert"

import { getNonEconomicValidationFields } from "../../api"
import { queryKeys } from "../../react-query/constants"
import { Plaintiff } from "common/types/matter"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"

interface Props {
  caseId: number
  currentPlaintiff: Nullable<Plaintiff>
}

interface ValidationFields {
  date_of_incident: string
  gender: string
  date_of_birth: string
  state: string
}
type ValidationFieldKey = keyof ValidationFields

const errorFields: ValidationFieldKey[] = ["date_of_incident"]
export const MissingNonEconomicAlert = ({ caseId, currentPlaintiff }: Props): JSX.Element => {
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)
  const { data, isLoading }: UseQueryResult<ValidationFields, boolean> = useQuery(
    [queryKeys.nonEconomicValidation, caseId],
    async () => {
      const nonEconomicValidation = await getNonEconomicValidationFields(caseId)
      if (multiPlaintiffEnabled && currentPlaintiff) {
        // @ts-expect-error - When FF on, we return array
        return nonEconomicValidation.find(plaintiff => plaintiff.pk === currentPlaintiff?.id)
      }
      return nonEconomicValidation[0]
    }
  )

  if (!data || isLoading) {
    return <></>
  }

  return <MissingFieldsAlert data={data} errorFields={errorFields} />
}
