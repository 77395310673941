import { v4 } from "uuid"
import { comparePageRanges } from "./cacheUtils"
import { DeleteAction, FormRange, AnnotatedExhibit, PartitionProvider } from "./types"

export const deletionsToRanges = (deletions: DeleteAction[]): FormRange[] => {
  if (deletions.length > 0) {
    return deletions.map(del => ({ formId: v4(), start: del.start_page, end: del.end_page ?? NaN }))
  }

  return [{ formId: v4(), start: NaN, end: NaN }]
}

export const duplicateActionsUpdate =
  ({ duplicateId, actions }: { duplicateId: number; actions: DeleteAction[] }) =>
  (exhibit: AnnotatedExhibit): Partial<AnnotatedExhibit> => {
    const newDuplicates = exhibit.duplicates.map(dup => {
      if (dup.pk !== duplicateId) {
        return dup
      }
      const nonDeleteActions = dup.actions?.filter(act => act.action_type !== "delete") ?? []
      const newActions = [...nonDeleteActions, ...actions].sort(comparePageRanges)
      return { ...dup, actions: newActions }
    })

    return {
      duplicates: newDuplicates,
    }
  }

export const exhibitActionsUpdate =
  ({ actions }: { actions: DeleteAction[] }) =>
  (exhibit: AnnotatedExhibit): Partial<AnnotatedExhibit> => {
    const nonDeleteActions = exhibit.actions.filter(action => action.action_type !== "delete")
    const newActions = [...nonDeleteActions, ...actions].sort(comparePageRanges)
    return {
      actions: newActions,
    }
  }

export const getFilteredPartitionProviders = (
  partitionProviders: PartitionProvider[],
  plaintiffId: Nullable<number> | undefined
) => {
  const filteredPartitionProviders = Array.from(
    partitionProviders
      .reduce((currentPartitionProviders, partitonProvider) => {
        // Add to map if this is the first instance of the partition provider name
        if (!currentPartitionProviders.has(partitonProvider.name)) {
          currentPartitionProviders.set(partitonProvider.name, partitonProvider)
          return currentPartitionProviders
        }

        // Override the partition provider if the plaintiffs do not match our paramter or is null
        if (
          currentPartitionProviders.get(partitonProvider.name).plaintiff_id !== plaintiffId &&
          (partitonProvider.plaintiff_id === plaintiffId || partitonProvider.plaintiff_id === null)
        ) {
          currentPartitionProviders.set(partitonProvider.name, partitonProvider)
          return currentPartitionProviders
        }

        return currentPartitionProviders
      }, new Map())
      .values()
  )

  // Ensure the partition provider matches the requirements of plaintiff match or no plaintiff
  return filteredPartitionProviders.filter(
    partitionProvider =>
      partitionProvider.plaintiff_id === null || partitionProvider.plaintiff_id === plaintiffId
  )
}
