import { noop } from "lodash"
import { useContext, createContext } from "react"
import { STEP_STATUSES } from "./constants"
import { CASE_STEP } from "./steps/enums"
import { Plaintiff } from "common/types/matter"

type StepStatusesKeys = keyof typeof STEP_STATUSES
type CaseStatusesKeys = keyof typeof CASE_STEP

interface HandleUpdateStepStatusPayload {
  status: (typeof STEP_STATUSES)[StepStatusesKeys]
  step?: (typeof CASE_STEP)[CaseStatusesKeys]
}

export interface RequestContext {
  pk: PrimaryKey
  type: string
}

interface FormContext {
  caseId: PrimaryKey
  request?: RequestContext
  savedSuccessfully: boolean
  setSavedSuccessfully: (flag?: boolean) => void
  showErrorMessage: (errorText?: string) => void
  handleUpdateStepStatus: (handleUpdateStepStatusPayload: HandleUpdateStepStatusPayload) => void
  currentPlaintiff: Plaintiff
}

export const FormContext = createContext<Nullable<FormContext>>(null)

export const LeftNavContext = createContext({
  leftNavTabIsExpanded: false,
  setLeftNavTabIsExpanded: noop,
  mobileNavIsOpen: false,
  setMobileNavIsOpen: noop,
})

export const FormProvider = FormContext.Provider

export function useFormContext(): FormContext | never {
  const context = useContext(FormContext)

  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider")
  }

  return context
}
