export enum CASE_SECTIONS {
  FACTS = "facts",
  LIABILITIES = "liabilities",
  INTRODUCTION = "introduction",
  CONCLUSION = "conclusion",
  FUTURE_MEDICAL_EXPENSES_PRE_TEXT = "future_medical_expenses_pre_text",
  FUTURE_MEDICAL_EXPENSES_POST_TEXT = "future_medical_expenses_post_text",
  LOSS_OF_INCOME_PRE_TEXT = "loss_of_income_pre_text",
  LOSS_OF_INCOME_POST_TEXT = "loss_of_income_post_text",
  LOSS_OF_HOUSEHOLD_SERVICES_PRE_TEXT = "loss_of_household_services_pre_text",
  LOSS_OF_HOUSEHOLD_SERVICES_POST_TEXT = "loss_of_household_services_post_text",
  PAST_MEDICAL_EXPENSES_PRE_TEXT = "past_medical_expenses_pre_text",
  PAST_MEDICAL_EXPENSES_POST_TEXT = "past_medical_expenses_post_text",
  PAST_AND_FUTURE_PAIN_AND_SUFFERING_PRE_TEXT = "past_and_future_pain_and_suffering_pre_text",
  PAIN_AND_SUFFERING_PLAINTIFF_DETAILS = "pain_and_suffering_plaintiff_details",
  PAST_AND_FUTURE_PAIN_AND_SUFFERING_POST_TEXT = "past_and_future_pain_and_suffering_post_text",
  PER_DIEM_ANALYSIS_PRE_TEXT = "per_diem_analysis_pre_text",
  PER_DIEM_ANALYSIS_POST_TEXT = "per_diem_analysis_post_text",
  SUMMARY_OF_INJURIES_PRE_TEXT = "summary_of_injuries_pre_text",
  VERDICT_ANALYSIS_PRE_TEXT = "verdict_analysis_pre_text",
  VERDICT_ANALYSIS_POST_TEXT = "verdict_analysis_post_text",
}
