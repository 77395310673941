import { hasEndOverlap, hasOverlap, isEncompassing } from "../rangeUtils"
import { AnnotationFileExcerpt, DeleteAction } from "../types"

export type CompareableDeletion = Pick<DeleteAction, "start_page" | "end_page" | "pk">

export interface GetRelevantDeletionsProps {
  deletions: CompareableDeletion[]
  start_page: number
  end_page: number
  excerpts: AnnotationFileExcerpt[]
}

export interface RelevantDeletion {
  pk: number
  start_page: number
  end_page: number
  excerpts: AnnotationFileExcerpt[]
}

export const getRelevantDeletions = ({
  deletions,
  start_page,
  end_page,
  excerpts,
}: GetRelevantDeletionsProps): RelevantDeletion[] => {
  const relevantDeletions: RelevantDeletion[] = []
  deletions.forEach(deletion => {
    const pk = deletion.pk
    const excerptOverlaps = excerpts.filter(excerpt =>
      hasOverlap(
        { start: deletion.start_page, end: deletion.end_page ?? NaN },
        { start: excerpt.start_page, end: excerpt.end_page }
      )
    )

    // if deletion encompasses partition page range
    if (
      isEncompassing(
        { start: deletion.start_page, end: deletion.end_page ?? NaN },
        { start: start_page, end: end_page }
      )
    ) {
      // display entire partition range
      relevantDeletions.push({ pk, start_page, end_page, excerpts: excerptOverlaps })
    } // if partition page range fully enompasses deletion
    else if (
      isEncompassing(
        { start: start_page, end: end_page },
        { start: deletion.start_page, end: deletion.end_page ?? NaN }
      )
    ) {
      // display deletion page range
      relevantDeletions.push({
        pk,
        start_page: deletion.start_page,
        end_page: deletion.end_page ?? NaN,
        excerpts: excerptOverlaps,
      })
    } // if deletion end overlaps into page range
    else if (
      hasEndOverlap(
        { start: deletion.start_page, end: deletion.end_page ?? NaN },
        { start: start_page, end: end_page }
      )
    ) {
      // display from start of partition range to end of deletion
      relevantDeletions.push({
        pk,
        start_page,
        end_page: deletion.end_page ?? NaN,
        excerpts: excerptOverlaps,
      })
    } // if page range end overlaps into deletion
    else if (
      hasEndOverlap(
        { start: start_page, end: end_page },
        { start: deletion.start_page, end: deletion.end_page ?? NaN }
      )
    ) {
      // display from start of deletion to end of partition range
      relevantDeletions.push({ pk, start_page: deletion.start_page, end_page, excerpts: excerptOverlaps })
    }
  })
  return relevantDeletions
}
