import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { makeStyles } from "tss-react/mui"

export const AnnotatedContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  border: "solid 0.5px",
  borderColor: theme.palette.grey[400],
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  width: "420px",
  // maxHeight: "180px",
  maxHeight: "500px",
  padding: theme.spacing(1.5, 2.5),
  fontSize: "12px",
  lineHeight: 1.83,
  letterSpacing: "0.21px",
}))

export const Header = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  fontWeight: "bold",
  display: "flex",
  justifyContent: "space-between",
}))

export const BillsHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  fontWeight: "bold",
  display: "flex",
  justifyContent: "space-between",
  boxShadow: `0px 0px 6px 0 ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.grey[50],
}))

export const SubHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontWeight: "bold",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
}))

export const Content = styled(Box)({
  overflowY: "scroll",
})

export const AdditionalEntries = styled(Box)(({ theme }) => ({
  color: theme.palette.blue.main,
  textDecoration: "underline",
  fontSize: "12px",
  lineHeight: 1.83,
  letterSpacing: "0.21px",
  "&:hover": {
    cursor: "pointer",
  },
}))

export const PopoverContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowY: "scroll",
  maxHeight: "120px",
  gap: theme.spacing(1),
  fontSize: "11px",
  lineHeight: 1.82,
  letterSpacing: "-0.11px",
  alignContent: "center",
  justifyContent: "center",
  textAlign: "center",
}))

export const Row = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: theme.spacing(2),
}))

export const Link = styled(Box)(({ theme }) => ({
  color: theme.palette.blue.main,
  textDecoration: "underline",
  "&:hover": {
    cursor: "pointer",
  },
}))

export const useStyles = makeStyles()(theme => ({
  darkRow: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: "5px",
  },
  lightRow: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: "5px",
  },
  entry: {
    width: "fit-content",
    "&:hover": {
      backgroundColor: theme.palette.blue.light,
      cursor: "pointer",
    },
  },
}))
