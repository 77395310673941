import { useMemo } from "react"
import { SILENT_QUERY_PARAMS, queryKeys } from "../../../react-query/constants"
import { getSubmitters } from "api"
import { withSuspense } from "common/withSuspense"
import { FilterSkeleton } from "./FilterSkeleton"
import { useSuspenseQuery } from "@suspensive/react-query"
import { UserDto } from "common/models/user"
import { useFilterState } from "hooks/useFilterState"
import { StyledFilterWrapper, StyledSelect } from "requests/styled"

interface RequesterFilterProps {
  value: Nullable<string[]>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...args: any) => void
}

export const RequesterFilter = withSuspense(
  ({ value: initialValue, onChange }: RequesterFilterProps) => {
    const { data: requesters } = useSuspenseQuery(
      [queryKeys.submitters],
      () =>
        (getSubmitters() as Promise<{ data: UserDto[] }>).then(({ data: submitters }) =>
          submitters.map(submitter => ({
            ...submitter,
            name: `${submitter.first_name} ${submitter.last_name}`,
          }))
        ),
      SILENT_QUERY_PARAMS
    )

    const options = useMemo(
      () => requesters.map(requester => ({ label: requester.name, value: String(requester.pk) })),
      [requesters]
    )

    const { value, onChangeValueHandler, onClearHandler } = useFilterState({
      initialValue,
      options,
      onChange,
    })

    return (
      <StyledFilterWrapper>
        <StyledSelect
          label="Requester"
          options={options}
          enableClear
          size="small"
          noOptionsText="No results"
          autoCompletePlaceholderText="Requesters..."
          value={value}
          onChangeValue={onChangeValueHandler}
          onClear={onClearHandler}
          virtualList
        />
      </StyledFilterWrapper>
    )
  },
  <FilterSkeleton label="Requester" />
)
