import React from "react"
import { Draggable } from "react-beautiful-dnd"
import Box from "@mui/material/Box"
import DragIndicator from "@mui/icons-material/DragIndicator"
import { makeStyles } from "tss-react/mui"
import UploadedFile, { UploadedFileProps } from "./UploadedFile"

const useStyles = makeStyles()(theme => ({
  providerDragIndicator: {
    fontSize: "1.9rem",
    marginRight: theme.spacing(4),
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
}))

interface DraggableFileProps extends UploadedFileProps {
  index: number
}

const DraggableFile: React.FC<DraggableFileProps> = ({ index, ...uploadedFileProps }) => {
  const { classes, cx } = useStyles()
  return (
    <Draggable key={uploadedFileProps.id} draggableId={String(uploadedFileProps.id)} index={index}>
      {draggableProvided => (
        <UploadedFile
          {...uploadedFileProps}
          draggableProps={draggableProvided.draggableProps}
          // @ts-expect-error: I couldn't figure out the definition between react-beautiful-dnd refs and react's forwardRef typing
          ref={draggableProvided.innerRef}
          dragHandle={
            <Box display="flex" {...draggableProvided.dragHandleProps}>
              <DragIndicator
                className={cx(classes.providerDragIndicator, uploadedFileProps.disabled && classes.disabled)}
              />
            </Box>
          }
        />
      )}
    </Draggable>
  )
}

export default DraggableFile
