import { useMemo } from "react"
import { Box, Skeleton } from "@mui/material"

import { smartAdvocateGetInsurance } from "api"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { Control } from "react-hook-form"

import { isArray } from "lodash"
import { SelectInput } from "common/form-components"
import { withSuspense } from "common/withSuspense"
import { SmartAdvocateFormValues, SmartAdvocateInsuranceField } from "./types"
import { useSuspenseQuery } from "@suspensive/react-query"
import invariant from "invariant"

interface InsuranceProviderSelectorProps {
  caseNumber: Nullable<string>
  control: Control<SmartAdvocateFormValues>
}

export const InsuranceProviderSelector = withSuspense(
  ({ caseNumber, control }: InsuranceProviderSelectorProps) => {
    invariant(!!caseNumber, "Valid Case Number should be provided")

    // TODO: Refactor to move serializer logic to API Service
    const { data: insuranceInformation } = useSuspenseQuery(
      [queryKeys.smartAdvocateInsurance, caseNumber],
      async () => smartAdvocateGetInsurance(caseNumber),
      SILENT_QUERY_PARAMS
    )

    const insuranceOptions: FilterOption[] = useMemo(() => {
      if (!insuranceInformation || !isArray(insuranceInformation?.insurance_options)) return []
      return insuranceInformation.insurance_options.map(
        (SmartAdvocateinsuranceField: SmartAdvocateInsuranceField) => {
          return {
            key: SmartAdvocateinsuranceField.insuranceID,
            display: SmartAdvocateinsuranceField.insurance.name,
          }
        }
      )
    }, [insuranceInformation])

    if (!caseNumber) {
      return (
        <SelectInput
          control={control}
          name="selectedInsuranceOption"
          disabled
          options={[]}
          label="Enter a Case Number to load Insurance Providers"
        />
      )
    }

    if (!insuranceOptions?.length) {
      return (
        <SelectInput
          control={control}
          name="selectedInsuranceOption"
          disabled
          options={[]}
          label="No Insurance Providers Found"
        />
      )
    }

    return (
      <Box display="flex" flexDirection="column">
        <SelectInput
          control={control}
          name="selectedInsuranceOption"
          label={"Insurance Provider"}
          variant="outlined"
          disabled={!insuranceOptions?.length}
          options={insuranceOptions}
        />
        <Box>{!insuranceOptions?.length ? "No Insurance Options" : "Select an Insurance Option"}</Box>
      </Box>
    )
  },
  <Skeleton width="400px" height="100px" />
)
