import Box from "@mui/material/Box"
import FileIcon from "@mui/icons-material/InsertDriveFileOutlined"
import { UploadedFile } from "common/types/UploadedFile"
import LinkButton from "common/buttons/LinkButton"
import { getAPIServerURL } from "utils"
import { FileWrapper, FileAttributes, FileNameLabel } from "./styled"

interface FileListProps {
  files: UploadedFile[]
}

function getFileUrl(filePathOrUrl: string): string {
  try {
    new URL(filePathOrUrl)
    return filePathOrUrl
  } catch {
    return `${getAPIServerURL()}${filePathOrUrl}`
  }
}

export function FileList({ files }: FileListProps): JSX.Element {
  return (
    <>
      {files.map(file => (
        <FileWrapper key={file.pk}>
          <FileAttributes>
            <FileIcon />
            <Box>
              <FileNameLabel>{file.name}</FileNameLabel>
            </Box>
          </FileAttributes>
          <FileAttributes>
            <LinkButton>
              <a
                download
                href={getFileUrl(file.fileUrl)}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                Download
              </a>
            </LinkButton>
          </FileAttributes>
        </FileWrapper>
      ))}
    </>
  )
}
