import { useMemo } from "react"
import { StyledText } from "../../CustomEditor"

type DataAttrs = { [x: `data-${string}`]: string }

export function useStyleAttributes(node: StyledText): DataAttrs | undefined {
  const attrs = useMemo<DataAttrs | undefined>(() => {
    if (node.highlight) return { "data-highlight": "true" }
  }, [node])

  return attrs
}
