import { CustomEditor } from "../../CustomEditor"
import { LEAF_BLOCK_ELEMENTS, LIST_BLOCK_ELEMENTS } from "../../elements"
import { Variable } from "common/types/variables"
import { Editor as WithVariablesEditor } from "../../features/variables/Editor"
import { Editor as WithCitationsEditor } from "../../features/citations/Editor"
import { Editor } from "../../Editor"
import { EDITOR_FEATURES } from "../../features/constants"
import { ELEMENT_STYLES, TEXT_STYLES } from "../../styles"
import { Citation } from "../../features/citations/types"

type BLOCK_ELEMENTS = LEAF_BLOCK_ELEMENTS | LIST_BLOCK_ELEMENTS

type ToolbarState = {
  [K in TEXT_STYLES]: boolean
} & {
  [K in keyof ELEMENT_STYLES]: ELEMENT_STYLES[K]
} & {
  [K in BLOCK_ELEMENTS]: boolean
} & {
  [K in EDITOR_FEATURES]: Nullable<boolean>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ToolbarButtonHandler = (...args: any[]) => void

type ToolbarButtonHandlers = {
  [K in keyof ToolbarState]: ToolbarButtonHandler
}

export function getToolbarButtonHandlerSafe(handler: ToolbarButtonHandler): ToolbarButtonHandler {
  return (...args) => {
    try {
      return handler(...args)
    } catch {
      return
    }
  }
}

export function getToolbarButtonHandlers(editor: CustomEditor): ToolbarButtonHandlers {
  return {
    [TEXT_STYLES.BOLD]: getToolbarButtonHandlerSafe((e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      Editor.toggleTextStyle(editor, TEXT_STYLES.BOLD)
    }),
    [TEXT_STYLES.ITALIC]: getToolbarButtonHandlerSafe((e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      Editor.toggleTextStyle(editor, TEXT_STYLES.ITALIC)
    }),
    [TEXT_STYLES.UNDERLINE]: getToolbarButtonHandlerSafe((e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      Editor.toggleTextStyle(editor, TEXT_STYLES.UNDERLINE)
    }),
    [TEXT_STYLES.HIGHLIGHT]: getToolbarButtonHandlerSafe((e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      Editor.toggleTextStyle(editor, TEXT_STYLES.HIGHLIGHT)
    }),
    [LEAF_BLOCK_ELEMENTS.PARAGRAPH]: getToolbarButtonHandlerSafe((e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      Editor.setElementType(editor, LEAF_BLOCK_ELEMENTS.PARAGRAPH)
    }),
    [LIST_BLOCK_ELEMENTS.ORDERED_LIST]: getToolbarButtonHandlerSafe(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        Editor.toggleList(editor, LIST_BLOCK_ELEMENTS.ORDERED_LIST)
      }
    ),
    [LIST_BLOCK_ELEMENTS.UNORDERED_LIST]: getToolbarButtonHandlerSafe(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        Editor.toggleList(editor, LIST_BLOCK_ELEMENTS.UNORDERED_LIST)
      }
    ),
    [EDITOR_FEATURES.VARIABLES]: getToolbarButtonHandlerSafe((variable: Variable) => {
      if (!Editor.isVariablesEditor(editor)) return

      if (variable) {
        WithVariablesEditor.insertVariable(editor, variable)
      }
    }),
    [EDITOR_FEATURES.CITATIONS]: getToolbarButtonHandlerSafe((citation: Citation) => {
      if (!Editor.isCitationsEditor(editor)) return

      if (citation) {
        WithCitationsEditor.insertCitation(editor, citation)
      }
    }),
    [EDITOR_FEATURES.FLAGS]: getToolbarButtonHandlerSafe((e: React.MouseEvent<HTMLButtonElement>) => {
      if (!Editor.isFlagsEditor(editor)) return
      e.preventDefault()
    }),
    [EDITOR_FEATURES.SUGGESTIONS]: getToolbarButtonHandlerSafe((e: React.MouseEvent<HTMLButtonElement>) => {
      if (!Editor.isSuggestionsEditor(editor)) return
      e.preventDefault()
    }),
  }
}

export function getToolbarStateValueSafe(getState: () => boolean): boolean {
  try {
    return getState()
  } catch {
    return false
  }
}

export const getToolbarState = (editor: CustomEditor): ToolbarState => {
  return {
    [TEXT_STYLES.BOLD]: getToolbarStateValueSafe(() => Editor.isTextStyleActive(editor, TEXT_STYLES.BOLD)),
    [TEXT_STYLES.ITALIC]: getToolbarStateValueSafe(() =>
      Editor.isTextStyleActive(editor, TEXT_STYLES.ITALIC)
    ),
    [TEXT_STYLES.UNDERLINE]: getToolbarStateValueSafe(() =>
      Editor.isTextStyleActive(editor, TEXT_STYLES.UNDERLINE)
    ),
    [TEXT_STYLES.HIGHLIGHT]: getToolbarStateValueSafe(() =>
      Editor.isTextStyleActive(editor, TEXT_STYLES.HIGHLIGHT)
    ),
    [LEAF_BLOCK_ELEMENTS.PARAGRAPH]: getToolbarStateValueSafe(() =>
      Editor.isElementsOfType(editor, LEAF_BLOCK_ELEMENTS.PARAGRAPH)
    ),
    [LIST_BLOCK_ELEMENTS.ORDERED_LIST]: getToolbarStateValueSafe(() =>
      Editor.isInListOfType(editor, LIST_BLOCK_ELEMENTS.ORDERED_LIST)
    ),
    [LIST_BLOCK_ELEMENTS.UNORDERED_LIST]: getToolbarStateValueSafe(() =>
      Editor.isInListOfType(editor, LIST_BLOCK_ELEMENTS.UNORDERED_LIST)
    ),
    [EDITOR_FEATURES.VARIABLES]: (function () {
      if (!Editor.isVariablesEditor(editor)) return null

      return Boolean(editor.selection) && editor.variables.size > 0
    })(),
    [EDITOR_FEATURES.CITATIONS]: (function () {
      if (!Editor.isCitationsEditor(editor)) return null

      return Boolean(editor.selection) && editor.citations.getItems().length > 0
    })(),
    [EDITOR_FEATURES.FLAGS]: (function () {
      return Editor.isFlagsEditor(editor)
    })(),
    [EDITOR_FEATURES.SUGGESTIONS]: (function () {
      return Editor.isSuggestionsEditor(editor)
    })(),
  }
}
