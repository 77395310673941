import { useCallback, useMemo } from "react"
import { Action } from "demand/Providers/reducer"

import { SelectionOptions } from "demand/Providers/Provider/SelectionOptions"
import { Bill } from "demand/Providers/types"
import { DELETE_SELECTED_BILLS, SELECT_ALL_BILLS, UNSELECT_ALL_BILLS } from "demand/Providers/reducer"

interface BillSelectionOptions {
  providerId: PrimaryKey
  bills: Bill[]
  dispatch: React.Dispatch<Action>
}

export const BillSelectionOptions = ({ providerId, bills, dispatch }: BillSelectionOptions) => {
  const handleSelectAllClick = useCallback(() => {
    dispatch({
      type: SELECT_ALL_BILLS,
      payload: { providerId },
    })
  }, [dispatch, providerId])

  const handleUnselectAllClick = useCallback(() => {
    dispatch({
      type: UNSELECT_ALL_BILLS,
      payload: { providerId },
    })
  }, [dispatch, providerId])

  const handleDeleteClick = useCallback(() => {
    dispatch({
      type: DELETE_SELECTED_BILLS,
      payload: { providerId },
    })
  }, [dispatch, providerId])

  const hasOneBillSelected = useMemo(() => {
    return bills.filter(bill => bill.selected).length > 0
  }, [bills])

  return (
    <SelectionOptions
      onSelectAllClick={handleSelectAllClick}
      onUnselectAllClick={handleUnselectAllClick}
      onDeleteClick={handleDeleteClick}
      selectAllDisabled={!bills.length}
      unselectAllDisabled={!hasOneBillSelected}
      deleteDisabled={!hasOneBillSelected}
    />
  )
}
