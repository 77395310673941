import { getIcdCodes, getCptCodes } from "api"

export enum CODE_TYPES {
  ICD,
  CPT,
}

export const codeTypeDataMap = {
  [CODE_TYPES.ICD]: {
    api: getIcdCodes,
    path: "results",
    title: "icd",
    label: "Add ICD code / Injuries",
    search: "search",
  },
  [CODE_TYPES.CPT]: {
    api: getCptCodes,
    path: "cpt_codes",
    title: "cpt",
    label: "Add CPT codes / Treatments",
    search: "prefix",
  },
}
