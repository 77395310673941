import { QueryClient } from "@tanstack/react-query"
import { ApiError } from "apiHelper"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (error instanceof ApiError && error.response.status === 403) {
          return false
        }

        if (failureCount < 3) return true

        return false
      },
    },
  },
})

export const basicRetryOption = (failureCount: number, error: unknown): boolean => {
  if (error instanceof ApiError && error.response.status >= 400 && error.response.status < 500) {
    return false
  }

  if (failureCount < 3) return true

  return false
}
