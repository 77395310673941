import Box from "@mui/material/Box"
import styled from "@emotion/styled"

export const INJURY_DETAIL_RECORD_MAX_HEIGHT = 800

interface ScrollIndicatorProps {
  show?: boolean
  bottom?: boolean
}

export const InjuryDetailsContainer = styled(Box)({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  width: "100%",
  minWidth: "400px",
})

export const InjuryDetailContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 3),
  "&:not(:last-child)": {
    borderBottomWidth: "0.5px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.grey[300],
    marginBottom: theme.spacing(1),
  },
}))

export const InjuryDetailRecordsWrapper = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  border: `0.5px solid ${theme.palette.grey[300]}`,
}))

export const Header = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  "& > *": {
    alignSelf: "center",
  },
  "&:hover": {
    cursor: "pointer",
  },
})

export const DateTitle = styled(Box)({
  fontWeight: "bold",
  fontSize: "14px",
})

export const Content = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  lineHeight: 1.5,
  fontSize: "14px",
  marginTop: theme.spacing(1),
  "& > *": {
    margin: theme.spacing(2, 0, 0, 0),
  },
}))

export const ImportantMedicalPassageContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: "fit-content",
}))

export const BarWrapper = styled.div({
  width: "100%",
  position: "relative",
})

export const StyledScrollIndicator = styled("div", { shouldForwardProp: () => false })<ScrollIndicatorProps>(
  ({ theme, show, bottom }) => ({
    position: "absolute",
    height: "5px",
    width: "calc(100% + 22px)",
    top: "-3px",
    left: "-11px",
    zIndex: 1,
    opacity: show ? "1" : "0",
    transition: "0.2s",
    borderRadius: "1px",
    backgroundColor: theme.palette.grey[300],
    boxShadow: `0px ${bottom ? "-2px" : "2px"} 4px rgba(0, 0, 0, 0.5)`,
  })
)
