import { ReactNode, useEffect, useMemo, useRef } from "react"
import { EditorFeatureRendererProps } from "../types"
import { EDITOR_FEATURES } from "../constants"
import { isCitationsEditor } from "../../queries"
import { Citations } from "./Citations"
import { CitationsContext, CitationsContextType } from "./CitationsContext"

import { CitationElement } from "common/form-components/rich-text/CustomEditor"

export function EditableWithCitations({
  editor,
  citations,
  onCitationClick,
  children,
}: EditorFeatureRendererProps<EDITOR_FEATURES.CITATIONS> & { children: ReactNode }): JSX.Element {
  const handleClickRef = useRef(onCitationClick)
  handleClickRef.current = onCitationClick

  const editorCitations = useMemo<CitationsContextType>(() => {
    const references = new Citations()
    references.push(...citations)
    return {
      references,
      onClick: (element: CitationElement, page: Nullable<number> = null) => {
        return handleClickRef.current(element, page)
      },
    }
  }, [citations])

  useEffect(() => {
    if (isCitationsEditor(editor)) {
      editor.citations = editorCitations.references
    }
  }, [editor, editorCitations])

  return <CitationsContext.Provider value={editorCitations}>{children}</CitationsContext.Provider>
}
