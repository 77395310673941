import { useMemo } from "react"
import { Select, MenuItem, Skeleton, CircularProgress, Box } from "@mui/material"

import { useSuspenseQuery } from "@suspensive/react-query"
import { queryKeys } from "react-query/constants"
import { caseService } from "api/services/case"
import { withSuspense } from "common/withSuspense"
import { Plaintiff } from "api/services/case/types"

interface PlaintiffSelectorProps {
  caseId: PrimaryKey
  plaintiff: Nullable<number>
  onChange: (newPlaintiffId: Nullable<number>) => void
  disabled?: boolean
  isLoading?: boolean
  size?: "small" | "medium"
}

export const PlaintiffSelector = withSuspense(
  ({
    caseId,
    plaintiff,
    onChange,
    disabled = false,
    isLoading = false,
    size = "small",
  }: PlaintiffSelectorProps) => {
    const { data } = useSuspenseQuery([queryKeys.plaintiffs, caseId], () => {
      return caseService.getPlaintiffs({ caseId })
    })

    const menuItems = useMemo(() => {
      return data.map((plaintiff: Plaintiff) => {
        return (
          <MenuItem key={plaintiff.id} value={plaintiff.id}>
            {plaintiff.firstName} {plaintiff.lastName}
          </MenuItem>
        )
      })
    }, [data])

    return (
      <Select
        style={{ borderRadius: "8px" }}
        disabled={disabled || isLoading}
        value={plaintiff ?? undefined}
        onChange={e => onChange(e.target.value === null ? null : +e.target.value)}
        displayEmpty
        renderValue={(value: Nullable<number>) => {
          if (isLoading) return <CircularProgress size={14} />
          if (value === undefined) return <Box color="grey">Select a Plaintiff</Box>
          return menuItems.find(menuItem => menuItem.props.value === value)
        }}
        size={size}
      >
        {menuItems}
      </Select>
    )
  },
  <Skeleton height={70} animation="wave" variant="rounded" />
)
