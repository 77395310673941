import { getAvailableSteps, getStepStatuses } from "api"
import { caseService } from "api/services/case"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { OSF } from "common/models/roles"
import useUser from "hooks/useUser"
import React, { memo, PropsWithChildren } from "react"
import { useQuery } from "@tanstack/react-query"
import { DEFAULT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { ROUTES_TO_SECTION } from "./constants"
import { DemandStepsProvider } from "./DemandStepsProvider"
import { CASE_STEP } from "./enums"
import { CaseStepConfig, CaseStepInfo, CASE_ATTRIBUTES_STEP } from "./types"
import { useLocationStore } from "app/location/store"

type DemandStepsProps = {
  // DemandStepsProvider double checks the expected section and navigates to it.
  // We only want to do that when the user is in case info or intake files
  doCurrentStepNavigation?: boolean
} & PropsWithChildren

const DemandStepsComponent: React.FC<DemandStepsProps & { caseId: PrimaryKey }> = ({
  children,
  doCurrentStepNavigation,
  caseId,
}) => {
  const {
    user: { role: userRole },
  } = useUser()
  const navigate = useNavigate()
  const { showErrorMessage } = useHandleMessages()

  const { data: availableSteps } = useQuery(
    [queryKeys.formSections, "-formatted"],
    async () => {
      let { results }: { results: CaseStepConfig[] } = await getAvailableSteps()

      results = results.filter(({ key }) => key !== CASE_STEP.FIRM)

      results = [
        {
          key: CASE_ATTRIBUTES_STEP,
          display: "Select Templates",
          label: "Select from the attributes below in order to choose templates for this demand",
          optional: false,
        },
        ...results,
      ]

      return results
    },
    { enabled: caseId !== null }
  )

  const { data: stepsWithStatus } = useQuery(
    [queryKeys.steps, caseId],
    async args => {
      return (await getStepStatuses(args)) as CaseStepInfo[]
    },
    {
      onError: error => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((error as any)?.response?.status === 404) {
          if (userRole === OSF) {
            showErrorMessage({
              message:
                "You are not authorized to access this demand request, it might be unassigned or marked as Completed",
              error,
            })
            setTimeout(() => {
              navigate("/demands")
            }, 3000)
          } else {
            showErrorMessage({
              message: "Demand not found or you are not authorized to access this demand request",
              error,
            })
          }
        }
      },
      enabled: caseId !== null,
    }
  )

  const { data: caseInfo } = useQuery(
    [queryKeys.case, caseId, "-serialized"],
    () => caseService.getCase({ caseId: caseId as PrimaryKey }),
    { enabled: caseId !== null, ...DEFAULT_QUERY_PARAMS }
  )

  const path = useLocationStore(location => location.pathname.split("/").reverse()[0])
  const section = ROUTES_TO_SECTION[path]

  if (!caseInfo || !stepsWithStatus || !availableSteps) {
    return null
  }

  return (
    <DemandStepsProvider
      caseInfo={caseInfo}
      statuses={stepsWithStatus}
      steps={availableSteps}
      section={section}
      doCurrentStepNavigation={doCurrentStepNavigation}
    >
      {children}
    </DemandStepsProvider>
  )
}

export const DemandSteps: React.FC<DemandStepsProps> = memo(function DemandStepsWrapper(props) {
  const { id: caseIdParam } = useParams()
  const caseId: Nullable<PrimaryKey> = typeof caseIdParam !== "undefined" ? parseInt(caseIdParam) : null

  if (caseId === null) {
    return <Navigate to="/demands" />
  }

  return <DemandStepsComponent {...props} caseId={caseId} />
})
