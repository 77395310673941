import { useContext } from "react"
import { DocumentStructureSection } from "../types"
import { StyledExpandArrow } from "settings/Library/LibraryTable/styled"
import { StyledSectionContent, StyledReadonlySectionTitle } from "./styled"
import { SectionContext } from "./context"

export type SectionContentReadonlyProps = {
  item: DocumentStructureSection
}

export function SectionContentReadonly({ item }: SectionContentReadonlyProps): JSX.Element {
  const hasChildren = !!item.children.length

  const { expanded, toggleExpanded } = useContext(SectionContext)

  return (
    <StyledSectionContent>
      <div>
        <StyledReadonlySectionTitle>{item.title}</StyledReadonlySectionTitle>
      </div>
      {hasChildren && (
        <StyledSectionContent>
          <StyledExpandArrow expanded={expanded} onClick={toggleExpanded} />
        </StyledSectionContent>
      )}
    </StyledSectionContent>
  )
}
