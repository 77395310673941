import React, { useMemo } from "react"
import { useSlate } from "slate-react"
import { TEXT_STYLES } from "../../styles"
import { LIST_BLOCK_ELEMENTS } from "../../elements"
import FormatBoldIcon from "@mui/icons-material/FormatBold"
import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined"
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill"
import FormatListBulleted from "@mui/icons-material/FormatListBulleted"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import { getToolbarButtonHandlers, getToolbarState } from "./utils"
import { UndoRedoButtons, AddVariableButton, SectionSymbolButton } from "./buttons"
import { StyledButton, StyledToolbar, StyledToolbarGroup } from "./styled"
import { AddCitationButton } from "./buttons/AddCitationButton"
import { AddFlagButton } from "./buttons/AddFlagButton"
import { EDITOR_FEATURES } from "../../features/constants"

export interface RichTextToolbarProps {
  customControls?: React.ReactNode
}

export const RichTextToolbar: React.FC<RichTextToolbarProps> = ({ customControls }) => {
  const editor = useSlate()
  const state = getToolbarState(editor)
  const handlers = useMemo(() => getToolbarButtonHandlers(editor), [editor])

  const isBoldEnabled = state[TEXT_STYLES.BOLD]
  const isItalicEnabled = state[TEXT_STYLES.ITALIC]
  const isUnderlineEnabled = state[TEXT_STYLES.UNDERLINE]
  const isHighlightEnabled = state[TEXT_STYLES.HIGHLIGHT]
  const isUnorderedListEnabled = state[LIST_BLOCK_ELEMENTS.UNORDERED_LIST]
  const isOrderedListEnabled = state[LIST_BLOCK_ELEMENTS.ORDERED_LIST]
  const isVariablesEnabled = state[EDITOR_FEATURES.VARIABLES]
  const isCitationsEnabled = state[EDITOR_FEATURES.CITATIONS]
  const isFlagsEnabled = state[EDITOR_FEATURES.FLAGS]

  return useMemo(() => {
    return (
      <StyledToolbar variant="dense" disableGutters>
        <UndoRedoButtons />
        <StyledToolbarGroup>
          <StyledButton onMouseDown={handlers[TEXT_STYLES.BOLD]} active={isBoldEnabled}>
            <FormatBoldIcon />
          </StyledButton>
          <StyledButton onMouseDown={handlers[TEXT_STYLES.ITALIC]} active={isItalicEnabled}>
            <FormatItalicIcon />
          </StyledButton>
          <StyledButton onMouseDown={handlers[TEXT_STYLES.UNDERLINE]} active={isUnderlineEnabled}>
            <FormatUnderlinedIcon />
          </StyledButton>
          <StyledButton onMouseDown={handlers[TEXT_STYLES.HIGHLIGHT]} active={isHighlightEnabled}>
            <FormatColorFillIcon />
          </StyledButton>
        </StyledToolbarGroup>
        <StyledToolbarGroup>
          <StyledButton
            onMouseDown={handlers[LIST_BLOCK_ELEMENTS.UNORDERED_LIST]}
            active={isUnorderedListEnabled}
          >
            <FormatListBulleted />
          </StyledButton>
          <StyledButton
            onMouseDown={handlers[LIST_BLOCK_ELEMENTS.ORDERED_LIST]}
            active={isOrderedListEnabled}
          >
            <FormatListNumberedIcon />
          </StyledButton>
        </StyledToolbarGroup>
        {/* TODO: Indent buttons not working platform wide, need to fix before uncommenting */}
        {/* <IndentButton /> */}
        <SectionSymbolButton />
        <AddVariableButton isVariablesEnabled={isVariablesEnabled} />
        <AddCitationButton isCitationsEnabled={isCitationsEnabled} />
        <AddFlagButton isFlagsEnabled={isFlagsEnabled} />
        {customControls && <StyledToolbarGroup ml="auto">{customControls}</StyledToolbarGroup>}
      </StyledToolbar>
    )
  }, [
    handlers,
    customControls,
    isBoldEnabled,
    isItalicEnabled,
    isUnderlineEnabled,
    isHighlightEnabled,
    isUnorderedListEnabled,
    isOrderedListEnabled,
    isVariablesEnabled,
    isCitationsEnabled,
    isFlagsEnabled,
  ])
}
