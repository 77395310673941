import { MinimalFirmDto } from "../firms/types"
import { FirmsUserManageModel } from "./models"
import { FirmsUserManageDto, FirmUserManage } from "./types"

export class UserServiceDeserializer {
  static fromFirmUserManageJSON(firm: MinimalFirmDto): FirmUserManage {
    return {
      id: firm.pk,
      name: firm.name,
    }
  }

  static fromFirmsUserManageJSON(firms: FirmsUserManageDto): FirmsUserManageModel {
    return new FirmsUserManageModel(
      firms.map((firm: MinimalFirmDto) => {
        return UserServiceDeserializer.fromFirmUserManageJSON(firm)
      }, [])
    )
  }
}
