import React, { useState, useMemo, useEffect, useImperativeHandle, useRef } from "react"
import { createEditor } from "slate"
import { withReact, Slate, Editable, RenderElementProps, RenderLeafProps } from "slate-react"
import styled from "@emotion/styled"
import { CopyTag } from "common/helpers/copy/constants"
import { Editor } from "./Editor"
import { DEFAULT_VALUE } from "./defaultValue"
import { EditorRoot, CustomEditor } from "./CustomEditor"
import { EditorFeatureProps, EditorFeatureRendererProps } from "./features/types"
import { getFeaturesWrapper } from "./features/wrappers"
import { getFeaturesRenderer } from "./features/renderers"
import { SLATE_EDITOR_DATA_TYPE_ATTRIBUTE } from "./constants"
import { isEqual } from "lodash"
import { EmptyContentState } from "./EmptyContentState"

export interface RichTextReadonlyProps {
  value?: Nullable<EditorRoot>
  readonly: true
  dataTest?: string
  name?: string
  renderElement: (props: RenderElementProps) => JSX.Element
  renderLeaf: (props: RenderLeafProps) => JSX.Element
  displayEmptyState?: boolean
}

const StyledEditor = styled("div")({
  "& p": {
    "&:first-of-type": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
})

export const RichTextEditorReadonly = React.forwardRef<
  CustomEditor,
  RichTextReadonlyProps & EditorFeatureProps
>(function RichTextEditorReadonly(
  { value = DEFAULT_VALUE, renderElement, renderLeaf, displayEmptyState = false, ...featureProps },
  ref
): JSX.Element {
  const editorValue = useMemo<EditorRoot>(() => {
    if (!value || value.length === 0) return DEFAULT_VALUE
    return value
  }, [value])
  const editorRef = useRef<Nullable<HTMLDivElement>>(null)
  const featureWrapper = useRef(getFeaturesWrapper(featureProps))
  const prevRange = useRef<Nullable<Range>>(null)
  const [editor] = useState<CustomEditor>(() => withReact(featureWrapper.current(createEditor())))
  const featuresRenderer = useRef(getFeaturesRenderer(featureProps))
  const rendererProps = { ...featureProps, editor } as EditorFeatureRendererProps

  useImperativeHandle(ref, () => editor, [editor])

  useEffect(() => {
    if (editorValue && editor.children !== editorValue) {
      Editor.resetEditableState(editor, editorValue)
    }
  }, [editor, editorValue])

  useEffect(() => {
    const handler = () => {
      const selection = window.getSelection()

      if (!selection || !editorRef.current) return

      if (
        editorRef.current.contains(selection.anchorNode) &&
        !editorRef.current.contains(selection.focusNode)
      ) {
        if (prevRange.current) {
          selection.removeAllRanges()
          selection.addRange(prevRange.current)
        }

        return
      }

      if (editorRef.current.contains(selection.anchorNode)) {
        prevRange.current = selection.getRangeAt(0)
      }
    }

    document.addEventListener("selectionchange", handler)

    return () => {
      document.removeEventListener("selectionchange", handler)
    }
  })

  return (
    <div ref={editorRef} data-type={SLATE_EDITOR_DATA_TYPE_ATTRIBUTE}>
      <Slate editor={editor} initialValue={editorValue}>
        <StyledEditor>
          {featuresRenderer.current({
            ...rendererProps,
            children: (
              <div data-copytag={CopyTag.SLATE_EDITOR}>
                <Editable readOnly renderElement={renderElement} renderLeaf={renderLeaf} />
              </div>
            ),
          })}
        </StyledEditor>
      </Slate>
      {displayEmptyState && isEqual(value, DEFAULT_VALUE) && <EmptyContentState />}
    </div>
  )
})
