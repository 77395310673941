import { InputFieldSkeleton } from "./"
import {
  ObjectiveTestFindingEditableRow,
  ObjectiveTestFindingReferencesEditableList,
  ObjectiveTestFindingReferenceEditableRow,
} from "../styled"

const FindingItemSkeleton = () => (
  <ObjectiveTestFindingEditableRow>
    <InputFieldSkeleton />
    <ObjectiveTestFindingReferencesEditableList>
      <ObjectiveTestFindingReferenceEditableRow>
        <InputFieldSkeleton />
        <InputFieldSkeleton />
      </ObjectiveTestFindingReferenceEditableRow>
    </ObjectiveTestFindingReferencesEditableList>
  </ObjectiveTestFindingEditableRow>
)

export default FindingItemSkeleton
