import {
  Attribute,
  Attributes as AttributesType,
  AttributeValue,
  RootAttributeTreeItem,
} from "common/types/attributes"

export interface HierarchyAttribute extends Attribute {
  parentValueId: Nullable<Attribute["id"]>
}

export class Attributes implements AttributesType {
  private readonly rootAtributes: Map<Attribute["id"], Attribute> = new Map()
  private readonly allAttributes: Map<Attribute["id"], HierarchyAttribute> = new Map()
  private readonly dependencies: Map<AttributeValue["id"], HierarchyAttribute[]> = new Map()

  constructor(attributes: HierarchyAttribute[]) {
    for (const attribute of attributes) {
      this.allAttributes.set(attribute.id, attribute)

      if (attribute.parentValueId === null) {
        this.rootAtributes.set(attribute.id, attribute)
      } else {
        const dependencies = this.dependencies.get(attribute.parentValueId) ?? []
        dependencies.push(attribute)
        this.dependencies.set(attribute.parentValueId, dependencies)
      }
    }
  }

  getAttributesTree(selectedValues: Record<number, Nullable<number>>): RootAttributeTreeItem[] {
    const attributes: RootAttributeTreeItem[] = []

    for (const { id } of this.rootAtributes.values()) {
      const attributeValue = selectedValues[id]
      const [attribute, ...children] = [
        ...this.getAllAttributesByValue(id, attributeValue ?? null, selectedValues),
      ]

      attributes.push({
        attribute,
        children: children.map(child => ({
          attribute: child,
          value: child.values.find(item => item.id === selectedValues[child.id]) ?? null,
        })),
        value: attribute.values.find(item => item.id === attributeValue) ?? null,
      })
    }

    return attributes
  }

  getAttributesList(selectedValues: Record<Attribute["id"], Nullable<AttributeValue["id"]>>): Attribute[] {
    const attributes: Attribute[] = []

    for (const { id } of this.rootAtributes.values()) {
      attributes.push(...this.getAllAttributesByValue(id, selectedValues[id] ?? null, selectedValues))
    }

    return attributes
  }

  private *getAllAttributesByValue(
    id: Attribute["id"],
    value: Nullable<AttributeValue["id"]>,
    values: Record<Attribute["id"], Nullable<AttributeValue["id"]>>
  ): Generator<Attribute> {
    const attribute = this.allAttributes.get(id)

    if (!attribute) return

    yield attribute

    if (value === null) return

    const dependantAttributes = this.dependencies.get(value)

    if (!dependantAttributes) return

    for (const dependantAttribute of dependantAttributes) {
      yield* this.getAllAttributesByValue(
        dependantAttribute.id,
        values[dependantAttribute.id] ?? null,
        values
      )
    }
  }
}
