import { useCallback, useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import Typography from "@mui/material/Typography"
import Button, { ButtonProps } from "@mui/material/Button"
import { revisionService } from "api/services/revision"
import { RevisionRequest } from "common/models/revision-request"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { queryKeys } from "react-query/constants"
import { Loading } from "common/loading"
import DialogForm from "common/dialog/DialogForm"

interface CancelRevisionRequestProps extends ButtonProps {
  revisionRequest: RevisionRequest
  requestId: string
}

export const CancelRevisionRequest = ({
  revisionRequest,
  requestId,
  ...buttonProps
}: CancelRevisionRequestProps): JSX.Element => {
  const queryClient = useQueryClient()
  const { showMessage } = useHandleMessages()
  const [isConfirmationShown, setIsConfirmationShown] = useState(false)
  const showConfirmation = useCallback(() => setIsConfirmationShown(true), [setIsConfirmationShown])
  const hideConfirmation = useCallback(() => setIsConfirmationShown(false), [setIsConfirmationShown])

  const handleChange = useCallback(() => {
    queryClient.invalidateQueries([queryKeys.requestRevisionEvents])
    queryClient.invalidateQueries([queryKeys.request])
  }, [queryClient])

  const cancelRevisionRequestMutation = useMutation(revisionService.cancelRevisionRequest, {
    onSuccess: () => showMessage({ type: "success", message: "Revision has been cancelled." }),
    onError: error =>
      showMessage({ type: "error", message: "There was an error cancelling revision.", error }),
    onSettled: () => handleChange(),
  })

  const isCancelInProgress = cancelRevisionRequestMutation.isLoading

  const cancelRevisionRequest = useCallback(async () => {
    hideConfirmation()

    await cancelRevisionRequestMutation.mutateAsync({
      requestId,
      id: revisionRequest.pk,
      data: revisionRequest,
    })
  }, [revisionRequest, cancelRevisionRequestMutation, requestId, hideConfirmation])

  return (
    <>
      {isCancelInProgress && <Loading showOnMutation />}
      <Button
        disableElevation
        variant="contained"
        size="small"
        {...buttonProps}
        disabled={buttonProps.disabled || isCancelInProgress}
        onClick={showConfirmation}
        data-test="cancel-request-button"
      >
        Cancel Revision
      </Button>
      <DialogForm
        open={isConfirmationShown}
        onClose={hideConfirmation}
        header={"Cancel Revision"}
        fullWidth={false}
        buttons={[
          <Button
            onClick={hideConfirmation}
            type="button"
            key="cancel--revision-close"
            variant="contained"
            disableElevation
          >
            Go Back
          </Button>,
          <Button
            key="cancel-contract-review"
            variant="contained"
            disableElevation
            color="secondary"
            onClick={cancelRevisionRequest}
            data-test="cancel-revision-confirm"
          >
            Yes, cancel this revision
          </Button>,
        ]}
      >
        <Typography variant="body2">Are you sure you want to cancel this revision?</Typography>
      </DialogForm>
    </>
  )
}
