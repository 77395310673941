import StatusLabel from "common/status/StatusLabel"
import useUser from "hooks/useUser"
import { OptionProps } from "evenup-ui/MultiSelectAutoComplete"

import { IntakeStatusOptionValue, getStatusOptionsByRole } from "./requestStatusProperties"
import { StyledSelect, StyledFilterWrapper } from "../styled"
import { useFilterState } from "hooks/useFilterState"
import { usePermissions } from "permissions/usePermissions"

type StatusFilterOption = OptionProps & IntakeStatusOptionValue

interface StatusFilterProps {
  value: Nullable<string[]>
  onChange: (ids: string[]) => void
}

const optionRender = (option: StatusFilterOption) => (
  <StatusLabel status={option.label} color={option.color} text={option.text} filled={option.filled} />
)

export const StatusFilter = ({ onChange, value: initialValue }: StatusFilterProps) => {
  const { user } = useUser()
  const { newStatusesForRequestsEnabled } = usePermissions()
  const options = getStatusOptionsByRole(user.role, newStatusesForRequestsEnabled)
  const { value, onChangeValueHandler, onClearHandler } = useFilterState({
    initialValue,
    options,
    onChange,
  })

  return (
    <StyledFilterWrapper>
      <StyledSelect<StatusFilterOption>
        popperWidth="400px"
        label="Status"
        dataTest="status-filter"
        options={options}
        size="small"
        enableClear
        noOptionsText="No results"
        autoCompletePlaceholderText="Statuses..."
        value={value}
        optionRender={optionRender}
        onChangeValue={onChangeValueHandler}
        onClear={onClearHandler}
      />
    </StyledFilterWrapper>
  )
}
