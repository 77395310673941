import { useState } from "react"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import { RightContentContainer, RightContentSubTitle, SelectableCard, StyledListItem } from "./styled"
import { theme } from "app/theme"
import { Container } from "./styled"
import { DOCUMENT_TYPE_LIST } from "./constants"
import { Link, Typography } from "@mui/material"
import { DOCUMENT_TYPES } from "requests/enums"
import { StyledPrimaryButton } from "common/buttons/styled"
import useUser from "hooks/useUser"
import { AVAILABLE_CONTRACT_TYPES_MAPPING } from "requests/constants"
import { ProductType } from "common/interfaces"
import { usePermissions } from "permissions/usePermissions"
import { RequestMultipleDocumentsModal } from "./RequestMultipleDocumentsModal"
import { DocumentTypesProps } from "./types"

type DocumentTypePermissions = Record<DOCUMENT_TYPES, boolean>

interface SelectDocumentProps {
  documentTypePermissions: DocumentTypePermissions
  selectedDocument?: DOCUMENT_TYPES
  availableContractTypes?: ProductType[]
  changedDocument?: (selectedDocument: DOCUMENT_TYPES) => void
}

export const SelectDocument = ({
  selectedDocument,
  changedDocument,
  documentTypePermissions,
  availableContractTypes,
}: SelectDocumentProps) => {
  const [selected, setSelected] = useState<DOCUMENT_TYPES | undefined>(selectedDocument)
  const { user } = useUser()
  const { showMultiDocumentSurveyEnabled, medicalChronologyPremiumEnabled, createSimpleEnabled } =
    usePermissions({
      firmId: user.firmId,
    })

  const handleDocumentChange = (documentType: DOCUMENT_TYPES) => {
    changedDocument?.(documentType)
    setSelected(documentType)
  }

  const demandCards = DOCUMENT_TYPE_LIST(medicalChronologyPremiumEnabled, createSimpleEnabled).filter(
    card => card.type === "demand"
  )
  const medChronCards = DOCUMENT_TYPE_LIST(medicalChronologyPremiumEnabled, createSimpleEnabled).filter(
    card => card.type === "medchron"
  )

  const renderCard = ({ value, rightContent, description, disabledLabel, ...props }: DocumentTypesProps) => {
    const isContractAvailable = availableContractTypes?.includes(
      AVAILABLE_CONTRACT_TYPES_MAPPING[value] as ProductType
    )
    const isPermissionDisabled = !documentTypePermissions[value]
    const isDisabled = isPermissionDisabled || (!user?.isInternal && !isContractAvailable)

    return (
      <SelectableCard
        key={value}
        isSelected={value === selected}
        onClick={() => !isDisabled && handleDocumentChange(value)}
        isDisabled={isDisabled}
        data-disabled={isDisabled}
        data-test={`document_type__${value}`}
        description={
          <>
            <Box>{description}</Box>
            {isDisabled && (
              <Box>
                <br />
                {disabledLabel}{" "}
                <Link href="mailto:support@evenuplaw.com" rel="noopener noreferrer" target="_top">
                  support@evenuplaw.com
                </Link>
              </Box>
            )}
          </>
        }
        {...props}
      >
        <RightContentContainer>
          <RightContentSubTitle component="p" isDisabled={isDisabled}>
            Includes:
          </RightContentSubTitle>
          <Box display="flex" gap={theme.spacing(4)} marginLeft={theme.spacing(4)}>
            {rightContent?.map((categories, key) => (
              <Box key={key} flex={1}>
                <List dense sx={{ listStyle: "disc" }}>
                  {categories.map((category, categoryKey) => (
                    <StyledListItem isDisabled={isDisabled} key={categoryKey}>
                      {category}
                    </StyledListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          <Box display="flex" justifyContent="end">
            {[DOCUMENT_TYPES.MEDICAL_CHRONOLOGY, DOCUMENT_TYPES.PREMIUM_MEDCHRON].includes(value) &&
              isPermissionDisabled && (
                <StyledPrimaryButton
                  href="https://info.evenuplaw.com/medchron-registration?utm_source=portal"
                  target="_blank"
                  size="small"
                  variant="contained"
                >
                  Join Waitlist
                </StyledPrimaryButton>
              )}
          </Box>
        </RightContentContainer>
      </SelectableCard>
    )
  }

  return (
    <Container>
      <Typography component="h1" variant="h1">
        Select a Document Type
      </Typography>

      {showMultiDocumentSurveyEnabled && <RequestMultipleDocumentsModal />}

      <Box>
        <Typography variant="h5" component="h2" mb={theme.spacing(2.5)}>
          Demand Packages
        </Typography>
        {demandCards.map(renderCard)}
        <Typography variant="h5" component="h2" mb={theme.spacing(2.5)}>
          Medical Chronologies
        </Typography>
        {medChronCards.map(renderCard)}
      </Box>
    </Container>
  )
}
