export enum COMPONENTS_KEYS {
  OBJECTIVE_TESTS_TABLE = "objective_tests_table",
  INTERVENTIONAL_TREATMENTS_TABLE = "interventional_treatments_table",
  TREATMENT_TIMELINE = "provider_timeline_chart",
  TREATMENT_CALENDAR = "provider_timeline_calendar",
  ICD_CODE_TABLE = "icd_code_table",
  FLAGS = "medchron_flags_table",
  PROVIDER_SUMMARY_TABLE = "provider_summary_table",
  APPOINTMENT_SUMMARY_TABLE = "appointment_summary_table",
  EXHIBIT_LIST = "exhibit_table",
}
