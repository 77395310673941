import styled from "@emotion/styled"
import { Box } from "@mui/material"
import TextButton from "common/buttons/TextButton"

const OptionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

interface SelectionOptionsProps {
  onSelectAllClick: () => void
  onUnselectAllClick: () => void
  onDeleteClick: () => void
  selectAllDisabled: boolean
  unselectAllDisabled: boolean
  deleteDisabled: boolean
}

export const SelectionOptions = ({
  onSelectAllClick,
  onUnselectAllClick,
  onDeleteClick,
  selectAllDisabled,
  unselectAllDisabled,
  deleteDisabled,
}: SelectionOptionsProps) => {
  return (
    <OptionsContainer>
      <TextButton textColor="grey" onClick={onSelectAllClick} disabled={selectAllDisabled}>
        SELECT ALL
      </TextButton>
      <TextButton textColor="grey" onClick={onUnselectAllClick} disabled={unselectAllDisabled}>
        UNSELECT ALL
      </TextButton>
      <TextButton textColor="red" onClick={onDeleteClick} disabled={deleteDisabled}>
        DELETE
      </TextButton>
    </OptionsContainer>
  )
}
