import styled from "@emotion/styled"
import Typography from "@mui/material/Typography"

export const CaseFactsFields = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr));",
  gap: theme.spacing(4),
}))

export const CaseFactsForm = styled("form")(({ theme }) => ({
  marginTop: theme.spacing(2),

  display: "grid",
  gridTemplateColumns: "minmax(250px, 100%)",
  gap: theme.spacing(4),
}))

export const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: "bold",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})) as typeof Typography

export const SectionSubtitle = styled(Typography)({
  width: "100%",
  fontSize: `13px`,
  fontWeight: 600,
}) as typeof Typography
