import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableFooter from "@mui/material/TableFooter"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"

import Divider from "@mui/material/Divider"
import { makeStyles } from "tss-react/mui"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import { useFormContext } from "./context"
import { Skeleton } from "@mui/material"

const useStyles = makeStyles()(theme => ({
  formFields: {
    marginTop: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(4),
  },
  actions: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    margin: theme.spacing(2, 0),
    justifyContent: "flex-end",
  },
  fullWidth: {
    gridColumn: "1 / 4",
  },
  tableContainer: {
    margin: theme.spacing(4, 2),
  },
  lossTable: {
    width: "max-content",
  },
  black: {
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
  grey: {
    background: "grey",
  },
  textCenter: {
    textAlign: "center",
  },
}))

function NonEconomicPreview({ isLoading, error, data }) {
  const { classes } = useStyles()
  const { showErrorMessage } = useFormContext()
  const headings = data?.headings

  if (isLoading)
    return (
      <Skeleton variant="rectangular" height="410px" width="380px" sx={{ marginX: "auto", marginTop: 4 }} />
    )

  if (error) {
    showErrorMessage("Could not calculate loss results.")
    return (
      <div className={classes.textCenter}>
        <Alert severity="error">
          <AlertTitle>Info</AlertTitle>
          Could not calculate loss results.
        </Alert>
      </div>
    )
  }

  if (!data || !data.past || !data.future || !headings)
    return <div className={classes.textCenter}>Could not calculate loss results.</div>
  return (
    <TableContainer className={classes.tableContainer}>
      <Divider />
      <Table align="center" size="small" className={classes.lossTable}>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2} className={classes.black}>
              Total Pain & Suffering
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2} className={classes.grey}>
              Initial Pain & Suffering
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {headings?.past?.map((heading, index) => (
            <TableRow key={index}>
              <TableCell component="th" align="left">
                {heading}
              </TableCell>
              <TableCell align="right">{data.past[heading]}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2} className={classes.grey}>
              Subsequent Pain & Suffering
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {headings?.future?.map((heading, index) => (
            <TableRow key={index}>
              <TableCell component="th" align="left">
                {heading}
              </TableCell>
              <TableCell align="right">{data.future[heading]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.black}>
              Total Pain & Suffering
            </TableCell>
            <TableCell align="right" className={classes.black} data-test="total-p-and-s">
              {data[data.headings.total]}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableFooter></TableFooter>
      </Table>
    </TableContainer>
  )
}

export default NonEconomicPreview
