import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { DocumentStructureRowData } from "common/models/library"
import { DocumentStructureFilters } from "settings/Library/Filters/DocumentStructureFilters"
import { AttributeFiltersData, SectionWithAttributes } from "common/attributes-filter"

import { DocumentStructureFormData as DocumentStructureFormDataType } from "../types"
import { LetteheadInfo } from "../hooks/useDocumentStructureForm"
import { UploadLetterhead } from "./Letterhead/UploadLetterhead"
import { DocumentStructureSections } from "./document-structure/DocumentStructureSections"
import { Button } from "@mui/material"
import { DOCUMENT_STRUCTURE_ITEM_TYPE } from "./document-structure/enums"
import {
  DocumentStructureBlock,
  DocumentStructureComponent,
  DocumentStructureHeading,
  DocumentStructureItem,
  DocumentStructureSection,
  DocumentStructureSubSectionAttrs,
  DocumentStructureTemplate,
} from "./document-structure/types"
import { REF_ID } from "./document-structure/constants"
import { v4 } from "uuid"
import { ReorderOperation } from "common/nested-list/SortableNestedList"
import { DocumentStructureCurrentFirmId } from "settings/Library/Tabs/DocumentStructure"

interface DocumentStructureFormDataProps {
  uuid: string
  onChange: (data: DocumentStructureFormDataType) => void
  onSaveSection: (section: DocumentStructureSection) => Promise<void>
  onDeleteSection: (section: DocumentStructureSection) => Promise<void>
  onSaveSubSection: (section: DocumentStructureItem & DocumentStructureSubSectionAttrs) => Promise<void>
  onSaveHeading: (heading: DocumentStructureHeading, sectionId: PrimaryKey) => Promise<void>
  onSaveComponent: (component: DocumentStructureComponent, sectionId: PrimaryKey) => Promise<void>
  onSaveTemplate: (template: DocumentStructureTemplate, sectionId: PrimaryKey) => Promise<void>
  onDeleteComponent: (template: DocumentStructureComponent, sectionId: PrimaryKey) => Promise<void>
  onDeleteTemplate: (template: DocumentStructureTemplate, sectionId: PrimaryKey) => Promise<void>
  onDeleteHeading: (heading: DocumentStructureHeading, sectionId: PrimaryKey) => Promise<void>
  onCreate: (item: DocumentStructureSection | DocumentStructureBlock) => void
  onReorder: (operation: ReorderOperation<DocumentStructureSection>) => Promise<void>
  onUloadLetterheadError: () => void
  initialAttributeValues?: AttributeFiltersData
  initialFirmId?: DocumentStructureRowData["initialFirmId"]
  initialDocumentName?: DocumentStructureRowData["initialDocumentName"]
  initialLetterhead?: DocumentStructureRowData["initialLetterhead"]
  initialSections: DocumentStructureRowData["initialSections"]
  documentStructureId?: PrimaryKey
  onLoading: (callbacks?: (() => void)[]) => void
  resolveWaitingRef: React.MutableRefObject<(value: boolean) => void>
  errorInFirm?: boolean
  creating?: boolean
  detailsLoading?: boolean
}

export const INITIAL_SECTION_WITH_ATTIRIBUTES: SectionWithAttributes = { section: null, attributeValues: {} }

export function DocumentStructureFormData({
  uuid,
  onChange,
  onSaveSection,
  onSaveSubSection,
  onCreate,
  onDeleteSection,
  onReorder,
  onSaveHeading,
  onSaveComponent,
  onDeleteComponent,
  onSaveTemplate,
  onDeleteHeading,
  onDeleteTemplate,
  initialAttributeValues,
  initialFirmId,
  initialDocumentName,
  initialSections,
  errorInFirm,
  documentStructureId,
  onUloadLetterheadError,
  initialLetterhead,
  onLoading,
  resolveWaitingRef,
  detailsLoading = false,
  creating = false,
}: DocumentStructureFormDataProps): JSX.Element {
  const onChangeRef = useRef(onChange)

  onChangeRef.current = onChange

  const [sectionWithAttributes, setSectionWithAttributes] = useState(INITIAL_SECTION_WITH_ATTIRIBUTES)
  const [firmId, setFirmId] = useState<DocumentStructureFormDataType["firmId"]>(initialFirmId ?? null)
  const [letterheadToSave, setLetterheadToSave] = useState<LetteheadInfo>({})

  const [documentName, setDocumentName] = useState<DocumentStructureFormDataType["documentName"]>(
    initialDocumentName ?? null
  )

  const { setCurrentFirmId } = useContext(DocumentStructureCurrentFirmId)

  const onFirmIdChange = useCallback(
    (firmId: Nullable<PrimaryKey>) => {
      setFirmId(firmId)
      setCurrentFirmId(firmId)
    },
    [setCurrentFirmId]
  )

  const sectionContainer = useRef<HTMLDivElement>(null)

  const addSection = useCallback(() => {
    onCreate({
      type: DOCUMENT_STRUCTURE_ITEM_TYPE.SECTION,
      title: "",
      id: null,
      [REF_ID]: v4(),
      children: [],
      repeatForMultiplePlaintiffs: false,
    })

    requestAnimationFrame(() => {
      sectionContainer.current?.scrollIntoView({ block: "end", behavior: "smooth" })
    })
  }, [onCreate])

  const handleReset = useCallback(() => {
    setSectionWithAttributes(INITIAL_SECTION_WITH_ATTIRIBUTES)
    setFirmId(null)
    setDocumentName(null)
  }, [])

  useEffect(() => {
    setCurrentFirmId(initialFirmId ?? null)

    return () => {
      setCurrentFirmId(null)
    }
  }, [initialFirmId, setCurrentFirmId])

  useEffect(() => {
    onChangeRef.current({ firmId, sectionWithAttributes, documentName, ...letterheadToSave })
  }, [firmId, sectionWithAttributes, documentName, letterheadToSave])

  return (
    <>
      <DocumentStructureFilters
        creating
        onlyFirmsUserManage
        onReset={handleReset}
        errorInFirm={errorInFirm}
        showResetButton={creating}
        documentName={documentName}
        setFirmId={onFirmIdChange}
        setAttributes={setSectionWithAttributes}
        setDocumentName={setDocumentName}
        firmId={firmId}
        attributes={sectionWithAttributes}
        initialAttributeValues={initialAttributeValues}
      >
        {!creating && !detailsLoading && (
          <Button
            variant="text"
            size="medium"
            color="secondary"
            sx={{ fontWeight: "bold" }}
            onClick={addSection}
          >
            + Section
          </Button>
        )}
      </DocumentStructureFilters>
      <UploadLetterhead
        uuid={uuid}
        onUloadLetterheadError={onUloadLetterheadError}
        setLetterheadToSave={setLetterheadToSave}
        initialLetterhead={initialLetterhead}
        documentStructureId={documentStructureId}
        onLoading={onLoading}
        resolveWaitingRef={resolveWaitingRef}
        creating={creating}
      />
      {!creating && (
        <DocumentStructureSections
          sections={initialSections}
          onSaveSection={onSaveSection}
          onDeleteSection={onDeleteSection}
          onSaveSubSection={onSaveSubSection}
          onDeleteComponent={onDeleteComponent}
          onSaveHeading={onSaveHeading}
          onSaveComponent={onSaveComponent}
          onSaveTemplate={onSaveTemplate}
          onDeleteHeading={onDeleteHeading}
          onDeleteTemplate={onDeleteTemplate}
          onReorder={onReorder}
          ref={sectionContainer}
        />
      )}
    </>
  )
}
