import { useCallback, useEffect, useState, ChangeEvent } from "react"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { Variable } from "common/types/variables"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"

import { ProviderTemplateFormData as ProviderTemplateFormDataType } from "../types"
import { GeneratorPicker } from "settings/Library/GeneratorPicker"
import { StyledEditorWrapper } from "./styled"

interface ProviderTemplateFormProps {
  onChange: (data: ProviderTemplateFormDataType) => void
  initialContent?: EditorRoot
  initialTemplateName?: string
  initialGeneratorKey?: string
  initialPossibleGeneratorKeys?: string[]
  variables?: Variable[]
  generatorKeys?: Array<{ key: string; display: string }>
  error?: boolean
  highlightEmptyFields?: boolean
}

const INITIAL_PROVIDER_NAME = ""
const INITIAL_POSSIBLE_GENERATOR_KEYS: string[] = []
const INITIAL_CONTENT: EditorRoot = []
const INITIAL_VARIABLES: Variable[] = []

const StyledBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "2fr 1fr",
  gap: theme.spacing(1),
}))

export function ProviderTemplateFormData({
  onChange,
  highlightEmptyFields,
  initialContent,
  initialTemplateName,
  initialPossibleGeneratorKeys,
  variables,
}: ProviderTemplateFormProps): JSX.Element {
  const [templateName, setTemplateName] = useState(initialTemplateName || INITIAL_PROVIDER_NAME)
  const [possibleGeneratorKeys, setPossibleGeneratorKeys] = useState<string[]>(
    initialPossibleGeneratorKeys || INITIAL_POSSIBLE_GENERATOR_KEYS
  )
  const [content, setContent] = useState(initialContent || INITIAL_CONTENT)

  const handleChangeTemplateName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTemplateName(e.target.value)
  }, [])

  useEffect(() => {
    onChange({ templateName, content, possibleGeneratorKeys })
  }, [onChange, templateName, content, possibleGeneratorKeys])

  const setGenerators = (generatorKeys: string[]) => {
    setPossibleGeneratorKeys(generatorKeys)
  }

  return (
    <>
      <StyledBox>
        <TextField
          fullWidth
          variant="outlined"
          value={templateName}
          error={highlightEmptyFields && !templateName}
          onChange={handleChangeTemplateName}
          label={"Provider Template name"}
        />
        <GeneratorPicker onChange={setGenerators} originalValue={possibleGeneratorKeys} />
      </StyledBox>
      <StyledEditorWrapper>
        <RichTextEditor
          value={content}
          onChange={setContent}
          keepValue={false}
          withVariables
          variables={variables || INITIAL_VARIABLES}
        />
      </StyledEditorWrapper>
    </>
  )
}
