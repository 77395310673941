import { isEqual } from "lodash"
import { ALL_ROLES, EXTERNAL_ROLES, INTERNAL_ROLES, NOT_SETUP_ROLE } from "./roles"

export interface UserDto {
  pk: number
  email: string
  first_name: string
  last_name: string
  role: ALL_ROLES
  rows_per_page: Nullable<number>
  stytch_user_id: Nullable<string>
  username: string
  firm_id?: Nullable<number>
  has_smart_advocate_credentials?: boolean
  impersonator?: string
}

interface Constructor<T> {
  new (...args: any[]): T // eslint-disable-line @typescript-eslint/no-explicit-any
}

const DEFAULT_ROWS_PER_PAGE = 50

export class User {
  constructor(
    readonly id: number,
    readonly email: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly role: ALL_ROLES,
    readonly rowsPerPage: number,
    readonly stytchUserId: Nullable<string>,
    readonly userName: string,
    readonly firmId?: Nullable<number>,
    readonly hasSmartAdvocateCredentials?: boolean,
    readonly impersonator?: string
  ) {}

  static fromJSON<T extends User>(this: Constructor<T>, userData: UserDto): T {
    return new this(
      userData.pk,
      userData.email,
      userData.first_name,
      userData.last_name,
      userData.role,
      userData.rows_per_page ?? DEFAULT_ROWS_PER_PAGE,
      userData.stytch_user_id ?? null,
      userData.username,
      userData.firm_id ?? null,
      userData.has_smart_advocate_credentials ?? false,
      userData.impersonator ?? null
    )
  }

  toJSON(): UserDto {
    return {
      pk: this.id,
      email: this.email,
      first_name: this.firstName,
      last_name: this.lastName,
      role: this.role,
      rows_per_page: this.rowsPerPage,
      stytch_user_id: this.stytchUserId,
      username: this.userName,
      firm_id: this.firmId,
      has_smart_advocate_credentials: this.hasSmartAdvocateCredentials,
    }
  }

  isRole(roles: ALL_ROLES | ALL_ROLES[]): boolean {
    const rollsArray = Array.isArray(roles) ? roles : [roles]
    return rollsArray.includes(this.role)
  }

  isEqual(other: User): boolean {
    return isEqual(this.toJSON(), other.toJSON())
  }

  get isInternal(): boolean {
    return Object.values<string>(INTERNAL_ROLES).includes(this.role)
  }

  get isExternal(): boolean {
    return Object.values<string>(EXTERNAL_ROLES).includes(this.role)
  }

  get hasStytchUserId(): boolean {
    return !!this.stytchUserId
  }
}

export class AuthorizedUser extends User {
  readonly isAuthorized = true
}

export class NotAuthorizedUser extends User {
  readonly isAuthorized = false

  constructor() {
    super(-1, "", "", "", NOT_SETUP_ROLE, DEFAULT_ROWS_PER_PAGE, null, "", null)
  }

  static fromJSON<T extends User>(this: Constructor<T>): T {
    return new this()
  }
}
