import { useState, useMemo, useEffect } from "react"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Chip from "@mui/material/Chip"
import Autocomplete from "@mui/material/Autocomplete"
import debounce from "lodash/debounce"

import { useFormContext } from "./FormContext"
import { fetchFacetsForText } from "../api"
import { MAP_LABEL_TO_FACET } from "./constants"
import { makeStyles } from "tss-react/mui"
import { noop } from "lodash"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles()(theme => ({
  label: {
    fontSize: "0.75rem",
    maxWidth: "7.5rem",
    overflow: "visible",
    wordWrap: "break-word",
    whiteSpace: "break-spaces",
  },
  chip: {
    marginLeft: "auto",
    fontSize: "0.75rem",
  },
}))

export default function FacetSearch({ facetKey }) {
  const { classes } = useStyles()
  const { queryActions, handleQueryUpdate } = useFormContext()
  const [value, setValue] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState("")
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    async function fetchOptions() {
      setLoading(true)
      const results = await fetchFacetsForText({ facet_key: facetKey, text: inputValue })
      if (results) {
        const facetSuggestions = results.facets[facetKey].data
        setOptions(facetSuggestions)
      }
      setLoading(false)
    }

    fetchOptions().catch(noop)
  }, [value, inputValue, facetKey])

  const handleOptionSelect = value => {
    handleQueryUpdate(queryActions.APPLY_FACET, { name: facetKey, value })
  }

  const changeHandler = (event, newValue) => {
    setInputValue(newValue)
  }

  const handleInputChange = useMemo(() => debounce(changeHandler, 300), [])

  return (
    <Autocomplete
      id={`${facetKey}-custom-facet`}
      autoComplete
      blurOnSelect
      clearOnBlur
      value={value}
      size="small"
      options={options}
      loading={loading}
      getOptionLabel={option => option?.value}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography variant="caption" display="block" className={classes.label}>
            {option.label || option.value || "(no label)"}
          </Typography>
          <Chip variant="outlined" size="small" label={option.count} className={classes.chip} />
        </li>
      )}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
        newValue && handleOptionSelect(newValue.value)
      }}
      onInputChange={handleInputChange}
      renderInput={params => (
        <TextField
          {...params}
          label={MAP_LABEL_TO_FACET[facetKey] || "Specify custom value"}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
