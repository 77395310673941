import { PropsWithChildren } from "react"
import styled from "@emotion/styled"
import Alert from "@mui/material/Alert"
import { Box, Button, IconButton } from "@mui/material"

interface StyledMessageProps {
  error?: boolean
}

export const StyledDeleteButtonWrapper = styled("div")(() => ({
  display: "flex",
}))

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.button.grey,
}))

export const StyledMessageWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  zIndex: 1,
  justifyContent: "center",
  height: theme.spacing(5),
}))
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: theme.palette.grey[500],
}))

export const StyledDialogWrapper = styled(Box)({
  padding: "50px 80px",
  width: "512px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})

export const StyledMessage = styled(({ error, ...props }: PropsWithChildren<StyledMessageProps>) => (
  <Alert {...props} severity={error ? "error" : "info"} icon={false} />
))(({ theme }) => ({
  position: "relative",
  borderRadius: "6px",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(5),
  lineHeight: "24px",

  "& .MuiAlert-message": {
    padding: 0,
  },
}))

export const StyledCrossButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  color: theme.palette.grey[500],
  ":hover": {
    color: theme.palette.grey[700],
  },
}))
