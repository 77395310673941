import { ReactNode } from "react"

export enum SETTINGS_ROUTE_PREFIX {
  PROFILE = "profile",
  FIRMS = "firms",
  ACCOUNTS = "accounts",
  CREDITS = "credits",
  LIBRARY = "library",
  FEATURE_PERMISSIONS = "feature_permissions",
  IMPORT = "import",
  BILLING = "billing",
}

export interface NavLink {
  link: SETTINGS_ROUTE_PREFIX
  pageTitle: string
  icon: ReactNode
}
