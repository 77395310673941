import { useEffect, MutableRefObject } from "react"

export const useOutsideClick = (ref: MutableRefObject<HTMLElement | null>, onOutsideClick: () => void) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, onOutsideClick])
}
