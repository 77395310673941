import { useState, useCallback } from "react"
import { useFormContext } from "./FormContext"
import Collapse from "@mui/material/Collapse"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { makeStyles } from "tss-react/mui"
import { DateField } from "common/form-components"

const useStyles = makeStyles()(theme => ({
  customRangeForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
  },
  customRangeInputs: {
    display: "flex",
    flexDirection: "column",
  },
  customRangeControls: {
    margin: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
  dateInput: {
    marginTop: `${theme.spacing(1)} !important`,
  },
  dateFilter: {
    marginBottom: theme.spacing(1),
  },
}))

export default function DateControls() {
  const { queryState, queryActions, handleQueryUpdate } = useFormContext()
  const { classes } = useStyles()
  const facetKey = "case_decision_date"
  const EPOCH_DATE = new Date("1970/01/01").toISOString().substring(0, 10)
  const today = () => new Date().toISOString().substring(0, 10)
  const [from, to] = queryState.query["case_decision_date"] || [EPOCH_DATE, today()]
  const [dates, setDates] = useState({ from, to })
  const [customRange, setCustomRange] = useState(false)

  const handleChange = useCallback(
    (date, key) => {
      setDates({
        ...dates,
        [key]: date,
      })
    },
    [dates]
  )

  const handleRangeSubmit = event => {
    event.preventDefault()
    let value = [dates.from, dates.to]
    handleQueryUpdate(queryActions.APPLY_RANGE_FACET, { name: facetKey, value })
  }

  const handleReset = () => {
    handleQueryUpdate(queryActions.RESET_RANGE_FACET, facetKey)
    setDates({
      from: EPOCH_DATE,
      to: today(),
    })
    setCustomRange(false)
  }

  const onChangeFrom = useCallback(newValue => handleChange(newValue, "from"), [handleChange])
  const onChangeTo = useCallback(newValue => handleChange(newValue, "to"), [handleChange])

  return (
    <Box className={classes.dateFilter} key={facetKey}>
      <Box>
        <Button
          style={{ fontSize: ".75rem" }}
          size="small"
          variant="contained"
          data-test="custom-range"
          onClick={() => setCustomRange(!customRange)}
          endIcon={customRange ? <ExpandLess /> : <ExpandMore />}
          color={"inherit"}
        >
          Filter by Date
        </Button>
      </Box>
      <Collapse in={customRange}>
        <form className={classes.customRangeForm} noValidate autoComplete="off" onSubmit={handleRangeSubmit}>
          <Box className={classes.customRangeInputs}>
            <DateField
              initialValue={dates.from}
              error={!dates.from}
              label="Start Date"
              onChange={onChangeFrom}
              dataTest="start-date"
              helperText={!dates.to ? "End date required" : null}
              fieldProps={{ id: "from-date-input", name: "from", margin: "normal" }}
            />
            <DateField
              initialValue={dates.to}
              error={!dates.to}
              label="End Date"
              dataTest="end-date"
              onChange={onChangeTo}
              helperText={!dates.from ? "Start date required" : null}
              fieldProps={{ id: "to-date-input", name: "to" }}
              className={classes.dateInput}
            />
          </Box>
          <Box className={classes.customRangeControls}>
            <Button
              data-test="apply-range"
              size="small"
              color="secondary"
              type="submit"
              disabled={!dates.from || !dates.to}
            >
              Apply Range
            </Button>
            <Button size="small" onClick={handleReset}>
              Reset
            </Button>
          </Box>
        </form>
      </Collapse>
    </Box>
  )
}
