// "no-extension" ==> ["no-extension", ""]
// "some-file.zip" ==> ["some-file", ".zip"]
// "some.file.name.png" ==> ["some.file.name", ".png"]
function splitFileName(name: string): [string, string] {
  const pieces = name.split(".")
  const ext = pieces.length > 1 ? `.${pieces.pop()}` : ""
  const fileName = pieces.join(".")
  return [fileName, ext]
}

export default splitFileName
