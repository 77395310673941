import React, { useCallback, useMemo } from "react"
import { CASE_SECTIONS } from "common/types/sections"
import { FieldKeysWithValue, FieldPathWithValue } from "common/types/helper"
import { CaseFacts, CaseIntroduction, CaseConclusion, CasePainAndSuffering } from "api/services/case/types"
import { EditorContent } from "common/form-components/rich-text"
import { Control, Path, useWatch } from "react-hook-form"
import { Box } from "@mui/material"
import { CaseEditorField, CaseTemplatedSections } from "demand/components/CaseEditor"
import { queryKeys } from "react-query/constants"
import { QueryKey, useQueryClient } from "@tanstack/react-query"
import { CaseFactsSectionImages } from "./CaseFacts/exhibits/images/CaseFactsSectionImages"
import { SectionTitle, SectionSubtitle } from "./CaseFacts/styled"
import { ExhibitDto } from "demand/Exhibits/Exhibit"
import { ReviewButton } from "review"
import { ReviewEditorProvider } from "review/ReviewEditor"
import { ReviewAlert } from "review/ReviewAlert"
import { v4 } from "uuid"

type CaseFactsEditorFields = FieldKeysWithValue<
  Omit<CaseFacts, "sections" | "exhibits">,
  Nullable<EditorContent>
>

type CaseIntroductionEditorFields = FieldKeysWithValue<
  Omit<CaseIntroduction, "sections">,
  Nullable<EditorContent>
>

type CaseConclusionEditorFields = FieldKeysWithValue<
  Omit<CaseConclusion, "sections">,
  Nullable<EditorContent>
>

type CasePainAndSufferingEditorFields = FieldKeysWithValue<
  Omit<CasePainAndSuffering, "sections">,
  Nullable<EditorContent>
>

type CaseFactsSectionFields = Pick<CaseFacts, CaseFactsEditorFields | keyof CaseTemplatedSections>
type CaseIntroductionSectionFields = Pick<
  CaseIntroduction,
  CaseIntroductionEditorFields | keyof CaseTemplatedSections
>
type CaseConclusionSectionFields = Pick<
  CaseConclusion,
  CaseConclusionEditorFields | keyof CaseTemplatedSections
>
type CasePainAndSufferingFields = Pick<
  CasePainAndSuffering,
  CasePainAndSufferingEditorFields | keyof CaseTemplatedSections
>

type CaseSectionFields =
  | CaseFactsSectionFields
  | CaseIntroductionSectionFields
  | CaseConclusionSectionFields
  | CasePainAndSufferingFields

const CASE_SECTION_DETAIL_FIELDS: Record<
  CASE_SECTIONS,
  | CaseFactsEditorFields
  | CaseIntroductionEditorFields
  | CaseConclusionEditorFields
  | CasePainAndSufferingEditorFields
  | ""
> = {
  [CASE_SECTIONS.FACTS]: "facts_json",
  [CASE_SECTIONS.LIABILITIES]: "liability_details_json",
  [CASE_SECTIONS.INTRODUCTION]: "content_json",
  [CASE_SECTIONS.CONCLUSION]: "content_json",
  [CASE_SECTIONS.FUTURE_MEDICAL_EXPENSES_PRE_TEXT]: "",
  [CASE_SECTIONS.FUTURE_MEDICAL_EXPENSES_POST_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_INCOME_PRE_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_INCOME_POST_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_HOUSEHOLD_SERVICES_PRE_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_HOUSEHOLD_SERVICES_POST_TEXT]: "",
  [CASE_SECTIONS.PAST_AND_FUTURE_PAIN_AND_SUFFERING_PRE_TEXT]: "pre_text_json",
  [CASE_SECTIONS.PAIN_AND_SUFFERING_PLAINTIFF_DETAILS]: "plaintiff_details_json",
  [CASE_SECTIONS.PAST_AND_FUTURE_PAIN_AND_SUFFERING_POST_TEXT]: "post_text_json",
  [CASE_SECTIONS.PAST_MEDICAL_EXPENSES_PRE_TEXT]: "",
  [CASE_SECTIONS.PAST_MEDICAL_EXPENSES_POST_TEXT]: "",
  [CASE_SECTIONS.PER_DIEM_ANALYSIS_PRE_TEXT]: "",
  [CASE_SECTIONS.PER_DIEM_ANALYSIS_POST_TEXT]: "",
  [CASE_SECTIONS.VERDICT_ANALYSIS_PRE_TEXT]: "",
  [CASE_SECTIONS.VERDICT_ANALYSIS_POST_TEXT]: "",
  [CASE_SECTIONS.SUMMARY_OF_INJURIES_PRE_TEXT]: "",
}

const CASE_SECTION_TITLES: Record<CASE_SECTIONS, string> = {
  [CASE_SECTIONS.FACTS]: "Facts",
  [CASE_SECTIONS.LIABILITIES]: "Liability",
  [CASE_SECTIONS.INTRODUCTION]: "",
  [CASE_SECTIONS.CONCLUSION]: "",
  [CASE_SECTIONS.FUTURE_MEDICAL_EXPENSES_PRE_TEXT]: "",
  [CASE_SECTIONS.FUTURE_MEDICAL_EXPENSES_POST_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_INCOME_PRE_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_INCOME_POST_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_HOUSEHOLD_SERVICES_PRE_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_HOUSEHOLD_SERVICES_POST_TEXT]: "",
  [CASE_SECTIONS.PAST_AND_FUTURE_PAIN_AND_SUFFERING_PRE_TEXT]: "",
  [CASE_SECTIONS.PAIN_AND_SUFFERING_PLAINTIFF_DETAILS]: "",
  [CASE_SECTIONS.PAST_AND_FUTURE_PAIN_AND_SUFFERING_POST_TEXT]: "",
  [CASE_SECTIONS.PAST_MEDICAL_EXPENSES_PRE_TEXT]: "",
  [CASE_SECTIONS.PAST_MEDICAL_EXPENSES_POST_TEXT]: "",
  [CASE_SECTIONS.PER_DIEM_ANALYSIS_PRE_TEXT]: "",
  [CASE_SECTIONS.PER_DIEM_ANALYSIS_POST_TEXT]: "",
  [CASE_SECTIONS.VERDICT_ANALYSIS_PRE_TEXT]: "",
  [CASE_SECTIONS.VERDICT_ANALYSIS_POST_TEXT]: "",
  [CASE_SECTIONS.SUMMARY_OF_INJURIES_PRE_TEXT]: "",
}

const CASE_SECTION_DETAIL_TITLES: Record<CASE_SECTIONS, string> = {
  [CASE_SECTIONS.FACTS]: "What are the facts of the case?",
  [CASE_SECTIONS.LIABILITIES]: "What are the liability details?",
  [CASE_SECTIONS.INTRODUCTION]: "Intro paragraph to start the demand",
  [CASE_SECTIONS.CONCLUSION]: "Closing paragraph or statements that will appear at the end of the demand",
  [CASE_SECTIONS.FUTURE_MEDICAL_EXPENSES_PRE_TEXT]: "",
  [CASE_SECTIONS.FUTURE_MEDICAL_EXPENSES_POST_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_INCOME_PRE_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_INCOME_POST_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_HOUSEHOLD_SERVICES_PRE_TEXT]: "",
  [CASE_SECTIONS.LOSS_OF_HOUSEHOLD_SERVICES_POST_TEXT]: "",
  [CASE_SECTIONS.PAST_AND_FUTURE_PAIN_AND_SUFFERING_PRE_TEXT]:
    "Default Pain and Suffering case law (optional)",
  [CASE_SECTIONS.PAIN_AND_SUFFERING_PLAINTIFF_DETAILS]: "Plaintiff's Pain and Suffering Narrative",
  [CASE_SECTIONS.PAST_AND_FUTURE_PAIN_AND_SUFFERING_POST_TEXT]: "Post text (optional)",
  [CASE_SECTIONS.PAST_MEDICAL_EXPENSES_PRE_TEXT]: "",
  [CASE_SECTIONS.PAST_MEDICAL_EXPENSES_POST_TEXT]: "",
  [CASE_SECTIONS.PER_DIEM_ANALYSIS_PRE_TEXT]: "",
  [CASE_SECTIONS.PER_DIEM_ANALYSIS_POST_TEXT]: "",
  [CASE_SECTIONS.VERDICT_ANALYSIS_PRE_TEXT]: "",
  [CASE_SECTIONS.VERDICT_ANALYSIS_POST_TEXT]: "",
  [CASE_SECTIONS.SUMMARY_OF_INJURIES_PRE_TEXT]: "",
}

type CaseSectionLayoutProps = {
  section: CASE_SECTIONS
  buttons?: React.ReactNode
  editor: React.ReactNode
  images: React.ReactNode
  alert?: React.ReactNode
}

export function CaseSectionLayout({
  section,
  buttons,
  editor,
  images,
  alert,
}: CaseSectionLayoutProps): JSX.Element {
  return (
    <Box>
      <SectionTitle variant="h6" component="h3">
        {CASE_SECTION_TITLES[section]}
        {buttons}
      </SectionTitle>
      <Box>
        <SectionSubtitle variant="subtitle1" component="p">
          {CASE_SECTION_DETAIL_TITLES[section]}
        </SectionSubtitle>
        {alert}
        {editor}
      </Box>
      {images}
    </Box>
  )
}

interface CaseSectionProps<T extends CaseSectionFields> {
  section: CASE_SECTIONS
  caseId: CaseFacts["pk"]
  control: Control<T>
  exhibits?: Nullable<ExhibitDto[]>
  disabled?: boolean
  reviewEnabled?: boolean
  plaintiffId?: PrimaryKey | string
  includeAskAI?: boolean
  displayEmptyState?: boolean
}

function CaseSectionComponent<T extends CaseSectionFields>({
  section,
  caseId,
  control,
  disabled,
  reviewEnabled,
  plaintiffId,
  exhibits = null,
  includeAskAI = true,
  displayEmptyState = false,
}: CaseSectionProps<T>): JSX.Element {
  const queryKeysForInvalidation = useMemo<QueryKey[]>(
    () => [
      [queryKeys.facts],
      [queryKeys.conclusion],
      [queryKeys.introduction],
      [queryKeys.case],
      [queryKeys.painAndSuffering],
      [queryKeys.steps, caseId],
    ],
    [caseId]
  )

  const queryClient = useQueryClient()
  const handleReviewChange = useCallback(() => {
    for (const queryKey of queryKeysForInvalidation) {
      queryClient.invalidateQueries(queryKey)
    }
  }, [queryClient, queryKeysForInvalidation])

  const reviewRequestId = useWatch({
    control,
    name: `sections.${section}.reviewRequestId` as Path<T>,
    exact: true,
  }) as Nullable<string> | undefined

  const sectionId = useWatch({
    control,
    name: `sections.${section}.id` as Path<T>,
    exact: true,
  }) as Nullable<number> | undefined

  const isReviewable = reviewEnabled && !!sectionId

  const editorId = useMemo(() => v4(), [])
  const editor = (
    <CaseEditorField<T>
      control={control}
      name={CASE_SECTION_DETAIL_FIELDS[section] as FieldPathWithValue<T, Nullable<EditorContent>>}
      section={section}
      invalidateQueryKeys={queryKeysForInvalidation}
      dataTest={`case-${section}`}
      includeAskAI={includeAskAI}
      caseId={caseId}
      disabled={disabled || !!reviewRequestId}
      displayEmptyState={displayEmptyState}
    />
  )

  return (
    <CaseSectionLayout
      section={section}
      buttons={
        isReviewable && (
          <ReviewButton
            templateType="document"
            sectionType={section}
            plaintiffId={plaintiffId}
            onCompleted={handleReviewChange}
            onCreated={handleReviewChange}
            onCancelled={handleReviewChange}
            reviewRequestId={reviewRequestId}
          />
        )
      }
      editor={
        isReviewable ? (
          <ReviewEditorProvider
            templateType="document"
            sectionType={section}
            plaintiffId={plaintiffId}
            reviewRequestId={reviewRequestId}
            editorId={editorId}
          >
            {editor}
          </ReviewEditorProvider>
        ) : (
          editor
        )
      }
      images={exhibits && <CaseFactsSectionImages caseId={caseId} section={section} exhibits={exhibits} />}
      alert={
        isReviewable && (
          <ReviewAlert
            editorId={editorId}
            templateType="document"
            sectionType={section}
            plaintiffId={plaintiffId}
          />
        )
      }
    />
  )
}

export const CaseSection = React.memo(CaseSectionComponent) as typeof CaseSectionComponent
