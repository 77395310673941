import { StyledBadge, StyledBoxIcon, StyledTypographyTitle } from "evenup-ui/SelectableCard/styled"
import { Box } from "@mui/material"
import HospitalIcon from "./hospitalIcon.svg?react"
import { TopTitleBadgesContainer } from "./styled"

export const PremiumMedChronTopPanel = () => {
  return (
    <>
      <Box display="flex" gap={2} alignItems={"center"}>
        <TopTitleBadgesContainer>
          <StyledBadge border="1px solid #9DB6FF">Nurse Reviewed</StyledBadge>
          <StyledBadge border="1px solid #9DB6FF">Flags</StyledBadge>
        </TopTitleBadgesContainer>
        <StyledBoxIcon paletteType="secondary">
          <HospitalIcon />
        </StyledBoxIcon>
        <StyledTypographyTitle size="large">New! Premium MedChron</StyledTypographyTitle>
      </Box>
      <Box textAlign="end" pt={1}>
        <b>1.5 credits for 500 pages</b>
        <br />
        (overage fee of $175 per 250 pages)
      </Box>
    </>
  )
}
