import { useCallback } from "react"
import FlagIcon from "@mui/icons-material/Flag"
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags"
import { StyledFlagMark } from "./styled"
import { documentActions, useDocumentStore } from "../store"
import { flagsDataSelectors } from "../store/flags"
import { FLAG_IMAGE_SIZE } from "./types"

export interface FlagMarkProps {
  top: number
  flagId: string
  right?: number
}

export const FlagMark = ({ top, right, flagId }: FlagMarkProps) => {
  const flagSelector = useDocumentStore(flagsDataSelectors.getFlagSelector)
  const hoveredFlagId = useDocumentStore(flagsDataSelectors.getHoveredFlagId)

  const buttonId = `flag-mark-${flagId}`
  const opened = flagSelector === buttonId
  const hovered = hoveredFlagId === flagId

  const handleClick = useCallback(() => {
    documentActions.editFlag(flagId, buttonId)
  }, [flagId, buttonId])

  const handleMouseOut = useCallback(() => {
    documentActions.setHoveredFlagId(null)
  }, [])

  const handleMouseEnter = useCallback(() => {
    documentActions.setHoveredFlagId(flagId)
  }, [flagId])

  return (
    <StyledFlagMark
      onMouseOut={handleMouseOut}
      onMouseEnter={handleMouseEnter}
      id={buttonId}
      hovered={hovered}
      onClick={handleClick}
      top={top}
      right={right}
      data-test="flag-mark"
    >
      {opened ? (
        <EmojiFlagsIcon sx={{ fontSize: `${FLAG_IMAGE_SIZE}px` }} />
      ) : (
        <FlagIcon sx={{ fontSize: `${FLAG_IMAGE_SIZE}px` }} />
      )}
    </StyledFlagMark>
  )
}
