import { SortableNestedList } from "common/nested-list/SortableNestedList"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { UserExhibit } from "exhibit-builder/store/types"
import Button from "evenup-ui/Button"
import { FormEventHandler, useState } from "react"
import { v4 } from "uuid"
import { useShallow } from "zustand/react/shallow"
import { ExhibitListItem } from "./ExhibitListItem"
import { ListItem } from "./types"
import { SortableListContainer } from "../styled"
import { BottomButtonGroup } from "../BottomButtonGroup"
import { Checkbox, CircularProgress, FormControlLabel, Typography } from "@mui/material"
import { useAction } from "../../../ActionButton"

export function Combine({ id, onClose }: { id: UserExhibit["id"]; onClose: () => void }) {
  const userExhibits = useExhibitBuilderStore(useShallow(state => state.userExhibitMap))
  const [deleteOriginal, setDeleteOriginal] = useState(false)
  const [items, setItems] = useState<ListItem[]>([
    { id: v4(), userExhibitId: id, disabled: true },
    { id: v4(), userExhibitId: "", disabled: false },
  ])

  const { handleAction, isLoading } = useAction({
    name: "combine exhibits",
    action: () => {
      return exhibitBuilderActions.combineUserExhibits({
        anchorUserExhibitId: items[0].userExhibitId,
        userExhibitsToCombine: items.slice(1).map(item => item.userExhibitId),
        deleteOriginal,
      })
    },
  })

  const addItem = () => {
    setItems([...items, { id: v4(), userExhibitId: "", disabled: false }])
  }

  const updateItem = (itemId: string, userExhibitId: UserExhibit["id"]) => {
    setItems(items => items.map(item => (item.id === itemId ? { ...item, userExhibitId } : item)))
  }

  const deleteItem = (itemId: string) => {
    setItems(items => items.filter(item => item.id !== itemId))
  }

  const handleReorder = (newItems: ListItem[]) => {
    setItems(newItems)
  }

  const isValid = items.length > 1 && items.every(item => item.userExhibitId)

  const handleSubmit: FormEventHandler = async event => {
    event.preventDefault()

    if (deleteOriginal) {
      exhibitBuilderActions.setIsReordering(true)
    }
    await handleAction()

    exhibitBuilderActions.setIsReordering(false)
    onClose()
  }

  const handleDeleteOriginal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteOriginal(event.target.checked)
  }

  const canAddUserExhibit = items.length < Object.keys(userExhibits).length

  return (
    <SortableListContainer>
      <form onSubmit={handleSubmit}>
        <SortableNestedList
          items={items}
          uniqueKey="id"
          ItemContentComponent={ExhibitListItem}
          PlaceholderComponent={() => null}
          contentProps={{
            onChange: updateItem,
            onDelete: deleteItem,
            selectedItems: items,
          }}
          onUpdate={handleReorder}
        />
        <Button textButton color="secondary" onClick={addItem} disabled={!canAddUserExhibit}>
          + Add another exhibit
        </Button>

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              value={deleteOriginal}
              onChange={handleDeleteOriginal}
              data-test="delete-original-checkbox"
            />
          }
          label={<Typography variant="body2">Delete original exhibits after combination</Typography>}
        />

        <BottomButtonGroup
          onClose={onClose}
          actionButton={
            <Button type="submit" disabled={!isValid || isLoading} data-test="combine-button">
              Combine {isLoading && <CircularProgress size={20} color="secondary" />}
            </Button>
          }
        />
      </form>
    </SortableListContainer>
  )
}
