import { Box, Typography } from "@mui/material"
import { documentStoreSelectors, useDocumentStore } from "documents/store"
import CircleIcon from "@mui/icons-material/Circle"
import { useShallow } from "zustand/react/shallow"
import { BlackTooltip } from "common/Tooltip"
import { TreatmentTimelineEntity } from "./TreatmentTimelineEntity"
import { StyledEntitiesWrapper, StyledProviderName, StyledProviderRuler } from "./styled"

export const TreatmentTimeline = (): JSX.Element => {
  const appointmentData = useDocumentStore(useShallow(documentStoreSelectors.getAppointmentMedicronData))
  const entities = useDocumentStore(useShallow(documentStoreSelectors.getTreatmentTimelineEntities))

  return (
    <Box mb={15}>
      <Typography fontSize="13px" fontWeight={700} mb={3}>
        List of Providers
      </Typography>
      <Box display="flex">
        <Box width="240px" display="flex" gap={2} flexDirection="column">
          {Object.values(appointmentData.providers).map(provider => (
            <Box display="flex" key={provider.id} alignItems="center" gap={1} position="relative">
              <StyledProviderRuler />
              <CircleIcon sx={{ width: "11px", height: "11px" }} htmlColor={provider.color} />
              <BlackTooltip title={provider.name} arrow>
                <StyledProviderName>{provider.name}</StyledProviderName>
              </BlackTooltip>
            </Box>
          ))}
        </Box>
        <StyledEntitiesWrapper>
          {entities.map(entity => (
            <TreatmentTimelineEntity key={`${entity.text}${entity.type}`} entity={entity} />
          ))}
        </StyledEntitiesWrapper>
      </Box>
    </Box>
  )
}
