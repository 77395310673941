import { Alert } from "@mui/material"
import useCase from "hooks/useCase"
import { usePermissions } from "permissions/usePermissions"
import { DOCUMENT_TYPES } from "requests/enums"

interface Props {
  caseId: PrimaryKey
}

const SimpleDemandSkipBanner = ({ caseId }: Props): JSX.Element => {
  const { caseObj } = useCase(caseId)
  const { createSimpleEnabled } = usePermissions({
    firmId: caseObj?.firm?.pk,
  })

  if (createSimpleEnabled && caseObj?.questionnaire_type === DOCUMENT_TYPES.SIMPLE) {
    return (
      <Alert severity="warning">
        This is a Simple Demand and does not require this section. Please skip this section.
      </Alert>
    )
  }

  return <></>
}

export { SimpleDemandSkipBanner as default }
