const handleIntersection: IntersectionObserverCallback = ([{ isIntersecting, target }], observer) => {
  if (!isIntersecting) {
    target.scrollIntoView({ block: "end", behavior: "smooth" })
  }

  observer.unobserve(target)
}

export const intersectionObserverWithAutoscroll = window.IntersectionObserver
  ? new IntersectionObserver(handleIntersection)
  : null
