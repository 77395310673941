import { BaseEditor } from "slate"
import { SuggestionsEditor, WithSuggestions } from "./types"
import { Suggestions } from "./Suggestions"

export function withSuggestions<T extends BaseEditor>(editor: T): ReturnType<WithSuggestions<T>> {
  const editorWithFlags = editor as T & SuggestionsEditor

  editorWithFlags.suggestions = new Suggestions()

  return editorWithFlags
}
