import { useQuery, UseQueryResult } from "@tanstack/react-query"
import MissingFieldsAlert from "./MissingFieldsAlert"

import { getLossOfIncomeValidationFields } from "../../api"
import { queryKeys } from "../../react-query/constants"
import { Plaintiff } from "common/types/matter"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"

interface Props {
  caseId: number
  currentPlaintiff: Nullable<Plaintiff>
}

type ValidationFieldValue = Nullable<string>
interface ValidationFields {
  gender: ValidationFieldValue
}
type ValidationFieldKey = keyof ValidationFields

const errorFields: ValidationFieldKey[] = []

const MissingLossOfHouseholdAlert = ({ caseId, currentPlaintiff }: Props): JSX.Element => {
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)
  const { data, isLoading }: UseQueryResult<ValidationFields, boolean> = useQuery(
    [queryKeys.householdValidation, caseId],
    async () => {
      const incomeValidation = await getLossOfIncomeValidationFields(caseId)
      if (multiPlaintiffEnabled && currentPlaintiff) {
        // @ts-expect-error - When FF on, we return array
        return incomeValidation.find(plaintiff => plaintiff.pk === currentPlaintiff?.id)
      }
      return incomeValidation[0]
    }
  )

  if (isLoading || !data) {
    return <></>
  }

  return <MissingFieldsAlert data={data} errorFields={errorFields} />
}

export { MissingLossOfHouseholdAlert as default }
