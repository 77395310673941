import { InputFieldSkeleton } from "."
import { InterventionalTreatmentEditableRow, ReferenceEditableRow } from "../styled"

export const InterventionalTreatmentItemSkeleton = () => (
  <InterventionalTreatmentEditableRow>
    <InputFieldSkeleton />
    <InputFieldSkeleton />
    <ReferenceEditableRow>
      <InputFieldSkeleton />
      <InputFieldSkeleton />
    </ReferenceEditableRow>
  </InterventionalTreatmentEditableRow>
)
