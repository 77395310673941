import { Variable } from "common/types/variables"
import { VariablesRegistry } from "./types"

export class Variables extends Map<string, Variable> implements VariablesRegistry<Variable> {
  getList(): string[] {
    return [...this.keys()]
  }
  getItems(): Variable[] {
    return [...this.values()]
  }
  getItem(name: string): Variable | undefined {
    return this.get(name)
  }
}
