import Box from "@mui/material/Box"
import styled from "@emotion/styled"

import ReportProblemIcon from "@mui/icons-material/ReportProblem"

interface ErrorStateProps {
  message?: string
}

export const ErrorIcon = styled(ReportProblemIcon)(({ theme }) => ({
  fontSize: "32px",
  color: theme.palette.error.main,
}))

const ErrorState = ({
  message = "There was an error retrieving annotated data",
}: ErrorStateProps): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" m={"auto"}>
      <Box m={"auto"}>
        <ErrorIcon />
      </Box>
      <Box m={"auto"}>{message}</Box>
    </Box>
  )
}

export { ErrorState as default }
