import { useEffect } from "react"
import { useIsMutating } from "@tanstack/react-query"
import { useFormContext } from "./context"
import { VerticalCenterBox } from "../common/FlexBox"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Done from "@mui/icons-material/Done"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(theme => ({
  saving: {
    color: theme.palette.info.main,
  },
  saved: {
    color: theme.palette.success.main,
  },
}))
export function SavingIndicator({ text }: { text?: string }) {
  const isMutating = useIsMutating() > 0
  const { classes } = useStyles()
  const { savedSuccessfully, setSavedSuccessfully } = useFormContext()

  useEffect(() => {
    if (savedSuccessfully) {
      setTimeout(() => {
        setSavedSuccessfully(false)
      }, 3000)
    }
  }, [savedSuccessfully, setSavedSuccessfully])

  return (
    <>
      {text && <Typography paragraph>{text}</Typography>}
      {isMutating && (
        <VerticalCenterBox className={classes.saving} data-test="saving-indicator-inprogress">
          <CircularProgress color={"inherit"} disableShrink size={"1.5em"} /> &nbsp;
          <div>Saving</div>
        </VerticalCenterBox>
      )}
      {!isMutating && savedSuccessfully && (
        <VerticalCenterBox className={classes.saved} data-test="saving-indicator-succeed">
          <Done /> &nbsp;
          <div>Saved</div>
        </VerticalCenterBox>
      )}
    </>
  )
}
