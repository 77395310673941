import { Plaintiff, Provider, UserExhibit } from "../store/types"
import { SetState } from "./types"

export type FiltersSlice = {
  selectedProviders: Provider["id"][]
  selectedPlaintiffs: Plaintiff["id"][]
  selectedDocTypes: string[]
  selectedSubDocTypes: string[]
  selectedStartDate: Nullable<string>
  selectedEndDate: Nullable<string>
  selectedExhibitTags: UserExhibit["tag"][]
  selectedFiles: string[]
  exactProviderMatch: boolean
}

export const filtersSlice: FiltersSlice = {
  selectedProviders: [],
  selectedPlaintiffs: [],
  selectedDocTypes: [],
  selectedSubDocTypes: [],
  selectedStartDate: null,
  selectedEndDate: null,
  selectedExhibitTags: [],
  selectedFiles: [],
  exactProviderMatch: false,
}

export const filtersSelectors = {
  hasFiltersApplied: (state: FiltersSlice) => {
    return Boolean(
      state.selectedDocTypes.length ||
        state.selectedSubDocTypes.length ||
        state.selectedStartDate ||
        state.selectedEndDate ||
        state.selectedExhibitTags.length ||
        state.selectedProviders.length ||
        state.selectedPlaintiffs.length ||
        state.selectedFiles.length
    )
  },
}

export const filtersActions = (set: SetState<FiltersSlice>) => {
  const selectProviders = (providerIds: Provider["id"][]) => {
    set(() => ({ selectedProviders: providerIds }))
  }
  const selectPlaintiffs = (plaintiffIds: Plaintiff["id"][]) => {
    set(() => ({ selectedPlaintiffs: plaintiffIds }))
  }
  const selectDocTypes = (docTypes: string[]) => {
    set(() => ({ selectedDocTypes: docTypes }))
  }
  const selectSubDocTypes = (subDocTypes: string[]) => {
    set(() => ({ selectedSubDocTypes: subDocTypes }))
  }
  const selectStartDate = (startDate: Nullable<string>) => {
    set(() => ({ selectedStartDate: startDate }))
  }
  const selectEndDate = (endDate: Nullable<string>) => {
    set(() => ({ selectedEndDate: endDate }))
  }
  const selectTags = (tags: UserExhibit["tag"][]) => {
    set(() => ({ selectedExhibitTags: tags }))
  }

  const setSelectedFiles = (fileIds: string[]) => {
    set(() => ({ selectedFiles: fileIds }))
  }

  const setExactProviderMatch = (exactProviderMatch: boolean) => {
    set(() => ({ exactProviderMatch }))
  }

  const clearFilters = () => {
    set(() => filtersSlice)
  }

  return {
    clearFilters,
    selectProviders,
    selectPlaintiffs,
    selectDocTypes,
    selectSubSections: selectSubDocTypes,
    selectStartDate,
    selectEndDate,
    selectTags,
    setSelectedFiles,
    setExactProviderMatch,
  }
}
