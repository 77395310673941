import { FlagsSlice, SetState } from "./types"

export const flagsSlice: FlagsSlice = {
  activeFlags: {},
}

export const flagsSelectors = {
  activeFlagsCount: (state: FlagsSlice) =>
    Object.values(state.activeFlags).reduce((result, count) => result + count, 0),
  activeFlagsObject: (state: FlagsSlice) => state.activeFlags,
}

export const flagsActions = (set: SetState<FlagsSlice>) => {
  const setFlagsCount = (id: string, count: number) => {
    set(({ activeFlags }) => ({
      activeFlags: {
        ...activeFlags,
        [id]: count,
      },
    }))
  }

  const changeFlagsCount = (id: string, count: number = 1) => {
    set(({ activeFlags }) => {
      return {
        activeFlags: {
          ...activeFlags,
          [id]: Math.max(activeFlags[id] - count, 0),
        },
      }
    })
  }

  return {
    changeFlagsCount,
    setFlagsCount,
  }
}
