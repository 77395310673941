import { BaseEditor } from "slate"
import { VariablesEditor, WithVariables } from "./types"
import { Variables } from "./Variables"

export function withVariables<T extends BaseEditor>(editor: T): ReturnType<WithVariables<T>> {
  const editorWithVariables = editor as T & VariablesEditor

  editorWithVariables.variables = new Variables()

  return editorWithVariables
}
