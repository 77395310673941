import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import LockOpen from "@mui/icons-material/LockOpen"
import Lock from "@mui/icons-material/Lock"
import HelpOutline from "@mui/icons-material/HelpOutline"
import Alert from "@mui/material/Alert"

import { Controller, FieldErrors, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createContract, deleteContract, getFirmsFirstContract, updateContract } from "api"

import { useHandleMessages } from "common/messages/useHandleMessages"
import useUser from "hooks/useUser"

import { Contract } from "common/interfaces"
import { CurrencyField, DateField, InputField, SwitchInput } from "common/form-components"
import { InputWithHeader } from "common/form-components/InputWithHeader"
import { MainTitle } from "app/styled"
import { TitleAndValue } from "./ContractCard"
import TextButton from "common/buttons/TextButton"

import { formatDate, toUSDateString } from "utils"
import { queryKeys } from "react-query/constants"
import { canRoleManageContracts } from "../permissions"
import {
  ContractDatesObject,
  findContractOverlap,
  getEndDate,
  parseContractDate,
} from "common/contracts/utils"
import { ApiError } from "apiHelper"
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material"
import { Row } from "./styled"
import { ProductForm } from "./ProductForm"
import {
  BASIC_PLUS_PRICING_MODEL_FORM_DEFAULT_VALUES,
  SIMPLE_PRICING_MODEL_FORM_DEFAULT_VALUES,
  MEDICAL_CHRONOLOGY_PRICING_MODEL_FORM_DEFAULT_VALUES,
  STANDARD_DEMAND_PRICING_MODEL_FORM_DEFAULT_VALUES,
  MEDICAL_CHRONOLOGY_PREMIUM_PRICING_MODEL_FORM_DEFAULT_VALUES,
} from "./constants"
import { noop } from "lodash"
import { FEATURES, useFeatures } from "hooks/useFeatures"
import { ContractFormData, ContractSubmitData, PricingModelFormData } from "./types"
import { usePermissions } from "permissions/usePermissions"

const Container = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  display: "flex",
  flexDirection: "column",
  minWidth: "800px",
  [theme.breakpoints.down("md")]: {
    minWidth: "600px",
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: "400px",
  },
}))

const ButtonContainer = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2, 4),
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  display: "flex",
  justifyContent: "space-between",
}))

const RightSideButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
}))

const SwitchLabel = styled(Box)(({ theme }) => ({
  margin: theme.spacing("auto", 0, "auto", 0.5),
  fontStyle: "italic",
  color: theme.palette.grey[600],
}))

interface Props {
  contract: Contract | null | undefined
  firmId?: number
  onSubmitCallback: () => void
  onClose: () => void
  contracts: Contract[]
}

const overlapErrorMessage = (overlaps: ContractDatesObject[]) =>
  `Overlaps with other contract dates: ${overlaps
    .map(
      overlap =>
        `(${toUSDateString(overlap.start_date)} - ${toUSDateString(
          overlap.cancellation_date || overlap.end_date
        )})`
    )
    .join(", ")}`

const anyValidationErrors = (...args: FieldErrors<PricingModelFormData>[]) =>
  args.some(arg => Object.keys(arg).length > 0)

const getPopulatedPricingModels = (...args: Nullable<PricingModelFormData>[]) => {
  const populatedPricingModels: PricingModelFormData[] = []
  args.forEach(arg => {
    // use base_credit as a proxy for whether the form is populated
    if (arg !== null && arg.base_credit !== null) {
      populatedPricingModels.push(arg)
    }
  })
  return populatedPricingModels
}

export const ContractForm = ({
  contract,
  firmId,
  onSubmitCallback,
  onClose,
  contracts,
}: Props): JSX.Element => {
  const INITIAL_FORM_STATE = {
    contract_type: contract?.contract_type ?? "",
    monthly_price: contract?.monthly_price ?? 2750.0,
    start_date: contract?.start_date ?? "",
    term_length: contract?.term_length ?? 12,
    auto_renewal: contract?.auto_renewal ?? true,
    monthly_credits: contract?.monthly_credits ?? 5,
    medical_pages_cap: contract?.medical_pages_cap ?? 250,
    medical_pages_overage_fee: contract?.medical_pages_overage_fee ?? 150.0,
    medical_pages_overage: contract?.medical_pages_overage ?? 250,
    verdict_fee: contract?.verdict_fee ?? 50.0,
    firm_id: firmId,
    price_per_credit: contract?.price_per_credit ?? null,
    note: contract?.note ?? "",
  }

  const { user } = useUser()
  const { isFeatureEnabled } = useFeatures()
  const pricingModelsEnabled = isFeatureEnabled(FEATURES.PRICING_MODELS_ENABLED)

  const { data: firstContract } = useQuery<Contract>(
    [queryKeys.firmFirstContract, firmId],
    async () => {
      return await getFirmsFirstContract(firmId)
    },
    {
      enabled: canRoleManageContracts(user.role) && !!firmId,
    }
  )

  const isFirstContract = firstContract?.pk === contract?.pk

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    setError,
  } = useForm<ContractFormData>({
    defaultValues: INITIAL_FORM_STATE,
  })
  const queryClient = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useHandleMessages()
  const handleValidationError = (error: unknown) => {
    if (error instanceof ApiError && error.validationErrors) {
      Object.entries(error.validationErrors).forEach(([key, value]) => {
        setError(key as keyof typeof INITIAL_FORM_STATE, {
          message: value,
        })
      })
    }
  }
  const { medicalChronologyPremiumEnabled, createSimpleEnabled } = usePermissions()

  const standardFormControls = useForm<PricingModelFormData>({
    defaultValues: STANDARD_DEMAND_PRICING_MODEL_FORM_DEFAULT_VALUES,
    values: contract?.pricing_models?.find(model => model.product_type === "standard_demand") ?? undefined,
  })
  const basicPlusFormControls = useForm<PricingModelFormData>({
    defaultValues: BASIC_PLUS_PRICING_MODEL_FORM_DEFAULT_VALUES,
    values: contract?.pricing_models?.find(model => model.product_type === "basic_plus") ?? undefined,
  })
  const simpleFormControls = useForm<PricingModelFormData>({
    defaultValues: SIMPLE_PRICING_MODEL_FORM_DEFAULT_VALUES,
    values: contract?.pricing_models?.find(model => model.product_type === "simple") ?? undefined,
  })
  const medChronFormControls = useForm<PricingModelFormData>({
    defaultValues: MEDICAL_CHRONOLOGY_PRICING_MODEL_FORM_DEFAULT_VALUES,
    values: contract?.pricing_models?.find(model => model.product_type === "medical_chronology") ?? undefined,
  })
  const medChronPremiumFormControls = useForm<PricingModelFormData>({
    defaultValues: MEDICAL_CHRONOLOGY_PREMIUM_PRICING_MODEL_FORM_DEFAULT_VALUES,
    values:
      contract?.pricing_models?.find(model => model.product_type === "medical_chronology_premium") ??
      undefined,
  })

  const createContractMutation = useMutation(createContract, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.firm])
      queryClient.invalidateQueries([queryKeys.firmContracts])
      queryClient.invalidateQueries([queryKeys.firmContract])
      showSuccessMessage("The contract was successfully created")
      onSubmitCallback()
    },
    onError: error => {
      handleValidationError(error)
      showErrorMessage({
        message: "There was an error creating the contract",
        error,
      })
    },
  })
  const updateContractMutation = useMutation(updateContract, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.firm])
      queryClient.invalidateQueries([queryKeys.firmContracts])
      queryClient.invalidateQueries([queryKeys.firmContract])
      showSuccessMessage("The contract was successfully updated")
      onSubmitCallback()
    },
    onError: error => {
      handleValidationError(error)
      showErrorMessage({
        message: "There was an error updating the contract",
        error,
      })
    },
  })
  const deleteContractMutation = useMutation(deleteContract, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.firmContracts])
      queryClient.invalidateQueries([queryKeys.firmContract])
      showSuccessMessage("Contract deleted.")
      onSubmitCallback()
    },
    onError: error => {
      showErrorMessage({
        message: "Could not delete contract. Try again shortly or contact a dev if your problem persists.",
        error,
      })
    },
  })

  const handleSaveContract = handleSubmit(
    async data => {
      const submitData: ContractSubmitData = { ...data }
      const overlaps = findContractOverlap({
        idToIgnore: contract?.pk,
        startDate: parseContractDate(submitData.start_date),
        endDate: getEndDate(submitData.start_date, submitData.term_length),
        contracts,
      })

      if (overlaps.length > 0) {
        setError("start_date", {
          message: overlapErrorMessage(overlaps),
        })
        return
      }

      if (pricingModelsEnabled) {
        let standardPricingModel: Nullable<PricingModelFormData> = null
        let standardErrors: FieldErrors<PricingModelFormData> = {}
        let basicPlusPricingModel: Nullable<PricingModelFormData> = null
        let basicPlusErrors: FieldErrors<PricingModelFormData> = {}
        let simplePricingModel: Nullable<PricingModelFormData> = null
        let simpleErrors: FieldErrors<PricingModelFormData> = {}
        let medChronPricingModel: Nullable<PricingModelFormData> = null
        let medChronPremiumPricingModel: Nullable<PricingModelFormData> = null
        let medChronErrors: FieldErrors<PricingModelFormData> = {}
        let medChronPremiumErrors: FieldErrors<PricingModelFormData> = {}

        await standardFormControls.handleSubmit(
          standardData => {
            standardPricingModel = standardData
          },
          errors => (standardErrors = errors)
        )()

        await basicPlusFormControls.handleSubmit(
          basicPlusData => {
            basicPlusPricingModel = basicPlusData
          },
          errors => (basicPlusErrors = errors)
        )()

        await simpleFormControls.handleSubmit(
          simpleData => {
            simplePricingModel = simpleData
          },
          errors => (simpleErrors = errors)
        )()

        await medChronFormControls.handleSubmit(
          medChronData => {
            medChronPricingModel = medChronData
          },
          errors => (medChronErrors = errors)
        )()

        await medChronPremiumFormControls.handleSubmit(
          medChronData => {
            medChronPremiumPricingModel = medChronData
          },
          errors => (medChronPremiumErrors = errors)
        )()

        // don't save if subforms have errors
        if (
          anyValidationErrors(
            standardErrors,
            basicPlusErrors,
            medChronErrors,
            medChronPremiumErrors,
            simpleErrors
          )
        ) {
          showErrorMessage("Form has some missing fields. Please double check for errors and try again.")

          return
        }

        submitData.pricing_models = getPopulatedPricingModels(
          standardPricingModel,
          basicPlusPricingModel,
          medChronPricingModel,
          medChronPremiumPricingModel,
          simplePricingModel
        )
      }

      if (contract?.pk) {
        updateContractMutation.mutate({ data: submitData, contractId: contract.pk })
      } else {
        createContractMutation.mutate({ data: submitData })
      }
    },
    async () => {
      if (pricingModelsEnabled) {
        // call submit on subforms to trigger validation when error happens in contract form
        await standardFormControls.handleSubmit(noop)()
        await basicPlusFormControls.handleSubmit(noop)()
        await simpleFormControls.handleSubmit(noop)()
        await medChronFormControls.handleSubmit(noop)()
        await medChronPremiumFormControls.handleSubmit(noop)()
        showErrorMessage("Form has some missing fields. Please double check for errors and try again.")
      }
    }
  )

  const startDate = watch("start_date")
  const termLength = watch("term_length")
  const monthlyPrice = watch("monthly_price")
  const monthlyCredits = watch("monthly_credits")

  const automaticPricePerCredit = Number(monthlyPrice ?? 0) / (Number(monthlyCredits) || 1)
  const overridePricePerCredit = watch("price_per_credit")

  return (
    <>
      <Container data-test="contract-form">
        <Box mb={1}>
          <MainTitle mb={0}>{contract ? "Edit Contract" : "Create Contract"}</MainTitle>
          {contract?.cancellation_date && (
            <Alert severity="info">
              Scheduled for cancellation: {formatDate(contract?.cancellation_date, "MM/dd/yyyy", true)}
            </Alert>
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <Row>
            <InputWithHeader header="Contract Type">
              <Controller
                name="contract_type"
                control={control}
                rules={{
                  required: "You must select a contract type",
                }}
                render={({ field }) => {
                  return (
                    <FormControl error={!!errors.contract_type}>
                      <Select {...field} size="small" displayEmpty data-test="contract_type">
                        <MenuItem disabled value="">
                          Choose a contract type
                        </MenuItem>
                        <MenuItem value="subscription">Subscription</MenuItem>
                        <MenuItem value="one_time">One Time</MenuItem>
                        <MenuItem value="pay_as_you_go">Pay as you Go</MenuItem>
                        <MenuItem value="month_to_month">Month-to-Month</MenuItem>
                      </Select>
                      <FormHelperText>{errors?.contract_type?.message}</FormHelperText>
                    </FormControl>
                  )
                }}
              />
            </InputWithHeader>
          </Row>
          <Row>
            <InputWithHeader header="Start Date">
              <Controller
                name={"start_date"}
                control={control}
                rules={{
                  required: "You must provide a valid start date",
                }}
                render={({ field }) => {
                  return (
                    <Box maxWidth="231px">
                      <DateField
                        initialValue={getValues("start_date") ?? null}
                        {...field}
                        error={!!errors?.start_date}
                        helperText={errors?.start_date?.message}
                        fieldProps={{
                          size: "small",
                        }}
                        dataTest="start-date"
                      />
                    </Box>
                  )
                }}
              />
            </InputWithHeader>

            <InputWithHeader header="Term Length (Months)">
              <InputField
                rules={{ required: "Required" }}
                control={control}
                errors={errors}
                size={"small"}
                variant="outlined"
                name="term_length"
                type="number"
              />
            </InputWithHeader>

            {!!startDate && termLength !== undefined && (
              <Box mt="5%" data-test="term-ends-date">
                <TitleAndValue
                  title="Term Ends"
                  value={formatDate(getEndDate(startDate, termLength), "MM/dd/yyyy", true)}
                ></TitleAndValue>
              </Box>
            )}
          </Row>
          {!!firstContract?.start_date && !isFirstContract && (
            <Box>
              <Alert severity="info">
                <TitleAndValue
                  dataTest="term-start-date"
                  title="First Contract Start Date"
                  value={formatDate(firstContract.start_date, "MM/dd/yyyy", true)}
                ></TitleAndValue>
                Please line up the day of the month with the previous contracts from this customer to ensure
                consistent month lengths.
              </Alert>
            </Box>
          )}
          <Row paddingLeft={1}>
            <SwitchInput control={control} errors={errors} name="auto_renewal" label={"Auto Renewal"} />
            <SwitchLabel>{watch("auto_renewal") ? "On" : "Off"}</SwitchLabel>
          </Row>
          <Row>
            <InputWithHeader header="Monthly Price">
              <CurrencyField
                rules={{ required: "Required" }}
                control={control}
                errors={errors}
                size={"small"}
                variant="outlined"
                name="monthly_price"
                data-test="monthly-price"
              />
            </InputWithHeader>
            <InputWithHeader header="Credits Per Month">
              <InputField
                rules={{ required: "Required" }}
                control={control}
                errors={errors}
                size={"small"}
                variant="outlined"
                name="monthly_credits"
                type="number"
                data-test="monthly-credits"
              />
            </InputWithHeader>
            <Box mt="5%" data-test="total-credits">
              <TitleAndValue
                title="Total Credits Per Term"
                value={termLength * monthlyCredits}
              ></TitleAndValue>
            </Box>
          </Row>
          <Row>
            <InputWithHeader
              header={
                <>
                  Price Per Credit{" "}
                  <Tooltip
                    title="Price per credit is calculated with: Monthly Price / Monthly Credits. In most cases it can be automatically calculated, however if you wish to give a customer extra credits without affecting the value of a credit in the credit calculator you can manually override it."
                    arrow
                  >
                    <HelpOutline fontSize="small" />
                  </Tooltip>
                </>
              }
            >
              <Controller
                name="price_per_credit"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      size={"small"}
                      variant="outlined"
                      data-test="price-per-credit"
                      {...field}
                      disabled={overridePricePerCredit === null}
                      value={overridePricePerCredit ?? automaticPricePerCredit}
                    />
                  )
                }}
              />
            </InputWithHeader>
            <Box alignSelf="flex-end">
              <Button
                data-test="price-per-credit-override"
                onClick={() =>
                  overridePricePerCredit === null
                    ? setValue("price_per_credit", automaticPricePerCredit)
                    : setValue("price_per_credit", null)
                }
                startIcon={overridePricePerCredit === null ? <LockOpen /> : <Lock />}
              >
                {overridePricePerCredit === null ? "Override" : "Automatic"}
              </Button>
            </Box>
          </Row>
          {!pricingModelsEnabled && (
            <>
              <Row>
                <InputWithHeader header="Medical Record Page Limit">
                  <InputField
                    rules={{ required: "Required" }}
                    control={control}
                    errors={errors}
                    size={"small"}
                    variant="outlined"
                    name="medical_pages_cap"
                    type="number"
                  />
                </InputWithHeader>
              </Row>
              <Row>
                <InputWithHeader header="Overage Pages">
                  <InputField
                    rules={{ required: "Required" }}
                    control={control}
                    errors={errors}
                    size={"small"}
                    variant="outlined"
                    name="medical_pages_overage"
                    type="number"
                  />
                </InputWithHeader>
                <InputWithHeader header="Overage Fees">
                  <CurrencyField
                    rules={{ required: "Required" }}
                    control={control}
                    errors={errors}
                    size={"small"}
                    name="medical_pages_overage_fee"
                  />
                </InputWithHeader>
              </Row>
              <Row>
                <InputWithHeader header="Verdict Fees">
                  <CurrencyField
                    rules={{ required: "Required" }}
                    control={control}
                    errors={errors}
                    size={"small"}
                    name="verdict_fee"
                  />
                </InputWithHeader>
              </Row>
              <Row>
                <InputWithHeader fullWidth header="Notes">
                  <InputField
                    control={control}
                    errors={errors}
                    size={"small"}
                    variant="outlined"
                    name="note"
                    type="text"
                    multiline
                    fullWidth
                  />
                </InputWithHeader>
              </Row>
            </>
          )}
        </Box>
        {pricingModelsEnabled && (
          <>
            <Row>
              <ProductForm
                product={contract?.pricing_models?.find(model => model.product_type === "standard_demand")}
                pricePerCredit={overridePricePerCredit ?? automaticPricePerCredit}
                name="Standard Demand"
                productType="standard_demand"
                description="Every contract must have a standard demand pricing model."
                useFormReturn={standardFormControls}
                required
                // open standard form by default if creating a contract
                initiallyVisible={!contract}
              />
            </Row>
            <Row>
              <ProductForm
                product={contract?.pricing_models?.find(model => model.product_type === "basic_plus")}
                pricePerCredit={overridePricePerCredit ?? automaticPricePerCredit}
                name="Basic+ Demand"
                productType="basic_plus"
                description="Please fill out all necessary fields in this section."
                useFormReturn={basicPlusFormControls}
              />
            </Row>
            {createSimpleEnabled && (
              <Row>
                <ProductForm
                  product={contract?.pricing_models?.find(model => model.product_type === "simple")}
                  pricePerCredit={overridePricePerCredit ?? automaticPricePerCredit}
                  name="Simple Demand"
                  productType="simple"
                  description="Please fill out all necessary fields in this section."
                  useFormReturn={simpleFormControls}
                />
              </Row>
            )}
            <Row>
              <ProductForm
                product={contract?.pricing_models?.find(model => model.product_type === "medical_chronology")}
                pricePerCredit={overridePricePerCredit ?? automaticPricePerCredit}
                name="Medical Chronology"
                productType="medical_chronology"
                description="Please fill out all necessary fields in this section."
                useFormReturn={medChronFormControls}
              />
            </Row>
            {medicalChronologyPremiumEnabled && (
              <Row>
                <ProductForm
                  product={contract?.pricing_models?.find(
                    model => model.product_type === "medical_chronology_premium"
                  )}
                  pricePerCredit={overridePricePerCredit ?? automaticPricePerCredit}
                  name="Medical Chronology Premium"
                  productType="medical_chronology_premium"
                  description="Please fill out all necessary fields in this section."
                  useFormReturn={medChronPremiumFormControls}
                />
              </Row>
            )}
          </>
        )}
      </Container>
      <ButtonContainer>
        <Box display="flex" alignItems="center">
          {!!contract && (
            <TextButton
              textColor="secondary"
              size="large"
              disabled={
                contract?.active ||
                deleteContractMutation.isLoading ||
                updateContractMutation.isLoading ||
                createContractMutation.isLoading
              }
              onClick={() => {
                if (confirm("Are you sure you want to delete this contract?")) {
                  deleteContractMutation.mutate(contract.pk)
                }
              }}
              data-test="delete-contract-button"
            >
              {deleteContractMutation.isLoading ? "Deleting Contract" : "Delete Contract"}
            </TextButton>
          )}
          <Box>
            {contract?.active && <Typography variant="caption">* Cannot delete active contracts</Typography>}
          </Box>
        </Box>
        <RightSideButtons>
          <Button
            disabled={updateContractMutation.isLoading || createContractMutation.isLoading}
            color="inherit"
            disableElevation
            size="large"
            variant="contained"
            onClick={onClose}
            data-test="cancel-button"
          >
            Cancel
          </Button>
          <Button
            disabled={updateContractMutation.isLoading || createContractMutation.isLoading}
            variant="contained"
            color="secondary"
            onClick={handleSaveContract}
            data-test="save-contract-button"
            size="large"
            disableElevation
          >
            {contract ? "Update" : "Create"}
          </Button>
        </RightSideButtons>
      </ButtonContainer>
    </>
  )
}
