import { deleteListBackward } from "common/form-components/rich-text/commands/lists"
import { createCitation } from "common/form-components/rich-text/create"
import { CustomEditor } from "common/form-components/rich-text/CustomEditor"
import { Range, Transforms } from "slate"
import { Citation, CitationsEditor } from "../types"

export function insertCitation(editor: CustomEditor & CitationsEditor, citation: Citation): void | string {
  if (!editor.selection) return

  const previousSelection = editor.selection

  if (Range.isExpanded(previousSelection)) {
    deleteListBackward(editor) || Transforms.delete(editor, { at: previousSelection, voids: true })
  }

  const range = Range.isBackward(previousSelection) ? previousSelection.focus : previousSelection.anchor
  const citationElement = createCitation(citation)

  Transforms.insertNodes(editor, citationElement, { at: range })
}
