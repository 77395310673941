import { handleEmptyResponse } from "api/utils"
import { REQUEST_METHODS, makeApiCall } from "apiHelper"
import { QueryKeys } from "react-query/constants"
import { formatPlaintiffsProperties } from "requests/RequestForm/utils"
import { RequestViewDto } from "requests/ViewRequest/types"

export type UpdateRequestAPICallParams = CreateRequestParams & {
  requestId: string | number
}

// TODO: Refactor this into `service.ts` similar to the other services
export const updateRequest = async ({ requestId, data }: UpdateRequestAPICallParams) => {
  const options = {
    path: `/intake/${requestId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return handleEmptyResponse(makeApiCall<RequestViewDto>(options)).then(data => ({
    ...data,
    plaintiffs: formatPlaintiffsProperties(data.plaintiffs),
  }))
}

export type CreateRequestParams = {
  data: Partial<RequestViewDto>
}

// In the future: Refactor this into `service.ts` similar to the other services
export const createRequest = async ({ data }: CreateRequestParams) => {
  const options = {
    path: `/intake/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return handleEmptyResponse(makeApiCall<RequestViewDto>(options)).then(data => ({
    ...data,
    plaintiffs: formatPlaintiffsProperties(data.plaintiffs),
  }))
}

// In the future: Refactor this into `service.ts` similar to the other services
export const fetchRequest = async ({ queryKey }: { queryKey: [QueryKeys, string] }) => {
  const [, requestId] = queryKey
  const options = {
    path: `/intake/${requestId}/`,
    method: REQUEST_METHODS.GET,
  }

  return handleEmptyResponse(makeApiCall<RequestViewDto>(options)).then(data => ({
    ...data,
    plaintiffs: formatPlaintiffsProperties(data.plaintiffs),
  }))
}
