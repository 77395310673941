import { getServiceWorker } from "./getServiceWorker"
import { MESSAGE_TOPIC, REACTIVATE_TOPIC, SUBSCRIBE_TOPIC, UNSUBSCRIBE_TOPIC } from "./topics"
import { Subscribe } from "./types"
import { getWorkbox } from "./workbox"

const subscribedTopics = new Map<MESSAGE_TOPIC, number>()

navigator.serviceWorker?.addEventListener("message", (event: Event) => {
  if (
    event instanceof MessageEvent &&
    event.ports[0] instanceof MessagePort &&
    event.data.topic === REACTIVATE_TOPIC
  ) {
    event.ports[0].postMessage({
      topics: Array.from(subscribedTopics.keys()).filter(key => (subscribedTopics.get(key) ?? 0) > 0),
    })
  }
})

export const subscribe: Subscribe = (topic, handler, onError) => {
  const handleMessage = (event: Event) => {
    if (!(event instanceof MessageEvent) || event.data.topic !== topic) return

    const result = handler(event)

    if (event.ports[0] instanceof MessagePort) {
      const port = event.ports[0]

      result.then(
        payload => port.postMessage({ success: true, payload }),
        error => port.postMessage({ success: false, error })
      )
    }
  }

  const subscribeToMessageBroker = () => {
    getServiceWorker()
      .then(serviceWorker => {
        navigator.serviceWorker.removeEventListener("message", handleMessage)
        navigator.serviceWorker.addEventListener("message", handleMessage)
        serviceWorker.postMessage({ topic: SUBSCRIBE_TOPIC, payload: topic })
      })
      .catch(onError)
  }

  subscribeToMessageBroker()

  getWorkbox().addEventListener("activated", subscribeToMessageBroker)
  subscribedTopics.set(topic, (subscribedTopics.get(topic) ?? 0) + 1)

  return () => {
    getServiceWorker()
      .then(serviceWorker => {
        getWorkbox().removeEventListener("activated", subscribeToMessageBroker)
        navigator.serviceWorker.removeEventListener("message", handleMessage)
        subscribedTopics.set(topic, (subscribedTopics.get(topic) ?? 1) - 1)
        serviceWorker.postMessage({ topic: UNSUBSCRIBE_TOPIC, payload: topic })
      })
      .catch(onError)
  }
}
