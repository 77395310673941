import React from "react"
import useUser from "../hooks/useUser"
import { Navigate } from "react-router-dom"

export default function Home(): React.ReactNode {
  const { user, isLoading } = useUser()

  if (!user.isAuthorized || isLoading) {
    return null
  }

  const navigateTo = "/requests"
  return <Navigate to={navigateTo} replace />
}
