import React from "react"
import MuiTooltip from "@mui/material/Tooltip"
import { withStyles } from "tss-react/mui"
import { theme } from "app/theme"

interface Props {
  tooltipText: string
  disabled?: boolean
  onClick?: () => void
  children: React.ReactElement
  placement?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  other?: any
}

export const BlackTooltip = withStyles(MuiTooltip, {
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    margin: "6px",
    backgroundColor: theme.palette.common.black,
    fontSize: "12px",
    textAlign: "center",
  },
})

export const WhiteTooltip = withStyles(MuiTooltip, {
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    margin: "6px",
    backgroundColor: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
    textAlign: "left",
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  },
})

const Tooltip = ({
  tooltipText,
  onClick,
  children,
  disabled = false,
  placement = "top",
  ...other
}: Props): React.ReactElement => {
  const child = React.cloneElement(children, { disabled, onClick, placement, ...other })

  return (
    <MuiTooltip title={tooltipText} {...other} data-test="tooltip">
      <span>{child}</span>
    </MuiTooltip>
  )
}

export { Tooltip as default }
