import { v4 } from "uuid"
import { isNull, sortBy, uniqBy } from "lodash"
import {
  Bill,
  Exhibit,
  FileToUpload,
  ExhibitValidationError,
  ID,
  Provider,
  ProviderDto,
  CaseIcdCode,
  ProviderFormState,
  ValidationErrors,
  CaseCptCode,
  PartitionedExhibit,
} from "./types"
import { providerLocalStorage } from "./ProviderLocalStorage"
import { ProviderTemplatedSectionDto } from "api/services/case/types"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { EXHIBIT, PARTITIONED_EXHIBIT } from "./constants"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"
import { DOCUMENT_TYPES } from "requests/enums"
import {
  ObjectiveTest,
  ObjectiveTestDto,
  ObjectiveTestFinding,
} from "api/services/provider-objective-test/types"
import {
  InterventionalTreatment,
  InterventionalTreatmentDto,
} from "api/services/provider-interventional-treatment/types"
import { ObjectiveTestDeserializer } from "api/services/provider-objective-test/serializers"
import { InterventionalTreatmentDeserializer } from "api/services/provider-interventional-treatment/serializers"

export function getInitialFormState(): ProviderFormState {
  return {
    activeProviderId: null,
    providers: [],
    validationErrors: {},
  }
}

export const SET_PROVIDERS = "SET_PROVIDERS"
export const SET_INITIAL_PROVIDERS = "SET_INITIAL_PROVIDERS"
export const UPDATE_PROVIDER = "UPDATE_PROVIDER"
export const UPDATE_PROVIDER_NAME = "UPDATE_PROVIDER_NAME"
export const UPDATE_PROVIDER_FIELD = "UPDATE_PROVIDER_FIELD"
export const UPDATE_INCLUDE_TABLE = "UPDATE_INCLUDE_TABLE"
export const UPDATE_FIRST_CONTACT = "UPDATE_FIRST_CONTACT"
export const UPDATE_LAST_CONTACT = "UPDATE_LAST_CONTACT"
export const UPDATE_VISIT_COUNT = "UPDATE_VISIT_COUNT"
export const UPDATE_ONGOING_APPOINTMENT = "UPDATE_ONGOING_APPOINTMENT"
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE"
export const ADD_PROVIDER = "ADD_PROVIDER"
export const TOGGLE_OPEN = "TOGGLE_OPEN"
export const UPDATE_ICD_CODES = "UPDATE_ICD_CODES"
export const UPDATE_CPT_CODES = "UPDATE_CPT_CODES"
export const UPDATE_INJURY_DETAILS = "UPDATE_INJURY_DETAILS"
export const BILL_EXHIBIT_CHANGE = "BILL_EXHIBIT_CHANGE"
export const SET_EDITING = "SET_EDITING"
export const TOGGLE_SAVING = "TOGGLE_SAVING"
export const TOGGLE_DELETING = "TOGGLE_DELETING"
export const SAVE_IN_BACKGROUND = "SAVE_IN_BACKGROUND"
export const PROVIDER_UPDATE_SUCCESS = "PROVIDER_UPDATE_SUCCESS"
export const PROVIDER_UPDATE_PARTIAL_SUCCESS = "PROVIDER_UPDATE_PARTIAL_SUCCESS"
export const SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS"
export const CLOSE_EDITING_PROVIDER = "CLOSE_EDITING_PROVIDER"
export const DELETE_PROVIDER = "DELETE_PROVIDER"
export const ADD_FILES_TO_UPLOAD = "ADD_FILES_TO_UPLOAD"
export const UPDATE_FILES_TO_UPLOAD = "UPDATE_FILES_TO_UPLOAD"
export const DELETE_FILE_TO_UPLOAD = "DELETE_FILE_TO_UPLOAD"
export const SET_FILE_VALIDATION = "SET_FILE_VALIDATION"
export const FILE_UPLOAD_START = "FILE_UPLOAD_START"
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR"
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS"
export const SET_EXHIBITS = "SET_EXHIBITS"
export const DELETE_EXHIBIT = "DELETE_EXHIBIT"
export const UPDATE_EXHIBITS = "UPDATE_EXHIBITS"
export const SET_EXHIBIT_EDITING = "SET_EXHIBIT_EDITING"
export const RESET_EXHIBIT = "RESET_EXHIBIT"
export const EXHIBIT_SAVE_START = "EXHIBIT_SAVE_START"
export const EXHIBIT_SAVE_SUCCESS = "EXHIBIT_SAVE_SUCCESS"
export const EXHIBIT_SAVE_ERROR = "EXHIBIT_SAVE_ERROR"
export const SORT_PROVIDERS = "SORT_PROVIDERS"
export const ADD_BILL = "ADD_BILL"
export const UPDATE_BILL = "UPDATE_BILL"
export const DELETE_BILL = "DELETE_BILL"
export const SELECT_ALL_BILLS = "SELECT_ALL_BILLS"
export const UNSELECT_ALL_BILLS = "UNSELECT_ALL_BILLS"
export const DELETE_SELECTED_BILLS = "DELETE_SELECTED_BILLS"
export const APPEND_EXHIBIT = "APPEND_EXHIBIT"
export const DELETE_PARTITION = "DELETE_PARTITION"
export const ADD_OBJECTIVE_TEST = "ADD_OBJECTIVE_TEST"
export const UPDATE_OBJECTIVE_TEST = "UPDATE_OBJECTIVE_TEST"
export const DELETE_OBJECTIVE_TEST = "DELETE_OBJECTIVE_TEST"
export const ADD_OBJECTIVE_TEST_FINDING = "ADD_OBJECTIVE_TEST_FINDING"
export const UPDATE_OBJECTIVE_TEST_FINDING = "UPDATE_OBJECTIVE_TEST_FINDING"
export const ADD_INTERVENTIONAL_TREATMENT = "ADD_INTERVENTIONAL_TREATMENT"
export const UPDATE_INTERVENTIONAL_TREATMENT = "UPDATE_INTERVENTIONAL_TREATMENT"
export const DELETE_INTERVENTIONAL_TREATMENT = "DELETE_INTERVENTIONAL_TREATMENT"

const setProviderExhibits = (provider: Provider) => {
  // Exhibits on the provider object can include both partitions and exhibits
  // To work around this, we filter out the partitions and re-add them below
  const exhibits: Exhibit[] = []
  const exhibitPartitions: Record<PartitionedExhibit["pk"], PartitionedExhibit> = {}

  provider.exhibits?.forEach((exhibit: Exhibit | PartitionedExhibit) => {
    if (exhibit.exhibitType === PARTITIONED_EXHIBIT) {
      exhibitPartitions[exhibit.pk] = exhibit as PartitionedExhibit
    } else {
      exhibits.push({ ...exhibit, exhibitType: EXHIBIT })
    }
  })

  // add partitions to the exhibits list if they don't already exist
  provider.exhibit_partitions?.forEach((partitionExhibit: PartitionedExhibit) => {
    if (!exhibitPartitions[partitionExhibit.pk]) {
      exhibitPartitions[partitionExhibit.pk] = { ...partitionExhibit, exhibitType: PARTITIONED_EXHIBIT }
    }
  })

  return [...exhibits, ...Object.values(exhibitPartitions)].sort((a, b) => {
    if (a.section_index === null && b.section_index === null) return 0
    if (a.section_index !== null && b.section_index === null) return 1
    if (a.section_index === null && b.section_index !== null) return -1

    return (a.section_index ?? -1) - (b.section_index ?? -1)
  })
}

// Deserialize objective_tests because the old Provider API returns the data in a different format
// This should be removed once the API is updated
const deserializeProviderObjectiveTests = (
  objectiveTests?: ObjectiveTestDto[]
): ObjectiveTest[] | undefined => {
  if (!objectiveTests) return undefined

  return ObjectiveTestDeserializer.fromListJSON(objectiveTests)
}

// Deserialize interventional_treatments because the old Provider API returns the data in a different format
// This should be removed once the API is updated
const deserializeProviderInterventionalTreatments = (
  interventionalTreatments?: InterventionalTreatmentDto[]
): InterventionalTreatment[] | undefined => {
  if (!interventionalTreatments) return undefined

  return InterventionalTreatmentDeserializer.fromListJSON(interventionalTreatments)
}

export const providerReducer = (currentState: ProviderFormState, action: Action): ProviderFormState => {
  switch (action.type) {
    case SET_PROVIDERS: {
      const state = { ...currentState }
      const { providers, plaintiffId } = action.payload

      if (!plaintiffId) {
        state.providers = providers
      } else {
        // Keep all providers for the other plaintiffs in order
        const reorderedProviders = [
          ...state.providers.filter((provider: Provider) => {
            return provider?.plaintiff?.pk !== plaintiffId
          }),
          ...providers,
        ]

        state.providers = reorderedProviders
      }

      return state
    }

    case SET_INITIAL_PROVIDERS: {
      const state = { ...currentState }
      const { providers: providersDto, caseId } = action.payload

      const providers: Provider[] = providersDto.map(provider => ({
        ...provider,
        objective_tests: deserializeProviderObjectiveTests(provider.objective_tests),
        interventional_treatments: deserializeProviderInterventionalTreatments(
          provider.interventional_treatments
        ),
      }))

      const savedProvider = providerLocalStorage.getSavedProvider(caseId)

      if (savedProvider && savedProvider.editing) {
        state.providers = providers.map(provider => {
          const providerData = {
            ...provider,
            bills: sortBy(
              provider.bills?.map(bill => {
                return {
                  ...bill,
                  selected: false,
                }
              }),
              "pk"
            ),
            exhibits: setProviderExhibits(provider),
          }

          if (provider.pk === savedProvider.providerId) {
            providerData.open = true
          }

          return providerData
        })

        state.activeProviderId = savedProvider.providerId

        return state
      }

      state.providers = providers.map(provider => {
        return {
          ...provider,
          exhibits: setProviderExhibits(provider),
        }
      })

      return state
    }

    case UPDATE_PROVIDER: {
      const { newProvider: newProviderDto } = action.payload

      const newProvider: Provider = {
        ...newProviderDto,
        objective_tests: deserializeProviderObjectiveTests(newProviderDto.objective_tests),
        interventional_treatments: deserializeProviderInterventionalTreatments(
          newProviderDto.interventional_treatments
        ),
      }

      const providerToReplace = currentState.providers.find(({ pk }) => newProvider.pk === pk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(provider =>
            provider === providerToReplace
              ? {
                  ...newProvider,
                  exhibits: setProviderExhibits(newProvider),
                }
              : provider
          ),
        }
      }

      return currentState
    }

    case UPDATE_PROVIDER_NAME: {
      const { providerId, name } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace ? { ...providerToReplace, name } : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_INCLUDE_TABLE: {
      const { providerId, includeTable } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? { ...providerToReplace, include_table: includeTable }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_PROVIDER_FIELD: {
      const { providerId, field, value } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace ? { ...providerToReplace, [field]: value } : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_FIRST_CONTACT: {
      const { providerId, firstContact } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)

      if (providerToReplace) {
        const isOneDayAppointment = Boolean(firstContact) && !providerToReplace.last_contact
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  first_contact: firstContact,
                  is_one_day_appointment: isOneDayAppointment,
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_VISIT_COUNT: {
      const { providerId, visitCount } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? { ...providerToReplace, visit_count: visitCount }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_ONGOING_APPOINTMENT: {
      const { providerId, ongoingAppointment } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            providerToReplace === stateProvider
              ? { ...providerToReplace, is_ongoing_appointment: ongoingAppointment }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_LAST_CONTACT: {
      const { providerId, lastContact } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)

      if (providerToReplace) {
        const isOneDayAppointment = Boolean(providerToReplace.first_contact) && !lastContact

        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  last_contact: lastContact,
                  is_one_day_appointment: isOneDayAppointment,
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_TEMPLATE: {
      const { providerId, template } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider => {
            if (providerToReplace === stateProvider) {
              const additions: { details_json?: EditorRoot } = {}
              if (template.custom_content) {
                additions.details_json = template.custom_content
              } else if (template.template?.content) {
                additions.details_json = template.template.content
              }

              return { ...providerToReplace, templated_sections: [template], ...additions }
            }

            return stateProvider
          }),
        }
      }

      return currentState
    }

    case UPDATE_ICD_CODES: {
      const { providerId, icdCodes } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)
      const icdCodesToSave = uniqBy(icdCodes, "code")

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? { ...providerToReplace, icd_codes: icdCodesToSave }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_CPT_CODES: {
      const { providerId, cptCodes } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)

      if (providerToReplace) {
        const cptCodesToSave = uniqBy(cptCodes, "code")
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? { ...providerToReplace, cpt_codes: cptCodesToSave }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_INJURY_DETAILS: {
      const { providerId, value, customContent } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => providerId === pk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider => {
            if (providerToReplace === stateProvider) {
              if (providerToReplace.templated_sections && customContent) {
                return {
                  ...providerToReplace,
                  details_json: customContent,
                  templated_sections: [
                    { ...providerToReplace.templated_sections[0], custom_content: customContent },
                  ],
                }
              }

              return {
                ...providerToReplace,
                details_json: value,
              }
            }

            return stateProvider
          }),
        }
      }

      return currentState
    }

    case ADD_PROVIDER: {
      const state = { ...currentState }
      const { provider: providerDto, caseId } = action.payload

      const provider: Provider = {
        ...providerDto,
        objective_tests: deserializeProviderObjectiveTests(providerDto.objective_tests),
        interventional_treatments: deserializeProviderInterventionalTreatments(
          providerDto.interventional_treatments
        ),
      }

      providerLocalStorage.onChange(true, caseId, provider.pk)
      state.providers.push({
        ...provider,
        open: true,
      })
      state.activeProviderId = provider.pk

      return state
    }

    case TOGGLE_OPEN: {
      const { id, demand_id, request_id, request_type, provider_name } = action.payload
      const providerToRepace = currentState.providers.find(provider => provider.pk === id)
      if (request_id && providerToRepace) {
        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(
            providerToRepace.open
              ? DemandAnalyticsEventTypes.ProviderDetailsClosed
              : DemandAnalyticsEventTypes.ProviderDetailsOpened,
            {
              demand_id,
              request_id,
              request_type,
              provider_name,
            }
          )
        )
      }
      if (providerToRepace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToRepace
              ? { ...providerToRepace, open: !providerToRepace.open }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case SET_EDITING: {
      const state = { ...currentState }
      const { id, caseId, request_id, request_type, provider_name } = action.payload
      const newProviders: Provider[] = []

      if (request_id) {
        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(DemandAnalyticsEventTypes.ProviderDetailsEditStarted, {
            demand_id: `${caseId}`,
            request_id,
            request_type,
            provider_name,
          })
        )
      }

      state.providers.forEach(prevProvider => {
        // if provider is entering the editing state, make sure it's open
        if (prevProvider.pk && prevProvider.pk === id) {
          providerLocalStorage.onChange(true, String(caseId), id)
          newProviders.push({ ...prevProvider, open: true })
        }
        // else if another existing provider is active, close it
        else if (prevProvider.pk && prevProvider.pk === state.activeProviderId) {
          newProviders.push({ ...prevProvider, open: false })
        }
        // make sure we only add providers with pks because
        // we want to remove any providers that may have been adding when an existing provider enters the editing state
        else if (prevProvider.pk) {
          newProviders.push(prevProvider)
        }
      })

      state.providers = newProviders
      state.validationErrors = {}
      state.activeProviderId = id

      return state
    }

    case TOGGLE_SAVING: {
      const { id } = action.payload
      const providerToReplace = currentState.providers.find(provider => provider.pk === id)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? { ...providerToReplace, saving: !providerToReplace.saving }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case SAVE_IN_BACKGROUND: {
      const state = { ...currentState }
      const { provider: providerDto } = action.payload

      const provider: Provider = {
        ...providerDto,
        objective_tests: deserializeProviderObjectiveTests(providerDto.objective_tests),
        interventional_treatments: deserializeProviderInterventionalTreatments(
          providerDto.interventional_treatments
        ),
      }

      const newProvider = { ...provider, bills: sortBy(provider.bills, "pk") }

      state.providers = state.providers.map(stateProvider => {
        return stateProvider.pk === provider.pk
          ? {
              ...stateProvider,
              ...newProvider,
              exhibits: setProviderExhibits(newProvider),
            }
          : stateProvider
      })

      return state
    }

    case TOGGLE_DELETING: {
      const { id, demand_id, request_id, request_type, provider_name } = action.payload
      const providerToReplace = currentState.providers.find(provider => provider.pk === id)
      if (request_id) {
        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(DemandAnalyticsEventTypes.ProviderDetailsEditEnded, {
            demand_id,
            request_id,
            request_type,
            provider_name,
          })
        )

        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(DemandAnalyticsEventTypes.ProviderDeleted, {
            demand_id,
            request_id,
            request_type,
            provider_name,
          })
        )
      }

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider => {
            return stateProvider === providerToReplace
              ? { ...providerToReplace, deleting: !providerToReplace.deleting }
              : stateProvider
          }),
        }
      }

      return currentState
    }

    case PROVIDER_UPDATE_SUCCESS: {
      const state = { ...currentState }
      const { provider: providerDto, caseId } = action.payload

      const provider: Provider = {
        ...providerDto,
        objective_tests: deserializeProviderObjectiveTests(providerDto.objective_tests),
        interventional_treatments: deserializeProviderInterventionalTreatments(
          providerDto.interventional_treatments
        ),
      }

      const providerToReplace = currentState.providers.find(({ pk }) => provider.pk === pk)

      if (providerToReplace) {
        state.providers = currentState.providers.map(stateProvider =>
          stateProvider === providerToReplace
            ? {
                ...provider,
                saving: false,
                open: false,
                exhibits: setProviderExhibits(provider),
              }
            : stateProvider
        )
      }

      state.validationErrors = {}
      state.activeProviderId = null
      providerLocalStorage.removeProvider(caseId)

      return state
    }

    case PROVIDER_UPDATE_PARTIAL_SUCCESS: {
      const state = { ...currentState }
      const { provider, caseId } = action.payload
      const providerToReplace = state.providers.find(stateProvider => stateProvider.pk === provider.pk)

      if (providerToReplace) {
        state.providers = currentState.providers.map(stateProvider =>
          stateProvider === providerToReplace ? { ...provider, saving: false, open: true } : stateProvider
        )
      }

      state.validationErrors = {}
      providerLocalStorage.removeProvider(caseId)

      return state
    }

    case SET_VALIDATION_ERRORS: {
      const state = { ...currentState }
      const { validationErrors } = action.payload

      state.validationErrors = validationErrors

      return state
    }

    case CLOSE_EDITING_PROVIDER: {
      const state = { ...currentState }
      const { pk, caseId, request_id, request_type, provider_name } = action.payload
      const providerToReplace = state.providers.find(stateProvider => stateProvider.pk === pk)
      if (request_id) {
        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(DemandAnalyticsEventTypes.ProviderDetailsEditEnded, {
            demand_id: caseId,
            request_id,
            request_type,
            provider_name,
          })
        )
      }
      if (providerToReplace) {
        state.providers = currentState.providers.map(stateProvider =>
          stateProvider === providerToReplace ? { ...providerToReplace, open: false } : stateProvider
        )
      }

      state.validationErrors = {}
      state.activeProviderId = null
      providerLocalStorage.removeProvider(caseId)

      return state
    }

    case DELETE_PROVIDER: {
      const state = { ...currentState }
      const { pk, caseId } = action.payload

      state.providers = state.providers.filter(provider => provider.pk !== pk)
      state.activeProviderId = null
      state.validationErrors = {}
      providerLocalStorage.removeProvider(caseId)

      return state
    }

    case ADD_FILES_TO_UPLOAD: {
      const { files, id } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === id)

      if (providerToReplace) {
        const filesToUpload: FileToUpload[] = files.map(file => ({
          formId: v4(),
          name: file.name,
          type: null,
          file,
        }))

        const providerFilesToUpload = providerToReplace.filesToUpload
          ? [...providerToReplace.filesToUpload, ...filesToUpload]
          : filesToUpload

        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? { ...providerToReplace, filesToUpload: providerFilesToUpload }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_FILES_TO_UPLOAD: {
      const { id, filesToUpload } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === id)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace ? { ...providerToReplace, filesToUpload } : stateProvider
          ),
        }
      }

      return currentState
    }

    case DELETE_FILE_TO_UPLOAD: {
      const { id, fileFormId } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === id)

      if (providerToReplace) {
        let errorIndex: Nullable<number> = null

        const result = {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  filesToUpload: stateProvider.filesToUpload?.filter((file, index) => {
                    // filter the file out of the array
                    if (file.formId === fileFormId) {
                      // also filter any validation errors associated with that file
                      errorIndex = index
                      return false
                    }
                    return true
                  }),
                  bills: stateProvider.bills?.map(bill =>
                    bill.file_to_upload_id === fileFormId ? { ...bill, file_to_upload_id: null } : bill
                  ),
                }
              : stateProvider
          ),
        }

        if (!isNull(errorIndex) && result.validationErrors.filesToUpload?.[errorIndex] !== undefined) {
          result.validationErrors = {
            ...result.validationErrors,
            filesToUpload: result.validationErrors.filesToUpload.filter((_, index) => errorIndex !== index),
          }
        }

        return result
      }

      return currentState
    }

    case SET_FILE_VALIDATION: {
      const state = { ...currentState }
      const { validationError, index, providerPk } = action.payload

      // if no validation errors yet create an array of null entries for each fileToUpload
      if (!state.validationErrors.filesToUpload) {
        const provider = state.providers.find(provider => provider.pk === providerPk)

        state.validationErrors.filesToUpload = provider?.filesToUpload?.map(() => null) ?? []
      }

      state.validationErrors = {
        ...state.validationErrors,
        filesToUpload: state.validationErrors.filesToUpload.map((file, fileIndex) =>
          index === fileIndex ? validationError : file
        ),
      }

      return state
    }

    case FILE_UPLOAD_START: {
      const { providerPk, formId } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace) {
        let errorIndex: Nullable<number> = null

        const result = {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  filesToUpload: providerToReplace.filesToUpload?.map((file, index) => {
                    if (file.formId === formId) {
                      errorIndex = index
                      return { ...file, error: undefined, uploading: true }
                    }

                    return file
                  }),
                }
              : stateProvider
          ),
        }

        if (!isNull(errorIndex) && result.validationErrors.filesToUpload) {
          result.validationErrors = {
            ...result.validationErrors,
            filesToUpload: result.validationErrors.filesToUpload.map((file, fileIndex) =>
              errorIndex === fileIndex ? null : file
            ),
          }
        }

        return result
      }

      return currentState
    }

    case FILE_UPLOAD_ERROR: {
      const { providerPk, formId, error } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace && providerToReplace.filesToUpload) {
        const filesToUpload = providerToReplace.filesToUpload.map(file =>
          file.formId === formId ? { ...file, error, uploading: false } : file
        )

        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace ? { ...providerToReplace, filesToUpload } : stateProvider
          ),
        }
      }

      return currentState
    }

    case FILE_UPLOAD_SUCCESS: {
      const { providerPk, formId, exhibit } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  filesToUpload: stateProvider.filesToUpload?.filter(file => file.formId !== formId),
                  exhibits: [...(stateProvider.exhibits ?? []), { ...exhibit, exhibitType: EXHIBIT }],
                  bills: stateProvider.bills?.map(bill =>
                    bill.file_to_upload_id === formId
                      ? { ...bill, file_to_upload_id: null, exhibit_id: exhibit.pk }
                      : bill
                  ),
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case SET_EXHIBITS: {
      const { exhibits, providerPk } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  exhibits,
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case DELETE_EXHIBIT: {
      const { exhibitPk, providerPk } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  exhibits: providerToReplace.exhibits?.filter(
                    exhibit =>
                      exhibit.pk !== exhibitPk || (exhibit.exhibitType && exhibit.exhibitType !== EXHIBIT)
                  ),
                  bills: providerToReplace.bills?.map(bill =>
                    bill.exhibit_id === exhibitPk ? { ...bill, exhibit_id: null } : bill
                  ),
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_EXHIBITS: {
      const { providerPk, exhibits } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  exhibits,
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case SET_EXHIBIT_EDITING: {
      const { providerPk, exhibitPk, editing, exhibitType } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace?.exhibits) {
        const exhibit = providerToReplace?.exhibits?.find(providerExhibit => {
          if (providerExhibit.pk !== exhibitPk) {
            return false
          }

          if (!providerExhibit.exhibitType || providerExhibit.exhibitType === exhibitType) {
            return true
          }

          return false
        })

        return exhibit
          ? {
              ...currentState,
              providers: currentState.providers.map(stateProvider =>
                stateProvider === providerToReplace
                  ? {
                      ...providerToReplace,
                      exhibits: providerToReplace.exhibits?.map(providerExhibit =>
                        providerExhibit === exhibit ? { ...providerExhibit, editing } : providerExhibit
                      ),
                    }
                  : stateProvider
              ),
            }
          : currentState
      }

      return currentState
    }

    case RESET_EXHIBIT: {
      const { providerPk, exhibitPk, exhibit } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace) {
        let errorIndex: Nullable<number> = null

        const result = {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  exhibits: providerToReplace.exhibits?.map((providerExhibit, index) => {
                    if (providerExhibit.pk === exhibitPk) {
                      errorIndex = index

                      return exhibit
                    }
                    return providerExhibit
                  }),
                }
              : stateProvider
          ),
        }

        if (!isNull(errorIndex) && result.validationErrors.exhibits) {
          result.validationErrors = {
            ...result.validationErrors,
            exhibits: result.validationErrors.exhibits.map((file, fileIndex) =>
              errorIndex === fileIndex ? null : file
            ),
          }
        }

        return result
      }

      return currentState
    }

    case EXHIBIT_SAVE_START: {
      const { providerPk, exhibitPk, exhibitType } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace) {
        let errorIndex: Nullable<number> = null

        const result = {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  exhibits: providerToReplace.exhibits?.map((providerExhibit, index) => {
                    if (
                      providerExhibit.pk === exhibitPk &&
                      (!providerExhibit.exhibitType || providerExhibit.exhibitType === exhibitType)
                    ) {
                      errorIndex = index

                      return { ...providerExhibit, saving: true }
                    }
                    return providerExhibit
                  }),
                }
              : stateProvider
          ),
        }

        if (!isNull(errorIndex) && result.validationErrors.exhibits) {
          result.validationErrors = {
            ...result.validationErrors,
            exhibits: result.validationErrors.exhibits.map((file, fileIndex) =>
              errorIndex === fileIndex ? null : file
            ),
          }
        }

        return result
      }

      return currentState
    }

    case EXHIBIT_SAVE_SUCCESS: {
      const { providerPk, exhibitPk, exhibit } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  exhibits: providerToReplace.exhibits?.map(providerExhibit => {
                    if (
                      providerExhibit.pk === exhibitPk &&
                      (!exhibit.exhibitType || providerExhibit.exhibitType === exhibit.exhibitType)
                    ) {
                      return exhibit
                    }
                    return providerExhibit
                  }),
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case EXHIBIT_SAVE_ERROR: {
      const state = { ...currentState }
      const { validationError, index, providerPk } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace) {
        state.providers = currentState.providers.map(stateProvider =>
          stateProvider === providerToReplace
            ? {
                ...providerToReplace,
                exhibits: providerToReplace.exhibits?.map((providerExhibit, providerIndex) =>
                  index === providerIndex ? { ...providerExhibit, saving: false } : providerExhibit
                ),
              }
            : stateProvider
        )
      }

      // if no validation errors yet create an array of null entries for each fileToUpload
      if (!state.validationErrors.exhibits) {
        const provider = state.providers.find(provider => provider.pk === providerPk)

        state.validationErrors.exhibits = provider?.exhibits?.map(() => null) ?? []
      }

      state.validationErrors = {
        ...state.validationErrors,
        exhibits: state.validationErrors.exhibits.map((file, fileIndex) =>
          index === fileIndex ? validationError : file
        ),
      }

      return state
    }

    case SORT_PROVIDERS: {
      const state = { ...currentState }
      const providers = [...state.providers].sort(
        (a, b) => Number(new Date(a.first_contact ?? "9999")) - Number(new Date(b.first_contact ?? "9999"))
      )

      state.providers = providers

      return state
    }

    case ADD_BILL: {
      const { providerId } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerId)

      if (providerToReplace) {
        const newBill = { formId: v4(), description: "", billed_amount: "0", selected: false }

        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  bills: providerToReplace.bills ? [...providerToReplace.bills, newBill] : [newBill],
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case UPDATE_BILL: {
      const { providerId, billId, updates } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerId)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  bills: providerToReplace.bills?.map(bill =>
                    bill.pk === billId || bill.formId === billId ? { ...bill, ...updates } : bill
                  ),
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case BILL_EXHIBIT_CHANGE: {
      const { bill, index, type, id, providerId } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerId)

      if (providerToReplace) {
        const prevBills = providerToReplace?.bills || []
        const newBills = prevBills.map((currentBill, billIndex) =>
          index === billIndex
            ? {
                ...bill,
                exhibit_id: null,
                file_to_upload_id: null,
                partition_id: null,
                [type]: id,
              }
            : currentBill
        )

        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  bills: newBills,
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case DELETE_BILL: {
      const { providerId, billId } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerId)

      if (providerToReplace) {
        let errorIndex: Nullable<number> = null

        const result = {
          ...currentState,
          providers: currentState.providers.map(stateProvider => {
            if (stateProvider === providerToReplace) {
              return {
                ...providerToReplace,
                bills: providerToReplace.bills?.filter((bill, index) => {
                  if (bill.pk === billId || bill.formId === billId) {
                    errorIndex = index
                    return false
                  }
                  return true
                }),
              }
            }
            return stateProvider
          }),
        }

        if (!isNull(errorIndex) && result.validationErrors.bills) {
          const bills = [...result.validationErrors.bills]

          bills.splice(errorIndex, 1)

          result.validationErrors = { ...result.validationErrors, bills }
        }

        return result
      }

      return currentState
    }

    case APPEND_EXHIBIT: {
      const { providerId, exhibit } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerId)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? { ...providerToReplace, exhibits: [...(providerToReplace.exhibits ?? []), exhibit] }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case DELETE_PARTITION: {
      const { providerPk, partitionPk } = action.payload
      const providerToReplace = currentState.providers.find(stateProvider => stateProvider.pk === providerPk)

      if (providerToReplace) {
        return {
          ...currentState,
          providers: currentState.providers.map(stateProvider =>
            stateProvider === providerToReplace
              ? {
                  ...providerToReplace,
                  bills: providerToReplace.bills?.map(bill =>
                    bill.partition_id === partitionPk
                      ? { ...bill, partition_id: null, partition: null }
                      : bill
                  ),
                  exhibits: providerToReplace.exhibits?.filter(
                    exhibit =>
                      exhibit.pk !== partitionPk ||
                      (exhibit.exhibitType && exhibit.exhibitType !== PARTITIONED_EXHIBIT)
                  ),
                }
              : stateProvider
          ),
        }
      }

      return currentState
    }

    case ADD_OBJECTIVE_TEST: {
      const { providerId, objectiveTest } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => pk === providerId)

      if (!providerToReplace) return currentState

      return {
        ...currentState,
        providers: currentState.providers.map(stateProvider =>
          stateProvider === providerToReplace
            ? {
                ...providerToReplace,
                objective_tests: providerToReplace.objective_tests
                  ? [...providerToReplace.objective_tests, objectiveTest]
                  : [objectiveTest],
              }
            : stateProvider
        ),
      }
    }

    case UPDATE_OBJECTIVE_TEST: {
      const { providerId, objectiveTestId, updates } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => pk === providerId)

      if (!providerToReplace) return currentState

      return {
        ...currentState,
        providers: currentState.providers.map(stateProvider =>
          stateProvider === providerToReplace
            ? {
                ...providerToReplace,
                objective_tests: providerToReplace.objective_tests?.map(objectiveTest =>
                  objectiveTest.id === objectiveTestId
                    ? {
                        ...objectiveTest,
                        description: updates.description ?? null,
                        date: updates.date ?? null,
                        findings: updates.findings ?? [],
                      }
                    : objectiveTest
                ),
              }
            : stateProvider
        ),
      }
    }

    case DELETE_OBJECTIVE_TEST: {
      const { providerId, objectiveTestId } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => pk === providerId)

      if (!providerToReplace) return currentState

      return {
        ...currentState,
        providers: currentState.providers.map(stateProvider => {
          if (stateProvider === providerToReplace) {
            return {
              ...providerToReplace,
              objective_tests: providerToReplace.objective_tests?.filter(({ id }) => id !== objectiveTestId),
            }
          }
          return stateProvider
        }),
      }
    }

    case ADD_OBJECTIVE_TEST_FINDING: {
      const { providerId, objectiveTestId, finding } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => pk === providerId)

      if (!providerToReplace) return currentState

      return {
        ...currentState,
        providers: currentState.providers.map(stateProvider => {
          if (stateProvider.pk !== providerToReplace.pk) return stateProvider

          return {
            ...providerToReplace,
            objective_tests: providerToReplace.objective_tests?.map(objectiveTest =>
              objectiveTest.id === objectiveTestId
                ? {
                    ...objectiveTest,
                    findings: objectiveTest.findings ? [...objectiveTest.findings, finding] : [finding],
                  }
                : objectiveTest
            ),
          }
        }),
      }
    }

    case UPDATE_OBJECTIVE_TEST_FINDING: {
      const { providerId, objectiveTestId, findingId, updates } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => pk === providerId)

      if (!providerToReplace) return currentState

      return {
        ...currentState,
        providers: currentState.providers.map(stateProvider => {
          if (stateProvider.pk !== providerToReplace.pk) return stateProvider

          return {
            ...providerToReplace,
            objective_tests: providerToReplace.objective_tests?.map(objectiveTest =>
              objectiveTest.id === objectiveTestId
                ? {
                    ...objectiveTest,
                    findings: objectiveTest.findings?.map(finding =>
                      finding.id === findingId ? { ...finding, ...updates } : finding
                    ),
                  }
                : objectiveTest
            ),
          }
        }),
      }
    }

    case ADD_INTERVENTIONAL_TREATMENT: {
      const { providerId, interventionalTreatment } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => pk === providerId)

      if (!providerToReplace) return currentState

      return {
        ...currentState,
        providers: currentState.providers.map(stateProvider =>
          stateProvider === providerToReplace
            ? {
                ...providerToReplace,
                interventional_treatments: providerToReplace.interventional_treatments
                  ? [...providerToReplace.interventional_treatments, interventionalTreatment]
                  : [interventionalTreatment],
              }
            : stateProvider
        ),
      }
    }

    case UPDATE_INTERVENTIONAL_TREATMENT: {
      const { providerId, interventionalTreatmentId, updates } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => pk === providerId)

      if (!providerToReplace) return currentState

      return {
        ...currentState,
        providers: currentState.providers.map(stateProvider =>
          stateProvider === providerToReplace
            ? {
                ...providerToReplace,
                interventional_treatments: providerToReplace.interventional_treatments?.map(
                  interventionalTreatment =>
                    interventionalTreatment.id === interventionalTreatmentId
                      ? {
                          ...interventionalTreatment,
                          treatment: updates.treatment ?? null,
                          date: updates.date ?? null,
                          referencePages: updates.referencePages ?? [],
                        }
                      : interventionalTreatment
                ),
              }
            : stateProvider
        ),
      }
    }

    case DELETE_INTERVENTIONAL_TREATMENT: {
      const { providerId, interventionalTreatmentId } = action.payload
      const providerToReplace = currentState.providers.find(({ pk }) => pk === providerId)

      if (!providerToReplace) return currentState

      return {
        ...currentState,
        providers: currentState.providers.map(stateProvider => {
          if (stateProvider === providerToReplace) {
            return {
              ...providerToReplace,
              interventional_treatments: providerToReplace.interventional_treatments?.filter(
                ({ id }) => id !== interventionalTreatmentId
              ),
            }
          }
          return stateProvider
        }),
      }
    }

    case SELECT_ALL_BILLS: {
      const { providerId } = action.payload
      const selectedProvider = currentState.providers.find(stateProvider => stateProvider.pk === providerId)

      if (!selectedProvider) return currentState

      const result = {
        ...currentState,
        providers: currentState.providers.map(stateProvider => {
          if (stateProvider === selectedProvider) {
            return {
              ...selectedProvider,
              bills: selectedProvider.bills?.map(bill => {
                return {
                  ...bill,
                  selected: true,
                }
              }),
            }
          }
          return stateProvider
        }),
      }

      return result
    }

    case UNSELECT_ALL_BILLS: {
      const { providerId } = action.payload
      const selectedProvider = currentState.providers.find(stateProvider => stateProvider.pk === providerId)

      if (!selectedProvider) return currentState

      const result = {
        ...currentState,
        providers: currentState.providers.map(stateProvider => {
          if (stateProvider === selectedProvider) {
            return {
              ...selectedProvider,
              bills: selectedProvider.bills?.map(bill => {
                return {
                  ...bill,
                  selected: false,
                }
              }),
            }
          }
          return stateProvider
        }),
      }

      return result
    }

    case DELETE_SELECTED_BILLS: {
      const { providerId } = action.payload
      const selectedProvider = currentState.providers.find(stateProvider => stateProvider.pk === providerId)

      if (!selectedProvider) return currentState

      const result = {
        ...currentState,
        providers: currentState.providers.map(stateProvider => {
          if (stateProvider === selectedProvider) {
            return {
              ...selectedProvider,
              bills: selectedProvider.bills?.filter(bill => !bill.selected),
            }
          }
          return stateProvider
        }),
      }

      return result
    }
  }
}

interface BasePayloadAction<T> {
  type: string
  payload: T
}

export interface SetProvidersAction
  extends BasePayloadAction<{ providers: Provider[]; plaintiffId?: PrimaryKey }> {
  type: typeof SET_PROVIDERS
}
export interface SetInitialProvidersAction
  extends BasePayloadAction<{
    providers: ProviderDto[]
    caseId: string
  }> {
  type: typeof SET_INITIAL_PROVIDERS
}
export interface UpdateProviderAction extends BasePayloadAction<{ newProvider: ProviderDto }> {
  type: typeof UPDATE_PROVIDER
}
export interface AddProviderAction extends BasePayloadAction<{ provider: ProviderDto; caseId: string }> {
  type: typeof ADD_PROVIDER
}
export interface UpdateProviderNameAction
  extends BasePayloadAction<{ name: string; providerId: PrimaryKey }> {
  type: typeof UPDATE_PROVIDER_NAME
}
export interface UpdateProviderFieldAction
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extends BasePayloadAction<{ field: keyof Provider; value: any; providerId: PrimaryKey }> {
  type: typeof UPDATE_PROVIDER_FIELD
}
export interface UpdateIncludeTableAction
  extends BasePayloadAction<{ includeTable: boolean; providerId: PrimaryKey }> {
  type: typeof UPDATE_INCLUDE_TABLE
}
export interface UpdateFirstContactAction
  extends BasePayloadAction<{ firstContact: Nullable<string>; providerId: PrimaryKey }> {
  type: typeof UPDATE_FIRST_CONTACT
}
export interface UpdateVisitCountAction
  extends BasePayloadAction<{ visitCount: Nullable<number>; providerId: PrimaryKey }> {
  type: typeof UPDATE_VISIT_COUNT
}
export interface UpdateOngoingAppointmentAction
  extends BasePayloadAction<{ ongoingAppointment: boolean; providerId: PrimaryKey }> {
  type: typeof UPDATE_ONGOING_APPOINTMENT
}
export interface UpdateLastContactAction
  extends BasePayloadAction<{ lastContact: Nullable<string>; providerId: PrimaryKey }> {
  type: typeof UPDATE_LAST_CONTACT
}
export interface UpdateTemplate
  extends BasePayloadAction<{ providerId: PrimaryKey; template: ProviderTemplatedSectionDto }> {
  type: typeof UPDATE_TEMPLATE
}
export interface UpdateIcdCodesAction
  extends BasePayloadAction<{ icdCodes: CaseIcdCode[]; providerId: PrimaryKey }> {
  type: typeof UPDATE_ICD_CODES
}
export interface UpdateCptCodesAction
  extends BasePayloadAction<{ cptCodes: CaseCptCode[]; providerId: PrimaryKey }> {
  type: typeof UPDATE_CPT_CODES
}
export interface UpdateInjuryDetailsAction
  extends BasePayloadAction<{
    value: Nullable<EditorRoot>
    customContent: Nullable<EditorRoot>
    providerId: PrimaryKey
  }> {
  type: typeof UPDATE_INJURY_DETAILS
}
export interface ToggleOpenAction extends BasePayloadAction<{ id: ID } & ProviderEventLog> {
  type: typeof TOGGLE_OPEN
}
export interface SetEditingAction
  extends BasePayloadAction<{ id: ID; caseId: number } & Omit<ProviderEventLog, "demand_id">> {
  type: typeof SET_EDITING
}
export interface BillExhibitChangeAction
  extends BasePayloadAction<{
    bill: Bill
    index: number
    type: "file_to_upload_id" | "billed_amount" | "exhibit_id" | "partition_id"
    id: number | string
    providerId: ID
  }> {
  type: typeof BILL_EXHIBIT_CHANGE
}
export interface ToggleSavingAction extends BasePayloadAction<{ id: ID }> {
  type: typeof TOGGLE_SAVING
}
export interface SaveInBackgroundAction extends BasePayloadAction<{ provider: ProviderDto }> {
  type: typeof SAVE_IN_BACKGROUND
}

interface ProviderEventLog {
  demand_id: string
  request_id?: string
  request_type?: DOCUMENT_TYPES
  provider_name: string
}

export interface ToggleDeletingAction
  extends BasePayloadAction<
    {
      id: ID
    } & ProviderEventLog
  > {
  type: typeof TOGGLE_DELETING
}
export interface ProviderUpdateSuccessAction
  extends BasePayloadAction<{ provider: ProviderDto; caseId: string }> {
  type: typeof PROVIDER_UPDATE_SUCCESS
}
export interface ProviderUpdatePartialSuccessAction
  extends BasePayloadAction<{ provider: Provider; caseId: string }> {
  type: typeof PROVIDER_UPDATE_PARTIAL_SUCCESS
}
export interface SetValidationErrorsAction extends BasePayloadAction<{ validationErrors: ValidationErrors }> {
  type: typeof SET_VALIDATION_ERRORS
}
export interface CloseEditingProviderAction
  extends BasePayloadAction<{ pk: ID; caseId: string } & Omit<ProviderEventLog, "demand_id">> {
  type: typeof CLOSE_EDITING_PROVIDER
}
export interface DeleteProviderAction extends BasePayloadAction<{ pk: number; caseId: string }> {
  type: typeof DELETE_PROVIDER
}
export interface AddFilesToUploadAction extends BasePayloadAction<{ id: ID; files: File[] }> {
  type: typeof ADD_FILES_TO_UPLOAD
}
export interface UpdateFilesToUploadAction
  extends BasePayloadAction<{ id: ID; filesToUpload: FileToUpload[] }> {
  type: typeof UPDATE_FILES_TO_UPLOAD
}
export interface DeleteFileToUploadAction extends BasePayloadAction<{ id: ID; fileFormId: string }> {
  type: typeof DELETE_FILE_TO_UPLOAD
}
export interface SetFileValidationAction
  extends BasePayloadAction<{
    validationError: ExhibitValidationError
    index: number
    providerPk: number
  }> {
  type: typeof SET_FILE_VALIDATION
}
export interface FileUploadStartAction extends BasePayloadAction<{ providerPk: number; formId: string }> {
  type: typeof FILE_UPLOAD_START
}
export interface FileUploadErrorAction
  extends BasePayloadAction<{ providerPk: number; formId: string; error: Error }> {
  type: typeof FILE_UPLOAD_ERROR
}
export interface FileUploadSuccessAction
  extends BasePayloadAction<{ providerPk: number; formId: string; exhibit: Exhibit }> {
  type: typeof FILE_UPLOAD_SUCCESS
}
export interface SetExhibitsAction extends BasePayloadAction<{ providerPk: number; exhibits: Exhibit[] }> {
  type: typeof SET_EXHIBITS
}
export interface DeleteExhibitAction extends BasePayloadAction<{ providerPk: number; exhibitPk: number }> {
  type: typeof DELETE_EXHIBIT
}
export interface UpdateExhibitsAction extends BasePayloadAction<{ providerPk: number; exhibits: Exhibit[] }> {
  type: typeof UPDATE_EXHIBITS
}
export interface ResetExhibitAction
  extends BasePayloadAction<{ providerPk: number; exhibitPk: number; exhibit: Exhibit }> {
  type: typeof RESET_EXHIBIT
}
export interface ExhibitSaveStartAction
  extends BasePayloadAction<{ providerPk: number; exhibitPk: number; exhibitType: string }> {
  type: typeof EXHIBIT_SAVE_START
}
export interface ExhibitSaveSuccessAction
  extends BasePayloadAction<{
    providerPk: number
    exhibitPk: number
    exhibit: Exhibit
    exhibitType: string
  }> {
  type: typeof EXHIBIT_SAVE_SUCCESS
}
export interface ExhibitSaveErrorAction
  extends BasePayloadAction<{ providerPk: number; index: number; validationError: ExhibitValidationError }> {
  type: typeof EXHIBIT_SAVE_ERROR
}
export interface SetExhibitEditingAction
  extends BasePayloadAction<{
    providerPk: number
    exhibitPk: number
    editing: boolean
    exhibitType: string
  }> {
  type: typeof SET_EXHIBIT_EDITING
}
export interface SortProvidersAction {
  type: typeof SORT_PROVIDERS
}
export interface AddBillAction extends BasePayloadAction<{ providerId: ID }> {
  type: typeof ADD_BILL
}
export interface UpdateBillAction
  extends BasePayloadAction<{ providerId: ID; billId: ID; updates: Partial<Bill> }> {
  type: typeof UPDATE_BILL
}
export interface DeleteBillAction extends BasePayloadAction<{ providerId: ID; billId: ID }> {
  type: typeof DELETE_BILL
}
export interface AppendExhibitAction extends BasePayloadAction<{ providerId: ID; exhibit: Exhibit }> {
  type: typeof APPEND_EXHIBIT
}
export interface DeletePartitionAction
  extends BasePayloadAction<{ providerId: ID; providerPk: number; partitionPk: number; exhibitPk: number }> {
  type: typeof DELETE_PARTITION
}
export interface AddObjectiveTestAction
  extends BasePayloadAction<{ providerId: ID; objectiveTest: ObjectiveTest }> {
  type: typeof ADD_OBJECTIVE_TEST
}
export interface UpdateObjectiveTestAction
  extends BasePayloadAction<{ providerId: ID; objectiveTestId: ID; updates: Partial<ObjectiveTest> }> {
  type: typeof UPDATE_OBJECTIVE_TEST
}
export interface DeleteObjectiveTestAction
  extends BasePayloadAction<{ providerId: ID; objectiveTestId: ID }> {
  type: typeof DELETE_OBJECTIVE_TEST
}
export interface SelectAllBillsAction extends BasePayloadAction<{ providerId: ID }> {
  type: typeof SELECT_ALL_BILLS
}
export interface UnselectAllBillsAction extends BasePayloadAction<{ providerId: ID }> {
  type: typeof UNSELECT_ALL_BILLS
}
export interface DeleteSelectedBillsAction extends BasePayloadAction<{ providerId: ID }> {
  type: typeof DELETE_SELECTED_BILLS
}

export interface AddAddObjectiveTestFindingAction
  extends BasePayloadAction<{ providerId: ID; objectiveTestId: ID; finding: ObjectiveTestFinding }> {
  type: typeof ADD_OBJECTIVE_TEST_FINDING
}

export interface UpdateObjectiveTestFindingAction
  extends BasePayloadAction<{
    providerId: ID
    objectiveTestId: ID
    findingId: ID
    updates: Partial<ObjectiveTestFinding>
  }> {
  type: typeof UPDATE_OBJECTIVE_TEST_FINDING
}

export interface AddInterventionalTreatmentAction
  extends BasePayloadAction<{ providerId: ID; interventionalTreatment: InterventionalTreatment }> {
  type: typeof ADD_INTERVENTIONAL_TREATMENT
}
export interface UpdateInterventionalTreatmentAction
  extends BasePayloadAction<{
    providerId: ID
    interventionalTreatmentId: ID
    updates: Partial<InterventionalTreatment>
  }> {
  type: typeof UPDATE_INTERVENTIONAL_TREATMENT
}
export interface DeleteInterventionalTreatmentAction
  extends BasePayloadAction<{ providerId: ID; interventionalTreatmentId: ID }> {
  type: typeof DELETE_INTERVENTIONAL_TREATMENT
}

export type Action =
  | SetProvidersAction
  | SetInitialProvidersAction
  | UpdateProviderAction
  | AddProviderAction
  | UpdateProviderNameAction
  | UpdateProviderFieldAction
  | ToggleOpenAction
  | SetEditingAction
  | UpdateIncludeTableAction
  | UpdateFirstContactAction
  | UpdateLastContactAction
  | UpdateVisitCountAction
  | UpdateOngoingAppointmentAction
  | UpdateTemplate
  | UpdateIcdCodesAction
  | UpdateCptCodesAction
  | UpdateInjuryDetailsAction
  | ToggleSavingAction
  | BillExhibitChangeAction
  | ToggleDeletingAction
  | SaveInBackgroundAction
  | ProviderUpdateSuccessAction
  | ProviderUpdatePartialSuccessAction
  | SetValidationErrorsAction
  | CloseEditingProviderAction
  | AddFilesToUploadAction
  | DeleteProviderAction
  | UpdateFilesToUploadAction
  | DeleteFileToUploadAction
  | SetFileValidationAction
  | FileUploadStartAction
  | FileUploadErrorAction
  | FileUploadSuccessAction
  | SetExhibitsAction
  | DeleteExhibitAction
  | UpdateExhibitsAction
  | ResetExhibitAction
  | ExhibitSaveStartAction
  | ExhibitSaveSuccessAction
  | ExhibitSaveErrorAction
  | SetExhibitEditingAction
  | SortProvidersAction
  | AddBillAction
  | UpdateBillAction
  | DeleteBillAction
  | AppendExhibitAction
  | DeletePartitionAction
  | AddObjectiveTestAction
  | UpdateObjectiveTestAction
  | DeleteObjectiveTestAction
  | AddAddObjectiveTestFindingAction
  | UpdateObjectiveTestFindingAction
  | AddInterventionalTreatmentAction
  | UpdateInterventionalTreatmentAction
  | DeleteInterventionalTreatmentAction
  | SelectAllBillsAction
  | UnselectAllBillsAction
  | DeleteSelectedBillsAction
