import { useCallback, useContext } from "react"
import { LibraryTabStateContext } from "../State/constants"
import { useAwaitAction } from "hooks/useAwaitAction"

import {
  createCollapseTemplateAction,
  createExpandTemplateAction,
  createStartEditTemplateAction,
} from "../State/templatesActions"
import {
  StyledTableListItem,
  StyledEditWrapper,
  StyledListItemButton,
  StyledExpandArrow,
  StyledCancelButton,
  StyledSaveButton,
} from "./styled"
import { LibraryRowButtons } from "./types"
import { LIBRARY_ROW_BUTTON_NAMES } from "./constants"

interface LibraryTableListItemButtonsProps {
  handleCancel: () => void
  handleSave: () => void
  handleDuplicate: () => void
  rowId: string
  canDuplicate: boolean
  canEdit: boolean
  rowButtons: LibraryRowButtons
  additionalButtons?: Nullable<JSX.Element>
  runningAction?: Nullable<Promise<boolean>>
}

export function LibraryTableListItemButtons({
  handleCancel,
  handleSave,
  rowId,
  handleDuplicate,
  canDuplicate,
  canEdit,
  rowButtons,
  runningAction,
  additionalButtons,
}: LibraryTableListItemButtonsProps): JSX.Element {
  const { state, dispatch } = useContext(LibraryTabStateContext)
  const [loading, saveHandler] = useAwaitAction(handleSave, runningAction)
  const disabled = !!(state.creatingData || state.editingRowId)
  const editing = state.editingRowId === rowId
  const expanded = state.expandedRowIds.includes(rowId)
  const showDetails = editing || expanded

  const handleExpand = useCallback(() => {
    if (editing) return

    const actionCreator = expanded ? createCollapseTemplateAction : createExpandTemplateAction

    dispatch(actionCreator({ templateId: rowId }))
  }, [dispatch, expanded, rowId, editing])

  const handleStartEditing = useCallback(() => {
    dispatch(createStartEditTemplateAction({ templateId: rowId }))
  }, [dispatch, rowId])

  const { title: actionButtonTitle, ...actionButtonProps } = rowButtons[LIBRARY_ROW_BUTTON_NAMES.ACTION]
  const { title: editButtonTitle, ...editButtonProps } = rowButtons[LIBRARY_ROW_BUTTON_NAMES.EDIT]

  return (
    <StyledTableListItem>
      {additionalButtons}
      {canEdit && editing ? (
        <>
          <StyledCancelButton onClick={handleCancel}>
            {rowButtons[LIBRARY_ROW_BUTTON_NAMES.CANCEL].title}
          </StyledCancelButton>
          <StyledSaveButton loading={loading} onClick={saveHandler} {...actionButtonProps}>
            {actionButtonTitle}
          </StyledSaveButton>
        </>
      ) : (
        <StyledEditWrapper>
          {canEdit && (
            <StyledListItemButton onClick={handleStartEditing} disabled={disabled} {...editButtonProps}>
              {editButtonTitle}
            </StyledListItemButton>
          )}
          {canDuplicate && (
            <StyledListItemButton onClick={handleDuplicate} disabled={disabled}>
              {rowButtons[LIBRARY_ROW_BUTTON_NAMES.DUPLICATE].title}
            </StyledListItemButton>
          )}
          <StyledExpandArrow expanded={showDetails} onClick={handleExpand} />
        </StyledEditWrapper>
      )}
    </StyledTableListItem>
  )
}
