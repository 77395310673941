import { fetchLawFirmConfig, getFirmAttorneys, setAttorney } from "api"
import { useCallback, useMemo, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { AutocompleteInput, AutocompleteInputValue } from "common/form-components/AutocompleteInput"
import { STEP_STATUSES } from "demand/constants"
import { useFormContext } from "demand/context"
import { Attorney } from "settings/Firm/Firm"
import { LawFirmTitle, StyledLawFirmWrapper } from "./styled"
import { Divider } from "@mui/material"
import { CASE_STEP } from "demand/steps/enums"
import { queryClient } from "react-query/queryClient"
import { isNull } from "lodash"
import { FirmSelect } from "common/attributes-filter/FirmSelect"

const getFullAttorneyName = (attorney: Attorney) => `${attorney.first_name} ${attorney.last_name}`

export function LawFirm(): JSX.Element {
  const { caseId, setSavedSuccessfully, showErrorMessage, handleUpdateStepStatus } = useFormContext()
  const [firmId, setFirmId] = useState<Nullable<PrimaryKey>>(null)
  const [attorneyId, setAttorneyId] = useState<Nullable<PrimaryKey>>(null)
  const { data: attorneys, isFetching: isLoadingAttorneys } = useQuery<Attorney[]>(
    [queryKeys.attorneys, firmId],
    getFirmAttorneys,
    { enabled: !isNull(firmId) }
  )

  useQuery([queryKeys.lawFirmConfig, caseId], async () => {
    const data = await fetchLawFirmConfig(caseId)

    if (data?.firm?.pk) setFirmId(data.firm.pk)
    if (data?.attorney?.pk) setAttorneyId(data.attorney.pk)

    return data
  })

  const setAttorneyMutation = useMutation(setAttorney, {
    onSuccess: data => {
      if (data?.firm?.pk) setFirmId(data.firm.pk)
      if (data?.attorney?.pk) setAttorneyId(data.attorney.pk)

      setSavedSuccessfully(true)
      handleUpdateStepStatus({ status: STEP_STATUSES.completed, step: CASE_STEP.FIRM })

      queryClient.invalidateQueries([queryKeys.lawFirmConfig])
    },
    onError: showErrorMessage,
  })

  const attorneyOptions = useMemo(() => {
    if (isLoadingAttorneys || !attorneys || isNull(firmId)) return []

    return attorneys.map(attorney => ({ key: attorney.pk, display: getFullAttorneyName(attorney) }))
  }, [isLoadingAttorneys, attorneys, firmId])

  const handleChangeFirm = useCallback(
    (id: AutocompleteInputValue) => {
      setAttorneyId(null)

      if (!isNull(id)) {
        setFirmId(Number(id))

        setAttorneyMutation.mutate({ caseId, data: { attorney_id: null, firm_id: Number(id) } })
      }
    },
    [setAttorneyMutation, caseId]
  )

  const handleChangeAttorney = useCallback(
    (id: AutocompleteInputValue) => {
      if (!isNull(id)) {
        setAttorneyId(Number(id))

        setAttorneyMutation.mutate({ caseId, data: { attorney_id: Number(id), firm_id: firmId } })
      }
    },
    [caseId, firmId, setAttorneyMutation]
  )

  return (
    <>
      <LawFirmTitle>Law Firm Information</LawFirmTitle>
      <StyledLawFirmWrapper>
        <FirmSelect firmId={firmId} setFirmId={handleChangeFirm} />
        <AutocompleteInput
          label="Select Attorney"
          options={attorneyOptions}
          onChange={handleChangeAttorney}
          value={attorneyId}
          disabled={!attorneyOptions.length || isNull(firmId)}
          dataTest="select-attorney"
        />
      </StyledLawFirmWrapper>
      <Divider />
    </>
  )
}
