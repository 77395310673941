import { ROOT_ITEM_KEY, ITEM_REF } from "../constants"
import {
  ItemType,
  ItemTypeWithChildren,
  ProjectionData,
  ProjectionItemData,
  ProjectionItemWithChildren,
  RootProjectionItem,
} from "../types"

export function isRootItem<T>(item: T | RootProjectionItem<T>): item is RootProjectionItem<T> {
  return (item as RootProjectionItem<T>).id === ROOT_ITEM_KEY
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getItemProjection<T extends Record<string, any>>(
  item: T | RootProjectionItem<T>,
  index: number,
  parent: Nullable<ProjectionItemData<T>>,
  projection: ProjectionData<T>,
  uniqueKey: Exclude<keyof T, "children">
): ProjectionData<T> {
  const itemId = String(isRootItem(item) ? ROOT_ITEM_KEY : item[uniqueKey] || `${parent?.id}_${index}`)
  const projectedItem = {
    ...item,
    id: itemId,
    children: [],
    [ITEM_REF]: item,
  } as unknown as ProjectionItemData<T>

  if (parent) {
    parent.children.push(itemId)
  }

  projection[itemId] = projectedItem

  if (!item.children) {
    return projection
  }

  return (item.children as ItemType<T>[]).reduce(
    (data, child, childIndex) => getItemProjection(child as T, childIndex, projectedItem, data, uniqueKey),
    projection
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFlatDataProjection<T extends Record<string, any>, K extends keyof T>(
  data: T[],
  uniqueKey: Exclude<K, "children">
): ProjectionData<T> {
  const rootItem = { [uniqueKey]: ROOT_ITEM_KEY, children: data } as RootProjectionItem<T>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const projection = {} as any as ProjectionData<T>
  return getItemProjection(rootItem, 0, null, projection, uniqueKey)
}

export function getItemFromProjection<T>(
  projectedData: ProjectionData<T>,
  itemId: string
): ItemType<T> | RootProjectionItem<T> {
  const projectedItem = projectedData[itemId]
  const item = projectedItem[ITEM_REF] as ItemTypeWithChildren<T>
  item.children = projectedItem.children.map(childId =>
    getItemFromProjection(projectedData, childId)
  ) as ItemTypeWithChildren<T>[]

  return item
}

export function getDataFromProjection<T>(projectedData: ProjectionData<T>): ItemType<T>[] {
  return projectedData[ROOT_ITEM_KEY].children.map(childId =>
    getItemFromProjection(projectedData, childId)
  ) as ItemType<T>[] // Virtual root item is removed here, so typecast is safe
}

export function normalizeItemFromProjection<T>(
  projectedData: ProjectionData<T>,
  itemId: string
): ProjectionItemWithChildren<T> {
  const projectedItem = projectedData[itemId]
  const normalizedItem: ProjectionItemWithChildren<T> = {
    ...projectedData[itemId],
    children: [] as ProjectionItemWithChildren<T>["children"],
  }

  normalizedItem.children = projectedItem.children.map(childId =>
    normalizeItemFromProjection(projectedData, childId)
  ) as ProjectionItemWithChildren<T>["children"]

  return normalizedItem
}

export function normalizeFromProjectionWithRef<T>(
  projectedData: ProjectionData<T>
): ProjectionItemWithChildren<T>[] {
  return projectedData[ROOT_ITEM_KEY].children.map(childId =>
    normalizeItemFromProjection(projectedData, childId)
  )
}

export function findParent<T>(
  data: ProjectionData<T>,
  itemId: string
): Nullable<ProjectionItemData<T> | ProjectionItemData<RootProjectionItem<T>>> {
  const result = Object.values(data).find(item => item.children.includes(itemId))
  return result ?? null
}

export function findFurthestParent<T>(data: ProjectionData<T>, itemId: string): ProjectionItemData<T> {
  const parent = findParent(data, itemId)

  if (!parent || parent.id === ROOT_ITEM_KEY) {
    return data[itemId]
  }

  return findFurthestParent(data, parent.id)
}
