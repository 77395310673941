export enum TEXT_STYLES {
  BOLD = "bold",
  ITALIC = "italic",
  UNDERLINE = "underline",
  HIGHLIGHT = "highlight",
}

export enum TEXT_ALIGN {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
  JUSTIFY = "justify",
}

export interface ELEMENT_STYLES {
  align?: TEXT_ALIGN
}
