import { Box, CardContent } from "@mui/material"
import { SelectableCardProps } from "./types"
import { StyledSelectableCard } from "./styled"
import { LargeSelectableCard } from "./LargeSelectableCard"
import { SmallSelectableCard } from "./SmallSelectableCard"
import CheckIcon from "@mui/icons-material/Check"
import styled from "@emotion/styled"

const SelectedWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "50%",
  backgroundColor: theme.palette.blue.main,
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: theme.spacing(0.5),
  left: theme.spacing(0.5),
  color: "white",
}))

const SelectableCard = ({
  isSelected = false,
  paletteType = "primary",
  title,
  description,
  icon,
  size = "small",
  ...props
}: SelectableCardProps) => {
  return (
    <StyledSelectableCard isSelected={isSelected} paletteType={paletteType} size={size} fullSize {...props}>
      <>
        {isSelected && (
          <SelectedWrapper>
            <CheckIcon />
          </SelectedWrapper>
        )}
        {size === "large" ? (
          <LargeSelectableCard
            title={title}
            description={description}
            icon={icon}
            size={size}
            paletteType={paletteType}
            {...props}
          />
        ) : (
          <SmallSelectableCard
            title={title}
            description={description}
            size={size}
            paletteType={paletteType}
            icon={icon}
            {...props}
          />
        )}
      </>
    </StyledSelectableCard>
  )
}

export default Object.assign(SelectableCard, {
  Content: CardContent,
})
