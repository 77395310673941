import { differenceInYears, parseISO as parseDate, compareDesc as compareDates, format } from "date-fns"
import { isEmpty, isNil } from "lodash"

export function getDate(date: Date | string): Date {
  return date instanceof Date ? date : parseDate(date)
}

export function isDateEarlier(date: Date, dateToCompare: Date): boolean
export function isDateEarlier(date: string, dateToCompare: string): boolean
export function isDateEarlier<T extends Date | string>(date: T, dateToCompare: T): boolean {
  const referenceDate = getDate(date)
  const comparisonDate = getDate(dateToCompare)

  return compareDates(referenceDate, comparisonDate) === 1
}

export function getDifferenceInYears(dateLeft: Date, dateRight: Date): number
export function getDifferenceInYears(dateLeft: string, dateRight: string): number
export function getDifferenceInYears<T extends Date | string>(left: T, right: T): number {
  const dateLeft = getDate(left)
  const dateRight = getDate(right)

  return differenceInYears(dateLeft, dateRight)
}

export const getFormattedDateString = (date: Nullable<string>): string => {
  if (date) {
    const dateObject = new Date(`${date}T00:00`)

    if (dateObject.valueOf()) {
      return format(dateObject, "MM/dd/yyyy")
    }
  }

  return ""
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasValidationIssues(validationSection?: Record<string, any>): boolean {
  if (!validationSection || isEmpty(validationSection)) return false

  for (const [, value] of Object.entries(validationSection)) {
    if (Array.isArray(value) && value.length) {
      return true
    }

    if (!Array.isArray(value) && !isNil(value)) {
      return true
    }

    continue
  }

  return false
}
