import { Alert, AlertTitle, Box } from "@mui/material"
import React from "react"
import { SETTLEMENTS_SUPPORT_EMAIL } from "./constants"

const SettlementsPageError: React.FC = () => {
  return (
    <Box sx={{ maxWidth: "525px", marginTop: "150px", marginX: "auto" }}>
      <Alert severity="error">
        <AlertTitle>Unexpected Error</AlertTitle>
        <Box>
          There was an error loading the page, please try and refresh the page. If your error persists contact{" "}
          <a href={`mailto:${SETTLEMENTS_SUPPORT_EMAIL}`}>{SETTLEMENTS_SUPPORT_EMAIL}</a>
        </Box>
      </Alert>
    </Box>
  )
}

export default SettlementsPageError
