import styled from "@emotion/styled"
import { Box } from "@mui/material"

export const StyledContent = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow:
    "0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
  padding: theme.spacing(2.5),
  zIndex: 10,
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(3.5),
  cursor: "grab",

  ":active": {
    cursor: "grabbing",
  },
}))
