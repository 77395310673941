import TextField from "@mui/material/TextField"
import styled from "@emotion/styled"
import { FormControl } from "@mui/base"

export const StyledTextField = styled(TextField)(() => ({
  border: "none",
}))

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  border: "none",
  padding: 0,
  margin: theme.spacing(1, 0, 1, 0),
}))
