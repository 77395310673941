import { useContext, useEffect, useMemo, useRef } from "react"
import useUser from "hooks/useUser"
import invariant from "invariant"
import { FeaturePermissions } from "./types"
import { isNil, isUndefined } from "lodash"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { STALE_TIMEOUT, queryKeys } from "react-query/constants"
import { permissionService } from "api/services/permissions"
import { PermissionServiceDeserializer } from "api/services/permissions/serializers"
import { PermissionFetcherContext } from "./PermissionFetcherContext"
import { retryOrProvideDefaultPermissions } from "./utils"

const INITIAL_PERMISSIONS = PermissionServiceDeserializer.fromJSON({})

type FirmIdArg = string | number | null | undefined

function getFirmId(firmId: FirmIdArg, userFirmId: FirmIdArg): Nullable<string> {
  if (firmId === null) return null
  if (!isUndefined(firmId)) return String(firmId)

  if (!isNil(userFirmId)) return String(userFirmId)

  return null
}

type UseFeaturePermissionsArgs = {
  firmId: FirmIdArg
  suspense: boolean
  enabled?: boolean
}

// Usage:
// const { createBasicPlusEnabled } = usePermissions()
export function usePermissions({
  firmId: firmIdParam,
  // there are some edge cases where suspense and enabled will conflict with each other
  // if you are using both, make sure you double check that they are working as expected
  suspense = true,
  enabled = true,
}: Partial<UseFeaturePermissionsArgs> = {}): FeaturePermissions {
  const { user, isLoading } = useUser({ suspense })

  // in suspense mode react query gets values in query function from closure
  // it leads to userId being -1 on the first load
  const userId = useRef(user.id)
  userId.current = user.id

  const queryClient = useQueryClient()

  invariant(enabled ? user.isAuthorized || isLoading : true, "User must be authorized to use permissions")

  const firmId = getFirmId(firmIdParam, user.firmId)
  const { subscribe, unsubscribe, subscribeFirmId, unsubscribeFirmId } = useContext(PermissionFetcherContext)

  const { data: fetchedPermissions = INITIAL_PERMISSIONS } = useQuery(
    [queryKeys.allPermissions, firmId ?? ""],
    async () => permissionService.checkPermissions({ firmId, userId: userId.current }),
    {
      suspense: suspense,
      staleTime: STALE_TIMEOUT.SHORT,
      keepPreviousData: true,
      // allow disabling of query in the case where we load permissions and user from the same component
      // also disable when user is still loading, mostly happens in test env when if useUser and usePermission are called in the same component
      enabled: !isLoading && enabled,
      meta: { disableLoader: true },
      retry: retryOrProvideDefaultPermissions(queryClient, [queryKeys.allPermissions, firmId ?? ""]),
      useErrorBoundary: () => false,
    }
  )

  const fetchedPermissionsRef = useRef(fetchedPermissions)
  fetchedPermissionsRef.current = fetchedPermissions
  const usedPermissions = useMemo<Set<keyof FeaturePermissions>>(() => new Set(), [])
  const actualPermissions = useRef(fetchedPermissions)
  const isChanged = Array.from(usedPermissions).some(
    permission => fetchedPermissions[permission] !== actualPermissions.current[permission]
  )

  if (isChanged) {
    actualPermissions.current = fetchedPermissions
  }

  const permissions = actualPermissions.current

  // Proxy reference changes only in case values of currently tracked features changed
  // E.g. usage
  // - const { createBasicPlusEnabled } = usePermissions()
  // - a different feature's value changes
  // - proxy object remains unchanged
  // - untracked features still return actual values if needed to have value inside callback
  //
  // const permissions = usePermissions()
  // const { createBasicPlusEnabled } = permissions // createBasicPlusEnabled become tracked
  // const handleChange = useCallback(() => {
  //   ...
  //   // in the next line `expensiveRequestApprovalEnabled` become tracked
  //   permissions.expensiveRequestApprovalEnabled && onChange()
  // })
  const proxy = useMemo(
    () =>
      new Proxy(permissions, {
        get(target, prop, receiver) {
          if (!(prop in target)) {
            return Reflect.get(target, prop, receiver)
          }

          const feature = prop as keyof FeaturePermissions

          if (!usedPermissions.has(feature)) {
            usedPermissions.add(feature)
            Reflect.set(target, prop, fetchedPermissionsRef.current[feature], receiver)
          }

          return Reflect.get(target, prop, receiver)
        },
      }),
    [usedPermissions, permissions]
  )

  useEffect(() => {
    if (firmId) {
      subscribeFirmId(firmId)
      return () => unsubscribeFirmId(firmId)
    } else {
      subscribe()
      return () => unsubscribe()
    }
  }, [firmId, subscribe, unsubscribe, subscribeFirmId, unsubscribeFirmId])

  return proxy
}

export function useUserPermissions({
  suspense = true,
}: Partial<Omit<UseFeaturePermissionsArgs, "firmId">> = {}): FeaturePermissions {
  return usePermissions({ firmId: null, suspense })
}

export function useFirmPermissions({
  firmId,
  suspense = true,
}: Partial<Omit<UseFeaturePermissionsArgs, "firmId">> & { firmId: string | number }): FeaturePermissions {
  return usePermissions({ firmId: firmId, suspense })
}
