import { RenderChipProps } from "../types"
import { StyledInjuryImpactKeyWrapper } from "../styled"
import { Chip } from "@mui/material"
import { useRequestAssets } from "requests/RequestAssets"

export const NonEconomicDamagesChip = ({ plaintiff, nonEconomicDamageKey, assetKey }: RenderChipProps) => {
  const nonEconomicDamage = plaintiff[nonEconomicDamageKey] as string[]
  const assets = useRequestAssets()

  if (!nonEconomicDamage || !Array.isArray(nonEconomicDamage)) return

  return (
    <StyledInjuryImpactKeyWrapper>
      {nonEconomicDamage.map((key, index) => (
        <Chip
          key={index}
          label={assets[assetKey]?.find(({ key: assetKey }) => assetKey === key)?.display ?? ""}
        />
      ))}
    </StyledInjuryImpactKeyWrapper>
  )
}
