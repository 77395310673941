export const DEFAULT_PAGE_SIZE = 10

export const MAX_PAGES_TO_SHOW = 5

export interface PaginatedListFields {
  readonly pageCount: number
  readonly count: number
  readonly page: number
}

export class PaginatedList<T> implements PaginatedListFields {
  readonly pageCount: number

  constructor(
    readonly items: T[],
    readonly count: number,
    readonly pageSize: number = DEFAULT_PAGE_SIZE,
    readonly page: number = 1
  ) {
    this.pageCount = Math.ceil(count / pageSize)
  }
}
