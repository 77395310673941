import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Alert, Box, Divider, Grid, IconButton } from "@mui/material"
import { makeStyles } from "tss-react/mui"
import { Close } from "@mui/icons-material"

import { Generator, GeneratorField, FIELD_TYPES, Summary } from "api/services/summaries/types"
import { summariesApiService, summariesGeneratorApiService } from "api/services/summaries"
import { useQuery, useMutation } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"

import { useHandleMessages } from "common/messages/useHandleMessages"
import { Controller, useForm } from "react-hook-form"

import {
  GenerateButton,
  InputContainer,
  SectionHeader,
  StyledInputField,
  StyledSelectField,
} from "summaries/styled"

import { DateField } from "common/form-components"
import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { GeneratorSelector } from "./GeneratorSelector"
import { SummaryResult } from "summaries/Summary/SummaryResult"
import { ResultLoading } from "summaries/shared/ResultLoading"
import { HelpTextAlert } from "summaries/Summary/styled"

const useStyles = makeStyles()(() => ({
  dateField: {
    width: "-webkit-fill-available",
    margin: "0",
    "& > .MuiInputBase-root": {
      borderRadius: "8px",
    },
  },
}))

const SUMMARY_MAX = 30000

interface SummaryFormProps {
  summary?: Nullable<Summary>
  caseId?: Nullable<PrimaryKey> | undefined
  matterId?: Nullable<string> | undefined
  onAddToDocumentClick?: (summary: Summary, replace?: boolean, existingSummary?: Summary) => void
  onClose?: () => void
  title?: string
  defaultText?: string
  fieldDefaults?: Map<string, string>
  warningBannerText?: string
  showFeedback?: boolean
  generatorKeys?: string[]
  additionalInfo?: React.ReactNode
}

export const SummaryForm = ({
  onAddToDocumentClick,
  summary: existingSummary,
  caseId,
  matterId,
  title,
  defaultText = "",
  fieldDefaults,
  warningBannerText,
  generatorKeys,
  onClose,
  showFeedback = false,
  additionalInfo,
}: SummaryFormProps): JSX.Element => {
  const { classes } = useStyles()
  const [generator, setGenerator] = useState<Nullable<Generator>>(null)
  const [summary, setSummary] = useState<Nullable<Summary>>(null)
  const [generating, setGenerating] = useState<boolean>(false)
  const [summaryResult, setSummaryResult] = useState<Nullable<EditorRoot>>(null)
  const [filteredGenerators, setFilteredGenerators] = useState<Nullable<Generator[]>>(null)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<Record<string, string>>({
    defaultValues: {},
    mode: "onChange",
  })

  const { showErrorMessage } = useHandleMessages()

  useQuery<Generator[]>(
    [queryKeys.generators, caseId],
    () => summariesGeneratorApiService.getGenerators({ caseId: caseId, matterId: matterId }),
    {
      retry: false,
      enabled: !!existingSummary?.generatorKey,
      onSuccess: data => {
        if (generatorKeys) {
          setFilteredGenerators(data.filter(generator => generatorKeys.includes(generator.generatorKey)))
        } else {
          setFilteredGenerators(data)
        }
      },
      meta: {
        disableLoader: true,
      },
    }
  )

  useEffect(() => {
    if (!existingSummary || !filteredGenerators) return

    const selectedGenerator: Nullable<Generator> =
      filteredGenerators?.find(
        (generator: Generator) => generator.generatorKey === existingSummary.generatorKey
      ) ?? null

    if (!selectedGenerator) return

    selectedGenerator.inputs.forEach(generatorField => {
      const existingField = existingSummary.fields.find(field => field.field === generatorField.fieldId)
      if (!existingField) return
      setValue(generatorField.fieldId, existingField.value ?? null)
    })

    setSummary(existingSummary)
    setGenerator(selectedGenerator)
  }, [existingSummary, filteredGenerators, setValue])

  const handleGeneratorChange = (generatorField: Generator) => {
    if (!generatorField) return

    // Set default values on generator select
    generatorField.inputs.forEach(field => {
      const { defaultValue, fieldType, fieldId } = field
      if (defaultValue) {
        setValue(fieldId, defaultValue)
      } else if (fieldDefaults?.has(fieldId)) {
        setValue(fieldId, fieldDefaults?.get(fieldId) ?? "")
      }
      if (fieldType === FIELD_TYPES.TEXT) {
        setValue(fieldId, defaultValue ?? defaultText)
      }
    })
    setGenerator(generatorField)
  }

  const generatorKey: Nullable<string> = useMemo(() => {
    if (!generator) return null
    return generator.generatorKey
  }, [generator])

  const inputs: GeneratorField[] = useMemo(() => {
    if (!generator) return []
    return generator?.inputs
  }, [generator])

  const isSummaryRegenerated: boolean = useMemo(() => {
    return !!summary && !!existingSummary && summary.pk !== existingSummary.pk
  }, [summary, existingSummary])

  const handleDateChange = useCallback(
    (date: Nullable<string>, field: GeneratorField) => {
      setValue(field.fieldId, date ?? "")
    },
    [setValue]
  )

  const handleSummaryResultChange = useCallback((newValue: EditorRoot) => {
    setSummaryResult(newValue)
  }, [])

  const renderField = useCallback(
    (field: GeneratorField): JSX.Element => {
      const { fieldId, fieldName, required } = field
      const defaultProps = {
        control: control,
        name: fieldId,
        key: fieldId,
        id: fieldId,
        label: (required ? "" : "Opt. ") + fieldName,
        error: !!errors[fieldId],
        rules: required ? { required: "This field is required" } : {},
        helperText: !!errors[fieldId] && errors[fieldId]?.message,
        fullWidth: true,
        shrinkLabel: true,
      }

      switch (field.fieldType) {
        case FIELD_TYPES.STRING:
          return <StyledInputField {...defaultProps} error={!!errors[fieldId]} />
        case FIELD_TYPES.TEXT:
          return (
            <StyledInputField
              {...defaultProps}
              multiline
              minRows={4}
              maxRows={10}
              showCharacterCounter
              rules={{
                ...defaultProps.rules,
                maxLength: { value: SUMMARY_MAX, message: "Over character limit" },
              }}
            />
          )
        case FIELD_TYPES.INTEGER:
          return (
            <StyledInputField
              {...defaultProps}
              variant="outlined"
              type="number"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            />
          )
        case FIELD_TYPES.DATE:
          return (
            <Box width="-webkit-fill-available">
              <Controller
                name={fieldId}
                control={control}
                rules={defaultProps.rules}
                render={({ field: fieldProps }) => (
                  <DateField
                    {...fieldProps}
                    initialValue={watch(fieldId) || Date.now().toString()}
                    onChange={date => handleDateChange(date, field)}
                    label={field.fieldName}
                    fieldProps={{ name: fieldId, margin: "normal" }}
                    className={classes.dateField}
                    error={!!errors[fieldId]}
                    helperText={errors[fieldId] ? "This is field required" : undefined}
                  />
                )}
              />
            </Box>
          )
        case FIELD_TYPES.CHOICE:
          return <StyledSelectField {...defaultProps} options={field?.choices ?? []} errors={errors} />
        default:
          return <></>
      }
    },
    [classes.dateField, handleDateChange, errors, control, watch]
  )

  const inputFields: GeneratorField[] = useMemo(() => {
    return inputs.filter(field => field.fieldType !== FIELD_TYPES.TEXT)
  }, [inputs])

  const textareaInputFields: GeneratorField[] = useMemo(() => {
    return inputs.filter(field => field.fieldType === FIELD_TYPES.TEXT)
  }, [inputs])

  const generateMutation = useMutation(summariesApiService.summarize, {
    onSuccess: (data: Summary) => {
      setSummary(data)
    },
    onError: error => {
      showErrorMessage({ message: "Something went wrong while generating the summary.", error })
      setSummaryResult(null)
      setGenerating(false)
    },
  })

  useQuery(
    [queryKeys.summary, summary],
    async () => {
      if (!summary?.pk) return false
      return await summariesApiService.getSummary(summary.pk)
    },
    {
      enabled: !!summary && !summaryResult,
      meta: {
        disableLoader: true,
      },
      refetchInterval: 2000,
      retry: () => {
        return !!summary?.pk && !summaryResult
      },
      onSuccess: (data: Summary) => {
        if (data.result?.status === "success" && data.outputs.length) {
          setSummaryResult(data.outputs[0].value)
          setSummary(data)
          setGenerating(false)
          return
        }

        if (data.result?.status === "error") {
          showErrorMessage(data.result?.error ?? "Something went wrong while generating the summary.")
          setSummaryResult(null)
          setGenerating(false)
          return
        }
      },
    }
  )

  const handleGenerateClick = handleSubmit(
    data => {
      clearErrors()
      setGenerating(true)
      setSummaryResult(null)
      setSummary(null)

      const valueFilledInputs = inputs.map((input: GeneratorField) => {
        return {
          field: input.fieldId,
          value: data[input.fieldId] ?? null,
          fieldType: input.fieldType,
        }
      })

      generateMutation.mutateAsync({
        data: {
          generatorKey: generatorKey,
          inputs: valueFilledInputs,
        },
      })
    },
    foundErrors => {
      // Scroll to first error
      const elements = Object.keys(foundErrors)
        .map(name => document.getElementsByName(name)[0])
        .filter(el => !!el)
      elements.sort((a, b) => b.scrollHeight - a.scrollHeight)
      elements[0]?.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  )

  return (
    <Box display="flex" flexDirection="column" data-selector="summary-form">
      {warningBannerText && <Alert severity="warning">{warningBannerText}</Alert>}
      <Box display="flex">
        {title && (
          <Box fontWeight={600} fontSize="20px">
            {title}
          </Box>
        )}
        <Box ml="auto">
          <IconButton onClick={onClose} data-selector="close-summary">
            <Close />
          </IconButton>
        </Box>
      </Box>
      {title && (
        <Box mt={2}>
          <Divider />
        </Box>
      )}
      <form>
        <Grid container direction={"row-reverse"} spacing={2}>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={12}>
                <GeneratorSelector
                  value={generatorKey ?? ""}
                  onChange={handleGeneratorChange}
                  caseId={caseId}
                  matterId={matterId}
                  generatorKeys={generatorKeys}
                />
              </Grid>
              {!!generatorKey && (
                <>
                  <Grid item xs={12} overflow={"auto"} mt={2}>
                    {!!textareaInputFields.length && (
                      <>
                        <SectionHeader mb={1}>What would you like to summarize?</SectionHeader>
                        {generator?.helpText && (
                          <HelpTextAlert severity="info">{generator?.helpText}</HelpTextAlert>
                        )}
                        {!!additionalInfo && <Box mt={1}>{additionalInfo}</Box>}
                        <Box mt={2}>{textareaInputFields.map(input => renderField(input))}</Box>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box textAlign={"end"}>
                      <GenerateButton
                        data-selector="generate-summary"
                        variant="contained"
                        onClick={handleGenerateClick}
                        disabled={generating}
                      >
                        {!summaryResult ? "Generate" : "Re-Generate"}
                      </GenerateButton>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            {generating ? (
              <Grid item xs={12} mt={3}>
                <ResultLoading />
              </Grid>
            ) : (
              <>
                <SummaryResult
                  generating={generating}
                  output={summary?.outputs[0] ?? null}
                  onSummaryResultChange={handleSummaryResultChange}
                  summaryId={summary?.pk as string}
                  warningText={
                    "Litty is currently under development so the generated summaries may contain some inaccuracies or errors. It is important to thoroughly review all summaries for their accuracy and completeness prior to their use."
                  }
                  showFeedback={showFeedback}
                />
              </>
            )}

            {!!onAddToDocumentClick && !!summaryResult && (
              <Box mt={3} display="flex">
                <Box ml="auto">
                  <GenerateButton
                    variant="contained"
                    disabled={!summary}
                    data-selector="generate-summary"
                    onClick={() =>
                      onAddToDocumentClick(
                        summary as Summary,
                        isSummaryRegenerated,
                        existingSummary ?? undefined
                      )
                    }
                  >
                    {isSummaryRegenerated ? "Replace" : "Add"}
                  </GenerateButton>
                </Box>
              </Box>
            )}
          </Grid>

          <InputContainer item xs={12} md={3} overflow={"auto"} mt={1}>
            {inputFields.length ? (
              inputFields.map(input => renderField(input))
            ) : (
              <p>Please select a summary type.</p>
            )}
          </InputContainer>
        </Grid>
      </form>
    </Box>
  )
}
