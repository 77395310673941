import styled from "@emotion/styled"
import { Box, Typography } from "@mui/material"
import Button from "evenup-ui/Button"
import useUser from "hooks/useUser"
import { getAPIServerURL } from "utils"

const Banner = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  top: 0,
  right: 0,
  gap: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.warning.light,
  padding: theme.spacing(0, 1),
}))

const StopButton = styled(Button)(({ theme }) => ({
  height: "20px",
  padding: theme.spacing(1),
  minWidth: "auto",
}))

export function ImpersonationBanner() {
  const { user } = useUser()

  if (!user.impersonator) {
    return null
  }

  return (
    <Banner>
      <Typography variant="caption">Impersonating: {user.email}</Typography>
      <StopButton href={`${getAPIServerURL()}/impersonate/stop`}>Stop</StopButton>
    </Banner>
  )
}
