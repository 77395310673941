import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Autocomplete, TextField, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { VerticalCenterBox } from "common"
import { isEqual, range } from "lodash"
import { useDocumentStore } from "documents/store"
import { IcdCodeRelation, Exhibit } from "documents/types"
import { v4 } from "uuid"
import { InputBox } from "./styled"

interface RelationFormProps {
  relation: Nullable<IcdCodeRelation>
  relations: Nullable<IcdCodeRelation>[]
  onChange: (relation: Nullable<IcdCodeRelation>) => void
  onDelete?: () => void
}

export function RelationForm({ relations, relation, onChange, onDelete }: RelationFormProps): JSX.Element {
  const uuid = useMemo(() => v4(), [])
  const exhibits = useDocumentStore(state => state.exhibits)
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)
  const partitions = useDocumentStore(state => state.partitions)

  const getOptionLabel = useCallback(
    (exhibitId: Exhibit["id"]) => {
      const index = exhibitsOrder.indexOf(exhibitId) + 1
      const exhibit = exhibits[exhibitId]
      return `Exhibit ${index} - ${exhibit.name}`
    },
    [exhibitsOrder, exhibits]
  )

  const [exhibitId, setExhibitId] = useState(relation ? partitions[relation.partitionId].exhibitId : null)
  const [page, setPage] = useState(relation ? relation.page : null)

  const pageOptions = useMemo(() => {
    if (!exhibitId) return []
    const exhibit = exhibits[exhibitId]
    if (typeof exhibit.pageCount !== "number") return []
    return range(1, exhibit.pageCount + 1)
  }, [exhibits, exhibitId])

  const partitionId = useMemo(() => {
    if (!exhibitId) return null
    const partitionIds = exhibits[exhibitId].partitionIds
    const pageToFind = page ?? 1
    const partitionId = partitionIds.find(
      id => partitions[id].startPage <= pageToFind && partitions[id].endPage >= pageToFind
    )
    return partitionId ?? null
  }, [exhibitId, exhibits, partitions, page])

  useEffect(() => {
    const nextRelation: Nullable<IcdCodeRelation> = partitionId && exhibitId ? { partitionId, page } : null
    if (!isEqual(nextRelation, relation)) onChange(nextRelation)
  }, [relation, partitionId, page, onChange, exhibitId])

  const handleExhibitChange = useCallback((_: React.SyntheticEvent, exhibitId: Nullable<Exhibit["id"]>) => {
    setExhibitId(exhibitId)
    setPage(null)
  }, [])

  return (
    <Box gap={2} display="flex">
      <InputBox mb={3.75}>
        <Box mb={1} fontWeight={600}>
          Exhibit
        </Box>
        <Autocomplete
          size="small"
          disablePortal
          sx={{ width: 260 }}
          id={`Exhibit_${uuid}`}
          options={exhibitsOrder}
          renderInput={params => <TextField {...params} label="Enter exhibit name/number" />}
          getOptionLabel={getOptionLabel}
          renderOption={(props, option) => (
            <li {...props} style={{ whiteSpace: "nowrap" }}>
              {getOptionLabel(option)}
            </li>
          )}
          selectOnFocus
          clearOnBlur
          value={exhibitId}
          onChange={handleExhibitChange}
        />
      </InputBox>
      <InputBox mb={2}>
        <Box mb={1} fontWeight={600}>
          Page number
        </Box>
        <Autocomplete
          size="small"
          disablePortal
          sx={{ width: 135 }}
          id={`Page-number_${uuid}`}
          options={pageOptions}
          renderInput={params => <TextField {...params} label="Enter page number" />}
          selectOnFocus
          clearOnBlur
          value={page}
          getOptionLabel={option => String(option)}
          onChange={(_, value) => setPage(value)}
          disabled={!pageOptions.length}
          getOptionDisabled={pageNumber => {
            return relations.some(
              relation => relation?.page === pageNumber && relation?.partitionId === partitionId
            )
          }}
        />
      </InputBox>
      {onDelete && (
        <VerticalCenterBox mb={1}>
          <IconButton onClick={onDelete} size="small">
            <CloseIcon />
          </IconButton>
        </VerticalCenterBox>
      )}
    </Box>
  )
}
