import styled from "@emotion/styled"
import StatusLabel from "common/status/StatusLabel"
import MultiSelectAutoComplete from "evenup-ui/MultiSelectAutoComplete"
import { listItemClasses, formControlLabelClasses } from "@mui/material"

export const StyledSelect = styled(MultiSelectAutoComplete)({
  [`.${listItemClasses.root}`]: {
    height: "32px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  [`.${formControlLabelClasses.label}`]: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}) as typeof MultiSelectAutoComplete

export const StyledFilterWrapper = styled("div")({
  width: "200px",
})

export const StyledStatusLabel = styled(StatusLabel)({
  textWrap: "nowrap",
  whiteSpace: "nowrap",
})
