import { useCallback } from "react"
import styled from "@emotion/styled"
import { useMutation } from "@tanstack/react-query"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { LoadingButton } from "@mui/lab"
import { requestService } from "api/services/request"
import useUser from "hooks/useUser"
import { INTERNAL_ROLES_VALUES } from "common/models/roles"
import { useConfirm } from "hooks/useConfirm"
import { Box, ButtonProps } from "@mui/material"
import { theme } from "app/theme"

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.common.white,
  background: theme.palette.blue.primary,
  borderRadius: "6px",
  ":hover": {
    background: theme.palette.blue.primary,
  },
}))
StyledButton.defaultProps = {
  variant: "contained",
}

interface StartAnnotationButtonProps {
  requestId: PrimaryKey
}

const confirmStartAnnotationDialogProps = {
  formContent: (
    <Box mt={3}>
      Please confirm if the supporting files have been accuratly manipulated. I.e. files with mutliple
      providers have been split, files with same providers have been combined, and duplicates have been
      deleted.
    </Box>
  ),
  PaperProps: { style: { borderRadius: "8px", padding: "16px 24px" } },
  header: (
    <Box alignItems="center" display="flex" gap={1} fontSize={28}>
      <b>Send to Annotation Confirmation</b>
    </Box>
  ),
  confirmationButtonProps: { sx: { background: theme.palette.blue.primary } } as ButtonProps,
  cancelButtonProps: { variant: "outlined", color: "secondary" } as ButtonProps,
  confirmationButtonContent: "Confirm",
}

export const StartAnnotationButton = ({ requestId }: StartAnnotationButtonProps) => {
  const { showErrorMessage, showInfoMessage, showSuccessMessage } = useHandleMessages()
  const { user } = useUser()

  const { confirm: confirmStartAnnotation, dialog: confirmStartAnnotationDialog } = useConfirm(
    confirmStartAnnotationDialogProps
  )

  const startAnnotationMutation = useMutation(requestService.startRequestAnnotation, {
    onMutate: () => {
      showInfoMessage("Starting annotation process ...")
    },
    onSuccess: () => {
      showSuccessMessage("Annotation has been started")
    },
    onError: error => {
      showErrorMessage({
        message: "There was an error starting annotations. Please contact engineering.",
        error,
      })
    },
  })

  const isLoading = startAnnotationMutation.isLoading

  const handleConfirmStartAnnotation = useCallback(async () => {
    const confirmed = await confirmStartAnnotation()

    if (confirmed) {
      startAnnotationMutation.mutateAsync({
        requestId: requestId,
        data: {},
      })
    }
  }, [confirmStartAnnotation, requestId, startAnnotationMutation])

  if (!INTERNAL_ROLES_VALUES.includes(user.role)) return <></>

  return (
    <>
      {confirmStartAnnotationDialog}
      <StyledButton loading={isLoading} onClick={handleConfirmStartAnnotation}>
        Start Annotation
      </StyledButton>
    </>
  )
}
