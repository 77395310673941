import { useEffect } from "react"
import { useTheme } from "@emotion/react"
import { Star } from "@mui/icons-material"
import { AlertTitle, Box, Typography } from "@mui/material"
import { useRequestContext } from "requests/context"
import { StyledExpensiveRequestNotificationContent, FullWidthMessageAlert } from "./styled"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import { pluralize } from "utils"
import { CreditBreakdown } from "requests/ViewRequest/types"
import { useFormContext, useWatch } from "react-hook-form"
import { SaveAndSubmitButtons } from "./SaveAndSubmitButtons"

type ExpensiveRequestNotificationProps = {
  creditBreakdown: Nullable<CreditBreakdown>
}

export const ExpensiveRequestNotification = ({ creditBreakdown }: ExpensiveRequestNotificationProps) => {
  const { totalPages } = useRequestContext()
  const { getValues } = useFormContext()
  const theme = useTheme()
  const plaintiffs = useWatch({ name: "plaintiffs" })

  useEffect(() => {
    const request = getValues()
    const { pk, plaintiff_first_name, plaintiff_last_name, type } = request

    if (pk) {
      amplitudeApm.trackEvent(
        new RequestAnalyticEvent(RequestAnalyticsEventTypes.SawCreditOverageAlert, {
          credit_total: creditBreakdown?.total_credits ?? 0,
          plaintiff_name: `${plaintiff_first_name} ${plaintiff_last_name}`,
          request_id: pk,
          request_type: type,
        })
      )
    }
  }, [creditBreakdown?.total_credits, getValues])

  return (
    <Box>
      <FullWidthMessageAlert
        sx={{ border: `1px solid ${theme.palette.blue.main}` }}
        severity="info"
        icon={<Star />}
      >
        <StyledExpensiveRequestNotificationContent>
          <Box sx={{ [theme.breakpoints.up("xl")]: { maxWidth: "700px" } }}>
            <AlertTitle>
              <Typography component="span" fontWeight={600}>
                Credit Overage Alert
              </Typography>
            </AlertTitle>
            <Box>
              <Typography fontSize="14px" textAlign="justify">
                The request you are about to submit will cost{" "}
                <Typography component="span" fontWeight={600}>
                  {creditBreakdown?.total_credits?.toFixed(2) ?? 0} credit
                  {(creditBreakdown?.total_credits ?? 0) === 1 ? "" : "s"}.
                </Typography>
              </Typography>
              <Typography fontSize="14px" textAlign="justify">
                By clicking &quot;Submit&quot; you agree to these additional charges.
              </Typography>
            </Box>
            <Box component="ul" sx={{ paddingLeft: 2 }}>
              {!!creditBreakdown?.plaintiff_overage_credits && (
                <Box component="li" sx={{ marginBottom: 1 }}>
                  <Typography fontSize="14px" fontWeight={600}>
                    Client Information Section
                  </Typography>
                  <Typography fontSize="14px">
                    Added {plaintiffs.length - 1} additional{" "}
                    {pluralize({ count: plaintiffs.length - 1, noun: "plaintiff" })} ={" "}
                    {creditBreakdown.plaintiff_overage_credits.toFixed(2)}{" "}
                    {pluralize({
                      count: creditBreakdown.plaintiff_overage_credits,
                      noun: "credit",
                      threshold: 0,
                    })}
                  </Typography>
                </Box>
              )}
              {!!creditBreakdown?.files_overage_credits && (
                <Box component="li" sx={{ marginBottom: 1 }}>
                  <Typography fontSize="14px" fontWeight={600}>
                    Upload Documents Section
                  </Typography>
                  <Typography fontSize="14px">
                    Total Page Count: {totalPages} = {creditBreakdown.files_overage_credits.toFixed(2)}{" "}
                    {pluralize({
                      count: creditBreakdown.files_overage_credits,
                      noun: "credit",
                    })}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <SaveAndSubmitButtons />
        </StyledExpensiveRequestNotificationContent>
      </FullWidthMessageAlert>
    </Box>
  )
}
