import Box from "@mui/material/Box"
import styled from "@emotion/styled"

const StyledTableHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
}))

const StyledLeftContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1.5),
  flexGrow: 1,
}))

const StyledRightContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1.5),
}))

export default function TableActionsHeader({
  children,
}: {
  children: JSX.Element | [JSX.Element, JSX.Element]
}) {
  let leftJSX, rightJSX
  if (Array.isArray(children)) {
    ;[leftJSX, rightJSX] = children
  } else {
    leftJSX = children
  }
  return (
    <StyledTableHeader>
      <StyledLeftContainer>{leftJSX}</StyledLeftContainer>
      <StyledRightContainer>{rightJSX}</StyledRightContainer>
    </StyledTableHeader>
  )
}
