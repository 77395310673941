import { DOCUMENT_TYPES } from "requests/enums"

export const TOOLTIP_TEXT_MAP = {
  [DOCUMENT_TYPES.STANDARD]: {
    ready: "Download Demand Package",
    notReady: "This demand package is not ready to be downloaded",
  },
  [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY]: {
    ready: "Download Medical Chronology",
    notReady: "This medical chronology is not ready to be downloaded",
  },
}

export const DOC_TYPE_TAG_BACKGROUND = {
  [DOCUMENT_TYPES.STANDARD]: "rgba(2, 136, 209, 0.08)",
  [DOCUMENT_TYPES.BASIC_PLUS]: "rgba(248, 208, 187, 1)",
  [DOCUMENT_TYPES.SIMPLE]: "rgba(255, 236, 179, 1)",
  [DOCUMENT_TYPES.MEDICAL_CHRONOLOGY]: "rgba(221, 187, 255, 1)",
  [DOCUMENT_TYPES.PREMIUM_MEDCHRON]: "rgba(178, 223, 219, 1)",
}
