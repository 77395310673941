import { NodeEntry, Path, Transforms } from "slate"
import { createListItem, createListItemContent } from "../../create"
import { CustomEditor, ListItemElement } from "../../CustomEditor"
import { Editor } from "../../Editor"
import { isListItem } from "./queries"

function normalizeEmptyListItem(editor: CustomEditor, [node, path]: NodeEntry<ListItemElement>): boolean {
  if (!node.children.length) {
    Transforms.removeNodes(editor, { at: path })

    const { selection } = editor

    if (selection && Path.isDescendant(selection.anchor.path, path)) {
      const previousNodePath = Path.hasPrevious(path) ? Path.previous(path) : Editor.start(editor, [0])
      Transforms.select(editor, previousNodePath)
    }

    return true
  }

  return false
}

function normalizeInvalidStructure(editor: CustomEditor, [node, path]: NodeEntry<ListItemElement>): boolean {
  const [listItemContent, nestedList] = node.children

  // Valid structure, only content in list
  if (Editor.isListItemContent(editor, listItemContent) && node.children.length === 1) {
    return false
  }

  // Valid structure, list item content + nested list
  if (
    Editor.isListItemContent(editor, listItemContent) &&
    node.children.length === 2 &&
    nestedList &&
    Editor.isList(editor, nestedList)
  ) {
    return false
  }

  const listItems: ListItemElement[] = []
  let lastListItem: Nullable<ListItemElement> = null

  for (const child of node.children) {
    // Normal list item content
    // Create new list item and push to list
    if (Editor.isListItemContent(editor, child)) {
      lastListItem = createListItem([child])
      listItems.push(lastListItem)
      continue
    }

    // Nested List
    if (Editor.isList(editor, child)) {
      // If last list item is full
      // We need to reset state to create new list item
      if (lastListItem && lastListItem.children.length !== 1) {
        lastListItem = null
      }

      // No current valid list item
      // Unwrap list and use its list items as siblings
      if (!lastListItem) {
        listItems.push(...child.children)
        lastListItem = listItems[listItems.length - 1]
        continue
      }

      // Add nested list to last list item
      lastListItem.children[1] = child
      continue
    }

    // Other nodes
    if (lastListItem && lastListItem.children.length === 2) {
      lastListItem = null
    }

    if (!lastListItem) {
      lastListItem = createListItem([createListItemContent([child])])
      listItems.push(lastListItem)
      continue
    }

    lastListItem.children[0].children.push(child)
  }

  // Actually we have invalid structure for current list item
  // We prepared a list of correct new list items
  // So need to remove existing invalid item and insert some valid
  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, { at: path })

    if (listItems) {
      Transforms.insertNodes(editor, listItems, { at: path })
    }
  })

  // Normalize selection if selected node is not accessible
  Editor.normalizePath(editor, path)

  return true
}

export function normalizeListItem(editor: CustomEditor, [node, path]: NodeEntry): boolean {
  if (!isListItem(editor, node)) return false

  return normalizeEmptyListItem(editor, [node, path]) || normalizeInvalidStructure(editor, [node, path])
}
