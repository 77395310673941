import { ASSET_KEYS, AssetKey, ECONOMIC_INJURIES_FIELDS, INJURY_IMPACTS_KEYS } from "requests/constants"
import { ECONOMIC_IMPACT_TYPES, PlaintiffProps, NonEconomicInjuriesProps } from "./types"
import { cloneDeep } from "lodash"

export const toParseableString = (value: string | undefined): string => {
  if (!value) return "[]"
  return value.replace(/'/g, '"')
}

export const removeInjuryInInjuryImpactKey = (key: string) => {
  return key.replace("injury_", "") as keyof NonEconomicInjuriesProps
}

export const getInjuryImpactDisplay = (display: string) => {
  switch (display) {
    case INJURY_IMPACTS_KEYS.recreation.display:
      return "Recreation and Leisure"
    case INJURY_IMPACTS_KEYS.socialActivities.display:
      return "Social Time"
    default:
      return display
  }
}

export const generatePlaintiff = (
  plaintiff: PlaintiffProps,
  assets: Partial<Record<string, ValueOptions<string>>>
): PlaintiffProps => {
  const clonedPlaintiff = cloneDeep(plaintiff)

  // Format the non economic impact from string array of number string to OptionProps[]
  // Sample response from the API: impacts_recreation: "['1', '2', '3', '4', '5', '6', '7']"
  Object.entries(INJURY_IMPACTS_KEYS).forEach(([injuryKey, injuryValue]) => {
    const newInjuryKey = removeInjuryInInjuryImpactKey(injuryValue.key) // Remove injury_ from NON_ECONOMIC_IMPACT_TYPES
    // Format the value from array of number string to OptionProps[]
    clonedPlaintiff[newInjuryKey] = clonedPlaintiff[newInjuryKey]
      ? assets[ASSET_KEYS[injuryKey as AssetKey]]
          ?.filter(({ key }) => (plaintiff[newInjuryKey] as string[])?.includes(key))
          .map(({ key, display }) => ({ value: key, label: display })) ?? []
      : []
  })

  // Format economic impacts from boolean to OptionProp
  Object.values(ECONOMIC_INJURIES_FIELDS).forEach(({ key }) => {
    // Because there isn't 'has_household_services' field among plaintiff props
    // its value is defined based on 'household_impairment_rate'
    const result =
      (key === ECONOMIC_IMPACT_TYPES.ECONOMIC_HAS_HOUSEHOLD_SERVICES &&
        plaintiff.household_impairment_rate) ||
      clonedPlaintiff[key]

    clonedPlaintiff[key] = result ? { label: "Yes", value: "yes" } : { label: "No", value: "no" }
  })

  return clonedPlaintiff
}
