import { useState, useRef } from "react"
import { Popover, Box, Typography, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { Draggable } from "../Draggable"
import { StyledHeader, StyledContent } from "./styled"
import { DraggablePopoverProps } from "./types"

export const DraggablePopover = ({
  title,
  titleIcon,
  onClose,
  children,
  open,
  ...otherProps
}: DraggablePopoverProps) => {
  const [headerElement, setHeaderElement] = useState(null)
  const refToElement = useRef(setHeaderElement).current

  if (!open) return null

  return (
    <Draggable target={headerElement}>
      <Popover open={open} onClose={onClose} {...otherProps}>
        <StyledContent ref={!title ? refToElement : undefined}>
          {title && (
            <StyledHeader>
              <Box display="flex" width="100%" gap={2} ref={refToElement}>
                {titleIcon}
                <Typography fontWeight={700} fontSize="24px">
                  {title}
                </Typography>
              </Box>
              {onClose && (
                <IconButton onClick={onClose} size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </StyledHeader>
          )}

          {children}
        </StyledContent>
      </Popover>
    </Draggable>
  )
}
