import { useEffect, useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { STALE_TIMEOUT, queryKeys as defaultQueryKeys } from "react-query/constants"
import { AuthorizedUser, NotAuthorizedUser } from "common/models/user"
import { fullstoryApm, sentryApm } from "infrastructure/apm"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { VitallyLib } from "infrastructure/vendors/Vitally"
import { BeamerLib } from "infrastructure/vendors/BeamerLib"
import { permissionService } from "api/services/permissions"
import { documentActions } from "documents/store"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { queryClient } from "react-query/queryClient"
import { NotAuthorizedError, useUserStore, actions as userActions } from "user/store"
import { AUTHENTICATE_ROUTE } from "app/constants"
import { useLocationStore } from "app/location/store"

useUserStore.subscribe(
  state => state.user,
  user => {
    permissionService.clearCache()
    documentActions.setUser(user)
    queryClient.setQueryData([defaultQueryKeys.session], user)

    if (user.isAuthorized) {
      fullstoryApm.setUserContext(user)
      amplitudeApm.setUserContext(user)
      sentryApm.setUserContext(user)
      VitallyLib.setUserContext(user)
      BeamerLib.setUserContext(user)
    } else {
      fullstoryApm.resetUserContext()
      amplitudeApm.resetUserContext()
      sentryApm.resetUserContext()
      BeamerLib.resetUserContext()
    }
  }
)

function pollUser() {
  const interval = useUserStore.getState().refetchInterval

  if (interval !== null) clearInterval(interval)

  if (!document.hidden) {
    useUserStore.setState({
      refetchInterval: window.setInterval(userActions.fetchUser, STALE_TIMEOUT.SHORT),
    })
  }
}

document.addEventListener("visibilitychange", pollUser)
pollUser()

interface UseUserProps {
  disableLoader?: boolean
  suspense?: boolean
}

interface UseUserHookReturn {
  isInitialized: boolean
  isLoading: boolean
  error: unknown // TODO: prepare common typed errors mechanism
  user: AuthorizedUser | NotAuthorizedUser
  isAuthorized: boolean
}

export default function useUser(props: UseUserProps = {}): UseUserHookReturn {
  const { disableLoader = false, suspense = false } = props

  const isAuthenticationPage = useLocationStore(state => state.pathname === AUTHENTICATE_ROUTE)
  const user = useUserStore(state => state.user)
  const isLoaded = useUserStore(state => state.isLoaded)
  const hasError = useUserStore(state => state.error)

  const { showErrorMessage } = useHandleMessages()
  const error = useMemo(() => (hasError ? new NotAuthorizedError() : null), [hasError])

  useQuery(
    [defaultQueryKeys.session],
    async () => {
      await userActions.fetchUser()
      return useUserStore.getState().user
    },
    {
      retry: false,
      keepPreviousData: true,
      staleTime: STALE_TIMEOUT.PERMANENT,
      suspense,
      meta: {
        disableLoader: shouldDisableLoader({ suspense, isLoaded, disableLoader }),
      },
      enabled: !isLoaded && !isAuthenticationPage,
    }
  )

  useEffect(() => {
    if (error) {
      showErrorMessage("Authorization failed. Please go to login page and re-authorize.")
    }
  }, [error, showErrorMessage])

  return useMemo(
    () => ({
      isInitialized: isLoaded,
      isLoading: !isLoaded,
      error,
      user,
      isAuthorized: user instanceof AuthorizedUser,
    }),
    [error, isLoaded, user]
  )
}

const shouldDisableLoader = ({
  suspense,
  isLoaded,
  disableLoader,
}: {
  suspense: boolean
  isLoaded: boolean
  disableLoader: boolean
}) => {
  if (disableLoader) return true
  if (suspense) return true

  return isLoaded
}
