import { RegisterOptions, Validate } from "react-hook-form"

export const requiredField = (message: string = "This field is required"): RegisterOptions => ({
  required: { value: true, message },
})

export const createIsIntegerValidator = (
  message: string = "Please enter a integer"
): Validate<unknown, unknown> => {
  return value => {
    // Note, we are not running validation on empty string, undefined or null
    // since we only want to run this validation rule when there is a value
    // use "required" rule for checking if the field is required
    if (typeof value !== "string" || value === "") return true

    return Number.isInteger(parseFloat(value)) || message
  }
}

export const createIsPositiveValidator = (
  message: string = "Please enter number greater than 0"
): Validate<unknown, unknown> => {
  return value => {
    if (typeof value !== "string" || value === "") return true
    return parseFloat(value) > 0 || message
  }
}

export const createDateInPastValidator = (
  message: string = "Date cannot be in the future."
): Validate<Date | string, unknown> => {
  return value => {
    // Skip checking invalid dates
    if (isNaN(Date.parse(String(value)))) return true

    return new Date(value) <= new Date() || message
  }
}
