import React, { useState, forwardRef, useCallback, MouseEventHandler } from "react"
import TextField from "@mui/material/TextField"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Paper from "@mui/material/Paper"
import MenuItem from "@mui/material/MenuItem"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import CircularProgress from "@mui/material/CircularProgress"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import DownloadIcon from "@mui/icons-material/GetApp"
import InsertDriveFileOutlined from "@mui/icons-material/InsertDriveFileOutlined"
import Cancel from "@mui/icons-material/Cancel"
import { makeStyles } from "tss-react/mui"

import { MAX_FILE_NAME_LENGTH } from "demand/constants"
import { ExhibitValidationError } from "demand/Providers/types"
import LinkButton from "../../buttons/LinkButton"
import splitFileName from "./splitFileName"
import TextButton from "../../buttons/TextButton"
import { DraggableProvidedDraggableProps } from "react-beautiful-dnd"
import FileTag from "./FileTag"
import styled from "@emotion/styled"

interface StyleProps {
  highlightOnHover: boolean
  editing?: boolean
}

const useStyles = makeStyles<StyleProps>()((theme, { highlightOnHover, editing }) => ({
  container: {
    display: "flex",
    padding: theme.spacing(2),
    alignItems: "center",
    marginBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: highlightOnHover && !editing ? theme.palette.blue.light : "inherit",
      cursor: highlightOnHover && !editing ? "pointer" : "inherit",
    },
  },
  existingFileIcon: {
    fontSize: "1.9rem",
    marginRight: theme.spacing(2),
  },
  existingFileName: {
    fontWeight: "bold",
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
    justifySelf: "end",
  },
}))

const EditFileBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  width: "100%",
  marginRight: theme.spacing(2),
}))

export interface UploadedFileProps {
  id: number
  fileName?: string
  fileType?: string
  fileTypeMap: Record<string, string>
  editing?: boolean
  onFileNameChange: (fileName: string) => void
  onFileTypeChange: (fileType: string) => void
  onEdit: () => void
  onSave: () => void
  onCancel: () => void
  onDelete: () => void
  onDownload: (fileName: string) => Promise<void>
  onClick?: (id: number, exhibitType: string | undefined) => void
  disabled: boolean
  validationError?: ExhibitValidationError | null
  editable?: boolean
  draggableProps?: DraggableProvidedDraggableProps
  dragHandle?: React.ReactNode
  tagLabel?: string
  highlightOnHover?: boolean
  exhibitType?: string
}

type EditFileFormProps = Pick<
  UploadedFileProps,
  | "id"
  | "fileName"
  | "fileType"
  | "fileTypeMap"
  | "onFileNameChange"
  | "onFileTypeChange"
  | "disabled"
  | "validationError"
>

const EditFileForm: React.FC<EditFileFormProps> = ({
  id,
  fileName,
  fileType,
  onFileNameChange,
  onFileTypeChange,
  disabled,
  validationError,
  fileTypeMap,
}) => {
  const [name, extension] = splitFileName(fileName ?? "")
  const handleFileNameChange: React.FocusEventHandler<HTMLInputElement> = event => {
    onFileNameChange(event.target.value + extension)
  }

  const handleFileTypeChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void = event => {
    onFileTypeChange(event.target.value as string)
  }

  return (
    <EditFileBox>
      <TextField
        label="File name"
        defaultValue={name}
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">{extension}</InputAdornment>,
        }}
        onBlur={handleFileNameChange}
        disabled={disabled}
        size="small"
        // slightly less than the max to account for file ext getting added automatically
        inputProps={{ maxLength: MAX_FILE_NAME_LENGTH - extension.length }}
        error={!!validationError?.name}
        helperText={validationError?.name}
      />
      <FormControl fullWidth disabled={disabled} error={!!validationError?.type}>
        <InputLabel id={`file-type-label-${id}`}>File type</InputLabel>
        <Select
          value={fileType ?? ""}
          labelId={`file-type-label-${id}`}
          id={`file-type-${id}`}
          label="File type"
          size="small"
          onChange={handleFileTypeChange}
          aria-describedby={validationError?.type ? `file-type-helper-text-${id}` : undefined}
        >
          <MenuItem value="" disabled>
            Please Select
          </MenuItem>
          {Object.entries(fileTypeMap).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {!!validationError?.type && <FormHelperText>{validationError?.type}</FormHelperText>}
      </FormControl>
    </EditFileBox>
  )
}

const UploadedFile: React.ForwardRefRenderFunction<React.Ref<HTMLElement>, UploadedFileProps> = (
  {
    id,
    editing,
    fileName,
    fileType,
    fileTypeMap,
    onDelete,
    onDownload,
    disabled,
    onFileNameChange,
    onFileTypeChange,
    onSave,
    onEdit,
    onCancel,
    validationError,
    editable = false,
    draggableProps,
    dragHandle,
    exhibitType,
    tagLabel = "",
    onClick = undefined,
    highlightOnHover = false,
  },
  ref
) => {
  const { classes } = useStyles({ highlightOnHover, editing })
  const [isDownloading, setIsDownloading] = useState(false)

  const onCancelHandler = useCallback<MouseEventHandler<HTMLElement>>(
    e => {
      e.stopPropagation()
      onCancel()
    },
    [onCancel]
  )

  const onSaveHandler = useCallback<MouseEventHandler<HTMLElement>>(
    e => {
      e.stopPropagation()
      onSave()
    },
    [onSave]
  )

  const onEditHandler = useCallback<MouseEventHandler<HTMLElement>>(
    e => {
      e.stopPropagation()
      onEdit()
    },
    [onEdit]
  )

  const onDeleteHandler = useCallback<MouseEventHandler<HTMLElement>>(
    e => {
      e.stopPropagation()
      onDelete()
    },
    [onDelete]
  )

  const onContainerClickHandler = useCallback<MouseEventHandler<HTMLElement>>(() => {
    !editing && onClick && onClick(id, exhibitType)
  }, [editing, exhibitType, id, onClick])

  const onDownloadHandler = useCallback<MouseEventHandler<HTMLElement>>(
    async e => {
      e.stopPropagation()
      try {
        setIsDownloading(true)
        await onDownload(fileName ?? "")
      } finally {
        setIsDownloading(false)
      }
    },
    [fileName, onDownload]
  )

  return (
    <Paper
      {...draggableProps}
      ref={ref}
      component={Box}
      variant="outlined"
      className={classes.container}
      data-test="uploaded-file"
      onClick={onContainerClickHandler}
    >
      {dragHandle}
      {!editing && (
        <>
          <InsertDriveFileOutlined className={classes.existingFileIcon} />
          <Box width="100%">
            <Box className={classes.existingFileName}>{fileName}</Box>
            <Box>{fileType ? fileTypeMap[fileType] ?? fileType : fileType}</Box>
          </Box>
        </>
      )}
      {editing && (
        <EditFileForm
          id={id}
          fileName={fileName}
          fileType={fileType}
          onFileNameChange={onFileNameChange}
          onFileTypeChange={onFileTypeChange}
          disabled={disabled}
          validationError={validationError}
          fileTypeMap={fileTypeMap}
        />
      )}
      <Box className={classes.buttons}>
        {editing ? (
          <>
            <TextButton disabled={disabled} textColor="blue" onClick={onSaveHandler}>
              Save
            </TextButton>
            <TextButton disabled={disabled} textColor="grey" onClick={onCancelHandler}>
              Cancel
            </TextButton>
          </>
        ) : (
          <>
            <Box alignSelf="center" mr={2}>
              <FileTag label={tagLabel} />
            </Box>
            {editable === true && (
              <Box mr={1} alignSelf="center">
                <LinkButton onClick={onEditHandler}>Edit</LinkButton>
              </Box>
            )}

            {isDownloading ? (
              <IconButton disabled={true}>
                <CircularProgress size={20} />
              </IconButton>
            ) : (
              <IconButton disabled={disabled} onClick={onDownloadHandler}>
                <DownloadIcon />
              </IconButton>
            )}

            <IconButton disabled={disabled} onClick={onDeleteHandler}>
              <Cancel />
            </IconButton>
          </>
        )}
      </Box>
    </Paper>
  )
}

export default forwardRef(UploadedFile)
