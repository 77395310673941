import React, { useCallback, useMemo } from "react"
import { Editor } from "common/form-components/rich-text/Editor"
import { useSlate } from "slate-react"
import { StyledButton, StyledTextButton, StyledToolbarGroup } from "../styled"

export const SectionSymbolButton = (): JSX.Element => {
  const editor = useSlate()

  const handleInsertSectionSymbol = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      Editor.insertSectionSymbol(editor)
    },
    [editor]
  )

  return useMemo(
    () => (
      <StyledToolbarGroup>
        <StyledButton onMouseDown={handleInsertSectionSymbol}>
          <StyledTextButton>§</StyledTextButton>
        </StyledButton>
      </StyledToolbarGroup>
    ),
    [handleInsertSectionSymbol]
  )
}
