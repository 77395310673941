import { useEffect, useState, useMemo } from "react"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import FormControlLabel from "@mui/material/FormControlLabel"
import CircularProgress from "@mui/material/CircularProgress"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { useParams } from "react-router-dom"
import { isEqual } from "lodash"
import DownloadIcon from "@mui/icons-material/GetApp"
import ErrorIcon from "@mui/icons-material/Error"

import { GoToRequestButton } from "common/buttons/GoToRequestButton"
import { SILENT_QUERY_PARAMS, queryKeys } from "../react-query/constants"
import { dateFormat, timeFormat } from "../utils"
import {
  generateDemand,
  listGeneratedDemandsV2,
  downloadDemandV2,
  validateDemand,
  getRequestByCase,
} from "../api"

import { GenericError } from "../common/GenericError"
import { useInterval } from "../common/utils"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useHandleMessages } from "../common/messages/useHandleMessages"
import { DemandValidation } from "./DemandValidation"
import { hasValidationIssues } from "./utils"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { DemandAnalyticEvent, DemandAnalyticsEventTypes } from "infrastructure/apm/events/demandEvents"

import useCase from "hooks/useCase"
import { useSyncWithDocumentStructure } from "hooks/useSyncWithDocumentStructure"
import { GeneratedDocuments } from "requests/ViewRequest/ViewRequestRevamp/components"
import { DocumentGenerateButton } from "documents/exhibits/DocumentGenerateButton"
import { DocumentContextProvider } from "documents/context"
import MobileNavButton from "./LeftNav/MobileNavButton"

import { SyncWithDocumentStructureButton } from "../common/SyncWithDocumentStructureButton"
import { useMultiPlaintiffDemandGenerator } from "hooks/useMultiPlaintiffDemandGenerator"

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(6),
    alignItems: "center",
  },
  buttonProgress: {
    position: "absolute",
    left: "50%",
    top: "50%",
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
  },
}))

const STATES = {
  IDLE: "",
  DOWNLOADING: "inProgress",
  DONE: "success",
  ERROR: "error",
}

function DemandGenerationStatus({ caseId, id, status }) {
  const [isDownloading, setIsDownloading] = useState(false)

  const downloadDemand = async () => {
    try {
      setIsDownloading(true)
      const response = await downloadDemandV2(caseId, id)
      const link = document.createElement("a")
      link.href = response.url
      link.setAttribute("download", "")
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    } catch (err) {
      // we should throw an error here
      // eslint-disable-next-line
      console.error(err)
    } finally {
      setIsDownloading(false)
    }
  }

  return status === "in_progress" ? (
    <IconButton size="small" disabled>
      <CircularProgress size={18} />
    </IconButton>
  ) : status == "error" ? (
    <IconButton size="small" disabled>
      <ErrorIcon color={"error"} />
    </IconButton>
  ) : status == "complete" ? (
    isDownloading ? (
      <LoadingButton size="small" loading disabled>
        {"\u00A0"}
      </LoadingButton>
    ) : (
      <IconButton size="small" onClick={downloadDemand}>
        <DownloadIcon />
      </IconButton>
    )
  ) : (
    "Not Started"
  )
}

function GeneratedDemands({ caseId }) {
  const [generatedRequests, setGeneratedRequests] = useState(null)

  useQuery([queryKeys.allGeneratedDemands], () => listGeneratedDemandsV2(caseId), {
    onSuccess: data => {
      if (!isEqual(data.results, generatedRequests)) {
        setGeneratedRequests(data.results)
      }
    },
  })

  const listGeneratedDemandsMutation = useMutation(listGeneratedDemandsV2, {
    onSuccess: data => {
      if (!isEqual(data.results, generatedRequests)) {
        setGeneratedRequests(data.results)
      }
    },
  })

  useInterval(() => {
    listGeneratedDemandsMutation.mutateAsync(caseId)
  }, 1000 * 5)

  const formatDateTime = dateStr => {
    const dateText = dateFormat.format(new Date(dateStr))
    const timeText = timeFormat.format(new Date(dateStr))

    return `${dateText} ${timeText}`
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Started</TableCell>
          <TableCell>Last Update</TableCell>
          <TableCell>Completed At</TableCell>
          <TableCell align="center">Download</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!!generatedRequests?.length &&
          generatedRequests.map(r => (
            <TableRow key={r.pk}>
              <TableCell>{formatDateTime(r.requested_at)}</TableCell>
              <TableCell>{r.last_updated_at ? formatDateTime(r.last_updated_at) : "--"}</TableCell>
              <TableCell>{r.completed_at ? formatDateTime(r.completed_at) : "--"}</TableCell>
              <TableCell align="center">
                <DemandGenerationStatus caseId={caseId} id={r.pk} status={r.status} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

function DemandGenerationRequests({ caseId, documentId, requestId }) {
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  if (multiPlaintiffEnabled && documentId && requestId) {
    return <GeneratedDocuments documentId={documentId} requestId={requestId} title="Generated Demands" />
  } else {
    return <GeneratedDemands caseId={caseId} />
  }
}

export function Review() {
  const { classes } = useStyles()
  const { id: caseId } = useParams()
  const [status, setStatus] = useState({ state: STATES.IDLE, message: "" })
  const {
    isLoading,
    data: validations,
    error,
  } = useQuery([queryKeys.validateDemand, caseId], validateDemand, { retry: false })
  const [extendedExhibits, setExtendedExhibits] = useState(false)
  const queryClient = useQueryClient()
  const { showErrorMessage } = useHandleMessages()
  const { data: request } = useQuery([queryKeys.request, caseId], getRequestByCase, SILENT_QUERY_PARAMS)
  const { caseObj } = useCase(caseId)
  const multiPlaintiffEnabled = useMultiPlaintiffDemandGenerator(caseId)

  useEffect(() => {
    if (request) {
      amplitudeApm.trackEvent(
        new DemandAnalyticEvent(DemandAnalyticsEventTypes.ReviewAndGenerateStarted, {
          demand_id: caseId,
          request_id: request.pk,
          request_type: request.type,
        })
      )

      return () =>
        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(DemandAnalyticsEventTypes.ReviewAndGenerateExited, {
            demand_id: caseId,
            request_id: `${request.pk}`,
            request_type: request.type,
          })
        )
    }
  }, [caseId, request])

  const startDemand = async () => {
    try {
      await generateDemand(caseId, extendedExhibits)
      queryClient.invalidateQueries([queryKeys.allGeneratedDemands])
      if (request) {
        amplitudeApm.trackEvent(
          new DemandAnalyticEvent(DemandAnalyticsEventTypes.DemandGenerated, {
            demand_id: caseId,
            request_id: request.pk,
            request_type: request.type,
          })
        )
      }
    } catch (error) {
      showErrorMessage({
        message: "There was an error creating the demand. Please contact engineering.",
        error,
      })
      setStatus({ state: STATES.ERROR })
    }
  }

  const documentId = caseObj?.document_id
  const requestId = caseObj?.questionnaire_id
  const showDocumentGenButton = multiPlaintiffEnabled && documentId && requestId

  const contextValue = useMemo(() => (documentId ? { documentId } : null), [documentId])

  const {
    isSyncDocumentStructureRequired,
    isLoading: isSyncingDocumentStructure,
    handleSyncDocumentStructure,
  } = useSyncWithDocumentStructure({ documentId, fetchOnMount: !!showDocumentGenButton })

  if (error) {
    return <GenericError error={error} />
  }

  const showValidationMessage =
    hasValidationIssues(validations?.errors) ||
    hasValidationIssues(validations?.warnings) ||
    hasValidationIssues(validations?.infos)

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.buttonWrapper}>
        <Box marginRight="auto">
          <MobileNavButton />
        </Box>
        <GoToRequestButton />
      </Box>
      {!isLoading && showValidationMessage && <DemandValidation validations={validations} />}

      {showDocumentGenButton && isSyncDocumentStructureRequired && (
        <SyncWithDocumentStructureButton
          isLoading={isSyncingDocumentStructure}
          onSync={handleSyncDocumentStructure}
        />
      )}

      <FormControlLabel
        label="Also include inline images as exhibits."
        control={
          <Checkbox
            color="secondary"
            checked={extendedExhibits}
            onChange={(event, checked) => setExtendedExhibits(checked)}
          />
        }
      />
      {showDocumentGenButton ? (
        <DocumentContextProvider value={contextValue}>
          <DocumentGenerateButton
            text="Generate Demand Package"
            redirectOnGenerate={false}
            extendedExhibits={extendedExhibits}
            isSyncDocumentStructureRequired={isSyncDocumentStructureRequired}
            isSyncingDocumentStructure={isSyncingDocumentStructure}
            onSyncDocumentStructure={handleSyncDocumentStructure}
          />
        </DocumentContextProvider>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          disabled={status.state === STATES.DOWNLOADING}
          onClick={startDemand}
          style={{ marginTop: "10px" }}
        >
          Generate Demand
        </Button>
      )}
      <DemandGenerationRequests
        caseId={parseInt(caseId)}
        documentId={caseObj?.document_id}
        requestId={caseObj?.questionnaire_id}
      />
      {status.state === STATES.DOWNLOADING && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Box>
  )
}
