import { ITEM_REF } from "common/nested-list/constants"
import { UserExhibitContent } from "./UserExhibit/UserExhibitContent"
import { ProjectionItemWithChildren } from "common/nested-list/types"
import { ExhibitPartitionContent } from "./ExhibitPartition/ExhibitPartitionContent"
import { DndUserExhibitListData } from "exhibit-builder/store/types"

export function UserExhibitListContent(props: { item: ProjectionItemWithChildren<DndUserExhibitListData> }) {
  const itemData = props.item[ITEM_REF]

  switch (itemData.type) {
    case "userExhibit":
      return <UserExhibitContent id={itemData.id} />
    case "exhibitPartition":
      return <ExhibitPartitionContent id={itemData.id} />
    default:
      return null
  }
}
