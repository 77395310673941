import { PropsWithChildren, Suspense, memo, useEffect } from "react"
import { RequestSkeleton } from "./RequestSkeleton"
import { RequestAssets } from "./RequestAssets"
import { QueryErrorResetBoundary, useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { queryKeys } from "react-query/constants"

export const RequestContainer = memo(function RequestContainer({ children }: PropsWithChildren): JSX.Element {
  const { id } = useParams()
  const queryClient = useQueryClient()

  useEffect(() => {
    return () => {
      if (id) {
        queryClient.invalidateQueries([queryKeys.request])
        queryClient.invalidateQueries([queryKeys.case])
      }
    }
  }, [id, queryClient])

  return (
    <QueryErrorResetBoundary>
      <Suspense fallback={<RequestSkeleton />}>
        <RequestAssets>{children}</RequestAssets>
      </Suspense>
    </QueryErrorResetBoundary>
  )
})
