import { DndProps, SortableNestedList } from "common/nested-list/SortableNestedList"
import { useCallback, useEffect, useMemo, useState } from "react"
import { v4 } from "uuid"
import Button from "evenup-ui/Button"
import { RangeItem } from "./types"
import { ListItem } from "./ListItem"
import { SortableListContainer } from "../../exhibit-builder/UserExhibitList/ActionPopup/styled"

function createItem(): RangeItem {
  return {
    id: v4(),
    startPage: "",
    endPage: "",
    errors: { startPage: "", endPage: "" },
  }
}

export function validatePageRange(startPage: string, endPage: string, range: { start: number; end: number }) {
  const errors = {
    startPage: "",
    endPage: "",
  }

  if (startPage && (Number(startPage) > range.end || Number(startPage) < range.start)) {
    errors.startPage = "Page not in range"
  }

  if (endPage && (Number(endPage) > range.end || Number(endPage) < range.start)) {
    errors.endPage = "Page not in range"
  }

  if (startPage && endPage && Number(endPage) < Number(startPage)) {
    errors.endPage = "Page must be greater than start page"
  }

  return errors
}

export function SortableRangeInput({
  disabled,
  onChange,
  range,
}: {
  disabled?: boolean
  range: { start: number; end: number }
  onChange: (ranges: RangeItem[]) => void
}) {
  const [items, setItems] = useState([createItem()])

  const addItem = () => {
    setItems([...items, createItem()])
  }

  const updateItem = useCallback(
    (id: string, startPage: string, endPage: string) => {
      setItems(
        items.map(item =>
          item.id === id
            ? { ...item, startPage, endPage, errors: validatePageRange(startPage, endPage, range) }
            : item
        )
      )
    },
    [items, range]
  )

  const removeItem = useCallback(
    (id: string) => {
      setItems(items.filter(item => item.id !== id))
    },
    [items]
  )

  const reorderList: DndProps<RangeItem>["onUpdate"] = items => {
    setItems(items.map((item, index) => ({ ...item, index })))
  }

  useEffect(() => {
    onChange(items)
  }, [items, onChange])

  const contentProps = useMemo(
    () => ({ onChange: updateItem, onRemove: removeItem, canDeleteRange: items.length > 1, disabled }),
    [updateItem, removeItem, items, disabled]
  )

  useEffect(() => {
    setItems([createItem()])
  }, [range])

  return (
    <SortableListContainer>
      <SortableNestedList
        items={items}
        uniqueKey="id"
        ItemContentComponent={ListItem}
        PlaceholderComponent={() => null}
        onUpdate={reorderList}
        contentProps={contentProps}
      />

      <Button textButton color="secondary" onClick={addItem} disabled={disabled}>
        + Add another page range
      </Button>
    </SortableListContainer>
  )
}
